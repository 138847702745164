
const styles = {
  buttontext: {
    fontWeight: "bold",
    color: "#000"
  },
  button: {
    alignSelf: "stretch",
    alignItems: "center",
    padding: 18,
    backgroundColor: "#A8CF45",
    marginTop: 20,
    borderRadius: 2
  },
  rightButton: {
    alignItems: "center",
    alignSelf: "flex-start",
    padding: 1,
    backgroundColor: "#A8CF45",
    marginLeft: 4.5,
    width: 40,
    height: 40,
    justifyContent: "center",
    borderTopEndRadius: 20,
    borderBottomEndRadius: 20
  },
  textinput: {
    alignSelf: "stretch",
    height: 40,
    marginBottom: 20,
    color: "#FFFFFF",
    backgroundColor: "#aaa",
    borderRadius: 33,
    borderBottomWidth: 0,
    color: "black",
    paddingLeft:10,
    flex:1
  },
  header: {
    fontSize: 24,
    color: "#FFFFFF",
    paddingBottom: 10,
    marginBottom: 40,
    borderBottomColor: "#FFFFFF",
    alignItems:"center",
    textAlign:"center"
    //borderBottomWidth: 1
  },
  header2: {
    fontSize: 24,
    color: "#FFFFFF",
    paddingBottom: 10,
    marginBottom: 10,
    borderBottomColor: "#FFFFFF",
    alignItems:"center"
  },
  regform: {
    alignSelf: "stretch",
    alignItems:"center"
  },
  belowText: {
    alignSelf: "stretch",
    height: 40,
    marginTop: 30,
    color: "#ffffff",
    borderBottomColor: "#FFFFFF"
  },
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: "#000",
    paddingLeft: 55,
    paddingRight: 55,
    alignItems:"center"
  }
};

export default styles;
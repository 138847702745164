//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
// Screen always "synced" between Wakanda Market and Health
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  Alert,
  Dimensions,
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import { NavigationEvents } from "react-navigation";



export default class JomeInnovationKitchenAgendaScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Agenda & Signup",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
    }
  };

  state = {
    speakers: [
      {
        name: "Biodun Makinde",
        title: "COO, African Founders",
        image: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/Events%2Felon.jpg?alt=media&token=dbd7fd86-6e52-437d-a7a7-e0390d01e6c6'
      },
      {
        name: "Subomi Plumptre",
        title: "Partner, Alder Consulting",
        image: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/Events%2Felon.jpg?alt=media&token=dbd7fd86-6e52-437d-a7a7-e0390d01e6c6'
      },
      {
        name: "Bidemi Akinade",
        title: "Founder, Guru",
        image: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/Events%2Felon.jpg?alt=media&token=dbd7fd86-6e52-437d-a7a7-e0390d01e6c6'
      },
      {
        name: "Christian Keller",
        title: "CEO, African Founders",
        image: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/Events%2Felon.jpg?alt=media&token=dbd7fd86-6e52-437d-a7a7-e0390d01e6c6'
      }
    ],
    wk1: "workshop1",
    wk2: "workshop2",
    wk3: "workshop3",
    wk4: "workshop4",
    wk5: "workshop5",
    wk6: "workshop6",
    wk7: "workshop7",
    wk8: "workshop8",
    wk9: "workshop9",
    wk10: "workshop10",
    wk11: "workshop11",
    wk12: "workshop12",
    wk13: "workshop13",
    wk14: "workshop14",
    wk15: "workshop15",
    wk16: "workshop16",
    wk17: "workshop17",
    wk18: "workshop18",
    wk19: "workshop19",
    wk20: "workshop20",
    wk21: "workshop21",
    wk22: "workshop22",
    wk23: "workshop23",
    wk24: "workshop24",
    wk25: "workshop25",
    wk26: "workshop26",
    wk27: "workshop27",
    wk1JoinerIDs: [],
    wk2JoinerIDs: [],
    wk3JoinerIDs: [],
    wk4JoinerIDs: [],
    wk5JoinerIDs: [],
    wk6JoinerIDs: [],
    wk7JoinerIDs: [],
    wk8JoinerIDs: [],
    wk9JoinerIDs: [],
    wk10JoinerIDs: [],
    wk11JoinerIDs: [],
    wk12JoinerIDs: [],
    wk13JoinerIDs: [],
    wk14JoinerIDs: [],
    wk15JoinerIDs: [],
    wk16JoinerIDs: [],
    wk17JoinerIDs: [],
    wk18JoinerIDs: [],
    wk19JoinerIDs: [],
    wk20JoinerIDs: [],
    wk21JoinerIDs: [],
    wk22JoinerIDs: [],
    wk23JoinerIDs: [],
    wk24JoinerIDs: [],
    wk25JoinerIDs: [],
    wk26JoinerIDs: [],
    wk27JoinerIDs: [],
    choice1: 0,
    choice2: 0,
    choice3: 0,
    choice4: 0,
    choice5: 0,
    choice6: 0,
    choice7: 0,
    choice8: 0,
    choice9: 0,
    showDayOneWorkshop: false,
    showDayTwoWorkshop: false,
    showDayThreeWorkshop: false,

    showDay1Agenda: false,
    showDay2Agenda: false,
    showDay3Agenda: false,
    agenda: {
      day1: [
        {
          id: 1,
          start_time: "9am",
          end_time: "10am",
          activity: "Registration",
          person: ""
        }, {
          id: 2,
          start_time: "10am",
          end_time: "10.30am",
          activity: "Welcome Remarks; Information, announcements and introduction of Keynote Speakers",
          person: ""
        }, {
          id: 3,
          start_time: "10.30am",
          end_time: "11.15am",
          activity: "Keynote address 1 + Q & A ",
          person: "AKINTAYO BAMIDURO"
        }, {
          id: 4,
          start_time: "11.15am",
          end_time: "12pm",
          activity: "Keynote address 2 + Q & A ",
          person: "TUNDE ADEGBOLA"
        }, {
          id: 5,
          start_time: "12pm",
          end_time: "12.45pm",
          activity: "Keynote address 3 + Q & A ",
          person: "PROF. ADEWUNMI TAIWO"
        }, {
          id: 6,
          start_time: "12.45pm",
          end_time: "1pm",
          activity: "Introduction to Break-out sessions & Round off of Keynotes",
          person: ""
        }, {
          id: 7,
          start_time: "1pm",
          end_time: "1.30pm",
          activity: "Lunch Break",
          person: ""
        }, {
          id: 8,
          start_time: "1.30pm",
          end_time: "2pm",
          activity: "Introduction & Special Presentations",
          person: ""
        }, {
          id: 9,
          start_time: "2pm",
          end_time: "3pm",
          activity: "Break-Out Sessions 1",
          person: "MR MICHAEL NWAOGU, RALLY, IBIYEMI IFATUROTI"
        }, {
          id: 10,
          start_time: "3pm",
          end_time: "4pm",
          activity: "Break-Out Sessions 2",
          person: "EDEM OSSAI, RALLY, GBENGA ADENUGA"
        }, {
          id: 11,
          start_time: "4pm",
          end_time: "5pm",
          activity: "Break-Out Sessions 3",
          person: "MOYOFADE IPADEOLA, RALLY, SUNDAY AFOLAYAN"
        }, {
          id: 12,
          start_time: "5pm",
          end_time: "6pm",
          activity: "Closing with live Jazz Music",
          person: ""
        }

      ],
      day2: [
        {
          id: 1,
          start_time: "9am",
          end_time: "10am",
          activity: "Registration",
          person: ""
        }, {
          id: 2,
          start_time: "10am",
          end_time: "10.30am",
          activity: "Welcome Remarks; Information, announcements and introduction of Keynote Speakers",
          person: ""
        }, {
          id: 3,
          start_time: "10.30am",
          end_time: "11.15am",
          activity: "Keynote address 1 + Q & A ",
          person: "AKINTAYO BAMIDURO"
        }, {
          id: 4,
          start_time: "11.15am",
          end_time: "12pm",
          activity: "Keynote address 2 + Q & A ",
          person: "TUNDE ADEGBOLA"
        }, {
          id: 5,
          start_time: "12pm",
          end_time: "12.45pm",
          activity: "Keynote address 3 + Q & A ",
          person: "PROF. ADEWUNMI TAIWO"
        }, {
          id: 6,
          start_time: "12.45pm",
          end_time: "1pm",
          activity: "Introduction to Break-out sessions & Round off of Keynotes",
          person: ""
        }, {
          id: 7,
          start_time: "1pm",
          end_time: "1.30pm",
          activity: "Lunch Break",
          person: ""
        }, {
          id: 8,
          start_time: "1.30pm",
          end_time: "2pm",
          activity: "Introduction & Special Presentations",
          person: ""
        }, {
          id: 9,
          start_time: "2pm",
          end_time: "3pm",
          activity: "Break-Out Sessions 1",
          person: "MR MICHAEL NWAOGU, RALLY, IBIYEMI IFATUROTI"
        }, {
          id: 10,
          start_time: "3pm",
          end_time: "4pm",
          activity: "Break-Out Sessions 2",
          person: "EDEM OSSAI, RALLY, GBENGA ADENUGA"
        }, {
          id: 11,
          start_time: "4pm",
          end_time: "5pm",
          activity: "Break-Out Sessions 3",
          person: "MOYOFADE IPADEOLA, RALLY, SUNDAY AFOLAYAN"
        }, {
          id: 12,
          start_time: "5pm",
          end_time: "6pm",
          activity: "Closing with live Jazz Music",
          person: ""
        }

      ],

      day3: [
        {
          id: 1,
          start_time: "9am",
          end_time: "10am",
          activity: "Registration",
          person: ""
        }, {
          id: 2,
          start_time: "10am",
          end_time: "10.30am",
          activity: "Welcome Remarks; Information, announcements and introduction of Keynote Speakers",
          person: ""
        }, {
          id: 3,
          start_time: "10.30am",
          end_time: "11.15am",
          activity: "Keynote address 1 + Q & A ",
          person: "TBD"
        }, {
          id: 4,
          start_time: "11.15am",
          end_time: "12pm",
          activity: "Keynote address 2 + Q & A ",
          person: "TBD"
        }, {
          id: 5,
          start_time: "12pm",
          end_time: "12.45pm",
          activity: "Keynote address 3 + Q & A ",
          person: "TBD"
        }, {
          id: 6,
          start_time: "12.45pm",
          end_time: "1pm",
          activity: "Introduction to Break-out sessions & Round off of Keynotes",
          person: ""
        }, {
          id: 7,
          start_time: "1pm",
          end_time: "1.30pm",
          activity: "Lunch Break",
          person: ""
        }, {
          id: 8,
          start_time: "1.30pm",
          end_time: "2pm",
          activity: "Introduction & Special Presentations",
          person: ""
        }, {
          id: 9,
          start_time: "2pm",
          end_time: "3pm",
          activity: "Break-Out Sessions 1",
          person: "AFRICAN FOUNDERS"
        }, {
          id: 10,
          start_time: "3pm",
          end_time: "4pm",
          activity: "Break-Out Sessions 2",
          person: "TBD"
        }, {
          id: 11,
          start_time: "4pm",
          end_time: "5pm",
          activity: "Break-Out Sessions 3",
          person: "TBD"
        }, {
          id: 12,
          start_time: "5pm",
          end_time: "6pm",
          activity: "Closing with live Jazz Music",
          person: ""
        }

      ]
    },
  }



  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }


  getWorkshops = async () => {
    try {
      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Apps")
        .doc("innovationKitchen")
        .onSnapshot(this.updateWorkshops);
    } catch (err) {
      console.log(" err is ", err)
    }

  }

  calculateRegistrants = (key) => {

    console.log(" joiner variable is ", this.state[`${key}JoinerIDs`].length || 0)
    return " " + this.state[`${key}JoinerIDs`].length || " " + 0
  }


  updateWorkshops = async (wDoc) => {
    let w = wDoc.data()
    this.setState({
      agenda: w.agenda,
      wk1: w.wk1 || "workshop1",
      wk2: w.wk2 || "workshop2",
      wk3: w.wk3 || "workshop3",
      wk4: w.wk4 || "workshop4",
      wk5: w.wk5 || "workshop5",
      wk6: w.wk6 || "workshop6",
      wk7: w.wk7 || "workshop7",
      wk8: w.wk8 || "workshop8",
      wk9: w.wk9 || "workshop9",
      wk10: w.wk10 || "workshop10",
      wk11: w.wk11 || "workshop11",
      wk12: w.wk12 || "workshop12",
      wk13: w.wk13 || "workshop13",
      wk14: w.wk14 || "workshop14",
      wk15: w.wk15 || "workshop15",
      wk16: w.wk16 || "workshop16",
      wk17: w.wk17 || "workshop17",
      wk18: w.wk18 || "workshop18",
      wk19: w.wk19 || "workshop19",
      wk20: w.wk20 || "workshop20",
      wk21: w.wk21 || "workshop21",
      wk22: w.wk22 || "workshop22",
      wk23: w.wk23 || "workshop23",
      wk24: w.wk24 || "workshop24",
      wk25: w.wk25 || "workshop25",
      wk26: w.wk26 || "workshop26",
      wk27: w.wk27 || "workshop27",
      wk1JoinerIDs: w.wk1JoinerIDs || [],
      wk2JoinerIDs: w.wk2JoinerIDs || [],
      wk3JoinerIDs: w.wk3JoinerIDs || [],
      wk4JoinerIDs: w.wk4JoinerIDs || [],
      wk5JoinerIDs: w.wk5JoinerIDs || [],
      wk6JoinerIDs: w.wk6JoinerIDs || [],
      wk7JoinerIDs: w.wk7JoinerIDs || [],
      wk8JoinerIDs: w.wk8JoinerIDs || [],
      wk9JoinerIDs: w.wk9JoinerIDs || [],
      wk10JoinerIDs: w.wk10JoinerIDs || [],
      wk11JoinerIDs: w.wk11JoinerIDs || [],
      wk12JoinerIDs: w.wk12JoinerIDs || [],
      wk13JoinerIDs: w.wk13JoinerIDs || [],
      wk14JoinerIDs: w.wk14JoinerIDs || [],
      wk15JoinerIDs: w.wk15JoinerIDs || [],
      wk16JoinerIDs: w.wk16JoinerIDs || [],
      wk17JoinerIDs: w.wk17JoinerIDs || [],
      wk18JoinerIDs: w.wk18JoinerIDs || [],
      wk19JoinerIDs: w.wk19JoinerIDs || [],
      wk20JoinerIDs: w.wk20JoinerIDs || [],
      wk21JoinerIDs: w.wk21JoinerIDs || [],
      wk22JoinerIDs: w.wk22JoinerIDs || [],
      wk23JoinerIDs: w.wk23JoinerIDs || [],
      wk24JoinerIDs: w.wk24JoinerIDs || [],
      wk25JoinerIDs: w.wk25JoinerIDs || [],
      wk26JoinerIDs: w.wk26JoinerIDs || [],
      wk27JoinerIDs: w.wk27JoinerIDs || [], 
    })

    if (!global.myProfileID) await global.timeout(5000)
    if (w.wk1JoinerIDs && w.wk1JoinerIDs.includes(global.myProfileID)) this.setState({ choice1: 1 })
    if (w.wk2JoinerIDs && w.wk2JoinerIDs.includes(global.myProfileID)) this.setState({ choice1: 2 })
    if (w.wk3JoinerIDs && w.wk3JoinerIDs.includes(global.myProfileID)) this.setState({ choice1: 3 })
    if (w.wk4JoinerIDs && w.wk4JoinerIDs.includes(global.myProfileID)) this.setState({ choice2: 1 })
    if (w.wk5JoinerIDs && w.wk5JoinerIDs.includes(global.myProfileID)) this.setState({ choice2: 2 })
    if (w.wk6JoinerIDs && w.wk6JoinerIDs.includes(global.myProfileID)) this.setState({ choice2: 3 })
    if (w.wk7JoinerIDs && w.wk7JoinerIDs.includes(global.myProfileID)) this.setState({ choice3: 1 })
    if (w.wk8JoinerIDs && w.wk8JoinerIDs.includes(global.myProfileID)) this.setState({ choice3: 2 })
    if (w.wk9JoinerIDs && w.wk9JoinerIDs.includes(global.myProfileID)) this.setState({ choice3: 3 })

    if (w.wk10JoinerIDs && w.wk10JoinerIDs.includes(global.myProfileID)) this.setState({ choice4: 4 })
    if (w.wk11JoinerIDs && w.wk11JoinerIDs.includes(global.myProfileID)) this.setState({ choice4: 5 })
    if (w.wk12JoinerIDs && w.wk12JoinerIDs.includes(global.myProfileID)) this.setState({ choice4: 6 })
    if (w.wk13JoinerIDs && w.wk13JoinerIDs.includes(global.myProfileID)) this.setState({ choice5: 4 })
    if (w.wk14JoinerIDs && w.wk14JoinerIDs.includes(global.myProfileID)) this.setState({ choice5: 5 })
    if (w.wk15JoinerIDs && w.wk15JoinerIDs.includes(global.myProfileID)) this.setState({ choice5: 6 })
    if (w.wk16JoinerIDs && w.wk16JoinerIDs.includes(global.myProfileID)) this.setState({ choice6: 4 })
    if (w.wk17JoinerIDs && w.wk17JoinerIDs.includes(global.myProfileID)) this.setState({ choice6: 5 })
    if (w.wk18JoinerIDs && w.wk18JoinerIDs.includes(global.myProfileID)) this.setState({ choice6: 6 })

    if (w.wk19JoinerIDs && w.wk19JoinerIDs.includes(global.myProfileID)) this.setState({ choice7: 7 })
    if (w.wk20JoinerIDs && w.wk20JoinerIDs.includes(global.myProfileID)) this.setState({ choice7: 8 })
    if (w.wk21JoinerIDs && w.wk21JoinerIDs.includes(global.myProfileID)) this.setState({ choice7: 9 })
    if (w.wk22JoinerIDs && w.wk22JoinerIDs.includes(global.myProfileID)) this.setState({ choice8: 7 })
    if (w.wk23JoinerIDs && w.wk23JoinerIDs.includes(global.myProfileID)) this.setState({ choice8: 8 })
    if (w.wk24JoinerIDs && w.wk24JoinerIDs.includes(global.myProfileID)) this.setState({ choice8: 9 })
    if (w.wk25JoinerIDs && w.wk25JoinerIDs.includes(global.myProfileID)) this.setState({ choice9: 7 })
    if (w.wk26JoinerIDs && w.wk26JoinerIDs.includes(global.myProfileID)) this.setState({ choice9: 8 })
    if (w.wk27JoinerIDs && w.wk27JoinerIDs.includes(global.myProfileID)) this.setState({ choice9: 9 })


  }




  setWorkshop = async (key) => {

    try {

      const r = await fetch(global.cloudFunctionURL + "ikGeneral", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "registerWorkshop",
          myUID: global.myUID,
          idToken: global.idToken,
          workshopKey: key
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        console.log(" workshop successfully registered!")
        //alert("Speech successfully created!")
      } else {

      }

    } catch (err) {
      console.log(" err is ", err)
      await global.timeout(50)
      alert("Error. Try again later!")
    }
  }



  async componentDidMount() {
    try {
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 200); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 400); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 500); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 500); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 600); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 1000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 2000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 4000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 5000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 8000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 10000); });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 10000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 100000); });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID)
        return;

      this.getWorkshops()

    } catch (err) {
      if (global.reportError) {
        global.reportError(err, "catches", "InnovationKitchenAgenda");
      }
    }
  }

  componentWillUnmount() {
    console.log("Component InnovationKitchenAgenda will unmount");
    this.unsubscribeFirestore();
  }

  circle(filled) {
    if (filled) {
      return (
        <View style={{ width: 20, height: 20, borderColor: 'red', borderWidth: 1, padding: 2, borderRadius: 10, justifyContent: "center", alignItems: "center" }}>
          <View style={{ width: 14, height: 14, backgroundColor: 'red', borderRadius: 7 }}></View>
        </View>);

    } else {
      return (<View style={{ width: 20, height: 20, borderColor: 'red', borderWidth: 1, borderRadius: 10 }}></View>);
    }
    this.getWorkshops()
  }


  render() {
    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: global.app == "health" ? "#fff" : "#131313"
        }}
      >
        <StatusBar barStyle="light-content" />

        <View
          style={{
            backgroundColor: "#131313",
            flex: 1
          }}
        >
          <View
            style={{
              backgroundColor: "#131313",
              borderBottomColor: "#555",
              borderBottomWidth: 1,
              ...Platform.select({
                ios: {
                  height: 55
                },
                android: {
                  height: 75
                }
              })
            }}
          />
          <ScrollView>

            <View id="workshops" style={{ justifyContent: "center", padding: 20 }}>
              <Text style={{ color: "red", fontWeight: "bold", marginBottom: 10, fontSize: 16 }}>Workshops</Text>
              <View style={{ backgroundColor: "#272727", borderRadius: 15, padding: 15, borderBottomWidth: 1, borderColor: '#3C3C3C' }}>
                <Text style={{ color: "white", fontWeight: "bold", fontSize: 13 }}>Register for workshops below</Text>



                <View style={{ paddingTop: 10 }}>

                  <View style={{
                    borderColor: '#444',
                    //borderWidth: this.state.showDayOneWorkshop == true ? 1 : 0,  
                    borderRadius: 10
                  }}>

                    <TouchableOpacity onPress={async () => { this.setState({ showDayOneWorkshop: this.state.showDayOneWorkshop == true ? false : true }) }}
                      style={{ padding: 10, paddingVertical: 20, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> DAY 1</Text>
                      <Text style={{ fontSize: 10, color: "white", }}> {this.state.showDayOneWorkshop == true ? "^" : "⌄"} </Text>
                    </TouchableOpacity>



                    {this.state.showDayOneWorkshop && <View style={{ paddingHorizontal: 10 }}>
                      <Text style={{ marginTop: 5, color: "white", fontSize: 10 }}>Time: 2pm</Text>
                      <View style={{ padding: 20, alignItems: "stretch", borderBottomWidth: 1, borderColor: '#3C3C3C', }} >
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk1"); this.setState({ choice1: 1 }) }}>{this.circle(this.state.choice1 == 1 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk1}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk1")}{" rgstd."}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk2"); this.setState({ choice1: 2 }) }}>{this.circle(this.state.choice1 == 2 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk2}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk2")}{" rgstd."}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk3"); this.setState({ choice1: 3 }) }}>{this.circle(this.state.choice1 == 3 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk3}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk3")}{" rgstd."}</Text>
                        </TouchableOpacity>
                      </View>

                      <Text style={{ marginTop: 5, color: "white", fontSize: 10 }}>Time: 3pm</Text>
                      <View style={{ padding: 20, alignItems: "stretch", borderBottomWidth: 1, borderColor: '#3C3C3C', }} >
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk4"); this.setState({ choice2: 1 }) }}>{this.circle(this.state.choice2 == 1 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk4}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk4")}{" rgstd."}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk5"); this.setState({ choice2: 2 }) }}>{this.circle(this.state.choice2 == 2 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk5}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk5")}{" rgstd."}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk6"); this.setState({ choice2: 3 }) }}>{this.circle(this.state.choice2 == 3 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk6}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk6")}{" rgstd."}</Text>
                        </TouchableOpacity>
                      </View>

                      <Text style={{ marginTop: 5, color: "white", fontSize: 10 }}>Time: 4pm</Text>
                      <View style={{ padding: 20, alignItems: "stretch", borderBottomWidth: 1, borderColor: '#3C3C3C', }} >

                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk7"); this.setState({ choice3: 1 }) }}>{this.circle(this.state.choice3 == 1 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk7}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk7")}{" rgstd."}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk8"); this.setState({ choice3: 2 }) }}>{this.circle(this.state.choice3 == 2 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk8}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk8")}{" rgstd."}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk9"); this.setState({ choice3: 3 }) }}>{this.circle(this.state.choice3 == 3 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk9}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk9")}{" rgstd."}</Text>
                        </TouchableOpacity>
                      </View>
                    </View>}
                  </View>






                  <View style={{
                    borderColor: '#444',
                    //borderWidth: this.state.showDayOneWorkshop == true ? 1 : 0,  
                    borderRadius: 10
                  }}>

                    <TouchableOpacity onPress={async () => { this.setState({ showDayTwoWorkshop: this.state.showDayTwoWorkshop == true ? false : true }) }}
                      style={{ padding: 10, paddingVertical: 20, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> DAY 2</Text>
                      <Text style={{ fontSize: 10, color: "white", }}> {this.state.showDayTwoWorkshop == true ? "^" : "⌄"} </Text>
                    </TouchableOpacity>



                    {this.state.showDayTwoWorkshop && <View style={{ paddingHorizontal: 10 }}>
                      <Text style={{ marginTop: 5, color: "white", fontSize: 10 }}>Time: 2pm</Text>
                      <View style={{ padding: 20, alignItems: "stretch", borderBottomWidth: 1, borderColor: '#3C3C3C', }} >
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk10"); this.setState({ choice4: 4 }) }}>{this.circle(this.state.choice4 == 4 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk10}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk10")}{" rgstd."}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk11"); this.setState({ choice4: 5 }) }}>{this.circle(this.state.choice4 == 5 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk11}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk11")}{" rgstd."}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk12"); this.setState({ choice4: 6 }) }}>{this.circle(this.state.choice4 == 6 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk12}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk12")}{" rgstd."}</Text>
                        </TouchableOpacity>
                      </View>

                      <Text style={{ marginTop: 5, color: "white", fontSize: 10 }}>Time: 3pm</Text>
                      <View style={{ padding: 20, alignItems: "stretch", borderBottomWidth: 1, borderColor: '#3C3C3C', }} >
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk13"); this.setState({ choice5: 4 }) }}>{this.circle(this.state.choice5 == 4 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk13}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk13")}{" rgstd."}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk14"); this.setState({ choice5: 5 }) }}>{this.circle(this.state.choice5 == 5 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk14}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk14")}{" rgstd."}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk15"); this.setState({ choice5: 6 }) }}>{this.circle(this.state.choice5 == 6 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk15}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk15")}{" rgstd."}</Text>
                        </TouchableOpacity>
                      </View>

                      <Text style={{ marginTop: 5, color: "white", fontSize: 10 }}>Time: 4pm</Text>
                      <View style={{ padding: 20, alignItems: "stretch", borderBottomWidth: 1, borderColor: '#3C3C3C', }} >

                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk16"); this.setState({ choice6: 4 }) }}>{this.circle(this.state.choice6 == 4 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk16}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk16")}{" rgstd."}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk17"); this.setState({ choice6: 5 }) }}>{this.circle(this.state.choice6 == 5 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk17}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk17")}{" rgstd."}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk18"); this.setState({ choice6: 6 }) }}>{this.circle(this.state.choice6 == 6 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk18}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk18")}{" rgstd."}</Text>
                        </TouchableOpacity>
                      </View>
                    </View>}
                  </View>




                  <View style={{
                    borderColor: '#444',
                    //borderWidth: this.state.showDayOneWorkshop == true ? 1 : 0,  
                    borderRadius: 10
                  }}>

                    <TouchableOpacity onPress={async () => { this.setState({ showDayThreeWorkshop: this.state.showDayThreeWorkshop == true ? false : true }) }}
                      style={{ padding: 10, paddingVertical: 20, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> DAY 3</Text>
                      <Text style={{ fontSize: 10, color: "white", }}> {this.state.showDayThreeWorkshop == true ? "^" : "⌄"} </Text>
                    </TouchableOpacity>



                    {this.state.showDayThreeWorkshop && <View style={{ paddingHorizontal: 10 }}>
                      <Text style={{ marginTop: 5, color: "white", fontSize: 10 }}>Time: 2pm</Text>
                      <View style={{ padding: 20, alignItems: "stretch", borderBottomWidth: 1, borderColor: '#3C3C3C', }} >
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk19"); this.setState({ choice7: 7 }) }}>{this.circle(this.state.choice7 == 7 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk19}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk19")}{" rgstd."}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk20"); this.setState({ choice7: 8 }) }}>{this.circle(this.state.choice7 == 8 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk20}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk20")}{" rgstd."}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk21"); this.setState({ choice7: 9 }) }}>{this.circle(this.state.choice7 == 9 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk21}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk21")}{" rgstd."}</Text>
                        </TouchableOpacity>
                      </View>

                      <Text style={{ marginTop: 5, color: "white", fontSize: 10 }}>Time: 3pm</Text>
                      <View style={{ padding: 20, alignItems: "stretch", borderBottomWidth: 1, borderColor: '#3C3C3C', }} >
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk22"); this.setState({ choice8: 7 }) }}>{this.circle(this.state.choice8 == 7 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk22}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk22")}{" rgstd."}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk23"); this.setState({ choice8: 8 }) }}>{this.circle(this.state.choice8 == 8 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk23}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk23")}{" rgstd."}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk24"); this.setState({ choice8: 9 }) }}>{this.circle(this.state.choice8 == 9 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk24}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk24")}{" rgstd."}</Text>
                        </TouchableOpacity>
                      </View>

                      <Text style={{ marginTop: 5, color: "white", fontSize: 10 }}>Time: 4pm</Text>
                      <View style={{ padding: 20, alignItems: "stretch", borderBottomWidth: 1, borderColor: '#3C3C3C', }} >

                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk25"); this.setState({ choice9: 7 }) }}>{this.circle(this.state.choice9 == 7 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk25}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk25")}{" rgstd."}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk26"); this.setState({ choice9: 8 }) }}>{this.circle(this.state.choice9 == 8 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk26}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk26")}{" rgstd."}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2 }} onPress={() => { this.setWorkshop("wk27"); this.setState({ choice9: 9 }) }}>{this.circle(this.state.choice9 == 9 ? true : false)}
                          <Text style={{ color: "white", marginLeft: 10, fontSize: 11 }}>{this.state.wk27}</Text>
                          <Text style={{ color: 'grey', fontSize: 10 }}>{this.calculateRegistrants("wk27")}{" rgstd."}</Text>
                        </TouchableOpacity>
                      </View>
                    </View>}
                  </View>

                </View>

              </View>

            </View>



            {/*-------------------------------------------Conference Program / Agenda ------------------------------------------- */}

            <View id="Workshops" style={{ justifyContent: "center", padding: 20 }}>
              <View style={{ backgroundColor: "#272727", borderRadius: 15, padding: 15, }}>
                <View style={{ borderBottomWidth: 1, borderColor: '#3C3C3C', marginBottom: 10, flexDirection: "row", justifyContent: "space-between" }}>
                  <Text style={{ color: "white", fontWeight: "bold", fontSize: 13, marginVertical: 10, }}>Agenda</Text>
                  {/* <TouchableOpacity style={{ backgroundColor: "red", padding: 10, paddingHorizontal: 20, borderRadius: 5, position: "absolute", top: -5, right: 0 }}>
                    <Text style={{ fontSize: 11, color: "white" }}>edit</Text>
                  </TouchableOpacity> */}
                </View>
                <TouchableOpacity onPress={async () => { this.setState({ showDay1Agenda: this.state.showDay1Agenda == true ? false : true }) }}
                  style={{ padding: 10, paddingVertical: 20, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                  <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> Day 1 Agenda</Text>
                  <Text style={{ fontSize: 10, color: "white", }}> {this.state.showDay1Agenda == true ? "^" : "⌄"} </Text>
                </TouchableOpacity>

                {this.state.showDay1Agenda && <View style={{
                  justifyContent: "center", alignItems: "center", padding: 10,
                  //backgroundColor: "#333"  
                }}>
                  {this.state.agenda.day1.map((item, id) => {
                    return (
                      <View id="day1" key={id}
                        style={{
                          flexDirection: "row", flexShrink: 1, width: "100%", padding: 10,
                          //backgroundColor: "#444" ,borderRadius: 10,
                          marginVertical: 5, marginTop: 0, backgroundColor: "#333", borderRadius: 3, justifyContent: "flex-start"
                        }}>

                        <View style={{ marginRight: 5, width: "18%" }}>
                          <Text style={{ fontSize: 10, color: "white", }}>{item.start_time || " - "}-</Text>
                          <Text style={{ fontSize: 10, color: "white", }}>{item.end_time || " - "}</Text>
                        </View>
                        <View style={{ flexShrink: 1, justifyContent: "flex-start", borderLeftWidth: 1, borderLeftColor: "red", paddingLeft: 5 }}>
                          <Text style={{ fontSize: 10, color: "white", fontWeight: "bold" }}>{item.activity || " - "}</Text>
                          <Text style={{ fontSize: 10, color: "white", }}>{item.person || " - "}</Text>
                        </View>
                      </View>
                    )
                  })}
                </View>}


                <TouchableOpacity onPress={async () => { this.setState({ showDay2Agenda: this.state.showDay2Agenda == true ? false : true }) }}
                  style={{ padding: 10, paddingVertical: 20, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                  <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> Day 2 Agenda</Text>
                  <Text style={{ fontSize: 10, color: "white", }}> {this.state.showDay2Agenda == true ? "^" : "⌄"} </Text>
                </TouchableOpacity>

                {this.state.showDay2Agenda && <View style={{
                  justifyContent: "center", alignItems: "center", padding: 10,
                  //backgroundColor: "#333"  
                }}>
                  {this.state.agenda.day2.map((item, id) => {
                    return (
                      <View id="day2" key={id}
                        style={{
                          flexDirection: "row", flexShrink: 1, width: "100%", padding: 10,
                          //backgroundColor: "#444" ,borderRadius: 10,
                          marginVertical: 5, marginTop: 0, backgroundColor: "#333", borderRadius: 3, justifyContent: "flex-start"
                        }}>

                        <View style={{ marginRight: 5, width: "18%" }}>
                          <Text style={{ fontSize: 10, color: "white", }}>{item.start_time || " - "}-</Text>
                          <Text style={{ fontSize: 10, color: "white", }}>{item.end_time || " - "}</Text>
                        </View>
                        <View style={{ flexShrink: 1, justifyContent: "flex-start", borderLeftWidth: 1, borderLeftColor: "red", paddingLeft: 5 }}>
                          <Text style={{ fontSize: 10, color: "white", fontWeight: "bold" }}>{item.activity || " - "}</Text>
                          <Text style={{ fontSize: 10, color: "white", }}>{item.person || " - "}</Text>
                        </View>
                      </View>
                    )
                  })}

                </View>}


                <TouchableOpacity onPress={async () => { this.setState({ showDay3Agenda: this.state.showDay3Agenda == true ? false : true }) }}
                  style={{ padding: 10, paddingVertical: 20, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                  <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> Day 3 Agenda</Text>
                  <Text style={{ fontSize: 10, color: "white", }}> {this.state.showDay3Agenda == true ? "^" : "⌄"} </Text>
                </TouchableOpacity>

                {this.state.showDay3Agenda && <View style={{
                  justifyContent: "center", alignItems: "center", padding: 10,
                  //backgroundColor: "#333"  
                }}>
                  {this.state.agenda.day3.map((item, id) => {
                    return (
                      <View id="day3" key={id}
                        style={{
                          flexDirection: "row", flexShrink: 1, width: "100%", padding: 10,
                          //backgroundColor: "#444" ,borderRadius: 10,
                          marginVertical: 5, marginTop: 0, backgroundColor: "#333", borderRadius: 3, justifyContent: "flex-start"
                        }}>

                        <View style={{ marginRight: 5, width: "18%" }}>
                          <Text style={{ fontSize: 10, color: "white", }}>{item.start_time || " - "}-</Text>
                          <Text style={{ fontSize: 10, color: "white", }}>{item.end_time || " - "}</Text>
                        </View>
                        <View style={{ flexShrink: 1, justifyContent: "flex-start", borderLeftWidth: 1, borderLeftColor: "red", paddingLeft: 5 }}>
                          <Text style={{ fontSize: 10, color: "white", fontWeight: "bold" }}>{item.activity || " - "}</Text>
                          <Text style={{ fontSize: 10, color: "white", }}>{item.person || " - "}</Text>
                        </View>
                      </View>
                    )
                  })}

                </View>}

              </View>
            </View>


            {/*-------------------------------------------Speakers ------------------------------------------- */}


            {/* <View id="speakers" style={{ justifyContent: "center", padding: 20 }}>
              <Text style={{ color: "red", fontWeight: "bold", marginBottom: 10, fontSize: 16 }}>Speakers</Text>
              <View style={{ backgroundColor: "#272727", borderRadius: 15, }}>
                {this.state.speakers.map((person, id) => {
                  return (
                    <View key={id} id="speaker_profile" style={{ flexDirection: "row", borderBottomWidth: 1, borderColor: '#3C3C3C', width: "100%", padding: 15 }}>
                      <Image
                        style={{ width: 60, height: 60, resizeMode: "cover", borderRadius: 13 }}
                        source={{ uri: `${person.image}` }}
                      ></Image>

                      <View style={{ padding: 20 }}>
                        <Text style={{ color: "white", fontWeight: "bold", fontSize: 13 }}>{person.name}</Text>
                        <Text style={{ color: "white", fontSize: 11 }}>{person.title}</Text>
                      </View>
                    </View>)
                })}
                <View style={{ height: 10, backgroundColor: "#3C3C3C" }}></View>
              </View>
            </View> */}

          </ScrollView>
          <NavigationEvents
          // onWillFocus={async payload => {
          //   global.tabBarIconColor = "white"
          // }}
          // onWillBlur={async payload => {
          //   global.tabBarIconColor = global.tabBarIconDefaultColor
          // }}
          />
        </View>
      </SafeAreaView>
    );
  }
}

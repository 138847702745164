import React, { Component } from "react";
import {
  Text,
  Image,
  View,
  TouchableOpacity,
  TextInput,
  Dimensions,
  Modal,
  Keyboard
} from "react-native";
const gStyles = global.gStyles //    "../../styles/gStyles";
import { Platform } from "@unimodules/core";

export default class GetVideo extends Component {
  state = {
    text: "", // from TextInput
    id: null, // 11-digit YouTube id
    thumbnail: null, // 1 or 2 or 3
    error: ""
  };


  async componentDidMount() {
    try {

    } catch (err) {
      console.log(err);
      alert("error", err);
    }
  }

  componentWillUnmount() {

  }

  updateTextInput = async (text) => {
    if (text.length == 0) return this.setState({ id: null, thumbnail: null, error: "" })
    if (text.length < 11) return this.setState({ id: null, thumbnail: null, error: "...too short..." })

    // if (text.substring(0, 17).toLowerCase() == "https://youtu.be/") text = text.substring(17, 28);
    // if (text.substring(0, 16).toLowerCase() == "http://youtu.be/") text = text.substring(16, 27);
    // if (text.substring(0, 16).toLowerCase() == "youtu.be/") text = text.substring(9, 20);
    // if (text.substring(0, 32).toLowerCase() == "https://www.youtube.com/watch?v=") text = text.substring(32, 43);
    // if (text.substring(0, 31).toLowerCase() == "http://www.youtube.com/watch?v=") text = text.substring(31, 42);
    // if (text.substring(0, 31).toLowerCase() == "www.youtube.com/watch?v=") text = text.substring(24, 35);
    // if (text.substring(0, 31).toLowerCase() == "youtube.com/watch?v=") text = text.substring(20, 31);

    let youtubeID = "", searchString = ""
    if (text.toLowerCase().includes("youtu.be/")) searchString = "youtu.be/"
    else if (text.toLowerCase().includes("youtube.com/watch?v=")) searchString = "youtube.com/watch?v="
    else if (text.toLowerCase().includes("youtube.com/watch?app=desktop&v=")) searchString = "youtube.com/watch?app=desktop&v="
    else if (text.toLowerCase().includes("v=")) searchString = "v="

    if (searchString) {
      let textPostion = text.toLowerCase().indexOf(searchString)
      let idPosition = textPostion + searchString.length
      youtubeID = text.substring(idPosition, idPosition + 11)
    }

    if (youtubeID.length == 11) {
      let isCorrect = true
      try {
        const fetchString = "http://www.youtube.com/oembed?url=http://www.youtube.com/watch?v=" + youtubeID
        const r = await fetch(fetchString, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });
        const r2 = await r.json();
      } catch (e) {
        isCorrect = false
      }
      console.log("iscorrect", isCorrect)
      if (isCorrect) this.setState({ id: youtubeID, error: "" })
      if (!isCorrect) this.setState({ id: null, thumbnail: null, error: "Video not found on YouTube" })
    } else this.setState({ id: null, thumbnail: null, error: "Incorrect YouTube link or id" })
  }

  render() {
    thumbWidth = Math.min(250, (Dimensions.get("window").width - 60) / 3.4)
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={true}
        onRequestClose={() => {
          //alert("Modal has been closed.");
        }}
      >
        <View
          style={{
            flex: 1, backgroundColor: "#000", justifyContent: "space-between",
            alignItems: "center", alignSelf: "center", width: "90%", borderRadius: 20,
            borderWidth: 8, borderColor: "#ccc", marginTop: 20, marginBottom: Platform.OS == "ios" ? 20 : 0
          }}
        >
          <View style={{ flex: 1, alignSelf: "stretch", margin: 15 }}>
            <Text
              style={{ fontSize: 28, alignSelf: "center", fontWeight: "bold", color: "#ccc", marginBottom: 50, marginTop: 7.5 }}
            >Add a Video</Text>
            <Text
              style={{ fontSize: 14, color: "#A8CF45" }}
            >Enter a YouTube video ID or link</Text>
            <TextInput
              onChangeText={text => {
                this.updateTextInput(text);
              }}
              placeholder={"e.g. https://youtu.be/xPo8xBwqNsU"}
              placeholderTextColor={"#888"}
              style={{
                color: "#ffffff",
                borderColor: "grey",
                borderWidth: 0,
                paddingLeft: 3,
                borderRadius: 5,
                height: 30,
                backgroundColor: "#334",
                marginTop: 8,
                marginBottom: 8
              }}
              maxLength={60}
              //keyboardShouldPersistTaps={'always'}
              underlineColorAndroid="transparent"
              defaultValue={this.state.title}
              keyboardDismissMode={'on-drag'}
              keyboardAppearance={"dark"}
              returnKeyType={"done"}
              autoCompleteType={'off'}
              autoCapitalize={'none'}
              autoCorrect={false}
            />
            <Text
              style={{ fontSize: 14, color: "#f66" }}
            >{this.state.error || ""}</Text>
            <View style={{ height: 40 }} />
            {!!this.state.id && <View>
              <Text
                style={{ fontSize: 14, color: "#A8CF45", marginBottom: 8 }}
              >{this.state.thumbnail ? "Your thumbnail" : "Select a thumbnail..."}</Text>
              <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
                <TouchableOpacity style={{ width: thumbWidth, height: thumbWidth, backgroundColor: "#000", borderWidth: 4, borderColor: this.state.thumbnail == 1 ? "#ccc" : "#333" }}
                  onPress={() => { Keyboard.dismiss(); this.setState({ thumbnail: 1 }) }}>
                  <Image
                    style={{ width: thumbWidth - 8, height: thumbWidth - 8 }} resizeMode={"contain"}
                    source={{ uri: "https://img.youtube.com/vi/" + this.state.id + "/0.jpg" }}
                  />
                </TouchableOpacity>
                <TouchableOpacity style={{ width: thumbWidth, height: thumbWidth, backgroundColor: "#000", borderWidth: 4, borderColor: this.state.thumbnail == 2 ? "#ccc" : "#333" }}
                  onPress={() => { Keyboard.dismiss(); this.setState({ thumbnail: 2 }) }}>
                  <Image
                    style={{ width: thumbWidth - 8, height: thumbWidth - 8 }} resizeMode={"contain"}
                    source={{ uri: "https://img.youtube.com/vi/" + this.state.id + "/1.jpg" }}
                  />
                </TouchableOpacity>
                <TouchableOpacity style={{ width: thumbWidth, height: thumbWidth, backgroundColor: "#000", borderWidth: 4, borderColor: this.state.thumbnail == 3 ? "#ccc" : "#333" }}
                  onPress={() => { Keyboard.dismiss(); this.setState({ thumbnail: 3 }) }}>
                  <Image
                    style={{ width: thumbWidth - 8, height: thumbWidth - 8 }} resizeMode={"contain"}
                    source={{ uri: "https://img.youtube.com/vi/" + this.state.id + "/2.jpg" }}
                  />
                </TouchableOpacity>
              </View>
            </View>}
          </View>
          <View style={{ height: 75, justifyContent: "center", alignItems: "center" }}>
            {(this.state.id && this.state.thumbnail) && <TouchableOpacity style={[global.gStyles.buttonXL, { paddingVertical: 5, paddingHorizontal: 22 }]}
              onPress={() => {
                this.props.onDone(this.state.id, "https://img.youtube.com/vi/" + this.state.id + "/" + (this.state.thumbnail - 1) + ".jpg");
              }}>
              <Text
                style={{ fontSize: 20, fontWeight: "bold", color: "#000" }}
              >
                OK, use this!
            </Text>
            </TouchableOpacity>}
          </View>
          <TouchableOpacity
            style={{
              position: "absolute", borderWidth: 0, borderColor: "rgba(0,0,0,0.2)", alignItems: "center", justifyContent: "center", width: 25, top: 25, left: 12, height: 25, backgroundColor: "#ccc", borderRadius: 3
            }}
            onPress={() => { this.props.onDone(null, null); }}
          >
            <Image
              style={{ width: 43, height: 43, opacity: 1 }}
              source={require("../../images/icons/CancelBlack.png")}
            />
          </TouchableOpacity>
        </View>
      </Modal>
    );
  }
}

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  TouchableHighlight,
  TouchableWithoutFeedback,
  ScrollView,
  SectionList,
  SafeAreaView,
  Keyboard,
  ActivityIndicator,
  Dimensions,
  Platform,
  Image,
  Alert,
  Modal
} from "react-native";
import { WebView } from 'react-native-webview';
import firebase from "firebase";
import "firebase/firestore";
const gStyles = global.gStyles //    "../../styles/gStyles";
import CurrentLocation from "../../shared/Map/currentLocation";
import GetPicture from "../../shared/Camera/GetPicture";
import GetVideo from "../../shared/Camera/GetVideo";

//selection selected
class ASelector extends PureComponent {
  render() {
    let ob = {}
    this.props.selection.forEach((o) => {
      if (o.type == this.props.selected) ob = o
    })
    return (
      <View style={{
        alignItems: "center", justifyContent: "center", height: 85,
        flexDirection: "row", padding: 3,
        borderRadius: 7.5, width: "100%", backgroundColor: "#18181f",
        marginBottom: 7, marginTop: 3
      }}>
        <View style={{ alignItems: "flex-start", justifyContent: "center", flex: 1, paddingLeft: 10 }}>
          <Text style={{ color: "#ccd", fontSize: 18, fontWeight: "bold" }}>{ob.title}</Text>
          <Text style={{ color: "#99a", fontSize: 14 }}>{ob.description}</Text>
        </View>
        <View style={{ alignItems: "center", justifyContent: "center", width: 50 }}>
          <Text style={{ color: "#ccc", fontSize: 16, fontWeight: "bold", color: "#889" }}>▼</Text>
        </View>
      </View>
    );
  }
}
class ASelectorW extends PureComponent {
  render() {
    const ob = this.props.ob
    return (
      <View style={{
        alignItems: "center", justifyContent: "center", height: 100,
        flexDirection: "row", padding: 3,
        borderRadius: 7.5, width: "100%", backgroundColor: "#ccd",
        marginVertical: 5, borderTopWidth: 1, borderTopColor: "#aab"
      }}>
        <View style={{ alignItems: "flex-start", justifyContent: "center", flex: 1, paddingLeft: 10 }}>
          <Text style={{ color: "#223", fontSize: 18, fontWeight: "bold" }}>{ob.title}</Text>
          <Text style={{ color: "#778", fontSize: 14 }}>{ob.description}</Text>
        </View>
        <View style={{ alignItems: "center", justifyContent: "center", width: 50 }}>
          <Text style={{ color: "#333", fontSize: 20, fontWeight: "bold", color: "#99b" }}></Text>
        </View>
      </View>
    );
  }
}

class Selector extends Component {
  render() {
    let height =
      this.props.text.length < 45
        ? 30
        : 45 + (this.props.text.length - 45) * 0.33;
    let circle = filled => {
      if (filled) {
        return (
          <View
            style={{ width: 20, height: 20, borderColor: "#9999a4", backgroundColor: "#9999a4", borderWidth: 1, borderRadius: 10 }}
          />
        );
      } else {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#556", borderWidth: 1, borderRadius: 10 }} />
        );
      }
    };

    return (
      <View style={{ height: height, flexDirection: "row", alignItems: "center" }}>
        {circle(this.props.selected)}
        <Text style={{ marginLeft: 10, color: "#999" }}>{this.props.text}</Text>
      </View>
    );
  }
}

export default class NewPostScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    return {
      title: "New Group Chat",
      headerTransparent: true,
      headerTintColor: "#A8CF45",
      headerTitleStyle: {
        fontWeight: "800",
        color: "#A8CF45",
        fontSize: 16,
        shadowOpacity: 1
      }
    };
  };

  state = {
    title: "",
    details: "",
    pictureURL: null,
    youtubeID: null,
    aSpecialCategory: "general",
    aType: "forum",
    aOpenness: "public",
    networkID: null,
    communityID: null,
    location: global.location || {},
    options: {},
    nOfComments: 0,
    nOfDirectComments: 0,
    nOfTotalLikes: 0,
    nOfJoiners: 0,
    topics: [],

    addMedia: false,
    showGetPicture: false,
    showGetVideo: false,
    activityIndicatorAnimating: false,
    loading: false,
    error: false,
    watchingYoutube: false,
    playingYoutubeID: "",
    modalAVisible: false,
    activeAModal: "",
    activeASelection: []
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    //this.unsubscribe = null;
  }

  async componentDidMount() {
  }

  playYoutube = (id) => {
    this.setState({ playingYoutubeID: id, watchingYoutube: true })
  }

  activateASelection = (ob) => {
    console.log("this.state.activeAModal", this.state.activeAModal)
    console.log("this.state.activeAModal", this.state.activeAModal)
    console.log("this.state.activeAModal", this.state.activeAModal)
    console.log("this.state.activeAModal", this.state.activeAModal)
    console.log("ob", ob)
    this.setState({ modalAVisible: false })
    const type = ob.type
    console.log("type", type)
    if (this.state.activeAModal == "aOpenness") {
      this.setState({ aOpenness: type })
    }
    if (this.state.activeAModal == "aType") {
      this.setState({ aType: type })
    }
  }

  callSend = async () => {

    if (!this.state.aType) {
      alert("No type selected");
      return;
    }
    if (!this.state.aOpenness) {
      alert("Select open vs. private");
      return;
    }
    if (!this.state.location || !this.state.location.coords || this.state.location.coords.latitude == 6.55) {
      alert("You need to specify the location of your offer");
      return;
    }
    if (!this.state.title || this.state.title.length < 2) {
      alert("Enter a title / name");
      return;
    }
    if (global.myUID && !this.state.activityIndicatorAnimating) {
      try {
        this.setState({ activityIndicatorAnimating: true });
        await global.fetchIdToken()
        const r = await fetch(global.cloudFunctionURL + "newGroup", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ isWeb:true,
            title: this.state.title,
            details: this.state.details,
            pictureURL: this.state.pictureURL,
            aSpecialCategory: this.state.aSpecialCategory,
            aType: this.state.aType,
            aOpenness: this.state.aOpenness,
            networkID: this.state.networkID,
            communityID: this.state.communityID,
            coords: this.state.location.coords,
            options: this.state.options,
            nOfComments: 0,
            nOfDirectComments: 0,
            nOfTotalLikes: 0,
            nOfJoiners: 0,
            topics: [],
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
        await this.setState({ activityIndicatorAnimating: false });
        const r2 = await r.json();
        if (r2.msg == "SUCCESS") {
          // alert
          // initiateApproval (including push notification for approver OR chat to approver)
          // navigate back

          console.log("Job created");
          this.props.navigation.navigate("chatGroupMain");
        } else {
          alert("Error. Try again");
          console.log("Error while calling createPost", r2);
        }
      } catch (err) {
        alert("Connection error.");
        console.log(err);
        this.setState({ activityIndicatorAnimating: false });
      }
    }
  };

  render() {
    const sWidth = Dimensions.get("window").width;
    const sHeight = Dimensions.get("window").height;
    const opennessSelection = [{
      type: "public",
      title: "Public",
      description: "Can be found by people around you, your followers, your communities"
    },
    {
      type: "closed",
      title: "Closed",
      description: "Only visible to people you invite"
    },
    {
      type: "communityOnly",
      title: "Community Only",
      description: "Accessible to people in the community you specify"
    }]
    const typeSelection = [{
      type: "forum",
      title: "Forum",
      description: "Anybody can contribute"
    },
    {
      type: "broadcast",
      title: "Broadcast",
      description: "Only you can send messages"
    }, {
      type: "broadcastWithResponses",
      title: "Broadcast with comments",
      description: "Only you can send messages, others can respond."
    }]

    if (this.state.loading) return (
      <View
        style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "black" }}
      >
        <Text style={{ color: "#555" }}>Loading...</Text>
      </View>
    );
    if (this.state.error) {
      return (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "black" }}
        >
          <Text style={{ color: "#555" }}>Error. Try again.</Text>
        </View>
      );
    } else {
      const sWidth = Dimensions.get("window").width
      const tStyle = { color: "#A8CF45", fontSize: 16, fontWeight: "800", textAlign: "left", marginTop: 11, marginBottom: 2 }

      return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#000000" }}>
          <TouchableWithoutFeedback
            onPress={Keyboard.dismiss}
            accessible={false}
          >
            <ScrollView style={{ marginTop: 50, paddingHorizontal: 10 }}
              onScroll={(e) => { Keyboard.dismiss() }}>
              {Platform.OS === "android" && <View style={{ height: 25 }} />}
              <Text style={tStyle}>Title</Text>
              <TextInput
                onChangeText={title => {
                  this.setState({ title });
                }}
                placeholder={this.state.aType == "group" ? "" : ""}
                placeholderTextColor={"#66a"}
                style={{
                  color: "#ffffff", borderColor: "grey", borderWidth: 0, paddingLeft: 3, borderRadius: 5, height: this.state.title.length > 100 ? 75 : this.state.title.length > 60 ? 60 : this.state.title.length > 30 ? 45 : 30, backgroundColor: "#223"
                }}
                maxLength={1000}
                numberOfLines={4}
                multiline={true}
                underlineColorAndroid="transparent"
                defaultValue={this.state.title}
                keyboardDismissMode={'on-drag'}
                keyboardAppearance={"dark"}
              />
              <Text style={tStyle}>Description (optional)</Text>
              <TextInput
                onChangeText={details => {
                  this.setState({ details });
                }}
                multiline={true}
                placeholder={""}
                placeholderTextColor={"#66a"}
                numberOfLines={10}
                style={{
                  color: "#ffffff", borderColor: "grey", borderWidth: 0, paddingLeft: 3, borderRadius: 5, height: this.state.details.length > 250 ? 200 : this.state.details.length > 150 ? 160 : 120, backgroundColor: "#223",
                }}
                maxLength={5000}
                underlineColorAndroid="transparent"
                defaultValue={this.state.details}
                rowSpan={10}
                keyboardDismissMode={'on-drag'}
                keyboardAppearance={"dark"}
              />
              {!!this.state.addMedia ? <View style={{ alignItems: "center", justifyContent: "center" }}>
                <Text style={[tStyle, { alignSelf: "flex-start" }]}>Picture / Video</Text>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showGetPicture: true });
                  }}>
                  {!!this.state.pictureURL ? <Image
                    key={this.state.pictureURL}
                    style={{ width: sWidth - 20, height: (sWidth - 20) * 9 / 16, borderRadius: 5, borderWidth: 1, borderColor: "#222" }}
                    source={{ uri: this.state.pictureURL }}
                  /> :
                    <View
                      style={{ width: sWidth - 20, height: (sWidth - 20) * 9 / 16, borderRadius: 5, borderColor: "#aaa", borderWidth: 1, borderStyle: "dashed" }}
                    />
                  }
                </TouchableOpacity>
                {this.state.showGetPicture && (
                  <GetPicture
                    onDone={url => {
                      if (url) { this.setState({ pictureURL: url }); }
                      console.log("PictureURL", url);
                      this.setState({ showGetPicture: false });
                    }}
                    item={"POST"}
                  />
                )}
                {this.state.showGetVideo && (
                  <GetVideo
                    onDone={(youtubeID, thumbURL) => {
                      if (youtubeID && thumbURL) { this.setState({ pictureURL: thumbURL, youtubeID }); }
                      console.log("Gotten YouTube video..", youtubeID, thumbURL);
                      this.setState({ showGetVideo: false });
                    }}
                    item={"POST"}
                  />
                )}
                {!!this.state.youtubeID && <TouchableOpacity style={{
                  marginTop: -(220), width: 220, height: 220, alignItems: "center", justifyContent: "center"
                }}
                  onPress={() => { this.playYoutube(this.state.youtubeID) }}>
                  <View
                    style={{
                      backgroundColor: "rgba(198,198,198,0.95)", borderRadius: 25, paddingLeft: Platform.OS == "ios" ? 3 : 3.5, paddingBottom: Platform.OS == "ios" ? 0.5 : 2.5,
                      height: 50, justifyContent: 'center', alignItems: 'center', width: 50,
                    }}>
                    <Text style={{ color: "#000", fontSize: 36 }} >
                      {"▶"}
                    </Text>
                  </View>
                </TouchableOpacity>}
                <TouchableOpacity
                  onPress={() => { this.setState({ showGetVideo: true }); }}
                  style={{ backgroundColor: "rgba(220, 220, 220, 0.8)", marginTop: -42, marginBottom: 19, padding: 3, borderRadius: 5 }}
                >
                  <Text style={{ color: "black", minWidth: 100, textAlign: "center" }}>{this.state.youtubeID ? 'Change Video' : 'Add Video'}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => { this.setState({ showGetPicture: true }); }}
                  style={{ backgroundColor: "rgba(220, 220, 220, 0.8)", marginTop: -11.5, padding: 3, borderRadius: 5 }}
                >
                  <Text style={{ color: "black", minWidth: 100, textAlign: "center" }}>{this.state.pictureURL ? this.state.youtubeID ? 'Change Thumbnail' : 'Change Picture' : 'Add Picture'}</Text>
                </TouchableOpacity>
              </View>
                : <TouchableOpacity
                  onPress={() => { this.setState({ addMedia: true }); }}
                  style={[global.gStyles.buttonXW, { width: 120, marginTop: 11, marginBottom: 15 }]}
                >
                  <Text style={global.gStyles.buttontXW}>Add Picture / Video</Text>
                </TouchableOpacity>}

              <Text style={[tStyle, { alignSelf: "flex-start" }]}>Type</Text>

              <TouchableOpacity onPress={() => this.setState({ modalAVisible: true, activeAModal: "aOpenness", activeASelection: opennessSelection })}>
                <ASelector selection={opennessSelection} selected={this.state.aOpenness} />
              </TouchableOpacity>

              <TouchableOpacity onPress={() => this.setState({ modalAVisible: true, activeAModal: "aType", activeASelection: typeSelection })}>
                <ASelector selection={typeSelection} selected={this.state.aType} />
              </TouchableOpacity>

              <Text style={[tStyle, { alignSelf: "flex-start" }]}>Location</Text>
              <View style={{ borderRadius: 7.5, width: "100%", backgroundColor: "#18181f", }}>
                <CurrentLocation
                  textColor={"#ccd"}
                  initialCoords={global.location ? this.state.location.coords : null}
                  onUpdate={(address, coords) => {
                    console.log("upda", address, coords);
                    this.setState({
                      location: { address: address, coords: coords }
                    });
                  }}
                  iconColor={"#889"}
                />
                <View style={{ height: 2 }} />
              </View>

              {/* <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TouchableHighlight
                  onPress={() => {
                    this.setState({ hasDelivery: !this.state.hasDelivery });
                  }}
                >
                  <Selector
                    selected={this.state.hasDelivery}
                    text={!this.state.hasDelivery ? "Offer Delivery" : "This product can be delivered"}
                  />
                </TouchableHighlight>
              </View> */}
              <View
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 10,
                  marginBottom: 10
                }}
              >
                <View style={{ height: 30 }} />
                {!this.state.activityIndicatorAnimating ? <TouchableOpacity
                  onPress={this.callSend}
                >
                  <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: 200, alignSelf: "center", alignItems: "center" }]} >
                    <Text style={{ fontSize: 20, fontWeight: "bold", color: "#000" }}>OK, Create</Text>
                  </View>
                </TouchableOpacity> :
                  <ActivityIndicator
                    size="large"
                    color="#A8CF45"
                    style={{ alignSelf: "center" }}
                    animating={true}
                  />}
              </View>

              <Modal
                animationType="slide"
                transparent={true}
                visible={this.state.modalAVisible}
                onRequestClose={() => {
                  this.setState({ modalAVisible: false });
                }}
              >
                <TouchableOpacity
                  onPress={() =>
                    this.setState({ modalAVisible: false })
                  }
                  style={{ flex: 1 }}
                />
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <View
                    style={{
                      alignItems: "center", justifyContent: "center", backgroundColor: "#ccd", borderTopEndRadius: 15, borderTopLeftRadius: 15
                    }}
                  >
                    <View style={{ paddingTop: 25 }}>
                      <Text style={{ fontSize: 24, fontWeight: "bold", color: "#223" }}>{this.state.activeAModal == "aOpenness" ? "Select who can participate" : "Select Type"}</Text>
                    </View>
                    <View style={{ marginTop: 15, marginBottom: 5 }}>
                    </View>
                    {this.state.activeASelection.map((x) => {
                      return <TouchableOpacity key={x.type} onPress={() => { this.activateASelection(x) }}>
                        <ASelectorW ob={x} />
                      </TouchableOpacity>
                    })}
                    <View style={{ paddingTop: 25 }} />
                  </View>
                </View>
              </Modal>
              <Modal
                animationType="fade"
                visible={this.state.watchingYoutube}
                onRequestClose={() => {
                  this.setState({ watchingYoutube: false });
                }}
              >
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "flex-end",
                    height: "100%",
                    backgroundColor: "#000"
                  }}
                >
                  <WebView
                    originWhitelist={['*']}
                    style={{ width: sWidth, height: sHeight, marginVertical: ((sHeight - sWidth * 0.7) / 2) }}
                    source={{ uri: 'https://www.youtube.com/embed/' + this.state.playingYoutubeID }}
                    javaScriptEnabled={true}
                    domStorageEnabled={true}
                    useWebKit={true}
                  />
                  <TouchableOpacity
                    style={{
                      position: "absolute",
                      borderWidth: 0,
                      backgroundColor: "#000",
                      borderColor: "rgba(0,0,0,0.2)",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 35,
                      top: 65,
                      padding: 10,
                      left: 5,
                      height: 25,
                      borderRadius: 35
                    }}
                    onPress={() => {
                      this.setState({ watchingYoutube: false });
                    }}
                  >
                    <Image
                      style={{ width: 35, height: 35, opacity: 1 }}
                      source={require("../../images/icons/CancelWhite.png")}
                    />
                  </TouchableOpacity>
                </View>
              </Modal>
            </ScrollView>
          </TouchableWithoutFeedback>
        </SafeAreaView>
      );
    }
  }
}

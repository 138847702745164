import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  Modal,
  TouchableWithoutFeedback,
  ScrollView,
  SafeAreaView,
  Keyboard,
  Platform,
  ActivityIndicator,
} from "react-native";
import * as SecureStore from 'expo-secure-store';
import firebase from "firebase";
import "firebase/firestore";
const gStyles = global.gStyles //    "../../styles/gStyles";
import CryptoJS from "react-native-crypto-js";

const Stellar = require("stellar-base");
//Stellar.Network.usePublicNetwork();


export default class WalletSetupScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    return {
      title: "Wallet Password",
      headerTransparent: true,
      headerTintColor: "#A8CF45",
      headerTitleStyle: {
        fontWeight: "bold",
        color: "#A8CF45",
        shadowOpacity: 1
      }
    };
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // SCREEN LOGIC: Landing on screens "0", "1" or "1x"
  //
  // Screen "0":  No coinsignkey, "You need to set a wallet passpord" "OK" --> Screen "2"
  // Screen "1":  You want to change password (coinsignkey exists), "Enter your existing password" Valid --> Screen "2"
  // Screen "1d": You have forgotten your password. then "Warning, your wallet may be blocked some days for security reasons". --> Screen "4"
  // Screen "1e": Coinsignkey, but no local securestore found. You have lost your phone? "Warning, your wallet may be blocked some days for security reasons". -> Screen "4"
  //
  // Screen "2":  "Enter new password twice". OK --> Store new key and go to walletMain
  // Screen "4":  Your wallet is now blocked for x days. People can still transfer money to you, but you cannot do any transfers or order transactions. After x days, you can reset the key.
  // Screen "11":  Enter your Wallet Password so we can enable the wallet transfer to a new device.
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  state = {
    loading: true,
    loadingSignout: false,
    error: false,
    screen: global.coinSignKeySet ? "1" : "0",
    length: 0,
    activityIndicatorAnimating: false,
    responseToDisplay: "",
    hasHadPassword: false
  };

  constructor() {
    super();
    this.wap = "";
    this.newWAP = "";
    this.newWAP2 = "";
    global.Buffer = require('buffer').Buffer;
  }

  async componentDidMount() {
    const startScreen = this.props.navigation.getParam('screen', null);
    if (startScreen) this.setState({ screen: startScreen })
    try {
      const w1 = SecureStore.getItemAsync("W" + global.myUID.substring(2, 20))
      const myUserSnapshot = await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .get();
      if (!myUserSnapshot.exists) return this.setState({ loading: false, error: true });
      const user = myUserSnapshot.data()
      if (!user.usesACN) this.setState({ error: true })
      if ((!user.coinSignKey && global.coinSignKeySet) || (!user.coinSignKey && global.coinSignKeySet)) {
        this.setState({ error: true });
        global.setGlobals();
      }
      //this.setState({hasHadPassword: (user.coinKeyLog && user.coinKeyLog.length>0)})
      this.setState({ hasHadPassword: user.coinLastResetDate ? true : false })


      const encK = await w1;

      const now = new Date()
      if ((user.coinBlockedBySystemUntil && user.coinBlockedBySystemUntil.toDate() > now)) {
        alert("Your wallet is currently blocked. You can receive payments only")
        this.setState({ error: true, loading: false });
        return;
      }
      if ((user.coinBlockedByUserUntil && user.coinBlockedByUserUntil.toDate() > now)) {
        alert("Your wallet is currently blocked. You can receive payments only")
        this.setState({ error: true, loading: false });
        return;
      }
      if (global.coinResetAfterBlock) {
        alert("Sign out of Wakanda and sign in again to be able to make payments again")
        await global.timeout(100)
        this.props.navigation.goBack()
        return;
      }
      if ((!global.coinSignKeySet)) {
        this.setState({ screen: "0" });
      }
      if (user.coinSignKey && !encK) this.setState({ screen: "1e" });

      this.setState({ loading: false });

    } catch (err) {
      console.log(err)
      this.setState({ error: true, loading: false });
    }
  }

  // cancel = () => {
  //   this.props.navigation.navigate("walletMain");
  // }

  //replace this
  makeID = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }



  callConfirmOldPassword = async (successScreen) => {
    if (this.state.activityIndicatorAnimating) return;
    if (this.wap.length < 8) {
      alert("Wallet Password not long enough");
      return;
    }

    try {
      this.setState({ activityIndicatorAnimating: true });
      await global.timeout(1000)
      const encK = await SecureStore.getItemAsync("W" + global.myUID.substring(2, 20))
      console.log("checking password...")

      // check password
      try {
        if (CryptoJS.AES.decrypt(encK, this.wap + global.myUID.substring(2, 12)).toString(CryptoJS.enc.Utf8) == "") {
          this.wap = ""
          await global.timeout(8000)
          this.setState({ activityIndicatorAnimating: false });
          alert("Password incorrect")
          //XXTBD Count Incorrect Password attempts

          return;
        }
        else {
          if (this.props.navigation.getParam('screen', null) == "11") {
            this.replacePassword()
            this.setState({ loadingSignout: true })
          } else this.setState({ screen: successScreen, activityIndicatorAnimating: false })
        }

      } catch (er) {
        this.wap = ""
        await global.timeout(8000)
        this.setState({ activityIndicatorAnimating: false });
        alert("Password incorrect")
        //XXTBD Count Incorrect Password attempts
      }
    } catch (err) {
      alert("General error")
      this.setState({ activityIndicatorAnimating: false });
      console.log(err)
    }
  }

  callSetNewPassword = async () => {
    if (this.state.activityIndicatorAnimating) return;
    if (this.newWAP != this.newWAP2) {
      alert("Passwords don't match");
      return;
    }
    if (this.newWAP.length < 8) {
      alert("Password must be at least 8 characters long");
      return;
    }
    // TBD check further password rules

    console.log("getting pair")
    //this.pair = Stellar.Keypair.random();

    this.pair = Stellar.Keypair.fromRawEd25519Seed(this.makeID(32))

    //console.log("pairs", this.pair.secret())
    console.log("Keypair created", this.pair.publicKey())

    if ((!global.coinSignKeySet)) {
      this.setFirstPassword()
    } else this.replacePassword()
  }

  setFirstPassword = async () => {
    console.log("calling setFirstPassword")
    this.setState({ activityIndicatorAnimating: true });
    try {
      const r = await fetch(global.cloudFunctionURL + "createCoinKey", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          myUID: global.myUID,
          idToken: global.idToken,
          newPublicSignKey: this.pair.publicKey()
        })
      });
      const r2 = await r.json();
      this.setState({ activityIndicatorAnimating: false });
      console.log("called createCoinKey, response: ", r2)
      await global.timeout(100)
      if (r2.msg === "SUCCESS") {
        global.coinSignKeySet = true
        this.storeNewSecret()
      } else {
        alert("Transaction failed");
      }
    } catch (error) {
      alert("Error.");
      this.setState({ activityIndicatorAnimating: false, });
      console.log("Error creating coin key. ", error);
    }
  };

  replacePassword = async () => {
    console.log("calling replacePassword")
    this.setState({ activityIndicatorAnimating: true });
    const isSignout = this.props.navigation.getParam('screen', null) == "11"
    try {

      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "changeCoinKey", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          myUID: global.myUID,
          idToken: global.idToken,
          newPublicSignKey: isSignout ? "----------------------------------------------------" : this.pair.publicKey(),
          ...(isSignout && { action: "signout" })
        })
      });
      const r2 = await r.json();
      console.log("called changeCoinKey, response: ", r2)

      if (r2.msg !== "SUCCESS") {
        this.setState({ activityIndicatorAnimating: false });
        alert("Transaction failed.");
        return;
      }

      // wait until Firestore has latest envelope (sometimes old data is cached and returned)
      await global.timeout(500)
      let userDoc = await firebase.firestore().collection("Users").doc(global.myUID).get()
      if (!userDoc.exists || !userDoc.data().pendingTransaction || userDoc.data().pendingTransaction.envelope !== r2.envelope) {
        await global.timeout(300)
        userDoc = await firebase.firestore().collection("Users").doc(global.myUID).get()
      }
      if (!userDoc.exists || !userDoc.data().pendingTransaction || userDoc.data().pendingTransaction.envelope !== r2.envelope) {
        await global.timeout(500)
        userDoc = await firebase.firestore().collection("Users").doc(global.myUID).get()
      }
      if (!userDoc.exists || !userDoc.data().pendingTransaction || userDoc.data().pendingTransaction.envelope !== r2.envelope) {
        await global.timeout(500)
        userDoc = await firebase.firestore().collection("Users").doc(global.myUID).get()
      }
      if (!userDoc.exists || !userDoc.data().pendingTransaction || userDoc.data().pendingTransaction.envelope !== r2.envelope) {
        await global.timeout(500)
        userDoc = await firebase.firestore().collection("Users").doc(global.myUID).get()
      }
      if (!userDoc.exists || !userDoc.data().pendingTransaction || userDoc.data().pendingTransaction.envelope !== r2.envelope) {
        await global.timeout(500)
        userDoc = await firebase.firestore().collection("Users").doc(global.myUID).get()
      }
      if (!userDoc.exists || !userDoc.data().pendingTransaction || userDoc.data().pendingTransaction.envelope !== r2.envelope) {
        await global.timeout(500)
        userDoc = await firebase.firestore().collection("Users").doc(global.myUID).get()
      }
      if (!userDoc.exists || !userDoc.data().pendingTransaction || userDoc.data().pendingTransaction.envelope !== r2.envelope) {
        await global.timeout(2500)
        userDoc = await firebase.firestore().collection("Users").doc(global.myUID).get()
      }
      if (!userDoc.exists || !userDoc.data().pendingTransaction || userDoc.data().pendingTransaction.envelope !== r2.envelope) {
        console.log("Major error!")
        this.setState({ activityIndicatorAnimating: false });
        alert("Connection error!")
        // reportError major error
        return;
      }

      //Received envelope, now verify transactionRecord 
      const user = userDoc.data()
      if (!user.pendingTransaction || !isSignout && (user.pendingTransaction.newSignPublicKey !== this.pair.publicKey())) {
        console.log("Major error!")
        this.setState({ activityIndicatorAnimating: false });
        alert("Error!")
        // reportError major error
        return;
      }

      //transactionRecord verified, now sign
      const stellarTransaction = new Stellar.Transaction(r2.envelope, Stellar.Networks.PUBLIC);
      let encK = await SecureStore.getItemAsync("W" + global.myUID.substring(2, 20))
      console.log("transactionRecord verified, now sign")
      this.signature = stellarTransaction.getKeypairSignature(Stellar.Keypair.fromSecret(CryptoJS.AES.decrypt(encK, this.wap + global.myUID.substring(2, 12)).toString(CryptoJS.enc.Utf8)));
      console.log("Signature set", this.signature)
      console.log("r2.envelope", r2.envelope)

      this.wap = ""
      encK = "X---"
      const R = await fetch(global.cloudFunctionURL + "changeCoinKeyValidation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          id: r2.id, // tbd check
          signature: this.signature,
          myUID: global.myUID,
          idToken: global.idToken,
        })
      });
      const R2 = await R.json();
      this.signature = ""
      this.setState({ activityIndicatorAnimating: false, });
      await global.timeout(100)
      console.log("Response from changeCoinKeyValidation", R2)

      if (R2.msg === "ACN_KEYCHANGE_SUCCESSFUL") {
        if (isSignout) {
          try {
            await SecureStore.deleteItemAsync("W" + global.myUID.substring(2, 20))
          } catch (e) {
            console.log(e)
          }
          global.signout()
        }
        else this.storeNewSecret();
      } else if (R2.msg === "BLOCKED") {
        alert(R2.reason || "ERROR");
      } else {
        alert("Transfer not successful.");
        console.log("ACN transaction failed. ", R2);
      }
    } catch (error) {
      alert("Error...");
      global.reportError?.(error, "catches", "WalletSetup_ACN")
      this.setState({ activityIndicatorAnimating: false, });
      console.log("Error changing coin key. ", error);
    }
  };

  storeNewSecret = async () => {
    // xx decrypt env here ????????
    let ciphertext = "";
    try {
      ciphertext = CryptoJS.AES.encrypt(this.pair.secret(), this.newWAP + global.myUID.substring(2, 12)).toString();
    } catch (e) {
      alert("Error. Password change only partially succesful. Please contact customer care.")
      //tbd reportError
    }
    try {
      await SecureStore.setItemAsync("W" + global.myUID.substring(2, 20), ciphertext, { keychainAccessible: SecureStore.WHEN_UNLOCKED_THIS_DEVICE_ONLY })
      alert("New Wallet Password successfully set");
    } catch (e) {
      alert("Error. Password change only partially succesful. Please contact customer care.")
      //tbd reportError
    }

    this.pair = "-----"
    this.props.navigation.navigate("walletMain");
  };

  callResetPassword = async (action) => {
    this.setState({ activityIndicatorAnimating: true });
    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "transferWallet", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          myUID: global.myUID,
          idToken: global.idToken,
          action
        })
      });
      const r2 = await r.json();
      console.log("called transferWallet, response: ", r2)
      this.setState({ activityIndicatorAnimating: false });

      if (r2.msg !== "SUCCESS") {
        alert("Transaction failed.");
        return;
      } else {
        this.setState({ screen: "4", responseToDisplay: r2.responseToDisplay })
      }

    } catch (error) {
      alert("..Error..");
      this.setState({ activityIndicatorAnimating: false, });
      console.log("Error changing coin key. ", error);
    }



    //if props.screen==11 then signout now
  }

  render() {

    if (this.state.error) {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#000"
          }}
        >
          <Text style={{ color: "#aaa", fontSize: 16 }}>Error. Try again.</Text>
        </View>
      );
    }
    if (this.state.loading) {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#000"
          }}
        >
          <ActivityIndicator
            size="large"
            color="#999999"
          />
        </View>
      );
    }
    if (this.state.loadingSignout) {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#000"
          }}
        >
          <ActivityIndicator
            size="large"
            color="#999999"
          />
          <Text style={{ color: "#ccc" }}>Wait until signout completed...</Text>
        </View>
      );
    }
    if (this.state.screen == "0") {
      return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#000" }}>
          <TouchableWithoutFeedback
            onPress={Keyboard.dismiss}
            accessible={false}
          >
            <ScrollView style={{ paddingTop: Platform.OS == "ios" ? 50 : 85, paddingHorizontal: 25, backgroundColor: "#000" }}>
              <Text
                style={{
                  fontSize: 24,
                  color: "#ccc",
                  paddingBottom: 25,
                  borderBottomColor: "#FFFFFF",
                  borderBottomWidth: 0,
                  marginTop: 20,
                  justifyContent: "flex-start",
                  textAlign: "center"
                }}
              >
                Set a Wallet Password
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "#ccc",
                  paddingBottom: 5,
                  borderBottomColor: "#FFFFFF",
                  borderBottomWidth: 0,
                  justifyContent: "flex-start",
                  textAlign: "center"
                }}
              >
                {this.state.hasHadPassword ? "As requested, your old Wallet Password has been invalidated. Set a new password so you can do payments." :
                  "Before you can do any transaction fron your Wakanda Wallet, you need to set a Wallet Password to protect it."}
              </Text>
              <View style={{ height: Platform.OS == "ios" ? 50 : 25 }} />
              <View style={{ alignSelf: "stretch" }}>
                <TouchableOpacity style={global.gStyles.button} onPress={() => { this.setState({ screen: "2" }) }}>
                  <Text style={global.gStyles.buttontext}>OK</Text>
                </TouchableOpacity>
              </View>
            </ScrollView>
          </TouchableWithoutFeedback>
        </SafeAreaView>
      );
    }

    if (this.state.screen == "1" || this.state.screen == "11") {
      return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#000" }}>
          <ScrollView style={{ paddingTop: Platform.OS == "ios" ? 50 : 85, paddingHorizontal: 25, backgroundColor: "#000" }}>
            <Text
              style={{
                fontSize: 16,
                color: "#ccc",
                paddingBottom: 5,
                borderBottomColor: "#FFFFFF",
                borderBottomWidth: 0,
                marginTop: 20,
                justifyContent: "flex-start",
                textAlign: "center"
              }}
            >
              Enter Current Wallet Password
              </Text>
            <View style={{
              marginBottom: 0,
              color: "#ffffff",
              borderWidth: 0,
              borderRadius: 25,
              height: 35,
              width: 224,
              backgroundColor: "#223",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              alignSelf: "center"
            }}>
              <Text style={{ fontSize: 18, color: "#ccc", marginHorizontal: 10 }}>
                🌍</Text>
              <TextInput
                onChangeText={wap => {
                  this.wap = wap;
                }}
                secureTextEntry={true}
                style={{
                  color: "#ccc",
                  borderWidth: 0,
                  height: 35,
                  flex: 1
                }}
                textAlign={'center'}
                underlineColorAndroid="transparent"
                bordered
              />
              <Text style={{ fontSize: 18, color: "#ccc", marginHorizontal: 10 }}>
                🌍</Text>
            </View>
            <View style={{ height: 30 }} />
            {!this.state.activityIndicatorAnimating ? (
              <View style={{ alignSelf: "stretch" }}>
                <TouchableOpacity style={[global.gStyles.button, { width: 224, alignSelf: "center" }]} onPress={() => this.callConfirmOldPassword("2")}>
                  <Text style={global.gStyles.buttontext}>Confirm</Text>
                </TouchableOpacity>
                {/* <TouchableOpacity style={{ margin: 25, alignSelf: "stretch", alignItems: "center" }} onPress={this.cancel}>
                    <Text style={{ color: "white", fontSize: 14 }}>Cancel</Text>
                  </TouchableOpacity> */}
              </View>
            ) : (
                <ActivityIndicator
                  size="small"
                  color="#fff"
                  style={{
                    alignSelf: "center",
                    margin: 20
                  }}
                  animating={true}
                />
              )}
            <TouchableOpacity style={{ width: 224, alignSelf: "center", alignItems: "center", marginTop: 30 }} onPress={() => this.setState({ screen: "1d" })}>
              <Text style={{ color: "#aaa", fontSize: 12 }}>Forgot Password?</Text>
            </TouchableOpacity>
          </ScrollView>
          {this.state.screen == "11" && <View style={{ height: 120, width: "100%", position: "absolute", bottom: 0, justifyContent: "center", paddingHorizontal: 15 }}>
            <Text style={{ color: "#aaa" }}>ⓘ Enter your wallet password so you can use your wallet from a new device.</Text>
            <Text style={{ color: "#aaa" }}>Once entered, you will sign out.</Text>
          </View>}
        </SafeAreaView>
      );
    }

    // Screen "1d": You have forgotten your password. then "Warning, your wallet may be blocked some days for security reasons". -->  Screen "4"
    if (this.state.screen == "1d") {
      return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#000" }}>
          <TouchableWithoutFeedback
            onPress={Keyboard.dismiss}
            accessible={false}
          >
            <ScrollView style={{ paddingTop: Platform.OS == "ios" ? 50 : 85, paddingHorizontal: 25, backgroundColor: "#000" }}>
              <Text
                style={{
                  fontSize: 24,
                  color: "#ccc",
                  paddingBottom: 5,
                  borderBottomColor: "#FFFFFF",
                  borderBottomWidth: 0,
                  marginTop: 20,
                  justifyContent: "flex-start",
                  textAlign: "center"
                }}
              >
                Sure?
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  color: "#ccc",
                  paddingBottom: 5,
                  borderBottomColor: "#FFFFFF",
                  borderBottomWidth: 0,
                  marginTop: 20,
                  justifyContent: "flex-start",
                  textAlign: "center"
                }}
              >
                To reset the password, we may need to block your wallet for a few days for security reasons. You will still be able to receive payments, but won't be able to do payments.
              </Text>
              <View style={{ height: 30 }} />
              {!this.state.activityIndicatorAnimating ? (
                <View style={{ alignSelf: "stretch" }}>
                  <TouchableOpacity style={[global.gStyles.button, { width: 224, alignSelf: "center" }]} onPress={() => this.callResetPassword("FORGOT_PASSWORD")}>
                    <Text style={global.gStyles.buttontext}>Reset Password</Text>
                  </TouchableOpacity>
                </View>
              ) : (
                  <ActivityIndicator
                    size="small"
                    color="#fff"
                    style={{
                      alignSelf: "center",
                      margin: 20
                    }}
                    animating={true}
                  />
                )}
            </ScrollView>
          </TouchableWithoutFeedback>
        </SafeAreaView>
      );
    }

    // Screen "1e": Coinsignkey, but no local securestore found. You have lost your phone? "Warning, your wallet may be blocked some days for security reasons". -> Screen "4"
    if (this.state.screen == "1e") {
      return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#000" }}>
          <TouchableWithoutFeedback
            onPress={Keyboard.dismiss}
            accessible={false}
          >
            <ScrollView style={{ paddingTop: Platform.OS == "ios" ? 50 : 85, paddingHorizontal: 25, backgroundColor: "#000" }}>
              <Text
                style={{
                  fontSize: 20,
                  color: "#ccc",
                  paddingBottom: 5,
                  borderBottomColor: "#FFFFFF",
                  borderBottomWidth: 0,
                  marginTop: 20,
                  justifyContent: "flex-start",
                  textAlign: "center"
                }}
              >
                New phone?
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  color: "#ccc",
                  paddingBottom: 5,
                  borderBottomColor: "#FFFFFF",
                  borderBottomWidth: 0,
                  marginTop: 20,
                  justifyContent: "flex-start",
                  textAlign: "center"
                }}
              >
                It seems that you have switched your phone or you have reinstalled the app.
                If you want to use this phone for making payments, please sign out of your old phone.
                If your old phone is lost / not accessible, you need to claim the wallet to this phone. For security reasons, the wallet would be blocked for a few days in this case.
              </Text>
              <View style={{ height: 30 }} />
              {!this.state.activityIndicatorAnimating ? (
                <View style={{ alignSelf: "stretch" }}>
                  <TouchableOpacity style={[global.gStyles.button, { width: 224, alignSelf: "center" }]} onPress={() => this.callResetPassword("LOST_DEVICE")}>
                    <Text style={global.gStyles.buttontext}>My old phone is lost/unaccessible</Text>
                  </TouchableOpacity>
                </View>
              ) : (
                  <ActivityIndicator
                    size="small"
                    color="#fff"
                    style={{
                      alignSelf: "center",
                      margin: 20
                    }}
                    animating={true}
                  />
                )}
            </ScrollView>
          </TouchableWithoutFeedback>
        </SafeAreaView>
      );
    }

    // Screen "2":  "Enter new password twice". OK --> Store new key and go to walletMain
    if (this.state.screen == "2") {
      return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#000" }}>
          <TouchableWithoutFeedback
            onPress={Keyboard.dismiss}
            accessible={false}
          >
            <ScrollView style={{ paddingTop: Platform.OS == "ios" ? 50 : 85, paddingHorizontal: 25, backgroundColor: "#000" }}>
              <Text
                style={{
                  fontSize: 16,
                  color: "#ccc",
                  paddingBottom: 5,
                  borderBottomColor: "#FFFFFF",
                  borderBottomWidth: 0,
                  marginTop: 20,
                  justifyContent: "flex-start",
                  textAlign: "center"
                }}
              >
                Enter New Wallet Password
              </Text>
              <View style={{
                marginBottom: 0,
                color: "#ffffff",
                borderWidth: 0,
                borderRadius: 25,
                height: 35,
                width: 224,
                backgroundColor: "#225",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "center"
              }}>
                <Text style={{ fontSize: 18, color: "#ccc", marginHorizontal: 10 }}>
                  🌍</Text>
                <TextInput
                  onChangeText={wap => {
                    this.newWAP = wap;
                    this.setState({ length: wap.length })
                  }}
                  secureTextEntry={true}
                  style={{
                    color: "#ccc",
                    borderWidth: 0,
                    height: 35,
                    flex: 1
                  }}
                  textAlign={'center'}
                  underlineColorAndroid="transparent"
                  bordered
                />
                <Text style={{ fontSize: 18, color: "#ccc", marginHorizontal: 10 }}>
                  🌍</Text>
              </View>
              {this.state.length < 8 ? <Text
                style={{
                  fontSize: 12,
                  color: "#f88",
                  paddingBottom: 5,
                  borderBottomColor: "#FFFFFF",
                  borderBottomWidth: 0,
                  marginTop: 5,
                  justifyContent: "flex-start",
                  textAlign: "center"
                }}
              >
                Minimum length: 8 characters
              </Text> : <View>
                  <Text
                    style={{
                      fontSize: 16,
                      color: "#ccc",
                      paddingBottom: 5,
                      borderBottomColor: "#FFFFFF",
                      borderBottomWidth: 0,
                      marginTop: 20,
                      justifyContent: "flex-start",
                      textAlign: "center"
                    }}
                  >
                    Repeat New Wallet Password
              </Text>
                  <View style={{
                    marginBottom: 0,
                    color: "#ffffff",
                    borderWidth: 0,
                    borderRadius: 25,
                    height: 35,
                    width: 224,
                    backgroundColor: "#225",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignSelf: "center"
                  }}>
                    <Text style={{ fontSize: 18, color: "#ccc", marginHorizontal: 10 }}>
                      🌍</Text>
                    <TextInput
                      onChangeText={wap => {
                        this.newWAP2 = wap;
                      }}
                      secureTextEntry={true}
                      style={{
                        color: "#ccc",
                        borderWidth: 0,
                        height: 35,
                        flex: 1
                      }}
                      textAlign={'center'}
                      underlineColorAndroid="transparent"
                      bordered
                    />
                    <Text style={{ fontSize: 18, color: "#ccc", marginHorizontal: 10 }}>
                      🌍</Text>
                  </View></View>}
              <View style={{ height: 25 }} />
              {!this.state.activityIndicatorAnimating ? (
                <View style={{ alignSelf: "stretch" }}>
                  {this.state.length >= 8 && <TouchableOpacity style={global.gStyles.button} onPress={this.callSetNewPassword}>
                    <Text style={global.gStyles.buttontext}>Set new Wallet Password</Text>
                  </TouchableOpacity>}
                  {/* <TouchableOpacity style={{ margin: 25, alignSelf: "stretch", alignItems: "center" }} onPress={this.cancel}>
                    <Text style={{ color: "white", fontSize: 14 }}>Cancel</Text>
                  </TouchableOpacity> */}
                </View>
              ) : (
                  <ActivityIndicator
                    size="small"
                    color="#fff"
                    style={{
                      alignSelf: "center",
                      margin: 20
                    }}
                    animating={true}
                  />
                )}
              <Text
                style={{
                  fontSize: 12,
                  color: "#888",
                  paddingBottom: 5,
                  borderBottomColor: "#FFFFFF",
                  borderBottomWidth: 0,
                  marginTop: 20,
                  justifyContent: "flex-start",
                  textAlign: "center"
                }}
              >
                Be sure to remember your password. If you forget it, your wallet may be blocked for up to 7 days.
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  color: "#888",
                  paddingBottom: 5,
                  borderBottomColor: "#FFFFFF",
                  borderBottomWidth: 0,
                  marginTop: 20,
                  justifyContent: "flex-start",
                  textAlign: "center"
                }}
              >
                If you ever change your phone, sign out of Wakanda first. If you do not sign out or lose your phone, your wallet may be blocked for up to 7 days.
              </Text>
            </ScrollView>
          </TouchableWithoutFeedback>
        </SafeAreaView>
      );
    }

    // Screen "4":  Your wallet is now blocked for x days. People can still transfer money to you, but you cannot do any transfers or order transactions. After x days, you can reset the key.
    if (this.state.screen == "4") {
      return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#000" }}>
          <ScrollView style={{ marginTop: 20, paddingTop: Platform.OS == "ios" ? 50 : 85, paddingHorizontal: 25, backgroundColor: "#000" }}>
            <Text
              style={{
                fontSize: 16,
                color: "#ccc",
                paddingBottom: 5,
                borderBottomColor: "#FFFFFF",
                borderBottomWidth: 0,
                marginTop: 16,
                justifyContent: "flex-start",
                textAlign: "center"
              }}
            >
              {this.state.responseToDisplay}
            </Text>
            {/* //
            // if screenparam 11 then button sign Out now -> call global.signout()
             */}

          </ScrollView>
        </SafeAreaView>
      );
    }
  }
}

import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  Modal,
  Platform,
  Dimensions,
  ActivityIndicator
} from "react-native";


export default class FeedMenu extends Component {
  state = {
    menuModalScreen: 'DEFAULT', // can be "MUTE"
    isFollow: false,
  }

  mute = async (duration) => {
    try {
      const x = this.props.chatGroup
      this.props.onDone()
      this.setState({ menuModalScreen: 'DEFAULT' })
      const r = await fetch(global.cloudFunctionURL + "joinGroup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          chatGroupID: x.xID,
          action: "mute",
          duration,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();

      if (r2.msg == "SUCCESS") {
        this.props.refresh()
      } else alert("Error changing mute setting. Try again.")
    } catch (err) {
      global.warn(err, "mute");
      alert("Error changing mute setting - Try again")
    }
  }






  render() {
    const x = this.props.chatGroup
    return (<Modal
      animationType="slide"
      transparent={true}
      visible={this.props.showMenuModal}
      onRequestClose={() => {
        this.props.onDone()
        this.setState({ menuModalScreen: 'DEFAULT' });
      }}
    >
      <TouchableOpacity
        onPress={() => {
          this.props.onDone()
          this.setState({ menuModalScreen: 'DEFAULT' })
        }}
        style={{ flex: 1 }}
      />

      {this.state.menuModalScreen == "DEFAULT" ? <View
        style={{
          alignItems: "flex-start", backgroundColor: "#ccd", paddingTop: 5,
          borderTopEndRadius: 15, borderTopLeftRadius: 15
        }}
      >

        {x.ownerProfileID == global.myProfileID &&
          <TouchableOpacity onPress={async () => {
            this.props.onDone()
            await global.timeout(50)
            let route = "meCommunityNewDiscussion"
            if (this.props.navigation.state.routeName.startsWith("home")) route = "homeCommunityNewDiscussion"
            if (this.props.navigation.state.routeName.startsWith("chat")) route = "chatCommunityNewDiscussion"
            if (this.props.navigation.state.routeName.startsWith("message")) route = "chatCommunityNewDiscussion"
            this.props.navigation.navigate(route, {
              action: "edit", group: x, delayGoBack: this.props.delayGoBack
            });
          }}
            style={{ flexDirection: "row", padding: 12, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
            <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18, }}>Edit</Text>
          </TouchableOpacity>}
        {x.ownerProfileID == global.myProfileID && x.aOpenness == "closed" && <TouchableOpacity onPress={async () => {
          this.props.onDone()
          await global.timeout(50)
          let route = "meCommunityNewDiscussion"
          if (this.props.navigation.state.routeName.startsWith("home")) route = "homeCommunityNewDiscussion"
          if (this.props.navigation.state.routeName.startsWith("chat")) route = "chatCommunityNewDiscussion"
          if (this.props.navigation.state.routeName.startsWith("message")) route = "chatCommunityNewDiscussion"
          this.props.navigation.navigate(route, {
            action: "editMembers", group: x, delayGoBack: this.props.delayGoBack
          });
        }}
          style={{ flexDirection: "row", padding: 12, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
          <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18, }}>Edit Members</Text>
        </TouchableOpacity>}

        <TouchableOpacity onPress={() => {
          if (x.xIsMuted)
            this.mute("0")
          else this.setState({ menuModalScreen: "MUTE" })
        }}
          style={{ flexDirection: "row", padding: 12, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
          <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18, }}>{x.xIsMuted ? "Unmute" : "Mute..."}</Text>
        </TouchableOpacity>

        {(x.ownerProfileID !== global.myProfileID) && !global.myFollowingIDs?.includes?.(x.ownerProfileID) && <TouchableOpacity
          onPress={async () => {
            try {
              this.props.onDone()
              let isFollow = true
              if (global.myFollowingIDs?.includes?.(x.ownerProfileID)) isFollow = false
              await global.followProfile(isFollow, x.ownerProfileID)
            }
            catch (err) { global.warn(err, "error k9df") }
          }}
          style={{ flexDirection: "row", padding: 12, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
          <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18, alignSelf: 'flex-start' }}>
            {global.myFollowingIDs?.includes?.(x.ownerProfileID) ? `Unfollow ${x.ownerName}` : `Follow ${x.ownerName}`}
          </Text>
        </TouchableOpacity>}
        {this.props.showEditComment && x.nOfComments !== 0 && x.moderatorProfileIDs.includes(global.myProfileID) && <TouchableOpacity
          onPress={async () => {
            try {
              this.props.editComment()
              this.props.onDone()
            }
            catch (err) { global.warn(err, "error k9df") }
          }}
          style={{ flexDirection: "row", padding: 12, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
          <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18, alignSelf: 'flex-start' }}>
            Edit Comment
          </Text>
        </TouchableOpacity>}
        {x.ownerProfileID !== global.myProfileID && <TouchableOpacity
          onPress={async () => {
            this.props.onDone()
            await global.timeout(50)
            this.props.navigation.navigate("meReport", {
              profileID: x.ownerProfileID,
              profileName: x.ownerName,
              profileBusinessName: x.ownerNameBusiness,
              profilePicture: x.ownerPictureURL || "",
              chatGroup: x
            });
          }}
          style={{ flexDirection: "row", padding: 12, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
          <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18, }}>Report</Text>
        </TouchableOpacity>}

        <View style={{ paddingTop: 100 }} />
      </View>
        :
        <View
          style={{
            alignItems: "flex-start", backgroundColor: "#ccd", borderTopEndRadius: 15, borderTopLeftRadius: 15
          }}
        >
          <TouchableOpacity onPress={async () => {
            this.mute("1d")
          }}
            style={{ flexDirection: "row", padding: 13, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
            <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18 }}>... 1 day</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={async () => {
            this.mute("1w")
          }}
            style={{ flexDirection: "row", padding: 13, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
            <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18 }}>... 1 week</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={async () => {
            this.mute("1y")
          }}
            style={{ flexDirection: "row", padding: 13, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
            <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18 }}>... 1 year</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={async () => {
            this.mute("28y")
          }}
            style={{ flexDirection: "row", padding: 13, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
            <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18 }}>... forever</Text>
          </TouchableOpacity>

          <View style={{ paddingTop: 50 }} />
        </View>}

    </Modal>)
  }
}


import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";

export default class Discount extends Component {
  state = {
    contactProperties: {
      opponentProfileID: null,
      opponentName: "✧ ✧ ✧ ✧ ✧ ✧ ✧ ✧ ✧ ✧",
      opponentNameBusiness: "✧ ✧ ✧ ✧ ✧ ✧ ✧",
      opponentProfilePictureURL: "",
      opponentLocationWork: null
    },
    activityIndicatorAnimating: false,
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = {timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    if (this.props.discount.providerProfileID) {
      try {
        this.unsubscribeFirestore = firebase
          .firestore()
          .collection("Profiles")
          .doc(this.props.discount.providerProfileID)
          .onSnapshot(this.updateContact);
      } catch (e) {
        console.log(e)
      }
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  updateContact = async contactSnapshot => {
    try {
      let contact = contactSnapshot.data();

      let opponentProfileID = contactSnapshot.id;
      let opponentName = contact.profileName;
      let opponentProfilePictureURL = contact.profilePictureURL;
      let opponentNameBusiness = contact.profileNameBusiness || "";
      let opponentLocationWork = contact.locationWork || null;

      let contactProperties = {
        opponentProfileID,
        opponentName,
        opponentNameBusiness,
        opponentProfilePictureURL,
        opponentLocationWork
      };

      //console.log("contactProperties", contactProperties);
      await this.setState({ contactProperties });

    } catch (err) {
      console.log("Slow internet. Could not fetch contacts (meContact)", err);
      alert("Could not fetch contacts. Check your internet connection");
    }
  };

  render() {
    let discountString = "✧ ✧ ✧ "
    if (global.walletType == "ACN") discountString = (this.props.discount.discountACN || 0) + " ₳"
    else {
      let discountValue = this.props.discount.discountNGN || 0
      discountValue += this.props.discount.discountWakandaNGN
      discountString = discountValue + " ₦"
    }
    let validTillString = " ✧ ✧ ✧ "
    if (!this.props.discount.timeOfExpirationMillis) validTillString = ""
    else {
      validTillString = "" + new Date(this.props.discount.timeOfExpirationMillis).toDateString()
    }
    return (
      <View style={{ height: 160, backgroundColor: "#20202f", borderRadius: 10, marginVertical: 6, marginHorizontal: 10, }}>

        <TouchableOpacity
          activeOpacity={1}
          onPress={() => {
            if (this.props.pressable && this.props.discount && this.props.discount.providerProfileID)
              this.props.navigation.navigate("meProfile", { profileID: this.props.discount.providerProfileID })
          }}
          style={{
            flexDirection: "row",

            height: 65,
            alignItems: "center",
            paddingHorizontal: 11,
            borderBottomColor: "#333",
            borderBottomWidth: 0.5
          }}
        >
          {!!this.state.contactProperties.opponentProfilePictureURL ? (
            <Image
              style={{ width: 42, height: 42, borderRadius: 21 }}
              source={{
                uri: this.state.contactProperties.opponentProfilePictureURL
              }}
            />
          ) : (
              <View style={{ width: 42, height: 42, borderRadius: 21, backgroundColor: "#223", borderWidth: 0.5, borderColor: "#446" }} />
            )}
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View
              style={{
                flexDirection: "column",
                flex: 1,
                paddingHorizontal: 8
              }}
            >
              <Text style={{ fontSize: 14, color: "#ccccd5" }}>
                {this.state.contactProperties.opponentName}
              </Text>
              {this.state.contactProperties.opponentNameBusiness != "" && (
                <Text style={{ fontSize: 11, color: "#ccccd5" }}>
                  {this.state.contactProperties.opponentNameBusiness}
                </Text>
              )}
            </View>
          </View>
        </TouchableOpacity>

        <View
          style={{
            flexDirection: "row",
            height: 95,
            alignItems: "center",
            paddingHorizontal: 11,
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center", marginLeft: 5 }}>
            <View style={{ flex: 10 }}>
              <Text style={{ fontSize: 10, fontWeight: "bold", color: "#ccccd5" }}>
                VALUE
            </Text>
              <Text style={{ fontSize: 14, color: "#ccccd5" }}>
                {discountString}
              </Text>
            </View>
            <View style={{ flex: 20 }}>
              {!!validTillString && <Text style={{ fontSize: 10, fontWeight: "bold", color: "#ccccd5" }}>
                VALID TILL
            </Text>}
              <Text style={{ fontSize: 14, color: "#ccccd5" }}>
                {validTillString}
              </Text>
            </View>
          </View>
        </View>
      </View >
    );
  }
}
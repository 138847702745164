import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  TextInput,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  Alert,
  Modal,
  ActivityIndicator,
  Platform
} from "react-native";
import NetInfo from '@react-native-community/netinfo';
import Moment from "react-moment";
import { NavigationEvents } from "react-navigation";
import firebase from "firebase";
import "firebase/firestore";

import Post from "../../Home/Post/Post";

// class SearchBox extends Component {
//   state = {};
//   searchChats = searchString => {
//     alert("Search not yet implemented");
//   };
//   render() {
//     return (
//       <View style={{ padding: 5, alignItems: "center", marginTop: 0 }}>
//         <TextInput
//           style={{
//             alignSelf: "stretch",
//             height: 50,
//             marginBottom: 0,
//             marginTop: 0,
//             color: "#FFFFFF",
//             borderBottomColor: "#FFFFFF",
//             borderBottomWidth: 1
//           }}
//           placeholder="Search messages"
//           placeholderTextColor="#008866"
//           //textAlign= "center"
//           underlineColor={"transparent"}
//           returnKeyType="search"
//           onChangeText={string => this.searchChats(string)}
//         />
//       </View>
//     );
//   }
// }

class PostLine extends PureComponent {
  state = {
    data: null,
    showPostDetail: false,
    showRejectedModal: false,
    showDiscountModal: false,
    askDelivery: false,
    aa: false
  };

  checkDelivery = (data) => {
    let askDelivery = true;
    if ("hasDeliveryDecided" in data) askDelivery = false
    console.log(data.needsDeliveryChoice, data.hasDelivery)
    this.setState({ askDelivery })
  }

  pausePost = async () => {
    if (this.state.aa) return;
    this.setState({ aa: true })
    try {
      const r = await fetch(global.cloudFunctionURL + "updatePost", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          postID: this.props.postID,
          action: "pause",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      this.setState({ aa: false })
      const r2 = await r.json();
      if (r2.msg == "ERROR") alert("Update not successful. Try again later.")
    } catch (err) {
      this.setState({ aa: false })
      console.log("Error in updatePost1", err);
    }
  };
  resumePost = async () => {
    if (this.state.aa) return;
    this.setState({ aa: true })

    try {
      const r = await fetch(global.cloudFunctionURL + "updatePost", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          postID: this.props.postID,
          action: "resume",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      this.setState({ aa: false })
      const r2 = await r.json();
      if (r2.msg == "ERROR") alert("Update not successful. Try again later.")
    } catch (err) {
      this.setState({ aa: false })
      console.log("Error in updatePost2", err);
    }
  };
  deletePost = async () => {
    if (this.state.aa) return;

    Alert.alert(
      "Delete this offer permanently?",
      "",
      [
        {
          text: "Cancel",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel"
        },
        {
          text: "Delete",
          onPress: () => {
            console.log("Delete Pressed");
            this.executeDeletePost();
          }
        }
      ],
      { cancelable: false }
    );
  };
  executeDeletePost = async () => {
    this.setState({ aa: true })

    try {
      const r = await fetch(global.cloudFunctionURL + "updatePost", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          postID: this.props.postID,
          action: "delete",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      this.setState({ aa: false })
      const r2 = await r.json();
      if (r2.msg == "ERROR") alert("Deletion not successful. Try again later.")
    } catch (err) {
      this.setState({ aa: false })
      console.log("Error in executeDeletePost", err);
    }
  };

  makeTopOffer = async () => {
    alert("Feature coming soon");
  };

  addDelivery = (yesno) => {
    let title = "Add delivery?"
    let message = "This offer will be updated permanently"
    if (yesno == "no") {
      title = "Don't offer delivery?"
    }
    Alert.alert(
      title, message,
      [
        { text: "Cancel", onPress: () => console.log("Cancel Pressed"), style: "cancel" },
        { text: "OK", onPress: () => { this.executeAddDelivery(yesno); } }
      ],
      { cancelable: false }
    );
  }

  executeAddDelivery = async (yesno) => {
    try {
      await firebase
        .firestore()
        .collection("Posts")
        .doc(this.props.postID)
        .update({ postHasDelivery: yesno == "yes" });
      alert("Offer updated")
      this.setState({ askDelivery: false })
    } catch (err) {
      console.log("Error pausing Post", err);
      alert("Error. Try again.");
    }
  }

  removeDiscount = () => {
    let title = "Remove Discount?"
    let message = ""
    Alert.alert(
      title, message,
      [
        { text: "Cancel", onPress: () => console.log("Cancel Pressed"), style: "cancel" },
        { text: "OK", onPress: () => { this.executeRemoveDiscount(); } }
      ],
      { cancelable: true }
    );
  }

  executeRemoveDiscount = async () => {
    try {
      // await firebase
      //   .firestore()
      //   .collection("Posts")
      //   .doc(this.props.postID)
      //   .update({ postPriceDiscount: 0, postPriceHasDiscount: false });
      if (this.state.aa) return;
      this.setState({ aa: true })
      const r = await fetch(global.cloudFunctionURL + "updatePost", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          postID: this.props.postID,
          action: "removeDiscount",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      this.setState({ aa: false })
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        alert("Discount Removed")
      }
      else {
        alert("Update not successful. Try again later.")
      }
      this.setState({ discount: "" })
    } catch (err) {
      this.setState({ aa: false })
      console.log("Error in executeRemoveDiscount", err);
      alert("Error. Try again.");
    }
  }

  addDiscount = async () => {
    console.log("Adding discount")
    const currency = this.state.data?.priceCurrency || "NGN"
    const discount = Number(this.state.discount)
    if (!discount || discount < 0) return alert("Discount is not a number")
    if (!this.state.data) return alert("Please try again.")
    if (!this.state.data.price) return alert("Not possible")
    if (currency == "NGN" || currency == "ACN") {
      if ((discount + 100) > this.state.data.price) return alert("Discount too high")
    } else {
      const discountACN = discount / global.xRates[currency]
      const priceACN = (this.state.data.price || 0) / global.xRates[currency]
      if ((discountACN + 140) > priceACN) return alert("Discount too high")
    }
    if (discount >= 0) {
      try {
        // await firebase
        //   .firestore()
        //   .collection("Posts")
        //   .doc(this.props.postID)
        //   .update({ postPriceDiscount: discount, postPriceHasDiscount: true });
        if (this.state.aa) return;
        this.setState({ aa: true })
        const r = await fetch(global.cloudFunctionURL + "updatePost", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            postID: this.props.postID,
            action: "addDiscount",
            discount,
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
        this.setState({ aa: false })
        this.setState({ showDiscountModal: false })
        await global.timeout(600)
        const r2 = await r.json();
        if (r2.msg == "SUCCESS") alert("Discount Added")
        else alert("Update not successful. Try again later.")
      } catch (err) {
        this.setState({ aa: false })
        console.log("Error applying discount", err);
        alert("Error. Try again.");
      }
    } else alert("Discount too low")
  }

  render() {
    const status = () => {
      switch (this.state.data.status) {
        case "PENDING_APPROVAL":
          return "⛱ NEEDS APPROVAL";
          break;
        case "LIVE":
          return "✅ LIVE";
          break;
        case "PAUSED":
          return "⚫ PAUSED";
          break;
      }
    };

    if (this.state.data && this.state.data.status == "DELETED") {
      return <View />;
    }
    return (<View style={{
      height: this.state.askDelivery ? 200 : 170,
      flex: 1,
      shadowOpacity: 0.5,
      shadowOffset: { width: 0, height: 3 },
      borderBottomColor: "#555555",
      borderWidth: 1,
      backgroundColor: this.state.data
        ? this.state.data.status == "LIVE"
          ? "#000"
          : "#222"
        : "#222",
    }}>
      <View
        style={[
          {
            flexDirection: "row",
            flex: 1,
            height: this.state.askDelivery ? 200 : 170,
            //width: 100,

            alignItems: "center",
            paddingLeft: 11,
            paddingRight: 11,

          },
          this.state.hasUnreadItems && {
            backgroundColor: "#A8CF45",
            height: 85
          }
        ]}
      >
        <View
          style={{
            width: 155,
            height: 155,
            transform: [{ scale: 0.5 }]
          }}
        >
          <TouchableOpacity
            onPress={() => {
              if (!this.state.showPostDetail) {
                this.setState({ showPostDetail: true });
              }
            }}
            style={{
              width: 300,
              height: 300,
              margin: -74
            }}
          >
            <Post
              key={this.state.showPostDetail}
              postID={this.props.postID}
              navigation={this.props.navigation}
              showDetail={this.state.showPostDetail}
              hideAuthor={true}
              onHideDetail={() => {
                this.setState({ showPostDetail: false });
              }}
              //relevantStatus={"LIVE"}
              onIsNotRelevant={() => {
                //this.setState({ visible: false });
              }}
              loadingColor={"#333"}
              onDataLoaded={data => {
                this.setState({ data });
                this.checkDelivery(data)
              }}
            />
          </TouchableOpacity>
        </View>

        {!!this.state.data && (
          <View
            style={{
              flex: 1,
              alignItems: "flex-end",
              justifyContent: "space-between",
              alignSelf: "stretch"
            }}
          >
            {this.state.data.status != "REJECTED" && (
              <View
                style={{
                  alignSelf: "stretch",
                  marginHorizontal: 10,
                  marginVertical: 15,
                  paddingVertical: 6,
                  flexDirection: "row",
                  borderColor: "#888",
                  borderRadius: 10,
                  alignItems: "center",
                  justifyContent: "flex-start"
                }}
              >
                <Text
                  style={{
                    color: this.state.data.status == "LIVE" ? "#0f0" : "#666",
                    fontSize: 16,
                    fontWeight: "bold"
                  }}
                >
                  {status()}
                </Text>
              </View>
            )}
            {this.state.data.status == "REJECTED" && (
              <TouchableOpacity
                onPress={() => {
                  this.setState({ showRejectedModal: true });
                }}
                style={{
                  height: 110,
                  alignSelf: "stretch",
                  margin: 8,
                  marginBottom: 0,
                  borderColor: "#888",
                  backgroundColor: "#000",
                  borderWidth: 0,
                  padding: 2,
                  borderColor: "#322"
                }}
              >
                <Text
                  style={{ color: "#844", fontWeight: "bold", fontSize: 16 }}
                >
                  ❌ NOT APPROVED
                </Text>
                {/* <Text style={{ color: "#844" }}>{this.state.data.statusReason}</Text> */}
                <Text
                  style={{ color: "#844", fontStyle: "italic", fontSize: 10 }}
                >
                  {this.state.data.statusReasonText}
                </Text>
              </TouchableOpacity>
            )}
            {!!this.state.aa ?
              (<View style={{ alignItems: "flex-end" }}>
                <ActivityIndicator size="small" color="#888" style={{ marginRight: 20, marginBottom: 20 }} />
              </View>)
              : <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end" }}>
                <View>
                  {(!!this.state.data && this.state.data.status == "LIVE" && !this.state.data.priceDiscount
                    && this.state.data.priceType != "Free" && this.state.data.priceType != "NONE") &&
                    <TouchableOpacity onPress={() => { this.setState({ discount: "100", showDiscountModal: true }) }}>
                      <Text
                        style={{
                          color: "#888",
                          fontWeight: "bold",
                          marginBottom: 15,
                          marginLeft: 8
                        }}
                      >
                        Add Discount
                      </Text>
                    </TouchableOpacity>}
                  {(!!this.state.data && this.state.data.status == "LIVE" && !!this.state.data.priceDiscount) && <TouchableOpacity
                    onPress={this.removeDiscount}
                    style={{
                      backgroundColor: '#ace600', padding: 5, paddingRight: 22, borderBottomLeftRadius: 5, borderBottomRightRadius: 5,
                      borderRightWidth: 1, marginLeft: 10, marginBottom: 15
                    }}>
                    <Text style={{ color: '#ff4d94', fontWeight: 'bold', fontSize: 12, marginBottom: 0 }}>DISCOUNT</Text>
                    <Text style={{ color: 'black', fontSize: 12, fontWeight: 'bold', paddingTop: 0 }}>{global.currencySymbols[(this.state.data?.priceCurrency || "NGN")]}{this.state.data.priceDiscount}</Text>
                    <Image
                      style={{ width: 20, height: 20, opacity: 1, tintColor: "black", position: "absolute", top: 1, right: 1 }}
                      source={require("../../images/icons/CancelWhite.png")}
                    />
                  </TouchableOpacity>}
                </View>
                <View style={{ flex: 1 }} />
                <View style={{ alignItems: "flex-end" }}>
                  {this.state.data.status == "LIVE" && (
                    <TouchableOpacity onPress={this.pausePost}>
                      <Text
                        style={{
                          color: "#888",
                          fontWeight: "bold",
                          margin: 15,
                          marginVertical: 5
                        }}
                      >
                        Pause
                  </Text>
                    </TouchableOpacity>
                  )}
                  {this.state.data.status == "PAUSED" && (
                    <TouchableOpacity onPress={this.resumePost}>
                      <Text
                        style={{
                          color: "#484",
                          fontWeight: "bold",
                          margin: 15,
                          marginVertical: 5
                        }}
                      >
                        Resume
                  </Text>
                    </TouchableOpacity>
                  )}
                  <TouchableOpacity onPress={this.deletePost}>
                    <Text
                      style={{
                        color: "#844",
                        fontWeight: "bold",
                        margin: 15,
                        marginVertical: 5
                      }}
                    >
                      Delete
                </Text>
                  </TouchableOpacity>
                  {/* {this.state.data.status == "LIVE" && (
                <TouchableOpacity onPress={this.makeTopOffer}>
                  <Text
                    style={{
                      color: "#484",
                      fontWeight: "bold",
                      margin: 15,
                      marginVertical: 5,
                      marginTop: 7
                    }}
                  >
                    Make TopOffer
                  </Text>
                </TouchableOpacity>
              )} */}
                  <View style={{ height: 10 }} />
                </View>
              </View>}
          </View>
        )}
        <Modal
          animationType="fade"
          transparent={true}
          visible={this.state.showRejectedModal}
          onRequestClose={() => {
            //alert("Modal has been closed.");
          }}
        >
          <TouchableOpacity
            activeOpacity={0.9}
            onPress={() => {
              this.setState({ showRejectedModal: false });
            }}
            style={{
              flex: 1,
              justifyContent: "center",
              backgroundColor: "rgba(128,0,0,.8)"
            }}
          >
            <View
              style={{
                height: 500,
                alignSelf: "stretch",
                margin: 8,
                marginBottom: 0,
                borderColor: "#888",
                backgroundColor: "#000",
                borderWidth: 0,
                padding: 15,
                borderColor: "#322"
              }}
            >
              <Text
                style={{
                  color: "#844",
                  fontWeight: "bold",
                  fontSize: 24,
                  marginBottom: 20
                }}
              >
                ❌ NOT APPROVED
              </Text>
              {/* <Text style={{ color: "#844" }}>{this.state.data.statusReason}</Text> */}
              <Text
                style={{ color: "#844", fontStyle: "italic", fontSize: 14 }}
              >
                Reason:{" "}
                {this.state.data
                  ? this.state.data.statusReasonText
                  : "Loading Rejection Reason"}
              </Text>
            </View>
          </TouchableOpacity>
        </Modal>
        <Modal
          animationType="fade"
          transparent={true}
          visible={this.state.showDiscountModal}
          onRequestClose={() => {
            //alert("Modal has been closed.");
          }}
        >
          <TouchableOpacity
            activeOpacity={0.9}
            onPress={() => {
              this.setState({ showDiscountModal: false });
            }}
            style={{
              flex: 1,
              justifyContent: "center",
              backgroundColor: "rgba(200,200,200,.8)"
            }}
          >
            <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20 }}>
              <Text style={{ color: "#fff", fontSize: 18, fontWeight: "bold", marginLeft: 20, opacity: 0.7 }}>Your Discount:</Text>
              <View style={{ padding: 10, paddingLeft: 20, paddingRight: 20 }}>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Discount will be shown immediately on your offer</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Discount will apply to anyone buying this item on Wakanda</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Your offer will appear in the discount section of the app</BulletPoint>
              </View>
              <View style={{ marginTop: 25 }}>
                <Text
                  style={{ fontSize: 14, color: "#A8CF45", paddingBottom: 10, justifyContent: "flex-start" }}
                >
                  Your Discount ({global.currencySymbols[(this.state.data?.priceCurrency || "NGN")]}):
            </Text>
                <TextInput
                  onChangeText={discount => { this.setState({ discount }); }}
                  style={{ color: "#ffffff", paddingLeft: 3, borderRadius: 5, height: 36, backgroundColor: "#334" }}
                  underlineColorAndroid="transparent"
                  maxLength={80}
                  defaultValue={this.state.discount}
                  keyboardAppearance={"dark"}
                  keyboardType={"numeric"}
                  returnKeyType={"done"}
                  bordered
                />
                {!this.state.aa ? <TouchableOpacity
                  onPress={() => { this.addDiscount() }}
                >
                  <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, marginTop: 35, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                    <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Add Discount</Text>
                  </View>
                </TouchableOpacity>
                  : <ActivityIndicator size="small" color="#ccc" style={{ alignSelf: "center", paddingVertical: 25 }} />}
              </View>
            </View>
            <View style={{ height: 200 }} />
          </TouchableOpacity>
        </Modal>
      </View>
      {!!this.state.askDelivery && <View style={{ flexDirection: "row", marginLeft: 11, marginBottom: 9 }}>
        <Text style={{ color: "#888", fontWeight: "bold", fontSize: 14, }}>
          Can this be delivered?{"  "}
        </Text>
        <TouchableOpacity onPress={() => { this.addDelivery("yes") }}>
          <Text style={{ color: "#484", fontWeight: "bold", fontSize: 14, }}>
            {"  Yes  "}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => { this.addDelivery("no") }}>
          <Text style={{ color: "#844", fontWeight: "bold", fontSize: 14, }}>
            {"  No  "}
          </Text>
        </TouchableOpacity>
      </View>}
    </View>
    );
  }
}

export default class MePostsScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "My Offers / Samples",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };
  state = {
    postIDs: [],
    hasBusiness: true,
    loading: true
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = {timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    try {
      if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 200); });
      if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 500); });
      if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 1000); });
      if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 2000); });
      if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 2000); });
      if (!global.myUID) await global.setGlobals();
      global.fetchIdToken()

      const action = this.props.navigation.getParam('action', null)
      const category1 = this.props.navigation.getParam('category1', null)
      const category2 = this.props.navigation.getParam('category2', null)
      if (action == "CREATE") {
        await global.timeout(100)
        let route = "newPost"
        if (this.props.navigation.state && this.props.navigation.state.routeName && this.props.navigation.state.routeName.startsWith("home"))
          route = "homeNewPost"
        this.props.navigation.navigate(route, { category1, category2 })
      }

      this.unsubscribeFirestore = await firebase
        .firestore()
        .collection("Profiles")
        .doc(global.myProfileID)
        .onSnapshot(async s => {
          this.updatePosts(s);
        });
      global.loadingSuccessMePosts = true;
    } catch (err) {
      global.loadingSuccessMePosts = false;
      global.warn(err, "MePostsA1")
    }
  }

  // componentWillUpdate() {
  //   if (global.loadingSuccessMePosts == false) {
  //     console.log(
  //       "Messages Screen Update: loadingSuccessChatMain was false before, now trying to reload"
  //     );
  //     this.componentDidMount();
  //   }
  // }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  updatePosts = async profileSnapshot => {
    try {
      if (profileSnapshot.data().profileNameBusiness) {
        let ids = profileSnapshot.data().postIDs;
        this.sortablePostIDs = ids;
        this.setState({ postIDs: ids, loading: false });
      } else {
        this.setState({ hasBusiness: false, loading: false });
      }
    } catch (err) {
      console.log("Error updating posts (probably network)");
    }
  };

  sortablePostIDs = [];
  lastUpdated = {};
  reorder = (id, timestamp) => {
    this.lastUpdated[id] = timestamp;
    this.sortablePostIDs.sort((a, b) => {
      if (!a in this.lastUpdated) {
        this.lastUpdated[a] = 0;
      }
      if (!b in this.lastUpdated) {
        this.lastUpdated[b] = 0;
      }
      return this.lastUpdated[b] - this.lastUpdated[a];
    });
    this.setState({
      postIDs: this.sortablePostIDs
    });
  };

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#131313" }}>
        <StatusBar barStyle="light-content" />

        <View style={{ backgroundColor: "#131313", flex: 1 }}>
          <View
            style={{
              backgroundColor: "#131313",
              borderBottomColor: "#555555",
              borderBottomWidth: 1,
              ...Platform.select({
                ios: {
                  height: 50
                },
                android: {
                  height: 75
                }
              })
            }}
          />
          {/* <SearchBox /> */}
          <ScrollView>
            {this.state.postIDs.map(id => {
              return (
                <PostLine
                  key={id}
                  postID={id}
                  navigation={this.props.navigation}
                  onChange={timestamp => {
                    this.reorder(id, timestamp);
                  }}
                />
              );
            })}
            <View style={{ height: 80 }} />
          </ScrollView>
          {this.state.postIDs.length == 0 && (
            <View
              style={{
                flex: 4,
                alignItems: "center",
                justifyContent: "flex-start"
              }}
            >
              {!this.state.loading ? (
                <View style={{ alignItems: "center" }}>
                  <Image
                    source={require("../../images/icons/Post.png")}
                    style={{ opacity: 0.4, marginBottom: 10 }}
                  />
                  <Text
                    style={{
                      color: "#999",
                      fontSize: 18,
                      fontStyle: "italic",
                      padding: 25,
                      textAlign: "center"
                    }}
                  >
                    {this.state.hasBusiness
                      ? "No " + (global.a.offers || "offers") + " yet"
                      : "Once you open a shop in your profile, you can post " + (global.a.offers || "offers") + " here."}
                  </Text>
                </View>
              ) : (
                  <ActivityIndicator
                    size="large"
                    color="#A8CF45"
                    style={{
                      opacity: 1
                    }}
                    animating={true}
                  />
                )}
            </View>
          )}

          {!!this.state.hasBusiness && (
            <TouchableOpacity
              style={{
                borderWidth: 1,
                borderColor: "rgba(0,0,0,0.2)",
                alignItems: "center",
                justifyContent: "center",
                width: 70,
                position: "absolute",
                bottom: 5,
                right: 15,
                height: 70,
                backgroundColor: "#A8CF45",
                borderRadius: 70
              }}
              onPress={() => {
                let route = "newPost"
                if (this.props.navigation.state && this.props.navigation.state.routeName && this.props.navigation.state.routeName.startsWith("home"))
                  route = "homeNewPost"
                this.props.navigation.navigate(route)
              }}
            >
              <Text
                style={{ fontSize: 36, fontWeight: "200", marginBottom: 2 }}
              >
                +
              </Text>
            </TouchableOpacity>
          )}
          <NavigationEvents
            onDidFocus={async payload => {
              //this.setState({ showNotificationHandler: true });
            }}
          />
        </View>
      </SafeAreaView>
    );
  }
}

class BulletPoint extends PureComponent {
  render() {
    return (
      <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
        <Text style={[this.props.style, { marginRight: 6 }]}>{`\u2022`}</Text>
        <Text style={this.props.style}>{this.props.children}</Text>
      </View>
    );
  }
}
import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  TouchableHighlight,
  TouchableWithoutFeedback,
  ScrollView,
  SectionList,
  SafeAreaView,
  ActivityIndicator,
  Dimensions,
  Platform,
  Image,
  Alert,
  Modal
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";

class Selector extends Component {
  render() {
    let height =
      this.props.text.length < 45
        ? 30
        : 45 + (this.props.text.length - 45) * 0.33;
    let circle = filled => {
      if (filled) {
        return (
          <View
            style={{
              width: 20,
              height: 20,
              borderColor: "#999",
              backgroundColor: "#999",
              borderWidth: 1,
              borderRadius: 10
            }}
          />
        );
      } else {
        return (
          <View
            style={{
              width: 20,
              height: 20,
              borderColor: "#556",
              borderWidth: 1,
              borderRadius: 10
            }}
          />
        );
      }
    };

    return (
      <View
        style={{
          height: height,
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        {circle(this.props.selected)}
        <Text style={{ marginLeft: 10, color: "#999" }}>{this.props.text}</Text>
      </View>
    );
  }
}

export default class NetworkMessageScreen extends Component {
  static navigationOptions = {
    headerTransparent: false,
    title: "Send Network Messages",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45"
    },
    headerStyle: {
      backgroundColor: "#000"
      //height: 15,
    }
  };

  state = {
    actionTarget: "none!",
    number: global.walletType == "ACN" ? "+233" : "+234",
    text: "",
    activityIndicatorAnimating: false
  };

  async componentDidMount() {
    try {

    } catch (err) {
      console.log("Error", err);
    }
  }

  componentWillUnmount() {
    //this.unsubscribeFirestore();
  }

  sendMessage = async () => {
    if (this.state.activityIndicatorAnimating) return;
    if (this.state.text.length < 15) { alert("Text too short"); return }
    if (this.state.actionTarget == "none!") { alert("Select recipients"); return }
    let alertMessage = "Send this message to ALL network users?"
    if (this.state.actionTarget == "number") alertMessage = "Send this message?"
    Alert.alert(
      "Sure?",
      alertMessage,
      [
        { text: "NO", onPress: () => console.log("Cancel Pressed"), style: "cancel" },
        { text: "YES", onPress: () => { this.sendMessageExecute(); } }
      ],
      { cancelable: false }
    );

  }
  sendMessageExecute = async () => {
    try {
      this.setState({ activityIndicatorAnimating: true });
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "sendNetworkMessage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          networkID: this.props.navigation.getParam('networkID'),
          text: this.state.text,
          actionTarget: this.state.actionTarget,
          number: this.state.number,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ activityIndicatorAnimating: false });

      if (r2.msg === "SUCCESS") {
        alert("Success. Sent to " + r2.numberOfUsers + " user(s)");
      } else if (r2.msg === "NO_USER_FOUND") {
        alert("No matching user found");
      } else {
        alert("Backend error. Request failed.");
      }
    } catch (err) {
      alert("Request not successful.");
      console.log(err);
      this.setState({ activityIndicatorAnimating: false });
    }
  }


  render() {

    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#241924",
          paddingHorizontal: 15,
          alignSelf: "stretch",
        }}
      >
        <ScrollView style={{
          alignSelf: "stretch",
        }}>
          <TextInput
            onChangeText={text => {
              this.setState({ text });
            }}
            multiline={true}
            numberOfLines={6}
            style={{
              marginTop: 10,
              marginBottom: 20,
              color: "#ffffff",
              borderColor: "grey",
              borderWidth: 0,
              paddingLeft: 3,
              borderRadius: 5,
              height: 180,
              alignSelf: "stretch",
              backgroundColor: "#000"
            }}
            maxLength={1000}
            underlineColorAndroid="transparent"
            defaultValue={this.state.text}
            rowSpan={5}
            bordered
          />
          <TouchableHighlight
            onPress={() => {
              this.setState({ actionTarget: "all" });
            }}
          >
            <Selector
              selected={this.state.actionTarget == "all"}
              text="All Users"
            />
          </TouchableHighlight>
          <View style={{
            flexDirection: "row",
            alignSelf: "stretch",
            justifyContent: "space-between"
          }}>
            <TouchableHighlight
              onPress={() => {
                this.setState({ actionTarget: "number" });
              }}
            >
              <Selector
                selected={this.state.actionTarget == "number"}
                text="PhoneNumber"
              />
            </TouchableHighlight>
            <TextInput
              onChangeText={number => {
                this.setState({ number });
              }}
              keyboardType="phone-pad"
              style={{
                marginLeft: 10,
                color: "#ffffff",
                borderColor: "grey",
                borderWidth: 0,
                paddingLeft: 3,
                borderRadius: 5,
                alignSelf: "stretch",
                width: "50%",
                backgroundColor: "#000"
              }}
              maxLength={1000}
              underlineColorAndroid="transparent"
              defaultValue={this.state.number}
              bordered
            />
          </View>
          {!this.state.activityIndicatorAnimating ?
            <View style={{ height: 20 }} /> :
            <ActivityIndicator
              size="small"
              color="#FFFFFF"
              animating={true}
            />}
          <TouchableOpacity
            style={global.gStyles.button}
            onPress={this.sendMessage}
          >
            <Text style={global.gStyles.buttontext}>Send</Text>
          </TouchableOpacity>
        </ScrollView>
      </View>

    );
  }
}

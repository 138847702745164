import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  Alert,
  Dimensions,
  Linking,
} from "react-native";
import * as Font from "expo-font";
import { LinearGradient } from "expo-linear-gradient";
import { Feather } from "@expo/vector-icons";
import { SvgCss } from "react-native-svg";
import firebase from "firebase";
import "firebase/firestore";
import Post from "../Post/Post";
import RecommendedOffer from "../Apps/shared/RecommendedOffer";

const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;

export default class OfferResult extends Component {
  static navigationOptions = ({ navigation }) => {
    const title = navigation.getParam("params", { title: "Offers" })?.["title"];

    const hideTitle = navigation.getParam("hideTitle", false);
    return {
      tabBarVisible: false,
      headerTransparent: true,
      title: hideTitle ? "" : title,
      headerTitleStyle: {
        fontWeight: "bold",
        color: "#333333",
        zIndex: 1,
        shadowOpacity: 0.1,
      },
    };
  };

  state = {
    assetsLoaded: false,
    PageOffers: [],
    setTitle: "",
    gradientColor: [],
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => {
      return;
    };
  }

  async componentDidMount() {
    const setTitle = this.props.navigation.getParam("params", {
      title: "Offers",
    })?.["title"];
    let PageOffers = this.props.navigation.getParam("params")[
      "offerCategories"
    ];
    let gradientColor = this.props.navigation.getParam("params")["screenColor"];

    if (setTitle == "RECOMMENDED OFFERS!!") {
      const recommendationPostIDs = await global.getRecommendationsAsync(110);
      if (
        Array.isArray(recommendationPostIDs) &&
        recommendationPostIDs.length > 0
      )
        PageOffers = recommendationPostIDs;
    }

    await this.setState({
      PageOffers,
      setTitle,
      gradientColor,
    });
    //console.log(" discountoffers", this.state.PageOffers);
    await Font.loadAsync({
      "ProximaNovaA-Bold": require("../../../assets/font/ProximaNovaA-Bold.ttf"),
      "ProximaNovaA-Regular": require("../../../assets/font/ProximaNovaA-Regular.ttf"),
    });
    this.setState({ assetsLoaded: true });
  }

  componentWillUnmount() { }

  l;
  render() {
    //console.log("page title", this.state.setTitle);
    //console.log("page offer", this.state.PageOffers);
    if (this.state.assetsLoaded) {
      return (
        <ScrollView>
          <LinearGradient
            colors={
              this.state.gradientColor === []
                ? [
                  "#AF2ABA",
                  "#F5F5F5",
                  "#F5F5F5",
                  "#F5F5F5",
                  "#F5F5F5",
                  "#F5F5F5",
                  "#F5F5F5",
                  "#F5F5F5",
                  "#F5F5F5",
                ]
                : this.state.gradientColor
            }
            style={{
              flex: 1,
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              height: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
          <StatusBar barStyle="light-content" />
          <View
            style={{
              backgroundColor: "F5F5F5",
              borderBottomColor: "#555",
              alignItems: "center",
              justifyContent: "center",

              // borderBottomWidth: 1,
              ...Platform.select({
                ios: {
                  height: Dimensions.get("window").height >= 800 ? 88 : 65,
                },
                android: { height: 85 },
              }),
            }}
          />
          <View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-around",
                alignItems: "center",
                margin: 5,
                padding: 5,
              }}
            >
              {this.state.setTitle === "RECOMMENDED OFFERS!!" &&
                this.state.PageOffers &&
                this.state.PageOffers.map((item, i) => {
                  return (
                    <RecommendedOffer
                      key={i}
                      postID={item}
                      colors={["#F5F5F5", "#F5F5F5", "#6Ec456"]}
                      navigation={this.props.navigation}
                    />
                  );
                })}

              {this.state.setTitle ==
                (global.salesString || "Holiday Sales!!") &&
                this.state.PageOffers &&
                <SmallOffers offers={this.state.PageOffers} navigation={this.props.navigation} />
              }
            </View>
          </View>
        </ScrollView>
      );
    } else {
      return (
        <View style={styles.container}>
          <ActivityIndicator />
          <StatusBar barStyle="default" />
        </View>
      );
    }
  }
}

export class SmallOffers extends PureComponent {

  state = {
    showDetail: false,
    detailPostResult: null,
  }

  render() {
    return (
      <View style={{ width: global.SWIDTH, flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
        {this.props.offers.map((item, i) => {
          let oldPrice = item.postPrice;
          let percentageOff = Math.round(
            (item.postPriceDiscount / oldPrice) * 100
          ).toString();

          const result = item;
          let pString = Math.round(
            result.postPrice - item.postPriceDiscount
          ).toString();
          if (pString.length > 8) {
            pString = pString.slice(0, pString.length - 6) + "M";
          } else if (pString.length > 6) {
            pString = Number(pString.slice(0, pString.length - 5)) / 10;
            pString = pString.toString() + "M";
          } else if (pString.length > 4) {
            pString = pString.slice(0, pString.length - 3) + "K";
          }
          // new  discount price ends

          // old  discount price starts

          let oldpString = Math.round(oldPrice).toString();
          if (oldpString.length > 8) {
            oldpString =
              oldpString.slice(0, oldpString.length - 6) + "M";
          } else if (oldpString.length > 6) {
            oldpString =
              Number(oldpString.slice(0, oldpString.length - 5)) / 10;
            oldpString = oldpString.toString() + "M";
          } else if (oldpString.length > 4) {
            oldpString =
              oldpString.slice(0, oldpString.length - 3) + "K";
          }
          // old  discount price ends
          let pCurrency = result.postPriceCurrency || "NGN";
          let pCurrencyString = global.currencySymbols[pCurrency];

          let priceString = pCurrencyString + " " + pString;
          let oldpriceString = pCurrencyString + " " + oldpString;

          //localString is acnString
          let localString = "";
          let oldLocalString = "";

          // xx possibly remove NGN from last section of the following line  to show less
          // xx possibly replace last section of the following line with global.xRates[post.postPriceCurrency||"NGN"] to show ACN for all (also old) NGN posts
          if (
            global.walletType == "ACN" &&
            !(result.postPriceCurrency == "ACN") &&
            result.postPriceType != "Free" &&
            result.postPrice > 0 &&
            global.xRates[result.postPriceCurrency || "NGN"]
          ) {
            const lPrice = Math.round(
              result.postPrice /
              global.xRates[result.postPriceCurrency || "NGN"]
            );
            const oldlPrice = Math.round(
              oldPrice /
              global.xRates[result.postPriceCurrency || "NGN"]
            );
            const lCurrency = global.currencySymbols["ACN"];
            let lString = Math.round(lPrice).toLocaleString(0);
            let oldlString = Math.round(oldlPrice).toLocaleString(0);
            localString = "≈ " + lCurrency + " " + lString;
            oldLocalString = "≈ " + lCurrency + " " + oldlString;
          }
          let beforeString = "";
          let afterString = "";
          if (
            result.postPriceType &&
            result.postPriceType != "Fixed Price"
          ) {
            if (result.postPriceType == "Starting From") {
              beforeString = "Starting From";
            } else {
              afterString = "(" + result.postPriceType + ")";
            }
            if (result.postPriceType == "Free") {
              oldpriceString = "FREE";
              priceString = "FREE";
              localString = "";
              afterString = "";
            }
            if (result.postPriceType == "NONE") {
              priceString = "";
              oldpriceString = "";
              localString = "";
              afterString = "";
            }
          }

          return (
            <TouchableOpacity
              key={item.xID}
              style={{
                backgroundColor: "#E5E7E9",
                minHeight: 150,
                width: "30%",
                borderRadius: 12,
                margin: 5,
                marginBottom: 10,
              }}
              onPress={() => {
                this.setState({
                  showDetail: true,
                  detailPostResult: item,
                });
              }}
            >
              <View>
                {(percentageOff > 0) && <View
                  style={{
                    backgroundColor: "red",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 5,
                    width: "32%",
                    position: "absolute",
                    padding: 2,

                    zIndex: 1,
                  }}
                >
                  <Text
                    style={{
                      color: "white",
                      fontSize: 12,
                      fontFamily: "ProximaNovaA-Bold",
                    }}
                  >
                    {percentageOff + "%"}
                  </Text>
                </View>}
                <Image
                  style={{
                    width: "100%",
                    height: 110,
                    borderRadius: 12,
                  }}
                  source={{
                    uri: item.postPictureURL,
                  }}
                  resizeMode="cover"
                />
              </View>

              <View
                style={{
                  marginHorizontal: 10,
                  paddingTop: 3,
                  marginTop: 2,
                }}
              >
                <Text
                  style={{
                    fontFamily: "ProximaNovaA-Bold",
                    fontSize: 10,
                  }}
                >
                  {item.postTitle}
                </Text>
              </View>
              {percentageOff > 0 ? <View
                style={{
                  flexDirection: "row",

                  width: "100%",
                  paddingVertical: 3,
                  marginVertical: 3,
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "ProximaNovaA-Bold",
                    fontSize: 12,
                    color: "red",
                  }}
                >
                  {priceString}
                </Text>
                <Text
                  style={{
                    fontFamily: "ProximaNovaA-Regular",
                    fontSize: 12,

                    textDecorationLine: "line-through",
                  }}
                >
                  {oldpriceString}
                </Text>
              </View>
                :
                <View
                  style={{
                    flexDirection: "row",

                    width: "100%",
                    paddingVertical: 3,
                    marginVertical: 3,
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "ProximaNovaA-Regular",
                      fontSize: 12,
                    }}
                  >
                    {oldpriceString}
                  </Text>
                  <View style={{ width: 12 }} />
                </View>}
            </TouchableOpacity>
          );
        })}

        {this.state.showDetail && (
          <Post
            postID={this.state.detailPostResult.xID}
            data={this.state.detailPostResult}
            //data={this.props.data}
            key={this.state.detailPostResult.xID + this.state.showDetail}
            navigation={this.props.navigation}
            showDetail={this.state.showDetail}
            onHideDetail={() => {
              this.setState({ showDetail: false });
            }}
            relevantStatus={"LIVE"}
            blackBackground={true}
            onIsNotRelevant={() => {
              alert("Offer not available at the moment");
            }}
          />
        )}
      </View>
    )
  }
}
import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TextInput,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  Linking,
  Alert,
  Dimensions,
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import { SvgCss } from "react-native-svg";
import { Feather } from "@expo/vector-icons";
import BlogPreview from '../Apps/shared/BlogPreview'

import BlogPostPreview from "./shared/BlogPostPreview";
import { Touchable } from "react-native";
const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;

export default class HomeBlogScreen extends Component {

  static navigationOptions = ({ navigation }) => {
    const blogTitle = navigation.getParam("blogTitle");
    console.log('blogTitle :', blogTitle)
    return {
      tabBarVisible: false,
      headerTransparent: true,
      title: blogTitle,
      headerTitleStyle: {
        fontWeight: "bold",
        color: "white",
        shadowOpacity: 1,
      },
    };
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => {
      return;
    };
    this.unsubscribeFirestore2 = () => {
      return;
    };
  }
  state = {
    blogData: {},
    blogPost: [],
    loadingBlog: true,
    loadingPosts: true,

    justChangedSubscription: null,
    justChangedNotifications: null,
    aaSubscribe: false
  };

  async componentDidMount() {
    console.log("param", this.props.navigation.getParam("blogTitle"))
    try {
      if (!this.props.navigation.getParam("blogID", null)) return alert("Oops - something went wrong")
      await global.fetchIdToken()
      const r3 = await fetch(global.cloudFunctionURL + "blog", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isWeb:true,
          action: "getBlog",
          // profileID: global.myProfileID,
          myUID: global.myUID,
          blogID: this.props.navigation.getParam("blogID", null),
          idToken: global.idToken,
        }),
      });
      const r4 = await r3.json();
      //console.log(r4)
      this.setState({ loadingBlog: false })

      await this.setState({
        blogData: r4.response.blog,
        justChangedNotifications: null,
        justChangedSubscription: null
      });
      // console.log("blogData", this.state.blogData);

      const r = await fetch(global.cloudFunctionURL + "findGroups", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isWeb:true,
          isWeb: true,
          type: "groupIDs",
          groupIDs: this.state.blogData.chatGroupIDs,
          myUID: global.myUID,
          idToken: global.idToken,
        }),
      });
      const r2 = await r.json();
      this.setState({ loadingPosts: false })

      await this.setState({
        blogPost: r2.groups,
      });
      //console.log("response", this.state.blogPost);
    } catch (error) {
      global.warn?.(error, "blogMount");
    }
  }
  componentWillUnmount() { }

  manageSubscription = async (action) => {
    if (this.state.aaSubscribe) return
    this.setState({ aaSubscribe: true })
    const oldJCN = this.state.justChangedNotifications
    const oldJCS = this.state.justChangedSubscription
    if (action == "subscribe") this.setState({ justChangedSubscription: "subscribed", justChangedNotifications: "on" })
    if (action == "unsubscribe") this.setState({ justChangedSubscription: "unsubscribed", justChangedNotifications: "off" })
    if (action == "enableNotifications") this.setState({ justChangedNotifications: "on" })
    if (action == "disableNotifications") this.setState({ justChangedNotifications: "off" })
    if (!this.props.navigation.getParam("blogID", null)) return alert("Oops - something went wrong")
    try {
      const r = await fetch(global.cloudFunctionURL + "blog", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isWeb:true,
          action,
          blogID: this.props.navigation.getParam("blogID", null),
          myUID: global.myUID,
          idToken: global.idToken,
        }),
      });
      const r2 = await r.json();
      this.setState({ aaSubscribe: false })
      if (r2.msg !== "SUCCESS") {
        alert("Something went wrong - try again")
        this.setState({ justChangedSubscription: oldJCS, justChangedNotifications: oldJCN })
      }
    } catch (e) {
      global.warn(e, "manageBlogSubscription")
      this.setState({ aaSubscribe: false })
    }
  }

  render() {
    if (this.state.loadingBlog) return (
      <View
        style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "black" }}
      >
        <ActivityIndicator
          size="large"
          color="#707477"
        />
        <Text style={{ color: "#707477", marginTop: 12 }}>Loading Blog...</Text>
      </View >
    );
    const subscribed = this.state.justChangedSubscription == "subscribed" ? true
      : this.state.justChangedSubscription == "unsubscribed" ? false
        : this.state.blogData?.xAmSubscriber ? true : false
    const notificationsOn = this.state.justChangedNotifications == "on" ? true
      : this.state.justChangedNotifications == "off" ? false
        : this.state.blogData?.xAmNotified ? true : false
    return (
      <SafeAreaView
        style={{
          // flex: 1,
          // backgroundColor: "#282828",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          width: SCREEN_WIDTH,
          backgroundColor: "#000",
        }}
      >
        <View
          style={{
            backgroundColor: "#282828",
            borderBottomColor: "#555",
            borderBottomWidth: 1,
            ...Platform.select({
              ios: { height: 55 },
              android: { height: 75 },
            }),
          }}
        />
        <ScrollView>
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              width: SCREEN_WIDTH,
            }}
          >
            <Image
              style={{
                width: SCREEN_WIDTH * 0.9,
                height: 150,
                paddingRight: 8,
                borderRadius: 8.5,
              }}
              source={{
                uri: this.state.blogData.blogPictureURL,
              }}
            />
            {/* <TouchableOpacity
              style={{
                borderRadius: 14,
                paddingLeft: 6,
                height: 38,
                justifyContent: "center",
                alignItems: "center",
                width: 38,
                bottom: 75,
              }}
            >
              <Text style={{ color: "#333", fontSize: 28 }}>{"▶️"}</Text>
            </TouchableOpacity> */}

            <View style={{ margin: 10, padding: 10 }}>
              {subscribed && <View style={{
                paddingVertical: 5, justifyContent: "flex-end",
                alignItems: "center", flexDirection: "row"
              }}>
                {notificationsOn ?
                  <TouchableOpacity
                    onPress={() => {
                      Alert.alert(
                        "Turn Notifications off?", "",
                        [{ text: "Cancel", onPress: () => console.log("Cancel Pressed"), style: "cancel" },
                        { text: "Yes", onPress: () => { this.manageSubscription('disableNotifications'); } }],
                        { cancelable: false });
                    }}>
                    <Image
                      style={{
                        width: 18,
                        height: 18,
                        marginVertical: 3,
                        marginRight: 13,
                        marginLeft: 13,
                        tintColor: "#909497"
                      }}
                      source={require("../../images/icons/NotificationsOn.png")}
                    />
                  </TouchableOpacity>
                  : <TouchableOpacity
                    onPress={() => {
                      Alert.alert(
                        "Turn Notifications on?", "",
                        [{ text: "Cancel", onPress: () => console.log("Cancel Pressed"), style: "cancel" },
                        { text: "Yes", onPress: () => { this.manageSubscription('enableNotifications'); } }],
                        { cancelable: false });
                    }}>
                    <Image
                      style={{
                        width: 18,
                        height: 18,
                        marginVertical: 3,
                        marginRight: 13,
                        marginLeft: 13,
                        tintColor: "#909497"
                      }}
                      source={require("../../images/icons/NotificationsOff.png")}
                    />
                  </TouchableOpacity>}
                <TouchableOpacity
                  onPress={() => {
                    Alert.alert(
                      "Unsubscribe from " + (this.state.blogData.blogTitle || "this blog") + "?", "",
                      [{ text: "Cancel", onPress: () => console.log("Cancel Pressed"), style: "cancel" },
                      { text: "OK", onPress: () => { this.manageSubscription('unsubscribe'); } }],
                      { cancelable: false });
                  }}>
                  <Text
                    style={{
                      color: "#909497",
                      fontFamily: "ProximaNovaA-Regular",
                      fontSize: 14,
                      marginVertical: 5,
                      marginRight: 3
                    }}
                  >
                    SUBSCRIBED
                  </Text>
                </TouchableOpacity>
              </View>}
              <Text
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontFamily: "ProximaNovaA-Bold",
                  fontSize: 20,
                  marginVertical: 5,
                }}
              >
                {this.state.blogData.blogTitle}
              </Text>

              {this.state.blogData?.nOfSubscribers >= 5 && <Text
                style={{
                  color: "#909497",
                  marginVertical: 5,
                  fontFamily: "ProximaNovaA-Regular",
                  fontSize: 10,
                }}
              >
                {this.state.blogData.nOfSubscribers} Subscribers
              </Text>}
              <Text
                style={{
                  color: "#909497",
                  marginVertical: 4,
                  fontFamily: "ProximaNovaA-Regular",
                  fontSize: 14,
                }}
              >
                {this.state.blogData.blogDescription}
              </Text>
            </View>
            {!subscribed && <TouchableOpacity
              onPress={() => { this.manageSubscription("subscribe") }}
              style={{
                width: SCREEN_WIDTH * 0.9,
                height: 50,
                backgroundColor: "#A9D046",
                borderRadius: 12,
                padding: 10,

                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  fontFamily: "ProximaNovaA-Bold",
                  fontSize: 15,
                }}
              >
                SUBSCRIBE
              </Text>
            </TouchableOpacity>}
            {this.state.loadingPosts && (
              <ActivityIndicator
                color={"white"}
              />
            )}

            {this.state.blogPost &&
              this.state.blogPost.map((item) => {
                return (
                  <TouchableOpacity
                    style={{
                      width: SCREEN_WIDTH,

                      margin: 10,
                      // borderRadius: 12,
                      // padding: 15,
                      // alignItems: "center",
                    }}
                    key={item.xID}
                    onPress={() => {
                      global.navigateChatGroup(this.props.navigation, {
                        group: item,
                        isBlogPost: true,
                        blog: this.state.blogData || {}
                      })
                    }}
                  >
                    <BlogPostPreview
                      post={item}
                      navigation={this.props.navigation}
                    />
                  </TouchableOpacity>
                );
              })}
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

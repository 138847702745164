import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  SafeAreaView,
  Platform,
  Dimensions,
  Alert,
  Linking
} from "react-native";
//import Constants from 'expo-constants';
import firebase from "firebase";
import "firebase/firestore";
import CachedImage from "../../shared/CachedImage";
//import DraggableView from "../../Home/Post/DraggableView";
const gStyles = global.gStyles //    "../../styles/gStyles";

class Contact extends Component {
  state = {
    contactProperties: {
      opponentProfileID: null,
      opponentName: "********",
      opponentNameBusiness: "************",
      opponentProfilePictureURL:
        ""
    },
    activityIndicatorAnimating: false
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  componentDidMount() {

    this.unsubscribeFirestore = firebase
      .firestore()
      .collection("Profiles")
      .doc(this.props.contactID)
      .onSnapshot(this.updateContact);
  }

  updateContact = async contactSnapshot => {
    console.log("Update of Referrer contact");
    try {
      let contact = contactSnapshot.data();

      let opponentProfileID = contactSnapshot.id;
      let opponentName = contact.profileName;
      let opponentProfilePictureURL = contact.profilePictureURL;
      let opponentNameBusiness = contact.profileNameBusiness;
      if (!contact.profileNameBusiness) {
        opponentNameBusiness = "";
      }

      let contactProperties = {
        opponentProfileID,
        opponentName,
        opponentNameBusiness,
        opponentProfilePictureURL
      };

      console.log("contactProperties", contactProperties);
      this.setState({
        contactProperties
      });
    } catch (err) {
      console.log(err);
    }
  };

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  removeReferrer = async () => {
    Alert.alert(
      "Remove Referrer?",
      "Do you want to permanently remove your referrer?",
      [
        {
          text: "Cancel",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel"
        },
        {
          text: "Yes",
          onPress: () => {
            this.executeRemoveReferrer();
          }
        }
      ],
      { cancelable: true }
    );
  }
  executeRemoveReferrer = async () => {
    this.setState({ activityIndicatorAnimating: true });
    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "addReferrer", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "remove",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ activityIndicatorAnimating: false });
      if (r2.msg === "REMOVE_SUCCESS") {
        alert("Referrer removed")
        await global.timeout(100)
        this.props.removeReferrer()
      } else {
        alert("Request failed");
      }
    } catch (error) {
      this.setState({ activityIndicatorAnimating: false });
      console.log(error);
    }
  }

  render() {
    return (
      <TouchableOpacity
        onPress={() => {
          this.props.navigation.navigate("meProfile", {
            profileID: this.props.contactID
          })
        }}
        onLongPress={() => {
          this.removeReferrer()
        }}
      >
        <View
          style={[
            {
              flexDirection: "row",
              height: 55,
              backgroundColor: "#222",
              alignItems: "flex-start",
              paddingHorizontal: 11,
              paddingVertical: 5,
              shadowOpacity: 0.5,
              shadowOffset: { width: 0, height: 3 },
              borderBottomColor: "#333",
              borderColor: "#333",
              borderWidth: 1,
              width: Dimensions.get("window").width - 50,
              marginVertical: 10
            }
          ]}
        >
          {this.state.contactProperties.opponentProfilePictureURL ? <Image
            style={{
              width: 42,
              height: 42,
              paddingRight: 30,
              borderRadius: 4.5,
              opacity: .8
            }}
            source={{
              uri: this.state.contactProperties.opponentProfilePictureURL
            }}
          /> : <View
              style={{
                width: 42,
                height: 42,
                paddingRight: 30,
                borderRadius: 4.5, backgroundColor: "#777"
              }}
            />}
          <View style={{ flexDirection: "row", flex: 1, alignItems: "center" }}>
            <View
              style={{
                flexDirection: "column",
                flex: 1,
                paddingHorizontal: 8
              }}
            >
              <Text style={{ fontSize: 14, color: "#ccc" }}>
                {this.state.contactProperties.opponentName}
              </Text>
              {this.state.contactProperties.opponentNameBusiness != "" && (
                <Text style={{ fontSize: 11, color: "#ccc" }}>
                  {this.state.contactProperties.opponentNameBusiness}
                </Text>
              )}
            </View>
            {!!this.state.activityIndicatorAnimating && (
              <ActivityIndicator
                size="small"
                color="#FFFFFF"
                animating={true}
                style={{ marginLeft: 10 }}
              />
            )}
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

export default class MeHelpScreen extends Component {
  // static navigationOptions = {
  //   headerTransparent: true
  // };
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Help / Feedback",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45"
    }
  };

  state = {
    activityIndicatorAnimating: false,
    devMenu: false,
    amAdmin: false,
    referrerProfileID: "LOADING"
  };

  componentDidMount = async () => {
    //console.log("navigation prop ", this.props.navigation);
    let userDoc = await firebase
      .firestore()
      .collection("Users")
      .doc(global.myUID)
      .get();

    this.setState({
      amAdmin: userDoc.data().isAdmin === true ? true : false,
      referrerProfileID: userDoc.data().referrerProfileID || "OPEN"
    });
  };

  callFeedback = async () => {
    if (!this.state.activityIndicatorAnimating) {
      let chatID = "";
      let chatProperties = {
        opponentProfileID: "F4C7tJCPqxdpawvBpd2B",
        opponentName: "Customer Care",
        opponentNameBusiness: global.app == "artisan" ? global.a.appName : "Wakanda",
        opponentProfilePictureURL:
          "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2Fw%20logo%20512X512b.png?alt=media&token=ee27b431-c5bc-42fe-9610-923efb58bce6"
      };

      //create a new chat or get existing chat ID
      this.setState({ activityIndicatorAnimating: true });
      try {
        const r = await fetch(global.cloudFunctionURL + "newChat", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            profileID1: global.myProfileID,
            profileID2: "F4C7tJCPqxdpawvBpd2B",
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
        const r2 = await r.json();
        chatID = r2.chatID;
        this.setState({ activityIndicatorAnimating: false });
      } catch (error) {
        this.setState({ activityIndicatorAnimating: false });
        console.log(error);
      }

      // navigate to chatID
      this.props.navigation.goBack();
      this.props.navigation.navigate("chatMain", {
        redirect: true,
        redirectParams: {
          chatID: chatID,
          chatProperties: chatProperties
        }
      });
    }
  };

  renderReferrer = () => {
    if (this.state.referrerProfileID == "NONE") return <View />

    if (this.state.referrerProfileID == "LOADING") return (<View>
      <Text style={{ color: "#555", textAlign: "center" }}>
        LOADING...
      </Text>
    </View>)

    if (this.state.referrerProfileID == "OPEN") return (<TouchableOpacity
      onPress={
        () => {
          this.props.navigation.navigate("meAddReferrer")
        }
      }
    >
      <Text style={{
        fontSize: 18, color: "#777", marginTop: 5
      }}
      >Add your Referrer</Text>
    </TouchableOpacity>)

    return (<View style={{}}>
      <Text style={{
        fontSize: 12, color: "#888", fontWeight: "bold"
      }}>MY REFERRER
    </Text>
      <Contact
        contactID={this.state.referrerProfileID}
        navigation={this.props.navigation}
        removeReferrer={() => {
          console.log("Removing referrer");
          this.setState({ referrerProfileID: "NONE" })
        }}
      />
    </View>)
  }

  render() {
    console.log(this.state.referrerProfileID)
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#131313" }}>
        <View
          style={{
            flex: 1,
            backgroundColor: "#131313",
            justifyContent: "space-between"
          }}
        >
          <View style={{ backgroundColor: "#131313" }}>
            <View
              style={{
                ...Platform.select({
                  ios: {
                    height: 42
                  },
                  android: {
                    height: 70
                  }
                }),
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 8,
                borderBottomColor: "#444",
                borderBottomWidth: 1
              }}
            >
              {/* <Text style={{ color: "#00A859", fontSize: 24 }}>
                Help / Feedback
              </Text> */}
            </View>
            {/* <DraggableView><View style={{width:20, height:20, backgroundColor:"red" }}/></DraggableView> */}
            <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => {
                this.callFeedback();
              }}
            >
              <Text style={global.gStyles.meText}>Customer Care / Feedback</Text>
              {!!this.state.activityIndicatorAnimating && (
                <ActivityIndicator
                  size="small"
                  color="#FFFFFF"
                  animating={true}
                  style={{ marginLeft: 10 }}
                />
              )}
            </TouchableOpacity>
            <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => {
                Linking.openURL(
                  global.termsConditionsLink || "https://www.african-founders.com/terms-and-conditions"
                );
              }}
            >
              <Text style={global.gStyles.meText}>
                Terms and Conditions
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => {
                Linking.openURL(
                  global.privacyPolicyLink || "https://www.african-founders.com/privacy-policy"
                );
              }}
            >
              <Text style={global.gStyles.meText}>
                Privacy Policy
              </Text>
            </TouchableOpacity>
            {!!this.state.amAdmin && <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => {
                this.props.navigation.navigate("meAdmin");
              }}
            >
              <Text style={global.gStyles.meText}>Admin</Text>
            </TouchableOpacity>}
            <View style={{ height: 20 }} />
            <TouchableOpacity
              style={[global.gStyles.me, { height: 120 }]}
              onPress={() => Linking.openURL("https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FWakanda%20Guide2.pdf?alt=media&token=7a32b6b0-b760-4f0e-ad61-71e49574453f")}
            >
              <View>
                <Text style={{ color: "#ccc", fontSize: 18, fontWeight: "bold", marginLeft: 0 }}>PDF Guide</Text>
                <View style={{ padding: 5, paddingLeft: 10, paddingRight: 20, flexDirection: "row" }}>
                  <CachedImage
                    style={{ width: 111, height: 61, margin: 5, resizeMode: "contain", borderRadius: 5, borderWidth: 0, borderColor: "#aaa" }}
                    source={{ uri: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FWG%20Thumb2.jpg?alt=media&token=ecfdaee9-f2b9-497d-b56c-db689eefe535" }}
                  />
                  {/* <CachedImage
                    style={{ width: 111, height: 61, margin: 5, resizeMode: "contain", borderRadius: 5, borderWidth: 0.5, borderColor: "#aaa" }}
                    source={{ uri: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FWG%20Thumb.jpg?alt=media&token=e89a75d2-f658-493e-af29-349b0780b125" }}
                  /> */}
                </View>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={[global.gStyles.me, { height: 120 }]}
              onPress={() => Linking.openURL("https://www.youtube.com/channel/UCGkQ187DmGAmA63VyyAACAA")}
            >
              <View>
                <View>
                  <Text style={{ color: "#ccc", fontSize: 18, fontWeight: "bold", marginLeft: 0 }}>Video Tutorials</Text>
                  <View style={{ padding: 5, paddingLeft: 10, paddingRight: 20, flexDirection: "row" }}>
                    <CachedImage
                      style={{ width: 111, height: 61, margin: 5, resizeMode: "contain" }}
                      source={{ uri: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FVid.jpg?alt=media&token=adf85ac0-7fff-445e-b543-95ad03eebff6" }}
                    />
                    {/* <CachedImage
                      style={{ width: 111, height: 61, margin: 5, resizeMode: "contain" }}
                      source={{ uri: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FVid3.jpg?alt=media&token=adf22127-ce61-465a-84e5-907c817dc7bd" }}
                    /> */}
                    {/* <Text style={{ fontSize: 14, marginBottom: 5, color: "#caa" }}>
                      Video Tutorials and more</Text>
                    <Text style={{ fontSize: 14, marginBottom: 5, color: "#caa" }}>
                      for Wakanda Sellers</Text> */}
                  </View>
                </View>
              </View>
            </TouchableOpacity>

            {/* <View style={{ backgroundColor: "#ccc", height: 64, width: 64, margin: 10, borderRadius: 32, justifyContent: "center", alignItems: "center" }}>
              <Text style={{ fontSize: 48, color: "#222224" }}>?</Text>
            </View> */}

            {/* {!!global.devMode && (
              <TouchableOpacity
                style={{
                  height: this.state.devMenu ? 0 : xHeight,
                  backgroundColor: "#222",
                  alignItems: "center",
                  justifyContent: "center",
                  borderBottomColor: "#444",
                  borderTopColor: "#444",
                  borderBottomWidth: 1,
                  borderTopWidth: 1
                }}
                onPress={() => {
                  this.setState({ devMenu: true });
                }}
              >
                <Text style={{ color: "#777", fontSize: 24 }}>Dev Menu</Text>
              </TouchableOpacity>
            )} */}
            {/* {!!this.state.devMenu && (
              <View
                style={{
                  height: 180,
                  backgroundColor: "#101",
                  alignItems: "center",
                  justifyContent: "center",
                  borderBottomColor: "#222",
                  borderTopColor: "#222",
                  borderBottomWidth: 1,
                  borderTopWidth: 1
                }}
              >
                <Text style={{ color: "#aaa", fontSize: 14 }}>
                  {`${Platform.OS} version: ${Platform.Version} `}
                </Text>
                <Text style={{ color: "#aaa", fontSize: 14 }}>
                  {`H / W Dimensions: ${Dimensions.get("window").height} / ${
                    Dimensions.get("window").width
                    } `}
                </Text>
                <Text style={{ color: "#aaa", fontSize: 14 }}>
                  {`deviceName: ${Constants.deviceName}, deviceYearClass ${
                    Constants.deviceYearClass
                    } `}
                </Text>
                <Text style={{ color: "#aaa", fontSize: 14 }}>
                  {`installationId: ${Constants.installationId}`}
                </Text>
              </View>
            )} */}
          </View >
          <View
            style={{
              height: 103,
              margin: 10,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {this.renderReferrer()}
          </View>
        </View >
      </SafeAreaView >
    );
  }
}

import React, { Component } from "react";
import {
  Text,
  View,
  Button,
  Image,
  TextInput,
  TouchableWithoutFeedback,
  SafeAreaView,
  TouchableOpacity,
  Keyboard,
  Modal,
  SectionList,
  Linking,
  ActivityIndicator
} from "react-native";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import initiateGlobalFunctions from "../../shared/globalFunctions";


import * as Permissions from "expo-permissions";
import * as Location from "expo-location";
import firebase from "firebase";
import "firebase/firestore";
const gStyles = global.gStyles //    "../../styles/gStyles";
import Group from "../../Messages/Groups/Group";
import CommunityFeed from "./CommunityFeed";
import FeedFeed from "./FeedFeed";
import ChatGroup from '../../Messages/Groups/ChatGroupDetailScreen'
//import { AntDesign } from "@expo/vector-icons";
import "firebase/firestore";
import { ScrollView } from "react-native-gesture-handler";
import * as Find from "../../Home/Find/findFunctions"
import CachedImage from "../../shared/CachedImage"
import { Dimensions } from "react-native";
//import { SearchBar } from "../../shared/SelectContactScreen"




class SearchBar extends Component {
  state = {
    searchTerm: "",
    activityIndicatorAnimating: false,
    noCommunityFound: false,
    resultID: 0,
    resultProfiles: [],
    //countryCode: global.walletType == "ACN" ? "+233" : "+234",
    modalVisible: false,
    searchedCommunities: [],

  };


  /* search = async (term) => {
    let searchTerm = term.toLowerCase()
    this.setState({ searchTerm, text: term })
  } */



  callSearch = async () => {
    let submissionSearchTerm = "";
    let searchLetters = this.state.searchTerm.replace(/[^a-zA-Z0-9_]/g, "")

    if (searchLetters.length > 0) { //is a business name
      submissionSearchTerm = this.state.searchTerm

    } else { //is a number
      let enteredNumber = this.state.searchTerm.replace(/[^\d]/g, "");
      // if (enteredNumber.startsWith("00")) {
      //   alert("Invalid phone number"); return;
      // }
      // if (enteredNumber.startsWith("0")) {
      //   enteredNumber = enteredNumber.slice(1);
      // }
      // submissionSearchTerm = this.state.countryCode + enteredNumber;
    }

    try {
      this.setState({ activityIndicatorAnimating: true });
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "findCommunity", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          isWeb: true,
          textSearch: submissionSearchTerm,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ activityIndicatorAnimating: false });
      Keyboard.dismiss();
      if (r2.msg === "NO_COMMUNITY_FOUND") {
        this.setState({ noCommunityFound: true });
        return;
      }
      if (r2.msg === "COMMUNITY_FOUND") {
        this.setState({ resultID: r2.contactID, resultType: "singleContact", noCommunityFound: false });
        return;
      }
      if (r2.msg === "COMMUNITIES_FOUND") {
        this.setState({ resultProfiles: [], resultType: "multipleContacts", noCommunityFound: false });
        await global.timeout(50)
        this.setState({ resultProfiles: r2.contacts, resultType: "multipleContacts", noCommunityFound: false });
        return;
      }
      alert("Error: " + r2.msg);
    } catch (e2) {
      alert("An error occurred. Check internet connection.");
      console.log(e2);
      this.setState({ activityIndicatorAnimating: false });
    }




  };

  searchedCommunities = async (term) => {
    term = this.state.text
    try {
      let communities = await Find.findCommunitiesAsync({
        amount: 4,
        searchTerm: term,
        location: {
          coords: {
            latitude: 6.6, longitude: 3.3
          }
        }
      });
      this.setState({ loading: false })
      if (communities !== -1) {
        this.setState({ searchedCommunities: communities })
      }
    }
    catch (error) {
      console.log("Error is ", error)
    }
  }

  renderBlueSearchBar() {
    return (
      <View
        style={{
          borderBottomWidth: 1,
          height: 50,
          borderColor: "#333"
        }}
      >
        <View
          style={{
            flexDirection: "row",
            marginTop: 0,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <TextInput
            style={{
              alignSelf: "stretch", height: 30, margin: 10, color: "#eee", backgroundColor: "#223", borderRadius: 15, borderBottomWidth: 0, paddingLeft: 10, flex: 1
            }}
            placeholder="Search communities"
            placeholderTextColor="#eee"
            textAlign="center"
            underlineColorAndroid="transparent"
            underlineColor={"transparent"}
            //onChangeText={this.searchedCommunities}
            onChangeText={term => this.searchedCommunities(term)}
            ref={component => (this.myTextInputBlue = component)}
            // clearButtonMode="always" //xx how set colour?
            value={this.state.text}
            keyboardShouldPersistTaps={'always'}
            keyboardDismissMode={'on-drag'}
            keyboardAppearance={"dark"}
            returnKeyType={"done"}
            onSubmitEditing={() => { Keyboard.dismiss() }}
            autoCompleteType={'off'}
            autoCapitalize={'none'}
            autoCorrect={false}
            onFocus={() => {
              this.props.onFocusSearchCommunities();
              // if (Platform.OS == "android") this.myTextInputBlue.focus()
            }}
          />
          <TouchableOpacity style={{ marginLeft: -50, width: 50, height: 50, alignItems: "center", justifyContent: "center", paddingRight: 10, paddingBottom: 3 }}
            onPress={() => { this.setState({ searchTerm: "", text: "" }); this.myTextInputBlue.focus() }}>
            <Text style={{ color: "white", fontSize: 24, color: "#667" }}>×</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }


  renderSingleContact = () => {
    return <View>
      {this.state.noContactFound && (
        <View
          style={{
            height: 40,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Text style={{ color: "#eee" }}>No result found.</Text>
        </View>
      )}
      {!this.state.noContactFound &&
        this.state.resultID != 0 && (
          <View>
            <Contact
              following={this.props.followingIDs.includes(this.state.resultID)}
              followed={this.props.followerIDs.includes(this.state.resultID)}
              contactID={this.state.resultID}
              height={65}
              noBackgroundColor={true}
              noBorder={true}
              navigation={this.props.navigation}
              onPress={this.props.onPress}
            />
          </View>
        )}
    </View>
  }


  renderMultipleContacts = () => {
    return <View>
      {this.state.noContactFound && (
        <View
          style={{
            height: 40,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Text style={{ color: "#eee" }}>No result found.</Text>
        </View>
      )}
      {!this.state.noContactFound &&
        this.state.resultProfiles && (
          <View style={{ marginBottom: 10 }}>
            {this.state.resultProfiles.map((profile, i) => {
              return <View style={{ borderTopColor: "#333", borderTopWidth: i == 0 ? 0 : 1 }} key={profile.xID || i}>
                <Contact
                  profile={profile}
                  following={this.props.followingIDs.includes(profile.xID)}
                  followed={this.props.followerIDs.includes(profile.xID)}
                  height={50}
                  noBackgroundColor={true}
                  noBorder={true}
                  navigation={this.props.navigation}
                  onPress={this.props.onPress}
                /></View>
            })}
          </View>
        )}
    </View>
  }

  render() {
    if (this.state.resultID == 0 && this.state.noContactFound == false) {
      //??
    }
    return (
      <View style={{ width: "98%", borderColor: "#444", borderRadius: 25, marginLeft: 3 }}>
        {this.renderBlueSearchBar()}
        {!!this.state.activityIndicatorAnimating && <ActivityIndicator size="small" color="#FFFFFF" animating={true} />}
        {this.state.resultType !== "multipleContacts"
          ? this.renderSingleContact()
          : this.renderMultipleContacts()}
      </View>
    );
  }
}


// class Selector extends Component {
//   state = {};

//   signout = async () => {
//     try {

//       const key = "aID" + global.myUID.substring(4, 10)
//       const bufferKey = "aIDbuffer" + global.myUID.substring(4, 10)
//       const userInfoKey = "aIDUserInfo" + global.myUID.substring(4, 10)
//       global.myUID = null;
//       global.idToken = null;
//       global.myProfileID = null;
//       global.globalsSet = false
//       global.myName = null;
//       global.myBusinessName = null;
//       global.myTimeCreated = null;
//       global.myLocationWork = null;
//       global.myLocationWorkAddress = "";
//       global.myProfilePictureURL = null;
//       global.myServicesOffered = [];
//       global.myJobQualifications = [];
//       global.myJobQualificationsStatus = "";
//       global.myNetworks = [];
//       global.myPostIDs = [];
//       global.myFollowingIDs = [];
//       global.myFollowerIDs = [];
//       global.myPushToken = null;
//       global.messageNotifications = {};
//       global.jobNotifications = {};
//       global.messageOpen = "";
//       global.myBlacklist = [];
//       global.myBlocklist = [];
//       global.myBlackedlist = [];
//       global.myBlockedlist = [];
//       global.loadingSuccessChatMain = null; //needed?
//       global.amApprover = false;
//       global.walletType = null;
//       //global.coinKeySet = null;
//       global.coinSignKey = null;
//       global.coinSignKeySet = null;
//       global.myLocalCurrency = null;
//       global.hasNoWebsiteYet = null;
//       global.walletBalance = null;
//       global.walletAmountInEscrowByYou = null;
//       global.walletAmountInEscrowForYou = null;
//       global.coinBalance = null;
//       global.coinAmountInEscrowByYou = null;
//       global.coinAmountInEscrowForYou = null;
//       global.coinResetAfterBlock = null;
//       global.coinLastResetDate = null;
//       global.blockedUntil = null;
//       global.localResultsList = null;
//       global.localResultsDate = null;
//       global.networks = null;
//       global.networkRequests = null;
//       global.networkAdminRights = null;
//       global.globalsSet = false;
//       global.myHealthFileID = null;
//       global.amDoctor = null;
//       global.myHealthProfileID = null;
//       global.healthChatNotifications = { patient: {}, doctor: {} }
//       global.myDiscounts = []

//       await localStorage.clear();
//       await global.asyncDeleteAllContainers();
//       await AsyncStorage.setItem("loanFormLast", "")
//       await AsyncStorage.setItem("localResultsString", "")
//       await AsyncStorage.setItem("localResultsDateString", "")
//       await AsyncStorage.removeItem("hideBOGuide-chat")
//       await AsyncStorage.removeItem("hideBOGuide-location")
//       await AsyncStorage.removeItem("hideBOGuide-newFeatures16")
//       await AsyncStorage.removeItem("hideBOGuide-hello")
//       await AsyncStorage.removeItem("discussionFeed")
//       await AsyncStorage.removeItem(key)
//       await AsyncStorage.removeItem(bufferKey)
//       await AsyncStorage.removeItem(userInfoKey)
//       await AsyncStorage.removeItem("RECOMMENDATIONS")
//       await AsyncStorage.removeItem("lastViewedPostIDs")
//       await AsyncStorage.clear()
//     } catch (err) {
//       console.log(err)
//     }
//     firebase.auth().signOut();
//     //this.props.navigation.navigate('welcome')
//     window.location.replace('http://localhost:19006')
//   }

//   render() {
//     return (
//       <View
//         style={{
//           flexDirection: "row",
//           height: 55,
//           width: '100%',
//           justifyContent: "space-between",
//           alignItems: "center",
//           borderBottomWidth: 1,
//           borderColor: "#333",
//           paddingTop: 0,
//         }}
//       >
//         <View
//           style={{
//             flexDirection: "row",
//             height: 55,
//             alignItems: "center",
//             borderColor: "#333",
//             paddingTop: 0,
//           }}>
//           <TouchableOpacity onPress={() => this.props.onPress("Feed")}>
//             <View
//               style={{
//                 height: 30,
//                 width: 130,
//                 margin: 5,
//                 borderRadius: 20,
//                 borderTopEndRadius: 0,
//                 borderBottomEndRadius: 0,
//                 borderColor: "#aaa",
//                 borderWidth: 1,
//                 backgroundColor: this.props.view == "Feed" ? "#aaa" : "#131313",
//                 justifyContent: "center",
//                 alignItems: "center"
//               }}
//             >
//               <Text
//                 style={{
//                   color: this.props.view == "Feed" ? "#000" : "#fff"
//                 }}
//               >
//                 Feed
//             </Text>
//             </View>
//           </TouchableOpacity>
//           <TouchableOpacity onPress={() => this.props.onPress("Communities")}>
//             <View
//               style={{
//                 height: 30,
//                 width: 140,
//                 margin: 5,
//                 borderTopEndRadius: 20,
//                 borderBottomEndRadius: 20,
//                 backgroundColor:
//                   this.props.view == "Communities" ? "#aaa" : "#131313",
//                 borderColor: "#aaa",
//                 borderWidth: 1,
//                 justifyContent: "center",
//                 alignItems: "center"
//               }}
//             >
//               <Text
//                 style={{
//                   color: this.props.view == "Communities" ? "#fff" : "#fff"
//                 }}
//               >
//                 Communities
//             </Text>
//             </View>
//           </TouchableOpacity>
//         </View>

//         <TouchableOpacity onPress={this.signout}>
//           <View
//             style={{
//               height: 30,
//               width: 140,
//               margin: 5,
//               borderTopLeftRadius: 20,
//               borderBottomLeftRadius: 20,
//               backgroundColor: "#131313",
//               borderColor: "#aaa",
//               borderWidth: 1,
//               justifyContent: "center",
//               alignItems: "center"
//             }}
//           >
//             <Text
//               style={{
//                 color: "#fff"
//               }}
//             >
//               Log Out
//             </Text>
//           </View>
//         </TouchableOpacity>
//       </View>
//     );
//   }
// }



class Feed extends Component {
  state = {
    currentEvents: [],
    futureEvents: [],
    pastEvents: [],
    overdueTasks: [],
    openTasks: [],
    completedTasks: [],
    showAllEvents: false,
    showAllTasks: false,
    taskSelection: "MY",
    myNotifications: this.props.myNotifications || {}
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      myNotifications: nextProps.myNotifications,
    };
  }

  renderLoading = () => (
    <View
      style={{
        height: 30,
        justifyContent: "center",
        alignItems: "center",
        //backgroundColor: "#131313"
      }}
    >
      <View
        style={{
          justifyContent: "center",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <ActivityIndicator size="small" color="#778" style={{ marginRight: 8 }} />
        <Text style={{ color: "#555" }}>Updating Feed...</Text>
      </View>
    </View>
  );

  componentDidMount = () => {

    if (global.homeActiveGroup) {
      this.props.navigation.setParams({ group: global.homeActiveGroup })
      this.setState({ activeGroup: global.homeActiveGroup })
      /* if ((this.props.navigation.getParam("viewSelection", "Communities") == "Communities") && global.activeCommunityID) {
        global.navigateCommunity(navigation, { communityID: global.activeCommunityID })
      } */
    }

  }

  render() {
    const feed = global.discussionFeed || []



    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#000", }}>
        <View style={{ backgroundColor: "#000", flex: 1, width: "100%" }}>
          <View
            style={{
              borderBottomColor: "#555555",
              borderWidth: 0,
              height: 5
            }}
          />
          {this.props.updatingFeed && this.renderLoading()}
          {feed.length == 0 && <View style={{ height: 150, justifyContent: "center", alignItems: "center" }}>
            <Text
              style={{ color: "#999", fontStyle: "italic", textAlign: "center", fontSize: 16, padding: 25, paddingBottom: 0 }}
            >
              Welcome to Wakanda!
            </Text>
            {/* <Text
              style={{ color: "#999", fontStyle: "italic", textAlign: "center", fontSize: 16, padding: 25, paddingTop: 10 }}
            >
              community discussions will appear here.
                  </Text> */}
          </View>}
          <View style={{ paddingHorizontal: 10 }}>

            <View style={{ flexDirection: 'row', width: '100%' }}>
              <View style={{ maxWidth: 350, padding: 10, backgroundColor: '#646464', height: global.SHEIGHT - 50 }}>
                <ScrollView showsVerticalScrollIndicator={false}>
                  <View key={this.state} style={{ marginBottom: 10 }}>
                    {global.renderAllTasksAndEvents(this, true)}
                  </View>

                  {feed.map((item) => {
                    const n = this.state.myNotifications?.[item.xID] || 0
                    return (
                      <TouchableOpacity
                        key={"Feed" + (item.xID || "")}
                        onPress={() => {
                          //global.navigateChatGroup(this.props.navigation, { group: item })
                          this.props.navigation.setParams({ group: item })
                          this.setState({ activeGroup: item })
                          global.homeActiveGroup = item
                        }}
                      >
                        {/* <FeedFeed chatGroup={item} view={"LIST"} navigation={this.props.navigation} /> */}
                        <CommunityFeed chatGroup={item} view={"LIST"}
                          key={(this.state.activeGroup?.xID || '') + (item.xID || '')}
                          notifications={n}
                          refresh={() => { }}
                          navigation={this.props.navigation} isActive={item.xID == this.state.activeGroup?.xID} />
                      </TouchableOpacity>
                    );
                  })}
                </ScrollView>

              </View>
              {feed.length > 0 && <View style={{
                flex: 1, padding: 10, backgroundColor: '#383838', height: global.SHEIGHT - 50
                //  , maxWidth: 500 
              }}>
                {!this.state.activeGroup ? <Text
                  style={{ fontSize: 12, color: "#ccd", paddingTop: 3 }}>...
                </Text> : !this.state.activeGroup?.groupYoutubeID ? <ChatGroup
                  backgroundColor='#383838'
                  navigation={this.props.navigation}
                  group={this.state.activeGroup}
                  key={this.state.activeGroup.xID}
                />
                  :
                  <View style={{ flexDirection: 'row', width: '100%', height: global.SHEIGHT - 50 }}>
                    <View style={{ width: '50%' }}>
                      <View
                        style={{
                          alignItems: "center",
                          backgroundColor: "#000"
                        }}
                      >
                        <iframe width="100%" height="315"
                          src={`https://www.youtube.com/embed/${this.state.activeGroup.groupYoutubeID}`}
                        >
                        </iframe>
                      </View>
                    </View>
                    <ChatGroup
                      backgroundColor='#383838'
                      navigation={this.props.navigation}
                      group={this.state.activeGroup}
                      key={this.state.activeGroup.xID}
                    />
                  </View>}
              </View>}
            </View>


          </View>
        </View>
      </SafeAreaView>
    );
  }
}

class Community extends Component {
  state = {
    communityID: true,
    feedID: false,
    name: "",
    description: "",
    pictureURL: "",
    backgroundPictureURL: "",
    managerProfileID: "",
    nOfMembers: 0,
    nOfDiscussions: 0
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => {
      return;
    };
  }

  componentDidMount() {
    try {
      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Communities")
        .doc(this.props.communityID)
        .onSnapshot(this.updateCommunity);
    }
    catch (err) {
      console.log('Error is ', err)
    }
  }


  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  updateCommunity = async communitySnapshot => {
    try {
      let community = communitySnapshot.data();
      let communityName = community.name || "";
      let communityPictureURL = community.communityPictureURL || "";
      let communityBackgroundPictureURL = community.communityBackgroundPictureURL || "";
      let communityDescription = community.description || "";
      let communityID = communitySnapshot.id;
      let managerProfileID = community.managerProfileID;
      let nOfMembers = community.memberProfileIDs.length
      let nOfDiscussions = community.nOfDiscussions || 0



      if (!global.communityInfo) {
        global.communityInfo = {}
      }
      global.communityInfo[communityID] = { communityBackgroundPictureURL, communityName }


      this.setState({
        name: communityName,
        pictureURL: communityPictureURL,
        backgroundPictureURL: communityBackgroundPictureURL,
        description: communityDescription,
        communityID,
        managerProfileID,
        nOfMembers,
        nOfDiscussions

      });
    } catch (err) {
      console.log("Slow Internet");
    }
  };
  render() {
    return (
      <View>
        <TouchableOpacity
          onPress={() => {
            if (!this.state.communityID) {
              return;
            }
            let mgrID = this.state.managerProfileID;
            let membersID = this.state.memberProfileIDs;
            let route = "meCommunity"
            if (this.props.navigation.state.routeName.startsWith("home")) route = "homeCommunity"
            if (this.props.navigation.state.routeName.startsWith("chat")) route = "chatCommunity"
            this.props.navigation.navigate({
              routeName: route,
              params: {
                communityID: this.state.communityID,
                managerID: mgrID,
                memberProfileIDs: membersID,
              },
              key: this.state.communityID
            });

            global.activeCommunityID = this.state.communityID
          }}
        >
          <View
            style={{
              flexDirection: "row",
              backgroundColor: "black",
              alignItems: "flex-start",
              marginTop: 5,
              marginHorizontal: 5,
              paddingLeft: 11,
              paddingRight: 11,
              shadowOpacity: 0.5,
              shadowOffset: { width: 0, height: 3 },
              borderBottomColor: "#333",
              borderRadius: 4
            }}
          >
            <View
              style={{
                justifyContent: "center",
                marginVertical: 15
              }}
            >
              {!!this.state.backgroundPictureURL ? (
                <CachedImage
                  style={{ width: 82, height: 82, borderRadius: 10 }}
                  source={{
                    uri: this.state.backgroundPictureURL
                  }}
                  key={this.state.backgroundPictureURL}
                />
              ) : (
                <View
                  style={{
                    width: 82,
                    height: 82,
                    borderRadius: 10,
                    backgroundColor: "#fff"
                  }}
                />
              )}
            </View>
            <View style={{ flex: 6, paddingTop: 15, paddingLeft: 10 }}>
              <Text
                style={{ color: "#fff", fontWeight: "bold", marginBottom: 5 }}
              >
                {this.state.name}
              </Text>
              <Text
                style={{ color: "#777", fontSize: 12 }}
                numberOfLines={2}
                ellipsizeMode="tail"
              >{this.state.description}
              </Text>
              <View style={{ height: 20 }}></View>
              <View
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row"
                }}
              >
                <Text style={{ fontSize: 10, color: "#889", fontWeight: "500" }}>
                  {this.state.nOfMembers} Members, {this.state.nOfDiscussions || 0} Discussions
                </Text>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}
class NewCommunities extends Component {
  state = {
    communityID: '',
    feedID: false,
    name: "",
    description: "",
    pictureURL: "",
    managerProfileID: "",
    aaID: null,
    newCommunities: [],
    hiddenCommunities: [],
    loading: true
  };



  join = async (communityID) => {
    this.setState({ aaID: communityID })
    try {
      const r = await fetch(global.cloudFunctionURL + "joinCommunity", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          isWeb: true,
          communityID: communityID,
          action: 'join',
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      //this.profileSearchTerm = term
      const r2 = await r.json();
      this.setState({ aaID: null })
      await global.timeout(10)
      if (r2.msg == 'SUCCESS') {
        /* let hiddenCommunities = global.duplicate(this.state.hiddenCommunities)
        hiddenCommunities.push(communityID)
        this.setState({ hiddenCommunities }) */
        let route = "meCommunity"
        if (this.props.navigation.state.routeName.startsWith("home")) route = "homeCommunity"
        if (this.props.navigation.state.routeName.startsWith("chat")) route = "chatCommunity"
        this.props.navigation.navigate(route, { communityID })
      } else {
        alert('Something went wrong, Try again')
      }
    }
    catch (error) {
      console.log('Error is ', error)
      alert('Something went wrong. Try again ... ')
      this.setState({ aaID: null })
    }
  }

  async componentDidMount() {
    try {
      const res = await Find.findCommunitiesAsync({
        amount: 80, interests: global.myInterests, location: {
          coords: {
            latitude: 6.6, longitude: 3.3
          }
        }
      })
      //alert(' res is ' + JSON.stringify(res))
      this.setState({ loading: false })
      if (res !== -1) {
        this.setState({ newCommunities: res })
      }
    }
    catch (err) {
      console.log("Error is ", err)
    }




  }


  render() {
    return (
      <View>

        {this.state.loading && <View style={{ height: 70, justifyContent: "center", alignItems: "center" }}>
          <Text style={{ color: "#889" }}>Searching...</Text>
        </View>}
        {this.state.newCommunities.map((item, id) => {
          let visible = true
          if (global.myCommunities && global.myCommunities.includes(item.xID)) {
            visible = false
          }
          if (this.state.hiddenCommunities.includes(item.xID)) {
            visible = false
          }

          if (visible)
            return (
              <View key={"new" + item.xID}>
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      backgroundColor: "black",
                      alignItems: "flex-start",
                      marginTop: 5,
                      marginHorizontal: 5,
                      paddingLeft: 11,
                      paddingRight: 11,
                      shadowOpacity: 0.5,
                      shadowOffset: { width: 0, height: 3 },
                      borderBottomColor: "#333",
                      borderRadius: 4
                    }}
                  >
                    <View
                      style={{
                        width: '26%',
                        justifyContent: "center",
                        marginVertical: 15
                      }}
                    >
                      {!!item.communityBackgroundPictureURL ? (
                        <CachedImage
                          style={{ width: 82, height: 82, borderRadius: 10 }}
                          source={{
                            uri: item.communityBackgroundPictureURL
                          }}
                          key={item.communityBackgroundPictureURL}
                        />
                      ) : (
                        <View
                          style={{
                            width: 82,
                            height: 82,
                            borderRadius: 10,
                            backgroundColor: "#fff"
                          }}
                        />
                      )}
                    </View>
                    <View style={{ width: '70%', padding: 10 }}>
                      <Text style={{ color: "#fff", fontWeight: "bold" }}>
                        {item.name}
                      </Text>
                      <Text
                        style={{ color: "#777", fontSize: 12 }}
                        numberOfLines={3}
                        ellipsizeMode="tail"
                      >
                        {item.description}
                      </Text>
                      <View style={{ height: 10 }}></View>
                      {/* <View
                        style={{
                          marginRight: 6,
                          justifyContent: "flex-start",
                          alignItems: "center",
                          flexDirection: "row",
                          minWidth: 45
                        }}
                      >
                        
                      </View> */}
                      <View style={{ flexDirection: "row", alignItems: "center", justifyContent: 'space-between', }}>
                        <View style={{ flexDirection: 'column' }}>
                          <View >
                            <Text
                              style={{ fontSize: 10, color: "#889", fontWeight: "500" }}
                            >
                              <Text>{item.memberProfileIDs.length}</Text> Members
                            </Text>
                          </View>
                          <Text
                            style={{ fontSize: 10, color: "#889", fontWeight: "500" }}
                          >
                            <Text>{this.state.nOfDiscussions}</Text> Discussions
                          </Text>
                        </View>
                        {(!this.state.aaID || this.state.aaID !== item.xID) ? <TouchableOpacity
                          style={{
                            height: 24,
                            borderRadius: 6,
                            alignItems: "center",
                            justifyContent: "center",
                            alignSelf: "stretch",
                            paddingHorizontal: 5,
                            backgroundColor: "#A8CF45",
                            marginRight: 10
                          }}
                          onPress={() => { this.join(item.xID) }}
                        >
                          <Text style={[global.gStyles.buttontX, { paddingHorizontal: 15 }]}>
                            + JOIN
                          </Text>
                        </TouchableOpacity> : <View
                          style={{
                            height: 24,
                            borderRadius: 6,
                            alignItems: "center",
                            justifyContent: "center",
                            alignSelf: "stretch",
                            paddingHorizontal: 5,
                            backgroundColor: "#A8CF45",
                            opacity: 0.5
                          }}
                        >
                          <Text style={[global.gStyles.buttontX, { paddingHorizontal: 15 }]}>
                            Joining
                          </Text>
                        </View>}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            )
          else {
            return (<View key={"hiddenNew" + item.xID}></View>)
          }
        })
        }

      </View >
    )
  }
}


export default class MeCommunitiesScreen extends Component {
  static navigationOptions = {
    header: null,
    animationEnabled: false,
    transitionConfig: () => ({
      transitionSpec: {
        duration: 0,
        timing: 0,
      }
    })
  };


  state = {
    communityIDs: [],
    viewSelection: "Feed",
    updatingFeed: true,
    searchTerm: '',
    myNotifications: {},
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
    this.unsubscribeFirestore2 = () => { return; };
    this.unsubscribeFirestore3 = () => { return; };

    this.unsubscribeFirestoreProfile = () => {
      return;
    };
    this.unsubscribeFirestoreToken = () => {
      return;
    };

    initiateGlobalFunctions(this);

    global.setGlobals();
  }


  componentWillUnmount() {
    this.unsubscribeFirestore();
    this.unsubscribeFirestore2();
    this.unsubscribeFirestore3();
  }


  componentDidMount = async () => {
    // this.parseURLandReturnValues()
    while (!global.globalsSet) await global.timeout(100)
    // this.joinInitialCommunity()
    console.log("@Bidemi: THISDOESNOTDISPLAYONWEBCONSOLE")

    this.unsubscribeFirestore3 = firebase
      .firestore()
      .collection("Users")
      .doc(global.myUID)
      .onSnapshot(this.updateUser);

    //  if (!global.myCommunities || global.myCommunities.length < 1) this.setState({ viewSelection: "Communities" })
    /* if (global.myInterests && global.myInterests.length == 0) {
      this.props.navigation.goBack()
      //await global.timeout(100)
      let route = "meCommunities"
      if (this.props.navigation.state.routeName.startsWith("home")) route = "homeCommunities"
      if (this.props.navigation.state.routeName.startsWith("chat")) route = "chatCommunities"
      this.props.navigation.navigate("meInterests", { nextScreen: route })
      //return???
    } */
    this.setFeed()
    this.loadMyNotifications()
    if (global.communities && global.communities.length > 0) this.setState({ communityIDs: global.myCommunities })
    try {
      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .onSnapshot(data => {
          this.updateCommunities(data);
        });
    }
    catch (err) {
      console.log('Error is ', err)
    }
  };

  updateCommunities = data => {
    const user = data.data()
    this.setState({ communityIDs: user.communities || [] });
  };

  updateUser = async snapshot => { //FUNCTION NORMALLY IN WALLETMAIN (web comment)
    const user = snapshot.data();
    if (!user) return;
    const walletBalance = user.walletBalance - user.walletAmountInEscrowByYou;
    const coinBalance = user.coinBalance - user.coinAmountInEscrowByYou;
    let blockedUntil = null;
    let blockedByUserUntil = null;
    let blockedBySystemUntil = null;
    const now = new Date()
    if (user.coinBlockedByUserUntil && !(user.coinBlockedByUserUntil.toDate() < now)) {
      blockedByUserUntil = user.coinBlockedByUserUntil.toDate()
      blockedUntil = blockedByUserUntil
    }
    if (user.coinBlockedBySystemUntil && !(user.coinBlockedBySystemUntil.toDate() < now)) {
      blockedBySystemUntil = user.coinBlockedBySystemUntil.toDate()
      blockedUntil = blockedBySystemUntil
    }
    if (blockedBySystemUntil && blockedByUserUntil) blockedUntil = Math.max(blockedBySystemUntil, blockedByUserUntil)

    if (typeof walletBalance == "number") { // works also for ACN as walletBalance will be 0

      console.log("New wallet data", {
        walletBalance: walletBalance || 0,
        walletAmountInEscrowByYou: user.walletAmountInEscrowByYou || 0,
        walletAmountInEscrowForYou: user.walletAmountInEscrowForYou || 0,
        coinBalance: coinBalance || 0,
        coinAmountInEscrowByYou: user.coinAmountInEscrowByYou || 0,
        coinAmountInEscrowForYou: user.coinAmountInEscrowForYou || 0,
      })
      global.walletBalance = walletBalance || 0
      global.walletAmountInEscrowByYou = user.walletAmountInEscrowByYou || 0
      global.walletAmountInEscrowForYou = user.walletAmountInEscrowForYou || 0
      global.coinBalance = coinBalance || 0
      global.coinAmountInEscrowByYou = user.coinAmountInEscrowByYou || 0
      global.coinAmountInEscrowForYou = user.coinAmountInEscrowForYou || 0
      global.coinResetAfterBlock = user.coinResetAfterBlock
      global.coinLastResetDate = user.coinLastResetDate ? user.coinLastResetDate.toDate() : null,
        global.blockedUntil = blockedUntil
      global.coinSignKeySet = user.coinSignKey
      global.coinSignKey = user.coinSignKey

    }
    global.walletType = user.usesACN ? "ACN" : "NGN"
    global.myBlacklist = user.blacklist || [];
    global.myBlocklist = user.blocklist || [];
    global.myBlackedlist = user.blackedlist || [];
    global.myBlockedlist = user.blockedlist || [];
    global.myProfileID = user.idProfile;
    global.myHealthFileID = user.idFile;
    global.amDoctor = user.isVerifiedDoctor;
    global.myHealthProfileID = global.amDoctor ? user.idHealthProfile : null;
    global.amAdmin = user.isAdmin || false;
    global.myLocalCurrency = user.localCurrency || "NGN"
    if (user.websiteTrialJustSelected) global.websiteTrialJustSelected = user.websiteTrialJustSelected
    else global.websiteTrialJustSelected = null
    if (user.pushToken) {
      global.myPushToken = user.pushToken;
      global.myPushBadge = user.pushBadge
    }
    global.amApprover = user.isApprover ? true : false;
    if (user.websiteJustPurchased || user.websiteTrialJustSelected > 0 || user.idShop) global.hasNoWebsiteYet = false
    else global.hasNoWebsiteYet = true

    global.myInterests = user.interests || []
    global.myCommunities = user.communities || []
    global.myTimeCreated = user.timeCreated?.toDate?.()
    if (!("interests" in user)) global.myInterestsNeverSet = true
    else global.myInterestsNeverSet = false

    if (user.rndDiscounts) {
      if (global.rndDiscounts !== user.rndDiscounts) global.rndDiscountsNeedsFetch = true

      global.rndDiscounts = user.rndDiscounts
    };
    global.hasSignupReferrals = user.hasSignupReferrals
  }

  setFeed = async (minutes) => {
    try {
      this.setState({ updatingFeed: true })
      if (!global.discussionFeed || global.discussionFeed.length == 0) await global.setSavedDiscussionFeed()
      //await global.setLocationFast(20);
      await global.setDiscussionFeed(minutes || 10)
      this.setState({ updatingFeed: false })
    } catch (e) {
      console.warn("ERROR setFeed3")
      console.log("ERROR setFeed3", e)
    }
  }

  /* parseURLandReturnValues() {
    let COMMUNITY = ''
    let fullURL = window.location.href.split('#')[0]
    fullURL = window.location.href;
    const fullURLsubStrings = fullURL.split("?")[1];
    if (!fullURLsubStrings) return;
    //OPTIONS = fullURLsubStrings.split("&&");  
    COMMUNITY = fullURLsubStrings.split("community=")[1];
    if (!COMMUNITY) return;
    global.initialCommunityID = COMMUNITY
    // this.setState({ communityID: COMMUNITY })
    //alert(`You're joining ${global.initialCommunityID} community`);

  };
  joinInitialCommunity = async () => {

    //this.setState({ aaJoin: true })
    const communityID = global.initialCommunityID
    //alert(`communityID...${communityID}`)
    if (!communityID) {
      return
    }
    try {
      while (!Array.isArray(global.myCommunities)) {
        await global.timeout(100)
      }
      if (global.myCommunities.includes(communityID)) {
        await global.timeout(3000)
        this.props.navigation.navigate({ routeName: "homeCommunities", params: { communityID }, key: communityID })
        return
      }
      //const communityID = this.community.xID
      //alert('joinInitialCommunity working...')
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "joinCommunity", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          communityID,
          action: 'join',
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      //this.profileSearchTerm = term
      // let route = "meCommunity"
      // if (this.props.navigation.state.routeName.startsWith("home")) route = "homeCommunity"
      // if (this.props.navigation.state.routeName.startsWith("chat")) route = "chatCommunity"
      /* const r2 = await r.json();
      await global.timeout(1500)
      this.setState({ aaJoin: false, showJoinModal: false })
      await global.timeout(50) 
      //this.props.navigation.goBack()
      if (r2.msg == 'SUCCESS') {
        await global.timeout(50)
        this.props.navigation.navigate({ routeName: "homeCommunities", params: { communityID }, key: communityID })
      } else {
        alert('an error occured. Search for the community by name')
      }
    }
    catch (error) {
      global.warn(error, 'joinCom')
      alert('Something went wrong. Try again')
      this.setState({ aaJoin: false })
    }
  } */

  loadMyNotifications = async () => {
    try {
      this.unsubscribeFirestore2 = await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .collection("Refs")
        .doc("notificationRefs")
        .onSnapshot(this.updateMyNotifications);
    } catch (error) {
      global.warn(error, "loadMyNotifications2")
    }
  }
  updateMyNotifications = async (refDoc) => {
    try {
      if (!refDoc.exists || !refDoc) return
      const ref = refDoc.data?.()
      let myNotifications = {}
      const communityIDs = Object.keys(ref.pinnedCommunityNotifications) || []
      console.log({ ref, communityIDs })
      communityIDs.forEach(cID => {
        myNotifications = { ...myNotifications, ...ref.pinnedCommunityNotifications[cID] || {} }
      })
      //OTHER ONE, DEL: const myNotifications = ref.pinnedCommunityNotifications?.[communityID] || {}
      console.log({ myNotifications })
      this.setState({ myNotifications })
    } catch (e) {
      global.warn(e, "updateMyNotifications2")
    }
  }

  render() {


    const xHeight = 80;

    return (
      <View style={{ flex: 1, backgroundColor: "#131313" }}>
        <View style={{ backgroundColor: "#131313", flex: 1 }}
          keyboardShouldPersistTaps={'always'}
          keyboardDismissMode={'on-drag'}
          //style={{ width: "100%", backgroundColor: "#000" }}
          ref={ref => (this.myScrollView = ref)}
          scrollEventThrottle={0}
          onScroll={(e) => {
            //if (Platform.OS == 'android') Keyboard.dismiss()
          }}>
          {/* 
          <Selector
            navigation={this.props.navigation}
            view={this.props.viewSelection}
            onPress={viewSelection => {
              //this.setState({ viewSelection });
              this.props.setSelection(viewSelection)
            }}
          /> */}


          {(this.props.navigation.getParam("viewSelection", "Feed") == "Communities" || (isMobile)) ? (
            <View style={{ flexDirection: 'row', width: '100%' }}>
              <View style={{ maxWidth: 400, height: global.SHEIGHT - 50, paddingBottom: 20 }}>
                <ScrollView showsVerticalScrollIndicator={false}>
                  {/* <SearchBar
                onFocusSearchCommunities={() => { this.myScrollView.scrollTo({ x: 0, y: (46 + (this.nNearMe || 0) * 50), animated: true }) }} /> */}
                  {this.state.communityIDs.map((communityID, id) => {
                    return (
                      <View key={communityID}>
                        <Community
                          communityID={communityID}
                          navigation={this.props.navigation}

                        />
                      </View>
                    );
                  })}
                  <View
                    style={{
                      marginTop: 12,
                      marginBottom: 6,
                      marginLeft: 0,
                      fontWeight: "bold",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Text style={{ color: "#fff", fontWeight: "bold" }}>
                      Communities you might be interested in
                    </Text>
                  </View>
                  {/* {this.state.communityIDs.map((communityID, id) => {
                return (
                  <View key={id}>
                    <NewCommunities
                      communityID={communityID}
                      navigation={this.props.navigation}
                    />
                  </View>
                );
              })} */}
                  <NewCommunities
                    navigation={this.props.navigation}
                  />
                </ScrollView>
              </View>


              {/* {feed.length > 0 && <View style={{
                flex: 1, padding: 10, backgroundColor: '#383838', height: global.SHEIGHT - 100
                //  , maxWidth: 500 
              }}>
                {!this.state.activeGroup ? <Text
                  style={{ fontSize: 12, color: "#ccd", paddingTop: 3 }}>Content goes here..
                </Text> :
                  <ChatGroup
                    backgroundColor='#383838'
                    navigation={this.props.navigation}
                    group={this.state.activeGroup}
                    key={this.state.activeGroup.xID}
                  />}
              </View>} */}
            </View>
          ) : (
            <View style={{ flex: 1 }}>
              <Feed
                navigation={this.props.navigation}
                updatingFeed={this.state.updatingFeed}
                myNotifications={this.state.myNotifications || {}}
              />
            </View>
          )}


        </View>
        {/*  <View
          style={{
            position: "absolute",
            bottom: 5,
            right: 15
            // flexDirection: "row"
          }}
        >
          <TouchableOpacity
            style={{
              borderWidth: 1,
              borderColor: "rgba(0,0,0,0.2)",
              alignItems: "center",
              justifyContent: "center",
              width: 70,
              height: 70,
              backgroundColor: "#A8CF45",
              borderRadius: 70,
              shadowOpacity: 1,
              shadowRadius: 3,
              elevation: 4
            }}
            onPress={() => {
              let route = "meCommunityUpdate"
              if (this.props.navigation.state.routeName.startsWith("home")) route = "homeCommunityUpdate"
              if (this.props.navigation.state.routeName.startsWith("chat")) route = "chatCommunityUpdate"
              this.props.navigation.navigate(route)
            }}
          >
            <Text
              style={{ fontSize: 36, fontWeight: "200", marginBottom: 2 }}
            >
              +
              </Text>
          </TouchableOpacity>
        </View> */}
      </View>
    );
  }
}

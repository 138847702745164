import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  ScrollView,
  SafeAreaView,
} from "react-native";
import Discount from "./Discount"


export default class MeDiscountsScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "My Discounts",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };
  state = {
    name: "",
    showGetPicture: false,
    activityIndicatorAnimating: false,
    keyboardSpacerHeight: 0,
    loadedKey: "1000_1" // to force rerender when globals are loaded
  };

  async componentDidMount() {
    try {
      while (true) {
        await new Promise(resolve => { setTimeout(resolve, 1000); });
        this.setState({ loadedKey: "1000_" + Math.random() })
      }
    } catch (e) {
      console.log(e)
    }
  }
  async componentDidMount() {
    try {
      const waitForDiscounts = async () => {
        try {
          let counter = 0
          while (!global.myDiscounts && counter < 20) {
            await new Promise(resolve => { setTimeout(resolve, 1000); });
            counter += 1
          }
          this.setState({ loadedKey: "1000_3" })
        } catch (e) {
          console.log(e)
        }
      }
      if (!global.myDiscounts) waitForDiscounts()

      if (!global.myFollowingIDs) {
        let countr = 0
        while (!global.myFollowingIDs && countr < 40) {
          await new Promise(resolve => { setTimeout(resolve, 500); });
          countr += 1
        }
        this.setState({ loadedKey: "1000_2" })
      }
    } catch (e) {
      console.log(e)
    }
  }



  render() {
    const discounts = global.myDiscounts || []
    console.log("DISCOUNTS", discounts)
    const followingIDs = global.myFollowingIDs || []
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#000000" }}>
        <View key={this.state.loadedKey}>
          <ScrollView style={{ width: "100%" }}>
            <View style={{ alignItems: "center", marginTop: 30 }}>

              {discounts.length > 0 && <View style={{ marginTop: 50 }}><Text style={[{ color: "#ccccd5", fontSize: 16, fontWeight: "bold", marginLeft: 5 }]}>
                You have the following discounts active
          </Text>
                <Text style={[{ color: "#ccccd5", fontSize: 12, marginLeft: 5 }]}>
                  They will be automatically applied when you order
          </Text><View
                  style={{ marginTop: 10, alignItems: "center", alignSelf: "stretch" }}>
                  {discounts.map((discount, i) => {
                    return (<View style={{ alignSelf: "stretch" }} key={discount.timeOfExpirationMillis || ((discount.providerProfileID + i) || "_")}>
                      <Discount
                        discount={discount}
                        pressable={true}
                        navigation={this.props.navigation}
                      />
                    </View>)
                  })}
                </View>
              </View>}
            </View>
          </ScrollView>
        </View>
      </SafeAreaView>
    );
  }
}
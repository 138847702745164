//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
// Screen always "synced" between Wakanda Market and Health
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ActivityIndicator,
  TouchableOpacity,
  Platform
} from "react-native";
import * as Permissions from 'expo-permissions';
import firebase from "firebase";
import "firebase/firestore";

export default class PushNotificationHandler extends Component {
  state = {
    pushStatus: "",
    log: "0",
    activityIndicatorAnimating: false,
    loading: true
  };
  constructor() {
    super();
  }
  componentDidMount() {
    this.getPushNotificationStatusAsync();
  }
  getPushNotificationStatusAsync = async () => {
    const { status: existingStatus } = await Permissions.getAsync(
      Permissions.NOTIFICATIONS
    );
    this.setState({ pushStatus: existingStatus, loading: false });
  };
  registerForPushNotificationsAsync = async () => {
    console.log("global.myPushToken", global.myPushToken);
    try {
      this.setState({ activityIndicatorAnimating: true });
      const { status: existingStatus } = await Permissions.getAsync(
        Permissions.NOTIFICATIONS
      );
      let finalStatus = existingStatus;

      if (existingStatus !== "granted") {
        const { status } = await Permissions.askAsync(
          Permissions.NOTIFICATIONS
        );
        finalStatus = status;
      }

      // Stop here if the user did not grant permissions
      if (finalStatus !== "granted") {
        this.setState({ activityIndicatorAnimating: false });
        alert(
          "Push notifications are not enabled. Enable permission in your System Settings > Apps"
        );
        return;
      } else {
        this.setState({ pushStatus: "granted" });
        global.setMyPushTokenIfNeeded(); // defined in HomeMainScreen
        this.setState({ activityIndicatorAnimating: false });
      }
    } catch (err) {
      console.log(err);
      alert("Error. Please check your connection");
      this.setState({ activityIndicatorAnimating: false });
    }
  };

  render() {
    if (this.state.pushStatus == "granted" || this.state.loading) {
      return <View />;
    } else {
      return (
        <View
          style={{
            height: 75,
            justifyContent: "center",
            alignItems: "center",
            alignItems: "stretch"
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              margin: 10,
              backgroundColor: "#223",
              borderRadius: 10,
              borderWidth: 4,
              borderColor: "#446",
              paddingHorizontal: 8
            }}
          >
            <Text style={{ color: "#99c" }}>
              Push Notifications turned off.
            </Text>
            {!this.state.activityIndicatorAnimating ? (
              <TouchableOpacity
                onPress={() => {
                  this.registerForPushNotificationsAsync();
                }}
              >
                <View
                  style={{
                    backgroundColor: "#99c", borderRadius: 5, padding: 8
                  }}
                >
                  <Text style={{ color: "#223" }}>ENABLE HERE</Text>
                </View>
              </TouchableOpacity>
            ) : (
                <ActivityIndicator size="small" color="#99c" animating={true} />
              )}
          </View>
        </View>
      );
    }
  }
}

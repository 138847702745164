import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  Platform,
  Keyboard,
  Modal,
  TextInput,
  ActivityIndicator,
} from "react-native";
import firebase from "firebase";
import DateTimePicker from '@react-native-community/datetimepicker';
import "firebase/firestore";
const gStyles = global.gStyles //    "../../../../styles/gStyles";



class Contact extends Component {
  state = {
    contactProperties: {
      opponentProfileID: null,
      opponentName: "********",
      opponentNameBusiness: "************",
      opponentProfilePictureURL: null
    },
    date: "---",
    code: "?",
    validUntil: null,
    comment: "****",
    commissionPercentage: 0,
    commissionPercentageL2: 0,
    discountPercentage: 0,
    editing: false,
    iosModal: false,
    androidPopup: false,
    isNewCode: false,
    deleted: false
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    this.setState({
      editing: this.props.data.xID ? false : true,
      isNewCode: this.props.data.xID ? false : true,
      activityIndicatorAnimating: false,
      activityIndicatorAnimatingSearch: false,
      date: this.props.data.timeLastUpdated ? this.props.data.timeLastUpdated.toString() || (new Date()).toString() : (new Date()).toString(),
      code: this.props.data.xID || "",
      validUntil: this.props.data.validUntil || null,
      comment: this.props.data.comment || "",
      commissionPercentage: this.props.data.commissionPercentage || 0,
      commissionPercentageL2: this.props.data.commissionPercentageL2 || 0,
      discountPercentage: this.props.data.discountPercentage || 0,
    })
    if (this.props.contactID) {
      const snapshot = await firebase
        .firestore()
        .collection("Profiles")
        .doc(this.props.contactID)
        .get()
      this.updateContact(snapshot);
    } else {
      this.setState({ contactProperties: null })
    }
  }

  componentWillUnmount() {
    //this.unsubscribeFirestore();
  }

  updateContact = async contactSnapshot => {
    console.log("Update of list of contacts triggered. (Contact in WebsiteCodesAdmin)");
    try {
      let contact = contactSnapshot.data();

      let opponentProfileID = contactSnapshot.id;
      let opponentName = contact.profileName;
      let opponentProfilePictureURL = contact.profilePictureURL;
      let opponentNameBusiness = contact.profileNameBusiness;
      if (!contact.profileNameBusiness) {
        opponentNameBusiness = "";
      }

      let contactProperties = {
        opponentProfileID,
        opponentName,
        opponentNameBusiness,
        opponentProfilePictureURL
      };

      //console.log("contactProperties", contactProperties);
      this.setState({
        contactProperties
      });
    } catch (err) {
      console.log("Slow internet. Could not fetch contact (websitecodesadmin)", err);
      alert("Could not fetch contact. Check your internet connection");
    }
  };

  submit = async (action) => {
    if (!action || action !== "delete") {
      if (!this.state.contactProperties && this.state.commissionPercentage > 0)
        return alert("No referrer, but commission entered")
      if (!this.state.contactProperties && this.state.commissionPercentageL2 > 0)
        return alert("No referrer, but commission L2 entered")
      if (!this.state.code)
        return alert("Select a code")
      if (this.state.commissionPercentage && !(this.state.commissionPercentage <= .75 && this.state.commissionPercentage >= 0))
        return alert("Select a valid commission percentage or 0")
      if (this.state.commissionPercentageL2 && !(this.state.commissionPercentageL2 <= .75 && this.state.commissionPercentageL2 >= 0))
        return alert("Select a valid commission percentage L2 or 0")
      if (!this.state.discountPercentage || !(this.state.discountPercentage <= .5 && this.state.discountPercentage > 0))
        return alert("Enter a discount percentage between 0 and 50")
      if (this.state.discountPercentage && this.state.commissionPercentage && !((1 - this.state.discountPercentage) * (1 - this.state.commissionPercentage) > .2))
        return alert("Discount and/or commission too high")
    }
    if (this.state.activityIndicatorAnimating) return;

    term = this.state.searchText
    try {
      this.setState({ activityIndicatorAnimating: true });
      const r = await fetch(
        global.cloudFunctionURL + "setWebsiteCode",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            code: this.state.code,
            isNewCode: this.state.isNewCode,
            action: action || null, // can be delete
            commissionPercentage: this.state.commissionPercentage || 0,
            commissionPercentageL2: this.state.commissionPercentageL2 || 0,
            discountPercentage: this.state.discountPercentage || 0,
            referrerProfileID: this.state.contactProperties ? this.state.contactProperties.opponentProfileID : null,
            comment: this.state.comment || "",
            validUntil: this.state.validUntil ? Date.parse(this.state.validUntil) : null,
            myUID: global.myUID,
            idToken: global.idToken
          })
        }
      );
      const r2 = await r.json();
      Keyboard.dismiss();
      if (r2.msg === "SUCCESS") {
        alert("Success")
        if (action == "delete") this.setState({ deleted: true })
        this.setState({ editing: false, isNewCode: false })
      } else {
        alert("Error: " + r2.msg)
      }
      this.setState({ activityIndicatorAnimating: false });
    } catch (e2) {
      alert("An error occurred.. Check internet connection..");
      console.log(e2);
      this.setState({ activityIndicatorAnimating: false });
    }
  }

  pickDate = async () => {
    if (Platform.OS == "ios") {
      // if (!this.state.validUntil) {
      this.setState({ validUntil: new Date() })
      // }
      this.setState({ iosModal: true })
    } else {
      this.setState({ androidPopup: true })


    }
  }

  selectReferrer = async () => {
    if (this.state.activityIndicatorAnimating) return;
    term = this.state.searchText
    try {
      this.setState({ activityIndicatorAnimatingSearch: true });
      const r = await fetch(
        global.cloudFunctionURL + "findContact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            phoneNumber: term,
            myUID: global.myUID,
            idToken: global.idToken
          })
        }
      );
      const r2 = await r.json();

      console.log("Received contact. ", r2);
      Keyboard.dismiss();
      if (r2.msg === "NO_CONTACT_FOUND") {
        alert("No profile found")
        this.setState({ activityIndicatorAnimatingSearch: false });
        return;
      }
      if (r2.msg === "CONTACT_FOUND") {
        const snapshot = await firebase
          .firestore()
          .collection("Profiles")
          .doc(r2.contactID)
          .get()
        this.updateContact(snapshot);
        this.setState({ activityIndicatorAnimatingSearch: false });
        return;
      }
      if (r2.msg === "CONTACTS_FOUND") {
        if (r2.contacts.length == 0) {
          alert("No profile found starting with this name")
          this.setState({ activityIndicatorAnimatingSearch: false });
          return;
        } else if (r2.contacts.length != 1) {
          alert("Several profiles found with this name. Try full name or phone number")
          this.setState({ activityIndicatorAnimatingSearch: false });
          return;
        } else {
          const snapshot = await firebase
            .firestore()
            .collection("Profiles")
            .doc(r2.contacts[0].xID)
            .get()
          this.updateContact(snapshot);
          this.setState({ activityIndicatorAnimatingSearch: false });
          return;
        }
      }
      alert("Error: " + r2.msg);
      this.setState({ activityIndicatorAnimatingSearch: false });
    } catch (e2) {
      alert("An error occurred. Check internet connection.");
      console.log(e2);
      this.setState({ activityIndicatorAnimatingSearch: false });
    }
  }


  render() {
    const codeValid = !this.state.validUntil || new Date(Date.parse(this.state.validUntil)) > new Date()
    if (!!this.state.deleted)
      return (
        <View style={{ height: 0, width: 0, opacity: 0 }} />
      )

    if (!this.state.editing && this.state.contactProperties)
      return (
        <TouchableOpacity
          onPress={() => {
            if (!this.state.contactProperties.opponentProfileID) {
              return
            }
            let handoverID = this.state.contactProperties.opponentProfileID;
            this.props.navigation.navigate("meProfile", {
              profileID: handoverID
            });
          }}
        >
          <View
            style={[
              {
                flexDirection: "row",
                flex: 1,
                backgroundColor: "black",
                alignItems: "center",
                paddingLeft: 11,
                paddingRight: 11,
                shadowOpacity: 0.5,
                shadowOffset: { width: 0, height: 3 },
                borderBottomColor: "#333",
                borderBottomWidth: 1
              }
            ]}
          >
            {!!this.state.contactProperties.opponentProfilePictureURL ? (
              <Image
                style={{ width: 42, height: 42, borderRadius: 4 }}
                source={{
                  uri: this.state.contactProperties.opponentProfilePictureURL
                }}
              />
            ) : (
                <View style={{ width: 42, height: 42, borderRadius: 4, backgroundColor: "#000" }} />
              )}
            <View style={{ flexDirection: "row", flex: 1 }}>
              <View
                style={{
                  flexDirection: "column",
                  flex: 1,
                  paddingHorizontal: 8
                }}
              >
                <Text style={{ fontSize: 14, color: "white" }}>
                  {this.state.contactProperties.opponentName}
                </Text>
                {this.state.contactProperties.opponentNameBusiness != "" && (
                  <Text style={{ fontSize: 11, color: "white" }}>
                    {this.state.contactProperties.opponentNameBusiness}
                  </Text>
                )}
                {/* <Text style={{ fontSize: 11, color: "#008866" }}>
                  Added  {this.state.date}
                </Text> */}
                <Text style={{ fontSize: 16, fontWeight: "bold", color: "#008866" }}>
                  Code  {this.state.code}
                </Text>
                {!!this.state.validUntil && <Text style={{ fontSize: 11, color: codeValid ? "#008866" : "#880000" }}>
                  Valid until  {this.state.validUntil ? this.state.validUntil.toString() : ""}
                </Text>}
                {!!this.state.comment && <Text style={{ fontSize: 11, color: "#008866" }}>
                  Comment  {this.state.comment}
                </Text>}
                <Text style={{ fontSize: 11, color: "#008866" }}>
                  {this.state.commissionPercentage * 100 + "%"}  Commission
                </Text>
                {/* <Text style={{ fontSize: 11, color: "#008866" }}>
                  {this.state.commissionPercentageL2 * 100 + "%"}  Commission L2
                </Text> */}
                <Text style={{ fontSize: 11, color: "#008866" }}>
                  {this.state.discountPercentage * 100 + "%"}  Discount
                </Text>
              </View>
            </View>
            <TouchableOpacity
              onPress={() => this.setState({ editing: true })}
              style={{ height: 50, width: 50, alignItems: "center", justifyContent: "center" }}>
              <Image style={{ width: 30, height: 30 }} source={require("../../../../images/icons/Change.png")} />
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
      );

    // view when no referrer
    if (!this.state.editing && !this.state.contactProperties)
      return (
        <View>
          <View
            style={[
              {
                flexDirection: "row",
                flex: 1,
                backgroundColor: "black",
                alignItems: "center",
                paddingLeft: 11,
                paddingRight: 11,
                shadowOpacity: 0.5,
                shadowOffset: { width: 0, height: 3 },
                borderBottomColor: "#333",
                borderBottomWidth: 1
              }
            ]}
          >
            <View style={{ width: 42, height: 42, borderRadius: 4, backgroundColor: "#000" }} />
            <View style={{ flexDirection: "row", flex: 1 }}>
              <View
                style={{ flexDirection: "column", flex: 1, paddingHorizontal: 8, }}
              >
                {/* <Text style={{ fontSize: 14, color: "white" }}>
                  - No Referrer -
                </Text>
                <Text style={{ fontSize: 11, color: "#008866" }}>
                  Added  {this.state.date}
                </Text> */}
                <Text style={{ fontSize: 16, fontWeight: "bold", color: "#008866" }}>
                  Code  {this.state.code}
                </Text>
                {!!this.state.validUntil && <Text style={{ fontSize: 11, color: codeValid ? "#008866" : "#880000" }}>
                  Valid until  {this.state.validUntil ? this.state.validUntil.toString() : ""}
                </Text>}
                {!!this.state.comment && <Text style={{ fontSize: 11, color: "#008866" }}>
                  Comment  {this.state.comment}
                </Text>}
                <Text style={{ fontSize: 11, color: "#008866" }}>
                  {this.state.discountPercentage * 100 + "%"}  Discount
                </Text>
              </View>
            </View>
            <TouchableOpacity
              onPress={() => this.setState({ editing: true })}
              style={{ height: 50, width: 50, alignItems: "center", justifyContent: "center" }}>
              <Image style={{ width: 30, height: 30 }} source={require("../../../../images/icons/Change.png")} />
            </TouchableOpacity>
          </View>
        </View>
      );

    //editing:
    return (<View style={{ padding: 10, backgroundColor: "#000", borderWidth: 1, borderColor: "#444" }}>
      {!!this.state.contactProperties ? <TouchableOpacity
        onPress={() => {
          if (!this.state.contactProperties.opponentProfileID) {
            return
          }
          let handoverID = this.state.contactProperties.opponentProfileID;
          this.props.navigation.navigate("meProfile", {
            profileID: handoverID
          });
        }}
      >
        <View
          style={[
            {
              flexDirection: "row",
              flex: 1,
              height: 65,
              backgroundColor: "black",
              alignItems: "center",
              paddingLeft: 11,
              paddingRight: 11,
              shadowOpacity: 0.5,
              shadowOffset: { width: 0, height: 3 },
              borderBottomColor: "#333",
              borderBottomWidth: 1
            }
          ]}
        >
          {!!this.state.contactProperties.opponentProfilePictureURL ? (
            <Image
              style={{ width: 42, height: 42, borderRadius: 4 }}
              source={{
                uri: this.state.contactProperties.opponentProfilePictureURL
              }}
            />
          ) : (
              <View style={{ width: 42, height: 42, borderRadius: 4, backgroundColor: "#000" }} />
            )}
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View
              style={{
                flexDirection: "column",
                flex: 1,
                paddingHorizontal: 8
              }}
            >
              <Text style={{ fontSize: 14, color: "white" }}>
                {this.state.contactProperties.opponentName}
              </Text>
              {this.state.contactProperties.opponentNameBusiness != "" && (
                <Text style={{ fontSize: 11, color: "white" }}>
                  {this.state.contactProperties.opponentNameBusiness}
                </Text>
              )}
              <Text style={{ fontSize: 11, color: "#008866" }}>
                Last Set {this.state.date}
              </Text>
            </View>
          </View>
          <TouchableOpacity
            onPress={() => this.setState({ contactProperties: null })}
            style={{ alignSelf: "stretch", alignItems: "center", marginBottom: 5, marginTop: 5, backgroundColor: "#A8CF45", padding: 7, borderRadius: 3, height: 30 }}
          >
            <Text
              style={[global.gStyles.buttontext, { fontSize: 12 }]}
            >Change</Text>
          </TouchableOpacity>
        </View>
      </TouchableOpacity> :
        <View>
          <View>
            <Text
              style={{ fontSize: 16, color: "#ccc", paddingBottom: 5, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 20, justifyContent: "flex-start" }}
            >
              Referrer
              </Text>
            <TextInput
              onChangeText={searchText => {
                this.setState({ searchText });
              }}
              style={{
                marginBottom: 0, color: "#ffffff", borderWidth: 0, paddingLeft: 3, borderRadius: 5, height: 35, backgroundColor: "#334"
              }}
              underlineColorAndroid="transparent"
              placeholder="Phone Number (+...) / Business Name"
              placeholderTextColor="#888"
              bordered
            />
          </View>
          <TouchableOpacity
            onPress={() => { this.selectReferrer(); }}
            style={{ alignSelf: "stretch", alignItems: "center", marginBottom: 5, marginTop: 5, backgroundColor: "#A8CF45", padding: 7, borderRadius: 3 }}
          >
            <Text
              style={[global.gStyles.buttontext, {}]}
            >{this.state.activityIndicatorAnimatingSearch ? "Searching..." : "Search Referrer"}</Text>
          </TouchableOpacity>
        </View>}
      <View>
        <Text
          style={{ fontSize: 16, color: "#ccc", paddingBottom: 5, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 20, justifyContent: "flex-start" }}
        >Referrer Code</Text>
        {this.state.isNewCode ? <TextInput
          onChangeText={code => {
            this.setState({ code });
          }}
          defaultValue={this.state.code || ""}
          style={{ marginBottom: 0, color: "#ffffff", borderWidth: 0, paddingLeft: 3, borderRadius: 5, height: 35, backgroundColor: "#334" }}
          underlineColorAndroid="transparent"
          placeholder="e.g. WEBSITEBUZZ"
          placeholderTextColor="#888"
          bordered
        /> :
          <Text style={{ fontSize: 14, color: "#008866" }}>
            {this.state.code}
          </Text>
        }
        <Text
          style={{ fontSize: 16, color: "#ccc", paddingBottom: 5, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 20, justifyContent: "flex-start" }}
        >Comment</Text>
        <TextInput
          onChangeText={comment => {
            this.setState({ comment });
          }}
          defaultValue={this.state.comment || ""}
          style={{ marginBottom: 0, color: "#ffffff", borderWidth: 0, paddingLeft: 3, borderRadius: 5, height: 35, backgroundColor: "#334" }}
          underlineColorAndroid="transparent"
          placeholder=""
          placeholderTextColor="#888"
          bordered
        />
        <Text
          style={{ fontSize: 16, color: "#ccc", paddingBottom: 5, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 20, justifyContent: "flex-start" }}
        >Commission (%)</Text>
        <TextInput
          keyboardType="numeric"
          onChangeText={p => {
            this.setState({ commissionPercentage: p / 100 || 0 });
          }}
          defaultValue={Math.round(this.state.commissionPercentage * 100) + "" || "0"}
          style={{ marginBottom: 0, color: "#ffffff", borderWidth: 0, paddingLeft: 3, borderRadius: 5, height: 35, backgroundColor: "#334" }}
          underlineColorAndroid="transparent"
          placeholder=""
          placeholderTextColor="#888"
          bordered
        />
        {/* <Text
          style={{ fontSize: 16, color: "#ccc", paddingBottom: 5, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 20, justifyContent: "flex-start" }}
        >Commission L2 (%)</Text>
        <TextInput
          keyboardType="numeric"
          onChangeText={p => {
            this.setState({ commissionPercentageL2: p / 100 || 0 });
          }}
          defaultValue={Math.round(this.state.commissionPercentageL2) * 100 + "" || "0"}
          style={{ marginBottom: 0, color: "#ffffff", borderWidth: 0, paddingLeft: 3, borderRadius: 5, height: 35, backgroundColor: "#334" }}
          underlineColorAndroid="transparent"
          placeholder=""
          placeholderTextColor="#888"
          bordered
        /> */}
        <Text
          style={{ fontSize: 16, color: "#ccc", paddingBottom: 5, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 20, justifyContent: "flex-start" }}
        >Discount (%)</Text>
        <TextInput
          keyboardType="numeric"
          onChangeText={p => {
            this.setState({ discountPercentage: p / 100 || 0 });
          }}
          defaultValue={Math.round(this.state.discountPercentage * 100) + "" || "0"}
          style={{ marginBottom: 0, color: "#ffffff", borderWidth: 0, paddingLeft: 3, borderRadius: 5, height: 35, backgroundColor: "#334" }}
          underlineColorAndroid="transparent"
          placeholder=""
          placeholderTextColor="#888"
          bordered
        />
        <View
          style={[
            { flexDirection: "row", flex: 1, backgroundColor: "black", alignItems: "center", }
          ]}
        >
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View
              style={{
                flexDirection: "column",
                flex: 1,
                paddingHorizontal: 8
              }}
            >
              <Text style={{ fontSize: 16, color: "#ccc", paddingBottom: 5, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 20, justifyContent: "flex-start" }}>
                Valid until
              </Text>
              <Text style={{ fontSize: 14, color: codeValid ? "#008866" : "#880000" }}>
                {this.state.validUntil ? this.state.validUntil.toString() || "unlimited" : "unlimited"}
              </Text>
            </View>
          </View>
          <View>
            <TouchableOpacity
              onPress={() => this.pickDate()}
              style={{ alignSelf: "stretch", alignItems: "center", marginBottom: 5, marginTop: 25, backgroundColor: "#A8CF45", padding: 7, borderRadius: 3, height: Platform.OS == "ios" ? 30 : 28 }}
            >
              <Text
                style={[global.gStyles.buttontext, { fontSize: 12 }]}
              >Change</Text>
            </TouchableOpacity>
            {Platform.OS == "android" && !!this.state.validUntil && <TouchableOpacity
              onPress={() => this.setState({ validUntil: null })}
              style={{ alignSelf: "stretch", alignItems: "center", marginBottom: 5, marginTop: 0, backgroundColor: "#A8CF45", padding: 7, borderRadius: 3, height: 28 }}
            >
              <Text
                style={[global.gStyles.buttontext, { fontSize: 12 }]}
              >Remove</Text>
            </TouchableOpacity>}
          </View>
        </View>
      </View>
      <TouchableOpacity
        onPress={() => { this.submit(); }}
        style={{ alignSelf: "stretch", alignItems: "center", marginBottom: 5, marginTop: 25, backgroundColor: "#A8CF45", padding: 11, borderRadius: 3 }}
      >
        <Text
          style={[global.gStyles.buttontext, {}]}
        >{this.state.activityIndicatorAnimating ? "Submitting..." : "Submit"}</Text>
      </TouchableOpacity>
      {!this.state.isNewCode && <TouchableOpacity
        onPress={() => { this.submit("delete"); }}
        style={{ alignSelf: "stretch", alignItems: "center", marginBottom: 5, marginTop: 15, borderColor: "#A8CF45", borderWidth: 1, padding: 11, borderRadius: 3 }}
      >
        <Text
          style={[global.gStyles.buttontext, { color: "#ccc" }]}
        >{this.state.activityIndicatorAnimating ? "..." : "Delete"}</Text>
      </TouchableOpacity>}

      {!!this.state.androidPopup && <DateTimePicker
        testID="dateTimePicker"
        timeZoneOffsetInMinutes={0}
        value={new Date()}
        mode={"date"}
        is24Hour={true}
        display="default"
        onChange={async (event, selectedDate) => {
          if (event.type == "set") {
            if (selectedDate) {
              await this.setState({ validUntil: selectedDate, androidPopup: false })
            }
          } else if (event.type == "dismissed") {
            this.setState({ androidPopup: false })
          }
        }}
      />}

      <Modal animationType="fade" transparent={false} visible={this.state.iosModal} onRequestClose={() => { this.setState({ iosModal: false }); }}>
        <View style={{ flex: 1, justifyContent: "center", backgroundColor: "#ccc" }}>
          {!!this.state.validUntil && <DateTimePicker
            testID="dateTimePicker"
            timeZoneOffsetInMinutes={0}
            value={this.state.validUntil}
            mode={"date"}
            is24Hour={true}
            display="default"
            onChange={(event, selectedDate) => {
              if (selectedDate) this.setState({ validUntil: selectedDate })
            }}
          />}
          <TouchableOpacity
            onPress={() => { this.setState({ iosModal: false }); }}
            style={{ alignSelf: "stretch", alignItems: "center", marginBottom: 5, marginTop: 35, marginHorizontal: 25, backgroundColor: "#000", borderColor: "#A8CF45", borderWidth: 1, padding: 11, borderRadius: 3 }}
          >
            <Text
              style={[global.gStyles.buttontext, { color: "#ccc" }]}
            >OK</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => { this.setState({ iosModal: false, validUntil: null }); }}
            style={{ alignSelf: "stretch", alignItems: "center", marginBottom: 5, marginTop: 15, marginHorizontal: 25, backgroundColor: "#888", borderColor: "#A8CF45", borderWidth: 1, padding: 11, borderRadius: 3 }}
          >
            <Text
              style={[global.gStyles.buttontext, { color: "#ccc" }]}
            >Set Unlimited Validity</Text>
          </TouchableOpacity>
        </View>
      </Modal>
    </View>
    );
  }
}



export default class WebsiteCodesAdminScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Website Referral Codes",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };
  state = {
    codes: [],
    loading: true
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
    //this.todayDate = new Date()
  }

  async componentDidMount() {

    try {
      this.setState({ activityIndicatorAnimating: true });

      const r = await fetch(global.cloudFunctionURL + "getWebsiteCodes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          myUID: global.myUID,
          idToken: global.idToken,
        })
      });
      const r2 = await r.json();
      this.setState({ activityIndicatorAnimating: false });


      if (r2.msg === "SUCCESS") {
        console.log("Received Success, result:", r2.data)

        let codes = r2.data
        codes.sort(function (a, b) {
          return b.timeCreated - a.timeCreated;
        });

        this.setState({ codes: r2.data, loading: false })
      } else {
        alert("Server Error");
      }
    } catch (err) {
      console.log(err);
      this.setState({ activityIndicatorAnimating: false });
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
    console.log("Component Contacts will unmount");
  }

  newCode = () => {
    const codes = global.duplicate(this.state.codes)
    codes.push({})
    this.setState({ codes })
  }


  render() {
    console.log("data>>", this.state.codes)
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#131313" }}>
        <View style={{ backgroundColor: "#131313", flex: 1 }}>
          <View
            style={{
              height: 40
            }}
          />
          <ScrollView>
            {Platform.OS == "android" && <View style={{ height: 50 }} />}
            <View>
              {this.state.codes.length > 0 ? (
                this.state.codes.map((c, i) => {
                  return (
                    <Contact
                      key={i}
                      contactID={c.referrerProfileID}
                      data={c}
                      navigation={this.props.navigation}
                    />
                  );
                })
              ) : (
                  <View
                    style={{
                      height: 300,
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    {!!this.state.loading ? (
                      <ActivityIndicator
                        size="large"
                        color="#008866"
                        style={{
                          opacity: 1
                        }}
                        animating={true}
                      />
                    ) : (
                        <Text style={{ color: "#999" }}>No codes yet</Text>
                      )}
                  </View>
                )}
            </View>
            <View style={{ height: 300 }} />
          </ScrollView>
          {!this.state.loading && <TouchableOpacity
            style={{ borderWidth: 1, borderColor: "rgba(0,0,0,0.2)", alignItems: "center", justifyContent: "center", width: 70, position: "absolute", bottom: 5, right: 15, height: 70, backgroundColor: "#A8CF45", borderRadius: 70, shadowOpacity: 1, shadowRadius: 3, elevation: 4 }}
            onPress={() => this.newCode()}
          >
            <Text style={{ fontSize: 36, fontWeight: "200", marginBottom: 2 }}>
              +
            </Text>
          </TouchableOpacity>}
        </View>
      </SafeAreaView>
    );
  }
}

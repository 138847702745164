import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  Platform,
  TextInput,
  Keyboard,
  ActivityIndicator
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import CachedImage from "../../shared/CachedImage"

class Selector extends Component {
  state = {};

  render() {
    return (
      <View
        style={{
          flexDirection: "row",
          height: 55,
          justifyContent: "center",
          alignItems: "center",
          borderBottomWidth: 1,
          borderColor: "#333",
          paddingBottom: 8,
          ...Platform.select({
            android: {
              marginTop: 30
            }
          })
        }}
      >
        <TouchableOpacity onPress={() => this.props.onPress("Following")}>
          <View
            style={{
              height: 30,
              width: 130,
              margin: 5,
              borderRadius: 20,
              borderTopEndRadius: 0,
              borderBottomEndRadius: 0,
              borderColor: "#aaa",
              borderWidth: 1,
              backgroundColor: this.props.view == "Following" ? "#aaa" : "#131313",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Text
              style={{
                color: this.props.view == "Following" ? "#000" : "#fff"
              }}
            >
              Following
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this.props.onPress("Followers")}>
          <View
            style={{
              height: 30,
              width: 140,
              margin: 5,
              borderTopEndRadius: 20,
              borderBottomEndRadius: 20,
              backgroundColor: this.props.view == "Followers" ? "#aaa" : "#131313",
              borderColor: "#aaa",
              borderWidth: 1,
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Text
              style={{
                color: this.props.view == "Following" ? "#fff" : "#000"
              }}
            >
              Followers
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

class Contact extends Component {
  state = {
    contactProperties: {
      opponentProfileID: null,
      opponentName: "✧ ✧ ✧ ✧",
      opponentNameBusiness: "✧ ✧ ✧ ✧ ✧ ✧ ✧ ✧ ✧ ✧",
      opponentProfilePictureURL: null
    },
    activityIndicatorAnimating: false,
    tmpFollowing: false,
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  componentDidMount() {
    try {
      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Profiles")
        .doc(this.props.contactID)
        .onSnapshot(this.updateContact);
    } catch (e) {
      console.log(e)
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  updateContact = async contactSnapshot => {
    console.log("Update of list of contacts triggered. (MeContacts Screen)");
    try {
      let contact = contactSnapshot.data();

      let opponentProfileID = contactSnapshot.id;
      let opponentName = contact.profileName;
      let opponentProfilePictureURL = contact.profilePictureURL;
      let opponentNameBusiness = contact.profileNameBusiness;
      if (!contact.profileNameBusiness) {
        opponentNameBusiness = "";
      }

      let contactProperties = {
        opponentProfileID,
        opponentName,
        opponentNameBusiness,
        opponentProfilePictureURL
      };

      //console.log("contactProperties", contactProperties);
      this.setState({
        contactProperties
      });
    } catch (err) {
      console.log("Slow internet. Could not fetch contacts (meContact)", err);
      alert("Could not fetch contacts. Check your internet connection");
    }
  };


  delayedUnimate = async () => {
    await new Promise(resolve => { setTimeout(resolve, 2800); });
    this.setState({ tmpFollowing: true, activityIndicatorAnimating: false });
    await new Promise(resolve => { setTimeout(resolve, 8000); });
    this.setState({ tmpFollowing: false });
  }

  callFollow = async follow => {
    if (this.state.contactProperties.opponentProfileID == global.myProfileID) return;
    if (!this.state.activityIndicatorAnimating) {
      try {
        this.setState({ activityIndicatorAnimating: true });

        // console.log(
        //   "follower: ",
        //   global.myUID,
        //   "followerProfile: ",
        //   global.myProfileID,
        //   "targetProfile: ",
        //   this.state.profileID,
        //   "action: ",
        //   follow ? "Follow" : "Unfollow"
        // );

        const r = await fetch(global.cloudFunctionURL + "followProfile", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            follower: global.myUID,
            followerProfile: global.myProfileID,
            targetProfile: this.state.contactProperties.opponentProfileID,
            action: follow ? "Follow" : "Unfollow",
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
        const r2 = await r.json();

        if (r2.msg === "UPDATE_COMPLETED") {
          this.delayedUnimate();
        } else {
          alert("Follow Request failed");
          this.setState({ activityIndicatorAnimating: false });
        }
      } catch (err) {
        alert("Request not successful.");
        console.log(err);
        this.setState({ activityIndicatorAnimating: false });
      }
    }
  };

  render() {
    if (this.props.searchTerm &&
      !this.state.contactProperties.opponentName.toLowerCase().includes(this.props.searchTerm) &&
      !this.state.contactProperties.opponentNameBusiness.toLowerCase().includes(this.props.searchTerm))
      return (<View />)
    return (
      <TouchableOpacity
        onPress={() => {
          if (!this.state.contactProperties.opponentProfileID) {
            return
          }
          let handoverID = this.state.contactProperties.opponentProfileID;
          this.props.navigation.navigate("meProfile", {
            profileID: handoverID
          });
        }}
      >
        <View
          style={[
            {
              flexDirection: "row",
              flex: 1,
              height: 65,
              backgroundColor: "black",
              alignItems: "center",
              paddingLeft: 11,
              paddingRight: 11,
              shadowOpacity: 0.5,
              shadowOffset: { width: 0, height: 3 },
              borderBottomColor: "#333",
              borderBottomWidth: 1
            }
          ]}
        >
          {!!this.state.contactProperties.opponentProfilePictureURL ?
            Platform.OS == "android" ? <Image
              style={{ width: 42, height: 42, borderRadius: 4 }}
              source={{ uri: this.state.contactProperties.opponentProfilePictureURL }}
            /> : <CachedImage
                style={{ width: 42, height: 42, borderRadius: 4 }}
                key={this.state.contactProperties.opponentProfilePictureURL || "opp"}
                source={{ uri: this.state.contactProperties.opponentProfilePictureURL }}
              />
            : (
              <View style={{ width: 42, height: 42, borderRadius: 4, backgroundColor: "#000" }} />
            )}
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View
              style={{
                flexDirection: "column",
                flex: 1,
                paddingHorizontal: 8
              }}
            >
              <Text style={{ fontSize: 14, color: "white" }}>
                {this.state.contactProperties.opponentName}
              </Text>
              {this.state.contactProperties.opponentNameBusiness != "" && (
                <Text style={{ fontSize: 11, color: "white" }}>
                  {this.state.contactProperties.opponentNameBusiness}
                </Text>
              )}
            </View>
            <View>
              {(this.props.following || this.state.tmpFollowing) ? <View
                style={{ alignItems: "center", marginBottom: 4 }}
              >
                <Image
                  style={{
                    width: 20,
                    height: 25,
                    marginRight: 7
                  }}
                  source={require("../../images/icons/Following.png")}
                />
                <Text style={{ color: "#aaa", fontSize: 12 }}>Following</Text>
              </View>
                : !this.state.activityIndicatorAnimating ? (<TouchableOpacity
                  style={{
                    alignSelf: "stretch",
                    alignItems: "center",
                    padding: 4,
                    backgroundColor: "#A8CF45",
                    marginTop: 5,
                    marginBottom: 5,
                    borderRadius: 5
                  }}
                  onPress={() => {
                    this.callFollow(true);
                  }}
                >
                  <Text style={{
                    fontWeight: "bold",
                    color: "#000",
                    fontSize: 14
                  }}>
                    Follow
                    Back
                  </Text>
                </TouchableOpacity>)
                  :
                  (<ActivityIndicator
                    size="small"
                    color="#FFFFFF"
                    animating={true}
                    style={{ marginRight: 12, marginTop: 2 }}
                  />)
              }
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

export default class MeContactsScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Contacts",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };
  state = {
    followingIDs: [],
    followerIDs: [],
    viewSelection: "Following",
    noFollowers: 0,
    noFollowing: 0,
    loading: true
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    console.log(window.navigator.platform);
    try {
      this.unsubscribeFirestore = await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .collection("Refs")
        .doc("contactRefs")
        .onSnapshot(this.updateContacts);
    } catch (err) {
      //alert("Error initiating database connection (Contacts)");
      console.log("Error initiating database connection (Contacts)", err);
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
    console.log("Component Contacts will unmount");
  }

  updateContacts = async contactRefSnapshot => {
    let fingIDs = contactRefSnapshot.data().followingProfileIDs;
    let ferIDs = contactRefSnapshot.data().followerProfileIDs;

    if (fingIDs) {
      this.setState({ followingIDs: fingIDs, loading: false });
    }
    if (ferIDs) {
      this.setState({ followerIDs: ferIDs, loading: false });
    }
  };

  search = async (term) => {
    let searchTerm = term.toLowerCase()
    this.setState({ searchTerm, text: term })
  }

  renderBlueSearchBar() {
    return (
      <View
        style={{
          borderBottomWidth: 1,
          height: 50,
          borderColor: "#333"
        }}
      >
        <View
          style={{
            flexDirection: "row",
            marginTop: 0,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <TextInput
            style={{
              alignSelf: "stretch", height: 30, margin: 10, color: "#eee", backgroundColor: "#223", borderRadius: 15, borderBottomWidth: 0, paddingLeft: 10, flex: 1
            }}
            placeholder="Search my contacts"
            placeholderTextColor="#eee"
            textAlign="center"
            underlineColorAndroid="transparent"
            underlineColor={"transparent"}
            onChangeText={searchTerm => this.search(searchTerm)}
            ref={component => (this.myTextInputBlue = component)}
            // clearButtonMode="always" //xx how set colour?
            value={this.state.text}
            keyboardShouldPersistTaps={'always'}
            keyboardDismissMode={'on-drag'}
            keyboardAppearance={"dark"}
            returnKeyType={"done"}
            onSubmitEditing={() => { Keyboard.dismiss() }}
            autoCompleteType={'off'}
            autoCapitalize={'none'}
            autoCorrect={false}
            onFocus={() => { this.myTextInputBlue.focus() }}
          />
          <TouchableOpacity style={{ marginLeft: -50, width: 50, height: 50, alignItems: "center", justifyContent: "center", paddingRight: 10, paddingBottom: 3 }}
            onPress={() => { this.setState({ searchTerm: "", text: "" }); this.myTextInputBlue.focus() }}>
            <Text style={{ color: "white", fontSize: 24, color: "#667" }}>×</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  render() {
    const contactIDs =
      this.state.viewSelection == "Following"
        ? this.state.followingIDs
        : this.state.viewSelection == "Followers"
          ? this.state.followerIDs
          : [];
          console.log({contactIDs})
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#131313" }}>
        <View style={{ backgroundColor: "#131313", flex: 1 }}>
          <View
            style={{
              height: 40
            }}
          />
          <Selector
            view={this.state.viewSelection}
            onPress={async viewSelection => {
              try {
                // await this.setState({ viewSelection: "" });
                // await global.timeout(100)
                this.setState({ viewSelection });
              } catch (e) {
                global.warn(e, "cViewSelection")
              }
            }}
          />
          {this.renderBlueSearchBar()}
          <ScrollView
            onScroll={(e) => { Keyboard.dismiss() }}
            scrollEventThrottle={3000}
          >
            <View>
              {contactIDs.length > 0 ? (
                contactIDs.map(id => {
                  return (
                    <Contact
                      key={id}
                      contactID={id}
                      following={this.state.followingIDs.includes(id)}
                      searchTerm={this.state.searchTerm}
                      navigation={this.props.navigation}
                    />
                  );
                })
              ) : (
                  <View
                    style={{
                      height: 300,
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    {!!this.state.loading ? (
                      <ActivityIndicator
                        size="large"
                        color="#A8CF45"
                        style={{
                          opacity: 1
                        }}
                        animating={true}
                      />
                    ) : (
                        <Text style={{ color: "#999" }}>No contacts yet</Text>
                      )}
                  </View>
                )}
            </View>
          </ScrollView>
          <TouchableOpacity
            onPress={() => this.props.navigation.navigate("meAddContacts")}
            style={{
              borderWidth: 1,
              borderColor: "rgba(0,0,0,0.2)",
              alignItems: "center",
              justifyContent: "center",
              width: 70,
              position: "absolute",
              bottom: 5,
              right: 15,
              height: 70,
              backgroundColor: "#A8CF45",
              borderRadius: 70
            }}
          >
            <Text style={{ fontSize: 36, fontWeight: "200", marginBottom: 2 }}>+</Text>
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    );
  }
}


import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  TextInput,
  Modal,
  Keyboard,
  Dimensions,
  SectionList,
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import { SvgCss } from "react-native-svg";
const gStyles = global.gStyles //    "../../styles/gStyles";
import * as Contacts from 'expo-contacts';
import * as Permissions from 'expo-permissions';


export default class HomeInviteScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Tell the World!",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };
  state = {
    screen: "MAIN", // can be "ENTER_NUMBER", "SELECT", "SUBMITTED", "DISCOUNT"
    filterTerm: "",
    filterString: "",
    contacts: [],
    loading: false
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    try {
      if (!global.myUID || !global.myProfileID || !global.globalsSet) await global.timeout(3500)
      if (!global.myUID || !global.myProfileID || !global.globalsSet) this.props.navigation.goBack();

    } catch (err) {
      if (global.reportError) {
        global.reportError(err, "catches", "homeSellersInvite");
      }
    }
  }

  componentWillUnmount() {
    console.log("Component homeInvite will unmount");
    this.unsubscribeFirestore();
  }

  preparePhonebook = async (showSelection) => {
    if (this.state.loading) return;
    try {
      const { status } = await Permissions.askAsync(Permissions.CONTACTS);
      if (status != "granted") {
        alert("You have not granted permission to access your contacts (Check app permissions settings)")
        return
      }
      this.setState({ loading: true })

      const { data } = await Contacts.getContactsAsync({
      });
      console.log("PHONEBOOK CONTACTS RECEIVED")
      const myContacts = []
      if (data.length > 0) {
        data.forEach((phoneContact) => {
          if (phoneContact.phoneNumbers) {
            let myPhoneNumbers = []
            phoneContact.phoneNumbers.forEach(number => {
              const trimmedNumber = number.number.trim()
              if (trimmedNumber) {
                if (trimmedNumber.startsWith("+")) {
                  if ((trimmedNumber.startsWith("+234")) || trimmedNumber.startsWith("+233"))
                    myPhoneNumbers.push("+" + String(trimmedNumber).replace(/[^\d]/g, ""));
                } else if (trimmedNumber.startsWith("0"))
                  myPhoneNumbers.push(((global.myLocalCurrency == "GHS") ? "+233" : "+234") + String(trimmedNumber).substring(1, 99).replace(/[^\d]/g, ""));
              }

            })
            if (myPhoneNumbers.length > 0) {
              myPhoneNumbers = myPhoneNumbers.filter((v, i, a) => a.indexOf(v) === i)
              myContacts.push({ name: phoneContact.name || "Unknown", phoneNumbers: myPhoneNumbers, selected: true })
            }
          }
        })
      }
      //console.log("CONTACTS PROCESSED", myContacts);
      if (myContacts.length < 1) {
        alert("No contacts found")
        this.setState({ loading: false })
        return;
      }
      myContacts.sort((a, b) => { return ((b.name < a.name) ? 1 : -1) })

      //console.log("CONTACTS SORTED", myContacts);

      if (showSelection) this.setState({ contacts: myContacts, screen: "SELECT", loading: false })
      else {
        await this.setState({ contacts: myContacts, loading: false })
        this.inviteSelected()
      }

    } catch (err) {
      this.setState({ loading: false })
      alert("Error accessing phone book")
      console.log(err)
    }
  }

  prepareIndividual = async (individualNumber) => {
    if (individualNumber.startsWith("+234") && individualNumber.length != 14) return alert("Invalid Phone Number")
    if (individualNumber.startsWith("+233") && individualNumber.length != 13) return alert("Invalid Phone Number")
    try {
      const contacts = []
      const myPhoneNumbers = [individualNumber]
      contacts.push({ name: "Unknown", phoneNumbers: myPhoneNumbers, selected: true })
      console.log("NUMBER PROCESSED", contacts);

      await this.setState({ contacts })
      if (individualNumber.startsWith("+233")) this.submitContactsExecution(0, 0)
      else this.inviteSelected()
    } catch (err) {
      this.setState({ loading: false })
      alert("Error accessing phone book")
      console.log(err)
    }
  }

  inviteSelected = async () => {
    try {
      if (global.walletType == "ACN") this.submitContactsExecution(0, 0)
      else {
        this.setState({ loading: true })
        await global.timeout(500)
        this.setState({ screen: "DISCOUNT", loading: false })
      }
    } catch (e) {
      global.warn(e, "inviteSelected")
    }
  }

  toggleCheckbox = (contactToToggle) => {
    const newArray = this.state.contacts.map(contact => {
      if (contact !== contactToToggle) return contact
      else {
        const newContact = contact
        newContact.selected = !contact.selected
        return newContact
      }
    })
    this.setState({ contacts: newArray })
  }
  selectAll = (selected) => {
    const newArray = this.state.contacts.map(contact => {
      const newContact = contact
      newContact.selected = selected
      return newContact
    })
    this.setState({ contacts: newArray })
  }

  submitContacts = (discountNGN) => {
    this.submitContactsExecution(discountNGN, 0)
  }
  submitContactsACN = (discountACN) => {
    this.submitContactsExecution(0, discountACN)
  }
  submitContactsExecution = async (discountNGN, discountACN) => {
    try {
      this.setState({ loading: true })
      console.log("SUBMITTING CONTACTS FOR INVITE")

      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "discount", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          contacts: this.state.contacts.filter(c => { return (c.selected) }),
          discountNGN,
          discountACN,
          action: "invite",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ loading: false })
      if (r2.msg === "SUCCESS") {
        this.setState({ screen: "SUBMITTED" });
        return;
      } else {
        alert("Server error.")
      }

    } catch (err) {
      this.setState({ loading: false })
      alert("Error. Check network connection")
      console.log(err)
    }
  }

  renderBlueSearchBar() {
    return (
      <View
        style={{
          borderBottomWidth: 1,
          height: 50,
          borderColor: "#333"
        }}
      >
        <View
          style={{
            flexDirection: "row",
            marginTop: 0,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <TextInput
            // style={{
            //   alignSelf: "stretch",
            //   height: 30,
            //   width: 300,
            //   marginBottom: 0,
            //   color: "#FFFFFF",
            //   borderBottomColor: "#FFFFFF",
            //   borderBottomWidth: 1
            // }}
            style={{
              alignSelf: "stretch", height: 30, margin: 10, color: "#eee", backgroundColor: "#223", borderRadius: 15, borderBottomWidth: 0, paddingLeft: 10, flex: 1
            }}
            placeholder="Search by name"
            placeholderTextColor="#eee"
            textAlign="center"
            underlineColorAndroid="transparent"
            underlineColor={"transparent"}
            onChangeText={filterString => {
              const filterTerm = filterString.toLowerCase()
              this.setState({ filterTerm, filterString })
            }}
            ref={component => (this.myTextInputBlue = component)}
            // clearButtonMode="always" //xx how set colour?
            value={this.state.filterString}
            keyboardShouldPersistTaps={'always'}
            keyboardDismissMode={'on-drag'}
            keyboardAppearance={"dark"}
            returnKeyType={"done"}
            onSubmitEditing={() => { Keyboard.dismiss() }}
            autoCompleteType={'off'}
            autoCapitalize={'none'}
            autoCorrect={false}
            onFocus={() => { if (Platform.OS == "android") this.myTextInputBlue.focus() }}
          />
          <TouchableOpacity style={{ marginLeft: -50, width: 50, height: 50, alignItems: "center", justifyContent: "center", paddingRight: 10, paddingBottom: 3 }}
            onPress={() => { this.setState({ filterTerm: "", filterString: "" }); this.myTextInputBlue.focus() }}>
            <Text style={{ color: "white", fontSize: 24, color: "#667" }}>×</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  render() {
    if (this.state.loading) return (
      <View style={{ height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: "#282828" }}>
        <View style={{ opacity: 0.8, marginBottom: 30 }}><Text style={{ color: "#bbb", fontStyle: "italic" }}>Sharing is Caring</Text></View>
        <ActivityIndicator size="large" color="#bbb" />
      </View>
    );
    if (this.state.screen == "MAIN") return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#282828" }}>
        <StatusBar barStyle="light-content" />

        <View
          style={{ backgroundColor: "#282828", flex: 1 }}
        >
          <View
            style={{
              backgroundColor: "#282828",
              borderBottomColor: "#555",
              borderBottomWidth: 1,
              ...Platform.select({ ios: { height: 55 }, android: { height: 75 } })
            }}
          />
          <ScrollView>
            {/* <View style={{ position: "absolute", top: 0, left: 20, opacity: 0.05 }}>
                <SvgCss height="800" width="800" xml={global.shopIcon} />
              </View> */}
            {/* <Text style={{ color: "#fff", fontSize: 14, marginLeft: 10, fontStyle: "italic" }}>Offering Goods or Services on Wakanda is easy:</Text>*/}

            <View style={{ height: 10 }} />
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Image style={{ width: 110, height: 80, margin: 5, alignSelf: "center", tintColor: "white" }}
                source={require("../../images/logos/home_logo.png")}
              />
              <View>
                <Text style={{ marginTop: 0, color: "#aaa", fontSize: 14, marginLeft: 20, opacity: 1, fontStyle: "italic" }}>    Wakanda is Awesome!</Text>
                <Text style={{ marginTop: 5, color: "#ccc", fontSize: 15, marginLeft: 20, opacity: 1, fontStyle: "italic" }}>             One App. One Africa.</Text>
                <Text style={{ marginTop: 15, color: "#fff", fontSize: 15, marginLeft: 20, opacity: 1, fontWeight: "bold" }}>Tell your friends and benefit!</Text>
              </View>
            </View>
            <View style={{ height: 5 }} />
            {/* <Text style={{ color: "#fff", fontSize: 14 }}>Here is how we help your business thrive:</Text> */}
            <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20 }}>
              <Text style={{ color: "#fff", fontSize: 18, fontWeight: "bold" }}>Invite phone contacts</Text>
              {/* <Text style={{ color: "#fff", fontSize: 18, fontWeight: "bold" }}>1.  Open a Shop on Wakanda Market </Text> */}
              <View style={{ padding: 10, paddingLeft: 20, paddingRight: 20 }}>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Select from your phonebook who you want to invite</BulletPoint>
              </View>
              <TouchableOpacity
                onPress={() => { this.preparePhonebook(true) }}
              >
                <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Select Now</Text>
                </View>
              </TouchableOpacity>
            </View>

            <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20 }}>
              <Text style={{ color: "#fff", fontSize: 18, fontWeight: "bold" }}>Invite individual</Text>
              {/* <Text style={{ color: "#fff", fontSize: 18, fontWeight: "bold" }}>1.  Open a Shop on Wakanda Market </Text> */}
              <View style={{ padding: 10, paddingLeft: 20, paddingRight: 20 }}>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Invite a single phone number</BulletPoint>
              </View>
              <TouchableOpacity
                onPress={() => { this.setState({ screen: "ENTER_NUMBER" }) }}
              >
                <View style={[global.gStyles.buttonXLW, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 16, color: "#fff" }}>Enter Phone Number</Text>
                </View>
              </TouchableOpacity>
            </View>

          </ScrollView>
        </View>
      </SafeAreaView >
    );
    if (this.state.screen == "ENTER_NUMBER") return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#282828" }}>
        <StatusBar barStyle="light-content" />

        <View
          style={{ backgroundColor: "#282828", flex: 1 }}
        >
          <View
            style={{
              backgroundColor: "#282828",
              borderBottomColor: "#555",
              borderBottomWidth: 1,
              ...Platform.select({ ios: { height: 55 }, android: { height: 75 } })
            }}
          />
          <View style={{ backgroundColor: "#000", marginTop: 50, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20 }}>
            <Text style={{ color: "#fff", fontSize: 18, fontWeight: "bold" }}>Enter Phone Number 🇬🇭🇳🇬</Text>
            <View style={{ padding: 10, paddingLeft: 20, paddingRight: 20 }}>
              <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                They will receive an SMS if not yet on Wakanda</BulletPoint>
            </View>
            <SearchBar
              onPress={individualNumber => {
                this.prepareIndividual(individualNumber)
              }}
            />
          </View>
        </View>
      </SafeAreaView>
    );
    const selectedCount = this.state.contacts.filter(c => { return (c.selected) }).length
    if (this.state.screen == "SELECT") return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#282828" }}>
        <StatusBar barStyle="light-content" />

        <View
          style={{ backgroundColor: "#282828", flex: 1 }}
        >
          <View
            style={{
              backgroundColor: "#282828",
              borderBottomColor: "#555",
              borderBottomWidth: 1,
              ...Platform.select({ ios: { height: 55 }, android: { height: 75 } })
            }}
          />
          <ScrollView style={{ flex: 1 }}>
            <View style={{ marginTop: 25, marginBottom: 1, paddingHorizontal: 18, flexDirection: "row", justifyContent: "space-between", alignSelf: "stretch" }}>
              <View>
                <Text style={{ color: "#ccc", fontSize: 18, fontWeight: "bold" }}>Your Contacts 🇬🇭🇳🇬</Text>
                <Text style={{ color: "#ccc", fontSize: 12, }}>{selectedCount} selected</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <TouchableOpacity
                  onPress={() => { this.selectAll(true) }}
                  style={[global.gStyles.buttonXW, { margin: 3, minWidth: 68 }]}>
                  <Text style={global.gStyles.buttontXW}>✓ All</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => { this.selectAll(false) }}
                  style={[global.gStyles.buttonXW, { margin: 3, minWidth: 68 }]}>
                  <Text style={global.gStyles.buttontXW}>None</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20 }}>
              {this.renderBlueSearchBar()}
              {this.state.contacts.map((contact, i) => {
                let numbersString = contact.phoneNumbers[0]
                if (contact.phoneNumbers.length > 1) numbersString += (", " + contact.phoneNumbers[1])
                if (contact.phoneNumbers.length > 2) numbersString += ("  + " + (contact.phoneNumbers.length - 2))
                const thisKey = i + contact.name + numbersString
                let hidden = true
                if (this.state.filterTerm) {
                  const included1 = contact.name.toLowerCase().includes(this.state.filterTerm)
                  const included2 = numbersString.includes(this.state.filterTerm)
                  if (included1 || included2) hidden = false
                  // if (contact?.name?.toLowerCase().startsWith("apoo")) {
                  //   console.log({
                  //     apoorva: true, included1, included2, filterTerm: this.state.filterTerm, nam: contact?.name?.toLowerCase(), includedd1: (contact?.name?.toLowerCase().includes(this.state.filterTerm?.toLowerCase())),
                  //     includedd2: (numbersString?.includes(this.state.filterTerm)), hopeHidden: (included1 || included2)
                  //   })
                  // }
                } else hidden = false
                if (hidden) { return <View key={"hidden_" + thisKey} /> }
                return (<TouchableOpacity onPress={() => { this.toggleCheckbox(contact) }} key={thisKey}>
                  <View style={{ height: 50, alignSelf: "stretch", flexDirection: "row", alignItems: "center" }} >
                    <CheckboxSelector selected={contact.selected} text={"  "} />
                    <View style={{ alignSelf: "stretch" }}>
                      <Text style={{ color: "#ccc", fontSize: 18, marginTop: 5, marginBottom: 3 }}>{contact.name.substring(0, 60)}</Text>
                      <Text style={{ color: "#9999a4", fontSize: 10 }}>{numbersString}</Text>
                    </View>
                  </View>
                </TouchableOpacity>)
              })}
            </View>
          </ScrollView>
          <View style={{ height: 100, width: "100%", justifyContent: "center", opacity: selectedCount > 0 ? 1 : 0.33 }}>
            <TouchableOpacity
              onPress={() => {
                if (selectedCount > 0)
                  this.inviteSelected()
              }}
            >
              <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "94%", alignSelf: "center", alignItems: "center" }]} >
                <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Invite Selected</Text>
              </View>
            </TouchableOpacity>
            <Text style={{ fontSize: 10, color: "#ccc", alignSelf: "center", marginTop: 10 }}>They will receive an SMS if not yet on Wakanda</Text>
          </View>
        </View>
      </SafeAreaView >
    );

    const conv = (c) => Math.round(c * global.xRates["GHS"])
    if (this.state.screen == "DISCOUNT") return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#282828" }}>
        <StatusBar barStyle="light-content" />

        <View
          style={{ backgroundColor: "#282828", flex: 1 }}
        >
          <View
            style={{
              backgroundColor: "#282828",
              borderBottomColor: "#555",
              borderBottomWidth: 1,
              ...Platform.select({ ios: { height: 55 }, android: { height: 75 } })
            }}
          />
          {global.walletType == "ACN" ? <ScrollView>
            <View style={{ height: 10 }} />
            {/* <Text style={{ color: "#fff", fontSize: 14 }}>Here is how we help your business thrive:</Text> */}
            <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20 }}>
              <Text style={{ color: "#ccc", fontSize: 20, textAlign: "center", marginBottom: 10 }}>Add a Welcome Discount!</Text>
              <Text style={{ color: "#ccc", fontSize: 16, textAlign: "center", fontStyle: "italic", marginBottom: 10 }}>Add a little discount on their first order from you. They will appreciate it.</Text>
              <TouchableOpacity
                style={{ marginVertical: 15 }}
                onPress={() => { this.submitContactsACN(200) }}
              >
                <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Add ₳ 200 ≈ {conv(200)} ₵</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={{ marginVertical: 15 }}
                onPress={() => { this.submitContactsACN(300) }}
              >
                <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Add ₳ 300 ≈ {conv(300)} ₵</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={{ marginVertical: 15 }}
                onPress={() => { this.submitContactsACN(500) }}
              >
                <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Add ₳ 500 ≈ {conv(500)} ₵</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={{ marginVertical: 15 }}
                onPress={() => { this.submitContactsACN(1000) }}
              >
                <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Add ₳ 1000 ≈ {conv(1000)} ₵</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={{ marginVertical: 15 }}
                onPress={() => { this.submitContactsACN(2200) }}
              >
                <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Add ₳ 2000 ≈ {conv(2000)} ₵</Text>
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                style={{ marginVertical: 15 }}
                onPress={() => { this.submitContactsACN(0) }}
              >
                <View style={[global.gStyles.buttonXLW, { paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 14, color: "#ccc" }}>Invite without any discount</Text>
                </View>
              </TouchableOpacity>
            </View>
          </ScrollView>
            : <ScrollView>
              <View style={{ height: 10 }} />
              {/* <Text style={{ color: "#fff", fontSize: 14 }}>Here is how we help your business thrive:</Text> */}
              <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20 }}>
                <Text style={{ color: "#ccc", fontSize: 20, textAlign: "center", marginBottom: 10 }}>Add a Welcome Discount!</Text>
                <Text style={{ color: "#ccc", fontSize: 16, textAlign: "center", fontStyle: "italic", marginBottom: 10 }}>Add a little discount on their first order from you. They will appreciate it.</Text>
                <TouchableOpacity
                  style={{ marginVertical: 15 }}
                  onPress={() => { this.submitContacts(200) }}
                >
                  <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                    <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Add ₦ 200  </Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{ marginVertical: 15 }}
                  onPress={() => { this.submitContacts(300) }}
                >
                  <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                    <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Add ₦ 300</Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{ marginVertical: 15 }}
                  onPress={() => { this.submitContacts(500) }}
                >
                  <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                    <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Add ₦ 500</Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{ marginVertical: 15 }}
                  onPress={() => { this.submitContacts(1000) }}
                >
                  <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                    <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Add ₦ 1000</Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{ marginVertical: 15 }}
                  onPress={() => { this.submitContacts(2200) }}
                >
                  <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                    <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Add ₦ 2200</Text>
                  </View>
                </TouchableOpacity>
                <Text style={{ color: "#ccc", fontSize: 14, fontWeight: "bold", marginVertical: 15, textAlign: "center" }}>SPECIAL: Wakanda will chip in an extra ₦ 300 discount on their first order with you!</Text>

                <TouchableOpacity
                  style={{ marginVertical: 15 }}
                  onPress={() => { this.submitContacts(0) }}
                >
                  <View style={[global.gStyles.buttonXLW, { paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                    <Text style={{ fontSize: 14, color: "#ccc" }}>Invite without any discount</Text>
                  </View>
                </TouchableOpacity>
              </View>
            </ScrollView>}
        </View>
      </SafeAreaView >
    );

    if (this.state.screen == "SUBMITTED") return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#282828" }}>
        <StatusBar barStyle="light-content" />

        <View
          style={{ backgroundColor: "#282828", flex: 1 }}
        >
          <View
            style={{
              backgroundColor: "#282828",
              borderBottomColor: "#555",
              borderBottomWidth: 1,
              ...Platform.select({ ios: { height: 55 }, android: { height: 75 } })
            }}
          />
          <ScrollView>
            {/* <View style={{ position: "absolute", top: 0, left: 20, opacity: 0.05 }}>
                <SvgCss height="800" width="800" xml={global.shopIcon} />
              </View> */}
            {/* <Text style={{ color: "#fff", fontSize: 14, marginLeft: 10, fontStyle: "italic" }}>Offering Goods or Services on Wakanda is easy:</Text>*/}

            <View style={{ height: 50 }} />
            {/* <Text style={{ color: "#fff", fontSize: 14 }}>Here is how we help your business thrive:</Text> */}
            <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20 }}>
              <Text style={{ color: "#ccc", fontSize: 20, textAlign: "center", marginBottom: 10 }}>✔ Success.</Text>
              <Text style={{ color: "#ccc", fontSize: 16, textAlign: "center" }}>Your contacts are invited.</Text>
              <Text style={{ color: "#ccc", fontSize: 16, textAlign: "center", marginBottom: 10 }}>We will notify you once they follow you.</Text>
            </View>
          </ScrollView>
        </View>
      </SafeAreaView >
    );
  }
}

class BulletPoint extends PureComponent {
  render() {
    return (
      <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
        <Text style={[this.props.style, { marginRight: 6 }]}>{`\u2022`}</Text>
        <Text style={this.props.style}>{this.props.children}</Text>
      </View>
    );
  }
}

class CheckboxSelector extends PureComponent {
  render() {
    let height =
      this.props.text.length < 45
        ? 30
        : 45 + (this.props.text.length - 45) * 0.33;
    let circle = filled => {
      if (filled) {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#9999a4", backgroundColor: "#334", borderWidth: 0, borderRadius: 10 }}>
            <Text style={{ fontSize: 24, marginTop: -5, color: this.props.color || "#ccd" }}>✓</Text>
          </View>
        );
      } else {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#ccd", borderWidth: 0.7, borderRadius: 10 }} />
        );
      }
    };

    return (
      <View style={{ height: height, flexDirection: "row", alignItems: "center" }}>
        {circle(this.props.selected)}
        <Text style={{ marginLeft: 10, color: this.props.color || "#ccd", fontWeight: this.props.selected ? "normal" : "normal" }}>{this.props.text}</Text>
      </View>)
  }
}

class SearchBar extends Component {
  state = {
    searchTerm: "",
    countryCode: "+234",
    //countryCode: global.walletType == "ACN" ? "+233" : "+234",
    modalVisible: false,
  };

  callSearch = () => {
    let enteredNumber = this.state.searchTerm.replace(/[^\d]/g, "");
    if (enteredNumber.startsWith("00")) {
      alert("Invalid phone number"); return;
    }
    if (enteredNumber.startsWith("0")) {
      enteredNumber = enteredNumber.slice(1);
    }
    const phoneNumber = this.state.countryCode + enteredNumber;
    console.log("pn", phoneNumber, this.state.countryCode, enteredNumber)
    this.props.onPress(phoneNumber);
  }

  render() {
    const countryCodes = ["Nigeria (+234)", "Ghana (+233)"];
    return (
      <View
        style={{
          flexDirection: "row",
          alignSelf: "center",
          height: 40,
          width: 275,
          backgroundColor: "#aaa",
          borderRadius: 20,
          borderBottomWidth: 0,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            this.setState({ modalVisible: true });
          }}
          style={{
            alignItems: "center",
            alignSelf: "flex-start",
            padding: 1,
            borderRightWidth: 1,
            borderRightColor: "gray",
            marginRight: 4.5,
            width: 65,
            height: 40,
            justifyContent: "center",
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            borderColor: "gray"
          }}
        >
          <Text>{this.state.countryCode}</Text>
        </TouchableOpacity>
        <TextInput
          style={{
            alignSelf: "stretch",
            height: 40,
            marginBottom: 20,
            color: "#FFFFFF",
            backgroundColor: "#aaa",
            borderRadius: 33,
            borderBottomWidth: 0,
            color: "black",
            paddingLeft: 10,
            flex: 1
          }}
          placeholder="Search a phone number"
          placeholderTextColor="#000"
          keyboardType="numeric"
          underlineColorAndroid="transparent"
          underlineColor={"transparent"}
          returnKeyType="search"
          onChangeText={searchTerm => this.setState({ searchTerm })}
          onSubmitEditing={this.callSearch}
          ref={component => (this.myTextInput = component)}
          // clearButtonMode="always" //xx how set colour?
          value={this.state.text}
        />

        {this.state.searchTerm.length > 0 && (
          <TouchableOpacity
            style={{
              alignItems: "center",
              alignSelf: "flex-start",
              padding: 1,
              backgroundColor: "#A8CF45",
              marginLeft: 4.5,
              width: 47,
              height: 40,
              justifyContent: "center",
              borderTopEndRadius: 20,
              borderBottomEndRadius: 20,
              borderColor: "gray",
              borderLeftWidth: 1
            }}
            onPress={this.callSearch}
          >
            <Image
              style={{
                width: 18,
                height: 18,
                marginRight: 1
              }}
              source={require("../../images/icons/Send.png")}
            />
          </TouchableOpacity>
        )}
        <Modal
          animationType="slide"
          transparent={false}
          visible={this.state.modalVisible}
          onRequestClose={() => {
            this.setState({ modalVisible: false });
          }}
        >
          <TouchableOpacity
            onPress={() => this.setState({ modalVisible: false })}
            style={{
              height: 50,
              backgroundColor: "#000"
            }}
          />
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#000"
            }}
          >
            <View
              style={{
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <View style={{ paddingTop: 20 }}>
                <Text style={{ fontSize: 30, color: "white" }}>
                  Select Country
                  </Text>
              </View>
              <View style={{ marginTop: 0, marginBottom: 5 }} />
              <SectionList
                renderItem={({ item, index, section }) => (
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({
                        modalVisible: false,
                        countryCode: item.split("(")[1].split(")")[0]
                      });
                    }}
                    style={{
                      flex: 1,
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 20,
                      width: Dimensions.get("window").width,
                      backgroundColor: "#112",
                      borderWidth: 1,
                      borderColor: "#223"
                    }}
                  >
                    <Text
                      style={{ fontSize: 16, color: "white" }}
                      key={index}
                    >
                      {item}
                    </Text>
                  </TouchableOpacity>
                )}
                renderSectionHeader={({ section: { title } }) => (
                  <Text style={{ fontWeight: "bold" }}>{title}</Text>
                )}
                sections={[
                  {
                    title: "",
                    data: countryCodes
                  }
                ]}
                keyExtractor={(item, index) => item + index}
              />
            </View>
          </View>
          <TouchableOpacity
            style={{
              position: "absolute",
              borderWidth: 0,
              backgroundColor: "#556",
              borderColor: "rgba(0,0,0,0.2)",
              alignItems: "center",
              justifyContent: "center",
              width: 20,
              top: 20,
              left: 20,
              height: 25,
              borderRadius: 30
            }}
            onPress={() => this.setState({ modalVisible: false })}
          >
            <Image
              style={{ width: 25, height: 25, opacity: 1 }}
              source={require("../../images/icons/CancelBlack.png")}
            />
            {/* <Text style={{ fontSize: 26, color: "#666" }}>x</Text> */}
          </TouchableOpacity>
        </Modal>
      </View>
    );
  }
}
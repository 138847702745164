// ************************ HOW TO USE ***********************************************************

// Prop category1 sets the category1
// Prop category2 sets the category2
// Prop categories limit the range of sectable categories (optional).
//    -> It expects a "ServicesOffered" object (see object "categories" below)
// Prop onSelectCategory returns (category1, category2) to its parent on change (MANDATORY)
//    -> will return (null, null) when "x" is pressed.
// Prop text sets the text (default: "What are you looking for?"  (optional)
// Prop noText hides the text if true  (optional)
// Proo textSize sets the size of text (optional)
// Prop backgroundColor (optional, defaults to black)
// Prop backButton shows backButton instead of editButton
// Prop immediatelyRequestCategory2 (use carefully!) (MUST have category1 and MUST NOT have category2) will immediately open modal for cat2
// Prop showButton DEPRECATED shows a "Find worker" button. DEPRECATED (Was only used by home screen. (optional)
// Prop onPressButton DEPRECATED executes when button is pressed (optional)
// Prop initialCategories DEPRECATED sets the categories (optional)
//    -> It expects {category1, category2}

import React, { Component } from "react";
import {
  Platform,
  Text,
  SectionList,
  View,
  Modal,
  Image,
  ScrollView,
  TouchableOpacity,
  Dimensions
} from "react-native";
const gStyles = global.gStyles //    "../styles/gStyles";

export default class CategorySelector extends Component {
  state = {
    c1: null,
    modalVisible: false
  };

  images = {
    Electronics: require("../images/categories/Electronics.png"),
    Home: require("../images/categories/Home.png"),
    Fashion: require("../images/categories/Fashion.png"),
    Cars: require("../images/categories/Cars.png"),
    Food: require("../images/categories/Food.png"),
    Beauty: require("../images/categories/Beauty.png"),
    Professionals: require("../images/categories/Professionals.png"),
    Repair_Refill: require("../images/categories/Repair_Refill.png"),
    Handyman: require("../images/categories/Handyman.png"),
    QuickJobs: require("../images/categories/QuickJobs.png"),
    Health: require("../images/categories/Health.png"),
    Art: require("../images/categories/Art.png"),
    Housing: require("../images/categories/Housing.png"),
    Travel: require("../images/categories/Travel.png"),
    Commercial: require("../images/categories/Commercial.png")
  };

  async componentDidMount() {
    await global.timeout(250)
    if (this.props.immediatelyRequestCategory2) this.setState({ c1: this.props.category1, modalVisible: true })
    //DEPRECATED delete
    // if (this.props.initialCategories) {
    //   this.setState({
    //     category1: this.props.initialCategories.category1,
    //     category2: this.props.initialCategories.category2
    //   });
    // }
  }

  render() {
    const iWidth = Dimensions.get("window").width / 7;

    categories = global.categories

    renderCategories = () => {
      let keys = Object.keys(
        this.props.categories ? this.props.categories : global.categories
      );

      let array = keys.map(key => {
        return (
          <View
            key={key}
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <TouchableOpacity
              style={{ justifyContent: "center", alignItems: "center" }}
              onPress={() => {
                this.setState({ c1: key, modalVisible: true });
              }}
            >
              <Image
                style={{
                  width: iWidth,
                  height: iWidth,
                  margin: 2,
                  borderRadius: iWidth / 3
                }}
                source={this.images[key]}
              />
              <Text style={{ color: "white", fontSize: 10 }}>{key}</Text>
            </TouchableOpacity>
          </View>
        );
      });
      return array;
    };

    return (
      <View
        style={{
          height: this.props.category2 ? 75 : this.props.noText ? iWidth * 1.7 : 150,
          marginTop: Platform.OS == 'ios' ? 0 : (this.props.backButton && this.props.category2) ? 24 : 0,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: this.props.backgroundColor
            ? this.props.backgroundColor
            : "#000",
          alignItems: "stretch",
        }}
      >
        <View
          style={{
            flex: this.props.category2 ? 160 : 280,
            alignItems: "center"
          }}
        >
          {!this.props.category2 ? (
            <View
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <View
                style={{
                  backgroundColor: this.props.backgroundColor
                    ? this.props.backgroundColor
                    : "#000",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                {!this.props.noText && <Text
                  style={{
                    color: "#fff",
                    fontSize: this.props.textSize ? this.props.textSize : 16,
                    fontStyle: "italic",
                    marginTop: 15
                  }}
                >
                  {this.props.text
                    ? this.props.text
                    : "What are you looking for?"}
                </Text>}
                <View style={{ height: iWidth * 1.7 }}>
                  <ScrollView horizontal>{renderCategories()}</ScrollView>
                </View>
              </View>
            </View>
          ) : (
              <TouchableOpacity
                onPress={() => {
                  this.setState({ c1: null });
                  this.props.onSelectCategory(null, null);
                }}
                style={{
                  flexDirection: "row",
                  flex: 1,
                  alignItems: "center",
                  width: Dimensions.get("window").width
                }}
              >
                {!!this.props.backButton && <View
                  style={{
                    //flex: 11,
                    alignItems: "center",
                    justifyContent: "center",
                    height: 25,
                    width: 25,
                    marginLeft: 8,
                    backgroundColor: "#000"
                  }}
                >
                  <Text style={{ fontSize: 45, color: "#fff", marginTop: -18 }}>‹</Text>
                </View>}
                <View style={{ flex: 30, alignItems: "center" }}>
                  <Image
                    style={{
                      width: iWidth,
                      height: iWidth,
                      margin: 2,
                      borderRadius: iWidth / 3
                    }}
                    source={this.images[this.props.category1]}
                  />
                </View>
                <View style={{ flex: 80 }}>
                  <Text style={{ color: "white", fontSize: 10 }}>
                    {this.props.category1}
                  </Text>
                  <Text style={{ color: "white", fontSize: 18 }}>
                    {this.props.category2}
                  </Text>
                </View>
                {!this.props.backButton && <View
                  style={{
                    //flex: 11,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 18,
                    height: 25,
                    width: 25,
                    marginRight: 13,
                    backgroundColor: "#000"
                  }}
                >
                  {/* <Text style={{ color: "white", fontSize: 18, fontWeight: "bold" }}>X</Text> */}
                  <Image
                    style={{ width: 20, height: 20 }}
                    source={require("../images/icons/Change.png")}
                  />
                </View>}
              </TouchableOpacity>
            )}
        </View>
        {/* <View
          style={{
            flex: this.state.category2 ? 100 : 1
          }}
        >
          {!!this.props.showButton && this.state.category2 != null ? (
            <View
              style={{
                flex: 1,
                paddingLeft: 30,
                paddingRight: 30,
                alignItems: "center",
                justifyContent: "flex-start",
                width: Dimensions.get("window").width
              }}
            >
              <TouchableOpacity
                style={global.gStyles.button}
                onPress={() => {
                  this.props.onPressButton();
                }}
              >
                <Text style={global.gStyles.buttontext}>Find a Worker!</Text>
              </TouchableOpacity>
            </View>
          ) : (
            <View />
          )}
        </View> */}
        <Modal
          animationType="slide"
          transparent={false}
          visible={this.state.modalVisible}
          onRequestClose={() => {
            //alert("Modal has been closed.");
          }}
        >
          <View style={{ height: 56, backgroundColor: "#cccfd5" }} />
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#cccfd5"
            }}
          >
            <View style={{ marginBottom: 15, marginTop: 0 }}>
              <Text style={{ fontSize: 30, fontStyle: "italic" }}>
                Select a category
              </Text>
            </View>
            <View style={{ marginTop: 25, marginBottom: 5 }}>
              <Image
                style={{ width: 100, height: 100, borderRadius: 33 }}
                source={this.images[this.state.c1]}
              />
            </View>
            <View style={{ marginBottom: 10 }}>
              <Text style={{ fontSize: 24 }}>{this.state.c1}</Text>
            </View>
            <SectionList
              style={{ flex: 1 }}
              renderItem={({ item, index, section }) => (
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ modalVisible: false });
                    this.props.onSelectCategory(this.state.c1, item);
                  }}
                  style={{
                    flex: 1,
                    paddingTop: 10,
                    paddingBottom: 10,
                    alignItems: "center",
                    width: Dimensions.get("window").width,
                    backgroundColor: "white",
                    borderWidth: 1,
                    borderColor: "#cccfd5"
                  }}
                >
                  <Text style={{ fontSize: 18 }} key={index}>
                    {item}
                  </Text>
                </TouchableOpacity>
              )}
              renderSectionHeader={({ section: { title } }) => (
                <Text style={{ fontWeight: "bold" }}>{title}</Text>
              )}
              sections={
                this.props.categories
                  ? [{ data: this.props.categories[this.state.c1] }]
                  : [{ data: global.categories[this.state.c1] }]
              }
              keyExtractor={(item, index) => item + index}
            />
          </View>
          <TouchableOpacity
            style={{
              position: "absolute",
              borderWidth: 0,
              borderColor: "rgba(0,0,0,0.2)",
              alignItems: "center",
              justifyContent: "center",
              width: 40,
              top: 45,
              left: 15,
              height: 59,
              backgroundColor: "#cccfd5",
              borderRadius: 30
            }}
            onPress={() => this.setState({ modalVisible: false })}
          >
            {/* <Image
              style={{ width: 40, height: 40, opacity: 1 }}
              source={require("../images/icons/CancelWhite.png")}
            />
            <View
              style={{
                marginTop: -40,
                height: 40,
                borderRadius: 30,
                alignSelf: "stretch",
                backgroundColor: "rgba(0,0,0,0.2)"
              }}
            /> */}
            <Text style={{ fontSize: 28, color: "#000", fontWeight: "bold" }}>
              X
            </Text>
          </TouchableOpacity>
        </Modal>
      </View>
    );
  }
}

import React, { Component } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  TouchableHighlight,
  Keyboard,
  Dimensions,
  TouchableWithoutFeedback,
  ActivityIndicator,
} from "react-native";
import { WebView } from 'react-native-webview';
import firebase from "firebase";
import "firebase/firestore";

export default class FundMyAccount extends Component {
  state = {
    amount: false,
    firstName: "",
    lastName: "",
    number: null,
    validMonth: null,
    validYear: null,
    cvv: null,
    pin: null,
    otp: null,
    transactionReference: null,
    validationPrompt: "",
    activityIndicatorAnimating: false,
    viewValidation: false,
    viewWebview: false,
    webviewURL: 'https://www.african-founders.com'
  };

  fundNow = async () => {
    if (
      !this.state.amount ||
      !this.state.number ||
      !this.state.validMonth ||
      !this.state.validYear ||
      !this.state.cvv ||
      !this.state.pin
    ) {
      alert("Please fill all fields");
      return;
    }
    if (this.state.amount < 100 || !Number(this.state.number)) {
      alert("Select higher amount");
      return;
    }
    if (
      this.state.number.length < 13 ||
      this.state.number.length > 19 ||
      !Number(this.state.number)
    ) {
      alert("Enter correct card number");
      return;
    }
    if (this.state.pin.length < 3) {
      alert("PIN too short");
      return;
    }
    if (this.state.validMonth.length != 2) {
      alert("Enter 2-digit month");
      return;
    }
    if (this.state.validYear.length != 2) {
      alert("Enter 2-digit year");
      return;
    }
    if (this.state.cvv.length < 2) {
      alert("Enter correct CVV");
      return;
    }

    try {
      this.setState({ activityIndicatorAnimating: true });
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "fundAccount", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          firstname: this.state.firstName,
          lastname: this.state.lastName,
          expirymonth: this.state.validMonth,
          expiryyear: this.state.validYear,
          cardno: this.state.number,
          cvv: this.state.cvv,
          amount: this.state.amount,
          pin: this.state.pin,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });

      const r2 = await r.json();
      this.setState({ activityIndicatorAnimating: false });

      if (r2.msg === "SUCCESS") {
        if (r2.vbvURL) {
          this.setState({
            viewWebview: true,
            webviewURL: r2.vbvURL
          });
        } else if (r2.noValidationNeeded) {
          await this.setState({
            viewValidation: false,
            amount: false,
            validMonth: null,
            validYear: null,
            cvv: null,
            pin: null,
            number: null,
            activityIndicatorAnimating: false
          });
          await global.timeout(500)
          this.props.onCloseSuccess();
        } else {
          const transactionReference = r2.transactionReference;
          const validationPrompt = r2.validationPrompt;
          this.setState({
            viewValidation: true,
            transactionReference,
            validationPrompt
          });
        }
      } else {
        console.log("r2", r2)
        console.log("r2.error", r2.error)
        if (r2.msg == "FUNDING_ACCOUNT_FAILED" && r2.error) {
          alert(r2.error);
        } else alert("Error. Please check entered information");
      }
    } catch (error) {
      console.log(error);
      alert("Error");
      this.setState({ activityIndicatorAnimating: false });
    }
  };

  validateNow = async () => {
    if (!this.state.otp) {
      alert("Please enter code");
      return;
    }
    if (this.state.otp.length < 4) {
      alert("Enter valid code");
      return;
    }
    if (!this.state.transactionReference || !this.state.amount) {
      alert("Internal error");
      return;
    }

    try {
      this.setState({ activityIndicatorAnimating: true });
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "fundAccountValidation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          transactionReference: this.state.transactionReference,
          otp: this.state.otp,
          myUID: global.myUID,
          idToken: global.idToken,
          amount: this.state.amount
        })
      });

      const r2 = await r.json();

      if (r2.msg === "TRANSACTION_SUCCESSFUL") {
        await this.setState({
          viewValidation: false,
          amount: false,
          validMonth: null,
          validYear: null,
          cvv: null,
          pin: null,
          number: null,
          activityIndicatorAnimating: false
        });
        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });
        this.props.onCloseSuccess();
      } else if (r2.msg === "FUNDING_VALIDATION_NOT_SUCCESSFUL") {
        await this.setState({
          viewValidation: false,
          amount: false,
          validMonth: null,
          validYear: null,
          cvv: null,
          pin: null,
          number: null,
          activityIndicatorAnimating: false
        });
        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });
        this.props.onCloseFail(r2.bankMessage);
      }
    } catch (error) {
      console.log("Error caught in fundMyAccount");
      console.log(error);
      this.setState({
        viewValidation: false,
        amount: false,
        validMonth: null,
        validYear: null,
        cvv: null,
        pin: null,
        number: null,
        activityIndicatorAnimating: false
      });
      this.props.onClose();
    }
  };

  render() {
    const bRadius = 5;
    let mBottom = 100;
    if (Dimensions.get("window").height > 800) {
      mBottom = 200;
    }
    if (Dimensions.get("window").height < 750) {
      mBottom = 50;
    }
    if (Dimensions.get("window").height < 700) {
      mBottom = 0;
    }
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "rgba(0,0,0,0.5)",
          justifyContent: "space-between"
        }}
      >
        <View
          behavior="padding"
          enabled
          style={{
            paddingTop: 5,
            flex: 1,
            backgroundColor: "#ccc",
            justifyContent: "space-between",
            marginHorizontal: 20,
            marginVertical: 30,
            marginBottom: mBottom
          }}
        >
          {(!this.state.viewValidation && !this.state.viewWebview) ? (
            <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
              <View
                style={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  margin: 25
                }}
              >
                <Text
                  style={{
                    fontSize: 24,
                    alignSelf: "center",
                    marginBottom: 20
                  }}
                >
                  Fund My Wallet
                </Text>
                <View style={{ height: 200, alignSelf: "stretch" }}>
                  <View
                    style={{
                      height: 55,
                      alignSelf: "stretch",
                      justifyContent: "flex-end"
                    }}
                  >
                    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                      <Text style={{ fontSize: 12, color: "gray" }}>
                        Bank Card Number
                    </Text>
                      <Text style={{ fontSize: 12, color: "gray", fontStyle: "italic" }}>
                        Naira cards only
                      </Text>
                    </View>
                    <TextInput
                      onChangeText={number => {
                        this.setState({ number });
                      }}
                      // onFocus={() => {
                      //   this.scrollView.scrollTo({ x: 0, y: 50, animated: true });
                      // }}
                      keyboardType="numeric"
                      style={{
                        color: "#fff",
                        borderColor: "white",
                        borderWidth: 0,
                        borderRadius: bRadius,
                        padding: 5,
                        height: 32,
                        backgroundColor: "#223",
                        alignSelf: "stretch",
                        shadowColor: "#223",
                        shadowOpacity: 2,
                        shadowOffset: { width: 0, height: 3 }
                      }}
                      underlineColorAndroid="transparent"
                      maxLength={16}
                      //defaultValue={this.state.name}
                      placeholder=""
                      bordered
                    />
                  </View>
                  <View
                    style={{
                      height: 55,
                      alignSelf: "stretch",
                      alignItems: "flex-end",
                      flexDirection: "row"
                    }}
                  >
                    <View style={{ flex: 10 }}>
                      <Text style={{ fontSize: 12, color: "gray" }}>
                        First Name
                      </Text>
                      <TextInput
                        onChangeText={firstName => {
                          this.setState({ firstName });
                        }}
                        // onFocus={() => {
                        //   this.scrollView.scrollTo({ x: 0, y: 50, animated: true });
                        // }}
                        style={{
                          color: "#fff",
                          borderColor: "white",
                          borderWidth: 0,
                          borderRadius: bRadius,
                          padding: 5,
                          height: 32,
                          backgroundColor: "#223",
                          alignSelf: "stretch",
                          shadowOpacity: 2,
                          shadowOffset: { width: 0, height: 3 }
                        }}
                        underlineColorAndroid="transparent"
                        //maxLength={8}
                        //defaultValue={this.state.name}
                        placeholder=""
                        bordered
                      />
                    </View>
                    <View style={{ flex: 1 }} />
                    <View style={{ flex: 10 }}>
                      <Text style={{ fontSize: 12, color: "gray" }}>
                        Last Name
                      </Text>
                      <TextInput
                        onChangeText={lastName => {
                          this.setState({ lastName });
                        }}
                        // onFocus={() => {
                        //   this.scrollView.scrollTo({ x: 0, y: 50, animated: true });
                        // }}
                        style={{
                          color: "#fff",
                          borderColor: "white",
                          borderWidth: 0,
                          borderRadius: bRadius,
                          padding: 5,
                          height: 32,
                          backgroundColor: "#223",
                          alignSelf: "stretch",
                          shadowOpacity: 2,
                          shadowOffset: { width: 0, height: 3 }
                        }}
                        underlineColorAndroid="transparent"
                        //maxLength={8}
                        //defaultValue={this.state.name}
                        placeholder=""
                        bordered
                      />
                    </View>
                  </View>

                  <View style={{ flexDirection: "row" }}>
                    <View
                      style={{
                        height: 55,
                        flex: 10,
                        alignSelf: "stretch",
                        justifyContent: "flex-end"
                      }}
                    >
                      <Text style={{ fontSize: 12, color: "gray" }}>
                        Valid Until
                      </Text>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          marginRight: 15
                        }}
                      >
                        <View
                          style={{
                            height: 30,
                            flex: 10,
                            alignSelf: "stretch",
                            justifyContent: "flex-end"
                          }}
                        >
                          <TextInput
                            onChangeText={validMonth => {
                              this.setState({ validMonth });
                            }}
                            // onFocus={() => {
                            //   this.scrollView.scrollTo({ x: 0, y: 50, animated: true });
                            // }}
                            keyboardType="numeric"
                            style={{
                              color: "#fff",
                              borderColor: "white",
                              borderWidth: 0,
                              borderRadius: bRadius,
                              padding: 5,
                              height: 32,
                              backgroundColor: "#223",
                              alignSelf: "stretch",
                              shadowOpacity: 2,
                              shadowOffset: { width: 0, height: 3 }
                            }}
                            underlineColorAndroid="transparent"
                            maxLength={2}
                            //defaultValue={this.state.name}
                            placeholder="MM"
                            placeholderTextColor="grey"
                            bordered
                          />
                        </View>
                        <View
                          style={{
                            flex: 1,
                            alignSelf: "stretch",
                            justifyContent: "flex-end"
                          }}
                        />
                        <View
                          style={{
                            height: 30,
                            flex: 10,
                            alignSelf: "stretch",
                            justifyContent: "flex-end"
                          }}
                        >
                          <TextInput
                            onChangeText={validYear => {
                              this.setState({ validYear });
                            }}
                            // onFocus={() => {
                            //   this.scrollView.scrollTo({ x: 0, y: 50, animated: true });
                            // }}
                            keyboardType="numeric"
                            style={{
                              color: "#fff",
                              borderColor: "white",
                              borderWidth: 0,
                              borderRadius: bRadius,
                              padding: 5,
                              height: 32,
                              backgroundColor: "#223",
                              alignSelf: "stretch",
                              shadowOpacity: 2,
                              shadowOffset: { width: 0, height: 3 }
                            }}
                            underlineColorAndroid="transparent"
                            maxLength={2}
                            //defaultValue={this.state.name}
                            placeholder="YY"
                            placeholderTextColor="grey"
                            bordered
                          />
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        height: 55,
                        flex: 8,
                        alignSelf: "stretch",
                        justifyContent: "flex-end"
                      }}
                    >
                      <Text style={{ fontSize: 12, color: "gray" }}>CVV</Text>
                      <TextInput
                        onChangeText={cvv => {
                          this.setState({ cvv });
                        }}
                        // onFocus={() => {
                        //   this.scrollView.scrollTo({ x: 0, y: 50, animated: true });
                        // }}
                        keyboardType="numeric"
                        style={{
                          color: "#fff",
                          borderColor: "white",
                          borderWidth: 0,
                          borderRadius: bRadius,
                          padding: 5,
                          height: 32,
                          backgroundColor: "#223",
                          alignSelf: "stretch",
                          shadowOpacity: 2,
                          shadowOffset: { width: 0, height: 3 }
                        }}
                        underlineColorAndroid="transparent"
                        maxLength={4}
                        //defaultValue={this.state.name}
                        placeholder=""
                        bordered
                      />
                    </View>
                  </View>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flex: 70 }}>
                    <Text
                      style={{
                        fontSize: 12,
                        color: "gray",
                        alignSelf: "flex-start"
                      }}
                    >
                      Amount (N)
                    </Text>
                    <TextInput
                      onChangeText={amount => {
                        this.setState({ amount });
                      }}
                      // onFocus={() => {
                      //   this.scrollView.scrollTo({ x: 0, y: 50, animated: true });
                      // }}
                      keyboardType="numeric"
                      style={{
                        color: "#000",
                        borderColor: "white",
                        borderWidth: 0,
                        borderRadius: bRadius,
                        padding: 5,
                        height: 32,
                        backgroundColor: "#fff",
                        alignSelf: "stretch",
                        shadowOpacity: 2,
                        shadowOffset: { width: 0, height: 3 },
                        shadowColor: "#fff"
                      }}
                      underlineColorAndroid="transparent"
                      //maxLength={8}
                      //defaultValue={this.state.name}
                      placeholder=""
                      bordered
                    />
                  </View>
                  <View style={{ flex: 8 }} />
                  <View style={{ flex: 40 }}>
                    <Text
                      style={{
                        fontSize: 12,
                        color: "gray",
                        alignSelf: "flex-start"
                      }}
                    >
                      PIN
                    </Text>
                    <TextInput
                      onChangeText={pin => {
                        this.setState({ pin });
                      }}
                      // onFocus={() => {
                      //   this.scrollView.scrollTo({ x: 0, y: 50, animated: true });
                      // }}
                      keyboardType="numeric"
                      secureTextEntry={true}
                      style={{
                        color: "#666",
                        borderColor: "grey",
                        borderWidth: 0,
                        borderRadius: bRadius,
                        padding: 5,
                        height: 32,
                        backgroundColor: "#223",
                        alignSelf: "stretch",
                        shadowOpacity: 2,
                        shadowOffset: { width: 0, height: 3 },
                        shadowColor: "#000"
                      }}
                      underlineColorAndroid="transparent"
                      maxLength={6}
                      //defaultValue={this.state.name}
                      placeholder=""
                      bordered
                    />
                  </View>
                </View>
                <Text
                  style={{
                    fontSize: 12,
                    marginVertical: 5,
                    color: "grey",
                    alignSelf: "flex-start"
                  }}
                >
                  My wallet balance: N {Math.round(this.props.walletBalance)}
                </Text>
                <View style={{ height: 20 }} />
                {!this.state.activityIndicatorAnimating && (
                  <TouchableOpacity
                    style={[
                      global.gStyles.button,
                      {
                        margin: 21,
                        flexDirection: "row",
                        justifyContent: "center"
                      }
                    ]}
                    onPress={() => {
                      if (!this.state.activityIndicatorAnimating) {
                        this.fundNow();
                      }
                    }}
                  >
                    <ActivityIndicator
                      size="small"
                      color="#000"
                      //animating={this.state.activityIndicatorAnimating}
                      style={{
                        opacity: this.state.activityIndicatorAnimating
                          ? 1.0
                          : 0.0
                      }}
                      animating={true}
                    />

                    <Text
                      style={[
                        global.gStyles.buttontext,
                        { marginRight: 30, marginLeft: 10 }
                      ]}
                    >
                      Fund my Wallet
                    </Text>
                  </TouchableOpacity>
                )}
                <ActivityIndicator
                  size="small"
                  color="#000"
                  //animating={this.state.activityIndicatorAnimating}
                  style={{
                    opacity: this.state.activityIndicatorAnimating ? 1.0 : 0.0
                  }}
                  animating={true}
                />
                <View style={{ height: 30 }} />
                <TouchableOpacity
                  onPress={() => {
                    this.props.onClose();
                  }}
                  style={{ alignSelf: "center" }}
                >
                  <Text style={{ color: "black", fontSize: 14 }}>Cancel</Text>
                </TouchableOpacity>
              </View>
            </TouchableWithoutFeedback>
          ) :
            this.state.viewValidation ? (
              <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
                <View
                  style={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    margin: 25
                  }}
                >
                  <Text
                    style={{
                      fontSize: 24,
                      alignSelf: "center",
                      marginBottom: 40
                    }}
                  >
                    Fund My Wallet
                </Text>
                  <Text style={{ fontSize: 12, color: "#000" }}>
                    Your bank requires a passcode to validate this transaction.
                </Text>
                  <Text style={{ fontSize: 12, color: "#000" }}>
                    {this.state.validationPrompt}
                  </Text>
                  <View
                    style={{
                      height: 60,
                      alignSelf: "stretch",
                      justifyContent: "center",
                      margin: 21
                    }}
                  >
                    <View
                      style={{
                        height: 55,
                        alignSelf: "stretch",
                        justifyContent: "flex-end"
                      }}
                    >
                      <Text style={{ fontSize: 12, color: "gray" }}>
                        Validation Code
                    </Text>
                      <TextInput
                        key={"valI"}
                        onChangeText={otp => {
                          this.setState({ otp });
                        }}
                        // onFocus={() => {
                        //   this.scrollView.scrollTo({ x: 0, y: 50, animated: true });
                        // }}
                        keyboardType="numeric"
                        style={{
                          color: "#fff",
                          borderColor: "white",
                          borderWidth: 0,
                          borderRadius: bRadius,
                          padding: 5,
                          height: 32,
                          backgroundColor: "#223",
                          alignSelf: "stretch",
                          shadowOpacity: 2,
                          shadowOffset: { width: 0, height: 3 }
                        }}
                        underlineColorAndroid="transparent"
                        maxLength={16}
                        defaultValue={""}
                        placeholder=""
                        bordered
                      />
                    </View>
                  </View>

                  <View style={{ height: 20 }} />
                  <ActivityIndicator
                    size="small"
                    color="#000"
                    //animating={this.state.activityIndicatorAnimating}
                    style={{
                      opacity: this.state.activityIndicatorAnimating ? 1.0 : 0.0
                    }}
                    animating={true}
                  />
                  {!this.state.activityIndicatorAnimating && (
                    <TouchableOpacity
                      style={[
                        global.gStyles.button,
                        {
                          margin: 21,
                          flexDirection: "row",
                          justifyContent: "center"
                        }
                      ]}
                      onPress={() => {
                        if (!this.state.activityIndicatorAnimating) {
                          this.validateNow();
                        }
                      }}
                    >
                      <ActivityIndicator
                        size="small"
                        color="#000"
                        //animating={this.state.activityIndicatorAnimating}
                        style={{
                          opacity: this.state.activityIndicatorAnimating
                            ? 1.0
                            : 0.0
                        }}
                        animating={true}
                      />

                      <Text
                        style={[
                          global.gStyles.buttontext,
                          { marginRight: 30, marginLeft: 10 }
                        ]}
                      >
                        Fund my Wallet
                    </Text>
                    </TouchableOpacity>
                  )}
                  <View style={{ height: 30 }} />
                  <TouchableOpacity
                    onPress={() => {
                      if (!this.state.activityIndicatorAnimating) {
                        this.props.onClose();
                      }
                    }}
                    style={{ alignSelf: "center" }}
                  >
                    <Text style={{ color: "black", fontSize: 14 }}>Cancel</Text>
                  </TouchableOpacity>
                </View>
              </TouchableWithoutFeedback>
            ) : (
                <View
                  style={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    margin: 25,
                    flex: 26
                  }}
                >
                  <Text
                    style={{
                      fontSize: 24,
                      alignSelf: "center",
                      marginBottom: 25
                    }}
                  >
                    Fund My Wallet
                    </Text>
                  <Text style={{ fontSize: 12, color: "#000" }}>
                    Your bank requires additional verification.
                    </Text>

                  <WebView
                    originWhitelist={['*']}
                    source={{ uri: this.state.webviewURL }}
                    style={{ marginTop: 20, height: Dimensions.get("window").height - 285, width: Dimensions.get("window").width - 60, alignSelf: "stretch" }}
                    useWebKit={true}
                  />
                  <View style={{ height: 30 }} />
                  <TouchableOpacity
                    onPress={() => {
                      if (!this.state.activityIndicatorAnimating) {
                        this.props.onClose();
                      }
                    }}
                    style={{ alignSelf: "center" }}
                  >
                    <Text style={{ color: "black", fontSize: 14 }}>Close</Text>
                  </TouchableOpacity>
                </View>
              )
          }

          <View style={{ flex: 1 }} />
        </View>
      </View>
    );
  }
}

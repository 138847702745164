
import React, { Component } from "react";
import {
  TouchableOpacity,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  View,
  Image,
  Alert,
  Dimensions,
  ActivityIndicator,
  ScrollView
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import CachedImage from "../../shared/CachedImage"

export default class BOSettingsScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    const title = navigation.getParam("title", "Notification Preferences");
    return {
      tabBarVisible: false,
      headerTransparent: true,
      title,
      headerTitleStyle: {
        fontWeight: "bold",
        color: "#A8CF45",
      }
    };
  };

  state = {
    settings: [],

    loading: true,
    error: null,
  };

  labels = {
    boNotifyUpdates: "Updates, Features",
    boNotifySellerUpdates: "Seller Updates",
    boNotifyDeals: "Deals",
    boNotifyMotivation: "Motivational Messages",
    boNotifyHealth: "Health Tips",
    boNotifyBreakingNews: "Breaking News",
  }
  alertMessages = {
    boNotifyUpdates: "You will miss updates on major Wakanda features",
    boNotifySellerUpdates: "You will miss important information on how to sell on Wakanda",
  }

  constructor() {
    super();
    const firestore = firebase.firestore();
  }

  async componentDidMount() {
    this.loadMySettings()
    // this.props.navigation.setParams({ title: "Find a Worker" });
  }

  loadMySettings = async () => {
    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "settings", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          action: "get",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS" && r2.response) {
        if (r2.response?.labels) this.labels = r2.response?.labels
        if (r2.response?.alertMessages) this.alertMessages = r2.response?.alertMessages
        this.setState({ settings: r2.response?.settings, loading: false })
      } else return alert("Backend error")
    } catch (error) {
      global.warn(error, "loadMySettings")
    }
  }

  changeSetting = async (key) => {
    const data = {}
    data[key] = (this.state.settings[key] === false)
    const msg = this.alertMessages[key]
    if (data[key] || !msg)
      return this.executeChangeSetting(data)
    else return Alert.alert("Sure?", msg,
      [{ text: "Cancel", onPress: () => { } },
      { text: "Yes", onPress: async () => { this.executeChangeSetting(data) } }],
      { cancelable: true }
    );
  }
  executeChangeSetting = async (data) => {
    try {
      this.setState(data)
      const r = await fetch(global.cloudFunctionURL + "settings", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          action: "set",
          data,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        this.loadMySettings()
      } else return alert("Error updating")
    } catch (error) {
      global.warn(error, "loadMySettings")
      alert("Error - check your internet connection")
    }
  }


  renderSwitch = (activated) => {
    if (activated) {
      return (
        <View style={{ width: 38.4, height: 21.4, borderRadius: 10.7, borderWidth: 0.7, backgroundColor: "#aca", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", padding: 2.5 }}>
          <View style={{ width: 15, height: 15, borderRadius: 7.5, backgroundColor: "#272", opacity: 0.5 }} />
        </View>
      );
    } else {
      return (
        <View style={{ width: 37, height: 20, borderRadius: 10, borderWidth: 0.7, borderColor: "#777778", flexDirection: "row", alignItems: "center", padding: 2 }}>
          <View style={{ width: 15, height: 15, backgroundColor: "#777778", borderRadius: 7.5 }} />
        </View>

      );
    }

  }

  renderMain = () => {
    const keys = Object.keys(this.labels)
    return (
      <View style={{ backgroundColor: "#000", flex: 1, height: "100%" }}>
        <View style={{ backgroundColor: "#000", borderBottomColor: "#55555a", borderBottomWidth: 1, ...Platform.select({ ios: { height: Dimensions.get("window").height >= 800 ? 88 : 65 }, android: { height: 85 } }) }} />
        <Text style={{ color: "#bbbbc5", fontSize: 15, fontWeight: "bold", margin: 20 }}>What do you want to be notified about?</Text>
        <ScrollView>
          {keys.map(key => {
            return (<TouchableOpacity key={key + (this.state.settings[key] ? "Set" : "")}
              onPress={async () => { this.changeSetting(key) }}
              style={{ flexDirection: "row", paddingHorizontal: 10, backgroundColor: "#202020", height: 40, justifyContent: "space-between", alignItems: "center", marginBottom: 10, marginHorizontal: 20, paddingLeft: 12, borderRadius: 8 }}>
              <Text style={{ color: "#bbbbc5", fontSize: 16 }}>{this.labels[key] || "?"}</Text>
              {this.renderSwitch(this.state.settings[key] !== false)}
              {/* <Text style={{ color: "#888892", fontSize: 24 }}>{">"}</Text> */}
            </TouchableOpacity>)
          })}
        </ScrollView>
      </View >)
  }

  render() {
    if (this.state.error) return (
      <View style={{ height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: "#131313" }}>
        <Text style={{ color: "#aab" }}>{this.state.error}</Text>
      </View >
    );
    if (this.state.loading) return (
      <View style={{ height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: "#131313" }}>
        <ActivityIndicator size="small" color="#aab" />
      </View>
    );
    return this.renderMain()
  }
}

const styles = ({
  text: {
    color: "#999",
    borderBottomColor: "#667",
    borderBottomWidth: 1,
    paddingHorizontal: 40,
    paddingVertical: 20
  },

  buttontext: {
    fontWeight: "bold",
    color: "#000"
  },
  button: {
    alignSelf: "stretch",
    alignItems: "center",
    padding: 20,
    backgroundColor: "#A8CF45",
    borderRadius: 5,
    marginHorizontal: 10,
    width: 260
  }
});

import React, { Component } from 'react';
import { Text, View, StyleSheet, TouchableOpacity, TextInput, Platform, Image, ScrollView, Dimensions } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient'
export default class JobScreen3 extends Component {
    state = {
        multipartPayment: true,
        showMultiPaymentForm: false,
        currency: 'NGN',
        editValues: false,
    }

    /*    componentDidMount = async () => {
           let a = this.props.itemCost
           let b = this.props.deliveryFee
           let c = this.props.itemCost + this.props.deliveryFee 
           console.log("item cost received via componentDidMountis: ", this.props.itemCost)
           await this.setState({
               multipartPayment: this.props.multipartPayment,
               delivery: this.props.delivery,
               deliveryFee: this.props.deliveryFee,
               newDeliveryFee: this.props.deliveryFee,
               itemCost: this.props.itemCost,
               newItemCost: this.props.itemCost
           })
    
       }
    */

    componentDidMount = async () => {
        await global.timeout(500)
        if (this.props.itemCost == 0) this.setState({ editValues: true })
    }


    pay(num) {
        return this.props.itemCost - num
    }


    onChanged = async (text) => {
        try {
            if (!Number(text) && Number(text) != 0) return alert("Please enter numbers only")
            if (Number(text) < 0) return alert("Please enter positive numbers")

            if (Number(text) > this.props.itemCost) {
                this.props.payInParts(0, this.props.itemCost, this.state.multipartPayment)
                alert('Installment must be smaller than charge');
                await this.setState({ showMultiPaymentForm: false })
                await global.timeout(10)
                await this.setState({ showMultiPaymentForm: true })

                return;
            }

            this.props.payInParts(Number(text), this.props.itemCost - Number(text), this.state.multipartPayment)
        } catch (err) {
            console.log('error craeting multi-part payment', err)
        }
    }

    changePrice2 = async (text) => {

        if (!Number(text) && Number(text) != 0) return alert("Please enter numbers only")
        if (Number(text) < 0) return alert("Please enter positive numbers")
        this.props.editPriceDetails(Number(text), this.props.deliveryFee, Number(text) + this.props.deliveryFee)
        this.setState({ priceACN: Number(text), priceLocal: this.localAmount(Number(text)) })
        if (Number(text) < this.props.installmentOne) {
            this.props.payInParts(0, this.props.itemCost, this.state.multipartPayment)
            alert('Installment must be smaller than charge');
            await this.setState({ showMultiPaymentForm: false })
            await global.timeout(10)
            await this.setState({ showMultiPaymentForm: true })

            return;
        }
    }

    changeDeliveryFee2 = async (text) => {

        if (!Number(text) && Number(text) != 0) return alert("Please enter numbers only")
        if (Number(text) < 0) return alert("Please enter positive numbers")

        this.props.editPriceDetails(this.props.itemCost, Number(text), Number(text) + this.props.itemCost)
        this.setState({ deliveryACN: (Number(text)), deliveryLocal: this.localAmount(Number(text)) })

    }

    localAmount = (amountACN) => {
        let localAmount = amountACN * global.xRates[global.myLocalCurrency]
        return Math.round(localAmount)
        //return Math.round(localAmount * 100) / 100
        //return localAmount
    }

    render() {
        const screenWidth = Math.round(Dimensions.get('window').width);
        const currency = global.currencySymbols[this.props.currency]
        const myLocalCurrency = global.currencySymbols[global.myLocalCurrency]
        const showACN = this.props.currency == "ACN"
        //console.log(' disocunt in screen3 ', this.props.discount)
        return (
            <View>
                <ScrollView ref={ref => (this.theScrollView = ref)}>
                    {/* <KeyboardAvoidingView> */}
                    <View style={{
                        flex: 1, alignItems: 'center',
                        width: screenWidth, paddingTop: 15
                    }}>

                        <Text style={styles.text}> Charge </Text>


                        <View style={{
                            borderWidth: 2, borderStyle: 'dashed', borderColor: '#00FF99', borderRadius: 60,
                            alignItems: 'center', justifyContent: 'center', width: showACN ? screenWidth : 280, height: (showACN && this.state.editValues) ? 200 : 180,
                        }}>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>

                                {/* {!this.state.editValues && <TouchableOpacity
                                    style={{ position: 'absolute', left: showACN ? 120 : 95, top: Platform.OS == "ios" ? -40 : 0 }}
                                    onPress={() => {
                                        if (this.state.editValues == true) { this.setState({ editValues: false }) }
                                        if (this.state.editValues == false) { this.setState({ editValues: true }) }
                                    }}>
                                    <LinearGradient
                                        colors={['#707070', '#282828']}
                                        style={{
                                            width: 50, borderWidth: 1, borderColor: '#00FF99', borderRadius: 25,
                                            height: 50, justifyContent: 'center', marginTop: 10, alignItems: 'center'
                                        }}>

                                        <Image
                                            style={{ height: 20, width: 20 }}
                                            source={require('./assets/pencil-edit-button-white.png')} />


                                    </LinearGradient>
                                </TouchableOpacity>} */}
                            </View>

                            <View style={{ paddingVertical: 15, borderBottomWidth: 1, borderColor: '#ffff00', width: showACN ? screenWidth - 50 : 200 }}>
                                {!this.state.editValues && <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
                                    <Text style={[styles.text4, { color: showACN ? "#00FF99" : "#fff" }]}>Item Cost </Text>
                                    <Text style={[styles.text4, { color: showACN ? "#00FF99" : "#fff" }]}>{Math.round(this.props.itemCost)} {currency}</Text>
                                    {showACN && <Text style={[styles.text4, { color: "#00FF99" }]}>{this.localAmount(this.props.itemCost)} {myLocalCurrency}</Text>}
                                </View>}
                                {!this.state.editValues && this.props.showDiscount && <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 14 }}>
                                    <Text style={[styles.text4, { color: showACN ? "#00FF99" : '#ace600' }]}>Discount </Text>
                                    <Text style={[styles.text4, { color: showACN ? "#00FF99" : '#ace600' }]}>{this.props.discount} {currency}</Text>
                                    {showACN && <Text style={[styles.text4, { color: "#00FF99" }]}>{this.localAmount(this.props.discount)} {myLocalCurrency}</Text>}
                                </View>}




                                {!!this.state.editValues &&
                                    <View style={{ flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                                        <View style={{ width: 120 }}><Text style={[styles.text4, { color: showACN ? '#00FF99' : '#fff' }]}>Charge {!showACN && currency}</Text></View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <TextInput
                                                //placeholder={`${this.props.itemCost}`}
                                                //placeholderTextColor={'#999'}
                                                keyboardType={Platform.OS == "ios" ? 'numeric' : 'default'}
                                                multiline={true}
                                                onChangeText={(text) => {
                                                    this.changePrice2(text)
                                                }}
                                                value={this.props.itemCost ? this.props.itemCost + "" : ""}
                                                maxLength={10}
                                                style={{
                                                    borderBottomWidth: 1, borderBottomColor: '#00FF99', height: 28, width: 80, color: '#00FF99',
                                                    fontSize: 16, paddingVertical: 0, textAlign: 'right'
                                                }}
                                            ></TextInput>
                                            <Text style={styles.text4ACN}>{showACN && currency}</Text>
                                        </View>

                                        {showACN && <View style={{ width: 90, flexDirection: "row" }}>
                                            <TextInput
                                                //placeholder={`${this.props.itemCost}`}
                                                //placeholderTextColor={'#999'}
                                                keyboardType={Platform.OS == "ios" ? 'numeric' : 'default'}
                                                multiline={true}
                                                onChangeText={(text) => {
                                                    this.changePrice2(global.acnAmount(Number(text)))
                                                }}
                                                value={this.props.itemCost ? global.localAmount(this.props.itemCost) + "" : ""}
                                                maxLength={10}
                                                style={{
                                                    borderBottomWidth: 1, borderBottomColor: '#00FF99', height: 28, width: 80, color: '#00FF99',
                                                    fontSize: 16, paddingVertical: 0, textAlign: 'right'
                                                }}
                                            ></TextInput>
                                            <Text style={showACN ? styles.text4ACN : styles.text4}>{myLocalCurrency}</Text></View>}
                                    </View>}


                                {!!this.state.editValues &&
                                    <View style={{ flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', marginTop: 10 }}>
                                        <View style={{ width: 120 }}><Text style={[styles.text4, { color: showACN ? '#00FF99' : '#fff' }]}>Delivery Fee {!showACN && currency}</Text></View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <TextInput
                                                //placeholder={`${this.props.deliveryFee.toString()}`}
                                                //placeholderTextColor={'#999'}
                                                keyboardType={Platform.OS == "ios" ? 'numeric' : 'default'}
                                                multiline={true}
                                                onChangeText={(text) => this.changeDeliveryFee2(text)}
                                                //value={this.state.newDeliveryFee}
                                                maxLength={10}
                                                style={{
                                                    borderBottomWidth: 1, borderBottomColor: '#00FF99', height: 28, width: 80, color: '#00FF99',
                                                    fontSize: 16, paddingVertical: 0, textAlign: 'right'
                                                }}
                                                value={this.props.deliveryFee ? this.props.deliveryFee + "" : ""}
                                            ></TextInput>
                                            <Text style={styles.text4ACN}>{showACN && currency}</Text>
                                        </View>
                                        {showACN && <View style={{ width: 90, flexDirection: "row" }}>
                                            <TextInput
                                                //placeholder={`${this.props.itemCost}`}
                                                //placeholderTextColor={'#999'}
                                                keyboardType={Platform.OS == "ios" ? 'numeric' : 'default'}
                                                multiline={true}
                                                onChangeText={(text) => {
                                                    this.changeDeliveryFee2(global.acnAmount(Number(text)))
                                                }}
                                                value={this.props.deliveryFee ? global.localAmount(this.props.deliveryFee) + "" : ""}
                                                //value={this.state.priceACN ? this.state.priceACN + "" : ""}
                                                maxLength={10}
                                                style={{
                                                    borderBottomWidth: 1, borderBottomColor: '#00FF99', height: 28, width: 80, color: '#00FF99',
                                                    fontSize: 16, paddingVertical: 0, textAlign: 'right'
                                                }}
                                            ></TextInput>
                                            <Text style={showACN ? styles.text4ACN : styles.text4}>{myLocalCurrency}</Text></View>}

                                    </View>}

                                {!showACN && !!this.state.editValues && this.props.showDiscount && this.props.discount != 0 && <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 18 }}>
                                    <Text style={[styles.text4, { color: showACN ? "#00FF99" : '#ace600' }]}>Discount </Text>
                                    <Text style={[styles.text4, { color: showACN ? "#00FF99" : '#ace600' }]}> - {this.props.discount} {currency}</Text>
                                </View>}

                                {!!showACN && !!this.state.editValues && this.props.showDiscount && this.props.discount != 0 && <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 18 }}>
                                    <Text style={[styles.text4, { color: showACN ? "#00FF99" : '#ace600' }]}>Discount </Text>
                                    <Text style={[styles.text4, { color: showACN ? "#00FF99" : '#ace600' }]}> - {this.props.discount} {currency}</Text>
                                    <Text style={[styles.text4, { color: showACN ? "#00FF99" : '#ace600' }]}> - {this.localAmount(this.props.discount)} {currency}</Text>
                                </View>}
                            </View>
                            {!showACN ? (<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 10, ...(showACN && { width: screenWidth - 60 }) }}>
                                <Text style={[styles.text2, { paddingHorizontal: showACN ? 0 : 40 }]}>
                                    Total:
                                </Text>
                                <Text style={[styles.text2, { paddingHorizontal: showACN ? 0 : 40 }]}>
                                    {(this.props.itemCost + this.props.deliveryFee - parseInt(this.props.discount || 0)) + " " + currency}
                                </Text>
                            </View>) :
                                (<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 10, width: screenWidth - 50 }}>
                                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: 225 }}>
                                        <Text style={[styles.text2ACN]}>
                                            Total:
                                        </Text>
                                        <Text style={[styles.text2ACN, { textAlign: "right" }]}>
                                            {Math.round(this.props.itemCost + this.props.deliveryFee - parseInt(this.props.discount || 0))} {currency}
                                        </Text>
                                    </View>
                                    <View style={{ width: screenWidth - 50 - 225 }}>
                                        <Text style={[styles.text2ACN, { textAlign: "right" }]}>{(this.props.itemCost + this.props.deliveryFee - parseInt(this.props.discount || 0)) > 0 && this.localAmount(this.props.itemCost + this.props.deliveryFee - parseInt(this.props.discount || 0)) + " " + myLocalCurrency}</Text>
                                    </View>
                                </View>)}
                        </View>

                        {!this.state.editValues && <TouchableOpacity
                            style={{ position: 'absolute', right: showACN ? 15 : 65, top: 10 }}
                            onPress={() => {
                                if (this.state.editValues == true) { this.setState({ editValues: false }) }
                                if (this.state.editValues == false) { this.setState({ editValues: true }) }
                            }}>
                            <LinearGradient
                                colors={['#707070', '#282828']}
                                style={{
                                    width: 50, borderWidth: 1, borderColor: '#00FF99', borderRadius: 25,
                                    height: 50, justifyContent: 'center', marginTop: 10, alignItems: 'center'
                                }}>

                                <Image
                                    style={{ height: 20, width: 20 }}
                                    source={require('./assets/pencil-edit-button-white.png')} />


                            </LinearGradient>
                        </TouchableOpacity>}

                        {this.state.multipartPayment && !this.props.showDiscount &&
                            <View style={{ alignItems: 'center', marginTop: 20, width: '100%' }}>
                                <Text style={{ color: '#11AA22' }}>Pay Parts Upfront</Text>
                                <TouchableOpacity
                                    onPress={() => {
                                        if (!this.state.showMultiPaymentForm) { this.setState({ showMultiPaymentForm: true }) };
                                        if (this.state.showMultiPaymentForm) { this.setState({ showMultiPaymentForm: false }) };
                                    }}>
                                    <LinearGradient
                                        colors={['#707070', '#282828']}
                                        style={{
                                            width: 60, borderWidth: 1, borderColor: '#00FF99', borderRadius: 30,
                                            height: 60, justifyContent: 'center', marginTop: 10, alignItems: 'center'
                                        }}>
                                        <Text style={styles.text4} style={{ color: '#00FF99' }}>▽</Text>
                                    </LinearGradient>
                                </TouchableOpacity>
                            </View>}

                        {this.state.showMultiPaymentForm &&
                            <View style={{ justifyContent: 'center', alignItems: 'center', }}>
                                <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 10, flexDirection: 'row' }}>
                                    <View style={{ justifyContent: 'center', alignItems: 'center', width: '40%' }}>
                                        <Text style={{ color: '#AAFF99', paddingBottom: 10, color: '#00FF99' }}>1st Installment ({currency})</Text>
                                        <TextInput
                                            keyboardType={Platform.OS == "ios" ? 'numeric' : 'default'}
                                            onChangeText={(text) => this.onChanged(text)}
                                            onFocus={() => { this.theScrollView?.scrollToEnd?.({ animated: false }); }}
                                            value={this.props.installmentOne.toString()}
                                            maxLength={10}
                                            multiline={true}
                                            placeholder={"Type here"}
                                            placeholderTextColor={'#666'}
                                            style={{
                                                borderBottomWidth: 1, borderBottomColor: '#00FF99', height: 30, width: 100, color: '#00FF99',
                                                fontSize: 16, paddingVertical: 0, textAlign: 'center'
                                            }}></TextInput>

                                    </View>
                                    <Text style={{ fontSize: 50, color: '#AAFF99' }}>|</Text>
                                    <View style={{ alignItems: 'center', justifyContent: 'center', height: 60, width: '40%' }}>
                                        <Text style={{ color: '#AAFF99', paddingBottom: 20 }}>2nd Installment ({currency})</Text>
                                        <Text style={{ color: '#AAFF99', paddingBottom: 5, fontSize: 16 }}>{this.props.itemCost - this.props.installmentOne}</Text>
                                    </View>
                                </View>
                                <TouchableOpacity
                                    style={{ backgroundColor: 'green', width: 100, alignItems: 'center', justifyContent: 'center', marginTop: 20, borderRadius: 10 }}
                                    onPress={async () => {
                                        this.props.payInParts(0, this.props.itemCost, this.state.multipartPayment)
                                        await this.setState({ showMultiPaymentForm: false })
                                        await global.timeout(10)
                                        await this.setState({ showMultiPaymentForm: true })
                                    }}>
                                    <Text style={{ paddingHorizontal: 20, paddingVertical: 5, color: 'white' }}> Clear</Text>
                                </TouchableOpacity>
                            </View>}
                    </View>
                    {/* </KeyboardAvoidingView> */}
                    {!this.state.showMultiPaymentForm && <View style={{ alignItems: 'center', justifyContent: 'center', marginTop: 25, width: screenWidth }}>
                        <Text style={styles.text5}>Charge must be agreed with the provider.
                    </Text>
                        <Text style={styles.text5}>
                            The money will be held by us. If the order is fulfilled, you can release it - if the order is not fulfilled, you will get the money back.
                    </Text>
                        {!this.props.showDiscount && <Text style={styles.text5}>
                            You can pay in multiple parts if the provider needs upfront payment to start.
                    </Text>}
                    </View>}
                    <View style={{ height: 200 }} />
                </ScrollView>
            </View>
        )
    }
}


const styles = ({
    text: {
        color: '#fff',
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        paddingHorizontal: 20,
        marginBottom: 10

    },
    text2: {
        color: '#ffff00',
        fontSize: 20,
        textAlign: 'center',
        fontWeight: 'bold',
        paddingHorizontal: 40,
        paddingVertical: 10

    },
    text2ACN: {
        color: '#ffff00',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: 'bold',
        paddingVertical: 10

    },
    text3: {
        color: '#ffffff',
        fontSize: 15,
        fontWeight: 'bold',
        textAlign: 'center',
        paddingHorizontal: 40,
        paddingTop: 20
    },
    text4: {
        color: '#ffffff',
        fontSize: 16,
        fontWeight: 'bold',
        //textAlign: 'center',
        //alignSelf: 'center'
    },
    text4ACN: {
        color: '#00ff99',
        fontSize: 16,
        marginLeft: 2,
        marginTop: 4
        //textAlign: 'center',
        //alignSelf: 'center'
    },
    text5: {
        color: 'grey',
        fontSize: 14,
        textAlign: 'center',
        paddingHorizontal: 40,
        alignSelf: 'center'
    },
    bottom: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20

    }

})

import React, { Component } from "react";
import {
  Text,
  View,
  SafeAreaView,
  Modal,
  Image,
  TouchableOpacity,
  Linking,
  Dimensions,
  Animated,
  ActivityIndicator,
  Alert,
  ScrollView
} from "react-native";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import firebase from "firebase";
import "firebase/firestore";
import { WebView } from "react-native-webview"
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Haptics from 'expo-haptics';
import Clipboard from 'expo-clipboard';
// import { SvgCss } from "react-native-svg";
import { TextInput } from "react-native-gesture-handler";
import { NavigationEvents } from "react-navigation";
import CommunityFeed from "./CommunityFeed";
import ChatGroup from '../../Messages/Groups/ChatGroupDetailScreen'
const gStyles = global.gStyles //    "../../styles/gStyles";
import CachedImage from "../../shared/CachedImage"
import FullscreenImage from "../../Messages/FullscreenImage"


// const imgUpload = `<svg xmlns="http://www.w3.org/2000/svg" width="21.671" height="17.337" viewBox="0 0 21.671 17.337"><defs><style>.a{fill:#898989;}</style></defs><path class="a" d="M25.5,5.167H16.836L14.669,3h-6.5A2.164,2.164,0,0,0,6.011,5.167L6,18.17a2.174,2.174,0,0,0,2.167,2.167H25.5a2.174,2.174,0,0,0,2.167-2.167V7.334A2.174,2.174,0,0,0,25.5,5.167ZM9.251,17.086l4.876-6.5,3.792,4.887,2.709-3.262,3.792,4.876Z" transform="translate(-6 -3)"/></svg>`

class Members extends Component {
  state = {
    memberName: '',
    memberDescription: '',
    memberPicture: '',
    businessName: ''
  }

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  componentDidMount() {
    // this.unsubscribeFirestore = firebase
    //   .firestore()
    //   .collection('Profiles')
    //   .doc(this.props.profileID)
    //   .onSnapshot(this.updatedMembership)
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  // updatedMembership = async profileSnapshot => {
  //   try {
  //     let profile = profileSnapshot.data();
  //     let profileName = profile.profileName || '';
  //     let profilePictureURL = profile.profilePictureURL || '';
  //     let businessName = profile.profileNameBusiness || ''

  //     this.setState({
  //       memberName: profileName,
  //       memberPicture: profilePictureURL,
  //       businessName



  //     })
  //   }
  //   catch (err) {
  //     console.log("Slow Internet")
  //   }
  // }
  render() {
    return (

      <TouchableOpacity onPress={() => {

        let handoverID = this.props.profileID;
        this.props.navigation.navigate("meProfile", { profileID })

      }}>
        <View style={{
          flexDirection: 'row',
          height: 92,
          backgroundColor: "black",
          alignItems: "flex-start",
          marginTop: 5,
          marginHorizontal: 5,
          paddingLeft: 11,
          paddingRight: 11,
          shadowOpacity: 0.5,
          shadowOffset: { width: 0, height: 3 },
          borderBottomColor: "#333",
          borderRadius: 4,
        }}>
          <View
            style={
              {
                flex: 1,
                justifyContent: 'center',
                marginVertical: 15,
              }
            }
          >
            {!!this.state.memberPicture ? (
              <Image
                style={{ width: 82, height: 82, borderRadius: 82 / 2 }}
                source={{
                  uri: this.state.memberPicture
                }}
              />
            ) : (
              <View style={{ width: 82, height: 82, borderRadius: 82 / 2, backgroundColor: "#000" }} />
            )}

          </View>
          <View style={{ flex: 3, padding: 10, }}>
            <View><Text style={{ color: '#fff', fontWeight: 'bold', }}> {this.state.memberName}</Text></View>
            <View><Text style={{ color: '#777' }}> {this.state.businessName}</Text></View>
            <View><Text style={{ color: '#fff' }}> testing </Text></View>
          </View>
        </View>
      </TouchableOpacity>

    );
  }
}

class Follower extends Component {
  state = {
    pictureURL: "",
    name: "***"
  };

  async componentDidMount() {
    // let isConnected = await NetInfo.isConnected.fetch();
    // if (!isConnected) {
    //   console.log(
    //     "No network while rendering Follower (isConnected is false). Still trying"
    //   );
    // }
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 200); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 500); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 1000); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 2000); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 3000); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 5000); });
    if (!global.myUID || !global.myProfileID) await global.setGlobals();
    if (!global.myUID || !global.myProfileID) return alert("No connection")
    try {
      let followerSnapshot = await firebase
        .firestore()
        .collection("Profiles")
        .doc(this.props.profileID)
        .get();
      let follower = followerSnapshot.data();
      let name = follower.profileName.replace(/ .*/, ""); // == first word
      // if (name.length>11){
      //   name = name.substring(0,9)+"...";
      // }
      let pictureURL = follower.profilePictureURL;
      this.setState({ pictureURL, name });
    } catch (err) {
      console.log("Error loading follower (Profile)");
    }
  }

  render() {
    return (
      <View style={{ alignItems: "center" }}>
        <View
          style={{
            backgroundColor: this.props.colF,
            margin: 2,
            height: 66,
            width: 66,
            borderRadius: 33,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {this.state.pictureURL != "" && (
            <Image
              style={{
                marginTop: 1,
                marginBottom: 2,
                width: 60,
                height: 60,
                borderRadius: 30
                //backgroundColor: "black"
              }}
              source={{
                uri: this.state.pictureURL
              }}
            />
          )}
        </View>
        <Text style={{ color: this.props.colF }}>{this.state.name}</Text>
      </View>
    );
  }
}

class MyFollowers extends Component {
  render() {
    return (
      <ScrollView horizontal>
        {this.props.followers.map(f => (
          <TouchableOpacity
            key={f}
            onPress={() => {
              const a = Math.floor(Math.random() * 899999 + 100000);
              this.props.navigation.navigate({
                routeName: "meProfile",
                params: { profileID: f },
                key: "Mutual" + a
              });
            }}
          >
            <Follower profileID={f} colF={this.props.colF || "#bbb"} />
          </TouchableOpacity>
        ))}
      </ScrollView>
    );
  }
}


export default class MeCommunityScreen extends Component {
  static navigationOptions = {
    headerTransparent: true,
    headerTintColor: "#A8CF45",
    title: "",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1,
      elevation: 1
    },
    animationEnabled: false,
    transitionConfig: () => ({
      transitionSpec: {
        duration: 0,
        timing: 0,
      }
    })
  };

  state = {
    communityID: null,
    communityManagerID: null,
    picture: null,
    backgroundPictureURL: null,
    name: '✧ ✧ ✧ ✧ ✧ ✧ ✧ ✧ ✧ ✧',
    description: '✧',
    pictureSize: 1,
    managerProfileIDs: [],
    jointJoiners: [],
    memberProfileIDs: [],
    ownerProfileID: null,
    nOfMembers: 0,
    nOfDiscussions: 0,
    nOfDiscussionsLoaded: false,
    showGetPicture: false,
    showGetBackgroundPicture: false,
    groups: [],
    currentEvents: [],
    futureEvents: [],
    pastEvents: [],
    overdueTasks: [],
    openTasks: [],
    completedTasks: [],
    groupsContacts: [],
    showAbout: false,
    allowAbout: false,
    showAllEvents: false,
    showAllTasks: false,
    taskSelection: "MY",
    membersEditable: false,
    loadingLocation: true,
    loadingGroups: false,
    leaving: false,
    myNotifications: {},
    showNewMenu: false,
    showMenuModal: false,
    showJoinModal: false,
    showInviteModal: false,
    menuModalScreen: 'DEFAULT', // can be "MUTE"
    showFullscreenImage: false,
    fullscreenImageURL: "",
    fullscreenImageIsCached: true,
    activeGroup: null,
    membersPhoneIsRequested: false,
    membersPhoneIsRequestedReason: "",
    memberProfileIDsSharingPhone: [],
    membersPhoneMailIsRequested: false,
    membersPhoneMailIsRequestedReason: "",
    memberProfileIDsSharingPhoneMail: [],
    phoneRequestedScreen: 0, // 0=don't show, 1=Standard, 2= Details
    phoneMailRequestedScreen: 0, // 0=don't show, 1=Standard, 2= Details
    email: "",
  }

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
    this.unsubscribeFirestore2 = () => { return; };
  }

  componentDidMount = async () => {
    //console.log("navigation prop ", this.props.navigation);
    if (!isMobile && global.communityActiveGroup) {
      this.props.navigation.setParams({ group: global.communityActiveGroup })
      this.setState({ activeGroup: global.communityActiveGroup })
    }
    const communityID = this.props.navigation.getParam("communityID")
    await this.setState({ communityID })
    console.log('Community id', communityID)
    try {
      this.loadMyNotifications()
      const groupsJSON = await AsyncStorage.getItem("communityGroups" + communityID.substring(5, 15))
      if (groupsJSON) {
        const groups = JSON.parse(groupsJSON)
        if (groups) this.setState({ groups })
      }
      let eventsJSON = await AsyncStorage.getItem("communityEventsFuture" + communityID.substring(5, 15))
      if (eventsJSON) {
        const events = JSON.parse(eventsJSON)
        if (events) this.setState({ futureEvents: events })
      }
      eventsJSON = await AsyncStorage.getItem("communityEventsPast" + communityID.substring(5, 15))
      if (eventsJSON) {
        const events = JSON.parse(eventsJSON)
        if (events) this.setState({ pastEvents: events })
      }
      eventsJSON = await AsyncStorage.getItem("communityEventsCurrent" + communityID.substring(5, 15))
      if (eventsJSON) {
        const events = JSON.parse(eventsJSON)
        if (events) this.setState({ currentEvents: events })
      }

      let tasksJSON = await AsyncStorage.getItem("communityTasksOpen" + communityID.substring(5, 15))
      if (tasksJSON) {
        const tasks = JSON.parse(tasksJSON)
        if (tasks) this.setState({ openTasks: tasks })
      }
      tasksJSON = await AsyncStorage.getItem("communityTasksOverdue" + communityID.substring(5, 15))
      if (tasksJSON) {
        const tasks = JSON.parse(tasksJSON)
        if (tasks) this.setState({ overdueTasks: tasks })
      }
      tasksJSON = await AsyncStorage.getItem("communityTasksCompleted" + communityID.substring(5, 15))
      if (tasksJSON) {
        const tasks = JSON.parse(tasksJSON)
        if (tasks) this.setState({ completedTasks: tasks })
      }
      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Communities")
        .doc(communityID)
        .onSnapshot(async (cDoc) => {
          if (!cDoc.exists) {
            this.props.navigation.goBack()
            await global.timeout(300)
            alert("Unable to access community")
            return
          }
          const c = cDoc.data()
          c.xID = communityID
          this.community = c

          if ((this.state.nOfDiscussionsLoaded && this.state.nOfDiscussions !== c.nOfDiscussions)
            || (this.state.nOfDiscussionsLoaded && c.randomTrigger && this.state.randomTrigger !== c.randomTrigger)) {
            //refresh discussions
            this.loadGroups(30);
          }

          if (!global.myProfileID) await global.timeout(1000)
          if (!global.myProfileID) await global.timeout(4000)
          if (!global.myProfileID || !c.memberProfileIDs.includes(global.myProfileID)) {
            this.hasNotYetJoined = true;
            if (c.openness !== "PUBLIC") return this.props.navigation.goBack()
          }

          const showPhoneMailRequest = c.membersPhoneMailIsRequested && !(c.memberProfileIDsSharingPhoneMail?.includes?.(global.myProfileID) || false)
          let showPhoneRequest = !c.membersPhoneMailIsRequested && c.membersPhoneIsRequested && !(c.memberProfileIDsSharingPhone?.includes?.(global.myProfileID) || false)

          await this.setState({
            ...(this.hasNotYetJoined && { showJoinModal: true }),

            name: c.name || "",
            backgroundPictureURL: c.communityBackgroundPictureURL || "",
            picture: c.communityPictureURL || "",
            description: c.description || "",
            memberProfileIDs: c.memberProfileIDs || [],
            managerProfileIDs: c.managerProfileIDs || [],
            ownerProfileID: c.ownerProfileID,
            nOfMembers: c.memberProfileIDs ? c.memberProfileIDs.length : 0,
            jointJoiners: c.xJointJoinerIDs || [],
            allowAbout: c.openness == "PRIVATE",
            nOfDiscussions: c.nOfDiscussions || 0,
            randomTrigger: c.randomTrigger,
            nOfDiscussionsLoaded: true,
            membersPhoneIsRequested: c.membersPhoneIsRequested || false,
            membersPhoneIsRequestedReason: c.membersPhoneIsRequestedReason || "NONE FOUND",
            memberProfileIDsSharingPhone: c.memberProfileIDsSharingPhone || [],
            phoneRequestedScreen: showPhoneRequest ? 1 : 0,

            membersPhoneMailIsRequested: c.membersPhoneMailIsRequested || false,
            membersPhoneMailIsRequestedReason: c.membersPhoneMailIsRequestedReason || "NONE FOUND",
            memberProfileIDsSharingPhone: c.memberProfileIDsSharingPhoneMail || [],
            phoneMailRequestedScreen: showPhoneMailRequest ? 1 : 0
          })
        })

      this.loadGroups(30);
    }
    catch (err) {
      console.log('Error is ', err)
      this.props.navigation?.goBack?.()
    }
  };

  componentWillUnmount() {
    this.unsubscribeFirestore2();
    this.unsubscribeFirestore();
  }



  loadGroups = async limit => {

    try {
      while (!Array.isArray(global.myCommunities)) await global.timeout(1000)

      const communityID = this.state.communityID
      if (!global.myCommunities.includes(communityID)) {
        console.log("Not yet a community member. Not loading groups")
        return;
      }
      if (this.state.loadingGroups) return
      this.setState({ loadingGroups: true });
      if (!global.location) {
        alert("Location not enabled");
        return;
      }

      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "findGroups", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          isWeb: true,
          isWeb: true,
          limit,
          type: "community",
          coords: global.location.coords,
          myUID: global.myUID,
          idToken: global.idToken,
          communityID
        })
      });
      const r2 = await r.json();
      this.setState({ loadingGroups: false });

      //console.log("Received groupssssss. ", r2);
      if (r2.msg === "SUCCESS") {
        const groups = r2.groups.filter(group => { return (group.aType != "event" && group.aType != "task") })

        const events = r2.groups.filter(group => { return (group.aType == "event") })
        let currentEvents = [], futureEvents = [], pastEvents = []
        events.forEach(event => {
          const o = event.eventMillisOne || 0
          const n = new Date() * 1
          let o2 = o
          if (event.eventOptions?.repeat && event.eventMillisOne) {
            o2 = global.calculateLastOccurrence(o, event.eventOptions?.repeat)
          }
          if ((o > (n - 2 * 3600000)) && (o <= (n + 22 * 3600000))) currentEvents.push(event)
          else if ((o2 > (n - 2 * 3600000)) && (o2 <= (n + 22 * 3600000))) currentEvents.push(event)
          else if (o > (n + 22 * 3600000)) futureEvents.push(event)
          else pastEvents.push(event)
        })

        const tasks = r2.groups.filter(group => { return (group.aType == "task") })
        let openTasks = [], completedTasks = [], overdueTasks = []
        tasks.forEach(task => {
          const assigneesNotDone = task.taskAssignees.filter(a => !task.taskAssigneesDone.includes(a))
          const o = task.taskMillisOne || 0
          const n = new Date() * 1
          if (o > n) openTasks.push(task)
          else if (assigneesNotDone.length === 0) completedTasks.push(task)
          else overdueTasks.push(task)
        })
        this.setState({ groups, currentEvents, futureEvents, pastEvents, openTasks, completedTasks, overdueTasks });
        await AsyncStorage.setItem("communityGroups" + communityID.substring(5, 15), JSON.stringify(groups))
        await AsyncStorage.setItem("communityEventsCurrent" + communityID.substring(5, 15), JSON.stringify(currentEvents))
        await AsyncStorage.setItem("communityEventsFuture" + communityID.substring(5, 15), JSON.stringify(futureEvents))
        await AsyncStorage.setItem("communityEventsPast" + communityID.substring(5, 15), JSON.stringify(pastEvents))
        await AsyncStorage.setItem("communityTasksOpen" + communityID.substring(5, 15), JSON.stringify(openTasks))
        await AsyncStorage.setItem("communityTasksCompleted" + communityID.substring(5, 15), JSON.stringify(completedTasks))
        await AsyncStorage.setItem("communityTasksOverdue" + communityID.substring(5, 15), JSON.stringify(overdueTasks))
      } else {
        console.log(r2)
        if (!this.hasRetriedLoadGroups) {
          this.hasRetriedLoadGroups = true
          await global.timeout(5000)
          this.loadGroups(limit)
        } else alert("Error loading data.");
      }
      //("Received findGroups: ", r2.msg);
    } catch (err) {
      this.setState({ loadingGroups: false });
      console.log("Error in findGroups", err);
    }
  };

  join = async () => {
    this.setState({ aaJoin: true })
    try {
      const communityID = this.community.xID
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "joinCommunity", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          isWeb: true,
          isWeb: true,
          communityID,
          action: 'join',
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      //this.profileSearchTerm = term
      let route = "meCommunity"
      if (this.props.navigation.state.routeName.startsWith("home")) route = "homeCommunity"
      if (this.props.navigation.state.routeName.startsWith("chat")) route = "chatCommunity"
      const r2 = await r.json();
      await global.timeout(1500)
      this.setState({ aaJoin: false, showJoinModal: false })
      await global.timeout(50)
      this.props.navigation.goBack()
      if (r2.msg == 'SUCCESS') {
        await global.timeout(50)
        this.props.navigation.navigate(route, { communityID })
      } else {
        alert('Not possible to join this community')
      }
    }
    catch (error) {
      global.warn(error, 'joinCom')
      alert('Something went wrong. Try again')
      this.setState({ aaJoin: false })
    }
  }

  loadMyNotifications = async () => {
    try {
      this.unsubscribeFirestore2 = await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .collection("Refs")
        .doc("notificationRefs")
        .onSnapshot(this.updateMyNotifications);
    } catch (error) {
      global.warn(error, "loadMyNotifications")
    }
  }
  updateMyNotifications = async (refDoc) => {
    const communityID = this.state.communityID
    try {
      if (!refDoc.exists || !refDoc) return
      const ref = refDoc.data?.()
      const myNotifications = ref.pinnedCommunityNotifications?.[communityID] || {}
      this.setState({ myNotifications })
    } catch (e) {
      global.warn(e, "updateMyNotifications")
    }
  }

  leave = async () => {
    global.fetchIdToken()
    Alert.alert(
      "Sure to leave this community?", "",
      [{ text: "Cancel", onPress: () => console.log("Cancel Pressed"), style: "cancel" },
      {
        text: "Yes", onPress: async () => {
          this.setState({ showMenuModal: false })
          await global.timeout(100)
          this.leaveExecution()
          await global.timeout(800)
          return this.props.navigation.goBack()
        }
      }],
      { cancelable: false });
  }
  leaveExecution = async () => {
    const communityID = this.state.communityID
    try {
      this.setState({ leaving: true })

      const r = await fetch(global.cloudFunctionURL + "joinCommunity", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          isWeb: true,
          isWeb: true,
          communityID: communityID,
          action: 'leave',
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == 'SUCCESS') {
        //this.props.navigation.goBack()
      }
      else {
        if (r2.reason == "MANAGER") alert("As a community manager, you cannot leave this group.")
        else alert('Something went wrong , Try again')
        this.setState({ leaving: false })
      }
    }
    catch (error) {
      this.setState({ leaving: false })
      console.log('Error is ', error)
      alert('Could not leave the group. Try again')
    }
  }

  sharePhone = async () => {
    global.fetchIdToken()
    /*  Alert.alert(
       "Share your name and phone number with the community moderators?", "",
       [{ text: "Cancel", onPress: () => console.log("Cancel Pressed"), style: "cancel" },
       { text: "Yes", onPress: () => { this.sharePhoneExecution() } }],
       { cancelable: true }); */

    this.sharePhoneExecution()
  }
  sharePhoneExecution = async () => {
    const communityID = this.state.communityID
    try {
      this.setState({ phoneRequestedScreen: 3 })

      const r = await fetch(global.cloudFunctionURL + "joinCommunity", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          communityID: communityID,
          action: 'shareData',
          data: "nameAndPhone",
          myUID: global.myUID,
          idToken: global.idToken,
          isWeb: true
        })
      });
      const r2 = await r.json();
      if (r2.msg == 'SUCCESS') {
        await global.timeout(500)
        this.setState({ phoneRequestedScreen: 0 })
      }
      else {
        alert('Something went wrong, try again later')
        this.setState({ phoneRequestedScreen: 1 })
      }
    }
    catch (error) {
      this.setState({ phoneRequestedScreen: 1 })
      console.log('Error is ', error)
      alert('Could not share data. Try again later')
    }
  }

  sharePhoneMail = async () => {
    function validateMail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
    if (!this.state.email) {
      this.myTextInputBlue?.focus()
      return alert("Please enter your e-mail address")
    }
    if (!validateMail(this.state.email)) return alert("Please enter a valid e-mail address")
    global.fetchIdToken()
    /* Alert.alert(
      "Share your name, phone number, and e-mail address with the community moderators?", "",
      [{ text: "Cancel", onPress: () => console.log("Cancel Pressed"), style: "cancel" },
      { text: "Yes", onPress: () => { this.sharePhoneMailExecution() } }],
      { cancelable: true }); */
    this.sharePhoneMailExecution()
  }
  sharePhoneMailExecution = async () => {
    const communityID = this.state.communityID
    try {
      this.setState({ phoneMailRequestedScreen: 3 })

      const r = await fetch(global.cloudFunctionURL + "joinCommunity", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          communityID: communityID,
          action: 'shareData',
          data: "nameAndPhoneAndMail",
          email: this.state.email,
          myUID: global.myUID,
          idToken: global.idToken,
          isWeb: true
        })
      });
      const r2 = await r.json();
      if (r2.msg == 'SUCCESS') {
        await global.timeout(500)
        this.setState({ phoneMailRequestedScreen: 0 })
      }
      else {
        alert('Something went wrong, try again later!')
        this.setState({ phoneMailRequestedScreen: 1 })
      }
    }
    catch (error) {
      this.setState({ phoneMailRequestedScreen: 1 })
      console.log('Error is ', error)
      alert('Could not share data. Try again later!')
    }
  }

  invite = async () => {
    this.setState({ showMenuModal: false })
    await global.timeout(1000)
    this.setState({ showInviteModal: true })
  }



  renderAbout = () => {
    return (<View style={{}}>
      <TouchableOpacity activeOpacity={1}
        style={{ paddingBottom: 12 }}
        onPress={() => { this.setState({ showAbout: !this.state.showAbout }) }}>
        <View style={{ marginTop: 2, borderColor: "#303034", borderBottomWidth: 1, padding: 5, borderRadius: 7 }}>
          <Text style={{ color: "#aab", fontSize: 12 }}>{this.state.description || ""}</Text>
        </View>
      </TouchableOpacity>
      {/* {this.state.amModerator && <TouchableOpacity onPress={() => { this.setState({ membersEditable: true }) }}
        style={{ alignSelf: "flex-start", margin: 5, width: 110, borderWidth: 1, height: 25, borderRadius: 13, borderColor: "#aab", backgroundColor: "#000", alignItems: "center", justifyContent: "center", marginRight: 10 }}>
        <Text style={{ color: "#aab", fontSize: 12 }}>Edit Members</Text>
      </TouchableOpacity>} */}
      {!!this.state.memberProfileIDs && this.state.memberProfileIDs.map(pID => {
        const isOwner = this.state.ownerProfileID == pID
        const amOwner = this.state.ownerProfileID == global.myProfileID
        const isManager = this.state.managerProfileIDs.includes(pID)
        const amManager = this.state.managerProfileIDs.includes(global.myProfileID)
        if (isOwner) return <Member profileID={pID} key={pID + (isOwner ? 2 : 1) + (isManager ? 2 : 1) + (amOwner ? 2 : 1) + (amManager ? 2 : 1) + (this.state.membersEditable ? 2 : 1)}
          editable={this.state.membersEditable && this.state.allowAbout}
          pressable={this.state.allowAbout}
          isManager={isManager}
          amManager={amManager}
          isOwner={isOwner}
          amOwner={amOwner}
          navigation={this.props.navigation} communityID={this.state.communityID}
        />
      })}
      {!!this.state.memberProfileIDs && this.state.memberProfileIDs.map(pID => {
        const isOwner = this.state.ownerProfileID == pID
        const amOwner = this.state.ownerProfileID == global.myProfileID
        const isManager = this.state.managerProfileIDs.includes(pID)
        const amManager = this.state.managerProfileIDs.includes(global.myProfileID)
        if (!isOwner && isManager) return <Member profileID={pID} key={pID + (isOwner ? 2 : 1) + (isManager ? 2 : 1) + (amOwner ? 2 : 1) + (amManager ? 2 : 1) + (this.state.membersEditable ? 2 : 1)}
          editable={this.state.membersEditable && this.state.allowAbout}
          pressable={this.state.allowAbout}
          isManager={isManager}
          amManager={amManager}
          isOwner={isOwner}
          amOwner={amOwner}
          navigation={this.props.navigation} communityID={this.state.communityID}
        />
      })}
      {!!this.state.memberProfileIDs && this.state.memberProfileIDs.map(pID => {
        const isOwner = this.state.ownerProfileID == pID
        const amOwner = this.state.ownerProfileID == global.myProfileID
        const isManager = this.state.managerProfileIDs.includes(pID)
        const amManager = this.state.managerProfileIDs.includes(global.myProfileID)
        if (!isOwner && !isManager) return <Member profileID={pID} key={pID + (isOwner ? 2 : 1) + (isManager ? 2 : 1) + (amOwner ? 2 : 1) + (amManager ? 2 : 1) + (this.state.membersEditable ? 2 : 1)}
          editable={this.state.membersEditable && this.state.allowAbout}
          pressable={this.state.allowAbout}
          isManager={isManager}
          amManager={amManager}
          isOwner={isOwner}
          amOwner={amOwner}
          navigation={this.props.navigation} communityID={this.state.communityID}
        />
      })}
    </View>
    )
  }

  render() {

    if (this.state.leaving) return <View
      style={{ backgroundColor: "#000", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}
    >
      <Text style={{ color: "#aca", fontSize: 17, fontStyle: "italic" }}>See you Again!</Text>
    </View>
    const link = "https://wakandamarket.com/community/" + (this.community?.xID || "")
    return (
      <View style={{ flexDirection: 'row', width: '100%', height: global.SHEIGHT, }}>
        <View style={{
          flex: 1, maxWidth: (this.state.activeGroup && (isMobile)) ? 0 : (isMobile) ? '100%' : 350, padding: 10, backgroundColor: '#646464',
          ...((this.state.activeGroup && (isMobile)) && { display: 'none', opacity: 0 })
        }} >
          {!this.state.activeGroup && <View style={{ position: 'absolute', top: 5, left: 5, zIndex: 999 }}>
            <TouchableOpacity onPress={() => {
              this.props.navigation.goBack()
            }}>
              <Image
                style={[{
                  width: 20,
                  height: 20,
                  tintColor: '#A8CF45'
                },
                ]}
                source={require("../../images/icons/AndroidBack.png")}
              />
            </TouchableOpacity>
          </View>}
          <ScrollView
            showsVerticalScrollIndicator={false}
          >
            <View style={{ backgroundColor: '#505050', borderRadius: 5, paddingHorizontal: 5 }}>
              {/***** 'Background' Image *****/}
              <View
                style={{
                  height: 180,
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 0,
                  overflow: "hidden"
                }}
              >
                {!!this.state.backgroundPictureURL && (
                  <TouchableOpacity
                    onPress={() => this.setState({ showFullscreenImage: true, fullscreenImageURL: this.state.backgroundPictureURL, fullscreenImageIsCached: true })}
                    activeOpacity={1}
                  >
                    <CachedImage
                      key={
                        this.state.backgroundPictureURL || "-"
                      }
                      style={{ width: Dimensions.get("window").width, height: 180, resizeMode: 'contain' }}
                      source={{
                        uri:
                          this.state.backgroundPictureURL
                      }}
                    />
                  </TouchableOpacity>
                )}


                <FullscreenImage on={this.state.showFullscreenImage} pictureURL={this.state.fullscreenImageURL}
                  onClose={() => { this.setState({ showFullscreenImage: false }) }}
                  cached={this.state.fullscreenImageIsCached} />

              </View>

              <View style={{
                justifyContent: "space-between", alignItems: "center",
                flexDirection: "row",
              }}>
                <View style={{ flex: 1 }}>

                  <TouchableOpacity activeOpacity={1}
                    style={{ paddingTop: 12, paddingBottom: 8, paddingLeft: 5 }}
                    onPress={() => { if (this.state.allowAbout) this.setState({ showAbout: !this.state.showAbout }) }}>
                    <Text style={{ color: "#aab", fontSize: 14, fontWeight: 'bold', }}>{this.state.name}</Text>
                    <Text style={{ color: "#889", fontSize: 12 }}>{this.state.nOfMembers} {this.state.nOfMembers == 1 ? 'member' : 'members'}</Text>
                  </TouchableOpacity>

                </View>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  {/* {this.state.nOfDiscussionsLoaded && <TouchableOpacity
                    onPress={async () => {
                      if (!this.community) for (let i = 0; i < 14; i += 1) { if (!this.community) await global.timeout(200) }
                      let route = "homeCommunitySettings"
                      if (this.props.navigation.state.routeName.startsWith("chat")) route = "chatCommunitySettings"
                      if (this.props.navigation.state.routeName.startsWith("me")) route = "meCommunitySettings"
                      this.props.navigation.navigate(route, { community: this.community })
                    }}
                    style={{ width: 47, zIndex: 5, height: 50, paddingTop: 11, alignItems: "center", justifyContent: "center", paddingLeft: 15, paddingRight: 10 }}>
                    <Image
                      style={{ width: 22, height: 22, tintColor: "#aab" }}
                      source={require("../../images/icons/EditNotifications.png")}
                    />
                  </TouchableOpacity>} */}
                  {this.state.nOfDiscussionsLoaded && <TouchableOpacity
                    onPress={() => {
                      this.setState({ showMenuModal: true });
                      ///  Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy)
                    }}
                    style={{ width: 40, zIndex: 5, height: 50, paddingTop: 12, alignItems: "center", justifyContent: "center" }}>
                    <Text style={{ fontWeight: "bold", fontSize: 24, color: "#ccd", marginTop: 4, marginRight: 5 }}>⋮</Text>
                  </TouchableOpacity>}
                </View>
              </View>

              <TouchableOpacity activeOpacity={1}
                style={{}}
                onPress={() => { this.setState({ showAbout: !this.state.showAbout }) }}>
                {!this.state.showAbout && <View style={{ marginTop: 2, borderColor: "#303034", borderWidth: 0, padding: 5, borderRadius: 7 }}>
                  <Text numberOfLines={4} style={{ color: "#aab", fontSize: 12 }}>{this.state.description || ""}</Text>
                </View>}
              </TouchableOpacity>


              {this.state.showAbout && this.renderAbout()}
            </View>
            <View key={this.state}>
              {global.renderAllTasksAndEvents(this, false)}
            </View>
            {this.state.phoneRequestedScreen == 1 && <View style={{
              marginTop: 3, backgroundColor: "#A8CF45", opacity: 0.75, borderWidth: 0, padding: 5, borderRadius: 7,
              flexDirection: "row", alignItems: "center", justifyContent: "space-between"
            }}>
              <View style={{ flex: 1 }}>
                <Text style={{ color: "#000", fontSize: 12, fontWeight: "bold", fontFamily: "ProximaNovaA-Bold" }}>DATA REQUESTED</Text>
                <Text style={{ color: "#000", fontSize: 12, fontFamily: "ProximaNovaA-Regular" }}>  - Name and Phone Number</Text>
                <TouchableOpacity
                  onPress={() => { this.setState({ phoneRequestedScreen: 2 }) }}
                  style={{ paddingTop: 6, alignItems: "flex-start" }}>
                  <Text style={{ color: "#000", fontSize: 10, fontFamily: "ProximaNovaA-Regular" }}><Text style={{ fontSize: 9, fontFamily: "ProximaNovaA-Regular" }}>&gt;&gt;</Text> More </Text>
                </TouchableOpacity>
              </View>
              <View>
                <View>
                  <TouchableOpacity style={{ padding: 1, backgroundColor: "#000", borderRadius: 20, marginRight: 10 }}
                    onPress={() => { this.sharePhone() }}
                  >
                    <View style={{
                      paddingVertical: 4, paddingHorizontal: 10, backgroundColor: "#000", borderRadius: 14,
                      borderColor: "#A8CF45", borderWidth: 1
                    }}>
                      <Text style={{ color: "#A8CF45", fontSize: 13, fontWeight: "bold", fontFamily: "ProximaNovaA-Bold" }}>SHARE</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>}

            {this.state.phoneRequestedScreen == 2 && <View style={{
              marginTop: 3, backgroundColor: "#A8CF45", opacity: 0.75, borderWidth: 0, padding: 5, borderRadius: 7,
              flexDirection: "row", alignItems: "center", justifyContent: "space-between"
            }}>
              <View style={{ flex: 1 }}>
                <Text style={{ color: "#000", fontSize: 12, fontWeight: "bold", fontFamily: "ProximaNovaA-Bold" }}>DATA REQUESTED</Text>
                <Text style={{ color: "#000", fontSize: 12, marginTop: 9, fontFamily: "ProximaNovaA-Regular" }}>The community moderators would like to receive the following information:</Text>
                <Text style={{ color: "#000", fontSize: 11, marginTop: 5, fontWeight: "bold", fontFamily: "ProximaNovaA-Bold" }}>  - Name: {global.myName || "?"}</Text>
                <Text style={{ color: "#000", fontSize: 11, fontWeight: "bold", fontFamily: "ProximaNovaA-Bold" }}>  - Phone Number: {global.myPhoneNumber}</Text>
                <Text style={{ color: "#000", fontSize: 11, fontWeight: "bold", marginTop: 12, fontFamily: "ProximaNovaA-Bold" }}>Reason:</Text>
                <Text style={{ color: "#000", fontSize: 11, fontFamily: "ProximaNovaA-Regular" }}>{this.state.membersPhoneIsRequestedReason}</Text>
                <TouchableOpacity
                  onPress={() => { this.setState({ phoneRequestedScreen: 1 }) }}
                  style={{ paddingTop: 12, alignItems: "flex-start" }}>
                  <Text style={{ color: "#000", fontSize: 10, fontFamily: "ProximaNovaA-Regular" }}><Text style={{ fontSize: 9, fontFamily: "ProximaNovaA-Regular" }}>&lt;&lt;</Text> Less </Text>
                </TouchableOpacity>
              </View>
              <View>
                <View>
                  <TouchableOpacity style={{ padding: 1, backgroundColor: "#000", borderRadius: 20, marginRight: 10 }}
                    onPress={() => { this.sharePhone() }}
                  >
                    <View style={{
                      paddingVertical: 4, paddingHorizontal: 10, backgroundColor: "#000", borderRadius: 14,
                      borderColor: "#A8CF45", borderWidth: 1
                    }}>
                      <Text style={{ color: "#A8CF45", fontSize: 13, fontWeight: "bold", fontFamily: "ProximaNovaA-Bold" }}>SHARE</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>}

            {this.state.phoneMailRequestedScreen == 1 && <View style={{
              marginTop: 3, backgroundColor: "#A8CF45", opacity: 0.75, borderWidth: 0, padding: 5, borderRadius: 7,
              flexDirection: "row", alignItems: "center", justifyContent: "space-between"
            }}>
              <View style={{ flex: 1 }}>
                <Text style={{ color: "#000", fontSize: 12, fontWeight: "bold", marginBottom: 3, fontFamily: "ProximaNovaA-Bold" }}>DATA REQUESTED</Text>
                <Text style={{ color: "#000", fontSize: 12, fontFamily: "ProximaNovaA-Regular" }}>  - Name</Text>
                <Text style={{ color: "#000", fontSize: 12, fontFamily: "ProximaNovaA-Regular" }}>  - Phone Number</Text>
                <Text style={{ color: "#000", fontSize: 12, fontFamily: "ProximaNovaA-Regular" }}>  - E-Mail Address</Text>
                <TouchableOpacity
                  onPress={() => { this.setState({ phoneMailRequestedScreen: 2 }) }}
                  style={{ paddingTop: 6, alignItems: "flex-start" }}>
                  <Text style={{ color: "#000", fontSize: 10, fontFamily: "ProximaNovaA-Regular" }}><Text style={{ fontSize: 9 }}>&gt;&gt;</Text> More </Text>
                </TouchableOpacity>
              </View>
              <View>
                <View>
                  <TouchableOpacity style={{ padding: 1, backgroundColor: "#000", borderRadius: 20, marginRight: 10 }}
                    onPress={() => { this.setState({ phoneMailRequestedScreen: 2 }) }}
                  >
                    <View style={{
                      paddingVertical: 4, paddingHorizontal: 10, backgroundColor: "#000", borderRadius: 14,
                      borderColor: "#A8CF45", borderWidth: 1
                    }}>
                      <Text style={{ color: "#A8CF45", fontSize: 13, fontWeight: "bold", fontFamily: "ProximaNovaA-Bold" }}>SHARE</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>}

            {this.state.phoneMailRequestedScreen == 2 && <View style={{
              marginTop: 3, backgroundColor: "#A8CF45", opacity: 0.75, borderWidth: 0, padding: 5, borderRadius: 7,
              flexDirection: "row", alignItems: "center", justifyContent: "space-between"
            }}>
              <View style={{ flex: 1 }}>
                <Text style={{ color: "#000", fontSize: 12, fontWeight: "bold", fontFamily: "ProximaNovaA-Bold" }}>DATA REQUESTED</Text>
                <Text style={{ color: "#000", fontSize: 12, marginTop: 9, fontFamily: "ProximaNovaA-Regular" }}>The community moderators would like to receive the following information:</Text>
                <Text style={{ color: "#000", fontSize: 11, marginTop: 5, fontWeight: "bold", fontFamily: "ProximaNovaA-Bold" }}>  - Name:</Text>
                <Text style={{ color: "#000", fontSize: 11, fontFamily: "ProximaNovaA-Regular" }}>       {global.myName || "?"}</Text>
                <Text style={{ color: "#000", fontSize: 11, fontWeight: "bold", marginTop: 5, fontFamily: "ProximaNovaA-Bold" }}>  - Phone Number:</Text>
                <Text style={{ color: "#000", fontSize: 11, fontFamily: "ProximaNovaA-Regular" }}>       {global.myPhoneNumber}</Text>
                <Text style={{ color: "#000", fontSize: 11, fontWeight: "bold", marginTop: 5, fontFamily: "ProximaNovaA-Bold" }}>  - E-Mail Address:</Text>
                <View
                  style={{ flexDirection: "row", marginTop: 0, justifyContent: "center", alignItems: "center", marginRight: 40, marginLeft: 15 }}
                >
                  <TextInput
                    style={{ alignSelf: "stretch", height: 25, margin: 5, marginTop: 2, borderWidth: 1, borderColor: "#000", color: "#000", backgroundColor: "#A8CF45", borderRadius: 13, paddingRight: 15, paddingLeft: 5, flex: 1, fontFamily: "ProximaNovaA-Regular" }}
                    placeholder="enter here ..."
                    placeholderTextColor="#000"
                    textAlign="center"
                    underlineColorAndroid="transparent"
                    underlineColor={"transparent"}
                    onChangeText={email => { this.setState({ email }) }}
                    ref={component => (this.myTextInputBlue = component)}
                    // clearButtonMode="always" //xx how set colour?
                    value={this.state.email}
                    keyboardDismissMode={'on-drag'}
                    keyboardAppearance={"dark"}
                    keyboardType={"email-address"}
                    textContentType={"emailAddress"}
                    returnKeyType={"done"}
                    onSubmitEditing={() => { Keyboard.dismiss() }}
                    autoCompleteType={'email'}
                    autoCapitalize={'none'}
                    autoCorrect={false}
                    onFocus={() => { this.myTextInputBlue.focus() }}
                  />
                  <TouchableOpacity style={{ marginLeft: -35, width: 35, height: 30, alignItems: "center", justifyContent: "center", paddingRight: 10, paddingBottom: 5 }}
                    onPress={() => { this.setState({ email: "", text: "" }); this.myTextInputBlue.focus() }}>
                    <Text style={{ color: "white", fontSize: 20, color: "#000" }}>×</Text>
                  </TouchableOpacity>
                </View>
                <Text style={{ color: "#000", fontSize: 11, fontWeight: "bold", marginTop: 12, fontFamily: "ProximaNovaA-Bold" }}>Reason:</Text>
                <Text style={{ color: "#000", fontSize: 11, fontFamily: "ProximaNovaA-Regular" }}>{this.state.membersPhoneMailIsRequestedReason}</Text>
                <TouchableOpacity
                  onPress={() => { this.setState({ phoneMailRequestedScreen: 1 }) }}
                  style={{ paddingTop: 12, alignItems: "flex-start" }}>
                  <Text style={{ color: "#000", fontSize: 10, fontFamily: "ProximaNovaA-Regular" }}><Text style={{ fontSize: 9, fontFamily: "ProximaNovaA-Regular" }}>&lt;&lt;</Text> Less </Text>
                </TouchableOpacity>
              </View>
              <View>
                <View>
                  <TouchableOpacity style={{ padding: 1, backgroundColor: "#000", borderRadius: 20, marginRight: 10 }}
                    onPress={() => { this.sharePhoneMail() }}
                  >
                    <View style={{
                      paddingVertical: 4, paddingHorizontal: 10, backgroundColor: "#000", borderRadius: 14,
                      borderColor: "#A8CF45", borderWidth: 1
                    }}>
                      <Text style={{ color: "#A8CF45", fontSize: 13, fontWeight: "bold" }}>SHARE</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>}

            {(this.state.phoneMailRequestedScreen == 3 || this.state.phoneƒRequestedScreen == 3) && <View style={{
              marginTop: 3, backgroundColor: "#A8CF45", opacity: 0.75, borderWidth: 0, padding: 25, borderRadius: 7,
              flexDirection: "row", alignItems: "center", justifyContent: "center",
            }}>
              <View style={{ flex: 1 }}>
                <Text style={{ color: "#000", fontSize: 12, fontWeight: "bold", fontFamily: "ProximaNovaA-Bold" }}>✔   Thank you!</Text>
              </View>
            </View>}
            <View style={{
              //backgroundColor: "black",
              alignItems: "flex-start",
              //s marginHorizontal: 5,
              shadowOpacity: 0.5,
              shadowOffset: { width: 0, height: 3 },
              borderBottomColor: "#333",
              borderRadius: 5,
            }}>
            </View>
            <View style={{ width: "100%", }}>
              <View
                style={{
                  borderBottomColor: "#555555",
                  height: 20
                }}
              />

              {this.state.groups && this.state.groups.map((item) => {
                const n = this.state.myNotifications?.[item.xID]
                return (
                  <TouchableOpacity
                    key={item.xID + n}
                    /* onPress={() => {
                      global.navigateChatGroup(this.props.navigation, { group: item })
                    }} */
                    onPress={() => {
                      //global.navigateChatGroup(this.props.navigation, { group: item })
                      this.props.navigation.setParams({ group: item })
                      this.setState({ activeGroup: item })
                      global.communityActiveGroup = item
                    }}
                  >
                    <CommunityFeed chatGroup={item} view={"LIST"}
                      key={(this.state.activeGroup?.xID || '') + (item.xID || '')}
                      notifications={n}
                      refresh={() => { this.loadGroups(30) }}
                      navigation={this.props.navigation}
                      isActive={item.xID == this.state.activeGroup?.xID}
                    />
                  </TouchableOpacity>);
              })}






            </View>
            <View style={{ height: 100 }} />
          </ScrollView>

          {/* <TouchableOpacity
            onPress={() => { this.setState({ showNewMenu: !this.state.showNewMenu }) }}
            style={{
              position: "absolute",
              right: 0,
              bottom: 50,
              paddingRight: 15,
              paddingBottom: 5,
              ...(this.state.showNewMenu && {
                height: Dimensions.get("window").height,
                width: Dimensions.get("window").width,
                //backgroundColor: "rgba(0,0,0,0)",
                justifyContent: "flex-end"
              }),
              alignItems: 'flex-end'
            }}>
            {this.state.showNewMenu && <FadeInView>
              <TouchableOpacity
                style={{
                  borderWidth: 1,
                  borderColor: "#A8CF45",
                  paddingLeft: 20,
                  justifyContent: "center",
                  width: Dimensions.get("window").width - 100,
                  height: 70,
                  width: 250,
                  marginBottom: 15,
                  marginRight: 0,
                  backgroundColor: "rgba(0,0,0,0.9)",
                  borderRadius: 70,
                  shadowOpacity: global.app == "health" ? 0 : 1,
                  shadowRadius: global.app == "health" ? 0 : 3,
                  elevation: 4
                }}
                onPress={async () => {
                  let route = "meCommunityNewDiscussion"
                  if (this.props.navigation.state.routeName.startsWith("home")) route = "homeCommunityNewDiscussion"
                  if (this.props.navigation.state.routeName.startsWith("chat")) route = "chatCommunityNewDiscussion"
                  if (!this.community) for (let i = 0; i < 14; i += 1) {
                    if (!this.community) await global.timeout(200)
                  }
                  this.props.navigation.navigate(route, { communityID: this.state.communityID, community: this.community, type: "task" })
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Text style={{ fontSize: 30, fontWeight: "200", color: "#ccd", width: 40, marginRight: 10 }}>📋</Text>
                  <View>
                    <Text style={{ fontSize: 15, fontWeight: "200", color: "#ccd" }}>New</Text>
                    <Text style={{ fontSize: 30, fontWeight: "200", color: "#ccd" }}>Task</Text>
                  </View>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  borderWidth: 1,
                  borderColor: "#A8CF45",
                  paddingLeft: 20,
                  justifyContent: "center",
                  width: Dimensions.get("window").width - 100,
                  height: 70,
                  width: 250,
                  marginBottom: 15,
                  marginRight: 0,
                  backgroundColor: "rgba(0,0,0,0.9)",
                  borderRadius: 70,
                  shadowOpacity: global.app == "health" ? 0 : 1,
                  shadowRadius: global.app == "health" ? 0 : 3,
                  elevation: 4
                }}
                onPress={async () => {
                  let route = "meCommunityNewDiscussion"
                  if (this.props.navigation.state.routeName.startsWith("home")) route = "homeCommunityNewDiscussion"
                  if (this.props.navigation.state.routeName.startsWith("chat")) route = "chatCommunityNewDiscussion"
                  if (!this.community) for (let i = 0; i < 14; i += 1) {
                    if (!this.community) await global.timeout(200)
                  }
                  this.props.navigation.navigate(route, { communityID: this.state.communityID, community: this.community, type: "event" })
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Text style={{ fontSize: 30, fontWeight: "200", color: "#ccd", width: 40, marginRight: 10 }}>🕑</Text>
                  <View>
                    <Text style={{ fontSize: 15, fontWeight: "200", color: "#ccd" }}>New</Text>
                    <Text style={{ fontSize: 30, fontWeight: "200", color: "#ccd" }}>Event</Text>
                  </View>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  borderWidth: 1,
                  borderColor: "#A8CF45",
                  paddingLeft: 20,
                  justifyContent: "center",
                  width: Dimensions.get("window").width - 100,
                  height: 70,
                  width: 250,
                  marginBottom: 15,
                  marginRight: 0,
                  backgroundColor: "rgba(0,0,0,0.9)",
                  borderRadius: 70,
                  shadowOpacity: global.app == "health" ? 0 : 1,
                  shadowRadius: global.app == "health" ? 0 : 3,
                  elevation: 4
                }}
                onPress={async () => {
                  let route = "meCommunityNewDiscussion"
                  if (this.props.navigation.state.routeName.startsWith("home")) route = "homeCommunityNewDiscussion"
                  if (this.props.navigation.state.routeName.startsWith("chat")) route = "chatCommunityNewDiscussion"
                  if (!this.community) for (let i = 0; i < 14; i += 1) {
                    if (!this.community) await global.timeout(200)
                  }
                  this.props.navigation.navigate(route, { communityID: this.state.communityID, community: this.community, type: "poll" })
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Text style={{ fontSize: 30, fontWeight: "200", color: "#ccd", width: 40, marginRight: 10 }}>🗳️</Text>
                  <View>
                    <Text style={{ fontSize: 15, fontWeight: "200", color: "#ccd" }}>New</Text>
                    <Text style={{ fontSize: 30, fontWeight: "200", color: "#ccd" }}>Poll</Text>
                  </View>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  borderWidth: 1,
                  borderColor: "#A8CF45",
                  paddingLeft: 20,
                  justifyContent: "center",
                  width: Dimensions.get("window").width - 100,
                  height: 70,
                  width: 250,
                  marginBottom: 15,
                  marginRight: 0,
                  backgroundColor: "rgba(0,0,0,0.9)",
                  borderRadius: 70,
                  shadowOpacity: global.app == "health" ? 0 : 1,
                  shadowRadius: global.app == "health" ? 0 : 3,
                  elevation: 4
                }}
                onPress={async () => {
                  let route = "meCommunityNewDiscussion"
                  if (this.props.navigation.state.routeName.startsWith("home")) route = "homeCommunityNewDiscussion"
                  if (this.props.navigation.state.routeName.startsWith("chat")) route = "chatCommunityNewDiscussion"
                  if (!this.community) for (let i = 0; i < 14; i += 1) {
                    if (!this.community) await global.timeout(200)
                  }
                  this.props.navigation.navigate(route, { communityID: this.state.communityID, community: this.community })
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Text style={{ fontSize: 30, fontWeight: "200", color: "#ccd", width: 40, marginRight: 10 }}>💬</Text>
                  <View>
                    <Text style={{ fontSize: 15, fontWeight: "200", color: "#ccd" }}>New</Text>
                    <Text style={{ fontSize: 30, fontWeight: "200", color: "#ccd" }}>Discussion</Text>
                  </View>
                </View>
              </TouchableOpacity>
            </FadeInView>}
            <View
              style={{
                borderWidth: 1,
                borderColor: "#222",
                alignItems: "center",
                justifyContent: "center",
                width: 70,
                height: 70,
                backgroundColor: "#A8CF45",
                borderRadius: 70,
                shadowOpacity: global.app == "health" ? 0 : 1,
                shadowRadius: global.app == "health" ? 0 : 3,
                elevation: 4
              }}
            >
              <Image
                style={{ width: 62, height: 62, borderRadius: 82 / 2 }}
                source={require("../../images/icons/Plus.png")}
              />
              
            </View>
          </TouchableOpacity> */}

          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.showMenuModal}
            onRequestClose={() => {
              this.setState({ showMenuModal: false, menuModalScreen: 'DEFAULT' });
            }}
          >
            <View style={{ flex: 1, maxWidth: 350, }}>

              <TouchableOpacity
                onPress={() =>
                  this.setState({ showMenuModal: false, menuModalScreen: 'DEFAULT' })
                }
                style={{ flex: 1 }}
              />

              {this.state.menuModalScreen == "DEFAULT"
                ? <View style={{
                  alignItems: "flex-start", backgroundColor: "#ccd", paddingTop: 5,
                  borderTopEndRadius: 15, borderTopLeftRadius: 15
                }}>

                  {this.state.managerProfileIDs.includes(global.myProfileID) && this.state.allowAbout && <TouchableOpacity onPress={async () => {
                    this.setState({ showMenuModal: false })
                    await global.timeout(50)
                    let route = "meSelectContact"
                    if (this.props.navigation.state.routeName.startsWith("home")) route = "homeSelectContact"
                    if (this.props.navigation.state.routeName.startsWith("chat")) route = "messageSelectContact"
                    if (this.props.navigation.state.routeName.startsWith("message")) route = "messageSelectContact"
                    if (this.state.communityID) this.props.navigation.navigate(route, {
                      input: this.state.profileID,
                      purpose: "AddCommunityMembers",
                      //nextScreen: this.props.navigation.state.routeName,
                      communityID: this.state.communityID,
                      communityMemberProfileIDs: this.community.memberProfileIDs || []
                    });
                  }}
                    style={{ flexDirection: "row", padding: 12, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
                    <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18, }}>Add Members</Text>
                  </TouchableOpacity>}

                  {this.state.managerProfileIDs.includes(global.myProfileID) && this.state.allowAbout && <TouchableOpacity onPress={async () => {
                    this.setState({ membersEditable: true, showAbout: true, showMenuModal: false })
                  }}
                    style={{ flexDirection: "row", padding: 12, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
                    <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18, }}>Edit Members</Text>
                  </TouchableOpacity>}

                  {this.state.managerProfileIDs.includes(global.myProfileID) &&
                    <TouchableOpacity onPress={async () => {
                      this.setState({ showMenuModal: false })
                      await global.timeout(50)
                      let route = "meCommunityUpdate"
                      if (this.props.navigation.state.routeName.startsWith("home")) route = "homeCommunityUpdate"
                      if (this.props.navigation.state.routeName.startsWith("chat")) route = "chatCommunityUpdate"
                      if (this.props.navigation.state.routeName.startsWith("message")) route = "chatCommunityUpdate"
                      this.props.navigation.navigate(route, {
                        action: "edit", community: this.community,
                      });
                    }}
                      style={{ flexDirection: "row", padding: 12, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
                      <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18, }}>Edit Community</Text>
                    </TouchableOpacity>}

                  {/* <TouchableOpacity onPress={() => {
                if (x.xIsMuted)
                  this.mute("0")
                else this.setState({ menuModalScreen: "MUTE" })
              }}
                style={{ flexDirection: "row", padding: 12, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
                <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18, }}>{x.xIsMuted ? "Unmute" : "Mute..."}</Text>
              </TouchableOpacity> */}

                  {(this.community?.openness == "PUBLIC") &&
                    <TouchableOpacity onPress={async () => {
                      this.invite()
                    }}
                      style={{ flexDirection: "row", padding: 12, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
                      <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18, }}>Invite to Community</Text>
                    </TouchableOpacity>
                  }

                  {!this.state.managerProfileIDs.includes(global.myProfileID) &&
                    <TouchableOpacity onPress={async () => {
                      this.leave()
                    }}
                      style={{ flexDirection: "row", padding: 12, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
                      <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18, }}>Leave Community</Text>
                    </TouchableOpacity>
                  }

                  {!this.state.managerProfileIDs.includes(global.myProfileID) && <TouchableOpacity
                    onPress={async () => {
                      this.setState({ showMenuModal: false })
                      await global.timeout(50)
                      this.props.navigation.navigate("meReport", {
                        community: this.community,
                        profileID: this.state.managerProfileID,
                        profileName: (this.community.name || " the community"),
                        profileBusinessName: "-",
                        profilePicture: this.community.communityBackgroundPictureURL || ""
                      });
                    }}
                    style={{ flexDirection: "row", padding: 12, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
                    <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18, }}>Report</Text>
                  </TouchableOpacity>}

                  <View style={{ paddingTop: 100 }} />
                </View>
                :
                <View
                  style={{
                    alignItems: "flex-start", backgroundColor: "#ccd", borderTopEndRadius: 15, borderTopLeftRadius: 15
                  }}
                >
                  {/* <TouchableOpacity onPress={async () => {
                this.mute("1d")
              }}
                style={{ flexDirection: "row", padding: 13, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
                <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18 }}>... 1 day</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={async () => {
                this.mute("1w")
              }}
                style={{ flexDirection: "row", padding: 13, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
                <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18 }}>... 1 week</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={async () => {
                this.mute("1y")
              }}
                style={{ flexDirection: "row", padding: 13, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
                <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18 }}>... 1 year</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={async () => {
                this.mute("28y")
              }}
                style={{ flexDirection: "row", padding: 13, width: "100%", borderBottomWidth: 0.5, borderBottomColor: "#eef" }}>
                <Text style={{ color: "#223", fontWeight: "bold", fontSize: 18 }}>... forever</Text>
              </TouchableOpacity> */}

                  <View style={{ paddingTop: 50 }} />
                </View>
              }

            </View>
          </Modal>
          <NavigationEvents
            onWillBlur={async (payload) => {
              if (this.state.showNewMenu) this.setState({ showNewMenu: false })
            }}
            onDidFocus={async (payload) => {
              if (this.state.communityID) this.loadGroups(30)
              // const newCommunityID = this.props.navigation.getParam("communityID", null);
              // if (newCommunityID !== this.state.communityID && this.state.communityID) {
              //   const routeName = this.props.navigation.state.routeName
              //   this.props.navigation.navigate({
              //     routeName, params: { communityID: newCommunityID }
              //     , key: newCommunityID
              //   })
              // }
            }}
          />

          <Modal
            animationType="fade"
            transparent={false}
            visible={this.state.showJoinModal}
            onRequestClose={() => {
              //
            }}
          >
            <View style={{ backgroundColor: "#131313", flex: 1, maxWidth: 350 }}>
              <ScrollView style={{ maxWidth: 350 }}>
                <View
                  style={{
                    height: 300, justifyContent: "flex-start", alignItems: "center",
                    marginBottom: 0, overflow: "hidden",
                  }}
                >
                  {!!this.state.backgroundPictureURL && (
                    <CachedImage
                      key={this.state.backgroundPictureURL || "-"}
                      style={{ width: Dimensions.get("window").width, height: 300 }}
                      source={{ uri: this.state.backgroundPictureURL }}
                    />
                  )}
                </View>
                <View style={{
                  justifyContent: "space-between", alignItems: "center",
                  flexDirection: "row",
                }}>
                  <View style={{ flex: 1 }}>
                    <View style={{ paddingTop: 12, paddingBottom: 8, paddingLeft: 5 }}>
                      <Text style={{ color: "#aab", fontSize: 18, fontWeight: 'bold', }}>{this.state.name}</Text>
                      <Text style={{ color: "#889", fontSize: 15 }}>{this.state.nOfMembers} {this.state.nOfMembers == 1 ? 'member' : 'members'}</Text>
                    </View>
                  </View>
                </View>
                {!this.state.showAbout && <View style={{ marginTop: 2, borderColor: "#303034", borderWidth: 0, padding: 5, borderRadius: 7 }}>
                  <Text numberOfLines={16} style={{ color: "#aab", fontSize: 15 }}>{this.state.description || ""}</Text>
                </View>}
                {!this.state.aaJoin ? <View>
                  <TouchableOpacity
                    onPress={() => { this.join() }}
                    style={{
                      width: "90%", height: 50, backgroundColor: "#A9D046",
                      borderRadius: 12, padding: 10, alignItems: "center",
                      justifyContent: "center", alignSelf: "center", marginTop: 40
                    }}
                  >
                    <Text style={{ fontWeight: "bold", fontSize: 15, }}>
                      {"JOIN >>"}
                    </Text>
                  </TouchableOpacity>
                  <View style={{ height: 40 }} />
                  <TouchableOpacity
                    onPress={async () => {
                      this.setState({ aaJoin: true })
                      await global.timeout(50)
                      this.props.navigation.goBack();
                      await global.timeout(500);
                      this.setState({ showJoinModal: false })
                    }}
                    style={{
                      width: "90%", height: 22, alignSelf: "center",
                      borderRadius: 12, alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text style={{ fontSize: 15, color: "#909090" }}>
                      {"Cancel  "}
                    </Text>
                  </TouchableOpacity>
                </View>
                  : <ActivityIndicator size="large" color="#A8CF45" style={{ marginTop: 75, alignSelf: "center" }} animating={true} />}
              </ScrollView>
              <View style={{ height: 30, width: "100%", alignItems: "center" }}>
                <Text style={{ fontSize: 15, color: "#909090" }}>
                  ⓘ You can always leave a community after joining
                </Text>
              </View>
              <View style={{ height: 30, width: "100%" }} />
            </View>
          </Modal>

          <Modal
            animationType="fade"
            transparent={false}
            visible={this.state.showInviteModal}
            onRequestClose={() => {
              //
            }}
          >
            <View style={{ backgroundColor: "#131313", flex: 1, maxWidth: 350 }}>
              <ScrollView>
                <View
                  style={{
                    height: 300, justifyContent: "flex-start", alignItems: "center",
                    marginBottom: 0, overflow: "hidden",
                  }}
                >
                  {!!this.state.backgroundPictureURL && (
                    <CachedImage
                      key={this.state.backgroundPictureURL || "-"}
                      style={{ width: Dimensions.get("window").width, height: 300 }}
                      source={{ uri: this.state.backgroundPictureURL }}
                    />
                  )}
                </View>
                <View style={{
                  justifyContent: "space-between", alignItems: "center",
                  flexDirection: "row",
                }}>
                  <View style={{ flex: 1 }}>
                    <View style={{ paddingTop: 12, paddingBottom: 28, paddingLeft: 5 }}>
                      <Text style={{ color: "#aab", fontSize: 14, }}>{this.state.name}</Text>
                    </View>
                  </View>
                </View>

                {!this.state.aaSubscribe ? <View>
                  {/***** Button Line *****/}
                  <View
                    style={{
                      backgroundColor: "#222",
                      minHeight: 120,
                      marginHorizontal: 5,
                      borderTopWidth: 0,
                      marginBottom: 30,
                      borderBottomColor: "#444",
                      borderTopColor: "#444",
                      borderRadius: 24
                    }}
                  >
                    <View style={{ marginBottom: 0, marginTop: 15, borderColor: "#303034", borderBottomWidth: 1, padding: 5, paddingBottom: 22, borderRadius: 7 }}>
                      <Text numberOfLines={2} style={{ color: "#eef", fontSize: 18, fontWeight: "bold", alignSelf: "center", textAlign: "center" }}>{link}</Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "#222",
                        height: 60,
                        borderTopWidth: 0,
                        margin: 10,
                        borderBottomColor: "#444",
                        borderTopColor: "#444",
                        borderRadius: 24
                      }}
                    >
                      {/***** Copy *****/}
                      {this.state.phoneNumber != "" && (
                        <View
                          style={{
                            flex: 1,
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          {!this.state.linkJustCopied ? <TouchableOpacity
                            onPress={async () => {
                              Clipboard.setString(link);
                              this.setState({ linkJustCopied: true })
                              await global.timeout(1000)
                              this.setState({ linkJustCopied: false })
                            }}
                            style={{
                              alignItems: "center",
                              justifyContent: "flex-end",
                              backgroundColor: "#000",
                              width: 75,
                              height: 55,
                              borderRadius: 18,
                              borderWidth: 0,
                              borderColor: "#444",
                              paddingHorizontal: 8,
                              paddingVertical: 5
                            }}
                          >
                            <Image
                              style={{
                                width: 21,
                                height: 21,
                                marginBottom: 5,
                                tintColor: "#aab"
                              }}
                              source={require("../../images/icons/Copy.png")}
                            />
                            <Text
                              style={{
                                fontSize: 12,
                                color: "#aab",
                                fontWeight: "bold"
                              }}
                            >
                              Copy
                            </Text>
                          </TouchableOpacity>
                            : <TouchableOpacity
                              onPress={async () => {
                                Clipboard.setString(link);
                                this.setState({ linkJustCopied: true })
                                await global.timeout(2000)
                                this.setState({ linkJustCopied: false })
                              }}
                              style={{
                                alignItems: "center",
                                justifyContent: "flex-end",
                                backgroundColor: "#bbb",
                                width: 75,
                                height: 55,
                                borderRadius: 18,
                                borderWidth: 0,
                                borderColor: "#444",
                                paddingHorizontal: 8,
                                paddingVertical: 5
                              }}
                            >
                              <Image
                                style={{
                                  width: 21,
                                  height: 21,
                                  marginBottom: 5,
                                }}
                                source={require("../../images/icons/yes_tick.png")}
                              />
                              <Text
                                style={{
                                  fontSize: 12,
                                  color: "#223",
                                  fontWeight: "bold"
                                }}
                              >
                                Copied
                              </Text>
                            </TouchableOpacity>}
                        </View>
                      )}
                      {/***** Chat *****/}
                      <View
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <TouchableOpacity
                          onPress={async () => {
                            this.setState({ showInviteModal: false })
                            await global.timeout(150)
                            this.props.navigation.navigate("meSelectContact", {
                              input: this.community.xID,
                              purpose: "ShareGivenCommunity"
                            });
                          }}
                          style={{
                            alignItems: "center",
                            justifyContent: "flex-end",
                            backgroundColor: "#000",
                            width: 75,
                            height: 55,
                            borderRadius: 18,
                            borderWidth: 0,
                            borderColor: "#444",
                            paddingHorizontal: 8,
                            paddingVertical: 5
                          }}
                        >
                          <Image
                            style={{
                              width: 24,
                              height: 24,
                              marginBottom: 5,
                              tintColor: "#aab"
                            }}
                            source={require("../../images/icons/Payout.png")}
                          />
                          <Text
                            style={{
                              fontSize: 12,
                              color: "#aab",
                              fontWeight: "bold"
                            }}
                          >
                            Forward
                          </Text>
                        </TouchableOpacity>
                      </View>
                      {/********** Share **********/}
                      <View
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        {
                          <TouchableOpacity
                            onPress={() => {
                              global.shareSocial(link)
                            }}
                            style={{
                              alignItems: "center",
                              justifyContent: "flex-end",
                              backgroundColor: "#000",
                              width: 75,
                              height: 55,
                              borderRadius: 18,
                              borderWidth: 0,
                              borderColor: "#444",
                              paddingHorizontal: 8,
                              paddingVertical: 5
                            }}
                          >
                            <Image
                              style={{
                                width: 21,
                                height: 21,
                                marginBottom: 5,
                                tintColor: "#aab"
                              }}
                              source={require("../../images/icons/Share.png")}
                            />
                            <Text
                              style={{
                                fontSize: 12,
                                color: "#aab",
                                fontWeight: "bold"
                              }}
                            >
                              Share
                            </Text>
                          </TouchableOpacity>
                        }
                      </View>
                    </View>
                  </View>

                  <TouchableOpacity style={{
                    marginTop: 20, alignSelf: "center", backgroundColor: "#000",
                    borderRadius: 20, padding: 14, width: 90, borderWidth: 0.2,
                    borderColor: "#aab"
                  }}
                    onPress={() => { this.setState({ showInviteModal: false }) }}>
                    <Text
                      style={{
                        fontSize: 18, fontWeight: "bold",
                        color: "#aab",
                        alignSelf: "center",
                      }}
                    >
                      Done
                    </Text>
                  </TouchableOpacity>
                </View>
                  : <ActivityIndicator size="large" color="#A8CF45" style={{ marginTop: 75, alignSelf: "center" }} animating={true} />}
              </ScrollView>
              <View style={{ height: 30, width: "100%", alignItems: "center" }}>
                <Text style={{ fontSize: 15, color: "#909090" }}>
                  ⓘ Anyone can open this link to join the community
                </Text>
              </View>
              <View style={{ height: 30, width: "100%" }} />
            </View>
          </Modal>

          <NavigationEvents
            onWillBlur={async (payload) => {
              if (this.state.showNewMenu) this.setState({ showNewMenu: false })
            }}
            onDidFocus={async (payload) => {
              if (this.state.communityID) this.loadGroups(30)
              // const newCommunityID = this.props.navigation.getParam("communityID", null);
              // if (newCommunityID !== this.state.communityID && this.state.communityID) {
              //   const routeName = this.props.navigation.state.routeName
              //   this.props.navigation.navigate({
              //     routeName, params: { communityID: newCommunityID }
              //     , key: newCommunityID
              //   })
              // }
            }}
          />
        </View >

        {<View style={{
          flex: 1, padding: 10, backgroundColor: '#383838',
          height: global.SHEIGHT, paddingBottom: 50,
          ...((!this.state.activeGroup && (isMobile)) && { display: 'none', opacity: 0 })
          //  , maxWidth: 500 
        }}>
          {!this.state.activeGroup ? (isMobile) ? <View></View> : <Text
            style={{ fontSize: 12, color: "#ccd", paddingTop: 3 }}>...
          </Text> :
            !this.state.activeGroup?.groupYoutubeID ? <ChatGroup
              backgroundColor='#383838'
              navigation={this.props.navigation}
              group={this.state.activeGroup}
              key={this.state.activeGroup.xID}
              goBack={() => { this.setState({ activeGroup: null }) }}
            /> : <View style={{ flexDirection: (isMobile) ? 'column' : 'row', width: '100%', height: global.SHEIGHT }}>
              <View style={{ width: (isMobile) ? '100%' : '50%' }}>
                <View
                  style={{
                    alignItems: "center",
                    backgroundColor: "#000"
                  }}
                >
                  <iframe width="100%" height={isMobile ? (global.SWIDTH * 9 / 16).toString() : "315"}
                    src={`https://www.youtube.com/embed/${this.state.activeGroup.groupYoutubeID}`}
                  >
                  </iframe>
                </View>
              </View>
              <ChatGroup
                backgroundColor='#383838'
                navigation={this.props.navigation}
                group={this.state.activeGroup}
                key={this.state.activeGroup.xID}
                goBack={() => { this.setState({ activeGroup: null }) }}
              />
            </View>}
        </View>}
      </View>
    );
  }
}

class Member extends Component {
  state = {
    pictureURL: "", name: "", businessName: "", showEditButtons: false, aa: false
  }

  async componentDidMount() {
    try {
      const profileDoc = await firebase
        .firestore()
        .collection("Profiles")
        .doc(this.props.profileID)
        .get()
      const profile = profileDoc.data()
      let name = profile.profileName || "- No Name -"
      if (this.props.isOwner) name = name + " 👑"
      else if (this.props.isManager) name = name + " 🎤"
      this.setState({
        pictureURL: profile.profilePictureURL || "",
        name,
        businessName: profile.profileNameBusiness || ""
      })
    } catch (e) {
      global.warn(e, "cdm_Member")
    }
  }

  editMember = async action => {
    let msg = ""
    if (action == 'removeMember') msg = "This will remove " + (this.state.name || "this member") + " from the community."
    else if (action == 'makeManager') msg = "This will grant " + (this.state.name || "this member") + " manager rights."
    else if (action == 'removeManager') msg = "This will remove manager rights from " + (this.state.name || "this member") + "."
    else if (action == 'transferOwnership') msg = "This will transfer community ownership from you to " + (this.state.name || "this member") + "."
    else return alert("Internal error")

    Alert.alert(
      "Sure?", msg,
      [{ text: "Cancel", onPress: () => console.log("Cancel Pressed"), style: "cancel" },
      { text: "Yes", onPress: () => { this.editMemberExecution(action) } }],
      { cancelable: false });
  }
  editMemberExecution = async action => {
    try {
      this.setState({ aa: true });
      const r = await fetch(global.cloudFunctionURL + "updateCommunity", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          isWeb: true,
          isWeb: true,
          action,
          targetProfileID: this.props.profileID,
          communityID: this.props.communityID,
          myUID: global.myUID,
          idToken: global.idToken,
        })
      });
      const r2 = await r.json();
      if (r2.msg === "SUCCESS") {
        await global.timeout(500)
      } else alert("Action not successful.");
      this.setState({ aa: false, showEditButtons: false });
    } catch (err) {
      this.setState({ aa: false });
      global.warn(err, "com_editMember")
      alert("Action not successful")
    }
  };

  render() {
    return (
      <View style={{ ...(this.state.showEditButtons && { backgroundColor: "#272727", borderRadius: 5, paddingTop: 5 }) }}>
        <TouchableOpacity activeOpacity={this.props.pressable ? 1 : 0.2}
          onPress={() => {
            if (this.props.pressable) global.navigateProfile(this.props.navigation, this.props.profileID)
          }}>
          <View style={{ height: 35, flexDirection: "row", alignItems: "center", marginLeft: 10, alignSelf: "stretch" }}>
            <View style={{ width: 33, justifyContent: "center" }}>
              {this.state.pictureURL ? <Image
                style={{
                  width: 25, height: 25, borderRadius: 12.5,
                }}
                source={{
                  uri: this.state.pictureURL
                }}
              /> : <View style={{
                width: 25, height: 25, borderRadius: 12.5, backgroundColor: "#668"
              }} />}
            </View>
            <View style={{ flex: 1 }}>
              <Text style={{ color: "#aab", fontSize: 13 }}>{this.state.name}</Text>
              <Text style={{ color: "#889", fontSize: 10 }}>{this.state.businessName}</Text>
            </View>
            {!this.props.isOwner && this.props.editable && <View style={{ width: 65 }}>
              <TouchableOpacity onPress={() => { this.setState({ showEditButtons: !this.state.showEditButtons }) }}
                style={{
                  alignSelf: "center", width: 55, borderWidth: 0.4, height: 25,
                  borderRadius: 13, borderColor: "#aab", alignItems: "center", justifyContent: "center",
                  backgroundColor: this.state.showEditButtons ? "#272727" : "#000"
                }}>
                <Text style={{ color: "#aab", fontSize: 12 }}>Edit</Text>
              </TouchableOpacity>
            </View>}
          </View>
        </TouchableOpacity>
        {this.state.showEditButtons && (this.state.aa ? <View style={{ height: 30, flexDirection: "row", justifyContent: "center", alignItems: "center", alignSelf: "stretch", marginBottom: 7, marginHorizontal: 3 }}>
          <ActivityIndicator size="small" color="#ccccd5" animating={true} />
          <Text style={[global.gStyles.buttontXW, { marginLeft: 8 }]}>Updating</Text>
        </View>
          : <View style={{ height: 30, flexDirection: "row", justifyContent: this.state.aa ? "center" : "space-between", alignItems: "center", alignSelf: "stretch", marginBottom: 7, marginHorizontal: 3 }}>
            {!this.props.isOwner && !this.props.isManager && <TouchableOpacity
              onPress={() => { this.editMember("removeMember") }}
              style={[global.gStyles.buttonXW, { margin: 3, minWidth: 85 }]}>
              <Text style={[global.gStyles.buttontXW, { fontWeight: "bold" }]}> ❌   Remove </Text>
            </TouchableOpacity>}
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              {!this.props.isManager && <TouchableOpacity
                onPress={() => { this.editMember("makeManager") }}
                style={[global.gStyles.buttonXW, { margin: 3, minWidth: 85 }]}>
                <Text style={global.gStyles.buttontXW}>Make Manager</Text>
              </TouchableOpacity>}
              {this.props.isManager && !this.props.isOwner && <TouchableOpacity
                onPress={() => { this.editMember("removeManager") }}
                style={[global.gStyles.buttonXW, { margin: 3, minWidth: 85 }]}>
                <Text style={global.gStyles.buttontXW}>Remove Manager</Text>
              </TouchableOpacity>}
              {this.props.amOwner && !this.props.isOwner && <TouchableOpacity
                onPress={() => { this.editMember("transferOwnership") }}
                style={[global.gStyles.buttonXW, { margin: 3, minWidth: 85 }]}>
                <Text style={global.gStyles.buttontXW}>Make Owner</Text>
              </TouchableOpacity>}
            </View>
          </View>)}
      </View>)
  }
}

class FadeInView extends Component {
  state = {
    opacity: new Animated.Value(0),
  }

  componentDidMount = () => {
    Animated.timing(this.state.opacity, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
    }).start();
  }

  render() {
    return (
      <Animated.View
        onLoad={this.onLoad}
        {...this.props}
        style={[
          {
            opacity: this.state.opacity,
            transform: [
              {
                scale: this.state.opacity.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0.85, 1],
                })
              },
            ],
          },
          this.props.style,
        ]}
      />
    );
  }
}


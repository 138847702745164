
import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TextInput,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  Linking
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import { SvgCss } from "react-native-svg";
const gStyles = global.gStyles //    "../../styles/gStyles";
import CachedImage from "../../shared/CachedImage";
import PushNotificationHandler from "../../Messages/PushNotificationHandler"


export default class HomeSellersScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Sellers' Corner",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };
  state = {
    hasBusiness: false,
    hasOffers: false,
    hasWebsite: false,
    websiteExpired: false,
    websiteName: "",
    trustScoreBadge: "",
    trustScoreDetails: [],
    email: "",
    loadingUser: true,
    loadingProfile: true, //not needed because of globals
    loadingTrustScore: true,
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
    this.unsubscribeFirestore2 = () => { return; };
  }

  // async componentDidMount() {
  //   this.setState({
  //     hasBusiness: true,
  //     hasWebsite: false,
  //     hasOffers: true,
  //     loadingProfile: false, loadingUser: false
  //   })
  // }
  async componentDidMount() {
    try {
      if (!global.myUID || !global.myProfileID || !global.globalsSet) await global.timeout(500)
      if (!global.myUID || !global.myProfileID || !global.globalsSet) await global.timeout(500)
      if (!global.myUID || !global.myProfileID || !global.globalsSet) await global.timeout(1500)
      if (!global.myUID || !global.myProfileID || !global.globalsSet) await global.timeout(1500)
      if (!global.myUID || !global.myProfileID || !global.globalsSet) await global.timeout(1500)
      if (!global.myUID || !global.myProfileID || !global.globalsSet) await global.timeout(1500)
      if (!global.myUID || !global.myProfileID || !global.globalsSet) await global.timeout(1500)
      if (!global.myUID || !global.myProfileID || !global.globalsSet) await global.timeout(1500)
      if (!global.myUID || !global.myProfileID || !global.globalsSet) await global.timeout(1500)
      if (!global.myUID || !global.myProfileID || !global.globalsSet) await global.timeout(2500)
      if (!global.myUID || !global.myProfileID || !global.globalsSet) this.props.navigation.goBack();

      this.setState({
        hasBusiness: global.myBusinessName ? true : false,
        hasWebsite: global.hasNoWebsiteYet ? false : true,
        hasOffers: (global.myPostIDs && Array.isArray(global.myPostIDs) && global.myPostIDs.length > 0),
        loadingProfile: false, loadingUser: false
      })

      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Profiles")
        .doc(global.myProfileID)
        .onSnapshot(this.updateProfile)
      this.unsubscribeFirestore2 = firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .onSnapshot(this.updateUser)

    } catch (err) {
      if (global.reportError) {
        global.reportError(err, "catches", "homeSellers");
      }
    }
  }

  updateProfile = async (pDoc) => {
    try {
      if (!pDoc) return;
      const p = pDoc.data()
      if (p.profileNameBusiness) this.setState({ hasBusiness: true })
      else this.setState({ hasBusiness: false })
      if (p.postIDs && Array.isArray(p.postIDs) && p.postIDs.length > 0) this.setState({ hasOffers: true })
      else this.setState({ hasOffers: false })
      this.setState({
        websiteName: p.profileWebsiteURL || "",
        trustScoreBadge: p.trustScoreBadge || "New Provider 🆕",
        trustScoreDetails: p.trustScoreDetails || []
      })

      this.setState({ loadingProfile: false, loadingTrustScore: false })
    } catch (err) {
      console.warn("error in homeSellers2")
      console.log(err)
      if (global.reportError) {
        global.reportError(err, "catches", "homeSellers2");
      }
    }
  }

  updateUser = async (uDoc) => {
    try {
      if (!uDoc) return;
      const u = uDoc.data()

      if (u.idShop && !this.shopLoaded) {
        const shopDoc = await firebase
          .firestore()
          .collection("Shops")
          .doc(u.idShop)
          .get()
        if (shopDoc) {
          this.shop = shopDoc.data()
          this.shopLoaded = true
        }
      }

      if (u.websiteJustPurchased || u.websiteTrialJustSelected > 0 || u.idShop) this.setState({ hasWebsite: true })
      else this.setState({ hasWebsite: false })
      if (u.idShop && this.shop && this.shop.renewalDate) {
        const nowDate = new Date()
        const renewalDate = this.shop.renewalDate.toDate()
        if (nowDate > renewalDate) this.setState({ websiteExpired: true })
        else this.setState({ websiteExpired: false })
      } else this.setState({ websiteExpired: false })

      this.setState({ loadingUser: false })
    } catch (err) {
      console.warn("error in homeSellers3")
      console.log(err)
      if (global.reportError) {
        global.reportError(err, "catches", "homeSellers3");
      }
    }
  }

  subscribeNewsletter = async () => {
    if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.email)))
      return alert("Not a valid e-mail address")
    try {
      this.setState({ aaNewsletter: true })
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "setGeneral", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          action: "subscribeNewsletter",
          email: this.state.email,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ aaNewsletter: false })
      if (r2.msg == "SUCCESS") {
        alert("Successfully signed up!")
      } else {
        alert("Server error. You can try to subscribe on wakandamarket.com")
      }
    } catch (e) {
      this.setState({ aaNewsletter: false })
      global.warn(e, "subscribeNewsletter")
      alert("Error while subscribing. Please subscribe on wakandamarket.com")
    }
  }

  componentWillUnmount() {
    console.log("Component homeSellers will unmount");
    this.unsubscribeFirestore();
    this.unsubscribeFirestore2();
  }



  render() {
    if (this.state.loadingUser || this.state.loadingProfile) return (
      <View style={{ height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: "#282828" }}>
        <View style={{ opacity: 0.5, marginBottom: 30 }}><SvgCss height="120" width="120" xml={global.shopIcon} /></View>
        <ActivityIndicator size="large" color="#bbb" />
      </View>
    );
    let colTrustScore = "#ccd"
    if (this.state.trustScoreBadge == "High") colTrustScore = "#93CF93"
    if (this.state.trustScoreBadge == "Very High") colTrustScore = "#93CF93"
    if (this.state.trustScoreBadge == "Low") colTrustScore = "#CF9393"
    if (this.state.trustScoreBadge == "New Provider") colTrustScore = "#CFCF93"
    if (this.state.trustScoreBadge == "OK") colTrustScore = "#CFCF93"
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#282828" }}>
        <StatusBar barStyle="light-content" />

        <View
          style={{ backgroundColor: "#282828", flex: 1 }}
        >
          <View
            style={{
              backgroundColor: "#282828",
              borderBottomColor: "#555",
              borderBottomWidth: 1,
              ...Platform.select({ ios: { height: 55 }, android: { height: 75 } })
            }}
          />
          <ScrollView
            ref={ref => (this.myScrollView = ref)}
          >
            <PushNotificationHandler />

            {/* <View style={{ position: "absolute", top: 0, left: 20, opacity: 0.05 }}>
                <SvgCss height="800" width="800" xml={global.shopIcon} />
              </View> */}
            {/* <Text style={{ color: "#fff", fontSize: 14, marginLeft: 10, fontStyle: "italic" }}>Offering Goods or Services on Wakanda is easy:</Text>*/}
            <View style={{ height: 5 }} />
            {!this.state.hasBusiness && <Text style={{ marginTop: 30, color: "#fff", fontSize: 14, marginLeft: 20, opacity: 0.7 }}>Do you sell goods or services?</Text>}
            <View style={{ height: 5 }} />
            {/* <Text style={{ color: "#fff", fontSize: 14 }}>Here is how we help your business thrive:</Text> */}
            {!this.state.hasBusiness && <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20, overflow: "hidden" }}>
              <Text style={{ color: "#fff", fontSize: 18, fontWeight: "bold" }}>1.  Open a Shop on Wakanda </Text>
              <View style={{ padding: 10, paddingLeft: 20, paddingRight: 20 }}>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  You tell us what you offer, we will contact you with customers</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Opening a shop is free of charge</BulletPoint>
              </View>
              <TouchableOpacity
                onPress={() => {
                  //this.props.navigation.navigate("homeProfileUpdate", { isNewBusiness: true })
                  this.props.navigation.navigate("homeProfile", { action: "OPEN_A_SHOP" })
                }}
              >
                <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Open a Shop</Text>
                </View>
              </TouchableOpacity>
            </View>}

            {this.state.hasBusiness && <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20, overflow: "hidden" }}>
              <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
                <Text style={{ color: "#fff", fontSize: 18, fontWeight: "bold" }}>1.  ✅Your Shop on Wakanda is Live</Text>
              </View>
              <View style={{ padding: 10, paddingLeft: 20, paddingRight: 20 }}>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Customers can find you by the services you offer</BulletPoint>
              </View>
              <TouchableOpacity
                onPress={() => this.props.navigation.navigate("homeProfile")}
              >
                <View style={[global.gStyles.buttonXLW, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 16, color: "#ccccd5" }}>View Shop</Text>
                </View>
              </TouchableOpacity>
            </View>}

            {!this.state.hasOffers && <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20, overflow: "hidden" }}>
              <Text style={{ color: "#fff", fontSize: 18, fontWeight: "bold" }}>2.  Post Offers</Text>
              <View style={{ padding: 10, paddingLeft: 20, paddingRight: 20 }}>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  List your products or services</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  People near you will contact you if they like what they see</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Posting offers is free of charge</BulletPoint>
              </View>
              {this.state.hasBusiness && <TouchableOpacity
                onPress={() => this.props.navigation.navigate("homePosts", { action: "CREATE" })}
              >
                <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Post An Offer</Text>
                </View>
              </TouchableOpacity>}
            </View>}

            {this.state.hasOffers && <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20, overflow: "hidden" }}>
              <Text style={{ color: "#fff", fontSize: 18, fontWeight: "bold", marginBottom: 30 }}>2.  ✅You have posted offers</Text>
              <TouchableOpacity
                onPress={() => this.props.navigation.navigate("homePosts")}
              >
                <View style={[global.gStyles.buttonXLW, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 16, color: "#ccccd5" }}>View Offers</Text>
                </View>
              </TouchableOpacity>
            </View>}

            {(!!this.state.hasBusiness && !this.state.loadingTrustScore) && <View style={{ marginTop: 0 }}>
              <View
                style={{
                  justifyContent: "center", alignItems: "flex-start", backgroundColor: "#000",
                  margin: 10, padding: 15, borderRadius: 15
                }}
              >
                <View
                  style={{ alignSelf: "center", justifyContent: "center", alignItems: "center", marginTop: 5 }}
                >
                  <Text style={{ fontSize: 24, color: "#fff", fontWeight: "bold" }}>
                    Your Trust Score
                        </Text>
                  <Text style={{ fontSize: 28, fontWeight: "bold", color: colTrustScore, marginTop: -5 }}>
                    {this.state.trustScoreBadge}
                  </Text>
                </View>
                <View style={{ height: 20 }} />
                {this.state.trustScoreDetails.map(item => {
                  let color = "#fff", icon = " \u2022" // default esp. for yellow
                  if (item.color == "green") color = "#93CF93"
                  if (item.color == "orange") color = "#CF9393"
                  if (item.color == "red") color = "#CF9393"
                  if (item.color == "green") icon = "☆"
                  if (item.color == "orange") icon = " ↯"
                  if (item.color == "red") icon = " ↯"
                  return (<View style={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginRight: 15, marginBottom: 5 }} key={item.text}>
                    <Text style={{ width: 24, color, fontSize: 14 }}>{icon}</Text>
                    <Text style={{ fontSize: 14, color }}>{item.text}</Text>
                  </View>)
                })}
                <View style={{ height: 25 }} />
                {((this.state.trustScoreBadge == "Very High" || this.state.trustScoreBadge == "High") && (global.myLocalCurrency == "NGN" || !global.myLocalCurrency))
                  ? <View>
                    <View style={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginRight: 15 }} >
                      <Text style={{ fontSize: 14, color: "#fff" }}>✅ You qualify for a </Text>
                      <Text style={{ fontSize: 14, fontWeight: "bold", color: "#fff" }}>business loan!  </Text>
                      <TouchableOpacity
                        onPress={() => {
                           alert("Currently no loan available. Bear with us... we're working hard on getting some by mid of April!")
                          // global.reportStats("pressed_Loan_SeeMore");
                          //this.props.navigation.navigate("homeSellersLoan")
                        }}
                      >
                        <View style={[global.gStyles.buttonX, {}]} >
                          <Text style={{ fontSize: 12, fontWeight: "bold", color: "#000" }}>Apply</Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                    <Text style={{ fontSize: 11, color: "#fff", marginBottom: 5, marginTop: 30 }}>{!!this.state.hasWebsite ? "ⓘ Increase your score further by referring customers, posting a video offer, or by getting a business website! \nAnd tell your customers to order through the Wakanda app!"
                      : "ⓘ Increase your score further by referring customers or posting a video offer!"}</Text>
                  </View>
                  : <View>
                    <Text style={{ fontSize: 14, color: "#fff" }}>Increase your score by referring customers, getting followers, likes, have customers order from you through Wakanda, by posting a video offer, or by getting a business website!</Text>
                    <Text style={{ fontSize: 14, color: "#fff", marginTop: 20 }}>Get access to a business loan and sell more with a higher trust score!</Text>
                  </View>}
                <View style={{ height: 10 }} />
              </View>
            </View>}

            <View style={{ height: 35 }} />
            <Text style={{ color: "#fff", fontSize: 14, marginLeft: 20, opacity: 0.7 }}>Here's further ways how we can help you sell:</Text>
            <View style={{ height: 5 }} />

            {!this.state.hasWebsite && <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20, overflow: "hidden" }}>
              <Text style={{ color: "#fff", fontSize: 18, fontWeight: "bold" }}>-  Get your own Website</Text>
              <View style={{ padding: 10, paddingLeft: 20, paddingRight: 20 }}>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Take your business to the next level with www.YourBusiness.com</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Try it out for free</BulletPoint>
              </View>
              <TouchableOpacity
                onPress={() => { this.props.navigation.navigate("meWebsite"); global.reportStats("pressed_SellerWebsite"); }}
              >
                <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Get a Website</Text>
                </View>
              </TouchableOpacity>
            </View>}

            {this.state.hasWebsite && !this.state.websiteExpired && <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20, overflow: "hidden" }}>
              <Text style={{ color: "#fff", fontSize: 18, fontWeight: "bold" }}>-  ✅Your website is live</Text>
              <Text style={{ color: "#fff", fontSize: 14, }}>           {this.state.websiteName}</Text>
              <View style={{ height: 30 }} />
              <TouchableOpacity
                onPress={() => { this.props.navigation.navigate("meWebsiteManagement") }}
              >
                <View style={[global.gStyles.buttonXLW, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 16, color: "#ccccd5" }}>Manage Website</Text>
                </View>
              </TouchableOpacity>
            </View>}

            <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20, overflow: "hidden" }}>
              <Text style={{ color: "#fff", fontSize: 18, fontWeight: "bold" }}>-  Refer customers</Text>
              <View style={{ padding: 10, paddingLeft: 20, paddingRight: 20 }}>
                <Text style={{ fontSize: 14, marginBottom: 5, fontWeight: "bold", color: "#ccc" }}>Tell your friends you're on Wakanda!</Text>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  They will automatically follow you</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Your offers will show on top of their searches</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  You can regularly message them</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Your trust score will go up the more you refer</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  You add a personalized welcome discount</BulletPoint>
              </View>

              <TouchableOpacity
                onPress={() => { this.props.navigation.navigate("homeInvite"); global.reportStats("pressed_SellerInvite"); }}
              >
                <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Invite friends!</Text>
                </View>
              </TouchableOpacity>
            </View>

            {!!this.state.hasOffers && <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20, overflow: "hidden" }}>
              <Text style={{ color: "#fff", fontSize: 18, fontWeight: "bold" }}>-  Participate in {global.salesString || "Flash Sales!!"}</Text>
              <View style={{ padding: 10, paddingLeft: 20, paddingRight: 20 }}>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Add a discount to one or several offers</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  After three days, you will automatically participate in {global.salesString || "Flash Sales!!"} on the app and on wakandamarket.com</BulletPoint>
              </View>
              <TouchableOpacity
                onPress={() => { this.props.navigation.navigate("homePosts"); global.reportStats("pressed_SellerAddDiscount") }}
              >
                <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Add Discounts</Text>
                </View>
              </TouchableOpacity>
            </View>}

            {/* <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20, overflow: "hidden" }}>
              <Text style={{ color: "#fff", fontSize: 18, fontWeight: "bold" }}>-  Take a Sales Training</Text>
              <View style={{ padding: 10, paddingLeft: 20, paddingRight: 20 }}>
                <Text style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>Learn how to</Text>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  start your business</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  grow your business</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  manage your business</BulletPoint>
              </View>

              <TouchableOpacity
                onPress={() => Linking.openURL("http://market.wakandaacademy.com")}
              >
                <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Take Training</Text>
                </View>
              </TouchableOpacity>
            </View> */}

            <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20, overflow: "hidden" }}>
              <Text style={{ color: "#fff", fontSize: 18, fontWeight: "bold", marginBottom: 30 }}>-  Become a Verified Partner Seller</Text>

              <TouchableOpacity
                onPress={() => {
                  Linking.openURL("tel:" + ((global.myLocalCurrency == "GHS") ? "+233545414599" : "+2348020510968"));
                  // Linking.openURL("tel:" + ((global.myLocalCurrency == "GHS") ? "+233545414599" : "+2349099992564"));
                  if (this.state.profileID !== global.myProfileID) global.reportStats("pressed_Sellers_BecomeVerifiedPremium")
                }}
              >
                <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Contact Wakanda</Text>
                </View>
              </TouchableOpacity>
            </View>

            <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20 }}>
              <Text style={{ color: "#fff", fontSize: 18, fontWeight: "bold" }}>Wakanda Academy Newsletter</Text>
              <View style={{ padding: 10, paddingLeft: 20, paddingRight: 20 }}>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Become a pro on Wakanda and selling online</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Get updates on sales events</BulletPoint>
              </View>
              <View style={{ marginTop: 10 }}>
                <Text style={{ fontSize: 14, color: "#A8CF45", paddingBottom: 5, justifyContent: "flex-start" }}>
                  Your e-mail address:</Text>
                <TextInput
                  onChangeText={email => { this.setState({ email }); }}
                  onFocus={() => { this.myScrollView?.scrollToEnd({ animated: true }); }}
                  style={{ color: "#ffffff", paddingLeft: 3, borderRadius: 5, height: 36, backgroundColor: "#334" }}
                  underlineColorAndroid="transparent"
                  maxLength={80}
                  defaultValue={this.state.email}
                  keyboardAppearance={"dark"}
                  returnKeyType={"done"}
                  bordered
                />
                {this.state.email?.length > 0 && (!this.state.aaNewsletter ? <TouchableOpacity
                  style={{ marginTop: 15 }}
                  onPress={() => { this.subscribeNewsletter(); global.reportStats("pressed_SubscribeSellerNewsletter"); }}
                >
                  <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                    <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Subscribe</Text>
                  </View>
                </TouchableOpacity>
                  : <ActivityIndicator size="small" color="#ccc" style={{ alignSelf: "center", paddingVertical: 25 }} />)}
              </View>
            </View>

            <TouchableOpacity
              style={[global.gStyles.me, { height: 120 }]}
              onPress={() => Linking.openURL("https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FWakanda%20Guide2.pdf?alt=media&token=7a32b6b0-b760-4f0e-ad61-71e49574453f")}
            >
              <View>
                <View style={{ flexDirection: "row" }}>
                  <View style={{ backgroundColor: "#ccc", height: 20, width: 20, marginRight: 8, borderRadius: 10, justifyContent: "center", alignItems: "center" }}>
                    <Text style={{ fontSize: 16, color: "#000", fontWeight: "bold" }}>?</Text>
                  </View>
                  <Text style={{ color: "#ccc", fontSize: 18, fontWeight: "bold", marginLeft: 0 }}>Wakanda Guide</Text>
                </View>
                <View style={{ padding: 5, paddingLeft: 20, paddingRight: 20, flexDirection: "row" }}>
                  <CachedImage
                    style={{ width: 111, height: 61, margin: 5, resizeMode: "contain", borderRadius: 5, borderWidth: 0, borderColor: "#aaa" }}
                    source={{ uri: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FWG%20Thumb2.jpg?alt=media&token=ecfdaee9-f2b9-497d-b56c-db689eefe535" }}
                  />
                  {/* <CachedImage
                    style={{ width: 111, height: 61, margin: 5, resizeMode: "contain", borderRadius: 5, borderWidth: 0.5, borderColor: "#aaa" }}
                    source={{ uri: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FWG%20Thumb.jpg?alt=media&token=e89a75d2-f658-493e-af29-349b0780b125" }}
                  /> */}
                </View>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={[global.gStyles.me, { height: 120 }]}
              onPress={() => Linking.openURL("https://www.youtube.com/channel/UCGkQ187DmGAmA63VyyAACAA")}
            >
              <View>
                <View>
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ backgroundColor: "#ccc", height: 20, width: 20, marginRight: 8, borderRadius: 10, justifyContent: "center", alignItems: "center" }}>
                      <Text style={{ fontSize: 16, color: "#000", fontWeight: "bold" }}>?</Text>
                    </View>
                    <Text style={{ color: "#ccc", fontSize: 18, fontWeight: "bold", marginLeft: 0 }}>Video Help</Text>
                  </View>
                  <View style={{ padding: 5, paddingLeft: 20, paddingRight: 20, flexDirection: "row" }}>
                    <CachedImage
                      style={{ width: 111, height: 61, margin: 5, resizeMode: "contain" }}
                      source={{ uri: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FVid.jpg?alt=media&token=adf85ac0-7fff-445e-b543-95ad03eebff6" }}
                    />
                    {/* <CachedImage
                      style={{ width: 111, height: 61, margin: 5, resizeMode: "contain" }}
                      source={{ uri: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FVid3.jpg?alt=media&token=adf22127-ce61-465a-84e5-907c817dc7bd" }}
                    /> */}
                    {/* <Text style={{ fontSize: 14, marginBottom: 5, color: "#caa" }}>
                      Video Tutorials and more</Text>
                    <Text style={{ fontSize: 14, marginBottom: 5, color: "#caa" }}>
                      for Wakanda Sellers</Text> */}
                  </View>
                </View>
              </View>
            </TouchableOpacity>
            <View style={{ height: 100 }} />

          </ScrollView>
        </View>
      </SafeAreaView >
    );
  }
}

class BulletPoint extends PureComponent {
  render() {
    return (
      <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
        <Text style={[this.props.style, { marginRight: 6 }]}>{`\u2022`}</Text>
        <Text style={this.props.style}>{this.props.children}</Text>
      </View>
    );
  }
}
import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  Modal,
  Platform,
  Dimensions,
  ActivityIndicator
} from "react-native";
import * as Permissions from 'expo-permissions';
import * as Haptics from 'expo-haptics';
import * as Location from 'expo-location';
import firebase from "firebase";
import "firebase/firestore";
import Moment from "react-moment";

const gStyles = global.gStyles //    "../../styles/gStyles";
import CachedImage from "../../shared/CachedImage"
import FeedMenu from "./FeedMenu"

export default class CommunityFeed extends Component {
  state = {
    titleHeight: 50,
    pressedLike: false,
    pressedDislike: false,
    pressedJoin: false,
    showFeedMenu: false,
    watchingYoutube: false,
  }


  renderTop = (x) => {

    if (this.props.view == "LIST")
      return (<View style={{
        height: 35, justifyContent: "space-between", alignItems: "flex-start",
        flexDirection: "row"
      }}>
        <View style={{ flex: 1, flexDirection: "row", marginTop: -1 }}>
          <View style={{ width: 33 }}>
            {x.ownerPictureURL ? <Image
              style={{ width: 25, height: 25, borderRadius: 12.5 }}
              source={{
                uri: x.ownerPictureURL
              }}
            /> : <View style={{ width: 25, height: 25, borderRadius: 12.5, backgroundColor: "#668" }} />}
          </View>
          <View style={{ flex: 1, }}>
            <Text style={{ color: "#aab", fontSize: 13 }} numberOfLines={1}>{x.ownerName}</Text>
            <Text style={{ color: "#889", fontSize: 10 }} numberOfLines={1}>{x.timeCreated && (
              <Moment fromNow element={Text}>{x.timeCreatedDate}</Moment>
            )}</Text>
          </View>
        </View>
        <View
          style={{
            marginRight: 6, justifyContent: "flex-start", alignItems: "center",
            flexDirection: "row", minWidth: 20
          }}
        >
          {x.xIsMuted && <Image
            style={{ height: 16, width: 16, tintColor: "#889", marginTop: 2 }}
            source={require("../../images/icons/Muted.png")}
          />}
        </View>
        <View
          style={{
            marginRight: 6, justifyContent: "flex-start", alignItems: "center",
            flexDirection: "row", minWidth: 45, marginTop: 2
          }}
        >
          <Image
            tintColor='#889'
            style={{ height: 16, width: 17, marginRight: 4, tintColor: "#889" }}
            source={require("../../images/icons/Chat.png")}
          />
          <Text style={{ fontSize: 10, color: "#889", fontWeight: "500" }}>{x.nOfComments || 0}</Text>
          {this.props.notifications > 0 && <View style={{ backgroundColor: "#A8CF45", height: 17, width: 22, borderRadius: 8, alignItems: "center", marginLeft: 8, borderWidth: 1, borderColor: "#A8CF45", justifyContent: "center" }}>
            <Text style={{ color: "#000", fontSize: 10 }}>{this.props.notifications}</Text>
          </View>}
        </View>
        {/* <TouchableOpacity onPress={(x) => {
          this.setState({ showFeedMenu: true });
          /// Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy)
        }}>
          <View style={{ width: 40, alignItems: "center", justifyContent: "center", paddingLeft: 15 }}>
            <Text style={{ fontWeight: "bold", fontSize: 24, color: "#ccd", marginTop: 4 }}>
              ⋮
            </Text>
          </View>
        </TouchableOpacity> */}

        <FeedMenu
          showMenuModal={this.state.showFeedMenu}
          chatGroup={x}
          refresh={this.props.refresh}
          onDone={() => { this.setState({ showFeedMenu: false }) }}
          navigation={this.props.navigation} />

      </View >)

    if (this.props.view == "DETAIL")
      return (<View style={{
        height: 15, justifyContent: "space-between", alignItems: "flex-start",
        flexDirection: "row", marginTop: 5
      }}>
        <View style={{ flex: 1, flexDirection: "row" }}>
          <View style={{ width: 33 }} />
          <View style={{ width: 20, alignItems: "flex-start", justifyContent: "center" }}>
            {x.ownerPictureURL ? <Image
              style={{
                width: 18, height: 18, borderRadius: 9,
              }}
              source={{
                uri: x.ownerPictureURL
              }}
            /> : <View style={{ width: 18, height: 18, borderRadius: 9, backgroundColor: "#668" }} />}
          </View>
          <Text style={{ flex: 1, color: "#aab", fontSize: 10 }}>{x.ownerName + "    "}</Text>
          <Text style={{ flex: 1, color: "#889", fontSize: 10 }}>{x.timeCreated && (
            <Moment fromNow element={Text}>
              {x.timeCreatedDate}
            </Moment>)}
          </Text>
        </View>
        {/* {x.xDist >= 0 && (<View
          style={{ flexDirection: "row", width: 60, justifyContent: "flex-end", alignItems: "center" }}
        >
          <Image
            style={{ width: 15, height: 16.5, marginRight: 2, tintColor: "#889" }}
            source={require("../../images/icons/Location.png")}
          />
          <Text style={{ fontSize: 10, color: "#aab", fontWeight: "500" }}>
            {(Math.round(x.xDist * 10)) / 10 + " km"}
          </Text>
        </View>)} */}
        <TouchableOpacity onPress={() => { return }}>
          <View style={{ width: 40, alignItems: "center", justifyContent: "center", paddingLeft: 15 }}>
            <Text style={{ fontWeight: "bold", fontSize: 24, color: "#ccd", marginTop: 4 }}>

            </Text>
          </View>
        </TouchableOpacity>
      </View>)
  }

  renderMain = (x) => {
    const imWidth = (Dimensions.get("window").width - 40)
    const imWidthNoImg = (Dimensions.get("window").width - 40) * .4
    const imFontSize = x.groupTitle.length > 60 ? 12 : x.groupTitle.length > 40 ? 14 : x.groupTitle.length > 20 ? 13 : 16
    const nImFontSize = x.groupTitle.length > 30 ? 13 : 16
    const mainHeight = imWidth / 8 * 4

    return (<View style={{
      justifyContent: "space-between", alignItems: "center", flexDirection: "row",
    }}>
      {!x.groupPictureURL ? <View style={{}}>
        <Text numberOfLines={3}
          onLayout={(e) => { this.setState({ titleHeight: Math.round(e.nativeEvent.layout.height) }) }}
          style={{ fontWeight: "bold", fontSize: nImFontSize, color: "#ccd" }}>{x.groupTitle}</Text>
        <Text numberOfLines={(Math.round(mainHeight - this.state.titleHeight - 6) / 12)}
          style={{ fontSize: 12, color: "#ccd" }}>{x.groupDetails}</Text>
      </View> :
        <View style={{ flex: 1 }}>
          <CachedImage
            style={{ width: '100%', height: 140, marginRight: 10, backgroundColor: "#224", borderRadius: 7 }}
            source={{ uri: x.groupPictureURL }}
            key={x.groupPictureURL}
          />
          <View style={{ width: '100%', height: mainHeight }}>
            <Text numberOfLines={3}
              onLayout={(e) => { this.setState({ titleHeight: Math.round(e.nativeEvent.layout.height) }) }}
              style={{ fontWeight: "bold", fontSize: imFontSize, color: "#ccd", marginTop: 5 }}>{x.groupTitle}</Text>
            <Text numberOfLines={(Math.round(mainHeight - this.state.titleHeight - 5) / 10)}
              style={{ fontSize: 10, color: "#ccd", }}>{x.groupDetails}</Text>
          </View>
        </View>}
    </View>)
  }

  renderMainAsPoll = (x) => {
    return (<View style={{}}>
      <TouchableOpacity
        style={{ alignItems: "center", }}
        activeOpacity={1}
        disabled={!x.groupYoutubeID}
        onPress={() => {
          this.setState({ watchingYoutube: true })
        }}>
        {x.groupPictureURL && <Image
          style={{
            width: Dimensions.get("window").width * 0.9, height: 140, paddingRight: 8, borderRadius: 8.5,
          }}
          source={{ uri: x.groupPictureURL }}
          key={x.groupPictureURL}
        />}
        {!!x.groupYoutubeID && <View
          style={{
            borderRadius: 14, paddingLeft: 6, height: 55, justifyContent: "center", alignItems: "center", width: 55, bottom: 95, marginBottom: -55
          }}
        >
          <Text style={{ color: "#333", fontSize: 42 }}>{"▶️"}</Text>
        </View>}
      </TouchableOpacity>

      <View style={{ margin: 10, marginTop: 2, padding: 10 }}>
        <Text
          style={{ color: "#ccd", fontWeight: "bold", fontFamily: "ProximaNovaA-Bold", fontSize: 13, marginBottom: 5 }}
        >
          🗳️  {x.groupTitle}
        </Text>

        <View>
          {x.pollAnswers && x.pollAnswers.map((a, i) => {
            if (!x.pollHasEnded && !x.xHaveVoted && (i < 3 || (x.pollAnswers.length == 4))) return <Text key={a + i + "txt"} style={{ color: "#ccd", flex: 1, marginLeft: 6, fontFamily: "ProximaNovaA-Regular", }} numberOfLines={1}>➤  {a}</Text>

            const count = x.pollResultsCondensed[i]
            const max = Math.max(...(x.pollResultsCondensed || [1])) || 1
            if (i < 3 || (x.pollAnswers.length == 4)) return (
              <View key={a + i}>
                <View style={{ height: 15, marginTop: 3, width: "100%", flexDirection: "row" }}>
                  <View style={{ flex: count / max, height: 15, backgroundColor: "rgba(60,0,255,1)", borderRadius: 5 }} />
                  <View style={{ flex: (max - count) / max, height: 15, backgroundColor: "rgba(60,0,255,0.2)" }} />
                </View>
                <View style={{ marginTop: -15, height: 15, width: "100%", flexDirection: "row", alignItems: "center" }}>
                  <Text style={{ color: "#ccd", flex: 1, marginLeft: 6, fontSize: 11 }} numberOfLines={1}>{a}</Text>
                  <Text style={{ color: "#ccd", fontWeight: "bold", width: 25, fontSize: 11 }}>{count}</Text>
                </View>
              </View>
            )
          })}
          {x.pollAnswers && x.pollAnswers.length > 4 && <View>
            <Text style={{ color: "#ccd", fontWeight: "bold", flex: 1, marginLeft: 6, marginTop: 5 }}>+ {x.pollAnswers.length - 3} more</Text>
          </View>}

          {(!x.pollHasEnded && !x.xHaveVoted) && <View style={[global.gStyles.buttonXLW, { paddingVertical: 2.5, paddingHorizontal: 22, width: (!x.pollHasEnded && !x.xHaveVoted) ? 125 : 110, flexDirection: "row", minWidth: 15, alignItems: "center", marginTop: 10, height: 25 }]} >
            <Text style={{ fontSize: 12, fontWeight: "normal", color: "#ccd", fontFamily: "ProximaNovaA-Regular", }}>{(!x.pollHasEnded && !x.xHaveVoted) ? "Vote Now!" : "See Results"}</Text>
          </View>}
        </View>

        {!!this.state.watchingYoutube && global.renderYoutubeModal(x.groupYoutubeID,
          () => { this.setState({ watchingYoutube: false }) })}

      </View>
    </View >)
  }

  renderBottom = (x) => {
    return (<View style={{
      justifyContent: "space-between", alignItems: "center",
      flexDirection: "row"
    }}>
      <View
        style={{
          marginRight: 6, justifyContent: "flex-start", alignItems: "center",
          flexDirection: "row", minWidth: 45
        }}
      >
        {/* <Image
          style={{ height: 16, width: 20, marginRight: 4, tintColor: "#889" }}
          source={require("../../images/icons/People.png")}
        />
        <Text style={{ fontSize: 10, color: "#889", fontWeight: "500" }}>{x.nOfJoiners || 0}</Text> */}
      </View>

      <View
        style={{
          marginRight: 6, justifyContent: "flex-start", alignItems: "center",
          flexDirection: "row", minWidth: 45
        }}
      >
        <Image
          style={{ height: 16, width: 17, marginRight: 4, tintColor: "#889" }}
          source={require("../../images/icons/Chat.png")}
        />
        <Text style={{ fontSize: 10, color: "#889", fontWeight: "500" }}>{x.nOfComments || 0}</Text>
      </View>
      <View style={{ flex: 1 }} />
      <View
        style={{
          marginRight: 6, justifyContent: "flex-start", alignItems: "center",
          flexDirection: "row", minWidth: 45
        }}
      >
        <Image
          style={{ height: 16, width: 17, marginRight: 4, tintColor: "#889" }}
          source={require("../../images/icons/Heart.png")}
        />
        <Text style={{ fontSize: 10, color: "#889", fontWeight: "500" }}>{x.nOfTotalLikes || 0}</Text>
      </View>
      <View style={{ flex: 1 }} />
      <View
        style={{
          marginRight: 6, justifyContent: "flex-start", alignItems: "center",
          flexDirection: "row"
        }}
      >
        {/* <TouchableOpacity style={global.gStyles.buttonX} onPress={() => { return; }}>
          <Text style={[global.gStyles.buttontX, { paddingHorizontal: 15 }]}>+ JOIN</Text>
        </TouchableOpacity> */}
      </View>
    </View>)
  }

  render() {
    const x = this.props.chatGroup
    const imWidth = (Dimensions.get("window").width - 40)
    const imWidthNoImg = (Dimensions.get("window").width - 40) * .4
    const imFontSize = x.groupTitle.length > 60 ? 12 : x.groupTitle.length > 40 ? 14 : x.groupTitle.length > 20 ? 13 : 16
    const nImFontSize = x.groupTitle.length > 30 ? 13 : 16
    const mainHeight = imWidth / 8 * 4
    // alert(this.props.isActive)
    /* return (<View style={{ maxWidth: 800, paddingHorizontal: 10, paddingTop: 8, minHeight: 120, borderBottomColor: "#889", backgroundColor: Platform.OS == "ios" ? '#171717' : '#1a1a1a', marginBottom: 10, borderRadius: 7 }}>
      <View style={{ height: 5 }} />
      {this.renderTop(x)}
      <View style={{ height: 5 }} />
      {x.aType == "poll" ? this.renderMainAsPoll(x) : this.renderMain(x)} 
    </View>) */
    if (x.aType == "poll") {
      return (
        <View style={{ maxWidth: 800, paddingHorizontal: 10, paddingTop: 8, paddingBottom: 10, borderBottomColor: "#889", backgroundColor: this.props.isActive ? '#131313' : '#383838', marginBottom: 10, borderRadius: 7 }}>
          {this.renderTop(x)}
          { this.renderMainAsPoll(x)}
        </View>
      )
    }
    return (
      <View style={{ maxWidth: 800, paddingHorizontal: 10, paddingTop: 8, paddingBottom: 10, borderBottomColor: "#889", backgroundColor: this.props.isActive ? '#131313' : '#383838', marginBottom: 10, borderRadius: 7 }}>
        {this.renderTop(x)}
        {!x.groupPictureURL ? <View style={{}}>
          <Text numberOfLines={3}
            onLayout={(e) => { this.setState({ titleHeight: 10 }) }}
            style={{ fontWeight: "bold", fontSize: 12, color: "#ccd" }}>{x.groupTitle}</Text>
          <Text numberOfLines={3}
            style={{ fontSize: 12, color: "#ccd" }}>{x.groupDetails}</Text>
        </View> :
          <View style={{ flex: 1, }}>
            <CachedImage
              style={{ width: '100%', height: 140, marginRight: 10, backgroundColor: "#224", borderRadius: 7 }}
              source={{ uri: x.groupPictureURL }}
              key={x.groupPictureURL}
            />
            <View style={{ width: '100%', paddingBottom: 10, paddingTop: 10 }}>
              <Text numberOfLines={3}
                onLayout={(e) => { this.setState({ titleHeight: 10 }) }}
                style={{ fontWeight: "bold", fontSize: 13, color: "#ccd", marginTop: 5 }}>{x.groupTitle}</Text>
              <Text numberOfLines={3}
                style={{ fontSize: 12, color: "#ccd", paddingTop: 3 }}>{x.groupDetails}</Text>
            </View>
          </View>}
      </View>
    )
  }
}


import React, { Component } from 'react';
import {
    Text,
    View,
    Button,
    Image,
    TextInput,
    TouchableWithoutFeedback,
    SafeAreaView,
    TouchableOpacity,
    Keyboard,
    Modal,
    SectionList,
    Linking,
    ActivityIndicator
} from "react-native";

import MeCommunitiesScreen from '../Me/Communities/MeCommunitiesScreen'
import Header from './Header'

export default class WebMainScreen extends Component {
    static navigationOptions = {
        headerShown: false,
    };
    state = {
        viewSelection: "Feed"
    }

    render() {
        return (
            <View  >
                {/* <Header
                    navigation={this.props.navigation}
                    view={this.state.viewSelection}
                    onPress={viewSelection => {
                        this.setState({ viewSelection })
                    }}
                /> */}
                <MeCommunitiesScreen
                    navigation={this.props.navigation}
                    setSelection={(selection) => { this.setState({ viewSelection: selection }) }}
                    viewSelection={this.state.viewSelection}
                />

            </View>
        )
    }

}
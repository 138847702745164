
import React, { Component, PureComponent } from "react";
import {
  Text,
  TextInput,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Keyboard,
  Platform,
  Linking,
  Dimensions
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { NavigationEvents } from "react-navigation";
import CachedImage from "../../../shared/CachedImage"

import ChatGroup from "../../../Messages/Groups/ChatGroupDetailScreen";
//import shareicon from "./Assets/share-icon.png";
const { width } = Dimensions.get("window");

export default class HomeInnovationKitchenScreen extends Component {
  /* state = {
    userstatus = "ADMIN"
  }; */

  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Innovation Kitchen",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45"
    }
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => {
      return;
    };
  }

  state = {
    amJoiner: true,
    activityIndicatorAnimating: false,
    amAdmin: false,
    chatGroup: null,
    sessions: null,
    discusions: null,
    currentSpeech: [],
    currentSpeechID: null, // determines whether to reload speeches
    speeches: []
  };


  getData = async () => {
    try {
      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Apps")
        .doc("innovationKitchen")
        .onSnapshot(this.getSpeeches);
    } catch (err) {
      console.log(" err is ", err)
    }
  }

  getSpeeches = async (data) => {

    try {
      const newData = data.data()
      if (newData.currentSpeechID == this.state.currentSpeechID) return;

      const r = await fetch(global.cloudFunctionURL + "ikGeneral", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "getSpeeches",
          myUID: global.myUID,
          idToken: global.idToken,
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        //console.log(" success! r2 speeches are: ", r2.speeches)

        if (this.state.speeches !== r2.speeches && this.state.speeches !== []) {
          this.setState({ speeches: r2.speeches })
        }

        let currentSpeech = []
        await this.state.speeches.forEach((speech) => {

          if (speech.id == newData["currentSpeechID"]) {
            currentSpeech.push(speech)
            this.setState({ currentSpeech, currentSpeechID: newData["currentSpeechID"] })
            console.log(" current speech is now ", newData["currentSpeechID"])
          }

        })
      } else {

        alert("Something went wrong fetching speeches. Try again later!")
      }

    } catch (err) {
      console.log(" err is ", err)
    }

  }




  joinApp = async () => {
    this.setState({ activityIndicatorAnimating: true });
    try {
      const r = await fetch(global.cloudFunctionURL + "ikGeneral", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "joinApp",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });

      const r2 = await r.json();

      if (r2.msg == "SUCCESS") {
        console.log(" success! ");
        this.setState({ activityIndicatorAnimating: false, amJoiner: true });
      } else {
        this.setState({ activityIndicatorAnimating: false });
        alert("Something went wrong. Try again later!");
      }
    } catch (err) {
      this.setState({ activityIndicatorAnimating: false });
      await global.timeout(50);
      console.log("err is ", err);
    }
  };

  async componentDidMount() {
    this.getData()
    try {
      if (!global.myUID || !global.myProfileID)
        await new Promise(resolve => {
          setTimeout(resolve, 200);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise(resolve => {
          setTimeout(resolve, 400);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise(resolve => {
          setTimeout(resolve, 600);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise(resolve => {
          setTimeout(resolve, 1000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise(resolve => {
          setTimeout(resolve, 2000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise(resolve => {
          setTimeout(resolve, 4000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise(resolve => {
          setTimeout(resolve, 5000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise(resolve => {
          setTimeout(resolve, 8000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise(resolve => {
          setTimeout(resolve, 10000);
        });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID || !global.myProfileID)
        await new Promise(resolve => {
          setTimeout(resolve, 10000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise(resolve => {
          setTimeout(resolve, 100000);
        });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID) return;
      global.fetchIdToken()
      await this.loadGroups(4);
      const r = await fetch(global.cloudFunctionURL + "ikGeneral", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "checkJoinerStatus",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });

      const r2 = await r.json();

      if (r2.msg == "SUCCESS") {
        console.log(" success!  amJoiner r2 is  ", r2);
        this.setState({ amJoiner: r2.amJoiner, amAdmin: r2.amAdmin });
      } else {
        alert("Something went wrong. Try again later!");
      }
    } catch (err) {
      if (global.reportError) {
        global.reportError(err, "catches", "homeInnovationKitchen");
      }
    }
  }

  componentWillUnmount() {
    console.log("Component homeInnovationKitchen will unmount");
    this.unsubscribeFirestore();
  }


  loadGroups = async limit => {
    // this.setState({ activityIndicatorAnimating: true });
    try {
      const r = await fetch(global.cloudFunctionURL + "findGroups", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          limit,
          type: "groupIDs",
          groupIDs: [
            "In45VOmKmFb23miG65GA",
            "UJcndGGQbpj6qMGC3nuX",
            "hDnIoiiWEVnMYVzxJGi3"
          ],
          // coords: global.location.coords,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();

      if (r2.msg === "SUCCESS") {
        //filter
        this.setState({
          chatGroup: r2.groups.filter(
            group => group.xID == "In45VOmKmFb23miG65GA"
          )[0],
          sessions: r2.groups.filter(
            group => group.xID == "UJcndGGQbpj6qMGC3nuX"
          )[0],
          discusions: r2.groups.filter(
            group => group.xID == "hDnIoiiWEVnMYVzxJGi3"
          )[0]
        });
      } else alert("Error loading data.");
    } catch (err) {
      if (global.reportError) {
        global.reportError(err, "catches", "homeInnovationKitchen");
      }
    }
  };




  renderLoadingGroup = () => {
    return (
      <View style={{ height: 40, width: "100%", alignItems: "center", justifyContent: "center" }}>
        <Text style={{ color: "#ccc", fontSize: 12 }}>LOADING</Text>
      </View>
    )
  }

  render() {
    const resizeMode = "contain";

    if (!this.state.amJoiner) {
      return (
        <View
          style={{
            flex: 1,
            backgroundColor: "#131313",
            borderBottomColor: "#555",
            justifyContent: "flex-start",
            alignItems: "center"
          }}
        >
          <CachedImage
            key={"ik"}
            style={{ width: width, height: 300, resizeMode }}
            source={{
              uri:
                "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/Events%2Fdownload%402x.png?alt=media&token=b5195eaf-4a52-4f70-ae94-4d2df55dffb4"
            }}
          />
          <View style={{ marginVertical: 20 }}>
            <Text style={{ color: "white" }}>
              Are you attending this Conference ?
            </Text>
          </View>

          <TouchableOpacity
            style={{
              backgroundColor: "red",
              height: 50,
              width: "90%",
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 20,
              marginTop: 10,
              paddingHorizontal: 20
            }}
            onPress={() => {
              this.joinApp();
            }}
          >
            {!!this.state.activityIndicatorAnimating ? (
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 15,
                  flex: 1
                }}
              >
                <ActivityIndicator
                  size="large"
                  color="#fff"
                  style={{
                    opacity: 1
                  }}
                  animating={true}
                />
              </View>
            ) : (
                <Text style={{ color: "white" }}>Join app</Text>
              )}
          </TouchableOpacity>
        </View>
      );
    }
    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: global.app == "health" ? "#fff" : "#131313"
        }}
      >
        <StatusBar barStyle="light-content" />

        <View
          style={{
            backgroundColor: "#131313",
            flex: 1,
            justifyContent: "flex-start"
          }}
        >
          <View
            style={{
              backgroundColor: "#131313",
              borderBottomColor: "#555",
              borderBottomWidth: 1,
              ...Platform.select({
                ios: {
                  height: 55
                },
                android: {
                  height: 75
                }
              })
            }}
          />
          <KeyboardAwareScrollView
            enableOnAndroid={true}
            extraHeight={Platform.OS == "ios" ? 100 : 0}
            extraScrollHeight={Platform.OS !== "ios" ? 200 : 0}
            //resetScrollToCoords={{ x: 0, y: 0 }}
            showsVerticalScrollIndicator={false}
          >
            {!!this.state.amAdmin && (
              <TouchableOpacity
                onPress={() => {
                  this.props.navigation.navigate("innovationKitchenAdmin", {});
                }}
                style={{
                  borderColor: "#a33",
                  borderWidth: 1,
                  height: 60,
                  width: "100%",
                  alignSelf: "center",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ color: "#a33", fontWeight: "bold" }}>
                  Edit Event
                </Text>
              </TouchableOpacity>
            )}
            <View id="header" style={{ justifyContent: "center" }}>
              <Image
                style={{ width: width, height: 200, resizeMode }}
                source={{
                  uri:
                    "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/Events%2Fdownload%402x.png?alt=media&token=b5195eaf-4a52-4f70-ae94-4d2df55dffb4"
                }}
              />
              <TouchableOpacity
                id="Conference_Program_Button"
                onPress={() => {
                  this.props.navigation.navigate("innovationKitchenAgenda", {});
                }}
                style={{
                  backgroundColor: "red",
                  height: 60,
                  width: "90%",
                  alignSelf: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 20,
                  marginTop: -10,
                  marginBottom: 10
                }}
              >
                <Text style={{ color: "white", fontWeight: "bold" }}>
                  AGENDA / Workshop Signup
                </Text>
              </TouchableOpacity>
            </View>

            <View
              id="announcements"
              style={{ justifyContent: "center", padding: 20 }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                <Text
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    marginBottom: 10,
                    fontSize: 14
                  }}
                >
                  Announcements
                </Text>
                {this.state.chatGroup && (
                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.navigate("homeGroupDetail", {
                        group: this.state.chatGroup,
                        title: " "
                      });
                    }}
                  >
                    <Text
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        marginBottom: 10,
                        fontSize: 14
                      }}
                    >
                      See All
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
              <View
                style={{
                  backgroundColor: "#272727",
                  borderRadius: 20,
                  padding: 15
                }}
              >
                {/* <Image
                  style={{
                    minHeight: 150,
                    resizeMode: "cover",
                    marginBottom: 20,
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20
                  }}
                  source={{
                    uri:
                      "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/Events%2Fdownload%402x.png?alt=media&token=b5195eaf-4a52-4f70-ae94-4d2df55dffb4"
                  }}
                ></Image> */}
                {this.state.chatGroup ? (
                  <ChatGroup
                    backgroundColor="#272727"
                    navigation={this.props.navigation}
                    group={this.state.chatGroup}
                  />
                ) : this.renderLoadingGroup()}
                <View
                  style={{
                    /* flexDirection: "row",
                    flex: 1,
                    border: "1px solid grey",
                    justifyContent: "flex-end",
                    width: "100%",
                    alignItems: "center" */
                    //borderWidth: 1,
                    borderBottomColor: "red"
                  }}
                >
                  {/* <Text
                    style={{
                      color: "#acacac",
                      fontSize: 12,
                      fontWeight: "bold",
                      marginRight: 5
                    }}
                  >
                    Share
                  </Text>
                  <Image
                    style={{ width: 16, resizeMode: "contain" }}
                    source={shareicon}
                  /> */}
                </View>
              </View>
            </View>

            <View
              id="current_session"
              style={{ justifyContent: "center", padding: 20 }}
            >
              <Text
                style={{
                  color: "red",
                  fontWeight: "bold",
                  marginBottom: 10,
                  fontSize: 14
                }}
              >
                Current Session
              </Text>
              <View style={{ backgroundColor: "#272727", borderRadius: 15 }}>
                {this.state.currentSpeech.length > 0 && <View
                  id="speaker_profile"
                  style={{
                    flexDirection: "row",
                    borderBottomWidth: 1,
                    borderColor: "#3C3C3C",
                    width: "100%",
                    padding: 15
                  }}
                >
                  <Image
                    style={{
                      width: 70,
                      height: 70,
                      resizeMode: "cover",
                      borderRadius: 20
                    }}
                    source={{
                      uri: this.state.currentSpeech[0].image || "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/Events%2Favatar.jpg?alt=media&token=babac7eb-0973-4c9a-a0e6-6efff5ecc477"
                    }}
                  ></Image>

                  <View style={{ padding: 10, flexShrink: 1 }}>

                    <Text
                      lineBreakMode={"tail"}
                      numberOfLines={2}
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: 12, flex: 1, flexWrap: 'wrap'
                      }}
                    >
                      {this.state.currentSpeech[0].title || ""}
                    </Text>
                    <Text
                      style={{
                        color: "#ddd",
                        fontWeight: "bold",
                        fontSize: 11
                      }}
                    >
                      {this.state.currentSpeech[0].name || ""}
                    </Text>
                    <Text style={{ color: "white", fontSize: 11 }}>
                      {this.state.currentSpeech[0].designation || ""}
                    </Text>
                  </View>

                </View>}

                <View id="audience_questions">
                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.navigate("homeGroupDetail", {
                        group: this.state.sessions,
                        title: " "
                      });
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingVertical: 10,
                        paddingRight: 20,
                        paddingLeft: 20
                      }}
                    >
                      <Text style={{ color: "grey", fontSize: 12 }}>
                        Audience Questions/Discusions
                    </Text>
                      <Text
                        style={{
                          color: "#dcdcdc",
                          fontWeight: "bold",
                          fontSize: 12
                        }}
                      >
                        See all
                      </Text>
                    </View>
                  </TouchableOpacity>
                  <View
                    style={{ backgroundColor: "#272727", borderRadius: 15, padding: 10, paddingRight: 15 }}
                  >
                    {this.state.sessions ? (
                      <ChatGroup
                        navigation={this.props.navigation}
                        group={this.state.sessions}
                      />
                    ) : this.renderLoadingGroup()}
                  </View>
                </View>
              </View>
            </View>

            <View
              id="current_session"
              style={{ justifyContent: "center", padding: 20 }}
            >
              <Text
                style={{
                  color: "red",
                  fontWeight: "bold",
                  marginBottom: 10,
                  fontSize: 14
                }}
              >
                Innovation Discussion Forum
              </Text>
              <View style={{ backgroundColor: "#272727", borderRadius: 15 }}>
                <View id="discussion_forum">
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingRight: 20,
                      paddingLeft: 20,
                      paddingVertical: 10
                    }}
                  >
                    <Text style={{ color: "grey", fontSize: 12 }}>
                      {" "}
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        this.props.navigation.navigate("homeGroupDetail", {
                          group: this.state.discusions,
                          title: " "
                        });
                      }}
                    >
                      <Text
                        style={{
                          color: "#dcdcdc",
                          fontWeight: "bold",
                          fontSize: 12
                        }}
                      >
                        See all
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <View
                    style={{ backgroundColor: "#272727", borderRadius: 15, padding: 10, paddingRight: 15 }}
                  >
                    {this.state.discusions ? (
                      <ChatGroup
                        navigation={this.props.navigation}
                        group={this.state.discusions}
                      />
                    ) : this.renderLoadingGroup()}
                  </View>
                </View>
              </View>
            </View>
            <View
              id="footer"
              style={{
                marginTop: 110,
                padding: 30,
                backgroundColor: "red",
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20,
                height: 150
              }}
            >
              <Text
                style={{ color: "white", fontWeight: "bold", fontSize: 12 }}
              >
                Enquiries
              </Text>
              <TouchableOpacity onPress={() => {
                Linking.openURL("tel:+2347037325246");
              }}>
                <Text style={{ color: "white", fontSize: 11 }}>
                  +234 703 7325 246
              </Text>
              </TouchableOpacity>
              <Text style={{ color: "white", fontSize: 11 }}>
                info@summitexpo.ng
              </Text>
              <TouchableOpacity onPress={() => {
                Linking.openURL("http://www.summitexpo.com.ng/e-commerce");
              }}>
                <Text
                  style={{ color: "white", fontWeight: "bold", fontSize: 11 }}
                >
                  www.summitexpo.com.ng/e-commerce
              </Text>
              </TouchableOpacity>
            </View>
          </KeyboardAwareScrollView>
          <NavigationEvents
          // onWillFocus={async payload => {
          //   global.tabBarIconColor = "white"
          // }}
          // onWillBlur={async payload => {
          //   global.tabBarIconColor = global.tabBarIconDefaultColor
          // }}
          />
        </View>
      </SafeAreaView>
    );
  }
}

import React, { Component } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  StyleSheet,
  ScrollView,
  Platform,
  Dimensions,
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';

const SCREEN_WIDTH = Dimensions.get("window").width;

// const SLIDE_DATA = [
//   { text: "Welcome >>>" },
//   { text: "This is Wakanda Market" },
//   { text: "Wakanda Market is great!" }
// ];

class Slider extends Component {
  renderSlides() {
    return this.props.data.map(slide => {
      return (
        <View key={slide.text} style={styles.container}>
          <Text style={styles.text}>{slide.text}</Text>
          <Text> </Text>
        </View>
      );
    });
  }
  render() {
    return (
      <View>
        <ScrollView horizontal pagingEnabled style={{ flex: 1 }}>
          {this.renderSlides()}
        </ScrollView>
      </View>
    );
  }
}

export default class WelcomeScreen extends Component {
  static navigationOptions = {
    //tabBarVisible: false,
  };

  componentDidMount() {
    global.reportOnboarding = async (step) => {
      const stepString = step.toString()
      try {
        // if (global.devMode) return
        let onboardingID = await AsyncStorage.getItem("onboardingID");
        const onboardingStep = await AsyncStorage.getItem("onboardingStep");
        console.log({ onboardingID, onboardingStep })
        if (Number(onboardingStep) >= Number(step)) return
        if (!onboardingID) {
          const makeID = (length) => {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
              result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
          }
          onboardingID = makeID(25)
          await AsyncStorage.setItem("onboardingID", onboardingID);
        }
        const r = await fetch(global.cloudFunctionURL + "reportStats", {
          method: "POST",
          headers: { "Content-Type": "application/json", },
          body: JSON.stringify({
            isWeb: true,
            isWeb: true,
            action: "reportOnboarding",
            onboardingID,
            step: stepString,
            os: Platform.OS || "-",
          }),
        });
        const r2 = await r.json();
        if (r2.msg == "SUCCESS")
          await AsyncStorage.setItem("onboardingStep", stepString);
      } catch (e) { console.log(e) }
    }

    global.reportOnboarding(10)
  }

  render() {

    return (
      <View
        style={{
          flex: 1.2,
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor: "#000"
        }}
      >
        <View>
          <View
            style={{
              borderWidth: 0,
              ...Platform.select({
                ios: {
                  height: 0
                },
                android: {
                  height: 100
                }
              })
            }}
          />
          <View
            style={{
              flex: 3,
              alignItems: "center",
              justifyContent: "flex-end"
            }}
          >
            {/* <Text
              style={{
                color: "#ccd",
                fontSize: 24,
                margin: 15
              }}
            >
              Welcome!
            </Text> */}
            <Text style={{ color: "#ccd", fontSize: 16, fontStyle: "italic", margin: 16 }}>
              Shop anything ✨ local ✨ instant ✨ free
            </Text>
            <Text style={{ color: "#ccd", fontSize: 16, fontStyle: "italic", margin: 16 }}>
              Chat ✨ Discuss ✨ Share
            </Text>
            <Text style={{ color: "#ccd", fontSize: 16, fontStyle: "italic", margin: 16, marginBottom: 26 }}>
              Find Jobs ✨ Consult a Doctor ✨ ...more
            </Text>

            <Image
              style={{ width: 200, height: 204, marginTop: 30 }}
              source={require("./images/logos/WM.png")}
            />
          </View>
          <View style={{ flex: 1, alignItems: "center" }}>
            <Text
              style={{ color: "#ccd", fontSize: 14, fontStyle: "italic" }}
            >
              One App. One Africa.
            </Text>
            <Text
              style={{ color: "#ccd", fontSize: 20, marginTop: 8, fontStyle: "italic" }}
            >
              🇳🇬🇬🇭
            </Text>
            <Text style={{ color: "#ccd", fontSize: 14, margin: 5 }} />
            {/* <Text style={{ color: "#ccd", fontSize: 14, margin: 5 }} >PLACE SLIDER HERE!</Text> */}
          </View>
          <View style={{ flex: 1, alignItems: "center" }}>
            <TouchableOpacity style={{ paddingHorizontal: 30, paddingVertical: 6, borderRadius: 25, borderWidth: 0.8, borderColor: "#A8CF45" }}
              onPress={() => this.props.navigation.navigate("signin")}
            >
              <Text style={[this.props.style, { color: "#fff", alignSelf: "center", fontWeight: "bold", fontSize: 20, margin: 5 }]}>
                Enter...
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
    // return (  //old slider
    //   <View style={styles.container}>
    //     <View style={styles.container1}>
    //       <Slider data={SLIDE_DATA} />
    //     </View>
    //     <View style={styles.container}>
    //       <Button
    //         title="Continue..."
    //         onPress={() => this.props.navigation.navigate("signin")}
    //       />
    //     </View>
    //   </View>
    // );
  }
}

const styles = {
  container1: {
    flex: 4,
    alignItems: "center",
    justifyContent: "center",
    width: SCREEN_WIDTH,
    backgroundColor: "#000"
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: SCREEN_WIDTH,
    backgroundColor: "#000"
  },
  text: {
    fontSize: 30
  }
};
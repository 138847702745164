import firebase from "firebase";
import "firebase/firestore";

var config = {
  apiKey: "AIzaSyCoiapgS7lDAzSIskAzN_oL_unr3LCag9s",
  authDomain: "afbase-7ee60.firebaseapp.com",
  databaseURL: "https://afbase-7ee60.firebaseio.com",
  projectId: "afbase-7ee60",
  storageBucket: "afbase-7ee60.appspot.com",
  messagingSenderId: "198655812344"
};

export default firebase.initializeApp(config);

// import firebase from 'firebase';

// var config = {
//     apiKey: "AIzaSyBjQGiX5IGZqF_oCERTBXm6QKCRuCymly4",
//     authDomain: "afsync-c34a3.firebaseapp.com",
//     databaseURL: "https://afsync-c34a3.firebaseio.com",
//     projectId: "afsync-c34a3",
//     storageBucket: "afsync-c34a3.appspot.com",
//     messagingSenderId: "115931017626"
//   };

// export default firebase.initializeApp(config);

//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
// everything is an EXACT copy from Wakanda Market and "synced"
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

import React, { Component } from "react";
import {
  Text,
  View,
  TextInput,
  Image,
  ImageBackground,
  TouchableOpacity,
  ActivityIndicator,
  Keyboard,
  Platform,
  Linking,
  Modal,
  Dimensions,
  SectionList,
  TouchableWithoutFeedback,
} from "react-native";
import Constants from 'expo-constants';
import NetInfo from '@react-native-community/netinfo';

import styles from "./styles";

class Selector extends Component {
  render() {
    let height = 20
    let circle = filled => {
      if (filled) {
        return (
          <View
            style={{ width: 15, height: 15, borderColor: "#9999a4", backgroundColor: "#9999a4", borderWidth: 1, borderRadius: 10 }}
          />
        );
      } else {
        return (
          <View
            style={{ width: 15, height: 15, borderColor: "#556", borderWidth: 1, borderRadius: 10 }}
          />
        );
      }
    };

    return (
      <View
        style={{ height: height, flexDirection: "row", alignItems: "center" }}
      >
        {circle(this.props.selected)}
        <Text style={{ fontSize: 11, marginLeft: 8, color: "#999" }}>{this.props.text}</Text>
      </View>
    );
  }
}

let COMMUNITY = ''
export default class SigninScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false
  };

  state = {
    phoneNumber: "",
    countryCode: "+234",
    mode: "whatsapp",
    activityIndicatorAnimating: false,
    modalVisible: false,
    keyboardSpacerHeight: 0,
    communityID: ''
  };

  parseURLandReturnValues() {

    let fullURL = window.location.href.split('#')[0]
    fullURL = window.location.href;
    const fullURLsubStrings = fullURL.split("?")[1];
    if (!fullURLsubStrings) return;
    //OPTIONS = fullURLsubStrings.split("&&");  
    COMMUNITY = fullURLsubStrings.split("community=")[1];
    global.initialCommunityID = COMMUNITY
    // this.setState({ communityID: COMMUNITY })
    // alert(`You're joining ${global.initialCommunityID} community`);

  };

  async componentDidMount() {
    //alert('Hiiii')
    this.parseURLandReturnValues()
    console.log("ExpoID", Constants.installationId)
    this.keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      this._keyboardDidShow
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      this._keyboardDidHide
    );

    global.termsConditionsLink = "https://www.african-founders.com/terms-and-conditions"
    if (global.app == "education") global.termsConditionsLink = "https://www.african-founders.com/wakanda-education-t-and-c"

    global.privacyPolicyLink = "https://www.african-founders.com/terms-and-conditions"
    if (global.app == "education") global.privacyPolicyLink = "https://www.african-founders.com/wakanda-education-privacy-policy"

    this.setState({ activityIndicatorAnimating: false });
  }

  componentWillUnmount() {
    console.log("Component signin will unmount");

    if (this.keyboardDidShowListener) this.keyboardDidShowListener.remove();
    if (this.keyboardDidHideListener) this.keyboardDidHideListener.remove();
  }

  _keyboardDidShow = async () => {
    console.log("Keyboard Shown");
    if (Platform.OS === "ios") {
      this.setState({ keyboardSpacerHeight: 100 });
    }
  };

  _keyboardDidHide = () => {
    console.log("Keyboard Hidden");
    this.setState({ keyboardSpacerHeight: 0 });
  };

  callSignin = async () => {
    if (this.state.activityIndicatorAnimating) return;
    let enteredNumber = this.state.phoneNumber.replace(/[^\d]/g, "");

    if (
      enteredNumber !== "0000000009" &&
      enteredNumber !== "0000000008" &&
      enteredNumber !== "0000000007" &&
      enteredNumber !== "0000000006" &&
      enteredNumber !== "1234567890"
    ) {
      if (enteredNumber.startsWith("00")) {
        alert("Invalid phone number");
        return;
      }
      if (enteredNumber.startsWith("0")) {
        enteredNumber = enteredNumber.slice(1);
      }
    }

    const phoneNumber = this.state.countryCode + enteredNumber;
    if (!phoneNumber.startsWith("+")) {
      alert("Error");
      return;
    }
    if (phoneNumber.startsWith("+234") && phoneNumber.length > 14) {
      alert("Your phone number is too long");
      return;
    }
    if (phoneNumber.startsWith("+234") && phoneNumber.length < 14) {
      alert("Your phone number is too short");
      return;
    }
    if (phoneNumber.startsWith("+233") && phoneNumber.length > 13) {
      alert("Your phone number is too long");
      return;
    }
    if (phoneNumber.startsWith("+233") && phoneNumber.length < 13) {
      alert("Your phone number is too short");
      return;
    }
    if (phoneNumber.length < 10) {
      alert("Phone number too short");
      return;
    }
    if (!global.isConnected) {
      alert("You are not connected to the internet");
      return;
    }

    try {
      this.setState({ activityIndicatorAnimating: true });

      let appVersion = "";
      if (Constants.manifest && Constants.manifest.version) {
        appVersion = Constants.manifest.version;
      }

      const r = await fetch(global.cloudFunctionURL + "signin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          isWeb: true,
          isWeb: true,
          phoneNumber,
          mode: this.state.mode,
          expoID: Constants.installationId,
          deviceInfo: {
            deviceName: Constants.deviceName || "-",
            expoVersion: Constants.expoVersion || "-",
            deviceYearClass: Constants.deviceYearClass || "-",
            platform: Constants.platform || "-",
            appVersion,
            devMode: global.devMode //can be removed with Expo SDK 37
          },
          app: global.app || "unknown",
          // communityID: this.state.communityID || null
        })
      });

      global.reportOnboarding(20)

      console.log(r);
      const r2 = await r.json();
      console.log(r2.msg);
      this.setState({ activityIndicatorAnimating: false });
      if (r2.msg === "OTP_SENT_NEW_USER_CREATED") {
        //alert ("Welcome")
        this.props.navigation.navigate("verifyOTP", {
          phoneNumber
        });
        return;
      }
      if (r2.msg === "OTP_SENT_EXISTING_USER") {
        //alert ("Welcome back!")
        this.props.navigation.navigate("verifyOTP", {
          phoneNumber
        });
        return;
      }
      alert("Error: " + r2.msg);
    } catch (e2) {
      console.log("HelloError");
      console.error(e2);
      alert("An error occurred.");
      if (global.reportError) global.reportError(e2, "catches", "signin");
      this.setState({ activityIndicatorAnimating: false });
    }
  };

  render() {
    // below cannot use global at this point yet
    const countryCodes = [
      "Nigeria (+234)",
      "Ghana (+233)",
      "Afghanistan (+93)",
      "Albania (+355)",
      "Algeria (+213)",
      "American Samoa (+1-684)",
      "Andorra (+376)",
      "Angola (+244)",
      "Anguilla (+1-264)",
      "Antarctica (+672)",
      "Antigua and Barbuda (+1-268)",
      "Argentina (+54)",
      "Armenia (+374)",
      "Aruba (+297)",
      "Australia (+61)",
      "Austria (+43)",
      "Azerbaijan (+994)",
      "Bahamas (+1-242)",
      "Bahrain (+973)",
      "Bangladesh (+880)",
      "Barbados (+1-246)",
      "Belarus (+375)",
      "Belgium (+32)",
      "Belize (+501)",
      "Benin (+229)",
      "Bermuda (+1-441)",
      "Bhutan (+975)",
      "Bolivia (+591)",
      "Bosnia and Herzegovina (+387)",
      "Botswana (+267)",
      "Brazil (+55)",
      "British Indian Ocean Territory (+246)",
      "British Virgin Islands (+1-284)",
      "Brunei (+673)",
      "Bulgaria (+359)",
      "Burkina Faso (+226)",
      "Burundi (+257)",
      "Cambodia (+855)",
      "Cameroon (+237)",
      "Canada (+1)",
      "Cape Verde (+238)",
      "Cayman Islands (+1-345)",
      "Central African Republic (+236)",
      "Chad (+235)",
      "Chile (+56)",
      "China (+86)",
      "Christmas Island (+61)",
      "Cocos Islands (+61)",
      "Colombia (+57)",
      "Comoros (+269)",
      "Cook Islands (+682)",
      "Costa Rica (+506)",
      "Croatia (+385)",
      "Cuba (+53)",
      "Curacao (+599)",
      "Cyprus (+357)",
      "Czech Republic (+420)",
      "Democratic Republic of the Congo (+243)",
      "Denmark (+45)",
      "Djibouti (+253)",
      "Dominica (+1-767)",
      "Dominican Republic (+1-809)",
      "Dominican Republic (+1-829)",
      "Dominican Republic (+1-849)",
      "East Timor (+670)",
      "Ecuador (+593)",
      "Egypt (+20)",
      "El Salvador (+503)",
      "Equatorial Guinea (+240)",
      "Eritrea (+291)",
      "Estonia (+372)",
      "Ethiopia (+251)",
      "Falkland Islands (+500)",
      "Faroe Islands (+298)",
      "Fiji (+679)",
      "Finland (+358)",
      "France (+33)",
      "French Polynesia (+689)",
      "Gabon (+241)",
      "Gambia (+220)",
      "Georgia (+995)",
      "Germany (+49)",
      "Ghana (+233)",
      "Gibraltar (+350)",
      "Greece (+30)",
      "Greenland (+299)",
      "Grenada (+1-473)",
      "Guam (+1-671)",
      "Guatemala (+502)",
      "Guernsey (+44-1481)",
      "Guinea (+224)",
      "Guinea-Bissau (+245)",
      "Guyana (+592)",
      "Haiti (+509)",
      "Honduras (+504)",
      "Hong Kong (+852)",
      "Hungary (+36)",
      "Iceland (+354)",
      "India (+91)",
      "Indonesia (+62)",
      "Iran (+98)",
      "Iraq (+964)",
      "Ireland (+353)",
      "Isle of Man (+44-1624)",
      "Israel (+972)",
      "Italy (+39)",
      "Ivory Coast (+225)",
      "Jamaica (+1-876)",
      "Japan (+81)",
      "Jersey (+44-1534)",
      "Jordan (+962)",
      "Kazakhstan (+7)",
      "Kenya (+254)",
      "Kiribati (+686)",
      "Kosovo (+383)",
      "Kuwait (+965)",
      "Kyrgyzstan (+996)",
      "Laos (+856)",
      "Latvia (+371)",
      "Lebanon (+961)",
      "Lesotho (+266)",
      "Liberia (+231)",
      "Libya (+218)",
      "Liechtenstein (+423)",
      "Lithuania (+370)",
      "Luxembourg (+352)",
      "Macau (+853)",
      "Macedonia (+389)",
      "Madagascar (+261)",
      "Malawi (+265)",
      "Malaysia (+60)",
      "Maldives (+960)",
      "Mali (+223)",
      "Malta (+356)",
      "Marshall Islands (+692)",
      "Mauritania (+222)",
      "Mauritius (+230)",
      "Mayotte (+262)",
      "Mexico (+52)",
      "Micronesia (+691)",
      "Moldova (+373)",
      "Monaco (+377)",
      "Mongolia (+976)",
      "Montenegro (+382)",
      "Montserrat (+1-664)",
      "Morocco (+212)",
      "Mozambique (+258)",
      "Myanmar (+95)",
      "Namibia (+264)",
      "Nauru (+674)",
      "Nepal (+977)",
      "Netherlands (+31)",
      "Netherlands Antilles (+599)",
      "New Caledonia (+687)",
      "New Zealand (+64)",
      "Nicaragua (+505)",
      "Niger (+227)",
      "Nigeria (+234)",
      "Niue (+683)",
      "North Korea (+850)",
      "Northern Mariana Islands (+1-670)",
      "Norway (+47)",
      "Oman (+968)",
      "Pakistan (+92)",
      "Palau (+680)",
      "Palestine (+970)",
      "Panama (+507)",
      "Papua New Guinea (+675)",
      "Paraguay (+595)",
      "Peru (+51)",
      "Philippines (+63)",
      "Pitcairn (+64)",
      "Poland (+48)",
      "Portugal (+351)",
      "Puerto Rico (+1-787)",
      "Puerto Rico (+1-939)",
      "Qatar (+974)",
      "Republic of the Congo (+242)",
      "Reunion (+262)",
      "Romania (+40)",
      "Russia (+7)",
      "Rwanda (+250)",
      "Saint Barthelemy (+590)",
      "Saint Helena (+290)",
      "Saint Kitts and Nevis (+1-869)",
      "Saint Lucia (+1-758)",
      "Saint Martin (+590)",
      "Saint Pierre and Miquelon (+508)",
      "Saint Vincent and the Grenadines (+1-784)",
      "Samoa (+685)",
      "San Marino (+378)",
      "Sao Tome and Principe (+239)",
      "Saudi Arabia (+966)",
      "Senegal (+221)",
      "Serbia (+381)",
      "Seychelles (+248)",
      "Sierra Leone (+232)",
      "Singapore (+65)",
      "Sint Maarten (+1-721)",
      "Slovakia (+421)",
      "Slovenia (+386)",
      "Solomon Islands (+677)",
      "Somalia (+252)",
      "South Africa (+27)",
      "South Korea (+82)",
      "South Sudan (+211)",
      "Spain (+34)",
      "Sri Lanka (+94)",
      "Sudan (+249)",
      "Suriname (+597)",
      "Svalbard and Jan Mayen (+47)",
      "Swaziland (+268)",
      "Sweden (+46)",
      "Switzerland (+41)",
      "Syria (+963)",
      "Taiwan (+886)",
      "Tajikistan (+992)",
      "Tanzania (+255)",
      "Thailand (+66)",
      "Togo (+228)",
      "Tokelau (+690)",
      "Tonga (+676)",
      "Trinidad and Tobago (+1-868)",
      "Tunisia (+216)",
      "Turkey (+90)",
      "Turkmenistan (+993)",
      "Turks and Caicos Islands (+1-649)",
      "Tuvalu (+688)",
      "U.S. Virgin Islands (+1-340)",
      "Uganda (+256)",
      "Ukraine (+380)",
      "United Arab Emirates (+971)",
      "United Kingdom (+44)",
      "United States (+1)",
      "Uruguay (+598)",
      "Uzbekistan (+998)",
      "Vanuatu (+678)",
      "Vatican (+379)",
      "Venezuela (+58)",
      "Vietnam (+84)",
      "Wallis and Futuna (+681)",
      "Western Sahara (+212)",
      "Yemen (+967)",
      "Zambia (+260)",
      "Zimbabwe (+263)"
    ];
    return (
      <TouchableWithoutFeedback accessible={false}>
        <View style={[styles.container, { justifyContent: "space-around", paddingTop: 110, flexDirection: 'row' }]}>
          {global.initialCommunityID && <View style={{ width: '35%', flexDirection: 'column', marginTop: -250, alignItems: 'flex-start', justifyContent: 'space-around ' }}>
            {/*  <video width="320" height="240" controls>
              <source src="https://www.youtube.com/watch?v=DjgVwN_k_88" type="video/mp4"/>
                <source src="movie.ogg" type="video/ogg"/>
                  Your browser does not support the video tag.
               </video> */}
            <Text style={{ color: "#aaa", fontSize: 16, marginBottom: 30 }}>We're using Wakanda as a community platform to share, organise, exchange, and keep in touch!</Text>
            <iframe width="300" height="215" allow="fullscreen;" style={{ borderRadius: 4, borderWidth: 0, backgroundColor: 'black' }}
              src="https://www.youtube.com/embed/w7mf3ZNWjBI?autoplay=1&mute=1">
            </iframe>
            <Text style={{ color: '#aaa', fontSize: 12, fontWeight: 'bold', marginTop: 10 }}><span style={{}}>Click</span>
              <a href="https://wakanda.community" target='_blank' style={{ color: "#A8CF45", marginLeft: 5, textDecoration: 'none' }}>here</a> to find out more</Text>
          </View>}
          <View style={[styles.regform]}>
            {(global.app == "market" || global.app == "health" || global.app == "education") &&
              <Image
                style={{
                  width: (global.app == "market") ? 450 : (global.app == "education") ? 654 : 750,
                  height: global.app == "market" ? 360 : (global.app == "education") ? 460 : 400,
                  margin: 70,
                  opacity: 0.2,
                  position: "absolute",
                  bottom: 5,
                  left: 5,
                  ...(global.app == "education" && { tintColor: "#88f" })
                }}
                source={require("../images/logos/home_logo.png")}
              />}

            <View style={{}}>
              <Text style={[styles.header, { marginBottom: 0, color: "#A8CF45" }]}>Enter your phone number</Text>
            </View>
            <ActivityIndicator
              size="small"
              color="#FFFFFF"
              style={{
                opacity: this.state.activityIndicatorAnimating ? 1.0 : 0.0,
                marginBottom: 10
              }}
              animating={true}
            />
            <View
              style={{
                flexDirection: "row",
                height: 40,
                marginBottom: 12,
                backgroundColor: "#aaa",
                borderRadius: 20,
                borderBottomWidth: 0,
                width: 275
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.setState({ modalVisible: true });
                }}
                style={{
                  alignItems: "center",
                  alignSelf: "flex-start",
                  padding: 1,
                  borderRightWidth: 1,
                  borderRightColor: "gray",
                  marginRight: 4.5,
                  width: 65,
                  height: 40,
                  justifyContent: "center",
                  borderTopLeftRadius: 20,
                  borderBottomLeftRadius: 20,
                  borderColor: "gray"
                }}
              >
                <Text>{this.state.countryCode}</Text>
              </TouchableOpacity>
              <TextInput
                style={styles.textinput}
                value={this.state.phoneNumber}
                //autoFocus={true}
                placeholder="your number "
                placeholderTextColor="#000"
                underlineColor={"transparent"}
                underlineColorAndroid="transparent"
                keyboardType="numeric"
                onChangeText={phoneNumber => {
                  this.setState({ phoneNumber });
                }}
              />
              {this.state.phoneNumber.length > 0 &&
                !this.state.activityIndicatorAnimating && (
                  <TouchableOpacity
                    onPress={this.callSignin}
                    style={{
                      alignItems: "center",
                      alignSelf: "flex-start",
                      padding: 1,
                      backgroundColor: "#A8CF45",
                      marginLeft: 4.5,
                      width: 47,
                      height: 40,
                      justifyContent: "center",
                      borderTopEndRadius: 20,
                      borderBottomEndRadius: 20,
                      borderColor: "gray",
                      borderLeftWidth: 1
                    }}
                  >
                    <Image
                      style={{
                        width: 18,
                        height: 18,
                        marginRight: 1
                      }}
                      source={require("../images/icons/Send.png")}
                    />
                  </TouchableOpacity>
                )}
            </View>
            <View>
              <Text
                style={{ color: "#aaa", marginBottom: 5, marginTop: 50, textAlign: "center" }}
              >
                We will send you a signin code
            </Text>
              <TouchableOpacity activeOpacity={1} onPress={() => { this.setState({ mode: "whatsapp" }) }}>
                <Selector
                  selected={this.state.mode == "whatsapp"}
                  text={"WhatsApp (recommended)"}
                />
              </TouchableOpacity>
              <TouchableOpacity activeOpacity={1} onPress={() => { this.setState({ mode: "sms" }) }}>
                <Selector
                  selected={this.state.mode == "sms"}
                  text={"SMS"}
                />
              </TouchableOpacity>
            </View>
            <View style={{ height: 100 }} />
            <View style={{ height: this.state.keyboardSpacerHeight }} />
          </View>
          <View
            style={{
              position: "absolute",
              bottom: Platform.OS === "ios" ? 100 : 25,
              width: "100%",
              justifyContent: "center",
              alignSelf: "center"
            }}
          >

            <View
              style={{ flexDirection: "row", width: "100%", justifyContent: "center", alignSelf: "center" }}
            >
              <Text style={{ fontSize: 10, color: "#aaa" }}>
                By using this app, you agree to the{" "}
              </Text>
              <TouchableOpacity
                onPress={() =>
                  Linking.openURL(
                    global.termsConditionsLink || "https://www.african-founders.com/terms-and-conditions"
                  )
                }
              >
                <Text
                  style={{
                    fontSize: 10,
                    color: global.app == "health" ? "#955" : "#595"
                  }}
                >
                  Terms and Conditions
              </Text>
              </TouchableOpacity>
              <Text style={{ fontSize: 10, color: "#888" }}>.</Text>
            </View>
          </View>

          <ImageBackground
            style={{
              width: Dimensions.get("window").width, height: Dimensions.get("window").height,
              position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: -1, opacity: 0.15
            }}
            source={require("../custom/Speakers/images/logos/SC-Background.jpg")}
          />
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.modalVisible}
            onRequestClose={() => {
              this.setState({ modalVisible: false });
            }}
          >
            <TouchableOpacity
              onPress={() => this.setState({ modalVisible: false })}
              style={{ height: 50, width: '100%', height: global.SHEIGHT - 50 }}
            >
              <View
                style={{
                  alignItems: "center",
                  // justifyContent: "center",
                  overflowX: 'hidden', height: global.SHEIGHT, width: 500, backgroundColor: "#112",
                  overflowY: 'scroll'
                }}
              >
                <View
                  style={{
                    //  justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <View style={{ paddingTop: 20, }}
                  >
                    <Text style={{ fontSize: 30, color: "white" }}>
                      Select Country
                  </Text>
                  </View>
                  <View style={{ marginTop: 20, marginBottom: 5, width: '100%' }} />
                  {countryCodes.map((item, id) => {
                    return (
                      <TouchableOpacity
                        key={item + id}
                        onPress={() => {
                          this.setState({
                            modalVisible: false,
                            countryCode: item.split("(")[1].split(")")[0]
                          });
                        }}
                        style={{
                          flex: 1,
                          paddingTop: 10,
                          paddingBottom: 10,
                          paddingLeft: 20,
                          width: '100%',
                          backgroundColor: "#112",
                          borderWidth: 1,
                          borderColor: "#223"
                        }}
                      >
                        <Text style={{ fontSize: 16, color: "white" }} >
                          {item}
                        </Text>
                      </TouchableOpacity>
                    )
                  })}
                  {/* <SectionList
                  renderItem={({ item, index, section }) => (
                    <TouchableOpacity
                      onPress={() => {
                        this.setState({
                          modalVisible: false,
                          countryCode: item.split("(")[1].split(")")[0]
                        });
                      }}
                      style={{
                        flex: 1,
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 20,
                        width: Dimensions.get("window").width,
                        backgroundColor: "#112",
                        borderWidth: 1,
                        borderColor: "#223"
                      }}
                    >
                      <Text
                        style={{ fontSize: 16, color: "white" }}
                        key={index}
                      >
                        {item}
                      </Text>
                    </TouchableOpacity>
                  )}
                  renderSectionHeader={({ section: { title } }) => (
                    <Text style={{ fontWeight: "bold" }}>{title}</Text>
                  )}
                  sections={[
                    {
                      title: "",
                      data: countryCodes
                    }
                  ]}
                  keyExtractor={(item, index) => item + index}
                /> */}
                </View>
              </View>
              <TouchableOpacity
                style={{
                  position: "absolute",
                  borderWidth: 0,
                  backgroundColor: "#556",
                  borderColor: "rgba(0,0,0,0.2)",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 20,
                  top: 20,
                  left: 20,
                  height: 25,
                  borderRadius: 30
                }}
                onPress={() => this.setState({ modalVisible: false })}
              >
                <Image
                  style={{ width: 25, height: 25, opacity: 1 }}
                  source={require("../images/icons/CancelBlack.png")}
                />
                {/* <Text style={{ fontSize: 26, color: "#666" }}>x</Text> */}
              </TouchableOpacity>
            </TouchableOpacity>
          </Modal>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  SafeAreaView,
  Keyboard,
  Modal,
  SectionList,
  Platform,
  Dimensions,
  ActivityIndicator
} from "react-native";
import * as Permissions from 'expo-permissions';
import * as Location from 'expo-location';
import firebase from "firebase";
import "firebase/firestore";
import { NavigationEvents } from "react-navigation";
const gStyles = global.gStyles //    "../../styles/gStyles";
import Group from "./Group"

// class GroupsNearby extends Component {

//   renderMultipleContacts = () => {
//     return <View>
//       {!this.props.noContactFound &&
//         this.props.contacts && (
//           <View style={{ marginBottom: 10 }}>
//             {!!this.props.loading ? <ActivityIndicator size="small" color="#FFFFFF" style={{ margin: 15 }} /> :
//               (!!this.props.moreNearbyToLoad && <TouchableOpacity
//                 onPress={() => { this.props.seeMore() }}
//                 style={[global.gStyles.buttonX, { width: 180, alignSelf: "center", marginTop: 5 }]}
//               >
//                 <Text style={global.gStyles.buttontX}>SEE MORE</Text>
//               </TouchableOpacity>)}
//           </View>
//         )}
//     </View>
//   }

//   render() {
//     if (this.props.locationNotEnabled) return (
//       <View style={{ backgroundColor: "#000", width: "98%", borderWidth: 1, borderColor: "#444", borderRadius: 25, marginLeft: 3 }}>
//         <Text style={{ color: "#eee", marginVertical: 15, marginHorizontal: 5 }}>Loacation not enabled</Text>
//       </View>
//     )
//     return (
//       <View style={{ backgroundColor: "#000", width: "98%", borderWidth: 1, borderColor: "#444", borderRadius: 25, marginLeft: 3 }}>
//         {this.renderMultipleContacts()}
//       </View>
//     );
//   }
// }

export default class ChatGroupMainScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    return {
      tabBarVisible: false,
      headerTransparent: true,
      headerTintColor: "#A8CF45",
      title: "Public Chats",
      headerTitleStyle: {
        fontWeight: "bold",
        color: "#A8CF45",
        shadowOpacity: 1
      }
    };
  };
  state = {
    groupsNearby: [{ xxStatus: "loading" }],
    groupsContacts: [{ xxStatus: "loading" }],
    loadingLocation: true,
    loadingNearby: true,
    errorNearby: false,
    locationNotEnabled: false,
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {

    try {
      let { status } = await Permissions.askAsync(Permissions.LOCATION);
      if (status == 'granted') {
        await this.getCurrentLocation1() // current location with low accuracy/high speed
        this.setState({ loadingLocation: false })
        if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 1000); });
        if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 3000); });
        if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 5000); });
        this.loadGroupsNearby(8)
        this.getCurrentLocation2() // takes too long to wait for, so this is just to set the global variable precise
      } else this.setState({ loadingLocation: false, locationNotEnabled: true })


      // let myContainerIDs = await global.getAllContainerIDsAsync()
      // console.log("Loaded myContainerIDs from async", myContainerIDs)
      // if (myContainerIDs == -1) {
      //   myContainerIDs = [];
      //   console.warn("CONTAINERID ISSUE")
      //   //TBD GET FROM BACKEND
      //   //TBD GET FROM BACKEND
      //   //TBD GET FROM BACKEND
      // }

      // const groupsContacts = []
      // const promises = myContainerIDs.map(async cID => {
      //   try {
      //     const group = {}////TBD 
      //   if (group && group != -1) groupsContacts.push(group)
      //   } catch (er) {
      //     console.log("Error in sgm2 transaction", er);
      //     return "ERROR";
      //   }
      // });
      // await Promise.all(promises);
      // this.setState({groupsContacts})


    } catch (err) {
      console.log("Error in componentDidMount (chatGroupMain)", err);
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
    console.log("Component chatGroupMain will unmount");
  }

  getCurrentLocation1 = async () => {
    try {
      const myLocation = await Location.getCurrentPositionAsync({ accuracy: 3, maximumAge: 60000 });
      global.location = myLocation
      global.myLocation = myLocation
    } catch (err) {
      console.log("error c", err)
    }
  }
  getCurrentLocation2 = async () => {
    try {
      const myLocation = await Location.getCurrentPositionAsync({ accuracy: 5 });
      global.location = myLocation
      global.myLocation = myLocation
    } catch (err) {
      console.log("error c", err)
    }
  }

  loadGroupsNearby = async (limit) => {
    try {
      this.setState({ loadingNearby: true })
      if (!global.location) {
        alert("Location not enabled")
        return;
      }
      this.setState({ moreNearbyToLoad: false })

      const r = await fetch(
        global.cloudFunctionURL + "findGroups",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            isWeb: true,
            limit,
            type: "location",
            coords: global.location.coords,
            myUID: global.myUID,
            idToken: global.idToken
          })
        }
      );
      const r2 = await r.json();

      //console.log("Received groups. ", r2);
      if (r2.msg === "SUCCESS") {
        //filter
        this.setState({ groupsNearby: r2.groups })
      } else alert("Error loading data.")
      this.setState({ loadingNearby: false });
      // console.log("Received findGroups: ", r2.msg);
    } catch (err) {
      console.log("Error in findGroups", err);
    }
  }

  renderLoadingGroup = () => <View style={{ height: 80, justifyContent: "center", alignItems: "center", backgroundColor: "#131313" }}>
    <View style={{ justifyContent: "center", flexDirection: "row", alignItems: "center" }}>
      <ActivityIndicator size="small" color="#FFFFFF" style={{ margin: 8 }} />
      <Text style={{ color: "#555" }}>Loading Data...</Text>
    </View>
  </View>

  render() {
    //console.log('GROUP CHAT PROPS', this.props)
    if (this.state.loadingLocation) return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "black" }}>
        <View style={{ justifyContent: "center", flexDirection: "row", alignItems: "center" }}>
          <Image style={{ width: 15, height: 16.5, marginRight: 4, tintColor: "#555" }} source={require("../../images/icons/Location.png")} />
          <Text style={{ color: "#555" }}>Getting your Location...</Text>
        </View>
      </View>
    );
    const tStyle = { color: "#A8CF45", fontSize: 22, fontWeight: "800", textAlign: "left", marginTop: 0 }
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#131313" }}>
        <View style={{ backgroundColor: "#131313", flex: 1, width: "100%" }}>
          <View
            style={{
              borderBottomColor: "#555555", borderWidth: 0, ...Platform.select({ ios: { height: 55 }, android: { height: 75 } })
            }}
          />
          <SectionList
            sections={[
              { title: 'Near Me', data: this.state.groupsNearby },
              // { title: 'Groups of My Contacts', data: this.state.GroupsNearby },
            ]}
            renderSectionHeader={({ section }) => <View style={{ borderBottomColor: "#A8CF45", borderBottomWidth: 0.5, backgroundColor: "#131313", height: 35, justifyContent: "flex-end" }}><Text style={tStyle}> {section.title} </Text></View>}
            renderItem={({ item }) => {
              if (!item) return (<View />)
              else if (item.xxStatus == "loading") return this.renderLoadingGroup()
              else return (<TouchableOpacity onPress={() => { this.props.navigation.navigate("chatGroupDetail", { group: item }) }}>
                <Group chatGroup={item} view={"LIST"} amJoiner={item.xAmJoiner} join={() => { this.props.navigation.navigate("chatGroupDetail", { group: item }) }} refresh={() => { global.warn("Refresh not implemented") }} />
              </TouchableOpacity>)
            }}
            keyExtractor={(item, index) => index}
          />

          <View style={{
            position: "absolute",
            bottom: 5,
            right: 15,
            // flexDirection: "row"
          }}>
            <TouchableOpacity
              style={{
                borderWidth: 1, borderColor: "rgba(0,0,0,0.2)", alignItems: "center", justifyContent: "center", width: 70, height: 70, backgroundColor: "#A8CF45", borderRadius: 70, shadowOpacity: 1, shadowRadius: 3, elevation: 4
              }}
              onPress={() => this.props.navigation.navigate("chatGroupNew")}
            >
              <Text style={{ fontSize: 36, fontWeight: "200", marginBottom: 2 }}>
                +
            </Text>
            </TouchableOpacity>
          </View>
        </View>
        <NavigationEvents
          onWillFocus={async payload => {
            if (global.chatGroupMainHasUpdates) {
              this.setState({
                groupsNearby: [{ xxStatus: "loading" }],
                groupsContacts: [{ xxStatus: "loading" }],
                loadingLocation: true,
                loadingNearby: true,
                errorNearby: false,
                locationNotEnabled: false,
              });
              this.componentDidMount()
              global.chatGroupMainHasUpdates = false
            }
          }}
          onWillBlur={async payload => {
            //alert("Leaving?")
          }}
        />
      </SafeAreaView>
    );
  }
}

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  TouchableHighlight,
  TouchableWithoutFeedback,
  ScrollView,
  SectionList,
  SafeAreaView,
  Keyboard,
  ActivityIndicator,
  Dimensions,
  Platform,
  Image,
  Alert,
  Modal
} from "react-native";
import { WebView } from 'react-native-webview';
//import firebase from "../../../firebase/afbase-config";
import firebase from "firebase";
import "firebase/firestore";
const gStyles = global.gStyles //    "../../styles/gStyles";
import CategorySelectorMarket from "../../Home/V1CategorySelector";
import CategorySelectorArtisan from "../../Home/V1CategorySelectorArtisan";
import CategorySelectorEducation from "../../Home/V1CategorySelectorEducation";
import CurrentLocation from "../../shared/Map/currentLocationV2";
import CurrentLocationModal from "../../shared/Map/currentLocationV2Modal";
import GetPicture from "../../shared/Camera/GetPicture";
import GetVideo from "../../shared/Camera/GetVideo";

class Selector extends Component {
  render() {
    let height =
      this.props.text.length < 45
        ? 30
        : 45 + (this.props.text.length - 45) * 0.33;
    let circle = filled => {
      if (filled) {
        return (
          <View
            style={{ width: 20, height: 20, borderColor: "#9999a4", backgroundColor: "#9999a4", borderWidth: 1, borderRadius: 10 }}
          />
        );
      } else {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#556", borderWidth: 1, borderRadius: 10 }} />
        );
      }
    };

    return (
      <View style={{ height: height, flexDirection: "row", alignItems: "center" }}>
        {circle(this.props.selected)}
        <Text style={{ marginLeft: 10, color: "#999" }}>{this.props.text}</Text>
      </View>
    );
  }
}

export default class NewPostScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    return {
      title: "New Offer",
      headerTransparent: true,
      headerTintColor: "#A8CF45",
      headerTitleStyle: {
        fontWeight: "bold",
        color: "#A8CF45",
        shadowOpacity: 1
      }
    };
  };

  state = {
    title: "",
    text: "",
    pictureURL: null,
    pictureURL2: null,
    pictureURL3: null,
    pictureURL4: null,
    pictureURL5: null,
    pictureToChange: 1,
    price: null,
    priceType: "Fixed Price",
    priceCurrency: null,
    currencies: [],
    hasDelivery: false,
    hasMultiplePictures: false,
    hasVideoEnabled: global.app == "speakers",
    category1: null,
    category2: null,
    location: {
      coords: {
        latitude: global.myLocationWork ? global.myLocationWork.latitude : 6.55,
        longitude: global.myLocationWork ? global.myLocationWork.longitude : 3.33
      },
      address: global.myLocationWorkAddress
    },
    showGetPicture: false,
    showGetVideo: false,
    showLocationModal: false,
    activityIndicatorAnimating: false,
    error: false,
    modalPriceTypeVisible: false,
    modalCurrencyVisible: false,
    watchingYoutube: false,
    playingYoutubeID: "",
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    //this.unsubscribe = null;
  }

  async componentDidMount() {
    const category1 = this.props.navigation.getParam("category1", null);
    const category2 = this.props.navigation.getParam("category2", null);
    if (category1) this.setState({ category1 });
    if (category2) this.setState({ category2 });

    //The following is not (yet?) in use. Meant for duplicating offers
    const input = this.props.navigation.getParam("input", null);
    if (input) {
      this.setState({
        text: input.text,
        pictureURL: input.pictureURL,
        pictureURL2: input.pictureURL2,
        pictureURL3: input.pictureURL3,
        pictureURL4: input.pictureURL4,
        pictureURL5: input.pictureURL5,
        price: input.price,
        priceType: input.priceType,
        hasDelivery: input.hasDelivery || false,
        category1: input.category1,
        category2: input.category2,
        location: input.location
      });
    }
    if (global.walletType) {
      if (global.walletType == "ACN") this.setState({ priceCurrency: "GHS", currencies: ["ACN: ACoin", "GHS: Ghana Cedi", "NGN: Nigerian Naira"] })
      if (global.walletType == "NGN") this.setState({ priceCurrency: "NGN", currencies: ["NGN: Nigerian Naira", "GHS: Ghana Cedi"] })
    } else {
      while (!global.walletType) {
        await new Promise(resolve => { setTimeout(resolve, 800); });
      }
      if (global.walletType == "ACN") this.setState({ priceCurrency: "GHS", currencies: ["ACN: ACoin", "GHS: Ghana Cedi", "NGN: Nigerian Naira"] })
      if (global.walletType == "NGN") this.setState({ priceCurrency: "NGN", currencies: ["NGN: Nigerian Naira", "GHS: Ghana Cedi"] })
    }
    if (global.app == "speakers") this.setState({ category1: "Professionals", category2: "Public Speakers" })

    if (!global.myLocationWork || !this.state.location || !this.state.location.coords) {
      await global.setLocationFast(5)
      const address = await global.getAddress()
      this.setState({ location: { coords: global.location.coords, address } })
    }
  }

  playYoutube = (id) => {
    this.setState({ playingYoutubeID: id, watchingYoutube: true })
  }

  callSend = async () => {
    if (this.state.hasMultiplePictures) {
      if (!this.state.pictureURL) await this.removePicture(0)
      if (!this.state.pictureURL) await this.removePicture(0)
      if (!this.state.pictureURL) await this.removePicture(0)
      if (!this.state.pictureURL) await this.removePicture(0)
      if (!this.state.pictureURL2) await this.removePicture(1)
      if (!this.state.pictureURL2) await this.removePicture(1)
      if (!this.state.pictureURL2) await this.removePicture(1)
      if (!this.state.pictureURL3) await this.removePicture(2)
      if (!this.state.pictureURL3) await this.removePicture(2)
      if (!this.state.pictureURL4) await this.removePicture(3)
    }
    if (!this.state.pictureURL) {
      alert("You need to add a picture");
      return;
    }
    if (Number(this.state.price) == NaN) {
      alert("Charge must be a number");
      return;
    }
    if (!this.state.location || !this.state.location.coords || this.state.location.coords.latitude == 6.55) {
      alert("You need to specify the location of your offer");
      return;
    }
    if (!this.state.location.coords.latitude || !this.state.location.coords.longitude) {
      alert("Error. Please update location to proceed");
      return;
    }
    if (!this.state.text && !this.state.title) {
      alert("Title and description are empty");
      return;
    }
    if (
      (this.state.includePrice &&
        this.state.price <= 0 &&
        this.state.priceType != "Free") || (
        this.state.includePrice &&
        !this.state.price &&
        this.state.priceType != "Free"
      )
    ) {
      if (this.state.category1 == "QuickJobs") alert("No pay entered");
      else alert("No price entered");
      return;
    }
    if (this.state.includePrice && !Number(this.state.price) && this.state.priceType != "Free") {
      alert("Price must be a number");
      return;
    }
    if (global.myProfileID && !this.state.activityIndicatorAnimating) {
      try {
        this.setState({ activityIndicatorAnimating: true });
        await global.fetchIdToken()
        const r = await fetch(global.cloudFunctionURL + "createPost", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ isWeb:true,
            myProfileID: global.myProfileID,
            text: this.state.text,
            title: this.state.title,
            pictureURL: this.state.pictureURL,
            youtubeID: this.state.youtubeID,
            ...(this.state.hasMultiplePictures && {
              pictureURL2: this.state.pictureURL2,
              pictureURL3: this.state.pictureURL3,
              pictureURL4: this.state.pictureURL4,
              pictureURL5: this.state.pictureURL5,
              youtubeID2: this.state.youtubeID2,
              youtubeID3: this.state.youtubeID3,
              youtubeID4: this.state.youtubeID4,
              youtubeID5: this.state.youtubeID5,
            }),
            price: this.state.includePrice ? Number(this.state.price) : -1,
            priceType: this.state.includePrice ? this.state.priceType : "NONE",
            priceCurrency: this.state.priceCurrency,
            hasDelivery: this.state.hasDelivery,
            category1: this.state.category1,
            category2: this.state.category2,
            location: this.state.location,
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
        await this.setState({ activityIndicatorAnimating: false });
        const r2 = await r.json();
        if (r2.msg == "SUCCESS") {
          // alert
          // initiateApproval (including push notification for approver OR chat to approver)
          // navigate back

          console.log("Job created");
          this.props.navigation.goBack();
        } else {
          alert("Error. Try again");
          console.log("Error while calling createPost", r2);
        }
      } catch (err) {
        alert("Connection error.");
        console.log(err);
        this.setState({ activityIndicatorAnimating: false });
      }
    }
  };

  classifyPicture = async (url) => {
    console.log("classifyPost called")
    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "classifyPost", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          imageURL: url,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      console.log("classifyPost response received", r2)
      if (r2.msg == "SUCCESS") {
        if (r2.categories && r2.categories.category1 && r2.categories.category2 && !this.state.category1 && !this.state.category2) this.setState({ category1: r2.categories.category1, category2: r2.categories.category2 })
        if (r2.lowQualitySuspected) alert("Low image quality. Higher quality images result in more customers.")
      }
    } catch (err) {
      console.log(err);
    }
  }

  removePicture = async (i) => {
    if (i == 0) {
      await this.setState({
        pictureURL: this.state.pictureURL2, pictureURL2: this.state.pictureURL3, pictureURL3: this.state.pictureURL4, pictureURL4: this.state.pictureURL5, pictureURL5: null,
        youtubeID: this.state.youtubeID2, youtubeID2: this.state.youtubeID3, youtubeID3: this.state.youtubeID4, youtubeID4: this.state.youtubeID5, youtubeID5: null
      })
    }
    if (i == 1) {
      await this.setState({
        pictureURL2: this.state.pictureURL3, pictureURL3: this.state.pictureURL4, pictureURL4: this.state.pictureURL5, pictureURL5: null,
        youtubeID2: this.state.youtubeID3, youtubeID3: this.state.youtubeID4, youtubeID4: this.state.youtubeID5, youtubeID5: null
      })
    }
    if (i == 2) {
      await this.setState({
        pictureURL3: this.state.pictureURL4, pictureURL4: this.state.pictureURL5, pictureURL5: null,
        youtubeID3: this.state.youtubeID4, youtubeID4: this.state.youtubeID5, youtubeID5: null
      })
    }
    if (i == 3) {
      await this.setState({
        pictureURL4: this.state.pictureURL5, pictureURL5: null,
        youtubeID4: this.state.youtubeID5, youtubeID5: null
      })
    }
    if (i == 4) {
      await this.setState({
        pictureURL5: null,
        youtubeID5: null
      })
    }
  }

  render() {
    let CategorySelector = global.app == "artisan" ? CategorySelectorArtisan : global.app == "education" ? CategorySelectorEducation :
      global.app == "speakers" ? (<View style={{ height: 20 }} />) : CategorySelectorMarket
    const sWidth = Dimensions.get("window").width;
    const sHeight = Dimensions.get("window").height;

    if (!this.state.priceCurrency) return (
      <View
        style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "black" }}
      >
        <Text style={{ color: "#555" }}>Loading...</Text>
      </View>
    );
    if (this.state.error) {
      return (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "black" }}
        >
          <Text style={{ color: "#555" }}>Error. Try again.</Text>
        </View>
      );
    } else {
      return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#000000" }}>
          <TouchableWithoutFeedback
            onPress={Keyboard.dismiss}
            accessible={false}
          >
            <ScrollView style={{ marginTop: 50, paddingHorizontal: 10 }}
              onScroll={(e) => { Keyboard.dismiss() }}>
              {Platform.OS === "android" && <View style={{ height: 25 }} />}
              {!this.state.hasMultiplePictures ? <View style={{ alignItems: "center", justifyContent: "center" }}>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showGetPicture: true });
                  }}>
                  {!!this.state.pictureURL ? <Image
                    key={this.state.pictureURL}
                    style={{ width: 220, height: 220, borderRadius: 22, borderWidth: 1, borderColor: "#222" }}
                    source={{ uri: this.state.pictureURL }}
                  /> :
                    <View
                      style={{ width: 220, height: 220, borderRadius: 22, borderColor: "#aaa", borderWidth: 1, borderStyle: "dashed" }}
                    />
                  }
                </TouchableOpacity>
                {this.state.showGetPicture && (
                  <GetPicture
                    onDone={url => {
                      if (url) {
                        this.setState({ pictureURL: url });
                        this.classifyPicture(url)
                      }
                      console.log("PictureURL", url);
                      this.setState({ showGetPicture: false });
                    }}
                    item={"POST"}
                  />
                )}
                {this.state.showGetVideo && (
                  <GetVideo
                    onDone={(youtubeID, thumbURL) => {
                      if (youtubeID && thumbURL) {
                        this.setState({ pictureURL: thumbURL, youtubeID });
                        //this.classifyPicture(thumbURL) 
                      }
                      console.log("Gotten YouTube video..", youtubeID, thumbURL);
                      this.setState({ showGetVideo: false });
                    }
                    }
                    item={"POST"}
                  />
                )}
                {!!this.state.youtubeID && <TouchableOpacity style={{
                  marginTop: -(220), width: 220, height: 220, alignItems: "center", justifyContent: "center"
                }}
                  onPress={() => { this.playYoutube(this.state.youtubeID) }}>
                  <View
                    style={{
                      backgroundColor: "rgba(198,198,198,0.95)", borderRadius: 25, paddingLeft: Platform.OS == "ios" ? 3 : 3.5, paddingBottom: Platform.OS == "ios" ? 0.5 : 2.5,
                      height: 50, justifyContent: 'center', alignItems: 'center', width: 50,
                    }}>
                    <Text style={{ color: "#000", fontSize: 36 }} >
                      {"▶"}
                    </Text>
                  </View>
                </TouchableOpacity>}
                {!!this.state.hasVideoEnabled && <TouchableOpacity
                  onPress={() => { this.setState({ showGetVideo: true }); }}
                  style={{ backgroundColor: "rgba(220, 220, 220, 0.8)", marginTop: -42, marginBottom: 19, padding: 3, borderRadius: 5 }}
                >
                  <Text style={{ color: "black", minWidth: 100, textAlign: "center" }}>{this.state.youtubeID ? 'Change Video' : 'Add Video'}</Text>
                </TouchableOpacity>}
                <TouchableOpacity
                  onPress={() => { this.setState({ showGetPicture: true }); }}
                  style={{ backgroundColor: "rgba(220, 220, 220, 0.8)", marginTop: -11.5, padding: 3, borderRadius: 5 }}
                >
                  <Text style={{ color: "black", minWidth: 100, textAlign: "center" }}>{this.state.pictureURL ? this.state.youtubeID ? 'Change Thumbnail' : 'Change Picture' : 'Add Picture'}</Text>
                </TouchableOpacity>
              </View>
                :
                <ScrollView horizontal style={{ paddingTop: 10 }}>
                  {[this.state.pictureURL, this.state.pictureURL2, this.state.pictureURL3, this.state.pictureURL4, this.state.pictureURL5]
                    .map((pic, i) => {
                      let stateURL = (i == 0) ? this.state.pictureURL : this.state["pictureURL" + (i + 1)]
                      let stateID = (i == 0) ? this.state.youtubeID : this.state["youtubeID" + (i + 1)]
                      return (
                        <View style={{ alignItems: "center", justifyContent: "center", margin: 5, paddingTop: 10 }} key={pic ? pic + i : i}>
                          <TouchableOpacity
                            onPress={() => {
                              this.setState({ pictureToChange: i + 1, showGetPicture: true });
                            }}>
                            {!!stateURL ? <Image
                              key={stateURL}
                              style={{ width: 150, height: 150, borderRadius: 15, borderWidth: 1, borderColor: "#222" }}
                              source={{ uri: stateURL }}
                            /> :
                              <View
                                style={{ width: 150, height: 150, borderRadius: 15, borderColor: "#aaa", borderWidth: 1, borderStyle: "dashed" }}
                              />
                            }
                          </TouchableOpacity>
                          {this.state.showGetPicture && (
                            <GetPicture
                              onDone={url => {
                                if (url) {
                                  if (this.state.pictureToChange == 1) this.setState({ pictureURL: url });
                                  if (this.state.pictureToChange == 2) this.setState({ pictureURL2: url });
                                  if (this.state.pictureToChange == 3) this.setState({ pictureURL3: url });
                                  if (this.state.pictureToChange == 4) this.setState({ pictureURL4: url });
                                  if (this.state.pictureToChange == 5) this.setState({ pictureURL5: url });
                                  this.classifyPicture(url)
                                }
                                console.log("PictureURL", url);
                                this.setState({ showGetPicture: false });
                              }}
                              item={"POST"}
                            />
                          )}
                          {this.state.showGetVideo && (
                            <GetVideo
                              onDone={(youtubeID, thumbURL) => {
                                if ((youtubeID && thumbURL)) {
                                  if (this.state.videoToChange == 1) this.setState({ pictureURL: thumbURL, youtubeID: youtubeID });
                                  if (this.state.videoToChange == 2) this.setState({ pictureURL2: thumbURL, youtubeID2: youtubeID });
                                  if (this.state.videoToChange == 3) this.setState({ pictureURL3: thumbURL, youtubeID3: youtubeID });
                                  if (this.state.videoToChange == 4) this.setState({ pictureURL4: thumbURL, youtubeID4: youtubeID });
                                  if (this.state.videoToChange == 5) this.setState({ pictureURL5: thumbURL, youtubeID5: youtubeID });
                                  //this.classifyPicture(thumbURL)
                                }
                                console.log("Gotten YouTube video", youtubeID, thumbURL);
                                this.setState({ showGetVideo: false });
                              }}
                              item={"POST"}
                            />
                          )}
                          {!!stateID && <TouchableOpacity style={{
                            marginTop: -(161.5), width: 161.5, height: 161.5, alignItems: "center", justifyContent: "center"
                          }}
                            onPress={() => { this.playYoutube(stateID) }}>
                            <View
                              style={{
                                backgroundColor: "rgba(198,198,198,0.95)", borderRadius: 25, paddingLeft: Platform.OS == "ios" ? 4 : 3.5, paddingBottom: Platform.OS == "ios" ? -2 : 2.5,
                                height: 50, justifyContent: 'center', alignItems: 'center', width: 50,
                              }}>
                              <Text style={{ color: "#000", fontSize: 32 }} >
                                {"▶"}
                              </Text>
                            </View>
                          </TouchableOpacity>}
                          {!!stateURL && <TouchableOpacity
                            onPress={() => { this.removePicture(i); }}
                            style={{ alignSelf: "flex-start", height: 20, width: 20, marginTop: -161.5, marginBottom: 141.5 }}
                          >
                            <View style={{ height: 20, width: 20, backgroundColor: "rgba(220, 220, 220, 0.7)", borderRadius: 15 }}>
                              <Image
                                source={require("../../images/icons/CancelBlack.png")}
                                style={{ height: 22, width: 22, marginLeft: -1, marginTop: -1 }}
                              />
                            </View>
                          </TouchableOpacity>}
                          {!!this.state.hasVideoEnabled && <TouchableOpacity
                            onPress={() => { this.setState({ videoToChange: i + 1, showGetVideo: true }); }}
                            style={{ backgroundColor: "rgba(220, 220, 220, 0.8)", marginTop: -42, marginBottom: 19, padding: 3, borderRadius: 5 }}
                          >
                            <Text style={{ color: "black", minWidth: 100, textAlign: "center" }}>{stateID ? 'Change Video' : 'Add Video'}</Text>
                          </TouchableOpacity>}
                          <TouchableOpacity
                            onPress={() => { this.setState({ pictureToChange: i + 1, showGetPicture: true }); }}
                            style={{ backgroundColor: "rgba(220, 220, 220, 0.8)", marginTop: -11.5, padding: 3, borderRadius: 5 }}
                          >
                            <Text style={{ color: "black", minWidth: 100, textAlign: "center" }}>{stateURL ? stateID ? 'Change Thumbnail' : 'Change Picture' : 'Add Picture'}</Text>
                          </TouchableOpacity>
                          {
                            i == 0 && <View
                              style={{ backgroundColor: "rgba(0,0,0,0.6)", marginTop: -156.5, height: 18, marginBottom: 137.5, justifyContent: "center", paddingHorizontal: 3, marginRight: 5, borderRadius: 2 }}
                            >
                              <Text style={{ color: "#aaa", fontWeight: "bold", fontSize: 11 }}>{stateID ? "Main Video" : "Main Picture"}</Text>
                            </View>
                          }
                        </View>)
                    })}
                </ScrollView>}
              <TextInput
                onChangeText={title => {
                  this.setState({ title });
                }}
                placeholder={"Enter a title ..."}
                placeholderTextColor={"#888"}
                style={{
                  color: "#ffffff",
                  borderColor: "grey",
                  borderWidth: 0,
                  paddingLeft: 3,
                  borderRadius: 5,
                  height: 30,
                  backgroundColor: "#334",
                  marginTop: 20
                }}
                maxLength={45}
                underlineColorAndroid="transparent"
                defaultValue={this.state.title}
                keyboardDismissMode={'on-drag'}
                keyboardAppearance={"dark"}
                returnKeyType={"done"}
              />
              <TextInput
                onChangeText={text => {
                  this.setState({ text });
                }}
                multiline={true}
                placeholder={"Enter a description ..."}
                placeholderTextColor={"#888"}
                numberOfLines={3}
                style={{
                  color: "#ffffff",
                  borderColor: "grey",
                  borderWidth: 0,
                  paddingLeft: 3,
                  borderRadius: 5,
                  height: 80,
                  backgroundColor: "#334",
                  marginTop: 10
                }}
                maxLength={2000}
                underlineColorAndroid="transparent"
                defaultValue={this.state.text}
                rowSpan={5}
                keyboardDismissMode={'on-drag'}
                keyboardAppearance={"dark"}
                returnKeyType={"done"}
              />
              {global.app != "speakers" && <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 15
                }}
              >
                <TouchableHighlight
                  onPress={() => {
                    if (this.state.includePrice) {
                      this.setState({ includePrice: false });
                    } else {
                      this.setState({ includePrice: true });
                    }
                  }}
                >
                  <Selector
                    selected={this.state.includePrice}
                    text={this.state.includePrice ? (this.state.category1 == "QuickJobs") ? "Pay" : "Price" : (this.state.category1 == "QuickJobs") ? "Include Pay" : "Include Price"}
                  />
                </TouchableHighlight>
                {!!this.state.includePrice && this.state.priceType != "Free" && (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <TouchableOpacity
                      onPress={() => this.setState({ modalCurrencyVisible: true })}
                      style={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <View
                        style={{
                          color: "#ffffff",
                          borderColor: "#334",
                          borderWidth: 1,
                          padding: 3,
                          borderRadius: 15,
                          width: 30,
                          backgroundColor: "#000",
                          alignItems: "center",
                          marginHorizontal: 1
                        }}
                      >
                        <Text style={{ color: "#ccc" }}>{global.currencySymbols[this.state.priceCurrency]}</Text>
                      </View>
                    </TouchableOpacity>
                    <TextInput
                      onChangeText={price => {
                        this.setState({ price });
                      }}
                      placeholder={"e.g. 1000"}
                      placeholderTextColor={"#888"}
                      keyboardType="numeric"
                      style={{
                        color: "#ffffff",
                        borderColor: "grey",
                        borderWidth: 0,
                        padding: 3,
                        borderRadius: 5,
                        width: 100,
                        backgroundColor: "#334",
                        textAlign: "center"
                      }}
                      maxLength={200}
                      underlineColorAndroid="transparent"
                      defaultValue={this.state.price}
                      rowSpan={5}
                      bordered
                    />
                  </View>
                )}
                {!!this.state.includePrice && (
                  <TouchableOpacity
                    onPress={() => this.setState({ modalPriceTypeVisible: true })}
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <View
                      style={{
                        color: "#ffffff",
                        borderColor: "#334",
                        borderWidth: 1,
                        padding: 3,
                        borderRadius: 15,
                        width: 100,
                        backgroundColor: "#000",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ color: "#ccc" }}>{this.state.priceType}</Text>
                    </View>
                  </TouchableOpacity>
                )}
              </View>}
              {global.app != "speakers" && <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TouchableHighlight
                  onPress={() => {
                    this.setState({ hasDelivery: !this.state.hasDelivery });
                  }}
                >
                  <Selector
                    selected={this.state.hasDelivery}
                    text={!this.state.hasDelivery ? "Offer Delivery" : "This product can be delivered"}
                  />
                </TouchableHighlight>
              </View>}
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TouchableHighlight
                  onPress={() => { this.setState({ hasMultiplePictures: !this.state.hasMultiplePictures }); }}
                >
                  <Selector
                    selected={this.state.hasMultiplePictures}
                    text={global.app != "speakers" ? "Add extra pictures" : "Add extra pictures / videos"}
                  />
                </TouchableHighlight>
              </View>
              {global.app != "speakers" && <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TouchableHighlight
                  onPress={() => { this.setState({ hasVideoEnabled: !this.state.hasVideoEnabled }); }}
                >
                  <Selector
                    selected={this.state.hasVideoEnabled}
                    text={"Add Video(s)"}
                  />
                </TouchableHighlight>
              </View>}
              <View
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 10,
                  marginBottom: 10
                }}
              >
                <View style={{}}>
                  <CurrentLocation
                    onPressEdit={() => { this.setState({ showLocationModal: true }) }}
                    setLiveLocation={false}
                    textColor={"#ccc"}
                    location={this.state.location}
                    iconColor={"#fff"}
                  />
                  <View style={{ height: 2 }} />
                  {global.app != "speakers" && <CategorySelector
                    type={"newPost"}
                    text={" "}
                    textSize={1}
                    category1={this.state.category1}
                    category2={this.state.category2}
                    immediatelyRequestCategory2={(this.state.category1 && !this.state.category2)}
                    onSelectCategory={(category1, category2) => {
                      this.setState({ category1, category2 });
                    }}
                  />}
                </View>
                <View style={{ height: 30 }} />
                {!this.state.activityIndicatorAnimating &&
                  !!this.state.category2 ? (
                  <TouchableOpacity
                    style={global.gStyles.button}
                    onPress={this.callSend}
                  >
                    <View style={{ flexDirection: "row" }}>
                      <ActivityIndicator
                        size="small"
                        color="#000"
                        style={{
                          marginRight: 20,
                          opacity: this.state.activityIndicatorAnimating
                            ? 1.0
                            : 0.0
                        }}
                        animating={true}
                      />
                      <Text style={global.gStyles.buttontext}>Submit Offer</Text>
                      <View style={{ width: 44 }} />
                    </View>
                  </TouchableOpacity>
                ) : !!this.state.category2 ? (
                  <ActivityIndicator
                    size="small"
                    color="#fff"
                    style={{
                      alignSelf: "center",
                      margin: 20
                    }}
                    animating={true}
                  />
                ) : (
                  <View
                    style={{
                      alignSelf: "center",
                      margin: 20
                    }}
                  >
                    <Text style={{ color: "#ccc", fontSize: 16 }}>
                      Select a category above...
                    </Text>
                  </View>
                )}
              </View>
              <Modal
                animationType="slide"
                transparent={true}
                visible={this.state.modalPriceTypeVisible}
                onRequestClose={() => {
                  this.setState({ modalPriceTypeVisible: false });
                }}
              >
                <TouchableOpacity
                  onPress={() =>
                    this.setState({ modalPriceTypeVisible: false })
                  }
                  style={{ height: 200 }}
                />
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#ccd"
                    }}
                  >
                    <View style={{ paddingTop: 50 }}>
                      <Text style={{ fontSize: 30 }}>{this.state.category1 == "QuickJobs" ? "Select a pay type" : "Select a price type"}</Text>
                    </View>
                    <View style={{ marginTop: 35, marginBottom: 5 }}>
                      {/* <Image
                    style={{ width: 100, height: 100, borderRadius: 33 }}
                    source={this.images[this.state.category1]}
                  /> */}
                    </View>
                    <SectionList
                      renderItem={({ item, index, section }) => (
                        <TouchableOpacity
                          onPress={() => {
                            this.setState({
                              modalPriceTypeVisible: false,
                              priceType: item
                            });
                          }}
                          style={{
                            flex: 1,
                            paddingTop: 10,
                            paddingBottom: 10,
                            alignItems: "center",
                            width: Dimensions.get("window").width,
                            backgroundColor: "white",
                            borderWidth: 1,
                            borderColor: "#ccd"
                          }}
                        >
                          <Text style={{ fontSize: 18 }} key={index}>
                            {item}
                          </Text>
                        </TouchableOpacity>
                      )}
                      renderSectionHeader={({ section: { title } }) => (
                        <Text style={{ fontWeight: "bold" }}>{title}</Text>
                      )}
                      sections={[
                        {
                          title: "",
                          data: [
                            "Fixed Price",
                            "Starting From",
                            "Negotiable",
                            "Free"
                          ]
                        }
                      ]}
                      keyExtractor={(item, index) => item + index}
                    />
                  </View>
                  {/* <TouchableOpacity
                    style={{
                      position: "absolute",
                      borderWidth: 0,
                      borderColor: "rgba(0,0,0,0.2)",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 40,
                      top: 0,
                      left: 3,
                      height: 59,
                      backgroundColor: "#ccc",
                      borderRadius: 30
                    }}
                    onPress={() =>
                      this.setState({ modalPriceTypeVisible: false })
                    }
                  >
                    <Text style={{ fontSize: 26, color: "#000" }} />
                  </TouchableOpacity> */}
                </View>
              </Modal>
              <Modal
                animationType="slide"
                transparent={true}
                visible={this.state.modalCurrencyVisible}
                onRequestClose={() => {
                  this.setState({ modalCurrencyVisible: false });
                }}
              >
                <TouchableOpacity
                  onPress={() =>
                    this.setState({ modalCurrencyVisible: false })
                  }
                  style={{ height: 200 }}
                />
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#ccd"
                    }}
                  >
                    <View style={{ paddingTop: 50 }}>
                      <Text style={{ fontSize: 30 }}>Select a currency</Text>
                    </View>
                    <View style={{ marginTop: 35, marginBottom: 5 }}>
                      {/* <Image
                    style={{ width: 100, height: 100, borderRadius: 33 }}
                    source={this.images[this.state.category1]}
                  /> */}
                    </View>
                    <SectionList
                      renderItem={({ item, index, section }) => (
                        <TouchableOpacity
                          onPress={() => {
                            this.setState({
                              modalCurrencyVisible: false,
                              priceCurrency: item.substring(0, 3)
                            });
                          }}
                          style={{
                            flex: 1,
                            paddingTop: 10,
                            paddingBottom: 10,
                            //alignItems: "center",
                            paddingHorizontal: 10,
                            width: Dimensions.get("window").width,
                            backgroundColor: "white",
                            borderWidth: 1,
                            borderColor: "#ccd"
                          }}
                        >
                          <Text style={{ fontSize: 18 }} key={index}>
                            {item}
                          </Text>
                        </TouchableOpacity>
                      )}
                      renderSectionHeader={({ section: { title } }) => (
                        <Text style={{ fontWeight: "bold" }}>{title}</Text>
                      )}
                      sections={[
                        {
                          title: "",
                          data: this.state.currencies
                        }
                      ]}
                      keyExtractor={(item, index) => item + index}
                    />
                  </View>
                  {/* <TouchableOpacity
                    style={{
                      position: "absolute",
                      borderWidth: 0,
                      borderColor: "rgba(0,0,0,0.2)",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 40,
                      top: 0,
                      left: 3,
                      height: 59,
                      backgroundColor: "#ccc",
                      borderRadius: 30
                    }}
                    onPress={() =>
                      this.setState({ modalPriceTypeVisible: false })
                    }
                  >
                    <Text style={{ fontSize: 26, color: "#000" }} />
                  </TouchableOpacity> */}
                </View>
              </Modal>
              <Modal
                animationType="fade"
                visible={this.state.watchingYoutube}
                onRequestClose={() => {
                  this.setState({ watchingYoutube: false });
                }}
              >
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "flex-end",
                    height: "100%",
                    backgroundColor: "#000"
                  }}
                >
                  <WebView
                    originWhitelist={['*']}
                    style={{ width: sWidth, height: sHeight, marginVertical: ((sHeight - sWidth * 0.7) / 2) }}
                    source={{ uri: 'https://www.youtube.com/embed/' + this.state.playingYoutubeID }}
                    javaScriptEnabled={true}
                    domStorageEnabled={true}
                    useWebKit={true}
                  />
                  <TouchableOpacity
                    style={{
                      position: "absolute",
                      borderWidth: 0,
                      backgroundColor: "#000",
                      borderColor: "rgba(0,0,0,0.2)",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 35,
                      top: 65,
                      padding: 10,
                      left: 5,
                      height: 25,
                      borderRadius: 35
                    }}
                    onPress={() => {
                      this.setState({ watchingYoutube: false });
                    }}
                  >
                    <Image
                      style={{ width: 35, height: 35, opacity: 1 }}
                      source={require("../../images/icons/CancelWhite.png")}
                    />
                  </TouchableOpacity>
                </View>
              </Modal>
            </ScrollView>
          </TouchableWithoutFeedback>
          {!!this.state.showLocationModal && <CurrentLocationModal
            onCancel={() => { this.setState({ showLocationModal: false }) }}
            onSelect={(coords, address) => {
              // console.log("upda", address, coords);
              this.setState({
                showLocationModal: false,
                location: {
                  address: address,
                  coords: coords
                }
              });
            }}
          />}
        </SafeAreaView>
      );
    }
  }
}

import React, { Component } from 'react'
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  Animated,
  Platform,
  Modal,
  Dimensions
} from "react-native";
import * as Permissions from 'expo-permissions';
import MapScreen from './Map/mapScreen';

const gStyles = global.gStyles //    "../styles/gStyles";


class FadeInView extends React.Component {
  state = {
    fadeAnim: new Animated.Value(0), // Initial value for opacity: 0
    timerDone: false,
  };
  async componentDidMount() {
    // await new Promise(resolve => {
    //   setTimeout(resolve, 1500);
    // });
    this.setState({ timerDone: true });
    Animated.timing(
      // Animate over time
      this.state.fadeAnim, // The animated value to drive
      {
        toValue: 1, // Animate to opacity: 1 (opaque)
        duration: 600, // Make it take a while
        useNativeDriver: true,
      }
    ).start(); // Starts the animation
  }

  render() {
    let { fadeAnim } = this.state;

    if (Platform.OS === "ios") {
      return (
        <Animated.View // Special animatable View
          style={{
            ...this.props.style,
            opacity: fadeAnim,
            backgroundColor: "transparent" // Bind opacity to animated value
          }}
        >
          {this.props.children}
        </Animated.View>
      );
    } else {
      return (
        <View>
          {!!this.state.timerDone && <View>{this.props.children}</View>}
        </View>
      );
    }
  }
}

// Popup that appears at the bottom of the screen showing which location is used (with buttons for hide, change, try again)
// Typically popping up in case location could not be retrieved
//
//props: msDelay (number) miliseconds of delay before popup should potentially start
export default class LocationPopus extends Component {

  state = {
    topText: "Location disabled",
    address: "Lagos, Nigeria",
    showPopup: false,
    buttonType: "TRY_AGAIN",
    buttonType2: "SET_MANUALLY",

    showModal: false,
    aa: false
  }
  async componentDidMount() {
    try {
      await new Promise(resolve => { setTimeout(resolve, this.props.msDelay || 3000); })
      if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 5000); });
      if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 5000); });
      if (!global.myUID) return;

      this.check()

    } catch (e) {
      console.warn('LocationPopup error');
      console.log('LocationPopup error:', e);
    }
  }

  check = async (reloadOnSuccess) => {
    try {
      this.setState({ aa: true })
      const { status } = await Permissions.getAsync(Permissions.LOCATION)
      console.log("Location status: " + status)
      if (status !== "granted") {
        if (!global.location) {
          global.location = { coords: { latitude: 6.6, longitude: 3.3 } }
          global.myLocation = global.location
        }
        const address = await global.getAddress()
        this.setState({
          showPopup: true, topText: "Location permissions not granted", buttonType: "ENABLE",
          buttonType2: "SET_MANUALLY", aa: false, address
        }) //tbd: set buttonType to SETTINGS and implement ??? done?
        return;
      } else {
        //console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
        const locationFound = await global.setLocationFast(5)
        //console.log("AAAAAAAAAAAAAAAAAAAAAAAA22222222222222AAAAAAAAAAAAAAAAAAAAAAAA", locationFound)
        if (locationFound) {
          if (this.props.showAlwaysOnce || this.props.showAlways) {
            const address = await global.getAddress()
            this.setState({
              showPopup: true, address, aa: false, buttonType: null,
              topText: "", buttonType2: "CHANGE",
            })
          }
          else {
            if (!reloadOnSuccess) this.setState({ showPopup: false, aa: false }) //check this
            else this.setState({ showPopup: true, aa: false })
          }
          if (reloadOnSuccess) this.props.onNewLocation()
        }
        else {
          const address = await global.getAddress()
          this.setState({ showPopup: true, topText: "Location disabled", buttonType: "TRY_AGAIN", buttonType2: "SET_MANUALLY", aa: false, address })
        }
      }
    } catch (e) {
      console.warn('LocationCheck error');
      console.log('LocationCheck error:', e);
      this.setState({ aa: false })
    }
  }

  enablePermissions = async () => {
    try {
      const { status } = await Permissions.askAsync(Permissions.LOCATION);
      if (status !== "granted") {
        alert("You need to enable permissions for Wakanda in the system settings of your phone")
        await global.timeout(1500)
        this.setState({ buttonType: "TRY_AGAIN", buttonType2: "SET_MANUALLY" })
        return;
      } else this.check(true)
    } catch (e) {
      console.warn('enablePermissions errorx');
      console.log('enablePermissions errorx:', e);
    }
  }

  render() {
    const marginBottom = 0 //+ (Dimensions.get("window").height >= 800 ? 78 : 48)
    const height = 80
    if (!this.state.showPopup) return <View />
    else return (
      <FadeInView>
        <View
          style={{
            flexDirection: "row",
            width: "99%",
            height,
            margin: 1,
            position: "absolute",
            bottom: marginBottom,
            left: 1,
            opacity: 1,
            backgroundColor: "#131313",
            borderRadius: 5,
            borderTopLeftRadius: 5,
            borderTopEndRadius: 5,
            borderBottomEndRadius: 5,
            justifyContent: "center",
            borderWidth: 1,
            borderColor: "#A8CF45",
          }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "flex-start",
              paddingLeft: 5,
            }}
          >
            <View
              style={{
                // justifyContent: "center",
                // alignSelf: "center",
                // alignItems: "center",
                // paddingRight: 30,
                // paddingLeft: 40,
              }}
            >
              <Text
                style={{
                  color: "#eef",
                  fontWeight: "bold"
                }}
              >
                {this.state.topText}
              </Text>
              <Text
                style={{
                  marginTop: 10,
                  color: "#aab",
                  textAlign: "center",
                  fontSize: 12
                }}
              >
                Showing near {this.state.address}
              </Text>
            </View>
          </View>
          <View style={{ width: 100, justifyContent: "center" }}>
            {this.state.buttonType ? !this.state.aa ? <TouchableOpacity
              onPress={() => {
                if (this.state.buttonType == "TRY_AGAIN") this.check(true);
                if (this.state.buttonType == "ENABLE") this.enablePermissions();
              }}
              style={[global.gStyles.buttonXW, { width: 80, borderColor: "#A8CF45", borderWidth: 0, marginBottom: 6.5 }]}
            >
              <Text style={[global.gStyles.buttontXW, { color: "#A8CF45" }]}>{this.state.buttonType == "TRY_AGAIN" ? "Try Again" : "Enable"}</Text>
            </TouchableOpacity>
              : <View
                style={[global.gStyles.buttonXW, { width: 80, borderColor: "#A8CF45", borderWidth: 0, marginBottom: 6.5 }]}
              >
                <ActivityIndicator size="small" color="#A8CF45" />
              </View> :
              <View
                style={[global.gStyles.buttonXW, { width: 80, borderColor: "#A8CF45", borderWidth: 0, marginBottom: 6.5 }]}
              >
                <View style={{ height: 20 }} />
              </View>}
            <TouchableOpacity
              onPress={() => { this.setState({ showModal: true }); }}
              style={[global.gStyles.buttonXW, { width: 80, borderColor: "#A8CF45", borderWidth: 0 }]}
            >
              <Text style={[global.gStyles.buttontXW, { color: "#A8CF45" }]}>{this.state.buttonType2 == "CHANGE" ? "Change" : "Set Manually"}</Text>
            </TouchableOpacity>
          </View>
        </View>

        <TouchableOpacity
          onPress={() => {
            {
              this.setState({ showPopup: false })
            }
          }}
          style={{
            height: 27,
            width: 27,
            backgroundColor: "#131313",
            position: "absolute",
            bottom: marginBottom + height - 15,
            right: 1,
            borderRadius: 15,
            alignItems: "center",
            justifyContent: "center",
            borderWidth: 1,
            borderColor: "#A8CF45",
          }}
        >
          <Text style={{ color: "#A8CF45", fontSize: 15 }}>✕</Text>
        </TouchableOpacity>
        <Modal
          animationType="slide"
          transparent={false}
          visible={this.state.showModal}
          onRequestClose={() => {
            //leave this here
          }}>
          <MapScreen
            getUpdatedLocation={(address, coords) => {
              this.setState({ mapAddress: address, mapCoords: coords });
            }}
            addresstoMapScreen={global.location ? global.location.coords : null}
          />
          <View style={{ width: '15%', margin: 20, marginTop: -130, flexDirection: 'row' }}>
            <TouchableOpacity
              style={{ backgroundColor: 'grey', borderRadius: 5, width: 70, justifyContent: 'center' }}
              onPress={() => { this.setState({ showModal: false, mapCoords: null, mapAddress: "" }) }}>
              <Text style={{ fontSize: 15, color: 'white', fontWeight: 'bold', padding: 5, textAlign: 'center' }}
              >Cancel</Text>
            </TouchableOpacity>
            {!!this.state.mapCoords && <TouchableOpacity
              style={{ backgroundColor: 'green', borderRadius: 5, width: 50, marginLeft: 10, justifyContent: 'center' }}
              onPress={async () => {
                this.setState({ showModal: false, address: this.state.mapAddress, showPopup: false });
                await global.timeout(50)
                global.location = { coords: this.state.mapCoords, address: this.state.mapCoords }
                global.myLocation = global.location
                this.props.onNewLocation()
              }}>
              <Text style={{ fontSize: 15, color: 'white', fontWeight: 'bold', padding: 5, textAlign: 'center' }}
              >Set</Text>
            </TouchableOpacity>}
          </View>
        </Modal>
      </FadeInView>
    )
  }
}
import React, { Component } from "react";
import { Animated, View, Dimensions, PanResponder } from "react-native";
import "firebase/firestore";
import Constants from 'expo-constants';

const EHEIGHT = Dimensions.get("window").height - (Dimensions.get("window").height >= 800 ? 78 : 48);
const EWIDTH = Dimensions.get("window").width;

export default class SlidingView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pan: new Animated.ValueXY(), // inits to zero
    };
    //this.fingerHasMoved=false;
    this.state.panResponder = PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onPanResponderMove: Animated.event([
        null,
        {
          //dx: this.state.pan.x, // x,y are Animated.Value
          dy: this.state.pan.y,
        }
      ], { useNativeDriver: false }),
      onPanResponderRelease: async (event, gesture) => {
        if (gesture.dy > 250 && !this.props.isFirst) {
          Animated.timing(this.state.pan, {
            toValue: { x: 0, y: EHEIGHT },
            duration: 250,
            useNativeDriver: false
          }).start();
          await global.timeout(250)
          this.state.pan.setValue({ x: 0, y: 0 });
          // Animated.spring(
          //   this.state.pan, // Auto-multiplexed
          //   { toValue: { x: 110, y: 110 } } // Back to zero
          // ).start();
          this.props.onSlidePrevious?.()
        } else if (gesture.dy < -250) {
          Animated.timing(this.state.pan, {
            toValue: { x: 0, y: -EHEIGHT },
            duration: 250,
            useNativeDriver: false
          }).start();
          await global.timeout(250)
          this.state.pan.setValue({ x: 0, y: 0 });
          this.props.onSlideNext?.()
        } else if (gesture.dx < 5 && gesture.dx > -5 && gesture.dy < 5 && gesture.dy > -5) {
          //this.props.onClick();
        } else {
          Animated.spring(
            this.state.pan, // Auto-multiplexed
            { useNativeDriver: false, toValue: { x: 0, y: 0 } } // Back to zero
          ).start();
        }
      }
    });
  }
  render() {
    const calculateStyleLast = () => {
      const { pan } = this.state;

      const marginTop = pan.y.interpolate({
        inputRange: [-EHEIGHT, 0, EHEIGHT],
        outputRange: [-EHEIGHT, -EHEIGHT, -EHEIGHT]
      });

      return {
        ...pan.getLayout(),
        //opacity,
        marginTop,
      };
    };
    const calculateStyle = () => {
      const { pan } = this.state;
      const opacity = pan.y.interpolate({
        inputRange: [-EHEIGHT, 0, EHEIGHT],
        outputRange: [0, 1, 0]
      });
      return {
        ...pan.getLayout(),
        //opacity,
      };
    };
    const calculateStyleNext = () => {
      const { pan } = this.state;
      return {
        ...pan.getLayout(),
      };
    };
    return (
      <View>
        <Animated.View
          {...this.state.panResponder.panHandlers}
          style={calculateStyleLast()}
          onLoad={this.onLoad}
        >
          {this.props.renderContentLast}
        </Animated.View>
        <Animated.View
          {...this.state.panResponder.panHandlers}
          style={calculateStyle()}
          onLoad={this.onLoad}
        >
          {this.props.renderContent}
        </Animated.View>
        <Animated.View
          {...this.state.panResponder.panHandlers}
          style={calculateStyleNext()}
          onLoad={this.onLoad}
        >
          {this.props.renderContentNext}
        </Animated.View>
      </View>
    );
  }
}

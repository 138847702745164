import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity,ActivityIndicator } from 'react-native';



export default class JobRating extends React.Component {
  
  state = {

    professionalism: null,
    quality: null,
    price: null,
    timeliness: null
  }
  
 


  circle(filled){
    if (filled){
      return (<View style={{width:20, height:20, borderColor:'#A8CF45', backgroundColor:'#A8CF45', borderWidth:1, borderRadius:10}}></View>);

    } else{
      return (<View style={{width:20, height:20, borderColor:'green', borderWidth:1, borderRadius:10}}></View>);
    }
   
  }
  
  render() {
    return (
      <View style={styles.container}>
          <View style ={{flexDirection:'row',borderBottomWidth:2, width: '100%',borderBottomColor:'green', justifyContent:'space-between'}}>
              
              <View style={{width:'50%'}}>
                <Text style ={{fontWeight:'bold', paddingBottom:10, color: 'white'}}>Open Job</Text>
              </View>
              {/* CLOSE BUTTON */}
              <TouchableOpacity 
              onPress = {()=>{ this.props.onClose()}}
              style ={{ width: '8%', marginBottom:5, backgroundColor:'red', borderRadius:20}}>
                <Text style ={{padding:1,  color: 'white', paddingTop:2, fontWeight:'bold', textAlign:'center'}}>X</Text>
              </TouchableOpacity>

          </View>
          
          <View style={{borderWidth:1, borderRadius:10, marginTop:30,
            borderColor:'green', width:'100%'}}>
            
                
            <View style={{flexDirection:'row', justifyContent:'space-between', margin:10, paddingRight:5}}>
                  <View style={{width:'30%'}}>
                     <Text style={{color: '#E9BC0A'}}>RATINGS:</Text>
                  </View>
                  <View style={{flexDirection:'row', width:'70%', paddingLeft:10,justifyContent:'space-between'}}>
                    <View><Text style={{color: '#E9BC0A'}}>Bad</Text></View>
                    <View><Text style={{color: '#E9BC0A'}}>  Good</Text></View>
                    <View><Text style={{color: '#E9BC0A'}}>Very Good</Text></View>
                    <View ><Text style={{color: '#E9BC0A'}}>Excellent</Text></View>
                  </View>
                </View>
                <View style={{flexDirection:'row', justifyContent:'space-between', margin:10}}>
                  <View style={{width:'30%'}}>
                     <Text style={{color:'white'}}>Professionalism</Text>
                  </View>
                  <View style={{flexDirection:'row', width:'70%', paddingLeft:10, justifyContent:'space-between'}}>
                     <TouchableOpacity onPress={ () => {this.setState({professionalism:1})}}>{this.circle(this.state.professionalism==1?true:false)}</TouchableOpacity>
                     <TouchableOpacity onPress={ () => {this.setState({professionalism:2})}}>{this.circle(this.state.professionalism==2?true:false)}</TouchableOpacity>
                     <TouchableOpacity onPress={ () => {this.setState({professionalism:3})}}>{this.circle(this.state.professionalism==3?true:false)}</TouchableOpacity>
                     <TouchableOpacity style ={{marginRight:40}}onPress={ () => {this.setState({professionalism:4})}}>{this.circle(this.state.professionalism==4?true:false)}</TouchableOpacity>
                    
                  </View>
                </View>
                <View style={{flexDirection:'row', justifyContent:'space-between', margin:10}}>
                  <View style={{width:'30%'}}>
                     <Text style={{color:'white'}}>Quality</Text>
                  </View>
                  <View style={{flexDirection:'row', width:'70%', paddingLeft:10, justifyContent:'space-between'}}>
                  <TouchableOpacity onPress={ () => {this.setState({quality:1})}}>{this.circle(this.state.quality==1?true:false)}</TouchableOpacity>
                     <TouchableOpacity onPress={ () => {this.setState({quality:2})}}>{this.circle(this.state.quality==2?true:false)}</TouchableOpacity>
                     <TouchableOpacity onPress={ () => {this.setState({quality:3})}}>{this.circle(this.state.quality==3?true:false)}</TouchableOpacity>
                     <TouchableOpacity style ={{marginRight:40}}onPress={ () => {this.setState({quality:4})}}>{this.circle(this.state.quality==4?true:false)}</TouchableOpacity>
                  </View>
                </View>
                <View style={{flexDirection:'row', justifyContent:'space-between', margin:10}}>
                  <View style={{width:'30%'}}>
                     <Text style={{color:'white'}}>Price</Text>
                  </View>
                  <View style={{flexDirection:'row', width:'70%', paddingLeft:10, justifyContent:'space-between'}}>
                  <TouchableOpacity onPress={ () => {this.setState({price:1})}}>{this.circle(this.state.price==1?true:false)}</TouchableOpacity>
                     <TouchableOpacity onPress={ () => {this.setState({price:2})}}>{this.circle(this.state.price==2?true:false)}</TouchableOpacity>
                     <TouchableOpacity onPress={ () => {this.setState({price:3})}}>{this.circle(this.state.price==3?true:false)}</TouchableOpacity>
                     <TouchableOpacity style ={{marginRight:40}}onPress={ () => {this.setState({price:4})}}>{this.circle(this.state.price==4?true:false)}</TouchableOpacity>
                  </View>
                </View>
                <View style={{flexDirection:'row', justifyContent:'space-between', margin:10}}>
                  <View style={{width:'30%'}}>
                     <Text style={{color:'white'}}>Timeliness</Text>
                  </View>
                  <View style={{flexDirection:'row', width:'70%', paddingLeft:10, justifyContent:'space-between'}}>
                     <TouchableOpacity onPress={ () => {this.setState({timeliness:1})}}>{this.circle(this.state.timeliness==1?true:false)}</TouchableOpacity>
                     <TouchableOpacity onPress={ () => {this.setState({timeliness:2})}}>{this.circle(this.state.timeliness==2?true:false)}</TouchableOpacity>
                     <TouchableOpacity onPress={ () => {this.setState({timeliness:3})}}>{this.circle(this.state.timeliness==3?true:false)}</TouchableOpacity>
                     <TouchableOpacity style ={{marginRight:40}}onPress={ () => {this.setState({timeliness:4})}}>{this.circle(this.state.timeliness==4?true:false)}</TouchableOpacity>
                  </View>
                </View>
              
          </View>
          <TouchableOpacity 
           onPress ={()=>{this.props.onSubmit(this.state.professionalism, this.state.quality, this.state.price, this.state.timeliness)}}
           style ={{width:'100%'}}>
            <View style = {{ flexDirection:'row', justifyContent:'center', backgroundColor:'#A8CF45', marginTop:10, borderRadius:8  }}>
                
                <ActivityIndicator
                  size="small"
                  color="#000000"
                  style={{
                    marginRight:10,
                    opacity: this.props.activityIndicatorAnimating ? 1.0 : 0.0
                  }}
                  animating={true}
                />
                <Text style={{color:'black', padding:10, textAlign:'center'}}>SUBMIT RATING</Text>
                <View style={{width:30}}></View>
            </View>
            
          </TouchableOpacity>
       </View>
    );
  }
}

const styles = ({
  container: {
    flex: 1,
    backgroundColor: '#222222',
    //justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    paddingTop: 50,
    width: '100%'
  },
});

import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  TextInput,
  Image,
  TouchableOpacity,
  SafeAreaView,
  Platform,
  ActivityIndicator
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";

class Contact extends Component {
  state = {
    contactProperties: {
      opponentProfileID: null,
      opponentName: "********",
      opponentNameBusiness: "************",
      opponentProfilePictureURL:
        "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/testDummies%2Favatar-1295429_640.png?alt=media&token=31c6b26e-6c40-4e6a-86cf-ee8c1f393706"
    },
    activityIndicatorAnimating: false
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  componentDidMount() {
    this.unsubscribeFirestore = firebase
      .firestore()
      .collection("Profiles")
      .doc(this.props.contactID)
      .onSnapshot(this.updateContact);
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }


  updateContact = async contactSnapshot => {
    console.log("Update of list of friends triggered. (NewChat)");
    try {
      let contact = contactSnapshot.data();

      let opponentProfileID = contactSnapshot.id;
      let opponentName = contact.profileName;
      let opponentProfilePictureURL = contact.profilePictureURL;
      let opponentNameBusiness = contact.profileNameBusiness;
      if (!contact.profileNameBusiness) {
        opponentNameBusiness = "";
      }

      let contactProperties = {
        opponentProfileID,
        opponentName,
        opponentNameBusiness,
        opponentProfilePictureURL
      };

      console.log("contactProperties", contactProperties);
      this.setState({
        contactProperties
      });
    } catch (err) {
      console.log(err);
      alert("Could not fetch contacts. Check your internet connection");
    }

  };

  render() {
    return (
      <TouchableOpacity onPress={this.props.onPress}>
        <View
          style={[
            {
              flexDirection: "row",
              flex: 1,
              height: 65,
              backgroundColor: "black",
              alignItems: "center",
              paddingLeft: 11,
              paddingRight: 11,
              shadowOpacity: 0.5,
              shadowOffset: { width: 0, height: 3 },
              borderBottomColor: "#333",
              borderWidth: 1
            }
          ]}
        >
          <Image
            style={{ width: 42, height: 42, paddingRight: 30 }}
            source={{
              uri: this.state.contactProperties.opponentProfilePictureURL
            }}
          />
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View
              style={{
                flexDirection: "column",
                flex: 1,
                paddingHorizontal: 8
              }}
            >
              <Text style={{ fontSize: 14, color: "white" }}>
                {this.state.contactProperties.opponentName}
              </Text>
              {this.state.contactProperties.opponentNameBusiness != "" && (
                <Text style={{ fontSize: 11, color: "white" }}>
                  {this.state.contactProperties.opponentNameBusiness}
                </Text>
              )}
            </View>
            <View>
              <ActivityIndicator
                size="small"
                color="#FFFFFF"
                //animating={this.state.activityIndicatorAnimating}
                style={{
                  opacity: this.state.activityIndicatorAnimating ? 1.0 : 0.0
                }}
                animating={true}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

export default class RecipientSelector extends Component {

  state = {
    contactIDs: []
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    try {
      this.unsubscribeFirestore = await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .collection("Refs")
        .doc("contactRefs")
        .onSnapshot(this.updateContacts);
    } catch (err) {
      //alert("Error initiating database connection (NewChat)");
      console.log("Error initiating database connection (NewChat)", err);
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
    console.log("Component NewChat will unmount");
  }

  updateContacts = async contactRefSnapshot => {
    let ids = contactRefSnapshot.data().followingProfileIDs;

    if (ids) {
      this.setState({ contactIDs: ids });
    }
  };

  render() {
    return (

      <View
      style={{
        paddingTop: 62,
        flex: 1,
        backgroundColor: "#ccc",
        justifyContent: "space-between",
        marginHorizontal: 20,
        marginVertical: 100,
        marginBottom: 200
      }}
    >
      <View
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          margin: 25
        }}
      >
        <Text style={{ fontSize: 24, alignSelf: "center", marginBottom:35 }}>
          Select Recipient
        </Text>
          <ScrollView>
            <View>
              {this.state.contactIDs.length > 0 ? (
                this.state.contactIDs.map(id => {
                  return (
                    <Contact
                      key={id}
                      contactID={id}
                      navigation={this.props.navigation}
                      onPress = {()=>{this.props.onSelect(id)}}
                    />
                  );
                })
              ) : (
                <Text style={{ color: "white" }}>No contacts yet</Text>
              )}
            </View>
          </ScrollView>
          {/* <TouchableOpacity
          style={{
            borderWidth: 1,
            borderColor: "rgba(0,0,0,0.2)",
            alignItems: "center",
            justifyContent: "center",
            width: 70,
            position: "absolute",
            bottom: 5,
            right: 15,
            height: 70,
            backgroundColor: "#A8CF45",
            borderRadius: 70
          }}
        >
          <Text style={{ fontSize: 36, fontWeight:"200", marginBottom:2 }}>+</Text>
        </TouchableOpacity> */}
        </View>
      </View>
    );
  }
}
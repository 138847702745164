
import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  Animated,
  Dimensions,
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";

import * as Find from "../Home/Find/findFunctions"
const gStyles = global.gStyles //    "../styles/gStyles";
import Post from "./Post/Post";
import distance from "../shared/distances";
import Moment from "react-moment";
import LocationPopup from "../shared/LocationPopup";



export default class HomeResultsScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    return {
      tabBarVisible: false,
      headerTransparent: true,
      title: navigation.getParam("title") || "Results",
      headerTitleStyle: {
        fontWeight: "bold",
        color: "#A8CF45",
      }
    }
  };
  state = {
    postResults: [],
    nOfResultsShowing: 15,
    showDetail: false,
    detailPostResult: null,
    loading: true,
    error: null,
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {

    try {
      const type = this.props.navigation.getParam("type", null);
      const searchTerm = this.props.navigation.getParam("searchTerm", null);
      if (!global.myUID || !global.myProfileID) this.setState({ error: "Loading Results" });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 200); }); if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 400); }); if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 500); }); if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 500); }); if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 600); }); if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 1000); }); if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 2000); }); if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 4000); }); if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 5000); }); if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 8000); }); if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 10000); });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 10000); }); if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 100000); });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID) return;
      this.setState({ error: null });

      if (type == "HOT_NEAR_ME") {
        this.props.navigation.setParams({ title: "Hot right now" })
        const re = await Find.findOffersAsync({
          searchTerm: "Fashion",
          location: global.location || { coords: { latitude: 6.6, longitude: 3.3 } },
          amount: 20,
        })
        if (re == -1) return this.setState({ error: "Error loading offers." })
        if (re.length == 0) return this.setState({ error: "No offers found." })
        if (re.length > 0) this.setState({ postResults: re, loading: false })
        if (re.length >= 20) {
          const re = await Find.findOffersAsync({
            searchTerm: "Fashion",
            location: global.location || { coords: { latitude: 6.6, longitude: 3.3 } },
            amount: 150,
          })
          if (re?.length > 0) this.setState({ postResults: re, loading: false })
        }
      }
      if (type == "MY_CONTACTS") {
        this.props.navigation.setParams({ title: "Offers by my contacts" })
        const re = Find.findOffersOffline({
          searchTerm: "",
          location: global.location || { coords: { latitude: 6.6, longitude: 3.3 } },
          amount: 120,
        })
        if (re == -1) return this.setState({ error: "Error loading offers." })
        if (re.length == 0) return this.setState({ error: "No offers found." })
        if (re.length > 0) this.setState({ postResults: re, loading: false })
      }
      if (type == "DEFAULT") {
        this.props.navigation.setParams({ title: "Hot Offers" })
        const re = await Find.findOffersAsync({
          searchTerm: searchTerm || "Fashion",
          location: global.location || { coords: { latitude: 6.6, longitude: 3.3 } },
          amount: 20,
        })
        if (re == -1) return this.setState({ error: "Error loading offers." })
        if (re.length == 0) return this.setState({ error: "No offers found." })
        if (re.length > 0) this.setState({ postResults: re, loading: false })
      }

      if (type == "NEW_AND_TRENDING") {
        this.props.navigation.setParams({ title: "New and trending" })
        const re = await Find.findPostOffersAsync({
          orderBy: "RECENT",
          location: global.location || { coords: { latitude: 6.6, longitude: 3.3 } },
          amount: 80,
        })
        // console.log("NEW_AND_TRENDING", re)
        if (re == -1) return this.setState({ error: "Error loading offers." })
        if (re.length == 0) return this.setState({ error: "No offers found." })
        if (re.length > 0) this.setState({ postResults: re, loading: false })
      }

      if (type == "HOTTEST_EVER") {
        this.props.navigation.setParams({ title: "All time Hottest" })
        const re = await Find.findPostOffersAsync({
          orderBy: "MOSTEVERLIKED",
          location: global.location || { coords: { latitude: 6.6, longitude: 3.3 } },
          amount: 100,
        })
        // console.log("NEW_AND_TRENDING", re)
        if (re == -1) return this.setState({ error: "Error loading offers." })
        if (re.length == 0) return this.setState({ error: "No offers found." })
        if (re.length > 0) this.setState({ postResults: re, loading: false })
      }

    } catch (err) {
      console.log(err)
      this.setState({ error: "Error loading page. Check internet connection and try again." })
      console.warn(err)
      if (global.reportError) {
        global.reportError(err, "catches", "homeResults");
      }
    }
  }

  componentWillUnmount() {
    console.log("Component homeResults will unmount");
    this.unsubscribeFirestore();
  }

  renderPostResult = (result, isLast) => {

    let B = props => <Text style={{ fontWeight: "bold" }}>{props.children}</Text>; //just for reference (delete?)

    const type = this.props.navigation.getParam("type", null);

    let milliseconds = 0
    let timeCreated = 0
    if (type == "NEW_AND_TRENDING" && result.timeCreated) {
      milliseconds = result.timeCreated._seconds ? result.timeCreated._seconds * 1000 + result.timeCreated._nanoseconds / 1000000 : result.timeCreated.seconds * 1000 + result.timeCreated.nanoseconds / 1000000
      timeCreated = new Date(milliseconds)
    }

    let pString = Math.round(result.p).toString();
    if (pString.length > 8) {
      pString = pString.slice(0, pString.length - 6) + "M";
    } else if (pString.length > 6) {
      pString = Number(pString.slice(0, pString.length - 5)) / 10;
      pString = pString.toString() + "M";
    } else if (pString.length > 4) {
      pString = pString.slice(0, pString.length - 3) + "K";
    }

    let pCurrency = result.pc || "NGN";
    let pCurrencyString = global.currencySymbols[pCurrency]

    let priceString = pCurrencyString + " " + pString;

    //localString is acnString
    let localString = ""
    // xx possibly remove NGN from last section of the following line  to show less 
    // xx possibly replace last section of the following line with global.xRates[post.postPriceCurrency||"NGN"] to show ACN for all (also old) NGN posts 
    if (global.walletType == "ACN" && !(result.pc == "ACN") && result.pt != "Free" && result.p > 0 && global.xRates[result.pc || "NGN"]) {
      const lPrice = Math.round(result.p / global.xRates[result.pc || "NGN"])
      const lCurrency = global.currencySymbols["ACN"]
      let lString = Math.round(lPrice).toLocaleString(0);
      localString = "≈ " + lCurrency + " " + lString
    }
    let beforeString = ""
    let afterString = ""
    if (result.pt && result.pt != "Fixed Price") {
      if (result.pt == "Starting From") {
        beforeString = "Starting From"
      } else {
        afterString = "(" + result.pt + ")";
      }
      if (result.pt == "Free") {
        priceString = "FREE";
        localString = ""
        afterString = ""
      }
      if (result.pt == "NONE") {
        priceString = "";
        localString = ""
        afterString = ""
      }
    }
    return (
      <TouchableOpacity
        onPress={() => {
          this.setState({ showDetail: true, detailPostResult: result })
        }}
        key={"P" + result.xID}
        style={{ flexDirection: "row", height: 100, paddingVertical: 2, borderBottomWidth: isLast ? 0 : 0.5, borderBottomColor: "#606072", alignItems: "center", alignSelf: "stretch", justifyContent: "space-between" }}>
        <View style={{ width: 85, marginRight: 8 }}>
          {!!result.url && (<View>
            <Image
              style={{
                width: 85,
                height: 85,
                paddingRight: 8,
                borderRadius: 8.5
              }}
              source={{
                uri: result.url
              }}
            />
            {!!result.authorURL && <Image
              style={{
                marginTop: -84,
                width: 30,
                height: 30,
                marginLeft: 1,
                marginBottom: 54,
                borderRadius: 15,
                borderWidth: 1,
                borderColor: "#ccd",
              }}
              source={{ uri: result.authorURL }}
            />}

          </View>
          )}
        </View>
        <View
          style={{
            flex: 1,
            height: 100,
            justifyContent: "space-between",
            paddingHorizontal: 8
          }}
        >
          <View style={{ marginTop: 8 }}>
            <Text numberOfLines={2} style={[{ fontSize: 13, letterSpacing: 0.36, color: "#fff", fontWeight: "500" }]}>
              {result.t}
            </Text>
          </View>
          <View style={{ marginBottom: 8 }}>
            {beforeString != "" && <Text style={[{ fontSize: 10, color: "#ccccd5" }]}>
              {beforeString}
            </Text>}
            <Text style={[{
              fontSize: 16,
              fontWeight: "bold",
              letterSpacing: 0.48,
              textAlign: "left",
              color: "#A8CF45",
              opacity: 0.8
            }]}>
              {priceString}
            </Text>
            {localString != "" && <Text style={[{
              fontSize: 12,
              fontWeight: "bold",
              letterSpacing: 0.48,
              textAlign: "left",
              color: "#A8CF45",
              opacity: 0.8
            }]}>
              {localString}
            </Text>}
            <Text style={[{ fontSize: 10, fontWeight: "500", color: "#ccccd5" }]}>
              {afterString}
            </Text>
          </View>
        </View>
        <View
          style={{ width: 80, alignItems: "flex-end", justifyContent: "center" }}
        >
          {!!timeCreated && (
            <View style={{ justifyContent: "flex-end", alignItems: "center", flexDirection: "row", marginBottom: 50 }} >
              <Text style={{ fontSize: 10, color: "#aaaab3", fontWeight: "500" }}>
                <Moment fromNow element={Text}>
                  {timeCreated.getTime()}
                </Moment>
              </Text>
            </View>)}
          {!!global.location && result.loc && result.loc._latitude != 6.6 && (
            <View
              style={{ justifyContent: "flex-end", alignItems: "center", flexDirection: "row" }}
            >
              <Image
                style={{ width: 15, height: 16.5, marginRight: 4, tintColor: "#aaaab3" }}
                source={require("../images/icons/Location.png")}
              />
              <Text style={{ fontSize: 10, color: "#aaaab3", fontWeight: "500" }}>
                {Math.round(distance(result.loc._latitude, result.loc._longitude, global.location.coords.latitude, global.location.coords.longitude, "K"
                ) * 1) / 1 + " km"}
              </Text>
            </View>)}
        </View>
      </TouchableOpacity>
    )
  }



  render() {
    const type = this.props.navigation.getParam("type", null);

    if (this.state.error) return (
      <View style={{ height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: "#000" }}>
        <Text style={{ color: "#889" }}>{this.state.error}</Text>
      </View >
    );
    if (this.state.loading) return (
      <View style={{ height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: "#000" }}>
        {type == "NEW_AND_TRENDING" && <Image
          style={{ width: 220, height: 220, opacity: 0.5, marginBottom: 15 }}
          source={require("../images/homePageIcons/imagesforwakandamarketrevamphometiles/order-completed-5.png")}
        />}
        {type == "HOT_NEAR_ME" && <Image
          style={{ width: 220, height: 220, opacity: 0.5, marginBottom: 15 }}
          source={require("../images/homePageIcons/imagesforwakandamarketrevamphometiles/clip-1.png")}
        />}
        {type == "HOTTEST_EVER" && <FadeInView><Image
          style={{ width: 330, height: 330, opacity: 0.5, marginBottom: 15 }}
          source={require("../images/homePageIcons/imagesforwakandamarketrevamphometiles/clip-1.png")}
        /></FadeInView>}
        <ActivityIndicator size="large" color="#889" />
      </View>
    );
    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: "#131313"
        }}
      >
        <StatusBar barStyle="light-content" />

        <View
          style={{
            backgroundColor: "#131313",
            flex: 1
          }}
        >
          <View
            style={{
              backgroundColor: "#131313",
              borderBottomColor: "#555",
              borderBottomWidth: 1,
              ...Platform.select({
                ios: {
                  height: 55
                },
                android: {
                  height: 75
                }
              })
            }}
          />
          <ScrollView
            scrollEventThrottle={1000}
            onScroll={(e) => {
              //images of bottom items should only be loaded after a while
              if (e.nativeEvent.contentOffset.y >= 1200 && this.state.nOfResultsShowing < 999) {
                this.setState({ nOfResultsShowing: 999 })
              } else if (e.nativeEvent.contentOffset.y >= 800 && this.state.nOfResultsShowing < 50) {
                this.setState({ nOfResultsShowing: 50 })
              } else if (e.nativeEvent.contentOffset.y >= 100 && this.state.nOfResultsShowing < 30) {
                this.setState({ nOfResultsShowing: 30 })
              }
            }}>
            {this.state.postResults.length > 0 &&
              <View
                style={{ backgroundColor: "#131318", borderRadius: 10, paddingHorizontal: 6, margin: 10, marginBottom: 0, alignItems: "center", alignSelf: "stretch" }}>
                {this.state.postResults.map((result, i) => {
                  if (i > this.state.nOfResultsShowing) return <View key={"noshow" + result.xID} />
                  return this.renderPostResult(result, i == this.state.postResults.length - 1)
                })}
              </View>}
            <View style={{ height: 200 }} />
          </ScrollView>
          {
            this.state.showDetail && (
              <Post
                postID={this.state.detailPostResult.xID}
                postResult={this.state.detailPostResult}
                //data={this.props.data}
                key={this.state.detailPostResult.xID + this.state.showDetail}
                navigation={this.props.navigation}
                showDetail={this.state.showDetail}
                onHideDetail={() => {
                  this.setState({ showDetail: false });
                }}
                relevantStatus={"LIVE"}
                onIsNotRelevant={() => { alert("Offer not available at the moment") }}
              />
            )
          }
        </View>
        <LocationPopup
          msDelay={100}
          //showAlwaysOnce={true}
          onNewLocation={async () => {
            await this.setState({
              postResults: [],
              nOfResultsShowing: 15,
              showDetail: false,
              detailPostResult: null,
              loading: true,
              error: null,
            })
            this.componentDidMount()
          }} />
      </SafeAreaView>
    );
  }
}

class FadeInView extends Component {
  state = {
    opacity: new Animated.Value(0),
  }

  componentDidMount = () => {
    Animated.timing(this.state.opacity, {
      toValue: 1,
      duration: this.props.duration || 2000,
      useNativeDriver: true,
    }).start();
  }

  render() {
    return (
      <Animated.View
        onLoad={this.onLoad}
        {...this.props}
        style={[
          {
            opacity: this.state.opacity,
            transform: [
              {
                scale: this.state.opacity.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0.85, 1],
                })
              },
            ],
          },
          this.props.style,
        ]}
      />
    );
  }
}
import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  SafeAreaView,
  Image,
  StatusBar,
  TouchableOpacity,
  TextInput,
  Platform,
  ActivityIndicator,
  Linking,
  Alert,
  Dimensions,
  Modal,
  ScrollView,
} from "react-native";
import * as Permissions from 'expo-permissions';
import { LinearGradient } from 'expo-linear-gradient';
import firebase from "firebase";
import "firebase/firestore";
const gStyles = global.gStyles //    "../../styles/gStyles";
import GetPicture from "../../shared/Camera/GetPicture";
import CachedImage from "../../shared/CachedImage"
import PushNotificationHandler from "../../Messages/PushNotificationHandler"


class CheckboxSelector extends PureComponent {
  render() {
    let height =
      this.props.text.length < 45
        ? 30
        : 45 + (this.props.text.length - 45) * 0.33;
    let circle = filled => {
      if (filled) {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#A8CF45", backgroundColor: "#334", borderWidth: 0, borderRadius: 10 }}>
            <Text style={{ fontSize: 24, marginTop: -5, color: "#A8CF45" }}>✓</Text>
          </View>
        );
      } else {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#A8CF45", backgroundColor: "#334", borderWidth: 0, borderRadius: 10 }} />
        );
      }
    };

    return (
      <View style={{ height: height, flexDirection: "row", alignItems: "center" }}>
        {circle(this.props.selected)}
        <Text style={{ marginLeft: 10, fontSize: 16, color: "#A8CF45", fontWeight: this.props.selected ? "bold" : "bold" }}>{this.props.text}</Text>
      </View>
    );
  }
}

class Selector2 extends PureComponent {
  state = {};

  render() {
    return (
      <View style={{ flexDirection: "row", height: 55, justifyContent: "center", alignItems: "center", borderBottomWidth: 1, borderColor: "#333", paddingBottom: 8, }}     >
        <TouchableOpacity onPress={() => this.props.onPress("Work")}>
          <View
            style={{ height: 30, width: 130, margin: 5, borderRadius: 20, borderTopEndRadius: 0, borderBottomEndRadius: 0, borderColor: "#aaa", borderWidth: 1, backgroundColor: this.props.view == "Work" ? "#aaa" : "#131313", justifyContent: "center", alignItems: "center" }}
          >
            <Text
              style={{ color: this.props.view == "Work" ? "#000" : "#eee" }}
            >
              Work
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this.props.onPress("Generic")}>
          <View
            style={{
              backgroundColor: this.props.view == "Generic" ? "#aaa" : "#131313", height: 30, width: 140, margin: 5, borderTopEndRadius: 20, borderBottomEndRadius: 20, borderColor: "#aaa", borderWidth: 1, justifyContent: "center", alignItems: "center"
            }}
          >
            <Text
              style={{ color: this.props.view == "Generic" ? "#000" : "#eee" }}
            >
              Generic
            </Text>
          </View>
        </TouchableOpacity>
      </View>);
  }
}
class Selector3 extends PureComponent {
  state = {};

  render() {
    return (
      <View style={{ flexDirection: "row", height: 55, justifyContent: "center", alignItems: "center", borderBottomWidth: 1, borderColor: "#333", paddingBottom: 8, }}     >
        <TouchableOpacity onPress={() => this.props.onPress("Work")}>
          <View
            style={{ height: 30, width: 90, margin: 5, borderRadius: 20, borderTopEndRadius: 0, borderBottomEndRadius: 0, borderColor: "#aaa", borderWidth: 1, backgroundColor: this.props.view == "Work" ? "#aaa" : "#131313", justifyContent: "center", alignItems: "center" }}
          >
            <Text
              style={{ color: this.props.view == "Work" ? "#000" : "#eee" }}
            >
              Work
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this.props.onPress("Rave")}>
          <View
            style={{ height: 30, width: 90, margin: 5, borderColor: "#aaa", borderWidth: 1, backgroundColor: this.props.view == "Rave" ? "#aaa" : "#131313", justifyContent: "center", alignItems: "center" }}
          >
            <Text
              style={{ color: this.props.view == "Rave" ? "#000" : "#eee" }}
            >
              Rave
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this.props.onPress("Generic")}>
          <View
            style={{
              backgroundColor: this.props.view == "Generic" ? "#aaa" : "#131313", height: 30, width: 90, margin: 5, borderTopEndRadius: 20, borderBottomEndRadius: 20, borderColor: "#aaa", borderWidth: 1, justifyContent: "center", alignItems: "center"
            }}
          >
            <Text
              style={{ color: this.props.view == "Generic" ? "#000" : "#eee" }}
            >
              Generic
            </Text>
          </View>
        </TouchableOpacity>
      </View>);
  }
}

class MyPost extends Component {
  state = { image: "", visible: false };

  async componentDidMount() {
    try {
      const postDoc = await firebase.firestore().collection("Posts").doc(this.props.postID).get()
      const post = postDoc.data()
      if (post.systemStatus == "LIVE" || post.systemStatus == "PAUSED") {
        this.setState({ image: post.postPictureURL, visible: true })
      }
    } catch (err) {
      console.log(err)
    }
  }

  render() {

    if (!this.state.visible) return (
      <View style={{ height: 0, opacity: 0 }} />)
    return (
      <View style={{ width: "100%", marginVertical: 5, alignItems: "center", width: this.props.width, height: this.props.height, borderRadius: this.props.width / 2 }}>
        <TouchableOpacity onPress={() => { if (this.state.visible) this.props.onPress(this.state.image) }}>
          <Image style={{ width: this.props.width, height: this.props.height, borderRadius: this.props.width / 2 }} source={{ uri: this.state.image }} />
        </TouchableOpacity>
      </View>)
  }
}

export default class SetupScreen extends Component {

  state = {
    loading: true,
    modalVisible: false,
    shop: null,
    showGetPicture: false,
    referrerDiscount: 15,
    referrerCommission: 10,
    activityIndicatorAnimating: false,
    aaRedirect: false,

    activeModal: "",
    newSlogan: "",
    newHeader: "",
    newPictureURL: "",
    newSocials: { facebook: "", linkedIn: "", twitter: "", instagram: "" },
    newCartBankAccount: { number: "", name: "", bankName: "" },
    newCartStatus: "OFF",
    newChat: { status: "OFF" }, //can be LIVE
    newBlog: { status: "OFF" }, //can be LIVE
    newColor: "",
    newGradientColors: "",
    newColorType: "",
    newShopType: "",

    myPostIDs: [],
    myDescription: "-",
    pictures: [
      "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/Web%2Fg2.jpg?alt=media&token=b1ad135e-7f8a-45d7-93a2-bc7e11dddf54",
      "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/Web%2Fb2.jpg?alt=media&token=006f7aec-4fbf-49ac-ae25-00a88da3c6e3"
    ],
    selectorSelection: "Rave",
    selectorSelectionPromo: "Work"
  }

  picturesWork = [
    "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/Web%2Fg2.jpg?alt=media&token=b1ad135e-7f8a-45d7-93a2-bc7e11dddf54",
  ]
  picturesGeneric = [
    "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/Web%2Fb2.jpg?alt=media&token=006f7aec-4fbf-49ac-ae25-00a88da3c6e3"
  ]
  picturesRave = [
    "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/Web%2Fb2.jpg?alt=media&token=006f7aec-4fbf-49ac-ae25-00a88da3c6e3"
  ]
  picturesPromoWork = [
    "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/Web%2Fb2.jpg?alt=media&token=006f7aec-4fbf-49ac-ae25-00a88da3c6e3"
  ]
  picturesPromoGeneric = [
    "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/Web%2Fb2.jpg?alt=media&token=006f7aec-4fbf-49ac-ae25-00a88da3c6e3"
  ]
  shopTypes = []
  knownShopTypeNames = ["Standard", "Standard White", "Shoppers", "Rave", "Electro", "Teacher", "Modaz", "Engineer", "Cake Factory"] // to check if update is required

  constructor() {
    super();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    try {
      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Shops")
        .doc(this.props.shopID)
        .onSnapshot(this.updateShop);

      const w1 = firebase
        .firestore()
        .collection("ServerClientReadOnly")
        .doc("charges")
        .get()
      const w2 = firebase
        .firestore()
        .collection("Profiles")
        .doc(global.myProfileID)
        .get()

      const doc = await firebase
        .firestore()
        .collection("ServerClientReadOnly")
        .doc("shopInfo")
        .get()
      this.picturesWork = doc.data().backgroundPictures || []
      this.picturesGeneric = doc.data().backgroundPicturesGeneral || []
      this.picturesRave = doc.data().backgroundPicturesRave || []
      this.picturesPromoWork = doc.data().promoPictures || []
      this.picturesPromoGeneric = doc.data().promoPicturesGeneral || []
      this.shopTypes = doc.data().shopTypes || []
      this.setState({ pictures: doc.data().backgroundPicturesRave, frontPictures: doc.data().frontPictures, promoPictures: doc.data().promoPictures })

      const chargesDoc = await w1
      const charges = chargesDoc.data()
      this.setState({ referrerDiscount: 100 * charges.referralDiscountPercentageStandard })
      this.setState({ referrerCommission: 100 * charges.referralCommissionPercentageStandard })

      const profileDoc = await w2
      const profile = profileDoc.data()
      this.setState({ myPostIDs: profile.postIDs, myDescription: profile.profileDescription || "-" })


    } catch (e) {
      console.log("Error", e)
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  updateShop = async snapshot => {
    const shop = snapshot.data()
    if (!shop.shopSocials) shop.shopSocials = {}
    if (!shop.shopCartBankAccount) shop.shopCartBankAccount = {}
    if (!shop.shopChat) shop.shopChat = { status: "OFF" }
    if (!shop.shopBlog) shop.shopBlog = { status: "OFF" }

    const renDat = shop.renewalDate.toDate()
    const nowDat = new Date()
    if (nowDat > renDat) {
      this.props.navigation.goBack()
      await global.timeout(300)
      this.props.navigation.navigate("meWebsite")
      await global.timeout(300)
      alert("Your website has expired, renew now!")
    }

    this.setState({ shop, loading: false })
  };

  submitChanges = async () => {
    try {

      let changes = {}
      if (this.state.activeModal == "promo") changes.shopPromoIsActive = !this.state.shop.shopPromoIsActive || false;
      if (this.state.activeModal == "promoTitle") changes.shopPromoTitle = this.state.newPromoTitle
      if (this.state.activeModal == "promoText") changes.shopPromoText = this.state.newPromoText
      if (this.state.activeModal == "frontPicture") changes.shopFrontPicture = this.state.newPictureURL
      if (this.state.activeModal == "logoPicture") changes.shopLogoPicture = this.state.newPictureURL
      if (this.state.activeModal == "promoPicture") changes.shopPromoPicture = this.state.newPictureURL
      if (this.state.activeModal == "blog") changes.shopBlog = this.state.newBlog
      if (this.state.activeModal == "slogan") {
        if (this.state.activeSection == 0 || this.state.activeSection == 1) changes.shopSlogan = this.state.newSlogan
        else changes["shopSlogan" + this.state.activeSection] = this.state.newSlogan
      }
      if (this.state.activeModal == "header") {
        if (this.state.activeSection == 0 || this.state.activeSection == 1) changes.shopHeader = this.state.newHeader
        else changes["shopHeader" + this.state.activeSection] = this.state.newHeader
      }
      if (this.state.activeModal == "backgroundPicture") {
        if (this.state.activeSection == 0 || this.state.activeSection == 1) changes.shopBackgroundPicture = this.state.newPictureURL
        else changes["shopBackgroundPicture" + this.state.activeSection] = this.state.newPictureURL
      }
      if (this.state.activeModal == "chat") {
        changes.shopChat = this.state.newChat
        if (this.state.newChat.status == "LIVE") {
          const { status } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
          if (status != "granted") return alert("You need to enable Push Notifications to use Live Chat")
        }
      }
      if (this.state.activeModal == "social") {
        changes.shopSocials = global.duplicate(this.state.newSocials)
        if (changes.shopSocials.instagram && changes.shopSocials.instagram.startsWith("@")) changes.shopSocials.instagram = "https://www.instagram.com/" + changes.shopSocials.instagram.substring(1, 999)
        else if (changes.shopSocials.instagram && !changes.shopSocials.instagram.includes(".")) {
          return alert("Instagram must be either a link or a handle (@)");
        }

        if (changes.shopSocials.twitter && changes.shopSocials.twitter.startsWith("@")) changes.shopSocials.twitter = "https://www.twitter.com/" + changes.shopSocials.twitter.substring(1, 999)
        else if (changes.shopSocials.twitter && !changes.shopSocials.twitter.includes(".")) {
          return alert("Twitter must be either a link or a handle (@)");
        }
      }
      if (this.state.activeModal == "cart") {
        changes.shopCartBankAccount = this.state.newCartBankAccount
        changes.shopCartStatus = this.state.newCartStatus
        if (this.state.newCartStatus == "LIVE" && (!this.state.newCartBankAccount.name || !this.state.newCartBankAccount.bankName || !this.state.newCartBankAccount.number)) {
          return alert("Enter all bank details or disable cart")
        }
      }
      if (this.state.activeModal == "shopType") {
        if (this.state.newShopType == "Shoppers") {
          changes.shopColorBackground = "#DB2543";
          changes.shopColorHeader = "#ffffff";
          changes.shopColorButton = "#7a73ea";
          changes.shopColorTitle = "#fbcb50";
        }
        if (this.state.newShopType == "Standard") {
          changes.shopColorBackground = "rgb(0,168,89)";
          changes.shopColorHeader = "#ffffff";
          changes.shopColorButton = "#efb31f";
          changes.shopColorTitle = "rgb(136,136,136)";
        }
        if (this.state.newShopType == "Standard White") {
          changes.shopColorBackground = "#CE0930";
          changes.shopColorHeader = "#ffffff";
          changes.shopColorButton = "#efb31f";
          changes.shopColorTitle = "rgb(136,136,136)";
        }
        if (this.state.newShopType == "Teacher") {
          changes.shopColorBackground = "#4AD6B7";
          changes.shopColorButton = "#0F79E5";
          changes.shopColorGradient1 = "#0F219E";
          changes.shopColorGradient2 = "#02FF95";
        }
        if (this.state.newShopType == "Rave") {
          changes.shopColorBackground = "rgba(12, 0, 255, 0.8)";
          changes.shopColorButton = "#efb31f";
          changes.shopColorGradient1 = "rgba(12, 0, 255, 0.8)";
          changes.shopColorGradient2 = "rgba(250, 16, 83, 0.8)";
        }
        if (this.state.newShopType == "Electro") {
          changes.shopColorBackground = "rgb(206,9,48)";
          changes.shopColorHeader = "#ffffff";
        }
        if (this.state.newShopType == "Engineer") {
          changes.shopColorBackground = "#00F";
          changes.shopColorHeader = "#00F";
          if (!this.state.shop.shopBackgroundPicture2) changes.shopBackgroundPicture2 = "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/Web%2Fengineer2.jpg?alt=media&token=61d30e67-60ba-4306-8110-aa9ad68b36f7"
          if (!this.state.shop.shopBackgroundPicture3) changes.shopBackgroundPicture3 = "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/Web%2Fengineer2.jpg?alt=media&token=61d30e67-60ba-4306-8110-aa9ad68b36f7"
          if (!this.state.shop.shopHeader2) changes.shopHeader2 = "About Us"
          if (!this.state.shop.shopSlogan2) changes.shopSlogan2 = this.state.myDescription || "-"
          if (!this.state.shop.shopHeader3) changes.shopHeader3 = "Our Services"
          if (!this.state.shop.shopSlogan3) changes.shopSlogan3 = this.state.myDescription || "-"
        }
        if (this.state.newShopType == "Cake Factory") {
          changes.shopColorHeader = "#F96FB9";
          if (!this.state.shop.shopBackgroundPicture2) changes.shopBackgroundPicture2 = "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/Web%2Fcake-rasp.jpeg?alt=media&token=294d3cb6-bcc9-457a-a01f-6780b8cfbfa7"
          if (!this.state.shop.shopHeader2) changes.shopHeader2 = this.state.shop.shopHeader || "Life is too short to say No to Cake"
          if (!this.state.shop.shopSlogan2) changes.shopSlogan2 = this.state.myDescription || "-"
        }
        changes.shopType = this.state.newShopType
      }
      if (this.state.activeModal == "colors") {
        if (this.state.newColorType == "background") changes.shopColorBackground = this.state.newColor
        if (this.state.newColorType == "button") changes.shopColorButton = this.state.newColor
        if (this.state.newColorType == "title") changes.shopColorTitle = this.state.newColor
        if (this.state.newColorType == "header") changes.shopColorHeader = this.state.newColor
        if (this.state.newColorType == "gradient") changes.shopColorGradient1 = this.state.newGradientColors.a
        if (this.state.newColorType == "gradient") changes.shopColorGradient2 = this.state.newGradientColors.b
        if (this.state.newColorType == "reset") {
          if (this.state.shop.shopType == "Shoppers") {
            changes.shopColorBackground = "#DB2543";
            changes.shopColorHeader = "#ffffff";
            changes.shopColorButton = "#7a73ea";
            changes.shopColorTitle = "#fbcb50";
          } else if (this.state.shop.shopType == "Rave") {
            changes.shopColorBackground = "rgba(12, 0, 255, 0.8)";
            changes.shopColorButton = "#efb31f";
            changes.shopColorGradient1 = "rgba(12, 0, 255, 0.8)";
            changes.shopColorGradient2 = "rgba(250, 16, 83, 0.8)";
          } else if (this.state.shop.shopType == "Engineer") {
            changes.shopColorBackground = "#00F";
            changes.shopColorHeader = "#00F";
          } else if (this.state.shop.shopType == "Cake Factory") {
            changes.shopColorHeader = "#F96FB9";
          } else if (this.state.shop.shopType == "Teacher") {
            changes.shopColorBackground = "#4AD6B7";
            changes.shopColorButton = "#0F79E5";
            changes.shopColorGradient1 = "#0F219E";
            changes.shopColorGradient2 = "#02FF95";
          } else if (this.state.shop.shopType == "Electro") {
            changes.shopColorBackground = "rgb(206,9,48)";
            changes.shopColorHeader = "#ffffff";
          } else if (this.state.shop.shopType == "Standard White") {
            changes.shopColorBackground = "#CE0930";
            changes.shopColorHeader = "#ffffff";
            changes.shopColorButton = "#efb31f";
            changes.shopColorTitle = "rgb(136,136,136)";
          } else {
            changes.shopColorBackground = "rgb(0,168,89)";
            changes.shopColorHeader = "#ffffff";
            changes.shopColorButton = "#efb31f";
            changes.shopColorTitle = "rgb(136,136,136)";
          }
        }
      }

      this.setState({ activityIndicatorAnimating: true });
      const r = await fetch(
        global.cloudFunctionURL + "updateShop",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ isWeb:true, myUID: global.myUID, idToken: global.idToken, changes })
        }
      );
      const r2 = await r.json();

      console.log("Received response. ", r2);
      this.setState({ activityIndicatorAnimating: false });
      await global.timeout(100)
      if (r2.msg === "SUCCESS") {
        this.setState({ modalVisible: false });
        await global.timeout(1200)
        alert("Website updated")
      } else alert("Error. Try again")
    } catch (err) {
      console.log("Error_", err);
      this.setState({ activityIndicatorAnimating: false });
    }
  }

  redirectToAdmin = async (link) => {
    if (this.state.aaRedirect) return;
    try {
      this.setState({ aaRedirect: true });
      const r = await fetch(
        global.cloudFunctionURL + "shopAdminSignin",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ isWeb:true, calledFromApp: true, myUID: global.myUID, idToken: global.idToken })
        }
      );
      const r2 = await r.json();

      console.log("Received shopAdminSignin response. ", r2);
      this.setState({ aaRedirect: false });
      await global.timeout(100)
      if (r2.msg === "SUCCESS") {
        Linking.openURL(link + "?code=" + r2.code)
      } else alert("Error. Try again...")
    } catch (err) {
      console.log("Error_", err);
      this.setState({ aaRedirect: false });
    }
  }


  renderMyDomain = () => {
    const shop = this.state.shop
    let renewalDateString = ""
    let renewalDateStringPlus1Year = ""
    const nowDat = new Date()
    let renDat = null
    if (shop.renewalDate) {
      renDat = new Date(shop.renewalDate.toDate());
      renDat.setDate(renDat.getDate() - 5);
      renewalDateString = renDat.toDateString();
      renDat1 = new Date(shop.renewalDate.toDate());
      renDat1.setDate(renDat1.getDate() + 365);
      renewalDateStringPlus1Year = renDat1.toDateString();
    }
    const upForRenewal = (renDat - nowDat) < 2592000000 // 30 days
    const tldStatusText = shop.tldStatus == "OPEN" ? "Setup in Progress" : shop.tldStatus == "LIVE" ? "✅ Live" : "Status: " + shop.tldStatus
    let wmd = "https://www.wakanda-market.com/" + this.props.shopID
    if (shop.shopCategory == "education") wmd = "https://www.wakanda-education.com/" + this.props.shopID
    const wmdStatusText = shop.status == "LIVE" ? "✅ Live" : "Status: " + shop.status
    return (<View>
      {shop.tldEnabled && <TouchableOpacity onPress={() => { Linking.openURL("http://www." + this.state.shop.tld) }}>
        <View style={{ flexDirection: "row", alignSelf: "center", width: "100%", backgroundColor: "#000", margin: 10, borderWidth: .6, borderColor: "#444", padding: 5 }} >
          <View style={{ alignSelf: "center", width: "94%", justifyContent: "space-between" }} >
            <View style={{ width: "94%" }}>
              <Text style={{ color: "#888", fontSize: 12 }}>MY DOMAIN</Text>
              <Text style={{ color: "white", fontSize: 16 }}>{shop.tld}</Text>
              <Text style={{ color: "#888", fontSize: 12 }}>{tldStatusText}</Text>
            </View>
          </View>
          <View style={{ alignSelf: "center", width: "6%", justifyContent: "center" }} >
            <Text style={{ color: "white", fontSize: 20, fontWeight: "bold", color: "#444", marginBottom: 2 }}>{">"}</Text>
          </View>
        </View>
      </TouchableOpacity>}
      <TouchableOpacity onPress={() => { Linking.openURL(wmd) }}>
        <View style={{ flexDirection: "row", alignSelf: "center", width: "100%", backgroundColor: "#000", margin: 10, borderWidth: .6, borderColor: "#444", padding: 5 }} >
          <View style={{ alignSelf: "center", width: "94%", justifyContent: "space-between" }} >
            <View style={{ width: "94%" }}>
              <Text style={{ color: "#888", fontSize: 12 }}>MY WAKANDA-MARKET DOMAIN</Text>
              <Text style={{ color: "white", fontSize: 16 }}>{wmd}</Text>
              <Text style={{ color: "#888", fontSize: 12 }}>{wmdStatusText}</Text>
            </View>
          </View>
          <View style={{ alignSelf: "center", width: "6%", justifyContent: "center" }} >
            <Text style={{ color: "white", fontSize: 20, fontWeight: "bold", color: "#444", marginBottom: 2 }}>{">"}</Text>
          </View>
        </View>
      </TouchableOpacity>
      {!!upForRenewal && <TouchableOpacity onPress={() => { this.props.navigation.navigate("meWebsite", { isRenewal: true, renewalPeriodStr: "until " + renewalDateStringPlus1Year }) }}>
        <View style={{ flexDirection: "row", justifyContent: "space-between", alignSelf: "center", width: "100%", margin: 5 }} >
          <View style={{ flex: 7, alignItems: "flex-start", paddingRight: 10 }}>
            <Text style={{ color: "#c11", fontSize: 15, fontWeight: "bold" }}>Your website is up for renewal</Text>
            <Text style={{ color: "#c11", fontSize: 15, fontWeight: "bold" }}>by {renewalDateString}!</Text>
          </View>
          <View style={{ alignSelf: "center", flex: 2.5, justifyContent: "center", alignItems: "flex-end" }} >
            <View style={global.gStyles.buttonX}>
              <Text style={global.gStyles.buttontX}>RENEW NOW</Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>}
    </View>);
  }

  renderHeader = () => {
    return (<View>
      <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 10, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 20, justifyContent: "flex-start" }} >
        My Header</Text>
      <TextInput
        onChangeText={newHeader => { this.setState({ newHeader }); }}
        multiline={true}
        numberOfLines={2}
        style={{ marginBottom: 20, color: "#ffffff", paddingLeft: 3, borderRadius: 5, height: 60, backgroundColor: "#334" }}
        maxLength={60}
        underlineColorAndroid="transparent"
        defaultValue={this.state.newHeader}
        returnKeyType={"done"}
        rowSpan={2}
        bordered
      />
    </View>)
  }

  renderSlogan = () => {
    const isSlogan = ((this.state.activeSection == 1 || this.state.activeSection == 0) && this.state.shop.shopType != "Cake Factory")
    return (<View>
      <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 10, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 20, justifyContent: "flex-start" }}>
        {!!isSlogan ? "My Slogan" : "Text Section " + this.state.activeSection}</Text>
      <TextInput
        onChangeText={newSlogan => { this.setState({ newSlogan }); }}
        multiline={true}
        numberOfLines={!!isSlogan ? 2 : 4}
        style={{ marginBottom: 20, color: "#ffffff", paddingLeft: 3, borderRadius: 5, height: isSlogan ? 60 : 120, backgroundColor: "#334" }}
        maxLength={!!isSlogan ? 60 : 600}
        underlineColorAndroid="transparent"
        defaultValue={this.state.newSlogan}
        returnKeyType={"done"}
        rowSpan={!!isSlogan ? 2 : 4}
        bordered
      />
    </View>)
  }

  renderPromoTitle = () => {
    return (<View>
      <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 10, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 20, justifyContent: "flex-start" }} >
        My Promo Title</Text>
      <TextInput
        onChangeText={newPromoTitle => { this.setState({ newPromoTitle }); }}
        numberOfLines={1}
        style={{ marginBottom: 20, color: "#ffffff", paddingLeft: 3, borderRadius: 5, height: 40, backgroundColor: "#334" }}
        maxLength={50}
        underlineColorAndroid="transparent"
        defaultValue={this.state.newPromoTitle}
        returnKeyType={"done"}
        bordered
      />
      <View style={{ height: 80, width: "100%", justifyContent: "center", paddingHorizontal: 5 }}>
        <Text style={{ color: "#aaa" }}>ⓘ Select your promo title here. E.g.:</Text>
        <Text style={{ color: "#aaa" }}>DISCOUNT WEEK</Text>
        <Text style={{ color: "#aaa" }}>HOT DEALS AVAILABLE</Text>
        <Text style={{ color: "#aaa" }}>NEW COLLECTION</Text>
      </View>
    </View>)
  }

  renderPromoText = () => {
    return (<View>
      <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 10, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 20, justifyContent: "flex-start" }} >
        My Promo Text</Text>
      <TextInput
        onChangeText={newPromoText => { this.setState({ newPromoText }); }}
        multiline={true}
        numberOfLines={2}
        style={{ marginBottom: 20, color: "#ffffff", paddingLeft: 3, borderRadius: 5, height: 50, backgroundColor: "#334" }}
        maxLength={150}
        underlineColorAndroid="transparent"
        defaultValue={this.state.newPromoText}
        returnKeyType={"done"}
        rowSpan={2}
        bordered
      />
      <View style={{ height: 80, width: "100%", justifyContent: "center", paddingHorizontal: 5 }}>
        <Text style={{ color: "#aaa" }}>ⓘ Select your promo text here. E.g.:</Text>
        <Text style={{ color: "#aaa" }}>Up to 50% off all handbags</Text>
        <Text style={{ color: "#aaa" }}>Get a free surprise on every order!</Text>
        <Text style={{ color: "#aaa" }}>Free delivery when you buy over N 2000!</Text>
      </View>
    </View>)
  }

  renderSocials = () => {
    return (<View>
      <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 5, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 10, justifyContent: "flex-start" }} >
        My Instagram Link or Handle</Text>
      <TextInput
        onChangeText={text => {
          const newSocials = global.duplicate(this.state.newSocials);
          newSocials.instagram = text;
          this.setState({ newSocials });
        }}
        style={{ marginBottom: 10, color: "#ffffff", paddingLeft: 3, borderRadius: 5, height: 25, backgroundColor: "#334" }}
        maxLength={300}
        placeholder={"e.g. @wakandamarketapp"}
        placeholderTextColor={"#889"}
        underlineColorAndroid="transparent"
        defaultValue={this.state.newSocials.instagram}
        returnKeyType={"done"}
        bordered
      />
      <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 5, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 10, justifyContent: "flex-start" }} >
        My Twitter Link or Handle</Text>
      <TextInput
        onChangeText={text => {
          const newSocials = global.duplicate(this.state.newSocials);
          newSocials.twitter = text;
          this.setState({ newSocials });
        }}
        style={{ marginBottom: 10, color: "#ffffff", paddingLeft: 3, borderRadius: 5, height: 25, backgroundColor: "#334" }}
        maxLength={300}
        placeholder={"e.g. @AfricanFounders"}
        placeholderTextColor={"#889"}
        underlineColorAndroid="transparent"
        defaultValue={this.state.newSocials.twitter}
        returnKeyType={"done"}
        bordered
      />
      <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 5, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, justifyContent: "flex-start" }} >
        My Facebook Link</Text>
      <TextInput
        onChangeText={text => {
          const newSocials = global.duplicate(this.state.newSocials);
          newSocials.facebook = text;
          this.setState({ newSocials });
        }}
        style={{ marginBottom: 10, color: "#ffffff", paddingLeft: 3, borderRadius: 5, height: 25, backgroundColor: "#334" }}
        maxLength={300}
        placeholder={"e.g. www.facebook.com/wakandamrkt/"}
        placeholderTextColor={"#889"}
        underlineColorAndroid="transparent"
        defaultValue={this.state.newSocials.facebook}
        returnKeyType={"done"}
        bordered
      />
      <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 5, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 10, justifyContent: "flex-start" }} >
        My LinkedIn Link</Text>
      <TextInput
        onChangeText={text => {
          const newSocials = global.duplicate(this.state.newSocials);
          newSocials.linkedIn = text;
          this.setState({ newSocials });
        }}
        style={{ marginBottom: 10, color: "#ffffff", paddingLeft: 3, borderRadius: 5, height: 25, backgroundColor: "#334" }}
        maxLength={300}
        placeholder={"e.g. www.linkedin.com/company/african-founders"}
        placeholderTextColor={"#889"}
        underlineColorAndroid="transparent"
        defaultValue={this.state.newSocials.linkedIn}
        returnKeyType={"done"}
        bordered
      />
      <View style={{ height: 350 }} />
    </View>)
  }

  renderCart = () => {
    const ba = this.state.shop.shopCartBankAccount
    return (<View>
      <Text style={{ flex: 1, fontSize: 18, color: "#ccc", textAlign: "center", fontWeight: "bold", justifyContent: "flex-start" }} >
        Shopping Cart</Text>
      <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 5, marginTop: 20, justifyContent: "flex-start" }} >
        My Bank Account Number</Text>
      <TextInput
        onChangeText={text => {
          const newCartBankAccount = global.duplicate(this.state.newCartBankAccount);
          newCartBankAccount.number = text;
          this.setState({ newCartBankAccount });
        }}
        style={{ marginBottom: 10, color: "#ffffff", paddingLeft: 3, borderRadius: 5, height: 25, backgroundColor: "#334" }}
        maxLength={30}
        placeholder={"e.g. 0123456789"}
        placeholderTextColor={"#889"}
        underlineColorAndroid="transparent"
        defaultValue={this.state.newCartBankAccount.number}
        returnKeyType={"done"}
        bordered
      />
      <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 5, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 5, justifyContent: "flex-start" }} >
        My Bank Account Name</Text>
      <TextInput
        onChangeText={text => {
          const newCartBankAccount = global.duplicate(this.state.newCartBankAccount);
          newCartBankAccount.name = text;
          this.setState({ newCartBankAccount });
        }}
        style={{ marginBottom: 10, color: "#ffffff", paddingLeft: 3, borderRadius: 5, height: 25, backgroundColor: "#334" }}
        maxLength={150}
        placeholder={"e.g. MyShop Limited"}
        placeholderTextColor={"#889"}
        underlineColorAndroid="transparent"
        defaultValue={this.state.newCartBankAccount.name}
        returnKeyType={"done"}
        bordered
      />
      <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 5, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 5, justifyContent: "flex-start" }} >
        My Bank Name</Text>
      <TextInput
        onChangeText={text => {
          const newCartBankAccount = global.duplicate(this.state.newCartBankAccount);
          newCartBankAccount.bankName = text;
          this.setState({ newCartBankAccount });
        }}
        style={{ marginBottom: 10, color: "#ffffff", paddingLeft: 3, borderRadius: 5, height: 25, backgroundColor: "#334" }}
        maxLength={150}
        placeholder={"e.g. FirstBank"}
        placeholderTextColor={"#889"}
        underlineColorAndroid="transparent"
        defaultValue={this.state.newCartBankAccount.bankName}
        returnKeyType={"done"}
        bordered
      />
      <Text style={{ flex: 1, fontSize: 14, color: "#aaa", paddingBottom: 15, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 0, justifyContent: "flex-start" }} >
        Your bank account details will be shown to customers upon checkout/order so they can transfer the order total to you.</Text>
      <TouchableOpacity onPress={() => {
        let newCartStatus = "LIVE"
        if (this.state.newCartStatus == "LIVE") newCartStatus = "OFF"
        this.setState({ newCartStatus })
      }}>
        <CheckboxSelector
          selected={this.state.newCartStatus == "LIVE"}
          text={this.state.newCartStatus == "LIVE" ? "Cart Enabled" : "Enable Cart"}
        />
      </TouchableOpacity>
      <View style={{ height: 350 }} />
    </View>)
  }

  renderChat = () => {
    return (<View>
      <Text style={{ textAlign: "center", flex: 1, fontSize: 18, fontWeight: "bold", color: "#ccc", borderBottomColor: "#FFFFFF", borderBottomWidth: 0, justifyContent: "flex-start", margin: 15, marginTop: 0, marginBottom: 20 }} >
        Live Chat</Text>
      <Text style={{ flex: 1, fontSize: 14, color: "#aaa" }} >
        ⓘ In addition to receiving messages, you can enable a live chat option on your website.</Text>
      <Text style={{ flex: 1, fontSize: 14, color: "#aaa" }} >
        Chat messages from customers will appear in the message inbox of this app.</Text>
      <Text style={{ flex: 1, fontSize: 14, color: "#aaa" }} >
        Only enable when you can always respond immediately to messages.</Text>
      <View style={{ height: 35 }} />
      <TouchableOpacity onPress={() => {
        let status = ""
        if (this.state.newChat.status == "LIVE") status = "OFF"
        else status = "LIVE"
        this.setState({ newChat: { ...this.state.newChat, status } })
        console.log("AAAAAAA3", { ...this.state.newChat, status })
      }}>
        <CheckboxSelector
          selected={this.state.newChat.status == "LIVE"}
          text={this.state.newChat.status == "LIVE" ? "Enabled" : "Enable"}
        />
      </TouchableOpacity>
      <PushNotificationHandler />
      <View style={{ height: 350 }} />
    </View>)
  }

  renderBlog = () => {
    let shop = this.state.shop
    let domain = "https://www.wakanda-market.com/" + this.props.shopID
    if (shop.shopCategory == "education") domain = "https://www.wakanda-education.com/" + this.props.shopID
    if (shop.tldEnabled) domain = "http://www." + this.state.shop.tld
    domain = domain + "/admin"
    return (<View>
      <Text style={{ textAlign: "center", flex: 1, fontSize: 18, fontWeight: "bold", color: "#ccc", borderBottomColor: "#FFFFFF", borderBottomWidth: 0, justifyContent: "flex-start", margin: 15, marginTop: 0, marginBottom: 20 }} >
        Blog</Text>
      <View style={{ height: 35 }} />
      <TouchableOpacity onPress={() => {
        let status = ""
        if (this.state.newBlog.status == "LIVE") status = "OFF"
        else status = "LIVE"
        this.setState({ newBlog: { ...this.state.newBlog, status } })
      }}>
        <CheckboxSelector
          selected={this.state.newBlog.status == "LIVE"}
          text={this.state.newBlog.status == "LIVE" ? "Enabled" : "Enable"}
        />
      </TouchableOpacity>
      {(this.state.shop.shopBlog && this.state.shop.shopBlog.status == "LIVE") ? (<View style={{ marginTop: 30, alignItems: "center" }}>
        {!this.state.aaRedirect ? (<TouchableOpacity onPress={() => { this.redirectToAdmin(domain) }}>

          <View style={{ flexDirection: "row", alignSelf: "center", width: "100%", backgroundColor: "#131313", margin: 10, borderWidth: .6, borderColor: "#444", padding: 5 }} >
            <View style={{ alignSelf: "center", width: "94%", justifyContent: "space-between" }} >
              <View style={{ width: "94%" }}>
                <Text style={{ color: "#aaa", fontSize: 16 }}>BLOG ADMIN PAGE</Text>
                <Text style={{ color: "#aaa", fontSize: 14 }}>{domain}</Text>
              </View>
            </View>
            <View style={{ alignSelf: "center", width: "6%", justifyContent: "center" }} >
              <Text style={{ color: "white", fontSize: 20, fontWeight: "bold", color: "#aaa", marginBottom: 2 }}>{">"}</Text>
            </View>
          </View>
        </TouchableOpacity>) : (<View style={{ flexDirection: "row", alignSelf: "center", width: "100%", margin: 30 }}>
          <ActivityIndicator size="small" color="#aaa" style={{ alignSelf: "center" }} />
          <Text style={{ color: "#aaa", fontSize: 14, marginLeft: 6, }}>Loading Admin Page... </Text></View>)}
      </View>) : (
          <Text style={{ flex: 1, fontSize: 14, color: "#aaa" }} >
            Once you enable the blog, you can edit your blog here</Text>
        )
      }
      <View style={{ height: 350 }} />
    </View>)
  }

  setPictureCategory = async (cat) => {
    this.setState({ pictures: [] })
    await global.timeout(100)
    if (cat == "Generic") this.setState({ pictures: this.picturesGeneric })
    if (cat == "Work") this.setState({ pictures: this.picturesWork })
    if (cat == "Rave") this.setState({ pictures: this.picturesRave })
    this.setState({ selectorSelection: cat })
  }
  setPictureCategoryPromo = async (cat) => {
    this.setState({ promoPictures: [] })
    await global.timeout(100)
    if (cat == "Generic") this.setState({ promoPictures: this.picturesPromoGeneric })
    if (cat == "Work") this.setState({ promoPictures: this.picturesPromoWork })
    this.setState({ selectorSelectionPromo: cat })
  }

  renderShopType = () => {
    let width = 250
    let height = 250
    if (this.state.activityIndicatorAnimating) return (
      <View style={{ flex: 1, backgroundColor: "#131313", justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator size="large" color="#aaa" style={{ alignSelf: "center", margin: 20 }} />
      </View>)
    return (<View style={{ alignItems: "center" }}>
      <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 10, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 20, justifyContent: "flex-start" }} >
        Choose your theme below</Text>
      {this.shopTypes.length > 0 && this.shopTypes.map((type, i) => {
        return (
          <View style={{ alignSelf: "stretch", alignItems: "center", marginTop: 15, marginBottom: 6 }} key={i}>
            <TouchableOpacity onPress={() => { Linking.openURL(type.url) }}>
              <View style={{ width, justifyContent: "center", alignItems: "center", backgroundColor: "#c0c0c8" }}>
                <Text style={{ color: "#000", fontSize: 20, margin: 2, textAlign: "center" }}>{type.name}</Text>
              </View>
              <View style={{ width, height, marginBottom: -height, justifyContent: "center", alignItems: "center", backgroundColor: "#444" }}>
                <Text style={{ color: "#c0c0c8" }}>Loading</Text>
              </View>
              <Image style={{ height, width }} source={{ uri: type.picture, cache: "force-cache" }} />
            </TouchableOpacity>
            <TouchableOpacity style={{ marginBottom: 25, marginTop: -35 }}
              onPress={async () => {
                await this.setState({ newShopType: type.name });
                Alert.alert("Select theme?", "The new theme will be live immediately",
                  [{ text: "No", onPress: () => { } },
                  { text: "Yes", onPress: () => { this.submitChanges() } }],
                  { cancelable: true });
              }}>
              <LinearGradient
                colors={['#556620', '#A8CF45']}
                style={{ flexDirection: "row", borderRadius: 5, height: 28, marginTop: 2, alignItems: 'center', justifyContent: "center", backgroundColor: '#A8CF45', paddingHorizontal: 10, borderWidth: 1, borderColor: "#131313" }}>
                <Text style={{ fontSize: 16, color: '#000', textAlign: "center", fontWeight: "bold" }}>Select Theme</Text>
              </LinearGradient>
            </TouchableOpacity>
            {!this.knownShopTypeNames.includes(type.name) && <View style={{ width, justifyContent: "center", alignItems: "center", marginTop: -25, marginBottom: 25 }}>
              <Text style={{ color: "#d00", margin: 2, marginVertical: 5, textAlign: "center" }}>App restart recommended before selecting this theme</Text>
            </View>}
          </View>)
      })}
      <View style={{ height: 100 }} />
    </View>)
  }

  renderFrontPicture = () => {
    let width = 160
    let height = 160
    if (this.state.activityIndicatorAnimating) return (
      <View style={{ flex: 1, backgroundColor: "#131313", justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator size="large" color="#aaa" style={{ alignSelf: "center", margin: 20 }} />
      </View>)
    return (<View style={{ alignItems: "center" }}>
      <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 10, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 20, justifyContent: "flex-start" }} >
        Choose your front picture below</Text>
      <TouchableOpacity onPress={async () => { this.setState({ modalVisible: false }); await global.timeout(200); this.setState({ showGetPicture: true }) }}>
        <View style={{ width: "100%", marginVertical: 5, alignItems: "center" }} key={"Upload"}>
          <View style={{ width, height: height / 3, justifyContent: "center", alignItems: "center" }}>
            <LinearGradient
              colors={['#556620', '#A8CF45']}
              style={{ alignItems: "center", justifyContent: "center", width: "100%", borderRadius: 5, height: 40, marginTop: 10, alignItems: 'center', backgroundColor: '#A8CF45', padding: 10 }}>
              <Text style={{ fontSize: 16, color: '#000', textAlign: "center", fontWeight: "bold" }}>Upload a picture</Text>
            </LinearGradient>
          </View>
        </View>
      </TouchableOpacity>

      {this.state.frontPictures.map((image, i) => {
        return <View style={{ width: "100%", marginVertical: 5, alignItems: "center" }} key={i}>
          <View style={{ width, height, marginBottom: -height, justifyContent: "center", alignItems: "center", backgroundColor: "#fff" }}>
            {/* <Text style={{ color: "#ccc" }}>Loading</Text> */}
          </View>
          <TouchableOpacity onPress={async () => {
            await this.setState({ newPictureURL: image.large })
            Alert.alert("Select Front Picture?", "Your selection will take effect immediately",
              [{ text: "No", onPress: () => { } },
              { text: "Yes", onPress: () => { this.submitChanges() } }],
              { cancelable: true });
          }}>
            <Image style={{ width, height }} source={{ uri: image.small || image.large, cache: "force-cache" }} />
          </TouchableOpacity>
        </View>
      })}
      {this.state.myPostIDs && this.state.myPostIDs.length > 0 && this.state.myPostIDs.map((id, i) => {
        return <MyPost postID={id} key={i} width={width} height={height} onPress={async (image) => {
          await this.setState({ newPictureURL: image })
          Alert.alert("Select Front Picture?", "Your selection will take effect immediately",
            [{ text: "No", onPress: () => { } },
            { text: "Yes", onPress: () => { this.submitChanges() } }],
            { cancelable: true });
        }} />
      })}
    </View>)
  }

  renderLogoPicture = () => {
    let width = 160
    let height = 160
    if (this.state.activityIndicatorAnimating) return (
      <View style={{ flex: 1, backgroundColor: "#131313", justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator size="large" color="#aaa" style={{ alignSelf: "center", margin: 20 }} />
      </View>)
    return (<View style={{ alignItems: "center" }}>
      <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 10, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 20, justifyContent: "flex-start" }} >
        Choose your logo below</Text>
      <TouchableOpacity onPress={async () => { this.setState({ modalVisible: false }); await global.timeout(200); this.setState({ showGetPicture: true }) }}>
        <View style={{ width: "100%", marginVertical: 5, alignItems: "center" }} key={"Upload"}>
          <View style={{ width, height: height / 3, justifyContent: "center", alignItems: "center" }}>
            <LinearGradient
              colors={['#556620', '#A8CF45']}
              style={{ alignItems: "center", justifyContent: "center", width: "100%", borderRadius: 5, height: 40, marginTop: 10, alignItems: 'center', backgroundColor: '#A8CF45', padding: 10 }}>
              <Text style={{ fontSize: 16, color: '#000', textAlign: "center", fontWeight: "bold" }}>Upload a logo</Text>
            </LinearGradient>
          </View>
        </View>
      </TouchableOpacity>

      <View style={{ width: "100%", marginVertical: 5, alignItems: "center" }} key={"PP"}>
        <View style={{ width, height, borderRadius: width / 2, marginBottom: -height, justifyContent: "center", alignItems: "center", backgroundColor: "#fff" }}>
          <Text style={{ color: "#ccc" }}>Loading</Text>
        </View>
        <TouchableOpacity onPress={async () => {
          await this.setState({ newPictureURL: global.myProfilePictureURL })
          Alert.alert("Select Logo?", "Your Wakanda profile picture will be used as your website logo",
            [{ text: "No", onPress: () => { } },
            { text: "Yes", onPress: () => { this.submitChanges() } }],
            { cancelable: true });
        }}>
          <Image style={{ width, height, borderRadius: height / 2 }} source={{ uri: global.myProfilePictureURL }} />
        </TouchableOpacity>
      </View>
    </View>)
  }

  renderPromoPicture = () => {
    let width = Dimensions.get("window").width * .9
    let height = width * 476 / 1400
    if (this.state.activityIndicatorAnimating) return (
      <View style={{ flex: 1, backgroundColor: "#131313", justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator size="large" color="#aaa" style={{ alignSelf: "center", margin: 20 }} />
      </View>)
    return (<View style={{ alignItems: "center" }}>
      <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 10, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 20, justifyContent: "flex-start" }} >
        Choose your promo banner below</Text>
      <TouchableOpacity onPress={async () => { this.setState({ modalVisible: false }); await global.timeout(200); this.setState({ showGetPicture: true }) }}>
        <View style={{ width: "100%", marginVertical: 5, alignItems: "center" }} key={"Upload2"}>
          <View style={{ width, height: height / 3, justifyContent: "center", alignItems: "center", backgroundColor: "#000", borderColor: "#777", borderWidth: 1 }}>
            <Text style={{ color: "#eee" }}>Upload your own banner</Text>
          </View>
        </View>
      </TouchableOpacity>
      {this.state.promoPictures.map((image, i) => {
        return <View style={{ width: "100%", marginVertical: 5, alignItems: "center" }} key={i}>
          <View style={{ width, height, marginBottom: -height, justifyContent: "center", alignItems: "center", backgroundColor: "#444" }}>
            <Text style={{ color: "#ccc" }}>Loading</Text>
          </View>
          <TouchableOpacity onPress={async () => {
            await this.setState({ newPictureURL: image.l })
            Alert.alert("Select Promo Banner?", "",
              [{ text: "No", onPress: () => { } },
              { text: "Yes", onPress: () => { this.submitChanges() } }],
              { cancelable: true });
          }}>
            <Image style={{ width, height }} source={{ uri: image.s, cache: "force-cache" }} />
          </TouchableOpacity>
        </View>
      })}
      <View style={{ height: 100 }} />
    </View>)
  }

  renderBackgroundPicture = () => {
    let width = Dimensions.get("window").width * .9
    let height = width * 720 / 1920
    if (this.state.activityIndicatorAnimating) return (
      <View style={{ flex: 1, backgroundColor: "#131313", justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator size="large" color="#aaa" style={{ alignSelf: "center", margin: 20 }} />
      </View>)
    return (<View style={{ alignItems: "center" }}>
      <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 10, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 20, justifyContent: "flex-start" }} >
        Choose your background picture below</Text>
      <TouchableOpacity onPress={async () => { this.setState({ modalVisible: false }); await global.timeout(200); this.setState({ showGetPicture: true }) }}>
        <View style={{ width: "100%", marginVertical: 5, alignItems: "center" }} key={"Upload"}>
          <View style={{ width, height: height / 3, justifyContent: "center", alignItems: "center", backgroundColor: "#000", borderColor: "#777", borderWidth: 1 }}>
            <Text style={{ color: "#eee" }}>Upload your own picture</Text>
          </View>
        </View>
      </TouchableOpacity>

      {this.state.pictures.map((image, i) => {
        return <View style={{ width: "100%", marginVertical: 5, alignItems: "center" }} key={i}>
          <View style={{ width, height, marginBottom: -height, justifyContent: "center", alignItems: "center", backgroundColor: "#444" }}>
            <Text style={{ color: "#ccc" }}>Loading</Text>
          </View>
          <TouchableOpacity onPress={async () => {
            await this.setState({ newPictureURL: image })
            Alert.alert("Select Background Picture?", "Your selection will take effect immediately",
              [{ text: "No", onPress: () => { } },
              { text: "Yes", onPress: () => { this.submitChanges() } }],
              { cancelable: true });
          }}>
            {Platform.OS == "android" ? <Image style={{ width, height }} source={{ uri: image, cache: "force-cache" }} /> :
              <CachedImage style={{ width, height }} source={{ uri: image, cache: "force-cache" }} key={image} />}
          </TouchableOpacity>
        </View>
      })}
      <View style={{ height: 100 }} />
    </View>)
  }

  renderBannerPreview = (isInModal) => {
    return (
      <View style={{ alignSelf: "center", ...(!isInModal ? { width: "72%" } : { alignItems: "center" }), justifyContent: "center" }} >
        <View style={{ width: 220, height: 220 * 476 / 1400, marginBottom: -220 * 476 / 1400, justifyContent: "center", alignItems: "center", backgroundColor: "#444" }}>
          <Text style={{ color: "#ccc" }}>Loading</Text>
        </View>
        <Image
          style={{ width: 220, height: 220 * 476 / 1400 }}
          source={{ uri: this.state.shop.shopPromoPicture || "", cache: "force-cache" }}
        />
        <View style={{ marginTop: -220 * 476 / 1400, height: 220 * 476 / 1400, width: 220, justifyContent: "center", alignItems: "center", paddingHorizontal: 30 }}>
          <Text style={{ fontSize: 16, textAlign: "center" }}>{this.state.shop.shopPromoTitle.toUpperCase()}</Text>
          <Text style={{ fontSize: 10, textAlign: "center" }}>{this.state.shop.shopPromoText}</Text>
        </View>
      </View>)
  }
  renderSocialsPreview = (isInModal) => {
    let count = 0
    if (this.state.shop.shopSocials) {
      if (this.state.shop.shopSocials.facebook) count += 1
      if (this.state.shop.shopSocials.instagram) count += 1
      if (this.state.shop.shopSocials.linkedIn) count += 1
      if (this.state.shop.shopSocials.twitter) count += 1
    }
    return (
      <View style={{ flexDirection: "row", alignSelf: "center", ...(!isInModal ? { width: "72%" } : { alignItems: "center" }), justifyContent: "center" }} >
        <Text style={{ fontSize: 12, color: "#ccc" }}>{count} social media links</Text>
      </View>)
  }
  renderCartPreview = (isInModal) => {
    let status = this.state.shop.shopCartStatus == "LIVE" ? "live" : "disabled"
    return (
      <View style={{ flexDirection: "row", alignSelf: "center", ...(!isInModal ? { width: "72%" } : { alignItems: "center" }), justifyContent: "center" }} >
        <Text style={{ fontSize: 12, color: "#ccc" }}>Shopping Cart / Checkout {status}</Text>
      </View>)
  }
  renderChatPreview = (isInModal) => {
    let status = "disabled"
    if (this.state.shop.shopChat.status == "LIVE") status = "enabled"
    return (
      <View style={{ flexDirection: "row", alignSelf: "center", ...(!isInModal ? { width: "72%" } : { alignItems: "center" }), justifyContent: "center" }} >
        <Text style={{ fontSize: 12, color: "#ccc" }}>Live Chat {status}</Text>
      </View>)
  }
  renderBlogPreview = (isInModal) => {
    let status = "disabled"
    if (this.state.shop.shopBlog.status == "LIVE") status = "enabled"
    return (
      <View style={{ flexDirection: "row", alignSelf: "center", ...(!isInModal ? { width: "72%" } : { alignItems: "center" }), justifyContent: "center" }} >
        <Text style={{ fontSize: 12, color: "#ccc" }}>Blog {status}</Text>
      </View>)
  }
  renderColorPreview = (isInModal) => {
    if (this.state.shop.shopType == "Cake Factory") return (
      <View style={{ alignSelf: "center", ...(!isInModal && { width: "72%" }), justifyContent: "center" }} >
        <View style={{ width: 220, height: 220 * 720 / 1920, justifyContent: "center", alignItems: "center", backgroundColor: "#fff", borderRadius: 10 }}>
          <Text style={{ color: this.state.shop.shopColorHeader, fontWeight: "bold", marginBottom: 2, fontSize: 18 }}>Text color </Text>
        </View>
      </View>)
    if (this.state.shop.shopType == "Electro") return (
      <View style={{ alignSelf: "center", ...(!isInModal && { width: "72%" }), justifyContent: "center" }} >
        <View style={{ width: 220, height: 220 * 720 / 1920, justifyContent: "center", alignItems: "center", backgroundColor: "#fff", borderRadius: 10 }}>
          <View style={{ width: 200, height: 220 * 720 / 1920 / 2.2, flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: this.state.shop.shopColorBackground }}>
            <Text style={{ color: this.state.shop.shopColorHeader, fontWeight: "bold" }}>Header</Text>
          </View>
        </View>
      </View>)
    if (this.state.shop.shopType == "Engineer") return (
      <View style={{ alignSelf: "center", ...(!isInModal && { width: "72%" }), justifyContent: "center" }} >
        <View style={{ width: 220, height: 220 * 720 / 1920, justifyContent: "center", alignItems: "center", backgroundColor: "#fff", borderRadius: 10 }}>
          <Text style={{ color: this.state.shop.shopColorHeader, fontWeight: "bold", marginBottom: 2 }}>Text color </Text>
          <View style={{ width: 200, height: 220 * 720 / 1920 / 3.3, flexDirection: "row", marginBottom: 3, justifyContent: "center", alignItems: "center", backgroundColor: this.state.shop.shopColorBackground }}>
          </View>
        </View>
      </View>)
    if (this.state.shop.shopType == "Rave" || this.state.shop.shopType == "Teacher") return (
      <View style={{ alignSelf: "center", ...(!isInModal && { width: "72%" }), justifyContent: "center" }} >
        <View style={{ width: 220, height: 100, justifyContent: "center", alignItems: "center", backgroundColor: "#fff", borderRadius: 10 }}>
          <LinearGradient
            colors={[this.state.shop.shopColorGradient1, this.state.shop.shopColorGradient2]} start={{ x: 0, y: 0 }} end={{ x: 1, y: 1 }}
            style={{ width: 200, height: 50, flexDirection: "row", marginBottom: 8, justifyContent: "center", alignItems: "center" }}>
            <View style={{ width: 80, height: 26, padding: 3, paddingHorizontal: 8, borderRadius: 20, justifyContent: "center", alignItems: "center", backgroundColor: this.state.shop.shopColorButton }}>
              <Text style={{ color: "#fff" }}>Button</Text>
            </View>
          </LinearGradient>
          <View style={{ width: 200, height: 15, flexDirection: "row", marginBottom: 0, justifyContent: "center", alignItems: "center", backgroundColor: this.state.shop.shopColorBackground }} />
        </View>
      </View>)
    return (
      <View style={{ alignSelf: "center", ...(!isInModal && { width: "72%" }), justifyContent: "center" }} >
        <View style={{ width: 220, height: 220 * 720 / 1920, justifyContent: "center", alignItems: "center", backgroundColor: "#fff", borderRadius: 10 }}>
          <View style={{ width: 200, height: 220 * 720 / 1920 / 2.2, flexDirection: "row", marginBottom: 10, justifyContent: "center", alignItems: "center", backgroundColor: this.state.shop.shopColorBackground }}>
            <View style={{ width: 80, height: 26, marginRight: 20, padding: 3, paddingHorizontal: 8, borderRadius: 20, justifyContent: "center", alignItems: "center", backgroundColor: this.state.shop.shopColorButton }}>
              <Text style={{ color: "#fff" }}>Button</Text>
            </View>
            <Text style={{ color: this.state.shop.shopColorHeader, fontWeight: "bold" }}>Header</Text>
          </View>
          <Text style={{ color: this.state.shop.shopColorTitle, fontWeight: "bold" }}>Title Text</Text>
        </View>
      </View>)
  }

  renderGradients = () => {
    const gradients = [{ a: "rgba(12, 0, 255, 0.8)", b: "rgba(250, 16, 83, 0.8)" },
    { a: "rgba(34, 113, 195, 0.8)", b: "rgba(45, 253, 240, 0.8)" },
    { a: "rgba(253, 32, 29, 0.8)", b: "rgba(252, 201, 69,0.8)" },
    { a: "rgba(29, 222, 253,0.8)", b: "rgba(69, 252, 216, 0.8)" },
    { a: "rgba(148, 181, 233, 0.8)", b: "rgba(238, 174, 202, 0.8)" },
    { a: "rgba(0, 74, 255, 0.8)", b: "rgba(124, 0, 255, 0.8)" },
    { a: "rgba(255, 0, 121,0.8)", b: "rgba(255, 0, 198, 0.8)" },
    ]
    return (<View style={{ alignItems: "center", alignSelf: "stretch" }}>
      {gradients.map((g) => {
        return (
          <TouchableOpacity key={g.a} onPress={async () => {
            await this.setState({ newGradientColors: g })
            Alert.alert('Select this gradient?', "Your selection will take effect immediately",
              [{ text: "No", onPress: () => { } },
              { text: "Yes", onPress: () => { this.submitChanges() } }],
              { cancelable: true });
          }}
            style={{ alignItems: "center", justifyContent: "center" }}>
            <LinearGradient
              style={{ backgroundColor: "red", width: 150, height: 50, marginBottom: 25 }} xxx
              colors={[g.a, g.b]} start={{ x: 0, y: 0 }} end={{ x: 1, y: 1 }}>
            </LinearGradient>
          </TouchableOpacity>)
      })}
    </View>
    )
  }

  renderColorCircle = (r, g, b) => {
    const backgroundColor = "rgb(" + Math.round(r) + "," + Math.round(g) + "," + Math.round(b) + ")"
    return (
      <TouchableOpacity onPress={async () => {
        await this.setState({ newColor: backgroundColor })
        Alert.alert('Select ' + this.state.newColorType + ' color?', "Your selection will take effect immediately",
          [{ text: "No", onPress: () => { } },
          { text: "Yes", onPress: () => { this.submitChanges() } }],
          { cancelable: true });
      }}
        style={{ alignItems: "center", justifyContent: "center" }}>
        <View style={{ backgroundColor, width: 25, height: 25, borderRadius: 13 }} />
      </TouchableOpacity>)
  }
  renderCircleRow = (r, g) => {
    const length = 8
    const array = []
    for (let i = 0; i <= length; i++) {
      array.push((<View style={{ alignItems: "center", justifyContent: "center" }} key={i}>{this.renderColorCircle(r, g, 255 * (i / length))}</View>))
    }
    return array
  }
  renderCircleSet = (r) => {
    const length = 6
    const array = []
    for (let i = 0; i <= length; i++) {
      array.push((<View style={{ alignItems: "center", justifyContent: "center", flexDirection: "row" }} key={i}>{this.renderCircleRow(r, 255 * (i / length))}</View>))
    }
    return array
  }
  renderCircles = () => {
    const length = 6
    const array = []
    array.push((<View style={{ alignItems: "center", justifyContent: "space-around", height: 60, width: "80%", margin: 10 }} key={"A1"}>
      <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", height: 30, width: "80%", margin: 10 }}>
        {this.renderColorCircle(27, 54, 73)}
        {this.renderColorCircle(123, 29, 175)}
        {this.renderColorCircle(15, 192, 252)}
        {this.renderColorCircle(212, 255, 71)}
        {this.renderColorCircle(255, 47, 185)}
      </View>
      <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", height: 30, width: "80%", margin: 10 }}>
        {this.renderColorCircle(251, 173, 24)}
        {this.renderColorCircle(228, 33, 40)}
        {this.renderColorCircle(254, 78, 0)}
        {this.renderColorCircle(91, 35, 51)}
        {this.renderColorCircle(247, 244, 243)}
      </View>
    </View>))

    for (let i = 0; i <= length; i++) {
      array.push((<View style={{ alignItems: "center", justifyContent: "center" }} key={i}>{this.renderCircleSet(255 * (i / length))}</View>))
    }
    return array
  }


  renderPromo = () => {
    if (this.state.activityIndicatorAnimating) return (
      <View style={{ flex: 1, backgroundColor: "#000", justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator size="large" color="#aaa" style={{ alignSelf: "center", margin: 20 }} />
      </View>)
    return (
      <View style={{ flex: 1, backgroundColor: "#000", width: "100%", justifyContent: "center", alignItems: "center" }}>
        {this.renderBannerPreview(true)}
        <TouchableOpacity style={[global.gStyles.buttonXW, { marginTop: 25, width: Dimensions.get("window").width * .5, alignSelf: "center", height: 30, borderRadius: 20 }]}
          onPress={() => { this.setState({ activeModal: "promoPicture" }) }}>
          <Text style={[global.gStyles.buttontXW, { fontSize: 14 }]}>Change Image</Text>
        </TouchableOpacity>
        <TouchableOpacity style={[global.gStyles.buttonXW, { marginTop: 10, width: Dimensions.get("window").width * .5, alignSelf: "center", height: 30, borderRadius: 20 }]}
          onPress={() => { this.setState({ activeModal: "promoTitle", newPromoTitle: this.state.shop.shopPromoTitle }) }}>
          <Text style={[global.gStyles.buttontXW, { fontSize: 14 }]}>Change Title</Text>
        </TouchableOpacity>
        <TouchableOpacity style={[global.gStyles.buttonXW, { marginTop: 10, width: Dimensions.get("window").width * .5, alignSelf: "center", height: 30, borderRadius: 20 }]}
          onPress={() => { this.setState({ activeModal: "promoText", newPromoText: this.state.shop.shopPromoText }) }}>
          <Text style={[global.gStyles.buttontXW, { fontSize: 14 }]}>Change Text</Text>
        </TouchableOpacity>
        <Text style={{ color: "#aaa", marginTop: 50 }}>{this.state.shop.shopPromoIsActive ? "The promo banner is currently visible" : "The Promo Banner is currently not visible."}</Text>
        <TouchableOpacity style={[global.gStyles.buttonX, { marginTop: 10, width: Dimensions.get("window").width * .5, alignSelf: "center", height: 30, borderRadius: 20 }]}
          onPress={async () => {
            Alert.alert(this.state.shop.shopPromoIsActive ? "Hide Promo Banner?" : "Activate Promo Banner?", "Change will take effect immediately",
              [{ text: "Cancel", onPress: () => { } },
              { text: "Yes", onPress: () => { this.submitChanges() } }],
              { cancelable: true });
          }}>
          <Text style={[global.gStyles.buttontX, { fontSize: 14 }]}>{this.state.shop.shopPromoIsActive ? "Hide Promo Banner" : "Activate Promo Banner"}</Text>
        </TouchableOpacity>
      </View>)
  }

  renderColors = () => {
    if (this.state.activityIndicatorAnimating) return (
      <View style={{ flex: 1, backgroundColor: "#000", justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator size="large" color="#aaa" style={{ alignSelf: "center", margin: 20 }} />
      </View>)
    if (this.state.newColorType == "" || this.state.newColorType == "reset") return (
      <View style={{ flex: 1, backgroundColor: "#000", width: "100%", justifyContent: "center", alignItems: "center" }}>
        {this.renderColorPreview(true)}
        {(this.state.shop.shopType == "Rave" || this.state.shop.shopType == "Teacher") && <TouchableOpacity style={{ marginTop: 25, marginBottom: -25 }}
          onPress={() => { this.setState({ newColorType: "gradient" }) }}>
          <LinearGradient
            colors={['#556620', '#A8CF45']}
            style={{ flexDirection: "row", width: Dimensions.get("window").width * .9, borderRadius: 5, height: 40, marginTop: 10, alignItems: 'center', backgroundColor: '#A8CF45', padding: 10 }}>
            <LinearGradient
              colors={[this.state.shop.shopColorGradient1, this.state.shop.shopColorGradient2]} start={{ x: 0, y: 0 }} end={{ x: 1, y: 1 }}
              style={{ marginRight: 10, borderRadius: 15, height: 30, width: 30, borderColor: "#fff" }}>
            </LinearGradient>
            <Text style={{ fontSize: 16, color: '#000', textAlign: "center", fontWeight: "bold" }}>Change Gradient</Text>
          </LinearGradient>
        </TouchableOpacity>}
        {this.state.shop.shopType != "Cake Factory" && <TouchableOpacity style={{ marginTop: 25 }}
          onPress={() => { this.setState({ newColorType: "background" }) }}>
          <LinearGradient
            colors={['#556620', '#A8CF45']}
            style={{ flexDirection: "row", width: Dimensions.get("window").width * .9, borderRadius: 5, height: 40, marginTop: 10, alignItems: 'center', backgroundColor: '#A8CF45', padding: 10 }}>
            <View style={{ backgroundColor: this.state.shop.shopColorBackground || "#000", marginRight: 10, borderRadius: 15, height: 30, width: 30, borderColor: "#fff" }} />
            <Text style={{ fontSize: 16, color: '#000', textAlign: "center", fontWeight: "bold" }}>Change Background Color</Text>
          </LinearGradient>
        </TouchableOpacity>}
        {(this.state.shop.shopType != "Electro" && this.state.shop.shopType != "Engineer" && this.state.shop.shopType != "Cake Factory") && <TouchableOpacity style={{}}
          onPress={() => { this.setState({ newColorType: "button" }) }}>
          <LinearGradient
            colors={['#556620', '#A8CF45']}
            style={{ flexDirection: "row", width: Dimensions.get("window").width * .9, borderRadius: 5, height: 40, marginTop: 10, alignItems: 'center', backgroundColor: '#A8CF45', padding: 10 }}>
            <View style={{ backgroundColor: this.state.shop.shopColorButton || "#000", marginRight: 10, borderRadius: 15, height: 30, width: 30, borderColor: "#fff" }} />
            <Text style={{ fontSize: 16, color: '#000', textAlign: "center", fontWeight: "bold" }}>Change Button Color</Text>
          </LinearGradient>
        </TouchableOpacity>}
        {(this.state.shop.shopType != "Rave" && this.state.shop.shopType != "Teacher") && <TouchableOpacity style={{}}
          onPress={() => { this.setState({ newColorType: "header" }) }}>
          <LinearGradient
            colors={['#556620', '#A8CF45']}
            style={{ flexDirection: "row", width: Dimensions.get("window").width * .9, borderRadius: 5, height: 40, marginTop: 10, alignItems: 'center', backgroundColor: '#A8CF45', padding: 10 }}>
            <View style={{ backgroundColor: this.state.shop.shopColorHeader || "#000", marginRight: 10, borderRadius: 15, height: 30, width: 30, borderColor: "#fff" }} />
            <Text style={{ fontSize: 16, color: '#000', textAlign: "center", fontWeight: "bold" }}>Change Header Color</Text>
          </LinearGradient>
        </TouchableOpacity>}
        {(this.state.shop.shopType != "Electro" && this.state.shop.shopType != "Engineer" && this.state.shop.shopType != "Cake Factory" && this.state.shop.shopType != "Rave" && this.state.shop.shopType != "Teacher") && <TouchableOpacity style={{}}
          onPress={() => { this.setState({ newColorType: "title" }) }}>
          <LinearGradient
            colors={['#556620', '#A8CF45']}
            style={{ flexDirection: "row", width: Dimensions.get("window").width * .9, borderRadius: 5, height: 40, marginTop: 10, alignItems: 'center', backgroundColor: '#A8CF45', padding: 10 }}>
            <View style={{ backgroundColor: this.state.shop.shopColorTitle || "#000", marginRight: 10, borderRadius: 15, height: 30, width: 30, borderColor: "#fff" }} />
            <Text style={{ fontSize: 16, color: '#000', textAlign: "center", fontWeight: "bold" }}>Change Title Color</Text>
          </LinearGradient>
        </TouchableOpacity>}
        <TouchableOpacity style={{ marginTop: 15 }}
          onPress={async () => {
            await this.setState({ newColorType: "reset" });
            Alert.alert("Reset colors?", "All colors will be set back to default",
              [{ text: "No", onPress: () => { } },
              { text: "Yes", onPress: () => { this.submitChanges() } }],
              { cancelable: true });
          }}>
          <LinearGradient
            colors={['#556620', '#A8CF45']}
            style={{ flexDirection: "row", width: Dimensions.get("window").width * .9, borderRadius: 5, height: 40, marginTop: 10, alignItems: 'center', backgroundColor: '#A8CF45', padding: 10 }}>
            <View style={{ marginRight: 10, borderRadius: 15, height: 30, width: 30, borderWidth: 0, borderColor: "#556620", alignItems: "center", justifyContent: "center" }} />
            <Text style={{ fontSize: 16, color: '#000', textAlign: "center", fontWeight: "bold" }}>Reset to Default Colors</Text>
          </LinearGradient>
        </TouchableOpacity>
      </View>)
    if (this.state.newColorType == "gradient") return (<View style={{ alignItems: "center", marginBottom: 100, alignSelf: "stretch" }}>
      <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 10, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 10, justifyContent: "flex-start" }} >
        Choose your Gradient colors below</Text>
      {this.renderGradients()}
    </View>)
    return (<View style={{ alignItems: "center", marginBottom: 100 }}>
      <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 10, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 10, justifyContent: "flex-start" }} >
        Choose your {this.state.newColorType} color below</Text>
      {this.renderCircles()}
    </View>)
  }

  renderHeaderBox = (sectionNumber) => {
    const txt = (sectionNumber == 0 || sectionNumber == 1) ? (this.state.shop.shopHeader || "") : this.state.shop["shopHeader" + sectionNumber] || ""
    return (<TouchableOpacity onPress={() => { this.setState({ modalVisible: true, activeModal: "header", newHeader: txt, activeSection: sectionNumber }) }}>
      <View style={{ flexDirection: "row", backgroundColor: "#000", alignSelf: "stretch", borderWidth: 1, borderColor: "#444", borderRadius: 15, padding: 5, marginBottom: 7 }}>
        <View style={{ alignSelf: "center", width: "20%", justifyContent: "center" }} >
          <Text
            style={{ fontSize: 12, color: "#ccc", fontWeight: "bold", paddingBottom: 10, marginTop: 8, justifyContent: "flex-start" }}
          >
            Header
      </Text></View>
        <View style={{ alignSelf: "center", width: "72%", justifyContent: "center" }} >
          <Text
            style={{ fontSize: 14, color: "#ccc", paddingBottom: 10, marginTop: 8, justifyContent: "flex-start", fontStyle: "italic" }}
          >
            {txt || "-"}
          </Text>
        </View>
        <View style={{ alignSelf: "center", width: "8%", justifyContent: "center" }} >
          <Image style={{ width: 20, height: 20 }} source={require("../../images/icons/Change.png")} />
          {/* <Text style={{ color: "white", fontSize: 20, fontWeight: "bold", color: "#444", marginBottom: 2 }}>></Text> */}
        </View>
      </View>
    </TouchableOpacity>)
  }
  renderSloganBox = (sectionNumber) => {
    const txt = (sectionNumber == 0 || sectionNumber == 1) ? (this.state.shop.shopSlogan || "") : this.state.shop["shopSlogan" + sectionNumber] || ""
    return (<TouchableOpacity onPress={() => { this.setState({ modalVisible: true, activeModal: "slogan", newSlogan: txt, activeSection: sectionNumber }) }}>
      <View style={{ flexDirection: "row", backgroundColor: "#000", alignSelf: "stretch", borderWidth: 1, borderColor: "#444", borderRadius: 15, padding: 5, marginBottom: 7 }}>
        <View style={{ alignSelf: "center", width: "20%", justifyContent: "center" }} >
          <Text
            style={{ fontSize: 12, color: "#ccc", fontWeight: "bold", paddingBottom: 10, marginTop: 8, justifyContent: "flex-start" }}
          >
            {((sectionNumber == 0 || sectionNumber == 1) && this.state.shop.shopType != "Cake Factory") ? "Slogan" : "Text"}
          </Text></View>
        <View style={{ alignSelf: "center", width: "72%", justifyContent: "center" }} >
          <Text
            style={{ fontSize: 14, color: "#ccc", paddingBottom: 10, marginTop: 8, justifyContent: "flex-start", fontStyle: "italic" }}
          >
            {txt || "-"}
          </Text>
        </View>
        <View style={{ alignSelf: "center", width: "8%", justifyContent: "center" }} >
          <Image style={{ width: 20, height: 20 }} source={require("../../images/icons/Change.png")} />
          {/* <Text style={{ color: "white", fontSize: 20, fontWeight: "bold", color: "#444", marginBottom: 2 }}>></Text> */}
        </View>
      </View>
    </TouchableOpacity>)
  }
  renderBackgroundPictureBox = (sectionNumber) => {
    let uri = this.state.shop.shopBackgroundPicture || ""
    if (sectionNumber >= 2) uri = this.state.shop["shopBackgroundPicture" + sectionNumber] || ""
    return (<TouchableOpacity onPress={() => { this.setState({ modalVisible: true, activeModal: "backgroundPicture", newPictureURL: this.state.shop.shopBackgroundPicture || "", activeSection: sectionNumber }) }}>
      <View style={{ flexDirection: "row", backgroundColor: "#000", alignSelf: "stretch", borderWidth: 1, borderColor: "#444", borderRadius: 15, padding: 5, marginBottom: 7 }}>
        <View style={{ alignSelf: "center", width: "20%", justifyContent: "center" }} >
          <Text style={{ fontSize: 12, color: "#ccc", fontWeight: "bold", paddingBottom: 10, marginTop: 8, justifyContent: "flex-start" }} >
            Image
      </Text>
        </View>
        <View style={{ alignSelf: "center", width: "72%", justifyContent: "center" }} >
          <View style={{ width: 220, height: 220 * 720 / 1920, marginBottom: -220 * 720 / 1920, justifyContent: "center", alignItems: "center", backgroundColor: "#444" }}>
            <Text style={{ color: "#ccc" }}>Loading</Text>
          </View>
          <Image
            style={{ width: 220, height: 220 * 720 / 1920 }}
            source={{ uri, cache: "force-cache" }}
          />
        </View>
        <View style={{ alignSelf: "center", width: "8%", justifyContent: "center" }} >
          <Image style={{ width: 20, height: 20 }} source={require("../../images/icons/Change.png")} />
          {/* <Text style={{ color: "white", fontSize: 20, fontWeight: "bold", color: "#444", marginBottom: 2 }}>></Text> */}
        </View>
      </View>
    </TouchableOpacity>)
  }


  render() {
    //console.log("STATE SetupScreen ", this.state)
    let shopTypeString = ""
    if (this.state.shop) {
      shopTypeString = this.state.shop.shopType
      if (this.state.shop.shopType == 1) shopTypeString = "Standard"
    }
    if (this.state.loading || !this.state.shop) return (
      <View
        style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "#131313" }}
      >
        <ActivityIndicator size="large" color="#ccc" />
      </View>
    );
    const sHeight = Dimensions.get("window").height
    if (!this.state.loading && this.state.shop) return (
      <SafeAreaView style={{ flex: 1, backgroundColor: this.state.selectedTLD ? "#222" : "#131313" }}>
        <StatusBar barStyle="light-content" />
        <ScrollView style={{ marginTop: Platform.OS == "ios" ? 50 : 85, paddingHorizontal: 15 }}>
          {this.renderMyDomain()}
          <Text style={{ textAlign: "center", fontSize: 20, color: "#ccc", paddingBottom: 10, marginTop: 20, fontWeight: "bold", justifyContent: "flex-start" }}>
            Configuration</Text>
          <Text
            style={{ fontSize: 14, color: "#888", paddingBottom: 10, marginTop: 8, justifyContent: "flex-start" }}
          >
            You can configure your website below (optional)
          </Text>

          <TouchableOpacity onPress={() => { this.setState({ modalVisible: true, activeModal: "shopType", newShopType: this.state.shop.shopType || "Standard" }) }}>
            <View style={{ flexDirection: "row", backgroundColor: "#000", alignSelf: "stretch", borderWidth: 1, borderColor: "#444", borderRadius: 15, padding: 5, marginBottom: 7 }}>
              <View style={{ alignSelf: "center", width: "20%", justifyContent: "center" }} >
                <Text
                  style={{ fontSize: 12, color: "#ccc", fontWeight: "bold", paddingBottom: 10, marginTop: 8, justifyContent: "flex-start" }}
                >
                  Theme
              </Text></View>
              <View style={{ alignSelf: "center", width: "72%", justifyContent: "center" }} >
                <Text
                  style={{ fontSize: 14, color: "#ccc", paddingBottom: 10, marginTop: 8, justifyContent: "flex-start" }}
                >
                  {shopTypeString}
                </Text>
              </View>
              <View style={{ alignSelf: "center", width: "8%", justifyContent: "center" }} >
                <Image style={{ width: 20, height: 20 }} source={require("../../images/icons/Change.png")} />
                {/* <Text style={{ color: "white", fontSize: 20, fontWeight: "bold", color: "#444", marginBottom: 2 }}>></Text> */}
              </View>
            </View>
          </TouchableOpacity>

          {(this.state.shop.shopType != "Engineer" && this.state.shop.shopType != "Cake Factory") ? (<View>
            {this.renderHeaderBox(0)}
            {this.renderSloganBox(0)}
            {this.state.shop.shopType != "Shoppers" && this.state.shop.shopType != "Electro" && this.renderBackgroundPictureBox(0)}
          </View>) : (<View>
            <View style={{ alignSelf: "stretch", borderWidth: 1, borderColor: "#ca4", borderRadius: 5, padding: 5, marginBottom: 7, backgroundColor: "#221" }}>
              <Text
                style={{ fontSize: 12, color: "#ca4", paddingBottom: 3, marginTop: 2, justifyContent: "flex-start", fontWeight: "bold" }}
              >Section 1</Text>
              {this.renderHeaderBox(1)}
              {this.renderSloganBox(1)}
              {this.renderBackgroundPictureBox(1)}
            </View>
            <View style={{ alignSelf: "stretch", borderWidth: 1, borderColor: "#4ac", borderRadius: 5, padding: 5, marginBottom: 7, backgroundColor: "#122" }}>
              <Text
                style={{ fontSize: 12, color: "#4ac", paddingBottom: 3, marginTop: 2, justifyContent: "flex-start", fontWeight: "bold" }}
              >Section 2</Text>
              {this.renderHeaderBox(2)}
              {this.renderSloganBox(2)}
              {this.renderBackgroundPictureBox(2)}
            </View>
            {this.state.shop.shopType != "Cake Factory" && <View style={{ alignSelf: "stretch", borderWidth: 1, borderColor: "#a4c", borderRadius: 5, padding: 5, marginBottom: 7, backgroundColor: "#212" }}>
              <Text
                style={{ fontSize: 12, color: "#a4c", paddingBottom: 3, marginTop: 2, justifyContent: "flex-start", fontWeight: "bold" }}
              >Section 3</Text>
              {this.renderHeaderBox(3)}
              {this.renderSloganBox(3)}
              {this.renderBackgroundPictureBox(3)}
            </View>}
          </View>)
          }




          {this.state.shop.shopType == "Shoppers" && <TouchableOpacity onPress={() => { this.setState({ modalVisible: true, activeModal: "frontPicture", newPictureURL: this.state.shop.shopFrontPicture || "" }) }}>
            <View style={{ flexDirection: "row", backgroundColor: "#000", alignSelf: "stretch", borderWidth: 1, borderColor: "#444", borderRadius: 15, padding: 5, marginBottom: 7 }}>
              <View style={{ alignSelf: "center", width: "20%", justifyContent: "center" }} >
                <Text style={{ fontSize: 12, color: "#ccc", fontWeight: "bold", paddingBottom: 10, marginTop: 8, justifyContent: "flex-start" }} >
                  Front Image
              </Text>
              </View>
              <View style={{ alignSelf: "center", width: "72%", justifyContent: "center" }} >
                <View style={{ width: 150, height: 150, marginBottom: -150, justifyContent: "center", alignItems: "center", borderRadius: 150 }}>
                  {/* <Text style={{ color: "#ccc" }}>Loading</Text> */}
                </View>
                <Image
                  style={{ width: 150, height: 150, borderRadius: 75, marginLeft: 25 }}
                  source={{ uri: this.state.shop.shopFrontPicture || "", cache: "force-cache" }}
                />
              </View>
              <View style={{ alignSelf: "center", width: "8%", justifyContent: "center" }} >
                <Image style={{ width: 20, height: 20 }} source={require("../../images/icons/Change.png")} />
                {/* <Text style={{ color: "white", fontSize: 20, fontWeight: "bold", color: "#444", marginBottom: 2 }}>></Text> */}
              </View>
            </View>
          </TouchableOpacity>}

          {(this.state.shop.shopType != "Shoppers" && this.state.shop.shopType != "Engineer") && <TouchableOpacity onPress={() => { this.setState({ modalVisible: true, activeModal: "logoPicture", newPictureURL: this.state.shop.shopLogoPicture || "" }) }}>
            <View style={{ flexDirection: "row", backgroundColor: "#000", alignSelf: "stretch", borderWidth: 1, borderColor: "#444", borderRadius: 15, padding: 5, marginBottom: 7 }}>
              <View style={{ alignSelf: "center", width: "20%", justifyContent: "center" }} >
                <Text style={{ fontSize: 12, color: "#ccc", fontWeight: "bold", paddingBottom: 10, marginTop: 8, justifyContent: "flex-start" }} >
                  Logo
              </Text>
              </View>
              <View style={{ alignSelf: "center", width: "72%", justifyContent: "center" }} >
                <View style={{ width: 150, height: 150, marginBottom: -150, justifyContent: "center", alignItems: "center", borderRadius: 150 }}>
                  {/* <Text style={{ color: "#ccc" }}>Loading</Text> */}
                </View>
                <Image
                  style={{ width: 150, height: 150, borderRadius: 75, marginLeft: 25 }}
                  source={{ uri: this.state.shop.shopLogoPicture || global.myProfilePictureURL }}
                />
              </View>
              <View style={{ alignSelf: "center", width: "8%", justifyContent: "center" }} >
                <Image style={{ width: 20, height: 20 }} source={require("../../images/icons/Change.png")} />
                {/* <Text style={{ color: "white", fontSize: 20, fontWeight: "bold", color: "#444", marginBottom: 2 }}>></Text> */}
              </View>
            </View>
          </TouchableOpacity>}

          <TouchableOpacity onPress={() => { this.setState({ modalVisible: true, activeModal: "colors", newColorType: "" }) }}>
            <View style={{ flexDirection: "row", backgroundColor: "#000", alignSelf: "stretch", borderWidth: 1, borderColor: "#444", borderRadius: 15, padding: 5, marginBottom: 7 }}>
              <View style={{ alignSelf: "center", width: "20%", justifyContent: "center" }} >
                <Text style={{ fontSize: 12, color: "#ccc", fontWeight: "bold", paddingBottom: 10, marginTop: 8, justifyContent: "flex-start" }} >
                  Colors
              </Text>
              </View>
              {this.renderColorPreview(false)}
              <View style={{ alignSelf: "center", width: "8%", justifyContent: "center" }} >
                <Image style={{ width: 20, height: 20 }} source={require("../../images/icons/Change.png")} />
                {/* <Text style={{ color: "white", fontSize: 20, fontWeight: "bold", color: "#444", marginBottom: 2 }}>></Text> */}
              </View>
            </View>
          </TouchableOpacity>

          {this.state.shop.shopType == "Electro" && <TouchableOpacity onPress={() => { this.setState({ modalVisible: true, activeModal: "promo", newPictureURL: this.state.shop.shopPromoPicture || "" }) }}>
            <View style={{ flexDirection: "row", backgroundColor: "#000", alignSelf: "stretch", borderWidth: 1, borderColor: "#444", borderRadius: 15, padding: 5, marginBottom: 7 }}>
              <View style={{ alignSelf: "center", width: "20%", justifyContent: "center" }} >
                <Text style={{ fontSize: 12, color: "#ccc", fontWeight: "bold", paddingBottom: 10, marginTop: 8, justifyContent: "flex-start" }} >
                  Promo Banner
                </Text>
                {!this.state.shop.shopPromoIsActive && <Text style={{ fontWeight: "100", paddingTop: 10, fontSize: 12, color: "#ccc", justifyContent: "flex-start" }}>(off)</Text>}
              </View>
              {this.renderBannerPreview()}
              <View style={{ alignSelf: "center", width: "8%", justifyContent: "center" }} >
                <Image style={{ width: 20, height: 20 }} source={require("../../images/icons/Change.png")} />
                {/* <Text style={{ color: "white", fontSize: 20, fontWeight: "bold", color: "#444", marginBottom: 2 }}>></Text> */}
              </View>
            </View>
          </TouchableOpacity>}

          <TouchableOpacity onPress={() => { this.setState({ modalVisible: true, activeModal: "social", newSocials: this.state.shop.shopSocials || this.state.newSocials }) }}>
            <View style={{ flexDirection: "row", backgroundColor: "#000", alignSelf: "stretch", borderWidth: 1, borderColor: "#444", borderRadius: 15, padding: 5, marginBottom: 7 }}>
              <View style={{ alignSelf: "center", width: "20%", justifyContent: "center" }} >
                <Text style={{ fontSize: 12, color: "#ccc", fontWeight: "bold", justifyContent: "flex-start" }} >
                  Social Media
                </Text>
              </View>
              {this.renderSocialsPreview()}
              <View style={{ alignSelf: "center", width: "8%", justifyContent: "center" }} >
                <Image style={{ width: 20, height: 20 }} source={require("../../images/icons/Change.png")} />
                {/* <Text style={{ color: "white", fontSize: 20, fontWeight: "bold", color: "#444", marginBottom: 2 }}>></Text> */}
              </View>
            </View>
          </TouchableOpacity>

          {this.state.shop.shopType != "Cart" && <TouchableOpacity onPress={() => { this.setState({ modalVisible: true, activeModal: "cart", newCartStatus: this.state.shop.shopCartStatus || "OFF", newCartBankAccount: this.state.shop.shopCartBankAccount || this.state.newCartBankAccount }) }}>
            <View style={{ flexDirection: "row", backgroundColor: "#000", alignSelf: "stretch", borderWidth: 1, borderColor: "#444", borderRadius: 15, padding: 5, marginBottom: 7 }}>
              <View style={{ alignSelf: "center", width: "20%", justifyContent: "center" }} >
                <Text style={{ fontSize: 12, color: "#ccc", fontWeight: "bold", justifyContent: "flex-start" }} >
                  Cart
                </Text>
              </View>
              {this.renderCartPreview()}
              <View style={{ alignSelf: "center", width: "8%", justifyContent: "center" }} >
                <Image style={{ width: 20, height: 20 }} source={require("../../images/icons/Change.png")} />
                {/* <Text style={{ color: "white", fontSize: 20, fontWeight: "bold", color: "#444", marginBottom: 2 }}>></Text> */}
              </View>
            </View>
          </TouchableOpacity>}

          <TouchableOpacity onPress={() => { this.setState({ modalVisible: true, activeModal: "chat", newChat: this.state.shop.shopChat || this.state.newChat }) }}>
            <View style={{ flexDirection: "row", backgroundColor: "#000", alignSelf: "stretch", borderWidth: 1, borderColor: "#444", borderRadius: 15, padding: 5, marginBottom: 7 }}>
              <View style={{ alignSelf: "center", width: "20%", justifyContent: "center" }} >
                <Text style={{ fontSize: 12, color: "#ccc", fontWeight: "bold", justifyContent: "flex-start" }} >
                  Chat
                </Text>
              </View>
              {this.renderChatPreview()}
              <View style={{ alignSelf: "center", width: "8%", justifyContent: "center" }} >
                <Image style={{ width: 20, height: 20 }} source={require("../../images/icons/Change.png")} />
                {/* <Text style={{ color: "white", fontSize: 20, fontWeight: "bold", color: "#444", marginBottom: 2 }}>></Text> */}
              </View>
            </View>
          </TouchableOpacity>

          {/* xx Comment in the following to re-enable the blog */}
          {/* <TouchableOpacity onPress={() => { this.setState({ modalVisible: true, activeModal: "blog", newBlog: this.state.shop.shopBlog || this.state.newBlog }) }}>
            <View style={{ flexDirection: "row", backgroundColor: "#000", alignSelf: "stretch", borderWidth: 1, borderColor: "#444", borderRadius: 15, padding: 5, marginBottom: 7 }}>
              <View style={{ alignSelf: "center", width: "20%", justifyContent: "center" }} >
                <Text style={{ fontSize: 12, color: "#ccc", fontWeight: "bold", justifyContent: "flex-start" }} >
                  Blog
                </Text>
              </View>
              {this.renderBlogPreview()}
              <View style={{ alignSelf: "center", width: "8%", justifyContent: "center" }} >
                <Image style={{ width: 20, height: 20 }} source={require("../../images/icons/Change.png")} />
              </View>
            </View>
          </TouchableOpacity> */}

          <Text style={{ fontSize: 14, color: "#888", paddingBottom: 10, marginTop: 8, justifyContent: "flex-start" }}  >
            Further information is taken from your profile
              </Text>
          <Text style={{ fontSize: 14, color: "#888", paddingBottom: 10, marginTop: 8, justifyContent: "flex-start" }}  >
            We are constantly adding more website configuration features such as more themes, so keep checking this page.
              </Text>
          <Text style={{ textAlign: "center", fontSize: 20, color: "#ccc", paddingBottom: 10, marginTop: 20, fontWeight: "bold", justifyContent: "flex-start" }}>
            Tell your Friends</Text>
          <Text style={{ fontSize: 14, color: "#888", paddingBottom: 10, marginTop: 8, justifyContent: "flex-start" }}>
            Happy with your website? Tell your friends! Share your referrer code and they will get {this.state.referrerDiscount}% discount when they purchase their site.
          </Text>
          <Text style={{ fontSize: 14, color: "#888", paddingBottom: 10, marginTop: 8, justifyContent: "flex-start" }}>
            YOUR REFERRER CODE: {this.props.shopID.toLowerCase()}
          </Text>
          <Text style={{ fontSize: 14, color: "#888", paddingBottom: 10, marginTop: 8, justifyContent: "flex-start" }}>
            For every website that you refer, you will receive {this.state.referrerCommission}% of the amount paid as a referrer bonus.
          </Text>
        </ScrollView>
        <Modal animationType="fade" transparent={false} visible={this.state.modalVisible} onRequestClose={() => { this.setState({ modalVisible: false }); }}>
          <View style={{ backgroundColor: "#000", height: sHeight, paddingTop: 20 }}>
            <View style={{ height: 50, alignSelf: "flex-start", justifyContent: "center", paddingTop: 10 }}>
              <TouchableOpacity
                style={{ width: 50, alignItems: "center", justifyContent: "center" }}
                onPress={() => { this.setState({ modalVisible: false }) }}
              >
                <View style={{ height: 30, width: 30, backgroundColor: "#556", borderRadius: 15 }}>
                  <Image source={require("../../images/icons/CancelBlack.png")} style={{ height: 30, width: 30 }} />
                </View>
              </TouchableOpacity>
            </View>
            <ScrollView style={{ flex: 1, paddingHorizontal: 15 }}>
              {this.state.activeModal == "slogan" && this.renderSlogan()}
              {this.state.activeModal == "header" && this.renderHeader()}
              {this.state.activeModal == "backgroundPicture" && this.renderBackgroundPicture()}
              {this.state.activeModal == "frontPicture" && this.renderFrontPicture()}
              {this.state.activeModal == "logoPicture" && this.renderLogoPicture()}
              {this.state.activeModal == "promo" && this.renderPromo()}
              {this.state.activeModal == "promoPicture" && this.renderPromoPicture()}
              {this.state.activeModal == "promoTitle" && this.renderPromoTitle()}
              {this.state.activeModal == "promoText" && this.renderPromoText()}
              {this.state.activeModal == "social" && this.renderSocials()}
              {this.state.activeModal == "cart" && this.renderCart()}
              {this.state.activeModal == "chat" && this.renderChat()}
              {this.state.activeModal == "blog" && this.renderBlog()}
              {this.state.activeModal == "shopType" && this.renderShopType()}
              {this.state.activeModal == "colors" && this.renderColors()}
            </ScrollView>
            {this.state.activeModal == "slogan" && (this.state.activeSection == 1 || this.state.activeSection == 0) && <View style={{ height: 120, width: "100%", position: "absolute", bottom: 80, justifyContent: "center", paddingHorizontal: 15 }}>
              <Text style={{ color: "#aaa" }}>ⓘ Your slogan (optional) is a tagline such as "We get things done!" or "Your catering service for Abuja".</Text>
              <Text style={{ color: "#aaa" }}>It will show right below your header on the website.</Text>
            </View>}
            {this.state.activeModal == "header" && <View style={{ height: 120, width: "100%", position: "absolute", bottom: 80, justifyContent: "center", paddingHorizontal: 15 }}>
              <Text style={{ color: "#aaa" }}>ⓘ Your header (optional) is the large text visible on top of the website.</Text>
            </View>}
            {this.state.activeModal == "colors" && <View style={{ height: 50, width: "100%", position: "absolute", bottom: 20, justifyContent: "center", paddingHorizontal: 15 }}>
              <Text style={{ color: "#aaa" }}>ⓘ Changes will take effect immediately so you can check them by loading your website</Text>
            </View>}
            {this.state.activeModal == "shopType" && <View style={{ height: 100, width: "100%", position: "absolute", bottom: 20, justifyContent: "center", paddingHorizontal: 15, backgroundColor: "rgba(0,0,0,0.8)" }}>
              <Text style={{ color: "#aaa" }}>ⓘ Select your theme here. Changes will take effect immediately so you can check them by loading your website. You can always revert to your current theme.</Text>
              <Text style={{ color: "#aaa" }}>Note: changing the theme will change your colors.</Text>
            </View>}
            {this.state.activeModal == "logoPicture" && <View style={{ height: 100, width: "100%", position: "absolute", bottom: 20, justifyContent: "center", paddingHorizontal: 15 }}>
              <Text style={{ color: "#aaa" }}>ⓘ Select your business logo here. You can pick your profile picture or select one specifically for your website.</Text>
            </View>}
            {this.state.activeModal == "promo" && <View style={{ height: 140, width: "100%", position: "absolute", bottom: 20, justifyContent: "center", alignItems: "center", paddingHorizontal: 15 }}>
              <Text style={{ color: "#aaa" }}>ⓘ Activate this banner to announce special promotions or news</Text>
            </View>}
            {this.state.activeModal == "blog" && <View style={{ height: 80, width: "100%", position: "absolute", bottom: 80, justifyContent: "center", alignItems: "center", paddingHorizontal: 15 }}>
              <Text style={{ color: "#aaa" }}>ⓘ You can enable your personal blog section on your website.</Text>
            </View>}
            {this.state.activeModal == "social" && <View style={{ height: 80, width: "100%", position: "absolute", bottom: 80, justifyContent: "center", alignItems: "center", paddingHorizontal: 15 }}>
              <Text style={{ color: "#aaa" }}>ⓘ Enter your Social Media Links here.</Text>
              <Text style={{ color: "#aaa" }}>They are shown at the footer of your website.</Text>
              <Text style={{ color: "#aaa" }}>Empty fields are automatically hidden.</Text>
            </View>}
            {this.state.activeModal == "cart" && <View style={{ height: 120, width: "100%", position: "absolute", bottom: 80, justifyContent: "center", paddingHorizontal: 15, backgroundColor: "rgba(0,0,0,0.8)" }}>
              <Text style={{ color: "#aaa" }}>ⓘ If enabled, customers can put your offers into the shopping cart and directly pay on your site.</Text>
              <Text style={{ color: "#aaa" }}>Only use this if you can guarantee availablity of all your offers and if orders don't require upfront discussion!</Text>
              {/* <Text style={{ color: "#aaa" }}>The shopping cart will only be active if you have entered above information AND selected a cart theme, e.g. theme Modaz</Text> */}
            </View>}
            {this.state.activeModal != "backgroundPicture" && this.state.activeModal != "frontPicture" && this.state.activeModal != "promo" && this.state.activeModal != "promoPicture" && this.state.activeModal != "logoPicture" && this.state.activeModal != "shopType" && this.state.activeModal != "colors" && <View style={{ height: 80, width: "100%", position: "absolute", bottom: 0, alignItems: "center" }}>
              {!this.state.activityIndicatorAnimating ? (
                <TouchableOpacity
                  style={[global.gStyles.buttonXL, { marginHorizontal: 75 }]}
                  onPress={this.submitChanges}
                >
                  <Text style={global.gStyles.buttontXL}>Submit Changes</Text>
                </TouchableOpacity>
              ) : (
                  <ActivityIndicator size="small" color="#fff" style={{ alignSelf: "center", margin: 20 }} animating={true} />
                )}
            </View>}
            {this.state.activeModal == "backgroundPicture" && <View style={{ height: 50, width: "100%", position: "absolute", bottom: 0, alignItems: "center", backgroundColor: "rgba(0,0,0,0.5)" }}>
              <Selector3 view={this.state.selectorSelection} onPress={this.setPictureCategory} />
            </View>}
            {this.state.activeModal == "promoPicture" && <View style={{ height: 50, width: "100%", position: "absolute", bottom: 0, alignItems: "center", backgroundColor: "rgba(0,0,0,0.5)" }}>
              <Selector2 view={this.state.selectorSelectionPromo} onPress={this.setPictureCategoryPromo} />
            </View>}
          </View>
        </Modal>
        {
          this.state.showGetPicture && (<GetPicture
            onDone={async pictureURL => {
              if (pictureURL) {
                await this.setState({ showGetPicture: false, newPictureURL: pictureURL });
                await global.timeout(100)
                this.submitChanges()
              } else this.setState({ showGetPicture: false })
            }} item={this.state.activeModal == "backgroundPicture" ? "WEBSITE_BACKGROUND_PICTURE" : this.state.activeModal == "frontPicture" ? "WEBSITE_FRONT_PICTURE" : this.state.activeModal == "promoPicture" ? "WEBSITE_PROMO_PICTURE" : "WEBSITE_LOGO_PICTURE"} />)
        }
      </SafeAreaView>
    );
  }
}


import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TextInput,
  Image,
  TouchableOpacity,
  TouchableHighlight,
  TouchableWithoutFeedback,
  ScrollView,
  SafeAreaView,
  Keyboard,
  Dimensions,
  ActivityIndicator
} from "react-native";
//import firebase from "../../../firebase/afbase-config";
import firebase from "firebase";
import "firebase/firestore";
import Post from "../../Home/Post/Post";
const gStyles = global.gStyles //    "../../styles/gStyles";

class Selector extends Component {
  render() {
    let height =
      this.props.text.length < 45
        ? 30
        : 45 + (this.props.text.length - 45) * 0.33;
    let circle = filled => {
      if (filled) {
        return (
          <View
            style={{
              width: 20,
              height: 20,
              borderColor: "#999",
              backgroundColor: "#999",
              borderWidth: 1,
              borderRadius: 10
            }}
          />
        );
      } else {
        return (
          <View
            style={{
              width: 20,
              height: 20,
              borderColor: "#556",
              borderWidth: 1,
              borderRadius: 10
            }}
          />
        );
      }
    };

    return (
      <View
        style={{
          height: height,
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        {circle(this.props.selected)}
        <Text style={{ marginLeft: 10, color: "#999" }}>{this.props.text}</Text>
      </View>
    );
  }
}

class SinglePost extends PureComponent {
  state = {
    showPostDetail: false
  };

  render() {
    return (
      <View
        style={{
          width: 170,
          height: 170,
          transform: [{ scale: 0.5 }],
          marginLeft: -75,
          marginTop: -75
        }}
      >
        <TouchableOpacity
          onPress={() => {
            if (!this.state.showPostDetail) {
              this.setState({ showPostDetail: true });
            }
          }}
          style={{ width: 300, height: 300 }}
        >
          <Post
            postID={this.props.postID}
            key={this.props.postID + this.state.showPostDetail}
            navigation={this.props.navigation}
            showDetail={this.state.showPostDetail}
            onHideDetail={() => {
              this.setState({ showPostDetail: false });
            }}
            //relevantStatus={"LIVE"} xxx What if not live any more?!?
            onIsNotRelevant={() => { }}
          />
        </TouchableOpacity>
      </View>
    );
  }
}

export default class MeReportScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    return {
      title: "Report " + navigation.getParam("titleName"),
      headerTransparent: true,
      headerTintColor: "#A8CF45",
      headerTitleStyle: {
        fontWeight: "bold",
        color: "#A8CF45",
        shadowOpacity: 1
      }
    };
  };

  state = {
    text: "",
    rPicture: false,
    rDescription: false,
    rMessages: false,
    rMessagesShare: false,
    rOther: false,
    rChatGroup: false,
    activityIndicatorAnimating: false,
    profileID: "",
    profileName: "",
    profileBusinessName: "",
    profilePicture: "",
    profileBackgroundPicture: "",
    profileDescription: "",
    chatGroup: null,
    community: null,
    error: false
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    const titleName = this.props.navigation.getParam("profileName", "");
    this.props.navigation.setParams({ titleName });
    global.fetchIdToken()
    await this.setState({
      chatGroup: this.props.navigation.getParam("chatGroup", null),
      profileID: this.props.navigation.getParam("profileID", ""),
      profileName: this.props.navigation.getParam("profileName", ""),
      profileBusinessName: this.props.navigation.getParam(
        "profileBusinessName", ""),
      profilePicture: this.props.navigation.getParam("profilePicture", ""),
      profileBackgroundPicture: this.props.navigation.getParam("profileBackgroundPicture", ""),
      profileDescription: this.props.navigation.getParam("profileDescription", ""),
      community: this.props.navigation.getParam("community", null)
    });
    if (!this.state.profileName) {
      this.setState({ error: true });
    }
    console.log("Reporting " + this.state.profileName);
    //console.log("STATE", this.state);
  }

  callFile = async () => {
    if (global.myProfileID) {
      try {
        let chatID = null;
        this.setState({ activityIndicatorAnimating: true });
        const r = await fetch(global.cloudFunctionURL + "newChat", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            profileID1: global.myProfileID,
            profileID2: "F4C7tJCPqxdpawvBpd2B",
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
        await this.setState({ activityIndicatorAnimating: false });
        const r2 = await r.json();
        chatID = r2.chatID;
        console.log("REPORT chatID", chatID);

        let text = `*** GENERATED MESSAGE ***
      
Report about user ${this.state.profileName} (business name: ${this.state.profileBusinessName
          }).
                
REPORT: ${this.state.text}

ASPECTS OF CONCERN:
`;

        if (this.state.community) {
          text = `*** GENERATED MESSAGE ***
      
Report about community ${this.state.profileName}
                
REPORT: ${this.state.text}

ASPECTS OF CONCERN:

+++Community ${this.state.community.name}
+++CommunityID ${this.state.community.xID || "unknown"}
`;
        } if (this.state.rDescription) {
          text =
            text +
            `+++Profile Description ${this.state.profileDescription}
`;
        }
        if (this.state.rPicture) {
          text =
            text +
            `+++Profile Pictures
`;
        }
        if (this.state.rChatGroup) {
          text =
            text +
            `+++Discussion ${this.state.chatGroup.groupTitle}
`;
        }
        if (this.state.rMessages) {
          text = text + `+++Messages `;

          if (!this.state.rMessagesShare) {
            text =
              text +
              `(Customer Care will not read messages between the sender and ${this.state.profileName
              }.)
`;
          }
        }
        if (this.state.rOther) {
          text =
            text +
            `+++Others.
`;
        }
        if (this.props.navigation.getParam("postID", null)) {
          text =
            text +
            `Offer (shared below).
`;
        }
        if (this.props.navigation.getParam("inquiryText", null)) {
          text =
            text +
            `+++Inquiry+++ 
(Special Request: ${this.props.navigation.getParam(
              "inquiryText",
              "-Error. Request text not submitted. Try again-"
            )} )
`;
        }

        if (chatID) {
          this.props.navigation.goBack();
          this.props.navigation.navigate("chatMain", {
            redirect: true,
            redirectParams: {
              chatID,
              text,
              report: "report",
              contactID: this.props.navigation.getParam(
                "isProfileReport",
                false
              )
                ? this.props.navigation.getParam("profileID", "")
                : null,
              postID: this.props.navigation.getParam("postID", null),
              inquiryImageURL: this.props.navigation.getParam(
                "inquiryImageURL",
                null
              ),
              chatProperties: {
                opponentProfileID: "F4C7tJCPqxdpawvBpd2B",
                opponentName: "Customer Care",
                opponentNameBusiness: "Wakanda",
                opponentProfilePictureURL:
                  "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2Fw%20logo%20512X512b.png?alt=media&token=ee27b431-c5bc-42fe-9610-923efb58bce6"
              }
            }
          });

          this.setState({ text: "" });
        }

        console.log("REPORT done", chatID);
      } catch (err) {
        alert("Connection error.");
        console.log(err);
        this.setState({ activityIndicatorAnimating: false });
      }
    }
  };

  render() {
    // allowText =
    //   "Allow Wakanda Customer Care to view my messages with " +
    //   this.state.profileName +
    //   " to investigate my report";
    if (this.state.error) {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "black"
          }}
        >
          <Text style={{ color: "#777" }}>Error. Try again.</Text>
        </View>
      );
    } else {
      return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#000000" }}>
          <TouchableWithoutFeedback
            onPress={Keyboard.dismiss}
            accessible={false}
          >
            <ScrollView style={{ marginTop: 50, paddingHorizontal: 25 }}>
              <Text
                style={{
                  fontSize: 16,
                  color: "#ccc",
                  paddingBottom: 10,
                  borderBottomColor: "#FFFFFF",
                  borderBottomWidth: 0,
                  marginTop: 20,
                  justifyContent: "flex-start"
                }}
              >
                What do you want to report?
              </Text>
              <TextInput
                onChangeText={text => {
                  this.setState({ text });
                }}
                multiline={true}
                numberOfLines={6}
                style={{
                  marginBottom: 20,
                  color: "#ffffff",
                  borderColor: "grey",
                  borderWidth: 0,
                  paddingLeft: 3,
                  borderRadius: 5,
                  height: 180,
                  backgroundColor: "#334"
                }}
                maxLength={1000}
                underlineColorAndroid="transparent"
                defaultValue={this.state.text}
                rowSpan={5}
                bordered
              />
              <View
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 10,
                  marginBottom: 10
                }}
              >
                {!this.props.navigation.getParam("postID", false) &&
                  !this.props.navigation.getParam("community", false) &&
                  !this.props.navigation.getParam("inquiryText", false) ? (
                    <View>
                      <Text
                        style={{
                          fontSize: 16,
                          color: "#ccc",
                          paddingBottom: 10,
                          borderBottomColor: "#FFFFFF",
                          borderBottomWidth: 0,
                          marginTop: 10,
                          justifyContent: "flex-start"
                        }}
                      >
                        What aspect are you referring to?
                    </Text>
                      {this.state.chatGroup && <TouchableHighlight
                        onPress={() => {
                          if (this.state.rChatGroup) {
                            this.setState({ rChatGroup: false });
                          } else {
                            this.setState({ rChatGroup: true });
                          }
                        }}
                      >
                        <Selector
                          selected={this.state.rChatGroup}
                          text="Discussion"
                        />
                      </TouchableHighlight>}
                      <TouchableHighlight
                        onPress={() => {
                          if (this.state.rPicture) {
                            this.setState({ rPicture: false });
                          } else {
                            this.setState({ rPicture: true });
                          }
                        }}
                      >
                        <Selector
                          selected={this.state.rPicture}
                          text="Profile picture(s)"
                        />
                      </TouchableHighlight>
                      <TouchableHighlight
                        onPress={() => {
                          if (this.state.rDescription) {
                            this.setState({ rDescription: false });
                          } else {
                            this.setState({ rDescription: true });
                          }
                        }}
                      >
                        <Selector
                          selected={this.state.rDescription}
                          text="Profile Description"
                        />
                      </TouchableHighlight>
                      <TouchableHighlight
                        onPress={() => {
                          if (this.state.rMessages) {
                            this.setState({
                              rMessages: false,
                              rMessagesShare: false
                            });
                          } else {
                            this.setState({ rMessages: true });
                          }
                        }}
                      >
                        <Selector
                          selected={this.state.rMessages}
                          text="Private Messages"
                        />
                      </TouchableHighlight>
                      <TouchableHighlight
                        onPress={() => {
                          if (this.state.rOther) {
                            this.setState({ rOther: false });
                          } else {
                            this.setState({ rOther: true });
                          }
                        }}
                      >
                        <Selector selected={this.state.rOther} text="Other" />
                      </TouchableHighlight>
                      {!!this.state.rMessages && (
                        <View style={{ marginTop: 30 }}>
                          {/* <TouchableHighlight
                          onPress={() => {
                            if (this.state.rMessagesShare) {
                              this.setState({ rMessagesShare: false });
                            } else {
                              this.setState({ rMessagesShare: true });
                            }
                          }}
                        >
                          <Selector
                            selected={this.state.rMessagesShare}
                            text={allowText}
                          />
                        </TouchableHighlight> */}
                          <Text>State the content xxx</Text>
                        </View>
                      )}
                    </View>
                  ) : (
                    <View>
                      {!!this.props.navigation.getParam("postID", false) && (
                        <View
                          style={{
                            height: 100,
                            marginLeft: 0,
                            marginTop: 20,
                            marginBottom: 20,
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <SinglePost
                            postID={this.props.navigation.getParam(
                              "postID",
                              null
                            )}
                            navigation={this.props.navigation}
                          />
                        </View>
                      )}
                      {!!this.props.navigation.getParam("inquiryText", false) && (
                        <View
                          style={{
                            width: "100%",
                            marginTop: 5,
                            marginBottom: 5,
                            backgroundColor: "#000",
                            borderColor: "#333",
                            borderWidth: 5,
                            padding: 10,
                            borderRadius: 4
                          }}
                        >
                          <Text
                            style={{
                              fontStyle: "italic",
                              color: "#ccc",
                              alignSelf: "flex-start"
                            }}
                          >
                            {"Inquiry text: " +
                              this.props.navigation.getParam(
                                "inquiryText",
                                "Error. Try reloading this page."
                              )}
                          </Text>
                          {!!this.props.navigation.getParam(
                            "inquiryImageURL",
                            null
                          ) && (
                              <Image
                                style={{
                                  marginTop: 10,
                                  width: Dimensions.get("window").width * 0.75,
                                  height: Dimensions.get("window").width * 0.75,
                                  alignSelf: "center"
                                }}
                                source={{
                                  uri: this.props.navigation.getParam(
                                    "inquiryImageURL",
                                    ""
                                  )
                                }}
                              />
                            )}
                        </View>
                      )}
                    </View>
                  )}
                <View style={{ height: 30 }} />
                {!this.state.activityIndicatorAnimating ? (
                  <TouchableOpacity
                    style={global.gStyles.button}
                    onPress={this.callFile}
                  >
                    <View style={{ flexDirection: "row" }}>
                      <ActivityIndicator
                        size="small"
                        color="#000"
                        style={{
                          marginRight: 20,
                          opacity: this.state.activityIndicatorAnimating
                            ? 1.0
                            : 0.0
                        }}
                        animating={true}
                      />
                      <Text style={global.gStyles.buttontext}>File Report</Text>
                      <View style={{ width: 44 }} />
                    </View>
                  </TouchableOpacity>
                ) : (
                    <ActivityIndicator
                      size="small"
                      color="#fff"
                      style={{
                        alignSelf: "center",
                        margin: 20
                      }}
                      animating={true}
                    />
                  )}
              </View>
            </ScrollView>
          </TouchableWithoutFeedback>
        </SafeAreaView>
      );
    }
  }
}

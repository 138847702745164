import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  Alert,
  Dimensions
} from "react-native";
import * as Font from "expo-font";
import { LinearGradient } from "expo-linear-gradient";
import { Feather, AntDesign } from "@expo/vector-icons";
import { SvgCss } from "react-native-svg";
import firebase from "firebase";
import "firebase/firestore";

import * as Find from "../../Find/findFunctions";
import Post from "../../Post/Post";

const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;

// const LikeIcon = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="60" height="60" viewBox="0 0 60 60"><defs><style>.a{fill:#fff;}.b{fill:red;stroke:#707070;}.c{filter:url(#a);}</style><filter id="a" x="0" y="0" width="60" height="60" filterUnits="userSpaceOnUse"><feOffset dy="3" input="SourceAlpha"/><feGaussianBlur stdDeviation="5" result="b"/><feFlood flood-opacity="0.149"/><feComposite operator="in" in2="b"/><feComposite in="SourceGraphic"/></filter></defs><g transform="translate(15 12)"><g class="c" transform="matrix(1, 0, 0, 1, -15, -12)"><rect class="a" width="30" height="30" rx="8" transform="translate(15 12)"/></g><path class="b" d="M6.786,12.075a.8.8,0,0,1-.525-.2c-.548-.479-1.077-.93-1.543-1.327l0,0a28.7,28.7,0,0,1-3.37-3.163A5.052,5.052,0,0,1,0,4.079a4.242,4.242,0,0,1,1.077-2.9A3.65,3.65,0,0,1,3.791,0,3.414,3.414,0,0,1,5.924.736a4.363,4.363,0,0,1,.862.9,4.363,4.363,0,0,1,.862-.9A3.414,3.414,0,0,1,9.78,0,3.65,3.65,0,0,1,12.5,1.182a4.242,4.242,0,0,1,1.077,2.9,5.051,5.051,0,0,1-1.346,3.306,28.7,28.7,0,0,1-3.369,3.163c-.467.4-1,.849-1.546,1.33A.8.8,0,0,1,6.786,12.075ZM3.791.8a2.863,2.863,0,0,0-2.13.925A3.451,3.451,0,0,0,.795,4.079a4.25,4.25,0,0,0,1.163,2.8A28.244,28.244,0,0,0,5.231,9.943l0,0c.468.4,1,.851,1.551,1.334.556-.484,1.087-.937,1.556-1.336a28.249,28.249,0,0,0,3.273-3.065,4.251,4.251,0,0,0,1.163-2.8,3.451,3.451,0,0,0-.866-2.358A2.863,2.863,0,0,0,9.78.8a2.637,2.637,0,0,0-1.646.57,3.858,3.858,0,0,0-.917,1.062.5.5,0,0,1-.862,0,3.854,3.854,0,0,0-.917-1.062A2.637,2.637,0,0,0,3.791.8Zm0,0" transform="translate(8 9)"/></g></svg>`;

export default class ProductPreview extends Component {
  state = {
    secondRow: [],
    showDetail: false,
    detailPostResult: null,
    randomString: "",
    aaLike: false,
    loading: true
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => {
      return;
    };
  }

  async componentDidMount() {
    try {
      if (!global.myUID)
        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID)
        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID)
        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID)
        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID)
        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID)
        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID)
        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID)
        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID)
        await new Promise(resolve => {
          setTimeout(resolve, 1500);
        });
      if (!global.myUID)
        await new Promise(resolve => {
          setTimeout(resolve, 2500);
        });

      await global.setLocationFast(10);

      const re = await Find.findOffersAsync({
        searchTerm: this.props.searchTerm,
        location: global.location || {
          coords: { latitude: 6.6, longitude: 3.3 }
        },
        amount: 4
      });
      if (re == -1) return this.setState({ error: "Error loading offers." });
      if (re.length > 0) this.setState({ secondRow: re, loading: false });

      //console.log("Results from findOffersAsync", re)
    } catch (e) {
      console.warn("Error 923432");
      console.log("Error 923432", e);
    }
  }
  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  likePost = async (isLike, postID) => {
    if (this.state.aaLike) return;
    console.log("Starting to call likePost");
    try {
      this.setState({ aaLike: true })
      if (isLike) {
        global.myLikedPostIDs.push(postID);
        global.myDislikedPostIDs = global.myDislikedPostIDs.filter(id => {
          return id != postID;
        });
      } else {
        global.myDislikedPostIDs.push(postID);
        global.myLikedPostIDs = global.myLikedPostIDs.filter(id => {
          return id != postID;
        });
      }
      this.setState({ randomString: "-" + Math.floor(Math.random() * 9999999999) })
      const r = await fetch(global.cloudFunctionURL + "likePost", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: isLike ? "like" : "unlike",
          postID: postID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();

      console.log("Received response. ", r2.msg);
      this.setState({ aaLike: false })
      if (r2.msg === "SUCCESS") {
        //
      } else {
        // revert setting global
        if (!isLike) {
          global.myLikedPostIDs.push(postID);
          global.myDislikedPostIDs = global.myDislikedPostIDs.filter(id => {
            return id != postID;
          });
        } else {
          global.myDislikedPostIDs.push(postID);
          global.myLikedPostIDs = global.myLikedPostIDs.filter(id => {
            return id != postID;
          });
        }
      }
    } catch (e2) {
      console.warn("ERROR u6");
      console.log("ERROR u6", e2);
      this.setState({ aaLike: false })
    }
  };

  render() {
    if (this.state.loading)
      return (
        <View
          style={{
            height: 200,
            alignItems: "center",
            justifyContent: "center"
            //backgroundColor: "#fff"
          }}
        >
          <ActivityIndicator size="small" color="#000" />
        </View>
      );
    return (
      <View
        style={{
          backgroundColor: "#FFFFFF",
          height: 592,
          // width: SCREEN_HEIGHT - 480,
          width: "90%",
          borderRadius: 12,
          alignItems: "center",
          justifyContent: "center",
          margin: 22,
          alignSelf: "center",
          shadowColor: "#000",
          shadowOpacity: 0.05,
          bottom: 50
        }}
      >
        <View
          style={{
            flexDirection: "row"
          }}
        >
          <View
            style={{
              padding: 10,
              margin: 10,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Text
              style={{
                right: 100,
                bottom: 10,
                fontSize: 12,
                fontFamily: "ProximaNovaA-Bold",
                color: "#333333"
              }}
            >
              {this.props.searchTerm == "Health" ? "Marketplace" : "Hot Now"}
            </Text>
          </View>
          <TouchableOpacity
            style={{ left: 89, bottom: 10 }}
            onPress={() => {
              this.props.navigation.navigate("homeResults", {
                type: "DEFAULT",
                searchTerm: this.props.searchTerm
              });
              global.reportStats("pressed_Product_SeeAll", this.props.searchTerm)
            }}
          >
            <View
              style={{
                padding: 10,
                margin: 10,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: "ProximaNovaA-Regular",
                  color: "#707070"
                  // right: 30
                }}
              >
                See All
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-around",
            justifyContent: "center"
          }}
        >
          {this.state.secondRow.map((item, i) => {
            const result = item;
            let pString = Math.round(result.p).toString();
            if (pString.length > 8) {
              pString = pString.slice(0, pString.length - 6) + "M";
            } else if (pString.length > 6) {
              pString = Number(pString.slice(0, pString.length - 5)) / 10;
              pString = pString.toString() + "M";
            } else if (pString.length > 4) {
              pString = pString.slice(0, pString.length - 3) + "K";
            }

            let pCurrency = result.pc || "NGN";
            let pCurrencyString = global.currencySymbols[pCurrency];

            let priceString = pCurrencyString + " " + pString;

            //localString is acnString
            let localString = "";
            // xx possibly remove NGN from last section of the following line  to show less
            // xx possibly replace last section of the following line with global.xRates[post.postPriceCurrency||"NGN"] to show ACN for all (also old) NGN posts
            if (
              global.walletType == "ACN" &&
              !(result.pc == "ACN") &&
              result.pt != "Free" &&
              result.p > 0 &&
              global.xRates[result.pc || "NGN"]
            ) {
              const lPrice = Math.round(
                result.p / global.xRates[result.pc || "NGN"]
              );
              const lCurrency = global.currencySymbols["ACN"];
              let lString = Math.round(lPrice).toLocaleString(0);
              localString = "≈ " + lCurrency + " " + lString;
            }
            let beforeString = "";
            let afterString = "";
            if (result.pt && result.pt != "Fixed Price") {
              if (result.pt == "Starting From") {
                beforeString = "Starting From";
              } else {
                afterString = "(" + result.pt + ")";
              }
              if (result.pt == "Free") {
                priceString = "FREE";
                localString = "";
                afterString = "";
              }
              if (result.pt == "NONE") {
                priceString = "";
                localString = "";
                afterString = "";
              }
            }

            let isLiked = false;
            if (
              global.myLikedPostIDs &&
              global.myLikedPostIDs.includes(item.xID)
            )
              isLiked = true;

            return (
              <View
                key={i + item.t + this.state.randomString}
                style={{
                  backgroundColor: "#F5F5F5",
                  height: 246,
                  width: 142,
                  borderRadius: 12,
                  margin: 6
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    this.setState({
                      showDetail: true,
                      detailPostResult: result
                    });
                  }}
                >
                  {item.url ? (
                    <Image
                      style={{
                        width: 142,
                        height: 164,
                        borderRadius: 12

                        // shadowColor: "#000",
                        // shadowOpacity: 0.5
                      }}
                      source={{ uri: item.url }}
                      resizeMode="cover"
                    />
                  ) : (
                      <View
                        style={{
                          width: 142,
                          height: 164,
                          borderRadius: 12
                        }}
                      />
                    )}
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    this.likePost(!isLiked, item.xID);
                    console.log("the state of liked", isLiked);
                  }}
                  style={{ position: "absolute", top: 139, left: 95 }}
                >
                  <View
                    style={{
                      margin: 5,
                      padding: 5,
                      backgroundColor: "white",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 7
                    }}
                  >
                    {isLiked == false ? (
                      <AntDesign name="hearto" size={20} color="#707070" />
                    ) : (
                        <AntDesign name="heart" size={20} color="red" />
                      )}
                  </View>
                </TouchableOpacity>

                <View style={{ marginVertical: 13, marginHorizontal: 15 }}>
                  <Text
                    style={{
                      fontFamily: "ProximaNovaA-Bold",
                      fontSize: 12
                    }}
                  >
                    {item.t}
                  </Text>
                </View>
                <View style={{ marginHorizontal: 15 }}>
                  {/* <Text
                    style={{
                      fontFamily: "ProximaNovaA-Bold",
                      fontSize: 12,
                      marginVertical: 5
                    }}
                  >
                    {item.p}
                  </Text> */}
                  <View style={{ marginBottom: 8 }}>
                    {beforeString != "" && (
                      <Text style={[{ fontSize: 10, color: "#ccccd5" }]}>
                        {beforeString}
                      </Text>
                    )}
                    <Text
                      style={[
                        {
                          fontSize: 16,
                          fontWeight: "bold",
                          letterSpacing: 0.48,
                          textAlign: "left",
                          color: "#A8CF45",
                          opacity: 0.8
                        }
                      ]}
                    >
                      {priceString}
                    </Text>
                    {localString != "" && (
                      <Text
                        style={[
                          {
                            fontSize: 12,
                            fontWeight: "bold",
                            letterSpacing: 0.48,
                            textAlign: "left",
                            color: "#A8CF45",
                            opacity: 0.8
                          }
                        ]}
                      >
                        {localString}
                      </Text>
                    )}
                    <Text
                      style={[
                        { fontSize: 10, fontWeight: "500", color: "#ccccd5" }
                      ]}
                    >
                      {afterString}
                    </Text>
                  </View>
                </View>
                <View></View>
              </View>
            );
          })}
        </View>
        {this.state.showDetail && (
          <Post
            postID={this.state.detailPostResult.xID}
            postResult={this.state.detailPostResult}
            //data={this.props.data}
            key={this.state.detailPostResult.xID + this.state.showDetail}
            navigation={this.props.navigation}
            showDetail={this.state.showDetail}
            onHideDetail={() => {
              this.setState({ showDetail: false });
            }}
            relevantStatus={"LIVE"}
            blackBackground={true}
            onIsNotRelevant={() => {
              alert("Offer not available at the moment");
            }}
          />
        )}
      </View>
    );
  }
}

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  TouchableHighlight,
  TouchableWithoutFeedback,
  ScrollView,
  Keyboard,
  ActivityIndicator,
  SectionList,
  Image,
  Modal
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import Moment from "react-moment";
const gStyles = global.gStyles //    "../../../../styles/gStyles";
import Post from "../../../../Home/Post/Post";
import SwipeableView from "../../../../Home/Post/SwipeableView";
import CategorySelector from "../../../../Home/CategorySelectorV2"
import BottomMenu from "../../../../shared/BottomMenu"
import Contact from "../../../Contacts/Contact"



class Selector extends Component {
  render() {
    let height =
      this.props.text.length < 45
        ? 30
        : 45 + (this.props.text.length - 45) * 0.33;
    let circle = filled => {
      if (filled) {
        return (
          <View
            style={{
              width: 20,
              height: 20,
              borderColor: "#99a",
              backgroundColor: "#99a",
              borderWidth: 1,
              borderRadius: 10
            }}
          />
        );
      } else {
        return (
          <View
            style={{
              width: 20,
              height: 20,
              borderColor: "#556",
              borderWidth: 1,
              borderRadius: 10
            }}
          />
        );
      }
    };

    return (
      <View
        style={{
          height: height,
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        {circle(this.props.selected)}
        <Text style={{ marginLeft: 10, color: "#999" }}>{this.props.text}</Text>
      </View>
    );
  }
}

export default class PostApproveScreen extends Component {
  static navigationOptions = {
    headerTransparent: false,
    title: "Offers To Approve",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45"
    },
    headerStyle: {
      backgroundColor: "#222"
      //height: 15,
    }
  };

  state = {
    view: "DEFAULT", // can be "AUTO", "ADD_AUTO"
    posts: [],
    showPostDetail: false,
    showRejectModal: false,
    showOptionsModal: false,
    showCategorySelector: false,
    rejectionReason: "",
    rQuality: false,
    rLegal: false,
    rOther: false,
    rDescription: false,
    rPhoto: false,
    rNeutral: false,
    category1: null,
    category2: null,
    screenStatus: "LOADING",

    showBottomMenuAuto: false,
    autoApprovals: [],
    autoApprovalsLoaded: false,
    resultID: 0,
    resultProfiles: [],
    resultType: null,
    noContactFound: false,
    showSearchBar: true,
    activityIndicatorAnimating: false,
  };

  async componentDidMount() {
    try {
      this.setState({ screenStatus: 'LOADING' });
      this.loadAutoApprovals()
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "getPostsToApprove", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg !== "SUCCESS") {
        console.warn("Error from getPostToApprove")
        console.log("Error", r2)
        alert("Server Error")
        return;
      }

      //SUCCESS
      const posts = r2.posts
      const screenStatus = posts.length == 0 ? "EMPTY" : "ACTIVE";
      console.log("Received Posts. screenStatus:", screenStatus);
      this.setState({ posts, screenStatus });
    } catch (err) {
      console.log("Error", err);
    }
  }

  componentWillUnmount() {
    //this.unsubscribeFirestore();
  }

  loadAutoApprovals = async () => {
    try {
      const r = await fetch(global.cloudFunctionURL + "approvePost", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "getAutoApprovals",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        this.setState({ autoApprovals: r2.results, autoApprovalsLoaded: true })
      } else {
        console.log("Error", r2)
        alert("Server Error. Auto-Approvals not loaded.")
      }
    } catch (err) {
      alert("Request for Auto-Approvals not successful.")
      console.log("Error", err);
    }
  }
  removeAutoApproval = async (profileID) => {
    try {
      if (!profileID) return
      this.setState({ autoApprovalsLoaded: false })
      const r = await fetch(global.cloudFunctionURL + "approvePost", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "removeAutoApprove",
          profileID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        await this.loadAutoApprovals()
      } else {
        console.log("ErrorX", r2)
        alert("Server Error. Auto-Approval likely not removed.")
      }
    } catch (err) {
      alert("Request for Remove not successful.")
      console.log("ErrorX", err);
    }
  }
  addAutoApproval = async (profileID) => {
    try {
      if (!profileID) return
      this.setState({ autoApprovalsLoaded: false, view: "AUTO" })
      const r = await fetch(global.cloudFunctionURL + "approvePost", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "autoApprove",
          profileID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        await this.loadAutoApprovals()
      } else {
        console.log("ErrorY", r2)
        alert("Server Error. Auto-Approval likely not added.")
      }
    } catch (err) {
      alert("Request for ADD not successful.")
      console.log("ErrorY", err);
    }
  }

  nextCard = async (direction, boostFactor) => {

    if (direction == "right") {
      let posts = global.duplicate(this.state.posts);
      const postToUpdate = posts.shift();
      const category1 = this.state.category1
      const category2 = this.state.category2
      this.setState({ posts, showOptionsModal: false, category1: null, category2: null });
      // Add a SwipeRight
      try {
        const r = await fetch(global.cloudFunctionURL + "approvePost", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            postID: postToUpdate.xID,
            action: "approve",
            ...(boostFactor && { boostFactor }),
            ...(category1 && { category1 }),
            ...(category2 && { category2 }),
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
        const r2 = await r.json();
        if (r2.msg == "ERROR") alert(r2.error)
        if (posts.length <= 0) {
          await new Promise(resolve => { setTimeout(resolve, 1000); });
          this.setState({ screenStatus: "EMPTY" })
        }
      } catch (err) {
        console.log("Error in PostApprove", err);
        console.warn("Error in PostApprove")
        alert("Error. Internet Connection?")
      }
    } else {
      // SwipeLeft
      this.setState({ showRejectModal: true });
    }
  };

  rejectPost = async () => {
    let posts = global.duplicate(this.state.posts);
    const postToUpdate = posts.shift();
    // console.log("Updated approvals: ", postToUpdate);
    // console.log("New post array: ", posts);
    this.setState({ posts, showRejectModal: false });

    let reason = this.state.rejectionReason;
    if (this.state.rOther) {
      reason = "Other Reasons. " + reason;
    }
    if (this.state.rLegal) {
      reason = "Potentially illegal, offensive, or obscene content. " + reason;
    }
    if (this.state.rQuality) {
      reason = "Quality/righteousness cannot be guaranteed. " + reason;
    }
    if (this.state.rDescription) {
      reason = "Description must focus only on accurately describing the product or service." + reason;
    }
    if (this.state.rPhoto) {
      reason = "Picture must represent the actual product or service offered. " + reason;
    }
    if (this.state.rNeutral) {
      reason = "Text must be neutral. " + reason;
    }

    try {
      const r = await fetch(global.cloudFunctionURL + "approvePost", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          postID: postToUpdate.xID,
          action: "reject",
          reason,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "ERROR") alert(r2.error)
    } catch (err) {
      console.warn("Error in PostReject2", err);
      console.log("Error in PostReject2", err);
      alert("Error... check network connection")
      return
    }
    if (posts.length <= 0) {
      await new Promise(resolve => { setTimeout(resolve, 1000); });
      this.setState({ screenStatus: "EMPTY" })
    }
  };

  // removePost = async id => {
  //   if (this.state.posts.length < 1) {
  //     alert("No post to skip?")
  //     return;
  //   }
  //   let posts = this.state.posts.filter(function (i) {
  //     return i != id
  //   });
  //   this.setState({ posts });

  //   try {
  //     const r = await fetch(global.cloudFunctionURL + "approvePost", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json"
  //       },
  //       body: JSON.stringify({ isWeb:true,
  //         postID: id,
  //         action: "irrelevant",
  //         myUID: global.myUID,
  //         idToken: global.idToken
  //       })
  //     });
  //     const r2 = await r.json();
  //     if (r2.msg == "ERROR") alert(r2.error)
  //   } catch (err) {
  //     console.log("Error in PostReject", err);
  //   }
  // };

  skip = () => {
    let posts = global.duplicate(this.state.posts);
    const postSkipped = posts.shift();
    posts.push(postSkipped)
    this.setState({ posts });
    console.log("Skipped post ", postSkipped);
  }

  renderAutoApproveButton = (noProviderShown) => {
    return (<TouchableOpacity
      style={{ position: "absolute", top: 0, left: 5, width: 100, height: 50, alignItems: "center", justifyContent: "center" }}
      onPress={() => {
        if (!noProviderShown) this.setState({ showBottomMenuAuto: true })
        else this.setState({ view: "AUTO" })
      }}>
      < View style={[global.gStyles.buttonXW, { margin: 3, minWidth: 65 }]} >
        <Text style={global.gStyles.buttontXW}>Auto-Approve</Text>
      </View >
    </TouchableOpacity >)
  }

  search = async term => {
    try {
      this.setState({ activityIndicatorAnimating: true });
      const r = await fetch(
        global.cloudFunctionURL + "findContact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            phoneNumber: term,
            myUID: global.myUID,
            idToken: global.idToken
          })
        }
      );
      const r2 = await r.json();
      Keyboard.dismiss();
      console.log("Received contact. ", r2.msg);
      this.setState({ activityIndicatorAnimating: false });
      if (r2.msg === "NO_CONTACT_FOUND") {
        this.setState({ noContactFound: true });
        return;
      }
      if (r2.msg === "CONTACT_FOUND") {
        this.setState({ resultID: 0, resultType: null, noContactFound: false });
        await global.timeout(50)
        this.setState({ resultID: r2.contactID, resultType: "singleContact", noContactFound: false });
        return;
      }
      if (r2.msg === "CONTACTS_FOUND") {
        this.setState({ resultProfiles: [], resultType: "multipleContacts", noContactFound: false });
        await global.timeout(50)
        this.setState({ resultProfiles: r2.contacts, resultType: "multipleContacts", noContactFound: false });
        return;
      }
      alert("Error: " + r2.msg);
    } catch (e2) {
      alert("An error occurred. Check internet connection.");
      console.log(e2);
      this.setState({ activityIndicatorAnimating: false });
    }
  };

  renderSingleContact = () => {
    return <ScrollView>
      {this.state.noContactFound && (
        <View
          style={{
            height: 300,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Text style={{ color: "white" }}>No result found.</Text>
        </View>
      )}
      {!this.state.noContactFound &&
        this.state.resultID != 0 && (
          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
              <Contact
                following={[]}
                followed={[]}
                contactID={this.state.resultID}
                navigation={this.props.navigation}
              />
            </View>
            <View style={{ width: 80, alignItems: "flex-end", justifyContent: "center", backgroundColor: "black" }}>
              <TouchableOpacity onPress={() => { this.addAutoApproval(this.state.resultID) }}>
                <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 14, fontWeight: "bold", color: "#000" }}>Add</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        )
      }
    </ScrollView>
  }

  renderMultipleContacts = () => {
    let title = this.state.showSearchBar ? "profiles found" : "phone contacts found on Wakanda"
    if (this.state.resultProfiles.length == 1) title = this.state.showSearchBar ? "profile found" : "phone contact found on Wakanda"
    return <ScrollView>
      {this.state.noContactFound && (
        <View
          style={{
            height: 300,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Text style={{ color: "white" }}>No result found.</Text>
        </View>
      )}
      {!this.state.noContactFound &&
        this.state.resultProfiles && (
          <View style={{ marginBottom: 10 }}>
            <Text style={{ color: "gray", fontSize: 18, marginLeft: 2, marginBottom: 6, alignSelf: "center" }}>{this.state.resultProfiles.length} {title}</Text>
            {this.state.resultProfiles.map((profile, i) => {
              return (<View style={{ flexDirection: "row" }} key={profile.xID}>
                <View style={{ flex: 1 }}>
                  <Contact
                    profile={profile}
                    following={[]}
                    followed={[]}
                    navigation={this.props.navigation}
                  />
                </View>
                <View style={{ width: 80, alignItems: "flex-end", justifyContent: "center", backgroundColor: "black" }}>
                  <TouchableOpacity onPress={() => { this.addAutoApproval(profile.xID) }}>
                    <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                      <Text style={{ fontSize: 14, fontWeight: "bold", color: "#000" }}>Add</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>)


            })}
          </View>
        )}
    </ScrollView>
  }

  renderAdd() {
    if (this.state.resultID == 0 && this.state.noContactFound == false) {
      //??
    }

    return (
      <View style={{ flex: 1, backgroundColor: "#224" }}>
        <View style={{ backgroundColor: "#224", flex: 1 }}>
          <View
            style={{
              borderBottomColor: "#555555",
              borderWidth: 0,
              ...Platform.select({
                ios: {
                  height: 55
                },
                android: {
                  height: 105
                }
              })
            }}
          />
          {!!this.state.showSearchBar && <SearchBar
            onPress={text => {
              this.search(text);
            }}
          />}
          <ActivityIndicator
            size="small"
            color="#FFFFFF"
            style={{
              opacity: this.state.activityIndicatorAnimating ? 1.0 : 0.0
            }}
            animating={true}
          />
          {this.state.resultType !== "multipleContacts" ? this.renderSingleContact() : this.renderMultipleContacts()}


        </View>
      </View>
    );
  }

  renderAutoApprovals = () => {
    if (!this.state.autoApprovalsLoaded)
      return (<View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "#224" }}>
        <ActivityIndicator size="large" color="#aaf" style={{ marginBottom: 10 }} animating={true} />
      </View>);

    return (<View style={{ backgroundColor: "#224", height: "100%", width: "100%" }}>
      <View
        style={{
          borderBottomColor: "#555555", borderBottomWidth: 0,
          ...Platform.select({ ios: { height: 10 }, android: { height: 10 } })
        }}
      />
      <ScrollView style={{ flex: 1 }}>
        <TouchableOpacity
          onPress={async () => { this.setState({ view: "ADD_AUTO" }) }}
          style={{ borderRadius: 20, padding: 6, marginHorizontal: 4, justifyContent: "center", alignSelf: "flex-end", alignItems: "center", marginBottom: 5 }}
        >
          <Text style={{ color: "#fff", fontWeight: "bold", fontSize: 16, }}>+ ADD</Text>
        </TouchableOpacity>
        {(Array.isArray(this.state.autoApprovals) && this.state.autoApprovals.length == 0) && <View style={{ height: 100, justifyContent: "center", padding: 5, flexDirection: "row", alignItems: "center" }} >
          <Text style={{ fontSize: 16, color: "#fff" }}>No Auto-Approvals</Text>
        </View>}
        {(Array.isArray(this.state.autoApprovals) && this.state.autoApprovals.length > 0) && <View><Text style={{ color: "white", fontSize: 16, fontWeight: "bold" }}>Auto-Approvals:</Text></View>}
        {(Array.isArray(this.state.autoApprovals) && this.state.autoApprovals.length > 0) && this.state.autoApprovals.map((ob, i) => {
          const date = global.stampToDate?.(ob.timeSet)
          return (<View key={ob.providerProfileID + "_" + i} style={{ padding: 4, margin: 4, alignSelf: "stretch", backgroundColor: "#ccc", borderRadius: 10 }}>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1 }}>
                <AdminProfile profileID={ob.adminProfileID} date={date} navigation={this.props.navigation} />
              </View>
              <View style={{ width: 35, alignItems: "center", justifyContent: "center" }}>
                <TouchableOpacity onPress={() => { this.removeAutoApproval(ob.providerProfileID) }}>
                  <View style={{ borderRadius: 10, padding: 4 }} >
                    <Text style={{ fontSize: 14, fontWeight: "bold", color: "#000" }}>X</Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
            <Contact
              following={global.myFollowingIDs || []}
              followed={global.myFollowerIDs || []}
              contactID={ob.providerProfileID}
              navigation={this.props.navigation}
            />
          </View>)
        })}
        <View style={{ height: 200 }} />
      </ScrollView>
      <View style={{ height: 70, justifyContent: "center", padding: 5, flexDirection: "row", alignItems: "center" }} >
        <Text style={{ fontSize: 14, color: "#999" }}>ⓘ Auto-Approval providers have their offers approved immediately when created. Used for trusted providers. </Text>
      </View>
    </View>)
  }

  render() {
    if (this.state.view == "ADD_AUTO") return this.renderAdd()
    if (this.state.view == "AUTO") return this.renderAutoApprovals()
    if (this.state.screenStatus == "LOADING") {
      return (
        <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "#224" }}>
          <ActivityIndicator size="large" color="#aab" style={{ marginBottom: 10 }} animating={true} />
        </View>);
    } else if (this.state.screenStatus == "EMPTY" || this.state.posts.length == 0) {
      return (
        <View
          style={{
            flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "#224"
          }}
        >
          <Text style={{ color: "#ccc", textAlign: "center", fontSize: 24 }}>{`✓
No posts to approve`}</Text>
          {this.renderAutoApproveButton(true)}
        </View>
      );
    } else
      return (
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#224"
          }}
        >
          <View
            style={{
              width: 300,
              height: 300,
              marginBottom: -300,
              borderRadius: 30,
              //backgroundColor: "#000",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Image
              style={{ width: 100, height: 60, opacity: 1.0 }}
              source={require("../../../../images/logos/home_logo.png")}
            />
          </View>
          {this.state.posts.length >= 1 && <SwipeableView
            onRelease={a => {
              this.nextCard(a);
            }}
            onClick={() => {
              this.setState({ showPostDetail: true });
            }}
          >
            <View
              style={{
                width: 300,
                height: 300,
                borderRadius: 30,
                backgroundColor: "#445"
              }}
            >
              <Post
                postID={this.state.posts[0].xID}
                data={this.state.posts[0]}
                key={this.state.posts[0].xID + this.state.showPostDetail}
                navigation={this.props.navigation}
                showDetail={this.state.showPostDetail}
                onHideDetail={() => {
                  this.setState({ showPostDetail: false });
                }}
                approverMode={true}
                relevantStatus={"PENDING_APPROVAL"}
                onIsNotRelevant={() => {
                  alert("Post not up for approval? Check with dev team")
                }}
              />
            </View>
          </SwipeableView>}
          <View style={{ height: 150 }} />
          <Modal
            animationType="fade"
            transparent={true}
            visible={this.state.showRejectModal}
            onRequestClose={() => {
              //alert("Modal has been closed.");
            }}
          >
            <View style={{ height: 10 }} />
            <View style={{ backgroundColor: "rgba(52,0,0,.9)" }}>
              <TouchableWithoutFeedback
                onPress={Keyboard.dismiss}
                accessible={false}
              >
                <ScrollView style={{ marginTop: 50, paddingHorizontal: 25 }}>
                  <Text
                    style={{
                      fontSize: 16,
                      color: "#ccc",
                      paddingBottom: 10,
                      borderBottomColor: "#FFFFFF",
                      borderBottomWidth: 0,
                      marginTop: 10,
                      justifyContent: "flex-start"
                    }}
                  >
                    Rejection Reason
                  </Text>
                  <TextInput
                    onChangeText={rejectionReason => {
                      this.setState({ rejectionReason });
                    }}
                    multiline={true}
                    numberOfLines={3}
                    style={{
                      marginBottom: 20,
                      color: "#ffffff",
                      paddingLeft: 3,
                      borderRadius: 5,
                      height: 90,
                      backgroundColor: "#334"
                    }}
                    maxLength={500}
                    underlineColorAndroid="transparent"
                    defaultValue={this.state.rejectionReason}
                    rowSpan={3}
                    bordered
                  />
                  <View
                    style={{
                      marginLeft: 5,
                      marginRight: 5,
                      marginTop: 5,
                      marginBottom: 10
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        color: "#ccc",
                        paddingBottom: 10,
                        borderBottomColor: "#FFFFFF",
                        borderBottomWidth: 0,
                        marginTop: 10,
                        justifyContent: "flex-start"
                      }}
                    >
                      Rejection Category
                    </Text>
                    <TouchableHighlight
                      onPress={() => {
                        if (this.state.rDescription) {
                          this.setState({ rDescription: false });
                        } else {
                          this.setState({ rDescription: true });
                        }
                      }}
                    >
                      <Selector
                        selected={this.state.rDescription}
                        text="Description must focus only on accurately describing the product or service"
                      />
                    </TouchableHighlight>
                    <TouchableHighlight
                      onPress={() => {
                        if (this.state.rPhoto) {
                          this.setState({ rPhoto: false });
                        } else {
                          this.setState({ rPhoto: true });
                        }
                      }}
                    >
                      <Selector
                        selected={this.state.rPhoto}
                        text="Picture must represent the actual product or service offered"
                      />
                    </TouchableHighlight>
                    <TouchableHighlight
                      onPress={() => {
                        if (this.state.rNeutral) {
                          this.setState({ rNeutral: false });
                        } else {
                          this.setState({ rNeutral: true });
                        }
                      }}
                    >
                      <Selector
                        selected={this.state.rNeutral}
                        text="Text must be neutral"
                      />
                    </TouchableHighlight>
                    <TouchableHighlight
                      onPress={() => {
                        if (this.state.rQuality) {
                          this.setState({ rQuality: false });
                        } else {
                          this.setState({ rQuality: true });
                        }
                      }}
                    >
                      <Selector
                        selected={this.state.rQuality}
                        text="Quality/righteousness cannot be guaranteed"
                      />
                    </TouchableHighlight>
                    <TouchableHighlight
                      onPress={() => {
                        if (this.state.rLegal) {
                          this.setState({ rLegal: false });
                        } else {
                          this.setState({ rLegal: true });
                        }
                      }}
                    >
                      <Selector
                        selected={this.state.rLegal}
                        text="Potentially illegal, offensive, or obscene content"
                      />
                    </TouchableHighlight>
                    {/* <TouchableHighlight
                    onPress={() => {
                      if (this.state.rMessages) {
                        this.setState({
                          rMessages: false,
                          rMessagesShare: false
                        });
                      } else {
                        this.setState({ rMessages: true });
                      }
                    }}
                  >
                    <Selector
                      selected={this.state.rMessages}
                      text="Private Messages"
                    />
                  </TouchableHighlight> */}
                    <TouchableHighlight
                      onPress={() => {
                        if (this.state.rOther) {
                          this.setState({ rOther: false });
                        } else {
                          this.setState({ rOther: true });
                        }
                      }}
                    >
                      <Selector selected={this.state.rOther} text="Other" />
                    </TouchableHighlight>
                    <View style={{ height: 30 }} />
                    <TouchableOpacity
                      style={global.gStyles.button}
                      onPress={this.rejectPost}
                    >
                      <Text style={global.gStyles.buttontext}>Reject</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{ alignItems: "center", marginTop: 20, marginBottom: 20 }}
                      onPress={() => {
                        this.setState({ showRejectModal: false });
                      }}
                    >
                      <Text style={{ color: "#999" }}>Cancel</Text>
                    </TouchableOpacity>
                  </View>
                  <View style={{ height: 150 }} />
                </ScrollView>
              </TouchableWithoutFeedback>
            </View>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={this.state.showOptionsModal}
            onRequestClose={() => {
              //alert("Modal has been closed.");
            }}
          >
            <View style={{ backgroundColor: "rgba(0,52,0,.9)", height: "100%" }}>
              <TouchableWithoutFeedback
                onPress={Keyboard.dismiss}
                accessible={false}
              >
                <View style={{ margin: 25 }}>
                  <Text style={{ fontSize: 28, color: "#ccc", marginTop: 50, marginBottom: 25, alignSelf: "flex-start", fontWeight: "bold" }}>
                    Options</Text>
                  <View style={{ height: 50 }} />
                  {!this.state.showCategorySelector ? <TouchableOpacity
                    style={global.gStyles.buttonXLW}
                    onPress={() => { this.setState({ showCategorySelector: true }) }}
                  >
                    <Text style={global.gStyles.buttontXLW}>Change Category</Text>
                  </TouchableOpacity>
                    : <CategorySelector
                      noText={false}
                      text={"Select a new offer category"}
                      backButton={false}
                      backgroundColor={"rgba(0,52,0,1)"}
                      category1={this.state.category1}
                      category2={this.state.category2}
                      onSelectCategory={(category1, category2) => {
                        this.setState({ category1, category2 })
                      }}
                    />}
                  <View style={{ height: 50 }} />
                  <TouchableOpacity
                    style={global.gStyles.button}
                    onPress={() => { this.nextCard("right", 2) }}
                  >
                    <Text style={global.gStyles.buttontext}>{"Approve & Boost"}</Text>
                  </TouchableOpacity>
                  <Text style={{ fontSize: 14, color: "#b9b9c1", justifyContent: "flex-start" }}>
                    Boosted Offers will appear higher on the search results. You can edit boosting of offers later.</Text>
                  <View style={{ height: 30 }} />
                  <TouchableOpacity
                    style={global.gStyles.button}
                    onPress={() => { this.nextCard("right") }}
                  >
                    <Text style={global.gStyles.buttontext}>Approve</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{ alignItems: "center", marginTop: 50, marginBottom: 20 }}
                    onPress={() => { this.setState({ showOptionsModal: false, category1: null, category2: null }); }}
                  >
                    <Text style={{ color: "#b9b9c1" }}>Cancel</Text>
                  </TouchableOpacity>
                </View>
              </TouchableWithoutFeedback>
            </View>
          </Modal>
          <View style={{ height: 50, position: "absolute", left: 0, bottom: 5, justifyContent: "center", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
            <TouchableOpacity
              onPress={() => { this.setState({ showRejectModal: true }); }}
              style={{ flexDirection: "row", alignItems: "center", height: 50, backgroundColor: "rgba(52,0,0,.9)", borderRadius: 6, padding: 2 }}>
              <Image
                source={require("../../../../images/icons/SwipeLeft.png")}
                style={{ height: 20, width: 13.2, marginHorizontal: 8, transform: [{ scale: 1.2 }] }}
              />
              <Text style={{ color: "#8a9" }}>REJECT</Text>
            </TouchableOpacity>
            {this.state.posts && this.state.posts.length > 1 && <TouchableOpacity
              onPress={this.skip}
              style={{ height: 50, justifyContent: "center", alignItems: "center", backgroundColor: "rgba(0,0,52,.9)", borderRadius: 6, padding: 2 }}>
              <Text style={{ color: "#8a9" }}>NEXT ({this.state.posts.length - 1})</Text>
              <View style={{ flexDirection: "row", alignItems: "center", }}>
                {this.state.posts.length > 1 && <Image
                  style={{ width: 25, height: 25, opacity: 1.0, marginHorizontal: 4, borderRadius: 2.5 }}
                  source={{ uri: this.state.posts[1].postPictureURL }}
                />}
                {this.state.posts.length > 2 && <Image
                  style={{ width: 25, height: 25, opacity: 1.0, marginHorizontal: 4, borderRadius: 2.5 }}
                  source={{ uri: this.state.posts[2].postPictureURL }}
                />}
                {this.state.posts.length > 3 && <Image
                  style={{ width: 25, height: 25, opacity: 1.0, marginHorizontal: 4, borderRadius: 2.5 }}
                  source={{ uri: this.state.posts[3].postPictureURL }}
                />}
              </View>
            </TouchableOpacity>}
            <TouchableOpacity
              onPress={() => { this.setState({ showOptionsModal: true }); }}
              style={{ alignItems: "center", height: 50, justifyContent: "center", backgroundColor: "rgba(0,0,52,.9)", borderRadius: 6, padding: 2 }}>
              <Text style={{ color: "#8a9" }}>OPTIONS</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => { this.nextCard("right") }}
              style={{ flexDirection: "row", alignItems: "center", height: 50, backgroundColor: "rgba(0,52,0,.9)", borderRadius: 6, padding: 2 }}>
              <Image
                source={require("../../../../images/icons/SwipeRight.png")}
                style={{ height: 20, width: 13.2, marginHorizontal: 8, transform: [{ scale: 1.2 }] }}
              />
              <Text style={{ color: "#8a9" }}>APPROVE</Text>
            </TouchableOpacity>
          </View>
          <BottomMenu items={["Show All", "Add this provider"]}
            visible={this.state.showBottomMenuAuto}
            onSelectItem={async (item) => {
              if (item == "Add this provider") {
                if (this.state.posts?.length == 0) return alert("No post showing")
                const profileID = this.state.posts[0].authorProfileID
                if (!profileID) return alert("Error ap")
                this.addAutoApproval(profileID)
              }
              if (item == "Show All") {
                this.setState({ view: "AUTO" })
              }
            }}
            onClose={() => { this.setState({ showBottomMenuAuto: false }) }}
            title={"Auto-Approvals"}
          />
          {this.renderAutoApproveButton()}
        </View>
      );
  }
}

export class AdminProfile extends Component {
  state = { name: " ✧ ✧ ✧ ✧ ✧ ✧", pictureURL: null }
  async componentDidMount() {
    try {
      const profileDoc = await firebase
        .firestore()
        .collection("Profiles")
        .doc(this.props.profileID)
        .get()
      const profile = profileDoc.data()
      this.setState({ pictureURL: profile.profilePictureURL, name: profile.profileName || "--" })
    } catch (error) {
      global.warn(error, "sdfx");
    }
  }
  render() {
    return (<View style={{ alignSelf: "stretch", borderRadius: 0 }}>
      <TouchableOpacity onPress={() => { this.props.navigation.navigate("meProfile", { profileID: this.props.profileID }) }}>
        <View style={{ alignSelf: "stretch", flexDirection: "row", alignItems: "center" }}>
          {this.state.pictureURL ? <Image
            style={{ width: 35, height: 35, borderRadius: 17, }}
            source={{ uri: this.state.pictureURL }}
          /> : <View style={{ width: 35, height: 35, borderRadius: 17, backgroundColor: "gray" }} />}
          <View style={{ marginLeft: 10 }}>
            <Text style={{ marginBottom: 2, fontWeight: "bold" }}>{this.state.name}</Text>
            <Text style={{ marginBottom: 2, fontSize: 12 }}>added this provider <Moment fromNow element={Text}>{this.props.date}</Moment></Text>
          </View>
        </View>
      </TouchableOpacity>
    </View >);
  }
}

class SearchBar extends Component {
  state = {
    searchTerm: "",
    countryCode: global.walletType == "ACN" ? "+233" : "+234",
    modalVisible: false
  };

  callSearch = () => {

    let submissionSearchTerm = "";
    const searchLetters = this.state.searchTerm.replace(/[^a-zA-Z]/g, "")

    if (searchLetters.length > 0) { //is a business name
      submissionSearchTerm = this.state.searchTerm

    } else { //is a number
      let enteredNumber = this.state.searchTerm.replace(/[^\d]/g, "");
      if (enteredNumber.startsWith("00")) {
        alert("Invalid phone number"); return;
      }
      if (enteredNumber.startsWith("0")) {
        enteredNumber = enteredNumber.slice(1);
      }
      submissionSearchTerm = this.state.countryCode + enteredNumber;
      //console.log("pn", submissionSearchTerm, this.state.countryCode, enteredNumber)
    }
    this.props.onPress(submissionSearchTerm);
  }

  render() {
    const countryCodes = global.countryCodes

    let searchLetters = this.state.searchTerm.replace(/[^a-zA-Z]/g, "")
    let showLeft = !(searchLetters.length > 0) //Shows +234 immediately
    //showLeft = !(searchLetters.length > 0 || this.state.searchTerm.length == 0) //Shows +234 after first number typed

    return (
      <View
        style={{
          flexDirection: "row",
          alignSelf: "center",
          height: 40,
          width: "90%",
          backgroundColor: "#aaa",
          borderRadius: 20,
          borderBottomWidth: 0,
        }}
      >
        {!!showLeft && <TouchableOpacity
          onPress={() => {
            this.setState({ modalVisible: true });
          }}
          style={{
            alignItems: "center",
            alignSelf: "flex-start",
            padding: 1,
            borderRightWidth: 1,
            borderRightColor: "gray",
            marginRight: 4.5,
            width: 65,
            height: 40,
            justifyContent: "center",
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            borderColor: "gray"
          }}
        >
          <Text>{this.state.countryCode}</Text>
        </TouchableOpacity>}
        <TextInput
          style={{
            alignSelf: "stretch",
            height: 40,
            marginBottom: 20,
            color: "#FFFFFF",
            backgroundColor: "#aaa",
            borderRadius: 33,
            borderBottomWidth: 0,
            color: "black",
            paddingLeft: 10,
            flex: 1
          }}
          placeholder="Search Phone Number / Business Name"
          placeholderTextColor="#000"
          //keyboardType="numeric"
          underlineColorAndroid="transparent"
          underlineColor={"transparent"}
          returnKeyType="search"
          onChangeText={searchTerm => this.setState({ searchTerm })}
          onSubmitEditing={this.callSearch}
          ref={component => (this.myTextInput = component)}
          // clearButtonMode="always" //xx how set colour?
          value={this.state.text}
          keyboardDismissMode={'on-drag'}
          keyboardAppearance={"dark"}
          autoCompleteType={'off'}
          autoCapitalize={'none'}
          autoCorrect={false}
        />

        {this.state.searchTerm.length > 4 && (
          <TouchableOpacity
            style={{
              alignItems: "center",
              alignSelf: "flex-start",
              padding: 1,
              backgroundColor: "#A8CF45",
              marginLeft: 4.5,
              width: 47,
              height: 40,
              justifyContent: "center",
              borderTopEndRadius: 20,
              borderBottomEndRadius: 20,
              borderColor: "gray",
              borderLeftWidth: 1
            }}
            onPress={this.callSearch}
          >
            <Image
              style={{
                width: 18,
                height: 18,
                marginRight: 1
              }}
              source={require("../../../../images/icons/Search.png")}
            />
          </TouchableOpacity>
        )}
        <Modal
          animationType="slide"
          transparent={false}
          visible={this.state.modalVisible}
          onRequestClose={() => {
            this.setState({ modalVisible: false });
          }}
        >
          <TouchableOpacity
            onPress={() => this.setState({ modalVisible: false })}
            style={{
              height: 50,
              backgroundColor: "#000"
            }}
          />
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#000"
            }}
          >
            <View
              style={{
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <View style={{ paddingTop: 20 }}>
                <Text style={{ fontSize: 30, color: "white" }}>
                  Select Country
                  </Text>
              </View>
              <View style={{ marginTop: 0, marginBottom: 5 }} />
              <SectionList
                renderItem={({ item, index, section }) => (
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({
                        modalVisible: false,
                        countryCode: item.split("(")[1].split(")")[0]
                      });
                    }}
                    style={{
                      flex: 1,
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 20,
                      width: Dimensions.get("window").width,
                      backgroundColor: "#112",
                      borderWidth: 1,
                      borderColor: "#223"
                    }}
                  >
                    <Text
                      style={{ fontSize: 16, color: "white" }}
                      key={index}
                    >
                      {item}
                    </Text>
                  </TouchableOpacity>
                )}
                renderSectionHeader={({ section: { title } }) => (
                  <Text style={{ fontWeight: "bold" }}>{title}</Text>
                )}
                sections={[
                  {
                    title: "",
                    data: countryCodes
                  }
                ]}
                keyExtractor={(item, index) => item + index}
              />
            </View>
          </View>
          <TouchableOpacity
            style={{
              position: "absolute",
              borderWidth: 0,
              backgroundColor: "#556",
              borderColor: "rgba(0,0,0,0.2)",
              alignItems: "center",
              justifyContent: "center",
              width: 20,
              top: 20,
              left: 20,
              height: 25,
              borderRadius: 30
            }}
            onPress={() => this.setState({ modalVisible: false })}
          >
            <Image
              style={{ width: 25, height: 25, opacity: 1 }}
              source={require("../../../../images/icons/CancelBlack.png")}
            />
            {/* <Text style={{ fontSize: 26, color: "#666" }}>x</Text> */}
          </TouchableOpacity>
        </Modal>
      </View>
    );
  }
}
import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  TouchableHighlight,
  TouchableWithoutFeedback,
  ScrollView,
  SafeAreaView,
  ActivityIndicator,
  Platform,
  Linking,
  Image,
  Modal,
  Dimensions
} from "react-native";
import { NavigationEvents } from "react-navigation";
import firebase from "firebase";
import "firebase/firestore";

export default class HealthProfileScreen extends Component {
  initialState = {
    profileID: null,
    profileName: "*********",
    profileJobTitle: "***************",
    profileDescription: "",
    profileCenterName: "",
    profilePictureURL: "",
    pictureSize: 1,
    isMyProfile: true,
    modalMenuEditVisible: false,
    activityIndicatorAnimating: false,
    pictureChanged: false,
    screenHasBeenLoadedOnceAlready: false,
    loading: true,
  };

  state = this.initialState

  static navigationOptions = ({ navigation }) => {
    return {
      title: " ",
      headerTransparent: true,
      headerTintColor: "red",
      headerTitleStyle: {
        fontWeight: "bold",
        color: "red",
      },

      headerRight: () => {
        if (!navigation.getParam("profileID", null)) {
          return (
            <View style={{ marginRight: 20, elevation: 4 }}>
              <TouchableOpacity
                onPress={navigation.getParam("optionMenuEdit")}
                style={{
                  height: 36, width: 36, borderTopRightRadius: 10, borderBottomRightRadius: 10, justifyContent: "center", alignItems: "center"
                }}
              >
                <Image
                  style={{ width: 28, height: 28, tintColor: "red" }}
                  source={require("../../../images/icons/Change.png")}
                />
              </TouchableOpacity>
            </View>
          )
        } else {
          return (
            <View style={{ marginRight: 10, elevation: 4 }}>
              {/* <TouchableOpacity onPress={navigation.getParam("optionMenu")}>
                <Text
                  style={{
                    fontWeight: "bold", fontSize: 24, color: "red", shadowOpacity: 1, elevation: 1, padding: 10
                  }}
                >
                  ⋮
            </Text>
              </TouchableOpacity> */}
            </View>
          )
        }
      }
    };
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    this.props.navigation.setParams({
      optionMenu: this.optionMenu
    });
    this.props.navigation.setParams({
      optionMenuEdit: this.optionMenuEdit
    });

    if (!global.myUID || !global.myHealthProfileID) await new Promise(resolve => { setTimeout(resolve, 200); });
    if (!global.myUID || !global.myHealthProfileID) await new Promise(resolve => { setTimeout(resolve, 500); });
    if (!global.myUID || !global.myHealthProfileID) await new Promise(resolve => { setTimeout(resolve, 1000); });
    if (!global.myUID || !global.myHealthProfileID) await new Promise(resolve => { setTimeout(resolve, 1500); });
    if (!global.myUID || !global.myHealthProfileID) await new Promise(resolve => { setTimeout(resolve, 5000); });
    if (!global.myUID || !global.myHealthProfileID) return;


    let pID = global.myHealthProfileID;
    try {

      let paramID = this.props.navigation.getParam("profileID", null);
      if (paramID) {
        pID = paramID;
        if (pID != global.myProfileID) {
          this.setState({ isMyProfile: false });
        }
      }
      this.fRef = firebase
        .firestore()
        .collection("Health")
        .doc("data")
        .collection("HealthProfiles")
        .doc(pID);
      this.unsubscribeFirestore = this.fRef.onSnapshot(this.updateProfile);

    } catch (err) {
      alert("Error loading health profile. Check internet connection.");
      console.log("Error loading health profile. Check internet connection.", err);
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  updateProfile = async pDoc => {
    if (!pDoc) return;
    const p = pDoc.data();
    if (!p) return;

    this.setState({
      loading: false,
      profileName: p.profileName || "",
      profileCenterName: p.profileCenterName || "",
      profileJobTitle: p.profileJobTitle || "",
      profilePictureURL: p.profilePictureURL || "",
      profileDescription: p.profileDescription || "",
    });

    if (!this.state.screenHasBeenLoadedOnceAlready) {
      await global.timeout(3000)
      this.setState({ screenHasBeenLoadedOnceAlready: true })
    }
  };

  optionMenu = () => {
    this.setState({ modalMenuVisible: true });
  };
  optionMenuEdit = () => {
    this.setState({ modalMenuEditVisible: true });
  };


  render() {
    const a = this.state.pictureSize;
    const colF = "#000";

    if (this.state.loading) {
      return (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "white" }}>
          <ActivityIndicator size="large" color="red" />
        </View>
      );
    } else {
      return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#e3e3e3" }}>
          <ScrollView style={{ backgroundColor: "#fff", flex: 1 }}>
            <NavigationEvents
              onDidFocus={async payload => {
                //xx replace this code when stack navigator is understood
                // or put it under componentWillUpdate?
                //check if this screen needs to be rerendered, then rerender
                try {
                  if (
                    this.props.navigation.getParam("profileID", "none") !=
                    "none" &&
                    this.state.profileID !=
                    this.props.navigation.getParam("profileID", null)
                    && this.state.screenHasBeenLoadedOnceAlready
                  ) {
                    await this.unsubscribeFirestore();
                    await this.setState(this.initialState);
                    this.componentDidMount();
                  }
                } catch (err) {
                  global.warn(err, "EA 12")
                }
                console.log("profile reloaded");
              }}
            />
            {/***** Background Image *****/}
            <View
              style={{
                height: 180,
                justifyContent: "flex-start",
                backgroundColor: "transparent",
                alignItems: "center",
                marginBottom: 0
                //shadowOpacity: 0,
                //shadowOffset: { width: 0, height: 1 },
                //shadowColor: "white"
              }}
            >
              <Image
                style={{ width: Dimensions.get("window").width, height: 180 }}
                source={{ uri: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2Fhealth-2082630_640.jpg?alt=media&token=690fea2e-7f71-4c7d-ad00-1dca0df90859" }}
                key={"BP"}
              />
            </View>
            <View
              style={{
                ...Platform.select({
                  ios: {
                    height: 0
                  },
                  android: {
                    height: 180,
                    backgroundColor: "rgba(44, 44, 44, 0.3)",
                    marginTop: -180,
                    marginBottom: 0
                  }
                })
              }}
            />

            {/***** Profile Image *****/}
            <View
              style={{
                marginTop: -52 * a,
                height: 104 * a,
                alignItems: "center"
              }}
            >
              <View>
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    width: 104 * a,
                    height: 104 * a,
                    borderRadius: 32 * a,
                    backgroundColor: "#fff",
                    shadowOpacity: 1,
                    shadowColor: "white",
                    shadowOffset: { height: 0, width: 0 },
                    shadowRadius: 32 * a
                  }}
                />
                {!!this.state.profilePictureURL && [
                  <Image
                    key={this.state.profilePictureURL + "2"}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      width: 104 * a,
                      height: 104 * a,
                      borderRadius: 32 * a,
                      marginBottom: -102 * a,
                      marginTop: -104 * a,
                      opacity: 0.8
                    }}
                    source={{
                      uri: this.state.profilePictureURL
                    }}
                  />,
                  <TouchableOpacity
                    key={"key002"}
                    onPress={() => {
                      this.setState({
                        pictureSize: this.state.pictureSize == 1 ? 2 : 1
                      });
                    }}
                  >
                    <Image
                      key={this.state.profilePictureURL}
                      style={{
                        width: 100 * a,
                        height: 100 * a,
                        borderRadius: 30 * a,
                        marginLeft: 2 * a
                      }}
                      source={{
                        uri: this.state.profilePictureURL
                      }}
                    />
                  </TouchableOpacity>
                ]}
                <View
                  style={{
                    marginBottom: -100 * a,
                    width: 100 * a,
                    height: 100 * a,
                    borderRadius: 30 * a,
                    marginLeft: 2 * a,
                    marginTop: 2 * a,
                  }}
                />
              </View>
            </View>

            {/***** Profile Names *****/}
            <View style={{ alignItems: "center", marginTop: 30 }}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ fontSize: 24, fontWeight: "bold", color: colF }}>
                  {this.state.profileName}
                </Text>
              </View>
              <Text style={{ fontSize: 18, color: colF, marginTop: 4 }}>
                {this.state.profileJobTitle}
              </Text>
              <Text style={{ fontSize: 18, color: colF, marginTop: 4 }}>
                {this.state.profileCenterName}
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 5
                }}
              >
              </View>

              {/***** Description Text *****/}
              <View
                style={{
                  paddingLeft: 15,
                  paddingRight: 15,
                  marginTop: 30
                }}
              >
                <Text
                  style={{
                    color: colF,
                    fontSize: this.state.profileDescription.length <= 200 ? 16 : 14
                  }}
                >
                  {this.state.profileDescription}
                </Text>
              </View>
            </View>
          </ScrollView>

          <Modal
            animationType="fade"
            transparent={true}
            visible={this.state.modalMenuEditVisible}
            onRequestClose={() => {
              //alert("Modal has been closed.");
            }}
          >
            <TouchableWithoutFeedback
              onPress={() => this.setState({ modalMenuEditVisible: false })}
            >
              <View
                style={{
                  height: "100%",
                  width: "100%",
                  alignItems: "flex-end"
                }}
              >
                <View
                  style={{
                    marginRight: 20,
                    marginTop: 60,
                    height: 100,
                    width: 200,
                    backgroundColor: "rgba(0,0,0,.9)",
                    borderRadius: 5,
                    borderColor: "#ccc",
                    borderWidth: 1
                  }}
                >
                  <TouchableOpacity
                    style={{
                      height: 50,
                      width: 200,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 15,
                      borderBottomWidth: 1,
                      borderColor: "#ccc"
                    }}
                    onPress={async () => {
                      this.setState({ modalMenuEditVisible: false });
                      await new Promise(resolve => {
                        setTimeout(resolve, 100);
                      });
                      let route = "healthProfileUpdate"
                      // if (this.props.navigation.state && this.props.navigation.state.routeName && this.props.navigation.state.routeName.startsWith("home"))
                      //   route = "homeProfileUpdate"
                      this.props.navigation.navigate(route);
                    }}
                  >
                    <Text style={{ color: "#ccc", fontWeight: "bold" }}>
                      Edit Profile
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </TouchableWithoutFeedback>
          </Modal>

        </SafeAreaView >
      );
    }
  }
}

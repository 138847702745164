import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  Modal,
  TouchableWithoutFeedback,
  ScrollView,
  SafeAreaView,
  Keyboard,
  Platform,
  ActivityIndicator
} from "react-native";
import * as SecureStore from 'expo-secure-store';
import Constants from 'expo-constants';
import firebase from "firebase";
import "firebase/firestore";
const gStyles = global.gStyles //    "../../styles/gStyles";
import CryptoJS from "react-native-crypto-js";

const Stellar = require("stellar-base");
//Stellar.Network.usePublicNetwork();


export default class MePayScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    return {
      title: "Pay " + navigation.getParam("recipientName"),
      headerTransparent: true,
      headerTintColor: "#A8CF45",
      headerTitleStyle: {
        fontWeight: "bold",
        color: "#A8CF45",
        shadowOpacity: 1
      }
    };
  };

  state = {
    walletType: global.walletType,
    amount: 0,
    amountLocal: 0,
    remarks: "",
    myWalletBalance: null,
    activityIndicatorAnimating: false,
    acnChecksDone: false,
    error: false,

    showModal: false,
    recipientName: "?",

    action: "payment", //"website" completes a website purchase //"order" executes an escrow action
    loadingAction: false
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
    this.wap = "";
    global.Buffer = require('buffer').Buffer;
  }

  async componentDidMount() {
    this.updateAccountBalance();
    this.checkWalletType()
    if (global.walletType == "ACN") this.checkAppUpdate()
    this.setState({ recipientName: this.props.navigation.getParam("recipientName") })

    if (this.props.navigation.getParam("action") && this.props.navigation.getParam("action") != "payment") {
      await this.setState({
        action: this.props.navigation.getParam("action"),
        amount: this.props.navigation.getParam("amount"),
        remarks: this.props.navigation.getParam("remarks"),
        myWalletBalance: this.props.navigation.getParam("tmpWalletBalance"),
        loadingAction: true
      })
    }
    const currency = this.props.navigation.getParam("amountCurrency")
    if (global.walletType == "ACN" && currency == "ACN" || global.walletType == "NGN" && currency == "NGN")
      this.callPay()
  }




  updateAccountBalance = async () => {
    const myUserSnapshot = await firebase
      .firestore()
      .collection("Users")
      .doc(global.myUID)
      .get();
    const user = myUserSnapshot.data()
    let myWalletBalance;
    if (global.walletType == "ACN") {
      myWalletBalance =
        user.coinBalance -
        user.coinAmountInEscrowByYou;
    }
    if (global.walletType == "NGN") {
      myWalletBalance =
        user.walletBalance -
        user.walletAmountInEscrowByYou;
    }

    if (myWalletBalance) {
      this.setState({ myWalletBalance });
    }

    //ACN checks
    if (global.walletType == "ACN") {
      const encK = await SecureStore.getItemAsync("W" + global.myUID.substring(2, 20))
      const now = new Date()
      if ((user.coinBlockedBySystemUntil && user.coinBlockedBySystemUntil.toDate() > now)) {
        alert("Your wallet is currently blocked. You can receive payments only.")
        this.props.navigation.goBack()
        return;
      }
      if ((user.coinBlockedByUserUntil && user.coinBlockedByUserUntil.toDate() > now)) {
        alert("Your wallet is currently blocked. You can receive payments only")
        this.props.navigation.goBack()
        return;
      }
      if (user.coinResetAfterBlock) {
        alert("Sign out of Wakanda and sign in again to be able to unblock your wallet")
        this.props.navigation.goBack()
        return;
      }
      if (!global.coinSignKeySet) {
        this.props.navigation.navigate("walletSetup", { screen: "0" });
        return;
      }
      if (user.coinSignKey && !encK) {
        this.props.navigation.goBack()
        this.props.navigation.navigate("walletSetup", { screen: "1e" })
        return;
      };
      this.setState({ acnChecksDone: true })
    }
  };

  checkAppUpdate = async () => {
    try {
      let appVersion = "";
      if (Constants.manifest && Constants.manifest.version) {
        appVersion = Constants.manifest.version;
      } else return;

      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "checkACNPay", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          platform: Constants.platform || "-",
          appVersion,
          myUID: global.myUID,
          idToken: global.idToken,
        })
      });
      const r2 = await r.json();
      if (r2.messageToDisplay) alert(r2.messageToDisplay)
    } catch (error) {
      console.log("Error checking acn pay ", error);
    }
  }

  checkWalletType = async () => {
    try {
      //check globals set correctly (wallet type)
      const recipientID = this.props.navigation.getParam("recipientProfileID", null);
      const profileDoc = await firebase
        .firestore()
        .collection("Profiles")
        .doc(recipientID)
        .get()
      const profile = profileDoc.data()
      if (profile.usesACN && global.walletType != "ACN") {
        alert("This user resides outside of Nigeria. Payment not possible at the moment.")
        await global.timeout(1000)
        this.props.navigation.goBack()
      }
      if (!profile.usesACN && global.walletType == "ACN") {
        alert("This user resides in Nigeria. Payment not possible at the moment.")
        await global.timeout(1000)
        this.props.navigation.goBack()
      }
      if (recipientID == global.myProfileID) {
        alert("You cannot pay money to yourself.")
        await global.timeout(1000)
        this.props.navigation.goBack()
      }
    } catch (er) {
      console.log(er)
    }
  }

  callPay = async () => {
    const specialRequest = this.props.navigation.getParam("specialRequest", null)

    if (this.state.activityIndicatorAnimating) return;
    const recipientID = this.props.navigation.getParam("recipientProfileID", null);
    if (!recipientID) {
      console.log("No recipientID");
      alert("Error.");
      return;
    }
    if (!global.myUID) {
      console.log("No GlobalUID");
      alert("Error. Restart app and try again");
      return;
    }
    if (!Number(this.state.amount)) {
      console.log("specialRequest", specialRequest)
      if (!specialRequest || specialRequest.discountPercentage != 1) {
        console.log("No number entered in amount field (pay)");
        alert("Please enter a number");
        return;
      }
    }
    if (this.state.amount > this.state.myWalletBalance) {
      console.log("Tried to transfer more than what is in myWalletBalance (pay)");
      alert("Insufficient Wallet Balance");
      return;
    }
    if (this.state.amount < 20) {
      if (!specialRequest || specialRequest.discountPercentage != 1) {
        alert("Enter a higher amount");
        return;
      }
    }

    this.setState({ activityIndicatorAnimating: true });
    if (global.walletType != "NGN" && !this.state.acnChecksDone) {
      await global.timeout(500)
      if (global.walletType != "NGN" && !this.state.acnChecksDone) {
        await global.timeout(500)
        if (global.walletType != "NGN" && !this.state.acnChecksDone) {
          await global.timeout(500)
          if (global.walletType != "NGN" && !this.state.acnChecksDone) {
            await global.timeout(500)
            if (global.walletType != "NGN" && !this.state.acnChecksDone) {
              await global.timeout(1000)
              if (global.walletType != "NGN" && !this.state.acnChecksDone) {
                await global.timeout(2000)
                if (global.walletType != "NGN" && !this.state.acnChecksDone) {
                  await global.timeout(2000)
                  if (global.walletType != "NGN" && !this.state.acnChecksDone) {
                    await global.timeout(3000)
                    if (global.walletType != "NGN" && !this.state.acnChecksDone) {
                      alert("Connection failed.")
                      return this.props.navigation.goBack()
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    try {
      const cloudFunction = this.state.walletType == "NGN" ? "transferCoin" : "transferACN"
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + cloudFunction, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          senderUID: global.myUID,
          myUID: global.myUID,
          idToken: global.idToken,
          recipientProfileID: recipientID,
          amount: Number(this.state.amount),
          remarks: this.state.remarks,
          ...(specialRequest && { specialRequest }),
          //del  appCapabilities: { referrerPayment: true }
        })
      });
      const r2 = await r.json();
      this.setState({
        activityIndicatorAnimating: false,
      });
      console.log("Received first server response", r2)
      await global.timeout(100)
      if (this.state.walletType == "NGN") {
        if (r2.msg === "TRANSACTION_SUCCESSFUL") {
          alert("Transfer successful");
          await global.timeout(500)
          if (this.state.action == "payment") this.props.navigation.navigate("walletMain")
          else this.props.navigation.navigate(this.props.navigation.getParam("nextScreen", "walletMain"))
        } else {
          if (r2.msg == "INIT_FAILED") alert("Website setup failed. Contact customer care")
          else alert("Transaction failed");
          console.log("Payment transaction failed. ", r2);
          if (this.state.action != "payment") {
            await global.timeout(100)
            this.props.navigation.goBack();
          }
        }
      }
      else if (this.state.walletType == "ACN") {
        if (r2.msg === "SUCCESS") {
          this.envelope = r2.envelope
          //del this.envelopeReferrer = r2.envelopeSplit || null;
          this.id = r2.id
          this.setState({ showModal: true })
        } else {
          if (r2.msg == "INIT_FAILED") alert("Website setup failed. Contact customer care")
          else alert("Could not initiate transfer");
          console.log("Payment transaction initiation failed. ", r2);
          if (this.state.action != "payment") {
            await global.timeout(100)
            this.props.navigation.goBack();
          }
        }
      }
    } catch (error) {
      this.setState({
        modalPayVisible: false,
        activityIndicatorAnimating: false,
      });
      console.log("Error sending money. ", error);
    }
  };

  callPayValidation = async () => {
    if (this.state.activityIndicatorAnimating) return;

    if (this.wap.length < 8) {
      alert("Wallet Password too short");
      return;
    }
    if (!global.myUID) {
      console.log("No GlobalUID");
      alert("Error. Restart app and try again");
      global.timeout(100)
      this.setState({ activityIndicatorAnimating: false, });
      this.props.navigation.goBack()
      return;
    }
    this.setState({ activityIndicatorAnimating: true });
    try {

      //Verify transactionRecord 
      const userDoc = await firebase.firestore().collection("Users").doc(global.myUID).get()
      const user = userDoc.data()
      const recipientProfileID = this.props.navigation.getParam("recipientProfileID", "");
      if (!user.pendingTransaction || user.pendingTransaction.envelope != this.envelope ||
        user.pendingTransaction.recipientProfileID != recipientProfileID ||
        user.pendingTransaction.transactionID != this.id) {
        console.log("Major error!")
        console.log("Major error!")
        alert("Error!")
        global.timeout(100)
        this.setState({ activityIndicatorAnimating: false });
        this.props.navigation.goBack()
        // tbd reportError major error
        return;
      }
      const specialRequest = this.props.navigation.getParam("specialRequest", null)
      // // Verify Referrer Bonus Transaction Record
      // if (specialRequest && specialRequest.action == "website" && specialRequest.referrerCode && (user.pendingTransaction.referrerCode != specialRequest.referrerCode)) {
      //   console.log("Major error!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", needsReferrerPayment, user.pendingTransaction.envelopeSplit, this.envelopeReferrer, user.pendingTransaction.referrerCode, specialRequest.referrerCode)
      //   alert("Error;")
      //   global.timeout(100)
      //   this.setState({ activityIndicatorAnimating: false, });
      //   this.props.navigation.goBack()
      //   // tbd reportError major error
      //   return;
      // }

      const stellarTransaction = new Stellar.Transaction(this.envelope, Stellar.Networks.PUBLIC);
      //del const stellarTransactionReferrer = needsReferrerPayment ? new Stellar.Transaction(this.envelopeReferrer) : null;
      await global.timeout(100)
      //check wep
      let encK = await SecureStore.getItemAsync("W" + global.myUID.substring(2, 20))

      try {
        if (CryptoJS.AES.decrypt(encK, this.wap + global.myUID.substring(2, 12)).toString(CryptoJS.enc.Utf8) == "") {
          await global.timeout(8000)
          this.setState({ activityIndicatorAnimating: false, });
          alert("Wallet Password incorrect")
          console.log("WAP INCORRECT")
          this.wap = ""
          //XXTBD Count Incorrect Password attempts

          return;
        }
        else console.log("WAP OK")
      } catch (er) {
        await global.timeout(8000)
        alert("Wallet Password incorrect")
        this.setState({ activityIndicatorAnimating: false, });
        console.log("WAP CAUHGT")
        this.wap = ""
        //XXTBD Count Incorrect Password attempts

        return;
      }

      console.log("Password likely correct")
      this.signature = stellarTransaction.getKeypairSignature(Stellar.Keypair.fromSecret(CryptoJS.AES.decrypt(encK, this.wap + global.myUID.substring(2, 12)).toString(CryptoJS.enc.Utf8)));
      //del if (needsReferrerPayment) this.signatureReferrer = stellarTransactionReferrer.getKeypairSignature(Stellar.Keypair.fromSecret(CryptoJS.AES.decrypt(encK, this.wap + global.myUID.substring(2, 12)).toString(CryptoJS.enc.Utf8)));
      this.wap = ""
      encK = "X---"
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "transferACNValidation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "execute",
          id: this.id,
          signature: this.signature,
          //del ...(needsReferrerPayment && { signatureSplit: this.signatureReferrer }),
          myUID: global.myUID,
          idToken: global.idToken,
          ...(specialRequest && { specialRequest })
        })
      });
      const r2 = await r.json();
      this.signature = ""
      //del this.signatureReferrer = ""
      this.setState({
        activityIndicatorAnimating: false,
        amount: 0
      });
      await global.timeout(100)
      console.log("Response from transferACNValidation", r2)

      if (r2.msg === "ACN_TRANSACTION_SUCCESSFUL" || r2.msg === "TRANSACTION_SUCCESSFUL") {
        this.setState({ showModal: false })
        await global.timeout(100)
        let successMsg = "Transfer successful"
        if (specialRequest && specialRequest.action == "website") successMsg = "Purchase successful. You can now select a domain name."
        if (specialRequest && specialRequest.action == "website" && specialRequest.isRenewal) successMsg = "Purchse successful. Now go to My Website to complete the renewal."
        alert(successMsg);
        await global.timeout(100)
        this.props.navigation.navigate(this.props.navigation.getParam("nextScreen", "walletMain"))
        return;
      } else {
        //this.setState({ showModal: false })
        //global.timeout(100)
        //this.props.navigation.goBack()
        global.timeout(100)
        if (r2.msg === "ACCOUNT_BALANCE_NOT_SUFFICIENT") {
          alert("Account balance not sufficient");
        } else if (r2.msg === "TRANSACTION_EXPIRED") {
          alert("Transaction expired after 10 minutes. Try again.");
        } else if (r2.msg === "BLOCKED") {
          alert(r2.reason || "ERROR");
        } else if (r2.msg === "INIT_FAILED") {
          alert("Website setup failed. Contact customer care")
        } else if (r2.msg === "ERROR") {
          if (r2.reason) alert(r2.reason)
          else alert("Transfer not successful. Try again later.");
        } else {
          alert("Transfer not successful. Try again later");
          console.log("ACN transaction failed. check", r2);
        }
      }
      global.timeout(100)
    } catch (error) {
      alert("An error occurred. Check internet connection")
      global.reportError?.(error, "catches", "MePay_callPayValidation")
      this.setState({
        activityIndicatorAnimating: false,
      });
      console.log("Error....... ", error);
    }
  };

  callPayValidationCancel = async () => {
    if (this.state.activityIndicatorAnimating) return;

    this.setState({ activityIndicatorAnimating: true });
    try {

      this.signature = "";
      //del this.signatureReferrer = "";
      this.wap = ""
      const r = await fetch(global.cloudFunctionURL + "transferACNValidation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "cancel",
          id: this.id,
          signature: "------------",
          myUID: global.myUID,
          idToken: global.idToken,
        })
      });
      //const r2 = await r.json();
      this.id = ""
      this.setState({
        activityIndicatorAnimating: false,
        showModal: false
      });
      await global.timeout(100)
      if (this.props.navigation.getParam("action")) this.props.navigation.goBack()

    } catch (error) {
      this.setState({
        activityIndicatorAnimating: false,
        showModal: false
      });
      console.log("Error..... ", error);
      await global.timeout(100)
      this.props.navigation.navigate("walletMain");
    }
  };



  render() {
    const isInsufficient = (this.state.amount > this.state.myWalletBalance)
    const showACN = global.walletType == "ACN"

    if (this.state.error) return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "black" }} >
        <Text style={{ color: "#555" }}>Error. Try again.</Text>
      </View>
    );
    if (this.state.loadingAction && !this.state.showModal) return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "#000" }}>
        <ActivityIndicator size="large" color="#ccf" animating={true} />
      </View>
    );
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#000000" }}>
        <TouchableWithoutFeedback
          onPress={Keyboard.dismiss}
          accessible={true}
        >
          <ScrollView style={{ marginTop: Platform.OS == "ios" ? 50 : 85, paddingHorizontal: 25 }}>
            {!showACN ? <View><Text
              style={{
                fontSize: 16,
                color: "#ccc",
                paddingBottom: 5,
                borderBottomColor: "#FFFFFF",
                borderBottomWidth: 0,
                marginTop: 20,
                justifyContent: "flex-start"
              }}
            >
              Amount ({this.state.walletType == "NGN" ? "₦" : "₳"})
              </Text>
              <TextInput
                onChangeText={amount => {
                  this.setState({ amount });
                }}
                style={{
                  marginBottom: 0,
                  color: "#ffffff",
                  borderWidth: 0,
                  paddingLeft: 3,
                  borderRadius: 5,
                  height: 35,
                  backgroundColor: "#334"
                }}
                keyboardType="numeric"
                maxLength={8}
                underlineColorAndroid="transparent"
                bordered
              /></View>
              :
              <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <View style={{ width: '45%' }}>
                  <Text style={{
                    fontSize: 16, color: "#ccc", paddingBottom: 5, borderBottomColor: "#FFFFFF",
                    borderBottomWidth: 0, marginTop: 20, justifyContent: "flex-start"
                  }}>
                    Amount (₳)
              </Text>
                  <TextInput
                    onChangeText={amount => {
                      this.setState({ amount, localAmount: Math.round(amount * global.xRates[global.myLocalCurrency]) });
                    }}
                    value={this.state.amount == 0 ? "" : this.state.amount + ''}
                    style={{
                      marginBottom: 0,
                      color: "#ffffff",
                      borderWidth: 0,
                      paddingLeft: 3,
                      borderRadius: 5,
                      height: 35,
                      backgroundColor: "#334"
                    }}
                    keyboardType="numeric"
                    maxLength={8}
                    underlineColorAndroid="transparent"
                    bordered
                  /></View>
                <Text style={{
                  fontSize: 20, color: "#ccc", paddingBottom: 5, borderBottomColor: "#FFFFFF",
                  borderBottomWidth: 0, marginTop: 30, justifyContent: "flex-start"
                }}>≈</Text>
                <View style={{ width: '45%' }}><Text
                  style={{
                    fontSize: 16,
                    color: "#ccc",
                    paddingBottom: 5,
                    borderBottomColor: "#FFFFFF",
                    borderBottomWidth: 0,
                    marginTop: 20,
                    justifyContent: "flex-start"
                  }}
                >
                  Amount ({global.currencySymbols[global.myLocalCurrency]})
              </Text>
                  <TextInput
                    onChangeText={localAmount => {
                      this.setState({ localAmount, amount: Math.round(localAmount / global.xRates[global.myLocalCurrency]) });
                    }}
                    value={(this.state.localAmount || '') + ''}
                    style={{
                      marginBottom: 0,
                      color: "#ffffff",
                      borderWidth: 0,
                      paddingLeft: 3,
                      borderRadius: 5,
                      height: 35,
                      backgroundColor: "#334"
                    }}
                    keyboardType="numeric"
                    maxLength={8}
                    underlineColorAndroid="transparent"
                    bordered
                  /></View>
              </View>}
            {!!this.state.myWalletBalance && <Text
              style={{
                fontSize: 12,
                color: isInsufficient ? "#a55" : "#556",
                paddingBottom: 5,
                borderBottomColor: "#FFFFFF",
                borderBottomWidth: 0,
                justifyContent: "flex-start"
              }}
            >
              (My wallet balance: {this.state.walletType == "NGN" ? "₦" : "₳"} {this.state.myWalletBalance})
              </Text>}
            <Text
              style={{
                fontSize: 16,
                color: "#ccc",
                paddingBottom: 5,
                borderBottomColor: "#FFFFFF",
                borderBottomWidth: 0,
                marginTop: 20,
                justifyContent: "flex-start"
              }}
            >
              Remarks (optional)
              </Text>
            <TextInput
              onChangeText={remarks => {
                this.setState({ remarks });
              }}
              style={{
                marginBottom: 20,
                color: "#ffffff",
                borderWidth: 0,
                paddingLeft: 3,
                borderRadius: 5,
                height: 35,
                backgroundColor: "#334"
              }}
              maxLength={120}
              underlineColorAndroid="transparent"
              bordered
            />
            <View style={{ height: 25 }} />
            {!this.state.activityIndicatorAnimating ? (
              <TouchableOpacity style={global.gStyles.button} onPress={this.callPay}>
                <Text style={global.gStyles.buttontext}>Pay Now</Text>
              </TouchableOpacity>
            ) : (
                <ActivityIndicator
                  size="small"
                  color="#fff"
                  style={{
                    alignSelf: "center",
                    margin: 20
                  }}
                  animating={true}
                />
              )}
            <Modal
              animationType="fade"
              transparent={false}
              visible={this.state.showModal}
              onRequestClose={() => {
                //this.setState({showModal: false});
              }}
            >
              <ScrollView style={{ paddingTop: 50, paddingHorizontal: 25, backgroundColor: "#000" }}>
                <Text
                  style={{
                    fontSize: 16,
                    color: "#ccc",
                    paddingBottom: 5,
                    borderBottomColor: "#FFFFFF",
                    borderBottomWidth: 0,
                    marginTop: 20,
                    justifyContent: "flex-start",
                    textAlign: "center"
                  }}
                >
                  Your Wallet Password
              </Text>
                <View style={{
                  marginBottom: 0,
                  color: "#ffffff",
                  borderWidth: 0,
                  borderRadius: 25,
                  height: 35,
                  width: 224,
                  backgroundColor: "#225",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "center"
                }}>
                  <Text style={{ fontSize: 18, color: "#ccc", marginHorizontal: 10 }}>
                    🌍</Text>
                  <TextInput
                    onChangeText={wap => {
                      this.wap = wap;
                    }}
                    secureTextEntry={true}
                    style={{
                      color: "#ccc",
                      borderWidth: 0,
                      height: 35,
                      flex: 1
                    }}
                    textAlign={'center'}
                    underlineColorAndroid="transparent"
                    bordered
                  />
                  <Text style={{ fontSize: 18, color: "#ccc", marginHorizontal: 10 }}>
                    🌍</Text>
                </View>

                <View style={{ height: 25 }} />
                {!this.state.activityIndicatorAnimating ? (
                  <View style={{ alignSelf: "stretch" }}>
                    <TouchableOpacity style={global.gStyles.button} onPress={this.callPayValidation}>
                      <Text style={global.gStyles.buttontext}>Pay Now</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={{ marginTop: 30, alignSelf: "stretch", alignItems: "center" }} onPress={this.callPayValidationCancel}>
                      <Text style={{ color: "#aaa", fontSize: 13 }}>Cancel</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={{ width: 224, alignSelf: "center", alignItems: "center", margin: 30 }} onPress={async () => {
                      this.setState({ showModal: false })
                      await global.timeout(100)
                      this.props.navigation.goBack()
                      await global.timeout(100)
                      this.props.navigation.navigate("walletSetup", { screen: "1d" })
                    }}>
                      <Text style={{ color: "#aaa", fontSize: 13 }}>Forgot Password?</Text>
                    </TouchableOpacity>
                  </View>
                ) : (
                    <ActivityIndicator
                      size="small"
                      color="#fff"
                      style={{
                        alignSelf: "center",
                        margin: 20
                      }}
                      animating={true}
                    />
                  )}
                <View style={{ backgroundColor: "#222", padding: 20 }}>
                  <Text
                    style={{
                      fontSize: 18,
                      color: "#888",
                      fontWeight: "bold",
                      marginBottom: 6
                    }}
                  >
                    Transfer details
                  </Text>
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#888",
                      marginTop: 6,
                    }}
                  >
                    Recipient: {this.state.recipientName}
                  </Text>
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#888",
                      marginTop: 6,

                    }}
                  >
                    Amount: {this.state.amount} {this.state.walletType == "NGN" ? "₦" : "₳"}
                  </Text>
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#888",
                      marginTop: 6,
                    }}
                  >
                    Remarks: {this.state.remarks || "-"}
                  </Text>
                </View>
              </ScrollView>
            </Modal>
          </ScrollView>
        </TouchableWithoutFeedback>
      </SafeAreaView>
    );
  }
}

import React, { Component } from "react";
import {
  Text,
  View,
  Image,
} from "react-native";

import firebase from "firebase";
import "firebase/firestore";
export default class Face extends Component {
  state = {
    pictureURL: ""
  }

  async componentDidMount() {
    try {
      let pictureURL = this.props.pictureURL
      if (!pictureURL) {
        const profileDoc = await firebase
          .firestore()
          .collection("Profiles")
          .doc(this.props.profileID)
          .get()
        pictureURL = profileDoc.data().profilePictureURL || ""
      }
      this.setState({ pictureURL })
    } catch (e) {
      global.warn(e, "cdm_Face")
    }
  }
  render() {
    let colorCode = "#9f9"
    if (this.props.status == "overdue") colorCode = "#f99"
    if (this.props.status == "open") colorCode = "#ddf"
    const S = this.props.status ? 18 : 20
    return (
      <View style={{ width: 20, justifyContent: "center" }}>
        <View style={{
          width: 20, height: 20, borderRadius: 10, backgroundColor: colorCode, justifyContent: "center", alignItems: "center",
        }}>
          {this.state.pictureURL ? <Image
            style={{ width: S, height: S, borderRadius: S / 2, }}
            source={{ uri: this.state.pictureURL }}
          /> : <View style={{
            width: S, height: S, borderRadius: S / 2, backgroundColor: "#668"
          }} />}
        </View>
        {this.props.status == "completed" && <View style={{ marginTop: -20, height: 20, paddingBottom: 2, justifyContent: "center" }}>
          <Text style={{ color: "#7f7", fontSize: 21, fontWeight: "bold" }}>✓</Text></View>}
      </View>)
  }
}
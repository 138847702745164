import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  Image,
  Alert,
  TouchableOpacity,
  TextInput,
  ScrollView,
  SafeAreaView,
  Platform,
  ActivityIndicator,
  Modal,
  Linking
} from "react-native";
import * as SecureStore from 'expo-secure-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import firebase from "firebase";
import "firebase/firestore";
import JobRating from './jobRating';
import Moment from "react-moment";
import Post from "../Home/Post/Post";
import CryptoJS from "react-native-crypto-js";
const gStyles = global.gStyles //    "../styles/gStyles";

const Stellar = require("stellar-base");
//Stellar.Network.usePublicNetwork();


class Job extends PureComponent {
  state = {
    hasUnreadJobs: false,
    needsAction: false,
    jobProperties: {
      opponentProfileID: null,
      opponentName: "✧ ✧ ✧ ✧ ✧ ✧",
      opponentNameBusiness: "✧ ✧ ✧ ✧",
      opponentProfilePictureURL: "",
      jobDescription: "",
      jobCategory1: "",
      jobCategory2: "",
      systemLastUpdated: null,
      jobCharge: 0,
      jobChargeDiscount: 0,
      jobChargeDiscountWakanda: 0,
      jobLocationAddress: "",
      jobLocationAddressDescription: "",
      jobLocationGeo: null,
      systemStatusJob: "",
      cancelReason: '',
      systemStatusRatingByCustomer: null,
      systemStatusRatingByProvider: null,
      jobChargeOnArrival: 0,
      jobChargeDeliveryFee: 0,
      jobHasDelivery: false,
      jobChargeCurrency: "NGN",
      jobPostID: null,
      jobQuantity: null,
      systemInstallmentPaid: 0,
      systemStatusDelivery: "",

    },
    amCustomer: false,
    activityIndicatorAnimating: false,
    modalVisible: false,
    showComplaintForm: false,
    showCancelForm: false,
    providerComplaintForm: false,
    showPostDetail: false,
    postStatus: false,
    complaint: '',
    showJobDetails: false,
    refreshing: true,
    dateCreated: null,
    workAgainRatio: '',

    showModalWAP: false
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
    this.lastReorderRequest = null
    this.id = null;
    this.envelope = null
    this.wap = "";
    this.actionToPerform = "";
    global.Buffer = require('buffer').Buffer;
  }

  async componentDidMount() {
    global.jobNotifications = {};
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 200); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 500); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 1000); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 2000); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 3000); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 4000); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 5000); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 8000); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 10000); });
    if (!global.myUID) await global.setGlobals();

    this.unsubscribeFirestore = firebase
      .firestore()
      .collection("Jobs")
      .doc(this.props.jobID)
      .onSnapshot(this.updateJob);
  }

  componentWillUnmount() {
    console.log("Component jobMain will unmount");
    this.unsubscribeFirestore();
  }

  updateJob = async jobSnapshot => {
    //console.log("Job update triggered.");
    try {
      let job = jobSnapshot.data();
      let opponentProfileID = "";
      let hasUnreadJobs = false;

      if (!job.participantProfileIDCustomer || !job.participantProfileIDProvider) {
        throw new Error("fundamental error, this job has incorrect data.");
      } else {
        if (global.myProfileID === job.participantProfileIDCustomer) {
          opponentProfileID = job.participantProfileIDProvider;
          hasUnreadJobs = job.systemCustomerHasUpdate;
        } else {
          opponentProfileID = job.participantProfileIDCustomer;
          hasUnreadJobs = job.systemProviderHasUpdate;
        }
      }

      if (job.participantProfileIDCustomer == global.myProfileID) {
        this.setState({ amCustomer: true });
      } else {
        this.setState({ amCustomer: false });
      }
      ///////////////////////////////////////////////////////////////

      let opponentName = this.state.jobProperties.opponentName;
      let opponentProfilePictureURL = this.state.jobProperties.opponentProfilePictureURL;
      let opponentNameBusiness = this.state.jobProperties.opponentNameBusiness;
      let workAgainRatio = this.state.workAgainRatio;

      if (!this.state.jobProperties.opponentProfileID) {
        let opponentProfileSnapshot = await firebase
          .firestore()
          .collection("Profiles")
          .doc(opponentProfileID)
          .get();
        let opponentProfile = opponentProfileSnapshot.data();
        opponentName = opponentProfile.profileName;
        opponentProfilePictureURL = opponentProfile.profilePictureURL;
        opponentNameBusiness = opponentProfile.profileNameBusiness;
        workAgainRatio = opponentProfile.ratingByProvidersWorkAgainRatio || '100%';
      }

      let jobDescription = job.jobDescription;
      let systemLastUpdated

      if (job.systemLastUpdated) {
        systemLastUpdated = job.systemLastUpdated.toDate();
      } else {
        systemLastUpdated = new Date(2017, 11, 24, 10, 33, 30, 0);
      }

      let jobCharge = job.jobCharge;
      let systemInstallmentPaid = job.systemInstallmentPaid || 0;
      let jobChargeOnArrival = job.jobChargeOnArrival || 0;
      let jobChargeDeliveryFee = job.jobChargeDeliveryFee || 0;
      let jobHasDelivery = job.jobHasDelivery;
      let jobChargeCurrency = job.jobChargeCurrency || "NGN";
      let jobChargeDiscount = job.jobChargeDiscount || 0
      let jobChargeDiscountWakanda = job.jobChargeDiscountWakanda || 0
      let jobPostID = job.jobPostID;
      let jobQuantity = job.jobQuantity;
      let jobLocationCity = job.jobLocationCity || "";
      let systemStatusJob = job.systemStatusJob;
      let cancelReason = job.systemStatusCancelReason;
      let systemStatusRatingByCustomer = job.systemStatusRatingByCustomer;
      let systemStatusRatingByProvider = job.systemStatusRatingByProvider;
      let systemStatusDelivery = job.systemStatusDelivery;
      let jobCategory1 = job.jobCategory1;
      let jobCategory2 = job.jobCategory2;
      let dateCreated = job.systemCreationDate.toDate();
      let jobLocationAddress = job.jobLocationAddress || "";
      let jobLocationAddressDescription = job.jobLocationAddressDescription || "";
      let jobLocationGeo = null;
      if (job.jobLocationGeo) {
        jobLocationGeo = {
          latitude: job.jobLocationGeo._lat,
          longitude: job.jobLocationGeo._long
        }
      }
      let jobProperties = {
        opponentProfileID,
        opponentName,
        opponentNameBusiness,
        opponentProfilePictureURL,
        jobDescription,
        jobCategory1,
        jobCategory2,
        systemLastUpdated,
        jobCharge,
        jobChargeDiscount,
        jobChargeDiscountWakanda,
        jobLocationAddress,
        jobLocationAddressDescription,
        jobLocationGeo,
        jobPostID,
        jobQuantity,
        systemStatusJob,
        cancelReason,
        systemStatusRatingByCustomer,
        systemStatusRatingByProvider,
        systemStatusDelivery,
        systemInstallmentPaid,
        jobChargeOnArrival,
        jobChargeDeliveryFee,
        jobHasDelivery,
        jobChargeCurrency,

      };

      this.setState({
        jobProperties,
        hasUnreadJobs,
        dateCreated,
        workAgainRatio
      });

      if (systemStatusJob == 'PENDING' && job.participantProfileIDProvider == global.myProfileID) this.setState({ showJobDetails: true })
      this.props.onChange(systemLastUpdated);

      nOfUnreadJobs = 0;
      if (this.state.jobProperties.systemStatusJob == "PENDING" && !this.state.amCustomer) { nOfUnreadJobs = 1 }
      if (this.state.jobProperties.systemStatusJob == "ONGOING") { nOfUnreadJobs = 1 }
      //if (this.state.jobProperties.systemStatusJob =="DECLINED") {nOfUnreadJobs = 1}
      //if (this.state.jobProperties.systemStatusJob =="CANCELLED BY CUSTOMER") {nOfUnreadJobs = 1}
      if (this.state.jobProperties.systemStatusJob == "COMPLETED_PENDING_REVIEW" && this.state.amCustomer) { nOfUnreadJobs = 1 }


      this.setState({ needsAction: nOfUnreadJobs });
      global.jobNotifications[this.props.jobID] = nOfUnreadJobs;
      let totalCount = 0;
      for (var el in global.jobNotifications) {
        if (global.jobNotifications.hasOwnProperty(el)) {
          totalCount += parseFloat(global.jobNotifications[el]);
        }
      }
      //console.log("totalCount", totalCount);
      const parent = this.props.navigation.dangerouslyGetParent();
      parent.setParams({ notifications: totalCount });
      this.setState({ refreshing: false });
      global.pushBadgeJobs = totalCount || 0
      global.updatePushBadge()

    } catch (err) {
      console.log(err);
      await new Promise(resolve => {
        setTimeout(resolve, 100);
      });
      alert("Error fetching job");
    }

    //Image.prefetch(url);
  };

  submitJobUpdate = async (action, cancelReason) => {
    if (global.walletType != this.state.jobProperties.jobChargeCurrency) {
      alert("Wallet type error. Restart app and try again")
      return
    }
    console.log('action', action)
    console.log('global.walletType', global.walletType)
    if (global.walletType == "ACN" && (action == "CUSTOMER_COMPLETES_JOB" || action == "CUSTOMER_REVIEWS_ACCEPTING" || action == "CUSTOMER_RELEASES_DELIVERY_FEE" || action == "CUSTOMER_RELEASES_INSTALLMENT")) {
      const now = new Date()
      console.log("DATT", now)
      if (global.blockedUntil > now) {
        alert("Your wallet is temporarily blocked for outgoing and escrow payments")
        return;
      }
      if (global.coinResetAfterBlock) {
        alert("Sign out of " + (global.a.appName || "Wakanda") + " and sign in again to be able to unblock your wallet")
        return;
      }
      if (!global.coinSignKey) {
        alert("Before you can release funds, you need to set a Wallet Password")
        this.props.navigation.navigate("walletSetup", { screen: "0" });
        return;
      }
      const encK = await SecureStore.getItemAsync("W" + global.myUID.substring(2, 20))
      if (!encK) {
        this.props.navigation.navigate("walletSetup", { screen: "1e" });
        return;
      }

    }

    //console.log('whats happening?...')
    try {
      this.setState({ activityIndicatorAnimating: true, refreshing: true });
      const cloudRoute = global.walletType == "ACN" ? "updateJobACN" : "updateJob"
      const r = await fetch(
        global.cloudFunctionURL + cloudRoute,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            jobID: this.props.jobID,
            action: action, // such as "CUSTOMER_COMPLETES_JOB"
            cancelReason: cancelReason,
            myUID: global.myUID,
            idToken: global.idToken
          })
        }
      );
      const r2 = await r.json();
      console.log(cloudRoute + " returned " + r2)

      this.setState({ activityIndicatorAnimating: false });
      await new Promise(resolve => {
        setTimeout(resolve, 100);
      });

      if (r2.msg === "SUCCESS") {
        if (r2.envelope && r2.id) {
          this.envelope = r2.envelope
          this.id = r2.id
          this.actionToPerform = action
          this.setState({ showModalWAP: true })
        } else alert("Request Successful")

      } else {
        this.setState({ refreshing: false })
        await global.timeout(100)
        console.log('error updating job...', r2.msg);

        if (r2.msg == "BLOCKED") alert(r2.reason || "Error..")
        else alert('Update not successful. Try again later');
      }
    } catch (err) {
      console.log(err);
      this.setState({ activityIndicatorAnimating: false, refreshing: false });
      await global.timeout(100)
      alert('Update not successful. Try again later');
    }
  }


  submitReview = async (professionalism, quality, price, timeliness) => {

    try {
      this.setState({ activityIndicatorAnimating: true, refreshing: true });
      const r = await fetch(
        global.cloudFunctionURL + "rate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            amCustomer: this.state.amCustomer,
            jobID: this.props.jobID,
            rating: {
              professionality: professionalism,
              quality: quality,
              affordability: price,
              timeliness: timeliness
            },
            myUID: global.myUID,
            idToken: global.idToken

          })
        }
      );
      const r2 = await r.json();

      this.setState({ activityIndicatorAnimating: false });
      if (r2.msg === "SUCCESS") {
        console.log("JOB REVIEW SUCCESSFULLY SUBMITTED ");

        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });

        this.setState({ modalVisible: false });
      } else {
        console.log('error sending job review..', r2.msg);
        await new Promise(resolve => {
          setTimeout(resolve, 100);
        });
        alert('Error during rating. Try again later');
        this.setState({ refreshing: false });
      }
    } catch (err) {
      this.setState({ activityIndicatorAnimating: false });

      await new Promise(resolve => {
        setTimeout(resolve, 100);
      });
      console.log(err);
      alert('Rating failed. Try again later');
    }
  }

  circle(filled) {
    if (filled) {
      return (<View style={{ width: 20, height: 20, borderColor: '#A8CF45', backgroundColor: '#A8CF45', borderWidth: 1, borderRadius: 10 }}></View>);

    } else {
      return (<View style={{ width: 20, height: 20, borderColor: 'green', borderWidth: 1, borderRadius: 10 }}></View>);
    }

  }

  showDetails = () => {
    if (this.state.showJobDetails == true) { this.setState({ showJobDetails: false }) }
    else { this.setState({ showJobDetails: true }) };
  }

  showMapAddress = () => {

    if (this.state.jobProperties.jobLocationAddress) {
      let q = this.state.jobProperties.jobLocationAddress;
      Linking.openURL(`http://maps.google.com/?q=${q}`);
    } else {
      if (this.state.jobProperties.jobLocationGeo) {
        Linking.openURL(
          `http://maps.google.com/?ll=${this.state.jobProperties.jobLocationGeo.latitude},${this.state.jobProperties.jobLocationGeo.longitude
          }`
        );
      }
    }
  }
  callWorkAgain = async (ANS) => {
    if (ANS == "YES") return this.callWorkAgainExecution(ANS)

    Alert.alert(
      "Sure?", "You don't want to receive further jobs from this customer?",
      [{ text: "Cancel", onPress: () => console.log("Cancel Pressed"), style: "cancel" },
      { text: "Yes", onPress: () => { this.callWorkAgainExecution(ANS) } }],
      { cancelable: false });
  }

  callWorkAgainExecution = async (ANS) => {

    try {
      this.setState({ activityIndicatorAnimating: true, refreshing: true });
      const r = await fetch(
        global.cloudFunctionURL + "rate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            jobID: this.props.jobID,
            amCustomer: false,
            rating: {
              workAgainWithCustomer: ANS
            },
            myUID: global.myUID,
            idToken: global.idToken
          })
        }
      );
      const r2 = await r.json();

      this.setState({ activityIndicatorAnimating: false });
      await new Promise(resolve => {
        setTimeout(resolve, 100);
      });

      if (r2.msg === "SUCCESS") {
        console.log("PROVIDER RATING SUBMITTED: " + ANS);

      } else {
        this.setState({ refreshing: false });
        await new Promise(resolve => {
          setTimeout(resolve, 100);
        });
        console.log('error updating job...', r2.msg);
        alert('Update not successful. Try again later');

      }
    } catch (err) {
      console.log(err);
      this.setState({ activityIndicatorAnimating: false });
      await new Promise(resolve => {
        setTimeout(resolve, 100);
      });
      alert('Update not successful. Try again later');
    }

  }

  callPayValidation = async () => {
    if (this.state.activityIndicatorAnimating) return;

    if (this.wap.length < 8) {
      alert("Wallet Password too short");
      return;
    }
    if (!global.myUID) {
      console.log("No GlobalUID");
      alert("Error. Restart app and try again");
      return;
    }
    this.setState({ activityIndicatorAnimating: true });
    try {

      //Verify transactionRecord 
      const userDoc = await firebase.firestore().collection("Users").doc(global.myUID).get()
      const user = userDoc.data()
      const recipientProfileID = this.state.jobProperties.opponentProfileID;
      if (!user.pendingTransaction || user.pendingTransaction.envelope != this.envelope ||
        user.pendingTransaction.recipientProfileID != recipientProfileID ||
        user.pendingTransaction.transactionID != this.id) {
        console.log("Major error!")
        console.log("Major error!")
        alert("Error!")
        // tbd reportError major error
        return;
      }

      const stellarTransaction = new Stellar.Transaction(this.envelope, Stellar.Networks.PUBLIC);
      await global.timeout(100)
      //check wep
      let encK = await SecureStore.getItemAsync("W" + global.myUID.substring(2, 20))

      try {
        if (CryptoJS.AES.decrypt(encK, this.wap + global.myUID.substring(2, 12)).toString(CryptoJS.enc.Utf8) == "") {
          await global.timeout(8000)
          this.setState({ activityIndicatorAnimating: false, });
          alert("Wallet Password incorrect")
          console.log("WAP INCORRECT")
          this.wap = ""
          //XXTBD Count Incorrect Password attempts

          return;
        }
        else console.log("WAP OK")
      } catch (er) {
        await global.timeout(8000)
        alert("Wallet Password incorrect")
        this.setState({ activityIndicatorAnimating: false, });
        console.log("WAP CAUHGT")
        this.wap = ""
        //XXTBD Count Incorrect Password attempts

        return;
      }

      console.log("Password likely correct")
      this.signature = stellarTransaction.getKeypairSignature(Stellar.Keypair.fromSecret(CryptoJS.AES.decrypt(encK, this.wap + global.myUID.substring(2, 12)).toString(CryptoJS.enc.Utf8)));
      this.wap = ""
      encK = "X---"
      const r = await fetch(global.cloudFunctionURL + "updateJobACNValidation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          id: this.id,
          action: this.actionToPerform, //job action
          signature: this.signature,
          jobID: this.props.jobID,
          //cancelReason: cancelReason, delete
          myUID: global.myUID,
          idToken: global.idToken,
        })
      });
      const r2 = await r.json();
      this.signature = ""
      this.setState({ activityIndicatorAnimating: false, });
      await global.timeout(100)
      console.log("Response from transferACNValidation", r2)

      if (r2.msg === "SUCCESS") {
        this.setState({ showModalWAP: false })
        await global.timeout(100)
        alert("Funds released");
      } else {
        console.log("ACN transaction failed. check1");
        //alert("Payment failed. Try again")
        await global.timeout(100)
        this.setState({ showModalWAP: false, refreshing: false })
        await global.timeout(100)
        if (r2.msg === "ACCOUNT_BALANCE_NOT_SUFFICIENT") {
          alert("Account balance not sufficient");
        } else if (r2.msg === "TRANSACTION_EXPIRED") {
          alert("Transaction expired after 10 minutes. Try again.");
        } else if (r2.msg === "BLOCKED") {
          alert(r2.reason || "ERROR");
        } else if (r2.msg === "ERROR") {
          alert("Transaction not successful - Try again later.")
        } else {
          alert("Transfer not successful. Try again later");
          console.log("ACN transaction failed. check");
        }
      }
    } catch (error) {
      alert("An error occurred. Check internet connection")
      global.reportError?.(error, "catches", "JobsMain_ACN")
      this.setState({ activityIndicatorAnimating: false, refreshing: false });
      console.log("Error...... ", error);
    }
  };


  localAmount = (amountACN) => {
    let localAmount = amountACN * global.xRates[global.myLocalCurrency]
    return Math.round(localAmount)
    //return Math.round(localAmount * 100) / 100
    //return localAmount
  }

  renderPriceTable = (position) => {
    //prices in currency
    const wakandaDiscount = this.state.jobProperties.jobChargeDiscountWakanda || 0
    const totalDiscount = (this.state.jobProperties.jobChargeDiscount || 0) + wakandaDiscount
    const inst1 = this.state.jobProperties.jobChargeOnArrival || 0
    const inst2 = this.state.jobProperties.jobCharge - inst1 - totalDiscount
    const charge = inst1 + inst2
    const delivery = this.state.jobProperties.jobChargeDeliveryFee || 0
    const total = charge + delivery
    const cur = global.currencySymbols[this.state.jobProperties.jobChargeCurrency]

    //prices in local currency
    const showACN = global.walletType == "ACN" && this.state.jobProperties.jobChargeCurrency == "ACN"
    let inst1L, inst2L, chargeL, deliveryL, totalL, curL
    if (showACN) {
      inst1L = this.localAmount(inst1)
      inst2L = this.localAmount(inst2)
      chargeL = this.localAmount(charge)
      deliveryL = this.localAmount(delivery)
      totalL = this.localAmount(total)
      curL = global.currencySymbols[global.myLocalCurrency]
      totalDiscountL = this.localAmount(totalDiscount)
    }

    //is paid?
    const inst1Paid = this.state.jobProperties.systemInstallmentPaid > 0
    const inst2Paid = this.state.jobProperties.systemStatusJob == 'COMPLETED'
    const chargePaid = this.state.jobProperties.systemStatusJob == 'COMPLETED'
    const deliveryPaid = this.state.jobProperties.systemStatusDelivery == "DELIVERY_FEE_PAID_OUT" || (delivery > 0 && this.state.jobProperties.systemStatusJob == 'COMPLETED')
    const totalPaid = this.state.jobProperties.systemStatusJob == 'COMPLETED'

    const payable = this.state.jobProperties.systemStatusJob == 'ONGOING' || this.state.jobProperties.systemStatusJob == 'COMPLETED_PENDING_REVIEW'

    if (position == 'details') {
      if (this.state.jobProperties.systemStatusJob == 'ONGOING' && this.state.amCustomer) {
        return (
          <View style={{}} />)
      }
    }


    return (
      <View style={{ width: "100%", marginVertical: 16, }}>
        {inst1 == 0 && <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "90%" }}>
          <Text style={{ color: '#c0c0cc', fontSize: 11, padding: 3, width: "45%" }}>Charge</Text>
          <View style={{ width: "30%" }}>
            <Text style={{ color: '#c0c0cc', fontSize: 11, padding: 3, width: "100%", textAlign: 'right', }}>{charge} {cur}</Text>
            {showACN && <View style={{ backgroundColor: "#c0c0cc", borderRadius: 10, margin: 2, marginTop: -4, width: "100%" }}><Text style={{ color: '#000', fontSize: 11, padding: 1, paddingRight: 5, textAlign: 'right', }}>{chargeL} {curL}</Text></View>}
          </View>
          <Text style={{ color: '#707077', fontSize: 9, padding: 3, width: "100%", paddingLeft: 6 }}>{chargePaid ? "Paid" : " "}</Text>
        </View>}

        {inst1 > 0 && <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "90%" }}>
          <Text style={{ color: '#c0c0cc', fontSize: 11, padding: 3, width: "45%" }}>1st Installment</Text>
          <View style={{ width: "30%" }}>
            <Text style={{ color: '#c0c0cc', fontSize: 11, padding: 3, width: "100%", textAlign: 'right', }}>{inst1} {cur}</Text>
            {showACN && <View style={{ backgroundColor: "#c0c0cc", borderRadius: 10, margin: 2, marginTop: -4, width: "100%" }}><Text style={{ color: '#000', fontSize: 11, padding: 1, paddingRight: 5, textAlign: 'right', }}>{inst1L} {curL}</Text></View>}
          </View>
          {!inst1Paid && payable && !!this.state.amCustomer ? <TouchableOpacity
            style={{ width: "25%" }}
            onPress={() => {
              Alert.alert(
                "", "Release first installment to provider?",
                [{ text: "Cancel", onPress: () => console.log("Cancel Pressed"), style: "cancel" },
                { text: "OK", onPress: () => { this.submitJobUpdate('CUSTOMER_RELEASES_INSTALLMENT', ''); } }],
                { cancelable: false });
            }}
          >
            <Text style={{ color: '#008866', fontSize: showACN ? 16 : 11, padding: 3, width: "100%", fontWeight: "bold", paddingLeft: 6 }}>PAY</Text>
          </TouchableOpacity> : <Text style={{ color: '#707077', fontSize: 9, padding: 3, width: "100%", paddingLeft: 6 }}>{inst1Paid ? "Paid" : " "}</Text>}
        </View>}

        {inst1 > 0 && <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "90%" }}>
          <Text style={{ color: '#c0c0cc', fontSize: 11, padding: 3, width: "45%" }}>2nd Installment</Text>
          <View style={{ width: "30%" }}>
            <Text style={{ color: '#c0c0cc', fontSize: 11, padding: 3, width: "100%", textAlign: 'right' }}>{inst2} {cur}</Text>
            {showACN && <View style={{ backgroundColor: "#c0c0cc", borderRadius: 10, margin: 2, marginTop: -4, width: "100%" }}><Text style={{ color: '#000', fontSize: 11, padding: 1, paddingRight: 5, textAlign: 'right', }}>{inst2L} {curL}</Text></View>}
          </View>
          <Text style={{ color: '#707077', fontSize: 9, padding: 3, width: "100%", paddingLeft: 6 }}>{inst2Paid ? "Paid" : " "}</Text>
        </View>}
        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "90%" }}>
          <Text style={{ color: '#c0c0cc', fontSize: 11, padding: 3, width: "45%" }}>Delivery Fee</Text>
          <View style={{ width: "30%" }}>
            <Text style={{ color: '#c0c0cc', fontSize: 11, padding: 3, width: "100%", textAlign: 'right' }}>{delivery} {cur}</Text>
            {showACN && <View style={{ backgroundColor: "#c0c0cc", borderRadius: 10, margin: 2, marginTop: -4, width: "100%" }}><Text style={{ color: '#000', fontSize: 11, padding: 1, paddingRight: 5, textAlign: 'right', }}>{deliveryL} {curL}</Text></View>}
          </View>
          {(delivery > 0 && !deliveryPaid && !!payable && !!this.state.amCustomer) ? <TouchableOpacity
            style={{ width: "25%" }}
            onPress={() => {
              Alert.alert(
                "", "Release delivery fee to provider?",
                [{ text: "Cancel", onPress: () => console.log("Cancel Pressed"), style: "cancel" },
                { text: "OK", onPress: () => { this.submitJobUpdate('CUSTOMER_RELEASES_DELIVERY_FEE', ''); } }],
                { cancelable: false });
            }}
          >
            <Text style={{ color: '#008866', fontSize: showACN ? 16 : 11, padding: 3, width: "100%", fontWeight: "bold", paddingLeft: 6 }}>PAY</Text>
          </TouchableOpacity> :
            <Text style={{ color: '#707077', fontSize: 9, padding: 3, width: "100%", paddingLeft: 6 }}>{!!deliveryPaid ? "Paid" : " "}</Text>}
        </View>
        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "90%", borderTopWidth: 0.5, borderTopColor: '#909099', paddingTop: 7 }}>
          <Text style={{ color: '#c0c0cc', fontSize: 11, padding: 3, width: "45%", fontWeight: "bold" }}>Total</Text>
          <View style={{ width: "30%" }}>
            <Text style={{ color: '#c0c0cc', fontSize: 11, padding: 3, width: "100%", textAlign: 'right', fontWeight: "bold" }}>{total} {cur}</Text>
            {showACN && <View style={{ backgroundColor: "#c0c0cc", borderRadius: 10, margin: 2, marginTop: -4, width: "100%" }}><Text style={{ color: '#000', fontSize: 11, padding: 1, paddingRight: 5, textAlign: 'right', }}>{totalL} {curL}</Text></View>}
          </View>
          {(!totalPaid && !!this.state.amCustomer && !!payable) ? <TouchableOpacity
            style={{ width: "25%" }}
            onPress={() => {
              Alert.alert(
                "Order completed?", "Remaining funds will be released to provider",
                [{ text: "Cancel", onPress: () => console.log("Cancel Pressed"), style: "cancel" },
                {
                  text: "OK", onPress: () => {
                    if (this.state.jobProperties.systemStatusJob == "COMPLETED_PENDING_REVIEW")
                      this.submitJobUpdate('CUSTOMER_REVIEWS_ACCEPTING', '')
                    else this.submitJobUpdate('CUSTOMER_COMPLETES_JOB', '');
                  }
                }],
                { cancelable: false });
            }}
          >
            <Text style={{ color: '#008866', fontSize: showACN ? 16 : 11, padding: 3, width: "100%", fontWeight: "bold", paddingLeft: 6 }}>PAY</Text>
          </TouchableOpacity> :
            <Text style={{ color: '#707077', fontSize: 9, padding: 3, width: "100%", paddingLeft: 6 }}>{totalPaid ? "Paid" : " "}</Text>}
        </View>
        {!!wakandaDiscount && !this.state.amCustomer && <Text style={{ color: '#b0eebc', fontSize: 11, padding: 3, marginTop: 3 }}>You get {cur} {wakandaDiscount} from Wakanda ADDED.</Text>}
        {!!totalDiscount && !showACN && <Text style={{ color: '#b0eebc', fontSize: 11, padding: 3 }}>Charge includes {cur} {totalDiscount} discount.</Text>}
        {!!totalDiscount && showACN && <Text style={{ color: '#b0eebc', fontSize: 11, padding: 3 }}>Charge includes {curL} {totalDiscountL} discount.</Text>}

      </View>
    )
  }



  render() {
    // images = {
    //   Electronics: require("../images/categories/Electronics.png"),
    //   Home: require("../images/categories/Home.png"),
    //   Fashion: require("../images/categories/Fashion.png"),
    //   Cars: require("../images/categories/Cars.png"),
    //   Food: require("../images/categories/Food.png"),
    //   Beauty: require("../images/categories/Beauty.png"),
    //   Professionals: require("../images/categories/Professionals.png"),
    //   Repair_Refill: require("../images/categories/Repair_Refill.png"),
    //   Handyman: require("../images/categories/Handyman.png"),
    //   QuickJobs: require("../images/categories/QuickJobs.png"),
    //   Health: require("../images/categories/Health.png"),
    //   Art: require("../images/categories/Art.png"),
    //   Housing: require("../images/categories/Housing.png"),
    //   Travel: require("../images/categories/Travel.png"),
    //   Commercial: require("../images/categories/Commercial.png")
    // };

    let cancelReason = this.state.jobProperties?.cancelReason;
    switch (this.state.jobProperties?.cancelReason) {

      case "NOT_NEEDED_ANYMORE":
        cancelReason = "The order is not needed anymore ";
        break;
      case "DIFFERENT_JOB_CHARGE":
        cancelReason = "We agreed on a different job/different charge ";
        break;
      case "NOT_WELL_DONE":
        cancelReason = "The job was not well done";
        break;
      case "ASKING_FOR_MORE_MONEY":
        cancelReason = "Provider is asking for more money than agreed";
        break;
      case "DID_SERIOUS_DAMAGE":
        cancelReason = "Provider did serious damage";
        break;
      case "DID_NOT_SHOW_UP":
        cancelReason = "Provider did not show up";
        break;
      case "NOT_REACHABLE":
        cancelReason = "Provider is not reachable for days";
        break;
      case "CUSTOMER_SAID_CANCEL":
        cancelReason = "Customer told me to cancel";
        break;
      case "UNDERESTIMATED_WORK":
        cancelReason = "Provider underestimated the amount of work to be done";
        break;
      case "CUSTOMER_NOT_AVAILABLE":
        cancelReason = "Customer not available for days";
        break;
      case "CUSTOMER_NOT_REACHABLE":
        cancelReason = "Customer not reachable for days";
        break;

    }

    const calendarStrings = {
      sameDay: "LT",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      lastDay: "[Yesterday]",
      lastWeek: "dddd",
      sameElse: "DD-MMM-YY"
    };

    return (
      <View>
        {/* //////////////////////////////////BEGINNING OF JOBS DISPLAY///////////////////////////////////////* */}
        <View style={{
          flexDirection: 'row', borderBottomColor: this.state.needsAction ? '#A8CF45' : '#444444', justifyContent: 'space-between', borderBottomWidth: 4,
          alignItems: 'stretch', backgroundColor: '#222222', borderWidth: this.state.needsAction ? 4 : 0,
          borderColor: this.state.needsAction ? '#A8CF45' : '#222222'
        }}>

          <TouchableOpacity style={{ width: '35%', backgroundColor: '#333333', padding: 5, paddingBottom: 10 }}
            onPress={() => {

              this.props.navigation.navigate("jobsProfile", {
                profileID: this.state.jobProperties.opponentProfileID
              })
            }}
          >
            <View>
              {this.state.jobProperties.opponentProfilePictureURL ? <Image style={{ width: 60, height: 60, margin: 20, borderRadius: 30, alignSelf: 'center' }}
                source={{ uri: this.state.jobProperties.opponentProfilePictureURL }}
              /> :
                <View style={{ width: 60, height: 60, margin: 20, borderRadius: 30, alignSelf: 'center', backgroundColor: '#444444' }}
                />}
              <View style={{ marginBottom: 5 }}>
                <Text style={{ marginTop: -10, color: '#f0f0ff', textAlign: 'center' }}>
                  {this.state.jobProperties.opponentName}</Text>
                <Text style={{ color: '#88888f', fontSize: 12, paddingBottom: 5, textAlign: 'center' }}>
                  {this.state.jobProperties.opponentNameBusiness} </Text>
              </View>
            </View>
          </TouchableOpacity>

          {!!this.state.activityIndicatorAnimating && <View style={{ width: '65%', marginLeft: 10, marginRight: 10, paddingBottom: 10, alignItems: 'center', justifyContent: 'center' }}>
            <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
              <ActivityIndicator
                size="large"
                color="#f0f0fff0f0ff"
                animating={true}
              />
            </View>
          </View>}

          {!!this.state.refreshing && !this.state.activityIndicatorAnimating && <View style={{ width: '65%', marginLeft: 10, marginRight: 10, paddingBottom: 10, alignItems: 'center', justifyContent: 'center' }}>
            <View style={{ marginRight: 5, paddingTop: 5 }}>
              <Text style={{ color: '#909099', fontWeight: 'bold' }}>  LOADING...</Text>
            </View>
          </View>}

          {!this.state.refreshing && !this.state.activityIndicatorAnimating && <View style={{ width: '65%', marginLeft: 10, marginRight: 10, paddingBottom: 10 }}>

            <Text style={{ textAlign: 'right', fontSize: 10, color: '#909099', paddingRight: 20, paddingTop: 3 }}>
              {this.state.dateCreated != null && (
                <Moment element={Text} calendar={calendarStrings}>
                  {this.state.dateCreated}
                </Moment>)}
            </Text>


            {/* <View style={{ flexDirection: 'row', marginTop: 0 }}>
              <Image style={{ height: 15, width: 15, borderRadius: 5, marginRight: 5 }}
                source={images[this.state.jobProperties.jobCategory1]}></Image>
              <Text style={{ color: 'white', paddingRight: 10 }}>
                {this.state.jobProperties.jobCategory1}
              </Text>
              <Text style={{ color: 'white', paddingRight: 10, fontSize: 11, color: '#dddddd', paddingTop: 2 }}>
                {this.state.jobProperties.jobCategory2}
              </Text>
            </View> */}
            {/* //////////OPTIONAL DISPLAYS BASED ON VALUES OF STATUS AND AMCUSTOMER////////////////// */}
            {/* status: 'PENDING' amCustomer: false */}

            {this.state.jobProperties.systemStatusJob == 'PENDING' && !this.state.amCustomer &&
              <View>
                <Text style={{ color: '#f0f0ff', fontWeight: 'bold', padding: 5 }}>NEW ORDER</Text>

                <View style={{
                  flexDirection: 'row', marginTop: 5, marginRight: 20,
                  alignItems: 'stretch', shadowOffset: { width: 0, height: 3 }
                }}>

                  <TouchableOpacity
                    onPress={() => {

                      Alert.alert(
                        "Accept this order?", "",
                        [
                          {
                            text: "Cancel",
                            onPress: () => console.log("Cancel Pressed"),
                            style: "cancel"
                          },
                          {
                            text: "YES",
                            onPress: () => {
                              console.log("OK Pressed");
                              this.submitJobUpdate('PROVIDER_ACCEPTS_JOB', '');
                            }
                          }
                        ],
                        { cancelable: false }
                      );
                    }}
                  >
                    <View style={{
                      marginRight: 5, flex: 1,
                      borderColor: '#008866', borderRadius: 10, borderWidth: 2
                    }}>
                      <Text style={{ color: 'green', padding: 5, fontWeight: 'bold' }}>ACCEPT</Text>
                    </View>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {

                      Alert.alert(
                        "Sure?", "Reject this order?",
                        [
                          {
                            text: "NO",
                            onPress: () => console.log("Cancel Pressed"),
                            style: "cancel"
                          },
                          {
                            text: "YES",
                            onPress: () => {
                              console.log("OK Pressed");
                              this.submitJobUpdate('PROVIDER_DECLINES_JOB', '');
                            }
                          }
                        ],
                        { cancelable: false }
                      );
                    }}>
                    <View style={{ marginRight: 5, borderColor: 'red', flex: 1, borderRadius: 10, borderWidth: 2 }}>
                      <Text style={{ color: 'red', padding: 5, textAlign: 'center', fontWeight: 'bold' }}>REJECT</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            }

            {/* /////////////////////////PROVIDER REJECTS JOB//////////////////////////////  */}

            {this.state.jobProperties.systemStatusJob == 'DECLINED' && !this.state.amCustomer &&
              <View >
                <Text style={{ color: '#f0f0ff', fontWeight: 'bold', padding: 5 }}>ORDER REJECTED</Text>
                <Text style={{ fontSize: 10, color: '#909099', padding: 5, marginTop: -10, marginBottom: 15 }}>You rejected the order</Text>
              </View>
            }
            {this.state.jobProperties.systemStatusJob == 'DECLINED' && !!this.state.amCustomer &&
              <View >
                <Text style={{ color: '#f0f0ff', fontWeight: 'bold', padding: 5 }}>ORDER REJECTED</Text>
                <Text style={{ fontSize: 10, color: '#909099', padding: 5, marginTop: -10, marginBottom: 15 }}>The provider did not accept the order</Text>
              </View>
            }
            {/* /////////////////////////CANCELLED BY PROVIDER//////////////////////////////  */}

            {this.state.jobProperties.systemStatusJob == 'CANCELLED_BY_PROVIDER' && !this.state.amCustomer &&
              <View>
                <View >
                  <Text style={{ color: '#f0f0ff', fontWeight: 'bold', paddingTop: 5 }}>YOU CANCELLED THE ORDER</Text>
                </View>
                <View style={{ width: '90%' }}>
                  <Text style={{ color: '#909099', fontSize: 10 }}>REASON: {cancelReason}</Text>
                </View>
                {this.state.jobProperties.systemStatusRatingByProvider == 'OPEN' ? <View style={{ width: '90%' }}>

                  <Text style={{ color: '#f0f0ff', fontSize: 10, paddingTop: 5 }}>Would you like to work with this customer again? </Text>
                  <View style={{ flexDirection: 'row', marginTop: 5 }}>
                    <TouchableOpacity
                      onPress={() => { this.callWorkAgain('YES') }}
                    ><Text style={{ color: '#008866', fontSize: 12, padding: 3, fontWeight: "bold" }}>YES</Text></TouchableOpacity>
                    <Text style={{ color: '#008866', fontSize: 12, padding: 3, fontWeight: "bold" }}> </Text><TouchableOpacity onPress={() => { this.callWorkAgain('NO') }}>
                      <Text style={{ color: '#CC3333', fontSize: 12, padding: 3, fontWeight: "bold" }}>NO</Text>
                    </TouchableOpacity>
                  </View>
                </View> :
                  <View>
                    <Text style={{ color: '#aaaab3', fontSize: 10 }}>{
                      this.state.jobProperties.systemStatusRatingByProvider.workAgainWithCustomer == 'NO' ? 'You will not receive further orders from this customer' : 'You will continue to receive orders from this customer'
                    }</Text>
                  </View>
                }
              </View>
            }
            {this.state.jobProperties.systemStatusJob == 'CANCELLED_BY_PROVIDER' && !!this.state.amCustomer &&
              <View>
                <View>
                  <Text style={{ color: '#f0f0ff', fontWeight: 'bold', paddingTop: 5 }}>PROVIDER CANCELLED ORDER</Text>
                </View>
                <View style={{ width: '90%' }}>
                  <Text style={{ color: '#909099', fontSize: 10 }}>REASON: {cancelReason}</Text>
                </View>
                {this.state.jobProperties.systemStatusRatingByCustomer == 'OPEN' && <TouchableOpacity
                  onPress={() => { this.setState({ modalVisible: true }) }}
                  style={{
                    marginRight: 5,
                    borderColor: '#E9BC0A', borderRadius: 10, borderWidth: 2, width: '60%', marginTop: 3
                  }}>
                  <Text style={{ color: '#E9BC0A', padding: 5, textAlign: 'center' }}>RATE ORDER</Text>
                </TouchableOpacity>}
              </View>
            }
            {/* /////////////////////////CANCELLED BY CUSTOMER//////////////////////////////  */}
            {this.state.jobProperties.systemStatusJob == 'CANCELLED_BY_CUSTOMER' && !this.state.amCustomer &&
              <View >
                <View >
                  <Text style={{ color: '#f0f0ff', paddingTop: 5, fontWeight: 'bold' }}>THE CUSTOMER CANCELLED</Text>
                </View>
                <View style={{ width: '90%' }}>
                  <Text style={{ color: '#909099', fontSize: 10 }}>REASON:{cancelReason}</Text>
                </View>
                {this.state.jobProperties.systemStatusRatingByProvider == 'OPEN' ? <View style={{ width: '90%' }}>

                  <Text style={{ color: '#E9BC0A', fontSize: 10, paddingTop: 5 }}>Would you like to receive orders from this customer again? </Text>
                  <View style={{ flexDirection: 'row', marginTop: 5 }}>
                    <TouchableOpacity
                      onPress={() => { this.callWorkAgain('YES') }}
                    ><Text style={{ color: '#008866', fontSize: 12, padding: 3, fontWeight: "bold" }}>YES</Text></TouchableOpacity>
                    <Text style={{ color: '#008866', fontSize: 12, padding: 3, fontWeight: "bold" }}> </Text><TouchableOpacity onPress={() => { this.callWorkAgain('NO') }}>
                      <Text style={{ color: '#CC3333', fontSize: 12, padding: 3, fontWeight: "bold" }}>NO</Text>
                    </TouchableOpacity>
                  </View>
                </View> :
                  <View>
                    <Text style={{ color: '#aaaab3', fontSize: 10 }}>{
                      this.state.jobProperties.systemStatusRatingByProvider.workAgainWithCustomer == 'NO' ? 'You will not receive further orders from this customer' : 'You will continue to receive orders from this customer'
                    }</Text>
                  </View>
                }
              </View>
            }
            {this.state.jobProperties.systemStatusJob == 'CANCELLED_BY_CUSTOMER' && !!this.state.amCustomer &&
              <View style={{ paddingLeft: -5 }}>
                <View >
                  <Text style={{ color: '#f0f0ff', paddingTop: 5, fontWeight: 'bold' }}>YOU CANCELLED</Text>
                </View>
                <View style={{ width: '90%' }}>
                  <Text style={{ color: '#909099', fontSize: 10 }}>REASON: {cancelReason}</Text>
                </View>
                <View >
                  <Text style={{ color: '#aaaab3', fontSize: 10 }}>You have been refunded.</Text>
                </View>
                {this.state.jobProperties.systemStatusRatingByCustomer == 'OPEN' && <TouchableOpacity
                  onPress={() => { this.setState({ modalVisible: true }) }}
                  style={{
                    marginRight: 5,
                    borderColor: '#E9BC0A', borderRadius: 10, borderWidth: 2, width: '60%', marginTop: 3
                  }}>
                  <Text style={{ color: '#E9BC0A', padding: 5, textAlign: 'center' }}>RATE ORDER</Text>
                </TouchableOpacity>}
              </View>
            }
            {/* //////////OPTIONAL DISPLAYS BASED ON VALUES OF STATUS AND AMCUSTOMER/////////////////////// */}
            {/* status: 'PENDING' amCustomer: true */}
            {this.state.jobProperties.systemStatusJob == 'PENDING' && !!this.state.amCustomer &&
              <View style={{
                marginRight: 10
                , justifyContent: 'space-between', alignItems: 'stretch'
              }}>
                <View style={{ marginRight: 5, paddingTop: 5 }}>
                  <Text style={{ color: '#f0f0ff', fontWeight: 'bold', padding: 5 }}>PENDING...</Text>
                  <Text style={{ fontSize: 10, color: '#909099', padding: 5, marginTop: -10, marginBottom: 15 }}>Provider has not yet accepted</Text>

                </View>
                <TouchableOpacity
                  onPress={() => {

                    Alert.alert(
                      "Sure", "Sure you want to cancel?",
                      [
                        {
                          text: "NO",
                          onPress: () => console.log("Cancel Pressed"),
                          style: "cancel"
                        },
                        {
                          text: "YES",
                          onPress: () => {
                            console.log("OK Pressed");
                            this.submitJobUpdate('CUSTOMER_CANCELS_BEFORE_ACCEPT', '');
                          }
                        }
                      ],
                      { cancelable: false }
                    );
                  }}
                >
                  <View style={{
                    marginRight: 5, flex: 1, borderRadius: 10,
                    width: '60%', marginTop: 3
                  }}>
                    <Text style={{ color: '#E9BC0A', padding: 5, fontSize: 12, fontWeight: 'bold' }}>CANCEL ORDER</Text>
                  </View>
                </TouchableOpacity>
              </View>
            }
            {/* //////////OPTIONAL DISPLAYS BASED ON VALUES OF STATUS AND AMCUSTOMER/////////////////////// */}
            {/* status: 'ONGOING' amCustomer: true */}
            {this.state.jobProperties.systemStatusJob == 'ONGOING' && !!this.state.amCustomer &&
              <View style={{
                marginTop: 0, marginRight: 10
                , justifyContent: 'space-between', alignItems: 'stretch'
              }}>
                <Text style={{ color: '#f0f0ff', fontWeight: 'bold', padding: 5 }}>ORDER ONGOING</Text>
                <Text style={{ fontSize: 10, color: '#909099', padding: 5, marginTop: -10, marginBottom: 15 }}>The provider has accepted the order</Text>

                {this.renderPriceTable("main")}

                <TouchableOpacity
                  onPress={() => { this.setState({ showCancelForm: true }) }}
                >
                  <Text style={{ color: '#cc3333', fontWeight: 'bold', fontSize: 10, paddingLeft: 3, paddingTop: 10, paddingBottom: 2 }}>CANCEL ORDER </Text>
                </TouchableOpacity>
              </View>
            }
            {/* //////////OPTIONAL DISPLAYS BASED ON VALUES OF STATUS AND AMCUSTOMER/////////////////////// */}
            {/* status: 'ONGOING' amCustomer: false */}
            {this.state.jobProperties.systemStatusJob == 'ONGOING' && !this.state.amCustomer &&
              <View style={{
                marginTop: 5, marginRight: 10
                , justifyContent: 'space-between', alignItems: 'stretch'
              }}>
                <Text style={{ color: '#f0f0ff', fontWeight: 'bold', padding: 5 }}>ORDER ACCEPTED</Text>
                <Text style={{ fontSize: 10, color: '#909099', padding: 5, marginTop: -10, }}>Fulfil now!</Text>

                <TouchableOpacity
                  onPress={() => {
                    Alert.alert(
                      "Sure?", "Sure the order has been completed?",
                      [{ text: "Cancel", onPress: () => console.log("Cancel Pressed"), style: "cancel" },
                      { text: "Yes", onPress: () => { this.submitJobUpdate('PROVIDER_COMPLETES_JOB', ''); } }],
                      { cancelable: false });
                  }}
                >
                  <View style={{
                    paddingTop: 3, marginRight: 20,
                  }}>
                    <Text style={{ color: '#008866', fontSize: 10, fontWeight: 'bold', padding: 5, paddingTop: 9 }}>MARK COMPLETED</Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => { this.setState({ providerComplaintForm: true }) }}
                >

                  <Text style={{
                    color: '#CC3333', padding: 5, fontSize: 10, fontWeight: 'bold'
                  }}>CANCEL</Text>

                </TouchableOpacity>
              </View>
            }
            {/* //////////OPTIONAL DISPLAYS BASED ON VALUES OF STATUS AND AMCUSTOMER/////////////////////// */}
            {/* status: 'COMPLETED PENDING REVIEW' amCustomer: true */}
            {this.state.jobProperties.systemStatusJob == 'COMPLETED_PENDING_REVIEW' && !!this.state.amCustomer &&
              <View style={{
                marginTop: 5, marginRight: 10
                , justifyContent: 'space-between', alignItems: 'stretch'
              }}>
                <Text style={{ color: '#f0f0ff', fontWeight: 'bold', paddingTop: 3, paddingBottom: 5 }}>
                  PROVIDER COMPLETED ORDER
                </Text>
                <Text style={{ fontSize: 10, color: '#909099', padding: 5, marginTop: -10, marginBottom: 15 }}>The provider has marked the order completed. Confirm now that the order is completed.</Text>

                <TouchableOpacity

                  onPress={async () => {

                    Alert.alert(
                      "Order completed?", "Remaining funds will be released to the provider",
                      [
                        {
                          text: "Cancel",
                          onPress: () => console.log("Cancel Pressed"),
                          style: "cancel"
                        },
                        {
                          text: "YES",
                          onPress: async () => {
                            console.log("OK Pressed");

                            try {
                              await this.submitJobUpdate('CUSTOMER_REVIEWS_ACCEPTING', '');

                              // this.setState({ modalVisible: true });
                            } catch (err) {
                              await new Promise(resolve => {
                                setTimeout(resolve, 100);
                              });
                              alert('NOT SUCCESSFUL. PLEASE TRY AGAIN LATER');
                              console.log(err);
                            }
                          }
                        }
                      ],
                      { cancelable: false }
                    );
                  }}
                >
                  <View style={{
                    marginRight: 5, padding: 5, alignItems: 'center',
                    borderColor: '#008866', borderRadius: 10, borderWidth: 2
                  }}>
                    <Text style={{ color: 'green', fontWeight: 'bold', textAlign: 'center' }}>CONFIRM</Text>
                  </View>
                </TouchableOpacity>

                <View style={{ padding: 5 }}>
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({ showComplaintForm: true });
                      this.setState({ showComplaintForm: true })
                    }}
                  >
                    <Text style={{ color: '#CC3333', fontSize: 10, fontWeight: 'bold' }}>LODGE A COMPLAINT</Text>
                  </TouchableOpacity>
                </View>
              </View>
            }
            {/* //////////OPTIONAL DISPLAYS BASED ON VALUES OF STATUS AND AMCUSTOMER/////////////////////// */}
            {/* status: 'COMPLETED PENDING REVIEW' amCustomer: false */}
            {this.state.jobProperties.systemStatusJob == 'COMPLETED_PENDING_REVIEW' && !this.state.amCustomer &&
              <View style={{
                marginTop: 5, marginRight: 10
                , justifyContent: 'space-between', alignItems: 'stretch'
              }}>

                <View style={{ padding: 5 }}>
                  <Text style={{ color: '#f0f0ff', fontWeight: 'bold' }}>ORDER COMPLETED</Text>
                  <Text style={{ color: '#909099', fontSize: 10 }}>The customer is notified and should now pay.</Text>
                </View>
              </View>
            }
            { /* //////////OPTIONAL DISPLAYS BASED ON VALUES OF STATUS AND AMCUSTOMER/////////////////////// */}
            {/* status: 'COMPLETED' amCustomer: true */}
            {this.state.jobProperties.systemStatusJob == 'COMPLETED' && !!this.state.amCustomer &&
              <View style={{
                marginTop: 5, marginRight: 10
                , justifyContent: 'space-between', alignItems: 'stretch'
              }}>
                <View style={{ paddingTop: 5 }}>
                  <Text style={{ color: '#f0f0ff', fontWeight: 'bold' }}>ORDER COMPLETED</Text>
                  <View >
                    <Text style={{ color: '#909099', fontSize: 10 }}>Money paid.</Text>
                  </View>
                </View>
                {this.state.jobProperties.systemStatusRatingByCustomer == 'OPEN' &&
                  <TouchableOpacity
                    onPress={() => { this.setState({ modalVisible: true }) }}
                    style={{
                      marginRight: 5,
                      borderColor: '#E9BC0A', borderRadius: 10, borderWidth: 2, width: '60%', marginTop: 3,
                    }}>
                    <Text style={{ color: '#E9BC0A', padding: 5, textAlign: 'center' }}>RATE ORDER</Text>
                  </TouchableOpacity>
                }
              </View>
            }
            {/* //////////OPTIONAL DISPLAYS BASED ON VALUES OF STATUS AND AMCUSTOMER/////////////////////// */}
            {/* status: 'COMPLETED' amCustomer: false */}
            {this.state.jobProperties.systemStatusJob == 'COMPLETED' && !this.state.amCustomer &&
              <View style={{
                marginTop: 5, marginRight: 10
                , justifyContent: 'space-between', alignItems: 'stretch'
              }}>

                <View style={{ paddingVertical: 5 }}>
                  <Text style={{ color: '#f0f0ff', fontWeight: 'bold' }}>ORDER COMPLETED</Text>
                  <Text style={{ color: '#909099', fontSize: 10 }}>You have been paid.</Text>
                </View>
                {this.state.jobProperties.systemStatusRatingByProvider == 'OPEN' ? <View style={{ width: '90%' }}>

                  <Text style={{ color: '#E9BC0A', fontSize: 10, paddingTop: 5 }}>Would you like to receive orders from this customer again? </Text>
                  <View style={{ flexDirection: 'row', marginTop: 5 }}>
                    <TouchableOpacity
                      onPress={() => { this.callWorkAgain('YES') }}
                    ><Text style={{ color: '#008866', fontSize: 12, padding: 3, fontWeight: "bold" }}>YES</Text></TouchableOpacity>
                    <Text style={{ color: '#008866', fontSize: 12, padding: 3, fontWeight: "bold" }}> </Text><TouchableOpacity onPress={() => { this.callWorkAgain('NO') }}>
                      <Text style={{ color: '#CC3333', fontSize: 12, padding: 3, fontWeight: "bold" }}>NO</Text>
                    </TouchableOpacity>
                  </View>
                </View> :
                  <View>
                    <Text style={{ color: '#aaaab3', fontSize: 10 }}>{
                      this.state.jobProperties.systemStatusRatingByProvider.workAgainWithCustomer == 'NO' ? 'You will not receive further orders from this customer' : 'You will continue to receive orders from this customer'
                    }</Text>
                  </View>
                }
              </View>
            }
            <View >
              <TouchableOpacity
                onPress={this.showDetails}
                style={{ flexDirection: 'row' }}
              >
                {!!this.state.showJobDetails && <Text style={{ color: '#909099', paddingTop: 10, }}>▽ Order Details</Text>}
                {!this.state.showJobDetails && <Text style={{ color: '#909099', paddingTop: 10, }}>▷ Order Details</Text>}

              </TouchableOpacity>
            </View>
            {!!this.state.showJobDetails &&
              <View style={{ marginLeft: 10 }}>
                <Text style={{ color: '#f0f0ff', paddingRight: 10 }}>
                  {this.state.jobProperties.jobDescription}
                </Text>

                {this.state.jobProperties.jobPostID && <View
                  style={{
                    width: 185,
                    height: 155,
                    flexDirection: "row",
                    alignItems: "flex-start",
                    transform: [{ scale: 0.5 }]
                  }}
                >
                  <TouchableOpacity
                    onPress={() => { if (!this.state.showPostDetail) this.setState({ showPostDetail: true }); }}
                    style={{ width: 300, height: 300, margin: -74, marginLeft: -90 }}
                  >
                    <Post
                      key={this.state.showPostDetail}
                      postID={this.state.jobProperties.jobPostID}
                      navigation={this.props.navigation}
                      showDetail={this.state.showPostDetail}
                      hideAuthor={true}
                      onHideDetail={() => {
                        this.setState({ showPostDetail: false });
                      }}
                      //relevantStatus={"LIVE"}
                      onIsNotRelevant={(postStatus) => {
                        this.setState({ postStatus });
                      }}
                      loadingColor={"#333"}
                      onDataLoaded={data => {
                        //this.setState({ data });
                      }}
                    />
                    {/* {this.state.postStatus && (
                      <View style={{ marginTop: -180, height: 180, alignItems: "center" }} >
                        <Text style={{ fontSize: 40, color: "white", fontWeight: "bold" }} >
                          {this.state.postStatus}
                        </Text>
                      </View>
                    )} */}
                  </TouchableOpacity>
                  {!!this.state.jobProperties.jobQuantity && <View style={{ marginLeft: 80, marginTop: -74, }}>
                    <Text style={{ color: '#c0c0cc', paddingRight: 10, fontSize: 18, paddingTop: 5 }}>
                      Quantity:
                </Text>
                    <Text style={{ color: '#c0c0cc', paddingRight: 10, fontSize: 28, paddingTop: 5, textAlign: "center" }}>
                      {this.state.jobProperties.jobQuantity}
                    </Text>
                  </View>}
                </View>}

                {this.renderPriceTable("details")}

                {(!!this.state.jobProperties.jobLocationAddress || !!this.state.jobProperties.jobLocationAddressDescription) && <Text style={{ paddingRight: 10, paddingTop: 0, color: '#f0f0ff' }}>
                  {this.state.jobProperties.jobLocationAddress}. {this.state.jobProperties.jobLocationAddressDescription}
                </Text>}
                {!!this.state.jobProperties.jobLocationGeo &&
                  <TouchableOpacity style={{ marginLeft: -5 }}
                    onPress={this.showMapAddress}
                  >
                    <Text style={{ color: 'orange' }}> Show on Map!</Text>
                  </TouchableOpacity>}
                {this.state.jobProperties.systemStatusJob == 'PENDING' && !this.state.amCustomer &&
                  <Text style={{ color: '#909099', paddingRight: 10, fontSize: 10, paddingTop: 5 }}>
                    {this.state.workAgainRatio} of providers want to work with this customer again
                </Text>}
              </View>
            }
          </View>}
          <View style={{ height: 15 }}></View>
        </View>
        {/* //////////////////////////////////END OF JOBS DISPLAY//////////////////////////////////////////////////////////* */}

        {/*/////////////////////////////////////LODGE COMPLAINT FORM/////////////////////////////////////////////////////////////////// */}
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.showComplaintForm}
          onRequestClose={() => {
            //leave this here
          }}
        >
          <View style={{
            alignItems: 'center', justifyContent: 'center',
            height: '50%', flex: 1, padding: 30
          }}
          >
            <View style={{ alignSelf: 'flex-end', marginRight: 10, marginTop: 5 }}>
              <TouchableOpacity
                style={{
                  backgroundColor: "white", borderRadius: 15, width: 30, height: 30,
                  padding: 2
                }}
                onPress={() => { this.setState({ showComplaintForm: false }) }}
              >
                <Text style={{ fontWeight: 'bold', fontSize: 20, color: 'blue', textAlign: 'center' }}>X</Text>
              </TouchableOpacity>
            </View>
            <View style={{
              backgroundColor: 'white', width: '90%', padding: 20, paddingTop: 30, paddingBottom: 30,
              borderRadius: 20
            }}>
              <Text style={{ fontWeight: 'bold', paddingBottom: 15 }}>Please state the reason why you want to lodge a complaint</Text>
              <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                <TouchableOpacity
                  onPress={() => { this.setState({ complaint: "NOT_NEEDED_ANYMORE" }) }}>
                  {this.circle(this.state.complaint == "NOT_NEEDED_ANYMORE" ? true : false)}
                </TouchableOpacity>
                <Text style={{ paddingLeft: 6, paddingTop: 1 }}>The order is not needed anymore</Text>
              </View>
              <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                <TouchableOpacity
                  onPress={() => { this.setState({ complaint: "DIFFERENT_JOB_CHARGE" }) }}>
                  {this.circle(this.state.complaint == "DIFFERENT_JOB_CHARGE" ? true : false)}
                </TouchableOpacity>
                <Text style={{ paddingLeft: 6, paddingTop: 1 }}>We agreed on a different job/different charge</Text>
              </View>
              <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                <TouchableOpacity
                  onPress={() => { this.setState({ complaint: "NOT_WELL_DONE" }) }}>
                  {this.circle(this.state.complaint == "NOT_WELL_DONE" ? true : false)}
                </TouchableOpacity>
                <Text style={{ paddingLeft: 6, paddingTop: 1 }}>The job was not well done</Text>
              </View>
              <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                <TouchableOpacity
                  onPress={() => { this.setState({ complaint: "ASKING_FOR_MORE_MONEY" }) }}>
                  {this.circle(this.state.complaint == "ASKING_FOR_MORE_MONEY" ? true : false)}
                </TouchableOpacity>
                <Text style={{ paddingLeft: 6, paddingTop: 1 }}>Provider is asking for more money than agreed</Text>
              </View>
              <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                <TouchableOpacity
                  onPress={() => { this.setState({ complaint: "DID_SERIOUS_DAMAGE" }) }}>
                  {this.circle(this.state.complaint == "DID_SERIOUS_DAMAGE" ? true : false)}
                </TouchableOpacity>
                <Text style={{ paddingLeft: 6, paddingTop: 1 }}>Provider did serious damage</Text>
              </View>
              <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                <TouchableOpacity
                  onPress={() => { this.setState({ complaint: "DID_NOT_SHOW_UP" }) }}>
                  {this.circle(this.state.complaint == "DID_NOT_SHOW_UP" ? true : false)}
                </TouchableOpacity>
                <Text style={{ paddingLeft: 6, paddingTop: 1 }}>Provider did not show up</Text>
              </View>
              <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                <TouchableOpacity
                  onPress={() => { this.setState({ complaint: "NOT_REACHABLE" }) }}>
                  {this.circle(this.state.complaint == "NOT_REACHABLE" ? true : false)}
                </TouchableOpacity>
                <Text style={{ paddingLeft: 6, paddingTop: 1 }}>Provider is not reachable for days</Text>
              </View>

              <TouchableOpacity
                onPress={() => {
                  this.setState({ showComplaintForm: false });
                  this.submitJobUpdate('CUSTOMER_REVIEWS_DECLINING', this.state.complaint)
                }}
                style={{ width: '100%' }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', backgroundColor: '#A8CF45', marginTop: 10, borderRadius: 8 }}>

                  <ActivityIndicator
                    size="small"
                    color="#000000"
                    style={{
                      marginRight: 10,
                      opacity: this.props.activityIndicatorAnimating ? 1.0 : 0.0
                    }}
                    animating={true}
                  />
                  <Text style={{ color: 'black', padding: 10, textAlign: 'center' }}>SUBMIT COMPLAINT</Text>
                  <View style={{ width: 30 }}></View>
                </View>

              </TouchableOpacity>
            </View>
          </View>
        </Modal>

        {/*//// ////////////////////////CANCEL FORM//////////////////////////////////////////////////////////////////////////////////////////*/}
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.showCancelForm}
          onRequestClose={() => {
            //leave this here
          }}
        >
          <View style={{
            alignItems: 'center', justifyContent: 'center',
            height: '50%', flex: 1, padding: 30
          }}
          >
            <View style={{ alignSelf: 'flex-end', marginRight: 10, marginTop: 5 }}>
              <TouchableOpacity
                style={{
                  backgroundColor: "white", borderRadius: 15, width: 30, height: 30,
                  padding: 2
                }}
                onPress={() => { this.setState({ showCancelForm: false }) }}
              >
                <Text style={{ fontWeight: 'bold', fontSize: 20, color: 'blue', textAlign: 'center' }}>X</Text>
              </TouchableOpacity>
            </View>
            <View style={{
              backgroundColor: 'white', width: '90%', padding: 20, paddingTop: 30, paddingBottom: 30,
              borderRadius: 20
            }}>
              <Text style={{ fontWeight: 'bold', paddingBottom: 15 }}>Please state the reason why the order needs to be cancelled</Text>
              <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                <TouchableOpacity
                  onPress={() => { this.setState({ complaint: "NOT_NEEDED_ANYMORE" }) }}>
                  {this.circle(this.state.complaint == "NOT_NEEDED_ANYMORE" ? true : false)}
                </TouchableOpacity>
                <Text style={{ paddingLeft: 6, paddingTop: 1 }}>The order is not needed anymore</Text>
              </View>
              <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                <TouchableOpacity
                  onPress={() => { this.setState({ complaint: "DIFFERENT_JOB_CHARGE" }) }}>
                  {this.circle(this.state.complaint == "DIFFERENT_JOB_CHARGE" ? true : false)}
                </TouchableOpacity>
                <Text style={{ paddingLeft: 6, paddingTop: 1 }}>We agreed on a different job/different charge</Text>
              </View>
              <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                <TouchableOpacity
                  onPress={() => { this.setState({ complaint: "NOT_WELL_DONE" }) }}>
                  {this.circle(this.state.complaint == "NOT_WELL_DONE" ? true : false)}
                </TouchableOpacity>
                <Text style={{ paddingLeft: 6, paddingTop: 1 }}>The job was not well done</Text>
              </View>
              <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                <TouchableOpacity
                  onPress={() => { this.setState({ complaint: "ASKING_FOR_MORE_MONEY" }) }}>
                  {this.circle(this.state.complaint == "ASKING_FOR_MORE_MONEY" ? true : false)}
                </TouchableOpacity>
                <Text style={{ paddingLeft: 6, paddingTop: 1 }}>Provider is asking for more money than agreed</Text>
              </View>
              <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                <TouchableOpacity
                  onPress={() => { this.setState({ complaint: "DID_SERIOUS_DAMAGE" }) }}>
                  {this.circle(this.state.complaint == "DID_SERIOUS_DAMAGE" ? true : false)}
                </TouchableOpacity>
                <Text style={{ paddingLeft: 6, paddingTop: 1 }}>Provider did serious damage</Text>
              </View>
              <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                <TouchableOpacity
                  onPress={() => { this.setState({ complaint: "DID_NOT_SHOW_UP" }) }}>
                  {this.circle(this.state.complaint == "DID_NOT_SHOW_UP" ? true : false)}
                </TouchableOpacity>
                <Text style={{ paddingLeft: 6, paddingTop: 1 }}>Provider did not show up</Text>
              </View>
              <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                <TouchableOpacity
                  onPress={() => { this.setState({ complaint: "NOT_REACHABLE" }) }}>
                  {this.circle(this.state.complaint == "NOT_REACHABLE" ? true : false)}
                </TouchableOpacity>
                <Text style={{ paddingLeft: 6, paddingTop: 1 }}>Provider is not reachable for days</Text>
              </View>

              <TouchableOpacity
                onPress={() => {
                  this.setState({ showCancelForm: false });
                  this.submitJobUpdate('CUSTOMER_CANCELS', this.state.complaint)
                }}
                style={{ width: '100%' }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', backgroundColor: '#A8CF45', marginTop: 10, borderRadius: 8 }}>

                  <ActivityIndicator
                    size="small"
                    color="#000000"
                    style={{
                      marginRight: 10,
                      opacity: this.props.activityIndicatorAnimating ? 1.0 : 0.0
                    }}
                    animating={true}
                  />
                  <Text style={{ color: 'black', padding: 10, textAlign: 'center' }}>CANCEL ORDER</Text>
                  <View style={{ width: 30 }}></View>
                </View>

              </TouchableOpacity>
            </View>
          </View>
        </Modal>

        {/*////////////////////////////////PROVIDER COMPLAINT FORM////////////////////////// */}
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.providerComplaintForm}
          onRequestClose={() => {
            //leave this here
          }}
        >
          <View style={{
            alignItems: 'center', justifyContent: 'center',
            height: '50%', flex: 1, padding: 30
          }}
          >
            <View style={{ alignSelf: 'flex-end', marginRight: 10, marginTop: 5 }}>
              <TouchableOpacity
                style={{
                  backgroundColor: "white", borderRadius: 15, width: 30, height: 30,
                  padding: 2
                }}
                onPress={() => { this.setState({ providerComplaintForm: false }) }}
              >
                <Text style={{ fontWeight: 'bold', fontSize: 20, color: 'blue', textAlign: 'center' }}>X</Text>
              </TouchableOpacity>
            </View>
            <View style={{
              backgroundColor: 'white', width: '90%', padding: 20, paddingTop: 30, paddingBottom: 30,
              borderRadius: 20
            }}>
              <Text style={{ fontWeight: 'bold', paddingBottom: 15 }}>Please state the reason why the order could not be completed</Text>
              <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                <TouchableOpacity
                  onPress={() => { this.setState({ complaint: "CUSTOMER_SAID_CANCEL" }) }}>
                  {this.circle(this.state.complaint == "CUSTOMER_SAID_CANCEL" ? true : false)}
                </TouchableOpacity>
                <Text style={{ paddingLeft: 6, paddingTop: 1 }}>Customer told me to cancel</Text>
              </View>
              <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                <TouchableOpacity
                  onPress={() => { this.setState({ complaint: "DIFFERENT_JOB_CHARGE" }) }}>
                  {this.circle(this.state.complaint == "DIFFERENT_JOB_CHARGE" ? true : false)}
                </TouchableOpacity>
                <Text style={{ paddingLeft: 6, paddingTop: 1 }}>We agreed on a different order/different charge</Text>
              </View>
              <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                <TouchableOpacity
                  onPress={() => { this.setState({ complaint: "UNDERESTIMATED_WORK" }) }}>
                  {this.circle(this.state.complaint == "UNDERESTIMATED_WORK" ? true : false)}
                </TouchableOpacity>
                <Text style={{ paddingLeft: 6, paddingTop: 1 }}>I underestimated the amount of work to be done</Text>
              </View>

              <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                <TouchableOpacity
                  onPress={() => { this.setState({ complaint: "CUSTOMER_NOT_AVAILABLE" }) }}>
                  {this.circle(this.state.complaint == "CUSTOMER_NOT_AVAILABLE" ? true : false)}
                </TouchableOpacity>
                <Text style={{ paddingLeft: 6, paddingTop: 1 }}>customer not available for days</Text>
              </View>
              <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                <TouchableOpacity
                  onPress={() => { this.setState({ complaint: "CUSTOMER_NOT_REACHABLE" }) }}>
                  {this.circle(this.state.complaint == "CUSTOMER_NOT_REACHABLE" ? true : false)}
                </TouchableOpacity>
                <Text style={{ paddingLeft: 6, paddingTop: 1 }}>customer not reachable for days</Text>
              </View>
              <Text style={{ paddingLeft: 6, paddingTop: 1, fontStyle: 'italic', color: 'grey', fontSize: 10 }}>YOU WILL NOT BE PAID THE REMAINING BALANCE</Text>
              <TouchableOpacity
                onPress={() => {
                  this.setState({ providerComplaintForm: false });
                  this.submitJobUpdate('PROVIDER_CANCELS', this.state.complaint)
                }}
                style={{ width: '100%' }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', backgroundColor: '#A8CF45', marginTop: 10, borderRadius: 8 }}>

                  <ActivityIndicator
                    size="small"
                    color="#000000"
                    style={{
                      marginRight: 10,
                      opacity: this.props.activityIndicatorAnimating ? 1.0 : 0.0
                    }}
                    animating={true}
                  />
                  <Text style={{
                    color: 'black', padding: 10, textAlign: 'center', fontWeight: 'bold'
                  }}>CANCEL ORDER</Text>
                  <View style={{ width: 30 }}></View>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>

        <Modal
          animationType="slide"
          transparent={false}
          visible={this.state.modalVisible}
          onRequestClose={() => {
            //leave this here
          }}>
          <JobRating
            onSubmit={(professionalism, quality, price, timeliness) => { this.submitReview(professionalism, quality, price, timeliness) }}
            onClose={() => { this.setState({ modalVisible: false }) }}
            activityIndicatorAnimating={this.state.activityIndicatorAnimating}
          ></JobRating>
        </Modal>

        {/* ///////////// ACN PAYMENT COMPLETION MODAL //////////////////// */}
        <Modal
          animationType="fade"
          transparent={false}
          visible={this.state.showModalWAP}
          onRequestClose={() => {
            //this.setState({showModalWAP: false});
          }}
        >
          <ScrollView style={{ paddingTop: 50, paddingHorizontal: 25, backgroundColor: "#000" }}>
            <Text
              style={{
                fontSize: 16,
                color: "#ccc",
                paddingBottom: 5,
                borderBottomColor: "#FFFFFF",
                borderBottomWidth: 0,
                marginTop: 20,
                justifyContent: "flex-start",
                textAlign: "center"
              }}
            >
              Your Wallet Password
              </Text>
            <View style={{
              marginBottom: 0,
              color: "#ffffff",
              borderWidth: 0,
              borderRadius: 25,
              height: 35,
              width: 224,
              backgroundColor: "#225",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              alignSelf: "center"
            }}>
              <Text style={{ fontSize: 18, color: "#ccc", marginHorizontal: 10 }}>
                🌍</Text>
              <TextInput
                onChangeText={wap => {
                  this.wap = wap;
                }}
                secureTextEntry={true}
                style={{
                  color: "#ccc",
                  borderWidth: 0,
                  height: 35,
                  flex: 1
                }}
                textAlign={'center'}
                underlineColorAndroid="transparent"
                bordered
              />
              <Text style={{ fontSize: 18, color: "#ccc", marginHorizontal: 10 }}>
                🌍</Text>
            </View>

            <View style={{ height: 25 }} />
            {!this.state.activityIndicatorAnimating ? (
              <View style={{ alignSelf: "stretch" }}>
                <TouchableOpacity style={global.gStyles.button} onPress={this.callPayValidation}>
                  <Text style={global.gStyles.buttontext}>Release Funds</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{ marginTop: 30, alignSelf: "stretch", alignItems: "center" }} onPress={() => { this.setState({ showModalWAP: false, refreshing: false }) }}>
                  <Text style={{ color: "#aaa", fontSize: 13 }}>Cancel</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{ width: 224, alignSelf: "center", alignItems: "center", margin: 30 }} onPress={async () => {
                  this.setState({ showModalWAP: false })
                  await global.timeout(100)
                  this.props.navigation.goBack()
                  await global.timeout(100)
                  this.props.navigation.navigate("walletSetup", { screen: "1d" })
                }}>
                  <Text style={{ color: "#aaa", fontSize: 13 }}>Forgot Password?</Text>
                </TouchableOpacity>
              </View>
            ) : (
              <ActivityIndicator
                size="small"
                color="#fff"
                style={{ alignSelf: "center", margin: 20 }}
                animating={true}
              />
            )}
            {/* <View style={{ backgroundColor: "#222", padding: 20 }}>
                  <Text
                    style={{
                      fontSize: 18,
                      color: "#888",
                      fontWeight: "bold",
                      marginBottom: 6
                    }}
                  >
                    Transfer details
                  </Text>
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#888",
                      marginTop: 6,
                    }}
                  >
                    Recipient: {this.state.recipientName}
                  </Text>
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#888",
                      marginTop: 6,

                    }}
                  >
                    Amount: {this.state.amount} {this.state.walletType == "NGN" ? "₦" : "₳"}
                  </Text>
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#888",
                      marginTop: 6,
                    }}
                  >
                    Remarks: {this.state.remarks || "-"}
                  </Text>
                </View> */}
          </ScrollView>
        </Modal>
      </View>
    );
  }
}

export default class JobsMainScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Orders",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };
  state = {
    jobIDs: [],
    loading: true
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    try {
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 200); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 500); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 1000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 2000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 3000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 4000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 5000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 8000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 10000); });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();

      this.unsubscribeFirestore = await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .collection("Refs")
        .doc("jobRefs")
        .onSnapshot(this.updateJobs);
    } catch (err) {
      alert("Connection lost");
      console.log("Error initiating database connection (JobMain)");
    }
  }

  componentWillUnmount() {
    console.log("Component JobMain will unmount");
    this.unsubscribeFirestore();
  }

  updateJobs = async jobRefSnapshot => {
    let ids = jobRefSnapshot.data().jobIDs;

    let inactiveJobIDsJSON = await AsyncStorage.getItem("inactiveJobIDs");
    if (inactiveJobIDsJSON) {
      let inactiveJobIDs = JSON.parse(inactiveJobIDsJSON);
      if (Array.isArray(inactiveJobIDs)) {
        ids.sort((a, b) => {
          const scoreA = (inactiveJobIDs.includes(a) ? 0 : 1)
          const scoreB = (inactiveJobIDs.includes(b) ? 0 : 1)
          return (scoreB - scoreA)
        })
      }
    }

    this.setState({ jobIDs: ids });
    this.sortableJobIDs = ids;
    this.setState({ loading: false });
    //Image.prefetch(url);
  };
  sortableJobIDs = [];
  lastUpdated = {};

  reorder = async (id, timestamp) => {
    //console.log('THE JOBID AND TIME STAMP ARE: ', id, timestamp);
    this.lastUpdated[id] = timestamp;

    const now = new Date()
    this.lastReorderRequest = now
    await global.timeout(100)
    if (this.lastReorderRequest != now) return;


    this.sortableJobIDs.sort((a, b) => {
      if (!a in this.lastUpdated) {
        this.lastUpdated[a] = 0;
      }
      if (!b in this.lastUpdated) {
        this.lastUpdated[b] = 0;
      }
      return this.lastUpdated[b] - this.lastUpdated[a];
    });
    this.setState({
      jobIDs: this.sortableJobIDs
    });
  };

  render() {
    // if (this.state.jobIDs == [] || typeof this.state.jobIDs != "array" || this.state.jobIDs.length == 0) {     (possible improvement if not working properly)
    if (this.state.jobIDs == []) {
      return (
        <View style={{ flex: 1, backgroundColor: '#000', justifyContent: "center", alignItems: "center" }}>
          <Image style={{ opacity: 0.3, height: 200, width: 220, alignItems: "center", marginBottom: 25 }}
            source={require('../images/tabNavigator/Jobs.png')}
          ></Image>
          <Text style={{ fontWeight: 'bold', fontSize: 15, textAlign: 'center', color: "#889" }}>
            You have no orders yet
        </Text>
          <Text style={{ textAlign: 'center', fontSize: 13, color: "#889" }}>
            Your orders will appear here
        </Text>
        </View>);
    };

    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#000000" }}>
        <View style={{ backgroundColor: "#000", flex: 1 }}>
          <View
            style={{
              borderBottomColor: "#555555",
              borderWidth: 1,
              ...Platform.select({
                ios: {
                  height: 55
                },
                android: {
                  height: 75
                }
              })
            }}
          />
          {!!this.state.loading && <View style={{ flex: 100, alignItems: 'center', justifyContent: 'center', marginVertical: 10 }}>
            <ActivityIndicator
              size="large"
              color="#909099"
              animating={true}
            />
          </View>}
          {!!this.state.jobIDs.length == 0 && !this.state.loading && <View style={{ flex: 100, alignItems: 'center', justifyContent: 'center', marginVertical: 10 }}>
            <Image style={{ opacity: 0.3, marginBottom: 25 }}
              source={require('../images/tabNavigator/Jobs.png')}
            ></Image>
            <Text style={{ color: '#909099', textAlign: 'center', fontSize: 18, fontStyle: 'italic' }}>
              Your Orders will be
                    </Text>
            <Text style={{ color: '#909099', textAlign: 'center', fontSize: 18, fontStyle: 'italic', paddingTop: 10, marginBottom: 20 }}>
              displayed here
                    </Text>
          </View>}
          <ScrollView>
            {this.state.jobIDs.map(id => {
              return (

                <Job
                  onChange={timestamp => {
                    this.reorder(id, timestamp);
                  }}
                  key={id}
                  jobID={id}
                  navigation={this.props.navigation}
                />
              );
            })}
          </ScrollView>

        </View>
      </SafeAreaView>
    );
  }
}

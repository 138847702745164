import React, { Component } from 'react'
import { Text, View, StyleSheet, Image, ScrollView, Dimensions, TextInput, TouchableHighlight, TouchableOpacity } from 'react-native';

export default class JobScreen2 extends Component {
    state = {

        shoppingImage: '',
        jobDescription: '',
        includeQty: true
    }


    onChangeJobDescription = async (text) => {

        try {
            console.log('job Description entered is:', text);
            await this.setState({ jobDescription: text })
            this.props.onUpdateDescription(text)
        }
        catch (err) {
            console.log('error is: ', err)
        }

    }

    render() {
        const screenWidth = Math.round(Dimensions.get('window').width);
        return (
            <View style={{ flex: 1, alignItems: 'flex-start', width: screenWidth }}>
                <ScrollView ref={ref => (this.theScrollView = ref)}>
                    <View style={{ paddingVertical: 20, alignItems: 'center', justifyContent: 'center', width: screenWidth }}>
                        <Text style={styles.text}>
                            Order Details
                    </Text>

                        <View style={{ alignSelf: "center", width: '90%', border: '1px green solid', marginTop: 10 }}>
                            {/* <Text style={{ color: 'white', paddingBottom: 5, textAlign:'center' }}>Description</Text> */}
                            <TextInput
                                multiline={true}
                                numberOfLines={4}
                                style={styles.textarea}
                                rowSpan={5} bordered placeholder="Briefly describe what you want in more detail if necessary"
                                placeholderTextColor='#666666'
                                // onChangeText={jobDescription => this.setState({ jobDescription })}
                                underlineColorAndroid="transparent"
                                textAlignVertical="top"
                                onChangeText={(text) => this.onChangeJobDescription(text)}
                            />
                        </View>
                        <View style={{ alignSelf: "center", flexDirection: "row", width: "90%", marginHorizontal: 10, justifyContent: "center", alignItems: "flex-start" }}>
                            {!this.props.itemTitle && !this.props.itemDescription && < View style={{ flex: 1 }} />}
                            {!!this.props.pictureURL && <View style={{
                                height: 150, width: 150, alignSelf: 'center', backgroundColor: 'black',
                                borderRadius: 15, marginTop: 10
                            }}>
                                <Image source={{ uri: this.props.pictureURL }}
                                    style={{
                                        height: 150, width: 150, alignSelf: 'center', borderRadius: 15
                                    }} />
                                {this.props.showDiscount && <View style={{
                                    backgroundColor: '#ace600', padding: 5, borderBottomLeftRadius: 5, borderBottomRightRadius: 5,
                                    borderRightWidth: 1, marginTop: -15
                                }}>
                                    {/* <Text style={{ color: '#ff4d94', fontWeight: 'bold', fontSize: 9, marginBottom: 0 }}>DISCOUNT</Text> */}
                                    <Text style={{ color: 'black', fontSize: 12, fontWeight: 'bold', paddingTop: 0 }}>{this.props.discount} OFF</Text>
                                </View>}
                            </View>}
                            <View style={{ flex: 1, justifyContent: "space-between" }}>
                                <View style={{ flex: 1 }}>
                                    <Text style={styles.text2}>{this.props.itemTitle}</Text>

                                    <Text style={styles.text3}>
                                        {this.props.itemDescription}
                                    </Text>
                                </View>

                                {!!this.props.itemTitle && <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginLeft: 10,
                                        marginRight: 0
                                    }}
                                >
                                    <TouchableOpacity
                                        onPress={() => {
                                            if (this.state.includeQty) {
                                                this.setState({ includeQty: false });
                                                this.props.onChangeQty("")
                                            } else {
                                                this.setState({ includeQty: true });
                                                this.props.onChangeQty("1")
                                            }
                                        }}
                                    >
                                        <Selector
                                            selected={this.state.includeQty}
                                            text={!!this.state.includeQty ? "Quantity" : "Include Quantity"}
                                        />
                                    </TouchableOpacity>
                                    {!!this.state.includeQty && (
                                        <View style={{ flexDirection: "row", alignItems: "center", }}>
                                            <TextInput
                                                onChangeText={q => {
                                                    this.props.onChangeQty(q)
                                                }}
                                                value={this.props.quantity}
                                                placeholder={"e.g. 1"}
                                                placeholderTextColor={"#888"}
                                                keyboardType="numeric"
                                                style={styles.textareaQty}
                                                maxLength={5}
                                                underlineColorAndroid="transparent"
                                                defaultValue={this.state.price}
                                                onFocus={() => { this.theScrollView?.scrollToEnd?.({ animated: true }); }}
                                                bordered
                                            />
                                        </View>
                                    )}
                                </View>}

                                {/* 
                                {this.props.showDiscount && <Text style={styles.text3}>
                                    {this.props.discount}
                                </Text>} */}
                            </View>
                        </View>
                    </View>
                    <View style={styles.bottom}>
                        <Text style={styles.text4}>
                            Only enter the product/service details you require here. Price and/or location will be entered in the next steps.
                        </Text>
                    </View>
                    <View style={{ height: 275 }} />
                </ScrollView>
            </View >
        )
    }
}


class Selector extends Component {
    render() {
        let height =
            this.props.text.length < 45
                ? 30
                : 45 + (this.props.text.length - 45) * 0.33;
        let circle = filled => {
            if (filled) {
                return (
                    <View
                        style={{ width: 20, height: 20, borderRadius: 10, borderColor: "#555",borderWidth:1 }}
                    >
                        <Text style={{ color: "#999",marginTop: -5, fontSize: 24 }}>✓</Text>
                    </View>
                );
            } else {
                return (
                    <View style={{ width: 20, height: 20, borderColor: "#556", borderWidth: 1, borderRadius: 10 }} />
                );
            }
        };

        return (
            <View style={{ height: height, flexDirection: "row", alignItems: "center" }}>
                {circle(this.props.selected)}
                <Text style={{ marginLeft: 10, color: "#999" }}>{this.props.text}</Text>
            </View>
        );
    }
}


const styles = ({
    textarea: {
        marginBottom: 20,
        color: "#e3e8ef",
        borderColor: "grey",
        borderWidth: 2,
        paddingLeft: 3,
        borderRadius: 5,
        height: 140,
    },
    textareaQty: {
        color: "#e3e8ef",
        borderColor: "grey",
        borderWidth: 2,
        padding: 3,
        borderRadius: 5,
        width: 100,
        textAlign: "center",
    },
    text: {
        color: '#fff',
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        paddingHorizontal: 20

    },
    text2: {
        color: '#ffffff',
        fontSize: 16,
        fontWeight: 'bold',
        paddingHorizontal: 10,
        paddingTop: 10

    },
    text3: {
        color: '#bbb',
        fontSize: 14,
        paddingHorizontal: 10,
        paddingVertical: 10

    },
    text4: {
        color: 'grey',
        fontSize: 14,
        textAlign: 'center',
        paddingVertical: 10,
        paddingHorizontal: 30

    },
    bottom: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 50
    }

})

//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
// Screen always "synced" between Wakanda Market and Health
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  Alert,
  Dimensions,
  Linking,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import * as Font from "expo-font";
import { SvgCss } from "react-native-svg";

import firebase from "firebase";
import "firebase/firestore";
import CachedImage from "../../shared/CachedImage";
const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;

export default class HomeServicesScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    const category1 = navigation.getParam("category1", "Services");
    const hideTitle = navigation.getParam("hideTitle", false);
    console.log("new category 1", category1);
    return {
      tabBarVisible: false,
      headerTransparent: true,
      title: hideTitle ? "" : category1,
      headerTitleStyle: {
        fontWeight: "bold",
        color: "#333333",
        shadowOpacity: 0.1,
      },
    };
  };

  state = {
    category1: null,
    assetsLoaded: false,
    promotionProfileID: null,
    promotionImageURL: null,
    promotionBannerComponents: [],
    listOfApps: ["Handyman", "Professionals", "Repair_Refill", "Cleaning"],
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => {
      return;
    };
  }

  appDetails = {
    Handyman: {
      icon: global.icons.Handyman.image,
      image: null,
      route: "",
      title: "Handyman",
      backgroundColor: global.icons.Handyman.bgcolor,
    },

    Professionals: {
      icon: global.icons.Professionals.image,
      image: null,
      route: "",
      title: "Professionals",
      backgroundColor: global.icons.Professionals.bgcolor,
    },
    Repair_Refill: {
      icon: global.icons.Repair_Refill.image,
      image: null,
      route: "",
      title: "Repair_Refill",
      backgroundColor: global.icons.Repair_Refill.bgcolor,
    },
    // Cleaning: {
    //   icon: global.icons.QuickJobs.image,
    //   image: null,
    //   route: "",
    //   title: "Cleaning",
    //   backgroundColor: global.icons.QuickJobs.bgcolor,
    // },
  };

  async componentDidMount() {
    console.log("global.categories", global.categories);
    console.log(
      "props categories",
      this.props.navigation.getParam("category1")
    );

    this.loadPromotions();
    await Font.loadAsync({
      "ProximaNovaA-Regular": require("../../../assets/font/ProximaNovaA-Regular.ttf"),
      "ProximaNovaA-Bold": require("../../../assets/font/ProximaNovaA-Bold.ttf"),
    });
    this.setState({ assetsLoaded: true });

    const category1 = this.props.navigation.getParam("category1", null);
    this.setState({ category1 });
    console.log("my category services", this.state.category1);
    try {
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 200);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 400);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 600);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 1000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 2000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 4000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 5000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 8000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 10000);
        });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 10000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 100000);
        });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID) return;
    } catch (err) {
      if (global.reportError) {
        global.reportError(err, "catches", "homeServices");
      }
    }
  }

  componentWillUnmount() {
    console.log("Component homeServices will unmount");
    this.unsubscribeFirestore();
  }

  loadPromotions = async () => {
    try {
      if (!global.myUID)
        await new Promise((resolve) => {
          setTimeout(resolve, 1000);
        });
      if (!global.myUID)
        await new Promise((resolve) => {
          setTimeout(resolve, 2000);
        });
      if (!global.myUID)
        await new Promise((resolve) => {
          setTimeout(resolve, 4000);
        });
      if (!global.myUID)
        await new Promise((resolve) => {
          setTimeout(resolve, 8000);
        });
      const promotionsDoc = await firebase
        .firestore()
        .collection("ServerClientReadOnly")
        .doc("promotions")
        .get();
      const promotions = promotionsDoc.data();

      const banners =
        global.myLocalCurrency == "GHS"
          ? promotions.ghBannersServices
          : promotions.topBannersServices;
      const newPromotions = banners.map((item) => {
        item.randomValue = Math.random();
        return item;
      });
      newPromotions.sort((a, b) => {
        return b.randomValue - a.randomValue;
      });
      this.setState({
        // promotionProfileID: promotions.topBannersElectronics[0].profileID,
        // promotionImageURL: promotions.topBannersElectronics[0].imageURL,
        promotionBannerComponents: newPromotions,
      });
      console.log(this.state.promotionBannerComponents);
    } catch (err) {
      console.log("Error initiating database connection (Search-CC)", err);
    }
  };

  render() {
    if (this.state.assetsLoaded) {
      return (
        <ScrollView>
          <LinearGradient
            colors={[
              "#4160E0",
              "#4160E0",
              "#F5F5F5",
              "#F5F5F5",
              "#F5F5F5",
              "#F5F5F5",
              "#F5F5F5",
              "#F5F5F5",
              "#F5F5F5",
              // "#F5F5F5",
              // "#F5F5F5",
            ]}
            style={{
              flex: 1,
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              height: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
          <SafeAreaView
          // style={{
          //   flex: 1,
          //   backgroundColor: global.app == "health" ? "#fff" : "#131313",
          // }}
          >
            <StatusBar barStyle="light-content" />

            <View
              style={{
                // backgroundColor: "#F5F5F5",
                flex: 1,
              }}
            >
              <View
                style={{
                  // backgroundColor: "#4160E0",
                  // borderBottomColor: "#555",
                  // borderBottomWidth: 1,
                  ...Platform.select({
                    ios: {
                      height: 55,
                    },
                    android: {
                      height: 75,
                    },
                  }),
                }}
              />

              <ScrollView
                horizontal={true}
                pagingEnabled={true}
                showsHorizontalScrollIndicator={false}
                // contentContainerStyle={{ bottom: 50 }}
              >
                {this.state.promotionBannerComponents.map((item) => {
                  return (
                    <TouchableOpacity
                      key={item.imageURL}
                      // key={item.profileID || item.url}
                      onPress={() => {
                        if (item.url) {
                          Linking.openURL(item.url);
                        } else if (item.profileID) {
                          let handoverID = item.profileID;
                          this.props.navigation.navigate("meProfile", {
                            profileID: handoverID,
                          });
                        }
                      }}
                      style={{ padding: 5, margin: 5 }}
                    >
                      {item.imageURL && (
                        <View style={{}}>
                          <CachedImage
                            key={item.imageURL}
                            style={{
                              width: SCREEN_WIDTH * 0.95,
                              height: SCREEN_HEIGHT * 0.25,
                              // bottom: 15,
                            }}
                            source={{
                              uri: item.imageURL,
                            }}
                            resizeMode="contain"
                          />
                        </View>
                      )}
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
              {this.state.promotionBannerComponents.length == 0 && (
                <View style={{ height: 75 }} />
              )}

              {!this.state.category2 && (
                <View
                  style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {this.state.listOfApps.map((item) => {
                    if (!this.appDetails[item]) return;
                    return (
                      <TouchableOpacity
                        key={this.appDetails[item].title}
                        // onPress={() => {
                        //   this.props.navigation.navigate(
                        //     this.appDetails[item].route,
                        //     {}
                        //   );
                        // }}
                        // onPress={() => {
                        //   this.props.navigation.navigate({
                        //     routeName: "servicesDetail",
                        //     // params: { category1: item.Text },
                        //     // key: item,
                        //   });
                        //   global.reportStats(
                        //     "pressed_Fashion_Category2",
                        //     item.Text
                        //   );
                        // }}
                        onPress={() => {
                          console.log(
                            "title params",
                            this.appDetails[item].title
                          );

                          this.props.navigation.push("servicesDetail", {
                            params: {
                              category1: this.appDetails[item].title,
                              gradientColor: this.appDetails[item]
                                .backgroundColor,
                            },
                            key: this.appDetails[item].title,
                          });
                          global.reportStats(
                            "pressed_services_Category2",
                            this.appDetails[item].title
                          );
                        }}
                        style={{
                          backgroundColor: this.appDetails[item]
                            .backgroundColor,
                          height: 150,
                          width: "43%",
                          borderRadius: 6,
                          alignItems: "center",
                          justifyContent: "center",
                          marginVertical: 15,
                          marginHorizontal: 11,
                          // margingRight: 10
                        }}
                      >
                        {this.appDetails[item].icon === null ? (
                          <Image
                            style={{ width: 120, height: 120 }}
                            source={innovation}
                            resizeMode="contain"
                          />
                        ) : (
                          <View>
                            <SvgCss xml={this.appDetails[item].icon} />
                            <Text
                              style={{
                                color: "#FFF",
                                fontSize: 16,

                                fontFamily: "ProximaNovaA-Bold",
                                alignSelf: "center",
                                top: 20,
                              }}
                            >
                              {this.appDetails[item].title}
                            </Text>
                          </View>
                        )}
                      </TouchableOpacity>
                    );
                  })}

                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.push("home_Grooming");
                    }}
                    style={{
                      backgroundColor: "#935FF0",
                      height: 150,
                      width: "43%",
                      borderRadius: 6,
                      alignItems: "center",
                      justifyContent: "center",
                      marginVertical: 15,
                      marginHorizontal: 11,
                    }}
                  >
                    <View>
                      <SvgCss xml={global.icons.QuickJobs.image} />
                      <Text
                        style={{
                          color: "#FFF",
                          fontSize: 16,

                          fontFamily: "ProximaNovaA-Bold",
                          alignSelf: "center",
                          top: 20,
                        }}
                      >
                        Home & Grooming
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              )}
            </View>
          </SafeAreaView>
        </ScrollView>
      );
    } else {
      return (
        <View style={styles.container}>
          <ActivityIndicator />
          <StatusBar barStyle="default" />
        </View>
      );
    }
  }
}

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  Modal,
  Platform,
  Dimensions,
  ActivityIndicator
} from "react-native";
import * as Permissions from 'expo-permissions';
import * as Location from 'expo-location';
import firebase from "firebase";
import "firebase/firestore";
import Moment from "react-moment";

const gStyles = global.gStyles //    "../../styles/gStyles";
import CachedImage from "../../shared/CachedImage"

export default class FeedFeed extends Component {
  state = {
    titleHeight: 50,
    pressedLike: false,
    pressedDislike: false,
    pressedJoin: false,
    showMenuModal: false,
    watchingYoutube: false,
  }

  renderTop = (x) => {
    let community = null
    if (global.communityInfo) {
      community = global.communityInfo[x.communityID]
    }
    else {
      console.log('community found is ', community)
    }

    if (this.props.view == "LIST")
      return (<View style={{
        height: 35, justifyContent: "space-between", alignItems: "flex-start",
        flexDirection: "row"
      }}>
        <View style={{ flex: 1, flexDirection: "row", marginTop: -1 }}>
          <View style={{ width: 33, alignItems: "flex-start", justifyContent: "center" }}>
            {x.ownerPictureURL ? <Image
              style={{
                width: 25, height: 25, borderRadius: 12.5,
              }}
              source={{
                uri: x.ownerPictureURL
              }}
            /> : <View style={{
              width: 25, height: 25, borderRadius: 12.5, backgroundColor: "#668"
            }} />}
          </View>
          <View style={{ flex: 1, }}>
            <Text style={{ color: "#aab", fontSize: 13 }}>{x.ownerName}</Text>
            <Text style={{ color: "#889", fontSize: 9 }}>{x.timeCreated && (
              <Moment fromNow element={Text}>
                {x.timeCreatedDate}
              </Moment>
            )}</Text>
          </View>
        </View>
        {community && <View
          style={{ flexDirection: "row", width: 160, justifyContent: "flex-end", alignItems: "center" }}><Text style={{ fontSize: 10, color: "green", fontWeight: "500", marginRight: 3 }}>
            {community.name}
          </Text>
        </View>}
        <View
          style={{
            marginRight: 6, marginLeft: 3, justifyContent: "flex-start", alignItems: "center",
            flexDirection: "row", minWidth: 45
          }}
        >
          <Image
            style={{ height: 16, width: 17, marginRight: 4, tintColor: "#889" }}
            source={require("../../images/icons/Chat.png")}
          />
          <Text style={{ fontSize: 10, color: "#889", fontWeight: "500" }}>{x.nOfComments || 0}</Text>
        </View>
        <TouchableOpacity onPress={() => { this.setState({ showMenuModal: true }) }}>
          <View style={{ width: 40, alignItems: "center", justifyContent: "center", paddingLeft: 15 }}>
            <Text style={{ fontWeight: "bold", fontSize: 24, color: "#ccd", marginTop: 4 }}>
              ⋮
            </Text>
          </View>
        </TouchableOpacity>
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.showMenuModal}
          onRequestClose={() => {
            this.setState({ showMenuModal: false });
          }}
        >
          <TouchableOpacity
            onPress={() =>
              this.setState({ showMenuModal: false })
            }
            style={{ flex: 1 }}
          />
          <View
            style={{
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <View
              style={{
                alignItems: "center", justifyContent: "center", backgroundColor: "#ccd", borderTopEndRadius: 15, borderTopLeftRadius: 15
              }}
            >
              <TouchableOpacity onPress={async () => {
                this.setState({ showMenuModal: false })
                await global.timeout(50)
                this.props.navigation.navigate("meReport", {
                  profileID: x.ownerProfileID,
                  profileName: x.ownerName,
                  profileBusinessName: x.ownerNameBusiness,
                  profilePicture: x.ownerPictureURL || "",
                  chatGroup: x
                });
              }}>
                <View style={{
                  alignItems: "center", justifyContent: "center", height: 100,
                  flexDirection: "row", padding: 3,
                  borderRadius: 7.5, width: "100%", backgroundColor: "#ccd",
                  marginVertical: 5, borderBottomWidth: 1, borderTopColor: "#aab"
                }}>
                  <View style={{ alignItems: "flex-start", justifyContent: "center", flex: 1, paddingLeft: 10 }}>
                    <Text style={{ color: "#223", fontSize: 18, fontWeight: "bold" }}>Report Discussion</Text>
                    {/* <Text style={{ color: "#778", fontSize: 14 }}>{ob.description}</Text> */}
                  </View>
                  <View style={{ alignItems: "center", justifyContent: "center", width: 50 }}>
                    <Text style={{ color: "#333", fontSize: 20, fontWeight: "bold", color: "#99b" }}></Text>
                  </View>
                </View>
              </TouchableOpacity>
              <View style={{ marginTop: 15, marginBottom: 5 }}>
              </View>
              <View style={{ paddingTop: 125 }} />
            </View>
          </View>
        </Modal>
      </View>)

    if (this.props.view == "DETAIL")
      return (<View style={{
        height: 15, justifyContent: "space-between", alignItems: "flex-start",
        flexDirection: "row", marginTop: 5
      }}>
        <View style={{ flex: 1, flexDirection: "row" }}>
          <View style={{ width: 33 }} />
          <View style={{ width: 20, alignItems: "flex-start", justifyContent: "center" }}>
            {x.ownerPictureURL ? <Image
              style={{
                width: 18, height: 18, borderRadius: 9,
              }}
              source={{
                uri: x.ownerPictureURL
              }}
            /> : <View style={{ width: 18, height: 18, borderRadius: 9, backgroundColor: "#668" }} />}
          </View>
          <Text style={{ flex: 1, color: "#aab", fontSize: 10 }}>{x.ownerName + "    "}</Text>
          <Text style={{ flex: 1, color: "#889", fontSize: 10 }}>{x.timeCreated && (
            <Moment fromNow element={Text}>
              {x.timeCreatedDate}
            </Moment>)}
          </Text>
        </View>
        {/* {x.xDist >= 0 && (<View
          style={{ flexDirection: "row", width: 60, justifyContent: "flex-end", alignItems: "center" }}
        >
          <Image
            style={{ width: 15, height: 16.5, marginRight: 2, tintColor: "#889" }}
            source={require("../../images/icons/Location.png")}
          />
          <Text style={{ fontSize: 10, color: "#aab", fontWeight: "500" }}>
            {(Math.round(x.xDist * 10)) / 10 + " km"}
          </Text>
        </View>)} */}
        <TouchableOpacity onPress={() => { return }}>
          <View style={{ width: 40, alignItems: "center", justifyContent: "center", paddingLeft: 15 }}>
            <Text style={{ fontWeight: "bold", fontSize: 24, color: "#ccd", marginTop: 4 }}>

            </Text>
          </View>
        </TouchableOpacity>
      </View>)
  }

  renderMain = (x) => {
    const imWidth = (Dimensions.get("window").width - 40)
    const imWidthNoImg = (Dimensions.get("window").width - 40) * .4
    const imFontSize = x.groupTitle.length > 60 ? 12 : x.groupTitle.length > 40 ? 14 : x.groupTitle.length > 20 ? 16 : 22
    const nImFontSize = x.groupTitle.length > 30 ? 16 : 22
    const mainHeight = imWidth / 8 * 4

    return (<View style={{
      justifyContent: "space-between", alignItems: "center", flexDirection: "row",
    }}>
      {!x.groupPictureURL ? <View style={{}}>
        <Text numberOfLines={3}
          onLayout={(e) => { this.setState({ titleHeight: Math.round(e.nativeEvent.layout.height) }) }}
          style={{ fontWeight: "bold", fontSize: nImFontSize, color: "#ccd" }}>{x.groupTitle}</Text>
        <Text numberOfLines={(Math.round(mainHeight - this.state.titleHeight - 6) / 12)}
          style={{ fontSize: 12, color: "#ccd" }}>{x.groupDetails}</Text>
      </View> :
        <View style={{ flex: 1 }}>
          <CachedImage
            style={{ width: imWidth, height: 140, marginRight: 10, backgroundColor: "#224", borderRadius: 7 }}
            source={{ uri: x.groupPictureURL }}
            key={x.groupPictureURL}
          />
          <View style={{ width: '100%' }}>
            <Text numberOfLines={3}
              onLayout={(e) => { this.setState({ titleHeight: Math.round(e.nativeEvent.layout.height) }) }}
              style={{ fontWeight: "bold", fontSize: imFontSize, color: "#ccd", marginTop: 5 }}>{x.groupTitle}</Text>
            <Text numberOfLines={(Math.round(mainHeight - this.state.titleHeight - 5) / 10)}
              style={{ fontSize: 10, color: "#ccd", }}>{x.groupDetails}</Text>
          </View>
        </View>}
    </View>)
  }

  renderMainAsPoll = (x) => {
    return (<View style={{}}>
      <TouchableOpacity
        style={{ alignItems: "center", }}
        activeOpacity={1}
        disabled={!x.groupYoutubeID}
        onPress={() => {
          this.setState({ watchingYoutube: true })
        }}>
        {x.groupPictureURL && <Image
          style={{
            width: Dimensions.get("window").width * 0.9, height: 140, paddingRight: 8, borderRadius: 8.5,
          }}
          source={{ uri: x.groupPictureURL }}
          key={x.groupPictureURL}
        />}
        {!!x.groupYoutubeID && <View
          style={{
            borderRadius: 14, paddingLeft: 6, height: 55, justifyContent: "center", alignItems: "center", width: 55, bottom: 95, marginBottom: -55
          }}
        >
          <Text style={{ color: "#333", fontSize: 42 }}>{"▶️"}</Text>
        </View>}
      </TouchableOpacity>

      <View style={{ margin: 10, marginTop: 2, padding: 10 }}>
        <Text
          style={{ color: "#ccd", fontWeight: "bold", fontFamily: "ProximaNovaA-Bold", fontSize: 20, marginBottom: 5 }}
        >
          🗳️  {x.groupTitle}
        </Text>

        <View>
          {x.pollAnswers && x.pollAnswers.map((a, i) => {
            if (!x.pollHasEnded && !x.xHaveVoted && (i < 3 || (x.pollAnswers.length == 4))) return <Text key={a + i + "txt"} style={{ color: "#ccd", flex: 1, marginLeft: 6, fontFamily: "ProximaNovaA-Regular", }} numberOfLines={1}>➤  {a}</Text>

            const count = x.pollResultsCondensed[i]
            const max = Math.max(...(x.pollResultsCondensed || [1])) || 1
            if (i < 3 || (x.pollAnswers.length == 4)) return (
              <View key={a + i}>
                <View style={{ height: 15, marginTop: 3, width: "100%", flexDirection: "row" }}>
                  <View style={{ flex: count / max, height: 15, backgroundColor: "rgba(60,0,255,1)", borderRadius: 5 }} />
                  <View style={{ flex: (max - count) / max, height: 15, backgroundColor: "rgba(60,0,255,0.2)" }} />
                </View>
                <View style={{ marginTop: -15, height: 15, width: "100%", flexDirection: "row", alignItems: "center" }}>
                  <Text style={{ color: "#ccd", flex: 1, marginLeft: 6 }} numberOfLines={1}>{a}</Text>
                  <Text style={{ color: "#ccd", fontWeight: "bold", width: 25 }}>{count}</Text>
                </View>
              </View>
            )
          })}
          {x.pollAnswers && x.pollAnswers.length > 4 && <View>
            <Text style={{ color: "#ccd", fontWeight: "bold", flex: 1, marginLeft: 6, marginTop: 5 }}>+ {x.pollAnswers.length - 3} more</Text>
          </View>}

          {(!x.pollHasEnded && !x.xHaveVoted) && <View style={[global.gStyles.buttonXLW, { paddingVertical: 2.5, paddingHorizontal: 22, width: (!x.pollHasEnded && !x.xHaveVoted) ? 125 : 110, flexDirection: "row", minWidth: 15, alignItems: "center", marginTop: 10, height: 25 }]} >
            <Text style={{ fontSize: 14, fontWeight: "normal", color: "#ccd", fontFamily: "ProximaNovaA-Regular", }}>{(!x.pollHasEnded && !x.xHaveVoted) ? "Vote Now!" : "See Results"}</Text>
          </View>}
        </View>

        {!!this.state.watchingYoutube && global.renderYoutubeModal(x.groupYoutubeID,
          () => { this.setState({ watchingYoutube: false }) })}

      </View>
    </View >)
  }


  renderBottom = (x) => {
    return (<View style={{
      justifyContent: "space-between", alignItems: "center",
      flexDirection: "row"
    }}>
      <View
        style={{
          marginRight: 6, justifyContent: "flex-start", alignItems: "center",
          flexDirection: "row", minWidth: 45
        }}
      >
        {/* <Image
          style={{ height: 16, width: 20, marginRight: 4, tintColor: "#889" }}
          source={require("../../images/icons/People.png")}
        />
        <Text style={{ fontSize: 10, color: "#889", fontWeight: "500" }}>{x.nOfJoiners || 0}</Text> */}
      </View>
      <View
        style={{
          marginRight: 6, justifyContent: "flex-start", alignItems: "center",
          flexDirection: "row", minWidth: 45
        }}
      >
        <Image
          style={{ height: 16, width: 17, marginRight: 4, tintColor: "#889" }}
          source={require("../../images/icons/Chat.png")}
        />
        <Text style={{ fontSize: 10, color: "#889", fontWeight: "500" }}>{x.nOfComments || 0}</Text>
      </View>
      <View style={{ flex: 1 }} />
      <View
        style={{
          marginRight: 6, justifyContent: "flex-start", alignItems: "center",
          flexDirection: "row", minWidth: 45
        }}
      >
        <Image
          style={{ height: 16, width: 17, marginRight: 4, tintColor: "#889" }}
          source={require("../../images/icons/Heart.png")}
        />
        <Text style={{ fontSize: 10, color: "#889", fontWeight: "500" }}>{x.nOfTotalLikes || 0}</Text>
      </View>
      <View style={{ flex: 1 }} />
      <View
        style={{
          marginRight: 6, justifyContent: "flex-start", alignItems: "center",
          flexDirection: "row"
        }}
      >
        {/* <TouchableOpacity style={global.gStyles.buttonX} onPress={() => { return; }}>
          <Text style={[global.gStyles.buttontX, { paddingHorizontal: 15 }]}>+ JOIN</Text>
        </TouchableOpacity> */}
      </View>
    </View>)
  }

  render() {
    const x = this.props.chatGroup
    if (x.aType == "event") return <View />
    return (<View style={{ paddingHorizontal: 10, paddingVertical: 8, borderBottomWidth: 0.5, borderBottomColor: "#889", minHeight: 130 }}>
      <View style={{ height: 5 }} />
      {this.renderTop(x)}
      <View style={{ height: 5 }} />
      {x.aType == "poll" ? this.renderMainAsPoll(x) : this.renderMain(x)}
      <View style={{ height: 5 }} />
      {/* {this.renderBottom(x)} */}
    </View>)
  }
}


import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  Platform,
  ActivityIndicator
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";



class Contact extends Component {
  state = {
    contactProperties: {
      opponentProfileID: null,
      opponentName: "********",
      opponentNameBusiness: "************",
      opponentProfilePictureURL: null
    },
    date: "--"
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  componentDidMount() {
    this.unsubscribeFirestore = firebase
      .firestore()
      .collection("Profiles")
      .doc(this.props.contactID)
      .onSnapshot(this.updateContact);

    this.setState({
      date: this.props.date
    })
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  updateContact = async contactSnapshot => {
    console.log("Update of list of contacts triggered. (Contact in ReferrerDashboard)");
    try {
      let contact = contactSnapshot.data();

      let opponentProfileID = contactSnapshot.id;
      let opponentName = contact.profileName;
      let opponentProfilePictureURL = contact.profilePictureURL;
      let opponentNameBusiness = contact.profileNameBusiness;
      if (!contact.profileNameBusiness) {
        opponentNameBusiness = "";
      }

      let contactProperties = {
        opponentProfileID,
        opponentName,
        opponentNameBusiness,
        opponentProfilePictureURL
      };

      //console.log("contactProperties", contactProperties);
      this.setState({
        contactProperties
      });
    } catch (err) {
      console.log("Slow internet. Could not fetch contacts (meContact)", err);
      alert("Could not fetch contacts. Check your internet connection");
    }
  };



  render() {
    return (
      <TouchableOpacity
        onPress={() => {
          if (!this.state.contactProperties.opponentProfileID) {
            return
          }
          let handoverID = this.state.contactProperties.opponentProfileID;
          this.props.navigation.navigate("meProfile", {
            profileID: handoverID
          });
        }}
      >
        <View
          style={[
            {
              flexDirection: "row",
              flex: 1,
              height: 65,
              backgroundColor: "black",
              alignItems: "center",
              paddingLeft: 11,
              paddingRight: 11,
              shadowOpacity: 0.5,
              shadowOffset: { width: 0, height: 3 },
              borderBottomColor: "#333",
              borderBottomWidth: 1
            }
          ]}
        >
          {!!this.state.contactProperties.opponentProfilePictureURL ? (
            <Image
              style={{ width: 42, height: 42, borderRadius: 4 }}
              source={{
                uri: this.state.contactProperties.opponentProfilePictureURL
              }}
            />
          ) : (
              <View style={{ width: 42, height: 42, borderRadius: 4, backgroundColor: "#000" }} />
            )}
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View
              style={{
                flexDirection: "column",
                flex: 1,
                paddingHorizontal: 8
              }}
            >
              <Text style={{ fontSize: 14, color: "white" }}>
                {this.state.contactProperties.opponentName}
              </Text>
              {this.state.contactProperties.opponentNameBusiness != "" && (
                <Text style={{ fontSize: 11, color: "white" }}>
                  {this.state.contactProperties.opponentNameBusiness}
                </Text>
              )}
              <Text style={{ fontSize: 11, color: "#008866" }}>
                Added  {this.props.date}
              </Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}



export default class ReferrerDashboardScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Referred Customers",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };
  state = {
    signups: [],
    loading: true
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {

    const signups = []
    const rData = this.props.navigation.getParam("rData")
    const keys = Object.keys(rData)
    keys.map(key => {
      if (key != "xID") {
        const a = {}
        a.profileID = key
        a.date = rData[key]
        signups.push(a)
      }
    })
    signups.sort(function (a, b) {
      return b.date - a.date;
    });
    this.setState({ signups })
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
    console.log("Component Contacts will unmount");
  }


  render() {
    console.log("data>>", this.state.date)
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#222" }}>
        <View style={{ backgroundColor: "#222", flex: 1 }}>
          <View
            style={{
              height: 40
            }}
          />
          <Text style={{ color: "gray", fontSize: 14, textAlign: "center", marginBottom: 10 }}>referred by {this.props.navigation.getParam("name")}</Text>
          <ScrollView>
            <View>
              {this.state.signups.length > 0 ? (
                this.state.signups.map(s => {
                  return (
                    <Contact
                      key={s.profileID}
                      contactID={s.profileID}
                      date={s.date}
                      navigation={this.props.navigation}
                    />
                  );
                })
              ) : (
                  <View
                    style={{
                      height: 300,
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    {!!this.state.loading ? (
                      <ActivityIndicator
                        size="large"
                        color="#008866"
                        style={{
                          opacity: 1
                        }}
                        animating={true}
                      />
                    ) : (
                        <Text style={{ color: "#999" }}>No referrers yet</Text>
                      )}
                  </View>
                )}
            </View>
          </ScrollView>
        </View>
      </SafeAreaView>
    );
  }
}

import React, { Component } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  ImageBackground,
  StyleSheet,
  ScrollView,
  Platform,
  Dimensions,
} from "react-native";

const SCREEN_WIDTH = Dimensions.get("window").width;

// const SLIDE_DATA = [
//   { text: "Welcome >>>" },
//   { text: "This is Wakanda Market" },
//   { text: "Wakanda Market is great!" }
// ];

class Slider extends Component {
  renderSlides() {
    return this.props.data.map(slide => {
      return (
        <View key={slide.text} style={styles.container}>
          <Text style={styles.text}>{slide.text}</Text>
          <Text> </Text>
        </View>
      );
    });
  }
  render() {
    return (
      <View>
        <ScrollView horizontal pagingEnabled style={{ flex: 1 }}>
          {this.renderSlides()}
        </ScrollView>
      </View>
    );
  }
}

class WelcomeScreen extends Component {
  static navigationOptions = {
    //tabBarVisible: false,
  };

  render() {
    return (
      <ImageBackground
        source={require("../../../custom/Artisan/images/logos/TAN-background.png")}
        style={{
          flex: 1.2,
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor: "#000"
        }}
      >
        <View>
          <View
            style={{
              borderWidth: 0,
              ...Platform.select({
                ios: {
                  height: 0
                },
                android: {
                  height: 150
                }
              })
            }}
          />
          <View
            style={{
              flex: 5,
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {/* <Text style={{ color: "#fff", fontSize: 24, marginTop: 15 }}>
              Welcome!
            </Text> */}
            <Image
              style={{ width: 300, height: 300, }}
              source={require("../../../custom/Artisan/images/logos/TAN-logo2.png")}
            />
            {/* <Text style={{color: "#fff",fontSize: 16,margin: 15,textAlign:"right" }}>
              by the Vocational Artisan Program
            </Text> */}
            <View style={{ flexDirection: "row", alignSelf: "flex-start" }}>
              <Text style={{ color: "#fff", fontSize: 18, marginVertical: 4, }} >🇳🇬 </Text>
              <Text style={{ color: "#ffd307", fontSize: 14, margin: 4, fontStyle: "italic" }}>
                Find artisans near you within seconds
            </Text>
            </View>
            <View style={{ flexDirection: "row", alignSelf: "flex-start" }}>
              <Text style={{ color: "#fff", fontSize: 18, marginVertical: 4, }} >🇳🇬 </Text>
              <Text style={{ color: "#ffd307", fontSize: 14, margin: 4, fontStyle: "italic" }}>
                Send requests to qualified artisans around you
            </Text>
            </View>
            <View style={{ flexDirection: "row", alignSelf: "flex-start" }}>
              <Text style={{ color: "#fff", fontSize: 18, marginVertical: 4, }} >🇳🇬 </Text>
              <Text style={{ color: "#ffd307", fontSize: 14, margin: 4, fontStyle: "italic" }}>
                Rate every job and use our escrow service
            </Text>
            </View>
          </View>
          <View style={{ flex: 1, alignItems: "center" }}>
            {/* <Text style={{ color: "#fff", fontSize: 14, marginTop: 8, marginBottom: 5, fontStyle: "italic" }} >
              by the Vocational Artisan Program
            </Text> */}

            {/* <Text
              style={{ color: "#fff", fontSize: 20, marginTop: 15, fontStyle: "italic" }}
            >
            </Text> */}
            <Text style={{ color: "#fff", fontSize: 14, margin: 5 }} />
            {/* <Text style={{ color: "#fff", fontSize: 14, margin: 5 }} >PLACE SLIDER HERE!</Text> */}
          </View>
          <View style={{ flex: 1.2, alignItems: "center" }}>
            <TouchableOpacity
              onPress={() => this.props.navigation.navigate("signin")}
            >
              <Text style={{ color: "#fff", fontSize: 20, margin: 5, marginBottom: 5 }}>
                Continue...
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={{ flexDirection: "row", position: "absolute", bottom: 25, alignItems: "flex-end", justifyContent: "center", marginTop: 15 }}>
          <Text style={{ fontSize: 14, color: "#fff", fontWeight: "bold" }} >powered by</Text>
          <Image
            style={{ width: 30, height: 24, marginLeft: 5, }}
            source={require("../../../images/logos/home_logo.png")}
          />
          <Text style={{ fontSize: 14, color: "#fff", fontWeight: "bold" }}>WAKANDA</Text>
        </View>
      </ImageBackground>
    );
  }
}

const styles = {
  container1: {
    flex: 4,
    alignItems: "center",
    justifyContent: "center",
    width: SCREEN_WIDTH,
    backgroundColor: "#000"
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: SCREEN_WIDTH,
    backgroundColor: "#000"
  },
  text: {
    fontSize: 30
  }
};

export default WelcomeScreen;

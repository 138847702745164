
import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  SafeAreaView,
  PanResponder,
  ActivityIndicator,
  Platform,
  Alert,
  Keyboard,
  Modal,
  Dimensions,
  Animated
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import SlidingView from "./SlidingView";
import { NavigationEvents } from "react-navigation";
import { Video } from 'expo-av';
import * as Font from "expo-font";
import Constants from 'expo-constants';
import DraggableView from '../shared/DraggableView2';
import RecommendedOffer from "../Home/Apps/shared/RecommendedOffer";
import Post from "../Home/Post/Post";
import ChatGroup from "../Messages/Groups/ChatGroupDetailScreen"

const EHEIGHT = Dimensions.get("window").height - (Dimensions.get("window").height >= 800 ? 78 : 48)
const EWIDTH = Dimensions.get("window").width;


export default class ExploreMainScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Explore",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 0
    }
  };

  state = {
    loading: true,
    groups: [],
    activeGroup: 0,
    shouldPlay: false,
    showPostDetail: false,
    showComments: false,
    keyboardHeight: 20
  }

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    try {
      this.keyboardDidShowListener = Keyboard.addListener(
        "keyboardDidShow",
        this._keyboardDidShow
      );
      this.keyboardDidHideListener = Keyboard.addListener(
        "keyboardDidHide",
        this._keyboardDidHide
      );
      if (!global.myUID || !global.myProfileID || !global.myLocation) await new Promise(resolve => { setTimeout(resolve, 200); });
      if (!global.myUID || !global.myProfileID || !global.myLocation) await new Promise(resolve => { setTimeout(resolve, 400); });
      if (!global.myUID || !global.myProfileID || !global.myLocation) await new Promise(resolve => { setTimeout(resolve, 400); });
      if (!global.myUID || !global.myProfileID || !global.myLocation) await new Promise(resolve => { setTimeout(resolve, 400); });
      if (!global.myUID || !global.myProfileID || !global.myLocation) await new Promise(resolve => { setTimeout(resolve, 400); });
      if (!global.myUID || !global.myProfileID || !global.myLocation) await new Promise(resolve => { setTimeout(resolve, 400); });
      if (!global.myUID || !global.myProfileID || !global.myLocation) await new Promise(resolve => { setTimeout(resolve, 400); });
      if (!global.myUID || !global.myProfileID || !global.myLocation) await new Promise(resolve => { setTimeout(resolve, 400); });
      if (!global.myUID || !global.myProfileID || !global.myLocation) await new Promise(resolve => { setTimeout(resolve, 500); });
      if (!global.myUID || !global.myProfileID || !global.myLocation) await new Promise(resolve => { setTimeout(resolve, 500); });
      if (!global.myUID || !global.myProfileID || !global.myLocation) await new Promise(resolve => { setTimeout(resolve, 600); });
      if (!global.myUID || !global.myProfileID || !global.myLocation) await new Promise(resolve => { setTimeout(resolve, 1000); });
      if (!global.myUID || !global.myProfileID || !global.myLocation) await new Promise(resolve => { setTimeout(resolve, 2000); });
      if (!global.myUID || !global.myProfileID || !global.myLocation) await new Promise(resolve => { setTimeout(resolve, 4000); });
      if (!global.myUID || !global.myProfileID || !global.myLocation) await new Promise(resolve => { setTimeout(resolve, 5000); });
      if (!global.myUID || !global.myProfileID || !global.myLocation) await new Promise(resolve => { setTimeout(resolve, 8000); });
      if (!global.myUID || !global.myProfileID || !global.myLocation) await new Promise(resolve => { setTimeout(resolve, 10000); });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID || !global.myProfileID || !global.myLocation) await new Promise(resolve => { setTimeout(resolve, 10000); });
      if (!global.myUID || !global.myProfileID || !global.myLocation) await new Promise(resolve => { setTimeout(resolve, 100000); });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID)
        return;

      this.loadMarket()

    } catch (err) {
      if (global.reportError) {
        global.reportError(err, "catches", "ExploreMain");
      }
    }
  }

  loadMarket = async () => {
    try {
      const r = await fetch(global.cloudFunctionURL + "findMarket", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isWeb:true,
          type: "All",
          coords: global.myLocation?.coords,
          myUID: global.myUID,
          idToken: global.idToken
        }),
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        const groups = r2.groups
        this.setState({ loading: false, groups, activeGroup: 0 })
        // console.log(r2.groups)
      }
    } catch (e) {
      global.warn(e, "loadMarket")
    }
  }

  componentWillUnmount() {
    console.log("Component exploreMain will unmount");
    this.unsubscribeFirestore();
    if (this.keyboardDidShowListener) this.keyboardDidShowListener.remove();
    if (this.keyboardDidHideListener) this.keyboardDidHideListener.remove();
  }

  _keyboardDidShow = async e => {
    if (Platform.OS === "ios") {
      if (Dimensions.get("window").height > 800) this.setState({ keyboardHeight: e.endCoordinates.height - 15 });
      else this.setState({ keyboardHeight: e.endCoordinates.height });

    } else {
      this.setState({ keyboardHeight: 0 }); // compromize between 45 for most devices, 10 for others
    }
  };

  _keyboardDidHide = () => {
    this.setState({ keyboardHeight: 20 });
  };

  like = async (isLike, chatGroupID) => {
    try {
      let groups = global.duplicate(this.state.groups)
      groups = groups.map(group => {
        if (group?.xID == chatGroupID) {
          group.nOfTotalLikes += (isLike ? 1 : -1)
          group.xAmJoiner = (isLike ? true : false)
        }
        return group
      })
      this.setState({ groups })
      fetch(global.cloudFunctionURL + "joinGroup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isWeb:true,
          chatGroupID,
          action: isLike ? "likeGroup" : "dislikeGroup",
          myUID: global.myUID,
          idToken: global.idToken
        }),
      });
    } catch (e) {
      global.warn(e, "exLike")
    }
  }

  markSeen = async (groupNumber) => {
    const chatGroupID = this.state.groups?.[groupNumber]?.xID
    if (!chatGroupID) return global.warn("Check...markSeenNull")
    try {
      await global.fetchIdToken()
      fetch(global.cloudFunctionURL + "joinGroup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isWeb:true,
          chatGroupID,
          action: "markSeen",
          myUID: global.myUID,
          idToken: global.idToken
        }),
      });
    } catch (e) { global.warn(e, "exMarkSeen") }
  }


  renderContent = (group) => {
    if (!group) {
      return (<View
        style={{ width: EWIDTH, height: EHEIGHT, alignItems: "center", justifyContent: "center" }}
      >
        <Text style={{ color: "green" }}>
          {this.state.groups.length > 0 ? "No more videos" : ":-(  No video at the moment"}
        </Text>
      </View>)
    }
    if (group == -1) {
      return (<View
        style={{ width: EWIDTH, height: EHEIGHT, alignItems: "center", justifyContent: "center" }}
      >
        <Text style={{ color: "green" }}>-</Text>
      </View>)
    }
    return (<View><Video
      source={{ uri: group.videoURL }}
      rate={1.0}
      volume={1.0}
      isMuted={true}
      resizeMode="cover"
      shouldPlay={this.state.shouldPlay}
      isLooping
      style={{ width: EWIDTH, height: EHEIGHT }}
    />
      <View style={{ position: "absolute", bottom: 0, right: 0, width: 70, alignItems: "center" }}>
        <TouchableOpacity activeOpacity={1} style={{ marginBottom: 15, alignItems: "center" }}
          onPress={() => { global.navigateProfile(this.props.navigation, group.ownerProfileID) }}
        >
          {!!group.ownerPictureURL ? (<View style={{ marginBottom: 15 }}>
            <View
              style={{
                width: 52, height: 52, borderRadius: 26,
                marginBottom: -51, backgroundColor: "white",
              }} />
            <Image
              style={{ width: 50, height: 50, borderRadius: 25, marginLeft: 1 }}
              source={{ uri: group.ownerPictureURL }}
            />
          </View>)
            : <View
              style={{
                borderColor: "#8AF", borderWidth: 1, width: 52, height: 52,
                borderRadius: 26, backgroundColor: "white", opacity: 0.7
              }} />}
        </TouchableOpacity>
        <TouchableOpacity activeOpacity={1} style={{ marginBottom: 15, alignItems: "center" }}
          onPress={() => {
            if (!group.xAmJoiner) this.like(true, group.xID)
            else this.like(false, group.xID)
          }} >
          <Image
            style={{ width: 45, height: 40, tintColor: "#fcc" }}
            source={require("../images/icons/Heart.png")}
          />
          <Image
            style={{ width: 43, height: 38, marginTop: -39, tintColor: !group.xAmJoiner ? "#f88" : "#F04" }}
            source={require("../images/icons/Heart.png")}
          />
          <Text style={{ color: "white", fontSize: 24, fontFamily: "ProximaNovaA-Bold", }}>
            {group.nOfTotalLikes || 0}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity activeOpacity={1} style={{ marginBottom: 15, alignItems: "center" }}
          onPress={() => { this.setState({ showComments: true }) }} >
          <Image
            style={{ width: 33, height: 33, tintColor: "#ccf" }}
            source={require("../images/tabNavigator/Messages.png")}
          />
          <Image
            style={{ width: 31, height: 31, tintColor: "#88f", marginTop: -32 }}
            source={require("../images/tabNavigator/Messages.png")}
          />
          <Text style={{ color: "white", fontSize: 24, fontFamily: "ProximaNovaA-Bold", }}>
            {group.nOfComments || 0}
          </Text>
        </TouchableOpacity>
      </View>
      {!!group.postID && <View style={{ position: "absolute", bottom: 25, left: 70, width: 200, alignItems: "center" }} key={group.postID}>
        <DraggableView onClick={() => {
          this.setState({ showPostDetail: true })
        }}>
          <View
            style={{
              width: 300, height: 300, borderRadius: 30, backgroundColor: "#455",
              opacity: 0.8
              // transform:[{ scale: 1 }]
            }}
          >
            <Post
              postID={group.postID}
              key={group.postID + this.state.showPostDetail}
              navigation={this.props.navigation}
              showDetail={this.state.showPostDetail}
              onHideDetail={() => { this.setState({ showPostDetail: false }); }}
              relevantStatus={"LIVE"}
              onIsNotRelevant={() => { global.warn("Check this!! onIsNotRelevant is called in ExMS.js"); }}
            />
            {/* <RecommendedOffer
            postID={group.postID}
            colors={["#F5F5F5", "#F5F5F5", "#6Ec456"]}
            navigation={this.props.navigation}
          /> */}
          </View>
        </DraggableView>
      </View>}
      <Modal
        animationType="slide"
        transparent={true}
        visible={this.state.showComments}
        style={{ flex: 1 }}
        onRequestClose={() => {
          //alert("Modal has been closed.");
        }}
      >
        <TouchableOpacity activeOpacity={1}
          onPress={() => { this.setState({ showComments: false }) }}
          style={{ flex: 1 }}
        >
          <View style={{
            flex: 1, paddingTop: 125,
          }}>
            <View style={{ alignSelf: "stretch", height: 2, backgroundColor: "#448" }} />
            {/* <ScrollView contentContainerStyle={{flex:1, alignSelf:"stretch"}}> */}
            <ChatGroup
              isVideoComments
              backgroundColor="#224"
              navigation={this.props.navigation}
              group={this.state.groups?.[this.state.activeGroup]}
            />
            {/* </ScrollView> */}
          </View>
          <View style={{ height: this.state.keyboardHeight }} />
        </TouchableOpacity>
      </Modal>
    </View>)
  }

  render() {

    if (this.state.loading) return (<View
      style={{ backgroundColor: "#000", width: EWIDTH, height: EHEIGHT, alignItems: "center", justifyContent: "center" }}
    >
      <Text style={{ color: "green" }}>Loading...</Text>
    </View>)
    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: "#131313"
        }}
      >
        <View style={{ marginTop: -Constants.statusBarHeight }} />
        <SlidingView
          isFirst={(this.state.activeGroup == 0)}
          renderContent={this.renderContent(this.state.groups[this.state.activeGroup])}
          renderContentLast={(this.state.activeGroup > 0)
            ? this.renderContent(this.state.groups[this.state.activeGroup - 1])
            : this.renderContent(-1)
          }
          renderContentNext={(this.state.groups.length > this.state.activeGroup)
            ? this.renderContent(this.state.groups[this.state.activeGroup + 1])
            : this.renderContent(null)
          }
          onClick={() => { this.setState({ shouldPlay: !this.state.shouldPlay }) }}
          onSlideNext={() => {
            this.markSeen(this.state.activeGroup)
            if (this.state.groups.length > this.state.activeGroup) this.setState({ activeGroup: this.state.activeGroup + 1 })
          }}
          onSlidePrevious={() => { if (this.state.activeGroup > 0) this.setState({ activeGroup: this.state.activeGroup - 1 }) }}
        >

        </SlidingView>
        <NavigationEvents
          onDidFocus={async payload => {
            this.setState({ shouldPlay: true })
          }}
          onWillBlur={async payload => {
            this.setState({ shouldPlay: false })
          }}
        />
      </SafeAreaView >
    );
  }
}



import React, { Component, PureComponent } from "react";
import {
  TouchableOpacity,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  View,
  Image,
  SafeAreaView,
  Dimensions,
  ActivityIndicator,
  ScrollView
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import MapResults from "../shared/Map/mapResults";
import distance from "../shared/distances";
import CachedImage from "../shared/CachedImage"


class Name extends Component {
  state = {
    name: "",
  };
  async componentDidMount() {
    try {
      const profileDoc = await firebase
        .firestore()
        .collection("Profiles")
        .doc(this.props.profileID)
        .get();

      const profile = profileDoc.data();
      this.setState({
        name: profile.profileName
      });
    } catch (err) {
      console.log(err)
    }
  }
  render() {
    return <Text>{this.state.name}</Text>;
  }
}

class NetworkVerification extends Component {
  state = {
    uri: null,
    name: "...",
    error: false
  }
  async componentDidMount() {
    try {
      let net = await global.getNetwork(this.props.verification.by)
      if (!net) { //this should not be necessary
        console.log("WARNING. Code not working as expected")
        const netDoc = await firebase
          .firestore()
          .collection("Networks")
          .doc(this.props.verification.by)
          .get();
        net = netDoc.data()
      }

      if (net.networkLogoURL) this.setState({ uri: net.networkLogoURL })
      if (net.networkName) this.setState({ name: net.networkName })
    } catch (err) {
      console.log(err)
      this.setState({ error: true })
    }
  }
  render() {
    const ver = this.props.verification
    if (this.state.error) return <View />
    return (
      <View style={{ borderRadius: 5, padding: 2, flexDirection: "row", alignItems: "center", backgroundColor: "rgba(22,66,44,0.5)", marginVertical: 3, marginLeft: 0, marginRight: 0 }}>
        {!!this.state.uri && <CachedImage
          style={{ width: 36, height: 26, marginRight: 5, borderColor: this.props.backgroundColor, resizeMode: "contain" }}
          source={{ uri: this.state.uri }}
        />}
        <Text numberOfLines={1} style={{ color: "#aba", fontSize: 12, flexWrap: "wrap", fontWeight: "bold", marginRight: 37 }}>Verified as {ver.type} by {this.state.name}</Text>
      </View>
    )
  }
}

class Result extends Component {
  state = {
    opponentProfileID: "",
    opponentName: "",
    opponentNameBusiness: "",
    opponentProfilePictureURL: "",
    opponentJobsDone: "",
    jointContacts: [],
    badges: [],
    workLocationLatitude: null,
    workLocationLongitude: null,
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 200); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 500); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 1000); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 2000); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 3000); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 4000); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 5000); });
    if (!global.myUID) await global.setGlobals();

    // this.unsubscribeFirestore = firebase
    //   .firestore()
    //   .collection("Profiles")
    //   .doc(this.props.profileID)
    //   .onSnapshot(async s => {
    //     //let connected = await NetInfo.isConnected;
    //     if (true) {
    //       this.updateResult(s);
    //     }
    //   });
    let profile = this.props.profileData;
    let opponentProfileID = profile.xID;
    let opponentName = profile.profileName;
    let opponentProfilePictureURL = profile.profilePictureURL;
    let opponentNameBusiness = profile.profileNameBusiness;
    let opponentJobsDone = profile.infoJobsDoneCount;
    let badges = profile.infoBadges;
    let verifiedSkills = profile.verifiedSkills || {};

    if (badges == null) {
      badges = [];
    }

    this.setState({
      opponentProfileID,
      opponentName,
      opponentNameBusiness,
      opponentProfilePictureURL,
      opponentJobsDone,
      badges,
      verifiedSkills,
      workLocationLatitude: profile.locationWork
        ? profile.locationWork._latitude
        : null,
      workLocationLongitude: profile.locationWork
        ? profile.locationWork._longitude
        : null
    });

    this.fetchJointItems();
  }

  fetchJointItems = async () => {
    try {
      if (!global.myProfileID) await global.timeout?.(1000)
      if (!global.myProfileID) await global.timeout?.(1000)
      if (!global.myProfileID) return
      console.log(
        "Fetching JointItems",
        global.myUID,
        global.myProfileID,
        this.props.profileData.xID
      );
      const r = await fetch(global.cloudFunctionURL + "getJointItems", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          myUID: global.myUID,
          idToken: global.idToken,
          myProfileID: global.myProfileID,
          targetProfileID: this.props.profileData.xID
        })
      });
      const r2 = await r.json();

      if (r2.msg === "SUCCESS") {
        //console.log("SUCCESS!!!!!", r2);
        if (r2.contactsFollowing != null) {
          this.setState({
            jointContacts: r2.contactsFollowing
          });
        }
      }
      //console.log("SUCCESS??", r2);
    } catch (error) {
      console.log(error);
    }
  };

  componentWillUnmount() {
    console.log("Component InquiryResults unmount");
    //this.unsubscribeFirestore();
    // NetInfo.isConnected.removeEventListener(
    //   "connectionChange",
    //   this.handleConnectivityChange
    // );
  }

  // handleConnectivityChange = isConnected => {
  //   this.setState({ isConnected });
  // };

  // updateResult = async profileSnapshot => {
  //   //console.log("Chat update triggered.");
  //   try {
  //     let profile = profileSnapshot.data();
  //     let opponentProfileID = profileSnapshot.id;
  //     let opponentName = profile.profileName;
  //     let opponentProfilePictureURL = profile.profilePictureURL;
  //     let opponentNameBusiness = profile.profileNameBusiness;
  //     let opponentJobsDone = profile.infoJobsDoneCount;
  //     let badges = profile.infoBadges;

  //     if (badges == null) {
  //       badges = [];
  //     }

  //     await this.setState({
  //       opponentProfileID,
  //       opponentName,
  //       opponentNameBusiness,
  //       opponentProfilePictureURL,
  //       opponentJobsDone,
  //       badges,
  //       workLocationLatitude: profile.locationWork ? profile.locationWork._lat : null,
  //       workLocationLongitude: profile.locationWork ? profile.locationWork._long : null

  //     });
  //     this.fetchJointItems();
  //   } catch (err) {
  //     console.log(err);
  //     alert("Slow internet. Could not fetch chat");
  //     console.log("Slow internet? Could not fetch chat".err);
  //   }

  //   //Image.prefetch(url);
  // };

  render() {
    const badges = {
      Quality: require("../images/badges/badgeQuality.png"),
      Affordability: require("../images/badges/badgeAffordability.png"),
      Professionality: require("../images/badges/badgeProfessionality.png"),
      Timeliness: require("../images/badges/badgeTimeliness.png")
    };
    const c2s = this.props.category2 ? this.props.category2.replace(/[^a-zA-Z]/g, "") : null
    return (
      <View
        style={{
          paddingHorizontal: 20,
          paddingTop: 8,
          paddingBottom: 8,
          flex: 1,
          borderBottomWidth: 1,
          borderBottomColor: "#55555a"
        }}
      >
        {/* <TouchableOpacity onPress={()=>{console.log(this.state.workLocationLatitude, this.props.profileData)}} style={{height:20, width:100, backgroundColor:"red"}}/> */}
        <View style={{ flexDirection: "row", minHeight: 50, alignItems: "center" }}>
          <View style={{ flexDirection: "row", flex: 7 }}>
            <View style={{ marginRight: 5 }}>
              {this.state.opponentProfilePictureURL != "" && (
                <Image
                  style={{
                    width: 42,
                    height: 42,
                    borderRadius: 21,
                    paddingRight: 30
                  }}
                  source={{
                    uri: this.state.opponentProfilePictureURL
                  }}
                />
              )}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: -5
                }}
              >
                {this.state.badges.map(badge => {
                  return (
                    <Image
                      key={"b_" + badge}
                      style={{
                        width: 10,
                        height: 10
                      }}
                      source={badges[badge]}
                    />
                  );
                })}
              </View>
            </View>
            <View>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                {/* <Image
                  key={"logo"}
                  style={{ width: 20, height: 20 }}
                  source={require("../images/logos/rccg-small.png")}
                /> */}
                <Text style={{ fontSize: 14, fontWeight: "bold", color: "#eef" }}>
                  {this.state.opponentName}
                </Text>
              </View>
              <Text style={{ fontSize: 13, color: "#99a" }}>
                {this.state.opponentNameBusiness}
              </Text>
            </View>
          </View>
          <View style={{ flex: 3, alignItems: "flex-end" }}>
            {this.state.workLocationLatitude != null && (
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row"
                  }}
                >
                  <Text style={{ fontSize: 10, color: "#bbc" }}>
                    {Math.round(
                      distance(
                        this.state.workLocationLatitude,
                        this.state.workLocationLongitude,
                        global.location.coords.latitude,
                        global.location.coords.longitude,
                        "K"
                      ) * 10
                    ) /
                      10 +
                      " km"}
                  </Text>
                  <Image
                    style={{
                      width: 15,
                      height: 16.5,
                      marginRight: 0,
                      tintColor: "#bbc"
                    }}
                    source={require("../images/icons/Location.png")}
                  />
                  {/* <Text style={{ textAlign: "right", color: "#888" }}>
                    {this.state.opponentJobsDone}
                    <Text style={{ fontSize: 10, color: "#888" }}>
                      JOBS DONE{" "}
                    </Text>
                  </Text> */}
                </View>
              </View>
            )}
          </View>
        </View>

        <View>
          <View style={{ marginTop: 0 }}>
            {this.state.verifiedSkills && c2s && this.state.verifiedSkills[c2s] && this.state.verifiedSkills[c2s].map((ver, i) => {
              return (<NetworkVerification key={ver.type + i} verification={ver} backgroundColor={"#264"} />)
            })}
            {this.state.jointContacts.length > 0 && (
              <View>
                <Text
                  style={{ fontSize: 13, color: "#667", fontStyle: "italic" }}
                >
                  Followed by <Text
                    style={{ fontSize: 13, color: "#667", fontStyle: "italic" }}
                  >
                    <Name profileID={this.state.jointContacts[0]} />
                  </Text>{this.state.jointContacts.length > 1 && (
                    <Text
                      style={{ fontSize: 13, color: "#667", fontStyle: "italic" }}
                    >, <Name profileID={this.state.jointContacts[1]} />{this.state.jointContacts.length > 2 && (
                      <Text
                        style={{ fontSize: 13, color: "#667", fontStyle: "italic" }}
                      >
                        <Text>, <Name profileID={this.state.jointContacts[2]} />{this.state.jointContacts.length > 3 && (
                          <Text
                            style={{ fontSize: 13, color: "#667", fontStyle: "italic" }}
                          >, and {this.state.jointContacts.length - 3} more</Text>
                        )}
                        </Text>
                      </Text>
                    )}
                    </Text>
                  )}</Text>
              </View>
            )}
          </View>
        </View>
      </View>
    );
  }
}

export default class HomeProviderResultsScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    const title = navigation.getParam("title", "Providers");
    return {
      tabBarVisible: false,
      headerTransparent: true,
      title,
      headerTitleStyle: {
        fontWeight: "bold",
        color: "#A8CF45",
      }
    };
  };
  state = {
    category1: null,
    category2: null,
    communities: null,
    results: [],
    address: "...",

    loading: true,
    error: null,
    view: "LIST",
    topView: "DEFAULT",
    jobQualification: null
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
  }

  async componentDidMount() {
    const type = this.props.navigation.getParam("type", null);
    if (type == "FIND_WORKER") { return this.loadFindWorker() }
    if (!type || type !== "FIND_WORKER") this.loadInitialData();
  }

  loadFindWorker = () => {
    this.props.navigation.setParams({ title: "Find a Worker" });
    const jobQualification = this.props.navigation.getParam("jobQualification", null);
    if (jobQualification) {
      this.setState({ jobQualification })
      this.loadInitialData();
    } else {
      this.setState({ mainView: "SELECT_WORKER", loading: false })
    }
  }

  loadInitialData = async () => {
    const category1 = this.props.navigation.getParam("category1", null);
    const category2 = this.props.navigation.getParam("category2", null);
    const communities = this.props.navigation.getParam("communities", null);
    this.setState({ category1, category2, communities, loading: true })

    try {
      await global.setLocationFast(10);
      if (!global.location) {
        global.location = { coords: { latitude: 6.6, longitude: 3.3 } }
        global.myLocation = { coords: { latitude: 6.6, longitude: 3.3 } }
        alert("Could not fetch location. Showing results near Lagos, Nigeria")
        this.setState({ address: "Lagos" })
      } else {
        this.fetchAddress()
      }
      this.findProviders({ amount: 30 })
    } catch (e) {
      global.warn(e, "ex1 homeProviderResults")
    }
  }

  fetchAddress = async () => {
    const addr = await fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + global.location.coords.latitude + "," + global.location.coords.longitude + "&key=" + "AIzaSyDxNz_ZMcLHWYEiVNW6kIH4PyI7e0NifKo");
    const addressJSON = await addr.json();
    let address = (addressJSON && addressJSON.results && addressJSON.results.length > 0) ? addressJSON.results[0].formatted_address : "Unknown Location";
    this.setState({ address })
  }

  findProviders = async data => {
    console.log("Starting to call findProviders");
    try {

      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "findProviders2", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          coords: global.location ? global.location.coords : { latitude: 6.6, longitude: 3.3 },
          // valNearYou: 0.5,
          // valAffordable: 0.5,
          // valQuality: 0.5,
          // valProfessional: 0.5,
          amount: data.amount || 30,
          jobQualification: this.state.jobQualification,
          category1: this.state.category1 || null,
          category2: this.state.category2 || null,
          communities: this.state.communities || null,
          myUID: global.myUID,
          idToken: global.idToken,
          ...((data.skills) && {
            budget: data.budget || 0,
            budgetCurrency: data.budgetCurrency || "NGN",
            skills: data.skills
          }),
        })
      });
      const r2 = await r.json();

      console.log("Received response. ", r2.msg);
      this.setState({ loading: false });
      if (r2.msg === "SUCCESS") {
        this.setState({ results: r2.results || [], loading: false });
        return;
      } else this.setState({ error: "An error occurred", loading: false });
    } catch (e2) {
      this.setState({ error: "An error occurred...", loading: false });
      console.log(e2);
    }
  };

  renderFilter = () => {
    const sWidth = Dimensions.get("window").width
    const resultTypeStr = this.state.jobQualification ? this.state.jobQualification + "s" : "providers"
    const string = (this.state.results ? this.state.results.length : "") + " " + (this.state.category2 ? this.state.category2 : (this.state.category1 || "")) + " " + resultTypeStr + " near " + (this.state.address || "")

    return (
      <View style={{ height: 40, alignItems: "center", justifyContent: "center", padding: 10 }}>
        <Text style={{ color: "#eef", fontWeight: "bold", width: sWidth - 60 }}>{string}</Text>
      </View>)
  }
  renderSelector = () => {
    return ((
      <View
        style={{
          flexDirection: "row", position: "absolute", right: 5, width: 120, height: 25,
          ...Platform.select({ ios: { top: Dimensions.get("window").height >= 800 ? 65 : 30 }, android: { top: 42 } })
        }}
      >
        <TouchableOpacity
          onPress={() => { this.setState({ view: "LIST" }); }}
          activeOpacity={0.6}
          style={{
            backgroundColor: this.state.view == "LIST" ? "#44444a" : "#222228", opacity: 0.8, width: 59,
            alignItems: "center", justifyContent: "center", borderBottomLeftRadius: 9, borderTopLeftRadius: 9,
            borderColor: "#667", borderWidth: 1
          }}
        >
          <Text style={{ color: "#eff" }}>List</Text>
        </TouchableOpacity>
        <View style={{ width: 2 }} />
        <TouchableOpacity
          onPress={() => { this.setState({ view: "MAP" }); }}
          activeOpacity={0.6}
          style={{
            backgroundColor: this.state.view == "MAP" ? "#44444a" : "#222228", opacity: 0.8,
            width: 59, alignItems: "center", justifyContent: "center",
            borderBottomRightRadius: 9, borderTopRightRadius: 9, borderColor: "#667", borderWidth: 1
          }}
        >
          <Text style={{ color: "#eff" }}>Map</Text>
        </TouchableOpacity>
      </View>
    ))
  }
  renderSelectWorker = () => {
    return (
      <View style={{ backgroundColor: "#000", flex: 1, height: "100%" }}>
        <View style={{ backgroundColor: "#000", borderBottomColor: "#55555a", borderBottomWidth: 1, ...Platform.select({ ios: { height: Dimensions.get("window").height >= 800 ? 88 : 65 }, android: { height: 85 } }) }} />
        <Text style={{ color: "#bbbbc5", fontSize: 15, fontWeight: "bold", margin: 20 }}>What are you looking for?</Text>
        <ScrollView>
          {global.jobQualifications.map(qualification => {
            return (<TouchableOpacity key={qualification}
              onPress={async () => { await this.setState({ jobQualification: qualification, mainView: "DEFAULT", loading: true }); this.loadInitialData() }}
              style={{ flexDirection: "row", paddingHorizontal: 10, backgroundColor: "#202020", height: 40, justifyContent: "space-between", alignItems: "center", marginBottom: 10, marginHorizontal: 20, paddingLeft: 12, borderRadius: 8 }}>
              <Text style={{ color: "#bbbbc5", fontSize: 16 }}>{qualification}</Text>
              <Text style={{ color: "#888892", fontSize: 24 }}>{">"}</Text>
            </TouchableOpacity>)
          })}
        </ScrollView>
      </View >)
  }

  render() {
    if (this.state.error) return (
      <View style={{ height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: "#131313" }}>
        <Text style={{ color: "#aab" }}>{this.state.error}</Text>
      </View >
    );
    if (this.state.loading) return (
      <View style={{ height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: "#131313" }}>
        <ActivityIndicator size="small" color="#aab" />
      </View>
    );
    if (this.state.mainView == "SELECT_WORKER") return this.renderSelectWorker()

    return (
      <View style={{ backgroundColor: "#131313", flex: 1, height: "100%" }}>
        <View
          style={{ backgroundColor: "#131313", borderBottomColor: "#555555", borderBottomWidth: 1, ...Platform.select({ ios: { height: Dimensions.get("window").height >= 800 ? 88 : 65 }, android: { height: 85 } }) }} />

        {this.state.view == "LIST" && <ScrollView style={{ height: 50 }}>
          {this.renderFilter()}
          {/* <View style={{ height: 20, borderBottomWidth: 0, borderBottomColor: "#446" }} /> */}
          {this.state.results.length == 0 && (
            <View style={{ marginTop: 180, alignItems: "center", justifyContent: "center" }}>
              <Text style={{ color: "#99a", fontStyle: "italic", padding: 20, fontSize: 22 }} >
                No Match Found.
              </Text>
            </View>
          )}
          {this.state.results.map(result => {
            return (
              <TouchableOpacity
                key={result.xID}
                onPress={() => {
                  if (global.myProfileID && result.xID == global.myProfileID)
                    this.props.navigation.navigate("homeProfile");
                  else this.props.navigation.navigate("homeProfile", { profileID: result.xID });
                }}
              >
                <Result
                  coords={global.location ? global.location.coords : { latitude: 6.6, longitude: 3.3 }}
                  profileID={result.xID}
                  profileData={result}
                  category2={this.state.category2}
                  navigation={this.props.navigation}
                />
              </TouchableOpacity>
            );
          })}
        </ScrollView>}
        {this.state.view == "MAP" && global.location && global.location.coords &&
          <MapResults
            addresstoMapScreen={global.location.coords}
            resultArray={this.state.results}
            mapDelta={0.1}
            navigation={this.props.navigation}
            category1={this.state.category1}
          />
        }
        {this.renderSelector()}
      </View>
    );
  }
}

const styles = ({
  text: {
    color: "#999",
    borderBottomColor: "#667",
    borderBottomWidth: 1,
    paddingHorizontal: 40,
    paddingVertical: 20
  },

  buttontext: {
    fontWeight: "bold",
    color: "#000"
  },
  button: {
    alignSelf: "stretch",
    alignItems: "center",
    padding: 20,
    backgroundColor: "#A8CF45",
    borderRadius: 5,
    marginHorizontal: 10,
    width: 260
  }
});


import React from "react";
import {
  Animated,
  View,
  PanResponder,
} from "react-native";

export default class DraggableView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pan: new Animated.ValueXY() // inits to zero
    };
    this.state.panResponder = PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onPanResponderMove:
        Animated.event([
          null,
          {
            dx: this.state.pan.x, // x,y are Animated.Value
            dy: this.state.pan.y,
          }
        ], { useNativeDriver: false }),
      onPanResponderRelease: (e, gesture) => {
        if (gesture.dx < 5 && gesture.dx > - 5
          && gesture.dy < + 5 && gesture.dy > - 5) {
          this.props.onClick?.();
        }
        if (this.props.springBack) {
          Animated.spring(
            this.state.pan, // Auto-multiplexed
            { toValue: { x: 0, y: 0 }, useNativeDriver: false }, // Back to zero
          ).start();
        } else {
          this.state.pan.setOffset({ x: this.currentPanValue.x, y: this.currentPanValue.y });
          this.state.pan.setValue({ x: 0, y: 0 });
        }
      }
    });
  }
  componentDidMount() {
    this.currentPanValue = { x: 0, y: 0 };
    this.lastFixedPosition = { x: 0, y: 0 };
    this.panListener = this.state.pan.addListener((value) => this.currentPanValue = value);
  }
  componentWillUnmount() {
    this.state.pan.removeListener(this.panListener);
  }
  render() {
    return (
      <Animated.View
        {...this.state.panResponder.panHandlers}
        style={this.state.pan.getLayout()}
      >
        {this.props.children}
      </Animated.View>
    );
  }
}

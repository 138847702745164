import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  TextInput,
  Image,
  Alert,
  TouchableOpacity,
  TouchableWithoutFeedback,
  SafeAreaView,
  Keyboard,
  Modal,
  SectionList,
  Platform,
  Dimensions,
  Animated,
  ActivityIndicator
} from "react-native";
import * as Location from 'expo-location';
import firebase from "firebase";

import "firebase/firestore";

import Contact from "../Me/Contacts/Contact"
import distance from "./distances";
const gStyles = global.gStyles //    "../styles/gStyles";
import { lessThan } from "react-native-reanimated";

class SearchBar extends Component {
  state = {
    searchTerm: "",
    activityIndicatorAnimating: false,
    noContactFound: false,
    resultID: 0,
    resultProfiles: [],
    countryCode: global.walletType == "ACN" ? "+233" : "+234",
    modalVisible: false,
  };

  callSearch = async () => {
    let submissionSearchTerm = "";
    const searchLetters = this.state.searchTerm.replace(/[^a-zA-Z]/g, "")

    if (searchLetters.length > 0) { //is a business name
      submissionSearchTerm = this.state.searchTerm

    } else { //is a number
      let enteredNumber = this.state.searchTerm.replace(/[^\d]/g, "");
      if (enteredNumber.startsWith("00")) {
        alert("Invalid phone number"); return;
      }
      if (enteredNumber.startsWith("0")) {
        enteredNumber = enteredNumber.slice(1);
      }
      submissionSearchTerm = this.state.countryCode + enteredNumber;
    }

    try {
      this.setState({ activityIndicatorAnimating: true });
      const r = await fetch(global.cloudFunctionURL + "findContact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          phoneNumber: submissionSearchTerm,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();

      console.log("Received contact. ", r2.msg);
      this.setState({ activityIndicatorAnimating: false });
      Keyboard.dismiss();
      if (r2.msg === "NO_CONTACT_FOUND") {
        this.setState({ noContactFound: true });
        return;
      }
      if (r2.msg === "CONTACT_FOUND") {
        this.setState({ resultID: r2.contactID, resultType: "singleContact", noContactFound: false });
        return;
      }
      if (r2.msg === "CONTACTS_FOUND") {
        this.setState({ resultProfiles: [], resultType: "multipleContacts", noContactFound: false });
        await global.timeout(50)
        this.setState({ resultProfiles: r2.contacts, resultType: "multipleContacts", noContactFound: false });
        return;
      }
      alert("Error: " + r2.msg);
    } catch (e2) {
      alert("An error occurred. Check internet connection.");
      console.log(e2);
      this.setState({ activityIndicatorAnimating: false });
    }
  };

  renderSearchBar = () => {
    const countryCodes = global.countryCodes

    const searchLetters = this.state.searchTerm.replace(/[^a-zA-Z]/g, "")
    const showLeft = !(searchLetters.length > 0) //Shows +234 immediately
    //showLeft = !(searchLetters.length > 0 || this.state.searchTerm.length == 0) //Shows +234 after first number typed

    let height = 30

    return (
      <View
        style={{
          flexDirection: "row", alignSelf: "center", height, width: "95%", marginVertical: 10, backgroundColor: "#223", borderRadius: 20, borderBottomWidth: 0,
        }}
      >
        {!!showLeft && <TouchableOpacity
          onPress={() => {
            this.setState({ modalVisible: true });
          }}
          style={{
            alignItems: "center", alignSelf: "flex-start", padding: 1, borderRightWidth: 1, borderRightColor: "gray", marginRight: 4.5, width: 65, height, justifyContent: "center", borderTopLeftRadius: 20, borderBottomLeftRadius: 20, borderColor: "gray"
          }}
        >
          <Text style={{ color: "#eee" }}>{this.state.countryCode}</Text>
        </TouchableOpacity>}
        <TextInput
          style={{
            alignSelf: "stretch", height, marginBottom: 20, color: "#eee", backgroundColor: "#223", borderRadius: 33, borderBottomWidth: 0, paddingLeft: 10, flex: 1
          }}
          returnKeyType="search"
          placeholder="Search Phone Number / Business Name"
          placeholderTextColor="#eee"
          //keyboardType="numeric"
          underlineColorAndroid="transparent"
          underlineColor={"transparent"}
          onChangeText={searchTerm => this.setState({ searchTerm })}
          onSubmitEditing={this.callSearch}
          ref={component => (this.myTextInput = component)}
          keyboardShouldPersistTaps={'always'}
          keyboardDismissMode={'on-drag'}
          keyboardAppearance={"dark"}
          autoCompleteType={'off'}
          autoCapitalize={'none'}
          autoCorrect={false}
        // clearButtonMode="always" //xx how set colour?
        />

        {this.state.searchTerm.length > 4 && (
          <TouchableOpacity
            style={{
              alignItems: "center", alignSelf: "flex-start", padding: 1, backgroundColor: "#A8CF45", marginLeft: 4.5, width: 47, height, justifyContent: "center", borderTopEndRadius: 20, borderBottomEndRadius: 20, borderColor: "gray", borderLeftWidth: 1
            }}
            onPress={this.callSearch}
          >
            {!this.state.activityIndicatorAnimating ? <Image
              style={{ width: 18, height: 18, marginRight: 1 }}
              source={require("../images/icons/Search.png")}
            /> :
              <ActivityIndicator size="small" color="#131313" animating={true} style={{ margin: -1 }} />}
          </TouchableOpacity>
        )}
        <Modal
          animationType="slide"
          transparent={false}
          visible={this.state.modalVisible}
          onRequestClose={() => {
            this.setState({ modalVisible: false });
          }}
        >
          <TouchableOpacity
            onPress={() => this.setState({ modalVisible: false })}
            style={{ height: 50, backgroundColor: "#000" }}
          />
          <View
            style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#000" }}
          >
            <View
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <View style={{ paddingTop: 20 }}>
                <Text style={{ fontSize: 30, color: "white" }}>
                  Select Country
                  </Text>
              </View>
              <View style={{ marginTop: 0, marginBottom: 5 }} />
              <SectionList
                renderItem={({ item, index, section }) => (
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({
                        modalVisible: false,
                        countryCode: item.split("(")[1].split(")")[0]
                      });
                    }}
                    style={{
                      flex: 1, paddingTop: 10, paddingBottom: 10, paddingLeft: 20, width: Dimensions.get("window").width, backgroundColor: "#112", borderWidth: 1, borderColor: "#223"
                    }}
                  >
                    <Text
                      style={{ fontSize: 16, color: "white" }}
                      key={index}
                    >
                      {item}
                    </Text>
                  </TouchableOpacity>
                )}
                renderSectionHeader={({ section: { title } }) => (
                  <Text style={{ fontWeight: "bold" }}>{title}</Text>
                )}
                sections={[
                  {
                    title: "",
                    data: countryCodes
                  }
                ]}
                keyExtractor={(item, index) => item + index}
              />
            </View>
          </View>
          <TouchableOpacity
            style={{
              position: "absolute",
              borderWidth: 0,
              backgroundColor: "#556",
              borderColor: "rgba(0,0,0,0.2)",
              alignItems: "center",
              justifyContent: "center",
              width: 20,
              top: 20,
              left: 20,
              height: 25,
              borderRadius: 30
            }}
            onPress={() => this.setState({ modalVisible: false })}
          >
            <Image
              style={{ width: 25, height: 25, opacity: 1 }}
              source={require("../images/icons/CancelBlack.png")}
            />
            {/* <Text style={{ fontSize: 26, color: "#666" }}>x</Text> */}
          </TouchableOpacity>
        </Modal>
      </View>
    );
  }

  renderSingleContact = () => {
    return <View>
      {this.state.noContactFound && (
        <View
          style={{
            height: 40,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Text style={{ color: "#eee" }}>No result found.</Text>
        </View>
      )}
      {!this.state.noContactFound &&
        this.state.resultID != 0 && (
          <View>
            <Contact
              following={this.props.followingIDs.includes(this.state.resultID)}
              followed={this.props.followerIDs.includes(this.state.resultID)}
              contactID={this.state.resultID}
              height={65}
              noBackgroundColor={true}
              noBorder={true}
              noAA={this.props.navigation.getParam("purpose", null) == "AddCommunityMembers"}
              navigation={this.props.navigation}
              onPress={this.props.onPress}
            />
          </View>
        )}
    </View>
  }

  renderMultipleContacts = () => {
    return <View>
      {this.state.noContactFound && (
        <View
          style={{
            height: 40,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Text style={{ color: "#eee" }}>No result found.</Text>
        </View>
      )}
      {!this.state.noContactFound &&
        this.state.resultProfiles && (
          <View style={{ marginBottom: 10 }}>
            {this.state.resultProfiles.map((profile, i) => {
              return <View style={{ borderTopColor: "#333", borderTopWidth: i == 0 ? 0 : 1 }} key={profile.xID || i}>
                <Contact
                  profile={profile}
                  following={this.props.followingIDs.includes(profile.xID)}
                  followed={this.props.followerIDs.includes(profile.xID)}
                  height={50}
                  noBackgroundColor={true}
                  noBorder={true}
                  noAA={this.props.navigation.getParam("purpose", null) == "AddCommunityMembers"}
                  navigation={this.props.navigation}
                  onPress={this.props.onPress}
                /></View>
            })}
          </View>
        )}
    </View>
  }

  render() {
    if (this.state.resultID == 0 && this.state.noContactFound == false) {
      //??
    }
    return (
      <View style={{ backgroundColor: "#000", width: "98%", borderWidth: 1, borderColor: "#444", borderRadius: 25, marginLeft: 3 }}>
        {this.renderSearchBar()}
        {!!this.state.activityIndicatorAnimating && <ActivityIndicator size="small" color="#FFFFFF" animating={true} />}
        {this.state.resultType !== "multipleContacts"
          ? this.renderSingleContact()
          : this.renderMultipleContacts()}
      </View>
    );
  }
}


class ContactsNearMe extends Component {

  renderMultipleContacts = () => {
    return <View>
      {this.props.noContactFound && (
        <View
          style={{ height: 40, alignItems: "center", justifyContent: "center" }}
        >
          <Text style={{ color: "#eee" }}>No nearby providers found.</Text>
        </View>
      )}
      {!this.props.noContactFound &&
        this.props.contacts && (
          <View style={{ marginBottom: 10 }}>
            {this.props.contacts.map((profile, i) => {
              return <View style={{ borderTopColor: "#333", borderTopWidth: i == 0 ? 0 : 1 }} key={profile.xID}>
                <Contact
                  key={i}
                  profile={profile}
                  following={this.props.followingIDs.includes(profile.xID)}
                  followed={this.props.followerIDs.includes(profile.xID)}
                  height={50}
                  showDistance={true}
                  noBackgroundColor={true}
                  noBorder={true}
                  noAA={this.props.navigation.getParam("purpose", null) == "AddCommunityMembers"}
                  navigation={this.props.navigation}
                  onPress={this.props.onPress}
                /></View>
            })}
            {!!this.props.loading ? <ActivityIndicator size="small" color="#FFFFFF" style={{ margin: 15 }} /> :
              (!!this.props.moreNearbyToLoad && <TouchableOpacity
                onPress={() => { this.props.seeMore() }}
                style={[global.gStyles.buttonX, { width: 180, alignSelf: "center", marginTop: 5 }]}
              >
                <Text style={global.gStyles.buttontX}>SEE MORE</Text>
              </TouchableOpacity>)}
          </View>
        )}
    </View>
  }

  render() {
    if (this.props.locationNotEnabled) return (
      <View style={{ backgroundColor: "#000", width: "98%", borderWidth: 1, borderColor: "#444", borderRadius: 25, marginLeft: 3 }}>
        <Text style={{ color: "#eee", marginVertical: 15, marginHorizontal: 5 }}>Loacation not enabled</Text>
      </View>
    )
    return (
      <View style={{ backgroundColor: "#000", width: "98%", borderWidth: 1, borderColor: "#444", borderRadius: 25, marginLeft: 3 }}>
        {this.renderMultipleContacts()}
      </View>
    );
  }
}

class MyContacts extends Component {

  state = {
    searchTerm: ""
  }

  search = async (term) => {
    let searchTerm = term.toLowerCase()
    this.setState({ searchTerm, text: term })
  }

  renderBlueSearchBar() {
    return (
      <View
        style={{
          borderBottomWidth: 1,
          height: 50,
          borderColor: "#333"
        }}
      >
        <View
          style={{
            flexDirection: "row",
            marginTop: 0,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <TextInput
            // style={{
            //   alignSelf: "stretch",
            //   height: 30,
            //   width: 300,
            //   marginBottom: 0,
            //   color: "#FFFFFF",
            //   borderBottomColor: "#FFFFFF",
            //   borderBottomWidth: 1
            // }}
            style={{
              alignSelf: "stretch", height: 30, margin: 10, color: "#eee", backgroundColor: "#223", borderRadius: 15, borderBottomWidth: 0, paddingLeft: 10, flex: 1
            }}
            placeholder="Search my contacts"
            placeholderTextColor="#eee"
            textAlign="center"
            underlineColorAndroid="transparent"
            underlineColor={"transparent"}
            onChangeText={searchTerm => this.search(searchTerm)}
            ref={component => (this.myTextInputBlue = component)}
            // clearButtonMode="always" //xx how set colour?
            value={this.state.text}
            keyboardShouldPersistTaps={'always'}
            keyboardDismissMode={'on-drag'}
            keyboardAppearance={"dark"}
            returnKeyType={"done"}
            onSubmitEditing={() => { Keyboard.dismiss() }}
            autoCompleteType={'off'}
            autoCapitalize={'none'}
            autoCorrect={false}
            onFocus={() => { this.props.onFocusSearchContacts(); if (Platform.OS == "android") this.myTextInputBlue.focus() }}
          />
          <TouchableOpacity style={{ marginLeft: -50, width: 50, height: 50, alignItems: "center", justifyContent: "center", paddingRight: 10, paddingBottom: 3 }}
            onPress={() => { this.setState({ searchTerm: "", text: "" }); this.myTextInputBlue.focus() }}>
            <Text style={{ color: "white", fontSize: 24, color: "#667" }}>×</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  renderMultipleContacts = () => {
    return <View>
      {this.renderBlueSearchBar()}
      {this.props.followingIDs.length == 0 && (
        <View style={{ height: 40, alignItems: "center", justifyContent: "center" }}>
          <Text style={{ color: "#eee" }}>No contacts yet.</Text>
        </View>
      )}
      {this.props.followingIDs.length > 0 && (

        <View style={{ marginBottom: 10 }}>
          {this.props.followingIDs.map((id, i) => {
            return <View style={{ borderTopColor: "#333", borderTopWidth: i == 0 ? 0 : 1, marginTop: -1 }} key={i}><Contact
              contactID={id}
              searchTerm={this.state.searchTerm}
              following={this.props.followingIDs.includes(id)}
              followed={this.props.followerIDs.includes(id)}
              height={50}
              noBackgroundColor={true}
              noBorder={true}
              noAA={this.props.navigation.getParam("purpose", null) == "AddCommunityMembers"}
              navigation={this.props.navigation}
              onPress={this.props.onPress}
            /></View>
          })}
        </View>
      )}
    </View>
  }

  render() {
    return (
      <View style={{ backgroundColor: "#000", width: "98%", borderWidth: 1, borderColor: "#444", borderRadius: 25, marginVertical: 0, marginLeft: 3 }}>
        {!!this.props.loading ? <ActivityIndicator size="small" color="#FFFFFF" style={{ margin: 15 }} /> :
          this.renderMultipleContacts()}
      </View>
    );
  }
}

export default class SelectContactScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    return {
      tabBarVisible: false,
      headerTransparent: true,
      headerTintColor: "#A8CF45",
      title:
        navigation.getParam("purpose", "") == "Pay" ? "Select Recipient"
          : navigation.getParam("purpose", "") == "AddCommunityMembers" ? "Select Contacts"
            : "Select Contact",
      headerTitleStyle: {
        fontWeight: "bold",
        color: "#A8CF45",
        shadowOpacity: 1
      },
    };
  };
  state = {
    purpose: "NewChat",
    contactIDs: [],
    loading: true,
    searchResult: null,

    followingIDs: [],
    followerIDs: [],

    contactsNearMe: [],
    noContactNearMeFound: false,
    locationNotEnabled: false,
    loadingContactsNearMe: true,
    moreNearbyToLoad: false,
    activityIndicatorAnimating: false,

    selectedProfiles: []
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    this.getContactsNearMe(2);
    try {
      global.fetchIdToken()
      const purpose = this.props.navigation.getParam("purpose", "NewChat");
      this.setState({ purpose });
      this.unsubscribeFirestore = await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .collection("Refs")
        .doc("contactRefs")
        .onSnapshot(this.updateContacts);

    } catch (err) {
      //alert("Error initiating database connection (NewChat)");
      console.log("Error initiating database connection (NewChat)", err);
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
    console.log("Component NewChat will unmount");
  }

  updateContacts = async contactRefSnapshot => {
    let fingIDs = contactRefSnapshot.data().followingProfileIDs;
    let ferIDs = contactRefSnapshot.data().followerProfileIDs;

    if (fingIDs) {
      this.setState({ followingIDs: fingIDs });
    }
    if (ferIDs) {
      this.setState({ followerIDs: ferIDs });
    }
    this.setState({ loading: false })
  };

  setResult = async result => {
    this.setState({ searchResult: result });
  };
  setResults = async result => {
    this.setState({ searchResult: result });
  };

  getContactsNearMe = async (limit) => {
    try {
      this.setState({ loadingContactsNearMe: true })
      let location = await Location.getCurrentPositionAsync({ accuracy: 3, maximumAge: 60000 });
      if (!location) {
        this.setState({ locationNotEnabled: true, loadingContactsNearMe: false });
        return;
      } else { global.myLocation = location; global.location = location }
      this.setState({ moreNearbyToLoad: false })

      const r = await fetch(
        global.cloudFunctionURL + "findContact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            phoneNumber: "#NEAR-ME#",
            findNearby: true,
            limit,
            coords: location.coords,
            myUID: global.myUID,
            idToken: global.idToken
          })
        }
      );
      const r2 = await r.json();

      console.log("Received contact(s). ", r2);
      this.setState({ loadingContactsNearMe: false });
      if (r2.msg === "NO_CONTACT_FOUND" || r2.msg === "CONTACTS_FOUND" && r2.closestDistance > 5) {
        this.setState({ noContactNearMeFound: true });
        this.nNearMe = 0
        return;
      }
      if (r2.msg === "CONTACTS_FOUND") {
        //filter
        const contactsNearMe = r2.contacts.filter(c => {
          if (!c.locationWork) return false
          if (distance(location.coords.latitude, location.coords.longitude, c.locationWork._latitude, c.locationWork._longitude, "K") > 10) return false
          return true
        })
        if (contactsNearMe.length == r2.contacts.length && r2.contacts.length < 12) {
          this.setState({ moreNearbyToLoad: true })
        }
        this.setState({ contactsNearMe, noContactNearMeFound: false });
        this.nNearMe = contactsNearMe.length
        return;
      }
      console.log("Error: " + r2.msg);
    } catch (err) {
      console.log("Error in getContactsNearMe", err);
    }
  }

  onPressContact = async contactProperties => {
    if (this.state.activityIndicatorAnimating) return;

    if (this.state.purpose == "NewChat") {
      this.callChat(contactProperties);
    }
    if (this.state.purpose == "SelectContactToShare") {
      this.callSelectContactToShare(contactProperties);
    }
    if (this.state.purpose == "AddCommunityMembers") {
      this.callAddCommunityMember(contactProperties);
    }
    if (this.state.purpose == "AddBlogEditor") {
      this.callAddBlogEditor(contactProperties);
    }
    if (this.state.purpose == "ShareGivenContact") {
      this.callShareGivenContact(contactProperties);
    }
    if (this.state.purpose == "ShareGivenCommunity") {
      this.callShareGivenCommunity(contactProperties);
    }
    if (this.state.purpose == "ShareGivenPost") {
      this.callShareGivenPost(contactProperties);
    }
    if (this.state.purpose == "Pay") {
      this.callPay(contactProperties);
    }
  }

  callPay = async (contactProperties) => {
    this.props.navigation.goBack();
    //this.props.navigation.navigate("mePay", {
    this.props.navigation.navigate("walletPay", {
      action: "payment",
      recipientProfileID: contactProperties.opponentProfileID,
      recipientName: contactProperties.opponentName,
      nextScreen: this.props.navigation.getParam("nextScreen", "walletMain")
    });
  };

  callSelectContactToShare = async (contactProperties) => {
    const nextScreen = this.props.navigation.getParam("nextScreen", "walletMain")
    this.props.navigation.navigate(nextScreen, {
      contactID: contactProperties.opponentProfileID
    });
  };

  callShareGivenContact = async (contactProperties) => {
    // call cloud function newChat  xxx try catch this
    this.setState({ activityIndicatorAnimating: true });
    try {
      const r = await fetch(global.cloudFunctionURL + "newChat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          profileID1: global.myProfileID,
          profileID2: contactProperties.opponentProfileID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ activityIndicatorAnimating: false });

      // navigate to returned chatid

      const input = this.props.navigation.getParam("input", null)
      this.props.navigation.goBack();
      this.props.navigation.navigate("chatMain", {
        redirect: true,
        redirectParams: {
          chatID: r2.chatID,
          chatProperties: contactProperties,
          contactID: input
        }
      });
    } catch (err) {
      console.log(err);
      this.setState({ activityIndicatorAnimating: false });
    }
  };

  callShareGivenCommunity = async (contactProperties) => {
    // call cloud function newChat  xxx try catch this
    this.setState({ activityIndicatorAnimating: true });
    try {
      const r = await fetch(global.cloudFunctionURL + "newChat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          profileID1: global.myProfileID,
          profileID2: contactProperties.opponentProfileID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ activityIndicatorAnimating: false });

      // navigate to returned chatid

      const input = this.props.navigation.getParam("input", null)
      this.props.navigation.goBack();
      this.props.navigation.navigate("chatMain", {
        redirect: true,
        redirectParams: {
          chatID: r2.chatID,
          chatProperties: contactProperties,
          communityID: input
        }
      });
    } catch (err) {
      console.log(err);
      this.setState({ activityIndicatorAnimating: false });
    }
  };

  callShareGivenPost = async (contactProperties) => {
    // call cloud function newChat   xx add try
    this.setState({ activityIndicatorAnimating: true });
    try {
      const r = await fetch(global.cloudFunctionURL + "newChat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          profileID1: global.myProfileID,
          profileID2: contactProperties.opponentProfileID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ activityIndicatorAnimating: false });

      // navigate to returned chatid
      const input = this.props.navigation.getParam("input", null)
      this.props.navigation.goBack();
      this.props.navigation.navigate("chatMain", {
        redirect: true,
        redirectParams: {
          chatID: r2.chatID,
          chatProperties: contactProperties,
          postID: input
        }
      });
    } catch (err) {
      console.log(err);
      this.setState({ activityIndicatorAnimating: false });
    }
  };

  callChat = async (contactProperties) => {
    // call cloud function newChat
    this.setState({ activityIndicatorAnimating: true });
    try {
      const r = await fetch(global.cloudFunctionURL + "newChat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          profileID1: global.myProfileID,
          profileID2: contactProperties.opponentProfileID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ activityIndicatorAnimating: false });

      // navigate to returned chatid
      this.props.navigation.goBack();
      this.props.navigation.navigate("chatMain", {
        redirect: true,
        redirectParams: {
          chatID: r2.chatID,
          chatProperties: contactProperties
        }
      });
    } catch (err) {
      console.log(err);
      this.setState({ activityIndicatorAnimating: false });
    }
  };


  callAddCommunityMember = async (contactProperties) => {
    const existingSelection = this.state.selectedProfiles.filter(p => { return p.opponentProfileID == contactProperties.opponentProfileID })
    if (existingSelection.length > 0) return;

    const communityMemberProfileIDs = this.props.navigation.getParam("communityMemberProfileIDs", []);
    if (communityMemberProfileIDs.includes(contactProperties.opponentProfileID)) {
      return alert((contactProperties.opponentName || "This contact") + " is already a member.")
    }
    const selectedProfiles = global.duplicate(this.state.selectedProfiles)
    selectedProfiles.unshift(contactProperties)
    await this.setState({ selectedProfiles })
    //this.hScrollView.scrollToEnd({ animated: true });
  }
  callAddCommunityMembers = async () => {
    if (this.state.selectedProfiles.length == 0) return;
    Alert.alert(
      "Sure?", "This will add " + this.state.selectedProfiles.length + " contacts to your community",
      [{ text: "Cancel", onPress: () => console.log("Cancel Pressed"), style: "cancel" },
      { text: "Yes", onPress: () => { this.callAddCommunityMembersExecution() } }],
      { cancelable: false });
  }
  callAddCommunityMembersExecution = async () => {
    // call cloud function newChat
    this.setState({ activityIndicatorAnimating: true });
    try {
      const communityID = this.props.navigation.getParam("communityID", null);
      const r = await fetch(global.cloudFunctionURL + "updateCommunity", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          communityID,
          action: "addMembers",
          targetProfileIDs: this.state.selectedProfiles.map(p => { return p.opponentProfileID }),
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ activityIndicatorAnimating: false });

      // navigate to returned chatid
      this.props.navigation.goBack();
      await global.timeout(300)
      if (r2.msg !== "SUCCESS") alert("Hmm - request not successful.")
    } catch (err) {
      global.warn(err, "scs-cacme");
      this.setState({ activityIndicatorAnimating: false });
      alert("Error....")
    }
  };

  callAddBlogEditor = async (contactProperties) => {
    const blogEditorProfileIDs = this.props.navigation.getParam("blogEditorProfileIDs", []);
    if (blogEditorProfileIDs.includes(contactProperties.opponentProfileID)) {
      return alert((contactProperties.opponentName || "This contact") + " is already an editor.")
    }
    Alert.alert(
      "Sure?", "This will make " + (contactProperties.opponentName || "this contact") + " an editor of your blog",
      [{ text: "Cancel", onPress: () => console.log("Cancel Pressed"), style: "cancel" },
      { text: "Yes", onPress: () => { this.callAddBlogEditorExecution(contactProperties) } }],
      { cancelable: false });
  }
  callAddBlogEditorExecution = async (contactProperties) => {
    // call cloud function newChat
    this.setState({ activityIndicatorAnimating: true });
    try {
      const blogID = this.props.navigation.getParam("blogID", null);
      const r = await fetch(global.cloudFunctionURL + "blog", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          blogID,
          action: "addEditor",
          targetProfileID: contactProperties.opponentProfileID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ activityIndicatorAnimating: false });

      // navigate to returned chatid
      this.props.navigation.goBack();
      await global.timeout(300)
      if (r2.msg !== "SUCCESS") alert("Hmm - request not successful.")
    } catch (err) {
      global.warn(err, "scs-cabee");
      this.setState({ activityIndicatorAnimating: false });
      alert("Error....")
    }
  };

  removeCommunityMember = async (contactProperties) => {
    let selectedProfiles = global.duplicate(this.state.selectedProfiles)
    selectedProfiles = selectedProfiles.filter(p => { return p.opponentProfileID !== contactProperties.opponentProfileID })
    this.setState({ selectedProfiles })
  }

  renderSelectedProfiles = () => {
    return (<View style={{ flexDirection: "row", alignItems: "center", marginBottom: Platform.OS == "ios" ? 40 : 25 }}>
      <ScrollView horizontal ref={ref => (this.hScrollView = ref)} showsHorizontalScrollIndicator={false}>
        {this.state.selectedProfiles.map(p => {
          let name = p.opponentName
          if (name.length > 11) name = name.substring(0, 9) + "…"
          return (
            <TouchableOpacity key={p.opponentProfileID}
              onPress={() => { global.navigateProfile(this.props.navigation, p.opponentProfileID) }}>
              <View style={{ alignItems: "center", width: 80, paddingTop: 10 }}>
                <View
                  style={{ backgroundColor: "#bbc", margin: 2, height: 66, width: 66, borderRadius: 33, justifyContent: "center", alignItems: "center" }}
                >
                  {p.opponentProfilePictureURL != "" && (<Image
                    style={{ marginTop: 1, marginBottom: 2, width: 60, height: 60, borderRadius: 30 }}
                    source={{ uri: p.opponentProfilePictureURL }}
                  />
                  )}
                </View>
                <TouchableOpacity style={{ marginTop: -75, marginRight: -10, height: 40, width: 40, marginBottom: 35, alignSelf: "flex-end" }}
                  onPress={() => { this.removeCommunityMember(p) }} >
                  <Image
                    style={{ width: 35, height: 35, opacity: 1 }}
                    source={require("../images/icons/CancelWhite.png")}
                  />
                </TouchableOpacity>
                <Text style={{ color: "#bbc", fontSize: 12 }}>{name}</Text>
              </View>
            </TouchableOpacity >
          )
        })
        }
      </ScrollView>
      {Platform.OS == "android" && this.state.selectedProfiles.length > 0 && <TouchableOpacity
        style={{
          alignItems: "center", justifyContent: "center", backgroundColor: "#A8CF45", paddingHorizontal: 2, zIndex: 95,
          marginLeft: 2, borderTopRightRadius: 20, borderBottomRightRadius: 20, paddingVertical: 8, width: 70, marginRight: 8
        }}
        onPress={this.callAddCommunityMembers}
      >
        <Image
          style={{ width: 30, height: 30, marginVertical: 3, marginLeft: 7 }}
          source={require("../images/icons/Send.png")}
        />
        <Text style={{ fontSize: 14, color: "#000", fontWeight: "bold" }}>Select</Text>
      </TouchableOpacity>}
    </View>)
  }

  render() {
    if (this.state.activityIndicatorAnimating) return (
      <View style={{ height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: "#000" }}>
        <FadeInView duration={5000}><Text style={{ color: "#889", fontSize: 28 }}>Processing...</Text></FadeInView>
        <ActivityIndicator size="large" color="#889" style={{ marginTop: 45 }} />
      </View>
    );

    const tStyle = { color: "#A8CF45", fontSize: 14, fontWeight: "100", textAlign: "left", marginTop: 10 }
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#131313" }}>
        <View style={{ backgroundColor: "#131313", flex: 1, width: "100%" }}>
          <View
            style={{
              borderBottomColor: "#555555", borderWidth: 0, ...Platform.select({ ios: { height: 50 }, android: { height: 90 } }),
              alignItems: "flex-end", paddingTop: Platform.OS == "ios" ? 3 : 44
            }}
          >
            {Platform.OS == "ios" && this.state.selectedProfiles.length > 0 && <TouchableOpacity
              style={{
                alignItems: "center", justifyContent: "center", backgroundColor: "#A8CF45", paddingHorizontal: 8, zIndex: 95,
                marginRight: 2, flexDirection: "row", borderRadius: 20, paddingVertical: 8, width: 100, marginRight: 20
              }}
              onPress={this.callAddCommunityMembers}
            >
              <Text style={{ fontSize: 14, color: "#000", fontWeight: "bold" }}>
                Select
                </Text>
              <Image
                style={{ width: 15, height: 15, marginVertical: 3, marginLeft: 7 }}
                source={require("../images/icons/Send.png")}
              />
            </TouchableOpacity>}
          </View>
          {this.state.selectedProfiles.length > 0 && this.renderSelectedProfiles()}
          <ScrollView
            keyboardShouldPersistTaps={'always'}
            keyboardDismissMode={'on-drag'}
            //style={{ width: "100%", backgroundColor: "#000" }}
            ref={ref => (this.myScrollView = ref)}
            scrollEventThrottle={0}
            onScroll={(e) => {
              if (Platform.OS == 'android') Keyboard.dismiss()
            }}
          >
            <View style={{}}>
              <SearchBar
                followingIDs={this.state.followingIDs}
                followerIDs={this.state.followerIDs}
                navigation={this.props.navigation}
                onPress={this.onPressContact}
              /></View>
            <Text style={tStyle}>Shops Around Me</Text>
            <ContactsNearMe
              followingIDs={this.state.followingIDs}
              followerIDs={this.state.followerIDs}
              navigation={this.props.navigation}
              onPress={this.onPressContact}
              noContactFound={this.state.noContactNearMeFound}
              locationNotEnabled={this.state.locationNotEnabled}
              contacts={this.state.contactsNearMe}
              loading={this.state.loadingContactsNearMe}
              moreNearbyToLoad={this.state.moreNearbyToLoad}
              seeMore={() => { this.getContactsNearMe(12); }}
            />
            <Text style={tStyle}>My Contacts</Text>
            <MyContacts
              loading={this.state.loading}
              followingIDs={this.state.followingIDs}
              followerIDs={this.state.followerIDs}
              navigation={this.props.navigation}
              onPress={this.onPressContact}
              onFocusSearchContacts={() => { this.myScrollView.scrollTo({ x: 0, y: (126 + (this.nNearMe || 0) * 50), animated: true }) }}
            />
            <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
              <View style={{ width: "100%", height: 600 }} />
            </TouchableWithoutFeedback>
          </ScrollView>
        </View>
      </SafeAreaView>
    );
  }
}

class FadeInView extends Component {
  state = {
    opacity: new Animated.Value(0),
  }

  componentDidMount = () => {
    Animated.timing(this.state.opacity, {
      toValue: 1,
      duration: this.props.duration || 2000,
      useNativeDriver: true,
    }).start();
  }

  render() {
    return (
      <Animated.View
        onLoad={this.onLoad}
        {...this.props}
        style={[
          {
            opacity: this.state.opacity,
            transform: [
              {
                scale: this.state.opacity.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0.85, 1],
                })
              },
            ],
          },
          this.props.style,
        ]}
      />
    );
  }
}
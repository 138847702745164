import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  ImageBackground,
  TouchableOpacity,
  Platform,
  Dimensions,
  ActivityIndicator
} from "react-native";
import * as Permissions from "expo-permissions";
import * as Location from "expo-location";
import firebase from "firebase";
import "firebase/firestore";
import Moment from "react-moment";
import * as Haptics from 'expo-haptics';

import CachedImage from "../../shared/CachedImage"
import FullscreenImage from "../FullscreenImage"
import FeedMenu from "../../Me/Communities/FeedMenu"
const gStyles = global.gStyles //    "../../styles/gStyles";
import { Touchable } from "react-native";

export default class Group extends Component {
  state = {
    titleHeight: 50,
    pressedLike: false,
    pressedDislike: false,
    pressedJoin: this.props.amJoiner,
    showAllTrue: false,
    showFullscreenImage: false,
    fullscreenImageIsCached: false,
    watchingYoutube: false,
    showFeedMenu: false,
    pollVotes: [],
    justVoted: false,
    justMarkedTaskDone: false,
    showDone: this.props.taskAssignees?.length > 12 ? false : true,
    showNotDone: this.props.taskAssignees?.length > 12 ? false : true,
    showEditComment: this.props.editComment,

    activityIndicatorAnimating: false
  };

  constructor(props) {
    super(props);
  }

  callVote = async () => {
    try {
      if (!this.state.pollVotes || this.state.pollVotes.length < 1) return alert(this.props.chatGroup?.pollOptions?.allowMultiple ? "Select at least one option" : "Select an option")
      if (this.state.activityIndicatorAnimating) return
      if (this.state.justVoted) return
      this.setState({ activityIndicatorAnimating: true })
      const x = this.props.chatGroup
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "joinGroup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          isWeb: true,
          chatGroupID: x.xID,
          action: "vote",
          pollVotes: this.state.pollVotes,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      this.setState({ activityIndicatorAnimating: false })
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") this.setState({ justVoted: true })
      console.log("callVote done")
    } catch (e2) {
      this.setState({ activityIndicatorAnimating: false })
      global.warn(e2, "callVote")
    }
  };

  callMarkTaskDone = async () => {
    try {
      this.setState({ activityIndicatorAnimating: true })
      const x = this.props.chatGroup
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "joinGroup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          isWeb: true,
          chatGroupID: x.xID,
          action: "markTaskDone",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      this.setState({ activityIndicatorAnimating: false })
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") this.setState({ justMarkedTaskDone: true })
      console.log("callMarkTaskDone done")
    } catch (e2) {
      this.setState({ activityIndicatorAnimating: false })
      global.warn(e2, "callMarkTaskDone")
    }
  };

  callEndPoll = async () => {
    try {
      if (!this.props.amModerator) return
      if (this.state.activityIndicatorAnimating) return
      this.setState({ activityIndicatorAnimating: true })
      const x = this.props.chatGroup
      await global.fetchIdToken()
      await fetch(global.cloudFunctionURL + "editGroup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          isWeb: true,
          chatGroupID: x.xID,
          action: "endPoll",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      this.setState({ activityIndicatorAnimating: false })
      console.log("callVote done")
    } catch (e2) {
      this.setState({ activityIndicatorAnimating: false })
      global.warn(e2, "callVote")
    }
  };

  calendarStrings = {
    lastDay: '[Yesterday at] LT',
    sameDay: '[Today at] LT',
    nextDay: '[Tomorrow at] LT',
    lastWeek: '[last] dddd [at] LT',
    nextWeek: 'dddd [at] LT',
    sameElse: 'L'
  }

  renderContextMenu = x => {
    return (
      <TouchableOpacity style={{ position: "absolute", zIndex: 3, top: 0, right: 0, height: 48, width: 48, alignItems: "center", justifyContent: "center" }}
        onPress={() => {
          this.setState({ showFeedMenu: true });
          ///Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy)
        }} >
        <View style={{ width: 40, alignItems: "center", justifyContent: "center", paddingLeft: 15 }}>
          <Text style={{ fontWeight: "bold", fontSize: 24, fontWeight: "bold", color: "#fff", marginTop: 4 }}>
            ⋮
            </Text>
        </View>
        <FeedMenu
          showEditComment
          showMenuModal={this.state.showFeedMenu}
          chatGroup={x}
          refresh={this.props.refresh}
          editComment={this.state.showEditComment}
          onDone={() => { this.setState({ showFeedMenu: false }) }}
          delayGoBack
          navigation={this.props.navigation} />
      </TouchableOpacity>)
  }

  renderTop = x => {
    if (false)
      // if (this.props.view !== "LIST")
      return (
        <View
          style={{
            height: 35,
            justifyContent: "space-between",
            alignItems: "flex-start",
            flexDirection: "row",
          }}
        >
          <View style={{ flex: 1, flexDirection: "row" }}>
            <View
              style={{
                width: 33,
                alignItems: "flex-start",
                justifyContent: "center"
              }}
            >
              {x.ownerPictureURL ? (
                <CachedImage
                  style={{
                    width: 25,
                    height: 25,
                    borderRadius: 12.5
                  }}
                  source={{
                    uri: x.ownerPictureURL
                  }}
                  key={x.ownerPictureURL}
                />
              ) : (
                <View
                  style={{
                    width: 25,
                    height: 25,
                    borderRadius: 12.5,
                    backgroundColor: "#668"
                  }}
                />
              )}
            </View>
            <View style={{ flex: 1 }}>
              <Text style={{ color: "#aab", fontSize: 13 }}>{x.ownerName}</Text>
              <Text style={{ color: "#889", fontSize: 9 }}>
                {x.timeCreated && (
                  <Moment fromNow element={Text}>
                    {x.timeCreatedDate}
                  </Moment>
                )}
              </Text>
            </View>
          </View>
          {x.xDist >= 0 && (
            <View
              style={{
                flexDirection: "row",
                width: 60,
                justifyContent: "flex-end",
                alignItems: "center"
              }}
            >
              <Image
                style={{
                  width: 15,
                  height: 16.5,
                  marginRight: 2,
                  tintColor: "#889"
                }}
                source={require("../../images/icons/Location.png")}
              />
              <Text style={{ fontSize: 10, color: "#aab", fontWeight: "500" }}>
                {Math.round(x.xDist * 10) / 10 + " km"}
              </Text>
            </View>
          )}
          <TouchableOpacity
            onPress={() => {
              return;
            }}
          >
            <View
              style={{
                width: 40,
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: 15
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 24,
                  color: "#ccd",
                  marginTop: 4
                }}
              >
                ⋮
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      );

    if (this.props.view == "DETAIL") return <View style={{ marginTop: 50 }} />;

    // return (<View style={{
    //   height: 25, justifyContent: "space-between", alignItems: "flex-start",
    //   flexDirection: "row", marginTop: 50
    // }}>
    //   <View style={{ flex: 1, flexDirection: "row" }}>
    //     <View style={{ width: 33 }} />
    //     <View style={{ width: 20, alignItems: "flex-start", justifyContent: "center" }}>
    //       {x.ownerPictureURL ? <Image
    //         style={{
    //           width: 18, height: 18, borderRadius: 9,
    //         }}
    //         source={{
    //           uri: x.ownerPictureURL
    //         }}
    //       /> : <View style={{ width: 18, height: 18, borderRadius: 9, backgroundColor: "#668" }} />}
    //     </View>
    //     <Text style={{ flex: 1, color: "#aab", fontSize: 10 }}>{x.ownerName + "    "}</Text>
    //     <Text style={{ flex: 1, color: "#889", fontSize: 10 }}>{x.timeCreated && (
    //       <Moment fromNow element={Text}>
    //         {x.timeCreatedDate}
    //       </Moment>)}
    //     </Text>
    //   </View>
    //   {x.xDist >= 0 && (<View
    //     style={{ flexDirection: "row", width: 60, justifyContent: "flex-end", alignItems: "center" }}
    //   >
    //     <Image
    //       style={{ width: 15, height: 16.5, marginRight: 2, tintColor: "#889" }}
    //       source={require("../../images/icons/Location.png")}
    //     />
    //     <Text style={{ fontSize: 10, color: "#aab", fontWeight: "500" }}>
    //       {(Math.round(x.xDist * 10)) / 10 + " km"}
    //     </Text>
    //   </View>)}
    //   <TouchableOpacity onPress={() => { return }}>
    //     <View style={{ width: 40, alignItems: "center", justifyContent: "center", paddingLeft: 15 }}>
    //       <Text style={{ fontWeight: "bold", fontSize: 24, color: "#ccd", marginTop: 4 }}>
    //         ⋮
    //       </Text>
    //     </View>
    //   </TouchableOpacity>
    // </View>)
  };

  renderMain = x => {
    const imWidth = (Dimensions.get("window").width - 40) * 0.4;
    const imFontSize =
      x.groupTitle.length > 60
        ? 14
        : x.groupTitle.length > 40
          ? 16
          : x.groupTitle.length > 20
            ? 18
            : 22;
    const nImFontSize = x.groupTitle.length > 20 ? 18 : 22;
    const mainHeight = (imWidth / 16) * 9;

    return (
      <View
        style={{
          height: !this.state.showAllTrue ? mainHeight : 'auto',
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          marginBottom: 5,
          marginTop: 2
        }}
      >
        {!x.groupPictureURL ? (
          <View
            style={{
              width: "100%",
              height: !this.state.showAllTrue ? mainHeight : 'auto',
              flex: 1,
              justifyContent: "space-between"
            }}
          >
            {!this.state.showAllTrue ? <Text
              numberOfLines={3}
              onLayout={e => {
                this.setState({
                  titleHeight: Math.round(e.nativeEvent.layout.height)
                });
              }}
              style={{
                fontWeight: "bold",
                fontSize: nImFontSize,
                color: "#ccd",
                marginBottom: 10
              }}
            >      {x.groupTitle}
            </Text> : <Text
              style={{
                fontWeight: "bold",
                fontSize: nImFontSize,
                color: "#ccd"
              }}
            >
              {x.groupTitle}
            </Text>}
            {!this.state.showAllTrue ? <Text
              numberOfLines={
                Math.round(mainHeight - this.state.titleHeight - 6) / 12
              }
              style={{ fontSize: 12, color: "#ccd" }}
            >
              {x.groupDetails}
            </Text> : <Text
              style={{ fontSize: 12, color: "#ccd" }}
            >
              {x.groupDetails}
            </Text>}
          </View>
        ) : (
          <View style={{ flexDirection: "row", flex: 1, height: !this.state.showAllTrue ? mainHeight : 'auto' }}>
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => {
                if (x.groupYoutubeID) this.setState({ watchingYoutube: true })
                else this.setState({ showFullscreenImage: true, fullscreenImageIsCached: true })
              }}>
              <CachedImage
                style={{
                  width: imWidth,
                  height: mainHeight,
                  marginRight: 10,
                  backgroundColor: "#224"
                }}
                source={{ uri: x.groupPictureURL }}
                key={x.groupPictureURL}
              />
            </TouchableOpacity>
            <View style={{ flex: 1, justifyContent: "space-between" }}>

              {!this.state.showAllTrue ? <Text
                numberOfLines={3}
                onLayout={e => {
                  this.setState({
                    titleHeight: Math.round(e.nativeEvent.layout.height)
                  });
                }}
                style={{
                  fontWeight: "bold",
                  fontSize: imFontSize,
                  color: "#ccd"
                }}
              >
                {x.groupTitle}
              </Text> : <Text
                style={{
                  fontWeight: "bold",
                  fontSize: imFontSize,
                  color: "#ccd"
                }}
              >
                {x.groupTitle}
              </Text>}
              {!this.state.showAllTrue ? <Text
                numberOfLines={
                  Math.round(mainHeight - this.state.titleHeight - 5) / 10
                }
                style={{ fontSize: 10, color: "#ccd" }}
              >
                {x.groupDetails}
              </Text> : <Text
                style={{ fontSize: 10, color: "#ccd" }}
              >
                {x.groupDetails}
              </Text>}
            </View>
          </View>
        )}
      </View>
    );
  };

  renderMainAsPoll = (x) => {
    return (<View style={{}}>
      <TouchableOpacity
        style={{ justifyContent: "space-between", alignItems: "center", }}
        activeOpacity={1}
        onPress={() => {
          if (x.groupYoutubeID) this.setState({ watchingYoutube: true })
          else this.setState({ showFullscreenImage: true, fullscreenImageIsCached: false })
        }}>
        {!!x.groupPictureURL && <Image
          style={{
            width: Dimensions.get("window").width * 0.9, height: 150, paddingRight: 8, borderRadius: 8.5,
          }}
          source={{ uri: x.groupPictureURL }}
          key={x.groupPictureURL}
        />}
        {!!x.groupPictureURL && !!x.groupYoutubeID && <View
          style={{
            borderRadius: 14, paddingLeft: 6, height: 55, justifyContent: "center", alignItems: "center", width: 55, bottom: 92, marginBottom: -55
          }}
        >
          <Text style={{ color: "#333", fontSize: 42 }}>{"▶️"}</Text>
        </View>}
      </TouchableOpacity>

      <View style={{ margin: 10, padding: 10 }}>
        <Text
          style={{ color: "white", fontWeight: "bold", fontFamily: "ProximaNovaA-Bold", fontSize: 20, marginVertical: 5 }}
        >
          🗳️  {x.groupTitle}
        </Text>

        {!x.xHaveVoted && !x.pollHasEnded && <View>
          {x.pollAnswers && x.pollAnswers.map((a, i) => {
            return (
              <TouchableOpacity key={a + i + this.state.pollVotes.includes(i)}
                onPress={() => {
                  if (!x.pollOptions?.allowMultiple) {
                    this.setState({ pollVotes: [i] })
                  } else {
                    let pollVotes = global.duplicate(this.state.pollVotes)
                    if (pollVotes.includes(i)) {
                      pollVotes = pollVotes.filter(pv => pv !== i)
                    }
                    else {
                      pollVotes.push(i)
                      pollVotes.sort((a, b) => (a - b))
                    }
                    this.setState({ pollVotes })
                  }
                }}>
                <View style={{ height: 30 }} >
                  <CheckboxSelector selected={this.state.pollVotes.includes(i)} text={a} />
                </View>
              </TouchableOpacity>
            )
          })}

          {!this.state.activityIndicatorAnimating ? <View style={{ marginTop: 35 }}>
            <TouchableOpacity
              onPress={this.callVote}
            >
              <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: 150, alignSelf: "center", alignItems: "center", marginTop: 5 }]} >
                <Text style={{ fontSize: !this.state.justVoted ? 20 : 14, fontWeight: "bold", color: "#000" }}>{!this.state.justVoted ? "Vote" : "Getting results ..."}</Text>
              </View>
            </TouchableOpacity>
          </View> :
            <ActivityIndicator size="large" color="#A8CF45" style={{ alignSelf: "center" }} animating={true}
            />}
        </View>}

        {(x.xHaveVoted || x.pollHasEnded) && <View>
          {x.pollAnswers && x.pollAnswers.map((a, i) => {
            const count = x.pollResultsCondensed[i]
            const max = Math.max(...(x.pollResultsCondensed || [1])) || 1
            const voters = Object.keys(x.pollResults || {})
            const votersForThis = voters.filter(voter => {
              return x.pollResults?.[voter]?.includes?.(i)
            })
            return (
              <View key={a + i}>
                <View style={{ height: voters.length > 0 ? 60 : 35, marginTop: 5, width: "100%", flexDirection: "row" }}>
                  <View style={{ flex: count / max, height: voters.length > 0 ? 60 : 35, backgroundColor: "rgba(60,0,255,1)", borderRadius: 5 }} />
                  <View style={{ flex: (max - count) / max, height: voters.length > 0 ? 60 : 35, backgroundColor: "rgba(60,0,255,0.2)" }} />
                </View>
                <View style={{ marginTop: voters.length > 0 ? -60 : -35, height: 30, width: "100%", flexDirection: "row", alignItems: "center" }}>
                  <Text style={{ color: "#ccd", fontWeight: "bold", flex: 1, marginLeft: 6, fontSize: a.length > 40 ? 12 : 14 }} numberOfLines={2}>{a}</Text>
                  <Text style={{ color: "#ccd", fontWeight: "bold", width: 30, fontSize: count > 99 ? 14 : 18, textAlign: "right", marginRight: 13 }}>{count}</Text>
                </View>
                <View style={{ height: voters.length > 0 ? 30 : 5, marginBottom: 5, width: "100%", flexDirection: "row" }}>
                  {votersForThis.length > 0 && Array.isArray(votersForThis) && <View style={{ marginLeft: 5, width: "100%", height: 28 }}>
                    <ScrollView horizontal>
                      {votersForThis.map(vID => {
                        return <Member profileID={vID} faceOnly key={vID} many={max > 6}
                          navigation={this.props.navigation} />
                      })}
                    </ScrollView>
                  </View>}
                </View>
              </View>
            )
          })}
          {this.props.amModerator && !x.pollHasEnded && !this.state.activityIndicatorAnimating ? <View>
            <TouchableOpacity
              onPress={this.callEndPoll}
            >
              <View style={[global.gStyles.buttonXLW, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: 150, alignSelf: "center", alignItems: "center" }]} >
                <Text style={{ fontSize: 20, fontWeight: "bold", color: "#ccd" }}>End Poll</Text>
              </View>
            </TouchableOpacity>
          </View> :
            this.state.activityIndicatorAnimating && <ActivityIndicator size="large" color="#A8CF45" style={{ alignSelf: "center" }} animating={true}
            />}
        </View>}

      </View>
    </View >)
  }

  renderMainAsEvent = (x) => {
    const repeat = x.eventOptions.repeat//["daily", "weekdays", "weekly", "monthly", "yearly", "never","1st","2nd","3rd","last"]
    let monthlyString = "" + ((new Date(x.eventMillisOne))?.getDate())
    if (monthlyString.endsWith(1)) monthlyString += "st"
    else if (monthlyString.endsWith(2)) monthlyString += "nd"
    else if (monthlyString.endsWith(3)) monthlyString += "rd"
    else monthlyString += "th"

    let everyString = ""
    if (["1st", "2nd", "3rd", "last"].includes(repeat)) {
      const weekday = global.dayStrings[(new Date(x.eventMillisOne)).getDay()]
      const day = ((new Date(x.eventMillisOne))?.getDate())
      let xthString = "1st"
      if (day > 7 && day <= 14) xthString = "2nd"
      if (day > 14 && day <= 21) xthString = "3rd"
      if (day > 21) xthString = "last"
      everyString = "every " + xthString + " " + weekday

    }

    return (
      <View style={{ paddingBottom: 20, paddingTop: 55, }}>
        <Text style={{ paddingLeft: 20, color: "white", fontFamily: "ProximaNovaA-Bold", fontSize: 20, marginBottom: 5 }}>
          {x.groupTitle}
        </Text>
        {!repeat && <Text style={{ color: "#ddf", paddingLeft: 20, fontFamily: "ProximaNovaA-Bold", marginVertical: 5, fontSize: 17 }}>
          <Moment calendar={this.calendarStrings} element={Text}>
            {x.eventMillisOne}
          </Moment>
        </Text>}
        {repeat == "daily" && <Text style={{ color: "#ddf", paddingLeft: 20, fontFamily: "ProximaNovaA-Bold", marginVertical: 5, fontSize: 17 }}>
          <Moment format="HH:mm" element={Text}>
            {x.eventMillisOne}
          </Moment> daily
        </Text>}
        {repeat == "weekdays" && <Text style={{ color: "#ddf", paddingLeft: 20, fontFamily: "ProximaNovaA-Bold", marginVertical: 5, fontSize: 17 }}>
          <Moment format="HH:mm" element={Text}>
            {x.eventMillisOne}
          </Moment> weekdays
        </Text>}
        {repeat == "weekly" && <Text style={{ color: "#ddf", paddingLeft: 20, fontFamily: "ProximaNovaA-Bold", marginVertical: 5, fontSize: 17 }}>
          <Moment format="HH:mm" element={Text}>
            {x.eventMillisOne}
          </Moment> every {global.dayStrings[(new Date(x.eventMillisOne))?.getDay?.() || 0]}
        </Text>}
        {repeat == "monthly" && <Text style={{ color: "#ddf", paddingLeft: 20, fontFamily: "ProximaNovaA-Bold", marginVertical: 5, fontSize: 17 }}>
          <Moment format="HH:mm" element={Text}>
            {x.eventMillisOne}
          </Moment> every {monthlyString} of the month
        </Text>}
        {repeat == "yearly" && <Text style={{ color: "#ddf", paddingLeft: 20, fontFamily: "ProximaNovaA-Bold", marginVertical: 5, fontSize: 17 }}>
          {global.monthStrings[(new Date(x.eventMillisOne))?.getMonth?.() || 0]} {monthlyString}, <Moment format="HH:mm" element={Text}>
            {x.eventMillisOne}
          </Moment> every year
        </Text>}
        {["1st", "2nd", "3rd", "last"].includes(repeat) && <Text style={{ color: "#ddf", paddingLeft: 20, fontFamily: "ProximaNovaA-Bold", marginVertical: 5, fontSize: 17 }}>
          <Moment format="HH:mm" element={Text}>
            {x.eventMillisOne}
          </Moment> {everyString}
        </Text>}
        <Text style={{ paddingLeft: 20, color: "#dfd", fontFamily: "ProximaNovaA-Bold", marginTop: 10, fontSize: 15 }}
        >{x.groupDetails || ""}</Text>

        {!!x.groupPictureURL && !!x.groupYoutubeID && < TouchableOpacity
          style={{ justifyContent: "space-between", alignItems: "center", }
          }
          activeOpacity={1}
          onPress={() => {
            this.setState({ watchingYoutube: true })
          }}>
          <View
            style={{
              borderRadius: 14, paddingLeft: 6, height: 50, paddingTop: 20, alignSelf: "flex-start",
              justifyContent: "center", alignItems: "center", paddingHorizontal: 20, marginLeft: 15,
            }}
          >
            <Text style={{ color: "#fff", fontWeight: "bold", fontSize: 15 }}>{"▶️ Play Video"}</Text>
          </View>
        </TouchableOpacity>}
      </View >)
  }

  renderMainAsTask = (x) => {
    const repeat = x.taskOptions.repeat
    let monthlyString = "" + ((new Date(x.taskMillisOne))?.getDate())
    if (monthlyString.endsWith(1)) monthlyString += "st"
    else if (monthlyString.endsWith(2)) monthlyString += "nd"
    else if (monthlyString.endsWith(3)) monthlyString += "rd"
    else monthlyString += "th"
    let overdueString = "Overdue reminders sent "
    if (repeat == "weekly") overdueString += ("every " + global.dayStrings[(new Date(x.taskMillisOne))?.getDay?.() || 0])
    else if (repeat == "monthly") overdueString += ("every " + monthlyString + " of the month")
    else overdueString += (repeat || "")

    let nowDate = new Date()
    let oStatus = "open"
    if (nowDate.getTime() > x.taskMillisOne) oStatus = "overdue"

    const assigneesNotDone = x.taskAssignees.filter(a => !x.taskAssigneesDone.includes(a))
    const assigneesDone = x.taskAssigneesDone
    const haveMarkedDone = assigneesDone.includes(global.myProfileID)
    const needsToMarkDone = assigneesNotDone.includes(global.myProfileID)

    return (
      <View style={{ paddingBottom: 20, paddingTop: 55, }}>
        <Text style={{ paddingLeft: 20, color: "white", fontFamily: "ProximaNovaA-Bold", fontSize: 20, marginBottom: 5 }}>
          {x.groupTitle}
        </Text>
        <Text style={{ paddingLeft: 20, color: "#fff", fontFamily: "ProximaNovaA-Bold", marginBottom: 10, fontSize: 15 }}
        >{x.groupDetails || ""}</Text>
        <Text style={{ color: oStatus == "overdue" ? "#daa" : "#ddf", paddingLeft: 20, fontFamily: "ProximaNovaA-Bold", marginVertical: 10, fontSize: 17 }}>
          Till <Moment calendar={this.calendarStrings} element={Text}>
            {x.taskMillisOne}
          </Moment>
        </Text>

        {!!x.groupPictureURL && !!x.groupYoutubeID && < TouchableOpacity
          style={{ justifyContent: "space-between", alignItems: "center", }
          }
          activeOpacity={1}
          onPress={() => {
            this.setState({ watchingYoutube: true })
          }}>
          <View
            style={{
              borderRadius: 14, paddingLeft: 6, height: 50, paddingTop: 20, alignSelf: "flex-start",
              justifyContent: "center", alignItems: "center", paddingHorizontal: 20, marginLeft: 15,
            }}
          >
            <Text style={{ color: "#fff", fontWeight: "bold", fontSize: 15 }}>{"▶️ Play Video"}</Text>
          </View>
        </TouchableOpacity >}

        {/* <Text style={{ paddingLeft: 20, color: "#fff", fontFamily: "ProximaNovaA-Bold", marginTop: 18, fontSize: 13 }}
        >ASSIGNED TO</Text> */}
        <TouchableOpacity style={{ paddingTop: 18 }}
          onPress={() => { this.setState({ showDone: !this.state.showDone }) }} >
          {assigneesDone.length > 0 && <Text style={{ paddingLeft: 20, color: "#ada", fontFamily: "ProximaNovaA-Bold", fontSize: 13 }}
          >{!this.state.showDone ? "►" : "▾"}  DONE {!this.state.showDone && ("(" + assigneesDone.length + ")")}</Text>}
        </TouchableOpacity>
        {!!this.state.showDone && assigneesDone.length > 0 && assigneesDone.map(aID => {
          return <Assignee
            key={aID + (haveMarkedDone ? 1 : 2) + "completed"}
            profileID={aID}
            status={"completed"}
            navigation={this.props.navigation}
          />
        })}
        <TouchableOpacity style={{ paddingTop: 18 }}
          onPress={() => { this.setState({ showNotDone: !this.state.showNotDone }) }} >
          {assigneesNotDone.length > 0 && <Text style={{ paddingLeft: 20, color: oStatus == "overdue" ? "#daa" : "#ddf", fontFamily: "ProximaNovaA-Bold", fontSize: 13 }}
          >{!this.state.showNotDone ? "►" : "▾"}  {oStatus.toUpperCase()} {!this.state.showNotDone && ("(" + assigneesNotDone.length + ")")}</Text>}
        </TouchableOpacity>
        {!!this.state.showNotDone && assigneesNotDone.length > 0 && assigneesNotDone.map(aID => {
          return <Assignee
            key={aID + (haveMarkedDone ? 1 : 2) + oStatus}
            profileID={aID}
            status={oStatus}
            navigation={this.props.navigation}
          />
        })}
        {oStatus == "overdue" && assigneesNotDone.length > 0 && <Text style={{ paddingLeft: 35, paddingTop: 10, color: oStatus == "overdue" ? "#daa" : "#ddf", fontFamily: "ProximaNovaA-Bold", fontSize: 11 }}
        >{overdueString}</Text>}
        {needsToMarkDone && <View>
          {!this.state.activityIndicatorAnimating ? <View style={{ marginTop: 35 }}>
            <TouchableOpacity
              onPress={this.callMarkTaskDone}
            >
              <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: 180, alignSelf: "center", alignItems: "center", marginTop: 5 }]} >
                <Text style={{ fontSize: !this.state.justVoted ? 20 : 14, fontWeight: "bold", color: "#000" }}>{!this.state.justMarkedTaskDone ? "✓  Mark Done" : "Updating ..."}</Text>
              </View>
            </TouchableOpacity>
          </View> :
            <ActivityIndicator size="large" color="#A8CF45" style={{ alignSelf: "center" }} animating={true}
            />}
        </View>}
      </View >)
  }


  showAll = () => {
    this.setState({ showAllTrue: !this.state.showAllTrue })
  }
  renderBottom = x => {
    let numberOfLineBreaks = x.groupTitle.split("\n").length - 1

    return (
      <View
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row"
        }}
      >
        {x.aOpenness !== "public" && <View
          style={{
            marginRight: 6, justifyContent: "flex-start", alignItems: "center",
            flexDirection: "row", minWidth: 45
          }}
        >
          <Image
            style={{ height: 16, width: 20, marginRight: 4, tintColor: "#889" }}
            source={require("../../images/icons/People.png")}
          />
          <Text style={{ fontSize: 10, color: "#889", fontWeight: "500" }}>{x.nOfJoiners || 0}</Text>
          {Array.isArray(x.xJoinerIDs) && <View style={{ marginLeft: 5, width: "75%", height: 28 }}>
            <ScrollView horizontal>
              {x.xJoinerIDs.map(jID => {
                return <Member profileID={jID} faceOnly key={jID} many={x.xJoinerIDs.length > 6}
                  navigation={this.props.navigation} />
              })}
            </ScrollView>
          </View>}
        </View>}
        <View
          style={{
            marginRight: 6,
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
            minWidth: 45
          }}
        >
          <Image
            style={{ height: 16, width: 17, marginRight: 4, tintColor: "#889" }}
            source={require("../../images/icons/Chat.png")}
          />
          <Text style={{ fontSize: 10, color: "#889", fontWeight: "500" }}>
            {x.nOfComments || 0}
          </Text>
        </View>
        {
          (x.groupTitle.length > 105 || numberOfLineBreaks > 1 || x.groupDetails.length > 43) && <View>
            <TouchableOpacity onPress={() => { this.showAll() }}>
              <Text style={{ color: '#889' }}>{this.state.showAllTrue ? 'Collapse' : 'Show all'}</Text>
            </TouchableOpacity>
          </View>
        }
        {/* <View style={{ flex: 1 }} /> */}

        {/* <View
        style={{
          marginRight: 6, justifyContent: "flex-start", alignItems: "center",
          flexDirection: "row", minWidth: 45
        }}
      >
        <Image
          style={{ height: 16, width: 17, marginRight: 4, tintColor: "#889" }}
          source={require("../../images/icons/Heart.png")}
        />
        <Text style={{ fontSize: 10, color: "#889", fontWeight: "500" }}>{x.nOfTotalLikes || 0}</Text>
      </View> */}
        {/* <View style={{ flex: 1 }} /> */}
        {/* {!this.state.pressedJoin && !this.props.amJoiner ? (
          <View
            style={{
              marginRight: 6,
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "row"
            }}
          >
            <TouchableOpacity
              style={global.gStyles.buttonX}
              onPress={() => {
                this.props.join(true);
              }}
            >
              <Text style={[global.gStyles.buttontX, { paddingHorizontal: 15 }]}>
                + JOIN
              </Text>
            </TouchableOpacity>
          </View>
        ) : this.props.view !== "LIST" ? (
          <View
            style={{
              marginRight: 6,
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "row"
            }}
          >
            <TouchableOpacity
              style={global.gStyles.buttonXW}
              onPress={() => {
                this.props.join(false);
              }}
            >
              <Text style={[global.gStyles.buttontXW, { paddingHorizontal: 15 }]}>
                LEAVE
              </Text>
            </TouchableOpacity>
          </View>
        ) : (
              <View style={{ marginRight: 6, width: 30, height: 20 }} />
            )} */}
      </View >
    );
  };

  renderImageAsBackground = (x) => {
    return (<ImageBackground
      style={{
        position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: -1, opacity: !!x.groupYoutubeID ? 0.3 : 0.4

      }}
      source={{ uri: x.groupPictureURL }}
      key={x.groupPictureURL}
    />)
  }

  render() {
    const x = this.props.chatGroup;
    return (
      <View
        style={{
          paddingHorizontal: 10,
          paddingVertical: 8,
          borderBottomWidth: 0.5,
          borderBottomColor: "#889",
          ...(this.props.backgroundColor && { backgroundColor: this.props.backgroundColor, borderTopLeftRadius: 12, borderTopRightRadius: 12 })
        }}
      >
        {this.renderContextMenu(x)}
        {/* {this.renderTop(x)} */}
        {/* <View style={{ height: 15 }} /> */}
        {x.aType == "poll" ? this.renderMainAsPoll(x) : x.aType == "event" ? this.renderMainAsEvent(x) : x.aType == "task" ? this.renderMainAsTask(x) : this.renderMain(x)}
        <View style={{ height: 15 }} />
        {(!["In45VOmKmFb23miG65GA", "UJcndGGQbpj6qMGC3nuX", "hDnIoiiWEVnMYVzxJGi3"].includes(x.xID))
          && this.renderBottom(x)}
        {/* // condition is for innovationKitchen, can be deleted later */}
        {(x.aType == "event" || x.aType == "task") && x.groupPictureURL && this.renderImageAsBackground(x)}

        <FullscreenImage on={this.state.showFullscreenImage} pictureURL={x.groupPictureURL}
          cached={this.state.fullscreenImageIsCached}
          onClose={() => { this.setState({ showFullscreenImage: false }) }} />

        {!!this.state.watchingYoutube && global.renderYoutubeModal(x.groupYoutubeID,
          () => { this.setState({ watchingYoutube: false }) })}

      </View>
    );
  }
}

class Member extends Component {
  state = {
    pictureURL: "", name: "", businessName: ""
  }

  async componentDidMount() {
    try {
      const profileDoc = await firebase
        .firestore()
        .collection("Profiles")
        .doc(this.props.profileID)
        .get()
      const profile = profileDoc.data()
      this.setState({
        pictureURL: profile.profilePictureURL || "",
        name: profile.profileName || "- No Name -",
        businessName: profile.profileNameBusiness || ""
      })
    } catch (e) {
      global.warn(e, "cdm_Member")
    }
  }

  renderCircle = filled => {
    if (filled) {
      return (
        <View style={{ width: 20, height: 20, borderColor: "#9999a4", backgroundColor: "#334", borderWidth: 0, borderRadius: 10 }}>
          <Text style={{ fontSize: 24, marginTop: -5, color: this.props.color || "#ccd" }}>✓</Text>
        </View>
      );
    } else {
      return (
        <View style={{ width: 20, height: 20, borderColor: "#ccd", borderWidth: 0.7, borderRadius: 10 }} />
      );
    }
  };

  render() {
    if (this.props.faceOnly) return (
      <TouchableOpacity onPress={() => { global.navigateProfile(this.props.navigation, this.props.profileID) }}>
        <View style={{ height: 28, width: this.props.many ? 22 : 35, alignItems: "center", justifyContent: "center" }}>
          {this.state.pictureURL ? <Image
            style={{ width: 25, height: 25, borderRadius: 12.5, opacity: 0.65 }}
            source={{ uri: this.state.pictureURL }}
          /> : <View style={{
            width: 25, height: 25, borderRadius: 12.5, backgroundColor: "#668"
          }} />}
        </View>
      </TouchableOpacity>
    )
    return (
      <View style={{ height: 35, flexDirection: "row", alignItems: "center", marginLeft: 10, alignSelf: "stretch" }}>
        <View style={{ width: 33, justifyContent: "center" }}>
          {this.renderCircle(this.props.selected)}
        </View>
        <View style={{ width: 33, justifyContent: "center" }}>
          {this.state.pictureURL ? <Image
            style={{ width: 25, height: 25, borderRadius: 12.5, }}
            source={{ uri: this.state.pictureURL }}
          /> : <View style={{
            width: 25, height: 25, borderRadius: 12.5, backgroundColor: "#668"
          }} />}
        </View>
        <View style={{ flex: 1 }}>
          <Text style={{ color: "#aab", fontSize: 13 }}>{this.state.name}</Text>
          <Text style={{ color: "#889", fontSize: 10 }}>{this.state.businessName}</Text>
        </View>
      </View>)
  }
}

class Assignee extends Component {
  state = {
    pictureURL: "", name: "", businessName: ""
  }

  async componentDidMount() {
    try {
      const profileDoc = await firebase
        .firestore()
        .collection("Profiles")
        .doc(this.props.profileID)
        .get()
      const profile = profileDoc.data()
      this.setState({
        pictureURL: profile.profilePictureURL || "",
        name: profile.profileName || "- No Name -",
        businessName: profile.profileNameBusiness || ""
      })
    } catch (e) {
      global.warn(e, "cdm_Assignee")
    }
  }

  render() {
    let colorCode = "#ada"
    if (this.props.status == "overdue") colorCode = "#daa"
    if (this.props.status == "open") colorCode = "#ddf"
    return (
      <TouchableOpacity style={{ height: 32, flexDirection: "row", alignItems: "center", marginLeft: 25, alignSelf: "stretch" }}
        onPress={() => { global.navigateProfile(this.props.navigation, this.props.profileID) }} >

        <View style={{ width: 25, paddingLeft: 5, justifyContent: "center" }}>
          {this.props.status == "completed" &&
            <Text style={{ color: "#9f9", fontSize: 16, fontWeight: "bold" }}>✓</Text>}
          {/* {this.props.status == "overdue" &&
            <Text style={{ color: "#f99", fontSize: 18 }}>⚡</Text>} */}
          {/* {this.props.status == "overdue" &&
            <View style={{
              height: 18, width: 18, borderRadius: 9, borderWidth: 2, borderColor: "#f99",
              backgroundColor: "rgba(256,122,122,0.5)", marginLeft: 3
            }} />} */}
        </View>
        <View style={{ width: 33, justifyContent: "center" }}>
          <View style={{
            width: 29, height: 29, borderRadius: 15, backgroundColor: colorCode, justifyContent: "center", alignItems: "center",
          }}>
            {this.state.pictureURL ? <Image
              style={{ width: 28, height: 28, borderRadius: 14, }}
              source={{ uri: this.state.pictureURL }}
            /> : <View style={{
              width: 28, height: 28, borderRadius: 14, backgroundColor: "#668"
            }} />}
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={{ color: colorCode, fontFamily: "ProximaNovaA-Bold", fontSize: 13 }}>{this.state.name} {false && this.props.status == "overdue" && "(overdue)"}</Text>
        </View>
      </TouchableOpacity>)
  }
}

class CheckboxSelector extends PureComponent {
  render() {
    let height =
      this.props.text.length < 45
        ? 30
        : 45 + (this.props.text.length - 45) * 0.33;
    let circle = filled => {
      if (filled) {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#9999a4", backgroundColor: "#334", borderWidth: 0, borderRadius: 10 }}>
            <Text style={{ fontSize: 24, marginTop: -5, color: this.props.color || "#ccd" }}>✓</Text>
          </View>
        );
      } else {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#ccd", borderWidth: 0.7, borderRadius: 10 }} />
        );
      }
    };

    return (
      <View style={{ height: height, flexDirection: "row", alignItems: "center" }}>
        {circle(this.props.selected)}
        <Text style={{ marginLeft: 10, color: this.props.color || "#ccd", fontWeight: this.props.selected ? "normal" : "normal" }}>{this.props.text}</Text>
      </View>)
  }
}
import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  Alert,
  Dimensions,
} from "react-native";
import data from "../imageData";
import * as Font from "expo-font";
import { LinearGradient } from "expo-linear-gradient";
import { Feather } from "@expo/vector-icons";
import { SvgCss } from "react-native-svg";
import firebase from "firebase";
import "firebase/firestore";
import CachedImage from "../../../shared/CachedImage";
// import CachedImage from "../../shared/CachedImage";

// import data from "./imageData";
// import ProductPreview from "./shared/ProductPreview";
// import CommunityPreview from "./shared/CommunityPreview";
// import ShopPreview from "./shared/ShopPreview";

const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;

const HandymanDetails = {
  Plumbing: {
    image:
      "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/MorePhotos%2Fplumbings.jpg?alt=media&token=2e4a9183-ea78-461e-a9af-ff66d75003aa",
  },

  Electrician: {
    image:
      "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/MorePhotos%2Felectrician.jpg?alt=media&token=ac14b6bc-6a3d-4051-a1e5-9d226d3be351",
  },

  AC: {
    image:
      "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/MorePhotos%2Fair-conditioner.jpg?alt=media&token=7d970466-c06f-4774-9b98-1531f63972ab",
  },

  Generator: {
    image:
      "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/MorePhotos%2FgeneratingSet.jpg?alt=media&token=824902bc-96f9-42b9-9e3f-09f9e7e382a1",
  },

  Appliances: {
    image:
      "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/MorePhotos%2Fappliances.jpg?alt=media&token=17c1aa8f-0cff-4284-98f0-9c78cbd367db",
  },

  Painting: {
    image:
      "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/MorePhotos%2Fpainter.jpg?alt=media&token=2b17c02f-afbc-48f3-9838-85daabb77eec",
  },
  Fumigation: {
    image:
      "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/MorePhotos%2Ffumigation.jpg?alt=media&token=d8d82875-fb52-4957-b158-b7918faf4169",
  },

  "Doors, Knobs, Locks": {
    image:
      "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/MorePhotos%2Fdoor-handle.jpg?alt=media&token=bd36ce56-3d52-4d7a-ba74-d64fb55b31e2",
  },

  "Curtains, Blinds": {
    image:
      "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/MorePhotos%2Fbedroomcurtains.jpg?alt=media&token=a92435fc-27fd-434e-a442-f291920c6278",
  },
  Flooring: {
    image:
      "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/MorePhotos%2Fflooring.jpg?alt=media&token=0f7086fe-5b06-4bf8-8987-a112cf74ac38",
  },
  "Walls & Ceiling": {
    image:
      "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/MorePhotos%2Fwallsandceiling.jpg?alt=media&token=d58e930f-1e8d-42a2-8bfb-d417f30d0805",
  },
  Gardening: {
    image:
      "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/MorePhotos%2Fgardening.jpg?alt=media&token=6f817666-058c-4d13-9ef7-5ce0e3cb7f0c",
  },
  "Furniture Assembly": {
    image:
      "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/MorePhotos%2Ffuniture.jpg?alt=media&token=345c5a95-0610-45f8-b15f-29427e664db0",
  },
  "General Work": {
    image:
      "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/MorePhotos%2Fcraftsmen.jpg?alt=media&token=377e98d1-2af8-4b0f-a4d4-d820dc66ee2e",
  },
};

export default class ServicesDetailScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    const category2 = navigation.getParam("params").category1;
    const hideTitle = navigation.getParam("hideTitle", false);
    console.log("params from option", category2);
    return {
      tabBarVisible: false,
      headerTransparent: true,
      title: hideTitle ? "" : category2,
      headerTitleStyle: {
        fontWeight: "bold",
        color: "#333333",
        shadowOpacity: 0.1,
      },
    };
  };
  constructor(props) {
    super(props);
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => {
      return;
    };
  }
  state = {
    assetsLoaded: false,
    category1: [],
    category2: null,
    showMore: false,
  };

  async componentDidMount() {
    console.log("handymandetails", HandymanDetails["Plumbing"].image);
    // console.log("global.categories", global.categories);
    let getServicesParams = this.props.navigation.getParam("params");
    console.log(
      "background color",
      this.props.navigation.getParam("params").gradientColor
    );
    let serviecsTitle = getServicesParams.category1;
    console.log("category serces 1", serviecsTitle);
    category1 = global.categories[serviecsTitle];
    this.setState({ category1 });

    await Font.loadAsync({
      "ProximaNovaA-Regular": require("../../../../assets/font/ProximaNovaA-Regular.ttf"),
      "ProximaNovaA-Bold": require("../../../../assets/font/ProximaNovaA-Bold.ttf"),
    });
    this.setState({ assetsLoaded: true });

    try {
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 200);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 400);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 600);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 1000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 2000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 4000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 5000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 8000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 10000);
        });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 10000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 100000);
        });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID) return;
    } catch (err) {
      if (global.reportError) {
        global.reportError(err, "catches", "homeServices");
      }
    }
  }
  toggleCategory = () => {
    this.setState({
      showMore: !this.state.showMore,
    });
    console.log("toggle button handler: " + this.state.showCategory);
  };

  componentWillUnmount() {
    console.log("Component ServicesDetailScreen will unmount");
    this.unsubscribeFirestore();
  }

  render() {
    let category1Array = this.state.category1;
    // console.log("new category state", category1Array);
    let newCategory1 = [];
    let restCategory = [];
    if (category1Array.length > 12) {
      if (!this.state.showMore) {
        newCategory1 = category1Array.slice(0, 12);
      } else {
        newCategory1 = category1Array;
      }
    } else {
      newCategory1 = category1Array;
    }

    // console.log("new category rest  state", restCategory);
    return (
      <ScrollView
        onScroll={(e) => {
          try {
            // if (e.nativeEvent.contentOffset.y >= 55)
            //   this.props.navigation.setParams({ hideTitle: true });
            // else this.props.navigation.setParams({ hideTitle: false });
          } catch (e) {
            console.warn("caught setParam/onScroll error");
            console.log("onScroll error", e);
          }
        }}
        scrollEventThrottle={1000}
      >
        <LinearGradient
          colors={[
            this.props.navigation.getParam("params").gradientColor,
            this.props.navigation.getParam("params").gradientColor,
            "#F5F5F5",
            "#F5F5F5",
            "#F5F5F5",
            "#F5F5F5",
            "#F5F5F5",
            "#F5F5F5",
            "#F5F5F5",
            "#F5F5F5",
          ]}
          style={{
            flex: 1,
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            height: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
        <StatusBar barStyle="light-content" />
        <View
          style={{
            backgroundColor: "F5F5F5",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              backgroundColor: "F5F5F5",
              borderBottomColor: "#555",
              alignItems: "center",
              justifyContent: "center",

              borderBottomWidth: 1,
              ...Platform.select({
                ios: {
                  height: Dimensions.get("window").height >= 800 ? 88 : 65,
                },
                android: { height: 85 },
              }),
            }}
          />

          <View
            style={{
              width: 340,
              justifyContent: "center",
              marginHorizontal: 10,
              // marginTop: 10
            }}
          >
            {this.state.category1 != null && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginLeft: 12,
                  flexWrap: "wrap",
                  // bottom: 50,
                  alignItems: "center",
                }}
              >
                {newCategory1.map((item) => {
                  return (
                    <TouchableOpacity
                      key={item}
                      style={{
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: 25,
                      }}
                      onPress={() => {
                        this.props.navigation.push("servicesPreview", {
                          params: {
                            category2: { item },
                            image:
                              this.props.navigation.getParam("params")
                                .category1 === "Handyman"
                                ? HandymanDetails[item].image
                                : data.images[item],
                            category1: this.props.navigation.getParam("params")
                              .category1,
                          },
                        });
                        console.log("items", data.images[item]);
                        global.reportStats("pressed_services_Category2", {
                          item,
                        });
                      }}
                    >
                      <View style={{ paddingTop: 7, marginTop: 7 }}>
                        {/* {
                          (console.log("inside render", item),
                          console.log(
                            " state inside render",
                            this.state.category1
                          ))
                        } */}
                        {this.props.navigation.getParam("params").category1 ===
                        "Handyman" ? (
                          <CachedImage
                            style={{
                              width: 70,
                              height: 70,
                              borderRadius: 12,
                              borderColor: "black",
                              borderWidth: 0.05,
                              shadowColor: "#000",
                              shadowOpacity: 0.3,
                            }}
                            source={{ uri: HandymanDetails[item].image }}
                            resizeMode="cover"
                          />
                        ) : (
                          <CachedImage
                            style={{
                              width: 70,
                              height: 70,
                              borderRadius: 12,
                              borderColor: "black",
                              borderWidth: 0.05,
                              shadowColor: "#000",
                              shadowOpacity: 0.3,
                            }}
                            source={{ uri: data.images[item] }}
                            resizeMode="cover"
                          />
                        )}
                        {/* <CachedImage
                          style={{
                            width: 70,
                            height: 70,
                            borderRadius: 12,
                            borderColor: "black",
                            borderWidth: 0.05,
                            shadowColor: "#000",
                            shadowOpacity: 0.3,
                          }}
                          source={{ uri: data.images[item] }}
                          resizeMode="cover"
                        /> */}
                        {/* <Image
                          style={{ width: 70, height: 70 }}
                          source={require("../../../images/handyman/Walls.png")}
                          resizeMode="cover"
                        /> */}
                      </View>

                      <View
                        style={{
                          width: 109,
                          alignItems: "center",
                          // backgroundColor: "black",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 12,
                            fontFamily: "ProximaNovaA-Regular",
                            color: "#333333",
                            marginBottom: 12,
                            top: 10,
                            position: "absolute",
                          }}
                        >
                          {item}
                        </Text>
                      </View>
                    </TouchableOpacity>
                  );
                })}

                <View
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    // backgroundColor: "red",
                    top: 0,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      this.toggleCategory();
                    }}
                  >
                    {!this.state.showMore ? (
                      <Text
                        style={{
                          fontSize: 12,
                          fontFamily: "ProximaNovaA-Bold",
                          color: "#4160E0",
                          margin: 10,
                          padding: 10,
                        }}
                      >
                        See More
                      </Text>
                    ) : (
                      <Text
                        style={{
                          fontSize: 12,
                          fontFamily: "ProximaNovaA-Bold",
                          color: "#4160E0",
                          margin: 10,
                          padding: 10,
                        }}
                      >
                        See less
                      </Text>
                    )}
                  </TouchableOpacity>
                </View>
              </View>
            )}
          </View>
        </View>
      </ScrollView>
    );
  }
}

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  Linking,
  Platform,
  Dimensions,
  StyleSheet,
  Image,
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import BottomMenu from "../../../../shared/BottomMenu"
import Post from "../../../../Home/Post/Post"

const sWidth = Dimensions.get("window").width
const leftWidth = sWidth / 2.8
const rightWidth = sWidth / 8.4


export default class DashboardScreen extends Component {
  static navigationOptions = {
    headerTransparent: false,
    title: "Dashboard",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45"
    },
    headerStyle: {
      backgroundColor: "#131313"
      //height: 15,
    }
  };

  state = {
    //V1
    data: { Loading: "Please wait..." },

    //V2
    showV1: false,
    showHomes: true,
    showFashion: true,
    showElectronics: true,
    showHealth: true,
    showFood: true,
    showJobs: true,
    showSeller: true,
    showProfiles: true,
    showPromos: true,
    showOffers: true,
    showSearchTerms: false,
    // showHomes: true,
    // showFashion: false,
    // showElectronics: false,
    // showHealth: false,
    // showFood: false,
    // showJobs: false,
    // showProfiles: false,
    // showPromos: false,
    // showOffers: false,
    platform: "All",
    country: "All",
    interval: "Weekly",
    nOfIntervals: 4,
    showCountryMenu: false,
    showPlatformMenu: false,
    showIntervalMenu: false,
  };

  async componentDidMount() {
    global.fetchIdToken()
  }

  loadV1 = async () => {
    try {

      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "getStats", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();

      if (r2.msg === "SUCCESS") {
        this.setState({ data: r2.data })
      } else {
        this.setState({ data: { Error: "Backend Error." } })
      }
    } catch (err) {
      this.setState({ data: { Error: "Ensure internet connection and try reloading this screen" } })
      console.log(err);
    }
  }
  getV2Data = async (event, detail1, detail2, getDataPoints, isTotal, isWebsite) => {
    try {

      const r = await fetch(global.cloudFunctionURL + "getStats", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          version: "V2",
          intervalDays: isTotal ? 99999 : this.state.interval == "Weekly" ? 7 : this.state.interval == "Monthly" ? 30 : 1,
          nOfIntervals: isTotal ? 1 : this.state.nOfIntervals,
          type: "event",
          event,
          ...(detail1 && { detail1 }),
          ...(detail2 && { detail2 }),
          ...(getDataPoints && { getDataPoints }),
          ...(this.state.platform != "All" && { platform: this.state.platform.toLowerCase() }),
          ...(this.state.country != "All" && { countryCurrency: this.state.country == "GH" ? "GHS" : "NGN" }),
          getWebEvents: isWebsite,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });

      const r2 = await r.json();

      if (r2.msg === "SUCCESS") {
        return r2
      } else {
        return false
      }
    } catch (err) {
      alert("Error")
      return false;
    }
  }

  loadV2 = async () => {
    const showHomes = this.state.showHomes
    const showPromos = this.state.showPromos
    const showProfiles = this.state.showProfiles
    const showOffers = this.state.showOffers

    await this.setState({ showHomes: false, showProfiles: false, showPromos: false, showOffers: false })
    await global.timeout(100)
    await this.setState({ showHomes, showProfiles, showPromos, showOffers })
  }


  componentWillUnmount() {
    //this.unsubscribeFirestore();
  }



  renderV1 = () => {

    return (
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#244",
          paddingTop: 15,
          marginTop: 15,
          borderTopColor: "#bbbbc5",
          borderTopWidth: 3
        }}
      >
        <Text selectable={true} style={{ color: "#bbbbc5" }}>{JSON.stringify(this.state.data, null, '....')}</Text>
      </View >
    );
  }

  renderFilters = () => {
    return (
      <View style={{ height: 50, width: "100%", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
        <TouchableOpacity onPress={() => { this.setState({ showIntervalMenu: true }) }}
          style={{ width: 110, borderWidth: 1, height: 25, borderRadius: 13, borderColor: "#bcc", alignItems: "center", justifyContent: "center", marginRight: 10 }}>
          <Text style={{ color: "#bcc", fontSize: 12 }}>{this.state.interval || ""}</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => { this.setState({ showPlatformMenu: true }) }}
          style={{ width: 110, borderWidth: 1, height: 25, borderRadius: 13, borderColor: "#bcc", alignItems: "center", justifyContent: "center", marginRight: 10 }}>
          <Text style={{ color: "#bcc", fontSize: 12 }}>Platform: {this.state.platform || ""}</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => { this.setState({ showCountryMenu: true }) }}
          style={{ width: 110, borderWidth: 1, height: 25, borderRadius: 13, borderColor: "#bcc", alignItems: "center", justifyContent: "center", marginRight: 10 }}>
          <Text style={{ color: "#bcc", fontSize: 12 }}>Country: {this.state.country || ""}</Text>
        </TouchableOpacity>
        <BottomMenu items={["All", "NG+", "GH"]}
          visible={this.state.showCountryMenu}
          onSelectItem={async (item) => { await this.setState({ country: item }); this.loadV2() }}
          onClose={() => { this.setState({ showCountryMenu: false }) }}
          title={"Select country"}
        />
        <BottomMenu items={["All", "iOS", "android"]}
          //itemDescriptions={["Your Inbox. Customers you should work on", "All snoozed customers", "Done customers only"]}
          visible={this.state.showPlatformMenu}
          onSelectItem={async (item) => { await this.setState({ platform: item }); this.loadV2() }}
          onClose={() => { this.setState({ showPlatformMenu: false }) }}
          title={""}
        />
        <BottomMenu items={["Monthly", "Weekly", "Daily"]}
          //itemDescriptions={["Your Inbox. Customers you should work on", "All snoozed customers", "Done customers only"]}
          visible={this.state.showIntervalMenu}
          onSelectItem={async (item) => { await this.setState({ interval: item }); this.loadV2() }}
          onClose={() => { this.setState({ showIntervalMenu: false }) }}
          title={""}
        />
      </View>
    )
  }
  renderHomes = () => {
    return (
      <View style={{ backgroundColor: "#244" }}>
        <HeaderRow myThis={this} title={"Onboarding" + ((this.state.country == "All") ? "" : "🇳🇬🇬🇭")} />
        <HomeRow event="ˆStuck@Start" myThis={this} />
        <HomeRow event="ˆStuck@EnteredPhone" myThis={this} />
        <HomeRow event="ˆStuck@Verify" myThis={this} />
        <HomeRow event="ˆSigned In 🎉" myThis={this} />
        <View style={{ height: 15 }} />

        <HeaderRow myThis={this} title={"Home Screen"} />
        <HomeRow event="pressed_Home_HotRightNow" myThis={this} />
        <HomeRow event="pressed_Home_NewAndTrending" myThis={this} />
        <HomeRow event="pressed_Home_HottestEver" myThis={this} />
        <HomeRow event="pressed_Home_FromMyContacts" myThis={this} />
        <HomeRow event="pressed_Home_HotNot" myThis={this} />
        <HomeRow event="pressed_Home_Communities" myThis={this} />
        <HomeRow event="pressed_Home_AroundMe" myThis={this} />
        <TouchableOpacity onPress={() => { this.setState({ showSeller: !this.state.showSeller }) }}>
          <HomeRow event="pressed_Home_Sellers" myThis={this} />
        </TouchableOpacity>
        {this.state.showSeller && <View>
          <HomeRow event="pressed_Loan_SeeMore" isLevel2 myThis={this} />
          <HomeRow event="pressed_Loan_Submit" isLevel2 myThis={this} />
          <HomeRow event="pressed_Loan_PH" isLevel2 myThis={this} />
          <HomeRow event="pressed_SellerWebsite" isLevel2 myThis={this} />
          <HomeRow event="pressed_SellerInvite" isLevel2 myThis={this} />
          <HomeRow event="pressed_SellerAddDiscount" isLevel2 myThis={this} />
          <HomeRow event="pressed_Sellers_BecomeVerifiedPremium" isLevel2 myThis={this} />
          <HomeRow event="pressed_SubscribeSellerNewsletter" isLevel2 myThis={this} />
        </View>}
        <TouchableOpacity onPress={() => { this.setState({ showHealth: !this.state.showHealth }) }}>
          <HomeRow event="pressed_Home_Health" myThis={this} />
        </TouchableOpacity>
        {this.state.showHealth && <View>
          <HomeRow event="pressed_Health_DDBanner1" isLevel2 myThis={this} />
          <HomeRow event="pressed_Health_FindCenter" isLevel2 myThis={this} />
          <HomeRow event="pressed_Health_FindPharmacy" isLevel2 myThis={this} />
          <HomeRow event="pressed_Health_FindLab" isLevel2 myThis={this} />
          <HomeRow event="pressed_Health_FindAmbulanceGhana" isLevel2 myThis={this} />
          <HomeRow event="pressed_Health_SeeForum" isLevel2 myThis={this} />
          <HomeRow event="pressed_Health_GoogleCovid19" isLevel2 myThis={this} />
          <HomeRow event="pressed_Health_GoogleCovid19Map" isLevel2 myThis={this} />
        </View>}
        <TouchableOpacity onPress={() => { this.setState({ showFood: !this.state.showFood }) }}>
          <HomeRow event="pressed_Home_Food" myThis={this} />
        </TouchableOpacity>
        {this.state.showFood && <View>
          <ProfileRow event="pressed_Food_Category2" isLevel2 textInsteadOfProfile myThis={this} />
          <HomeRow event="pressed_Food_SpecialRequest" isLevel2 myThis={this} />
        </View>}
        <TouchableOpacity onPress={() => { this.setState({ showElectronics: !this.state.showElectronics }) }}>
          <HomeRow event="pressed_Home_Electronics" myThis={this} />
        </TouchableOpacity>
        {this.state.showElectronics && <View>
          <ProfileRow event="pressed_Electronics_Category2" isLevel2 textInsteadOfProfile myThis={this} />
          <HomeRow event="pressed_Electronics_SpecialRequest" isLevel2 myThis={this} />
        </View>}
        <TouchableOpacity onPress={() => { this.setState({ showFashion: !this.state.showFashion }) }}>
          <HomeRow event="pressed_Home_Fashion" myThis={this} />
        </TouchableOpacity>
        {this.state.showFashion && <View>
          <ProfileRow event="pressed_Fashion_Category2" isLevel2 textInsteadOfProfile myThis={this} />
          <HomeRow event="pressed_Fashion_SpecialRequest" isLevel2 myThis={this} />
        </View>}
        <TouchableOpacity onPress={() => { this.setState({ showJobs: !this.state.showJobs }) }}>
          <HomeRow event="pressed_Home_Jobs" myThis={this} />
        </TouchableOpacity>
        {this.state.showJobs && <View>
          <HomeRow event="pressed_Jobs_ImAWorker" isLevel2 myThis={this} />
          <HomeRow event="pressed_Jobs_IOfferProfessionalServices" isLevel2 myThis={this} />
          <HomeRow event="pressed_Jobs_FindAWorker" isLevel2 myThis={this} />
          <HomeRow event="pressed_Jobs_FindProfessionalServices" isLevel2 myThis={this} />
          <HomeRow event="pressed_Jobs_QuickJobsBlog" isLevel2 myThis={this} />
          <HomeRow event="pressed_Jobs_QuickJobsSeeMore" isLevel2 myThis={this} />
          <ProfileRow event="pressed_Jobs_Category2" isLevel2 textInsteadOfProfile myThis={this} />
        </View>}
        <ProfileRow event="pressed_Product_SeeAll" textInsteadOfProfile myThis={this} />

      </View>
    );
  }
  renderProfiles = () => {
    return (
      <View style={{ backgroundColor: "#244" }}>
        <HeaderRow myThis={this} title={"Providers"} />
        <ProfileRow event="viewed_Profile" myThis={this} navigation={this.props.navigation} />
        <ProfileRow event="pressed_Call" myThis={this} navigation={this.props.navigation} />
        <ProfileRow event="pressed_Call" myThis={this} isWebsite navigation={this.props.navigation} />
        <ProfileRow event="pressed_Chat" myThis={this} navigation={this.props.navigation} />
        <ProfileRow event="pressed_Order" myThis={this} navigation={this.props.navigation} />
        <ProfileRow event="pressed_Profile_Socials" myThis={this} navigation={this.props.navigation} />
        <ProfileRow event="pressed_Profile_Website" myThis={this} navigation={this.props.navigation} />
        <ProfileRow event="pressed_OrderNow" myThis={this} navigation={this.props.navigation} />
        {/* <ProfileRow event="pressed_OrderNow_Failed1" myThis={this} navigation={this.props.navigation} />
        <ProfileRow event="pressed_OrderNow_Failed2" myThis={this} navigation={this.props.navigation} /> */}
      </View>
    );
  }

  renderPromos = () => {
    return (
      <View style={{ backgroundColor: "#244" }}>
        <HeaderRow myThis={this} title={"Promo Banners"} />
        <BannerRow event="pressed_Electronics_Banner" myThis={this} navigation={this.props.navigation} />
        <BannerRow event="pressed_Fashion_Banner" myThis={this} navigation={this.props.navigation} />
        <BannerRow event="pressed_Food_Banner" myThis={this} navigation={this.props.navigation} />
        <BannerRow event="pressed_Health_Banner" myThis={this} navigation={this.props.navigation} />
        <BannerRow event="pressed_Jobs_Banner" myThis={this} navigation={this.props.navigation} />
      </View>
    );
  }

  renderOffers = () => {
    return (
      <View style={{ backgroundColor: "#244" }}>
        <HeaderRow myThis={this} title={"Offers"} />
        <ProfileRow event="pressed_PostDetail" offerInsteadOfProfile myThis={this} navigation={this.props.navigation} />
      </View>
    );
  }

  renderSearchTerms = () => {
    return (
      <View style={{ backgroundColor: "#244", width: "100%" }}>
        <SearchTerms />
      </View>
    );
  }

  render() {

    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#244"
        }}
      >
        {this.renderFilters()}
        <ScrollView
          style={{
            flex: 1,
            width: "100%",
            paddingHorizontal: 5,
            marginTop: 1
          }}
        >
          <View style={{ alignItems: "center" }}>
            {this.state.showHomes && this.renderHomes()}
            <View style={{ height: 15 }} />
            {this.state.showProfiles ? this.renderProfiles()
              :
              <TouchableOpacity
                onPress={async () => { this.setState({ showProfiles: true }); }}
                style={{ backgroundColor: "#466", borderRadius: 20, padding: 6, width: 175, margin: 15, justifyContent: "center", alignSelf: "flex-start", alignItems: "center" }}
              >
                <Text style={{ color: "#1C1B1B", fontWeight: "bold", fontSize: 14, }}>Show Profile Data</Text>
              </TouchableOpacity>}
            <View style={{ height: 15 }} />

            {this.state.showPromos ? this.renderPromos()
              :
              <TouchableOpacity
                onPress={async () => { this.setState({ showPromos: true }); }}
                style={{ backgroundColor: "#466", borderRadius: 20, padding: 6, width: 175, margin: 15, justifyContent: "center", alignSelf: "flex-start", alignItems: "center" }}
              >
                <Text style={{ color: "#1C1B1B", fontWeight: "bold", fontSize: 14, }}>Show Promo Data</Text>
              </TouchableOpacity>}
            <View style={{ height: 15 }} />

            {this.state.showOffers ? this.renderOffers()
              :
              <TouchableOpacity
                onPress={async () => { this.setState({ showOffers: true }); }}
                style={{ backgroundColor: "#466", borderRadius: 20, padding: 6, width: 175, margin: 15, justifyContent: "center", alignSelf: "flex-start", alignItems: "center" }}
              >
                <Text style={{ color: "#1C1B1B", fontWeight: "bold", fontSize: 14, }}>Show Offers</Text>
              </TouchableOpacity>}
            <View style={{ height: 15 }} />

            {this.state.showSearchTerms ? this.renderSearchTerms()
              :
              <TouchableOpacity
                onPress={async () => { this.setState({ showSearchTerms: true }); }}
                style={{ backgroundColor: "#466", borderRadius: 20, padding: 6, width: 175, margin: 15, justifyContent: "center", alignSelf: "flex-start", alignItems: "center" }}
              >
                <Text style={{ color: "#1C1B1B", fontWeight: "bold", fontSize: 14, }}>Show Search Terms</Text>
              </TouchableOpacity>}
            <View style={{ height: 15 }} />

            {this.state.showV1 ? this.renderV1()
              :
              <TouchableOpacity
                onPress={async () => { this.setState({ showV1: true }); this.loadV1(); }}
                style={{ backgroundColor: "#466", borderRadius: 20, padding: 6, width: 175, margin: 15, justifyContent: "center", alignSelf: "flex-start", alignItems: "center" }}
              >
                <Text style={{ color: "#1C1B1B", fontWeight: "bold", fontSize: 14, }}>Further Data</Text>
              </TouchableOpacity>}
            <View style={{ height: 15 }} />
          </View>
        </ScrollView>
      </View>

    );
  }
}

class ProfileRow extends Component {

  state = {
    numbers: [],
    dataPointArrays: [],
    sortByI: -1,
    detail1s: [],
    loading: true,
    showDetail: false,
    loadingDetail: true,
  }

  componentDidMount = async () => {
    try {
      const myThis = this.props.myThis;

      const wrTotal = myThis.getV2Data(this.props.event, null, null, false, true, this.props.isWebsite)
      const r2 = await myThis.getV2Data(this.props.event, null, null, false, false, this.props.isWebsite)
      const rTotal = await wrTotal
      const numbers = r2.numbers.reverse()
      numbers.push(rTotal.numbers[0])
      this.setState({ numbers, loading: false })
    } catch (e) {
      console.warn("E234e")
      console.log(e)
    }
  }
  loadDetail = async () => {
    try {
      const myThis = this.props.myThis;
      this.setState({ loadingDetail: true })

      const wrTotal = myThis.getV2Data(this.props.event, null, null, true, true, this.props.isWebsite)
      const r2 = await myThis.getV2Data(this.props.event, null, null, true, false, this.props.isWebsite)
      const numbers = r2.numbers.reverse()
      const dataPointArrays = r2.dataPointArrays.reverse()
      const rTotal = await wrTotal
      numbers.push(rTotal.numbers[0])
      dataPointArrays.push(rTotal.dataPointArrays[0])

      const detail1s = []
      r2.dataPointArrays.forEach(dpArray => {
        dpArray.forEach(dp => {
          if (detail1s.indexOf(dp.detail1) === -1) detail1s.push(dp.detail1)
        })
      })
      detail1s.sort((a, b) => {
        const aFilteredArr = dataPointArrays[this.state.sortByI].filter(event => { return event.detail1 == a })
        const bFilteredArr = dataPointArrays[this.state.sortByI].filter(event => { return event.detail1 == b })
        return bFilteredArr.length - aFilteredArr.length
      })
      this.setState({ numbers, detail1s, dataPointArrays, loadingDetail: false })
    } catch (e) {
      console.warn("E234f")
      console.log(e)
    }
  }

  render() {
    let eventText = this.props.event
    if (eventText.startsWith("pressed_")) eventText = eventText.substring(8, 99)
    if (eventText.endsWith("_Banner")) eventText = eventText.substring(0, (eventText.length - 7))
    if (this.props.isWebsite) eventText += "Website"
    const style = this.props.isLevel2 ? styles3 : styles
    return (<View>
      <View style={style.row}>
        <View style={style.rowTitle}><Text style={style.rowText}>{eventText}</Text></View>
        {this.state.loading ? <View style={[style.rowRight, { width: rightWidth * 5, opacity: 0.9 }]}>
          <Text style={style.rowText}>Loading...</Text>
        </View>
          : this.state.numbers.map((number, i) => {
            const isLast = (i == (this.state.numbers.length - 1))
            const isSelected = (i == (this.state.sortByI))
            return <TouchableOpacity key={number + "_" + i}
              onPress={async () => {
                if (isSelected) return this.setState({ showDetail: false, sortByI: -1 })
                else {
                  await this.setState({ showDetail: true, sortByI: i });
                  this.loadDetail()
                }
              }}
              style={[style.rowRight, { ...(isLast && { backgroundColor: "#9aa" }), ...(isSelected && { borderTopLeftRadius: 20, borderTopEndRadius: 20 }) }]}>
              <Text style={[style.rowText, { ...(isLast && { fontWeight: "bold" }) }]}>{number}</Text>
            </TouchableOpacity>
          })}
      </View>
      {
        this.state.showDetail && this.state.loadingDetail && <View style={[styles.row1Right, { width: 390 }]}>
          <Text style={styles.row1Text}>Loading...</Text>
        </View>
      }
      {this.state.showDetail && !this.state.loadingDetail && <View>
        {this.state.detail1s.map(d1 => {
          return (<TouchableOpacity key={d1} style={styles.row1} onPress={() => { this.props.navigation.navigate("meProfile", { profileID: d1 }) }}>
            <View style={styles.row1Title}>{this.props.textInsteadOfProfile ? <Text style={styles.row1Text}>{d1}</Text>
              : this.props.offerInsteadOfProfile ? <OfferLeft postID={d1} />
                : <ProfileLeft profileID={d1} />}</View>
            {this.state.numbers.map((number, i) => {
              const isLast = (i == (this.state.numbers.length - 1))
              const filteredNumber = this.state.dataPointArrays[i].filter((dp) => { return (dp.detail1 == d1) }).length
              return <View key={number + "_" + d1 + "_" + i}
                style={[styles.row1Right, { ...(isLast && { backgroundColor: "#464" }) }]}>
                <Text style={[styles.row1Text, { ...(isLast && { fontWeight: "bold" }) }]}>{filteredNumber}</Text>
              </View>
            })}
          </TouchableOpacity>)
        })
        }</View>}
    </View >)
  }
}

class BannerRow extends Component {

  state = {
    numbers: [],
    dataPointArrays: [],
    sortByI: -1,
    detail2s: [],
    loading: true,
    showDetail: false,
    loadingDetail: true
  }
  profileIDs = {}

  componentDidMount = async () => {
    try {
      const myThis = this.props.myThis;

      const wrTotal = myThis.getV2Data(this.props.event, null, null, false, true)
      const r2 = await myThis.getV2Data(this.props.event, null, null, false)
      const rTotal = await wrTotal
      const numbers = r2.numbers.reverse()
      numbers.push(rTotal.numbers[0])
      this.setState({ numbers, loading: false })
    } catch (e) {
      console.warn("E234e")
      console.log(e)
    }
  }
  loadDetail = async () => {
    try {
      const myThis = this.props.myThis;
      this.setState({ loadingDetail: true })

      const wrTotal = myThis.getV2Data(this.props.event, null, null, true, true)
      const r2 = await myThis.getV2Data(this.props.event, null, null, true)
      const numbers = r2.numbers.reverse()
      const dataPointArrays = r2.dataPointArrays.reverse()
      const rTotal = await wrTotal
      numbers.push(rTotal.numbers[0])
      dataPointArrays.push(rTotal.dataPointArrays[0])

      const detail2s = []
      r2.dataPointArrays.forEach(dpArray => {
        dpArray.forEach(dp => {
          if (detail2s.indexOf(dp.detail2) === -1) {
            detail2s.push(dp.detail2);
            this.profileIDs[dp.detail2] = dp.detail1
          }
        })
      })
      detail2s.sort((a, b) => {
        const aFilteredArr = dataPointArrays[this.state.sortByI].filter(event => { return event.detail2 == a })
        const bFilteredArr = dataPointArrays[this.state.sortByI].filter(event => { return event.detail2 == b })
        return bFilteredArr.length - aFilteredArr.length
      })
      this.setState({ numbers, detail2s, dataPointArrays, loadingDetail: false })
    } catch (e) {
      console.warn("E235f")
      console.log(e)
    }
  }

  render() {
    let eventText = this.props.event
    if (eventText.startsWith("pressed_")) eventText = eventText.substring(8, 99)
    if (eventText.endsWith("_Banner")) eventText = eventText.substring(0, (eventText.length - 7))
    return (<View>
      <View style={styles.row}>
        <View style={styles.rowTitle}><Text style={styles.rowText}>{eventText}</Text></View>
        {this.state.loading ? <View style={[styles.rowRight, { width: rightWidth * 5, opacity: 0.9 }]}>
          <Text style={styles.rowText}>Loading...</Text>
        </View>
          : this.state.numbers.map((number, i) => {
            const isLast = (i == (this.state.numbers.length - 1))
            const isSelected = (i == (this.state.sortByI))
            return <TouchableOpacity key={number + "_" + i}
              onPress={async () => {
                if (isSelected) return this.setState({ showDetail: false, sortByI: -1 })
                else {
                  await this.setState({ showDetail: true, sortByI: i });
                  this.loadDetail()
                }
              }}
              style={[styles.rowRight, { ...(isLast && { backgroundColor: "#9aa" }), ...(isSelected && { borderTopLeftRadius: 20, borderTopEndRadius: 20 }) }]}>
              <Text style={[styles.rowText, { ...(isLast && { fontWeight: "bold" }) }]}>{number}</Text>
            </TouchableOpacity>
          })}
      </View>
      {
        this.state.showDetail && this.state.loadingDetail && <View style={[styles.row1Right, { width: 390 }]}>
          <Text style={styles.row1Text}>Loading...</Text>
        </View>
      }
      {this.state.showDetail && !this.state.loadingDetail && <View>
        {this.state.detail2s.map(d2 => {
          return (<TouchableOpacity key={d2} style={styles.row1} onPress={() => {
            const profileID = this.profileIDs[d2]
            if (profileID.startsWith("http:") || profileID.startsWith("www.") || profileID.startsWith("wakanda"))
              Linking.openURL(profileID)
            else this.props.navigation.navigate("meProfile", { profileID })
          }}>
            <View style={styles.row1Title}>
              <Image style={{ width: 135, height: 35, borderRadius: 2 }} source={{ uri: d2 }} />
            </View>
            {this.state.numbers.map((number, i) => {
              const isLast = (i == (this.state.numbers.length - 1))
              const filteredNumber = this.state.dataPointArrays[i].filter((dp) => { return (dp.detail2 == d2) }).length
              return <View key={number + "_" + d2 + "_" + i}
                style={[styles.row1Right, { ...(isLast && { backgroundColor: "#464" }) }]}>
                <Text style={[styles.row1Text, { ...(isLast && { fontWeight: "bold" }) }]}>{filteredNumber}</Text>
              </View>
            })}
          </TouchableOpacity>)
        })
        }</View>}
    </View >)
  }
}

class ProfileLeft extends Component {

  state = {
    pictureURL: null,
    businessName: ".... ....",
    loading: true,
  }

  async componentDidMount() {
    try {
      const pDoc = await firebase
        .firestore()
        .collection("Profiles")
        .doc(this.props.profileID)
        .get()
      const p = pDoc.data()
      this.setState({ businessName: p.profileNameBusiness || "--", pictureURL: p.profilePictureURL })
    } catch (error) {
      console.warn("error nm,")
      console.log(error)
    }
  }

  render() {
    return (<View style={{ flexDirection: "row", alignItems: "center" }}>
      {this.state.pictureURL ? (
        <Image style={{ width: 30, height: 30, borderRadius: 4, marginRight: 5 }} source={{ uri: this.state.pictureURL }} />)
        : (<View style={{ width: 30, height: 30, borderRadius: 4, backgroundColor: "#233", borderWidth: 0.5, borderColor: "#466", marginRight: 5 }} />
        )}
      <Text style={[styles.row1Text, { fontSize: 10 }]}>{this.state.businessName}</Text>
    </View>)
  }
}

class OfferLeft extends Component {

  state = {
    showPostDetail: false,
    title: "",
    pictureURL: null,

    isLive: true,
    status: "LIVE"
  }

  async componentDidMount() {
    try {
      const pDoc = await firebase
        .firestore()
        .collection("Posts")
        .doc(this.props.postID)
        .get()
      const p = pDoc.data()
      this.setState({ title: p.postTitle || "", pictureURL: p.postPictureURL })
    } catch (error) {
      console.warn("error nm,")
      console.log(error)
    }
  }

  render() {
    return (<View>
      <TouchableOpacity onPress={() => { this.setState({ showPostDetail: true }); }}
        style={{ flexDirection: "row", alignItems: "center" }}>
        {this.state.pictureURL ? (
          <Image style={{ width: 34, height: 34, borderRadius: 4, marginRight: 5, marginLeft: -4 }} source={{ uri: this.state.pictureURL }} />)
          : (<View style={{ width: 34, height: 34, borderRadius: 4, backgroundColor: "#233", borderWidth: 0.5, borderColor: "#466", marginRight: 5, marginLeft: -4 }} />
          )}
        <Text style={[styles.row1Text, { fontSize: 10 }]}>{!this.state.isLive ? this.state.status : ""} {this.state.title}</Text>
      </TouchableOpacity>
      {this.state.showPostDetail && <Post
        postID={this.props.postID}
        navigation={this.props.navigation}
        showDetail={true}
        onHideDetail={() => { this.setState({ showPostDetail: false }); }}
        relevantStatus={"LIVE"}
        onIsNotRelevant={status => { this.setState({ isLive: false, status }); }}
      />}
    </View >)
  }
}

class HomeRow extends Component {

  state = {
    numbers: [],
    loading: true,
  }

  componentDidMount = async () => {
    try {
      const myThis = this.props.myThis;

      const wrTotal = myThis.getV2Data(this.props.event, null, null, false, true)
      const r2 = await myThis.getV2Data(this.props.event, null, null, false)
      const rTotal = await wrTotal
      const numbers = r2.numbers.reverse()
      numbers.push(rTotal.numbers[0])
      this.setState({ numbers, loading: false })
    } catch (e) {
      console.warn("E234g")
      console.log(e)
    }
  }

  render() {
    let eventText = this.props.event
    if (eventText.startsWith("pressed_Home_")) eventText = eventText.substring(13, 99)
    if (eventText.startsWith("pressed_")) eventText = eventText.substring(8, 99)
    const style = this.props.isLevel2 ? styles3 : styles
    return (<View>
      <View style={style.row}>
        <View style={style.rowTitle}><Text style={style.rowText}>{eventText}</Text></View>
        {this.state.loading ? <View style={[style.rowRight, { width: rightWidth * 5, opacity: 0.9 }]}>
          <Text style={style.rowText}>Loading...</Text>
        </View>
          : this.state.numbers.map((number, i) => {
            const isLast = (i == (this.state.numbers.length - 1))
            return <View key={number + "_" + i} style={[style.rowRight, { ...(isLast && { backgroundColor: "#9aa" }) }]}>
              <Text style={[style.rowText, { ...(isLast && { fontWeight: "bold" }) }]}>{number}</Text>
            </View>
          })}
      </View>
    </View>)
  }
}

class HeaderRow extends PureComponent {

  render() {
    const titles = []
    const myState = this.props.myThis.state;
    for (let i = myState.nOfIntervals; i > 0; i--) {
      if (myState.interval == "Monthly") titles.push("-" + i + "m")
      if (myState.interval == "Weekly") titles.push("-" + i + "w")
      if (myState.interval == "Daily") titles.push("-" + i + "d")
    }
    titles.push("Total")
    return (<View style={{ marginBottom: 1.5 }}>
      <View style={styles.row}>
        <View style={[styles.rowTitle, { backgroundColor: "#9aa" }]}><Text style={[styles.rowText, { fontWeight: "bold" }]}>{this.props.title || ""}</Text></View>
        {titles.map((t, i) => {
          const isLast = (i == (titles.length - 1))
          return <View key={t} style={[styles.rowRight, { backgroundColor: isLast ? "#788" : "#9aa" }]}>
            <Text style={[styles.rowText, { fontWeight: "bold" }]}>{t}</Text>
          </View>
        })}
      </View>
    </View>)
  }
}

class SearchTerms extends Component {

  state = {
    searches: {},
    loading: true
  }

  componentDidMount = async () => {
    try {

      const r = await fetch(global.cloudFunctionURL + "getStats", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          version: "V2",
          type: "serverStat",
          serverStat: "searches",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();

      if (r2.msg === "SUCCESS") {
        console.log({ r2 })
        this.setState({ searches: r2.searches, loading: false })
      } else {
        alert("Error....")
        return false
      }
    } catch (err) {
      alert("Error..")
      return false;
    }
  }

  render() {
    return (<View style={{ width: "100%", }}>
      <View style={{ width: "100%", height: 40, justifyContent: "center" }}>
        <Text style={[styles.row1Text, { fontSize: 16, fontWeight: "bold" }]}>Top 100 Searches (since May8 2020)</Text>
      </View>
      {this.state.loading ?
        <View style={{ width: "100%", flexDirection: "row", paddingBottom: 5, paddingTop: 5, alignItems: "center", borderBottomWidth: 0.6, borderColor: "#bcc" }}>
          <Text style={[styles.row1Text, {}]}>Loading...</Text>
        </View>
        : this.state.searches.map(s => {
          return (
            <View key={s.term} style={{ flexDirection: "row", paddingBottom: 5, paddingTop: 5, alignItems: "center", borderBottomWidth: 0.6, borderColor: "#676" }}>
              <Text style={[styles.row1Text, { width: 200 }]}>{s.term}</Text>
              <Text style={[styles.row1Text, {}]}>{s.count}</Text>
            </View>
          )
        })}
    </View >)
  }
}

const styles = ({
  row: { flexDirection: "row", height: 40, alignItems: "center" },
  rowTitle: { width: leftWidth, height: 40, paddingLeft: 4, justifyContent: "center", borderBottomWidth: 1.5, borderColor: "#244", backgroundColor: "#bcc" },
  rowRight: { width: rightWidth, height: 40, borderBottomWidth: 1.5, borderLeftWidth: 1.5, borderColor: "#244", alignItems: "center", justifyContent: "center", backgroundColor: "#bcc" },
  rowText: { color: "#244" },
  row1: { flexDirection: "row", height: 40, alignItems: "center" },
  row1Title: { width: leftWidth, height: 40, paddingLeft: 4, justifyContent: "center", borderBottomWidth: .5, borderColor: "#bcc", backgroundColor: "#244" },
  row1Right: { width: rightWidth, height: 40, borderBottomWidth: .5, borderLeftWidth: .5, borderColor: "#bcc", alignItems: "center", justifyContent: "center", backgroundColor: "#244" },
  row1Text: { color: "#bcc" },
});
const styles3 = {
  row: { flexDirection: "row", height: 40, alignItems: "center" },
  rowTitle: { width: leftWidth, height: 40, paddingLeft: 4, justifyContent: "center", borderBottomWidth: 1.5, borderColor: "#244", backgroundColor: "#cdd" },
  rowRight: { width: rightWidth, height: 40, borderBottomWidth: 1.5, borderLeftWidth: 1.5, borderColor: "#244", alignItems: "center", justifyContent: "center", backgroundColor: "#cdd" },
  rowText: { color: "#244" },
};

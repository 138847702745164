import React, { Component } from 'react'
import { Text, View, StyleSheet, TouchableOpacity, ActivityIndicator, Dimensions, Image } from 'react-native';

export default class JobScreen1 extends Component {
    render() {
        const screenWidth = Math.round(Dimensions.get('window').width);
        return (
            <View>
                <View style={{
                    alignItems: 'center', justifyContent: 'center',
                    width: screenWidth, flex: 3
                }}>
                    <Text style={styles.textHeadline}>
                        Provider
                    </Text>
                    {!!this.props.providerPictureURL ? <Image source={{ uri: this.props.providerPictureURL }}
                        style={{ width: 150, height: 150, borderRadius: 50, marginBottom: 5 }} /> :
                        <View style={{ width: 150, height: 150, borderRadius: 50, backgroundColor: "#131313", marginBottom: 5 }} />}
                    <Text style={styles.textName}>{this.props.providerName}</Text>
                    <Text style={styles.textName2}>{this.props.providerBusinessName}</Text>

                    <View style={{ flexDirection: 'row', paddingVertical: 20, justifyContent: 'center' }}>
                        {!this.props.loading ? <TouchableOpacity onPress={this.props.callCall}>
                            <Text style={styles.text2}>
                                Call
                    </Text>
                        </TouchableOpacity> :
                            <ActivityIndicator size="small" color="#ffff00" animating={true} />
                        }
                        {!this.props.activityIndicatorAnimating ? <TouchableOpacity onPress={this.props.callChat}>
                            <Text style={styles.text2}>
                                Chat
                    </Text>
                        </TouchableOpacity> :
                            <ActivityIndicator size="small" color="#ffff00" animating={true} />
                        }
                    </View>
                    {this.props.showDiscount && <View style={{
                        backgroundColor: '#ace600', padding: 5, borderBottomLeftRadius: 5, borderBottomRightRadius: 5,
                        borderRightWidth: 1, marginVertical: 10, marginTop: -10
                    }}>
                        <Text style={{ color: '#ff4d94', fontWeight: 'bold', fontSize: 12, marginBottom: 0 , textAlign:'center'}}>Available discount</Text>
                        {!!this.props.discountNGN &&<Text style={{ color: 'black', fontSize: 12, fontWeight: 'bold', paddingTop: 0, textAlign:'center' }}>{this.props.discountCurrency}{this.props.discountNGN} from {this.props.providerName}</Text>}
                        {!!this.props.discountWakandaNGN && <Text style={{ color: 'black', fontSize: 12, fontWeight: 'bold', paddingTop: 0, textAlign:'center' }}>
                            {this.props.discountCurrency}{this.props.discountWakandaNGN} from Wakanda
                            </Text>}
                    </View>}
                    <Text style={styles.text}>Contact the provider to ensure availability.</Text>
                    <View style={{ height: 10 }} />
                    <Text style={styles.text}>Then complete the order and benefit from the Wakanda Escrow Service.</Text>
                </View>
                <View style={{ width: screenWidth, flex: 1 }}>
                    <View style={styles.bottom}>
                        <Text style={styles.text3}>
                            To continue with your order, swipe left
                        </Text>
                    </View>
                </View>


            </View>
        )
    }
}


const styles = ({
    textHeadline: {
        color: '#fff',
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        paddingHorizontal: 20,
        marginBottom: 10

    },
    text: {
        color: '#999',
        fontSize: 15,
        textAlign: 'center',
        paddingHorizontal: 30

    },
    text2: {
        color: '#ffff00',
        fontSize: 15,
        fontWeight: 'bold',
        textAlign: 'center',
        paddingHorizontal: 20

    },
    text3: {
        color: 'grey',
        fontSize: 14,
        textAlign: 'center',
        paddingHorizontal: 40,

    },
    textName: {
        color: '#ccc',
        fontSize: 16,
        textAlign: 'center',
        paddingHorizontal: 30,
        fontWeight: 'bold',


    },
    textName2: {
        color: '#ccc',
        fontSize: 12,
        textAlign: 'center',
        paddingHorizontal: 30,
        fontWeight: 'bold',


    },
    bottom: {
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        bottom: -40
    }
})

import React, { Component } from "react";
import { Animated, PanResponder } from "react-native";
import "firebase/firestore";

export default class SwipeableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pan: new Animated.ValueXY(), // inits to zero
    };
    //this.fingerHasMoved=false;
    this.state.panResponder = PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onPanResponderMove: Animated.event([
        null,
        {
          dx: this.state.pan.x, // x,y are Animated.Value
          dy: this.state.pan.y,
        }
      ], { useNativeDriver: false }),
      onPanResponderRelease: async (event, gesture) => {
        if (gesture.dx > 100) {
          Animated.timing(this.state.pan, {
            toValue: { x: 500, y: 0 },
            duration: 250,
            useNativeDriver: false
          }).start();
          this.props.onRelease("right");
          await new Promise(resolve => {
            setTimeout(resolve, 500);
          });
          this.state.pan.setValue({ x: 0, y: 0 });
          // Animated.spring(
          //   this.state.pan, // Auto-multiplexed
          //   { toValue: { x: 110, y: 110 } } // Back to zero
          // ).start();
        } else if (gesture.dx < -100) {
          Animated.timing(this.state.pan, {
            toValue: { x: -500, y: 0 },
            duration: 250,
            useNativeDriver: false
          }).start();
          this.props.onRelease("left");
          await new Promise(resolve => {
            setTimeout(resolve, 500);
          });
          this.state.pan.setValue({ x: 0, y: 0 });
        } else if (gesture.dx < 5 && gesture.dx > -5 && gesture.dy < 5 && gesture.dy > -5) {
          this.props.onClick();
        } else {
          Animated.spring(
            this.state.pan, // Auto-multiplexed
            { useNativeDriver: false, toValue: { x: 0, y: 0 } } // Back to zero
          ).start();
        }
      }
    });
  }
  render() {
    const calculatePostStyle = () => {
      const { pan } = this.state;
      const opacity = pan.x.interpolate({
        inputRange: [-250, 0, 250],
        outputRange: [0, 1, 0]
      });
      const rotate = pan.x.interpolate({
        inputRange: [-500, 0, 500],
        outputRange: ["60deg", "0deg", "-60deg"]
      });
      return {
        ...pan.getLayout(),
        opacity,
        transform: [{ rotate }]
      };
    };
    return (
      <Animated.View
        {...this.state.panResponder.panHandlers}
        style={calculatePostStyle()}
        onLoad={this.onLoad}
      >
        {this.props.children}
      </Animated.View>
    );
  }
}

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  SafeAreaView,
  Image,
  StatusBar,
  TouchableOpacity,
  TextInput,
  Platform,
  ActivityIndicator,
  Alert,
  ScrollView,
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import SetupScreen from "./SetupScreen";
const gStyles = global.gStyles //    "../../styles/gStyles";
import Constants from 'expo-constants';


export default class MeWebsiteManagementScreen extends Component {
  static navigationOptions = {
    title: "Website Management",
    headerTransparent: true,
    headerTintColor: "#A8CF45",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };

  state = {
    screen: "loading",// "select" // "manage" // TOBEDONE: "upForRenewal" // "outdated"
    searchTerm: global.myBusinessName ? global.myBusinessName.replace(/[^a-zA-Z0-9]/g, "") || "" : "",
    results: [],
    plan: null, // "classic" or "premium" or "education"
    selectedTLD: null, // for premium: once you select a tld, it will be set here 
    shopID: null,
    oldShopID: null,
    oldShop: null,
    oldShopLoaded: false,
    oldShopOutdated: true,
    isForTrial: false
  }

  constructor() {
    super();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 800); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 800); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 1800); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 2800); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 4800); });
    if (!global.myUID || !global.myProfileID) return;
    if (!global.myBusinessName) {
      let profileDoc = await firebase
        .firestore()
        .collection("Profiles")
        .doc(global.myProfileID)
        .get();
      let profile = profileDoc.data();
      global.myName = profile.profileName;
      global.myBusinessName = profile.profileNameBusiness || "";
    }
    if (!global.myBusinessName) {
      this.props.navigation.goBack()
      //global.timeout(100);
      this.props.navigation.navigate("meProfile");
      //global.timeout(100);
      alert("You need to open a shop first. In your profile, press Open a Shop.");
      return
    }

    try {
      //this.callSearch();
      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .onSnapshot(this.updateUser);
    } catch (e) {
      console.log("Error", e)
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  updateUser = async snapshot => {
    const user = snapshot.data()
    if (user.websiteTrialJustSelected && user.websiteTrialJustSelected > 0) {
      this.setState({ screen: "select", plan: "classic", ...(user.idShop && { oldShopID: user.idShop }), isForTrial: true })
      if (user.idShop) this.loadOldShop(user.idShop)
    }
    else if (user.websiteJustPurchased) {
      this.setState({ screen: "select", plan: user.websiteJustPurchased, ...(user.idShop && { oldShopID: user.idShop }) })
      if (user.idShop) this.loadOldShop(user.idShop)
    }
    else if (user.idShop) {
      this.setState({ screen: "manage", shopID: user.idShop })
    }
  };

  loadOldShop = async (oldShopID) => {
    const oldShopDoc = await firebase
      .firestore()
      .collection("Shops")
      .doc(oldShopID)
      .get();
    const oldShop = oldShopDoc.data()
    if (oldShop) this.setState({ oldShop })
    if (oldShop.renewalDate) {
      const renDat = oldShop.renewalDate.toDate()
      const nowDat = new Date()
      if (nowDat < renDat) {
        this.setState({ oldShopOutdated: false })
      }
    }
    this.setState({ oldShopLoaded: true })
  }

  callSearch = async () => {
    if (this.state.activityIndicatorAnimating) return
    try {
      let chatID = null;
      this.setState({ activityIndicatorAnimating: true });
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "findDomains", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          searchTerm: this.state.searchTerm,
          searchType: (this.state.plan == "premium" && !this.state.selectedTLD) ? "TLD" : this.state.plan == "education" ? "WED" : "WMD",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      await this.setState({ activityIndicatorAnimating: false });
      console.log("r2", r2)
      if (r2.msg == "SUCCESS") {
        this.setState({ results: r2.results });
      } else alert("Error. Try again later.")

    } catch (err) {
      alert("Connection error.");
      console.log(err);
      this.setState({ activityIndicatorAnimating: false });
    }
  };

  // allow only letters, numbers or '-'
  setSearchTerm = (searchTerm) => {
    searchTerm = searchTerm.substring(0, 56);
    searchTerm = searchTerm.replace(/[^a-zA-Z0-9-]/g, "");
    this.setState({ searchTerm })
  }

  select = async (domain) => {
    if (this.state.plan == "premium") {
      if (!this.state.selectedTLD) {
        await this.setState({ selectedTLD: domain, results: [] })
        this.callSearch()
      } else {
        this.sendSelection(domain)
      }
    }
    if (this.state.plan == "classic" || this.state.plan == "education") {
      this.sendSelection(domain)

    }
  }

  sendSelection = async (domain) => {
    Alert.alert(
      this.state.plan != "premium" ? "Select this domain?" : "Select these domains?",
      this.state.isForTrial ? "" : "Your selection will be permanent.",
      [
        {
          text: "Cancel",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel"
        },
        {
          text: "Select",
          onPress: () => {
            this.sendSelectionExecution(domain);
            this.setState({ screen: "loading" })
          }
        }
      ],
      { cancelable: true }
    );
  }

  sendSelectionExecution = async (domain) => {
    if (this.state.activityIndicatorAnimating) return

    if (!global.myBusinessName) {
      try {
        let profileDoc = await firebase
          .firestore()
          .collection("Profiles")
          .doc(global.myProfileID)
          .get();
        let profile = profileDoc.data();
        global.myName = profile.profileName;
        global.myBusinessName = profile.profileNameBusiness || "";
      } catch (err) {
        console.log(err)
        return;
      }
    }
    if (!global.myBusinessName) {
      this.props.navigation.navigate("meProfile");
      //global.timeout(100);
      alert("You need to open a shop first. In your profile, press Open a Shop.");
      return
    }

    let appVersion = "";
    if (Constants && Constants.manifest && Constants.manifest.version) {
      appVersion = Constants.manifest.version;
    }
    try {
      if (this.state.activityIndicatorAnimating) return
      this.setState({ activityIndicatorAnimating: true });
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "createShop", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          WMD: domain,
          ...((this.state.plan == "premium" && this.state.selectedTLD) && { TLD: this.state.selectedTLD }),
          plan: this.state.plan,
          app: global.app,
          appVersion,
          standardWhiteAvailable: true,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      await this.setState({ activityIndicatorAnimating: false });
      console.log("r2", r2)
      if (r2.msg == "SUCCESS") {
        alert("Success: Domain set!")
      } else alert("Unfortunately, your website could not be created at this time. Try again later.")

    } catch (err) {
      alert("Connection error.");
      console.log(err);
      this.setState({ activityIndicatorAnimating: false });
    }
  }

  renderSelectWMD = () => {
    let selectedTLD = this.state.selectedTLD
    if (selectedTLD.startsWith("$EXISTING")) selectedTLD = selectedTLD.substring(9, 99)
    return (<View
      style={{ alignSelf: "center", height: 120, width: "100%", backgroundColor: "#222", borderRadius: 5, margin: 10, borderWidth: 1, borderColor: "#ccc", padding: 5, justifyContent: "space-between" }}
    >
      <View style={{ width: "100%" }}>
        <Text style={{ color: "white", fontSize: 12 }}>YOUR DOMAIN</Text>
        <Text style={{ color: "white", fontSize: 16 }}>✅ {selectedTLD}</Text>
      </View>
      <View style={{ width: "100%" }}>
        <Text style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>In addition to your premium domain, you will also get a wakanda domain. Please choose below</Text>
      </View>
    </View>)
  }

  renderSearchBar = () => {
    return (<View
      style={{ flexDirection: "row", alignSelf: "center", height: 40, width: "100%", backgroundColor: "#334", borderRadius: 20, borderBottomWidth: 0, }}
    >
      <TextInput
        style={{ alignSelf: "stretch", height: 40, marginBottom: 20, color: "#FFFFFF", backgroundColor: "#334", borderRadius: 33, borderBottomWidth: 0, paddingLeft: 10, flex: 1 }}
        placeholderTextColor="#fff"
        underlineColorAndroid="transparent"
        underlineColor={"transparent"}
        returnKeyType="search"
        onChangeText={searchTerm => this.setSearchTerm(searchTerm)}
        onSubmitEditing={this.callSearch}
        ref={component => (this.myTextInput = component)}
        value={this.state.searchTerm}
        keyboardAppearance={"dark"}
        autoCompleteType={'off'}
        autoCapitalize={'none'}
        autoCorrect={false}
      />

      <TouchableOpacity
        style={{ alignItems: "center", alignSelf: "flex-start", padding: 1, backgroundColor: "#A8CF45", marginLeft: 4.5, width: 47, height: 40, justifyContent: "center", borderTopEndRadius: 20, borderBottomEndRadius: 20, borderColor: "gray", borderLeftWidth: 1 }}
        onPress={this.callSearch}
      >
        {!this.state.activityIndicatorAnimating ? <Image
          style={{ width: 18, height: 18, marginRight: 1 }}
          source={require("../../images/icons/Send.png")}
        /> :
          <ActivityIndicator size="small" color="#000" animating={true} style={{ margin: -1, marginRight: 0 }} />
        }
      </TouchableOpacity>
    </View>)
  }

  renderSearchResult = (result, i) => {
    return (<View key={i}
      style={{ flex: 1, alignItems: "center", alignSelf: "stretch", marginTop: 10 }}
    >
      <View style={{ height: 85, borderBottomColor: "#ccc", borderBottomWidth: 0.5, width: "100%", justifyContent: "center" }}>
        <Text style={{ fontSize: 16, color: result.available ? "#ccc" : "#a44", }}>
          {result.name}
        </Text>
        {!result.available ?
          <Text style={{ fontSize: 12, color: "#a44", paddingTop: 15, paddingBottom: 10 }}>
            ✗ Not available
          </Text>
          : <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
            <Text style={{ fontSize: 12, color: "#888", paddingTop: 15, paddingBottom: 10 }}>
              ✅ Available
        </Text>
            <TouchableOpacity onPress={() => { this.select(result.name) }} style={[global.gStyles.buttonXW, { padding: 5, marginTop: 10, marginBottom: 5 }]}>
              <Text style={[global.gStyles.buttontXW, { paddingHorizontal: 6 }]}>
                SELECT</Text>
            </TouchableOpacity></View>}
      </View>
    </View>)
  }

  renderKeepExisting = () => {
    if (!this.state.oldShopLoaded) return (<View style={{ height: 85, marginTop: 10, alignSelf: "stretch", alignItems: "center", justifyContent: "center" }}>
      <ActivityIndicator size="small" color="#445" />
    </View>)
    if (!this.state.oldShop) return (<View />)

    let myDomain = "???"
    if (this.state.plan == "premium" && !this.state.selectedTLD && this.state.oldShop.tld) myDomain = this.state.oldShop.tld
    if (this.state.plan == "premium" && this.state.selectedTLD && this.state.oldShopID) myDomain = "wakanda-market.com/" + this.state.oldShopID
    if (this.state.plan == "classic" && this.state.oldShopID) myDomain = "wakanda-market.com/" + this.state.oldShopID
    if (this.state.plan == "education" && this.state.oldShopID) myDomain = "wakanda-education.com/" + this.state.oldShopID
    if (myDomain == "???") return (<View />)
    return (<View
      style={{ flex: 1, alignItems: "center", alignSelf: "stretch", marginTop: 10 }}
    >
      <View style={{ height: 85, backgroundColor: "#020", width: "100%", justifyContent: "center", borderColor: "#373", borderWidth: 0.5, padding: 5, marginHorizontal: -5 }}>
        <Text style={{ fontSize: 16, color: "#ccc" }}>
          {myDomain}
        </Text>
        <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
          <Text style={{ fontSize: 12, paddingTop: 15, paddingBottom: 10, color: "#ccc" }}>
            ✅ My Current Domain
        </Text>
          <TouchableOpacity onPress={() => { this.select("$EXISTING" + myDomain) }} style={[global.gStyles.buttonXW, { padding: 5, marginTop: 10, marginBottom: 5, backgroundColor: "#131313" }]}>
            <Text style={[global.gStyles.buttontXW, { paddingHorizontal: 6 }]}>
              KEEP DOMAIN</Text>
          </TouchableOpacity></View>
      </View>
    </View>)
  }

  render() {
    //console.log("STATE", this.state)
    if (this.state.screen == "loading") return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "#131313" }} >
        <ActivityIndicator size="large" color="#466" animating={true} />
      </View>
    );
    if (this.state.screen == "manage") return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "#131313" }} >
        <SetupScreen shopID={this.state.shopID} navigation={this.props.navigation} />
      </View>
    );
    if (this.state.screen == "select") return (
      <SafeAreaView style={{ flex: 1, backgroundColor: this.state.selectedTLD ? "#222" : "#131313" }}>
        <StatusBar barStyle="light-content" />
        <ScrollView style={{ marginTop: Platform.OS == "ios" ? 50 : 85, paddingHorizontal: 25, marginBottom: 120 }}>
          <View style={{ flex: 1, alignItems: "center" }}>
            {this.state.selectedTLD && this.renderSelectWMD()}
            <Text
              style={{ fontSize: 16, color: "#ccc", paddingBottom: 10, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 20, justifyContent: "flex-start" }}
            >
              DOMAIN NAME SEARCH
              </Text>
            {this.renderSearchBar()}
            {this.state.oldShopID && !this.state.oldShopOutdated && this.renderKeepExisting()}
            {this.state.results.length > 0 && this.state.results.map((result, i) => {
              return this.renderSearchResult(result, i)
            })}
          </View>
        </ScrollView>
        {<View style={{ height: 120, width: "100%", position: "absolute", bottom: 0, justifyContent: "center", paddingHorizontal: 15 }}>
          <Text style={{ color: "#aaa" }}>ⓘ You need to choose the domain name for your new website, e.g. {(this.state.plan && this.state.plan == "classic") ? "wakanda-market.com/" : (this.state.plan && this.state.plan == "education") ? "wakanda-education.com/" : ""}YOUR-SHOP{this.state.plan && this.state.plan == "classic" ? "" : ".com"} .</Text>
          <Text style={{ color: "#aaa" }}>Search your preferred domain name here to see if available.</Text>
          {!this.state.isForTrial && <Text style={{ color: "#aaa" }}>The domain name will be permanent, it cannot be changed later.</Text>}
        </View>}
      </SafeAreaView>
    );
  }
}

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  TouchableHighlight,
  TouchableWithoutFeedback,
  ScrollView,
  SectionList,
  SafeAreaView,
  ActivityIndicator,
  Dimensions,
  Platform,
  Keyboard,
  Image,
  Alert,
  Modal
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
const gStyles = global.gStyles //    "../../../../styles/gStyles";

class SelectNetwork extends Component {

  state = {
    activityIndicatorAnimating: true
  };
}

export default class NetworkVerificationScreen extends Component {
  static navigationOptions = {
    headerTransparent: false,
    title: "Verify Qualification",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45"
    },
    headerStyle: {
      backgroundColor: "#000"
      //height: 15,
    }
  };

  state = {
    type: "",
    networkID: "-",
    networkName: "++++++",
    networks: [],
    availableCategories2: [],
    category2: null,

    profileName: "+++",
    profileBusinessName: "+++",
    profilePictureURL: "",
    profileID: "",

    showModal: false,
    showCategoryModal: false,
    loading: true,
    activityIndicatorAnimating: false
  };

  async componentDidMount() {
    const profilePictureURL = this.props.navigation.getParam("profilePicture", "")
    const profileName = this.props.navigation.getParam("profileName", "---")
    const profileBusinessName = this.props.navigation.getParam("profileBusinessName", "---")
    const profileID = this.props.navigation.getParam("profileID", null)
    this.setState({ profileName, profileBusinessName, profilePictureURL, profileID })
    try {
      const networkIDs = this.props.navigation.getParam("networkIDs", []);
      console.log("networkIDs", networkIDs)
      if (networkIDs.length == 1) {
        const netw = await global.getNetwork(networkIDs[0], true)
        if (!netw) return alert("Network not found error")
        if (!netw.categories || netw.categories.length === 0) {
          this.setState({ showCategoryModal: false })
          await global.timeout(100)
          this.props.navigation.goBack()
          await global.timeout(900)
          return alert("This network cannot yet process certifications. No categories.")
        }
        if (netw.categories && netw.categories.length == 1) { this.setState({ category2: netw.categories[0] }) }
        return this.setState({ loading: false, networkID: networkIDs[0], networkName: netw.networkName, availableCategories2: netw.categories || [] })
      } else if (networkIDs.length == 0) {
        return this.props.navigation.goBack()
      } else {
        let networks = []
        for (nID of networkIDs) {
          console.log("nID", nID)
          const network = await global.getNetwork(nID, true) || {}
          if (network) network["xID"] = nID
          networks.push(network)
        }
        return this.setState({ showModal: true, networks, loading: false })
      }
    } catch (err) {
      console.log("Error", err);
    }
  }

  componentWillUnmount() {
    //this.unsubscribeFirestore();
  }

  verify = async () => {
    if (this.state.activityIndicatorAnimating) return;
    if (!this.state.networkID || this.state.networkID == "-") return alert("Error. No network recognized")
    if (!this.state.profileID) return alert("Error. No profile recognized")
    if (!this.state.category2) return alert("No category selected")
    if (this.state.type.length < 3) return alert("Text too short");
    Alert.alert(
      "Sure?",
      "Add Verification",
      [
        { text: "NO", onPress: () => console.log("Cancel Pressed"), style: "cancel" },
        { text: "YES", onPress: () => { this.verifyExecute(); } }
      ],
      { cancelable: false }
    );
  }

  verifyExecute = async () => {
    try {
      this.setState({ activityIndicatorAnimating: true });
      const targetProfileID = this.state.profileID;
      const r = await fetch(global.cloudFunctionURL + "verifyQualification", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          networkID: this.state.networkID,
          type: this.state.type,
          targetProfileID,
          category2: this.state.category2,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ activityIndicatorAnimating: false });

      if (r2.msg === "SUCCESS") {
        this.props.navigation.goBack()
        await global.timeout(900)
        alert("...SUCCESS...");
      } else {
        alert("Backend error. Request failed.");
      }
    } catch (err) {
      alert("Request not successful.");
      console.log(err);
      this.setState({ activityIndicatorAnimating: false });
    }
  }


  render() {
    if (this.state.loading) return (<View style={{
      flex: 1, alignItems: "center", justifyContent: "flex-start", backgroundColor: "#131313", padding: 15, paddingTop: 50, alignSelf: "stretch",
    }}>
      <ActivityIndicator size="small" color="#8a8" animating={true} />
    </View>)

    return (
      <TouchableOpacity
        activeOpacity={1}
        onPress={() => { Keyboard.dismiss() }}
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "flex-start",
          backgroundColor: "#131313",
          padding: 15,
          paddingTop: 0,
          alignSelf: "stretch",
        }}
      >
        <View style={{ alignSelf: "stretch" }}>
          <View style={{ alignSelf: "stretch", alignItems: "center", flexDirection: "row", height: 65, padding: 24, borderColor: "#484848", borderBottomWidth: 0.5 }}>
            {!!this.state.profilePictureURL && <Image
              style={{ width: 50, height: 50, borderRadius: 25, borderWidth: 0.5, borderColor: "#484848" }}
              source={{ uri: this.state.profilePictureURL }}
            />}
            <View
              style={{ flexDirection: "column", flex: 1, paddingHorizontal: 8 }}
            >
              <Text style={{ fontSize: 16, color: "white" }}>
                {this.state.profileName}
              </Text>
              <Text style={{ fontSize: 14, color: "white" }}>
                {this.state.profileBusinessName}
              </Text>
            </View>
          </View>
          <View style={{ height: 60 }} />
          <View style={{ borderColor: "#484848", borderTopWidth: 0.5, borderBottomWidth: 0.5, padding: 5 }}>
            <Text style={{ color: "white" }}>Verified as...</Text>
            <TextInput
              onChangeText={type => {
                this.setState({ type });
              }}
              style={{
                marginVertical: 10,
                color: "#ffffff",
                borderRadius: 5,
                padding: 2,
                height: 26,
                justifyContent: "center",
                alignSelf: "stretch",
                backgroundColor: "#334"
              }}
              maxLength={80}
              placeholder={"e.g. Primary School Teacher"}
              placeholderTextColor={"#889"}
              underlineColorAndroid="transparent"
              defaultValue={this.state.type}
              keyboardDismissMode={'on-drag'}
              keyboardAppearance={"dark"}
              returnKeyType={"done"}
              bordered
            />
            <Text style={{ color: "white" }}>..by {this.state.networkName ? this.state.networkName || "NETWORK NAME NOT FOUND" : "NETWORK NOT FOUND"}</Text>
          </View>
          <View style={{ height: 25 }} />
          <TouchableOpacity
            style={[global.gStyles.buttonXLW, { width: 250, height: 35, borderWidth: 0.5, borderColor: "#484848" }]}
            onPress={() => { this.setState({ showCategoryModal: true }) }}
          >
            <Text style={global.gStyles.buttontXLW}>{this.state.category2 || "Select a category"}</Text>
          </TouchableOpacity>
          <View style={{ height: 60 }} />
          {!this.state.activityIndicatorAnimating ?
            <View style={{ height: 20 }} /> :
            <ActivityIndicator
              size="small"
              color="#FFFFFF"
              animating={true}
            />}
          <TouchableOpacity
            style={global.gStyles.buttonXL}
            onPress={this.verify}
          >
            <Text style={global.gStyles.buttontXL}>VERIFY QUALIFICATION</Text>
          </TouchableOpacity>
        </View>
        <Modal animationType="fade" transparent={false} visible={this.state.showModal} onRequestClose={() => { this.setState({ showModal: false }); }}>
          <View style={{ flex: 1, justifyContent: "center", backgroundColor: "#131313", alignItems: "center" }}>
            <View
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#131313",
                padding: 15,
                alignSelf: "stretch",
              }}
            >
              <Text style={{ color: "white", fontSize: 20, fontWeight: "bold" }}>More than one network detected</Text>
              <View style={{ height: 5 }} />
              <Text style={{ color: "white", fontSize: 16 }}>Choose one for verification</Text>
              <View style={{ height: 20 }} />
              {this.state.networks.map(network => {
                return (<View key={network.xID} style={{ marginTop: 20 }}>
                  <TouchableOpacity
                    style={global.gStyles.buttonXL}
                    onPress={async () => {
                      if (!network.categories || network.categories.length === 0) {
                        this.setState({ showModal: false })
                        await global.timeout(100)
                        this.props.navigation.goBack()
                        await global.timeout(900)
                        return alert("This network cannot yet process certifications. No categories.")
                      }
                      if (network.categories && network.categories.length == 1) { this.setState({ category2: network.categories[0] }) }
                      this.setState({ showModal: false, networkID: network.xID, availableCategories2: network.categories || [], networkName: network.networkName })
                    }}
                  >
                    <Text style={global.gStyles.buttontXL}>{network.networkName || "NOT FOUND"}</Text>
                  </TouchableOpacity>
                </View>)
              })}
            </View>
          </View>
        </Modal>
        <Modal animationType="slide" transparent={false} visible={this.state.showCategoryModal} onRequestClose={() => { this.setState({ showCategoryModal: false }); }}>
          <View style={{ flex: 1, justifyContent: "center", backgroundColor: "#131313", alignItems: "center" }}>
            <View
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#131313",
                padding: 15,
                alignSelf: "stretch",
              }}
            >
              <View style={{ height: 50 }} />
              <Text style={{ color: "white", fontSize: 20, fontWeight: "bold" }}>Select a category</Text>
              <View style={{ height: 5 }} />
              <Text style={{ color: "white", fontSize: 16 }}>Verifications are per category</Text>
              <View style={{ height: 20 }} />
              <ScrollView>
                {this.state.availableCategories2.map(cat2 => {
                  return (<View key={cat2} style={{ marginTop: 20 }}>
                    <TouchableOpacity
                      style={global.gStyles.buttonXL}
                      onPress={() => {
                        this.setState({ showCategoryModal: false, category2: cat2 })
                      }}
                    >
                      <Text style={global.gStyles.buttontXL}>{cat2}</Text>
                    </TouchableOpacity>
                  </View>)
                })}
              </ScrollView>
            </View>
          </View>
        </Modal>
      </TouchableOpacity>
    );
  }
}

import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  TextInput,
  Image,
  TouchableOpacity,
  SafeAreaView,
  SectionList,
  Platform,
  Dimensions,
  ActivityIndicator
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";


 export default class Pay extends Component {
  render() {
    return <View />;
  }
}

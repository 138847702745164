import React, { Component } from "react";
import {
  Platform,
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Modal,
  ScrollView,
  Image,
  Animated,
  Linking,
  ActivityIndicator,
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from "expo-constants";
import { WebView } from 'react-native-webview';
import { ScreenOrientation } from "expo";
import firebase from "firebase";
import "firebase/firestore";
import { LinearGradient } from 'expo-linear-gradient'
import Author from "./Author";

export default class Post extends Component {
  constructor() {
    super();
    this.unsubscribeFirestore = () => { return; };
    this.sv = {}
    this.hasMoved = false
  }

  state = {
    title: " ✧ ✧ ✧ ✧ ✧   ✧ ✧ ✧   ✧ ✧ ✧ ✧ ✧ ",
    text: "Loading more ...",
    authorProfileID: null,
    authorProfileName: "",
    authorBusinessName: "",
    authorProfilePictureURL: null,
    authorLocation: {},
    authorUsesACN: "UNKNOWN",
    pictureURL: null,
    pictureURLs: null,
    youtubeID: null,
    youtubeIDs: null,
    price: -1,
    priceDiscount: 0,
    priceType: "NONE",
    priceACN: -1,
    priceACNDiscount: 0,
    category1: "xxxxxxxx",
    category2: "xxxxxxxx",
    location: { address: "", coords: { latitude: 6.6, longitude: 3.3 } },
    hasDelivery: true,
    likingProfileIDs: [],
    similiarPostsIDs: [],
    nOfSwipesRight: 0,
    nOfViews: 0,
    nOfClicks: 0,
    nOfSaves: 0,
    nOfUnreadSwipesRight: 0,
    status: "#######",
    statusReason: "",
    statusReasonText: "",
    nOfUnreadItems: 0,
    creationDate: null,
    hasUnreadItems: false,
    isConnected: true,

    wishlistChanging: false,
    activityIndicatorChat: false,
    aaLike: false,

    phoneNumber: null,
    isOnWishlist: false,

    menuVisible: false,
    modalVisible: false,
    watchingYoutube: false,
    playingYoutubeID: "",
    loadingPost: true,
    loadingAuthor: true,
    showTextFull: false,

    scrollPosition: 0,

    walletType: null,
  };

  async componentDidMount() {
    this.setState({ modalVisible: this.props.showDetail });

    if (this.props.postResult)
      this.preloadPostFromSearchResults()

    if (!this.props.data) {
      try {
        // tbd: check that below is not called several times / whenever props change?
        this.fRef = await firebase
          .firestore()
          .collection("Posts")
          .doc(this.props.postID); // xx change
        this.unsubscribeFirestore = this.fRef.onSnapshot(this.updatePost);
      } catch (err) {
        console.log("Error loading post", err);
      }
    } else {
      this.loadPostFromData();
    }
    if (global.walletType) {
      this.setState({ walletType: global.walletType })
    } else {
      while (!global.walletType) {
        await new Promise(resolve => { setTimeout(resolve, 2000); });
      }
      this.setState({ walletType: global.walletType })
    }

    if (this.props.showDetail) {
      this.reportDetail()
    }
    if (!this.props.postID) console.warn("No postID supplied")
  }

  componentDidUpdate(prevProps) {
    if (this.props.showDetail && !prevProps.showDetail) {
      this.reportDetail()
    }
  }


  async componentWillUnmount() {
    if (this.unsubscribeFirestore) {
      this.unsubscribeFirestore();
    }
  }

  reportDetail = async () => {
    try {
      console.log("reportDetail called ----------------------", this.props.postID)
      if (this.props.postID) {
        console.log("with postID old lastViewedPostIDs----------------------", global.lastViewedPostIDs)
        global.reportStats("pressed_PostDetail", this.props.postID)
        if (!global.lastViewedPostIDs) global.lastViewedPostIDs = []
        global.lastViewedPostIDs = global.lastViewedPostIDs.filter(id => id !== this.props.postID)
        global.lastViewedPostIDs.unshift(this.props.postID)
        if (global.lastViewedPostIDs.length > 100) global.lastViewedPostIDs.length = 100
        AsyncStorage.setItem("lastViewedPostIDs", JSON.stringify(global.lastViewedPostIDs))
        //console.log("new lastViewedPostIDs ----------------------", global.lastViewedPostIDs)
      }
      if (!this.state.similiarPostsIDs || this.state.similiarPostsIDs.length == 0) await global.timeout(2000)
      if (!this.state.similiarPostsIDs || this.state.similiarPostsIDs.length == 0) await global.timeout(2000)
      if (!this.state.similiarPostsIDs || this.state.similiarPostsIDs.length == 0) await global.timeout(4000)
      if (!this.state.similiarPostsIDs || this.state.similiarPostsIDs.length == 0) return
      global.setRecommendationsAsync(this.state.similiarPostsIDs, 1)
    } catch (e) {
      global.warn(e, "Post_reportDetail")
    }
  }

  preloadPostFromSearchResults = async () => {
    const p = this.props.postResult;

    const data = {
      authorProfileID: p.author,
      title: p.t,
      pictureURL: p.url,
      price: p.p,
      priceCurrency: p.pc || "NGN",
      priceType: p.pt,
      status: "LIVE",
      loadingPost: false
    };
    if (global.walletType == "ACN") {
      if (global.xRates[p.pc])
        data.priceACN = p.p / global.xRates[p.pc]
    }
    if (p.pc == "ACN") {
      data.priceACN = p.p
    }

    this.setState(data);
  }

  loadPostFromData = async () => {
    const post = this.props.data;

    console.log(
      "global.myWishlist.indexOf(this.props.postID)",
      global.myWishlist.indexOf(this.props.postID)
    );
    const data = {
      authorProfileID: post.authorProfileID,
      title: post.postTitle,
      pictureURL: post.postPictureURL,
      pictureURLs: null,
      youtubeID: post.postYoutubeID,
      youtubeIDs: null,
      text: post.postText,
      price: post.postPrice,
      priceDiscount: post.postPriceDiscount,
      priceCurrency: post.postPriceCurrency || "NGN",
      hasDelivery: post.postHasDelivery,
      priceType: post.postPriceType,
      category1: post.assignedCategory1,
      category2: post.assignedCategory2,
      // location: {
      //   address: post.assignedLocationAddress,
      //   coords: {
      //     latitude: post.assignedLocationGeo._latitude,
      //     longitude: post.assignedLocationGeo._longitude
      //   }
      // },
      likingProfileIDs: post.likingProfileIDs,
      nOfSwipesRight: post.nOfSwipesRight,
      nOfViews: post.nOfViews,
      nOfClicks: post.nOfClicks,
      nOfSaves: post.nOfSaves,
      nOfUnreadSwipesRight: post.nOfSwipesRightSeen - post.nOfSwipesRight,
      similiarPostsIDs: post.similiarPostsIDs || [],
      status: post.systemStatus,
      statusReason: post.systemStatusReason,
      statusReasonText: post.systemStatusReasonText,
      nOfUnreadItems: post.systemStatusSeen ? 0 : 1,
      //(creationDate: post.timeCreated.toDate(),
      hasUnreadItems: !post.systemStatusSeen,

      //isOnWishlist: global.myWishlist.indexOf(this.props.postID) > -1,

      loadingPost: false
    };
    if (post.postPictureURL2) {
      const pictureURLs = [post.postPictureURL, post.postPictureURL2]
      const youtubeIDs = [post.postYoutubeID, post.postYoutubeID2]
      if (post.postPictureURL3) { pictureURLs.push(post.postPictureURL3); youtubeIDs.push(post.postYoutubeID3) }
      if (post.postPictureURL4) { pictureURLs.push(post.postPictureURL4); youtubeIDs.push(post.postYoutubeID4) }
      if (post.postPictureURL5) { pictureURLs.push(post.postPictureURL5); youtubeIDs.push(post.postYoutubeID5) }
      data.pictureURLs = pictureURLs
      data.youtubeIDs = youtubeIDs
    }
    if (global.walletType == "ACN") {
      if (global.xRates[post.postPriceCurrency])
        data.priceACN = post.postPrice / global.xRates[post.postPriceCurrency]
    }
    if (post.postPriceCurrency == "ACN") {
      data.priceACN = post.postPrice
    }

    this.setState(data);
    if (this.props.onDataLoaded) {
      this.props.onDataLoaded(data);
    }
    if (post.systemStatus != this.props.relevantStatus) {
      await new Promise(resolve => {
        setTimeout(resolve, 100);
      });
      console.log("Post status not relevant", this.props.postID);
      this.props.onIsNotRelevant(post.systemStatus);
    }
  };

  updatePost = async postSnapshot => {
    const post = postSnapshot.data();

    console.log(
      "global.myWishlist.indexOf(this.props.postID)",
      global.myWishlist.indexOf(this.props.postID)
    );
    const data = {
      authorProfileID: post.authorProfileID,
      text: post.postText,
      title: post.postTitle,
      pictureURL: post.postPictureURL,
      pictureURLs: null,
      youtubeID: post.postYoutubeID,
      youtubeIDs: null,
      price: post.postPrice,
      priceDiscount: post.postPriceDiscount,
      priceType: post.postPriceType,
      priceCurrency: post.postPriceCurrency || "NGN",
      category1: post.assignedCategory1,
      category2: post.assignedCategory2,
      hasDelivery: post.postHasDelivery,
      ...("postHasDelivery" in post && { hasDeliveryDecided: true }), // only use case: so that old posts can update their choice in MePostsScreen
      location: {
        address: post.assignedLocationAddress,
        coords: {
          latitude: post.assignedLocationGeo._lat,
          longitude: post.assignedLocationGeo._long
        }
      },
      likingProfileIDs: post.likingProfileIDs,
      nOfSwipesRight: post.nOfSwipesRight,
      nOfViews: post.nOfViews,
      nOfClicks: post.nOfClicks,
      nOfSaves: post.nOfSaves,
      nOfUnreadSwipesRight: post.nOfSwipesRightSeen - post.nOfSwipesRight,
      similiarPostsIDs: post.similiarPostsIDs || [],
      status: post.systemStatus,
      statusReason: post.systemStatusReason,
      statusReasonText: post.systemStatusReasonText,
      nOfUnreadItems: post.systemStatusSeen ? 0 : 1,
      creationDate: post.timeCreated.toDate(),
      hasUnreadItems: !post.systemStatusSeen,

      isOnWishlist: global.myWishlist.indexOf(this.props.postID) > -1,

      loadingPost: false
    };
    if (post.postPictureURL2) {
      const pictureURLs = [post.postPictureURL, post.postPictureURL2]
      const youtubeIDs = [post.postYoutubeID, post.postYoutubeID2]
      if (post.postPictureURL3) { pictureURLs.push(post.postPictureURL3); youtubeIDs.push(post.postYoutubeID3) }
      if (post.postPictureURL4) { pictureURLs.push(post.postPictureURL4); youtubeIDs.push(post.postYoutubeID4) }
      if (post.postPictureURL5) { pictureURLs.push(post.postPictureURL5); youtubeIDs.push(post.postYoutubeID5) }
      data.pictureURLs = pictureURLs
      data.youtubeIDs = youtubeIDs
    }
    if (global.walletType == "ACN") {
      if (global.xRates[post.postPriceCurrency])
        data.priceACN = post.postPrice / global.xRates[post.postPriceCurrency]
      data.priceACNDiscount = post.postPriceDiscount / global.xRates[post.postPriceCurrency]
    }
    if (post.postPriceCurrency == "ACN") {
      data.priceACN = post.postPrice
      data.priceACNDiscount = post.postPriceDiscount
    }

    this.setState(data);

    if (this.props.onDataLoaded) {
      this.props.onDataLoaded(data);
    }
    if (post.systemStatus != this.props.relevantStatus) {
      await new Promise(resolve => {
        setTimeout(resolve, 100);
      });
      console.log("Post status not relevant", this.props.postID);
      this.props.onIsNotRelevant(post.systemStatus);
    }
  };

  shareSocial = async () => {
    if (!this.props.postID || !this.state.authorProfileID) return alert("Error. Refresh and try again.")
    const message = this.state.title + " on Wakanda: https://wakandamarket.com/" + this.state.authorProfileID + "/" + this.props.postID
    if (Platform.OS == "ios") {
      this.setState({ menuVisible: false, });
    } else {
      this.setState({ modalVisible: false, menuVisible: false, watchingYoutube: false });
      this.props.onHideDetail();
      await new Promise(resolve => { setTimeout(resolve, 500); });
      this.props.onHideDetail();
    }
    global.shareSocial(message)
  };


  toggleWishlist = async () => {
    if (!global.myUID) return;
    this.setState({ wishlistChanging: true });
    if (this.state.isOnWishlist) {
      try {
        await firebase
          .firestore()
          .collection("Users")
          .doc(global.myUID)
          .collection("Refs")
          .doc("wishlist")
          .update({
            postIDs: firebase.firestore.FieldValue.arrayRemove(
              this.props.postID
            )
          });
        global.myWishlist = global.myWishlist.filter(item => {
          return item != this.props.postID;
        });
        console.log("Removed from Wishlist");
        this.setState({ isOnWishlist: false, wishlistChanging: false });
      } catch (err) {
        console.log("Error toggling Wishlist", err);
        alert("Error. Try again.");
        this.setState({ wishlistChanging: false });
      }
    } else {
      try {
        await firebase
          .firestore()
          .collection("Users")
          .doc(global.myUID)
          .collection("Refs")
          .doc("wishlist")
          .update({
            postIDs: firebase.firestore.FieldValue.arrayUnion(this.props.postID)
          });
        global.myWishlist.push(this.props.postID);
        this.setState({ isOnWishlist: true, wishlistChanging: false });
        console.log("Added to Wishlist");
      } catch (err) {
        console.log("Error toggling Wishlist", err);
        alert("Error. Try again.");
        this.setState({ wishlistChanging: false });
      }
    }
  };

  callChat = async () => {
    if (!this.state.authorProfileID) return;
    if (this.state.authorProfileID == global.myProfileID) {
      alert("Are you really trying to chat with yourself?");
      return;
    }
    global.reportStats("pressed_Chat", this.state.authorProfileID, "POST")
    let chatID = "";
    let chatProperties = {
      opponentProfileID: this.state.authorProfileID,
      opponentName: this.state.authorProfileName,
      opponentNameBusiness: this.state.authorBusinessName,
      opponentProfilePictureURL: this.state.authorProfilePictureURL
    };

    if (!this.state.authorProfilePictureURL) {
      chatProperties["opponentProfilePictureURL"] = "";
    }
    if (!this.state.authorBusinessName) {
      chatProperties["opponentNameBusiness"] = "";
    }

    // call newChat
    this.setState({ activityIndicatorChat: true });
    try {
      const r = await fetch(global.cloudFunctionURL + "newChat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          profileID1: global.myProfileID,
          profileID2: this.state.authorProfileID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      chatID = r2.chatID;
      this.setState({
        activityIndicatorChat: false,
        modalVisible: false,
        menuVisible: false
      });
      this.props.onHideDetail();
      await new Promise(resolve => {
        setTimeout(resolve, 50);
      });
    } catch (error) {
      this.setState({ activityIndicatorChat: false });
      console.log(error);
    }

    // navigate to chatID
    if (chatID) {
      const replyMessage = {
        postID: this.props.postID,
        text: ""
      }
      this.props.navigation.navigate("chatMain", {
        redirect: true,
        redirectParams: {
          chatID: chatID,
          chatProperties: chatProperties,
          replyMessage
        }
      });
    }
  };

  callCall = () => {
    if (!this.state.authorProfileID) return;
    if (this.state.authorProfileID == global.myProfileID) {
      alert("You cannot order from yourself");
      return;
    }
    //alert("Call clicked");
    if (this.state.phoneNumber) {
      this.setState({ activityIndicatorAnimating: true });
      Linking.openURL("tel:" + this.state.phoneNumber);
      global.reportStats("pressed_Call", this.state.authorProfileID, "POST")
      this.setState({ activityIndicatorAnimating: false });
      if (this.state.authorProfileID !== global.myProfileID)
        fetch(global.cloudFunctionURL + "reportEvent", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            event: "call",
            profileID: this.state.authorProfileID,
            postID: this.props.postID,
            userInfo: {
              firstName: global.myName?.split?.(" ")[0],
              appVersion: Constants?.manifest?.version || "0"
            },
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
    } else {
      alert("No phone number found.");
    }
  };

  callOrder = async () => {
    if (this.state.authorProfileID == global.myProfileID)
      return alert("You cannot order from yourself");

    if (this.state.authorUsesACN == "UNKNOWN") {
      await global.timeout(1000)
      if (this.state.authorUsesACN == "UNKNOWN") {
        await global.timeout(1000)
        if (this.state.authorUsesACN == "UNKNOWN") {
          console.log("Error. authorUsesACN UNKNOWN")
          return
        }
      }
    }
    if (this.state.authorUsesACN && global.walletType == "NGN") {
      alert("You cannot use our escrow service with providers outside Nigeria for now. Contact them directly!")
      return
    }
    if (!this.state.authorUsesACN && global.walletType == "ACN") {
      alert("You cannot use our escrow service with Nigerian providers for now. Contact them!")
      return
    }

    global.reportStats("pressed_Order", this.state.authorProfileID, "POST")

    const currentRoute = this.props.navigation.state.routeName
    let route = "jobsNew";
    if (currentRoute.startsWith("home")) {
      route = "homeJobsNew";
    }
    if (currentRoute.startsWith("job")) {
      route = "jobsNew";
    }
    if (currentRoute.startsWith("message") || currentRoute.startsWith("chat")) {
      route = "messageJobsNew";
    } else if (currentRoute.startsWith("me")) {
      route = "meJobsNew";
    }
    // xx tbd check if blocked or blocking - here or in newJob!
    this.setState({
      modalVisible: false,
      menuVisible: false
    });
    console.log("opening route " + route)
    this.props.onHideDetail();
    console.log("this.state.authorLocation", this.state.authorLocation)
    this.props.navigation.navigate(route, {
      providerProfileID: this.state.authorProfileID,
      postID: this.props.postID,
      price: this.state.price,
      priceDiscount: this.state.priceDiscount,
      priceCurrency: this.state.priceCurrency,
      hasDelivery: this.state.hasDelivery,
      title: this.state.title,
      text: this.state.text,
      pictureURL: this.state.pictureURL,
      providerProfileName: this.state.authorProfileName,
      providerBusinessName: this.state.authorBusinessName,
      providerProfilePictureURL: this.state.authorProfilePictureURL,
      providerPhoneNumber: this.state.phoneNumber,
      providerLocation: this.state.authorLocation
    });
  };

  callShare = async () => {
    if (!this.state.title || !this.props.postID || !this.state.authorProfileID) return alert("Error. Refresh and try again.")
    const message = this.state.title + " on Wakanda: https://wakandamarket.com/" + (this.state.authorProfileID) + "/" + this.props.postID
    global.shareSocial(message)
  };

  likePost = async (isLike) => {
    if (this.state.aaLike) return;
    const postID = this.props.postID
    if (this.state.aaLike) return;
    console.log("Starting to call likePost");
    try {
      this.setState({ aaLike: true })
      if (isLike) {
        global.myLikedPostIDs.push(postID);
        global.myDislikedPostIDs = global.myDislikedPostIDs.filter(id => {
          return id != postID;
        });
      } else {
        global.myDislikedPostIDs.push(postID);
        global.myLikedPostIDs = global.myLikedPostIDs.filter(id => {
          return id != postID;
        });
      }
      this.setState({ nOfSwipesRight: this.state.nOfSwipesRight + (isLike ? 1 : -1) })
      this.setState({ randomString: "-" + Math.floor(Math.random() * 9999999999) })
      const r = await fetch(global.cloudFunctionURL + "likePost", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: isLike ? "like" : "unlike",
          postID: postID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();

      console.log("Received response. ", r2.msg);
      this.setState({ aaLike: false })
      if (r2.msg === "SUCCESS") {
        //
      } else {
        // revert setting global
        if (!isLike) {
          global.myLikedPostIDs.push(postID);
          global.myDislikedPostIDs = global.myDislikedPostIDs.filter(id => {
            return id != postID;
          });
        } else {
          global.myDislikedPostIDs.push(postID);
          global.myLikedPostIDs = global.myLikedPostIDs.filter(id => {
            return id != postID;
          });
        }
      }
    } catch (e2) {
      console.warn("ERROR u6");
      console.log("ERROR u6", e2);
      this.setState({ aaLike: false })
    }
  };

  playYoutube = async (id) => {
    // if (Platform.OS == 'ios') {
    this.setState({ playingYoutubeID: id, watchingYoutube: true })
    //   return;
    // }
    // this.setState({ modalVisible: false })
    // await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.DEFAULT);
    // await global.timeout(100)
    // this.setState({ modalVisible: true, playingYoutubeID: id, watchingYoutube: true })
  }



  renderDelivery = () => {
    const width = global.walletType == "ACN" ? 24 : 30
    const height = global.walletType == "ACN" ? 16 : 20
    if (Platform.OS == "ios") {
      return (
        <View>
          {!!this.state.hasDelivery && <View style={{
            backgroundColor: "rgba(255,255,255,.0)", borderRadius: 0, height: 24, width: 30, alignSelf: "flex-end", alignItems: "center", justifyContent: "center", marginBottom: 5, marginRight: 4
          }}>
            <Image
              style={{ width, height, margin: 3, borderRadius: 3, shadowColor: "black", shadowOpacity: 1, tintColor: "white" }}
              source={require("../../images/icons/Delivery.png")}
            />
          </View>}
        </View>)
    } else {
      return (<View>
        {!!this.state.hasDelivery && <View style={{
          backgroundColor: "rgba(255,255,255,.5)", borderRadius: 0, height: 24, width: 30, alignSelf: "flex-end", alignItems: "center", justifyContent: "center"
        }}>
          <Image
            style={{ width, height, margin: 3, borderRadius: 3, }}
            source={require("../../images/icons/Delivery.png")}
          />
        </View>}
      </View>)
    }
  }

  renderPrice = (pString, aString, pStringO, aStringO) => {
    //console.log({ pString, aString, pStringO, aStringO })
    const gradient = ["rgba(0,0,0,.5)", "rgba(0,0,0,.5)"]
    return (
      (this.state.price > 1 || this.state.priceType == "Free") && (
        <View>
          <View style={Platform.OS == "android" && { backgroundColor: "rgba(255,255,255,.5)" }}>
            {this.state.priceType != "NONE" &&
              this.state.priceType != "Fixed Price" &&
              this.state.priceType != "Free" && (
                <Text style={{ alignSelf: "flex-end", color: Platform.OS == "android" ? "black" : "white", fontWeight: "bold", shadowColor: "black", shadowOpacity: 1, fontSize: global.walletType == "ACN" ? 11 : 14, marginBottom: 2, marginRight: 1 }}>
                  {this.state.priceType}
                </Text>
              )}
          </View>
          {/* //ACN if user uses ACN or author stated ACN */}
          {((global.walletType == "ACN" || this.state.priceCurrency == "ACN") && this.state.priceACN > 0 && this.state.priceType != "Free") && <LinearGradient
            colors={gradient} style={{ height: 64, width: 64, justifyContent: "center", alignItems: "center", borderRadius: 33, alignSelf: "flex-end" }}>
            <View style={{ height: 62, width: 62, justifyContent: "center", alignItems: "center", borderRadius: 33, borderWidth: 1.2, borderStyle: "dotted", borderColor: "#fff" }}>
              <LinearGradient
                colors={gradient}
                style={{
                  justifyContent: "center", alignItems: "center", height: 60, width: 60, borderRadius: 30
                }}>
                <Text style={{ fontWeight: "bold", fontSize: 20, color: "#fff" }}>
                  ₳
            </Text>
                <Text style={{ fontSize: 18, color: "#fff", marginBottom: 5, marginTop: -2 }}>
                  {aString}
                </Text>
              </LinearGradient>
            </View>
          </LinearGradient>}

          {/* //fiat Currency by author (unless author used ACN) */}
          {(this.state.priceCurrency != "ACN" || this.state.priceType == "Free") && <View style={{
            backgroundColor: "rgba(255,255,255,.5)", borderRadius: 10, paddingHorizontal: 2, flexDirection: "row", justifyContent: "center"
          }}>
            <Text style={{ color: "black", shadowColor: "white", shadowOpacity: 1, fontSize: global.walletType == "ACN" ? 16 : 20, fontWeight: global.walletType == "ACN" ? "normal" : "bold", marginVertical: -1 }}  >
              {this.state.priceDiscount > 0 && <Text style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid', fontWeight: "normal" }}>{this.state.priceType == "Free" ? "" : `${global.currencySymbols[this.state.priceCurrency]} ${pStringO}`}</Text>}{this.state.priceDiscount && "       "}{this.state.priceType == "Free" ? "FREE" : `${global.currencySymbols[this.state.priceCurrency]} ${pString}`}
            </Text>
          </View>}
        </View>
      )
    )
  }

  scroll = (screen) => {
    const picWidth = Dimensions.get("window").width - 30
    const maxScrollPosition = picWidth * 4 // Change if you add a screen (#screens-1)
    if (screen == "<") this.sv.scrollTo({ x: Math.max(0, this.state.scrollPosition - picWidth), y: 0, animated: true })
    if (screen == ">") this.sv.scrollTo({ x: Math.min(maxScrollPosition, this.state.scrollPosition + picWidth), y: 0, animated: true })
    if (typeof screen == "number") this.sv.scrollTo({ x: (screen - 1) * picWidth, y: 0, animated: true })
  }

  handleScroll = (event) => {
    this.setState({ scrollPosition: (event.nativeEvent.contentOffset.x) })
    this.hasMoved = true
  }

  renderLink = (text) => {
    let link = text
    if (text.toLowerCase().startsWith("www.")) link = "http://" + text
    return (<Text style={{ color: "blue" }} onPress={() => { Linking.openURL(link); }}>{text}</Text>)
  }

  renderText = (text) => {
    let t = text.toLowerCase()
    // console.log("renderText", text, t)

    // CASE text starts with link
    if (t.startsWith("http://") || t.startsWith("https://") || t.startsWith("www.")) {
      // console.log("CASE text starts with link")
      const linkArray = text.split(" ", 1)
      let link = linkArray[0]
      const linkArray2 = text.split("\n", 1)
      if (linkArray2[0].length < link.length) link = linkArray2[0]
      // console.log("link and remainder", link, text.substring(link.length, 999999999))
      if (link.length == text.length) return this.renderLink(link)
      return (<Text>{this.renderLink(link)}{this.renderText(text.substring(link.length, 999999999))}</Text>)
    }

    let fontSize = 14
    if (text && text.length < 8) {
      const numberOfEmojis = text.split(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g).length - 1
      if (numberOfEmojis >= 1 && text.length <= numberOfEmojis + 2) fontSize = 32
    }

    // CASE text contains no links
    if (!(t.includes(" http://") || t.includes(" https://") || t.includes(" www.")))
      // console.log("CASE ok")
      if (!(t.includes(" http://") || t.includes(" https://") || t.includes(" www.")))
        return (<Text style={{ fontSize }}>{text}</Text>)

    // CASE text contains Links
    // console.log("CASE text contains Links")
    let position1 = t.search("http://")
    let position2 = t.search("https://")
    let position3 = t.search("www.")
    if (position1 == -1) position1 = 9999999999
    if (position2 == -1) position2 = 9999999999
    if (position3 == -1) position3 = 9999999999
    const position = Math.min(position1, position2, position3)
    // console.log("position", position, position1, position2, position3)
    // console.log("splits", text.substring(0, position), text.substring(position, 999999999))
    return (<Text>{this.renderText(text.substring(0, position))}{this.renderText(text.substring(position, 999999999))}</Text>)
  }

  renderFullText = (text, fHeight, sWidth, sHeight, titleSize) => {
    return (<TouchableWithoutFeedback
      style={{
        alignItems: "center",
        justifyContent: "flex-end",
        height: "100%",
      }}
      onPress={() => {
        this.setState({ showTextFull: false });
      }}
    >
      <View
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          backgroundColor: "#333"
        }}
      >
        <View style={{ height: 80, justifyContent: "flex-end", alignItems: "center" }}>
          <View style={{
            height: 48, width: 48, borderRadius: 20, marginBottom: 10,
            backgroundColor: "#424242", justifyContent: "center", alignItems: "center"
          }}>
            <Image
              style={{ width: 65, height: 65, tintColor: "#333" }}
              source={require("../../images/icons/CancelWhite.png")}
            />
          </View>
        </View>
        <View
          style={{
            height: fHeight - 20,
            width: sWidth,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <View style={{ backgroundColor: "#fff", flex: 1, width: sWidth, margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 5, paddingTop: 20 }}>
            <Text style={{ color: "#000", fontSize: titleSize, fontWeight: "bold", marginBottom: 33 }}>{this.state.title}</Text>
            <View style={{ marginTop: 0 }}>
              <Text
                style={{ fontSize: 10, color: "#000", paddingBottom: 10, justifyContent: "flex-start" }}
              >
                {this.renderText(text)}
              </Text>
            </View>
          </View>
        </View>
      </View >
    </TouchableWithoutFeedback>)
  }

  render() {
    //calculate discount
    //console.log(' state is ', this.state)
    let showDiscount = false;
    let discountValue = 0
    let discountValueProvider = 0 //new
    let discountValueWakanda = 0 //new
    const currency = this.state.priceCurrency || "NGN"
    let discountCurrency = global.currencySymbols[currency]
    const now = new Date()
    const discountsOnPost = (global.myDiscounts && this.state.authorProfileID) ? global.myDiscounts.filter(d => {
      if (d.postID == this.props.postID || (d.postID == "ALL" && d.providerProfileID == this.state.authorProfileID)) {
        if (!d.timeOfExpirationMillis || d.timeOfExpirationMillis > now.getTime())
          return true
      }
    }) : [];
    //console.log({ discountsOnPost })
    if (this.state.priceDiscount) {
      if (currency == "NGN") discountsOnPost.push({ discountNGN: this.state.priceDiscount, discountWakandaNGN: 0 })
      else if (currency == "ACN") discountsOnPost.push({ discountACN: this.state.priceDiscount, discountWakandaACN: 0 })
      else if (currency == "GHS") discountsOnPost.push({ discountGHS: this.state.priceDiscount, discountWakandaGHS: 0 })
      //console.log('discountsOnPost is now ', discountsOnPost)
    }
    if (discountsOnPost.length > 0) {
      // if (global.walletType == "ACN" && currency == "ACN") {
      //   discountsOnPost.sort((a, b) => { return (b.discountACN - a.discountACN) })
      //   if (discountsOnPost[0].discountACN > 0) {
      //     showDiscount = true
      //     discountValue = discountsOnPost[0].discountACN
      //     //discountCurrency = "₳"
      //   }
      // }
      // if (global.walletType == "ACN" && currency == "GHS") {
      //   discountsOnPost.sort((a, b) => { return (b.discountGHS - a.discountGHS) })
      //   if (discountsOnPost[0].discountGHS > 0) {
      //     showDiscount = true
      //     discountValue = discountsOnPost[0].discountGHS
      //     //discountCurrency = "C"
      //   }
      // }
      if (global.walletType == "NGN" && (currency == "NGN")) {
        discountsOnPost.sort((a, b) => { return ((b.discountNGN + b.discountWakandaNGN) - (a.discountNGN + a.discountWakandaNGN)) })
        if ((discountsOnPost[0].discountNGN + discountsOnPost[0].discountWakandaNGN) > 0) {
          showDiscount = true
          discountValue = discountsOnPost[0].discountNGN + discountsOnPost[0].discountWakandaNGN
          discountValueProvider = discountsOnPost[0].discountNGN //new
          discountValueWakanda = discountsOnPost[0].discountWakandaNGN //new
          //discountCurrency = "₦"
        }
      } else {
        if (this.state.priceDiscount) {
          showDiscount = true
          discountValue = this.state.priceDiscount
          discountValueProvider = this.state.priceDiscount
          discountValueWakanda = 0
        }
      }
    }
    let adjustedDiscountValue;
    if (currency == "NGN" || currency == "ACN") adjustedDiscountValue = Math.min(discountValue, Math.max(0, (this.state.price - 100)))
    else adjustedDiscountValue = Math.min(discountValue, Math.max(0, (this.state.price - 120 * global.xRates[currency])))
    if (!(adjustedDiscountValue > 0)) showDiscount = false
    let disString = Math.round(adjustedDiscountValue).toString();
    if (disString.length > 8) {
      disString = disString.slice(0, disString.length - 6) + "M";
    } else if (disString.length > 6) {
      disString = Number(disString.slice(0, disString.length - 5)) / 10;
      disString = disString.toString() + "M";
    } else if (disString.length > 4) {
      disString = disString.slice(0, disString.length - 3) + "K";
    }
    //console.log("STATE X ", this.state.pictureURLs)

    const icons = {
      Call: require("../../images/icons/Call.png"),
      Chat: require("../../images/icons/Chat.png"),
      RequestService: require("../../images/icons/RequestService.png"),
      Pay: require("../../images/icons/Pay.png"),
      Location: require("../../images/icons/Location.png"),
      Following: require("../../images/icons/Following.png"),
      Save: require("../../images/icons/Save.png"),
      Share: require("../../images/icons/Share.png")
    };
    const images = {
      Electronics: require("../../images/categories/Electronics.png"),
      Home: require("../../images/categories/Home.png"),
      Fashion: require("../../images/categories/Fashion.png"),
      Cars: require("../../images/categories/Cars.png"),
      Food: require("../../images/categories/Food.png"),
      Beauty: require("../../images/categories/Beauty.png"),
      Professionals: require("../../images/categories/Professionals.png"),
      Repair_Refill: require("../../images/categories/Repair_Refill.png"),
      Handyman: require("../../images/categories/Handyman.png"),
      QuickJobs: require("../../images/categories/QuickJobs.png"),
      Health: require("../../images/categories/Health.png"),
      Art: require("../../images/categories/Art.png"),
      Housing: require("../../images/categories/Housing.png"),
      Travel: require("../../images/categories/Travel.png"),
      Commercial: require("../../images/categories/Commercial.png")
    };
    const categoryColors = {
      Electronics: { r: 30, g: 125, b: 184 },
      Home: { r: 84, g: 50, b: 28 },
      Fashion: { r: 51, g: 51, b: 51 },
      Cars: { r: 46, g: 51, b: 94 },
      Food: { r: 97, g: 35, b: 35 },
      Beauty: { r: 102, g: 38, b: 102 },
      Professionals: { r: 92, g: 92, b: 46 },
      Repair_Refill: { r: 148, g: 92, b: 9 },
      Handyman: { r: 27, g: 144, b: 122 },
      QuickJobs: { r: 34, g: 53, b: 85 },
      Health: { r: 229, g: 0, b: 60 },
      Art: { r: 168, g: 47, b: 36 },
      Housing: { r: 92, g: 92, b: 46 },
      Travel: { r: 138, g: 113, b: 43 },
      Commercial: { r: 26, g: 105, b: 55 },
      xxxxxxxx: { r: 0, g: 128, b: 128 }
    };
    let authorColorCode =
      "rgba(" +
      categoryColors[this.state.category1].r + "," +
      categoryColors[this.state.category1].g + "," +
      categoryColors[this.state.category1].b + ",.5)";
    let authorColorCodeFull =
      "rgba(" +
      categoryColors[this.state.category1].r + "," +
      categoryColors[this.state.category1].g + "," +
      categoryColors[this.state.category1].b + ",1)";
    const authorColorCodeFlex = (opacity) => {
      return ("rgba(" +
        categoryColors[this.state.category1].r + "," +
        categoryColors[this.state.category1].g + "," +
        categoryColors[this.state.category1].b + "," + opacity + ")")
    }

    const colF = "#000";
    const colB = "#fff";
    const colBo = "#aaa";
    const wBo = 0;
    let titleSize = 20;
    if (this.state.title.length < 48) titleSize = 22;
    if (this.state.title.length < 40) titleSize = 24;
    if (this.state.title.length < 30) titleSize = 26;
    const text =
      this.state.text +
      `
    
    
    
    `;

    const sWidth = Dimensions.get("window").width;
    const sHeight = Dimensions.get("window").height;
    const fHeight = Math.max(sHeight - 125, sWidth - 20 + 250);

    const selectedScreen = Math.round((this.props.scrollPosition + sWidth) / sWidth)
    const numberStyle = { height: 30, width: 40, margin: 5, borderWidth: 0, borderRadius: 15, justifyContent: "center", alignItems: "center" }


    const calendarStrings = {
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      lastDay: "[Yesterday]",
      lastWeek: "dddd",
      sameElse: "DD-MM-YYYY"
    };

    //pStringO and aStringO are price before discount
    let pStringO = Math.round(this.state.price).toString();
    if (pStringO.length > 8) {
      pStringO = pStringO.slice(0, pStringO.length - 6) + "M";
    } else if (pStringO.length > 6) {
      pStringO = Number(pStringO.slice(0, pStringO.length - 5)) / 10;
      pStringO = pStringO.toString() + "M";
    } else if (pStringO.length > 4) {
      pStringO = pStringO.slice(0, pStringO.length - 3) + "K";
    }
    let aStringO = Math.round(this.state.priceACN).toString();
    if (aStringO.length > 8) {
      aStringO = aStringO.slice(0, aStringO.length - 6) + "M";
    } else if (aStringO.length > 6) {
      aStringO = Number(aStringO.slice(0, aStringO.length - 5)) / 10;
      aStringO = aStringO.toString() + "M";
    } else if (aStringO.length > 4) {
      aStringO = aStringO.slice(0, aStringO.length - 3) + "K";
    }

    //pString and aString are after discount
    const pDiscountedPrice = this.state.price - (this.state.priceDiscount || 0)
    let pString = Math.round(pDiscountedPrice).toString();
    if (pString.length > 8) {
      pString = pString.slice(0, pString.length - 6) + "M";
    } else if (pString.length > 6) {
      pString = Number(pString.slice(0, pString.length - 5)) / 10;
      pString = pString.toString() + "M";
    } else if (pString.length > 4) {
      pString = pString.slice(0, pString.length - 3) + "K";
    }
    const aDiscountedPrice = this.state.priceACN - (this.state.priceACNDiscount || 0)
    let aString = Math.round(aDiscountedPrice).toString();
    if (aString.length > 8) {
      aString = aString.slice(0, aString.length - 6) + "M";
    } else if (aString.length > 6) {
      aString = Number(aString.slice(0, aString.length - 5)) / 10;
      aString = aString.toString() + "M";
    } else if (aString.length > 4) {
      aString = aString.slice(0, aString.length - 3) + "K";
    }

    const gradient = ["rgba(0,0,0,.5)", "rgba(0,0,0,.5)"]
    // gradient = ["rgba(33,33,46,1)", "rgba(33,33,46,1)", "rgba(0,0,0,1)", "rgba(55,55,55,.5)"]

    let isLiked = false;
    if (global.myLikedPostIDs?.includes(this.props.postID)) isLiked = true;

    if (this.state.loadingPost) {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#223"
          }}
        >
          <ActivityIndicator
            size="large"
            color="#8888CC"
            style={{
              opacity: 1
            }}
            animating={true}
          />
        </View>
      );
    } else {
      return (
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: this.props.loadingColor
              ? this.props.loadingColor
              : "#111",
            borderRadius: 30
          }}
        >
          <Image
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 30
            }}
            source={{
              uri: this.state.pictureURL
            }}
          />
          <View
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              height: 50,
              width: "100%",
              borderTopRightRadius: 30,
              borderTopLeftRadius: 30,
              backgroundColor: this.props.hideAuthor
                ? "rgba(0,128,128,0)"
                : authorColorCode
            }}
          >
            {!!this.state.authorProfileID && !this.props.hideAuthor && (
              <Author
                type={"Post"}
                profileID={this.state.authorProfileID}
                onLoad={() => {
                  this.setState({ loadingAuthor: false });
                }}
                navigation={this.props.navigation}
                onGetPhoneNumber={phoneNumber => {
                  this.setState({ phoneNumber });
                }}
                onGetProfileID={(
                  authorProfileID,
                  authorProfileName,
                  authorBusinessName,
                  authorProfilePictureURL,
                  authorLocation,
                  authorUsesACN
                ) => {
                  this.setState({
                    authorProfileID,
                    authorProfileName,
                    authorBusinessName,
                    authorProfilePictureURL,
                    authorLocation,
                    authorUsesACN
                  });
                }}
                onNavigate={() => {
                  this.setState({ modalVisible: false, menuVisible: false });
                  this.props.onHideDetail();
                }}
              />
            )}
            {this.state.category1 == "QuickJobs" && <View
              style={{
                position: "absolute",
                left: 0,
                top: this.props.hideAuthor ? 0 : 50,
                height: 60,
                width: 100,
                borderTopRightRadius: 30,
                borderTopLeftRadius: 30,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <View transform={[
                { rotateZ: `-10deg` },
              ]}
                style={{
                  width: 70, height: 32, backgroundColor: "#4c8", borderWidth: 2, borderColor: "#6fa",
                  alignItems: "center", justifyContent: "center", borderRadius: 40
                }} >
                <Text style={{ fontWeight: "bold", fontSize: 24, fontStyle: "italic" }}>Job</Text>
              </View>
            </View>}
          </View>
          <View
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0, left: 0,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!!this.state.youtubeID && <View>
              <View
                style={{
                  backgroundColor: "rgba(198,198,198,0.6)", borderRadius: 50, paddingLeft: 6, paddingBottom: 1,
                  height: 100, justifyContent: 'center', alignItems: 'center', width: 100,
                }}>
                <Text style={{ color: "#000", fontSize: 64 }} >
                  {"▶"}
                </Text>
              </View>
            </View>}
          </View>
          {
            this.state.pictureURLs && this.state.pictureURLs.length > 1 && (
              <View
                style={{
                  width: "100%",
                  paddingHorizontal: 10,
                  height: 30,
                  position: "absolute",
                  top: 60,
                  alignItems: "flex-end",
                  justifyContent: "center"
                }}
              >
                {this.state.pictureURLs.length > 4 && <View
                  style={{
                    borderColor: "#fff", borderTopWidth: 0.5, borderLeftWidth: 0.5, borderRadius: 2,
                    height: 20, width: 20, marginBottom: -18, marginRight: 6
                  }} />}
                {this.state.pictureURLs.length > 3 && <View
                  style={{
                    borderColor: "#fff", borderTopWidth: 0.5, borderLeftWidth: 0.5, borderRadius: 2,
                    height: 20, width: 20, marginBottom: -18, marginRight: 4
                  }} />}
                {this.state.pictureURLs.length > 2 && <View
                  style={{
                    borderColor: "#fff", borderTopWidth: 0.5, borderLeftWidth: 0.5, borderRadius: 2,
                    height: 20, width: 20, marginBottom: -18, marginRight: 2
                  }} />}
                <View
                  style={{
                    borderColor: "#fff", borderWidth: 0.5, borderRadius: 2, backgroundColor: "rgba(198,198,198,0.5)",
                    height: 20, justifyContent: 'center', alignItems: 'center', width: 20,
                  }}>
                  <Text style={{ color: "#000", fontSize: 11, fontWeight: "bold" }} >
                    {"+" + (this.state.pictureURLs.length - 1)}
                  </Text>
                </View>
              </View>
            )
          }
          <View
            style={{
              position: "absolute",
              left: 0,
              bottom: this.state.title ? 53 : 15,
              height: 100,
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-end",
              paddingHorizontal: 6
            }}
          >
            <View>
              {this.state.nOfSwipesRight > 0 && (
                <View
                  style={{
                    flexDirection: "row",
                    backgroundColor: "rgba(0255,0255,0255,.0)",
                    alignItems: "center"
                  }}
                >
                  <Image
                    style={{
                      width: 19,
                      height: 16,
                      marginRight: 3,
                      borderRadius: 3
                    }}
                    source={require("../../images/icons/Heart.png")}
                  />
                  <Text style={{ fontWeight: "bold", shadowColor: "black", shadowOpacity: 1, fontSize: 16, color: "white" }}>
                    {this.state.nOfSwipesRight}
                  </Text>
                </View>
              )}
            </View>


            <View>
              {showDiscount && <View style={{
                backgroundColor: 'black', padding: 5, borderTopLeftRadius: 5, borderBottomLeftRadius: 5,
                borderRightWidth: 1, borderColor: '#ace600', marginBottom: 5, alignSelf: "flex-end"
              }}>
                <Text style={{ color: '#ff4d94', fontWeight: 'bold', fontSize: 9, marginBottom: 0 }}>DISCOUNT</Text>
                <Text style={{ color: '#ace600', fontWeight: 'bold', fontSize: 15, paddingTop: 0 }}>{discountCurrency}{disString} OFF</Text>
              </View>}
              {this.renderDelivery()}

              {this.renderPrice(pString, aString, pStringO, aStringO)}

            </View>
          </View>

          <View
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              height: 50,
              width: "100%",
              borderBottomRightRadius: 30,
              borderBottomLeftRadius: 30,
              paddingHorizontal: 10,
              backgroundColor: this.state.title ? "rgba(0255,0255,0255,.8)" : "rgba(0255,0255,0255,0)",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Text style={{ fontWeight: "bold", fontSize: 18 }}>
              {this.state.title}
            </Text>
            {/* <Text style={{ fontWeight: "bold", fontSize: 18, color:"green",  }}>
              {` N ${this.state.price}`}
            </Text> */}
          </View>
          {
            !!this.props.approverMode && (
              <View
                style={{
                  position: "absolute",
                  left: -22,
                  bottom: -150,
                  height: 150,
                  width: "115%",
                  //borderBottomRightRadius: 30,
                  //borderBottomLeftRadius: 30,
                  borderRadius: 5,
                  paddingHorizontal: 5,
                  paddingTop: 5,
                  backgroundColor: "#9aa"
                }}
              >
                <Text style={{ fontSize: 10 }}>{this.state.category1 + " / " + this.state.category2}</Text>
                <Text style={{ fontSize: 10 }}>{this.state.text}</Text>
                {/* <Text style={{ fontWeight: "bold", fontSize: 18, color:"green",  }}>
              {` N ${this.state.price}`}
            </Text> */}
              </View>
            )
          }
          <Modal
            animationType="fade"
            transparent={this.props.blackBackground ? false : true}
            visible={this.state.modalVisible}
            onRequestClose={() => {
              //alert("Modal has been closed.");
            }}
          >
            <View>
              {this.state.showTextFull ? this.renderFullText(text, fHeight, sWidth, sHeight, titleSize) :
                !this.state.watchingYoutube ? <TouchableWithoutFeedback
                  style={{
                    alignItems: "center",
                    justifyContent: "flex-end",
                    height: "100%",
                  }}
                  onPress={() => {
                    this.setState({ modalVisible: false, menuVisible: false, watchingYoutube: false });
                    this.props.onHideDetail();
                  }}
                >
                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "flex-end",
                      height: "100%",
                      backgroundColor: this.props.blackBackground ? "black" : "rgba(10,10,15,0.5)"
                    }}
                  >
                    <View
                      style={{
                        height: fHeight,
                        width: sWidth - 20,
                        width: sWidth - 20,
                        alignItems: "flex-end",
                        marginBottom: sHeight > 720 ? 50 : 5,

                        marginBottom: 10,
                        justifyContent: "flex-end",
                        backgroundColor: "#fff",
                        shadowOpacity: 0.5,
                        shadowOffset: { width: 0, height: 3 },
                        borderColor: "#fff",
                        borderBottomColor: "#ccc",
                        borderWidth: 5,
                        borderRadius: 35
                      }}
                    >
                      <View
                        style={{
                          width: "100%",
                          borderTopRightRadius: 30,
                          borderTopLeftRadius: 30,
                          backgroundColor: authorColorCodeFull,
                          borderWidth: 0,
                          height: 55
                        }}
                      >
                        <Image
                          source={images[this.state.category1]}
                          style={{
                            height: 100,
                            width: 180,
                            borderTopRightRadius: 30,
                            borderTopLeftRadius: 30,
                            opacity: 0.05,
                            marginBottom: -100,
                            alignSelf: "flex-end"
                          }}
                        />
                        {!!this.state.authorProfileID && (
                          <Author
                            type={"PostDetail"}
                            profileID={this.state.authorProfileID}
                            onLoad={() => {
                              this.setState({ loadingAuthor: false });
                            }}
                            onGetPhoneNumber={phoneNumber => {
                              this.setState({ phoneNumber });
                            }}
                            onGetProfileID={(
                              authorProfileID,
                              authorProfileName,
                              authorBusinessName,
                              authorProfilePictureURL,
                              authorLocation,
                              authorUsesACN
                            ) => {
                              this.setState({
                                authorProfileID,
                                authorProfileName,
                                authorBusinessName,
                                authorProfilePictureURL,
                                authorLocation,
                                authorUsesACN
                              });
                            }}
                            navigation={this.props.navigation}
                            onNavigate={() => {
                              this.setState({
                                modalVisible: false, menuVisible: false, watchingYoutube: false
                              });
                              this.props.onHideDetail();
                            }}
                            onPressMenu={() => {
                              this.setState({
                                menuVisible: true
                              });
                            }}
                          />
                        )}
                      </View>
                      <TouchableOpacity
                        onPress={() => {
                          this.setState({
                            modalVisible: false, menuVisible: false, watchingYoutube: false
                          });
                          this.props.onHideDetail();
                        }}
                        style={
                          {
                            // shadowOpacity: 0.5,
                            // shadowOffset: { width: 0, height: 3 },
                            // borderColor: "#fff",
                            // borderBottomColor: "#ccc",
                            // borderWidth: 5,
                            // borderRadius: 35
                          }
                        }
                      >
                        {!this.state.pictureURLs ? (<FadeInView>
                          <Image
                            style={{
                              width: sWidth - 30,
                              height: sWidth - 30
                            }}
                            source={{
                              uri: this.state.pictureURL
                            }}
                          />
                          {!!this.state.youtubeID && <TouchableOpacity style={{
                            marginTop: -(sWidth - 30), width: sWidth - 30, height: sWidth - 30, alignItems: "center", justifyContent: "center"
                          }}
                            onPress={() => { this.playYoutube(this.state.youtubeID) }}>
                            <View
                              style={{
                                backgroundColor: "rgba(198,198,198,0.95)", borderRadius: 50, paddingLeft: Platform.OS == "ios" ? 6 : 7, paddingBottom: Platform.OS == "ios" ? 1 : 5,
                                height: 100, justifyContent: 'center', alignItems: 'center', width: 100,
                              }}>
                              <Text style={{ color: "#000", fontSize: 64 }} >
                                {"▶"}
                              </Text>
                            </View>
                          </TouchableOpacity>}
                        </FadeInView>)
                          :
                          <View style={{ width: sWidth - 30, height: sWidth - 30 }}>
                            <ScrollView horizontal pagingEnabled
                              showsHorizontalScrollIndicator={true}
                              ref={ref => (this.sv = ref)}
                              scrollEventThrottle={0}
                              onScroll={this.handleScroll}
                              onScrollEndDrag={() => { this.hasMoved = false }}>
                              {this.state.pictureURLs.map((pictureURL, i) => {
                                return <View key={pictureURL}><Image onStartShouldSetResponder={() => true}
                                  onResponderRelease={(evt) => {
                                    if (!this.hasMoved || Platform.OS == "ios") {
                                      this.setState({ modalVisible: false, menuVisible: false, watchingYoutube: false });
                                      this.props.onHideDetail();
                                    } else this.hasMoved = false;
                                    return true
                                  }}
                                  style={{
                                    width: sWidth - 30,
                                    height: sWidth - 30
                                    //borderRadius: 35
                                  }}
                                  source={{
                                    uri: pictureURL
                                  }}
                                />
                                  {(!!this.state.youtubeIDs && this.state.youtubeIDs[i]) && <TouchableOpacity style={{
                                    marginTop: -(sWidth - 30), width: sWidth - 30, height: sWidth - 30, alignItems: "center", justifyContent: "center"
                                  }}
                                    onPress={() => { this.playYoutube(this.state.youtubeIDs[i]) }}>
                                    <View
                                      style={{
                                        backgroundColor: "rgba(198,198,198,0.95)", borderRadius: 50, paddingLeft: Platform.OS == "ios" ? 6 : 7, paddingBottom: Platform.OS == "ios" ? 1 : 5,
                                        height: 100, justifyContent: 'center', alignItems: 'center', width: 100,
                                      }}>
                                      <Text style={{ color: "#000", fontSize: 64 }} >
                                        {"▶"}
                                      </Text>
                                    </View>
                                  </TouchableOpacity>}
                                </View>
                              })}
                            </ScrollView>
                            <View
                              style={{
                                width: "100%",
                                paddingHorizontal: 10,
                                height: 34,
                                position: "absolute",
                                top: 10,
                                alignItems: "flex-start",
                                justifyContent: "space-between",
                                alignItems: "flex-end",
                                flexDirection: "row"
                              }}
                            >
                              <TouchableOpacity onPress={() => this.scroll("<")}>
                                <View
                                  style={{
                                    backgroundColor: "rgba(198,198,198,0.5)", borderRadius: 17, borderTopEndRadius: 0, borderBottomEndRadius: 0,
                                    height: 34, justifyContent: 'center', alignItems: 'center', width: 34,
                                  }}>
                                  <Text style={{ color: "#000", fontSize: 18 }} >
                                    {"◀"}
                                  </Text>
                                </View>
                              </TouchableOpacity>
                              <TouchableOpacity onPress={() => this.scroll(">")}>
                                <View
                                  style={{
                                    backgroundColor: "rgba(198,198,198,0.5)", borderRadius: 17, borderTopLeftRadius: 0, borderBottomLeftRadius: 0,
                                    height: 34, justifyContent: 'center', alignItems: 'center', width: 34,
                                  }}>
                                  <Text style={{ color: "#000", fontSize: 18 }} >
                                    {"▶"}
                                  </Text>
                                </View>
                              </TouchableOpacity>
                            </View>
                          </View>
                        }
                        <LinearGradient
                          //colors={[authorColorCodeFlex(0), authorColorCodeFlex(0.5), authorColorCodeFlex(0.75), authorColorCodeFlex(0.9), authorColorCodeFlex(1)]}
                          colors={['rgba(255,255,255,0)', 'rgba(255,255,255,.5)', 'rgba(255,255,255,.75)', 'rgba(255,255,255,.9)', 'rgba(255,255,255,1)']}
                          //colors={['rgba(0,0,0,0)', 'rgba(0,0,0,.5)', 'rgba(0,0,0,.75)', 'rgba(0,0,0,.9)', 'rgba(0,0,0,1)']}
                          style={{
                            position: "absolute",
                            left: 0,
                            bottom: 0,
                            height: 33,
                            width: "100%",
                          }}
                        />
                        <View
                          style={{
                            width: "100%",
                            paddingHorizontal: 5,
                            height: 65,
                            position: "absolute",
                            bottom: 30,
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            flexDirection: "row"
                          }}
                        >
                          <TouchableOpacity onPress={() => { this.likePost(!isLiked) }}>
                            <View
                              style={{
                                flexDirection: "row",
                                height: 25,
                                borderRadius: 30,
                                paddingHorizontal: 6,
                                backgroundColor: "rgba(0255,0255,0255,.0)",
                                alignItems: "center",
                                justifyContent: "flex-start"
                              }}
                            >
                              {!isLiked && Platform.OS == "android" && <Image
                                style={{
                                  width: 21,
                                  height: 18,
                                  marginRight: -21,
                                  borderRadius: 3,
                                  tintColor: "black"
                                }}
                                source={require("../../images/icons/HeartOpen.png")}
                              />}
                              {!isLiked && <Image
                                style={{
                                  width: 19,
                                  height: 16,
                                  marginRight: 3,
                                  borderRadius: 3,
                                  shadowColor: "black", shadowOpacity: 1,
                                }}
                                source={require("../../images/icons/HeartOpen.png")}
                              />}
                              {isLiked && <Image
                                style={{
                                  width: 19,
                                  height: 16,
                                  marginRight: 3,
                                  borderRadius: 3,
                                  shadowColor: "black", shadowOpacity: 1,
                                }}
                                source={require("../../images/icons/Heart.png")}
                              />}
                              {this.state.nOfSwipesRight > 0 && <View>
                                <Text style={{ fontWeight: "bold", shadowColor: "black", shadowOpacity: 1, color: "white", fontWeight: "bold", fontSize: 16, }}>
                                  {this.state.nOfSwipesRight}
                                </Text>
                              </View>}
                            </View>
                          </TouchableOpacity>
                          <View
                            style={{
                              paddingRight: 5,
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: 5
                            }}
                          >
                            {showDiscount && <View style={{
                              backgroundColor: 'black', padding: 5, borderTopLeftRadius: 5, borderBottomLeftRadius: 5,
                              borderRightWidth: 1, borderColor: '#ace600', marginBottom: 5, alignSelf: "flex-end"
                            }}>
                              <Text style={{ color: '#ff4d94', fontWeight: 'bold', fontSize: 9, marginBottom: 0 }}>DISCOUNT</Text>
                              <Text style={{ color: '#ace600', fontWeight: 'bold', fontSize: 15, paddingTop: 0 }}>{discountCurrency}{disString} OFF</Text>
                            </View>}

                            <View>
                              {this.renderDelivery()}
                              {this.renderPrice(pString, aString, pStringO, aStringO)}

                            </View>
                          </View>
                        </View>
                      </TouchableOpacity>
                      {!!this.state.menuVisible && (
                        <TouchableWithoutFeedback
                          onPress={() => {
                            this.setState({ menuVisible: false });
                          }}
                        >
                          <View
                            style={{
                              position: "absolute",
                              left: 0,
                              top: 0,
                              height: "100%",
                              width: "100%",
                              alignItems: "flex-end"
                            }}
                          >
                            <View
                              style={{
                                marginRight: 5,
                                marginTop: 60,
                                height: 200,
                                width: 200,
                                backgroundColor: "rgba(256,256,256,.7)",
                                borderRadius: 0,
                                borderColor: "#000",
                                borderWidth: 1
                              }}
                            >
                              <TouchableOpacity
                                style={{
                                  height: 50,
                                  width: 200,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: 15,
                                  borderBottomWidth: 1,
                                  borderColor: "#000"
                                }}
                                onPress={async () => {
                                  this.setState({
                                    modalVisible: false, menuVisible: false, watchingYoutube: false
                                  });
                                  this.props.onHideDetail();
                                  await new Promise(resolve => { setTimeout(resolve, 100); });
                                  this.props.navigation.navigate(
                                    "meSelectContact",
                                    {
                                      //xx change to home
                                      input: this.props.postID,
                                      purpose: "ShareGivenPost"
                                    }
                                  );
                                }}
                              >
                                <Text style={{ color: "#223", fontWeight: "bold" }}>
                                  Forward
                            </Text>
                              </TouchableOpacity>
                              <TouchableOpacity
                                style={{
                                  height: 50,
                                  width: 200,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: 15,
                                  borderBottomWidth: 1,
                                  borderColor: "#000"
                                }}
                                onPress={async () => {
                                  this.shareSocial()
                                }}
                              >
                                <Text style={{ color: "#223", fontWeight: "bold" }}>
                                  Share
                            </Text>
                              </TouchableOpacity>
                              <TouchableOpacity
                                style={{
                                  height: 50,
                                  width: 200,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: 15,
                                  borderBottomWidth: 1,
                                  borderColor: "#000"
                                }}
                                onPress={async () => {
                                  if (!this.state.authorProfileID) return;
                                  this.setState({
                                    modalVisible: false, menuVisible: false, watchingYoutube: false
                                  });
                                  this.props.onHideDetail();

                                  await new Promise(resolve => {
                                    setTimeout(resolve, 50);
                                  });

                                  let route = "meProfile";
                                  if (
                                    this.props.navigation.state.routeName ===
                                    "homeMain"
                                  ) {
                                    route = "homeProfile";
                                  }
                                  if (
                                    this.props.navigation.state.routeName ===
                                    "chatDetail"
                                  ) {
                                    route = "messageProfile";
                                  }
                                  if (
                                    this.props.navigation.state.routeName ===
                                    "homeChat"
                                  ) {
                                    route = "homeProfile";
                                  }
                                  if (
                                    this.props.navigation.state.routeName ===
                                    "meChat"
                                  ) {
                                    route = "meProfile";
                                  }
                                  if (
                                    this.props.navigation.state.routeName ===
                                    "jobsChat"
                                  ) {
                                    route = "jobsProfile";
                                  }

                                  let handoverID = this.state.authorProfileID;
                                  if (handoverID == global.myProfileID) {
                                    handoverID = null; // xx TBD: paste this everywhere
                                  }
                                  this.props.navigation.navigate(route, {
                                    profileID: handoverID
                                  });
                                }}
                              >
                                <Text style={{ color: "#223", fontWeight: "bold" }}>
                                  More from {this.state.authorProfileName?.split?.(" ")[0] || "this provider"}
                                </Text>
                              </TouchableOpacity>
                              <TouchableOpacity
                                style={{
                                  height: 50,
                                  width: 200,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: 15
                                }}
                                onPress={async () => {
                                  this.setState({
                                    modalVisible: false, menuVisible: false, watchingYoutube: false
                                  });
                                  this.props.onHideDetail();
                                  await new Promise(resolve => {
                                    setTimeout(resolve, 100);
                                  });

                                  this.props.navigation.navigate("meReport", {
                                    profileID: this.state.authorProfileID,
                                    profileName: this.state.authorProfileName,
                                    profileBusinessName: this.state
                                      .authorBusinessName,
                                    postID: this.props.postID
                                  });
                                }}
                              >
                                <Text style={{ color: "#223", fontWeight: "bold" }}>
                                  Report
                            </Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </TouchableWithoutFeedback>
                      )}
                      {/* <View
                    style={{
                      position: "absolute",
                      left: 40,
                      top: sWidth - 75,
                      height: 100,
                      width: 100
                    }}
                  >
                    <Svg height="165" width="300">
                      <Svg.G rotation="-0" origin="00, 00">
                        <Svg.Rect
                          x="170"
                          y="60"
                          height="40"
                          width="120"
                          fill="rgba(256,256,256,.0)"
                        />
                        {this.state.priceType != "NONE" &&
                          this.state.priceType != "Free" && (
                            <Svg.Text
                              fill="lightgreen"
                              fontSize="18"
                              x="230"
                              y="72"
                              textAnchor="middle"
                            >
                              {this.state.priceType}
                            </Svg.Text>
                          )}
                        {(this.state.price > 1 ||
                          this.state.priceType == "Free") && (
                            <Svg.Text
                              fill="green"
                              stroke="#fff"
                              fontSize="30"
                              fontWeight="bold"
                              x="230"
                              y="95"
                              textAnchor="middle"
                            >
                              {this.state.priceType == "Free"
                                ? "FREE"
                                : `₦ ${pString}`}
                            </Svg.Text>
                          )}
                      </Svg.G>
                    </Svg>
                  </View> */}
                      <View
                        style={{
                          width: "100%",
                          paddingHorizontal: 5,
                          marginTop: -20,
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Text style={{ fontWeight: "bold", fontSize: titleSize, }}>
                          {this.state.title}
                        </Text>

                      </View>
                      <View
                        style={{
                          width: "100%",
                          flex: 99,
                          alignItems: "flex-start",
                          justifyContent: "flex-start"
                        }}
                      >
                        {/* <ScrollView
                        style={{
                          flex: 6,
                          width: "100%",
                          paddingHorizontal: 5,
                          marginTop: 1
                        }}
                      > */}
                        <TouchableOpacity
                          onPress={() => { this.setState({ showTextFull: true }) }}
                          style={{
                            flex: 6,
                            width: "100%",
                            alignItems: "flex-start",
                            justifyContent: "flex-start"
                          }}
                        >
                          <Text style={{ fontSize: 12 }}>{text}</Text>
                          {/* <Text style={{ fontWeight: "bold", fontSize: 18, color:"green",  }}>
              {` N ${this.state.price}`}
               </Text> */}
                        </TouchableOpacity>
                        {/* </ScrollView> */}
                      </View>
                      {/***** Order Call Chat Follow *****/}
                      <View
                        style={{
                          height: 60,
                          width: "100%"
                          //backgroundColor: "yellow"
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: 60,
                            borderTopWidth: 0,
                            marginBottom: 10,
                            borderBottomColor: "#444",
                            borderTopColor: "#444",
                            borderRadius: 24
                          }}
                        >
                          {/***** Order *****/}
                          {this.state.category1 !== "QuickJobs" && <View
                            style={{
                              flex: 1,
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                          >
                            <TouchableOpacity
                              onPress={() => {
                                this.callOrder();
                              }}
                              style={{
                                alignItems: "center",
                                justifyContent: "flex-end",
                                backgroundColor: colB,
                                width: 80,
                                height: 55,
                                borderRadius: 18,
                                borderWidth: wBo,
                                borderColor: colBo,
                                paddingHorizontal: 8,
                                paddingVertical: 5
                              }}
                            >
                              <Image
                                style={{
                                  width: 24,
                                  height: 24,
                                  marginBottom: 3
                                }}
                                source={icons["RequestService"]}
                              />
                              <Text
                                style={{
                                  fontSize: 12,
                                  color: colF,
                                  fontWeight: "bold"
                                }}
                              >
                                Order
                            </Text>
                            </TouchableOpacity>
                          </View>}
                          {/***** Call *****/}
                          {this.state.phoneNumber != "" && (
                            <View
                              style={{
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center"
                              }}
                            >
                              <TouchableOpacity
                                onPress={() => {
                                  this.callCall();
                                }}
                                style={{
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                  backgroundColor: colB,
                                  width: 55,
                                  height: 55,
                                  borderRadius: 18,
                                  borderWidth: wBo,
                                  borderColor: colBo,
                                  paddingHorizontal: 8,
                                  paddingVertical: 5
                                }}
                              >
                                <Image
                                  style={{
                                    width: 25,
                                    height: 25,
                                    marginBottom: 4
                                  }}
                                  source={icons["Call"]}
                                />
                                <Text
                                  style={{
                                    fontSize: 12,
                                    color: colF,
                                    fontWeight: "bold"
                                  }}
                                >
                                  Call
                            </Text>
                              </TouchableOpacity>
                            </View>
                          )}
                          {/***** Chat *****/}
                          {!this.state.activityIndicatorChat ? (
                            <View
                              style={{
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center"
                              }}
                            >
                              <TouchableOpacity
                                onPress={() => {
                                  this.callChat();
                                }}
                                style={{
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                  backgroundColor: colB,
                                  width: 55,
                                  height: 55,
                                  borderRadius: 18,
                                  borderWidth: wBo,
                                  borderColor: colBo,
                                  paddingHorizontal: 8,
                                  paddingVertical: 5
                                }}
                              >
                                <Image
                                  style={{
                                    width: 20,
                                    height: 20,
                                    marginBottom: 5.5
                                  }}
                                  source={icons["Chat"]}
                                />
                                <Text
                                  style={{
                                    fontSize: 12,
                                    color: colF,
                                    fontWeight: "bold"
                                  }}
                                >
                                  Chat
                            </Text>
                              </TouchableOpacity>
                            </View>
                          ) : (
                            <View
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: colB,
                                width: 80,
                                height: 55,
                                borderRadius: 18,
                                borderWidth: wBo,
                                borderColor: colBo,
                                paddingHorizontal: 8,
                                paddingVertical: 5
                              }}
                            >
                              <ActivityIndicator
                                size="small"
                                color="#333"
                                style={{
                                  opacity: 1
                                }}
                                animating={true}
                              />
                            </View>
                          )}
                          {/********** Save to Wishlist **********/}
                          <View
                            style={{
                              flex: 1,
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                          >
                            {!this.state.wishlistChanging ? (
                              <TouchableOpacity
                                onPress={() => {
                                  this.toggleWishlist();
                                }}
                                style={{
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                  backgroundColor: colB,
                                  width: 80,
                                  height: 55,
                                  borderRadius: 18,
                                  borderWidth: wBo,
                                  borderColor: colBo,
                                  paddingHorizontal: 8,
                                  paddingVertical: 5
                                }}
                              >
                                {!this.state.isOnWishlist && (
                                  <View style={{ alignItems: "center" }}>
                                    <Image
                                      style={{
                                        width: 20,
                                        height: 20,
                                        marginBottom: 7.5
                                      }}
                                      source={icons["Save"]}
                                    />
                                    <Text
                                      style={{
                                        fontSize: 12,
                                        color: colF,
                                        fontWeight: "bold",
                                        textAlign: "center"
                                      }}
                                    >
                                      Wishlist
                                </Text>
                                  </View>
                                )}
                                {!!this.state.isOnWishlist && (
                                  <View style={{ alignItems: "center" }}>
                                    <View
                                      style={{
                                        backgroundColor: "#595",
                                        height: 15,
                                        width: 15,
                                        borderRadius: 5
                                      }}
                                    >
                                      <Image
                                        style={{ height: 15, width: 15 }}
                                        source={require("../../images/icons/Star.png")}
                                      />
                                    </View>
                                    <Text
                                      style={{
                                        fontSize: 12,
                                        color: colF,
                                        fontWeight: "bold"
                                      }}
                                    >
                                      Is On
                                </Text>
                                    <Text
                                      style={{
                                        fontSize: 12,
                                        color: colF,
                                        fontWeight: "bold"
                                      }}
                                    >
                                      Wishlist
                                </Text>
                                  </View>
                                )}
                              </TouchableOpacity>
                            ) : (
                              <View
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: colB,
                                  width: 80,
                                  height: 55,
                                  borderRadius: 18,
                                  borderWidth: wBo,
                                  borderColor: colBo,
                                  paddingHorizontal: 8,
                                  paddingVertical: 5
                                }}
                              >
                                <ActivityIndicator
                                  size="small"
                                  color="#333"
                                  style={{
                                    opacity: 1
                                  }}
                                  animating={true}
                                />
                              </View>
                            )}
                          </View>
                          {/***** Chat *****/}
                          {!this.state.activityIndicatorChat ? (
                            <View
                              style={{
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center"
                              }}
                            >
                              <TouchableOpacity
                                onPress={() => {
                                  this.callShare();
                                }}
                                style={{
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                  backgroundColor: colB,
                                  width: 55,
                                  height: 55,
                                  borderRadius: 18,
                                  borderWidth: wBo,
                                  borderColor: colBo,
                                  paddingHorizontal: 8,
                                  paddingVertical: 5
                                }}
                              >
                                <Image
                                  style={{
                                    width: 25,
                                    height: 25,
                                    marginBottom: 4, tintColor: "#6a6"
                                  }}
                                  source={icons["Share"]}
                                />
                                <Text
                                  style={{
                                    fontSize: 12,
                                    color: colF,
                                    fontWeight: "bold"
                                  }}
                                >
                                  Share
                            </Text>
                              </TouchableOpacity>
                            </View>
                          ) : (
                            <View
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: colB,
                                width: 80,
                                height: 55,
                                borderRadius: 18,
                                borderWidth: wBo,
                                borderColor: colBo,
                                paddingHorizontal: 8,
                                paddingVertical: 5
                              }}
                            >
                              <ActivityIndicator
                                size="small"
                                color="#333"
                                style={{
                                  opacity: 1
                                }}
                                animating={true}
                              />
                            </View>
                          )}
                        </View>
                      </View>
                    </View>
                    <TouchableOpacity
                      style={{
                        position: "absolute",
                        borderWidth: 0,
                        backgroundColor: "#000",
                        borderColor: "rgba(0,0,0,0.2)",
                        alignItems: "center",
                        justifyContent: "center",
                        width: 35,
                        top: sHeight - fHeight - 15,
                        left: 5,
                        height: 25,
                        borderRadius: 35
                      }}
                      onPress={() => {
                        this.setState({ modalVisible: false, menuVisible: false, watchingYoutube: false });
                        this.props.onHideDetail();
                      }}
                    >
                      <Image
                        style={{ width: 35, height: 35, opacity: 1 }}
                        source={require("../../images/icons/CancelWhite.png")}
                      />
                    </TouchableOpacity>
                  </View>
                </TouchableWithoutFeedback> :
                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "flex-end",
                      height: "100%",
                      backgroundColor: "#000"
                    }}
                  >
                    <WebView
                      originWhitelist={['*']}
                      style={{ width: sWidth, height: sHeight, marginVertical: ((sHeight - sWidth * 0.7) / 2) }}
                      source={{ uri: 'https://www.youtube.com/embed/' + this.state.playingYoutubeID }}
                      javaScriptEnabled={true}
                      domStorageEnabled={true}
                      useWebKit={true}
                    />
                    <TouchableOpacity
                      style={{
                        position: "absolute",
                        borderWidth: 0,
                        backgroundColor: "#000",
                        borderColor: "rgba(0,0,0,0.2)",
                        alignItems: "center",
                        justifyContent: "center",
                        width: 35,
                        top: sHeight - fHeight - 15,
                        left: 5,
                        height: 25,
                        borderRadius: 35
                      }}
                      onPress={() => {
                        this.setState({ menuVisible: false, watchingYoutube: false });
                        this.props.onHideDetail();
                      }}
                    >
                      <Image
                        style={{ width: 35, height: 35, opacity: 1 }}
                        source={require("../../images/icons/CancelWhite.png")}
                      />
                    </TouchableOpacity>
                  </View>}
            </View>
          </Modal>
        </View >
      );
    }
  }
}

class FadeInView extends Component {
  state = {
    opacity: new Animated.Value(0),
  }

  componentDidMount = () => {
    Animated.timing(this.state.opacity, {
      toValue: 1,
      duration: this.props.duration || 500,
      useNativeDriver: true,
    }).start();
  }

  render() {
    return (
      <Animated.View
        onLoad={this.onLoad}
        {...this.props}
        style={[
          {
            opacity: this.state.opacity,
            transform: [
              {
                scale: this.state.opacity.interpolate({
                  inputRange: [0, 1],
                  outputRange: [1, 1],
                })
              },
            ],
          },
          this.props.style,
        ]}
      />
    );
  }
}
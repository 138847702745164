import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  Image,
  ImageBackground,
  TouchableOpacity,
  ScrollView,
  TextInput,
  Modal,
  Keyboard,
  ActivityIndicator,
  Dimensions,
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import firebase from "firebase";
import "firebase/firestore";
import CachedImage from "../../shared/CachedImage"
const gStyles = global.gStyles //    "../../styles/gStyles"

class BulletPoint extends PureComponent {
  render() {
    return (
      <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
        <Text style={[this.props.style, { marginRight: 6 }]}>{`\u2022`}</Text>
        <Text style={this.props.style}>{this.props.children}</Text>
      </View>
    );
  }
}

export default class MeNetworkScreen extends Component {
  static navigationOptions = {
    headerTransparent: true,
    headerTintColor: "#A8CF45",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1,
      elevation: 1
    },
  };

  state = {
    name: "...",
    logo: null,
    background: null,
    description: "...",
    memberCount: 0,

    amMember: false,
    membershipRequested: false,
    amAdmin: false,
    loading: true,
    showJoinModal: false,
    requestText: "",
    modalPage: 1
  }

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  componentDidMount = async () => {
    const networkID = this.props.navigation.getParam('networkID')
    if (global.networks && global.networks.includes(networkID)) this.setState({ amMember: true })
    if (global.networkRequests && global.networkRequests.includes(networkID)) this.setState({ membershipRequested: true })
    if (global.networkAdminRights && global.networkAdminRights.includes(networkID)) this.setState({ amAdmin: true })

    //get comminityAsync
    let network;
    const networkString = await AsyncStorage.getItem("network::" + networkID)
    if (networkString) {
      console.log("Async Network received")
      network = await JSON.parse(networkString)
    } else {
      console.log("Async Network not yet stored, retrieving from firestore")
      try {
        const networkDoc = await firebase
          .firestore()
          .collection("Networks")
          .doc(networkID)
          .get();
        network = networkDoc.data()
        console.log(":Loaded network " + networkID)
      } catch (err) {
        console.log(err)
      }
    }
    this.updateNetwork(network, networkID)

    try {
      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Networks")
        .doc(networkID)
        .onSnapshot((doc) => {
          this.updateNetwork(doc.data(), doc.id)
        })
    } catch (err) {
      console.log(err)
    }
  };

  updateNetwork = (network, networkID) => {
    if (!network) return;
    this.setState({
      name: network.networkName, logo: network.networkLogoURL, background: network.networkBackgroundURL,
      description: network.networkDescription || "", memberCount: network.menberCount || 0, loading: false
    })
    AsyncStorage.setItem("network::" + networkID, JSON.stringify(network))
  }

  componentWillUnmount = () => {
    this.unsubscribeFirestore()
  }

  requestMembership = async () => {
    if (this.state.aa) return
    try {
      this.setState({ aa: true })
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "requestMembership", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          requestText: this.state.requestText,
          networkID: this.props.navigation.getParam('networkID'),
          myUID: global.myUID,
          idToken: global.idToken,
        })
      });
      const r2 = await r.json();
      console.log(r2)
      this.setState({ aa: false })

      if (r2.msg === "SUCCESS") {
        this.setState({ showJoinModal: false, membershipRequested: true });
        this.setState({ modalPage: 1 });
        await global.timeout(100)
        alert("Membership Request Successful.")
      } else {
        alert("Server Error");
      }
    } catch (err) {
      console.log(err);
      this.setState({ aa: false })
      alert("Server Error. Try again later.")
    }
  }

  renderNetworkDetails = () => {
    return (
      <View style={{ alignSelf: "stretch", marginVertical: 15, marginHorizontal: 5, alignItems: "center" }}>
        <CachedImage
          source={{ uri: this.state.logo }}
          style={{ width: 300, height: 200, marginTop: 0, resizeMode: "contain" }}
        />
        <View style={{ height: 20 }} />
        <View style={{ alignSelf: "stretch", padding: 12, backgroundColor: "rgba(20,20,25,0.8)", alignItems: "center", alignItems: "center" }}>
          <Text style={{ color: "#bbc", fontWeight: "bold", fontSize: 16 }}>{this.state.name}</Text>
          <View style={{ height: 20 }} />
          <Text style={{ color: "#bbc" }}>{this.state.description}</Text>
        </View>
      </View>)
  }

  render() {
    if (this.state.loading) return (
      <View style={{ height: 25, alignItems: "center", justifyContent: "center", backgroundColor: "#131313" }}>
        <ActivityIndicator size="small" color="#334" />
      </View>
    );

    if (!this.state.amMember) return (
      <View style={{ flex: 1, backgroundColor: "#131313", }}>
        <ScrollView style={{ flex: 1 }}>
          {this.renderNetworkDetails()}
          {/***** Join Network box *****/}
          {!this.state.membershipRequested ? global.app == "speakers" ? <View style={{ backgroundColor: "rgba(200,200,215,0.8)", padding: 16, margin: 20, alignSelf: "stretch" }}>
            <Text style={{ color: "black", fontWeight: "bold", fontSize: 16, marginBottom: 7 }}>
              Join now and benefit:
            </Text>
            <BulletPoint style={{ fontSize: 14, marginBottom: 5 }}>
              Get professional training and mentorship by the Speakers Connect team.</BulletPoint>
            <BulletPoint style={{ fontSize: 14, marginBottom: 5 }}>
              Engage in upcoming network events across Nigeria.</BulletPoint>
            <BulletPoint style={{ fontSize: 14, marginBottom: 5 }}>
              Be inspired by weekly updates and access to exclusive online training content.</BulletPoint>
            <BulletPoint style={{ fontSize: 14, marginBottom: 5 }}>
              Verified members appear as trusted professionals on top of the search results.</BulletPoint>
            <BulletPoint style={{ fontSize: 14, marginBottom: 5 }}>
              You will be able to receive requests by fellow network members, e.g. for larger training programs.</BulletPoint>
            <TouchableOpacity
              onPress={() => { this.setState({ showJoinModal: true }); }}
              style={[global.gStyles.button, { marginTop: 20 }]}
            >
              <Text style={global.gStyles.buttontext}>JOIN NOW</Text>
            </TouchableOpacity>
          </View>
            :
            <View style={{ backgroundColor: "rgba(200,200,215,0.8)", padding: 16, margin: 20, alignSelf: "stretch" }}>
              <Text style={{ color: "black", fontWeight: "bold", fontSize: 16, marginBottom: 7 }}>
                Join now and benefit:</Text>
              <BulletPoint style={{ fontSize: 14, marginBottom: 5 }}>
                We will verify your professional qualification.</BulletPoint>
              <BulletPoint style={{ fontSize: 14, marginBottom: 5 }}>
                Verified profiles will appear high in search results and get more requests.</BulletPoint>
              <BulletPoint style={{ fontSize: 14, marginBottom: 5 }}>
                You will be able to receive contract work requests by fellow network members.</BulletPoint>
              <BulletPoint style={{ fontSize: 14, marginBottom: 5 }}>
                You will be able to participate in upcoming network events.</BulletPoint>
              <BulletPoint style={{ fontSize: 14, marginBottom: 5 }}>
                You will receive regular network updates and access to training content.</BulletPoint>
              <TouchableOpacity
                onPress={() => { this.setState({ showJoinModal: true }); }}
                style={[global.gStyles.button, { marginTop: 20 }]}
              >
                <Text style={global.gStyles.buttontext}>JOIN NOW</Text>
              </TouchableOpacity>
            </View> :
            <View style={{ backgroundColor: "rgba(200,200,215,0.8)", padding: 16, margin: 20, alignSelf: "stretch", alignItems: "center", justifyContent: "center" }}>

              <Text style={{ color: "black", fontWeight: "bold", fontSize: 28, marginBottom: 7 }}>
                ✓
            </Text>
              <Text style={{ fontSize: 14, marginBottom: 5 }}>
                Membership Requested
                  </Text>
              <Text style={{ marginBottom: 5, fontSize: 11 }}>
                A representative will be in touch with you shortly.
                  </Text>
            </View>}
        </ScrollView>
        <ImageBackground
          style={{
            position: "absolute", top: 0, left: 0, right: 0, bottom: 0,
            width: Dimensions.get("window").width,
            height: Dimensions.get("window").height, zIndex: -1
          }}
          source={{ uri: this.state.background }}
        />
        <Modal
          animationType="slide"
          transparent={false}
          visible={this.state.showJoinModal}
          onRequestClose={() => {
            //alert("Modal has been closed.");
          }}
        >
          <View
            style={{
              flex: 1,
              backgroundColor: global.app == "education" ? "#ccc" : "#bbc",
              justifyContent: "center"
            }}
          >
            <TouchableOpacity
              activeOpacity={1}
              onPress={Keyboard.dismiss}
              accessible={false}
            >
              {this.state.modalPage == 1 ? (
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                    margin: 25, alignSelf: "stretch"
                  }}
                >
                  <Text style={{ fontSize: 22, alignSelf: "center", fontWeight: "bold", textAlign: "center" }}>
                    Join {this.state.name}
                  </Text>
                  <View style={{ height: 35 }} />
                  <Text
                    style={{ fontSize: 14, color: "#000", paddingBottom: 6, fontWeight: "bold", justifyContent: "flex-start" }}
                  >
                    {global.app == "speakers" ? "Existing Membership ID or Reason for Joining" : "Reason for Joining"}
                  </Text>
                  <View style={{ height: 75, alignSelf: "stretch", marginBottom: 15 }} >
                    <TextInput
                      onChangeText={requestText => {
                        this.setState({ requestText });
                      }}
                      multiline={true}
                      numberOfLines={3}
                      placeholder={global.app == "speakers" ? "e.g. I am passionate about ..." : "e.g. I am a professional with certification by ..."}
                      placeholderTextColor="#000"
                      style={{ marginBottom: 20, color: "#000", paddingLeft: 3, borderRadius: 5, height: 90, backgroundColor: "#ccd" }}
                      maxLength={500}
                      underlineColorAndroid="transparent"
                      defaultValue={this.state.requestText}
                      returnKeyType={"done"}
                      rowSpan={3}
                      bordered
                    />
                  </View>
                  <Text
                    style={{ marginTop: 35, fontSize: 14, color: "#000", paddingBottom: 6, fontWeight: "bold", justifyContent: "flex-start" }}
                  >
                    Next Steps
                  </Text>
                  <BulletPoint style={{ fontSize: 14, marginBottom: 5, }}>
                    A representative of {this.state.name} will get in touch with you shortly to verify any needed documents.
                  </BulletPoint>
                  <BulletPoint style={{ fontSize: 14, marginBottom: 5 }}>
                    A verification fee of N1000 will apply.
                  </BulletPoint>
                  <View style={{ height: 40 }} />
                  <TouchableOpacity
                    style={[global.gStyles.button, { margin: 21 }]}
                    onPress={async () => {
                      if (!this.state.requestText) return alert("Enter a reason for joining")
                      this.setState({ modalPage: 2 });
                    }}
                  >
                    <Text style={global.gStyles.buttontext}>
                      REQUEST MEMBERSHIP
                  </Text>
                  </TouchableOpacity>
                  <View style={{ height: 18 }} />
                  <TouchableOpacity
                    onPress={async () => {
                      this.setState({ showJoinModal: false });
                      await global.timeout(90)
                      this.setState({ modalPage: 1 });
                    }}
                    style={{ alignSelf: "center" }}
                  >
                    <Text style={{ color: "black", fontSize: 16 }}>
                      Maybe later
                    </Text>
                  </TouchableOpacity>
                </View>
              ) : (
                  <ScrollView
                    style={{
                      padding: 25,
                      backgroundColor: "#dbb",
                      borderRadius: 15,
                      height: "100%"
                    }}
                  >
                    <View style={{ height: 60 }} />
                    <Text style={{ fontSize: 24, alignSelf: "center" }}>
                      Agreement
                  </Text>
                    <View style={{ height: 25 }} />
                    <Text style={{ fontSize: 14 }}>
                      To request membership at {this.state.name}, you to agree to its membership Terms and Conditions.
                  </Text>
                    <Text style={{ fontSize: 2 }} />
                    {/* <Text style={{ fontSize: 14 }}>
                    Your profile information must be an honest representation of
                    your business.
                  </Text>
                  <Text style={{ fontSize: 2 }} />
                  <Text style={{ fontSize: 14 }}>
                    If you accept orders from customers, you have to fulfill
                    them unless there is convincing reason. Every customer will
                    be able to rate your work: The better your reviews, the more
                    jobs you will get in the future.
                  </Text>
                  <Text style={{ fontSize: 2 }} />
                  <Text style={{ fontSize: 14 }}>
                    All users have the chance to block users and report
                    fraudulent, misleading, insulting or other inappropriate
                    behavior or information. We will look into such reports and
                    act within 24 hours.
                  </Text> */}
                    <Text style={{ fontSize: 2 }} />
                    {/* <View style={{ flexDirection: "row" }}>
                    <Text style={{ fontSize: 14 }}>I agree to the </Text>
                    <TouchableOpacity
                      onPress={() =>
                        Linking.openURL(
                          "https://www.african-founders.com/terms-and-conditions"
                        )
                      }
                    >
                      <Text style={{ color: "blue" }}>
                        Terms and Conditions
                      </Text>
                    </TouchableOpacity>
                    <Text style={{ fontSize: 14 }}>.</Text>
                  </View> */}
                    <View style={{ height: 25 }} />
                    {!this.state.aa && <TouchableOpacity
                      style={[global.gStyles.button, { margin: 21 }]}
                      onPress={async () => {
                        // xx tbd: unmount this screen (but why??)
                        this.requestMembership()
                      }}
                    >
                      <Text style={global.gStyles.buttontext}>Okay, I agree</Text>
                    </TouchableOpacity>}
                    {!!this.state.aa && <View style={{ height: 60, justifyContent: "center", alignItems: "center", padding: 3 }}>
                      <ActivityIndicator size="small" color="#222828" animating={true}
                      />
                    </View>}
                    <View style={{ height: 25 }} />
                    <TouchableOpacity
                      onPress={async () => {
                        this.setState({ showJoinModal: false });
                        this.setState({ modalPage: 1 });
                      }}
                      style={{ alignSelf: "center" }}
                    >
                      <Text style={{ color: "black", fontSize: 14 }}>Cancel</Text>
                    </TouchableOpacity>
                    <View style={{ height: 100 }} />
                  </ScrollView>
                )}
            </TouchableOpacity>
          </View>
        </Modal>
      </View>
    );

    //case amMember
    return (
      <View style={{ flex: 1, backgroundColor: "#131313", alignItems: "center" }}>
        <View style={{ height: 25 }} />
        {this.renderNetworkDetails()}
        {!!this.state.amAdmin && <View style={{ width: '100%', margin: 10 }}>
          <TouchableOpacity style={[global.gStyles.buttonX, { marginTop: 10, width: Dimensions.get("window").width * .5, alignSelf: "center", height: 30, borderRadius: 20 }]}
            onPress={() => { this.props.navigation.navigate("meNetworkAdmin", { networkID: this.props.navigation.getParam('networkID') }) }}>
            <Text style={[global.gStyles.buttontX, { fontSize: 14 }]}>Show Admin Panel</Text>
          </TouchableOpacity>
        </View>}
        <ImageBackground
          style={{
            position: "absolute", top: 0, left: 0, right: 0, bottom: 0,
            width: Dimensions.get("window").width,
            height: Dimensions.get("window").height, zIndex: -1
          }}
          source={{ uri: this.state.background }}
        />
      </View>
    );
  }
}


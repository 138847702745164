import React, { Component } from "react";
import {
  Text,
  View,
  TextInput,
  Image,
  TouchableOpacity,
  TouchableHighlight,
  TouchableWithoutFeedback,
  Keyboard,
  Modal,
  ActivityIndicator
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import FundMyAccount from "./fundMyAccount";

export default class EscrowModal extends Component {
  state = {
    balance: 0,
    currency: global.walletType,
    showFund: false,
    loading: true,
    error: false
  };

  async componentDidMount() {
    try {

      if (!global.myUID || !global.walletType) {
        alert("Error. Try again");
        await global.timeout(100)
        this.props.onClose();
        await global.timeout(100)
        this.setState({ error: true });
        throw new Error("global.myUID not set.");
      }
      const myUserDoc = await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .onSnapshot(this.updateUser);

      this.setState({ loading: false });
    } catch (err) {
      console.log("Error fetching EscrowModal data", err);
    }
  }
  updateUser = async userSnapshot => {
    user = userSnapshot.data()
    if (global.walletType != "ACN" && user.usesACN || global.walletType == "ACN" && !user.usesACN) {
      alert("Error. Restart app.");
      await global.timeout(100)
      this.props.onClose();
      await global.timeout(100)
      this.setState({ error: true });
      return;
    }
    if (global.walletType == "NGN") {
      let balance =
        user.walletBalance -
        user.walletAmountInEscrowByYou;
      if (balance) {
        this.setState({ balance, loading: false });
      }
    } else if (global.walletType == "ACN") {
      let balance =
        user.coinBalance -
        user.coinAmountInEscrowByYou;
      if (balance) {
        this.setState({ balance, loading: false });
      }
    } else {
      this.setState({ error: true });
      await global.timeout(100)
      this.props.onClose();
    }
  };

  fundMyAccount = async () => {
    if (global.walletType == "NGN") {
      this.setState({ showFund: true });
    }
    if (global.walletType == "ACN") {
      this.props.onClose();
      await global.timeout(100)
      this.props.navigation.navigate("walletTrader");
    }
  };

  render() {
    const cur = global.currencySymbols[this.state.currency]
    if (!this.props.showModal) {
      return <View />;
    } else if (this.state.showFund) {
      return (
        <Modal
          animationType="fade"
          transparent={true}
          visible={this.props.showModal}
          onRequestClose={() => {
            //alert("Modal has been closed.");
          }}
        >
          <FundMyAccount
            walletBalance={this.state.balance}
            onClose={() => {
              this.setState({ showFund: false });
            }}
          />
        </Modal>
      );
    } else {
      const bRadius = 5;
      return (
        <Modal
          animationType="fade"
          transparent={true}
          visible={this.props.showModal}
          onRequestClose={() => {
            //alert("Modal has been closed.");
          }}
        >
          <TouchableWithoutFeedback
            onPress={Keyboard.dismiss}
            accessible={false}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: "rgba(0,0,0,0.5)",
                justifyContent: "space-between"
              }}
            >
              <View
                behavior="padding"
                enabled
                style={{
                  paddingTop: 5,
                  flex: 1,
                  backgroundColor: "#ccc",
                  justifyContent: "space-between",
                  marginHorizontal: 20,
                  marginVertical: 30,
                  marginBottom: 100
                }}
              >
                <View
                  style={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    margin: 25
                  }}
                >
                  <View style={{ flexDirection: "row", marginBottom: 30, backgroundColor: "#000", paddingHorizontal:5,alignItems: "flex-end", justifyContent: "center", marginTop: 15 }}>
                    <View style={{ padding:2.5, paddingVertical:6, backgroundColor: "#000" }}>
                      <Image
                        style={{ width: 40, height: 32, backgroundColor: "#000" }}
                        source={require("../../images/logos/home_logo.png")}
                      /></View>
                    <Text
                      style={{
                        fontSize: 22, alignSelf: "center", color:"#fff"
                      }}
                    >
                      Wakanda Escrow
                  </Text>
                  </View>

                  <View style={{ height: 18 }} />
                  <View
                    style={{
                      flexDirection: "row",
                      marginBottom: 5,
                      justifyContent: "flex-start",
                      alignSelf: "flex-start"
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 20,
                        marginRight: 4
                      }}
                    >
                      ➀
                    </Text>
                    <Text
                      style={{
                        fontSize: 16,
                        marginTop: 1
                      }}
                    >
                      {`You pay the job charge (${cur} ${
                        this.props.jobCharge
                        }) from your wallet to us.`}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      marginBottom: 5,
                      justifyContent: "flex-start",
                      alignSelf: "flex-start"
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 20,
                        marginRight: 4
                      }}
                    >
                      ➁
                    </Text>
                    <Text
                      style={{
                        fontSize: 16,
                        marginTop: 1
                      }}
                    >
                      We keep it until the job is done.
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      marginBottom: 5,
                      justifyContent: "flex-start",
                      alignSelf: "flex-start"
                    }}
                  >
                    <Text
                      style={{ fontSize: 20, marginRight: 4 }}
                    >
                      ➂
                    </Text>
                    <Text
                      style={{ fontSize: 16, marginTop: 1 }}
                    >
                      Once the job is done as agreed, the money is released.
                    </Text>
                  </View>
                  <View style={{ height: 40 }} />
                  {!!this.state.loading ? (
                    <View
                      style={{ justifyContent: "flex-start", alignSelf: "stretch", alignItems: "center" }}
                    >
                      {!this.state.error ? (
                        <ActivityIndicator
                          size="small"
                          color="#000"
                          style={{ margin: 20, alignSelf: "center" }}
                          animating={true}
                        />
                      ) : (
                          <Text>Error. Try again.</Text>
                        )}
                    </View>
                  ) : (
                      <View>
                        <Text
                          style={{ fontSize: 16, marginTop: 1 }}
                        >
                          {`Your wallet balance: ${cur + " " + this.state.balance}`}
                        </Text>
                        <View style={{ height: 20 }} />
                        {this.state.balance - this.props.jobCharge < 0 && (
                          <Text
                            style={{ fontSize: 14, marginTop: 1, color: "#900", fontWeight: "bold", textAlign: "center" }}
                          >
                            {`Your wallet needs at least N ${cur + " " + (this.props.jobCharge - this.state.balance)} funding to be able to complete the job`}
                          </Text>
                        )}

                        <View style={{ height: 5 }} />

                        {this.state.balance - this.props.jobCharge < 0 ? (
                          <TouchableOpacity
                            style={[
                              global.gStyles.button,
                              { margin: 21, flexDirection: "row", justifyContent: "center" }
                            ]}
                            onPress={() => {
                              this.fundMyAccount();
                            }}
                          >
                            <Text style={[global.gStyles.buttontext, {}]}>
                              Fund my wallet
                          </Text>
                          </TouchableOpacity>
                        ) : (
                            <TouchableOpacity
                              style={[
                                global.gStyles.button,
                                { margin: 21, flexDirection: "row", justifyContent: "center" }
                              ]}
                              onPress={() => { this.props.onPayNow(); }}
                            >
                              <Text style={[global.gStyles.buttontext, {}]}>Pay now</Text>
                            </TouchableOpacity>
                          )}
                      </View>
                    )}
                  <View style={{ height: 40 }} />
                  <TouchableHighlight
                    onPress={() => {
                      this.props.onClose();
                    }}
                    style={{ alignSelf: "center" }}
                  >
                    <Text style={{ color: "black", fontSize: 14 }}>Cancel</Text>
                  </TouchableHighlight>
                </View>
                <View style={{ flex: 1 }} />
              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      );
    }
  }
}

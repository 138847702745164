import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  TextInput,
  SafeAreaView,
  SectionList,
  Keyboard,
  Platform,
  Modal,
  Dimensions,
  ActivityIndicator
} from "react-native";
import * as Contacts from 'expo-contacts';
import * as Permissions from 'expo-permissions';
import firebase from "firebase";
import "firebase/firestore";
const gStyles = global.gStyles //    "../../styles/gStyles";
import Contact from "./Contact"

class SearchBar extends Component {
  state = {
    searchTerm: "",
    countryCode: global.walletType == "ACN" ? "+233" : "+234",
    modalVisible: false
  };

  callSearch = () => {

    let submissionSearchTerm = "";
    const searchLetters = this.state.searchTerm.replace(/[^a-zA-Z]/g, "")

    if (searchLetters.length > 0) { //is a business name
      submissionSearchTerm = this.state.searchTerm

    } else { //is a number
      let enteredNumber = this.state.searchTerm.replace(/[^\d]/g, "");
      if (enteredNumber.startsWith("00")) {
        alert("Invalid phone number"); return;
      }
      if (enteredNumber.startsWith("0")) {
        enteredNumber = enteredNumber.slice(1);
      }
      submissionSearchTerm = this.state.countryCode + enteredNumber;
      //console.log("pn", submissionSearchTerm, this.state.countryCode, enteredNumber)
    }
    this.props.onPress(submissionSearchTerm);
  }

  render() {
    const countryCodes = global.countryCodes

    searchLetters = this.state.searchTerm.replace(/[^a-zA-Z]/g, "")
    showLeft = !(searchLetters.length > 0) //Shows +234 immediately
    //showLeft = !(searchLetters.length > 0 || this.state.searchTerm.length == 0) //Shows +234 after first number typed

    return (
      <View
        style={{
          flexDirection: "row",
          alignSelf: "center",
          height: 40,
          width: "90%",
          backgroundColor: "#aaa",
          borderRadius: 20,
          borderBottomWidth: 0,
        }}
      >
        {!!showLeft && <TouchableOpacity
          onPress={() => {
            this.setState({ modalVisible: true });
          }}
          style={{
            alignItems: "center",
            alignSelf: "flex-start",
            padding: 1,
            borderRightWidth: 1,
            borderRightColor: "gray",
            marginRight: 4.5,
            width: 65,
            height: 40,
            justifyContent: "center",
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            borderColor: "gray"
          }}
        >
          <Text>{this.state.countryCode}</Text>
        </TouchableOpacity>}
        <TextInput
          style={{
            alignSelf: "stretch",
            height: 40,
            marginBottom: 20,
            color: "#FFFFFF",
            backgroundColor: "#aaa",
            borderRadius: 33,
            borderBottomWidth: 0,
            color: "black",
            paddingLeft: 10,
            flex: 1
          }}
          placeholder="Search Phone Number / Business Name"
          placeholderTextColor="#000"
          //keyboardType="numeric"
          underlineColorAndroid="transparent"
          underlineColor={"transparent"}
          returnKeyType="search"
          onChangeText={searchTerm => this.setState({ searchTerm })}
          onSubmitEditing={this.callSearch}
          ref={component => (this.myTextInput = component)}
          // clearButtonMode="always" //xx how set colour?
          value={this.state.text}
          keyboardDismissMode={'on-drag'}
          keyboardAppearance={"dark"}
          autoCompleteType={'off'}
          autoCapitalize={'none'}
          autoCorrect={false}
        />

        {this.state.searchTerm.length > 4 && (
          <TouchableOpacity
            style={{
              alignItems: "center",
              alignSelf: "flex-start",
              padding: 1,
              backgroundColor: "#A8CF45",
              marginLeft: 4.5,
              width: 47,
              height: 40,
              justifyContent: "center",
              borderTopEndRadius: 20,
              borderBottomEndRadius: 20,
              borderColor: "gray",
              borderLeftWidth: 1
            }}
            onPress={this.callSearch}
          >
            <Image
              style={{
                width: 18,
                height: 18,
                marginRight: 1
              }}
              source={require("../../images/icons/Search.png")}
            />
          </TouchableOpacity>
        )}
        <Modal
          animationType="slide"
          transparent={false}
          visible={this.state.modalVisible}
          onRequestClose={() => {
            this.setState({ modalVisible: false });
          }}
        >
          <TouchableOpacity
            onPress={() => this.setState({ modalVisible: false })}
            style={{
              height: 50,
              backgroundColor: "#000"
            }}
          />
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#000"
            }}
          >
            <View
              style={{
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <View style={{ paddingTop: 20 }}>
                <Text style={{ fontSize: 30, color: "white" }}>
                  Select Country
                  </Text>
              </View>
              <View style={{ marginTop: 0, marginBottom: 5 }} />
              <SectionList
                renderItem={({ item, index, section }) => (
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({
                        modalVisible: false,
                        countryCode: item.split("(")[1].split(")")[0]
                      });
                    }}
                    style={{
                      flex: 1,
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 20,
                      width: Dimensions.get("window").width,
                      backgroundColor: "#112",
                      borderWidth: 1,
                      borderColor: "#223"
                    }}
                  >
                    <Text
                      style={{ fontSize: 16, color: "white" }}
                      key={index}
                    >
                      {item}
                    </Text>
                  </TouchableOpacity>
                )}
                renderSectionHeader={({ section: { title } }) => (
                  <Text style={{ fontWeight: "bold" }}>{title}</Text>
                )}
                sections={[
                  {
                    title: "",
                    data: countryCodes
                  }
                ]}
                keyExtractor={(item, index) => item + index}
              />
            </View>
          </View>
          <TouchableOpacity
            style={{
              position: "absolute",
              borderWidth: 0,
              backgroundColor: "#556",
              borderColor: "rgba(0,0,0,0.2)",
              alignItems: "center",
              justifyContent: "center",
              width: 20,
              top: 20,
              left: 20,
              height: 25,
              borderRadius: 30
            }}
            onPress={() => this.setState({ modalVisible: false })}
          >
            <Image
              style={{ width: 25, height: 25, opacity: 1 }}
              source={require("../../images/icons/CancelBlack.png")}
            />
            {/* <Text style={{ fontSize: 26, color: "#666" }}>x</Text> */}
          </TouchableOpacity>
        </Modal>
      </View>
    );
  }
}



export default class MeAddContactsScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Add Contacts",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };

  state = {
    activityIndicatorAnimating: false,
    activityIndicatorAnimating2: false,
    resultID: 0,
    resultProfiles: [],
    resultType: null,
    noContactFound: false,
    followingIDs: [],
    followerIDs: [],
    viewSelection: "Following",
    showSearchBar: true
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    try {
      this.unsubscribeFirestore = await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .collection("Refs")
        .doc("contactRefs")
        .onSnapshot(this.updateContacts);
    } catch (err) {
      //alert("Error initiating database connection (AddContacts)");
      console.log("Error initiating database connection (AddContacts)", err);
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
    console.log("Component Contacts will unmount");
  }

  updateContacts = async contactRefSnapshot => {
    let fingIDs = contactRefSnapshot.data().followingProfileIDs;
    let ferIDs = contactRefSnapshot.data().followerProfileIDs;

    if (fingIDs) {
      this.setState({ followingIDs: fingIDs });
    }
    if (ferIDs) {
      this.setState({ followerIDs: ferIDs });
    }
  };

  search = async term => {
    try {
      this.setState({ activityIndicatorAnimating: true });
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "findContact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            phoneNumber: term,
            myUID: global.myUID,
            idToken: global.idToken
          })
        }
      );
      const r2 = await r.json();
      Keyboard.dismiss();

      console.log("Received contact. ", r2.msg);
      this.setState({ activityIndicatorAnimating: false });
      if (r2.msg === "NO_CONTACT_FOUND") {
        this.setState({ noContactFound: true });
        return;
      }
      if (r2.msg === "CONTACT_FOUND") {
        this.setState({ resultID: 0, resultType: null, noContactFound: false });
        await global.timeout(50)
        this.setState({ resultID: r2.contactID, resultType: "singleContact", noContactFound: false });
        return;
      }
      if (r2.msg === "CONTACTS_FOUND") {
        this.setState({ resultProfiles: [], resultType: "multipleContacts", noContactFound: false });
        await global.timeout(50)
        this.setState({ resultProfiles: r2.contacts, resultType: "multipleContacts", noContactFound: false });
        return;
      }
      alert("Error: " + r2.msg);
    } catch (e2) {
      alert("An error occurred. Check internet connection.");
      console.log(e2);
      this.setState({ activityIndicatorAnimating: false });
    }
  };

  callMatchPhonebook = async () => {
    global.fetchIdToken()
    if (this.state.activityIndicatorAnimating2) return;
    try {
      this.setState({ activityIndicatorAnimating2: true })
      const { status } = await Permissions.askAsync(Permissions.CONTACTS);
      if (status != "granted") {
        alert("You have not granted permission to access your contacts (Check app permissions settings)")
        return
      }

      const { data } = await Contacts.getContactsAsync({
      });
      console.log("CONTACTS RECEIVED", data)
      let numbers = []
      if (data.length > 0) {
        data.forEach((contact) => {
          if (contact.phoneNumbers)
            contact.phoneNumbers.forEach((number) => {
              let digits = ""
              if (number.number.startsWith("+")) digits += "+"
              digits += String(number.number).replace(/[^\d]/g, "");
              numbers.push(digits)
            })
        })
      }
      console.log("CONTACTS PROCESSED", numbers);
      if (numbers.length < 1) {
        this.setState({ noContactFound: true })
        return;
      }
      const r = await fetch(global.cloudFunctionURL + "findContacts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          phoneNumbers: numbers,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ activityIndicatorAnimating2: false })
      //console.log("r2",r2)
      if (r2.msg === "NO_CONTACTS_FOUND") {
        this.setState({ noContactFound: true });
        return;
      }
      if (r2.msg == "CONTACTS_FOUND") {
        //console.log("Matching profiles found", r2.profiles)
        this.setState({ resultProfiles: r2.profiles, resultType: "multipleContacts", showSearchBar: false, noContactFound: false })
        //alert("SUCCESS")
      } else {
        alert("Could not connect to server")
      }

    } catch (err) {
      this.setState({ activityIndicatorAnimating2: false })
      alert("Error accessing phone book")
      console.log(err)
    }
  }

  renderSingleContact = () => {
    return <ScrollView>
      {this.state.noContactFound && (
        <View
          style={{
            height: 300,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Text style={{ color: "white" }}>No result found.</Text>
        </View>
      )}
      {!this.state.noContactFound &&
        this.state.resultID != 0 && (
          <View>
            <Contact
              following={this.state.followingIDs.includes(this.state.resultID)}
              followed={this.state.followerIDs.includes(this.state.resultID)}
              contactID={this.state.resultID}
              navigation={this.props.navigation}
            />
          </View>
        )}
    </ScrollView>
  }

  renderMultipleContacts = () => {
    let title = this.state.showSearchBar ? "profiles found" : "phone contacts found on Wakanda"
    if (this.state.resultProfiles.length == 1) title = this.state.showSearchBar ? "profile found" : "phone contact found on Wakanda"
    return <ScrollView>
      {this.state.noContactFound && (
        <View
          style={{
            height: 300,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Text style={{ color: "white" }}>No result found.</Text>
        </View>
      )}
      {!this.state.noContactFound &&
        this.state.resultProfiles && (
          <View style={{ marginBottom: 10 }}>
            <Text style={{ color: "gray", fontSize: 18, marginLeft: 2, marginBottom: 6, alignSelf: "center" }}>{this.state.resultProfiles.length} {title}</Text>
            {this.state.resultProfiles.map((profile, i) => {
              return <Contact
                key={i}
                profile={profile}
                following={this.state.followingIDs.includes(profile.xID)}
                followed={this.state.followerIDs.includes(profile.xID)}
                navigation={this.props.navigation}
              />
            })}
          </View>
        )}
    </ScrollView>
  }

  render() {
    if (this.state.resultID == 0 && this.state.noContactFound == false) {
      //??
    }

    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#222" }}>
        <View style={{ backgroundColor: "#222", flex: 1 }}>
          <View
            style={{
              borderBottomColor: "#555555",
              borderWidth: 0,
              ...Platform.select({
                ios: {
                  height: 55
                },
                android: {
                  height: 105
                }
              })
            }}
          />
          {!!this.state.showSearchBar && <SearchBar
            onPress={text => {
              this.search(text);
            }}
          />}
          <ActivityIndicator
            size="small"
            color="#FFFFFF"
            style={{
              opacity: this.state.activityIndicatorAnimating ? 1.0 : 0.0
            }}
            animating={true}
          />
          {this.state.resultType !== "multipleContacts" ? this.renderSingleContact() : this.renderMultipleContacts()}
          {this.state.resultType !== "multipleContacts" && <View
            style={{
              marginLeft: 20,
              marginRight: 20,
              marginTop: 10,
              marginBottom: 10,
              alignItems: "center"
            }}
          >
            <Text style={{ color: "white", marginVertical: 12 }}>{"Some of your phone contacts may be on " + (global.a.appName || "Wakanda") + ". See who it is and follow them!"}</Text>
            <TouchableOpacity style={global.gStyles.button} onPress={this.callMatchPhonebook}>
              <View style={{ flexDirection: "row" }}>
                {!!this.state.activityIndicatorAnimating2 ? (
                  <ActivityIndicator
                    size="small"
                    color="#000"
                    style={{
                      marginRight: 20
                    }}
                    animating={true}
                  />
                ) : (
                  <View style={{ width: 40, height: 20 }} />
                )}
                <Text style={global.gStyles.buttontext}>Check my phone contacts!</Text>
                <View style={{ width: 44 }} />
              </View>
            </TouchableOpacity>
            <Text style={{ color: "gray", margin: 20, marginTop: 10, marginBottom: 35, fontSize: 10 }}>We will not store your phone contacts on our servers</Text>

          </View>}


        </View>
      </SafeAreaView>
    );
  }
}

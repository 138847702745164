
import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  Linking,
  Platform,
  Dimensions,
  ActivityIndicator,
  KeyboardAvoidingView,
  Image,
  Keyboard,
  TextInput
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import Moment from "react-moment";
//import * as Permissions from 'expo-permissions'
import HealthChat from "./HealthChat"
import DoctorNotes from "./DoctorNotes"

//import { Audio } from 'expo-av';


export default class HealthRecordScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    return {
      tabBarVisible: false,
      headerTransparent: true,
      title: navigation.getParam("title", "") || "",
      headerTintColor: "black",
      headerTitleStyle: {
        fontWeight: "bold",
        color: "red",
      }
    }
  };

  state = {
    screen: "LOADING", // can be "MAIN",
    file: {},
    record: {},
    showChat: false,
    showNotes: false,
    notificationCount: 0,
    fileLoaded: false,
    amDoctor: false,
    showChatButton: true,
    showNotesButton: false,

    keyboardHeight: 0,
  };

  async componentDidMount() {
    const file = this.props.navigation.getParam("file", null)
    const fileID = this.props.navigation.getParam("fileID", null)
    let record = this.props.navigation.getParam("record", null)
    const recordID = this.props.navigation.getParam("recordID", null)
    if (record && file) {
      this.setState({ record, file, fileLoaded: true, screen: "MAIN" })
    } else if (record && fileID) {
      this.loadHealthFile(fileID)
      this.setState({ record, screen: "MAIN" })
    } else if (recordID) {
      await this.loadHealthRecord(recordID)
      this.loadHealthFile(this.state.fileID)
      this.setState({ screen: "MAIN" })
    } else {
      this.props.navigation.goBack();
      return alert("System error")
    }

    if (!record) record = this.state.record
    if (global.amDoctor && record.patientProfileID !== global.myProfileID)
      this.setState({ amDoctor: true })
    this.setState({
      showChatButton: record.type == "Online Consultation",
      showNotesButton: record.type == "Online Consultation" && global.amDoctor
    })


    this.keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      this._keyboardDidShow
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      this._keyboardDidHide
    );
  }

  componentWillUnmount() {
    if (this.keyboardDidShowListener) this.keyboardDidShowListener.remove();
    if (this.keyboardDidHideListener) this.keyboardDidHideListener.remove();
  }

  _keyboardDidShow = async e => {
    if (Platform.OS == "ios") this.setState({ keyboardHeight: e.endCoordinates.height - 85 });
    else this.setState({ keyboardHeight: 1 });
    //if (Platform.OS != "ios") this.setState({ keyboardHeight: e.endCoordinates.height - 45 });
  };

  _keyboardDidHide = () => {
    this.setState({ keyboardHeight: 0 });
  };

  loadHealthRecord = async (recordID) => {
    try {
      const recDoc = await firebase
        .firestore()
        .collection("Health")
        .doc("data")
        .collection("HealthRecords")
        .doc(recordID)
        .get()
      const rec = recDoc.data()
      rec.xID = recordID
      await this.setState({ record: rec, fileID: rec.fileID })
    } catch (e2) {
      global.warn(e2, "error z34")
    }
  }

  loadHealthFile = async (fileID) => {
    try {
      const fileDoc = await firebase
        .firestore()
        .collection("Health")
        .doc("data")
        .collection("HealthFiles")
        .doc(fileID)
        .get()
      const file = fileDoc.data()
      this.setState({ file, fileLoaded: true })
    } catch (error) {
      global.warn(error, "710")
    }
  }


  renderMain() {
    const record = this.state.record
    const file = this.state.file
    const recordCreationDate = (record && global.stampToDate) && global.stampToDate(record.timeCreated)
    const sHeight = Dimensions.get("window").height
    const doBlur = this.state.showChat || this.state.showNotes

    const unreadMessages = global.healthChatNotifications ? (global.healthChatNotifications[this.state.amDoctor ? "doctor" : "patient"][record.xID] || 0) : 0

    return (<View style={{ backgroundColor: doBlur ? "#b88" : "#fff", flex: 1, width: "100%" }}>
      <TouchableOpacity onPress={() => { this.setState({ showChat: false, showNotes: false }) }} style={{ flex: 1 }} activeOpacity={1}>
        <Image style={{ width: "100%", alignSelf: "center", height: 150, justifyContent: "flex-end", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
          source={require("../../../images/images/DD.jpeg")} />
        <View style={{ marginTop: -50, height: 50, backgroundColor: "rgba(255,0,0,0.75)", justifyContent: "center", alignItems: "center", flexDirection: "row" }} >
          <Image style={{ width: 25, height: 30, tintColor: Platform.OS == "ios" ? "#faa" : "#fcc", marginRight: 8 }} source={require("../../../images/icons/Record.png")} />
          <Text style={{ color: "white", fontSize: Platform.OS == "ios" ? 28 : 22, fontWeight: "100", marginRight: 30 }}> Health Record</Text>
        </View>
        <ScrollView style={{ flex: 1, opacity: doBlur ? 0.3 : 1, }}>
          <View style={{ margin: 12, alignSelf: "stretch" }}>
            {this.state.fileLoaded ? <PatientProfile file={file} />
              : <ActivityIndicator size="small" color="gray" style={{ margin: 15 }} />}
            <Text style={{ fontWeight: "bold", fontSize: 20, marginTop: 10, marginBottom: 5, alignSelf: "center" }}>{record.type} </Text>
            <Text style={{ color: "red", fontSize: 14, marginBottom: 5, marginTop: 10, fontWeight: "bold" }}>Time created</Text>
            <Text style={{ fontSize: 12, marginBottom: 10 }}><Moment fromNow element={Text}>{recordCreationDate}</Moment></Text>
            <Text style={{ color: "red", fontSize: 14, marginBottom: 5, marginTop: 12, fontWeight: "bold" }}>Status</Text>
            <RecordStatus status={record.status || ""} />
            {!!record.doctorProfileID ? <View>
              <Text style={{ color: "red", fontSize: 14, marginBottom: 5, marginTop: 19, fontWeight: "bold" }}>Doctor</Text>
              <DoctorProfile healthProfileID={record.doctorProfileID} navigation={this.props.navigation} />
            </View>
              : <View style={{ height: 50, justifyContent: "center" }}>
                <Text style={{ color: "gray", fontSize: 12, marginBottom: 0, marginTop: 5 }}>{!this.props.hideNoDoctorMessage && "No doctor attended"}</Text>
              </View>}
            {!!record.data.complaint && <View>
              <Text style={{ color: "red", fontSize: 14, marginBottom: 5, marginTop: 12, fontWeight: "bold" }}>Complaint</Text>
              <Text style={{ fontSize: 12, marginBottom: 10 }}>{record.data.complaint}</Text>
            </View>}
            {/* {!!record.chatMessages && record.chatMessages.length > 0 && <View>
              <Text style={{ color: "red", fontSize: 14, marginBottom: 5, marginTop: 12, fontWeight: "bold" }}>Messages</Text>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View style={{ marginLeft: 10 }}>
                  {unreadMessages > 0 ? <Text style={{ fontSize: 16, color: "red" }}>{unreadMessages} unread</Text>
                    : <Text style={{ fontSize: 16, color: "gray" }}>{record.chatMessages.length}</Text>}
                </View>
                {!this.state.showChatButton && <TouchableOpacity style={{
                  marginLeft: 10, height: 25, borderRadius: 12.5,
                  backgroundColor: "red", alignItems: "center", justifyContent: "center", paddingHorizontal: 10
                }}
                  onPress={() => { this.setState({ showChatButton: true, showChat: true, showNotes: false }) }}>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Image style={{ width: 16, height: 16, tintColor: "white" }} source={require("../../../images/tabNavigator/Messages.png")} />
                    <Text style={{ color: "white" }}>Show Messages</Text>
                  </View>
                </TouchableOpacity>}
              </View>
            </View>} */}
          </View>
          <View style={{ height: 200 }} />
        </ScrollView>

        <View style={{ margin: 10 }}>
          <HealthChat recordID={!!(record?.data) && record.xID} showSystemMessages // showSystemMessages not yet functional
            setNotifications={(n) => { this.setState({ notificationCount: Math.min(n, this.state.record.chatMessages.length) }) }}
            visible={this.state.showChat}
            style={{
              position: "absolute", bottom: this.state.keyboardHeight || 0, left: 0, width: "100%",
              height: !this.state.showChat ? 0 : ((sHeight - 20 - this.state.keyboardHeight) / (this.state.keyboardHeight ? 1 : 2)),
              backgroundColor: "white", borderRadius: 10, borderColor: "#800", borderWidth: 1, shadowOpacity: 1
            }} />
        </View>
        {this.state.showNotesButton && <View style={{ position: "absolute", bottom: 0, left: 0, height: "100%", width: this.state.showNotes ? "100%" : 0 }}>
          <DoctorNotes parentRecordID={!!(record?.data) && record.xID}
            visible={this.state.showNotes}
          // style={{
          //   position: "absolute", bottom: this.state.keyboardHeight || 0, left: 0, width: "100%",
          //   height: !this.state.showChat ? 0 : ((sHeight - 20 - this.state.keyboardHeight) / (this.state.keyboardHeight ? 1 : 2)),
          //   backgroundColor: "white", borderRadius: 10, borderColor: "#800", borderWidth: 1, shadowOpacity: 1
          // }}
          />
        </View>}
        {this.state.showChatButton && <View style={{ position: "absolute", bottom: 10 + (this.state.keyboardHeight || 0), left: 0, width: 52, height: 65, alignItems: "center", flexDirection: "row" }}>
          <TouchableOpacity onPress={() => {
            if (this.state.showChat) this.setState({ showChat: false, keyboardHeight: 0 });
            else this.setState({ showChat: true, showNotes: false, notificationCount: 0 });
          }}>
            <View style={{ height: 50, width: 50, borderRadius: 25, backgroundColor: this.state.showChat ? "red" : "white", justifyContent: "center", alignItems: "center", borderWidth: 0.5, borderColor: "#000" }}>
              <Image
                style={{ width: 25, height: 25, tintColor: this.state.showChat ? "white" : "red" }}
                source={require("../../../images/tabNavigator/Messages.png")}
              />
              {this.state.notificationCount >= 1 && (
                <View
                  style={{
                    backgroundColor: "red", height: 21.875, width: 27.5, borderRadius: 11, alignSelf: "flex-end", alignItems: "center", marginTop: -36, marginRight: -8, marginLeft: 10, marginBottom: 10, borderWidth: 0.5, borderColor: "#fff", justifyContent: "center"
                  }}
                >
                  <Text style={{ color: "white", fontWeight: "bold", fontSize: 14 }}>
                    {this.state.notificationCount}
                  </Text>
                </View>
              )}
            </View>
          </TouchableOpacity>
        </View>}
        {this.state.showNotesButton && <View style={{ position: "absolute", bottom: 65 + (this.state.keyboardHeight || 0), left: 0, width: 52, height: 65, alignItems: "center", flexDirection: "row" }}>
          <TouchableOpacity onPress={() => {
            if (this.state.showNotes) this.setState({ showNotes: false, keyboardHeight: 0 });
            else this.setState({ showNotes: true, showChat: false });
          }}>
            <View style={{ height: 50, width: 50, borderRadius: 25, backgroundColor: this.state.showNotes ? "red" : "white", justifyContent: "center", alignItems: "center", borderWidth: 0.5, borderColor: "#000" }}>
              <Image
                style={{ width: 25, height: 25, tintColor: this.state.showNotes ? "white" : "red" }}
                source={require("../../../images/icons/Note.png")}
              />
              {/* {this.state.notificationCount >= 1 && (
                <View
                  style={{backgroundColor: "red", height: 21.875, width: 27.5, borderRadius: 11, alignSelf: "flex-end", alignItems: "center", marginTop: -36, marginRight: -8, marginLeft: 10, marginBottom: 10, borderWidth: 0.5, borderColor: "#fff", justifyContent: "center"}}
                >
                  <Text style={{ color: "white", fontWeight: "bold", fontSize: 14 }}>
                    {this.state.notificationCount}
                  </Text>
                </View>
              )} */}
            </View>
          </TouchableOpacity>
        </View>}
      </TouchableOpacity>
    </View >)
  }


  renderLoading() {
    return (<View style={{ backgroundColor: "#fff", height: "100%", width: "100%", alignItems: "center", justifyContent: "center" }}>
      <Image style={{ borderRadius: 17, height: 100, width: 200, marginBottom: -65 }}
        source={require("../../../images/images/DD.jpeg")} />
      <ActivityIndicator size="large" color="white" animating={true} />
      <Text style={{ color: "#888", fontSize: 18, fontStyle: "italic", marginTop: 50 }}>One moment...</Text>
    </View>)
  }

  render() {
    if (this.state.screen == "MAIN") return this.renderMain()
    if (this.state.screen == "LOADING") return this.renderLoading()
    return (<View />)
  }
}

class PatientProfile extends Component {
  state = { name: " ✧ ✧ ✧ ✧ ✧ ✧", pictureURL: null, }
  async componentDidMount() {
    try {
      const profileDoc = await firebase
        .firestore()
        .collection("Profiles")
        .doc(this.props.file.profileID)
        .get()
      const profile = profileDoc.data()
      this.setState({ pictureURL: profile.profilePictureURL, name: profile.profileName || "--" })
    } catch (error) {
      global.warn(error, "sdf_8");
    }
  }
  render() {
    const file = this.props.file
    let age = "", gender = ""
    if (file.generalInformation) { gender = file.generalInformation.gender; age = file.generalInformation.age }

    return (<View style={{ alignSelf: "stretch", borderRadius: 0 }}>
      <View style={{ alignSelf: "stretch", flexDirection: "row", alignItems: "center" }}>
        {this.state.pictureURL ? <Image
          style={{ width: 35, height: 35, borderRadius: 17, }}
          source={{ uri: this.state.pictureURL }}
        /> : <View style={{ width: 35, height: 35, borderRadius: 17, backgroundColor: "gray" }} />}
        <View style={{ marginLeft: 10 }}>
          <Text style={{ marginBottom: 2, fontWeight: "bold" }}>{this.state.name}</Text>
          <Text style={{ marginBottom: 2, fontSize: 12 }}>{age}, {gender}</Text>
          {/* <Text style={{ marginBottom: 2, fontWeight: "bold" }}>{this.state.name} ({age}, {gender})</Text> */}
        </View>
      </View>
    </View >);
  }
}
export class DoctorProfile extends Component {
  state = { name: " ✧ ✧ ✧ ✧ ✧ ✧", title: "", pictureURL: null, }
  async componentDidMount() {
    try {
      const profileDoc = await firebase
        .firestore()
        .collection("Health")
        .doc("data")
        .collection("HealthProfiles")
        .doc(this.props.healthProfileID)
        .get()
      const profile = profileDoc.data()
      this.setState({ pictureURL: profile.profilePictureURL, name: profile.profileName || "--", title: profile.profileJobTitle || " " })
    } catch (error) {
      global.warn(error, "sdfx");
    }
  }
  render() {
    return (<View style={{ alignSelf: "stretch", borderRadius: 0 }}>
      <TouchableOpacity onPress={() => { this.props.navigation.navigate("healthProfile", { profileID: this.props.healthProfileID }) }}>
        <View style={{ alignSelf: "stretch", flexDirection: "row", alignItems: "center" }}>
          {this.state.pictureURL ? <Image
            style={{ width: 35, height: 35, borderRadius: 17, }}
            source={{ uri: this.state.pictureURL }}
          /> : <View style={{ width: 35, height: 35, borderRadius: 17, backgroundColor: "gray" }} />}
          <View style={{ marginLeft: 10 }}>
            <Text style={{ marginBottom: 2, fontWeight: "bold" }}>{this.state.name}</Text>
            <Text style={{ marginBottom: 2, fontSize: 12 }}>{this.state.title}</Text>
          </View>
        </View>
      </TouchableOpacity>
    </View >);
  }
}

export class RecordStatus extends Component {
  render() {
    let statusString = this.props.status
    if (this.props.status == "CLOSED") statusString = "Closed"
    let statusStyle = { backgroundColor: "#6f6", borderColor: "green" }
    let statusTextStyle = {}
    if (this.props.status != "OPEN") statusStyle = { backgroundColor: "#cdc", borderColor: "#9b9" }
    if (this.props.status != "OPEN") statusTextStyle = { color: "#686" }
    if (this.props.small) {
      statusStyle = { borderWidth: 0 }
      if (this.props.status == "OPEN") statusTextStyle = { color: "#060" }
    }

    return (
      <View style={{ alignSelf: "stretch", flexDirection: "row", alignItems: "center" }}>
        <View style={[{ paddingHorizontal: 5, paddingVertical: this.props.small ? 1 : 5, marginVertical: 1, alignItems: "center", justifyContent: "center", borderRadius: 9, borderWidth: 2 }, statusStyle]}>
          <Text style={[{ fontSize: this.props.small ? 12 : 14, fontWeight: "bold" }, statusTextStyle]}>{statusString}</Text>
        </View>
      </View>)
  }
}

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  Alert,
  Dimensions
} from "react-native";
import * as Font from "expo-font";
import { LinearGradient } from "expo-linear-gradient";
import { Feather } from "@expo/vector-icons";
import { SvgCss } from "react-native-svg";
import firebase from "firebase";
import "firebase/firestore";
import { NavigationEvents } from "react-navigation";

import * as Find from "../../Find/findFunctions";
import Post from "../../Post/Post";

const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;
// const chatGroups = [
//   {
//     id: 1,
//     tile: "Ikeja Phone Sellers Group",
//     Text:
//       "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna"
//   },
//   {
//     id: 2,
//     tile: "Ikeja Laptop Sellers Group",
//     Text:
//       "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna"
//   },
//   {
//     id: 3,
//     tile: "Ikeja Phone Sellers Group",
//     Text:
//       "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna"
//   }
// ];

export default class CommunityPreview extends Component {
  state = {
    communities: [],
    showDetail: false,
    detailPostResult: null,
    loading: true,
    aaID: false,
    randomNumber: 0
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => {
      return;
    };
  }

  async componentDidMount() {
    try {
      if (!global.myUID)
        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID)
        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID)
        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID)
        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID)
        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID)
        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID)
        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID)
        await new Promise(resolve => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID)
        await new Promise(resolve => {
          setTimeout(resolve, 1500);
        });
      if (!global.myUID)
        await new Promise(resolve => {
          setTimeout(resolve, 2500);
        });

      await global.setLocationFast(10);

      const res = await Find.findCommunitiesAsync({
        amount: 4,
        category1: this.props.communityCategory
      });
      if (res !== -1) {
        this.setState({ communities: res, loading: false });
        //console.log("my chat groups", this.state.communities);
      }
    } catch (e) {
      console.warn("Error 923432");
      console.log("Error 923432", e);
    }
  }
  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  join = async (communityID) => {
    if (this.state.aaID) return
    this.setState({ aaID: communityID })
    try {
      const r = await fetch(global.cloudFunctionURL + "joinCommunity", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          communityID: communityID,
          action: 'join',
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      //this.profileSearchTerm = term
      const r2 = await r.json();
      this.setState({ aaID: null })
      await global.timeout(10)
      if (r2.msg == 'SUCCESS') {
        let route = "meCommunity"
        if (this.props.navigation.state.routeName.startsWith("home")) route = "homeCommunity"
        if (this.props.navigation.state.routeName.startsWith("chat")) route = "chatCommunity"
        this.props.navigation.navigate(route, { communityID })
      } else {
        alert('Something went wrong, Try again')
      }
    }
    catch (error) {
      global.warn(error, 'joinIT')
      alert('Something went wrong. Try again')
      this.setState({ aaID: null })
    }
  }

  render() {
    if (this.state.loading)
      return (
        <View
          style={{
            height: 150,
            alignItems: "center",
            justifyContent: "center",
            //backgroundColor: "#fff"
          }}
        >
          <ActivityIndicator size="small" color="#000" />
        </View>
      );
    return (
      <View
        style={{
          backgroundColor: "#FFFFFF",
          minHeight: 160,
          width: SCREEN_WIDTH*0.9,
          borderRadius: 12,
          // alignItems: "center",
          // justifyContent: "center",
          margin: 22,
          // alignSelf: "center",
          shadowColor: "#000",
          shadowOpacity: 0.05,
          bottom: 50
        }}
      >
        <View
          style={{
            flexDirection: "row",
            top: 10,
            justifyContent: "center",
            margin: 12
          }}
        >
          <Text
            style={{
              right: 70,
              bottom: 10,
              fontSize: 12,
              fontFamily: "ProximaNovaA-Bold",
              color: "#333333"
            }}
          >
            Communities                 {/*  Near You */}
          </Text>
          <TouchableOpacity style={{ left: 59, bottom: 10 }}
            onPress={() => { this.props.navigation.navigate("homeCommunities") }} >
            <Text
              style={{
                fontSize: 12,
                fontFamily: "ProximaNovaA-Regular",
                color: "#707070"
              }}
            >
              See All
            </Text>
          </TouchableOpacity>
        </View>

        {this.state.communities.map(item => {
          return (
            <TouchableOpacity
              key={item.xID + this.state.randomNumber}
              style={{
                backgroundColor: "#F5F5F5",
                height: 105,
                width: SCREEN_WIDTH*0.85,
                borderRadius: 12,
                alignItems: "center",
                justifyContent: "center",
                margin: 10,
                marginTop:2,
                alignSelf: "center"
              }}
              onPress={() => {
                if (Array.isArray(global.myCommunities) && global.myCommunities.includes(item.xID)) {
                  let route = "homeCommunity"
                  if (this.props.navigation.state.routeName.startsWith("home")) route = "homeCommunity"
                  if (this.props.navigation.state.routeName.startsWith("chat")) route = "chatCommunity"
                  this.props.navigation.navigate(route, { communityID: item.xID })
                } else {
                  this.join(item.xID)
                }
              }}
            >
              <View style={{ left: 120 }}>
                <Text
                  style={{
                    color: "#707070",
                    fontSize: 9,
                    fontFamily: "ProximaNovaA-Bold"
                  }}
                >
                  {item.memberProfileIDs.length > 1
                    ? item.memberProfileIDs.length + " Members"
                    : item.memberProfileIDs.length + " Member"}{" "}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <View
                  style={{
                    height: 60,
                    width: 60,
                    borderRadius: 12,
                    borderWidth: 0.2,
                    shadowOpacity: 0.1,
                    shadowColor: "black",
                    left: 8
                  }}
                >
                  <Image
                    source={{ uri: item.communityBackgroundPictureURL }}
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: 12
                    }}
                    resizeMode="cover"
                  />
                </View>
                <View style={{ marginLeft: 20 }}>
                  <View style={{}}>
                    <Text
                      style={{
                        fontFamily: "ProximaNovaA-Bold",
                        fontSize: 14,
                        color: "#333333"
                      }}
                    >
                      {item.name}
                    </Text>
                  </View>

                  <View style={{ width: 230 }}>
                    <Text
                      numberOfLines={3}
                      style={{
                        fontFamily: "ProximaNovaA-Regular",
                        fontSize: 12,
                        color: "#707070"
                      }}
                    >
                      {item.description}
                    </Text>
                  </View>
                </View>
              </View>
              {!global.myCommunities?.includes?.(item.xID) && ((!this.state.aaID || this.state.aaID !== item.xID) ? <View
                style={{
                  alignSelf: "flex-end",
                  paddingHorizontal: 5,
                }}
              >
                <Text style={{
                  paddingHorizontal: 9, fontFamily: "ProximaNovaA-Regular",
                  fontSize: 12,
                  color: "#333333"
                }}>
                  Join &gt;&gt;
                  </Text>
              </View> : <View
                style={{
                  height: 24,
                  borderRadius: 6,
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "stretch",
                  paddingHorizontal: 5,
                  backgroundColor: "#A8CF45",
                  opacity: 0.5
                }}
              >
                  <Text style={[global.gStyles.buttontX, { paddingHorizontal: 15 }]}>
                    Joining
                  </Text>
                </View>)}
            </TouchableOpacity>
          );
        })}
        <NavigationEvents onWillFocus={async() => {
          console.log("Rerender Communities")
          await global.timeout(500)
          this.setState({ randomNumber: Math.random() })
        }} />
      </View>
    );
  }
}

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  Alert,
  Dimensions,
  Linking,
} from "react-native";
import * as Font from "expo-font";
import { LinearGradient } from "expo-linear-gradient";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { SvgCss } from "react-native-svg";
import firebase from "firebase";
import "firebase/firestore";
import BottomMenu from "../../../shared/BottomMenu";
import Moment from "react-moment";
const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;

export default class BlogPostPreview extends Component {
  state = {
    watchingYoutube: false,
    showBottomMenu: false,
  };

  render() {
    let milliseconds = 0;
    let timeCreated = 0;

    milliseconds = this.props.post?.timeCreated?._seconds
      ? this.props.post?.timeCreated?._seconds * 1000 +
      this.props.post?.timeCreated?._nanoseconds / 1000000
      : this.props.post?.timeCreated?.seconds * 1000 +
      this.props.post?.timeCreated?.nanoseconds / 1000000;
    if (milliseconds > 0) timeCreated = new Date(milliseconds);

    return (
      <View
        style={{
          minHeight: 92,
          borderBottomWidth: 0,
          borderBottomColor: "#606072",
          alignItems: "center",
          width: SCREEN_WIDTH *0.90,

          margin: 6,
          borderRadius: 6,
          backgroundColor: "#242424",
          padding: 5,

          alignSelf: "center",
        }}
      >
        {!!this.state.watchingYoutube &&
          global.renderYoutubeModal(this.props.post.groupYoutubeID, () => {
            this.setState({ watchingYoutube: false });
          })}
        <View
          style={{
            width: "100%",
            flexDirection: "row",

            justifyContent: "space-between",
            // padding: 2,
            // margin: 2,
            paddingHorizontal: 15,
            marginHorizontal: 10,
          }}
        >
          <Text
            style={{
              color: "#979A9A",
              fontSize: 10,
              fontFamily: "ProximaNovaA-Regular",
            }}
          >
            <Moment fromNow element={Text}>
              {timeCreated?.getTime?.()}
            </Moment>
          </Text>
          {/* <Moment fromNow element={Text}>
                      {this.state.timeCreated.getTime()}
                    </Moment> */}
          {!!this.props.edit && (
            <TouchableOpacity
              onPress={() => {
                this.setState({ showBottomMenu: true });
              }}
            >
              <MaterialCommunityIcons
                name="dots-vertical"
                size={15}
                color="#979A9A"
              />
            </TouchableOpacity>
          )}
        </View>
        <View
          style={{
            alignSelf: "flex-start",
            padding: 5,
            margin: 5,
          }}
        >
          <Text
            style={{
              color: "white",
              fontSize: 15,
              fontFamily: "ProximaNovaA-Bold",
            }}
          >
            {this.props.post.groupTitle}
          </Text>
        </View>
        <Image
          style={{
            width: SCREEN_WIDTH * 0.90,
            height: 135,
            paddingRight: 8,
            borderRadius: 8.5,
          }}
          source={{
            uri: this.props.post.groupPictureURL,
          }}
        />

        {!!this.props.post.groupYoutubeID && (
          <TouchableOpacity
            style={{
              borderRadius: 14,
              paddingLeft: 6,
              height: 55,
              justifyContent: "center",
              alignItems: "center",
              width: 55,
              bottom: 92,
              marginBottom: -55,
            }}
            onPress={() => {
              this.setState({ watchingYoutube: true });
            }}
          >
            <Text style={{ color: "#333", fontSize: 42 }}>{"▶️"}</Text>
          </TouchableOpacity>
        )}
        <View>
          <Text
          numberOfLines={8}
            style={{
              fontSize: 14,
              fontFamily: "ProximaNovaA-Regular",
              color: "#979A9A",
              marginTop: 5,
              // flexWrap: "wrap"
            }}
          >
            {this.props.post.groupDetails}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            backgroundColor: "rgba(0255,0255,0255,.0)",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            padding: 5,
            margin: 5,
          }}
        >
          {/*<View
            style={{
              flexDirection: "row",
              backgroundColor: "rgba(0255,0255,0255,.0)",
              alignItems: "center",
            }}
          >
           <Image
              style={{
                width: 10,
                height: 10,

                // borderRadius: 3,
              }}
              source={require("../../../images/icons/Heart.png")}
            /> */}
          {/* <Text
              style={{
                fontSize: 10,
                fontFamily: "ProximaNovaA-Regular",
                color: "#979A9A",
                paddingHorizontal: 5,
              }}
            >
              {this.props.post.nOfLikes} likes
            </Text> 
          </View>*/}

          <View>
            <Text
              style={{
                fontSize: 10,
                fontFamily: "ProximaNovaA-Regular",
                color: "#979A9A",
                paddingHorizontal: 5,
              }}
            >
              {this.props.post.nOfComments} comments
            </Text>
          </View>
        </View>
        <BottomMenu
          items={["Edit Post", this.props.post.aStatus == "DEACTIVATED" ? "Take Post Live" : "Pause Post"]}
          visible={this.state.showBottomMenu}
          onSelectItem={async (item) => {
            if (item == "Edit Post") {
              const blog = this.props.blog || {};
              if (blog.xID)
                this.props.navigation.navigate("meBlogPostEdit", {
                  action: "edit",
                  blogPost: this.props.post,
                  blog,
                });
            } else if (item == "Take Post Live") {
              this.props.activatePost();
            } else if (item == "Pause Post") {
              this.props.pausePost();
            }
          }}
          onClose={() => {
            this.setState({ showBottomMenu: false });
          }}
          title={"Select action"}
        />
      </View>
    );
  }
}

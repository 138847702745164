//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
// Screen always "synced" between Wakanda Market and Health
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  TouchableHighlight,
  TextInput,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  Alert,
  Dimensions,
  Animated,
  Keyboard,
  ImageBackground
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Swipeable } from 'react-native-gesture-handler';
import NetInfo from '@react-native-community/netinfo';
import * as Notifications from 'expo-notifications'
import Moment from "react-moment";
import { NavigationEvents } from "react-navigation";
import firebase from "firebase";
import "firebase/firestore";
import { timeout } from "../shared/helpers";
import CachedImage from "../shared/CachedImage";
import { LinearGradient } from "expo-linear-gradient";

const outerRadius = 20
const innerRadius = 5


import PushNotificationHandler from "./PushNotificationHandler";
import BOGuide from "../shared/Guide/BOGuide";

// class SearchBox extends Component {
//   state = {};
//   searchChats = searchString => {
//     alert("Search not yet implemented");
//   };
//   render() {
//     return (
//       <View style={{ padding: 5, alignItems: "center", marginTop: 0 }}>
//         <TextInput
//           style={{
//             alignSelf: "stretch",
//             height: 50,
//             marginBottom: 0,
//             marginTop: 0,
//             color: "#FFFFFF",
//             borderBottomColor: "#FFFFFF",
//             borderBottomWidth: 1
//           }}
//           placeholder="Search messages"
//           placeholderTextColor="#008866"
//           //textAlign= "center"
//           underlineColor={"transparent"}
//           returnKeyType="search"
//           onChangeText={string => this.searchChats(string)}
//         />
//       </View>
//     );
//   }
// }

class Chat extends PureComponent {
  state = {
    hasUnreadMessages: false,
    chatProperties: {
      opponentProfileID: null,
      opponentName: "✧ ✧ ✧ ✧ ✧ ✧ ✧ ✧ ✧ ✧",
      opponentNameBusiness: "✧ ✧ ✧ ✧ ✧ ✧ ✧ ✧ ✧ ✧",
      opponentProfilePictureURL: null,
      opponentBackgroundPictureURL: null,
      nOfUnreadMessages: 0,
      lastMessage: "",
      lastMessageDate: null
    },
    isConnected: true,
    visible: true,
    inquiryStatus: false,
    opponentLoaded: false
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
    this.mySwipeable = {}
  }

  async componentDidMount() {

    if (this.props.type == "old") return this.mountWithOldChat();

    if (!global.isConnected) {
      this.cannotLoad();
      return;
    }
    global.messageNotifications = {};

    if (this.props.chatID) {
      const cmChatID = await AsyncStorage.getItem("cmChatID" + this.props.chatID.substring(5, 15))
      if (cmChatID) {
        const ob = JSON.parse(cmChatID)
        if (ob.chatProperties) this.setState({ chatProperties: ob.chatProperties })
        if (ob.hasUnreadMessages) this.setState({ hasUnreadMessages: ob.hasUnreadMessages })
      }
    }

    if (!global.myUID) await timeout(150);
    if (!global.myUID) await timeout(400);
    if (!global.myUID) await timeout(700);
    if (!global.myUID) await timeout(1200);
    if (!global.myUID) await timeout(1800);
    if (!global.myUID) await timeout(3500);
    if (!global.myUID) await timeout(5000);
    if (!global.myUID) await timeout(8000);
    if (!global.myUID) await timeout(10000);
    if (!global.myUID) await global.setGlobals();

    try {
      this.unsubscribeFirestore = await firebase
        .firestore()
        .collection("Chats")
        .doc(this.props.chatID)
        .onSnapshot(this.updateChat);

    } catch (err) {
      if (global.reportError) {
        global.reportError(err, "catches", "ChatMainChat");
      }
      console.log(err);
      this.cannotLoad();
    }

    // NetInfo.isConnected.addEventListener(
    //   "connectionChange",
    //   this.handleConnectivityChange
    // );
  }

  componentWillUnmount() {
    console.log("Component Chat (in chatMain) will unmount");
    if (this.unsubscribeFirestore) {
      this.unsubscribeFirestore();
    }
    // NetInfo.isConnected.removeEventListener(
    //   "connectionChange",
    //   this.handleConnectivityChange
    // );
  }

  // handleConnectivityChange = isConnected => {
  //   this.setState({ isConnected });
  // };

  updateChat = async chatSnapshot => {
    if (!chatSnapshot) return;
    let opponentProfileID = "";
    try {
      let chat = chatSnapshot.data();
      let myParticipantID = 0;
      if (!chat.participantProfileID1 || !chat.participantProfileID2) {
        console.warn("No participantProfileIDs.");
        return;
      } else {
        if (global.myProfileID === chat.participantProfileID1) {
          opponentProfileID = chat.participantProfileID2;
          myParticipantID = 1;
        } else {
          opponentProfileID = chat.participantProfileID1;
          myParticipantID = 2;
        }
      }
      let opponentName = "";
      let opponentProfilePictureURL = "";
      let opponentBackgroundPictureURL = "";
      let opponentNameBusiness = "";
      if (!this.state.opponentLoaded || !this.state.chatProperties || !this.state.chatProperties.opponentProfileID) {
        let opponentProfileSnapshot = await firebase
          .firestore()
          .collection("Profiles")
          .doc(opponentProfileID)
          .get();
        const opponentProfile = opponentProfileSnapshot.data();
        opponentName = opponentProfile.profileName;
        opponentProfilePictureURL = opponentProfile.profilePictureURL;
        opponentBackgroundPictureURL = opponentProfile.profilePictureBackgroundURL;
        opponentNameBusiness = opponentProfile.profileNameBusiness;
        this.props.onOpponentUpdated({
          chatID: this.props.chatID, chatProperties: { opponentProfileID, opponentName, opponentProfilePictureURL, opponentNameBusiness }
        })
      } else {
        opponentName = this.state.chatProperties.opponentName
        opponentProfilePictureURL = this.state.chatProperties.opponentProfilePictureURL
        opponentBackgroundPictureURL = this.state.chatProperties.opponentBackgroundPictureURL
        opponentNameBusiness = this.state.chatProperties.opponentNameBusiness
      }
      let lastMessage = "";
      let lastMessageDate = new Date(2000, 1, 1);
      if (chat.lastRead[global.myProfileID]) {
        lastMessageDate = chat.lastRead[global.myProfileID].toDate();
      }
      let unreadMessages = [];
      if (chat.chatMessages.length > 0) {
        lastMessage = chat.chatMessages[chat.chatMessages.length - 1].text;
        if (lastMessage == "" && chat.chatMessages[chat.chatMessages.length - 1].location)
          lastMessage = "Location shared"
        if (lastMessage == "" && chat.chatMessages[chat.chatMessages.length - 1].pictureURL)
          lastMessage = "Picture shared"
        if (lastMessage == "" && chat.chatMessages[chat.chatMessages.length - 1].video)
          lastMessage = "Video reaction"
        if (lastMessage == "" && chat.chatMessages[chat.chatMessages.length - 1].voiceURL)
          lastMessage = "▶️ Voice Message "
        if (lastMessage == "" && chat.chatMessages[chat.chatMessages.length - 1].contactID)
          lastMessage = "Contact shared"
        if (lastMessage == "" && chat.chatMessages[chat.chatMessages.length - 1].postID)
          lastMessage = "Offer or Sample shared"
        lastMessageDate = chat.chatMessages[
          chat.chatMessages.length - 1
        ].timeSent.toDate();

        if (!global.myProfileID) timeout(1500)
        if (!global.myProfileID) timeout(5000)
        if (!global.myProfileID) await global.setGlobals();
        let lastReadMillis = 1;
        if (chat.lastRead[global.myProfileID]) {
          lastReadMillis = chat.lastRead[global.myProfileID].toMillis();
        }
        unreadMessages = chat.chatMessages.filter(
          message => message.timeSent.toMillis() > lastReadMillis
        );
      }
      let nOfUnreadMessages = unreadMessages.length;

      //console.log("nofUnreadmsgs ", this.props.chatID,  nOfUnreadMessages);
      if (global.messageOpen == this.props.chatID) {
        nOfUnreadMessages = 0;
      }

      let inquiryStatus = false;
      if (chat.inquiryStatusCustomer) {
        if (
          (myParticipantID == 1 && chat.inquiryAuthor == 1) ||
          (myParticipantID == 2 && chat.inquiryAuthor == 2)
        ) {
          //am Customer
          if (chat.inquiryStatusProvider == "ACCEPTED") {
            inquiryStatus = "InquiryCustomer";
            if (lastMessage == "") {
              lastMessage = chat.inquiryText;
            }
            if (chat.inquiryStatusCustomer == "OPEN") {
              nOfUnreadMessages += 1;
            }
          }
        } else {
          //am Provider
          inquiryStatus = chat.inquiryChatExistedBefore
            ? "InquiryExistingProvider"
            : "InquiryProvider";
          lastMessage = chat.inquiryText;
          if (chat.inquiryStatusProvider == "OPEN") {
            nOfUnreadMessages += 1;
          }

          if (!chat.inquiryChatExistedBefore) {
            opponentProfilePictureURL =
              "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FInquiry.png?alt=media&token=70658c52-9b2a-432b-ab0a-745b2aefa19e";
            opponentName = opponentName.replace(/ .*/, ""); // == first word

            opponentNameBusiness = " ";
          }
        }
        lastMessageDate = chat.inquiryTimeCreated.toDate();
      }

      global.messageNotifications[this.props.chatID] = nOfUnreadMessages;
      let totalCount = 0;
      for (var el in global.messageNotifications) {
        if (global.messageNotifications.hasOwnProperty(el)) {
          totalCount += parseFloat(global.messageNotifications[el]);
        }
      }
      if (typeof global.cNotifications == "object") {
        for (var el in global.cNotifications) {
          if (global.cNotifications.hasOwnProperty(el)) {
            totalCount += parseFloat(global.cNotifications[el]);
          }
        }
      }

      //console.log("totalCount", totalCount);
      const parent = this.props.navigation.dangerouslyGetParent();
      parent.setParams({ notifications: totalCount });
      global.pushBadgeMessages = totalCount || 0
      global.updatePushBadge()
      // global.messageNotifications.forEach(n => {
      //   //totalCount += n;
      //   console.log(n)
      // });
      //nOfUnreadMessagesxxxxx

      let chatProperties = {
        opponentProfileID,
        opponentName,
        opponentNameBusiness,
        opponentProfilePictureURL,
        opponentBackgroundPictureURL,
        nOfUnreadMessages,
        lastMessage,
        lastMessageDate
      };

      this.setState({
        chatProperties,
        hasUnreadMessages: nOfUnreadMessages > 0,
        inquiryStatus,
        opponentLoaded: true
      });

      //global.print(chatProperties.opponentName)

      const txt = lastMessage ? lastMessage.length > 39 ? lastMessage.substring(0, 39) + "..." : lastMessage : ""
      this.props.onChange({
        txt,
        time: lastMessageDate,
        name: opponentName || "",
        ...(opponentNameBusiness && { nameB: opponentNameBusiness }),
        ...(nOfUnreadMessages > 0 && { unread: nOfUnreadMessages }),
        pic: opponentProfilePictureURL || "",
        myParticipantID
      });

      const storeThis = { chatProperties, hasUnreadMessages: nOfUnreadMessages > 0 }
      await AsyncStorage.setItem("cmChatID" + this.props.chatID.substring(5, 15), JSON.stringify(storeThis))
    } catch (err) {
      if (global.reportError) {
        global.reportError(err, "catches", "ChatMainChatUpdate");
      }
      console.log(err);
      //alert("Could not fetch chat. Check your internet connection");
      console.log(
        "Could not fetch chat. Check your internet connection",
        err,
        this.props.chatID
      );
    }

    //Image.prefetch(url);
  };

  mountWithOldChat = () => {
    const c = this.props.chatOld
    let chatProperties = {
      opponentProfileID: "old chat",
      opponentName: c.name || " ",
      opponentNameBusiness: c.nameB || " ",
      opponentProfilePictureURL: c.pic || null,
      nOfUnreadMessages: c.unread ? c.unread : 0,
      lastMessage: c.txt || " ",
      lastMessageDate: Date.parse(c.time)
    };

    this.setState({
      chatProperties,
      hasUnreadMessages: c.unread ? c.unread > 0 : false,
    });
  };

  cannotLoad = async () => {
    NetInfo.isConnected.addEventListener("connectionChange", this.tryToLoad);
  };
  tryToLoad = async isConnected => {
    console.log("*********+++++++++******+++++++*++*++*+*+*+*+ ", isConnected);
    if (isConnected) {
      //this.forceUpdate();
      await this.removeLoadListener();
      if (this.unsubscribeFirestore) {
        await this.unsubscribeFirestore();
        console.log("unsubscribing Firestore");
      }
      //this.props.onRemount();
      await timeout(50);
      console.log("redoing componentDidMount");
      this.componentDidMount();

      // const chatDoc = await firebase
      // .firestore()
      // .collection("Chats")
      // .doc(this.props.chatID)
      // await this.updateChat(chatDoc)
    }
  };
  removeLoadListener = async () => {
    await NetInfo.isConnected.removeEventListener(
      "connectionChange",
      this.handleConnectivityChange
    );
  };

  swipe = (action) => {
    if (!global.isConnected || !this.state.chatProperties.opponentProfileID) {
      if (this.mySwipeable) this.mySwipeable.close();
      return;
    }
    // if (action == "remove" && this.props.chatID && this.props.chatID.startsWith("z_s")) {
    if (this.props.chatID && this.props.chatID.startsWith("z_s")) {
      alert("Cannot hide or remove system messages.");
      if (this.mySwipeable) this.mySwipeable.close();
      return
    }

    if (action == "remove") {
      let text2 = "The chat will be irreversibly removed. The other person can still contact you."
      if (this.props.chatID && this.props.chatID.startsWith("z_WebChat")) text2 = "The chat will be irreversibly removed."
      Alert.alert("Remove Chat?", text2,
        [{ text: "Cancel", onPress: () => { if (this.mySwipeable) this.mySwipeable.close(); } },
        { text: "Yes", onPress: () => { this.executeSwipe(action); } }],
        { cancelable: true }
      );
    } else this.executeSwipe(action)
  }

  executeSwipe = async (action) => {
    this.setState({ visible: false })
    this.props.onSwipe(action)
    if (this.mySwipeable) this.mySwipeable.close();
  }

  renderLeftActions = () => {
    if (!this.state.chatProperties.opponentProfileID) {
      return (<View />)
    }
    return (<View style={{ backgroundColor: '#630d1a', justifyContent: 'center', flex: 1 }}>
      <Text style={{ color: '#fff', fontWeight: '600', padding: 20 }}>Remove</Text>
    </View>)
  }
  renderRightActions = () => {
    if (!this.state.chatProperties.opponentProfileID || this.props.type == "old") {
      return (<View />)
    }
    return (<View style={{ backgroundColor: '#0d632f', justifyContent: 'center', alignItems: 'flex-end', flex: 1 }}>
      <Text style={{ color: '#fff', fontWeight: '600', padding: 20 }}>Hide</Text>
    </View>)
  }

  render() {
    let hidden = false
    if (this.props.searchTerm) {
      hidden = true
      if (this.state.chatProperties) {
        if (this.state.chatProperties.opponentName && this.state.chatProperties.opponentName.toLowerCase().includes(this.props.searchTerm))
          hidden = false
        if (this.state.chatProperties.opponentNameBusiness && this.state.chatProperties.opponentNameBusiness.toLowerCase().includes(this.props.searchTerm))
          hidden = false
        if (this.state.chatProperties.lastMessage && this.state.chatProperties.lastMessage.toLowerCase().includes(this.props.searchTerm))
          hidden = false
      }
    }

    if (hidden) return (<View />)

    let B = props => <Text style={{ fontWeight: "bold" }}>{props.children}</Text>; //just for reference (delete?)
    let colF = global.app == "health" ? "black" : "#eef"; // color foreground
    let colF2 = global.app == "health" ? "black" : "#b0b0bb"; // color foreground
    let colFHi = global.app == "health" ? "white" : "black"; // color foreground highlighted
    let colB = global.app == "health" ? "white" : "black"; // color background
    let colBHi = global.app == "health" ? "#45A8A8" : "#A8CF45"; // color background highlighted
    let colBor = global.app == "health" ? "#5bb" : "#000000"; // color border

    const calendarStrings = {
      sameDay: "LT",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      lastDay: "[Yesterday]",
      lastWeek: "dddd",
      sameElse: "DD-MMM-YY"
    };

    let newM = this.state.hasUnreadMessages
      ? " (" + this.state.chatProperties.nOfUnreadMessages + ")"
      : "";

    // lineBreaks =
    //   this.state.chatProperties.lastMessage.substring(0, 84).split("\n")
    //     .length - 1;
    let text = this.state.chatProperties.lastMessage;
    if (text.startsWith("_PhoneNumber:")) text = "New message from your website..."

    if (!this.state.visible) return (<View style={{ backgroundColor: "#889", height: 1 }} />)

    return (
      <Swipeable
        renderLeftActions={this.renderLeftActions}
        renderRightActions={this.renderRightActions} ref={ref => (this.mySwipeable = ref)}
        onSwipeableLeftOpen={() => { this.swipe("remove") }}
        onSwipeableRightOpen={() => { this.swipe("hide") }}>
        <TouchableHighlight
          onPress={() => {
            if (this.props.hasOverlay) return this.props.onOverlayPress()
            if (this.state.chatProperties.opponentProfileID) {
              this.props.navigation.navigate("chatDetail", {
                chatID: this.props.chatID,
                chatProperties: this.state.chatProperties,
                prohibitProfile:
                  this.state.inquiryStatus == "InquiryProvider" ? true : false
              });
            }
          }}
          onLongPress={async () => {
            if (
              //global.devMode ||
              global.myUID == "+2348181088440" ||
              global.myUID == "+2340000000009"
            ) {
              //this.forceUpdate();
              //this.props.onRemount();
              alert("chatID: " + this.props.chatID);
            }
            await this.removeLoadListener();
            if (this.unsubscribeFirestore) {
              await this.unsubscribeFirestore();
              console.log("unsubscribing Firestore");
            }
            if (this.unsubscribeFirestore2) {
              await this.unsubscribeFirestore2();
              console.log("unsubscribing Firestore");
            }
            //this.props.onRemount();
            await timeout(150);
            this.componentDidMount();
            this.forceUpdate();
          }}
        >
          <View
            style={[
              {
                flexDirection: "row",
                flex: 1,
                height: 85,
                //width: 100,
                backgroundColor: colB,
                alignItems: "center",
                paddingLeft: 11,
                paddingRight: 11,
                shadowOpacity: 0.5,
                shadowOffset: { width: 0, height: 3 },
                borderWidth: global.app == "health" ? 0 : 1,
                borderWidth: 1,
                borderBottomColor: colBor,
                borderRadius: innerRadius,
                overflow: "hidden",
              },
              this.state.hasUnreadMessages && {
                backgroundColor: colBHi,
                height: 85
              }
            ]}
          >
            <View style={{ width: 58 }}>
              {!!this.state.chatProperties.opponentProfilePictureURL && (
                <View>
                  {/* <View style={{
                    width: 56,
                    height: 56,
                    borderRadius: 28,
                    backgroundColor: "white",
                  }} />
                  <View
                    style={{
                      width: 56,
                      height: 56,
                      borderRadius: 28,
                      backgroundColor: "black",
                      marginTop: -55,
                      marginBottom: -56,
                      paddingLeft: 1
                    }}
                  /> */}
                  <CachedImage
                    style={{
                      width: 55,
                      height: 55,
                      paddingRight: 3,
                      borderRadius: 28
                    }}
                    key={this.state.chatProperties.opponentProfilePictureURL}
                    source={{
                      uri: this.state.chatProperties.opponentProfilePictureURL
                    }}
                  />
                  {!!this.state.inquiryStatus && (
                    <Text
                      style={{
                        color: colF,
                        fontSize: 12,
                        fontWeight: "bold",
                        paddingRight: 8,
                        alignSelf: "center"
                      }}
                    >
                      Special Request
                    </Text>
                  )}
                </View>
              )}
            </View>
            <View style={{ flexDirection: "column", flex: 1 }}>
              <View style={{ flexDirection: "row" }}>
                <View
                  style={{
                    flexDirection: "column",
                    flex: 1,
                    paddingHorizontal: 8
                  }}
                >
                  <Text
                    style={[
                      { fontSize: 14, color: colF, fontWeight: "bold" },
                      this.state.hasUnreadMessages && {
                        fontWeight: "bold",
                        color: colFHi
                      }
                    ]}
                  >
                    {this.state.chatProperties.opponentName + newM}
                  </Text>
                  {this.state.chatProperties.opponentNameBusiness && (
                    <Text
                      style={[
                        { fontSize: 11, color: colF, fontWeight: "bold" },
                        this.state.hasUnreadMessages && {
                          fontWeight: "bold",
                          color: colFHi
                        }
                      ]}
                    >
                      {this.state.chatProperties.opponentNameBusiness}
                    </Text>
                  )}
                  <Text />
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    paddingHorizontal: 8,
                    alignItems: "baseline",
                    justifyContent: "flex-end"
                  }}
                >
                  <Text
                    style={[
                      { fontSize: 10, color: colF2, fontStyle: "italic" },
                      this.state.hasUnreadMessages && {
                        color: colFHi
                      }
                    ]}
                  >
                    {this.state.chatProperties.lastMessageDate != null && (
                      <Moment element={Text} calendar={calendarStrings}>
                        {this.state.chatProperties.lastMessageDate}
                      </Moment>
                    )}
                  </Text>
                </View>
              </View>
              <View style={{ paddingHorizontal: 8 }}>
                <Text
                  style={[
                    { fontSize: 10, color: colF2, },
                    this.state.hasUnreadMessages && {
                      fontWeight: "bold",
                      color: colFHi
                    }
                  ]}
                  numberOfLines={2}
                >
                  {text}
                  {/* {this.state.chatProperties.lastMessage} */}
                </Text>
              </View>
            </View>
            {!!this.state.chatProperties.opponentBackgroundPictureURL && <ImageBackground
              style={{
                position: "absolute", top: 0, left: 0, right: 0, bottom: 0,
                width: Platform.OS == "ios" ? "80%" : "110%",
                opacity: Platform.OS == "ios" ? 0.58 : 0.28,
                marginLeft: Platform.OS == "ios" ? 200 : 0,
                zIndex: -1
              }}
              source={{ uri: this.state.chatProperties.opponentBackgroundPictureURL }}
            >
              {Platform.OS == "ios" && <LinearGradient
                colors={[
                  this.state.hasUnreadMessages ? colBHi : "#000",
                  ...(!!this.state.hasUnreadMessages ? [colBHi, "transparent", "transparent"] : []),
                  "transparent",
                ]}
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 1 }}
                style={{
                  flex: 1,
                  height: "100%",
                  width: "110%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />}
            </ImageBackground>
            }
          </View>
        </TouchableHighlight>
      </Swipeable>
    );
  }
}

export default class ChatMainScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Messages",
    headerTitleStyle: {
      //fontWeight: "100",
      color: global.app == "health" ? "#45A8A8" : "#A8CF45",
      shadowOpacity: 0
    }
  };
  state = {
    chatIDs: [],
    chatsOld: [],
    loading: true,
    searchText: "",
    searchTerm: "",
    showOldMessages: false,
    notification: { data: "HELLO" },
    showNotificationHandler: false,
    incomingPushNotification: { origin: "-", data: "-" },
    showNewMenu: false,

    cIDs: null,
    cNotifications: {}
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = {timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
    this.unsubscribeFirestore2 = () => { return; };
    this.lastReorderRequest = null
    this.allChatData = {}
  }

  async componentDidMount() {
    try {

      const wCC1 = AsyncStorage.getItem("cmCommunityState")
      const cmChatIDs = await AsyncStorage.getItem("cmChatIDs")
      const cC1 = await wCC1
      if (cmChatIDs) {
        const chatIDs = JSON.parse(cmChatIDs)
        if (Array.isArray(chatIDs)) this.setState({ chatIDs })
      }
      if (cC1) {
        const { cIDs, cNotifications } = JSON.parse(cC1)
        if (cIDs) this.setState({ cIDs, cNotifications })
      }

      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 200); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 400); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 500); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 500); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 600); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 1000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 2000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 4000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 5000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 8000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 10000); });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 10000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 100000); });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID)
        return;

      this.loadPinnedCommunities()
      this.unsubscribeFirestore = await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .collection("Refs")
        .doc("chatRefs")
        .onSnapshot(async s => {
          //let connected = await NetInfo.isConnected;
          if (true) {
            this.updateChats(s);
          }
        });
      Notifications.addNotificationResponseReceivedListener(this._handleNotificationResponse);
      Notifications.setNotificationHandler({
        handleNotification: async () => ({
          shouldShowAlert: true,
          shouldPlaySound: true,
          shouldSetBadge: false,
        }),
      });
      global.loadingSuccessChatMain = true;

    } catch (err) {
      if (global.reportError) {
        global.reportError(err, "catches", "ChatMain");
      }
      //alert("Error initiating database connection (ChatMain)");

      global.loadingSuccessChatMain = false;
      console.log(
        "Error initiating database connection (ChatMain). Internet connection: " +
        global.isConnected, err
      );
    }
  }

  _handleNotificationResponse = async (r) => {
    console.log("Handling selected push notification")

    let data = {}
    if (r && r.notification && r.notification.request && r.notification.request.content &&
      r.notification.request.content.data) data = r.notification.request.content.data


    if (!data) return;
    // if (notification.origin == "selected") {

    console.log({ selectedPushNotificationData: data })

    /////////////////////
    if (data.route) {
      this.props.navigation.navigate(data.route, data.routeParams || {})
    }
    /////////////////////
    else if (data.showChatChatID) {
      if (this.redirectDataByChatID && this.redirectDataByChatID[data.showChatChatID]) {
        this.props.navigation.navigate("chatMain", {
          redirect: true,
          redirectParams: this.redirectDataByChatID[data.showChatChatID]
        })
      }
      else {
        this.props.navigation.navigate("chatMain")
      }
    }
    /////////////////////
    else if (data.showChatProfileID) {
      if (this.redirectDataByProfileID && this.redirectDataByProfileID[data.showChatProfileID]) {
        this.props.navigation.navigate("chatMain", {
          redirect: true,
          redirectParams: this.redirectDataByProfileID[data.showChatProfileID]
        })
      }
      else {
        this.props.navigation.navigate("chatMain")
      }
    }
    /////////////////////
    else if (data.showProfileProfileID) {
      this.props.navigation.navigate("homeProfile", { profileID: data.showProfileProfileID })
    }
    /////////////////////
    else if (data.showBlogBlogID) {
      this.props.navigation.navigate("homeBlog", { blogID: data.showBlogBlogID, blogTitle: "" })
    }
    /////////////////////
    else if (data.showDoctorConsult) {
      if (!global.amDoctor) await global.timeout(1000)
      if (!global.amDoctor) await global.timeout(2000)
      if (!global.amDoctor) await global.timeout(3000)
      if (!global.amDoctor) return;
      this.props.navigation.navigate("doctorConsult")
    }
    /////////////////////
    else if (data.showHealthConsult) {
      let lastHealthType = await AsyncStorage.getItem("lastHealthType");
      console.log("Redirecting " + lastHealthType)
      if (lastHealthType == "text")
        this.props.navigation.navigate("healthConsultText")
      else if (lastHealthType == "video")
        this.props.navigation.navigate("healthConsult")
      else
        this.props.navigation.navigate("homeHealth")
    }
    /////////////////////
    else if (data.showChatGroupID) {
      try {
        this.setState({ loading: true })
        while (!global.myUID) await global.timeout(100)

        await global.fetchIdToken()
        const r = await fetch(global.cloudFunctionURL + "findGroups", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            isWeb: true,
            // limit,
            type: "groupIDs",
            groupIDs: [data.showChatGroupID],
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
        const r2 = await r.json();
        this.setState({ loading: false })

        if (r2.msg === "SUCCESS") {
          if (r2.groups?.length > 0) {
            this.props.navigation.navigate({
              routeName: Platform.OS == "ios" ? "chatGroupDetail" : "homeGroupDetail",
              params: { group: r2.groups[0], commentID: data.commentID },
              key: data.showChatGroupID
            })
          } else alert("Oops - You no longer seem to have access to this")
        }
      } catch (err) {
        global.warn?.(err, "redirectShowChatGroupID");
        this.setState({ loading: false })
      }
    }
    /////////////////////
    else if (data.showCommunityCommunityID) {
      this.props.navigation.navigate({
        routeName: "homeCommunity", params: { communityID: data.showCommunityCommunityID },
        key: data.showCommunityCommunityID
      })
    }
    /////////////////////
    else if (data.showIntentIntentID || data.showProfileIntentID || data.showCommunityIntentID || data.showBlogIntentID || data.showPostIntentID) {
      this.props.navigation.navigate("homeMain")
      await global.timeout(250)
      alert("Check for updates to use this feature")
    }
  };

  componentWillUnmount() {
    console.log("Component chatMain will unmount");
    //Notifications.removeAllNotificationListeners() // may cause red screen on liveReload -> will be fixed in latest expo
    this.unsubscribeFirestore();
    this.unsubscribeFirestore2();
  }

  updateChats = async chatRefSnapshot => {
    if (!chatRefSnapshot) return;
    try {
      let chatRefs = chatRefSnapshot.data()
      if (!chatRefs) return
      let ids = chatRefs.chatIDs;
      this.sortableChatIDs = ids;
      let chatsOld = chatRefs.chatsOld || [];
      if (this.state.chatIDs && this.state.chatIDs.length > 1) {
        ids.sort((a, b) => {
          return this.state.chatIDs.indexOf(a) - this.state.chatIDs.indexOf(b)
        })
      }
      this.setState({ chatIDs: ids, chatsOld, loading: false });
    } catch (err) {
      if (global.reportError) {
        global.reportError(err, "catches", "ChatMainUpdate");
      }
      console.log("Error updating chats (probably network)");
    }
  };

  sortableChatIDs = [];
  lastUpdated = {};
  reorder = async (id, changes) => {
    const timestamp = changes.time
    this.lastUpdated[id] = timestamp;
    this.allChatData[id] = changes


    const now = new Date()
    this.lastReorderRequest = now
    await global.timeout(1000)
    if (this.lastReorderRequest != now) return;
    console.log("reorder of chat screen", id)

    this.sortableChatIDs.sort((a, b) => {
      if (!a in this.lastUpdated) {
        this.lastUpdated[a] = 0;
      }
      if (!b in this.lastUpdated) {
        this.lastUpdated[b] = 0;
      }
      return this.lastUpdated[b] - this.lastUpdated[a];
    });

    this.setState({
      chatIDs: this.sortableChatIDs
    });
    await AsyncStorage.setItem("cmChatIDs", JSON.stringify(this.sortableChatIDs))

    //check for aging chats
    const dat = new Date()
    this.lastAgingRequest = dat
    await global.timeout(20000)
    if (this.lastAgingRequest != dat) return;
    const lastAgingString = await AsyncStorage.getItem("lastAgingString")
    if (lastAgingString) {
      let lastAging = JSON.parse(lastAgingString)
      lastAgingDate = Date.parse(lastAging);
    }
    //if (lastAgingString && (dat - lastAgingDate) < 100000) { //1.6 minutes
    if (lastAgingString && (dat - lastAgingDate) < 100000000) { //1.1 days
      console.log("*** recently aged...", dat - lastAgingDate)
      return
    }
    let needsAging = false
    for (let key in this.allChatData) {
      //console.log("DATAGGGG this.allChatData[key]", this.allChatData[key])
      //console.log("AAA", (Number(this.allChatData[key].time) + 1000000000), "BBB", (dat + 1))
      if ((Number(this.allChatData[key].time) + 1000000000) < dat) { needsAging = true; break; } // 11 days
    }
    if (!needsAging) {
      await AsyncStorage.setItem("lastAgingString", JSON.stringify(dat))
      console.log("Successfully aged, no changes")
      return;
    }
    console.log("Aging now...")

    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "ageChats", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          chatData: this.allChatData,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      console.log("Aging result: " + r2.msg)
      if (r2.msg == "SUCCESS") await AsyncStorage.setItem("lastAgingString", JSON.stringify(dat))
    } catch (error) {
      console.log(error);
    }
  };

  loadPinnedCommunities = async () => {
    try {
      this.unsubscribeFirestore2 = await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .collection("Refs")
        .doc("notificationRefs")
        .onSnapshot(this.updatePinnedCommunities);
    } catch (e) {
      global.warn(e, "loadPinnedCommunities")
      if (this.state.cIDs?.length > 0) {
        this.setState({ cIDs: [], cNotifications: [] })
      }
    }
  }

  updatePinnedCommunities = async (refDoc) => {
    try {
      const ref = refDoc?.data?.()
      if (!ref) {
        if (this.state.cIDs?.length > 0) {
          this.setState({ cIDs: [], cNotifications: [] })
          AsyncStorage.setItem("cmCommunityState", JSON.stringify({ cIDs: [], cNotifications: [] }))
        }
        return
      }
      const cNotifications = {}
      const keys = Object.keys(ref.pinnedCommunityNotifications || {}) || []
      keys.forEach(key => {
        const innerKeys = Object.keys(ref.pinnedCommunityNotifications?.[key] || {})
        let count = 0
        innerKeys.forEach(k => {
          count += ref.pinnedCommunityNotifications?.[key]?.[k]
        })
        cNotifications[key] = count
      })


      const cIDs = Object.keys((cNotifications)) || []
      cIDs.sort((a, b) => {
        return (cNotifications[b] - cNotifications[a])
      })
      this.setState({ cIDs, cNotifications })
      global.cNotifications = cNotifications

      let totalCount = 0;
      for (var el in global.messageNotifications) {
        if (global.messageNotifications.hasOwnProperty(el)) {
          totalCount += parseFloat(global.messageNotifications[el]);
        }
      }
      if (typeof cNotifications == "object") {
        for (var el in cNotifications) {
          if (cNotifications.hasOwnProperty(el)) {
            totalCount += parseFloat(cNotifications[el]);
          }
        }
      }
      const parent = this.props.navigation.dangerouslyGetParent();
      parent.setParams({ notifications: totalCount });
      global.pushBadgeMessages = totalCount || 0
      global.updatePushBadge()


      await AsyncStorage.setItem("cmCommunityState", JSON.stringify({ cIDs, cNotifications }))
    } catch (e) {
      global.warn(e, "updatePinnedCommunities")
    }
  }



  showOldMessages = () => {
    this.setState({ showOldMessages: true })
  }

  swiped = async (chatID, action, currentStatus) => {
    let chat = null;
    if (action == "hide") {
      chat = this.allChatData[chatID]
      if (!chat) return
    }
    console.log("WILL DO", chatID, action, currentStatus, chat)
    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "ageChat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          chatID,
          action,
          currentStatus,
          myProfileID: global.myProfileID,
          ...(chat && { chat }),
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      console.log("AgeChat result: " + r2.msg)
      if (r2.msg !== "SUCCESS") {
        this.setState({ loading: true, chatIDs: [], chatsOld: [] })
        Notifications.removeAllNotificationListeners() // added here because inside componentWillUnmount, it causes red screen
        this.componentWillUnmount()
        await global.timeout(300)
        this.componentDidMount()
      }
    } catch (error) {
      console.log(error);
    }
  }

  search = async (term) => {
    let searchTerm = term.toLowerCase()
    this.setState({ searchTerm, searchText: term })
  }

  renderBlueSearchBar() {
    return (
      <View
        style={{
          borderBottomWidth: 0,
          height: 50,
          borderColor: "#333"
        }}
      >
        <View
          style={{
            flexDirection: "row",
            marginTop: 0,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <TextInput
            // style={{
            //   alignSelf: "stretch",
            //   height: 30,
            //   width: 300,
            //   marginBottom: 0,
            //   color: "#FFFFFF",
            //   borderBottomColor: "#FFFFFF",
            //   borderBottomWidth: 1
            // }}
            style={{
              alignSelf: "stretch", height: 30, margin: 10, color: "#eef", backgroundColor: "#223", borderRadius: 15, borderBottomWidth: 0, paddingLeft: 10, flex: 1
            }}
            placeholder="Search by name"
            placeholderTextColor="#eee"
            textAlign="center"
            underlineColorAndroid="transparent"
            underlineColor={"transparent"}
            onChangeText={searchTerm => this.search(searchTerm)}
            ref={component => (this.myTextInputBlue = component)}
            // clearButtonMode="always" //xx how set colour?
            value={this.state.searchText}
            keyboardShouldPersistTaps={'always'}
            keyboardDismissMode={'on-drag'}
            keyboardAppearance={"dark"}
            returnKeyType={"done"}
            onSubmitEditing={() => { Keyboard.dismiss() }}
            autoCompleteType={'off'}
            autoCapitalize={'none'}
            autoCorrect={false}
            onFocus={() => {
              if (Platform.OS == "android") this.myTextInputBlue.focus()
              if (this.state.showNewMenu) this.setState({ showNewMenu: false })
            }}
          />
          <TouchableOpacity style={{ marginLeft: -50, width: 50, height: 50, alignItems: "center", justifyContent: "center", paddingRight: 10, paddingBottom: 3 }}
            onPress={() => { this.setState({ searchTerm: "", searchText: "" }); this.myTextInputBlue.focus() }}>
            <Text style={{ color: "white", fontSize: 24, color: "#667" }}>×</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  renderCommunities = () => {
    if (this.state.searchTerm) return <View />
    return (
      <View style={{ width: "100%", height: 65, backgroundColor: "black", alignItems: "center", paddingHorizontal: 11, flexDirection: "row", borderBottomColor: "#222", borderBottomWidth: 0, borderBottomLeftRadius: innerRadius, borderBottomRightRadius: innerRadius }}>
        <ScrollView horizontal>
          {this.state.cIDs.map(cID => {
            const n = this.state.cNotifications?.[cID]
            return <TouchableOpacity key={cID}
              onPress={() => {
                this.props.navigation.navigate({
                  routeName: Platform.OS == "ios" ? "chatCommunity" : "homeCommunity",
                  //keyboard would not open on android in chatCommunity
                  params: { communityID: cID },
                  key: cID || "-"
                })
              }}
              style={{ marginRight: this.state.cIDs?.length > 8 ? 0 : 5, paddingTop: 6, paddingBottom: 6 }}
            >
              <CommunityPicture communityID={cID} />
              {n > 0 ? <View style={{ backgroundColor: "#A8CF45", height: 17, width: 22, borderRadius: 8, alignSelf: "flex-end", alignItems: "center", marginTop: -45, marginBottom: 35, marginLeft: -6, borderWidth: 1, borderColor: "#A8CF45", justifyContent: "center" }}>
                <Text style={{ color: "#000", fontSize: 10 }}>{n}</Text>
              </View> :
                <View style={{ height: 17, width: 22, alignSelf: "flex-end", alignItems: "center", marginTop: -45, marginBottom: 35, marginLeft: -6, justifyContent: "center" }}>
                  <Text style={{ color: "#000", fontSize: 10 }}></Text>
                </View>}
            </TouchableOpacity>
          })}
        </ScrollView>
      </View >
    )
  }


  render() {
    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: global.app == "health" ? "#fff" : "#000000"
        }}
      >
        <StatusBar barStyle="light-content" />

        <View
          style={{
            backgroundColor: global.app == "health" ? "#fff" : "#000000",
            flex: 1
          }}
        >
          <View
            style={{
              backgroundColor: global.app == "health" ? "#fff" : "#000000",
              borderBottomColor: global.app == "health" ? "#555" : "#555",
              borderBottomWidth: 0,
              ...Platform.select({
                ios: {
                  height: 55
                },
                android: {
                  height: 75
                }
              })
            }}
          />
          {/* <SearchBox /> */}
          <ScrollView
            showsVerticalScrollIndicator={false}
            //ref={ref => (this.myScrollView = ref)}
            scrollEventThrottle={1000}
            onScroll={(e) => {
              if (this.state.showNewMenu) { this.setState({ showNewMenu: false }) }
            }}>

            {!!this.state.showNotificationHandler && (
              <PushNotificationHandler />
            )}

            <View style={{ backgroundColor: "#000", width: "100%", borderWidth: 1, borderColor: "#444", borderTopEndRadius: outerRadius, borderTopLeftRadius: outerRadius, marginVertical: 0, marginLeft: 0, borderWidth: 0 }}>
              {this.renderBlueSearchBar()}
            </View>

            {!!this.state.cIDs && Array.isArray(this.state.cIDs) && this.state.cIDs.length > 0 && (
              this.renderCommunities()
            )}

            {this.state.chatIDs.map(id => {
              return (
                <Chat
                  key={id}
                  chatID={id}
                  navigation={this.props.navigation}
                  hasOverlay={this.state.showNewMenu}
                  onOverlayPress={() => this.setState({ showNewMenu: false })}
                  onChange={changes => {
                    this.reorder(id, changes);
                  }}
                  onRemount={async () => {
                    this.setState({ chatIDs: [] });
                    await timeout(150);
                    this.componentDidMount();
                  }}
                  onOpponentUpdated={(obj) => {
                    if (!this.redirectDataByProfileID) this.redirectDataByProfileID = {}
                    if (!this.redirectDataByChatID) this.redirectDataByChatID = {}
                    this.redirectDataByProfileID[obj.chatProperties.opponentProfileID || "err"] = obj
                    this.redirectDataByChatID[obj.chatID || "err"] = obj

                    // console.log(this.redirectDataByChatID)
                    // console.log(this.redirectDataByProfileID)
                  }}
                  onSwipe={(action) => {
                    this.swiped(id, action, "LIVE")
                  }}
                  searchTerm={this.state.searchTerm}
                />
              );
            })}

            {(!!this.state.showOldMessages || this.state.searchTerm.length >= 3) && this.state.chatsOld.map(chat => {
              return (
                <Chat
                  type={"old"}
                  chatOld={chat}
                  key={chat.id}
                  chatID={chat.id}
                  navigation={this.props.navigation}
                  hasOverlay={this.state.showNewMenu}
                  onOverlayPress={() => this.setState({ showNewMenu: false })}
                  onChange={changes => {
                    // this.reorder(id, changes);
                  }}
                  onRemount={async () => {
                    // this.setState({ chatIDs: [] });
                    // await timeout(150);
                    // this.componentDidMount();
                  }}
                  onOpponentUpdated={(obj) => {
                    // if (!this.redirectDataByProfileID) this.redirectDataByProfileID = {}
                    // if (!this.redirectDataByChatID) this.redirectDataByChatID = {}
                    // this.redirectDataByProfileID[obj.chatProperties.opponentProfileID || "err"] = obj
                    // this.redirectDataByChatID[obj.chatID || "err"] = obj
                  }}
                  onSwipe={(action) => {
                    this.swiped(chat.id, action, "OLD")
                  }}
                  searchTerm={this.state.searchTerm}
                />
              );
            })}

            {!this.state.showOldMessages && this.state.chatsOld.length > 0 && this.state.chatIDs.length > 0 && this.state.searchTerm == ""
              && <View style={{ height: 120, width: "100%" }}>
                <View style={{
                  height: 70, alignItems: "center", backgroundColor: "#000", borderTopRightRadius: innerRadius, borderTopLeftRadius: innerRadius,
                  borderBottomEndRadius: outerRadius, borderBottomLeftRadius: outerRadius, justifyContent: "center",
                }}>
                  <TouchableOpacity onPress={this.showOldMessages}
                    style={[global.gStyles.buttonXLW, { margin: 20, width: 225, alignSelf: "center", borderColor: "#888", height: 35 }]}>
                    <Text style={global.gStyles.buttontXLW}>Show old messages ({this.state.chatsOld.length})</Text>
                  </TouchableOpacity>
                </View>
              </View>}

            {!this.state.showOldMessages && this.state.chatsOld.length > 0 && this.state.chatIDs.length == 0 && <View style={{ height: 250, width: "100%", marginTop: 50 }}>
              <View style={{
                height: 150, alignItems: "center",
                borderBottomEndRadius: outerRadius, borderBottomLeftRadius: outerRadius, justifyContent: "flex-end",
              }}>
                <Image
                  source={require("../images/tabNavigator/Messages.png")}
                  style={{ opacity: 0.3, width: 100, height: 100, margin: 10 }}
                />
                <Text style={[{ fontSize: 20, color: "#ccccd5" }]}>No recent messages</Text>
              </View>
              <View style={{
                height: 100, alignItems: "center",
                borderBottomEndRadius: outerRadius, borderBottomLeftRadius: outerRadius, justifyContent: "center",
              }}>
                <TouchableOpacity onPress={this.showOldMessages}
                  style={[global.gStyles.buttonXLW, { margin: 20, width: 225, alignSelf: "center", borderColor: "#888", height: 35 }]}>
                  <Text style={global.gStyles.buttontXLW}>Show old messages ({this.state.chatsOld.length})</Text>
                </TouchableOpacity>
              </View>
            </View>}
          </ScrollView>

          {this.state.chatIDs.length == 0 && this.state.chatsOld.length == 0 && (
            <View
              style={{
                flex: 100,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {!this.state.loading ? (
                <View style={{ alignItems: "center" }}>
                  <Image
                    source={require("../images/tabNavigator/Messages.png")}
                    style={{ opacity: 0.3 }}
                  />
                  <Text
                    style={{
                      color: "#999",
                      fontStyle: "italic",
                      textAlign: "center",
                      fontSize: 18,
                      padding: 25,
                      paddingBottom: 0
                    }}
                  >
                    Once you chat with a provider,
                  </Text>
                  <Text
                    style={{
                      color: "#999",
                      fontStyle: "italic",
                      textAlign: "center",
                      fontSize: 18,
                      padding: 25,
                      paddingTop: 10
                    }}
                  >
                    your messages will appear here.
                  </Text>
                </View>
              ) : (
                <ActivityIndicator
                  size="large"
                  color="#999999"
                  style={{
                    opacity: 1
                  }}
                  animating={true}
                />
              )}
            </View>
          )}

          <View style={{
            position: "absolute",
            bottom: 5,
            right: 15,
            alignItems: 'flex-end'
          }}>
            {this.state.showNewMenu && <FadeInView>
              <TouchableOpacity
                style={{
                  borderWidth: 1,
                  borderColor: "#A8CF45",
                  paddingLeft: 35,
                  justifyContent: "center",
                  width: Dimensions.get("window").width - 100,
                  height: 70,
                  width: 220,
                  marginBottom: 15,
                  marginRight: 0,
                  backgroundColor: "rgba(0,0,0,0.9)",
                  borderRadius: 70,
                  shadowOpacity: global.app == "health" ? 0 : 1,
                  shadowRadius: global.app == "health" ? 0 : 3,
                  elevation: 4
                }}
                onPress={() => {
                  this.props.navigation.navigate("chatCommunityUpdate", { openness: "PRIVATE" })
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Image
                    source={require("../images/icons/PublicChats.png")}
                    style={{ tintColor: "#889", width: 45, height: 45, marginRight: 30 }}
                  />
                  <View>
                    <Text style={{ fontSize: 15, fontWeight: "200", color: "#ccd" }}>New</Text>
                    <Text style={{ fontSize: 30, fontWeight: "200", color: "#ccd" }}>Group</Text>
                  </View>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  borderWidth: 1,
                  borderColor: "#A8CF45",
                  paddingLeft: 35,
                  justifyContent: "center",
                  width: Dimensions.get("window").width - 100,
                  height: 70,
                  width: 220,
                  marginBottom: 15,
                  marginRight: 0,
                  backgroundColor: "rgba(0,0,0,0.9)",
                  borderRadius: 70,
                  shadowOpacity: global.app == "health" ? 0 : 1,
                  shadowRadius: global.app == "health" ? 0 : 3,
                  elevation: 4
                }}
                onPress={() => { this.props.navigation.navigate("messageSelectContact") }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Image
                    source={require("../images/tabNavigator/Messages.png")}
                    style={{ tintColor: "#889", width: 45, height: 45, marginRight: 30 }}
                  />
                  <View>
                    <Text style={{ fontSize: 15, fontWeight: "200", color: "#ccd" }}>New</Text>
                    <Text style={{ fontSize: 30, fontWeight: "200", color: "#ccd" }}>Chat</Text>
                  </View>
                </View>
              </TouchableOpacity>

            </FadeInView>}
            {global.app == "HIDDENmarket" && <TouchableOpacity
              style={{
                borderWidth: 1,
                borderColor: "#A8CF45",
                alignItems: "center",
                justifyContent: "center",
                width: Dimensions.get("window").width - 100,
                height: 70,
                marginRight: 15,
                backgroundColor: "rgba(0,0,0,0.9)",
                borderRadius: 70,
                shadowOpacity: global.app == "health" ? 0 : 1,
                shadowRadius: global.app == "health" ? 0 : 3,
                elevation: 4
              }}
              onPress={() =>
                this.props.navigation.navigate("chatGroupMain")
              }
            >
              <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <Image
                  source={require("../images/icons/PublicChats.png")}
                  style={{ tintColor: "#889", width: 45, height: 45, marginRight: 30 }}
                />
                <View>
                  <Text style={{ fontSize: 15, fontWeight: "200", color: "#ccd" }}>
                    Find Nearby
            </Text>
                  <Text style={{ fontSize: 30, fontWeight: "200", color: "#ccd" }}>
                    Public Chats
            </Text>
                </View>
              </View>
            </TouchableOpacity>}
            <TouchableOpacity
              style={{
                borderWidth: 1,
                borderColor:
                  global.app == "health"
                    ? "rgba(255,255,255,0.2)"
                    : "rgba(0,0,0,0.2)",
                alignItems: "center",
                justifyContent: "center",
                width: 70,
                height: 70,
                backgroundColor: global.app == "health" ? "#45A8A8" : "#A8CF45",
                borderRadius: 70,
                shadowOpacity: global.app == "health" ? 0 : 1,
                shadowRadius: global.app == "health" ? 0 : 3,
                elevation: 4
              }}
              onPress={() => { this.setState({ showNewMenu: !this.state.showNewMenu }) }}
            >
              <Text style={{ fontSize: 36, fontWeight: "200", marginBottom: 2 }}>
                +
            </Text>
            </TouchableOpacity>
          </View>
          <NavigationEvents
            onWillBlur={async payload => {
              this.setState({ searchTerm: "", searchText: "", showNewMenu: false })
            }}
            onDidFocus={async payload => {
              if (global.loadingSuccessChatMain == false) {
                console.log(
                  "Messages Screen Update: loadingSuccessChatMain was false before, now trying to reload"
                );
                await this.componentDidMount();
              }
              global.fetchIdToken()
              await timeout(200);
              let redirect = await this.props.navigation.getParam(
                "redirect",
                null
              );
              let redirectParams = await this.props.navigation.getParam(
                "redirectParams",
                null
              );
              if (redirect) {
                this.props.navigation.navigate("chatDetail", redirectParams);
                this.props.navigation.setParams({ redirect: null });
              }

              this.setState({ showNotificationHandler: true });
            }}
          />
        </View>

        <BOGuide
          type={"chat"}
          onDone={() => { }}
        />
      </SafeAreaView>
    );
  }
}

class CommunityPicture extends Component {
  state = {
    pictureURL: "",
  };
  name = ""

  async componentDidMount() {
    try {
      const pictureURLAsyncStored = await AsyncStorage.getItem("cmCommunityPictureURL" + this.props.communityID?.substring?.(5, 15))
      if (pictureURLAsyncStored) this.setState({ pictureURL: pictureURLAsyncStored });

      let cDoc = await firebase
        .firestore()
        .collection("Communities")
        .doc(this.props.communityID)
        .get();
      let c = cDoc.data();
      const pictureURL = c.communityBackgroundPictureURL
      if (pictureURL !== pictureURLAsyncStored) {
        this.setState({ pictureURL });
        await AsyncStorage.setItem("cmCommunityPictureURL" + this.props.communityID?.substring?.(5, 15), pictureURL)
      }
      this.name = c.name
    } catch (err) {
      global.warn(err, "CommunityPicture")
    }
  }
  render() {
    return (
      <View style={{
        height: 40, width: 40, borderRadius: 4, backgroundColor: "#445", borderColor: "#444", borderWidth: 1,
        marginRight: 5, justifyContent: "center", alignItems: "center"
      }}>
        {!!this.state.pictureURL && (
          <CachedImage
            style={{ width: 40, height: 40, borderRadius: 4, borderColor: "#444", borderWidth: 1, }}
            source={{ uri: this.state.pictureURL }}
            key={this.state.pictureURL}
          />
        )}
      </View>
    );
  }
}

class FadeInView extends Component {
  state = {
    opacity: new Animated.Value(0),
  }

  componentDidMount = () => {
    Animated.timing(this.state.opacity, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
    }).start();
  }

  render() {
    return (
      <Animated.View
        onLoad={this.onLoad}
        {...this.props}
        style={[
          {
            opacity: this.state.opacity,
            transform: [
              {
                scale: this.state.opacity.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0.85, 1],
                })
              },
            ],
          },
          this.props.style,
        ]}
      />
    );
  }
}
import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  SafeAreaView,
  Image,
  StatusBar,
  TouchableOpacity,
  TextInput,
  Modal,
  Alert,
  ActivityIndicator,
  ScrollView,
  Platform,
  Linking
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import FundMyAccount from "../Wallet/fundMyAccount";
const gStyles = global.gStyles //    "../../styles/gStyles";

class Title extends PureComponent {
  render() {
    return (
      <Text style={[this.props.style, { color: "#A8CF45", marginVertical: 6, marginTop: 15, fontWeight: "bold" }]}>
        {this.props.children}
      </Text>
    );
  }
}
class BulletPoint extends PureComponent {
  render() {
    return (
      <View style={{ flexDirection: "row", width: "90%", justifyContent: "flex-start" }}>
        <Text style={[this.props.style, { marginRight: 6, color: "#A8CF45", }]}>{`\u2022`}</Text>
        <Text style={[this.props.style, { color: "white", fontWeight: "bold", fontSize: 12, flexWrap: "wrap" }]}>{this.props.children}</Text>
      </View>
    );
  }
}

export default class MeWebsiteScreen extends Component {
  static navigationOptions = {
    title: "",
    headerTransparent: true,
    headerTintColor: "#A8CF45",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };

  state = {
    priceClassicNGN: "###",
    priceClassicACN: "###",
    pricePremiumNGN: "###",
    pricePremiumACN: "###",
    priceWakandaEducationNGN: "###",
    priceWakandaEducationACN: "###",
    canGetWakandaEducation: false,
    vat: 17.5,
    xrGHS: global.xRates[global.myLocalCurrency || "GHS"],
    discountPercentage: 0,
    walletBalance: -1,
    coinBalance: -1,
    screen: "main", // "classic" // "premium" 
    referrerCode: "",
    referrerCodeValid: true,
    referralState: "none", // "enter" // "entered"
    existingShopID: null,
    samples: [],
    showFundModal: false,
    aaTrial: false,
    aaCode: false,

    isRenewal: false,
    allowTrial: false,
    renewalPeriodStr: "for one year"
  }

  constructor() {
    super();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    if (this.props.navigation.getParam("isRenewal", false)) this.setState({ isRenewal: true })
    else if (!global.websiteTrialJustSelected) this.setState({ allowTrial: true })
    if (this.props.navigation.getParam("renewalPeriodStr", false)) this.setState({ renewalPeriodStr: this.props.navigation.getParam("renewalPeriodStr", "") })


    if (global.app == "education") this.setState({ canGetWakandaEducation: true })
    try {
      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .onSnapshot(this.updateUser);
      const w1 = firebase.firestore().collection("ServerClientReadOnly").doc("taxRates").get()
      const cDoc = await firebase.firestore().collection("ServerClientReadOnly").doc("charges").get()
      const tDoc = await w1
      const taxesAndFeesPercentage = tDoc.data().taxesAndFeesPercentage
      while (!global.myTaxRate) await global.timeout(500)
      const charges = cDoc.data()
      this.setState({
        priceClassicACN: charges.chargeWebsiteClassicACN * (global.myTaxRate + 100) / 100,
        priceClassicNGN: charges.chargeWebsiteClassicNGN,
        pricePremiumACN: charges.chargeWebsitePremiumACN * (global.myTaxRate + 100) / 100,
        pricePremiumNGN: charges.chargeWebsitePremiumNGN,
        priceWakandaEducationACN: charges.chargeWebsiteWakandaEducationACN * (global.myTaxRate + 100) / 100,
        priceWakandaEducationNGN: charges.chargeWebsiteWakandaEducationNGN,
        vat: global.myTaxRate,
      })
      const sDoc = await firebase.firestore().collection("ServerClientReadOnly").doc("shopInfo").get()
      const shopInfo = sDoc.data()
      this.setState({
        samples: shopInfo.samples,
      })
    } catch (e) {
      console.log("Error", e)
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  updateUser = async snapshot => {
    const user = snapshot.data();
    const walletBalance = user.walletBalance - user.walletAmountInEscrowByYou;
    const coinBalance = user.coinBalance - user.coinAmountInEscrowByYou;
    const networks = user.networks || []
    if (global.app == "education" || networks.includes("WakandaEducation")) {
      this.setState({ canGetWakandaEducation: true })
    }
    if (typeof walletBalance == "number") {
      this.setState({ walletBalance: walletBalance || 0 });
    }
    if (typeof coinBalance == "number") {
      //this.setState({ coinBalance: 24060.55324 || 0 });
      this.setState({ coinBalance: coinBalance || 0 });
    }
    if (user.idShop) this.setState({ existingShopID: user.idShop })
  };

  addReferrerCode = async (plan) => {
    if (!this.state.referrerCode || this.state.referrerCode.length < 4) {
      alert("Code not valid");
      return;
    }
    if (this.state.referrerCode.toLowerCase() == this.state.existingShopID) {
      alert("Cannot use own referrer code.");
      return;
    }
    try {
      this.setState({ aaCode: true });

      const r = await fetch(global.cloudFunctionURL + "addReferrerCode", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          referrerCode: this.state.referrerCode,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      console.log("Received response. ", r2.msg);
      this.setState({ aaCode: false });
      if (r2.msg === "SUCCESS") {
        let discountPercentage = r2.discountPercentage
        if (plan == "classic" && r2.discountPercentageClassic) discountPercentage = r2.discountPercentageClassic
        this.setState({ referralState: "entered", discountPercentage, referrerCodeValid: true })
      } else if (r2.msg === "NOT_FOUND") {
        this.setState({ referralState: "enter", referrerCodeValid: false })
      } else
        alert("Unable to validate code at this point. Try again later. ");
    } catch (e2) {
      alert("Could not connect to server.");
      console.log(e2);
      this.setState({ aaCode: false });
    }
  }

  removeReferrerCode = () => {
    this.setState({ referralState: "none", discountPercentage: 0, referrerCode: "", referralNote: "" })
  }

  callFund = () => {
    if (global.walletType !== "ACN") {
      this.setState({ showFundModal: true })
    } else {
      console.log("trying to call meWalletTrader screen")
      this.props.navigation.navigate("meWalletTrader");
    }
  }
  callPurchase = (plan, chargeACN, chargeNGN, taxesAndFeesPercentage) => {
    if (global.walletType == "ACN") {
      const now = new Date()
      if (global.blockedUntil > now) {
        alert("Your wallet is temporarily blocked for outgoing payments")
        return;
      }
      if (global.coinResetAfterBlock) {
        alert("Sign out of Wakanda and sign in again to be able to make payments again")
        return;
      }
      if (!global.coinSignKey) {
        this.props.navigation.navigate("walletSetup", { screen: "0" });
        return;
      }
    } else taxesAndFeesPercentage = 7.5
    const isRenewal = this.props.navigation.getParam("isRenewal", false)

    this.props.navigation.navigate("mePay", {
      action: "website",
      recipientName: "Wakanda",
      recipientProfileID: global.walletType == "ACN" ? "9VzoehSHnKk3Ow04Dh6m" : "3PU7CypSTgFPrhJLD9As",
      tmpWalletBalance: global.walletType == "ACN" ? this.state.coinBalance : this.state.walletBalance,
      amount: global.walletType == "ACN" ? chargeACN : chargeNGN,
      amountCurrency: global.walletType,
      remarks: "Website Purchase",
      nextScreen: "meMain",
      specialRequest: {
        action: "website",
        referrerCode: this.state.discountPercentage > 0 ? this.state.referrerCode : null,
        plan,
        isRenewal,
        taxesAndFeesPercentage,
        localCurrency: global.myLocalCurrency,
        discountPercentage: this.state.discountPercentage // just added for free classic sites
      }
    });
  }

  intendPurchase = type => {
    this.setState({ screen: type })
  }
  intendTrial = () => {
    Alert.alert("Try out classic website for 2 weeks for free?", "",
      [{ text: "Cancel", onPress: () => { } },
      { text: "Yes", onPress: () => { this.executeTrial(); } }],
      { cancelable: true }
    );
  }
  executeTrial = async () => {
    try {
      this.setState({ aaTrial: true });
      const r = await fetch(global.cloudFunctionURL + "initiateTrialShop", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();

      this.props.navigation.goBack()
      await global.timeout(300)
      if (r2.msg === "SUCCESS") {
        alert("Success. Set up your website now.")
      } else {
        alert("Attempt failed. Try again later");
      }
    } catch (err) {
      alert("Attempt failed.");
      console.log(err);
      this.setState({ aaTrial: false });
    }
  }

  renderDetailsAndroid = (plan) => {
    let planColor = (plan == "classic") ? "#A8CF45" : (plan == "education") ? "#0128bc" : "#18A45C"
    let planString = (plan == "premium") ? "YOUR-BUSINESS.com or YOUR-BUSINESS.com.ng" : (plan == "education") ? "wakanda-education.com/YOUR-NAME" : "wakanda-market.com/YOUR-BUSINESS"

    let priceNGN = (plan == "classic") ? this.state.priceClassicNGN : (plan == "education") ? this.state.priceWakandaEducationNGN : this.state.pricePremiumNGN
    let priceACN = (plan == "classic") ? this.state.priceClassicACN : (plan == "education") ? this.state.priceWakandaEducationACN : this.state.pricePremiumACN
    let chargeNGN = (priceNGN - (priceNGN * this.state.discountPercentage)).toFixed(0)
    let chargeACN = (priceACN - (priceACN * this.state.discountPercentage)).toFixed(0)
    //nextScreenString = "₳ " + chargeACN + " ≈  ₵ " + (chargeACN * this.state.xrGHS).toFixed(0).toLocaleString('en')

    let disclaimer = (plan == "premium") ? "After you purchase, select your domain name. After about 5 days your site will be live." : "After your purchase, select your domain name and your site will immediately be live."
    if (this.state.isRenewal) disclaimer = "After your purchase, you choose whether to keep your domain name(s) or get new ones"
    let referralNote = this.state.referrerCodeValid ? "" : "Code not valid"

    let fundsNeeded = global.walletType == "NGN" ? (chargeNGN - this.state.walletBalance).toFixed(0) : (chargeACN - this.state.coinBalance).toFixed(0)
    let fundsColor = (fundsNeeded > 0) ? "#844" : "#484"

    let txStyle = { fontSize: 16, color: "#444", fontWeight: "200" }
    let txStyle2 = { fontSize: 12, color: "#444", fontWeight: "200" }
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "#fff", }} >
        <View style={{ borderColor: planColor, borderWidth: 0, borderBottomWidth: 5, borderTopWidth: 5, backgroundColor: "#fff", borderRadius: 15, marginTop: 24 }}>
          <View style={{ height: 33, marginHorizontal: 15, borderBottomColor: "#cfd", borderBottomWidth: 1, flexDirection: "row", alignItems: "center" }}>
            <Text style={{ color: "black", width: 80, }}>{plan == "classic" ? "Classic" : plan == "education" ? "Education" : "Premium"}</Text>
            {global.walletType == "NGN" && <Text style={{ color: "black", fontWeight: "100", fontSize: 24 }}>₦ {priceNGN.toLocaleString('en')}</Text>}
            {global.walletType == "ACN" && <Text style={{ color: "black", fontWeight: "100", fontSize: 22 }}>₳ {priceACN.toLocaleString('en')}</Text>}
            {global.walletType == "ACN" && <Text style={{ color: "black", fontWeight: "100", fontSize: 22 }}>  ≈  ₵ {(priceACN * this.state.xrGHS).toFixed(0).toLocaleString('en') || "###"}</Text>}
            <Text style={{ color: "black", fontWeight: "100", fontSize: 10, marginTop: 6 }}>   / year</Text>
          </View>
          <View style={{ height: 33, marginHorizontal: 15, flexDirection: "row", alignItems: "center" }}>
            <Text style={{ color: "black", fontSize: 12, fontWeight: "200" }}>{planString}</Text>
          </View>
        </View>
        <View style={{ height: 30 }} />
        {this.state.referralState == "none" && <TouchableOpacity onPress={() => { this.setState({ referralState: "enter" }) }} style={{ height: 56, flexDirection: "row", width: 280, justifyContent: "space-between", alignItems: "center", paddingBottom: 5 }}>
          <Text style={{ color: "#448", fontSize: 12 }}>Add a referrer code</Text>
        </TouchableOpacity>}
        {this.state.referralState == "enter" && <View style={{ height: 65, justifyContent: "center" }}>
          <View style={{ height: 40, flexDirection: "row", width: 280, justifyContent: "space-between", alignItems: "center" }}>
            <TextInput
              onChangeText={referrerCode => { this.setState({ referrerCode }); }}
              style={{ height: 40, color: "#444", borderColor: "#ccc", borderWidth: 1, paddingLeft: 3, borderRadius: 0, width: 230, backgroundColor: "#fff" }}
              underlineColorAndroid="transparent"
              placeholder={"Add a referrer code"}
              placeholderTextColor="#999"
              bordered
              onSubmitEditing={() => { this.addReferrerCode(plan) }}
            />
            <TouchableOpacity
              style={{ alignItems: "center", padding: 1, backgroundColor: planColor, marginLeft: 4.5, width: 47, height: 40, justifyContent: "center", borderTopEndRadius: 20, borderBottomEndRadius: 20, borderColor: "gray", borderLeftWidth: 1 }}
              onPress={() => { this.addReferrerCode(plan) }}
            >
              {!!this.state.aaCode ? (
                <ActivityIndicator size="small" color="#fff" />
              ) : (<Image style={{ width: 18, height: 18, marginRight: 1, tintColor: plan == "classic" ? "#444" : "#fff" }}
                source={require("../../images/icons/Search.png")}
              />)}
            </TouchableOpacity>
          </View>
          <Text style={{ fontSize: 12, color: "#844", fontWeight: "200" }}>{referralNote}</Text>
        </View>}
        {this.state.referralState == "entered" && <View style={{ height: 65, flexDirection: "row", width: 280, justifyContent: "space-between", alignItems: "center", paddingBottom: 5, paddingTop: 9 }}>
          <View>
            <Text style={[txStyle]}>Referrer Discount</Text>
            <TouchableOpacity onPress={this.removeReferrerCode}>
              <Text style={[txStyle2, { color: "#448" }]}>Remove</Text>
            </TouchableOpacity>
          </View>
          <Text style={[txStyle]}>{(this.state.discountPercentage * 100).toFixed(0)} %</Text>
        </View>}
        <View style={{ height: 56, flexDirection: "row", width: 280, justifyContent: "space-between", alignItems: "center", paddingBottom: 5 }}>
          <View>
            <Text style={[txStyle, { fontWeight: "bold" }]}>Total Charge</Text>
            <Text style={{ color: "#444", fontSize: 12 }}>includes {global.walletType == "NGN" ? "5" : global.myTaxRate}% taxes and fees</Text>
          </View>
          <View style={{ alignItems: "flex-end" }}>
            {global.walletType == "NGN" && <Text style={[txStyle, { fontWeight: "bold" }]}>₦ {chargeNGN.toLocaleString('en')}</Text>}
            {global.walletType == "ACN" && <Text style={[txStyle, { fontWeight: "bold" }]}>₳ {chargeACN.toLocaleString('en')}</Text>}
            {global.walletType == "ACN" && <Text style={[txStyle2, { fontWeight: "bold" }]}>≈  ₵ {(chargeACN * this.state.xrGHS).toFixed(0).toLocaleString('en') || "###"}</Text>}
            {/* <View style={{ borderRadius: 8, borderColor: "gray", borderWidth: 0.5, padding: 0 }}><Text style={[txStyle]}>= A 7000</Text></View> */}
          </View>
        </View>
        <View style={{ height: 1, width: 280, borderWidth: .5, borderStyle: "dashed", borderColor: "#888" }} />
        <View style={{ height: 25 }} />
        <View style={{ height: 40, flexDirection: "row", width: 280, justifyContent: "space-between", alignItems: "center", paddingBottom: 5 }}>
          <Text style={txStyle}>My Wallet Balance</Text>
          <View style={{ alignItems: "flex-end" }}>
            {global.walletType == "NGN" && <Text style={txStyle}>₦ {(this.state.walletBalance >= 0) ? this.state.walletBalance.toFixed(0).toLocaleString('en') : "###"}</Text>}
            {global.walletType == "ACN" && <Text style={txStyle}>₳ {(this.state.coinBalance >= 0) ? this.state.coinBalance.toFixed(0).toLocaleString('en') : "###"}</Text>}
            {global.walletType == "ACN" && (this.state.coinBalance >= 0) && <Text style={txStyle2}>≈  ₵ {(this.state.coinBalance * this.state.xrGHS).toFixed(0).toLocaleString('en')}</Text>}
          </View>
        </View>
        <View style={{ height: 40, flexDirection: "row", width: 280, justifyContent: "space-between", alignItems: "center", paddingBottom: 5 }}>
          <Text style={[txStyle, { color: fundsColor }]}>Funds Needed</Text>
          {(fundsNeeded <= 0) ? <Text style={[txStyle, { color: fundsColor }]}>0</Text> : (<View style={{ alignItems: "flex-end" }}>
            {global.walletType == "NGN" && <Text style={[txStyle, { color: fundsColor }]}>₦ {fundsNeeded}</Text>}
            {global.walletType == "ACN" && <Text style={[txStyle, { color: fundsColor }]}>₳ {fundsNeeded}</Text>}
            {global.walletType == "ACN" && <Text style={[txStyle2, { color: fundsColor }]}>≈  ₵ {(fundsNeeded * this.state.xrGHS).toFixed(0).toLocaleString('en')}</Text>}
          </View>)}
        </View>
        <View style={{ height: this.state.referralState == "none" ? 28 : 19 }} />
        <TouchableOpacity style={[global.gStyles.button, { borderRadius: 0, backgroundColor: planColor, marginHorizontal: 36 }]} onPress={fundsNeeded > 0 ? () => { this.callFund() } : () => { this.callPurchase(plan, chargeACN, chargeNGN, this.state.vat) }}>
          <View style={{ flexDirection: "row", }}>
            <View style={{ width: 40, height: 15 }} />
            <Text style={[global.gStyles.buttontext, { fontSize: 16, fontWeight: "normal", color: (plan == "classic") ? "#444" : "#fff" }]}>{fundsNeeded > 0 ? "Fund My Wallet" : "Purchase Website"}</Text>
            <View style={{ width: 44 }} />
          </View>
        </TouchableOpacity>
        <View style={{ height: 35, width: 280, paddingTop: 5 }}>
          <Text style={[txStyle2, { textAlign: "center" }]}>{disclaimer}</Text>
        </View>
        <Modal animationType="fade" transparent={true} onRequestClose={() => { }} visible={this.state.showFundModal}>
          <FundMyAccount
            walletBalance={this.state.walletBalance}
            onClose={() => { this.setState({ showFundModal: false }); }} />
        </Modal>
      </View>
    );
  }

  renderDetails = (plan) => {
    let planColor = (plan == "classic") ? "#A8CF45" : (plan == "education") ? "#0128bc" : "#18A45C"
    let planString = (plan == "premium") ? "YOUR-BUSINESS.com or YOUR-BUSINESS.com.ng" : (plan == "education") ? "wakanda-education.com/YOUR-NAME" : "wakanda-market.com/YOUR-BUSINESS"

    let priceNGN = (plan == "classic") ? this.state.priceClassicNGN : (plan == "education") ? this.state.priceWakandaEducationNGN : this.state.pricePremiumNGN
    let priceACN = (plan == "classic") ? this.state.priceClassicACN : (plan == "education") ? this.state.priceWakandaEducationACN : this.state.pricePremiumACN
    let chargeNGN = (priceNGN - (priceNGN * this.state.discountPercentage)).toFixed(0)
    let chargeACN = (priceACN - (priceACN * this.state.discountPercentage)).toFixed(0)

    //nextScreenString = "₳ " + chargeACN + " ≈  ₵ " + (chargeACN * this.state.xrGHS).toFixed(0).toLocaleString('en')

    let disclaimer = (plan == "premium") ? "After you purchase, select your domain name. After about 5 days your site will be live." : "After your purchase, select your domain name and your site will immediately be live."
    if (this.state.isRenewal) disclaimer = "After your purchase, you choose whether to keep your domain name(s) or get new ones"
    let referralNote = this.state.referrerCodeValid ? "" : "Code not valid"

    let fundsNeeded = global.walletType == "NGN" ? (chargeNGN - this.state.walletBalance).toFixed(0) : (chargeACN - this.state.coinBalance).toFixed(0)
    let fundsColor = (fundsNeeded > 0) ? "#844" : "#484"

    let txStyle = { fontSize: 16, color: "#444", fontWeight: "200" }
    let txStyle2 = { fontSize: 12, color: "#444", fontWeight: "200" }
    return (
      <View style={{ backgroundColor: "rgba(0,0,0,.85)", position: "absolute", top: 0, height: "100%", width: "100%", alignItems: "center", justifyContent: "center" }} >
        <View style={{ backgroundColor: "white", height: 475, marginTop: 25, width: 350, borderRadius: 10 }}>
          <View style={{ backgroundColor: planColor, height: 100, width: 350, borderTopRightRadius: 10, alignItems: "center" }}>
            {/* <TouchableWithoutFeedback onPress={() => { this.setState({ screen: "main" }) }}>
          <Image style={{ width: 18, height: 18, opacity: 1 }} source={require("../../images/icons/CancelBlack.png")} />
              </TouchableWithoutFeedback> */}
            <View style={{ marginTop: 10, height: 75, borderRadius: 5, marginHorizontal: 15, marginBottom: 9 }}>
              <View style={{ backgroundColor: planColor, height: 9, borderTopLeftRadius: 5, borderTopRightRadius: 5, borderTopEndRadius: 5 }} />
              <View style={{ height: 33, marginHorizontal: 15, borderBottomColor: "#cfd", borderBottomWidth: .5, flexDirection: "row", alignItems: "center" }}>
                <Text style={{ color: "black", width: 80, }}>{plan == "classic" ? "Classic" : plan == "education" ? "Education" : "Premium"}</Text>
                {global.walletType == "NGN" && <Text style={{ color: "black", fontWeight: "100", fontSize: 24 }}>₦ {priceNGN.toLocaleString('en')}</Text>}
                {global.walletType == "ACN" && <Text style={{ color: "black", fontWeight: "100", fontSize: 22 }}>₳ {priceACN.toLocaleString('en')}</Text>}
                {global.walletType == "ACN" && <Text style={{ color: "black", fontWeight: "100", fontSize: 22 }}>  ≈  ₵ {(priceACN * this.state.xrGHS).toFixed(0).toLocaleString('en') || "###"}</Text>}
                <Text style={{ color: "black", fontWeight: "100", fontSize: 10, marginTop: 6 }}>   / year</Text>
              </View>
              <View style={{ height: 33, marginHorizontal: 15, flexDirection: "row", alignItems: "center" }}>
                <Text style={{ color: "black", fontSize: 12, fontWeight: "200" }}>{planString}</Text>
              </View>
            </View>
            <View style={{ height: 30 }} />
            {this.state.referralState == "none" && <TouchableOpacity onPress={() => { this.setState({ referralState: "enter" }) }} style={{ height: 56, flexDirection: "row", width: 280, justifyContent: "space-between", alignItems: "center", paddingBottom: 5 }}>
              <Text style={{ color: "#448", fontSize: 12 }}>Add a referrer code</Text>
            </TouchableOpacity>}
            {this.state.referralState == "enter" && <View style={{ height: 65, justifyContent: "center" }}>
              <View style={{ height: 40, flexDirection: "row", width: 280, justifyContent: "space-between", alignItems: "center" }}>
                <TextInput
                  onChangeText={referrerCode => { this.setState({ referrerCode }); }}
                  style={{ height: 40, color: "#444", borderColor: "#ccc", borderWidth: 1, paddingLeft: 3, borderRadius: 0, width: 230, backgroundColor: "#fff" }}
                  underlineColorAndroid="transparent"
                  placeholder={"Add a referrer code"}
                  placeholderTextColor="#999"
                  bordered
                  onSubmitEditing={() => { this.addReferrerCode(plan) }}
                />
                <TouchableOpacity
                  style={{ alignItems: "center", padding: 1, backgroundColor: planColor, marginLeft: 4.5, width: 47, height: 40, justifyContent: "center", borderTopEndRadius: 20, borderBottomEndRadius: 20, borderColor: "gray", borderLeftWidth: 1 }}
                  onPress={() => { this.addReferrerCode(plan) }}
                >
                  {!!this.state.aaCode ? (
                    <ActivityIndicator size="small" color="#fff" style={{ margin: -1 }} />
                  ) : (<Image style={{ width: 18, height: 18, marginRight: 1, tintColor: plan == "classic" ? "#444" : "#fff" }}
                    source={require("../../images/icons/Search.png")}
                  />)}
                </TouchableOpacity>
              </View>
              <Text style={{ fontSize: 12, color: "#844", fontWeight: "200" }}>{referralNote}</Text>
            </View>}
            {this.state.referralState == "entered" && <View style={{ height: 65, flexDirection: "row", width: 280, justifyContent: "space-between", alignItems: "center", paddingBottom: 5, paddingTop: 9 }}>
              <View>
                <Text style={[txStyle]}>Referrer Discount</Text>
                <TouchableOpacity onPress={this.removeReferrerCode}>
                  <Text style={[txStyle2, { color: "#448" }]}>Remove</Text>
                </TouchableOpacity>
              </View>
              <Text style={[txStyle]}>{(this.state.discountPercentage * 100).toFixed(0)} %</Text>
            </View>}
            <View style={{ height: 56, flexDirection: "row", width: 280, justifyContent: "space-between", alignItems: "center", paddingBottom: 5 }}>
              <View>
                <Text style={[txStyle, { fontWeight: "bold" }]}>Total Charge</Text>
                <Text style={{ color: "#444", fontSize: 12 }}>includes {global.walletType == "NGN" ? "5" : global.myTaxRate}% taxes and fees</Text>
              </View>
              <View style={{ alignItems: "flex-end" }}>
                {global.walletType == "NGN" && <Text style={[txStyle, { fontWeight: "bold" }]}>₦ {chargeNGN.toLocaleString('en')}</Text>}
                {global.walletType == "ACN" && <Text style={[txStyle, { fontWeight: "bold" }]}>₳ {chargeACN.toLocaleString('en')}</Text>}
                {global.walletType == "ACN" && <Text style={[txStyle2, { fontWeight: "bold" }]}>≈  ₵ {(chargeACN * this.state.xrGHS).toFixed(0).toLocaleString('en') || "###"}</Text>}
                {/* <View style={{ borderRadius: 8, borderColor: "gray", borderWidth: 0.5, padding: 0 }}><Text style={[txStyle]}>= A 7000</Text></View> */}
              </View>
            </View>
            <View style={{ height: 1, width: 280, borderWidth: .5, borderStyle: "dashed", borderColor: "#888" }} />
            <View style={{ height: 25 }} />
            <View style={{ height: 40, flexDirection: "row", width: 280, justifyContent: "space-between", alignItems: "center", paddingBottom: 5 }}>
              <Text style={txStyle}>My Wallet Balance</Text>
              <View style={{ alignItems: "flex-end" }}>
                {global.walletType == "NGN" && <Text style={txStyle}>₦ {(this.state.walletBalance >= 0) ? this.state.walletBalance.toFixed(0).toLocaleString('en') : "###"}</Text>}
                {global.walletType == "ACN" && <Text style={txStyle}>₳ {(this.state.coinBalance >= 0) ? this.state.coinBalance.toFixed(0).toLocaleString('en') : "###"}</Text>}
                {global.walletType == "ACN" && (this.state.coinBalance >= 0) && <Text style={txStyle2}>≈  ₵ {(this.state.coinBalance * this.state.xrGHS).toFixed(0).toLocaleString('en')}</Text>}
              </View>
            </View>
            <View style={{ height: 40, flexDirection: "row", width: 280, justifyContent: "space-between", alignItems: "center", paddingBottom: 5 }}>
              <Text style={[txStyle, { color: fundsColor }]}>Funds Needed</Text>
              {(fundsNeeded <= 0) ? <Text style={[txStyle, { color: fundsColor }]}>0</Text> : (<View style={{ alignItems: "flex-end" }}>
                {global.walletType == "NGN" && <Text style={[txStyle, { color: fundsColor }]}>₦ {fundsNeeded}</Text>}
                {global.walletType == "ACN" && <Text style={[txStyle, { color: fundsColor }]}>₳ {fundsNeeded}</Text>}
                {global.walletType == "ACN" && <Text style={[txStyle2, { color: fundsColor }]}>≈  ₵ {(fundsNeeded * this.state.xrGHS).toFixed(0).toLocaleString('en')}</Text>}
              </View>)}
            </View>
            <View style={{ height: this.state.referralState == "none" ? 28 : 19 }} />
            <TouchableOpacity style={[global.gStyles.button, { borderRadius: 0, backgroundColor: planColor }]} onPress={fundsNeeded > 0 ? () => { this.callFund() } : () => { this.callPurchase(plan, chargeACN, chargeNGN, this.state.vat) }}>
              <View style={{ flexDirection: "row" }}>
                <View style={{ width: 40, height: 15 }} />
                <Text style={[global.gStyles.buttontext, { fontSize: 16, fontWeight: "normal", color: (plan == "classic") ? "#444" : "#fff" }]}>{fundsNeeded > 0 ? "Fund My Wallet" : "Purchase Website"}</Text>
                <View style={{ width: 44 }} />
              </View>
            </TouchableOpacity>
            <View style={{ height: 35, width: 280, paddingTop: 5 }}>
              <Text style={[txStyle2, { textAlign: "center" }]}>{disclaimer}</Text>
            </View>
          </View>
        </View>
      </View>)
  }

  render() {
    const xHeight = 60;
    if (this.state.aaTrial) return (<View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "#131313" }}>
      <ActivityIndicator size="large" color="#888" />
      <Text style={{ color: "#888", fontSize: 18, marginTop: 10 }}>Initiating Trial</Text>
    </View>);
    if (this.state.screen != "main" && Platform.OS == "android") return (this.renderDetailsAndroid(this.state.screen))
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#000" }}>
        <StatusBar barStyle="light-content" />
        <ScrollView>
          <View style={{ flex: 1, backgroundColor: "#131313" }}>
            <Image style={{ width: "100%", height: 200 }}
              source={require("../../images/website/BackgroundLaptop.png")}
            />
            {!this.state.isRenewal &&
              <View style={{ flexDirection: "row", marginTop: -70, alignItems: "flex-end", marginLeft: 8 }}>
                <Text style={{ color: "white", fontSize: 18 }}>Reach a wider audience, </Text>
                <Text style={{ color: "white", fontSize: 18 }}>get your</Text>
              </View>}
            {!this.state.isRenewal &&
              <View style={{ flexDirection: "row", alignItems: "flex-end", marginLeft: 8 }}>
                <Text style={{ color: "white", fontSize: 26, fontWeight: "bold" }}>Personal Website </Text>
                <Text style={{ color: "white", fontSize: 26, fontWeight: "bold" }}>today!</Text>
              </View>}
            {!!this.state.isRenewal &&
              <View style={{ flexDirection: "row", marginTop: -70, alignItems: "flex-end", marginLeft: 8 }}>
                <Text style={{ color: "white", fontSize: 18 }}>Keep it going, </Text>
              </View>}
            {!!this.state.isRenewal && <View style={{ flexDirection: "row", alignItems: "flex-end", marginLeft: 8 }}>
              <Text style={{ color: "white", fontSize: 26, fontWeight: "bold" }}>Renew your website! </Text>
            </View>}
            {!this.state.isRenewal ? <View style={{ marginLeft: 30, marginTop: 20, alignSelf: "stretch", }}>
              <Title>You will enjoy</Title>
              <BulletPoint>Fully customizable content</BulletPoint>
              <BulletPoint>5 themes, custom color code</BulletPoint>
              <BulletPoint>Your profile & offerings live from {global.app == "education" ? "Wakanda" : global.a.appName || "Wakanda"}</BulletPoint>
              <BulletPoint>No configuration required, just pick a domain name and you're set!</BulletPoint>
              <Title>Select a Plan</Title>
            </View> :
              <View style={{ marginLeft: 30, marginTop: 20, alignSelf: "stretch", }}>
                <Title>Renewal Terms</Title>
                <BulletPoint>Same great features</BulletPoint>
                <BulletPoint>Renew {this.state.renewalPeriodStr}</BulletPoint>
                <BulletPoint>Keep your domain names or select new ones</BulletPoint>
                <Title>Select a Plan</Title>
              </View>}

            {/* {!!this.state.canGetWakandaEducation && <TouchableOpacity onPress={() => this.intendPurchase("education")}>
              <View style={{ backgroundColor: "white", height: 75, borderRadius: 5, shadowOpacity: 1, marginHorizontal: 15, marginBottom: 9 }}>
                <View style={{ backgroundColor: "#0128bc", height: 9, borderTopLeftRadius: 5, borderTopEndRadius: 5 }} />
                <View style={{ height: 33, marginHorizontal: 15, borderBottomColor: "#888", borderBottomWidth: .5, flexDirection: "row", alignItems: "center" }}>
                  <Text style={{ color: "#0128bc", width: 80, }}>Education</Text>
                  {global.walletType == "NGN" && <Text style={{ color: "black", fontWeight: "100", fontSize: 24 }}>₦ {this.state.priceWakandaEducationNGN.toLocaleString('en')}</Text>}
                  {global.walletType == "ACN" && <Text style={{ color: "black", fontWeight: "100", fontSize: 22 }}>₳ {this.state.priceWakandaEducationACN.toLocaleString('en')}</Text>}
                  {global.walletType == "ACN" && <Text style={{ color: "black", fontWeight: "100", fontSize: 22 }}>  ≈  ₵ {(this.state.priceWakandaEducationACN * this.state.xrGHS).toFixed(0).toLocaleString('en') || "###"}</Text>}
                  <Text style={{ color: "black", fontWeight: "100", fontSize: 10, marginTop: 6 }}>   / year</Text>
                </View>
                <View style={{ height: 33, marginHorizontal: 15, flexDirection: "row", alignItems: "center" }}>
                  <Text style={{ color: "black", fontSize: 12, fontWeight: "200" }}>wakanda-education.com/YOUR-NAME</Text>
                </View>
              </View>
            </TouchableOpacity>} */}

            <TouchableOpacity onPress={() => this.intendPurchase("classic")}>
              <View style={{ backgroundColor: "white", height: 75, borderRadius: 5, shadowOpacity: 1, marginHorizontal: 15, marginBottom: 9 }}>
                <View style={{ backgroundColor: "#A8CF45", height: 9, borderTopLeftRadius: 5, borderTopEndRadius: 5 }} />
                <View style={{ height: 33, marginHorizontal: 15, borderBottomColor: "#888", borderBottomWidth: .5, flexDirection: "row", alignItems: "center" }}>
                  <Text style={{ color: "#A8CF45", width: 80, }}>Classic</Text>
                  {global.walletType == "NGN" && <Text style={{ color: "black", fontWeight: "100", fontSize: 24 }}>₦ {this.state.priceClassicNGN.toLocaleString('en')}</Text>}
                  {global.walletType == "ACN" && <Text style={{ color: "black", fontWeight: "100", fontSize: 22 }}>₳ {this.state.priceClassicACN.toLocaleString('en')}</Text>}
                  {global.walletType == "ACN" && <Text style={{ color: "black", fontWeight: "100", fontSize: 22 }}>  ≈  ₵ {(this.state.priceClassicACN * this.state.xrGHS).toFixed(0).toLocaleString('en') || "###"}</Text>}
                  <Text style={{ color: "black", fontWeight: "100", fontSize: 10, marginTop: 6 }}>   / year</Text>
                </View>
                <View style={{ height: 33, marginHorizontal: 15, flexDirection: "row", alignItems: "center" }}>
                  <Text style={{ color: "black", fontSize: 12, fontWeight: "200" }}>wakanda-market.com/YOUR-BUSINESS</Text>
                </View>
              </View>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => this.intendPurchase("premium")}>
              <View style={{ backgroundColor: "white", height: 75, borderRadius: 5, shadowOpacity: 1, marginHorizontal: 15, }}>
                <View style={{ backgroundColor: "#18A45C", height: 9, borderTopLeftRadius: 5, borderTopEndRadius: 5 }} />
                <View style={{ height: 33, marginHorizontal: 15, borderBottomColor: "#888", borderBottomWidth: .5, flexDirection: "row", alignItems: "center" }}>
                  <Text style={{ color: "#18A45C", width: 80, }}>Premium</Text>
                  {global.walletType == "NGN" && <Text style={{ color: "black", fontWeight: "100", fontSize: 24 }}>₦ {this.state.pricePremiumNGN.toLocaleString('en')}</Text>}
                  {global.walletType == "ACN" && <Text style={{ color: "black", fontWeight: "100", fontSize: 22 }}>₳ {this.state.pricePremiumACN.toLocaleString('en')}</Text>}
                  {global.walletType == "ACN" && <Text style={{ color: "black", fontWeight: "100", fontSize: 22 }}>  ≈  ₵ {(this.state.pricePremiumACN * this.state.xrGHS).toFixed(0).toLocaleString('en') || "###"}</Text>}
                  <Text style={{ color: "black", fontWeight: "100", fontSize: 10, marginTop: 6 }}>   / year</Text>
                </View>
                <View style={{ height: 33, marginHorizontal: 15, flexDirection: "row", alignItems: "center" }}>
                  <Text style={{ color: "black", fontSize: 12, fontWeight: "200" }}>YOUR-BUSINESS.com   or   YOUR-BUSINESS.com.ng</Text>
                </View>
              </View>
            </TouchableOpacity>

            {!!this.state.allowTrial && <TouchableOpacity onPress={() => this.intendTrial()}>
              <View style={{ height: 28, borderRadius: 5, shadowOpacity: 1, marginHorizontal: 15, marginTop: 9, borderWidth: 0.5, borderColor: "#888" }}>
                <View style={{ height: 28, marginHorizontal: 15, flexDirection: "row", alignItems: "center" }}>
                  <Text style={{ color: "#aaa", width: 100, }}>2-Week Trial</Text>
                  <Text style={{ color: "#aaa", fontSize: 12, fontWeight: "300" }}>Free Classic plan, same features</Text>
                </View>
              </View>
            </TouchableOpacity>}

            {!this.state.isRenewal && <View style={{ marginHorizontal: 30, marginTop: 5, alignSelf: "stretch", marginBottom: 12 }}>
              <Title>Check out these samples</Title>
              {this.state.samples.length > 0 && this.state.samples.map((sample, i) => {
                return (
                  <View style={{ alignSelf: "stretch", alignItems: "center", marginTop: 15, marginBottom: 6 }} key={i}>
                    <TouchableOpacity onPress={() => { Linking.openURL(sample.url) }}>
                      <Image style={{ height: 200, width: 200 }} source={{ uri: sample.picture }} />
                      <Text style={{ color: "gray", fontSize: 12, marginTop: 10, textAlign: "center" }}>{sample.name}</Text>
                    </TouchableOpacity>
                  </View>)
              })}
            </View>}
          </View>
        </ScrollView>
        {this.state.screen != "main" && this.renderDetails(this.state.screen)}
        <Modal animationType="fade" transparent={true} onRequestClose={() => { }} visible={this.state.showFundModal}>
          <FundMyAccount
            walletBalance={this.state.walletBalance}
            onClose={() => { this.setState({ showFundModal: false }); }} />
        </Modal>
      </SafeAreaView>
    );
  }
}

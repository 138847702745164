
import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  Linking,
  Platform,
  Dimensions,
  ActivityIndicator,
  KeyboardAvoidingView,
  Image,
  Keyboard,
  TextInput
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import { NavigationEvents } from "react-navigation";
import Moment from "react-moment";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
//import * as Permissions from 'expo-permissions'
import HealthChat from "./HealthChat"
import { Record } from "./HealthFileScreen"


export default class HealthRecordsScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    return {
      tabBarVisible: false,
      headerTransparent: true,
      title: navigation.getParam("title", "") || "",
      headerTintColor: "black",
      headerTitleStyle: {
        fontWeight: "bold",
        color: "red",
      }
    }
  };

  state = {
    screen: "LOADING", // can be "MAIN",
    recordsLoaded: false,
    files: []
  };

  async componentDidMount() {
    if (!global.amDoctor) {
      this.props.navigation.goBack()
      await global.timeout(100)
      alert("Access issue")
    }
    this.loadRecords()
  }

  componentWillUnmount() {
    if (this.keyboardDidShowListener) this.keyboardDidShowListener.remove();
    if (this.keyboardDidHideListener) this.keyboardDidHideListener.remove();
  }

  loadRecords = async () => {
    try {
      const r = await fetch(global.cloudFunctionURL + "getHealthRecords", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        let records = r2.records
        if (global.healthChatNotifications && global.healthChatNotifications.doctor) {
          const rec1 = r2.records.filter(r => { return (global.healthChatNotifications.doctor[r.xID]) })
          const rec2 = r2.records.filter(r => { return (!global.healthChatNotifications.doctor[r.xID] && r.status == "OPEN") })
          const rec3 = r2.records.filter(r => { return (!global.healthChatNotifications.doctor[r.xID] && r.status !== "OPEN") })
          records = [...rec1, ...rec2, ...rec3]
        }
        this.setState({ records, recordsLoaded: true, screen: "MAIN" })
      } else return alert("Backend error")
    } catch (error) {
      global.warn(error, "Error lhf")
    }
  }



  renderMain() {
    return (<View style={{ backgroundColor: "#fff", height: "100%", width: "100%" }}>
      <View style={{ borderColor: "#312", borderRadius: 0, borderWidth: 0, }}>
        {this.isFullScreen && <View>
          <Image style={{ width: "100%", alignSelf: "center", height: 150, justifyContent: "flex-end", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
            source={require("../../../images/images/DD.jpeg")} />
          <View style={{ marginTop: -50, height: 50, backgroundColor: "rgba(255,0,0,0.75)", justifyContent: "center", alignItems: "center", flexDirection: "row" }} >
            <Image style={{ width: 28, height: 22, tintColor: "#faa", marginRight: 8 }} source={require("../../../images/icons/File.png")} />
            <Text style={{ color: "white", fontSize: 28, fontWeight: "100", marginRight: 30 }}>Health Records</Text>
            {/* <Text style={{ fontSize: 16, fontWeight: "bold" }}> </Text> */}
          </View>
        </View>}
        <KeyboardAwareScrollView
          enableOnAndroid={true}
          extraHeight={150}
        >
          {(!this.state.recordsLoaded) && <View style={{ flexDirection: "row", height: 100, width: '100%', justifyContent: "center", alignItems: "center" }}>
            <ActivityIndicator size="small" color="#444" style={{ marginRight: 8 }} />
            <Text style={{ color: "#444" }}>Loading Records...</Text>
          </View>}
          {this.state.recordsLoaded && <View style={{ margin: 15 }}>
            {(this.state.records.length == 0) && <View style={{ height: 600, justifyContent: "center", padding: 5, alignItems: "center" }} >
              <Image style={{ width: 90, height: 111, tintColor: "gray" }} source={require("../../../images/icons/Record.png")} />
              <Text style={{ fontSize: 18, color: "gray", fontWeight: "bold", marginTop: 20 }}>No Records yet</Text>
            </View>}
            {(this.state.records.length > 0) && <View style={{ marginBottom: 10, marginTop: 70, marginLeft: 4 }}><Text style={{ color: "black", fontSize: 20, fontWeight: "bold" }}>Records:</Text></View>}
            {(this.state.records.length > 0) && this.state.records.map((rec, i) => { return (<Record showPatient key={rec.xID} record={rec} navigation={this.props.navigation} isFirst={i == 0} file={this.state.file} />) })}
          </View>}
          {this.state.aaRefresh && <View style={{ alignSelf: "center" }}><Text style={{ fontSize: 12, color: "gray", marginTop: 20 }}>Updating ...</Text></View>}
          <View style={{ height: 25 }} />
        </KeyboardAwareScrollView>
        <NavigationEvents
          onDidFocus={async payload => {
            await this.setState({ aaRefresh: true })
            await global.timeout(50)
            await this.setState({ aaRefresh: false })
          }}
        />
      </View>
    </View>)
  }

  renderLoading() {
    return (<View style={{ backgroundColor: "#fff", height: "100%", width: "100%", alignItems: "center", justifyContent: "center" }}>
      <Image style={{ borderRadius: 17, height: 100, width: 200, marginBottom: -65 }}
        source={require("../../../images/images/DD.jpeg")} />
      <ActivityIndicator size="large" color="white" animating={true} />
      <Text style={{ color: "#888", fontSize: 18, fontStyle: "italic", marginTop: 50 }}>Getting the records straight...</Text>
    </View>)
  }

  render() {
    if (this.props.hide) return (<View />)

    if (this.state.screen == "MAIN") return this.renderMain()
    if (this.state.screen == "LOADING") return this.renderLoading()
    return (<View />)
  }
}

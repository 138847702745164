
import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  Alert,
  TextInput,
  Dimensions,
  Modal,
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import GetPicture from "../../../shared/Camera/GetPicture";
import { NavigationEvents, ThemeColors } from "react-navigation";

let speechDetails = {}
let cspeechStatus = {}
let currentDetails = {}



export default class JomeInnovationKitchenAdminScreen extends Component {

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Innovation Kitchen Admin",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
    }
  };

  state = {
    editAgenda: false,
    editwkloading: false,
    showGetPicture: false,
    editSpeech: false,
    loading: true,
    makeCurrentSpeechLoading: false,
    removeAsCurrentSpeechLoading: false,
    wkmodalVisible: false,
    selectedWkValue: 0,
    wkTempTitle: '',
    modalVisible: false,
    modalDataName: "",
    modalDataTitle: "",
    modalDataDescription: "",
    modalDataTitle: "",
    modalDataImage: "",
    modalDataID: "",
    showCreateSpeech: false,
    showSpeechDetails: {},
    currentSpeeches: [],
    currentSpeechStatus: {},
    name: "",
    designation: "",
    title: "",
    description: "",
    image: "",
    showDay1Agenda: false,
    showDay2Agenda: false,
    showDay3Agenda: false,
    speeches: [],
    currentDetails: {},
    agenda: {
      day1: [
        {
          id: 1,
          start_time: "9am",
          end_time: "10am",
          activity: "Registration",
          person: ""
        }, {
          id: 2,
          start_time: "10am",
          end_time: "10.30am",
          activity: "Welcome Remarks; Information, announcements and introduction of Keynote Speakers",
          person: ""
        }, {
          id: 3,
          start_time: "10.30am",
          end_time: "11.15am",
          activity: "Keynote address 1 + Q & A ",
          person: "AKINTAYO BAMIDURO"
        }, {
          id: 4,
          start_time: "11.15am",
          end_time: "12pm",
          activity: "Keynote address 2 + Q & A ",
          person: "TUNDE ADEGBOLA"
        }, {
          id: 5,
          start_time: "12pm",
          end_time: "12.45pm",
          activity: "Keynote address 3 + Q & A ",
          person: "PROF. ADEWUNMI TAIWO"
        }, {
          id: 6,
          start_time: "12.45pm",
          end_time: "1pm",
          activity: "Introduction to Break-out sessions & Round off of Keynotes",
          person: ""
        }, {
          id: 7,
          start_time: "1pm",
          end_time: "1.30pm",
          activity: "Lunch Break",
          person: ""
        }, {
          id: 8,
          start_time: "1.30pm",
          end_time: "2pm",
          activity: "Introduction & Special Presentations",
          person: ""
        }, {
          id: 9,
          start_time: "2pm",
          end_time: "3pm",
          activity: "Break-Out Sessions 1",
          person: "MR MICHAEL NWAOGU, RALLY, IBIYEMI IFATUROTI"
        }, {
          id: 10,
          start_time: "3pm",
          end_time: "4pm",
          activity: "Break-Out Sessions 2",
          person: "EDEM OSSAI, RALLY, GBENGA ADENUGA"
        }, {
          id: 11,
          start_time: "4pm",
          end_time: "5pm",
          activity: "Break-Out Sessions 3",
          person: "MOYOFADE IPADEOLA, RALLY, SUNDAY AFOLAYAN"
        }, {
          id: 12,
          start_time: "5pm",
          end_time: "6pm",
          activity: "Closing with live Jazz Music",
          person: ""
        }

      ],
      day2: [
        {
          id: 1,
          start_time: "9am",
          end_time: "10am",
          activity: "Registration",
          person: ""
        }, {
          id: 2,
          start_time: "10am",
          end_time: "10.30am",
          activity: "Welcome Remarks; Information, announcements and introduction of Keynote Speakers",
          person: ""
        }, {
          id: 3,
          start_time: "10.30am",
          end_time: "11.15am",
          activity: "Keynote address 1 + Q & A ",
          person: "AKINTAYO BAMIDURO"
        }, {
          id: 4,
          start_time: "11.15am",
          end_time: "12pm",
          activity: "Keynote address 2 + Q & A ",
          person: "TUNDE ADEGBOLA"
        }, {
          id: 5,
          start_time: "12pm",
          end_time: "12.45pm",
          activity: "Keynote address 3 + Q & A ",
          person: "PROF. ADEWUNMI TAIWO"
        }, {
          id: 6,
          start_time: "12.45pm",
          end_time: "1pm",
          activity: "Introduction to Break-out sessions & Round off of Keynotes",
          person: ""
        }, {
          id: 7,
          start_time: "1pm",
          end_time: "1.30pm",
          activity: "Lunch Break",
          person: ""
        }, {
          id: 8,
          start_time: "1.30pm",
          end_time: "2pm",
          activity: "Introduction & Special Presentations",
          person: ""
        }, {
          id: 9,
          start_time: "2pm",
          end_time: "3pm",
          activity: "Break-Out Sessions 1",
          person: "MR MICHAEL NWAOGU, RALLY, IBIYEMI IFATUROTI"
        }, {
          id: 10,
          start_time: "3pm",
          end_time: "4pm",
          activity: "Break-Out Sessions 2",
          person: "EDEM OSSAI, RALLY, GBENGA ADENUGA"
        }, {
          id: 11,
          start_time: "4pm",
          end_time: "5pm",
          activity: "Break-Out Sessions 3",
          person: "MOYOFADE IPADEOLA, RALLY, SUNDAY AFOLAYAN"
        }, {
          id: 12,
          start_time: "5pm",
          end_time: "6pm",
          activity: "Closing with live Jazz Music",
          person: ""
        }

      ],

      day3: [
        {
          id: 1,
          start_time: "9am",
          end_time: "10am",
          activity: "Registration",
          person: ""
        }, {
          id: 2,
          start_time: "10am",
          end_time: "10.30am",
          activity: "Welcome Remarks; Information, announcements and introduction of Keynote Speakers",
          person: ""
        }, {
          id: 3,
          start_time: "10.30am",
          end_time: "11.15am",
          activity: "Keynote address 1 + Q & A ",
          person: "TBD"
        }, {
          id: 4,
          start_time: "11.15am",
          end_time: "12pm",
          activity: "Keynote address 2 + Q & A ",
          person: "TBD"
        }, {
          id: 5,
          start_time: "12pm",
          end_time: "12.45pm",
          activity: "Keynote address 3 + Q & A ",
          person: "TBD"
        }, {
          id: 6,
          start_time: "12.45pm",
          end_time: "1pm",
          activity: "Introduction to Break-out sessions & Round off of Keynotes",
          person: ""
        }, {
          id: 7,
          start_time: "1pm",
          end_time: "1.30pm",
          activity: "Lunch Break",
          person: ""
        }, {
          id: 8,
          start_time: "1.30pm",
          end_time: "2pm",
          activity: "Introduction & Special Presentations",
          person: ""
        }, {
          id: 9,
          start_time: "2pm",
          end_time: "3pm",
          activity: "Break-Out Sessions 1",
          person: "AFRICAN FOUNDERS"
        }, {
          id: 10,
          start_time: "3pm",
          end_time: "4pm",
          activity: "Break-Out Sessions 2",
          person: "TBD"
        }, {
          id: 11,
          start_time: "4pm",
          end_time: "5pm",
          activity: "Break-Out Sessions 3",
          person: "TBD"
        }, {
          id: 12,
          start_time: "5pm",
          end_time: "6pm",
          activity: "Closing with live Jazz Music",
          person: ""
        }

      ]
    },
    agendaModal_day: 1,
    agendaModal1_start_time: "",
    agendaModal1_end_time: "",
    agendaModal1_activity: "",
    agendaModal1_person: "",
    agendaModal1_id: "",
    agendaModal2_start_time: "",
    agendaModal2_end_time: "",
    agendaModal2_activity: "",
    agendaModal2_person: "",
    agendaModal1_id: "",
    agendaModal3_start_time: "",
    agendaModal3_end_time: "",
    agendaModal3_activity: "",
    agendaModal3_person: "",
    agendaModal3_id: "",
    agendaModal4_start_time: "",
    agendaModal4_end_time: "",
    agendaModal4_activity: "",
    agendaModal4_person: "",
    agendaModal4_id: "",
    agendaModal5_start_time: "",
    agendaModal5_end_time: "",
    agendaModal5_activity: "",
    agendaModal5_person: "",
    agendaModal5_id: "",
    agendaModal6_start_time: "",
    agendaModal6_end_time: "",
    agendaModal6_activity: "",
    agendaModal6_person: "",
    agendaModal6_id: "",
    agendaModal7_start_time: "",
    agendaModal7_end_time: "",
    agendaModal7_activity: "",
    agendaModal7_person: "",
    agendaModal7_id: "",
    agendaModal8_start_time: "",
    agendaModal8_end_time: "",
    agendaModal8_activity: "",
    agendaModal8_person: "",
    agendaModal8_id: "",
    agendaModal9_start_time: "",
    agendaModal9_end_time: "",
    agendaModal9_activity: "",
    agendaModal9_person: "",
    agendaModal9_id: "",
    agendaModal10_start_time: "",
    agendaModal10_end_time: "",
    agendaModal10_activity: "",
    agendaModal10_person: "",
    agendaModal10_id: "",
    agendaModal11_start_time: "",
    agendaModal11_end_time: "",
    agendaModal11_activity: "",
    agendaModal11_person: "",
    agendaModal11_id: "",
    agendaModal12_start_time: "",
    agendaModal12_end_time: "",
    agendaModal12_activity: "",
    agendaModal12_person: "",
    agendaModal12_id: "",
    activityIndicatorAnimating: false,
    showDayOneWorkshop: false,
    showDayTwoWorkshop: false,
    showDayThreeWorkshop: false,
    wk1: "workshop1",
    wk2: "workshop2",
    wk3: "workshop3",
    wk4: "workshop4",
    wk5: "workshop5",
    wk6: "workshop6",
    wk7: "workshop7",
    wk8: "workshop8",
    wk9: "workshop9",
    wk10: "workshop10",
    wk11: "workshop11",
    wk12: "workshop12",
    wk13: "workshop13",
    wk14: "workshop14",
    wk15: "workshop15",
    wk16: "workshop16",
    wk17: "workshop17",
    wk18: "workshop18",
    wk19: "workshop19",
    wk20: "workshop20",
    wk21: "workshop21",
    wk22: "workshop22",
    wk23: "workshop23",
    wk24: "workshop24",
    wk25: "workshop25",
    wk26: "workshop26",
    wk27: "workshop27",
    wk1JoinerIDs: [],
    wk2JoinerIDs: [],
    wk3JoinerIDs: [],
    wk4JoinerIDs: [],
    wk5JoinerIDs: [],
    wk6JoinerIDs: [],
    wk7JoinerIDs: [],
    wk8JoinerIDs: [],
    wk9JoinerIDs: [],
    wk10JoinerIDs: [],
    wk11JoinerIDs: [],
    wk12JoinerIDs: [],
    wk13JoinerIDs: [],
    wk14JoinerIDs: [],
    wk15JoinerIDs: [],
    wk16JoinerIDs: [],
    wk17JoinerIDs: [],
    wk18JoinerIDs: [],
    wk19JoinerIDs: [],
    wk20JoinerIDs: [],
    wk21JoinerIDs: [],
    wk22JoinerIDs: [],
    wk23JoinerIDs: [],
    wk24JoinerIDs: [],
    wk25JoinerIDs: [],
    wk26JoinerIDs: [],
    wk27JoinerIDs: [],
  };





  generateRandomID() {
    let r = Math.random().toString(36).substring(10)
    console.log("Random ID generated is: ", r)
    return r
  }



  setModalVisible(visible) {
    this.setState({ modalVisible: visible });
  }


  getSpeeches = async () => {
    try {

      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "ikGeneral", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "getSpeeches",
          myUID: global.myUID,
          idToken: global.idToken,
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        console.log(" success! r2 speeches are: ", r2.speeches)
        this.setState({ speeches: r2.speeches, loading: false })
        //alert("Speech successfully created!")
      } else {
        this.setState({ loading: false })
        alert("Something went wrong fetching speeches. Try again later!")
      }

    } catch (err) {
      console.log(" err is ", err)
      this.setState({ loading: false })
      await global.timeout(50)
      alert("Error. Try again later!")
    }


    await this.state.speeches.forEach((speech) => {
      speechDetails[speech.id] = false
      if (speech["isCurrentSpeech"] == true) {
        cspeechStatus[speech.id] = true
      } else {
        cspeechStatus[speech.id] = false
      }

    })


    this.setState({ currentSpeechStatus: cspeechStatus })
  }





  editAgenda = async () => {
    if (!this.state.agenda) {
      return alert(" Error. ")
    }

    this.setState({ editAgendaAnimationLoading: true })

    try {

      const r = await fetch(global.cloudFunctionURL + "ikAdmin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "updateAgenda",
          myUID: global.myUID,
          idToken: global.idToken,
          agenda: this.state.agenda
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        console.log(" success! ")
        this.setState({ editAgendaAnimationLoading: false, editAgenda: false })
        alert("Agenda successfully updated!")
        this.getSpeeches()

      } else {
        this.setState({ editAgendaAnimationLoading: false })
        alert("Something went wrong. Try again later!")
      }

    } catch (err) {
      console.log(" err is ", err)
      this.setState({ editAgendaAnimationLoading: false })
      await global.timeout(50)
      alert("Error. Try again later!")
    }
  }




  editSpeech = async () => {
    if (this.state.modalDataTitle == "") {
      return alert(" Please enter a speech title!")
    }

    this.setState({ editSpeechAnimationLoading: true })

    try {

      const r = await fetch(global.cloudFunctionURL + "ikAdmin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "updateSpeech",
          myUID: global.myUID,
          idToken: global.idToken,
          speechID: this.state.modalDataID,
          speech: {
            name: this.state.modalDataName,
            designation: this.state.modalDataDesignation,
            image: this.state.image || this.state.modalDataImage,
            title: this.state.modalDataTitle,
            description: this.state.modalDataDescription,
          }
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        console.log(" success! ")
        this.setState({ editSpeechAnimationLoading: false, editSpeech: false })
        alert("Speech successfully updated!")
        this.getSpeeches()

      } else {
        this.setState({ editSpeechAnimationLoading: false })
        alert("Something went wrong. Try again later!")
      }

    } catch (err) {
      console.log(" err is ", err)
      this.setState({ editSpeechAnimationLoading: false })
      await global.timeout(50)
      alert("Error. Try again later!")
    }
  }



  createSpeech = async () => {

    if (this.state.title == "") {
      return alert(" Please enter a speech title!")
    }

    this.setState({ activityIndicatorAnimating: true })

    try {

      const r = await fetch(global.cloudFunctionURL + "ikAdmin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "addSpeech",
          myUID: global.myUID,
          idToken: global.idToken,
          speech: {
            name: this.state.name,
            designation: this.state.designation,
            image: this.state.image,
            title: this.state.title,
            description: this.state.description,
          }
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        console.log(" success! ")
        this.setState({ activityIndicatorAnimating: false, showCreateSpeech: false })
        alert("Speech successfully created!")
        this.getSpeeches()

      } else {
        this.setState({ activityIndicatorAnimating: false })
        alert("Something went wrong. Try again later!")
      }

    } catch (err) {
      console.log(" err is ", err)
      this.setState({ activityIndicatorAnimating: false })
      await global.timeout(50)
      alert("Error. Try again later!")
    }
  }





  editAgendaItem = (agenda_data) => {
    /* console.log("Agenda_item is: ", agenda_item)
    let fullAgenda = this.state.agenda
    
    fullAgenda[day].forEach(item =>{
      if(item.id == agenda_item.id){
        item = agenda_item
      }
    })

    this.setState({agenda:fullAgenda}) */
  }


  makeCurrentSpeech = async (speech) => {

    console.log(" speech received in makecurrentspeech function is: ", speech)
    this.setState({ makeCurrentSpeechLoading: true })
    try {

      const r = await fetch(global.cloudFunctionURL + "ikAdmin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "setCurrentSpeech",
          myUID: global.myUID,
          idToken: global.idToken,
          speechID: speech.id
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        let speechdata = this.state.currentSpeeches
        speechdata.push(speech)
        cspeechStatus[speech.id] = true
        //speechDetails[speech.id] = (speechDetails[speech.id] == true) ? false : true
        speechDetails[speech.id] = false
        this.setState({ currentSpeeches: speechdata, showSpeechDetails: speechDetails, currentSpeechStatus: cspeechStatus, makeCurrentSpeechLoading: false })
        this.getSpeeches()
        //alert("Speech successfully created!")
      } else {
        this.setState({ makeCurrentSpeechLoading: false })
        alert("Something went wrong fetching speeches. Try again later!")
      }

    } catch (err) {
      console.log(" err is ", err)
      this.setState({ makeCurrentSpeechLoading: false })
      await global.timeout(50)
      alert("Error. Try again later!")
    }
  }



  removeAsCurrentSpeech = async (cspeech) => {
    this.setState({ removeAsCurrentSpeechLoading: true })
    try {

      const r = await fetch(global.cloudFunctionURL + "ikAdmin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "setCurrentSpeech",
          myUID: global.myUID,
          idToken: global.idToken,
          speechID: "none"
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {

        let speechdata = this.state.currentSpeeches
        let final = []
        speechdata.forEach(item => {
          if (item !== cspeech) {
            final.push(cspeech)
          }
        })
        this.setState({ currentSpeeches: final })
        cspeechStatus[cspeech.id] = false
        speechDetails[cspeech.id] = (speechDetails[cspeech.id] == true) ? false : true
        this.setState({ showSpeechDetails: speechDetails, currentSpeechStatus: cspeechStatus, removeAsCurrentSpeechLoading: false })

        //alert("Speech successfully created!")
      } else {
        this.setState({ removeAsCurrentSpeechLoading: false })
        alert("Something went wrong fetching speeches. Try again later!")
      }

    } catch (err) {
      console.log(" err is ", err)
      this.setState({ removeAsCurrentSpeechLoading: false })
      await global.timeout(50)
      alert("Error. Try again later!")
    }
  }




  deleteSpeech = async (speech) => {


    this.setState({ editSpeechAnimationLoading: true })

    try {

      const r = await fetch(global.cloudFunctionURL + "ikAdmin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "updateSpeech",
          myUID: global.myUID,
          idToken: global.idToken,
          speechID: this.state.speech.id,
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        console.log(" success! ")
        this.setState({ editSpeechAnimationLoading: false, editSpeech: false })
        alert("Speech successfully updated!")
        this.getSpeeches()

      } else {
        this.setState({ editSpeechAnimationLoading: false })
        alert("Something went wrong. Try again later!")
      }

    } catch (err) {
      console.log(" err is ", err)
      this.setState({ editSpeechAnimationLoading: false })
      await global.timeout(50)
      alert("Error. Try again later!")
    }
  }




  getWkValue = (num) => {
    if (num == 1) {
      return this.state.wk1
    } else if (num == 2) {
      return this.state.wk2
    } else if (num == 3) {
      return this.state.wk3
    } else if (num == 4) {
      return this.state.wk4
    } else if (num == 5) {
      return this.state.wk5
    } else if (num == 6) {
      return this.state.wk6
    } else if (num == 7) {
      return this.state.wk7
    } else if (num == 8) {
      return this.state.wk8
    } else if (num == 9) {
      return this.state.wk9
    } else if (num == 10) {
      return this.state.wk10
    } else if (num == 11) {
      return this.state.wk11
    } else if (num == 12) {
      return this.state.wk12
    } else if (num == 13) {
      return this.state.wk13
    } else if (num == 14) {
      return this.state.wk14
    } else if (num == 15) {
      return this.state.wk15
    } else if (num == 16) {
      return this.state.wk16
    } else if (num == 17) {
      return this.state.wk17
    } else if (num == 18) {
      return this.state.wk18
    } else if (num == 19) {
      return this.state.wk19
    } else if (num == 20) {
      return this.state.wk20
    } else if (num == 21) {
      return this.state.wk21
    } else if (num == 22) {
      return this.state.wk22
    } else if (num == 23) {
      return this.state.wk23
    } else if (num == 24) {
      return this.state.wk24
    } else if (num == 25) {
      return this.state.wk25
    } else if (num == 26) {
      return this.state.wk26
    } else if (num == 27) {
      return this.state.wk27
    }
  }


  setWkModalValue = (num, value) => {
    if (num == 1) {
      this.setState({ wk1: value })
    } else if (num == 2) {
      this.setState({ wk2: value })
    } else if (num == 3) {
      this.setState({ wk3: value })
    } else if (num == 4) {
      this.setState({ wk4: value })
    } else if (num == 5) {
      this.setState({ wk5: value })
    } else if (num == 6) {
      this.setState({ wk6: value })
    } else if (num == 7) {
      this.setState({ wk7: value })
    } else if (num == 8) {
      this.setState({ wk8: value })
    } else if (num == 9) {
      this.setState({ wk9: value })
    } else if (num == 10) {
      this.setState({ wk10: value })
    } else if (num == 11) {
      this.setState({ wk11: value })
    } else if (num == 12) {
      this.setState({ wk12: value })
    } else if (num == 13) {
      this.setState({ wk13: value })
    } else if (num == 14) {
      this.setState({ wk14: value })
    } else if (num == 15) {
      this.setState({ wk15: value })
    } else if (num == 16) {
      this.setState({ wk16: value })
    } else if (num == 17) {
      this.setState({ wk17: value })
    } else if (num == 18) {
      this.setState({ wk18: value })
    } else if (num == 19) {
      this.setState({ wk19: value })
    } else if (num == 20) {
      this.setState({ wk20: value })
    } else if (num == 21) {
      this.setState({ wk21: value })
    } else if (num == 22) {
      this.setState({ wk22: value })
    } else if (num == 23) {
      this.setState({ wk23: value })
    } else if (num == 24) {
      this.setState({ wk24: value })
    } else if (num == 25) {
      this.setState({ wk25: value })
    } else if (num == 26) {
      this.setState({ wk26: value })
    } else if (num == 27) {
      this.setState({ wk27: value })
    }

  }


  getWorkshops = async () => {
    try {
      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Apps")
        .doc("innovationKitchen")
        .onSnapshot(this.updateWorkshops);
    } catch (err) {
      console.log(" err is ", err)
    }

  }



  calculateRegistrants = (key) => {

    console.log(" joiner variable is ", this.state[`${key}JoinerIDs`].length || 0)
    return this.state[`${key}JoinerIDs`].length || 0
  }



  updateWorkshops = async (wDoc) => {
    let w = wDoc.data()
    console.log(" data obtained is ", w)
    this.setState({
      wk1: w.wk1 || "",
      wk2: w.wk2 || "",
      wk3: w.wk3 || "",
      wk4: w.wk4 || "",
      wk5: w.wk5 || "",
      wk6: w.wk6 || "",
      wk7: w.wk7 || "",
      wk8: w.wk8 || "",
      wk9: w.wk9 || "",
      wk10: w.wk10 || "",
      wk11: w.wk11 || "",
      wk12: w.wk12 || "",
      wk13: w.wk13 || "",
      wk14: w.wk14 || "",
      wk15: w.wk15 || "",
      wk16: w.wk16 || "",
      wk17: w.wk17 || "",
      wk18: w.wk18 || "",
      wk19: w.wk19 || "",
      wk20: w.wk20 || "",
      wk21: w.wk21 || "",
      wk22: w.wk22 || "",
      wk23: w.wk23 || "",
      wk24: w.wk24 || "",
      wk25: w.wk25 || "",
      wk26: w.wk26 || "",
      wk27: w.wk27 || "",
      wk1JoinerIDs: w.wk1JoinerIDs || [],
      wk2JoinerIDs: w.wk2JoinerIDs || [],
      wk3JoinerIDs: w.wk3JoinerIDs || [],
      wk4JoinerIDs: w.wk4JoinerIDs || [],
      wk5JoinerIDs: w.wk5JoinerIDs || [],
      wk6JoinerIDs: w.wk6JoinerIDs || [],
      wk7JoinerIDs: w.wk7JoinerIDs || [],
      wk8JoinerIDs: w.wk8JoinerIDs || [],
      wk9JoinerIDs: w.wk9JoinerIDs || [],
      wk10JoinerIDs: w.wk10JoinerIDs || [],
      wk11JoinerIDs: w.wk11JoinerIDs || [],
      wk12JoinerIDs: w.wk12JoinerIDs || [],
      wk13JoinerIDs: w.wk13JoinerIDs || [],
      wk14JoinerIDs: w.wk14JoinerIDs || [],
      wk15JoinerIDs: w.wk15JoinerIDs || [],
      wk16JoinerIDs: w.wk16JoinerIDs || [],
      wk17JoinerIDs: w.wk17JoinerIDs || [],
      wk18JoinerIDs: w.wk18JoinerIDs || [],
      wk19JoinerIDs: w.wk19JoinerIDs || [],
      wk20JoinerIDs: w.wk20JoinerIDs || [],
      wk21JoinerIDs: w.wk21JoinerIDs || [],
      wk22JoinerIDs: w.wk22JoinerIDs || [],
      wk23JoinerIDs: w.wk23JoinerIDs || [],
      wk24JoinerIDs: w.wk24JoinerIDs || [],
      wk25JoinerIDs: w.wk25JoinerIDs || [],
      wk26JoinerIDs: w.wk26JoinerIDs || [],
      wk27JoinerIDs: w.wk27JoinerIDs || [],
      agenda: w.agenda
    })
  }


  updateOneWorkshop = async (key, val) => {
    this.setState({ editwkloading: true })
    try {
      const r = await fetch(global.cloudFunctionURL + "ikAdmin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "updateWorkshopInformation",
          myUID: global.myUID,
          idToken: global.idToken,
          workshopKey: key,
          workshopTitle: val
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        //console.log(" success! r2 speeches are: ", ) 
        //this.setState({ showDayOneWorkshop: false, showDayTwoWorkshop: false, showDayThreeWorkshop: false, wkmodalVisible: false, editwkloading:false })
        this.setState({ wkmodalVisible: false })
        alert("Success!")
      } else {
        this.setState({ editwkloading: false })
        alert(`Something went wrong editing workshop ${key}'s details. Try again later!`)
      }

    } catch (err) {
      this.setState({ editwkloading: false })
      alert("Error. Try again later!")
    }
  }

  async componentDidMount() {
    console.log(" IK event admin screen mounting...")
    try {
      this.getWorkshops()
      const r = await fetch(global.cloudFunctionURL + "ikGeneral", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "getSpeeches",
          myUID: global.myUID,
          idToken: global.idToken,
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        console.log(" success! r2 speeches are: ", r2.speeches, "\n total data received is ", r2)
        this.setState({ speeches: r2.speeches, loading: false })
        //alert("Speech successfully created!")
      } else {
        this.setState({ loading: false })
        alert("Something went wrong fetching speeches. Try again later!")
      }

    } catch (err) {
      console.log(" err is ", err)
      this.setState({ loading: false })
      await global.timeout(50)
      alert("Error. Try again later!")
    }


    await this.state.speeches.forEach((speech) => {
      speechDetails[speech.id] = false
      if (speech["isCurrentSpeech"] == true) {
        cspeechStatus[speech.id] = true
        this.makeCurrentSpeech(speech)
      } else {
        cspeechStatus[speech.id] = false
      }

    })


    this.setState({ currentSpeechStatus: cspeechStatus })
    //console.log("currentSpeechStatus in state is: ", this.state.currentSpeechStatus)

  }



  componentWillUnmount() {
    console.log("Component homeInnovationKitchenAdmin will unmount");
    this.unsubscribeFirestore();
  }



  render() {
    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: global.app == "health" ? "#fff" : "#131313"
        }}
      >
        <StatusBar barStyle="light-content" />

        <View
          style={{
            backgroundColor: "#131313",
            flex: 1
          }}
        >
          <View
            style={{
              backgroundColor: "#131313",
              borderBottomColor: "#555",
              borderBottomWidth: 1,
              ...Platform.select({
                ios: {
                  height: 55
                },
                android: {
                  height: 75
                }
              })
            }}
          />
          <ScrollView>
            <View id="Create_Speech" style={{ justifyContent: "center", padding: 20 }}>
              <Text style={{ color: "red", fontWeight: "bold", marginBottom: 10, fontSize: 16 }}>Dashboard</Text>
              <View style={{ backgroundColor: "#272727", borderRadius: 15, padding: 15, }}>
                <View style={{ borderBottomWidth: 1, borderColor: '#3C3C3C' }}>
                  <Text style={{ color: "white", fontWeight: "bold", fontSize: 13, marginVertical: 10 }}>Speeches</Text>
                  {!this.state.showCreateSpeech && <TouchableOpacity id="Conference_Program_Button"
                    onPress={() => {
                      this.setState({ showCreateSpeech: true })
                    }}
                    style={{
                      backgroundColor: '#A8CF45', height: 40, alignSelf: "center", width: 40, top: -12, right: 0,
                      flex: 1, justifyContent: "center", alignItems: "center", borderRadius: 20, position: "absolute",
                      alignSelf: "flex-start"
                    }}>
                    <Text style={{ color: 'white', fontWeight: "bold", }}>+</Text>
                  </TouchableOpacity>}

                  {this.state.showCreateSpeech && <TouchableOpacity id="Conference_Program_Button"
                    onPress={() => {
                      this.setState({ showCreateSpeech: false })
                    }}
                    style={{
                      backgroundColor: 'red', height: 40, alignSelf: "center", width: 40, top: -12, right: 0,
                      flex: 1, justifyContent: "center", alignItems: "center", borderRadius: 20, position: "absolute",
                      alignSelf: "flex-start"
                    }}>
                    <Text style={{ color: 'white', fontWeight: "bold", fontSize: 10 }}>X</Text>
                  </TouchableOpacity>}

                </View>

                {this.state.speeches.length == 0 && !this.state.showCreateSpeech && <View id="scheduled_speeches" style={{ paddingTop: 10 }}>
                  <Text style={{ color: "grey", fontSize: 12 }}>No speeches scheduled yet...</Text>
                  <Text style={{ color: "grey", fontSize: 12 }}>Kindly tap the plus button to create one.</Text>
                </View>}

                {this.state.showCreateSpeech && <View>
                  <View style={{ paddingVertical: 5, }}>
                    <Text style={{ color: "#A8CF45", fontWeight: "bold", fontSize: 12, marginVertical: 10 }}>Create New Event Speech</Text>
                  </View>

                  <View>
                    <Text style={{ color: "#A8CF45", fontSize: 12, marginBottom: 3 }}>Title</Text>
                    <TextInput placeholder={"What is the title of the speech?..."} onChange={(e) => {
                      this.setState({ title: e.nativeEvent.text })
                      console.log(" title text is ", e.nativeEvent.text)
                    }}
                      style={{ minHeight: 30, backgroundColor: "#777", borderRadius: 10, padding: 5, paddingVertical: 2, color: "white" }}></TextInput>
                  </View>

                  <View style={{ marginTop: 10, }}>
                    <Text style={{ color: "#A8CF45", fontSize: 12, marginBottom: 3 }}>Description</Text>
                    <TextInput placeholder={"Enter speech details here..."} multiline={true}
                      onChange={(e) => {
                        this.setState({ description: e.nativeEvent.text })
                        console.log(" Description text is ", e.nativeEvent.text)
                      }}
                      style={{ minHeight: 30, backgroundColor: "#777", borderRadius: 10, padding: 5, color: "white" }}></TextInput>
                  </View>


                  <View style={{ padding: 10, backgroundColor: "#444", marginTop: 20, borderRadius: 10 }}>
                    <Text style={{ color: "white", fontSize: 12, marginBottom: 3, fontWeight: "bold", marginBottom: 10 }}>Speaker Section</Text>
                    <View style={{ paddingTop: 10, flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderTopWidth: 1, borderTopColor: "white" }}>
                      <Image
                        style={{ width: 60, height: 60, resizeMode: "cover", borderRadius: 15 }}
                        source={{ uri: this.state.image || 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/Events%2Favatar.jpg?alt=media&token=babac7eb-0973-4c9a-a0e6-6efff5ecc477' }}
                      ></Image>
                      <TouchableOpacity id="Upload image button"
                        onPress={() => { this.setState({ showGetPicture: true }) }}
                        style={{
                          backgroundColor: 'red', height: 40, width: "60%", alignSelf: "center", justifyContent: "center", alignItems: "center", borderRadius: 10,
                        }}>
                        <Text style={{ color: 'white', fontWeight: "bold", fontSize: 9 }}>Upload Image</Text>
                      </TouchableOpacity>
                    </View>

                    <View style={{ marginTop: 10, }}>
                      <Text style={{ color: "white", fontSize: 12, marginBottom: 3 }}>Name:</Text>
                      <TextInput placeholder={"Name of speaker"} onChange={(e) => {
                        this.setState({ name: e.nativeEvent.text })
                        console.log(" Description text is ", e.nativeEvent.text)
                      }}
                        style={{ minHeight: 30, backgroundColor: "#777", borderRadius: 10, padding: 5, color: "white" }}></TextInput>
                    </View>
                    <View style={{ marginTop: 10, }}>
                      <Text style={{ color: "white", fontSize: 12, marginBottom: 3 }}>Designation:</Text>
                      <TextInput placeholder={"Is the person a COO, a CEO, Founder etc ?"} style={{ fontSize: 8 }}
                        onChange={(e) => {
                          this.setState({ designation: e.nativeEvent.text })
                          console.log(" Description text is ", e.nativeEvent.text)
                        }}
                        style={{ minHeight: 30, backgroundColor: "#777", borderRadius: 10, padding: 5, color: "white" }}></TextInput>
                    </View>

                    <View style={{ marginTop: 15, paddingTop: 10, flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderTopWidth: 1, borderTopColor: "white" }}>
                      <TouchableOpacity id="Upload image button"
                        onPress={() => { this.createSpeech() }}
                        style={{
                          backgroundColor: '#A8CF45', height: 40, width: "100%", alignSelf: "center", justifyContent: "center", alignItems: "center", borderRadius: 10,
                        }}>
                        {!!this.state.activityIndicatorAnimating ? (
                          <View
                            style={{
                              //backgroundColor: "#223",
                              justifyContent: "center",
                              alignItems: "center",
                              //padding: 15,
                              flex: 1
                            }}
                          >
                            <ActivityIndicator
                              size="large"
                              color="#fff"
                              style={{
                                opacity: 1
                              }}
                              animating={true}
                            />
                          </View>
                        ) : <Text style={{ color: 'white', fontWeight: "bold", fontSize: 9 }}>Save</Text>}
                      </TouchableOpacity>
                    </View>

                  </View>

                </View>}

                {!!this.state.loading &&
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 15,
                      flex: 1
                    }}
                  >
                    <ActivityIndicator
                      size="large"
                      color="#fff"
                      style={{
                        opacity: 1
                      }}
                      animating={true}
                    />
                  </View>
                }
                {this.state.speeches.length > 0 && !this.state.showCreateSpeech && <View id="scheduled_speeches" style={{ paddingTop: 10 }}>

                  {this.state.speeches.length > 0 && <View style={{ borderBottomWidth: 1, borderBottomColor: "red" }}>
                    {this.state.currentSpeeches.length == 0 && <Text style={{ color: "white", fontSize: 11, marginVertical: 10 }}> No current speeches ongoing...</Text>}
                    {this.state.currentSpeeches.length > 0 && <Text style={{ color: "red", fontSize: 11, marginVertical: 10, fontWeight: "bold", marginBottom: 0 }}>  Live</Text>}

                    <View style={{ marginBottom: 10 }}>
                      {this.state.speeches.map((cspeech, id) => {

                        return (
                          <View key={id}>

                            {cspeechStatus[cspeech.id] == true && <View >
                              <TouchableOpacity onPress={async () => {
                                //speechDetails[speech.id] = (speechDetails[speech.id] == true)? false : (speechDetails[speech.id]== false)?true:false 
                                speechDetails[cspeech.id] = (speechDetails[cspeech.id] == true) ? false : true
                                await this.setState({ showSpeechDetails: speechDetails })
                                console.log(" showSpeechdetails when clicked is ", this.state.showSpeechDetails)
                              }} style={{ padding: 20, backgroundColor: "red", marginVertical: 5, borderRadius: 10 }}>
                                <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> :: {cspeech.title}</Text>
                                <Text style={{ fontSize: 10, color: "white", }}> :: {cspeech.name}</Text>
                              </TouchableOpacity>
                              {this.state.showSpeechDetails[cspeech.id] && <View style={{
                                flexDirection: "row", justifyContent: "space-between", backgroundColor: "#333", borderRadius: 5,
                              }}>
                                <TouchableOpacity style={{ backgroundColor: "#444", padding: 10, borderRadius: 5, paddingHorizontal: 20 }}
                                  onPress={() => {
                                    this.removeAsCurrentSpeech(cspeech)
                                  }}>
                                  {!!this.state.removeAsCurrentSpeechLoading ? (
                                    <View
                                      style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        //padding: 15,
                                        flex: 1
                                      }}
                                    >
                                      <ActivityIndicator
                                        size="small"
                                        color="#fff"
                                        style={{
                                          opacity: 1
                                        }}
                                        animating={true}
                                      />
                                    </View>
                                  ) : <Text style={{ color: "white", fontSize: 10, fontWeight: "bold" }}>Remove</Text>}
                                </TouchableOpacity>
                                <TouchableOpacity
                                  onPress={() => {
                                    // this.setModalVisible(true)
                                    this.setState({
                                      modalData: cspeech,
                                      modalDataName: cspeech.name,
                                      modalDataTitle: cspeech.title,
                                      modalDataDescription: cspeech.description,
                                      modalDataDesignation: cspeech.designation,
                                      modalDataTitle: cspeech.title,
                                      modalDataImage: cspeech.image,
                                      modalDataID: cspeech.id,
                                      editSpeech: true
                                    })
                                  }}
                                  style={{ backgroundColor: "#444", padding: 10, borderRadius: 5, paddingHorizontal: 20 }}>
                                  <Text style={{ color: "white", fontSize: 10, fontWeight: "bold" }}>Edit</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ backgroundColor: "#444", padding: 10, borderRadius: 5, paddingHorizontal: 20 }}>
                                  <Text style={{ color: "white", fontSize: 10, fontWeight: "bold" }}>Delete</Text>
                                </TouchableOpacity>
                              </View>}
                            </View>}

                          </View>
                        )
                      })}
                    </View>

                  </View>}


                  <View style={{ marginTop: 10 }}>
                    {this.state.speeches.map((speech, id) => {
                      return (
                        <View key={id}>
                          {cspeechStatus[speech.id] == false && <View style={{
                            width: "90%",
                            justifyContent: "center", alignSelf: "center",
                          }}>
                            <View style={{ borderWidth: this.state.showSpeechDetails[speech.id] ? 1 : 0, borderColor: "rgba(255,255,255,0.5)", borderRadius: 10, padding: 5 }}>
                              <TouchableOpacity onPress={async () => {
                                //speechDetails[speech.id] = (speechDetails[speech.id] == true)? false : (speechDetails[speech.id]== false)?true:false 
                                speechDetails[speech.id] = (speechDetails[speech.id] == true) ? false : true
                                await this.setState({ showSpeechDetails: speechDetails })
                                console.log(" showSpeechdetails when clicked is ", this.state.showSpeechDetails)
                              }} style={{ padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, }}>
                                <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> :: {speech.title}</Text>
                                <Text style={{ fontSize: 10, color: "white", }}> :: {speech.name}</Text>
                              </TouchableOpacity>

                              {this.state.showSpeechDetails[speech.id] && <View style={{
                                flexDirection: "row", justifyContent: "space-between", borderRadius: 5,
                              }}>
                                <TouchableOpacity style={{ backgroundColor: "#444", padding: 10, borderRadius: 5, paddingHorizontal: this.state.makeCurrentSpeechLoading == true ? 20 : 10 }}
                                  onPress={() => {
                                    this.makeCurrentSpeech(speech)
                                  }}
                                >
                                  {!!this.state.makeCurrentSpeechLoading ? (
                                    <View
                                      style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        //padding: 15,
                                        flex: 1
                                      }}
                                    >
                                      <ActivityIndicator
                                        size="small"
                                        color="#fff"
                                        style={{
                                          opacity: 1
                                        }}
                                        animating={true}
                                      />
                                    </View>
                                  ) : <Text style={{ color: "#A8CF45", fontSize: 10, fontWeight: "bold" }}>Make Live</Text>}
                                </TouchableOpacity>
                                <TouchableOpacity
                                  onPress={() => {
                                    //this.setModalVisible(true)
                                    this.setState({
                                      modalData: speech,
                                      modalDataName: speech.name,
                                      modalDataTitle: speech.title,
                                      modalDataDescription: speech.description,
                                      modalDataDesignation: speech.designation,
                                      modalDataTitle: speech.title,
                                      modalDataImage: speech.image,
                                      modalDataID: speech.id,
                                      editSpeech: true
                                    })
                                  }}
                                  style={{ backgroundColor: "#444", padding: 10, borderRadius: 5, paddingHorizontal: 20 }}>
                                  <Text style={{ color: "orange", fontSize: 10, fontWeight: "bold" }}>Edit</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ backgroundColor: "#444", padding: 10, borderRadius: 5, paddingHorizontal: 20, }}>
                                  <Text style={{ color: "red", fontSize: 10, fontWeight: "bold" }}>Delete</Text>
                                </TouchableOpacity>
                              </View>}
                            </View>


                          </View>}

                        </View>)
                    })}
                  </View>

                </View>}
              </View>
              <View style={{ paddingBottom: 10 }}>

              </View>
            </View>

            {/* ----------------------------------------- Workshops ------------------------------------ */}


            <View id="Workshops" style={{ justifyContent: "center", padding: 20 }}>
              <View style={{ backgroundColor: "#272727", borderRadius: 15, padding: 15, }}>
                <View style={{ borderBottomWidth: 1, borderColor: '#3C3C3C', marginBottom: 10 }}>
                  <Text style={{ color: "white", fontWeight: "bold", fontSize: 13, marginVertical: 10 }}>Workshops</Text>
                </View>
                {/* ----------------------------------------- DAY 1 WORKSHOPS ------------------------------------ */}
                <View style={{ borderColor: '#888', borderWidth: this.state.showDayOneWorkshop == true ? 1 : 0, padding: this.state.showDayOneWorkshop == true ? 5 : 0, borderRadius: 10 }}>

                  <TouchableOpacity onPress={async () => { this.setState({ showDayOneWorkshop: this.state.showDayOneWorkshop == true ? false : true }) }}
                    style={{ padding: 10, paddingVertical: 20, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                    <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> DAY 1</Text>
                    <Text style={{ fontSize: 10, color: "white", }}> {this.state.showDayOneWorkshop == true ? "^" : "⌄"} </Text>
                  </TouchableOpacity>

                  {this.state.showDayOneWorkshop && <View style={{ justifyContent: "center", alignItems: "center" }}>
                    <Text style={{ padding: 10, color: "white", fontSize: 10 }}>Time: 2pm</Text>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk1}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk1")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 1, wkTempTitle: this.state.wk1 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk2}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk2")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 2, wkTempTitle: this.state.wk2 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk3}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk3")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 3, wkTempTitle: this.state.wk3 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <Text style={{ padding: 10, color: "white", fontSize: 10 }}>Time: 3pm</Text>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk4}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk4")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 4, wkTempTitle: this.state.wk4 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk5}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk5")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 5, wkTempTitle: this.state.wk5 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk6}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk6")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 6, wkTempTitle: this.state.wk6 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <Text style={{ padding: 10, color: "white", fontSize: 10 }}>Time: 4pm</Text>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk7}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk7")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 7, wkTempTitle: this.state.wk7 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk8}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk8")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 8, wkTempTitle: this.state.wk8 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk9}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk9")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 9, wkTempTitle: this.state.wk9 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                  </View>}
                </View>


                {/* ----------------------------------------- DAY 2 WORKSHOPS ------------------------------------ */}
                <View style={{ borderColor: '#888', borderWidth: this.state.showDayTwoWorkshop == true ? 1 : 0, padding: this.state.showDayTwoWorkshop == true ? 5 : 0, borderRadius: 10 }}>

                  <TouchableOpacity onPress={async () => { this.setState({ showDayTwoWorkshop: this.state.showDayTwoWorkshop == true ? false : true }) }}
                    style={{ padding: 10, paddingVertical: 20, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                    <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> DAY 2</Text>
                    <Text style={{ fontSize: 10, color: "white", }}>{this.state.showDayTwoWorkshop == true ? "^" : "⌄"}</Text>
                  </TouchableOpacity>


                  {this.state.showDayTwoWorkshop && <View style={{ justifyContent: "center", alignItems: "center" }}>
                    <Text style={{ padding: 10, color: "white", fontSize: 10 }}>Time: 2pm</Text>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk10}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk10")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 10, wkTempTitle: this.state.wk10 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk11}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk11")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 11, wkTempTitle: this.state.wk11 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk12}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk12")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 12, wkTempTitle: this.state.wk12 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <Text style={{ padding: 10, color: "white", fontSize: 10 }}>Time: 3pm</Text>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk13}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk13")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 13, wkTempTitle: this.state.wk13 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk14}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk14")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 14, wkTempTitle: this.state.wk14 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk15}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk15")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 15, wkTempTitle: this.state.wk15 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <Text style={{ padding: 10, color: "white", fontSize: 10 }}>Time: 4pm</Text>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk16}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk16")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 16, wkTempTitle: this.state.wk16 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk17}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk17")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 17, wkTempTitle: this.state.wk17 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk18}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk18")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 18, wkTempTitle: this.state.wk18 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                  </View>}
                </View>

                {/* ----------------------------------------- DAY 3 WORKSHOPS ------------------------------------ */}
                <View style={{ borderColor: '#888', borderWidth: this.state.showDayThreeWorkshop == true ? 1 : 0, padding: this.state.showDayThreeWorkshop == true ? 5 : 0, borderRadius: 10 }}>
                  <TouchableOpacity onPress={async () => { this.setState({ showDayThreeWorkshop: this.state.showDayThreeWorkshop == true ? false : true }) }}
                    style={{ padding: 10, paddingVertical: 20, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                    <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> DAY 3</Text>
                    <Text style={{ fontSize: 10, color: "white", }}> {this.state.showDayThreeWorkshop == true ? "^" : "⌄"} </Text>
                  </TouchableOpacity>


                  {this.state.showDayThreeWorkshop && <View style={{ justifyContent: "center", alignItems: "center" }}>
                    <Text style={{ padding: 10, color: "white", fontSize: 10 }}>Time: 2pm</Text>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk19}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk19")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 19, wkTempTitle: this.state.wk19 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk20}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk20")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 20, wkTempTitle: this.state.wk20 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk21}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk21")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 21, wkTempTitle: this.state.wk21 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <Text style={{ padding: 10, color: "white", fontSize: 10 }}>Time: 3pm</Text>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk22}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk22")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 22, wkTempTitle: this.state.wk22 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk23}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk23")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 23, wkTempTitle: this.state.wk23 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk24}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk24")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 24, wkTempTitle: this.state.wk24 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <Text style={{ padding: 10, color: "white", fontSize: 10 }}>Time: 4pm</Text>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk25}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk25")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 25, wkTempTitle: this.state.wk25 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk26}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk26")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 26, wkTempTitle: this.state.wk26 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: "80%", padding: 10, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                      <View>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> {this.state.wk27}</Text>
                        <Text style={{ fontSize: 10, color: "#ff5555", marginTop: 2 }}> {this.calculateRegistrants("wk27")}{" rgstd."}</Text>
                      </View>
                      <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: true, selectedWkValue: 27, wkTempTitle: this.state.wk27 }) }}>
                        <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> edit</Text>
                      </TouchableOpacity>
                    </View>
                  </View>}
                </View>

              </View>
            </View>

            {/*-------------------------------------------Conference Program / Agenda ------------------------------------------- */}

            <View id="Workshops" style={{ justifyContent: "center", padding: 20 }}>
              <View style={{ backgroundColor: "#272727", borderRadius: 15, padding: 15, }}>

                <View style={{ borderBottomWidth: 1, borderColor: '#3C3C3C', marginBottom: 10, flexDirection: "row", justifyContent: "space-between" }}>
                  <Text style={{ color: "white", fontWeight: "bold", fontSize: 13, marginVertical: 10, }}>Agenda</Text>
                </View>

                <TouchableOpacity onPress={async () => { this.setState({ showDay1Agenda: this.state.showDay1Agenda == true ? false : true }) }}
                  style={{ padding: 10, paddingVertical: 20, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                  <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> Day 1 Agenda</Text>
                  <Text style={{ fontSize: 10, color: "white", }}> {this.state.showDay1Agenda == true ? "^" : "⌄"} </Text>
                  {this.state.showDay1Agenda && <TouchableOpacity
                    onPress={async () => {
                      console.log("Just clicked on edit agenda 1 ")
                      let agenda = this.state.agenda
                      await this.setState({ currentDetails: agenda, editAgenda: true, agendaModal_day: 1, showDay2Agenda: false, showDay3Agenda: false })

                      this.state.agenda.day1.forEach(item => {
                        this.setState({
                          [`agendaModal${item.id}_start_time`]: item.start_time,
                          [`agendaModal${item.id}_end_time`]: item.end_time,
                          [`agendaModal${item.id}_activity`]: item.activity,
                          [`agendaModal${item.id}_person`]: item.person,
                          [`agendaModal${item.id}_id`]: item.id
                        })
                      })
                      //console.log("currentdetails saved is now: ", this.state.currentDetails)

                    }}
                    style={{ backgroundColor: "red", padding: 10, paddingHorizontal: 20, borderRadius: 5, position: "absolute", top: 10, right: 25 }}>
                    <Text style={{ fontSize: 11, color: "white" }}>edit</Text>
                  </TouchableOpacity>}
                </TouchableOpacity>

                {this.state.showDay1Agenda && <View style={{
                  justifyContent: "center", alignItems: "center", padding: 10,
                  //backgroundColor: "#333"  
                }}>
                  {this.state.agenda.day1.map((item, id) => {
                    return (
                      <View id="day1" key={id}
                        style={{
                          flexDirection: "row", flexShrink: 1, width: "100%", padding: 10,
                          //backgroundColor: "#444" ,borderRadius: 10,
                          marginVertical: 5, marginTop: 0, backgroundColor: "#333", borderRadius: 3, justifyContent: "flex-start"
                        }}>

                        <View style={{ marginRight: 5, width: "18%" }}>
                          <Text style={{ fontSize: 10, color: "white", }}>{item.start_time || " - "}-</Text>
                          <Text style={{ fontSize: 10, color: "white", }}>{item.end_time || " - "}</Text>
                        </View>
                        <View style={{ flexShrink: 1, justifyContent: "flex-start", borderLeftWidth: 1, borderLeftColor: "red", paddingLeft: 5 }}>
                          <Text style={{ fontSize: 10, color: "white", fontWeight: "bold" }}>{item.activity || " - "}</Text>
                          <Text style={{ fontSize: 10, color: "white", }}>{item.person || " - "}</Text>
                        </View>
                      </View>
                    )
                  })}
                </View>}


                <TouchableOpacity onPress={async () => { this.setState({ showDay2Agenda: this.state.showDay2Agenda == true ? false : true }) }}
                  style={{ padding: 10, paddingVertical: 20, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                  <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> Day 2 Agenda</Text>
                  <Text style={{ fontSize: 10, color: "white", }}> {this.state.showDay2Agenda == true ? "^" : "⌄"} </Text>
                  {this.state.showDay2Agenda && <TouchableOpacity
                    onPress={async () => {
                      let agenda = this.state.agenda
                      await this.setState({ currentDetails: agenda, editAgenda: true, agendaModal_day: 2, showDay1Agenda: false, showDay3Agenda: false })

                      this.state.agenda.day2.forEach(item => {
                        this.setState({
                          [`agendaModal${item.id}_start_time`]: item.start_time,
                          [`agendaModal${item.id}_end_time`]: item.end_time,
                          [`agendaModal${item.id}_activity`]: item.activity,
                          [`agendaModal${item.id}_person`]: item.person,
                          [`agendaModal${item.id}_id`]: item.id
                        })

                      })

                    }}
                    style={{ backgroundColor: "red", padding: 10, paddingHorizontal: 20, borderRadius: 5, position: "absolute", top: 10, right: 25 }}>
                    <Text style={{ fontSize: 11, color: "white" }}>edit</Text>
                  </TouchableOpacity>}
                </TouchableOpacity>

                {this.state.showDay2Agenda && <View style={{
                  justifyContent: "center", alignItems: "center", padding: 10,
                  //backgroundColor: "#333"  
                }}>
                  {this.state.agenda.day2.map((item, id) => {
                    return (
                      <View id="day2" key={id}
                        style={{
                          flexDirection: "row", flexShrink: 1, width: "100%", padding: 10,
                          //backgroundColor: "#444" ,borderRadius: 10,
                          marginVertical: 5, marginTop: 0, backgroundColor: "#333", borderRadius: 3, justifyContent: "flex-start"
                        }}>

                        <View style={{ marginRight: 5, width: "18%" }}>
                          <Text style={{ fontSize: 10, color: "white", }}>{item.start_time || " - "}-</Text>
                          <Text style={{ fontSize: 10, color: "white", }}>{item.end_time || " - "}</Text>
                        </View>
                        <View style={{ flexShrink: 1, justifyContent: "flex-start", borderLeftWidth: 1, borderLeftColor: "red", paddingLeft: 5 }}>
                          <Text style={{ fontSize: 10, color: "white", fontWeight: "bold" }}>{item.activity || " - "}</Text>
                          <Text style={{ fontSize: 10, color: "white", }}>{item.person || " - "}</Text>
                        </View>
                      </View>
                    )
                  })}

                </View>}


                <TouchableOpacity onPress={async () => { this.setState({ showDay3Agenda: this.state.showDay3Agenda == true ? false : true }) }}
                  style={{ padding: 10, paddingVertical: 20, backgroundColor: "#444", marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                  <Text style={{ fontSize: 11, color: "white", fontWeight: "bold" }}> Day 3 Agenda</Text>
                  <Text style={{ fontSize: 10, color: "white", }}> {this.state.showDay3Agenda == true ? "^" : "⌄"} </Text>

                  {this.state.showDay3Agenda && <TouchableOpacity
                    onPress={async () => {
                      let agenda = this.state.agenda
                      await this.setState({ currentDetails: agenda, editAgenda: true, agendaModal_day: 3, showDay1Agenda: false, showDay1Agenda: false })

                      this.state.agenda.day3.forEach(item => {
                        this.setState({
                          [`agendaModal${item.id}_start_time`]: item.start_time,
                          [`agendaModal${item.id}_end_time`]: item.end_time,
                          [`agendaModal${item.id}_activity`]: item.activity,
                          [`agendaModal${item.id}_person`]: item.person,
                          [`agendaModal${item.id}_id`]: item.id
                        })

                      })

                    }}
                    style={{ backgroundColor: "red", padding: 10, paddingHorizontal: 20, borderRadius: 5, position: "absolute", top: 10, right: 25 }}>
                    <Text style={{ fontSize: 11, color: "white" }}>edit</Text>
                  </TouchableOpacity>}
                </TouchableOpacity>

                {this.state.showDay3Agenda && <View style={{
                  justifyContent: "center", alignItems: "center", padding: 10,
                  //backgroundColor: "#333"  
                }}>
                  {this.state.agenda.day3.map((item, id) => {
                    return (
                      <View id="day3" key={id}
                        style={{
                          flexDirection: "row", flexShrink: 1, width: "100%", padding: 10,
                          //backgroundColor: "#444" ,borderRadius: 10,
                          marginVertical: 5, marginTop: 0, backgroundColor: "#333", borderRadius: 3, justifyContent: "flex-start"
                        }}>

                        <View style={{ marginRight: 5, width: "18%" }}>
                          <Text style={{ fontSize: 10, color: "white", }}>{item.start_time || " - "}-</Text>
                          <Text style={{ fontSize: 10, color: "white", }}>{item.end_time || " - "}</Text>
                        </View>
                        <View style={{ flexShrink: 1, justifyContent: "flex-start", borderLeftWidth: 1, borderLeftColor: "red", paddingLeft: 5 }}>
                          <Text style={{ fontSize: 10, color: "white", fontWeight: "bold" }}>{item.activity || " - "}</Text>
                          <Text style={{ fontSize: 10, color: "white", }}>{item.person || " - "}</Text>
                        </View>
                      </View>
                    )
                  })}

                </View>}


                {/* <TouchableOpacity id="Upload agenda button"
                  onPress={() => { this.editAgenda() }}
                  style={{
                    backgroundColor: 'orange', height: 40, width: "100%", alignSelf: "center", justifyContent: "center", alignItems: "center", borderRadius: 10,
                  }}>
                  {!!this.state.editAgendaAnimationLoading ? (
                    <View
                      style={{
                        //backgroundColor: "#223",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 15,
                        flex: 1
                      }}
                    >
                      <ActivityIndicator
                        size="large"
                        color="#fff"
                        style={{
                          opacity: 1
                        }}
                        animating={true}
                      />
                    </View>
                  ) : <Text style={{ color: 'white', fontWeight: "bold", fontSize: 9 }}>Update Agenda</Text>}
                </TouchableOpacity> */}


              </View>
            </View>
            {/* //*------  -------  -------  ---------  ------- Workshop  Modal --- ------  -------  -------  ---------  -------  */}
            <Modal
              animationType="slide"
              transparent={true}
              visible={this.state.wkmodalVisible}
              onRequestClose={() => {
                Alert.alert('Modal has been closed.');
              }}>

              <View
                style={{
                  flex: 1, alignItems: "center", width: "100%", padding: 20, backgroundColor: "rgba(0,0,0,0.3)",
                  marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between"
                }}>

                <View style={{
                  width: "100%", backgroundColor: "#272727", borderRadius: 20, padding: 20, flexDirection: "row",
                  justifyContent: "space-between", borderWidth: 1, borderColor: "white"
                }}>

                  <TextInput value={this.state.wkTempTitle}
                    //this.getWkValue(this.state.selectedWkValue)} 
                    multiline={true}
                    onChange={(e) => {
                      console.log(" text is ", e.nativeEvent.text)
                      //this.setWkModalValue(this.state.selectedWkValue, e.nativeEvent.text)
                      this.setState({ wkTempTitle: e.nativeEvent.text })
                    }}
                    style={{ minHeight: 30, borderRadius: 10, color: "white" }}></TextInput>
                  <View style={{ borderLeftWidth: 1, borderColor: 'white', justifyContent: "flex-start" }}>
                    <TouchableOpacity style={{ justifyContent: "center", alignItems: "center" }}
                      onPress={() => {
                        this.updateOneWorkshop(`wk${this.state.selectedWkValue}`, this.state.wkTempTitle)

                      }}>
                      {!!this.state.editwkloading ? (
                        <View
                          style={{
                            //backgroundColor: "#223",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 5,
                            flex: 1
                          }}
                        >
                          <ActivityIndicator
                            size="small"
                            color="#fff"
                            style={{
                              opacity: 1
                            }}
                            animating={true}
                          />
                        </View>
                      ) : <Text style={{ color: "white", fontWeight: "bold", padding: 5 }}> save</Text>}
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => { this.setState({ wkmodalVisible: false }) }}>
                      <Text style={{ color: "red", fontWeight: "bold", padding: 5, marginLeft: 5, fontSize: 12 }}>cancel</Text>
                    </TouchableOpacity>
                  </View>

                </View>
              </View>

            </Modal>





          </ScrollView>
          {/* //*------  -------  -------  ---------  ------- Edit Agenda Workshop Modal --- ------  -------  -------  ---------  -------  */}
          <Modal
            animationType="slide"
            transparent={false}
            visible={this.state.editAgenda}
            style={{ padding: 20 }}
            onRequestClose={() => {
              Alert.alert('Modal has been closed.');
            }}>
            <ScrollView style={{ padding: 20, paddingTop: 50, backgroundColor: "#222" }}>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ color: "white", marginVertical: 20, fontWeight: "bold" }}>Edit Agenda</Text>
                <View style={{ flexDirection: 'row', position: "absolute", top: 10, right: 0 }}>
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({ editAgenda: false, });
                      this.editAgenda()
                    }}
                    style={{ backgroundColor: "#A8CF45", padding: 10, paddingHorizontal: 20, borderRadius: 5, marginRight: 10 }}>
                    <Text style={{ fontSize: 11, color: "white" }}>save & close</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={async () => {
                      let details = this.state.currentDetails
                      console.log("new initial day1 activity1 is: ", this.state.agenda.day1[0].activity)
                      console.log("original day1 activity1 is: ", details.day1[0].activity)
                      await this.setState({ editAgenda: false, agenda: details })

                    }}
                    style={{ backgroundColor: "red", padding: 10, paddingHorizontal: 20, borderRadius: 5 }}>
                    <Text style={{ fontSize: 11, color: "white" }}>close</Text>
                  </TouchableOpacity>
                </View>

              </View>

              {this.state.agenda[`day${this.state.agendaModal_day}`].map((item, id) => {
                let data = `this.state.agendaModal${this.state.agendaModal_day}`
                let fullAgenda = this.state.agenda

                return (
                  <View key={id}
                    style={{
                      flexDirection: "row", flexShrink: 1, width: "100%", padding: 10,
                      //backgroundColor: "#444" ,borderRadius: 10,
                      marginVertical: 5, marginTop: 0, backgroundColor: "#333", borderRadius: 3, justifyContent: "flex-start"
                    }}>

                    <View style={{ marginRight: 5, width: "18%", paddingRight: 5 }}>
                      <TextInput value={item.start_time || " - "} placeholder={"start time"}
                        onChange={(e) => {
                          console.log(" start_time text is ", e.nativeEvent.text)
                          fullAgenda[`day${this.state.agendaModal_day}`].forEach(k => {
                            if (k.id == item.id) {
                              k.start_time = e.nativeEvent.text
                            }
                          })
                          this.setState({ agenda: fullAgenda })
                          //this.editAgendaItem(agendaItem, "day3")
                        }}
                        style={{ minHeight: 30, backgroundColor: "#555", borderRadius: 5, padding: 5, paddingVertical: 2, color: "white", fontSize: 10, marginBottom: 2 }}>
                      </TextInput>
                      <TextInput value={item.end_time || " - "} placeholder={"start time"}
                        onChange={(e) => {
                          console.log(" end_time text is ", e.nativeEvent.text)
                          fullAgenda[`day${this.state.agendaModal_day}`].forEach(k => {
                            if (k.id == item.id) {
                              k.end_time = e.nativeEvent.text
                            }
                          })
                          this.setState({ agenda: fullAgenda })
                        }}
                        style={{ minHeight: 30, backgroundColor: "#555", borderRadius: 5, padding: 5, paddingVertical: 2, color: "white", fontSize: 10 }}>
                      </TextInput>
                    </View>
                    <View style={{ flexShrink: 1, justifyContent: "flex-start", borderLeftWidth: 1, borderLeftColor: "red", paddingLeft: 5, alignItems: "center" }}>
                      <TextInput value={item.activity || " - "} placeholder={"what activity... "}
                        onChange={(e) => {
                          console.log(" activity text is ", e.nativeEvent.text)
                          fullAgenda[`day${this.state.agendaModal_day}`].forEach(k => {
                            if (k.id == item.id) {
                              k.activity = e.nativeEvent.text
                            }
                          })
                          this.setState({ agenda: fullAgenda })
                        }}
                        style={{ minHeight: 30, backgroundColor: "#444", borderRadius: 5, padding: 5, paddingVertical: 2, color: "white", fontSize: 10, width: "100%", flex: 1, marginBottom: 2 }}>
                      </TextInput>
                      <TextInput value={item.person || " - "} placeholder={"who are speaking?"}
                        onChange={(e) => {
                          console.log(" person text is ", e.nativeEvent.text)
                          fullAgenda[`day${this.state.agendaModal_day}`].forEach(k => {
                            if (k.id == item.id) {
                              k.person = e.nativeEvent.text
                            }
                          })
                          this.setState({ agenda: fullAgenda })
                        }}
                        style={{ minHeight: 30, backgroundColor: "#444", borderRadius: 5, padding: 5, paddingVertical: 2, color: "white", fontSize: 10, width: "100%", flex: 1 }}>
                      </TextInput>
                    </View>
                  </View>
                )
              })}
              <View style={{ height: 100 }}></View>
            </ScrollView>
          </Modal>

          {/* //*------  -------  -------  ---------  ------- Speaker  Modal (Not really a modal anymore) --- ------  -------  -------  ---------  -------  */}

          {this.state.editSpeech && <View style={{
            position: "absolute", bottom: 0, left: 0, height: "100%", width: "100%",
            justifyContent: "center", alignItems: "center", backgroundColor: "rgba(0,0,0,0.3)",
            padding: 20, alignSelf: "center", borderTopEndRadius: 20
          }}>

            <View style={{ backgroundColor: "#272727", borderRadius: 20, padding: 20, }}>

              <View>
                <View style={{ paddingVertical: 5, }}>
                  <Text style={{ color: "orange", fontWeight: "bold", fontSize: 12, marginVertical: 10 }}>Edit Event Speech</Text>
                </View>

                <View>
                  <Text style={{ color: "orange", fontSize: 12, marginBottom: 3 }}>Title</Text>
                  <TextInput value={this.state.modalDataTitle} placeholder={"What is the title of the speech?..."} onChange={(e) => {
                    console.log(" title text is ", e.nativeEvent.text)
                    this.setState({
                      modalDataTitle: e.nativeEvent.text
                    })
                  }}
                    style={{ minHeight: 30, backgroundColor: "#777", borderRadius: 10, padding: 5, paddingVertical: 2, color: "white" }}></TextInput>
                </View>

                <View style={{ marginTop: 10, }}>
                  <Text style={{ color: "orange", fontSize: 12, marginBottom: 3 }}>Description</Text>
                  <TextInput value={this.state.modalDataDescription} placeholder={"Enter speech details here..."} multiline={true}
                    onChange={(e) => {
                      console.log(" Description text is ", e.nativeEvent.text)
                      this.setState({
                        modalDataDescription: e.nativeEvent.text
                      })
                    }}
                    style={{ minHeight: 30, backgroundColor: "#777", borderRadius: 10, padding: 5, color: "white" }}></TextInput>
                </View>


                <View style={{ padding: 10, backgroundColor: "#444", marginTop: 20, borderRadius: 10 }}>
                  <Text style={{ color: "white", fontSize: 12, marginBottom: 3, fontWeight: "bold", marginBottom: 10 }}>Speaker Section</Text>
                  <View style={{ paddingTop: 10, flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderTopWidth: 1, borderTopColor: "white" }}>
                    <Image
                      style={{ width: 60, height: 60, resizeMode: "cover", borderRadius: 15 }}
                      source={{ uri: this.state.image || this.state.modalDataImage || 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/Events%2Favatar.jpg?alt=media&token=babac7eb-0973-4c9a-a0e6-6efff5ecc477' }}
                    ></Image>
                    <TouchableOpacity id="Upload image button"
                      onPress={() => { this.setState({ showGetPicture: true }) }}
                      style={{
                        backgroundColor: 'orange', height: 40, width: "60%", alignSelf: "center", justifyContent: "center", alignItems: "center", borderRadius: 10,
                      }}>
                      <Text style={{ color: 'white', fontWeight: "bold", fontSize: 9 }}>Upload Image</Text>
                    </TouchableOpacity>
                  </View>

                  <View style={{ marginTop: 10, }}>
                    <Text style={{ color: "white", fontSize: 12, marginBottom: 3 }}>Name:</Text>
                    <TextInput placeholder={"Name of speaker"} value={this.state.modalDataName} onChange={(e) => {
                      console.log(" Description text is ", e.nativeEvent.text)
                      this.setState({
                        modalDataName: e.nativeEvent.text
                      })
                    }}
                      style={{ minHeight: 30, backgroundColor: "#777", borderRadius: 10, padding: 5, color: "white" }}></TextInput>
                  </View>
                  <View style={{ marginTop: 10, }}>
                    <Text style={{ color: "white", fontSize: 12, marginBottom: 3 }}>Designation:</Text>
                    <TextInput placeholder={"Is the person a COO, a CEO, Founder etc ?"} value={this.state.modalDataDesignation}
                      style={{ fontSize: 8 }} onChange={(e) => {
                        console.log(" Description text is ", e.nativeEvent.text)
                        //let designation = e.nativeEvent.text
                        this.setState({
                          modalDataDesignation: e.nativeEvent.text
                        })
                      }}
                      style={{ minHeight: 30, backgroundColor: "#777", borderRadius: 10, padding: 5, color: "white" }}></TextInput>
                  </View>

                  <View style={{ marginTop: 15, paddingTop: 10, flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderTopWidth: 1, borderTopColor: "white" }}>
                    <TouchableOpacity id="Upload image button"
                      onPress={() => { this.editSpeech() }}
                      style={{
                        backgroundColor: 'orange', height: 40, width: "100%", alignSelf: "center", justifyContent: "center", alignItems: "center", borderRadius: 10,
                      }}>
                      {!!this.state.editSpeechAnimationLoading ? (
                        <View
                          style={{
                            //backgroundColor: "#223",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 15,
                            flex: 1
                          }}
                        >
                          <ActivityIndicator
                            size="large"
                            color="#fff"
                            style={{
                              opacity: 1
                            }}
                            animating={true}
                          />
                        </View>
                      ) : <Text style={{ color: 'white', fontWeight: "bold", fontSize: 9 }}>Save</Text>}
                    </TouchableOpacity>
                  </View>

                </View>

              </View>
              <TouchableOpacity id="close_modal"
                onPress={() => {
                  this.setState({ editSpeech: false })
                }}
                style={{
                  backgroundColor: 'orange', height: 40, alignSelf: "center", width: 40, top: -10, right: -10,
                  justifyContent: "center", alignItems: "center", borderRadius: 20, position: "absolute",
                  alignSelf: "flex-start"
                }}>
                <Text style={{ color: 'white', fontWeight: "bold", }}>X</Text>
              </TouchableOpacity>

            </View>
          </View>}


          {this.state.showGetPicture && (
            <GetPicture
              onDone={url => {
                if (url) { this.setState({ image: url }); }
                console.log("image chosen is: ", url);
                this.setState({ showGetPicture: false });
              }}
              item={"POST"}
            />
          )}
        </View>
      </SafeAreaView >
    );
  }
}

import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ImageBackground,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  Linking,
  Modal,
  Platform,
  ActivityIndicator,
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import firebase from "firebase";
import "firebase/firestore";
import { NavigationEvents } from "react-navigation";

const gStyles = global.gStyles //    "../styles/gStyles";
import CachedImage from "../shared/CachedImage"
import OrderCounter from "./OrderCounter"






class Network extends Component {

  state = {
    logo: null,
    background: null,
    name: "LOADING",
    loading: true
  }

  async componentDidMount() {
    this.retrieveNetworks()
  }

  retrieveNetworks = async () => {
    try {
      if (!global.getNetwork) await new Promise(resolve => { setTimeout(resolve, 200); });
      if (!global.getNetwork) await new Promise(resolve => { setTimeout(resolve, 300); });
      if (!global.getNetwork) await new Promise(resolve => { setTimeout(resolve, 500); });
      if (!global.getNetwork) await new Promise(resolve => { setTimeout(resolve, 500); });
      if (!global.getNetwork) await new Promise(resolve => { setTimeout(resolve, 2000); });
      if (!global.getNetwork) await new Promise(resolve => { setTimeout(resolve, 5000); });
      if (!global.getNetwork) return;

      const network = await global.getNetwork(this.props.networkID)
      if (!network) return;
      this.setState({ name: network.networkName, logo: network.networkLogoURL, background: network.networkBackgroundURL, loading: false })
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    if (this.state.loading) return (
      <View style={{ height: 25, alignItems: "center", justifyContent: "center", backgroundColor: "#131313" }}>
        <ActivityIndicator size="small" color="#445" />
      </View>
    );
    return (
      <View style={{ height: 60, justifyContent: "center", borderBottomWidth: 0.5, borderColor: "#445", }}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>

          {this.state.logo && <CachedImage source={{ uri: this.state.logo }}
            style={{
              height: 50, width: 75, alignSelf: 'center', borderRadius: 0, borderWidth: 0, borderColor: "#445", resizeMode: "contain"
            }} />}
          <View style={{ flex: 1, height: 60, justifyContent: "center" }}>
            <Text style={{ fontSize: 18, color: "#dde", flexWrap: "wrap", paddingLeft: 8 }}>{this.state.name}</Text>
          </View>
        </View>
        <ImageBackground
          style={{
            position: "absolute", top: 0, left: 0, right: 0, bottom: 0,
            width: "100%", opacity: 0.3,
            zIndex: -1
          }}
          source={{ uri: this.state.background }}
        />
      </View>
    )
  }
}

export default class MeScreen extends Component {
  static navigationOptions = {
    headerShown: false
  };

  state = {
    websiteRoute: global.hasNoWebsiteYet ? "meWebsite" : "meWebsiteManagement",
    networks: [],
    suggestedNetworks: [],
    showModal: false,
    immediateSignoutPossible: global.coinResetAfterBlock,
    activityIndicatorAnimating: false,
    hasBusiness: true
  }

  async componentDidMount() {

    try {
      if (!global.myUID) {
        await new Promise(resolve => { setTimeout(resolve, 500); });
        if (!global.myUID) {
          await new Promise(resolve => { setTimeout(resolve, 500); });
          if (!global.myUID) {
            await new Promise(resolve => { setTimeout(resolve, 500); });
            if (!global.myUID) {
              await new Promise(resolve => { setTimeout(resolve, 1000); });
              if (!global.myUID) {
                await new Promise(resolve => { setTimeout(resolve, 1500); });
                if (!global.myUID) {
                  await new Promise(resolve => { setTimeout(resolve, 1500); });
                  if (!global.myUID) {
                    await new Promise(resolve => { setTimeout(resolve, 1500); });
                    if (!global.myUID) {
                      await new Promise(resolve => { setTimeout(resolve, 3500); });
                      if (!global.myUID) {
                        await new Promise(resolve => { setTimeout(resolve, 5500); });
                        if (!global.myUID) {
                          await new Promise(resolve => { setTimeout(resolve, 5500); });
                          if (!global.myUID) {
                            return
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }


      const myUserDoc = firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .onSnapshot(this.updateUser);

      if (!global.myBusinessName) await global.timeout(50)
      if (!global.myBusinessName) await global.timeout(100)
      if (!global.myBusinessName) await global.timeout(100)
      if (!global.myBusinessName) await global.timeout(100)
      if (!global.myBusinessName) await global.timeout(200)
      if (!global.myBusinessName) await global.timeout(200)
      if (!global.myBusinessName) await global.timeout(200)
      if (!global.myBusinessName) await global.timeout(300)
      if (!global.myBusinessName) await global.timeout(500)
      if (!global.myBusinessName) await global.timeout(500)
      if (!global.myBusinessName) await global.timeout(1000)
      if (!global.myBusinessName) await global.timeout(1200)
      if (!global.myBusinessName) await global.timeout(1500)
      if (!global.myBusinessName) await global.timeout(5000)
      if (!global.myBusinessName) await global.timeout(5000)
      if (!global.myBusinessName) await global.timeout(5000)
      if (!global.myBusinessName) await global.timeout(3000)
      if (global.myBusinessName) this.setState({ hasBusiness: true })

      //this.setState({ loading: false });
    } catch (err) {
      console.log("Error wwwe/", err);
    }
  }

  updateUser = async userSnapshot => {
    const user = userSnapshot.data()
    if (user.websiteJustPurchased || user.websiteTrialJustSelected > 0 || user.idShop) this.setState({ websiteRoute: "meWebsiteManagement" })
    else this.setState({ websiteRoute: "meWebsite" })
    if (user.coinResetAfterBlock) this.setState({ immediateSignoutPossible: true })
    else this.setState({ immediateSignoutPossible: false })
    const networks = user.networks || []
    global.networks = networks
    const suggestedNetworks = []
    if ((global.app == "artisan") && !networks.includes("TAN")) suggestedNetworks.push("TAN")
    if (global.app == "education" && !networks.includes("WakandaEducation")) suggestedNetworks.push("WakandaEducation")
    if (global.app == "speakers" && !networks.includes("SpeakersConnect")) suggestedNetworks.push("SpeakersConnect")
    if (global.app == "market" && global.myServicesOffered && global.myServicesOffered.Professionals && global.myServicesOffered.Professionals.length > 0) {
      //if(global.myServicesOffered.Professionals.includes("Public Speakers")) suggestedNetworks.push("SpeakersNetwork")
      if (!networks.includes("WakandaEducation") && global.myServicesOffered.Professionals.includes("Teaching - Primary")) suggestedNetworks.push("WakandaEducation")
      if (!networks.includes("WakandaEducation") && global.myServicesOffered.Professionals.includes("Teaching - Secondary")) suggestedNetworks.push("WakandaEducation")
      if (!networks.includes("WakandaEducation") && global.myServicesOffered.Professionals.includes("Teaching - Tertiary")) suggestedNetworks.push("WakandaEducation")
      if (!networks.includes("SpeakersConnect") && global.myServicesOffered.Professionals.includes("Public Speakers")) suggestedNetworks.push("SpeakersConnect")
    }
    if (global.app == "market" && global.myServicesOffered && global.myServicesOffered.Handyman && global.myServicesOffered.Handyman.length > 0) {
      if (!networks.includes("TAN")) suggestedNetworks.push("TAN")
    }
    this.setState({ networks, suggestedNetworks })
    if (user.networkAdminRights) global.networkAdminRights = user.networkAdminRights
    if (user.networkRequests) global.networkRequests = user.networkRequests
  };

  callSwitchPhone = async () => {
    console.log("Switch phones pressed")
    this.props.navigation.navigate("walletSetup", { screen: "11" })
    await global.timeout(250)
    this.setState({ showModal: false })
  }

  render() {
    let shownNetworksLong = [...this.state.networks, ...this.state.suggestedNetworks]
    let shownNetworks = shownNetworksLong.filter((value, i, self) => self.indexOf(value) === i);

    return (
      <View style={{ flex: 1, backgroundColor: "#131313" }}>
        <LinkingHandler navigation={this.props.navigation} />
        <View style={{ height: 25 }} />
        <ScrollView>
          <View style={{ height: 20 }} />
          <View style={{ backgroundColor: "#131313" }}>
            {(this.state.networks.length > 0 || this.state.suggestedNetworks.length > 0) && <View><Text style={{ color: "#A8CF45" }}>{(this.state.networks.length + this.state.suggestedNetworks.length) == 1 ? "" : "Networks"}</Text>
              {shownNetworks.map((network, i) => {
                return (
                  <View key={network} style={{ borderTopWidth: i == 0 ? 0.5 : 0, borderColor: "#445" }}>
                    <TouchableOpacity onPress={() => { this.props.navigation.navigate("meNetwork", { networkID: network }) }}>
                      <Network networkID={network} />
                    </TouchableOpacity>
                  </View>
                )
              })}
            </View>}
            <View><Text style={{ color: "#A8CF45", marginTop: 15 }}>Business</Text>
              <View>
                <View>
                  <TouchableOpacity
                    style={global.gStyles.me}
                    // onPress={() => this.props.navigation.navigate("meInterests", { nextScreen: "meProfile" })}
                    onPress={() => this.props.navigation.navigate("meProfile")}
                  >
                    <Text style={global.gStyles.meText}>My Profile</Text>
                  </TouchableOpacity>

                  {!!this.state.hasBusiness && <TouchableOpacity
                    style={global.gStyles.me}
                    onPress={() => this.props.navigation.navigate("mePosts")}
                  >
                    <Text style={global.gStyles.meText}>My {global.a ? global.a.offers ? global.a.offers : "Offers" : "Offers"}</Text>
                  </TouchableOpacity>}
                  <TouchableOpacity
                    style={global.gStyles.me}
                    onPress={() => this.props.navigation.navigate("meOrders")}
                  >
                    <Text style={global.gStyles.meText}>My Orders</Text>
                    <OrderCounter navigation={this.props.navigation} />
                  </TouchableOpacity>
                  {(!!this.state.hasBusiness && !!this.state.websiteRoute && (Platform.OS != "ios" || global.app != "education")) && <TouchableOpacity
                    style={global.gStyles.me}
                    onPress={() => this.props.navigation.navigate(this.state.websiteRoute)}
                  >
                    <Text style={global.gStyles.meText}>My Website</Text>
                    {Platform.OS == "android" && <View
                      style={{
                        backgroundColor: "#A8CF45",
                        marginLeft: 8,
                        height: 22,
                        width: 32,
                        borderRadius: 16,
                        alignItems: "center",
                        borderWidth: 0.5,
                        borderColor: "#000",
                        justifyContent: "center"
                      }}
                    >
                      <Text style={{ color: "#000", fontSize: 12 }}>
                        hot
                    </Text>
                    </View>}
                  </TouchableOpacity>}
                </View>
              </View>
            </View>

            <View>
              <Text style={{ color: "#A8CF45", marginTop: 15 }}>Account</Text>
              <TouchableOpacity
                style={global.gStyles.me}
                onPress={() => this.props.navigation.navigate("meContacts")}
              >
                <Text style={global.gStyles.meText}>Contacts</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={global.gStyles.me}
                onPress={() => this.props.navigation.navigate("meCommunities")}
              >
                <Text style={global.gStyles.meText}>Communities</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={global.gStyles.me}
                onPress={() => this.props.navigation.navigate("meSavedPosts")}
              >
                <Text
                  style={global.gStyles.meText}
                >{(global.app == "speakers" || global.app == "artisan") ? `Saved Samples / Offers` : `Saved Offers`}</Text>
              </TouchableOpacity>
              {(Array.isArray(global.myDiscounts) && global.myDiscounts.length > 0) && <TouchableOpacity
                style={global.gStyles.me}
                onPress={() => this.props.navigation.navigate("meDiscounts")}
              >
                <Text
                  style={global.gStyles.meText}
                >Discounts</Text>
              </TouchableOpacity>}
              {["JjhIuLk9EGYK0BwrHeLi", "nGFFXkvelQ0T8GQYxjMW", "tRRAwR3zmdoKrb0Bt5JA", "7ETlFZhN64xhfe7I413E", "Ai5YXHzMWKkDDGtgR35p", "5RvOIVlXeuiHxaAq9oct", "6dQ3ZXsy6akM4smf9yiY", 'F4C7tJCPqxdpawvBpd2B', 'mLYGuuZszmt1YMi1rxxw', 'NCcoeztvpJi3vNbG748A'].includes(global.myProfileID) && <TouchableOpacity
                // {true && <TouchableOpacity
                style={global.gStyles.me}
                onPress={() => this.props.navigation.navigate("meBlogsManage")}
              >
                <Text
                  style={global.gStyles.meText}
                >Blog (AF)</Text>
              </TouchableOpacity>}

              {["JjhIuLk9EGYK0BwrHeLi", "nGFFXkvelQ0T8GQYxjMW", "tRRAwR3zmdoKrb0Bt5JA", "7ETlFZhN64xhfe7I413E", "Ai5YXHzMWKkDDGtgR35p", "5RvOIVlXeuiHxaAq9oct", "6dQ3ZXsy6akM4smf9yiY", 'F4C7tJCPqxdpawvBpd2B', 'mLYGuuZszmt1YMi1rxxw', 'NCcoeztvpJi3vNbG748A'].includes(global.myProfileID) && <TouchableOpacity
                // {true && <TouchableOpacity
                style={global.gStyles.me}
                onPress={() => this.props.navigation.navigate("meSocialStatus")}
              >
                <Text
                  style={global.gStyles.meText}
                >My Social Status (AF)</Text>
              </TouchableOpacity>}
              {/* <TouchableOpacity
                // {true && <TouchableOpacity
                style={global.gStyles.me}
                onPress={() => this.props.navigation.navigate("recordVideo")}
              >
                <Text
                  style={global.gStyles.meText}
                >Record Video</Text>
              </TouchableOpacity> */}
              <TouchableOpacity
                style={global.gStyles.me}
                onPress={() => this.props.navigation.navigate("meInterests")}
              >
                <Text style={global.gStyles.meText}>
                  Interests
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={global.gStyles.me}
                onPress={() => this.props.navigation.navigate("meHelp")}
              >
                <Text style={global.gStyles.meText}>
                  {`Help & Feedback`}
                </Text>
              </TouchableOpacity>
              {/* <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => this.props.navigation.navigate("meSettings")}
              >
              <Text style={global.gStyles.meText}>
              {`Settings`}
              </Text>
            </TouchableOpacity> */}
              <TouchableOpacity
                style={global.gStyles.me}
                onPress={() => this.props.navigation.navigate("meSettings")}
              >
                <Text style={global.gStyles.meText}>
                  {`Settings`}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
        <NavigationEvents
          onDidFocus={async payload => {
            if (!global.myBusinessName && this.state.hasBusiness) this.setState({ hasBusiness: false })
            if (global.myBusinessName && !this.state.hasBusiness) this.setState({ hasBusiness: true })
          }}
        />
      </View>
    );
  }
}

class LinkingHandler extends Component {
  async componentDidMount() {
    try {
      const initialURL = await Linking.getInitialURL()
      if (initialURL.startsWith("wakanda")) this.handleLink({ url: initialURL })
      Linking.addEventListener('url', this.handleLink)
    } catch (e) { global?.warn("E-LinkingHandler") }
  }
  handleLink = async ({ url }) => {
    //alert(url)
    if (url.startsWith("wakanda://community/")) {
      this.props.navigation.navigate("homeCommunity", { communityID: url.substring(20, 100) })
    }
  }
  render() { return <View /> }
}
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
// Screen always "synced" between Wakanda Market and Health
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  // findNodeHandle,
  TextInput,
  Image,
  ImageBackground,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Keyboard,
  Dimensions,
  KeyboardAvoidingView,
  ActivityIndicator,
  Platform,
  Linking,
  Modal,
  Alert,
} from "react-native";
import { WebView } from 'react-native-webview'
const gStyles = global.gStyles //    "../styles/gStyles";
import firebase from "firebase";
import "firebase/firestore";
import { NavigationEvents } from "react-navigation";
import * as Device from 'expo-device';

import MapScreen from "../shared/Map/mapScreen";
import Post from "../Home/Post/Post";
import GetPicture from "../shared/Camera/GetPicture";
import { GetReaction, VideoMessage, calculateVideoSize } from "./Reaction";
import { RecordMessage, VoiceMessage } from "./VoiceMessage";
import FullscreenImage from "./FullscreenImage"
import PushNotificationHandler from "./PushNotificationHandler"
import * as DocumentPicker from 'expo-document-picker';
import { getLinkPreview } from 'link-preview-js';
import { Swipeable } from 'react-native-gesture-handler';
import { LinearGradient } from "expo-linear-gradient";


export class TypeBox extends Component {
  state = {
    text: "",
    showAttachMenu: false,
    showMapModal: false,
    showGetPicture: false,
    showGetReaction: false,
    mapCoords: null,
    mapAddress: null,
    showUploadingDocModal: false,
    youtubeID: null,
    youtubeThumbnail: null,
    youtubeTitle: null,
    showYouTube: false,
    urlTitle: null,
    urlLink: null,
    urlThumbnail: null,
    showURL: false,
    // showYouTubeLoading: true
  };
  activeDocumentUploadID = ""
  replyingToMessage = false

  componentDidUpdate(prevProps) {
    if (this.props.replyingToMessage && !prevProps.replyingToMessage) {
      //this.replyingToMessage = !!this.props.replyingToMessage
      this.myTextInput?.focus?.()
    }
  }

  send = async data => {
    try {
      if (!data.text || data.text.length == 0) {
        alert("No message entered");
        return;
      }
      this.myTextInput?.setNativeProps?.({ text: "" });
      this.setState({ text: "", showYouTube: false, showURL: false });
      this.props.onPressSend(data);
    } catch (err) {
      console.log("Known error (functionality not broken)", err); //xx
    }
  };

  attachPicture = async () => {
    this.setState({ showGetPicture: true });
  };

  attachDocument = async () => {
    try {
      if (Platform.OS == "ios") await global.timeout(400)
      const { type, uri, name, size } = await DocumentPicker.getDocumentAsync()
      if (type == 'success') {
        await global.timeout(100)
        console.log("Document selected for sharing", { type, uri, name, size })
        if (size > 20000000) return alert("File must be smaller than 20 MB")

        const sizeString = Math.round(size / 100000) / 10 + " MB"
        const nameString = name ? name.substring(0, 40) : "-"
        Alert.alert("Share Document?", nameString + " (" + sizeString + ")",
          [{ text: "Cancel", onPress: () => { } },
          { text: "Yes", onPress: () => { this.executeAttachDocument({ type, uri, name, nameString, size, sizeString }) } }],
          { cancelable: true });
      } else {
        console.log("No document picked, status " + (type || "unknown!"))
      }
    } catch (e) { global.warn(e, "Error attachDocument") }
  };

  attachContact = async () => {
    this.props.navigation.navigate("messageSelectContact", {
      input: this.state.profileID,
      purpose: "SelectContactToShare",
      nextScreen: this.props.navigation.state.routeName
    });
  };

  executeAttachDocument = async (params) => {
    //xx tbd: delete uploaded file if upload was cancelled but still went through
    //xx tbd: delete uploaded files after 30 days or so (with users aware of it)
    const { type, uri, name, nameString, size, sizeString } = params
    try {
      this.setState({ showUploadingDocModal: true });
      const thisDocumentUploadID = Math.floor(Math.random() * 8999999999999999 + 1000000000000000);
      this.activeDocumentUploadID = thisDocumentUploadID

      const fileArray = name.split(".")
      let fileType = fileArray[fileArray.length - 1]
      if (fileType.length > 7) fileType = ""

      const storagePath =
        "ChatDocuments/" +
        this.props.chatID.substring(0, 10) +
        "__" +
        Math.floor(Math.random() * 89999999 + 10000000) + (fileType ? ("." + fileType) : "");

      const blob = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          resolve(xhr.response);
        };
        xhr.onerror = function (e) {
          console.log(e);
          reject(new TypeError("Network request failed"));
        };
        xhr.responseType = "blob";
        xhr.open("GET", uri, true);
        xhr.send(null);
      });

      const ref = firebase
        .storage()
        .ref()
        .child(storagePath);
      const snapshot = await ref.put(blob);
      const documentURL = await ref.getDownloadURL();

      if (this.state.showUploadingDocModal && this.activeDocumentUploadID == thisDocumentUploadID) { // execute only if upload hasn't been cancelled
        const document = { url: documentURL, name, type: fileType, size: sizeString }
        this.props.onPressSend({ text: "", document })

        this.setState({ showUploadingDocModal: false });
      }
    } catch (err) {
      this.setState({ showUploadingDocModal: false });
      await global.timeout(200)
      alert("Error while uploading. Check internet connection and try again");
      global.warn(err, "UpDoc1")
    }
  }

  updateTextInput = async (text) => {
    this.setState({ text })
    let youtubeID = "", searchString = ""
    if (text.toLowerCase().includes("youtu.be/")) searchString = "youtu.be/"
    if (text.toLowerCase().includes("youtube.com/watch?v=")) searchString = "youtube.com/watch?v="
    if (text.toLowerCase().includes("www.youtube.com/watch?app=desktop&v=")) searchString = "https://www.youtube.com/watch?app=desktop&v="

    if (searchString) {
      let textPostion = text.toLowerCase().indexOf(searchString)
      let idPosition = textPostion + searchString.length
      youtubeID = text.substring(idPosition, idPosition + 11)
    }

    if (youtubeID?.length == 11) {
      let isCorrect = true, r2
      try {
        const fetchString = "https://www.youtube.com/oembed?url=http://www.youtube.com/watch?v=" + youtubeID
        const r = await fetch(fetchString, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });
        r2 = await r.json();
      } catch (e) {
        isCorrect = false
      }
      console.log("iscorrect", isCorrect)
      if (isCorrect) await this.setState({ youtubeID: youtubeID, youtubeThumbnail: r2.thumbnail_url, youtubeTitle: r2?.title, urlThumbnail: null, urlTitle: null, urlLink: null, showURL: false, showYouTube: true })
      if (!isCorrect) await this.setState({ youtubeID: null, showYouTube: false, showYouTubeLoading: false })
    } else {
      await this.setState({ youtubeID: null, showYouTube: false, showYouTubeLoading: false })
      if (text.toLowerCase().includes('www.') && !text.includes("http:") && !text.includes("https:")) {
        text = text.toLowerCase().replace("www.", "http://www.")
      }
      getLinkPreview(text)
        .then(async (data) => {
          await this.setState({ urlThumbnail: data?.images[0], urlTitle: data?.title, urlLink: data?.url, showURL: true })
          this.props.getUrlDetail(this.state.urlTitle, this.state.urlThumbnail, this.state.urlLink)
        })
        .catch((err) => { this.setState({ urlThumbnail: null, urlTitle: null, urlLink: null, showURL: false }) })

    }
    this.props.getYoutubeDetail(this.state.youtubeID, this.state.youtubeThumbnail, this.state.youtubeTitle)
  }

  render() {
    return (
      <View style={{
        flexDirection: "row", padding: 1,
        alignItems: "center",
      }}>
        <View
          style={{
            borderBottomColor: "#333",
            borderBottomWidth: 1,
            flexDirection: "row",
            alignSelf: "stretch",
            alignItems: "center",
            flex: 1008866
          }}
        >
          <View style={{ alignItems: "center", flex: 1 }}>
            {
              (this.state.showYouTube || this.state.showURL) &&
              <View
                style={{ bottom: Platform.OS == "ios" ? 20 : 30, width: Dimensions.get("window").width, borderTopRightRadius: 5, backgroundColor: '#404040', position: 'relative' }} >
                <View style={{ flexDirection: 'row', display: 'flex', }}>
                  <View style={{
                    justifyContent: 'center', alignItems: 'center', marginLeft: 40
                  }}>
                    <Image
                      style={[{
                        width: 100,
                        height: 70,
                      },
                      ]}
                      source={{
                        uri: this.state.showYouTube ? this.state.youtubeThumbnail : this.state.urlThumbnail
                      }}
                    />
                    {this.state.youtubeID && <View
                      style={{
                        position: 'absolute', backgroundColor: "rgba(198,198,198,0.6)", borderRadius: 20, paddingLeft: 6, paddingBottom: 1,
                        height: 40, justifyContent: 'center', alignItems: 'center', width: 40,
                      }}>
                      <Text style={{ color: "#000", fontSize: 25 }} >
                        {"▶️"}
                      </Text>
                    </View>}
                  </View>
                  <View style={{ marginLeft: 3, paddingTop: 5, width: (Dimensions.get("window").width * 0.88) - 130 }}>
                    <Text style={{ opacity: 0.8, fontSize: 12, color: '#fff', fontStyle: "italic", fontWeight: "bold", }} numberOfLines={3} >{this.state.showYouTube ? this.state.youtubeTitle : this.state.urlTitle}</Text>
                  </View>
                </View>
                <View style={{ height: 10, width: '100%' }} />
                <TouchableOpacity style={{
                  padding: 20, right: 0, position: "absolute", flex: 1, height: '100%', alignItems: 'center', justifyContent: 'center'
                }}
                  onPress={async () => {
                    this.props.getYoutubeDetail(null, null, null,)
                    this.props.getUrlDetail(null, null, null)
                    this.setState({ youtubeID: null, youtubeThumbnail: null, youtubeTitle: null, urlTitle: null, urlThumbnail: null, urlLink: null, showYouTube: false, showURL: false })
                    await new Promise(resolve => {
                      setTimeout(resolve, 100);
                    });
                  }}>
                  <Text style={{ color: "#A8CF45", fontWeight: 'bold', fontSize: 20, alignSelf: 'center' }} >x</Text>
                </TouchableOpacity>
              </View>
            }
            <TextInput
              ref={ref => (this.myTextInput = ref)}
              style={{
                paddingTop: Platform.OS == "ios" ? 15 : 2,
                paddingBottom: Platform.OS == "ios" ? 15 : 2,
                alignSelf: "stretch",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 50,
                // ...(Platform.OS == "ios" && { paddingTop: 15, paddingBottom: 15 }),
                marginBottom: 0,
                color: global.app == "health" ? "#000" : "#FFFFFF",
                paddingLeft: 10
              }}
              multiline
              textAlignVertical={"center"}
              placeholder={"Type a message"}
              placeholderTextColor={"#008866"}
              //textAlign= "center"
              underlineColorAndroid="transparent"
              underlineColor={"transparent"}
              //returnKeyType="send"
              onChangeText={text => { this.updateTextInput(text) }}
              //onSubmitEditing={() => this.send({ text: this.state.text })}
              ref={component => (this.myTextInput = component)}
              // clearButtonMode="always" //xx how set color?
              value={this.state.text}
              onBlur={() => { this.props.onBlur?.() }}
              keyboardAppearance={"dark"}
            />
          </View>
          <TouchableOpacity
            style={{
              alignItems: "center",
              padding: 1,
              paddingHorizontal: 10,
              height: 50,
              justifyContent: "center",
              borderTopEndRadius: 20,
              borderBottomEndRadius: 20
            }}
            onPress={() => {
              this.setState({ showAttachMenu: true });
            }}
          >
            <Image
              style={{
                width: 24,
                height: 24,
                marginRight: 1
              }}
              source={require("../images/icons/Attach.png")}
            />
          </TouchableOpacity>
          {this.state.text.length == 0 && <TouchableOpacity
            style={{
              alignItems: "center",
              padding: 1,
              paddingHorizontal: 10,
              height: 50,
              justifyContent: "center",
            }}
            onPress={() => {
              this.setState({ showGetReaction: true });
            }}
          >
            <Image
              style={{
                width: 24,
                height: 24,
                tintColor: "#026550",
              }}
              source={require("../images/icons/React.png")}
            />
          </TouchableOpacity>}
          {this.state.text.length == 0 && <View style={{ width: 52 }} />}
          {this.state.text.length == 0 && <RecordMessage onSend={this.send} />}
          {/* {Platform.OS == "ios" && this.state.text.length == 0 && <View style={{ width: 52 }} />}
          {Platform.OS == "ios" && this.state.text.length == 0 && <RecordMessage onSend={this.send} />} */}
        </View>
        {this.state.text.length > 0 && (
          <TouchableOpacity
            style={{
              alignItems: "center",
              alignSelf: "center",
              padding: 1,
              backgroundColor: global.app == "health" ? "#45A8A8" : "#A8CF45",
              paddingHorizontal: 6,
              marginBottom: 2,
              height: 40,
              minWidth: 40,
              justifyContent: "center",
              borderTopEndRadius: 20,
              borderBottomEndRadius: 20
            }}
            onPress={() => this.send({ text: this.state.text })}
          >
            <Image
              style={{
                width: 18,
                height: 18,
                marginRight: 1
              }}
              source={require("../images/icons/Send.png")}
            />
          </TouchableOpacity>
        )}
        {this.state.showGetPicture && (
          <GetPicture
            onDone={pictureURL => {
              this.setState({ showGetPicture: false });
              console.log("PictureURL", pictureURL);
              if (pictureURL) {
                this.props.onNewPicture(pictureURL);
              }
            }}
            item={"CHAT_PICTURE"}
            chatID={this.props.chatID}
            noCrop
          />
        )}
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.showAttachMenu}
          style={{}}
          onRequestClose={() => {
            //alert("Modal has been closed.");
          }}
        >
          <TouchableWithoutFeedback
            onPress={() => this.setState({ showAttachMenu: false })}
          >
            <View
              style={{
                height: "100%",
                width: "100%",
                alignItems: "flex-end",
                justifyContent: "flex-end"
              }}
            >
              <View
                style={{
                  marginRight: 20,
                  marginBottom: 100,
                  height: 200,
                  width: 200,
                  backgroundColor: "rgba(0,0,00,.9)",
                  borderRadius: 5,
                  borderColor: "#008866",
                  borderWidth: 1
                }}
              >
                <TouchableOpacity
                  style={{
                    height: 50,
                    width: 200,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 15,
                    borderBottomWidth: 1,
                    borderColor: "#008866"
                  }}
                  onPress={async () => {
                    this.setState({ showAttachMenu: false });
                    await new Promise(resolve => {
                      setTimeout(resolve, 100);
                    });
                    this.attachPicture();
                  }}
                >
                  <Text style={{ color: "#ccc", fontWeight: "bold" }}>
                    Share Picture
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    height: 50,
                    width: 200,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 15,
                    borderBottomWidth: 1,
                    borderColor: "#008866"
                  }}
                  onPress={async () => {
                    this.setState({ showAttachMenu: false });
                    await new Promise(resolve => {
                      setTimeout(resolve, 100);
                    });
                    this.attachContact();
                  }}
                >
                  <Text style={{ color: "#ccc", fontWeight: "bold" }}>
                    Share Contact
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    height: 50,
                    width: 200,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 15,
                    borderBottomWidth: 1,
                    borderColor: "#008866"
                  }}
                  onPress={async () => {
                    await this.setState({ showAttachMenu: false });
                    await new Promise(resolve => {
                      setTimeout(resolve, 100);
                    });
                    this.setState({ showMapModal: true });
                  }}
                >
                  <Text style={{ color: "#ccc", fontWeight: "bold" }}>
                    Share Location
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    height: 50,
                    width: 200,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 15,
                    borderBottomWidth: 1,
                    borderColor: "#008866"
                  }}
                  onPress={async () => {
                    this.setState({ showAttachMenu: false });
                    await new Promise(resolve => {
                      setTimeout(resolve, Platform.OS == "ios" ? 500 : 100);
                    });
                    this.attachDocument();
                  }}
                >
                  <Text style={{ color: "#ccc", fontWeight: "bold" }}>
                    Share Document
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
        <Modal
          animationType="slide"
          transparent={false}
          visible={this.state.showMapModal}
          onRequestClose={() => {
            //leave this here
          }}
        >
          <MapScreen
            getUpdatedLocation={(address, coords) => {
              this.setState({
                mapCoords: coords,
                mapAddress: address
              });
              // console.log(
              //   "mapScreen data successfully passed! address: ",
              //   this.state.mapAddress,
              //   " coordinates passed: ",
              //   this.state.mapCoords
              // );
            }}
          //addresstoMapScreen={this.getCoordinates()}
          />

          <View
            style={{
              height: "5%",
              width: "15%",
              margin: 20,
              marginTop: -100,
              flexDirection: "row"
            }}
          >
            <TouchableOpacity
              onPress={() => {
                this.setState({ showMapModal: false });
              }}
            >
              <Text
                style={{
                  fontSize: 15,
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "grey",
                  padding: 5,
                  borderRadius: 5
                }}
              >
                Cancel
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={async () => {
                await this.setState({ showMapModal: false });
                let a = this.state.mapCoords;
                let b = this.state.mapAddress;
                await this.setState({
                  location: {
                    coords: a
                  },
                  address: b
                });
                this.props.onPressSend({
                  text: "", location: {
                    address: this.state.address,
                    coords: this.state.location.coords
                  },
                }
                );
              }}
            >
              <Text
                style={{
                  fontSize: 15,
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "green",
                  padding: 5,
                  borderRadius: 5,
                  marginLeft: 15
                }}
              >
                Set
              </Text>
            </TouchableOpacity>
          </View>
        </Modal>
        <Modal
          animationType="fade"
          transparent={true}
          visible={this.state.showUploadingDocModal}
          style={{}}
          onRequestClose={() => {
            //alert("Modal has been closed.");
          }}
        >
          <TouchableWithoutFeedback
          // onPress={() => this.setState({ showUploadingDocModal: false })}
          >
            <View
              style={{
                height: "100%",
                width: "100%",
                alignItems: "flex-end",
                justifyContent: "flex-end"
              }}
            >
              <View
                style={{
                  flex: 1,
                  width: "92%",
                  alignSelf: "center",
                  marginVertical: 20,
                  backgroundColor: "rgba(0,0,00,.9)",
                  borderRadius: 5,
                  borderColor: "#008866",
                  borderWidth: 1,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <ActivityIndicator size="large" color={"#9ab"} />
                <Text style={{ color: "#ccc", fontWeight: "bold", fontSize: 24, marginTop: 12 }}>
                  Uploading Document
                  </Text>
                <Text style={{ color: "#ccc", fontWeight: "bold", marginTop: 12 }}>
                  This may take a bit...
                  </Text>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showUploadingDocModal: false })
                    this.activeDocumentUploadID = 0
                  }}
                  style={{ marginTop: 75 }}>
                  <Text style={{ color: "#ccc", fontWeight: "bold", }}>Cancel</Text>
                </TouchableOpacity>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
        {this.state.showGetReaction && (
          <GetReaction
            onDone={reaction => {
              this.setState({ showGetReaction: false });
              console.log("reaction", reaction);
              if (reaction) {
                this.props.onPressSend({ text: "", video: reaction });
              }
            }}
          />
        )}
      </View >
    );
  }
}

export class SystemMessage extends Component {
  render() {
    return (
      <View
        style={{
          alignItems: "center",
          alignSelf: "center",
          height:
            this.props.text.length > 30
              ? 30 + (this.props.text.length - 30) * 0.5
              : 30,
          backgroundColor: "transparent",
          margin: 10,
          marginTop: 25,
          marginBottom: 0
        }}
      >
        <View
          style={[
            {
              borderWidth: 1,
              borderColor: global.app == "health" ? "#fff" : "rgba(0,0,0,0.2)",
              alignItems: "center",
              justifyContent: "center",
              height:
                this.props.text.length > 30
                  ? 20 + (this.props.text.length - 30) * 0.5
                  : 20,
              backgroundColor: global.app == "health" ? "#fff" : "#000",
              borderRadius: 2,
              paddingLeft: 6,
              paddingRight: 6
            }
          ]}
        >
          <Text style={{ color: "#AAA" }}>{this.props.text}</Text>
        </View>
      </View>
    );
  }
}

export class Contact extends Component {
  state = {
    contactProperties: {
      opponentProfileID: null,
      opponentName: "********",
      opponentNameBusiness: "************",
      opponentProfilePictureURL: null
    },
    activityIndicatorAnimating: false
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = {timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  componentDidMount() {
    try {
      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Profiles")
        .doc(this.props.contactID)
        .onSnapshot(this.updateContact);
    } catch (error) {
      console.log(error)
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.contactID !== prevProps.contactID)
      try {
        await this.unsubscribeFirestore()
        this.unsubscribeFirestore = firebase
          .firestore()
          .collection("Profiles")
          .doc(this.props.contactID)
          .onSnapshot(this.updateContact);
      } catch (error) {
        console.log(error)
      }
  }

  async componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  updateContact = async contactSnapshot => {
    console.log("Update of list of contacts triggered. (Contact in ChatDetail)");
    try {
      if (!contactSnapshot.exists) return;
      let contact = contactSnapshot.data();

      let opponentProfileID = contactSnapshot.id;
      let opponentName = contact.profileName;
      let opponentProfilePictureURL = contact.profilePictureURL;
      let opponentNameBusiness = contact.profileNameBusiness;
      if (!contact.profileNameBusiness) {
        opponentNameBusiness = "";
      }

      let contactProperties = {
        opponentProfileID,
        opponentName,
        opponentNameBusiness,
        opponentProfilePictureURL
      };

      //console.log("contactProperties", contactProperties);
      this.setState({
        contactProperties
      });
    } catch (err) {
      console.log("Slow internet. Could not fetch contacts (chatDetail)", err);
      //alert("Could not fetch contacts. Check your internet connection");
    }
  };

  render() {
    return (
      <TouchableOpacity
        style={{ height: 75 }}
        onPress={() => {
          if (!this.state.contactProperties.opponentProfileID) {
            return;
          }
          if (!this.props.navigation || !this.props.navigation.state) {
            return
          }
          let route = null;
          if (this.props.navigation.state.routeName && this.props.navigation.state.routeName.startsWith("chat")) {
            route = "messageProfile";
          }
          if (this.props.navigation.state.routeName && this.props.navigation.state.routeName.startsWith("home")) {
            route = "homeProfile";
          }
          if (this.props.navigation.state.routeName && this.props.navigation.state.routeName.startsWith("me")) {
            route = "meProfile";
          }
          if (this.props.navigation.state.routeName && this.props.navigation.state.routeName.startsWith("jobs")) {
            route = "jobsProfile";
          }

          let handoverID = this.state.contactProperties.opponentProfileID;
          this.props.navigation.navigate(route, {
            profileID: handoverID
          });
        }}
      >
        <View
          style={[
            {
              flexDirection: "row",
              flex: 1,
              height: 75,
              width: Dimensions.get("window").width * 0.68,
              backgroundColor: "black",
              alignItems: "center",
              paddingLeft: 11,
              paddingRight: 11,
              shadowOpacity: 0.5,
              shadowOffset: { width: 0, height: 3 },
              borderBottomColor: "#333",
              borderBottomWidth: 1
            }
          ]}
        >
          {!!this.state.contactProperties.opponentProfilePictureURL ? (
            <Image
              style={{ width: 42, height: 42, borderRadius: 4 }}
              source={{
                uri: this.state.contactProperties.opponentProfilePictureURL
              }}
            />
          ) : (
            <View style={{ width: 42 }} />
          )}
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View
              style={{
                flexDirection: "column",
                flex: 1,
                paddingHorizontal: 8
              }}
            >
              <Text style={{ fontSize: 14, color: "white" }}>
                {this.state.contactProperties.opponentName}
              </Text>
              {this.state.contactProperties.opponentNameBusiness != "" && (
                <Text style={{ fontSize: 11, color: "white" }}>
                  {this.state.contactProperties.opponentNameBusiness}
                </Text>
              )}
            </View>
            <View>
              {/* TBD: xx Add Follow Back button here
              <ActivityIndicator
                size="small"
                color="#FFFFFF"
                //animating={this.state.activityIndicatorAnimating}
                style={{
                  opacity: this.state.activityIndicatorAnimating ? 1.0 : 0.0
                }}
                animating={true}
              /> */}
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}
export class SinglePost extends PureComponent {
  state = {
    showPostDetail: false,
    isLive: true
  };

  render() {
    return (
      <View
        style={{
          width: 170,
          height: 170,
          transform: [{ scale: 0.6 }],
          marginLeft: -75,
          marginTop: -75
        }}
      >
        <TouchableOpacity
          onPress={() => {
            if (!this.state.showPostDetail && this.state.isLive) {
              this.setState({ showPostDetail: true });
            }
          }}
          style={{ width: 300, height: 300 }}
        >
          <Post
            postID={this.props.postID}
            key={this.props.postID + this.state.showPostDetail}
            navigation={this.props.navigation}
            showDetail={this.state.showPostDetail}
            onHideDetail={() => {
              this.setState({ showPostDetail: false });
            }}
            relevantStatus={"LIVE"}
            onIsNotRelevant={status => {
              this.setState({ isLive: false, status });
            }}
          />
          {!this.state.isLive && (
            <View
              style={{ marginTop: -180, height: 180, alignItems: "center" }}
            >
              <Text
                style={{ fontSize: 40, color: "white", fontWeight: "bold" }}
              >
                {this.state.status}
              </Text>
            </View>
          )}
        </TouchableOpacity>
      </View>
    );
  }
}

export class Message extends Component {
  state = {
    showImage: this.props.renderContent ? this.props.pictureURL ? true : false : false,
    showMeta: false,
    showFullscreenImage: false,
    watchingYoutube: false,
    playingYoutubeID: "",

    replyMessageHeight: 1350,
  };
  mySwipeable = {}


  openURL = (link) => {
    if (link.startsWith("https://wakandamarket.com/community/") && link.length == 56) {
      const communityID = link.substring(36, 56)
      global.navigateCommunity(this.props.navigation, { communityID })
    }
    else Linking.openURL(link);
  }

  renderLink = (text) => {
    let link = text
    if (text.toLowerCase().startsWith("www.")) link = "http://" + text
    return (<Text style={{ color: "blue" }} onPress={() => { this.openURL(link); }}>{text}</Text>)
  }

  renderText = (text) => {

    let t = text.toLowerCase()
    // console.log("renderText", text, t)

    // CASE text starts with link
    if (t.startsWith("http://") || t.startsWith("https://") || t.startsWith("www.")) {
      // console.log("CASE text starts with link")
      const linkArray = text.split(" ", 1)
      let link = linkArray[0]
      const linkArray2 = text.split("\n", 1)
      if (linkArray2[0].length < link.length) link = linkArray2[0]
      // console.log("link and remainder", link, text.substring(link.length, 999999999))
      if (link.length == text.length) return this.renderLink(link)
      return (<Text>{this.renderLink(link)}{this.renderText(text.substring(link.length, 999999999))}</Text>)
    }

    let fontSize = 14
    if (text && text.length < 8) {
      const numberOfEmojis = text.split(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g).length - 1
      if (numberOfEmojis >= 1 && text.length <= numberOfEmojis + 2) fontSize = 32
    }
    if (this.props.isReplyMessage && !this.props.isFutureReplyMessage) {
      if (text.length < 45) fontSize = 14
      if (text.length < 60) fontSize = 12
      else fontSize = 11
    }
    // if (this.props.isReplyMessage) {
    //   if (text.length < 45) fontSize = 24
    //   if (text.length < 60) fontSize = 20
    //   else fontSize = 18
    // }

    if (!t.includes(" http://") && !t.includes(" https://") && !t.includes(" www."))   // CASE text contains no links
      return (<Text style={{ fontSize }}>{text}</Text>)

    // CASE text contains Links
    // console.log("CASE text contains Links")
    let position1 = t.indexOf("http://")
    let position2 = t.indexOf("https://")
    let position3 = t.indexOf("www.")
    if (position1 == -1) position1 = 9999999999
    if (position2 == -1) position2 = 9999999999
    if (position3 == -1) position3 = 9999999999
    const position = Math.min(position1, position2, position3)
    // console.log("position", position, position1, position2, position3)
    // console.log("splits", text.substring(0, position), text.substring(position, 999999999))
    return (<Text>{this.renderText(text.substring(0, position))}{this.renderText(text.substring(position, 999999999))}</Text>)
  }

  playYoutube = async (id) => {
    this.setState({ playingYoutubeID: id, watchingYoutube: true })
  }

  swipe = (action) => {
    if (!global.isConnected || this.props.inquiry || this.props.isReplyMessage
      || this.props.disableReply) {
      if (this.mySwipeable) this.mySwipeable.close();
      return;
    }
    if (action == "reply") {
      this.props.replyToThis?.()
      if (this.mySwipeable) this.mySwipeable.close();
    }
    if (action == "delete") {
      this.props.deleteThis?.()
      if (this.mySwipeable) this.mySwipeable.close();
    }
  }

  renderRightActions = () => {
    if (!global.isConnected || this.props.inquiry || this.props.isReplyMessage
      || this.props.disableReply) {
      return <View />;
    }
    return (<View style={{ justifyContent: 'center', alignItems: 'flex-end', flex: 1 }}>
      <Text style={{ color: this.props.type == "health" ? "#000" : "#fff", fontSize: this.props.type == "health" ? 14 : 16, fontWeight: "bold", paddingHorizontal: 20 }}>Reply</Text>
    </View>)
  }
  renderLeftActions = () => {
    if (this.props.type !== "health" || this.props.disableDelete || this.props.isReplyMessage) {
      return <View />;
    }
    return (<View style={{ justifyContent: 'center', flex: 1 }}>
      <Text style={{ color: '#630d1a', fontWeight: '600', paddingHorizontal: 20 }}>Delete</Text>
    </View>)
  }



  render() {

    // Calculate width of invisible outer spacer so that width of small text bubbles is lower
    let length = this.props.text.length;
    let numberOfLineBreaks = this.props.text?.split?.("\n").length - 1 || 0;
    let spacerFlex = () => {
      const l = length + numberOfLineBreaks * 25;
      if (this.props.pictureURL && this.props.isReplyMessage && !this.props.isFutureReplyMessage)
        return 85;
      if (this.props.pictureURL) return 14;
      if (this.props.voiceURL) return 85;
      if (this.props.document) return 14;
      if (this.props.contactID && this.props.isReplyMessage && !this.props.isFutureReplyMessage) return 18;
      if (this.props.contactID) return 14;
      if (this.props.postID) return 60;
      if (this.props.youtubeID) return 14;
      if (this.props.urlLink) return 14;
      if (this.props.location) return 14;
      if (this.props.inquiry) return 14;
      if (this.props.isReplyMessage) {
        if (l <= 15) return 82;
        if (l >= 35) return 14;
        return 52;
      }
      if (l <= 15) return 150;
      if (l >= 35) return 14;
      return 140 - ((l - 15) * 130) / 20;
    };
    let bubbleHeight = () => {
      const l = length + numberOfLineBreaks * 25;

      let height = l > 60 ? 40 + (l - 50) * 0.5 : 40;
      if (this.props.pictureURL) {
        this.props.heightCalculated?.(273)
        return 273;
      }
      if (this.props.video) {
        const { width, height } = calculateVideoSize(this.props.video)
        this.props.heightCalculated?.(height)
        return height;
      }
      if (this.props.contactID) {
        this.props.heightCalculated?.(100)
        return 100;
      }
      if (this.props.postID) {
        this.props.heightCalculated?.(200)
        return 200;
      }
      if (this.props.youtubeID) {
        height = height + 260;
      }
      if (this.props.urlLink) {
        height = height + 60;
      }
      if (this.props.location) {
        this.props.heightCalculated?.(100)
        return 100;
      }
      if (this.props.document) {
        this.props.heightCalculated?.(100)
        return 100;
      }
      if (this.props.inquiry) {
        height = height + 60;
      }
      this.props.heightCalculated?.(height)
      return height;
    };

    const sWidth = Dimensions.get("window").width;
    const sHeight = Dimensions.get("window").height;

    const m = this.props.replyMessage

    return (
      <View style={{ marginTop: (this.props.followOnMessage) ? 0 : 18 }}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          if (this.props.isKeyItem) {
            this.props.setKeyY(layout.y)
            console.log('keyY:', layout.y);
          }
        }}>
        {!!this.props.replyMessage && <View style={{ opacity: 0.6, maxHeight: this.state.replyMessageHeight / 2 }}>
          {/* {!!this.props.replyMessage && <View style={{ transform: [{ scale: 0.5 }], opacity: 0.4, maxHeight: this.state.replyMessageHeight / 2, ...(this.props.authorMe ? { marginLeft: -100 } : { marginRight: -100 }), }}> */}
          <Message
            isReplyMessage={true}
            type={this.props.type}
            authorMe={this.props.replyMessageAuthorMe}
            text={m.text}
            pictureURL={m.pictureURL}
            document={m.document}
            video={m.video}
            voiceURL={m.voiceURL}
            voiceDuration={m.voiceDuration}
            contactID={m.contactID}
            postID={m.postID}
            youtubeID={m.youtubeID}
            youtubeTitle={m.youtubeTitle}
            youtubeThumbnailURL={m.youtubeThumbnailURL}
            urlTitle={m.urlTitle}
            urlThumbnail={m.urlThumbnail}
            urlLink={m.urlLink}
            // inquiry={m.inquiry}
            location={m.location}
            followOnMessage={true}
            time={""}
            picURL={""}
            renderContent={this.props.renderContent}
            renderVideo={false}
            navigation={this.props.navigation}
            disableReply={true}
            // replyMessage={m.replyMessage}
            heightCalculated={(h) => { if (h !== this.state.replyMessageHeight) this.setState({ replyMessageHeight: h }) }}
          //replyToThis={() => { this.setState({ replyMessage: m }) }}
          />
        </View>}
        <Swipeable
          renderRightActions={this.renderRightActions}
          renderLeftActions={this.renderLeftActions}
          rightThreshold={75} leftThreshold={150}
          ref={ref => (this.mySwipeable = ref)}
          onSwipeableLeftOpen={() => { this.swipe("delete") }}
          onSwipeableRightOpen={() => { this.swipe("reply") }}>
          <TouchableWithoutFeedback
            onPress={() => {
              this.setState({ showMeta: !this.state.showMeta });
            }}
          >
            <View
              style={{
                flexDirection: this.props.authorMe ? "row" : "row-reverse",
                justifyContent: "space-between",
                alignItems: "flex-start",
                height: (bubbleHeight() * ((this.props.isReplyMessage && !this.props.isFutureReplyMessage) ? 0.65 : 1)) + 2,
                backgroundColor: "transparent",
              }}
            >
              {/* ***** Spacer to shorten width of short messages ***** */}
              {/* <View style={{ flex: spacerFlex(this.props.text.length), alignSelf:"center", padding: 4 }} ><Text style={{color: "#888", fontSize: 10}}>{this.props.time}</Text></View> */}
              <View
                style={{
                  flex: !this.state.showMeta ? spacerFlex() : spacerFlex() * 1.21,
                  alignSelf: "flex-end",
                  padding: 4
                }}
              >
                {!!this.state.showMeta && (<View style={{
                  alignSelf: this.props.authorMe ? "flex-end" : "flex-start",
                  alignItems: this.props.authorMe ? "flex-end" : "flex-start"
                }}>
                  {this.props.read && <Image
                    style={{ height: 20, width: 22, tintColor: "#888", }}
                    source={require("../images/icons/Read.png")}
                  />}
                  <Text
                    style={{
                      color: "#888",
                      fontSize: 12,
                      textAlign: this.props.authorMe ? "left" : "right"
                    }}
                  >
                    {this.props.time}
                  </Text>
                </View>
                )}
                {!!this.props.pending && (
                  <Text
                    style={{
                      color: "#888",
                      fontSize: 9,
                      textAlign: "right"
                    }}
                  >
                    Sending
                  </Text>
                )}
                {/* {!!this.props.pending && (
              <ActivityIndicator size="small" color={this.props.authorMe ? "#ab9" : "#9ab"} style={{alignSelf: this.props.authorMe ? "flex-end" : "flex-start"}}/>
            )} */}
              </View>
              {this.props.video && <VideoMessage
                video={this.props.video}
                playOnStart={this.props.renderVideo}
                authorMe={this.props.authorMe} />}
              {!this.props.video && <View
                style={[
                  {
                    flex: 100,
                    borderWidth: 1,
                    borderColor: "rgba(0,0,0,0.2)",
                    alignItems: "center",
                    justifyContent: "center",
                    height: bubbleHeight() * ((this.props.isReplyMessage && !this.props.isFutureReplyMessage) ? 0.65 : 1),
                    backgroundColor: this.props.authorMe ? "#ab9" : "#9ab",
                    ...((this.props.type == "health") && { backgroundColor: this.props.authorMe ? "#fdd" : "#fff", }),
                    borderRadius: this.props.isReplyMessage ? 11 : 19,
                    paddingLeft: this.props.isReplyMessage ? 3 : 6,
                    paddingRight: this.props.isReplyMessage ? 3 : 6
                  },
                  this.props.authorMe && { borderTopEndRadius: 0 },
                  !this.props.authorMe && { borderTopLeftRadius: 0 }
                ]}
              >
                {!!this.props.pictureURL && (
                  <View>
                    {this.state.showImage ? (
                      <View>
                        <View
                          style={{
                            height: 265,
                            marginBottom: -252, //weird, should be (and was) 265
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <Text style={{ opacity: 0.35, fontStyle: "italic" }}>Loading Image</Text>
                        </View>
                        <TouchableOpacity
                          onPress={() => this.setState({ showFullscreenImage: true })}
                        >
                          <Image
                            style={[
                              !(this.props.isReplyMessage && !this.state.isFutureReplyMessage)
                                ? { height: 245, width: 265, borderRadius: 16 }
                                : { height: 159, width: 172, borderRadius: 8 },
                              this.props.authorMe && { borderTopRightRadius: 0 },
                              !this.props.authorMe && { borderTopLeftRadius: 0 }
                            ]}
                            source={{
                              uri: this.props.pictureURL
                            }}
                          />
                        </TouchableOpacity>
                      </View>
                    ) : (
                      <TouchableOpacity
                        onPress={() => {
                          this.setState({ showImage: true });
                        }}
                      >
                        <View
                          style={{ justifyContent: "center", alignItems: "center" }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              height: 51,
                              padding: 3,
                              alignItems: "center",
                              borderTopLeftRadius: 20,
                              borderTopEndRadius: 20
                            }}
                          >
                            <Image
                              style={[
                                { height: 48, width: 50 },
                                this.props.authorMe && { borderTopRightRadius: 0 },
                                !this.props.authorMe && { borderTopLeftRadius: 0 }
                              ]}
                              source={require("../images/icons/DownloadImage.png")}
                            />
                            <Text
                              style={{
                                color: "white",
                                fontSize: 16,
                                marginLeft: 5
                              }}
                            >
                              Show Picture
                        </Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    )}
                  </View>
                )}
                {!!this.props.voiceURL && <VoiceMessage
                  voiceURL={this.props.voiceURL}
                  voiceDuration={this.props.voiceDuration}
                  playOnStart={this.props.renderVideo}
                />}
                {!!this.props.contactID && (
                  <View>
                    <Contact
                      contactID={this.props.contactID}
                      navigation={this.props.navigation}
                    />
                  </View>
                )}
                {!!this.props.postID && (
                  <View>
                    <SinglePost
                      postID={this.props.postID}
                      navigation={this.props.navigation}
                    />
                  </View>
                )}
                {!!this.props.youtubeID && (<TouchableOpacity
                  onPress={() => {
                    if (this.props.youtubeID) { this.playYoutube(this.props.youtubeID); }
                  }}
                >
                  <View
                    style={{ alignItems: 'center', flex: 1, paddingTop: 10 }}
                  >
                    <View style={{
                      justifyContent: 'center', alignItems: 'center'
                    }}>
                      <Image
                        style={[{
                          width: 265,
                          height: 200,
                        },
                        !this.props.authorMe && { borderTopRightRadius: 10 },
                        this.props.authorMe && { borderTopLeftRadius: 10 }
                        ]}
                        source={{
                          uri: this.props.youtubeThumbnailURL
                        }}
                      /><View
                        style={{
                          position: 'absolute', backgroundColor: "rgba(198,198,198,0.6)", borderRadius: 20, paddingLeft: 6, paddingBottom: 1,
                          height: 40, justifyContent: 'center', alignItems: 'center', width: 40,
                        }}>
                        <Text style={{ color: "#000", fontSize: 25 }} >
                          {"▶️"}
                        </Text>
                      </View>
                    </View>
                    <Text style={{ opacity: 0.8, fontSize: 16, marginTop: 5, fontStyle: "italic", fontWeight: "bold" }} numberOfLines={1}>{this.props.youtubeTitle}</Text>
                  </View>
                  <Text selectable={true}>{this.renderText(this.props.text)}</Text>
                </TouchableOpacity>)}


                {!!this.props.urlLink && <TouchableOpacity
                  onPress={() => { Linking.openURL(this.props.urlLink); }}
                >
                  <View style={{ flex: 1, flexDirection: 'row', paddingTop: 10, width: '80%' }}>
                    <Image
                      style={[{
                        width: 50,
                        height: 50,
                        marginRight: 5,
                      },
                      !this.props.authorMe && { borderTopRightRadius: 10 },
                      this.props.authorMe && { borderTopLeftRadius: 10 }
                      ]}
                      source={{
                        uri: this.props.urlThumbnail
                      }}
                    />
                    <Text style={{ opacity: 0.8, fontSize: 16, marginTop: 5, fontStyle: "italic", fontWeight: "bold" }} numberOfLines={1}>{this.props.urlTitle}</Text>
                  </View>
                  <Text selectable={true}>{this.renderText(this.props.text)}</Text>
                </TouchableOpacity>}
                {/* {this.state.watchingYoutube && <View
              style={{
                alignItems: "center",
                justifyContent: "flex-end",
                height: "100%",
                backgroundColor: "#000"
              }}
            >
              <WebView
                originWhitelist={['*']}
                // style={{ width: sWidth, height: sHeight, marginVertical: ((sHeight - sWidth * 0.7) / 2) }}
                source={{ uri: 'https://www.youtube.com/embed/' + this.state.playingYoutubeID }}
                javaScriptEnabled={true}
                domStorageEnabled={true}
                useWebKit={true}
              />
              <TouchableOpacity
                style={{
                  position: "absolute",
                  borderWidth: 0,
                  backgroundColor: "#000",
                  borderColor: "rgba(0,0,0,0.2)",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 35,
                  // top: sHeight - fHeight - 15,
                  left: 5,
                  height: 25,
                  borderRadius: 35
                }}
                onPress={() => {
                  this.setState({ menuVisible: false, watchingYoutube: false });
                  this.props.onHideDetail();
                }}
              >
                <Image
                  style={{ width: 35, height: 35, opacity: 1 }}
                  source={require("../images/icons/CancelWhite.png")}
                />
              </TouchableOpacity>
            </View>} */}
                {!!this.props.location && (
                  <View>
                    <Text style={{ color: "black", marginBottom: 5 }}>
                      Location shared:
                </Text>
                    <TouchableOpacity
                      onPress={() => {
                        let q = this.props.location.address;
                        // if (Platform.OS === "ios") {
                        //   Linking.openURL(`http://maps.apple.com/?q=${q}`);
                        // } else {
                        Linking.openURL(`http://maps.google.com/?q=${q}`);
                        //}
                      }}
                    >
                      <Text style={{ color: "blue", marginBottom: 5 }}>
                        {this.props.location.address}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}
                {!!this.props.document && (
                  <View>
                    <Text style={{ color: "black", marginBottom: 5, fontWeight: "bold", fontSize: 11 }}>DOCUMENT SHARED:</Text>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                      <Image
                        style={{ width: 25, height: 25, marginRight: 2, tintColor: "black" }}
                        source={require("../images/icons/Attach.png")}
                      />
                      <View style={{}}>
                        <TouchableOpacity
                          onPress={() => {
                            try {
                              if (this.props.document.url) {
                                Linking.openURL(this.props.document.url);

                                // let remoteUrl = this.props.document.url;
                                // let localPath = `${FileSystem.documentDirectory}` + this.props.document.name;

                                // FileSystem.downloadAsync(remoteUrl, localPath)
                                //   .then(({ uri }) => Linking.openURL(uri));
                              }
                            } catch (e) {
                              console.warn("Err F7&")
                            }
                          }}
                        >
                          <Text style={{ color: "blue", marginBottom: 0 }}>{this.props.document.name || "?"}</Text>
                          <Text style={{ color: "black", marginBottom: 5, fontSize: 11 }}>{this.props.document.size || "Unknown Size"}  -  {this.props.document.type || "Unknown Type (!)"}</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                )}
                {!!this.props.inquiry && (
                  <Text
                    style={{ fontWeight: "bold", fontSize: 24, marginVertical: 10 }}
                  >
                    My Special Request
                  </Text>
                )}
                {!!this.props.text && !this.props.youtubeID && !this.props.urlLink && (<Text selectable={true}>{this.renderText(this.props.text)}</Text>)}
                {/* {this.props.text.length > 0 && this.renderText(text, replaceTerms)} */}
              </View>}
              {this.props.followOnMessage ? (
                <View style={{ width: 42, height: 42 }} />
              ) : (
                <View>
                  <View
                    style={{
                      width: 42,
                      height: 42,
                      borderRadius: 21,
                      backgroundColor: "white",
                      marginTop: 0
                    }}
                  />
                  <View
                    style={{
                      width: 42,
                      height: 42,
                      borderRadius: 21,
                      backgroundColor: "black",
                      marginTop: -41,
                      paddingLeft: 1
                    }}
                  />
                  {!!this.props.picURL ? <Image
                    style={{
                      width: 41,
                      height: 41,
                      borderRadius: 21,
                      marginTop: -42,
                      marginLeft: 0
                    }}
                    source={{
                      uri: this.props.picURL
                    }}
                  /> : <View
                    style={{
                      width: 41,
                      height: 41,
                      borderRadius: 21,
                      marginTop: -42,
                      marginLeft: 0
                    }}
                  />}
                </View>
              )}

              {this.state.showImage &&
                <FullscreenImage on={this.state.showFullscreenImage} pictureURL={this.props.pictureURL}
                  onClose={() => { this.setState({ showFullscreenImage: false }) }} />}

              <Modal
                animationType="fade"
                visible={this.state.watchingYoutube}
                onRequestClose={() => {
                  this.setState({ watchingYoutube: false });
                }}
              >
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "flex-end",
                    height: "100%",
                    backgroundColor: "#000"
                  }}
                >
                  <WebView
                    originWhitelist={['*']}
                    style={{ width: sWidth, height: sHeight, marginVertical: ((sHeight - sWidth * 0.7) / 2) }}
                    source={{ uri: 'https://www.youtube.com/embed/' + this.state.playingYoutubeID }}
                    javaScriptEnabled={true}
                    domStorageEnabled={true}
                    useWebKit={true}
                  />
                  <TouchableOpacity
                    style={{
                      position: "absolute",
                      borderWidth: 0,
                      backgroundColor: "#000",
                      borderColor: "rgba(0,0,0,0.2)",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 35,
                      top: 50,
                      left: 5,
                      height: 25,
                      borderRadius: 35
                    }}
                    onPress={() => {
                      this.setState({ watchingYoutube: false });
                    }}
                  >
                    <Image
                      style={{ width: 35, height: 35, opacity: 1 }}
                      source={require("../images/icons/CancelWhite.png")}
                    />
                  </TouchableOpacity>
                </View>
              </Modal>
            </View>
          </TouchableWithoutFeedback>
        </Swipeable>
      </View>
    );
  }
}

class CallBack extends Component {
  state = {
  };

  render() {
    console.log(this.props.number)
    let number = this.props.number.replace(/[^\d]/g, "");
    if (this.props.number.startsWith("+")) number = "+" + number

    let spacerFlex = 30
    let bubbleHeight = 45

    if (number.startsWith("+") && number.length < 11) return (<View />)
    if (number.length < 9) return (<View />)

    return (
      <View
        style={{
          flexDirection: this.props.authorMe ? "row" : "row-reverse",
          justifyContent: "space-between",
          alignItems: "flex-start",
          height: bubbleHeight + 2,
          backgroundColor: "transparent",
          marginTop: 0
        }}
      >
        <View
          style={{
            flex: spacerFlex,
            alignSelf: "flex-end",
            padding: 4
          }}
        >
        </View>
        <View
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            height: bubbleHeight,
            borderRadius: 19,
            paddingLeft: 6,
            paddingRight: 6,
            width: 100
          }}
        >
          <TouchableOpacity style={global.gStyles.buttonX}
            onPress={() => { Linking.openURL("tel:" + number); }}
          >
            <Text style={global.gStyles.buttontX}>CALL BACK</Text>
          </TouchableOpacity>
        </View>
        <View style={{ width: 42, height: 42 }} />
      </View>)
  }
}
class ContactCustomerCare extends Component {
  state = {
    activityIndicatorAnimating: false
  };

  render() {
    let spacerFlex = 30
    let bubbleHeight = 45
    return (
      <View
        style={{
          flexDirection: this.props.authorMe ? "row" : "row-reverse",
          justifyContent: "space-between",
          alignItems: "flex-start",
          height: bubbleHeight + 2,
          backgroundColor: "transparent",
          marginTop: 0
        }}
      >
        <View
          style={{
            flex: spacerFlex,
            alignSelf: "flex-end",
            padding: 4
          }}
        >
        </View>
        <View
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            height: bubbleHeight,
            borderRadius: 19,
            paddingLeft: 6,
            paddingRight: 6,
            width: 170
          }}
        >
          <TouchableOpacity style={global.gStyles.buttonXW}
            onPress={async () => {
              if (this.state.activityIndicatorAnimating) return;
              let chatID = "";
              let chatProperties = {
                opponentProfileID: "F4C7tJCPqxdpawvBpd2B",
                opponentName: "Customer Care",
                opponentNameBusiness: global.app == "artisan" ? global.a.appName : "Wakanda",
                opponentProfilePictureURL:
                  "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2Fw%20logo%20512X512b.png?alt=media&token=ee27b431-c5bc-42fe-9610-923efb58bce6"
              };
              //create a new chat or get existing chat ID
              this.setState({ activityIndicatorAnimating: true });
              try {
                await global.fetchIdToken()
                const r = await fetch(global.cloudFunctionURL + "newChat", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify({ isWeb:true,
                    profileID1: global.myProfileID,
                    profileID2: "F4C7tJCPqxdpawvBpd2B",
                    myUID: global.myUID,
                    idToken: global.idToken
                  })
                });
                const r2 = await r.json();
                chatID = r2.chatID;
                this.setState({ activityIndicatorAnimating: false });
              } catch (error) {
                this.setState({ activityIndicatorAnimating: false });
                console.log(error);
              }

              // navigate to chatID
              // this.props.navigation.goBack();
              // await global.timeout(100)
              this.props.navigation.navigate("chatMain", {
                redirect: true,
                redirectParams: {
                  chatID: chatID,
                  chatProperties: chatProperties
                }
              });
            }}
          >
            <Text style={global.gStyles.buttontXW}>{this.state.activityIndicatorAnimating ? "loading..." : "CONTACT CUSTOMER CARE"}</Text>
          </TouchableOpacity>
        </View>
        <View style={{ width: 42, height: 42 }} />
      </View>)
  }
}


export default class ChatDetailScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    return {
      headerTitleStyle: {
        fontWeight: "bold",
        color: global.app == "health" ? "#45A8A8" : "#008866",
        shadowOpacity: 0
      },
      headerStyle: {
        backgroundColor: global.app == "health" ? "#45A8A8" : "#000000",
        height: Dimensions.get("window").height >= 800 ? 100 : 75
      },

      headerRight: () => {
        if (!!navigation.getParam("isBusiness") &&
          global.app != "health") return (
            <TouchableOpacity
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#A8CF45",
                paddingHorizontal: 8,
                marginRight: 10,
                flexDirection: "row",
                borderRadius: 20,
                paddingVertical: 3
              }}
              onPress={navigation.getParam("requestService")}
            >
              <Image
                style={{
                  width: 15,
                  height: 15,
                  marginVertical: 3,
                  marginRight: 3
                }}
                source={require("../images/icons/RequestService.png")}
              />
              <Text
                style={{
                  fontSize: 12,
                  color: "#000",
                  fontWeight: "bold"
                }}
              >
                Order
            </Text>
            </TouchableOpacity>
          )
        if (navigation.getParam("opponentProfileID") == "z_systemInfoProfile1")
          return (
            <TouchableOpacity
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#A8CF45",
                paddingHorizontal: 8,
                marginRight: 10,
                flexDirection: "row",
                borderRadius: 20,
                paddingVertical: 3
              }}
              onPress={navigation.getParam("showPreferences")}
            >
              <Image
                style={{
                  width: 15,
                  height: 15,
                  marginVertical: 3,
                  marginRight: 3
                }}
                source={require("../images/icons/EditNotifications.png")}
              />
              <Text
                style={{
                  fontSize: 12,
                  color: "#000",
                  fontWeight: "bold"
                }}
              >
                Preferences
            </Text>
            </TouchableOpacity>
          )
      },

      headerTitle: () => {
        return (
          <View
            style={{
              flexDirection: "row",
              backgroundColor: global.app == "health" ? "#45A8A8" : "#000",
              width: Dimensions.get("window").width,

              justifyContent: "flex-start",
              alignItems: "center",
              paddingRight: 80,
              ...Platform.select({
                ios: {
                  marginLeft: 72
                },
                android: {
                  marginLeft: -20
                }
              })
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                paddingRight: 22
              }}
            >
              {/* ******** Left side (photo) ******** */}
              <TouchableOpacity
                onPress={async () => {
                  let handoverID = await navigation.getParam("opponentProfileID");
                  if (handoverID == "old chat") {
                    handoverID = null
                    let chatID = navigation.getParam("chatID", null);
                    if (!chatID) return;
                    try {
                      const chatDoc = await firebase.firestore().collection("Chats").doc(chatID).get();
                      const chat = chatDoc.data()
                      if (chat.participantProfileID1 == global.myProfileID) handoverID = chat.participantProfileID2
                      else handoverID = chat.participantProfileID1
                      console.log("HandoverID", handoverID)
                    } catch (e) {
                      console.log(e)
                    }
                  }
                  let prohibitProfile = await navigation.getParam(
                    "prohibitProfile"
                  );
                  if (handoverID && !handoverID.startsWith("z_systemInfo") && !prohibitProfile) {
                    navigation.navigate("messageProfile", {
                      profileID: handoverID
                    });
                  }
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    height: 42,
                    //backgroundColor: "#bbbbff",
                    alignItems: "center"
                  }}
                >
                  <View>
                    <View
                      style={{
                        width: 42,
                        height: 42,
                        borderRadius: 21,
                        backgroundColor: "white",
                        marginTop: 0
                      }}
                    />
                    <View
                      style={{
                        width: 42,
                        height: 42,
                        borderRadius: 21,
                        backgroundColor: "black",
                        marginTop: -41,
                        marginBottom: -42,
                        paddingLeft: 1
                      }}
                    />
                    {!!navigation.getParam("opponentProfilePictureURL") ? (
                      <Image
                        style={{
                          width: 41,
                          height: 41,
                          borderRadius: 21
                        }}
                        source={{
                          uri: navigation.getParam("opponentProfilePictureURL")
                        }}
                      />
                    ) : (
                      <View
                        style={{
                          width: 41,
                          height: 41,
                          paddingRight: 30,
                          borderRadius: 21
                        }}
                      />
                    )}
                  </View>
                  {/* ******** Center side (name, business) ******** */}
                  <View style={{ paddingHorizontal: 8 }}>
                    <Text style={{ fontSize: 16, color: "white" }}>
                      {navigation.getParam("opponentName")}
                    </Text>
                    {!!navigation.getParam("opponentNameBusiness", null) && (
                      <Text style={{ fontSize: 12, color: "white" }}>
                        {navigation.getParam("opponentNameBusiness", "")}
                      </Text>
                    )}
                  </View>
                </View>
              </TouchableOpacity>
            </View>
            <View style={{ alignItems: "flex-end", paddingRight: 20 }}>
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  height: 55
                }}
              />
            </View>
            {!!navigation.getParam("opponentBackgroundPictureURL") && <ImageBackground
              style={{
                position: "absolute", top: 0, left: -60, right: 0, bottom: 0,
                width: Platform.OS == "ios" ? "100%" : "140%",
                ...(Platform.OS == "android" && { height: "106%" }),
                opacity: Platform.OS == "ios" ? 0.6 : 0.35,
                marginLeft: Platform.OS == "ios" ? 100 : 0,
                zIndex: -1
              }}
              source={{ uri: navigation.getParam("opponentBackgroundPictureURL") }}
            >
              {Platform.OS == "ios" && <LinearGradient
                colors={[
                  "black", "transparent"
                ]}
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 1 }}
                style={{
                  flex: 1,
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />}
            </ImageBackground>}
          </View>
        )
      }
    };
  };
  state = {
    chat: {},
    chatMessages: [],
    sentBuffer: [],
    messagesShown: (Device.totalMemory >= 3500000000 && Device.deviceYearClass >= 2018) ? 40 : 18,
    messagesShownDelayed: (Device.totalMemory >= 3500000000 && Device.deviceYearClass >= 2018) ? 40 : 18, //is updated AFTER transition
    lastRead: {},
    myAuthorID: 1,
    status1: null,
    status2: null,
    keyboardSpacerHeight: 0,
    chatProperties: {
      opponentProfileID: null,
      opponentName: "********",
      opponentNameBusiness: "************",
      opponentProfilePictureURL: null,
      nOfUnreadMessages: 0,
      lastMessage: "",
      lastMessageDate: new Date()
    },
    inquiryStatus: "",
    inquiryText: "",
    inquiryImageURL: null,
    inquiryChatExistedBefore: true,
    inquiryAA: false,

    showGetPicture: false,
    showGetReaction: false,
    screenFocused: true,
    messagesLoaded: false,
    showOlderMessages: false,

    youtubeID: null,
    youtubeThumbnail: null,
    youtubeTitle: null,
    showYouTube: false,
    urlTitle: null,
    urlLink: null,
    urlThumbnail: null,
    showURL: false,

    replyMessage: null,
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = {timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
    global.lastReadCounter = 0;
  }

  async componentDidMount() {

    // let aa = await AsyncStorage.getItem("wakandaFatalError");
    // let bb = await AsyncStorage.getItem("wakandaFatalErrortst");
    // console.log(aa, bb)
    // console.log(aa, bb)
    // console.log(aa, bb)
    // console.log(aa, bb)
    // console.log(aa, bb)

    this.props.navigation.setParams({
      requestService: this.requestService,
      showPreferences: this.showPreferences
    });
    console.log("Component chatdetail did mount");
    if (Platform.OS == "android") this.keyboardDidShowListener = Keyboard.addListener("keyboardDidShow", this._keyboardDidShow);
    if (Platform.OS == "android") this.keyboardDidHideListener = Keyboard.addListener("keyboardDidHide", this._keyboardDidHide);
    if (Platform.OS == "ios") this.keyboardWillShowListener = Keyboard.addListener("keyboardWillShow", this._keyboardWillShow);
    if (Platform.OS == "ios") this.keyboardWillHideListener = Keyboard.addListener("keyboardWillHide", this._keyboardWillHide);

    try {
      let chatProperties = await this.props.navigation.getParam(
        "chatProperties",
        null
      );
      this.props.navigation.setParams({
        opponentName: chatProperties.opponentName,
        opponentNameBusiness: chatProperties.opponentNameBusiness,
        opponentProfilePictureURL: chatProperties.opponentProfilePictureURL,
        opponentBackgroundPictureURL: chatProperties.opponentBackgroundPictureURL,
      });
      this.props.navigation.setParams({
        opponentProfileID: chatProperties.opponentProfileID
      });
      if (chatProperties.opponentNameBusiness) {
        if (
          chatProperties.opponentNameBusiness.length > 1 &&
          chatProperties.opponentProfileID != "F4C7tJCPqxdpawvBpd2B"
        ) {
          this.props.navigation.setParams({
            isBusiness: true
          });
        }
      }
      if (chatProperties.opponentProfilePictureURL) {
        //Image.prefetch(chatProperties.opponentProfilePictureURL);
      }

      //this.props.navigation.setParams ({getChatProperties: () => {return "chatProperties"}})

      this.setState({
        chatProperties
      });

      let chatID = this.props.navigation.getParam("chatID", null);
      global.messageOpen = chatID;

      this.fChatRef = await firebase
        .firestore()
        .collection("Chats")
        .doc(chatID);
      this.unsubscribeFirestore = this.fChatRef.onSnapshot(this.updateChat);
    } catch (err) {
      alert("Could not fetch data. Check your internet connection");
      console.log("Slow internet?", err);
    }
  }

  async componentWillUnmount() {
    this.updateReadTimestamp();
    console.log("Component chatdetail will unmount");
    if (this.keyboardDidShowListener) this.keyboardDidShowListener.remove();
    if (this.keyboardDidHideListener) this.keyboardDidHideListener.remove();
    if (this.keyboardWillShowListener) this.keyboardWillShowListener.remove();
    if (this.keyboardWillHideListener) this.keyboardWillHideListener.remove();
    this.unsubscribeFirestore();
    global.messageOpen = "";
  }

  componentDidUpdate() {
    //this.scrollView?.scrollToEnd?.({animated: true})
    //console.log("Component chatdetail did update");
  }

  // Android
  _keyboardDidShow = async e => {
    console.log("Keyboard Shown", e, Dimensions.get("window").height, "-", Dimensions.get("screen").height);
    if (Platform.OS === "ios") {
      // if (Dimensions.get("window").height > 800) this.setState({keyboardSpacerHeight: 90 });
      // else this.setState({keyboardSpacerHeight: 65 });
      if (Dimensions.get("window").height > 800) this.setState({ keyboardSpacerHeight: e.endCoordinates.height - 75 });
      else this.setState({ keyboardSpacerHeight: e.endCoordinates.height - 45 });

    } else {
      if (true) { // was: Platform.Version <= 19
        this.setState({ keyboardSpacerHeight: e.endCoordinates.height - 28 }); // compromize between 45 for most devices, 10 for others
        // this.setState({keyboardSpacerHeight: 85 }); Android would also work with KeyboardAvoidingView "position" and this line
      }
    }
    // if (Platform.OS === "ios") {
    //   this.setState({ keyboardSpacerHeight: e.endCoordinates.height - 75 });
    // } else {
    //   if (true) { // was: Platform.Version <= 19
    //     this.setState({ keyboardSpacerHeight: e.endCoordinates.height - 50 });
    //   }
    // }
    await new Promise(resolve => { setTimeout(resolve, 50); });
    if (this.scrollView) this.scrollView?.scrollToEnd?.({ animated: true });
  };

  // Android
  _keyboardDidHide = () => {
    console.log("Keyboard Hidden");
    this.setState({ keyboardSpacerHeight: 0 });
  };

  //iOS
  _keyboardWillShow = async e => {
    console.log("Keyboard Will Show", e, Dimensions.get("window").height, "-", Dimensions.get("screen").height);

    // if (Dimensions.get("window").height > 800) this.setState({keyboardSpacerHeight: 90 });
    // else this.setState({keyboardSpacerHeight: 65 });
    if (Dimensions.get("window").height > 800) this.setState({ keyboardSpacerHeight: e.endCoordinates.height - 75 });
    else this.setState({ keyboardSpacerHeight: e.endCoordinates.height - 45 });

    await new Promise(resolve => { setTimeout(resolve, 50); });
    if (this.scrollView) this.scrollView?.scrollToEnd?.({ animated: true });
  };

  // iOS
  _keyboardWillHide = () => {
    console.log("Keyboard Hidden");
    this.setState({ keyboardSpacerHeight: 0 });
  };

  updateReadTimestamp = async () => {
    try {
      let chatID = this.props.navigation.getParam("chatID", null);
      if (!global.myProfileID) await global.timeout(5000)
      if (!global.myProfileID) return

      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "sendMessage", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          action: "markRead",
          chatID,
          myProfileID: global.myProfileID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg !== "SUCCESS") {
        console.warn("updateReadTimestamp!");
        console.log(r2.msg);
      }
    } catch (e) {
      global?.warn(e, "updateReadTimestamp")
    }

  };

  requestService = () => {
    this.props.navigation.navigate("messageJobsNew", {
      providerProfileID: this.state.chatProperties.opponentProfileID,
      providerProfileName: this.state.chatProperties.opponentName,
      providerBusinessName: this.state.chatProperties.opponentNameBusiness,
      providerProfilePictureURL: this.state.chatProperties.opponentProfilePictureURL,
    });
  };
  showPreferences = () => {
    this.props.navigation.navigate("chatBOSettings");
  };

  updateInput = (youtubeID, youtubeThumbnail, youtubeTitle) => {
    this.setState({ youtubeID, youtubeThumbnail, youtubeTitle, urlTitle: null, urlThumbnail: null, urlLink: null })
  }
  updateUrlInput = (urlTitle, urlThumbnail, urlLink) => {
    this.setState({ urlTitle, urlThumbnail, urlLink, youtubeID: null, youtubeThumbnail: null, youtubeTitle: null })
  }

  sendMessage = async (data) => {
    global.fetchIdToken()
    console.log("Sending chat message");
    console.log(this.state.replyMessage);

    try {
      let now = await firebase.firestore.Timestamp.now();
      console.log("Sending chat message ...");
      let addThis = {
        text: data.text,
        ...(this.state.youtubeID && { youtubeID: this.state.youtubeID }),
        ...(this.state.youtubeTitle && { youtubeTitle: this.state.youtubeTitle }),
        ...(this.state.youtubeThumbnail && { youtubeThumbnailURL: this.state.youtubeThumbnail }),
        ...(this.state.urlTitle && { urlTitle: this.state.urlTitle }),
        ...(this.state.urlThumbnail && { urlThumbnail: this.state.urlThumbnail }),
        ...(this.state.urlLink && { urlLink: this.state.urlLink }),
        ...(this.state.replyMessage && { replyMessage: this.state.replyMessage }),
        author: this.state.myAuthorID,
        timeSent: now,
        pending: true,
      };
      if (data.pictureURL) addThis.pictureURL = data.pictureURL;
      if (data.video) addThis.video = data.video;
      if (data.contactID) addThis.contactID = data.contactID;
      if (data.postID) addThis.postID = data.postID;
      if (data.location) addThis.location = data.location;
      if (data.inquiry) addThis.inquiry = data.inquiry;
      if (data.document) addThis.document = data.document;
      console.log("Sending chat message ... ...");

      await this.setState({
        sentBuffer: [...this.state.sentBuffer, addThis], replyMessage: null
      });

      console.log("Sending chat message ---");


      if (data.uploadFunction) { // case VOICE MESSAGE
        const voiceURL = await data.uploadFunction()
        console.log("voiceURL: ", voiceURL)
        addThis.text = ""
        addThis.voiceURL = voiceURL
        addThis.voiceDuration = data.voiceDuration
      }

      if (!global.myUID) await global.timeout(8000)
      const chatID = this.props.navigation.getParam("chatID", null);
      console.log({ chatID })
      const r = await fetch(global.cloudFunctionURL + "sendMessage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          myProfileID: global.myProfileID,
          messageData: addThis,
          senderName: global.myName || "",
          chatID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      this.setState({
        youtubeID: null,
        youtubeThumbnail: null,
        youtubeTitle: null,
        showYouTube: false,
        urlTitle: null,
        urlLink: null,
        urlThumbnail: null,
        showURL: false,
      })

    } catch (e2) {
      alert(e2);
      console.log(e2);
    }
  };

  updateChat = async querySnapshot => {
    const chat = querySnapshot.data()
    const chatMessages = chat.chatMessages
    let willUpdateTimestampLater = false;

    if (chatMessages.length > 0) {
      const newSentBuffer = this.state.sentBuffer.filter(message => {
        let returnValue = true
        chatMessages.forEach(msg => {
          if (msg.text == message.text) returnValue = false
        })
        return returnValue;
      })
      if (chatMessages.length > (this.state.chatMessages.length + this.state.sentBuffer.length)) {
        willUpdateTimestampLater = true;
      }
      console.log("AAAA", chatMessages.length, this.state.chatMessages.length, this.state.sentBuffer.length)
      await this.setState({ chatMessages: chatMessages, sentBuffer: newSentBuffer, status1: chat.status1, status2: chat.status2 });
    }
    if (chat.participantProfileID1 == global.myProfileID) {
      this.setState({ myAuthorID: 1 });
    }
    if (chat.participantProfileID2 == global.myProfileID) {
      this.setState({ myAuthorID: 2 });
    }
    await this.setState({ lastRead: chat.lastRead });
    if (this.state.screenFocused && willUpdateTimestampLater) {
      if (global.lastReadCounter < 110) {
        this.updateReadTimestamp();
      }
      global.lastReadCounter += 1;
      if (global.lastReadCounter > 20) {
        console.log(
          "WARNING. global.lastReadCounter > 20. Only possible if many messages have been written in this chat session"
        );
      }
      if (global.lastReadCounter > 100) {
        console.log("WARNING. global.lastReadCounter > 100. Check!");
      }
    }
    if (chat.inquiryStatusCustomer) {
      const inquiryText = chat.inquiryText;
      const inquiryChatExistedBefore = chat
        .inquiryChatExistedBefore;
      let inquiryStatus = "";
      if (
        chat.inquiryStatusCustomer == "OPEN" &&
        chat.inquiryStatusProvider == "OPEN" &&
        chat.inquiryAuthor != this.state.myAuthorID
      ) {
        inquiryStatus = "ProviderOpen";
      }
      if (
        chat.inquiryStatusCustomer == "OPEN" &&
        chat.inquiryStatusProvider == "ACCEPTED" &&
        chat.inquiryAuthor != this.state.myAuthorID
      ) {
        inquiryStatus = "ProviderAccepted";
      }
      if (
        chat.inquiryStatusCustomer == "OPEN" &&
        chat.inquiryStatusProvider == "ACCEPTED" &&
        chat.inquiryAuthor == this.state.myAuthorID
      ) {
        inquiryStatus = "CustomerOpen";
      }
      const inquiryImageURL = chat.inquiryImageURL;
      await this.setState({
        inquiryStatus,
        inquiryText,
        inquiryImageURL,
        inquiryChatExistedBefore
      });
    } else {
      this.setState({ inquiryStatus: "" });
    }

    if (!this.state.messagesLoaded) {
      let replyMessage = this.props.navigation.getParam("replyMessage", null);
      if (replyMessage) {
        const timeSent = await firebase.firestore.Timestamp.now();
        replyMessage.timeSent = timeSent
        replyMessage.author = this.state.myAuthorID == 2 ? 1 : 2
        this.setState({ replyMessage })
      }
    }
    this.setState({ messagesLoaded: true });
  };

  renderEvents = () => {
    return (
      <View style={{
        position: "absolute", top: 0, right: 0, alignItems: "flex-end",
        width: Dimensions.get("window").width, height: 55, zIndex: 2
      }}>
        <TouchableOpacity onPress={() => { }}>
          <Image
            style={{ width: 55, height: 55, tintColor: "#026550", }}
            source={require("../images/icons/NewEvent.png")}
          />
        </TouchableOpacity>
      </View>
    )
  }

  renderShowOlderMessages = () => {
    return (<View style={{ backgroundColor: "black", }}>
      <View style={{
        height: 80, alignItems: "center", backgroundColor: "#222",
        borderTopEndRadius: 40, borderTopLeftRadius: 40, justifyContent: "center",
      }}>
        <TouchableOpacity onPress={async () => {
          const oldY = this.keyY
          this.dontScrollToEnd = true
          const newMessagesShown = this.state.messagesShown + 50
          this.setState({ messagesShown: newMessagesShown })
          for (let i = 0; i < 100; i++) {
            if (this.keyY !== oldY) {
              this.scrollView.scrollTo({ x: 0, y: Math.floor((this.keyY || 150) - 150), animated: false })
              this.setState({ messagesShownDelayed: newMessagesShown })
              i = 1000
            }
            await global.timeout(100)
          }
          this.dontScrollToEnd = false
        }}
          style={[global.gStyles.buttonXLW, { margin: 20, minWidth: 200, alignSelf: "center", borderColor: "#888", height: 30 }]}>
          <Text style={global.gStyles.buttontXLW}>Show older messages ({this.state.chatMessages?.length - this.state.messagesShown})</Text>
        </TouchableOpacity>
      </View>
    </View>)
  }

  createMessageList = () => {
    let lastTimestamp = 0;
    let lastAuthor = 0;
    let sameAuthor = false;
    let lastDate = new Date("2000-01-02 10:34:23");
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    const totalArrayLength = [...this.state.chatMessages, ...this.state.sentBuffer]?.length || 0

    return [...this.state.chatMessages, ...this.state.sentBuffer].map((m, i) => {
      const threshold = this.state.messagesShown + (this.state.sentBuffer?.length || 0)
      if ((totalArrayLength > threshold) && (i < (totalArrayLength - threshold)))
        return <View key={i + "empty"}
        />

      if (lastAuthor === m.author) {
        sameAuthor = true;
      } else {
        sameAuthor = false;
      }
      lastAuthor = m.author;

      let sysMsg = null;
      // // let lastDate = firebase.firestore.FieldValue.serverTimestamp();
      if (
        lastDate.getDate() == m.timeSent.toDate().getDate() &&
        lastDate.getMonth() == m.timeSent.toDate().getMonth()
      ) {
        sysMsg = <View key={"v_" + i} />;
      } else {
        let dateString = String(
          m.timeSent.toDate().getDate() +
          "-" +
          months[m.timeSent.toDate().getMonth()] +
          "-" +
          m.timeSent.toDate().getFullYear()
        );
        sysMsg = <SystemMessage key={"sm_" + i} text={dateString} />;
      }
      let hoursString = "00:00am";
      let leadingZero = m.timeSent.toDate().getMinutes() < 10 ? "0" : "";
      if (m.timeSent.toDate().getHours() < 12) {
        hoursString =
          m.timeSent.toDate().getHours() +
          ":" +
          leadingZero +
          m.timeSent.toDate().getMinutes() +
          "am";
      } else {
        if (m.timeSent.toDate().getHours() < 13) {
          hoursString =
            m.timeSent.toDate().getHours() +
            ":" +
            leadingZero +
            m.timeSent.toDate().getMinutes() +
            "pm";
        } else {
          hoursString =
            m.timeSent.toDate().getHours() -
            12 +
            ":" +
            leadingZero +
            m.timeSent.toDate().getMinutes() +
            "pm";
        }
      }
      lastDate = m.timeSent.toDate();
      const chatID = this.props.navigation.getParam("chatID", null);

      if (m.text.startsWith("_PhoneNumber:")) return [
        sysMsg,
        <CallBack number={m.text.substring(14, 40)} authorMe={this.state.myAuthorID === m.author} key={"CB" + m.text.substring(14, 40)} />];
      if (m.text.startsWith("A visitor of your website is trying to chat")) return [sysMsg,
        <SystemMessage key={"cfw"} text={"Chat from your Website"} />];

      //  if (m.text.startsWith("Hmm.")) console.log(this.state.lastRead?.[this.state.chatProperties?.opponentProfileID]?.toDate?.() )

      return [
        sysMsg,
        <Message
          authorMe={this.state.myAuthorID === m.author}
          read={(this.state.myAuthorID === m.author) && !m.pending
            && (this.state.chatProperties.opponentProfileID && this.state.lastRead[this.state.chatProperties.opponentProfileID]
              && (m.timeSent.toDate() < this.state.lastRead[this.state.chatProperties.opponentProfileID].toDate()))}
          key={i + m.text}
          text={m.text}
          pictureURL={m.pictureURL}
          document={m.document}
          video={m.video}
          voiceURL={m.voiceURL}
          voiceDuration={m.voiceDuration}
          contactID={m.contactID}
          postID={m.postID}
          youtubeID={m.youtubeID}
          youtubeTitle={m.youtubeTitle}
          youtubeThumbnailURL={m.youtubeThumbnailURL}
          urlTitle={m.urlTitle}
          urlThumbnail={m.urlThumbnail}
          urlLink={m.urlLink}
          inquiry={m.inquiry}
          location={m.location}
          followOnMessage={sameAuthor}
          time={hoursString}
          picURL={this.state.myAuthorID === m.author ? global.myProfilePictureURL : this.state.chatProperties.opponentProfilePictureURL}
          pending={m.pending}
          renderContent={(this.state.chatMessages?.length - i) < 75}
          renderVideo={(this.state.chatMessages?.length - i) < (Platform.OS == "ios" ? 6 : 2)} // Android can crash when playing several videos at a time
          navigation={this.props.navigation}
          replyMessage={m.replyMessage}
          disableReply={chatID?.startsWith?.("z_systemInfo") || chatID?.startsWith?.("z_shop")}
          replyMessageAuthorMe={m.replyMessage?.author == this.state.myAuthorID}
          replyToThis={() => { this.setState({ replyMessage: m }); }}
          isKeyItem={(i == (totalArrayLength - this.state.messagesShownDelayed))}
          setKeyY={(y) => { this.keyY = y }}
        />,
        ...(m.text.startsWith("You just signed in to") || m.contactCustomerCareButton)
          ? [<ContactCustomerCare authorMe={this.state.myAuthorID === m.author} key={"CC"} navigation={this.props.navigation} />]
          : []
      ];
    });
  };

  createReplyMessage = () => {
    const m = this.state.replyMessage || {}
    return [
      <View style={{ height: 200 }} />,
      <View style={{ flexDirection: "row", marginHorizontal: 30, justifyContent: "space-between", alignItems: "flex-end", }}>
        <View style={{}}><Text style={{ color: "#fff", fontSize: 18, fontWeight: "bold", marginBottom: 10 }}>Replying to </Text></View>
        <TouchableOpacity style={{ paddingHorizontal: 20, paddingVertical: 10 }} onPress={() => { this.setState({ replyMessage: null }) }}>
          <Image
            style={{ width: 35, height: 35, opacity: 1 }}
            source={require("../images/icons/CancelWhite.png")}
          />
        </TouchableOpacity>
      </View>,
      <Message
        isReplyMessage={true}
        isFutureReplyMessage={true}
        type={this.props.type}
        disableReply={true}
        authorMe={m.author == this.state.myAuthorID}
        text={m.text}
        pictureURL={m.pictureURL}
        document={m.document}
        video={m.video}
        voiceURL={m.voiceURL}
        voiceDuration={m.voiceDuration}
        contactID={m.contactID}
        postID={m.postID}
        youtubeID={m.youtubeID}
        youtubeTitle={m.youtubeTitle}
        youtubeThumbnailURL={m.youtubeThumbnailURL}
        urlTitle={m.urlTitle}
        urlThumbnail={m.urlThumbnail}
        urlLink={m.urlLink}
        // inquiry={m.inquiry}
        location={m.location}
        followOnMessage={true}
        time={""}
        picURL={""}
        renderContent={true}
        renderVideo={false}
        navigation={this.props.navigation}
        // replyMessage={m.replyMessage}
        heightCalculated={(h) => { if (h !== this.state.replyMessageHeight) this.setState({ replyMessageHeight: h }) }}
      //replyToThis={() => { this.setState({ replyMessage: m }) }}
      />
    ];
  };

  pressedInquiryRed = async () => {
    if (this.state.inquiryAA) return;
    this.setState({ inquiryAA: true })

    const chatID = this.props.navigation.getParam("chatID", null);
    //xxx if !chat existed before, delete chat, delete chatRef(s)
    try {
      if (this.state.inquiryChatExistedBefore) {
        await firebase
          .firestore()
          .collection("Chats")
          .doc(chatID)
          .update({
            inquiryStatusProvider: firebase.firestore.FieldValue.delete(),
            inquiryStatusCustomer: firebase.firestore.FieldValue.delete(),
            inquiryText: firebase.firestore.FieldValue.delete(),
            inquiryImageURL: firebase.firestore.FieldValue.delete(),
            inquiryTimeCreated: firebase.firestore.FieldValue.delete()
            //inquiryChatExistedBefore: firebase.firestore.FieldValue.delete(),
          });
      } else {
        if (!global.isConnected) {
          this.setState({ inquiryAA: false })
          return alert("No internet connection")
        }
        await global.fetchIdToken()
        const r = fetch(global.cloudFunctionURL + "deleteChat", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            chatID,
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
      }
      await global.timeout(3002)
      this.props.navigation.goBack();
      this.setState({ inquiryAA: false })
    } catch (err) {
      this.setState({ inquiryAA: false })
      alert("Failed. Try again");
      console.log(err);
    }
    // this.props.navigation.navigate("chatMain");
  };

  pressedInquiryGreen = async () => {
    if (this.state.inquiryAA) return;
    this.setState({ inquiryAA: true })
    const chatID = this.props.navigation.getParam("chatID", null);
    try {
      if (this.state.inquiryStatus.startsWith("Provider")) {
        await firebase
          .firestore()
          .collection("Chats")
          .doc(chatID)
          .update({
            inquiryStatusProvider: "ACCEPTED"
          });

        if (this.state.inquiryChatExistedBefore) {
          // check if chat needs to be re-activated on customer side
          let sendActivationMessage = false
          if (this.state.myAuthorID == 1 && (this.state.status2 == "OLD" || this.state.status2 != "REMOVED")) sendActivationMessage = true
          if (this.state.myAuthorID == 2 && (this.state.status1 == "OLD" || this.state.status1 != "REMOVED")) sendActivationMessage = true
          if (sendActivationMessage) this.sendMessage({ text: "Request accepted." });
        }

        if (!this.state.inquiryChatExistedBefore) {
          if (!global.isConnected) {
            this.setState({ inquiryAA: false })
            return alert("No internet connection")
          }
          await global.fetchIdToken()
          const r = await fetch(global.cloudFunctionURL + "addChatRef", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ isWeb:true,
              chatID,
              profileID: this.state.chatProperties.opponentProfileID,
              myUID: global.myUID,
              idToken: global.idToken
            })
          });
          const r2 = await r.json();
          if (r2.msg != "SUCCESS") {
            alert("Error.");
            global.warn?.(r2.msg);
          }
        }
        await global.fetchIdToken()
        const r = await fetch(global.cloudFunctionURL + "sendPushNotification", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            targetProfileID: this.state.chatProperties.opponentProfileID,
            text: "ACCEPTED: I can deliver. Check out my profile!",
            senderName: global.myName || "",
            chatID,
            appVersionHasAgingCapability: true,
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
      } else {
        //xxx if chat existed before, delete chat, delete chatRef(s)
        //send InquiryMessage xxx

        await firebase
          .firestore()
          .collection("Chats")
          .doc(chatID)
          .update({
            inquiryStatusProvider: firebase.firestore.FieldValue.delete(),
            inquiryStatusCustomer: firebase.firestore.FieldValue.delete(),
            inquiryText: firebase.firestore.FieldValue.delete(),
            inquiryImageURL: firebase.firestore.FieldValue.delete(),
            inquiryTimeCreated: firebase.firestore.FieldValue.delete()
            //inquiryChatExistedBefore: firebase.firestore.FieldValue.delete(),
          });
      }
      this.setState({ inquiryAA: false })
    } catch (err) {
      this.setState({ inquiryAA: false })
      console.log("Error updating Inquiry ", err);
      alert("An error occurred.");
    }
  };

  render() {
    let chatID = this.props.navigation.getParam("chatID", "") || ""
    // OK, Chat. Inquiry accepted. Color green. Now waiting...
    let renderInquiryAA = (
      <View
        style={{
          width: "100%",
          paddingHorizontal: 15,
          paddingVertical: 30,
          alignItems: "center"
        }}
      >
        <ActivityIndicator
          size="large"
          color="#FFFFFF"
          animating={true}
        />
      </View>)

    let renderInquiry = (
      <View
        style={{
          width: "100%",
          paddingHorizontal: 15,
          paddingVertical: 30,
          alignItems: "center"
        }}
      >
        <Text
          style={{
            color: this.state.inquiryStatus.startsWith("Provider")
              ? "#f99"
              : "#9f9",
            fontWeight: "bold",
            fontSize: 24
          }}
        >
          {this.state.inquiryStatus == "ProviderOpen"
            ? "Special Request"
            : "Request Accepted"}
        </Text>
        <View
          style={{
            width: "100%",
            marginTop: 20,
            marginBottom: 20,
            backgroundColor: "#000",
            borderColor: "#333",
            borderWidth: 5,
            padding: 10,
            borderRadius: 4
          }}
        >
          <Text
            style={{
              fontStyle: "italic",
              color: this.state.inquiryStatus.startsWith("Provider")
                ? "#ccc" // red
                : "#ccc", // green
              alignSelf: "flex-start"
            }}
          >
            {this.state.inquiryText}
          </Text>
          {!!this.state.inquiryImageURL && (
            <View>
              <View
                style={{
                  marginTop: 10,
                  width: Dimensions.get("window").width * 0.84,
                  height: Dimensions.get("window").width * 0.84,
                  alignSelf: "center",
                  backgroundColor: "#333",
                  marginBottom: -Dimensions.get("window").width * 0.84
                }}
                source={{ uri: this.state.inquiryImageURL }}
              />
              <Image
                style={{
                  width: Dimensions.get("window").width * 0.84,
                  height: Dimensions.get("window").width * 0.84,
                  alignSelf: "center"
                }}
                source={{ uri: this.state.inquiryImageURL }}
              />
            </View>
          )}
        </View>
        {this.state.inquiryStatus == "ProviderAccepted" ? (
          <View
            style={{
              width: "100%",
              marginBottom: 20,
              //backgroundColor: "#111",
              borderRadius: 4
            }}
          >
            <Text
              style={{
                color: "#f99",
                alignSelf: "center",
                textAlign: "center",
                fontWeight: "bold"
              }}
            >
              The customer is notified and can now call or chat with you. You
              can also send a message below.
            </Text>
            <View style={{ flexDirection: "row", alignSelf: "center" }}>
              <Text
                style={{
                  color: "#f99",
                  alignSelf: "center",
                  fontWeight: "bold"
                }}
              >
                If you change your mind, you can still{" "}
              </Text>
              <TouchableOpacity onPress={this.pressedInquiryRed}>
                <Text
                  style={{
                    color: "#99f",
                    alignSelf: "center",
                    fontWeight: "bold"
                  }}
                >
                  reject
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        ) : (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%"
            }}
          >
            <TouchableOpacity
              onPress={this.pressedInquiryGreen}
              style={{
                height: 45,
                width: "45%",
                //backgroundColor: "#464",
                borderColor: "#464",
                borderTopWidth: 4,
                borderRightWidth: 5,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 10
              }}
            >
              <Text style={{ color: "#cfc", fontWeight: "bold", fontSize: 18 }}>
                {this.state.inquiryStatus.startsWith("Provider")
                  ? "Accept"
                  : "OK, Chat!"}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={this.pressedInquiryRed}
              style={{
                height: 45,
                width: "45%",
                //backgroundColor: "#644",
                borderColor: "#644",
                borderTopWidth: 4,
                borderRightWidth: 5,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 10
              }}
            >
              <Text style={{ color: "#fcc", fontWeight: "bold", fontSize: 18 }}>
                {this.state.inquiryStatus.startsWith("Provider")
                  ? "Reject"
                  : "Cancel"}
              </Text>
            </TouchableOpacity>
          </View>
        )}
        {/* <Text
          style={{
            color: "#888",
            alignSelf: "flex-start",
            fontSize: 10,
            alignSelf: "center",
            marginTop: 15
          }}
        >
          Only accept if you are sure you can deliver as specified!
        </Text> */}
        {this.state.inquiryStatus.startsWith("ProviderOpen") && ( //xxxx TBD: Make sure one cannot add this to an already open meReport screen (close any open meReport screen before navigating to it.)
          <TouchableOpacity
            onPress={() => {
              this.props.navigation.navigate("meReport", {
                profileID: this.state.chatProperties.opponentProfileID,
                profileName: this.state.chatProperties.opponentName,
                profileBusinessName: this.state.chatProperties
                  .opponentNameBusiness,
                inquiryText: this.state.inquiryText,
                inquiryImageURL: this.state.inquiryImageURL
              });
            }}
          >
            <Text
              style={{
                color: "#888",
                alignSelf: "flex-start",
                fontSize: 12,
                alignSelf: "center",
                marginTop: 20
              }}
            >
              Report Request
            </Text>
          </TouchableOpacity>
        )}
      </View>
    );
    return (
      <KeyboardAvoidingView
        behavior={Platform.OS == "ios" ? null : null}
        style={{
          flex: 1,
          backgroundColor: global.app == "health" ? "#fff" : "#000"
        }}
      >
        <View
          style={{
            backgroundColor: global.app == "health" ? "#fff" : "#000",
            flex: 1
          }}
        >
          <View
            style={{
              height: 1,
              backgroundColor: global.app == "health" ? "#fff" : "#000"
            }}
          />
          {/* <ChatHeader navigation={this.props.navigation} /> */}
          <PushNotificationHandler />

          <View style={{ flex: 1 }}>

            <ScrollView
              ref={ref => (this.scrollView = ref)}
              onContentSizeChange={(contentWidth, contentHeight) => {
                if (!this.dontScrollToEnd)
                  this.scrollView?.scrollToEnd?.({ animated: false, duration: 1500 });
              }}
              // onScroll={() => {
              //   this.childItem.measureLayout(findNodeHandle(this.containerWrapper), (x, y, width, height) => {
              //     console.log('got measurement', x, y, width, height)
              //   })
              // }}
              // scrollEventThrottle={1000}
              style={{
                backgroundColor: global.app == "health" ? "#fff" : "#222222",
                flex: 66
              }}
            >
              {!this.state.replyMessage && (this.state.messagesShown < this.state.chatMessages.length) && this.renderShowOlderMessages()}
              {!this.state.replyMessage && this.createMessageList()}
              {!this.state.replyMessage && !!this.state.inquiryStatus && <View>{!this.state.inquiryAA ? renderInquiry : renderInquiryAA}</View>}
              {!!this.state.replyMessage && this.createReplyMessage()}
              <View style={{ height: 5 }} />
            </ScrollView>
          </View>
          {!chatID.startsWith("z_systemInfo") &&
            !chatID.startsWith("z_shop") &&
            ((this.state.inquiryStatus != "CustomerOpen" &&
              this.state.inquiryStatus != "ProviderOpen") ||
              !!this.state.inquiryChatExistedBefore) && (
              <TypeBox
                onPressSend={this.sendMessage}
                getYoutubeDetail={this.updateInput}
                getUrlDetail={this.updateUrlInput}
                navigation={this.props.navigation}
                chatID={this.props.navigation.getParam("chatID", "unknownChat")}
                onNewPicture={pictureURL => { this.sendMessage({ text: "", pictureURL }); }}
                replyingToMessage={!!this.state.replyMessage}
              // onBlur={() => { this.setState({ replyMessage: null }) }}
              />
            )}
          <View
            style={{
              height: this.state.keyboardSpacerHeight
            }}
          />
        </View>

        <NavigationEvents
          onDidFocus={async payload => {
            this.setState({ screenFocused: true });

            await new Promise(resolve => {
              setTimeout(resolve, 500);
            });
            if (!this.state.messagesLoaded) {
              await new Promise(resolve => {
                setTimeout(resolve, 1000);
              });
            }
            if (!this.state.messagesLoaded) {
              await new Promise(resolve => {
                setTimeout(resolve, 1000);
              });
            }
            if (!this.state.messagesLoaded) {
              await new Promise(resolve => {
                setTimeout(resolve, 1000);
              });
            }
            if (!this.state.messagesLoaded) {
              await new Promise(resolve => {
                setTimeout(resolve, 1000);
              });
            }
            if (!this.state.messagesLoaded) {
              await new Promise(resolve => {
                setTimeout(resolve, 1000);
              });
            }
            if (!this.state.messagesLoaded) {
              await new Promise(resolve => {
                setTimeout(resolve, 1000);
              });
            }
            if (!this.state.messagesLoaded) {
              return;
            }

            if (this.props.navigation.getParam("chatID", null) != null) {
              global.messageOpen = this.props.navigation.getParam("chatID", null);
            }

            let text = this.props.navigation.getParam("text", null);
            if (text) {
              this.sendMessage({ text, pictureURL });
              this.props.navigation.setParams({ text: false });
            }
            let pictureURL = this.props.navigation.getParam("pictureURL", false);
            if (pictureURL) {
              this.sendMessage({ text: "", pictureURL });
              this.props.navigation.setParams({ pictureURL: false });
            }
            let contactID = this.props.navigation.getParam("contactID", false);
            if (contactID) {
              this.sendMessage({ text: "", contactID });
              this.props.navigation.setParams({ contactID: false });
            }
            let communityID = this.props.navigation.getParam("communityID", false);
            if (communityID) {
              this.sendMessage({ text: "Join this community: https://wakandamarket.com/community/" + communityID });
              this.props.navigation.setParams({ communityID: false });
            }
            let location = this.props.navigation.getParam("location", false);
            if (location) {
              this.sendMessage({ text: "", location });
              this.props.navigation.setParams({ location: false });
            }

            let postID = this.props.navigation.getParam("postID", false);
            if (postID) {
              this.sendMessage({ text: "", postID });
              this.props.navigation.setParams({ postID: false });
            }
            let inquiryImageURL = this.props.navigation.getParam("inquiryImageURL", false);
            if (inquiryImageURL) {
              this.sendMessage({ text: "", pictureURL: inquiryImageURL });
              this.props.navigation.setParams({ inquiryImageURL: false });
            }

            this.scrollView?.scrollToEnd?.({ animated: true });

            let report = this.props.navigation.getParam("report", null);
            if (report) {
              this.props.navigation.setParams({
                report: false
              });
              await new Promise(resolve => {
                setTimeout(resolve, 500);
              });
              alert(
                "Your report has been filed. We will look into it and take action when appropriate."
              );
            }
          }}
        />
        <NavigationEvents
          onDidBlur={async payload => {
            this.setState({ screenFocused: false });
            global.messageOpen = "";
          }}
        />
      </KeyboardAvoidingView>
    );
  }
}

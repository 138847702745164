import React, { Component } from "react";
import {
  Text,
  View,
  TextInput,
  Image,
  TouchableOpacity,
  TouchableHighlight,
  Keyboard,
  TouchableWithoutFeedback,
  Dimensions,
  ActivityIndicator
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";

export default class PayToBankAccount extends Component {
  state = {
    amount: false,
    number: null,
    activityIndicatorAnimating: false
  };

  sendNow = async () => {
    if (!global.myUID) {
      alert("System error. please restart the app");
      return;
    }
    if (!this.state.amount || !this.state.number) {
      alert("Please fill all fields");
      return;
    }
    if (!this.props.bankCode || !this.props.bankName) {
      alert("System Error");
      return;
    }
    if (this.state.amount < 100) {
      alert("Select higher amount");
      return;
    }
    if (!Number(this.state.amount)) {
      alert("Amount must be a number");
      return;
    }
    if (this.state.number.length != 10 || !Number(this.state.number)) {
      alert("Enter a 10-digit account number");
      return;
    }
    if (this.props.walletBalance - this.state.amount < 50) {
      alert("Insufficient Balance");
      return;
    }

    try {
      this.setState({ activityIndicatorAnimating: true });

      const r = await fetch(global.cloudFunctionURL + "payToBankAccount", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          accountNumber: this.state.number,
          amount: this.state.amount,
          bankCode: this.props.bankCode,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });

      const r2 = await r.json();
      this.setState({ activityIndicatorAnimating: false });

      if (r2.msg === "SUCCESS") {
        //const transactionReference = r2.transactionReference;
        await this.setState({ amount: null, number: null });
        console.log("Transaction successful...");
        await new Promise(resolve => {
          setTimeout(resolve, 100);
        });
        this.props.onCloseSuccess();
      } else {
        if (r2.reason && r2.reason == "Account resolve failed") {
          console.log("Account number not found", r2.error);
          alert("Unknown account number");
        } else {
          console.log("Transaction error (sendNow)", r2.error);
          alert("Transaction error");
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ activityIndicatorAnimating: false });
      alert("Error");
    }
  };

  render() {
    const bRadius = 5;
    let mBottom = 100;
    if (Dimensions.get("window").height > 800) {
      mBottom = 200;
    }
    if (Dimensions.get("window").height < 750) {
      mBottom = 50;
    }
    if (Dimensions.get("window").height < 700) {
      mBottom = 0;
    }
    return (
      <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
        <View
          style={{
            flex: 1,
            backgroundColor: "rgba(0,0,0,0.5)",
            justifyContent: "space-between"
          }}
        >
          <View
            style={{
              paddingTop: 5,
              flex: 1,
              backgroundColor: "#ccc",
              justifyContent: "space-between",
              marginHorizontal: 20,
              marginVertical: 30,
              marginBottom: mBottom
            }}
          >
            <View
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                margin: 25
              }}
            >
              <Text
                style={{ fontSize: 24, alignSelf: "center", marginBottom: 20 }}
              >
                Pay to Bank Account
              </Text>
              <View style={{ height: 115, alignSelf: "stretch" }}>
                <View
                  style={{
                    height: 90,
                    alignSelf: "stretch",
                    justifyContent: "flex-end"
                  }}
                >
                  <Text style={{ fontSize: 12, color: "gray" }}>
                    Account Number
                  </Text>
                  <TextInput
                    onChangeText={number => {
                      this.setState({ number });
                    }}
                    // onFocus={() => {
                    //   this.scrollView.scrollTo({ x: 0, y: 50, animated: true });
                    // }}
                    keyboardType="numeric"
                    style={{
                      color: "#fff",
                      borderColor: "white",
                      borderWidth: 0,
                      borderRadius: bRadius,
                      padding: 5,
                      height: 32,
                      backgroundColor: "#223",
                      alignSelf: "stretch",
                      shadowColor: "#223",
                      shadowOpacity: 2,
                      shadowOffset: { width: 0, height: 3 }
                    }}
                    underlineColorAndroid="transparent"
                    maxLength={16}
                    //defaultValue={this.state.name}
                    placeholder=""
                    bordered
                  />
                  <Text
                    style={{ fontSize: 12, marginVertical: 5, color: "gray" }}
                  >
                    {`(Bank: ${this.props.bankName})`}
                  </Text>
                </View>
              </View>
              <View style={{ width: "100%" }}>
                <Text
                  style={{
                    fontSize: 12,
                    color: "gray",
                    alignSelf: "flex-start"
                  }}
                >
                  Amount (N)
                </Text>
                <TextInput
                  onChangeText={amount => {
                    this.setState({ amount });
                  }}
                  // onFocus={() => {
                  //   this.scrollView.scrollTo({ x: 0, y: 50, animated: true });
                  // }}
                  keyboardType="numeric"
                  style={{
                    color: "#000",
                    borderColor: "white",
                    borderWidth: 0,
                    borderRadius: bRadius,
                    padding: 5,
                    height: 32,
                    backgroundColor: "#fff",
                    alignSelf: "stretch",
                    shadowOpacity: 2,
                    shadowOffset: { width: 0, height: 3 },
                    shadowColor: "#fff"
                  }}
                  underlineColorAndroid="transparent"
                  //maxLength={8}
                  //defaultValue={this.state.name}
                  placeholder=""
                  bordered
                />
              </View>
              <Text
                style={{
                  fontSize: 12,
                  marginVertical: 5,
                  color: "grey",
                  alignSelf: "flex-start"
                }}
              >
                My wallet balance: N {Math.round(this.props.walletBalance)}
              </Text>
              <View style={{ height: 30 }} />
              <Text
                style={{
                  fontSize: 16,
                  color: "#933",
                  alignSelf: "center"
                }}
              >
                Transaction fee: N 50.
              </Text>
              <View style={{ height: 30 }} />
              {!this.state.activityIndicatorAnimating && (
                <TouchableOpacity
                  style={[
                    global.gStyles.button,
                    {
                      margin: 21,
                      flexDirection: "row",
                      justifyContent: "center"
                    }
                  ]}
                  onPress={() => {
                    if (!this.state.activityIndicatorAnimating) {
                      this.sendNow();
                    }
                  }}
                >
                  <ActivityIndicator
                    size="small"
                    color="#000"
                    //animating={this.state.activityIndicatorAnimating}
                    style={{
                      opacity: this.state.activityIndicatorAnimating ? 1.0 : 0.0
                    }}
                    animating={true}
                  />

                  <Text
                    style={[
                      global.gStyles.buttontext,
                      { marginRight: 30, marginLeft: 10 }
                    ]}
                  >
                    Send now
                  </Text>
                </TouchableOpacity>
              )}
              <ActivityIndicator
                size="small"
                color="#000"
                //animating={this.state.activityIndicatorAnimating}
                style={{
                  opacity: this.state.activityIndicatorAnimating ? 1.0 : 0.0
                }}
                animating={true}
              />
              <View style={{ height: 10 }} />
              <TouchableHighlight
                onPress={() => {
                  this.props.onClose();
                }}
                style={{ alignSelf: "center" }}
              >
                <Text style={{ color: "black", fontSize: 14 }}>Cancel</Text>
              </TouchableHighlight>
            </View>
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  TextInput,
  Image,
  TouchableOpacity,
  Keyboard,
  Dimensions,
  Platform,
  Modal,
} from "react-native";
import { Video } from 'expo-av';

export class GetReaction extends Component {
  topSuggestions = [{
    height: 100, width: 100, miniPosterUrl: "https://thumbs.gfycat.com/RashRipeArabianhorse-mobile.jpg",
    miniUrl: "https://thumbs.gfycat.com/RashRipeArabianhorse-mobile.mp4",
  }, {
    "miniPosterUrl": "https://thumbs.gfycat.com/CandidEmotionalAlpinegoat-mobile.jpg",
    "miniUrl": "https://thumbs.gfycat.com/CandidEmotionalAlpinegoat-mobile.mp4", height: 368, width: 640
  }]
  state = {
    keyword: "trending",
    suggestions: this.topSuggestions,
    selectedVideo: null,
    searchTerm: "",
    thumbsOnly: true,
    hasScrolled: false,
    keyboardShowing: false
  };

  async componentDidMount() {
    this.getSuggestions("trending")

    this.keyboardDidShowListener = Keyboard.addListener("keyboardDidShow", this._keyboardDidShow);
    this.keyboardDidHideListener = Keyboard.addListener("keyboardDidHide", this._keyboardDidHide);
  }

  componentWillUnmount() {
    if (this.keyboardDidShowListener) this.keyboardDidShowListener.remove();
    if (this.keyboardDidHideListener) this.keyboardDidHideListener.remove();
  }

  _keyboardDidShow = async e => {
    this.setState({ keyboardShowing: true })
  };

  _keyboardDidHide = () => {
    this.setState({ keyboardShowing: false })
  };

  getSuggestions = async (keyword) => {
    try {
      let sText = keyword.toLowerCase()
      sText = sText.replace(/[^a-z\d\s]+/gi, "") //only letters, numbers and white spaces
      this.setState({ keyword: sText })
      sText = sText.replace(/\s/g, "+") //replace white spaces with +
      console.log(sText)
      let url = 'https://api.gfycat.com/v1/gfycats/search?count=48&search_text=' + sText
      //url = 'https://api.gfycat.com/v1/stickers/search?count=48&search_text=' + sText
      if (sText == "trending") url = "https://api.gfycat.com/v1/reactions/populated?tagName=trending"
      const r = await fetch(url)
      const r2 = await r.json();
      //console.log({ r2 })
      let suggestions = r2?.gfycats
      if (sText == "trending") suggestions = [...this.topSuggestions, ...suggestions]
      if (suggestions) this.setState({ suggestions })
      this.myScrollView?.scrollTo?.({ x: 0, y: 0, animated: false });
    } catch (e) {
      global.warn(e, "getSuggestions")
    }
  }

  componentWillUnmount() {
  }

  search = async (searchTerm) => {
    try {
      this.setState({ searchTerm })
      const nowDate = new Date();
      this.lastimeTyped = nowDate
      await global?.timeout?.(1000)
      if (nowDate == this.lastimeTyped) {
        this.getSuggestions(searchTerm.toLowerCase())
      }
    } catch (e) {
      global.warn(e, "gr-search")
    }
  }

  renderSearchBar = () => {
    return (<View
      style={{
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: Platform.OS == "ios" ? "70%" : "85%"
      }}
    >
      <TextInput
        style={{
          alignSelf: "stretch", height: 30, margin: 10, marginLeft: 0, color: "#fff", backgroundColor: "#889", borderRadius: 15, borderBottomWidth: 0, paddingLeft: 10, flex: 1
        }}
        placeholder="Search"
        placeholderTextColor="#eee"
        textAlign="center"
        underlineColorAndroid="transparent"
        underlineColor={"transparent"}
        onChangeText={searchTerm => this.search(searchTerm)}
        ref={component => (this.myTextInputBlue = component)}
        // clearButtonMode="always" //xx how set colour?
        value={this.state.searchTerm}
        keyboardShouldPersistTaps={'always'}
        keyboardDismissMode={'on-drag'}
        keyboardAppearance={"dark"}
        returnKeyType={"done"}
        maxLength={50}
        onSubmitEditing={() => { Keyboard.dismiss() }}
        autoCompleteType={'off'}
        autoCapitalize={'none'}
        autoCorrect={false}
      //onFocus={() => { if (Platform.OS == "android") this.myTextInputBlue?.focus?.() }}
      />
      <TouchableOpacity style={{ marginLeft: -50, width: 50, height: 50, alignItems: "center", justifyContent: "center", paddingRight: 10, paddingBottom: 3 }}
        onPress={() => { this.setState({ searchTerm: "" }); this.myTextInputBlue?.focus?.() }}>
        <Text style={{ color: "white", fontSize: 24, color: "#667" }}>×</Text>
      </TouchableOpacity>
    </View>)
  }

  renderSmiley = (smiley, keyword) => {
    return <TouchableOpacity onPress={() => {
      this.setState({ showSearch: false, searchTerm: "" })
      this.getSuggestions(keyword)
    }}
      style={{ ...(this.state.keyword == keyword && { backgroundColor: "#fff", borderRadius: 5 }) }}
    >
      <Text style={{ fontSize: 36 }}>{smiley}</Text>
    </TouchableOpacity>
  }

  render() {
    const sWidth = Dimensions.get("window").width;
    const sHeight = Dimensions.get("window").height;
    const vHeight = sHeight - 30 - 50 - 60
    const topSpacerHeight = (this.state.selectedVideo || this.state.keyboardShowing) ? 30 : 30 + vHeight / 3
    const selectedHeight = vHeight / 3
    let selectedWidth = sWidth - 4
    if (this.state.selectedVideo) {
      selectedWidth = this.state.selectedVideo.width / this.state.selectedVideo.height * selectedHeight
      selectedWidth = Math.min(selectedWidth, (sWidth - 4))
    }
    return (<Modal
      animationType="slide"
      transparent={true}
      visible={true}
      onRequestClose={() => {
        this.props.onDone()
      }}
    >
      <View style={{ flex: 1 }}>
        <TouchableOpacity
          onPress={() => this.props.onDone()}
          style={{ height: topSpacerHeight, width: "100%", opacity: 0.7, backgroundColor: "#222" }}
        />
        <View style={{
          flex: 1,
          alignItems: "flex-start", backgroundColor: "#000", borderWidth: 2, borderColor: "#889",
          borderTopEndRadius: 15, borderTopLeftRadius: 15
        }}>
          <View style={{ height: 48 }} />
          <View style={{ flex: 1, alignItems: "center" }}>
            {!!this.state.selectedVideo && <Video
              source={{ uri: this.state.selectedVideo.videoURL }}
              rate={1.0}
              volume={1.0}
              isMuted={true}
              resizeMode="cover"
              shouldPlay
              isLooping
              style={{ width: selectedWidth, height: selectedHeight }}
            />}

            <ScrollView
              showsVerticalScrollIndicator={false}
              ref={ref => (this.myScrollView = ref)}
              scrollEventThrottle={100}
              onScroll={(e) => {
                //images of hidden items should only be loaded when scrolling starts
                if (e.nativeEvent.contentOffset.y >= 10) {
                  this.setState({ hasScrolled: true });
                } else this.setState({ hasScrolled: false })
              }}>
              <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                {this.state.suggestions.map((s, i) => {
                  const numberRendering = this.state.hasScrolled ? 48 : 12
                  if (i < numberRendering) return <TouchableOpacity
                    key={s.miniUrl} activeOpacity={1}
                    onPress={() => {
                      console.log(s)
                      const selectedVideo = {
                        videoURL: s.content_urls?.mobile?.url || s.miniUrl || "",
                        posterURL: s.content_urls?.mobilePoster?.url || s.miniPosterUrl || "",
                        height: s.content_urls?.mobile?.height || s.height || 100,
                        width: s.content_urls?.mobile?.width || s.width || 100
                      }
                      this.setState({ selectedVideo })
                    }}
                  >
                    <View style={{ height: vHeight / 6, width: sWidth / 3 - 2, backgroundColor: s.avgColor || "#" + Math.round(Math.random * 899999 + 100000) }}>
                      {!this.state.thumbsOnly ? <Video
                        source={{ uri: s.miniUrl }}
                        rate={1.0}
                        volume={1.0}
                        isMuted={true}
                        resizeMode="cover"
                        shouldPlay={true}
                        isLooping
                        style={{ height: vHeight / 6, width: sWidth / 3 - 2 }}
                      /> :
                        <Image
                          style={{ height: vHeight / 6, width: sWidth / 3 - 2 }}
                          source={{ uri: s.content_urls?.mobilePoster?.url || s.miniPosterUrl || "" }}
                        />
                      }

                    </View>
                  </TouchableOpacity>
                })
                }
                {this.state.suggestions?.length == 0 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((s) => {

                  const backgroundColor = ("#" + Math.round(Math.random() * 899999 + 100000))
                  return <View key={s}
                    style={{
                      height: vHeight / 6, width: sWidth / 3 - 2,
                      backgroundColor
                    }} />

                })
                }
              </View>
            </ScrollView>
          </View>


          <View style={{
            height: 60, width: "100%", padding: 5, alignItems: "center", flexDirection: "row", justifyContent: "space-around",

          }}>
            {this.renderSmiley("😡", "angry")}
            {this.renderSmiley("😢", "sad")}
            {this.renderSmiley("😲", "wow")}
            {this.renderSmiley("⭐", "trending")}
            {this.renderSmiley("😂", "lol")}
            {this.renderSmiley("😎", "cool")}
            {this.renderSmiley("😍", "love")}
          </View>

        </View>
        <View style={{
          position: "absolute", top: topSpacerHeight, left: 0, height: 50, width: "100%",
          padding: 5, alignItems: "center", flexDirection: "row", justifyContent: "space-between",
          borderWidth: 2, borderColor: "#889", backgroundColor: "#222226", borderTopEndRadius: 15, borderTopLeftRadius: 15
        }}>
          <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>

            <TouchableOpacity
              style={{ paddingRight: 10, width: 45 }}
              onPress={() => { this.props.onDone() }}
            >
              <Image
                style={{ width: 35, height: 35, opacity: 1, }}
                source={require("../images/icons/CancelWhite.png")}
              />
            </TouchableOpacity>
            {Platform.OS == "ios" && <TouchableOpacity
              style={{ paddingRight: 10, width: 45 }}
              onPress={() => { this.setState({ thumbsOnly: !this.state.thumbsOnly }) }}
            >
              {this.state.thumbsOnly ? <Image
                style={{ width: 35, height: 35, opacity: 1, tintColor: "#fff" }}
                source={require("../images/icons/PlayDisabled.png")}
              />
                : <Image
                  style={{ width: 35, height: 35, opacity: 1, tintColor: "#fff" }}
                  source={require("../images/icons/Play.png")}
                />}
            </TouchableOpacity>}
            {!this.state.showSearch ? <TouchableOpacity
              style={{ paddingRight: 10 }}
              onPress={async () => {
                await this.setState({ showSearch: true })
                await global.timeout(100)
                this.myTextInputBlue?.focus?.()
              }}
            >
              <Image
                style={{ width: 25, height: 25, opacity: 1, tintColor: "#fff" }}
                source={require("../images/icons/Search.png")}
              />
            </TouchableOpacity>
              :
              this.renderSearchBar()}
          </View>
          {!!this.state.selectedVideo && <TouchableOpacity
            style={{
              alignItems: "center", alignSelf: "center", padding: 1,
              backgroundColor: "#A8CF45", flexDirection: "row",
              marginHorizontal: 4.5, width: 100, height: 32,
              justifyContent: "center", borderRadius: 20
            }}
            onPress={() => {
              this.props.onDone(this.state.selectedVideo)
            }}
          >
            <Text style={{ fontWeight: "bold", marginTop: 1 }}>SEND   </Text>
            <Image
              style={{ width: 18, height: 18, marginRight: 1 }}
              source={require("../images/icons/Send.png")}
            />
          </TouchableOpacity>}
        </View>
      </View>
    </Modal >)
  }
}

export class VideoMessage extends Component {
  state = {
    height: 110,
    width: 110,
    play: false,
    loading: true
  };

  async componentDidMount() {
    const { height, width } = calculateVideoSize(this.props.video)
    this.setState({
      height,
      width,
      play: this.props.playOnStart || false,
      loading: false
    })
    if (this.props.playOnStart && Platform.OS == "android") this.setPlayTimer()
  }

  setPlayTimer = async () => {
    try {
      await global.timeout(15000)
      this.setState({ play: false })
    } catch (e) {
      global.warn(e, "setPlayTimer")
    }
  }

  render() {
    if (this.state.loading) return <View />
    return (<View style={{
      height: this.state.height,
      width: this.state.width,
      borderWidth: 1,
      borderColor: "#fff",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: this.props.authorMe ? "#ab9" : "#9ab",
      ...((this.props.type == "health") && { backgroundColor: this.props.authorMe ? "#fdd" : "#fff", }),
      borderRadius: 19,
      ...(this.props.authorMe ? { borderTopEndRadius: 0 } : { borderTopLeftRadius: 0 }),
    }} >

      {(!this.state.play) && <View>
        <Image
          style={{
            height: this.state.height,
            width: this.state.width,
            backgroundColor: this.props.authorMe ? "#ab9" : "#9ab",
            ...((this.props.type == "health") && { backgroundColor: this.props.authorMe ? "#fdd" : "#fff", }),
            borderRadius: 19,
            ...(this.props.authorMe ? { borderTopRightRadius: 0 } : { borderTopLeftRadius: 0 }),
          }}
          source={{ uri: this.props.video.posterURL }}
        />
        <TouchableOpacity
          style={{
            marginTop: -(this.state.height),
            height: this.state.height,
            width: this.state.width,
            alignItems: "center",
            justifyContent: "center"
          }}
          onPress={() => {
            this.setState({ play: true })
            this.setPlayTimer()
          }}
        >
          <View
            style={{
              borderRadius: 14, paddingLeft: 6,
              height: 28, justifyContent: 'center', alignItems: 'center', width: 28,
            }}>
            <Text style={{ color: "#333", fontSize: 18 }} >{"▶️"}</Text>
          </View>
        </TouchableOpacity>
      </View>}
      <TouchableOpacity activeOpacity={1} onPress={() => { this.setState({ play: true }) }}>
        {(this.state.play) && <video width={this.state.width} height={this.state.height} loop='true' controls
          poster={this.props.video.posterURL}>
          <source src={this.props.video.videoURL} type="video/mp4" />
          <source src="movie.ogg" type="video/ogg" />
            Your browser does not support the video tag.
       </video>}

      </TouchableOpacity>
      {/*  <TouchableOpacity activeOpacity={1} onPress={() => { this.setState({ play: true }) }}>
        {(this.state.play || Platform.OS == "ios") && <Video
          source={{ uri: this.props.video.videoURL }}
          posterSource={{ uri: this.props.video.posterURL }}
          useNativeControls={Platform.OS == "ios"}
          rate={1.0}
          volume={1.0}
          isMuted={true}
          resizeMode="cover"
          shouldPlay={this.state.play}
          isLooping
          style={{
            height: this.state.height,
            width: this.state.width,
            backgroundColor: this.props.authorMe ? "#ab9" : "#9ab",
            ...((this.props.type == "health") && { backgroundColor: this.props.authorMe ? "#fdd" : "#fff", }),
            borderRadius: 19,
            ...(this.props.authorMe ? { borderTopEndRadius: 0 } : { borderTopLeftRadius: 0 }),
          }}
        />}
        
      </TouchableOpacity> */}

    </View>)
  }
}

export function calculateVideoSize(video, max) {
  const MAX = max || 250
  let height = 100
  let width = 100
  if (video?.height > 0 && video?.width > 0) {
    if (video.height > video.width) {
      height = MAX
      width = video.width / video.height * height
    } else {
      width = MAX
      height = video.height / video.width * width
    }
  }
  return { width, height }
}
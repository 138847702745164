import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  Image,
  ImageBackground,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Dimensions,
} from "react-native";
import { NavigationEvents } from "react-navigation";
import firebase from "firebase";
import "firebase/firestore";
import CachedImage from "../../shared/CachedImage"
const gStyles = global.gStyles //    "../../styles/gStyles"

const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;


export default class MeBlogsScreen extends Component {
  static navigationOptions = {
    headerTransparent: true,
    headerTitle: "My Social Status",
    headerTintColor: "#A8CF45",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1,
      elevation: 1
    },
  };

  state = {
    myBoard: {},
    afBoards: [],
    loading: true
  }

  componentDidMount = () => {
    this.loadBoards()
    this.loadAFBoards()
  }

  loadBoards = async () => {
    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "socialStatus", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "get",
          myUID: global.myUID,
          idToken: global.idToken,
        }),
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        this.setState({ myBoard: r2.response })
      }
      this.setState({ loading: false })
    } catch (e) {
      global.warn(e, "loadBoards")
    }
  }

  loadAFBoards = async () => {
    try {
      const r = await fetch(global.cloudFunctionURL + "socialStatus", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "getAF",
          myUID: global.myUID,
          idToken: global.idToken,
        }),
      });
      const r2 = await r.json();
      // console.log({ r2 })
      if (r2.msg == "SUCCESS") {
        this.setState({ afBoards: r2.response.afResults || [] })
      }
      this.setState({ loading: false })
    } catch (e) {
      global.warn(e, "loadBoards")
    }
  }

  componentWillUnmount = () => {
  }

  render() {
    if (this.state.loading) return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "#131313" }}>
        <ActivityIndicator size="large" color="#889" />
        <Text style={{ marginTop: 12, color: "#66666a" }}>Loading my Status</Text>
      </View>
    );
    return (
      < View style={{ flex: 1, backgroundColor: "#131313", paddingTop: 115 }}>
        <ScrollView style={{ flex: 1 }}>
          <Board board={this.state.myBoard} showDetailsOnLoad navigation={this.props.navigation} />
          {Array.isArray(this.state.afBoards) && this.state.afBoards.map(result => {
            if (result.profileID !== global.myProfileID) return (<Board key={result.profileID} board={result} navigation={this.props.navigation} />)
          })}
        </ScrollView>

        <NavigationEvents
          onDidFocus={async (payload) => {
            console.log("Reloading Status")
            //this.loadBoards()
          }}
        />
      </View >
    );
  }
}

class Board extends Component {
  state = {
    showDetails: this.props.showDetailsOnLoad
  }

  async componentDidMount() {
    try {
      //
    } catch (e) {
      global.warn(e, "Board-cdm")
    }
  }

  render() {
    const board = this.props.board
    const max = board?.nInvited || 1
    return (<View style={{ backgroundColor: "#232323", borderWidth: 2, borderColor: "#666", borderRadius: 12, padding: 16, margin: 20, paddingLeft: 32, alignSelf: "stretch" }}>
      <View style={{ height: 55, marginBottom: -50, zIndex: 2 }}>
        <Member profileID={board.profileID} navigation={this.props.navigation} />
      </View>
      <Text style={{ color: "#b0b4b7", fontStyle: "italic", fontSize: 15, marginBottom: 2 }}>                 Wakanda is awesome...</Text>
      <Text style={{ color: "#d0d5d9", fontStyle: "italic", fontWeight: "bold", fontSize: 16, marginBottom: 32 }}>                      The world will know!</Text>
      <Text style={{ color: "#e0e5e9", fontStyle: "italic", fontWeight: "bold", fontSize: 18, marginBottom: 8 }}>
        Your Referral Board
      </Text>

      {[{ a: "Friends Invited", count: board?.nInvited || 0, profileIDs: [] },
      { a: "Friends Joined Wakanda", count: board?.referrals?.length || 0, profileIDs: board?.referrals },
      { a: "Friends active on Wakanda", count: board?.activeReferrals?.length || 0, profileIDs: board?.activeReferrals }
      ].map(ob => {
        return (<TouchableOpacity onPress={() => { this.setState({ showDetails: !this.state.showDetails }) }} key={ob.a}>
          <View style={{ height: 22, marginTop: 3, width: "100%", flexDirection: "row" }}>
            <View style={{ flex: ob.count / max, height: 22, backgroundColor: "rgba(60,0,255,1)", borderRadius: 5 }} />
            <View style={{ flex: (max - ob.count) / max, height: 22, backgroundColor: "rgba(0,0,0,1)" }} />
          </View>
          <View style={{ marginTop: -22, height: 22, width: "100%", flexDirection: "row", alignItems: "center" }}>
            <Text style={{ color: "#ccd", flex: 1, marginLeft: 6 }} numberOfLines={1}>{ob.a}</Text>
            <Text style={{ color: "#ccd", fontWeight: "bold", width: 35, textAlign: "right", marginRight: 6 }}>{ob.count}</Text>
          </View>
          {this.state.showDetails &&
            <View style={{ height: 30, marginBottom: 5, width: "100%", flexDirection: "row" }}>
              {ob.profileIDs?.length > 0 && Array.isArray(ob.profileIDs) && <View style={{ marginLeft: 5, width: "100%", height: 28 }}>
                <ScrollView horizontal>
                  {ob.profileIDs.map(pID => {
                    return <Member profileID={pID} faceOnly key={pID} many={max > 6}
                      navigation={this.props.navigation} />
                  })}
                </ScrollView>
              </View>}
            </View>}
        </TouchableOpacity>)
      })}
      <ImageBackground
        style={{
          position: "absolute", top: 0, left: 0, right: 0, bottom: 0, opacity: 0.5, zIndex: -1
        }}
        source={{ uri: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2Fwood1280.jpg?alt=media&token=55b7c07a-3f36-4cab-b1bc-ae9668ad40e9" }}
      />
    </View>)
  }
}


class Member extends Component {
  state = {
    pictureURL: "", name: "", businessName: ""
  }

  async componentDidMount() {
    try {
      const profileDoc = await firebase
        .firestore()
        .collection("Profiles")
        .doc(this.props.profileID)
        .get()
      const profile = profileDoc.data()
      this.setState({
        pictureURL: profile.profilePictureURL || "",
        name: profile.profileName || "- No Name -",
        businessName: profile.profileNameBusiness || ""
      })
    } catch (e) {
      global.warn(e, "cdm_Member")
    }
  }

  render() {
    if (this.props.faceOnly) return (
      <TouchableOpacity onPress={() => { global.navigateProfile(this.props.navigation, this.props.profileID) }}>
        <View style={{ height: 28, width: this.props.many ? 22 : 35, alignItems: "center", justifyContent: "center" }}>
          {this.state.pictureURL ? <Image
            style={{ width: 25, height: 25, borderRadius: 12.5, opacity: 0.65 }}
            source={{ uri: this.state.pictureURL }}
          /> : <View style={{
            width: 25, height: 25, borderRadius: 12.5, backgroundColor: "#668", alignItems: "center", justifyContent: "center",
          }}>
            <Text style={{ fontWeight: "bold", fontSize: 16, color: "#99b" }}>{this.state.name.substring(0, 1)}</Text>
          </View>}
        </View>
      </TouchableOpacity>
    )
    return (
      <TouchableOpacity onPress={() => { global.navigateProfile(this.props.navigation, this.props.profileID) }}>
        <View style={{ height: 55, flexDirection: "row", alignItems: "center", alignSelf: "stretch" }}>
          <View style={{ width: 53, justifyContent: "center" }}>
            {this.state.pictureURL ? <Image
              style={{ width: 50, height: 50, borderRadius: 12.5, }}
              source={{ uri: this.state.pictureURL }}
            /> : <View style={{
              alignItems: "center", justifyContent: "center",
              width: 50, height: 50, borderRadius: 12.5, backgroundColor: "#668"
            }}>
              <Text style={{ fontWeight: "bold", fontSize: 26, color: "#99b" }}>{this.state.name.substring(0, 1)}</Text>
            </View>}
          </View>
          <View style={{ flex: 1 }}>
          </View>
        </View>
      </TouchableOpacity>)
  }
}
import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  Alert,
  Dimensions,
} from "react-native";
import * as Font from "expo-font";
import { LinearGradient } from "expo-linear-gradient";
import { Feather, AntDesign } from "@expo/vector-icons";
import { SvgCss } from "react-native-svg";
import firebase from "firebase";
import "firebase/firestore";
import Post from "../../Post/Post";
const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;

export default class RecommendedOffer extends Component {
  state = {
    loading: true,
    title: "***** *****",
    text: "***** ***** ***** ***** *****",
    authorBusinessName: "",
    authorProfilePictureURL: null,
    pictureURL: null,
    price: -1,
    priceDiscount: 0,
    priceType: "NONE",
    priceACN: -1,
    priceCurrency: "NGN",
    newPriceString: "",
    itemDetails: null,
    showDetail: false,
    detailPostResult: null,
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => {
      return;
    };
  }

  async componentDidMount() {
    //console.log(this.props.postID);
    const postDoc = await firebase
      .firestore()
      .collection("Posts")
      .doc(this.props.postID)
      .get();

    const post = postDoc.data();
    post.xID = postDoc.id;

    this.setState({
      itemDetails: post,
      title: post.postTitle,
      pictureURL: post.postPictureURL,
      text: post.postText,
      price: post.postPrice,
      priceType: post.postPriceType,
      priceDiscount: post.postPriceDiscount || 0,
      priceCurrency: post.postPriceCurrency || "NGN",
      status: post.systemStatus,

      loading: false,
    });
  }
  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  render() {
    //console.log("details", this.state.itemDetails);
    let oldPrice = this.state.price;
    let DiscountPrice = this.state.priceDiscount;
    let percentageOff = Math.round((DiscountPrice / oldPrice) * 100).toString();

    // new  discount price starts

    let pString = Math.round(oldPrice - DiscountPrice).toString();
    if (pString.length > 8) {
      pString = pString.slice(0, pString.length - 6) + "M";
    } else if (pString.length > 6) {
      pString = Number(pString.slice(0, pString.length - 5)) / 10;
      pString = pString.toString() + "M";
    } else if (pString.length > 4) {
      pString = pString.slice(0, pString.length - 3) + "K";
    }
    // new  discount price ends

    // old  discount price starts

    let oldpString = Math.round(oldPrice).toString();
    if (oldpString.length > 8) {
      oldpString = oldpString.slice(0, oldpString.length - 6) + "M";
    } else if (oldpString.length > 6) {
      oldpString = Number(oldpString.slice(0, oldpString.length - 5)) / 10;
      oldpString = oldpString.toString() + "M";
    } else if (oldpString.length > 4) {
      oldpString = oldpString.slice(0, oldpString.length - 3) + "K";
    }
    // old  discount price ends

    let pCurrency = this.state.priceCurrency || "NGN";
    let pCurrencyString = global.currencySymbols[pCurrency];

    let priceString = pCurrencyString + " " + pString;
    let oldpriceString = pCurrencyString + " " + oldpString;

    //localString is acnString
    let localString = "";
    let oldLocalString = "";

    // xx possibly remove NGN from last section of the following line  to show less
    // xx possibly replace last section of the following line with global.xRates[post.postPriceCurrency||"NGN"] to show ACN for all (also old) NGN posts
    if (
      global.walletType == "ACN" &&
      !(this.state.priceCurrency == "ACN") &&
      this.state.priceType != "Free" &&
      this.state.price > 0 &&
      global.xRates[this.state.priceCurrency || "NGN"]
    ) {
      const lPrice = Math.round(
        this.state.price / global.xRates[this.state.priceCurrency || "NGN"]
      );
      const oldlPrice = Math.round(
        oldPrice / global.xRates[this.state.priceCurrency || "NGN"]
      );
      const lCurrency = global.currencySymbols["ACN"];
      let lString = Math.round(lPrice).toLocaleString(0);
      let oldlString = Math.round(oldlPrice).toLocaleString(0);
      localString = "≈ " + lCurrency + " " + lString;
      oldLocalString = "≈ " + lCurrency + " " + oldlString;
    }
    let beforeString = "";
    let afterString = "";
    if (this.state.priceType && this.state.priceType != "Fixed Price") {
      if (this.state.priceType == "Starting From") {
        beforeString = "Starting From";
      } else {
        afterString = "(" + this.state.priceType + ")";
      }
      if (this.state.priceType == "Free") {
        priceString = "FREE";
        oldpriceString = "FREE";
        localString = "";
        afterString = "";
      }
      if (this.state.priceType == "NONE") {
        priceString = "";
        oldpriceString = "";
        localString = "";
        afterString = "";
      }
    }
    // let pString = Math.round(this.state.price).toString();

    // // console.log("stringed price :", pString);
    // if (pString.length > 8) {
    //   pString = pString.slice(0, pString.length - 6) + "M";
    // } else if (pString.length > 6) {
    //   pString = Number(pString.slice(0, pString.length - 5)) / 10;
    //   pString = pString.toString() + "M";
    // } else if (pString.length > 4) {
    //   pString = pString.slice(0, pString.length - 3) + "K";
    // }

    // let pCurrency = this.state.priceCurrency || "NGN";

    // let pCurrencyString = global.currencySymbols[pCurrency];

    // let priceString = pCurrencyString + " " + pString;

    // //localString is acnString
    // let localString = "";
    // if (
    //   global.walletType == "ACN" &&
    //   !(this.state.priceCurrency == "ACN") &&
    //   this.state.priceType != "Free" &&
    //   this.state.price > 0 &&
    //   global.xRates[this.state.priceCurrency || "NGN"]
    // ) {
    //   const lPrice = Math.round(
    //     this.state.price / global.xRates[this.state.priceCurrency || "NGN"]
    //   );
    //   const lCurrency = global.currencySymbols["ACN"];
    //   let lString = Math.round(lPrice).toLocaleString(0);
    //   localString = "≈ " + lCurrency + " " + lString;
    // }
    // let beforeString = "";
    // let afterString = "";
    // if (this.state.priceType && this.state.priceType != "Fixed Price") {
    //   if (this.state.priceType == "Starting From") {
    //     beforeString = "Starting From";
    //   } else {
    //     afterString = "(" + this.state.priceType + ")";
    //   }
    //   if (this.state.priceType == "Free") {
    //     priceString = "FREE";
    //     localString = "";
    //     afterString = "";
    //   }
    //   if (this.state.priceType == "NONE") {
    //     priceString = "";
    //     localString = "";
    //     afterString = "";
    //   }
    // }
    // console.log("price and  currency :", priceString);

    if (this.state.loading)
      return (
        <View
          style={{
            height: 200,
            alignItems: "center",
            justifyContent: "center",
            //backgroundColor: "#fff"
          }}
        >
          <ActivityIndicator size="small" color="#000" />
        </View>
      );
    if (this.state.status !== "LIVE") return <View />;
    return (
      <TouchableOpacity
        onPress={() => {
          this.setState({
            showDetail: true,
            detailPostResult: this.state.itemDetails,
          });
        }}
        style={{
          // pading: 5,
          marginHorizontal: 7,
          marginVertical: 10,
          borderRadius: 15,
          maxWidth: "35%",
          maxHeight:
            Dimensions.get("window").height < 688
              ? Dimensions.get("window").height * 0.33
              : Dimensions.get("window").height * 0.25,
          backgroundColor: "#F5F5F5",
          marginBottom: 20,
        }}
      >
        <LinearGradient
          colors={this.props.colors}
          style={{
            flex: 1,
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            height: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 15,
          }}
        />
        {percentageOff != 0 && (
          <View
            style={{
              backgroundColor: "red",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 5,
              width: "32%",
              position: "absolute",
              padding: 2,

              zIndex: 1,
            }}
          >
            <Text
              style={{
                color: "white",
                fontSize: 12,
                fontFamily: "ProximaNovaA-Bold",
              }}
            >
              {percentageOff + "%"}
            </Text>
          </View>
        )}
        <Image
          style={{
            width: "100%",
            height: 120,
            borderRadius: 12,
            marginBottom: 20,
            shadowColor: "#000",
            shadowOpacity: 0.5,
          }}
          source={{
            uri: this.state.pictureURL,
          }}
          resizeMode="cover"
        />

        <View
          style={{
            minWidth: "100%",
            maxHeight: "35%",
            borderBottomRightRadius: 12,
            borderBottomLeftRadius: 12,
            // backgroundColor: "#F46E56",
            paddingHorizontal: 10,
            paddingBottom: 10,
          }}
        >
          <View style={{ paddingTop: 5, minHeight: 35 }}>
            <Text
              style={{
                color: "black",
                fontSize: 12,
                fontFamily: "ProximaNovaA-Bold",
              }}
              numberOfLines={2}
            >
              {this.state.title}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                backgroundColor: oldpriceString === "" ? "" : "red",
                padding: 7,
                maxHeight: 25,
                borderRadius: 12,
                maxWidth: "60%",
                marginTop: 5,
              }}
            >
              <Text
                style={{
                  fontSize: 11,
                  fontFamily: "ProximaNovaA-Bold",
                  color: "#FFFFFF",
                }}
              >
                {percentageOff != 0 ? priceString : oldpriceString}
              </Text>
            </View>

            {percentageOff != 0 && (
              <View>
                <Text
                  style={{
                    fontFamily: "ProximaNovaA-Bold",
                    fontSize: 11,

                    textDecorationLine: "line-through",
                  }}
                >
                  {oldpriceString}
                </Text>
              </View>
            )}
          </View>
        </View>
        {this.state.showDetail && (
          <Post
            postID={this.state.detailPostResult.xID}
            data={this.state.detailPostResult}
            //data={this.props.data}
            key={this.state.detailPostResult.xID + this.state.showDetail}
            navigation={this.props.navigation}
            showDetail={this.state.showDetail}
            onHideDetail={() => {
              this.setState({ showDetail: false });
            }}
            relevantStatus={"LIVE"}
            blackBackground={true}
            onIsNotRelevant={() => {
              alert("Offer not available at the moment");
            }}
          />
        )}
      </TouchableOpacity>
    );
  }
}

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  TextInput,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  Alert,
  ActivityIndicator,
  Platform,
  Dimensions
} from "react-native";
import NetInfo from '@react-native-community/netinfo';
import firebase from "firebase";
import "firebase/firestore";

import Post from "../Home/Post/Post";

// class SearchBox extends Component {
//   state = {};
//   searchChats = searchString => {
//     alert("Search not yet implemented");
//   };
//   render() {
//     return (
//       <View style={{ padding: 5, alignItems: "center", marginTop: 0 }}>
//         <TextInput
//           style={{
//             alignSelf: "stretch",
//             height: 50,
//             marginBottom: 0,
//             marginTop: 0,
//             color: "#FFFFFF",
//             borderBottomColor: "#FFFFFF",
//             borderBottomWidth: 1
//           }}
//           placeholder="Search messages"
//           placeholderTextColor="#008866"
//           //textAlign= "center"
//           underlineColor={"transparent"}
//           returnKeyType="search"
//           onChangeText={string => this.searchChats(string)}
//         />
//       </View>
//     );
//   }
// }

class SinglePost extends PureComponent {
  state = {
    visible: true,
    showPostDetail: false
  };

  render() {
    // const iWidth = Dimensions.get("window").width / 2;
    // const colF = this.props.colF;
    // const Nope = (
    //   <View
    //     style={{ height: 50, justifyContent: "center", alignItems: "center" }}
    //   >
    //     <Text style={{ color: colF }}>Currently no offerings.</Text>
    //   </View>
    // );

    // if (!this.props.postIDs) {
    //   // xx redo
    //   return Nope;
    // }

    if (Platform.OS == "ios")
      return (
        <View
          style={{
            width: this.state.visible ? 155 : 0,
            height: 155,
            transform: [{ scale: 0.5 }]
          }}
        >
          {!!this.state.visible && (
            <TouchableOpacity
              onPress={() => {
                if (!this.state.showPostDetail) {
                  this.setState({ showPostDetail: true });
                }
              }}
              style={{
                width: this.state.visible ? 300 : 0,
                height: 300,
                marginTop: -70,
                marginBottom: -70
              }}
            >
              <Post
                postID={this.props.postID}
                key={this.props.postID + this.state.showPostDetail}
                navigation={this.props.navigation}
                showDetail={this.state.showPostDetail}
                onHideDetail={() => {
                  this.setState({ showPostDetail: false });
                }}
                relevantStatus={"LIVE"}
                onIsNotRelevant={() => {
                  this.setState({ visible: false });
                }}
                loadingColor={"#686"}
              />
            </TouchableOpacity>
          )}
        </View>
      );
    else
      return (
        <View
          style={{
            width: this.state.visible ? 300 : 0,
            height: 300,
            transform: [{ scale: 0.5 }],
            marginBottom: -75,
            marginTop: -75,
            marginRight: this.state.visible ? -105 : 0,
            marginLeft: this.state.visible ? -40 : 0
          }}
        >
          {!!this.state.visible && (
            <TouchableOpacity
              onPress={() => {
                if (!this.state.showPostDetail) {
                  this.setState({ showPostDetail: true });
                }
              }}
              style={{
                width: this.state.visible ? 300 : 0,
                height: 300,
                marginTop: 0,
                marginBottom: -70,
                marginRight: -70
              }}
            >
              <Post
                postID={this.props.postID}
                key={this.props.postID + this.state.showPostDetail}
                navigation={this.props.navigation}
                showDetail={this.state.showPostDetail}
                onHideDetail={() => {
                  this.setState({ showPostDetail: false });
                }}
                relevantStatus={"LIVE"}
                onIsNotRelevant={() => {
                  this.setState({ visible: false });
                }}
                loadingColor={"#686"}
              />
            </TouchableOpacity>
          )}
        </View>
      );
  }
}
class WishlistPosts extends PureComponent {
  render() {
    if (this.props.postIDs.length < 1) {
      return (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: 50
          }}
        >
          <Text style={{ color: "#888", fontStyle: "italic" }}>
            Offers you add to your Wishlist will appear here.
          </Text>
        </View>
      );
    }

    return (
      <ScrollView horizontal style={{ marginLeft: -50 }}>
        <View style={{ width: 10 }} />
        {this.props.postIDs.map(postID => {
          return (
            <View key={postID}>
              <SinglePost postID={postID} navigation={this.props.navigation} />
              {!!this.props.edit && (
                <TouchableOpacity
                  onPress={() => {
                    this.props.onRemove(postID);
                  }}
                  style={{
                    alignSelf: "center",
                    height: Platform.OS == "ios" ? 20 : 25,
                    width: Platform.OS == "ios" ? 20 : 25,
                    marginLeft: -71,
                    marginTop: Platform.OS == "ios" ? -155 : -150,
                    marginBottom: 158
                  }}
                >
                  <View
                    style={{
                      height: 25,
                      width: 25,
                      backgroundColor: "#c45",
                      borderRadius: 15
                    }}
                  >
                    <Image
                      source={require("../images/icons/CancelBlack.png")}
                      style={{ height: 25, width: 25 }}
                    />
                  </View>
                </TouchableOpacity>
              )}
            </View>
          );
        })}
        <View style={{ width: 35 }} />
      </ScrollView>
    );
  }
}
class LikedPosts extends PureComponent {
  render() {
    // const iWidth = Dimensions.get("window").width / 2;
    // const colF = this.props.colF;
    // const Nope = (
    //   <View
    //     style={{ height: 50, justifyContent: "center", alignItems: "center" }}
    //   >
    //     <Text style={{ color: colF }}>Currently no offerings.</Text>
    //   </View>
    // );

    // if (!this.props.postIDs) {
    //   // xx redo
    //   return Nope;
    // }

    if (this.props.postIDs.length < 1) {
      return (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: 50
          }}
        >
          <Text style={{ color: "#888", fontStyle: "italic" }}>
            Offers you like (swiping right) will appear here.
          </Text>
        </View>
      );
    }

    return (
      <ScrollView horizontal style={{ marginLeft: -50 }}>
        <View style={{ width: 10 }} />
        {this.props.postIDs.map(postID => {
          return (
            <SinglePost
              postID={postID}
              key={postID}
              navigation={this.props.navigation}
            />
          );
        })}
        <View style={{ width: 35 }} />
      </ScrollView>
    );
  }
}

export default class MeSavedPostsScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "My Saved Offers",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };
  state = {
    wishlistPostIDs: [],
    likedPostIDs: [],
    editWishlist: false,
    loadingWishlist: true,
    loadingLiked: true
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore1 = null;
    this.unsubscribeFirestore2 = null;
  }

  async componentDidMount() {
    try {
      if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 200); });
      if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 500); });
      if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 2000); });
      if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 4000); });
      if (!global.myUID) await global.setGlobals();

      this.unsubscribeFirestore1 = await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .collection("Refs")
        .doc("wishlist")
        .onSnapshot(async s => {
          //let connected = await NetInfo.isConnected;
          this.updateWishlist(s);
        });
      this.unsubscribeFirestore2 = await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .collection("Refs")
        .doc("likedPosts")
        .onSnapshot(async s => {
          //let connected = await NetInfo.isConnected;
          this.updateLikedPosts(s);
        });
      // global.loadingSuccessMePosts = true;
    } catch (err) {
      // global.loadingSuccessMePosts = false;
      global.warn(err, "MeSavedPostsA1")
    }
  }

  // componentWillUpdate() {
  //   if (global.loadingSuccessMePosts == false) {
  //     console.log(
  //       "Messages Screen Update: loadingSuccessChatMain was false before, now trying to reload"
  //     );
  //     this.componentDidMount();
  //   }
  // }

  componentWillUnmount() {
    this.unsubscribeFirestore1();
    this.unsubscribeFirestore2();
  }

  updateWishlist = async wishlistSnapshot => {
    try {
      let ids = wishlistSnapshot.data().postIDs;
      this.setState({ wishlistPostIDs: ids, loadingWishlist: false });
    } catch (err) {
      console.log("Error updating posts (probably network)");
    }
  };
  updateLikedPosts = async likedPostsSnapshot => {
    try {
      let ids = likedPostsSnapshot.data().postIDs;
      this.setState({ likedPostIDs: ids, loadingLiked: false });
    } catch (err) {
      console.log("Error updating posts (probably network)");
    }
  };

  toggleWishlistEdit = () => {
    if (this.state.editWishlist) {
      this.setState({ editWishlist: false });
    } else {
      this.setState({ editWishlist: true });
    }
  };

  removePostFromWishlist = async postID => {
    Alert.alert(
      "Remove this offer from your wishlist permanently?",
      "",
      [
        {
          text: "Cancel",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel"
        },
        {
          text: "OK",
          onPress: () => {
            console.log("OK Pressed");
            this.executeRemovePostFromWishlist(postID);
          }
        }
      ],
      { cancelable: false }
    );
  };
  executeRemovePostFromWishlist = async postID => {
    try {
      await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .collection("Refs")
        .doc("wishlist")
        .update({ postIDs: firebase.firestore.FieldValue.arrayRemove(postID) });
      global.myWishlist = global.myWishlist.filter(item => {
        return item != postID;
      });
      console.log("Removed from Wishlist");
    } catch (error) {
      console.log(error);
      alert("Request failed. Try again");
    }
  };

  // sortablePostIDs = [];
  // lastUpdated = {};
  // reorder = (id, timestamp) => {
  //   this.lastUpdated[id] = timestamp;
  //   this.sortablePostIDs.sort((a, b) => {
  //     if (!a in this.lastUpdated) {
  //       this.lastUpdated[a] = 0;
  //     }
  //     if (!b in this.lastUpdated) {
  //       this.lastUpdated[b] = 0;
  //     }
  //     return this.lastUpdated[b] - this.lastUpdated[a];
  //   });
  //   this.setState({
  //     postIDs: this.sortablePostIDs
  //   });
  // };

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#111" }}>
        <StatusBar barStyle="light-content" />

        <View
          style={{
            backgroundColor: "#111",
            flex: 1,
            paddingHorizontal:
              Dimensions.get("window").height > 800 && Platform.OS === "ios"
                ? 5
                : 12
          }}
        >
          <View
            style={{
              backgroundColor: "#111",
              borderBottomColor: "#555555",
              borderBottomWidth: 1,
              ...Platform.select({
                ios: {
                  height: 50
                },
                android: {
                  height: 75
                }
              })
            }}
          />
          {/* <SearchBox /> */}
          <View style={{ marginVertical: 5 }}>
            {true && (
              <View
                style={{
                  paddingTop: 15
                }}
              >
                <TouchableOpacity
                  onPress={this.toggleWishlistEdit}
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 15
                  }}
                >
                  <Text
                    style={{
                      color: "#ccc",
                      fontSize: 14,
                      marginRight: 8
                    }}
                  >
                    MY WISHLIST
                  </Text>
                  <Image
                    source={require("../images/icons/Change.png")}
                    style={{ height: 20, width: 20 }}
                  />
                </TouchableOpacity>
                {/* <TouchableOpacity onPress={()=>this.toggleWishlistEdit()}>
                <Image
                source={require("../images/icons/Change.png")}
                style={{height:18, width:18, paddingLeft:8}}
                /></TouchableOpacity> */}

                {!this.state.loadingWishlist ? (
                  <WishlistPosts
                    postIDs={this.state.wishlistPostIDs}
                    edit={this.state.editWishlist}
                    navigation={this.props.navigation}
                    onRemove={postID => {
                      this.removePostFromWishlist(postID);
                    }}
                  />
                ) : (
                    <ActivityIndicator
                      size="small"
                      color="#888"
                      style={{
                        opacity: 1
                      }}
                      animating={true}
                    />
                  )}
              </View>
            )}
          </View>

          <View
            style={{
              height: 27,
              borderBottomColor: "#555555",
              borderBottomWidth: 1
            }}
          />

          <View style={{ marginVertical: 5, marginTop: 5 }}>
            {this.state.likedPostIDs != [] && (
              <View style={{ paddingTop: 15 }}>
                <Text
                  style={{
                    color: "#ccc",
                    fontSize: 14,
                    marginBottom: 16
                  }}
                >
                  {global.a.offers ? "My liked " + global.a.offers : "MY LIKED OFFERS"}
                </Text>
                {!this.state.loadingLiked ? (
                  <LikedPosts
                    postIDs={this.state.likedPostIDs}
                    navigation={this.props.navigation}
                  />
                ) : (
                    <ActivityIndicator
                      size="small"
                      color="#888"
                      style={{
                        opacity: 1
                      }}
                      animating={true}
                    />
                  )}
              </View>
            )}
          </View>

          <View
            style={{
              height: 27,
              borderBottomColor: "#555555",
              borderBottomWidth: 1
            }}
          />
        </View>
      </SafeAreaView>
    );
  }
}

import React, { Component } from 'react';
import {
    Text,
    View,
    Button,
    Image,
    TextInput,
    TouchableWithoutFeedback,
    SafeAreaView,
    TouchableOpacity,
    Keyboard,
    Modal,
    SectionList,
    Linking,
    ActivityIndicator
} from "react-native";

import firebase from "firebase";
import "firebase/firestore";

import googlePlay from '../images/images/googlePlay.png'
import appStore from '../images/images/APPSTORE.png'

export default class Header extends Component {
    static navigationOptions = {
        tabBarVisible: false
    };
    state = {
        profilePicture: global.myProfilePictureURL || '',
        name: global.myName || ''
    };

    componentDidMount = async () => {

        this.parseURLandReturnValues()
        this.joinInitialCommunity()
        this.setState({
            profilePicture: global.myProfilePictureURL,
            name: global.myName
        })
        while (!global.myName) {
            await global.timeout(1000)

        }
        this.setState({
            profilePicture: global.myProfilePictureURL,
            name: global.myName
        })
    }

    signout = async () => {
        try {

            const key = "aID" + global.myUID.substring(4, 10)
            const bufferKey = "aIDbuffer" + global.myUID.substring(4, 10)
            const userInfoKey = "aIDUserInfo" + global.myUID.substring(4, 10)
            global.myUID = null;
            global.idToken = null;
            global.myProfileID = null;
            global.globalsSet = false
            global.myName = null;
            global.myBusinessName = null;
            global.myTimeCreated = null;
            global.myLocationWork = null;
            global.myLocationWorkAddress = "";
            global.myProfilePictureURL = null;
            global.myServicesOffered = [];
            global.myJobQualifications = [];
            global.myJobQualificationsStatus = "";
            global.myNetworks = [];
            global.myPostIDs = [];
            global.myFollowingIDs = [];
            global.myFollowerIDs = [];
            global.myPushToken = null;
            global.messageNotifications = {};
            global.jobNotifications = {};
            global.messageOpen = "";
            global.myBlacklist = [];
            global.myBlocklist = [];
            global.myBlackedlist = [];
            global.myBlockedlist = [];
            global.loadingSuccessChatMain = null; //needed?
            global.amApprover = false;
            global.walletType = null;
            //global.coinKeySet = null;
            global.coinSignKey = null;
            global.coinSignKeySet = null;
            global.myLocalCurrency = null;
            global.hasNoWebsiteYet = null;
            global.walletBalance = null;
            global.walletAmountInEscrowByYou = null;
            global.walletAmountInEscrowForYou = null;
            global.coinBalance = null;
            global.coinAmountInEscrowByYou = null;
            global.coinAmountInEscrowForYou = null;
            global.coinResetAfterBlock = null;
            global.coinLastResetDate = null;
            global.blockedUntil = null;
            global.localResultsList = null;
            global.localResultsDate = null;
            global.networks = null;
            global.networkRequests = null;
            global.networkAdminRights = null;
            global.globalsSet = false;
            global.myHealthFileID = null;
            global.amDoctor = null;
            global.myHealthProfileID = null;
            global.healthChatNotifications = { patient: {}, doctor: {} }
            global.myDiscounts = []

            await localStorage.clear();
            await global.asyncDeleteAllContainers();
            await AsyncStorage.setItem("loanFormLast", "")
            await AsyncStorage.setItem("localResultsString", "")
            await AsyncStorage.setItem("localResultsDateString", "")
            await AsyncStorage.removeItem("hideBOGuide-chat")
            await AsyncStorage.removeItem("hideBOGuide-location")
            await AsyncStorage.removeItem("hideBOGuide-newFeatures16")
            await AsyncStorage.removeItem("hideBOGuide-hello")
            await AsyncStorage.removeItem("discussionFeed")
            await AsyncStorage.removeItem(key)
            await AsyncStorage.removeItem(bufferKey)
            await AsyncStorage.removeItem(userInfoKey)
            await AsyncStorage.removeItem("RECOMMENDATIONS")
            await AsyncStorage.removeItem("lastViewedPostIDs")
            await AsyncStorage.clear()
        } catch (err) {
            console.log(err)
        }
        firebase.auth().signOut();
        //this.props.navigation.navigate('welcome')
        // window.location.replace('http://localhost:19006')
    }

    parseURLandReturnValues() {
        let COMMUNITY = ''
        let fullURL = window.location.href.split('#')[0]
        fullURL = window.location.href;
        const fullURLsubStrings = fullURL.split("?")[1];
        if (!fullURLsubStrings) return;
        //OPTIONS = fullURLsubStrings.split("&&");  
        COMMUNITY = fullURLsubStrings.split("community=")[1];
        if (!COMMUNITY) return;
        global.initialCommunityID = COMMUNITY
        // this.setState({ communityID: COMMUNITY })
        //alert(`You're joining ${global.initialCommunityID} community`);

    };
    joinInitialCommunity = async () => {

        //this.setState({ aaJoin: true })
        const communityID = global.initialCommunityID
        //alert(`communityID...${communityID}`)
        if (!communityID) {
            return
        }
        try {
            while (!Array.isArray(global.myCommunities)) {
                await global.timeout(100)
            }
            if (global.myCommunities.includes(communityID)) {
                // await global.timeout(3000)
                //this.props.navigation.navigate({ routeName: "homeCommunity", params: { communityID }, key: communityID })
                this.props.nav("homeCommunity", { communityID }, communityID)
                this.props.onPress("Communities")
                return
            }
            //const communityID = this.community.xID
            //alert('joinInitialCommunity working...')
            await global.fetchIdToken()
            const r = await fetch(global.cloudFunctionURL + "joinCommunity", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    communityID,
                    action: 'join',
                    myUID: global.myUID,
                    idToken: global.idToken
                })
            });
            //this.profileSearchTerm = term
            // let route = "meCommunity"
            // if (this.props.navigation.state.routeName.startsWith("home")) route = "homeCommunity"
            // if (this.props.navigation.state.routeName.startsWith("chat")) route = "chatCommunity"
            const r2 = await r.json();
            await global.timeout(1500)
            this.setState({ aaJoin: false, showJoinModal: false })
            await global.timeout(50)
            //this.props.navigation.goBack()
            if (r2.msg == 'SUCCESS') {
                await global.timeout(2000)
                //this.props.navigation.navigate({ routeName: "homeCommunities", params: { communityID }, key: communityID })
                this.props.nav("homeCommunity", { communityID }, communityID)
                this.props.onPress("Communities")
            } else {
                alert('an error occured. Search for the community by name')
            }
        }
        catch (error) {
            global.warn(error, 'joinCom')
            alert('Something went wrong. Try again in joinInitialCommunity')
            this.setState({ aaJoin: false })
        }
    }

    render() {
        return (
            <View
                style={{
                    flexDirection: "row",
                    height: 55,
                    width: '100%',
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottomWidth: 1,
                    borderColor: "#333",
                    paddingTop: 0,
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        height: 55,
                        alignItems: "center",
                        borderColor: "#333",
                        paddingTop: 0,
                    }}>
                    <Image
                        style={{
                            width: 30,
                            height: 30,
                            borderRadius: 15,
                            marginRight: 6,
                            marginLeft: 15
                        }}
                        source={require('../images/logos/home_logo.png')}
                    />
                    <View style={{ alignItems: 'flex-end', justifyContent: 'flex-end', marginRight: 10 }}>
                        <Text style={{ color: "#A8CF45", }}>Waknda Community</Text><Text style={{ fontSize: 10, color: '#646464' }}>beta release 1.0</Text>
                    </View>
                    <div style={{ padding: 5, justifyContent: 'space-around', marginRight: 10 }}>
                        <a href="https://play.google.com/store/apps/details?id=com.african_founders.Wakanda" target='_blank'><img src={googlePlay} width='100' alt='google play' /></a>
                        <a href="https://apps.apple.com/ng/app/wakanda-market/id1451309384" target='_blank'>&nbsp;<img src={appStore} width='100' alt='appstore' /></a>
                    </div>
                    <TouchableOpacity onPress={() => {
                        this.props.onPress("Feed")
                        this.props.nav("homeCommunities", { viewSelection: "Feed" }, "Feed")
                    }}>
                        <View
                            style={{
                                flexDirection: "row",
                                height: 32,
                                width: 160,
                                margin: 2,
                                borderRadius: 14,
                                borderColor: "#A8CF45",
                                borderWidth: 0,
                                backgroundColor: this.props.view == "Feed" ? "#A8CF45" : "#000",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Image
                                style={{
                                    width: 14,
                                    height: 14,
                                    marginRight: 6,
                                    tintColor: this.props.view !== "Feed" ? "#A8CF45" : "#000",
                                }}
                                source={require("../images/tabNavigator/Home.png")}
                            />
                            <Text
                                style={{
                                    color: this.props.view == "Feed" ? "#000" : "#A8CF45", fontWeight: "bold"
                                }}
                            >
                                Home
                            </Text>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => {
                        this.props.onPress("Communities")
                        this.props.nav("homeCommunities", { viewSelection: "Communities" }, "Communities")
                    }}>
                        <View
                            style={{
                                flexDirection: "row",
                                height: 32,
                                width: 160,
                                margin: 2,
                                borderRadius: 14,
                                backgroundColor: this.props.view == "Communities" ? "#A8CF45" : "#000",
                                borderColor: "#A8CF45",
                                borderWidth: 0,
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Image
                                style={{
                                    height: 14 * 2.6 / 2.9,
                                    width: 14 * 3.7 / 2.9,
                                    marginRight: 6,
                                    tintColor: this.props.view == "Communities" ? "#000" : "#A8CF45",
                                }}
                                source={require("../images/tabNavigator/Community.png")}
                            />
                            <Text
                                style={{
                                    color: this.props.view == "Communities" ? "#000" : "#A8CF45", fontWeight: "bold"
                                }}
                            >
                                Communities
                        </Text>
                        </View>
                    </TouchableOpacity>
                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>

                        {this.state.name ? <Text style={{ color: "#A8CF45", fontSize: 12, marginRight: 5, fontWeight: 'bold' }}>
                            {this.state.name}</Text> : <Text style={{ color: "#A8CF45", fontSize: 9, marginRight: 5 }}> ...</Text>}

                        {this.state.profilePicture ? (
                            <Image
                                style={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: 15,
                                    marginRight: 6
                                }}
                                source={{ uri: this.state.profilePicture }}
                            />
                        ) : (
                            <View
                                style={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: 15,
                                    backgroundColor: "#646464",
                                    marginRight: 6
                                }}
                            />
                        )}
                    </View>
                    <TouchableOpacity onPress={this.signout}>
                        <View
                            style={{
                                height: 30,
                                width: 140,
                                margin: 5,
                                borderTopLeftRadius: 20,
                                borderBottomLeftRadius: 20,
                                backgroundColor: "#131313",
                                borderColor: "#aaa",
                                borderWidth: 1,
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Text
                                style={{
                                    color: "#fff",
                                    fontSize: 12
                                }}
                            >
                                Log Out
                            </Text>
                        </View>
                    </TouchableOpacity>
                </View>

            </View>
        );
    }
}
import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  ScrollView,
  SectionList,
  SafeAreaView,
  Keyboard,
  ActivityIndicator,
  Dimensions,
  Platform,
  Image,
  Alert,
  Modal
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";

import GetPicture from "../../../shared/Camera/GetPicture";
const gStyles = global.gStyles //    "../../../styles/gStyles";


export default class ProfileUpdateScreen extends Component { //class MeProfileUpdateScreen
  static navigationOptions = {
    title: "",
    headerTransparent: true,
    headerTintColor: "red",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "red",
      shadowOpacity: 1
    }
  };
  state = {
    profileName: "*********",
    profileJobTitle: "***************",
    profileDescription: "",
    profileCenterName: "",
    profilePictureURL: "",
    keyboardSpacerHeight: 56,
    activityIndicatorAnimating: false,
    modalActivityIndicatorAnimating: false,
    showGetPicture: false,
    modalVisible: false,
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {

    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 200); });
    if (!global.myUID || !global.myHealthProfileID) await new Promise(resolve => { setTimeout(resolve, 500); });
    if (!global.myUID || !global.myHealthProfileID) await new Promise(resolve => { setTimeout(resolve, 1000); });
    if (!global.myUID || !global.myHealthProfileID) await new Promise(resolve => { setTimeout(resolve, 3000); });
    if (!global.myUID || !global.myHealthProfileID) return alert("No connection - try again")

    try {
      const pDoc = await firebase
        .firestore()
        .collection("Health")
        .doc("data")
        .collection("HealthProfiles")
        .doc(global.myHealthProfileID)
        .get();
      const p = await pDoc.data();

      this.setState({
        loading: false,
        profileName: p.profileName || "",
        profileCenterName: p.profileCenterName || "",
        profileJobTitle: p.profileJobTitle || "",
        profilePictureURL: p.profilePictureURL || "",
        profileDescription: p.profileDescription || ""
      });



      this.keyboardDidShowListener = Keyboard.addListener(
        "keyboardDidShow",
        this._keyboardDidShow
      );
      this.keyboardDidHideListener = Keyboard.addListener(
        "keyboardDidHide",
        this._keyboardDidHide
      );

      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Health")
        .doc("data")
        .collection("HealthProfiles")
        .doc(global.myHealthProfileID)
        .onSnapshot(this.updateProfile);
    } catch (e) {
      global.warn(e,"eeee")
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
    if (this.keyboardDidShowListener) this.keyboardDidShowListener.remove();
    if (this.keyboardDidHideListener) this.keyboardDidHideListener.remove();
  }

  _keyboardDidShow = async e => {
    if (Platform.OS === "ios") {
      this.setState({ keyboardSpacerHeight: e.endCoordinates.height - 45 });
    } else {
      if (Platform.Version <= 19) {
        this.setState({ keyboardSpacerHeight: e.endCoordinates.height - 10 });
      }
    }
    //this.scrollView.scrollToEnd({ animated: false });
    //this.state.chatMessages.push({text: "Hello", author: 1, timeSent: 1})
  };

  _keyboardDidHide = () => {
    this.setState({ keyboardSpacerHeight: 56 });
  };

  updateProfile = pDoc => {
    if (!pDoc) return
    const p = pDoc.data();
    if (!p) return
    this.setState({ profilePictureURL: p.profilePictureURL, });
  };


  callSet = async () => {
    if (this.state.activityIndicatorAnimating) return;
    if (!this.state.profileName) return alert("You need to enter your name")
    if (!this.state.profileJobTitle) return alert("You need to specify your title")

    try {
      this.setState({ activityIndicatorAnimating: true });

      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "updateHealthProfile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "update",
          data: {
            profileName: this.state.profileName,
            profileCenterName: this.state.profileCenterName,
            profileJobTitle: this.state.profileJobTitle,
            profilePictureURL: this.state.profilePictureURL,
            profileDescription: this.state.profileDescription
          },
          myUID: global.myUID,
          idToken: global.idToken,
        })
      });
      const r2 = await r.json();

      this.setState({ activityIndicatorAnimating: false });
      global.myHealthPictureURL = this.state.profilePictureURL || ""
      if (r2.msg === "SUCCESS") {
        await global.timeout(500)
        this.props.navigation.goBack();
      }
    } catch (err) {
      alert("Error. Please check your internet connection");
      console.log(err);
      this.setState({ activityIndicatorAnimating: false });
    }
  };


  render() {
    if (this.state.loading) return (
      <View style={{ height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: "#fff" }}>
        <ActivityIndicator size="small" color="red" />
      </View>
    );
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <ScrollView
          ref={ref => (this.scrollView = ref)}
          style={{ backgroundColor: "#fff", flex: 1 }}
        >
          {/***** Background Image *****/}
          <View
            style={{
              height: 180,
              justifyContent: "flex-start",
              backgroundColor: "transparent",
              alignItems: "center",
              marginBottom: 0
              //shadowOpacity: 0,
              //shadowOffset: { width: 0, height: 1 },
              //shadowColor: "white"
            }}
          >
            {!!this.state.backgroundPicture && (
              <Image
                key={"B"}
                style={{ width: Dimensions.get("window").width, height: 180 }}
                source={{ uri: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2Fhealth-2082630_640.jpg?alt=media&token=690fea2e-7f71-4c7d-ad00-1dca0df90859" }}
              />
            )}
          </View>
          <View
            style={{
              ...Platform.select({
                ios: {
                  height: 0,
                  marginBottom: -100
                },
                android: {
                  height: 85,
                  backgroundColor: "rgba(44, 44, 44, 0.3)",
                  marginTop: -180,
                  marginBottom: -5
                }
              })
            }}
          />
          <View
            style={{
              alignItems: "flex-end",
              marginBottom: 30,
              marginRight: 10
            }}
          >
            <View style={{ padding: 3 }}>
              <Text style={{ color: "black" }}></Text>
            </View>
          </View>

          {/***** Profile Image *****/}
          <View
            style={{
              height: 102,
              alignItems: "center"
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: 102,
                height: 102,
                borderRadius: 32,
                backgroundColor: "#FFFFFF",
                shadowOpacity: 1,
                shadowColor: "white",
                shadowOffset: { height: 0, width: 0 },
                shadowRadius: 32
              }}
            >
              {!!this.state.profilePictureURL ? <Image
                key={this.state.profilePictureURL}
                style={{
                  width: 100,
                  height: 100,
                  borderRadius: 32
                }}
                source={{
                  uri: this.state.profilePictureURL
                }}
              />
                : <View
                  key={"NoPic"}
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 32,
                    backgroundColor: "gray"
                  }}
                />}
            </View>
          </View>
          <View
            style={{ alignItems: "center", marginTop: -35, marginBottom: 20 }}
          >
            {this.state.showGetPicture && (
              <GetPicture
                onDone={async pictureURL => {
                  this.setState({ showGetPicture: false });
                  console.log("PictureURL", pictureURL);
                }}
                item={"HEALTH_PROFILE_PICTURE"}
              />
            )}
            <TouchableOpacity
              onPress={() => {
                this.setState({ showGetPicture: true });
              }}
              style={{
                backgroundColor: "rgba(220, 220, 220, 0.8)",
                padding: 3,
                borderRadius: 5
              }}
            >
              <Text style={{ color: "black" }}>Change</Text>
            </TouchableOpacity>
          </View>

          {/***** Name *****/}
          <View
            style={{
              alignItems: "flex-start",
              marginHorizontal: 20,
              flex: 1,
              alignItems: "stretch"
            }}
          >

            <Text
              style={{
                fontSize: 14,
                color: "red",
                paddingBottom: 10,
                borderBottomColor: "#FFFFFF",
                borderBottomWidth: 0,
                marginTop: 20,
                justifyContent: "flex-start"
              }}
            >
              My name:
            </Text>
            <TextInput
              onChangeText={profileName => {
                this.setState({ profileName });
              }}
              onFocus={() => {
                this.scrollView.scrollTo({ x: 0, y: 200, animated: true });
              }}
              style={{
                marginBottom: 5,
                color: "#ffffff",
                borderColor: "grey",
                borderWidth: 0,
                paddingLeft: 3,
                borderRadius: 5,
                height: 36,
                backgroundColor: "#daa"
              }}
              underlineColorAndroid="transparent"
              maxLength={32}
              defaultValue={this.state.profileName}
              keyboardAppearance={"dark"}
              returnKeyType={"done"}
              bordered
            />
          </View>

          <View>
            <Text />
            <View
              style={{
                //alignItems: "flex-start",
                marginHorizontal: 20,
                flex: 1,
                alignItems: "stretch"
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  color: "red",
                  paddingBottom: 10,
                  borderBottomColor: "#FFFFFF",
                  borderBottomWidth: 0,
                  marginTop: 20,
                  justifyContent: "flex-start"
                }}
              >
                My job title:
                </Text>
              <TextInput
                onChangeText={profileJobTitle => {
                  this.setState({ profileJobTitle });
                }}
                onFocus={() => {
                  this.scrollView.scrollTo({ x: 0, y: 320, animated: true });
                }}
                style={{
                  marginBottom: 20,
                  color: "#ffffff",
                  borderColor: "grey",
                  borderWidth: 0,
                  paddingLeft: 3,
                  borderRadius: 5,
                  height: 36,
                  backgroundColor: "#daa"
                }}
                underlineColorAndroid="transparent"
                maxLength={40}
                defaultValue={this.state.profileJobTitle}
                keyboardAppearance={"dark"}
                returnKeyType={"done"}
                bordered
              />
            </View>

            <View
              style={{
                //alignItems: "flex-start",
                marginHorizontal: 20,
                flex: 1,
                alignItems: "stretch"
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  color: "red",
                  paddingBottom: 10,
                  borderBottomColor: "#FFFFFF",
                  borderBottomWidth: 0,
                  marginTop: 20,
                  justifyContent: "flex-start"
                }}
              >
                My health center (optional):
                </Text>
              <TextInput
                onChangeText={profileCenterName => {
                  this.setState({ profileCenterName });
                }}
                onFocus={() => {
                  this.scrollView.scrollTo({ x: 0, y: 420, animated: true });
                }}
                style={{
                  marginBottom: 20,
                  color: "#ffffff",
                  borderColor: "grey",
                  borderWidth: 0,
                  paddingLeft: 3,
                  borderRadius: 5,
                  height: 36,
                  backgroundColor: "#daa"
                }}
                underlineColorAndroid="transparent"
                maxLength={40}
                defaultValue={this.state.profileCenterName}
                keyboardAppearance={"dark"}
                returnKeyType={"done"}
                bordered
              />
            </View>

            {/***** Description *****/}
            <View
              style={{
                marginHorizontal: 20,
                flex: 1,
                alignItems: "stretch"
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  color: "red",
                  paddingBottom: 10,
                  marginTop: 20,
                  justifyContent: "flex-start"
                }}
              >
                About me / my practice (optional):
                </Text>
              <TextInput
                onChangeText={profileDescription => {
                  this.setState({ profileDescription });
                }}
                onFocus={() => {
                  this.scrollView.scrollTo({ x: 0, y: 540, animated: true });
                }}
                multiline={true}
                numberOfLines={6}
                style={{
                  marginBottom: 20,
                  color: "#ffffff",
                  borderColor: "grey",
                  borderWidth: 0,
                  paddingLeft: 3,
                  borderRadius: 5,
                  height: 180,
                  backgroundColor: "#daa"
                }}
                maxLength={400}
                underlineColorAndroid="transparent"
                defaultValue={this.state.profileDescription}
                rowSpan={5}
                keyboardAppearance={"dark"}
                returnKeyType={"done"}
                bordered
              />
            </View>
            <View style={{ height: 150 }} />
          </View>

        </ScrollView>
        <View
          style={{
            marginLeft: 20,
            marginRight: 20,
            marginTop: 10,
            marginBottom: 10,
            height: this.state.keyboardSpacerHeight
          }}
        >
          <TouchableOpacity style={[global.gStyles.button, { backgroundColor: "red" }]} onPress={this.callSet}>
            <View style={{ flexDirection: "row" }}>
              {!!this.state.activityIndicatorAnimating ? (
                <ActivityIndicator
                  size="small"
                  color="#fff"
                  style={{ marginRight: 20 }}
                />
              ) : (
                  <View style={{ width: 40, height: 20 }} />
                )}
              <Text style={[global.gStyles.buttontext, { color: "white" }]}>UPDATE PROFILE</Text>
              <View style={{ width: 44 }} />
            </View>
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    );
  }
}

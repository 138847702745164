import React from 'react';
import {
  Platform, Linking, Share, Text,
  View, Image, TouchableOpacity, Dimensions, Modal
} from "react-native";
import { WebView } from "react-native-webview"

import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Notifications from 'expo-notifications'
import * as Permissions from "expo-permissions";
import * as Location from "expo-location";
import Constants from "expo-constants";
import * as IntentLauncher from 'expo-intent-launcher';
import firebase from "firebase";
import "firebase/firestore";

import Face from "../Me/Communities/Face"

export default function initiateGlobalFunctions(myThis) {
  global.timeout = async (ms) => {
    return await new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  global.stampToDate = (stamp) => {
    if (!stamp) return new Date(1970, 0, 1);
    let date;
    if (stamp.toDate && typeof stamp.toDate == "function") date = stamp.toDate()
    else {
      date = new Date(1970, 0, 1)
      if (stamp.seconds) date.setSeconds(stamp.seconds);
      else if (stamp._seconds) date.setSeconds(stamp._seconds);
    }
    return date;
  }

  global.warn = (error, eTxt) => {
    if (!global.devMode && global.reportError) global.reportError(error, "warn", eTxt)
    console.warn("Error WM - " + eTxt)
    console.log(eTxt, error)
  }

  global.setConstants = () => {
    const aSpeakers = {
      appName: "Speakers Connect",
      appName2: "Speakers Connect",
      appNameShort: "Speakers Connect",
      appNameVeryShort: "this app",
      browseOffers: "Browse Samples",
      findProviders: "List",
      specialRequest: "Inquire",
      selectACategory: "Select a category (Primary, Secondary, Tertiary)",
      offers: "Samples / Offers",
      initialAction: "SPECIAL",
      defaultCategory1: "Professionals",
      defaultCategory2: "Public Speakers",

      doYouSellString: "Are you a professional speaker?",
      becomeAProviderString: "Register on Speakers Connect now!",
    };
    const aEducation = {
      appName: "Wakanda Education",
      appName2: "Wakanda",
      appNameShort: "Wakanda Education",
      appNameVeryShort: "Wakanda",
      browseOffers: "Browse",
      findProviders: "List",
      specialRequest: "Inquiry",
      selectACategory: "Select a category (Primary, Secondary, Tertiary)",
      initialAction: "SPECIAL",
      defaultCategory1: "Professionals",

      doYouSellString: "Are you an education professional?",
      becomeAProviderString: "Offer your services on Wakanda Education now.",
    };
    const aArtisan = {
      appName: "The Arisan Network",
      appName2: "The Arisan Network",
      appNameShort: "Arisan Network",
      appNameVeryShort: "this app",
      browseOffers: "Browse Offers",
      findProviders: "Find Worker",
      specialRequest: "Request",
      selectACategory: "Select a category (e.g. AC, Electrical, Plumbing)",
      offers: "Offers / Work Samples",
      initialAction: "SPECIAL",
      defaultCategory1: "Handyman",

      doYouSellString: "Are you an artisan?",
      becomeAProviderString: "Offer your services on The Artisan Network now.",
    };
    const aMarket = {
      appName: "Wakanda", // "Wakanda Market",
      appName2: "Wakanda",
      appNameShort: "Wakanda", // "Wakanda Market",
      appNameVeryShort: "Wakanda",
      initialAction: "BROWSE",
      browseOffers: "Browse Offers",
      findProviders: "Find Providers",
      specialRequest: "Special Request",
      selectACategory:
        "Select a category (e.g. Electronics, Food, Professionals)",
    };
    if (global.app == "speakers") global.a = aSpeakers;
    if (global.app == "education") global.a = aEducation;
    if (global.app == "artisan") global.a = aArtisan;
    if (global.app == "market") global.a = aMarket;

    // following is a copy from signinScreen
    global.termsConditionsLink =
      "https://www.african-founders.com/terms-and-conditions";
    if (global.app == "education")
      global.termsConditionsLink =
        "https://www.african-founders.com/wakanda-education-t-and-c";
    global.privacyPolicyLink =
      "https://www.african-founders.com/terms-and-conditions";
    if (global.app == "education")
      global.privacyPolicyLink =
        "https://www.african-founders.com/wakanda-education-privacy-policy";

    global.currencySymbols = {
      GHS: "₵",
      NGN: "₦",
      ACN: "₳",
    };

    global.dayStrings = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    global.monthStrings = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    global.countryCodes = [
      "Nigeria (+234)",
      "Ghana (+233)",
      "Afghanistan (+93)",
      "Albania (+355)",
      "Algeria (+213)",
      "American Samoa (+1-684)",
      "Andorra (+376)",
      "Angola (+244)",
      "Anguilla (+1-264)",
      "Antarctica (+672)",
      "Antigua and Barbuda (+1-268)",
      "Argentina (+54)",
      "Armenia (+374)",
      "Aruba (+297)",
      "Australia (+61)",
      "Austria (+43)",
      "Azerbaijan (+994)",
      "Bahamas (+1-242)",
      "Bahrain (+973)",
      "Bangladesh (+880)",
      "Barbados (+1-246)",
      "Belarus (+375)",
      "Belgium (+32)",
      "Belize (+501)",
      "Benin (+229)",
      "Bermuda (+1-441)",
      "Bhutan (+975)",
      "Bolivia (+591)",
      "Bosnia and Herzegovina (+387)",
      "Botswana (+267)",
      "Brazil (+55)",
      "British Indian Ocean Territory (+246)",
      "British Virgin Islands (+1-284)",
      "Brunei (+673)",
      "Bulgaria (+359)",
      "Burkina Faso (+226)",
      "Burundi (+257)",
      "Cambodia (+855)",
      "Cameroon (+237)",
      "Canada (+1)",
      "Cape Verde (+238)",
      "Cayman Islands (+1-345)",
      "Central African Republic (+236)",
      "Chad (+235)",
      "Chile (+56)",
      "China (+86)",
      "Christmas Island (+61)",
      "Cocos Islands (+61)",
      "Colombia (+57)",
      "Comoros (+269)",
      "Cook Islands (+682)",
      "Costa Rica (+506)",
      "Croatia (+385)",
      "Cuba (+53)",
      "Curacao (+599)",
      "Cyprus (+357)",
      "Czech Republic (+420)",
      "Democratic Republic of the Congo (+243)",
      "Denmark (+45)",
      "Djibouti (+253)",
      "Dominica (+1-767)",
      "Dominican Republic (+1-809)",
      "Dominican Republic (+1-829)",
      "Dominican Republic (+1-849)",
      "East Timor (+670)",
      "Ecuador (+593)",
      "Egypt (+20)",
      "El Salvador (+503)",
      "Equatorial Guinea (+240)",
      "Eritrea (+291)",
      "Estonia (+372)",
      "Ethiopia (+251)",
      "Falkland Islands (+500)",
      "Faroe Islands (+298)",
      "Fiji (+679)",
      "Finland (+358)",
      "France (+33)",
      "French Polynesia (+689)",
      "Gabon (+241)",
      "Gambia (+220)",
      "Georgia (+995)",
      "Germany (+49)",
      "Ghana (+233)",
      "Gibraltar (+350)",
      "Greece (+30)",
      "Greenland (+299)",
      "Grenada (+1-473)",
      "Guam (+1-671)",
      "Guatemala (+502)",
      "Guernsey (+44-1481)",
      "Guinea (+224)",
      "Guinea-Bissau (+245)",
      "Guyana (+592)",
      "Haiti (+509)",
      "Honduras (+504)",
      "Hong Kong (+852)",
      "Hungary (+36)",
      "Iceland (+354)",
      "India (+91)",
      "Indonesia (+62)",
      "Iran (+98)",
      "Iraq (+964)",
      "Ireland (+353)",
      "Isle of Man (+44-1624)",
      "Israel (+972)",
      "Italy (+39)",
      "Ivory Coast (+225)",
      "Jamaica (+1-876)",
      "Japan (+81)",
      "Jersey (+44-1534)",
      "Jordan (+962)",
      "Kazakhstan (+7)",
      "Kenya (+254)",
      "Kiribati (+686)",
      "Kosovo (+383)",
      "Kuwait (+965)",
      "Kyrgyzstan (+996)",
      "Laos (+856)",
      "Latvia (+371)",
      "Lebanon (+961)",
      "Lesotho (+266)",
      "Liberia (+231)",
      "Libya (+218)",
      "Liechtenstein (+423)",
      "Lithuania (+370)",
      "Luxembourg (+352)",
      "Macau (+853)",
      "Macedonia (+389)",
      "Madagascar (+261)",
      "Malawi (+265)",
      "Malaysia (+60)",
      "Maldives (+960)",
      "Mali (+223)",
      "Malta (+356)",
      "Marshall Islands (+692)",
      "Mauritania (+222)",
      "Mauritius (+230)",
      "Mayotte (+262)",
      "Mexico (+52)",
      "Micronesia (+691)",
      "Moldova (+373)",
      "Monaco (+377)",
      "Mongolia (+976)",
      "Montenegro (+382)",
      "Montserrat (+1-664)",
      "Morocco (+212)",
      "Mozambique (+258)",
      "Myanmar (+95)",
      "Namibia (+264)",
      "Nauru (+674)",
      "Nepal (+977)",
      "Netherlands (+31)",
      "Netherlands Antilles (+599)",
      "New Caledonia (+687)",
      "New Zealand (+64)",
      "Nicaragua (+505)",
      "Niger (+227)",
      "Nigeria (+234)",
      "Niue (+683)",
      "North Korea (+850)",
      "Northern Mariana Islands (+1-670)",
      "Norway (+47)",
      "Oman (+968)",
      "Pakistan (+92)",
      "Palau (+680)",
      "Palestine (+970)",
      "Panama (+507)",
      "Papua New Guinea (+675)",
      "Paraguay (+595)",
      "Peru (+51)",
      "Philippines (+63)",
      "Pitcairn (+64)",
      "Poland (+48)",
      "Portugal (+351)",
      "Puerto Rico (+1-787)",
      "Puerto Rico (+1-939)",
      "Qatar (+974)",
      "Republic of the Congo (+242)",
      "Reunion (+262)",
      "Romania (+40)",
      "Russia (+7)",
      "Rwanda (+250)",
      "Saint Barthelemy (+590)",
      "Saint Helena (+290)",
      "Saint Kitts and Nevis (+1-869)",
      "Saint Lucia (+1-758)",
      "Saint Martin (+590)",
      "Saint Pierre and Miquelon (+508)",
      "Saint Vincent and the Grenadines (+1-784)",
      "Samoa (+685)",
      "San Marino (+378)",
      "Sao Tome and Principe (+239)",
      "Saudi Arabia (+966)",
      "Senegal (+221)",
      "Serbia (+381)",
      "Seychelles (+248)",
      "Sierra Leone (+232)",
      "Singapore (+65)",
      "Sint Maarten (+1-721)",
      "Slovakia (+421)",
      "Slovenia (+386)",
      "Solomon Islands (+677)",
      "Somalia (+252)",
      "South Africa (+27)",
      "South Korea (+82)",
      "South Sudan (+211)",
      "Spain (+34)",
      "Sri Lanka (+94)",
      "Sudan (+249)",
      "Suriname (+597)",
      "Svalbard and Jan Mayen (+47)",
      "Swaziland (+268)",
      "Sweden (+46)",
      "Switzerland (+41)",
      "Syria (+963)",
      "Taiwan (+886)",
      "Tajikistan (+992)",
      "Tanzania (+255)",
      "Thailand (+66)",
      "Togo (+228)",
      "Tokelau (+690)",
      "Tonga (+676)",
      "Trinidad and Tobago (+1-868)",
      "Tunisia (+216)",
      "Turkey (+90)",
      "Turkmenistan (+993)",
      "Turks and Caicos Islands (+1-649)",
      "Tuvalu (+688)",
      "U.S. Virgin Islands (+1-340)",
      "Uganda (+256)",
      "Ukraine (+380)",
      "United Arab Emirates (+971)",
      "United Kingdom (+44)",
      "United States (+1)",
      "Uruguay (+598)",
      "Uzbekistan (+998)",
      "Vanuatu (+678)",
      "Vatican (+379)",
      "Venezuela (+58)",
      "Vietnam (+84)",
      "Wallis and Futuna (+681)",
      "Western Sahara (+212)",
      "Yemen (+967)",
      "Zambia (+260)",
      "Zimbabwe (+263)",
    ];

    global.categories = {
      Electronics: [
        "Phones",
        "Tablets",
        "Computers",
        "TV",
        "Cameras",
        "Sound",
        "Software",
        "Gaming",
        "ACs",
        "Fans",
        "Accessories",
        "Appliances",
      ],
      Fashion: [
        "Men´s Clothes",
        "Women´s Clothes",
        "Children's Clothes",
        "Bags",
        "Shoes",
        "Watches",
        "Jewlery",
        "Sports",
        "Luggage",
        "Clothing Accessories",
        "Wedding Wear",
        "Taylors & Fashion Design",
        "Fabrics",
        "Laundry",
      ],
      Food: [
        "Restaurants / Cafes",
        "Fresh Food",
        "Groceries",
        "Drinks",
        "Cakes & Bakery",
        "Lifestock",
        "Wholesale",
        "Agricultural Products",
        "Catering",
      ],
      Beauty: [
        "Hair",
        "Makeup",
        "Fragrances",
        "Vitamins",
        "Body & Bath",
        "Skin Care",
        "Massages",
        "Salons & Barbers",
        "Accessories",
      ],
      Home: [
        "Furniture",
        "Cleaning",
        "Drycleaning",
        "Curtains & Blinds",
        "Carpets",
        "Lights",
        "Garden & Flowers",
        "Kitchen & Dining",
        "Interior, Decoration, Doors, Floors",
        "Exterior, Windows, Tools",
        "Security & Home Technology",
        "Baby Products",
        "Sports",
        "Pets",
        "Home / Office For Sale",
        "Home / Office For Rent",
        "Short-term Rent",
        "Land & Plots",
        "Event Locations",
        "Shared Workspaces",
      ],
      Handyman: [
        "Plumbing",
        "Electrician",
        "AC",
        "Generator",
        "Appliances",
        "Painting",
        "Fumigation",
        "Doors, Knobs, Locks",
        "Curtains, Blinds",
        "Flooring",
        "Walls & Ceiling",
        "Gardening",
        "Furniture Assembly",
        "General Work",
      ],
      Health: [
        "Pharmacies",
        "Labs",
        "General Doctors / Primary Clinics",
        "Eye Doctors / Clinics",
        "Ear, Nose, Throat",
        "Dentists / Dental Clinics",
        "Nutritionsts",
        "Physiotherapists",
        "Clinical Psychologists",
        "Psychiatrists",
        "Specialty Nurses",
        "Sexual Health Specialist / Clinic",
        "Occupational Therapists",
        "Other Specialists",
        "Health Shops / Products",
        "Blood Banks",
        "Medical Instruments",
        "Gyms & Fitness Centers",
      ],
      Professionals: [
        "Legal",
        "Public Speakers",
        "Marketing",
        "Digital Marketing",
        "PR",
        "Graphics Design",
        "Writing / Content",
        "Translation",
        "Video",
        "Animation",
        "Photography",
        "Music",
        "Marketing Materials, Printing, Branding",
        "Software Development",
        "Website Development",
        "App Development",
        "Computer & IT Services",
        "General Business",
        "Facility Management",
        "Teaching - Primary",
        "Teaching - Secondary",
        "Teaching - Tertiary",
        "Training & Education",
        "Training - Soft Skills",
        "Coaching",
        "Visa & Concierge",
        "Events & Catering",
        "Wedding Planning",
        "Trade Services",
        "Financial & Investment",
        "Insurances",
        "Loans",
        "Business Consulting",
        "IT Consulting",
        "Other Services",
        "Fun / Entertainment",
      ],
      Cars: [
        "New Cars",
        "Imported Cars",
        "Locally Used Cars",
        "Used Cars with Warranty",
        "Trucks",
        "Motorbikes",
        "Spare Parts",
        "Car Insurance",
        "Car Loans",
        "Mechanics",
        "Accessories",
        "Car Wash",
        "Drivers",
        "Drivers with Cars",
        "Drivers with Motorbike",
        "Drivers with Truck",
      ],
      Repair_Refill: [
        "Airtime",
        "Fuel Supply",
        "Gas Supply",
        "Trade-Ins",
        "Phone Repair",
        "Computer Repair",
        "TV Repair / Installation",
        "Electronics Repair",
        "Appliance Repair",
        "Home Repair",
        "Other Repair",
      ],
      Travel: [
        "Hotels",
        "Accomodation & Hostels",
        "Tours",
        "Sights",
        "Flights",
        "Bus & Train Rides",
      ],
      Commercial: [
        "Machinery",
        "Construction",
        "Tools & Parts",
        "Repair & Maintenance",
        "Waste Recycling",
        "Cement, Wood, Materials",
        "Commercial Accessories",
        "Office Supply",
        "Business Centers",
        "Crowdfunding, Investments",
        "Donations, Initiatives, Sponsorship",
      ],
      Art: [
        "Books",
        "Crafts & Paintings",
        "Collectibles",
        "Movies",
        "Music",
        "Musical Instruments",
        "Concerts, Performances, Events",
        "Exhibitions, Galleries",
        "Theater, Comedy, Shows",
        "General Art",
      ],
      QuickJobs: [
        // "One-Time Jobs",
        // "Temporary Jobs",
        // "Weekend Jobs",
        "Sales and Marketing",
        "Physical Workmanship",
        "Technical, IT",
        "Creatives, Design, DJ, Entertainer",
        "Waiters, Hostesses, Representatives",
        "Teaching, Nanny, Nursing",
        "Household, Cooking, Errands",
        "Office and Admin",
        "Extra Cash",
        "Other",
        // "Full-Time Jobs",
        // "Internships"
      ],
    };
    global.categoryKeys = Object.keys(global.categories);

    global.skills = {
      PublicSpeakers: [
        "Motivation",
        "Business",
        "Family",
        "Health",
        "Wealth",
        "Entertainment",
        "Politics",
      ],
      TeachingPrimary: [
        "Arabic",
        "Basic Science",
        "Basic Technology",
        "Christian Religious Studies",
        "Civic Education",
        "Cultural & Creative Arts",
        "English Language",
        "Mathematics",
        "Hausa Language",
        "Igbo Language",
        "Information Technology (IT)",
      ],
      TeachingSecondary: [
        "English Language",
        "Mathematics",
        "Computer Studies/ICT",
        "Civic Education",
        "Accounting",
        "Store Management",
        "Office Practice",
        "Insurance",
        "Commerce",
        "Biology",
        "Chemistry",
        "Physics",
        "Agriculture",
        "Physical Education",
        "Health Education",
        "Nigerian Languages",
        "Literature in English",
        "Geography",
        "Government",
        "Christian Religious Studies",
        "Islamic Studies",
        "History",
        "Visual Arts",
        "Music",
        "French",
        "Arabic",
        "Economics",
        "Entrepreneurship",
        "Technical Drawing",
        "General Metalwork",
        "Basic Electricity",
        "Electronics",
        "Auto-Mechanics",
        "Building Construction",
        "Woodwork",
        "Home Management",
        "Food and Nutrition",
        "Clothing and Textile",
      ],
      TeachingTertiary: [
        "Agriculture",
        "Arts",
        "Biology",
        "Mathematics",
        "Physics",
        "Chemistry",
        "Economics",
        "Business Management",
        "Education",
        "Engineering",
        "Computer Science",
        "Architecture",
        "Environmental Sciences",
        "Law",
        "Medical Sciences",
        "Dentistry",
        "Nursing",
        "Pharmacy",
        "Social Sciences",
        "English and Literary Studies",
      ],
    };
    global.skillKeys = Object.keys(global.skills);

    global.jobQualifications = [
      "Driver",
      "Security Guard",
      "Gardener",
      "Cleaner / Janitor",
      "Factory Worker",
      "Construction Laborer",
      "Farm Worker",
      "Messenger",
      "Home Cook / Chef",
      "Laundry Operator",
      "Househelp",

      "Nanny",
      "Nurse",
      "Nursing Assistant / Auxiliary Nurse",
      "Teacher",
      "Artisan",
      "Student",
      "Designer",
      "Tailor",
      "Tailoring Assistant",

      "Retail Salesperson / Shop Assistant",
      "Market Seller",
      "Waiter / Waitress",
      "Fast Food Worker",
      "Bartender",

      "Business Development Officer",
      "Sales Representative",
      "Marketing Officer",
      "Finance Manager",
      "HR Manager",
      "Operations Manager",
      "Customer Service Representative",
      "Information Desk Clerk",
      "Manager",
      "Facility Manager",
      "Project Manager",
      "Secretary / Administrative Assistant",
      "Front Desk Officer",
      "Office Clerk",
      "Developer / IT Technician",
      "Engineer",
    ];
  };

  global.calculateTags = async () => {
    let sTags;
    sTags = {};

    if (global.app == "market" || global.app == "health") {
      sTags.a1 = {
        cat1: "Electronics",
        cat2: "Phones",
        tags:
          "phones iphone samsung cell_phone mobile landline android ios telephone",
      };
      sTags.a2 = {
        cat1: "Electronics",
        cat2: "Tablets",
        tags: "tablets ipads ios android",
      };
      sTags.a3 = {
        cat1: "Electronics",
        cat2: "Computers",
        tags: "computers laptops notebook notebooks desktops pcs",
      };
      sTags.a4 = { cat1: "Electronics", cat2: "TV", tags: "TVs tv" };
      sTags.a5 = {
        cat1: "Electronics",
        cat2: "Cameras",
        tags: "Cameras photos",
      };
      sTags.a6 = {
        cat1: "Electronics",
        cat2: "Sound",
        tags: "sounds speakers hifi surround headphones",
      };
      sTags.a7 = {
        cat1: "Electronics",
        cat2: "Software",
        tags: "Software programs windows office macos photoshop",
      };
      sTags.a8 = {
        cat1: "Electronics",
        cat2: "Gaming",
        tags: "Gaming game games consoles",
      };
      sTags.a9 = {
        cat1: "Electronics",
        cat2: "ACs",
        tags:
          "ACs split air conditioner conditioning air_conditioner air_conditioning",
      };
      sTags.a10 = {
        cat1: "Electronics",
        cat2: "Fans",
        tags: "Fans ventilators wind blow",
      };
      sTags.a11 = {
        cat1: "Electronics",
        cat2: "Accessories",
        tags:
          "Accessories mouse mouses phone_charger chargers battery batteries cover phone_cover covers usb cables andriod ios",
      };
      sTags.a12 = {
        cat1: "Electronics",
        cat2: "Appliances",
        tags: "Appliances fridge washing washer dryer kitchen",
      };
      sTags.a13 = {
        cat1: "Fashion",
        cat2: "Men´s Clothes",
        tags:
          "Men Ties suits tie suit pants shirts t-shirts trousers jeans man wear",
      };
      sTags.a14 = {
        cat1: "Fashion",
        cat2: "Women´s Clothes",
        tags:
          "Women Clothes dresses skirts pants tank_top tanktop t-shirts & vest kimono shirts & blouses casual tops scarves beanies gloves hats caps crop_tops denim_jeans jackets denim_jackets skinny jeans leggings wedding dress offices_wear shorts beach wear dinner gown pull-over palazo",
      };
      sTags.a15 = {
        cat1: "Fashion",
        cat2: "Children´s Clothes",
        tags:
          "shorts gowns trousers denims jackets suite dress formal dress pagents dress casual denim swim suites rompers sleep_overs ropes bottoms hoodes boomers footies rompers childrens kids babies young",
      };
      sTags.a16 = {
        cat1: "Fashion",
        cat2: "Bags",
        tags:
          "Bags backpacks leather belts waist bag clutches purse shoulder bag cross body bags body bag satchel bag sling bag quilted bag wristlet bag hobo bag manaudiere bag beach bag wallets ",
      };
      sTags.a17 = {
        cat1: "Fashion",
        cat2: "Shoes",
        tags:
          "Shoes sneakers highheels sandals footwear boots leather mules gladiators sandal wellington boots court boots ankle boots lace ups wedges flip flops canvas shoes loafers trainers brogues calfs boots chealsea boots military boot wellies slippers sandals ",
      };
      sTags.a18 = { cat1: "Fashion", cat2: "Watches", tags: "Watches clocks" };
      sTags.a19 = {
        cat1: "Fashion",
        cat2: "Jewlery",
        tags:
          "Jewlery luxury neckless bangles bracelet cuff links class rings engagement rings slave bracelet belly chain brooch chatelaine toe rings puzzle jewelry pledge pins signet ring thumb ring lockets pendants medallion foilbacks tie chain collar pin pectoral ",
      };
      sTags.a20 = {
        cat1: "Fashion",
        cat2: "Sports",
        tags:
          "Sports football suit soccer basketball running trainning tops trainning boots socks shorts sport singlet sport head bands hand bands knee bads",
      };
      sTags.a21 = {
        cat1: "Fashion",
        cat2: "Luggage",
        tags: "Luggage bags suitcases traveling backpacks",
      };
      sTags.a22 = {
        cat1: "Fashion",
        cat2: "Clothing Accessories",
        tags: "Clothing Accessories",
      };
      sTags.a23 = {
        cat1: "Fashion",
        cat2: "Wedding Wear",
        tags: "Wedding Wear marriage dress bride shower dress train dress ",
      };
      sTags.a24 = {
        cat1: "Fashion",
        cat2: "Taylors & Fashion Design",
        tags:
          "Taylors Fashion_Design clothes native tayloring suit ankara fabrics",
      };
      sTags.a25 = {
        cat1: "Fashion",
        cat2: "Fabrics",
        tags: "Fabrics native cotton wool",
      };
      sTags.a26 = {
        cat1: "Fashion",
        cat2: "Laundry",
        tags: "Laundry wash washing clean cleaning cleaner",
      };
      sTags.a27 = {
        cat1: "Food",
        cat2: "Restaurants / Cafes",
        tags:
          "Restaurants  Cafes eating food jollof rice eba fufu egusi salads coffee burgers meals drinking fish chicken beef goat",
      };
      sTags.a28 = {
        cat1: "Food",
        cat2: "Fresh Food",
        tags:
          "Fresh Food apples bananas pineapples mangos peas fruits vegetables carrots cucumbers salads potatoes tomatoes fish chicken beef goat",
      };
      sTags.a29 = {
        cat1: "Food",
        cat2: "Groceries",
        tags: "Groceries shopping food store convenience grocery",
      };
      sTags.a30 = {
        cat1: "Food",
        cat2: "Drinks",
        tags:
          "Drinks water coke pepsi juices smoothies beer alcohol wine liquor spirits drinking coffee",
      };
      sTags.a31 = {
        cat1: "Food",
        cat2: "Cakes & Bakery",
        tags:
          "Cakes  Bakery birthday celebrating sweets donuts bagels bread vanilla strawberry",
      };
      sTags.a32 = {
        cat1: "Food",
        cat2: "Lifestock",
        tags: "Lifestocks goats chickens cows pigs farm animals",
      };
      sTags.a33 = { cat1: "Food", cat2: "Wholesale", tags: "Wholesale" };
      sTags.a34 = {
        cat1: "Food",
        cat2: "Agricultural Products",
        tags:
          "Agricultural Products tomatos rice cassawa casawa palmoil apples bananas pineapples mangos peas fruits vegetables carrots cucumbers salads potatoes farm",
      };
      sTags.a35 = {
        cat1: "Food",
        cat2: "Catering",
        tags:
          "Catering restaurants cafes food delivery food_delivery events supply wedding ",
      };
      sTags.a36 = {
        cat1: "Beauty",
        cat2: "Hair",
        tags: "Hair beauty extensions barbers wigs attachments cover ups ",
      };
      sTags.a37 = {
        cat1: "Beauty",
        cat2: "Makeup",
        tags:
          "Makeup beauty foundations lip gloss powder mascara eye shadow lip stain eye liner eye pencil makeup brushes lip stick powder applicator blending sponge   ",
      };
      sTags.a38 = {
        cat1: "Beauty",
        cat2: "Fragrances",
        tags: "Fragrances beauty body oil perfume oils body spray cologne ",
      };
      sTags.a39 = {
        cat1: "Beauty",
        cat2: "Vitamins",
        tags: "Vitamins enhancements",
      };
      sTags.a40 = {
        cat1: "Beauty",
        cat2: "Body & Bath",
        tags:
          "Body Baths shower bathing soap bathing gel body oil body cream scrubs facial wash ",
      };
      sTags.a41 = {
        cat1: "Beauty",
        cat2: "Skin Care",
        tags: "Skin_Care skin mayonnaise skin scrub beauty",
      };
      sTags.a42 = {
        cat1: "Beauty",
        cat2: "Massages",
        tags: "Massages treatment spa manicure pedicure nails",
      };
      sTags.a43 = {
        cat1: "Beauty",
        cat2: "Salons & Barbers",
        tags:
          "Salons Barbers salon barber hair cutting beard extensions styling nails beauty",
      };
      sTags.a44 = { cat1: "Beauty", cat2: "Accessories", tags: "Accessories" };
      sTags.a45 = {
        cat1: "Home",
        cat2: "Furniture",
        tags: "Furniture tables chairs doors",
      };
      sTags.a46 = {
        cat1: "Home",
        cat2: "Cleaning",
        tags: "Cleaning home office ",
      };
      sTags.a47 = {
        cat1: "Home",
        cat2: "Drycleaning",
        tags: "Drycleaning suits clothes",
      };
      sTags.a48 = {
        cat1: "Home",
        cat2: "Curtains & Blinds",
        tags: "Curtains Blinds wall art wall papers ",
      };
      sTags.a49 = { cat1: "Home", cat2: "Carpets", tags: "Carpets floors" };
      sTags.a50 = { cat1: "Home", cat2: "Lights", tags: "Lights bulbs lamps" };
      sTags.a51 = {
        cat1: "Home",
        cat2: "Garden & Flowers",
        tags: "Garden Flowers gardening ",
      };
      sTags.a52 = {
        cat1: "Home",
        cat2: "Kitchen & Dining",
        tags: "Kitchen Dining",
      };
      sTags.a53 = {
        cat1: "Home",
        cat2: "Interior, Decoration, Doors, Floors",
        tags: "Interior Decoration Doors Floors tables ceilings walls",
      };
      sTags.a54 = {
        cat1: "Home",
        cat2: "Exterior, Windows, Tools",
        tags: "Exterior Windows Tools walls",
      };
      sTags.a55 = {
        cat1: "Home",
        cat2: "Security & Home Technology",
        tags: "Security Home Technology",
      };
      sTags.a56 = {
        cat1: "Home",
        cat2: "Baby Products",
        tags: "Baby toddlers babies",
      };
      sTags.a57 = {
        cat1: "Home",
        cat2: "Sports",
        tags: "Sports football basketball soccer baseball tennis",
      };
      sTags.a58 = {
        cat1: "Home",
        cat2: "Pets",
        tags: "Pets animals cats dogs",
      };
      sTags.a59 = {
        cat1: "Home",
        cat2: "Home / Office For Sale",
        tags:
          "Home Offices Sale  property houses stay accomodations appartments",
      };
      sTags.a60 = {
        cat1: "Home",
        cat2: "Home / Office For Rent",
        tags:
          "Home Offices Rent  property flats houses accomodations appartments",
      };
      sTags.a61 = {
        cat1: "Home",
        cat2: "Short-term Rent",
        tags:
          "Short-term Rent property flats houses home stay accomodations appartments",
      };
      sTags.a62 = {
        cat1: "Home",
        cat2: "Land & Plots",
        tags: "Land Plots property",
      };
      sTags.a63 = {
        cat1: "Home",
        cat2: "Event Locations",
        tags:
          "Event Locations event_centers weddings funerals birthdays celebrations",
      };
      sTags.a64 = {
        cat1: "Home",
        cat2: "Shared Workspaces",
        tags: "Shared Workspaces co-working",
      };
      sTags.a65 = {
        cat1: "Handyman",
        cat2: "Plumbing",
        tags: "Plumbing plumber toilet sink shower handyman artisans",
      };
      sTags.a66 = {
        cat1: "Handyman",
        cat2: "Electrician",
        tags: "Electrician handyman artisans lights switches cables",
      };
      sTags.a67 = {
        cat1: "Handyman",
        cat2: "AC",
        tags: "AC handyman artisans repair installation maintenance",
      };
      sTags.a68 = {
        cat1: "Handyman",
        cat2: "Generator",
        tags: "Generator handyman artisans repair installation maintenance",
      };
      sTags.a69 = {
        cat1: "Handyman",
        cat2: "Appliances",
        tags: "Appliances handyman artisans repair installation",
      };
      sTags.a70 = {
        cat1: "Handyman",
        cat2: "Painting",
        tags: "Painting colours house wall painters",
      };
      sTags.a71 = {
        cat1: "Handyman",
        cat2: "Fumigation",
        tags: "Fumigation handyman artisans bugs house",
      };
      sTags.a72 = {
        cat1: "Handyman",
        cat2: "Doors, Knobs, Locks",
        tags: "Doors Knobs Locks handles",
      };
      sTags.a73 = {
        cat1: "Handyman",
        cat2: "Curtains, Blinds",
        tags: "Curtains Blinds fabrics window_blinds",
      };
      sTags.a74 = {
        cat1: "Handyman",
        cat2: "Flooring",
        tags: "Flooring handyman artisans",
      };
      sTags.a75 = {
        cat1: "Handyman",
        cat2: "Walls & Ceiling",
        tags: "Walls Ceiling handyman artisans fences",
      };
      sTags.a76 = {
        cat1: "Handyman",
        cat2: "Gardening",
        tags: "Gardening handyman artisans",
      };
      sTags.a77 = {
        cat1: "Handyman",
        cat2: "Furniture Assembly",
        tags: "Furniture Assembly handyman artisans",
      };
      sTags.a78 = {
        cat1: "Handyman",
        cat2: "General Work",
        tags: "General Work handyman artisans",
      };
      sTags.a79 = {
        cat1: "Health",
        cat2: "Pharmacies",
        tags:
          "Pharmacies health drugs medicine panadol aspirin malaria medication fever pills eyedrops",
      };
      sTags.a80 = {
        cat1: "Health",
        cat2: "Labs",
        tags: "Labs health diagnostic_center laboratory health_check",
      };
      sTags.a81 = {
        cat1: "Health",
        cat2: "General Doctors / Primary Clinics",
        tags:
          "General Doctors Primary Clinics health hospitals care center emergency treatment malaria exercise nutrition birth delivery ambulance pain neck shoulder belly stomach headache fever",
      };
      sTags.a82 = {
        cat1: "Health",
        cat2: "Eye Doctors / Clinics",
        tags: "Eye Doctors Clinics health ophtalmologists",
      };
      sTags.a83 = {
        cat1: "Health",
        cat2: "Ear, Nose, Throat",
        tags: "Ear Nose Throat health ENT",
      };
      sTags.a84 = {
        cat1: "Health",
        cat2: "Dentists / Dental Clinics",
        tags:
          "Dentists Dental Clinics health tooth teeth dental care scaling_polishing",
      };
      sTags.a85 = {
        cat1: "Health",
        cat2: "Nutritionsts",
        tags: "Nutritionsts health weighloss diet slim_tea keto",
      };
      sTags.a86 = {
        cat1: "Health",
        cat2: "Physiotherapists",
        tags: "Physiotherapists health sports pain",
      };
      sTags.a87 = {
        cat1: "Health",
        cat2: "Clinical Psychologists",
        tags: "Clinical Psychologists health",
      };
      sTags.a88 = {
        cat1: "Health",
        cat2: "Psychiatrists",
        tags: "Psychiatrists mental depression schizophrenia",
      };
      sTags.a89 = {
        cat1: "Health",
        cat2: "Specialty Nurses",
        tags: "Specialty Nurses health",
      };
      sTags.a90 = {
        cat1: "Health",
        cat2: "Sexual Health Specialist / Clinic",
        tags: "Sexual Health Specialist Clinic hiv",
      };
      sTags.a91 = {
        cat1: "Health",
        cat2: "Occupational Therapists",
        tags: "Occupational Therapists ",
      };
      sTags.a92 = {
        cat1: "Health",
        cat2: "Other Specialists",
        tags: "Other Specialists cancer",
      };
      sTags.a93 = {
        cat1: "Health",
        cat2: "Health Shops / Products",
        tags: "Health Shops Products",
      };
      sTags.a94 = {
        cat1: "Health",
        cat2: "Blood Banks",
        tags: "Blood Banks health",
      };
      sTags.a95 = {
        cat1: "Health",
        cat2: "Medical Instruments",
        tags: "Medical Instruments",
      };
      sTags.a96 = {
        cat1: "Health",
        cat2: "Gyms & Fitness Centers",
        tags:
          "Gyms Fitness Centers workout aerobics body training exercise weightlifting",
      };
      sTags.a97 = {
        cat1: "Professionals",
        cat2: "Legal",
        tags: "Legal lawyers contracts consulting ",
      };
      sTags.a98 = {
        cat1: "Professionals",
        cat2: "Marketing",
        tags: "Marketing marketers pr branding agencies advertising ",
      };
      sTags.a99 = {
        cat1: "Professionals",
        cat2: "Digital Marketing",
        tags:
          "Digital Marketing SEO SEM content search google online online_marketing digital_marketing websites facebool twitter instagram radio tv billboards flyers t-shirts branding",
      };
      sTags.a100 = {
        cat1: "Professionals",
        cat2: "PR",
        tags: "PR public press publicity writing content ",
      };
      sTags.a101 = {
        cat1: "Professionals",
        cat2: "Graphics Design",
        tags: "Graphics Design materials flyers pictures images avatars",
      };
      sTags.a102 = {
        cat1: "Professionals",
        cat2: "Writing / Content",
        tags: "Writing Content publicity content press blogging ",
      };
      sTags.a103 = {
        cat1: "Professionals",
        cat2: "Translation",
        tags: "Translation language",
      };
      sTags.a104 = {
        cat1: "Professionals",
        cat2: "Video",
        tags: "Video animation youtube tv filming",
      };
      sTags.a105 = {
        cat1: "Professionals",
        cat2: "Animation",
        tags: "video animation youtube tv 3d",
      };
      sTags.a106 = {
        cat1: "Professionals",
        cat2: "Photography",
        tags: "Photography wedding pictures photographers photos shots camera",
      };
      sTags.a107 = {
        cat1: "Professionals",
        cat2: "Music",
        tags:
          "Musicians singers entertainers songwriters musicals drummers events",
      };
      sTags.a108 = {
        cat1: "Professionals",
        cat2: "Marketing Materials, Printing, Branding",
        tags: "Marketing Materials Printing Branding",
      };
      sTags.a109 = {
        cat1: "Professionals",
        cat2: "Software Development",
        tags: "Software Development apps devlopers coders coding design web ",
      };
      sTags.a110 = {
        cat1: "Professionals",
        cat2: "Website Development",
        tags:
          "Website Development Software Development apps devlopers coders coding design web",
      };
      sTags.a111 = {
        cat1: "Professionals",
        cat2: "App Development",
        tags:
          "App Development Software Development apps devlopers coders coding design web ",
      };
      sTags.a112 = {
        cat1: "Professionals",
        cat2: "Computer & IT Services",
        tags:
          "Computer  IT Services Software Development apps devlopers coders coding design web hard disk laptop screens installation systems it support it_support",
      };
      sTags.a113 = {
        cat1: "Professionals",
        cat2: "General Business",
        tags: "General Business consultants",
      };
      sTags.a1113 = {
        cat1: "Professionals",
        cat2: "Teaching - Primary",
        tags: "Teaching education Teacher Tutor home schooling",
      };
      sTags.a2113 = {
        cat1: "Professionals",
        cat2: "Teaching - Secondary",
        tags: "Teaching education Teacher Tutor home schooling",
      };
      sTags.a3113 = {
        cat1: "Professionals",
        cat2: "Teaching - Tertiary",
        tags:
          "Teaching education Teacher Tutor lecturer professor university college",
      };
      sTags.a4113 = {
        cat1: "Professionals",
        cat2: "Public Speakers",
        tags: "Public Speaking speeches event talk",
      };
      sTags.a114 = {
        cat1: "Professionals",
        cat2: "Facility Management",
        tags: "Facility Management facility_management",
      };
      sTags.a115 = {
        cat1: "Professionals",
        cat2: "Training & Education",
        tags: "Training  Education",
      };
      sTags.a116 = {
        cat1: "Professionals",
        cat2: "Training - Soft Skills",
        tags: "Training Soft Skills leadership motivation",
      };
      sTags.a117 = {
        cat1: "Professionals",
        cat2: "Coaching",
        tags:
          "Coaching life mentoring weight success fulfilment wealth money teacher mentors",
      };
      sTags.a118 = {
        cat1: "Professionals",
        cat2: "Visa & Concierge",
        tags: "Visa  Concierge travel advisor immigration greencard",
      };
      sTags.a119 = {
        cat1: "Professionals",
        cat2: "Events & Catering",
        tags: "Events Catering",
      };
      sTags.a120 = {
        cat1: "Professionals",
        cat2: "Wedding Planning",
        tags: "Weddings Planning events marriage",
      };
      sTags.a121 = {
        cat1: "Professionals",
        cat2: "Trade Services",
        tags: "Trade Services",
      };
      sTags.a122 = {
        cat1: "Professionals",
        cat2: "Financial & Investment",
        tags: "Financial  Investment money forex retirement stock",
      };
      sTags.a123 = {
        cat1: "Professionals",
        cat2: "Insurances",
        tags: "Insurances health car insurer",
      };
      sTags.a124 = {
        cat1: "Professionals",
        cat2: "Loans",
        tags: "Loans money cash",
      };
      sTags.a125 = {
        cat1: "Professionals",
        cat2: "Business Consulting",
        tags: "Business Consulting advisory consultants management strategy",
      };
      sTags.a126 = {
        cat1: "Professionals",
        cat2: "IT Consulting",
        tags:
          "IT Consulting advisory consultants management strategy technology ",
      };
      sTags.a127 = {
        cat1: "Professionals",
        cat2: "Other Services",
        tags: "Other Services",
      };
      sTags.a128 = {
        cat1: "Professionals",
        cat2: "Fun / Entertainment",
        tags: "Fun Entertainment Events",
      };
      sTags.a129 = {
        cat1: "Cars",
        cat2: "New Cars",
        tags: "New Cars Toyota Honda Mercedes Tokunbo Nigerian_used ",
      };
      sTags.a130 = {
        cat1: "Cars",
        cat2: "Imported Cars",
        tags:
          "Imported Cars Toyota Honda Mercedes Tokunbo Toyota Honda Mercedes vehicles",
      };
      sTags.a131 = {
        cat1: "Cars",
        cat2: "Locally Used Cars",
        tags: "Locally Used Cars Nigerian_used Toyota Honda Mercedes vehicles",
      };
      sTags.a132 = {
        cat1: "Cars",
        cat2: "Used Cars with Warranty",
        tags:
          "Used Cars with Warranty locally Nigerian_Used Toyota Honda Mercedes vehicles",
      };
      sTags.a133 = {
        cat1: "Cars",
        cat2: "Trucks",
        tags: "Trucks Macks vehicles commercial",
      };
      sTags.a134 = {
        cat1: "Cars",
        cat2: "Motorbikes",
        tags: "Motorbikes bikes kekes okada",
      };
      sTags.a135 = {
        cat1: "Cars",
        cat2: "Spare Parts",
        tags: "Spare Parts cars spare_parts cludge brakes engines",
      };
      sTags.a136 = {
        cat1: "Cars",
        cat2: "Car Insurance",
        tags: "Car Insurance",
      };
      sTags.a137 = { cat1: "Cars", cat2: "Car Loans", tags: "Car Loans" };
      sTags.a138 = {
        cat1: "Cars",
        cat2: "Mechanics",
        tags: "Mechanics repair car truck motorbike maintenance",
      };
      sTags.a139 = {
        cat1: "Cars",
        cat2: "Accessories",
        tags: "Accessories cars window spoilers",
      };
      sTags.a140 = { cat1: "Cars", cat2: "Car Wash", tags: "Car Wash" };
      sTags.a141 = {
        cat1: "Cars",
        cat2: "Drivers",
        tags: "Drivers cars taxi uber",
      };
      sTags.a142 = {
        cat1: "Cars",
        cat2: "Drivers with Cars",
        tags: "Drivers with Cars",
      };
      sTags.a143 = {
        cat1: "Cars",
        cat2: "Drivers with Motorbike",
        tags: "Drivers with Motorbike delivery",
      };
      sTags.a144 = {
        cat1: "Cars",
        cat2: "Drivers with Truck",
        tags: "Drivers with Truck delivery logistics transportations",
      };
      sTags.a145 = {
        cat1: "Repair_Refill",
        cat2: "Airtime",
        tags:
          "Airtime recharge bundles data phones call topup bonus mobile mtn airtel mobile orange etisalat glo smile telephone",
      };
      sTags.a146 = {
        cat1: "Repair_Refill",
        cat2: "Fuel Supply",
        tags: "Fuel Supply diesel gasoline generator refuel refill pump oil ",
      };
      sTags.a147 = {
        cat1: "Repair_Refill",
        cat2: "Gas Supply",
        tags: "Gas Supply oven refill ",
      };
      sTags.a148 = {
        cat1: "Repair_Refill",
        cat2: "Trade-Ins",
        tags: "Trade-Ins",
      };
      sTags.a149 = {
        cat1: "Repair_Refill",
        cat2: "Phone Repair",
        tags: "Phones Repair fixing battery glass broken ios android",
      };
      sTags.a150 = {
        cat1: "Repair_Refill",
        cat2: "Computer Repair",
        tags: "Computer Repair",
      };
      sTags.a151 = {
        cat1: "Repair_Refill",
        cat2: "TV Repair / Installation",
        tags: "TV Repair  Installation",
      };
      sTags.a152 = {
        cat1: "Repair_Refill",
        cat2: "Electronics Repair",
        tags: "Electronics Repair",
      };
      sTags.a153 = {
        cat1: "Repair_Refill",
        cat2: "Appliance Repair",
        tags: "Appliance Repair",
      };
      sTags.a154 = {
        cat1: "Repair_Refill",
        cat2: "Home Repair",
        tags: "Home Repair",
      };
      sTags.a155 = {
        cat1: "Repair_Refill",
        cat2: "Other Repair",
        tags: "Repair",
      };
      sTags.a156 = {
        cat1: "Travel",
        cat2: "Hotels",
        tags: "Hotels reservation stay trip travel ",
      };
      sTags.a157 = {
        cat1: "Travel",
        cat2: "Accomodation & Hostels",
        tags: "Accomodation Hostels houses stay bed breakfast living",
      };
      sTags.a158 = {
        cat1: "Travel",
        cat2: "Tours",
        tags: "Tours trips guided events tourism sightseeing hiking ",
      };
      sTags.a159 = {
        cat1: "Travel",
        cat2: "Sights",
        tags: "Sights Tourism travel",
      };
      sTags.a160 = {
        cat1: "Travel",
        cat2: "Flights",
        tags: "Flights air travel plane ",
      };
      sTags.a161 = {
        cat1: "Travel",
        cat2: "Bus & Train Rides",
        tags: "Bus Train Rides interstate tour",
      };
      sTags.a162 = {
        cat1: "Commercial",
        cat2: "Machinery",
        tags: "Machinery machines heavy commercial",
      };
      sTags.a163 = {
        cat1: "Commercial",
        cat2: "Construction",
        tags: "Constructions commercial ",
      };
      sTags.a164 = {
        cat1: "Commercial",
        cat2: "Tools & Parts",
        tags: "Tools  Parts spare spare_parts",
      };
      sTags.a165 = {
        cat1: "Commercial",
        cat2: "Repair & Maintenance",
        tags: "Repair  Maintenance commercial",
      };
      sTags.a166 = {
        cat1: "Commercial",
        cat2: "Waste Recycling",
        tags: "Waste Recycling",
      };
      sTags.a167 = {
        cat1: "Commercial",
        cat2: "Cement, Wood, Materials",
        tags: "Cement Wood Materials Bricks Mortar Building commercial",
      };
      sTags.a168 = {
        cat1: "Commercial",
        cat2: "Commercial Accessories",
        tags: "Commercial Accessories",
      };
      sTags.a169 = {
        cat1: "Commercial",
        cat2: "Office Supply",
        tags: "Office Supply",
      };
      sTags.a170 = {
        cat1: "Commercial",
        cat2: "Business Centers",
        tags: "Business Centers CAFES ",
      };
      sTags.a171 = {
        cat1: "Commercial",
        cat2: "Crowdfunding, Investments",
        tags: "Crowdfunding Investments",
      };
      sTags.a172 = {
        cat1: "Commercial",
        cat2: "Donations, Initiatives, Sponsorship",
        tags: "Donations Initiatives Sponsorship NGOs",
      };
      sTags.a173 = {
        cat1: "Art",
        cat2: "Books",
        tags:
          "Books Readings school books poetry fiction art cook books technical books ",
      };
      sTags.a174 = {
        cat1: "Art",
        cat2: "Crafts & Paintings",
        tags: "Crafts  Paintings Art",
      };
      sTags.a175 = {
        cat1: "Art",
        cat2: "Collectibles",
        tags: "Collectibles Art",
      };
      sTags.a176 = { cat1: "Art", cat2: "Movies", tags: "Movies Art" };
      sTags.a177 = { cat1: "Art", cat2: "Music", tags: "Music Art" };
      sTags.a178 = {
        cat1: "Art",
        cat2: "Musical Instruments",
        tags:
          "Musical Instruments voilin guitar pianos ukulele saxophone cellos trumpets bass drums bamboo",
      };
      sTags.a179 = {
        cat1: "Art",
        cat2: "Concerts, Performances, Events",
        tags: "Concerts Performances Events",
      };
      sTags.a180 = {
        cat1: "Art",
        cat2: "Exhibitions, Galleries",
        tags: "Exhibitions Galleries",
      };
      sTags.a181 = {
        cat1: "Art",
        cat2: "Theater, Comedy, Shows",
        tags: "Theater Comedy Shows",
      };
      sTags.a182 = { cat1: "Art", cat2: "General Art", tags: "General Art" };
      sTags.a183 = {
        cat1: "QuickJobs",
        cat2: "Sales and Marketing",
        tags: "Sales Marketing Jobs marketers",
      };
      sTags.a184 = {
        cat1: "QuickJobs",
        cat2: "Physical Workmanship",
        tags: "Physical Workmanship Jobs Employment",
      };
      sTags.a185 = {
        cat1: "QuickJobs",
        cat2: "Technical, IT",
        tags: "Technical IT Jobs Employment",
      };
      sTags.a186 = {
        cat1: "QuickJobs",
        cat2: "Creatives, Design, DJ, Entertainer",
        tags: "Creatives Design DJ Entertainer Jobs Employment",
      };
      sTags.a187 = {
        cat1: "QuickJobs",
        cat2: "Waiters, Hostesses, Representatives",
        tags: "Waiters Hostesses Representatives ushers Jobs Employment",
      };
      sTags.a188 = {
        cat1: "QuickJobs",
        cat2: "Teaching, Nanny, Nursing",
        tags:
          "Teaching Nanny Nursing teachers nannies nurses education school lecturing Jobs Employment",
      };
      sTags.a189 = {
        cat1: "QuickJobs",
        cat2: "Household, Cooking, Errands",
        tags: "Household Cooking Errands concierges Jobs Employment",
      };
      sTags.a190 = {
        cat1: "QuickJobs",
        cat2: "Office and Admin",
        tags: "Office and Admin Jobs Employment",
      };
      sTags.a191 = {
        cat1: "QuickJobs",
        cat2: "Extra Cash",
        tags: "Extra Cash Jobs  Employment",
      };
      sTags.a192 = {
        cat1: "QuickJobs",
        cat2: "Other",
        tags: "Jobs Employment",
      };
    }
    if (global.app == "artisan") {
      sTags.a65 = {
        cat1: "Handyman",
        cat2: "Plumbing",
        tags: "Plumbing plumber toilet sink shower handyman artisans",
      };
      sTags.a66 = {
        cat1: "Handyman",
        cat2: "Electrician",
        tags: "Electrician handyman artisans lights switches cables",
      };
      sTags.a67 = {
        cat1: "Handyman",
        cat2: "AC",
        tags: "AC handyman artisans repair installation maintenance",
      };
      sTags.a68 = {
        cat1: "Handyman",
        cat2: "Generator",
        tags: "Generator handyman artisans repair installation maintenance",
      };
      sTags.a69 = {
        cat1: "Handyman",
        cat2: "Appliances",
        tags: "Appliances handyman artisans repair installation",
      };
      sTags.a70 = {
        cat1: "Handyman",
        cat2: "Painting",
        tags: "Painting colours house wall painters",
      };
      sTags.a71 = {
        cat1: "Handyman",
        cat2: "Fumigation",
        tags: "Fumigation handyman artisans bugs house",
      };
      sTags.a72 = {
        cat1: "Handyman",
        cat2: "Doors, Knobs, Locks",
        tags: "Doors Knobs Locks handles",
      };
      sTags.a73 = {
        cat1: "Handyman",
        cat2: "Curtains, Blinds",
        tags: "Curtains Blinds fabrics window_blinds",
      };
      sTags.a74 = {
        cat1: "Handyman",
        cat2: "Flooring",
        tags: "Flooring handyman artisans",
      };
      sTags.a75 = {
        cat1: "Handyman",
        cat2: "Walls & Ceiling",
        tags: "Walls Ceiling handyman artisans fences",
      };
      sTags.a76 = {
        cat1: "Handyman",
        cat2: "Gardening",
        tags: "Gardening handyman artisans",
      };
      sTags.a77 = {
        cat1: "Handyman",
        cat2: "Furniture Assembly",
        tags: "Furniture Assembly handyman artisans",
      };
      sTags.a78 = {
        cat1: "Handyman",
        cat2: "General Work",
        tags: "General Work handyman artisans",
      };
    }
    if (global.app == "education") {
      sTags.a165 = {
        cat1: "Professionals",
        cat2: "Teaching - Primary",
        tags: "Teaching primary teacher school home",
      };
      sTags.a166 = {
        cat1: "Professionals",
        cat2: "Teaching - Secondary",
        tags: "Teaching Secondary teacher high school middle home",
      };
      sTags.a167 = {
        cat1: "Professionals",
        cat2: "Teaching - Tertiary",
        tags: "Teaching Tertiary teacher Lecturer Professor College University",
      };
    }
    // if (global.app == "speakers") {
    //   sTags.a4113 = { cat1: 'Professionals', cat2: 'Public Speakers', tags: 'Public Speaking speeches event talk' };
    // }

    global.tags = {};

    const keys = Object.keys(sTags);
    keys.map((key) => {
      const string = sTags[key].tags;
      const stringArray = string.split(" ");
      stringArray.forEach((w) => {
        if (w.trim().length > 0) {
          const wl = w.trim().toLowerCase();
          if (!global.tags[wl])
            global.tags[wl] = [
              { cat1: sTags[key].cat1, cat2: sTags[key].cat2 },
            ];
          else
            global.tags[wl].push({
              cat1: sTags[key].cat1,
              cat2: sTags[key].cat2,
            });
        }
      });
      global.tagKeys = Object.keys(global.tags);
    });

    //console.log("tags")
    //console.log(global.tags)
  };

  global.setMyPushTokenIfNeeded = async () => {
    if (Platform.OS !== "ios") {
      const channelMessages = Notifications.getNotificationChannelAsync("Messages")
      const channelCommunities = Notifications.getNotificationChannelAsync("Communities")
      const channelBlogs = Notifications.getNotificationChannelAsync("Blogs")
      const channelComments = Notifications.getNotificationChannelAsync("Comments")
      const channelPayment = Notifications.getNotificationChannelAsync("Payment")
      const channelOrders = Notifications.getNotificationChannelAsync("Orders")
      const channelSystem = Notifications.getNotificationChannelAsync("System")
      if (!channelMessages) Notifications.setNotificationChannelAsync('Messages', {
        name: 'Messages',
        importance: Notifications.AndroidImportance.HIGH,
        //sound: 'email-sound.wav', // <- for Android 8.0+, see channelId property below
      });
      if (!channelCommunities) Notifications.setNotificationChannelAsync('Communities', {
        name: 'Communities',
        importance: Notifications.AndroidImportance.HIGH,
        //sound: 'email-sound.wav', // <- for Android 8.0+, see channelId property below
      });
      if (!channelBlogs) Notifications.setNotificationChannelAsync('Blogs', {
        name: 'Blogs',
        importance: Notifications.AndroidImportance.HIGH,
        //sound: 'email-sound.wav', // <- for Android 8.0+, see channelId property below
      });
      if (!channelComments) Notifications.setNotificationChannelAsync('Comments', {
        name: 'Comments',
        importance: Notifications.AndroidImportance.HIGH,
        //sound: 'email-sound.wav', // <- for Android 8.0+, see channelId property below
      });
      if (!channelOrders) Notifications.setNotificationChannelAsync('Orders', {
        name: 'Orders',
        importance: Notifications.AndroidImportance.HIGH,
        //sound: 'email-sound.wav', // <- for Android 8.0+, see channelId property below
      });
      if (!channelSystem) Notifications.setNotificationChannelAsync('System', {
        name: 'System',
        importance: Notifications.AndroidImportance.HIGH,
        //sound: 'email-sound.wav', // <- for Android 8.0+, see channelId property below
      });
      if (!channelPayment) Notifications.setNotificationChannelAsync('Payment', {
        name: 'Payment',
        importance: Notifications.AndroidImportance.HIGH,
        //sound: 'email-sound.wav', // <- for Android 8.0+, see channelId property below
      });
    }
    if (!global.myUID) await new Promise((resolve) => { setTimeout(resolve, 2000); });
    if (!global.myUID) await new Promise((resolve) => { setTimeout(resolve, 6000); });
    if (!global.myUID) await new Promise((resolve) => { setTimeout(resolve, 20000); });
    if (!global.myUID) await new Promise((resolve) => { setTimeout(resolve, 80000); });
    const { status } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
    if (!global.devMode)
      if (status == "granted") {
        try {
          let tokenOb = await Notifications.getExpoPushTokenAsync();
          let token = tokenOb ? tokenOb.data : ""
          if (token && global.myPushToken != token) {
            global.myPushToken = token;
            console.log("Registering new Push Token:", token);

            await fetch(global.cloudFunctionURL + "registerPushToken", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                isWeb: true,
                token,
                experienceId: Constants.manifest.id,
                myUID: global.myUID,
                idToken: global.idToken,
              }),
            });
          }
        } catch (err) {
          console.log("Error setting Push Token", err);
        }
      }
  };

  global.setIDTokenListener = async () => {
    try {
      myThis.unsubscribeFirestoreToken = await firebase
        .auth()
        .onIdTokenChanged(function (user) {
          if (user) {
            // User is signed in or token was refreshed.
            global.fetchIdToken();
          }
        });
    } catch (err) {
      console.log("ERROR setting idToken Listener", err);
    }
  };

  global.fetchIdToken = async () => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken();
      // .currentUser.getIdToken(/* forceRefresh */ true);
      if (idToken) {
        global.idToken = idToken;
      } else {
        console.log("Check! fetchIdToken Error");
      }
      console.log("Success fetching idToken");
    } catch (err) {
      console.log("ERROR fetching idToken", err);
    }
  };

  global.fetchWishlistGlobal = async () => {
    let wishDoc = await firebase
      .firestore()
      .collection("Users")
      .doc(global.myUID)
      .collection("Refs")
      .doc("wishlist")
      .get();
    global.myWishlist = wishDoc.data().postIDs;
  };

  global.updatePushBadge = async () => {
    try {
      let pushBadge = (global.pushBadgeMessages || 0) + (global.pushBadgeCommunities || 0) + (global.pushBadgeJobs || 0) + (global.pushBadgeHealth || 0)
      Notifications.setBadgeCountAsync(pushBadge)
      const thisDate = new Date()
      global.lastStartedUpdatePushBadge = thisDate
      await global.timeout(3000)
      if (!global.myUID) await global.timeout(10000);
      if (!global.myUID) return;
      if (global.lastStartedUpdatePushBadge == thisDate && global.myPushBadge != pushBadge) {
        const { status } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
        if (status == "granted") {
          let tokenOb = !global.devMode && await Notifications.getExpoPushTokenAsync();
          const token = (tokenOb && !global.devMode) ? tokenOb.data : null
          await global.fetchIdToken?.()
          const r = await fetch(global.cloudFunctionURL + "registerPushToken", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              isWeb: true,
              action: "updateBadge",
              pushBadge,
              token,
              myUID: global.myUID,
              idToken: global.idToken,
            }),
          });
          const r2 = await r.json();
          if (r2.msg !== "SUCCESS") {
            global?.warn(e, "updatePushBadge-NOSUCCESS")
          }
        }
      }
    } catch (e) {
      global?.warn(e, "updatePushBadge")
    }
  };

  global.updateXRates = (snapshot) => {
    if (!snapshot) return;
    const xRates = snapshot.data();
    if (!xRates) return;
    global.xRates = xRates.oneACNEquals;
  };
  global.updateTaxRates = async (snapshot) => {
    if (!snapshot) return;
    const taxRates = snapshot.data();
    global.taxRates = taxRates.taxesAndFeesPercentage;

    while (!global.myLocalCurrency) {
      await global.timeout(1000);
    }
    global.myTaxRate = taxRates.taxesAndFeesPercentage[global.myLocalCurrency];
    if (!global.myTaxRate)
      global.myTaxRate = taxRates.taxesAndFeesPercentage["default"];
  };

  global.getAddress = async (coords) => {
    try {
      let lat = 0;
      let lon = 0;
      if (!coords) {
        // for global.location
        if (!global.location) {
          global.location = { latitude: 6.6, longitude: 3.3 };
          return "Lagos, Nigeria";
        }
        lat = global.location.coords.latitude;
        lng = global.location.coords.longitude;
      }
      if (coords) {
        // for specific coords
        if (!coords.latitude || !coords.longitude) return "System error";
        lat = coords.latitude;
        lng = coords.longitude;
      }
      const address = await fetch(
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
        lat +
        "," +
        lng +
        "&key=" +
        "AIzaSyBi_IkZq2WGqptedE4IaLdt7auklRYG0jg"
      );
      let addressJSON = await address.json();
      let ADDRESS =
        addressJSON && addressJSON.results && addressJSON.results.length > 0
          ? addressJSON.results[0].formatted_address
          : "Unknown Location";
      return ADDRESS;
    } catch (e) {
      console.warn("getAddress error");
      console.log("getAddress error:", e);
      return "Unknown";
    }
  };

  global.setGlobals = async () => {
    const dat1 = new Date();
    global.setGlobalPermissions();
    global.setConstants();
    global.calculateTags();

    global.xRates = {
      GHS: 0.011236, /// will change a second later and is not binding
    };

    global.localAmount = (amountACN) => {
      let localAmount = amountACN * global.xRates[global.myLocalCurrency];
      return Math.round(localAmount);
      //return Math.round(localAmount * 100) / 100
      //return localAmount
    };
    global.acnAmount = (amountLocal) => {
      let acnAmount =
        amountLocal / (global.xRates[global.myLocalCurrency] || 9999999);
      return Math.round(acnAmount);
      //return Math.round(localAmount * 100) / 100
      //return localAmount
    };

    global.getNetwork = async (networkID, doRefresh) => {
      let network;
      let networkString = "";
      try {
        networkString = await AsyncStorage.getItem("network::" + networkID);
      } catch (err) {
        console.log(err);
        networkString = null;
      }
      if (networkString && !doRefresh) {
        console.log("Async Network received", networkID);
        network = await JSON.parse(networkString);
      } else {
        console.log(
          doRefresh
            ? "Network Refresh requested"
            : "Async Network not yet stored" + ", retrieving from firestore",
          networkID
        );
        try {
          const networkDoc = await firebase
            .firestore()
            .collection("Networks")
            .doc(networkID)
            .get();
          network = networkDoc.data();
          console.log(":Loaded network " + networkID);

          if (network)
            AsyncStorage.setItem(
              "network::" + networkID,
              JSON.stringify(network)
            );
        } catch (err) {
          console.log(err);
        }
      }
      return network;
    };

    //xx improve
    if (!global.isConnected)
      await new Promise((resolve) => {
        setTimeout(resolve, 500);
      });
    if (!global.isConnected)
      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });
    if (!global.isConnected)
      await new Promise((resolve) => {
        setTimeout(resolve, 2000);
      });
    if (!global.isConnected)
      await new Promise((resolve) => {
        setTimeout(resolve, 5000);
      });
    if (!global.isConnected) return;

    const dat2 = new Date();

    try {
      let uid = await firebase.auth().currentUser.uid;
      if (uid) {
        global.myUID = uid;
        console.log("Success retrieving myUID.", global.myUID);
      } else {
        console.log("Global.MyUID could not be retrieved.");
        throw new Error("Could not set Globals.");
      }
      const dat3 = new Date();

      firebase
        .firestore()
        .collection("ServerClientReadOnly")
        .doc("xRates")
        .onSnapshot(global.updateXRates);

      firebase
        .firestore()
        .collection("ServerClientReadOnly")
        .doc("taxRates")
        .onSnapshot(global.updateTaxRates);

      global.setIDTokenListener();
      //global.fetchIdToken();
      global.fetchWishlistGlobal();
      const dat4 = new Date();

      let userDoc = await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .get();
      let user = userDoc.data();
      const dat5 = new Date();

      global.myProfileID = user.idProfile;
      if (user.pushToken) {
        global.myPushToken = user.pushToken;
      }
      if (user.usesACN) {
        global.walletType = "ACN";
      } else {
        if (user) global.walletType = "NGN";
      }
      //if (user.coinKey) global.coinKeySet = true
      if (user.coinSignKey) global.coinSignKeySet = true; // doubled in wallet screen, remove here
      global.myLocalCurrency = user.localCurrency || "NGN";
      global.setMyPushTokenIfNeeded();
      const dat6 = new Date();

      global.myBlacklist = [];
      global.myBlocklist = [];
      global.myBlackedlist = [];
      global.myBlockedlist = [];

      global.myBlacklist = user.blacklist;
      global.myBlocklist = user.blocklist;
      global.myBlackedlist = user.blackedlist;
      global.myBlockedlist = user.blockedlist;

      if (user.isApprover) {
        global.amApprover = true;
      } else {
        global.amApprover = false;
      }
      if (
        user.websiteJustPurchased ||
        user.websiteTrialJustSelected > 0 ||
        user.idShop
      )
        global.hasNoWebsiteYet = false;
      else global.hasNoWebsiteYet = true;

      global.updateProfile = (snapshot) => {
        const profile = snapshot.data();
        global.myName = profile.profileName || "";
        global.myBusinessName = profile.profileNameBusiness || "";
        global.myLocationWork = profile.locationWork || {};
        global.myLocationWorkAddress = profile.locationWorkAddress || "";
        global.myProfilePictureURL = profile.profilePictureURL || "";
        global.myServicesOffered = profile.profileServicesOffered || [];
        global.myJobQualifications = profile.jobQualifications || [];
        global.myJobQualificationsStatus =
          profile.jobQualificationsStatus || "";
        global.myNetworks = profile.networks || [];
        global.myPostIDs = profile.postIDs || [];
        if (profile.profileName === undefined || !profile.profileName) {
          console.log("No profile name - referring to new user onboarding");
          myThis.props.navigation.navigate("homeEnterDetails", {});
        }
      };

      const w1 = firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .collection("Refs")
        .doc("likedPosts")
        .get();

      let profileDoc = await firebase
        .firestore()
        .collection("Profiles")
        .doc(user.idProfile)
        .get();
      global.updateProfile(profileDoc);
      if (profileDoc.data() && !profileDoc.data().profileName) {
        console.log("No profile name -- referring to new user onboarding");
        myThis.props.navigation.navigate("homeEnterDetails", {});
      }

      myThis.unsubscribeFirestoreProfile = firebase
        .firestore()
        .collection("Profiles")
        .doc(user.idProfile)
        .onSnapshot(global.updateProfile);

      const lDoc = await w1;
      const l = lDoc.data();
      global.myLikedPostIDs = l.postIDs;
      global.myDislikedPostIDs = l.dislikedPostIDs;

      if (!global.lastViewedPostIDs) {
        const lvpJSON = await AsyncStorage.getItem("lastViewedPostIDs")
        if (lvpJSON !== null) {
          const lastViewedPostIDs = JSON.parse(lvpJSON);
          if (!Array.isArray(lastViewedPostIDs)) console.warn("Error YY88")
          if (lastViewedPostIDs && Array.isArray(lastViewedPostIDs)) global.lastViewedPostIDs = lastViewedPostIDs
        }
      }

      myThis.setState({ globalsSet: true });
      global.globalsSet = true;
      console.log("GLOBALS SET!");

      const dat7 = new Date();
      console.log("dat 2 time elapsed", dat2 - dat1);
      console.log("dat 3 time elapsed", dat3 - dat1);
      console.log("dat 4 time elapsed", dat4 - dat1);
      console.log("dat 5 time elapsed", dat5 - dat1);
      console.log("dat 6 time elapsed", dat6 - dat1);
      console.log("dat 7 time elapsed", dat7 - dat1);

      //global.updateUsageCount()
    } catch (err) {
      //alert("Error setting global variables");
      console.log("Error setting global variables", err);
    }
    return;
  };
  global.setGlobalPermissions = async () => {
    const { status1 } = await Permissions.getAsync(Permissions.CAMERA_ROLL);
    if (status1 === "granted") {
      global.cameraRollStatus = status1;
    }
    const { status2 } = await Permissions.getAsync(Permissions.CAMERA);
    if (status2 === "granted") {
      global.cameraStatus = status2;
    }
  };
  global.duplicate = (src) => {
    return JSON.parse(JSON.stringify(src));
  };

  global.setLocationFast = async (minutes) => {
    //minutes of location age that is accepted
    try {
      const { status } = await Permissions.getAsync(Permissions.LOCATION);
      if (status !== "granted") {
        if (!global.location) {
          global.location = {
            coords: { latitude: 6.61, longitude: 3.31 },
            address: "Lagos, Nigeria",
          };
          global.myLocation = global.location;
        }
        return false;
      }
      if (minutes && minutes > 0) {
        const msThreshold = minutes * 60 * 1000;
        const now = new Date();
        const msSinceLastUpdated = now - global.locationLastUpdated;
        if (msSinceLastUpdated <= msThreshold) {
          // location still accurate / still within minutes threshold
          if (msSinceLastUpdated > 50000) global.setLocation(); // location older than 50 secs, so update for future use
          return true;
        }
      }
      const myLocation = await Location.getCurrentPositionAsync({
        accuracy: 3,
        maximumAge: 1000,
      });
      if (myLocation && myLocation.coords) {
        global.myLocation = myLocation;
        global.location = myLocation;
        global.locationLastUpdated = new Date();
        global.setLocation();
        return true;
      } else {
        console.log("Error. Wrong location format..", myLocation);
        global.setLocation();
        return false;
      }
    } catch (err) {
      console.log("error cx1", err);
      return false;
    }
  };
  global.setLocation = async (minutes) => {
    try {
      const { status } = await Permissions.getAsync(Permissions.LOCATION);
      if (status !== "granted") {
        if (!global.location) {
          global.location = {
            coords: { latitude: 6.61, longitude: 3.31 },
            address: "Lagos, Nigeria",
          };
          global.myLocation = global.location;
        }
        return false;
      }
      if (minutes && minutes > 0) {
        const msThreshold = minutes * 60 * 1000;
        const now = new Date();
        const msSinceLastUpdated = now - global.locationLastUpdated;
        if (msSinceLastUpdated <= msThreshold) {
          // location still accurate / still within minutes threshold
          return true;
        }
      }
      const myLocation = await Location.getCurrentPositionAsync({
        accuracy: 5,
      });
      if (
        myLocation &&
        myLocation.coords &&
        myLocation.coords.latitude &&
        myLocation.coords.longitude
      ) {
        global.myLocation = myLocation;
        global.location = myLocation;
        global.locationLastUpdated = new Date();
        AsyncStorage.setItem("latitude", myLocation.coords.latitude.toString());
        AsyncStorage.setItem(
          "longitude",
          myLocation.coords.longitude.toString()
        );
        return true;
      } else {
        console.log("Error. Wrong location format", myLocation);
        return false;
      }
      //console.log("global location: ", global.location)
    } catch (err) {
      if (!global.location) {
        global.location = {
          coords: { latitude: 6.6, longitude: 3.3 },
          address: "Lagos, Nigeria",
        };
        global.myLocation = global.location;
      }
      console.log(
        "error cx2: Location not found despite permission granted",
        err
      );
      console.warn("cx2: Location not found despite permission granted");
      return false;
    }
  };

  global.discussionFeed = [];
  global.setSavedDiscussionFeed = async () => {
    try {
      let j = await AsyncStorage.getItem("discussionFeed");
      if (j !== null) {
        const df = JSON.parse(j);
        if (Array.isArray(df)) global.discussionFeed = df;
      }
      j = await AsyncStorage.getItem("communityEventsCurrent");
      if (j !== null) {
        const df = JSON.parse(j);
        if (Array.isArray(df)) global.communityEventsCurrent = df;
      }
      j = await AsyncStorage.getItem("communityEventsFuture");
      if (j !== null) {
        const df = JSON.parse(j);
        if (Array.isArray(df)) global.communityEventsFuture = df;
      }
      j = await AsyncStorage.getItem("communityEventsPast");
      if (j !== null) {
        const df = JSON.parse(j);
        if (Array.isArray(df)) global.communityEventsPast = df;
      }
      j = await AsyncStorage.getItem("communityTasksOpen");
      if (j !== null) {
        const df = JSON.parse(j);
        if (Array.isArray(df)) global.communityTasksOpen = df;
      }
      j = await AsyncStorage.getItem("communityTasksCompleted");
      if (j !== null) {
        const df = JSON.parse(j);
        if (Array.isArray(df)) global.communityTasksCompleted = df;
      }
      j = await AsyncStorage.getItem("communityTasksOverdue");
      if (j !== null) {
        const df = JSON.parse(j);
        if (Array.isArray(df)) global.communityTasksOverdue = df;
      }
      return true
    } catch (e) {
      console.warn("ERROR 50");
      console.log(e);
      return false;
    }
  };
  // calculate last occurrence of event if it is repeating and return it in milliseconds
  global.calculateLastOccurrence = (oMillis, repeat) => {
    let eventDateOne = new Date(oMillis)
    let pastDate;

    if (repeat === "daily") {
      pastDate = global.addDays(eventDateOne, -1);
    }
    if (repeat === "weekdays") {
      pastDate = global.addDays(eventDateOne, -1);
      if (pastDate.getDay() === 0) pastDate = global.addDays(pastDate, -2);//wasSunday
      if (pastDate.getDay() === 6) pastDate = global.addDays(pastDate, -1);//wasSaturday
    }
    if (repeat === "weekly") {
      pastDate = global.addDays(eventDateOne, -7);
    }
    if (repeat === "monthly") {
      pastDate = new Date(eventDateOne.setMonth(eventDateOne.getMonth() - 1));
    }
    if (repeat === "yearly") {
      pastDate = new Date(eventDateOne.setMonth(eventDateOne.getMonth() - 12));
    }
    if (["1st", "2nd", "3rd", "last"].includes(repeat)) {
      pastDate = new Date(eventDateOne.getFullYear(), eventDateOne.getMonth() + 0, 0, eventDateOne.getHours(), eventDateOne.getMinutes()); //end of last month
      let i = 1
      if (repeat === "2nd") i = 8
      if (repeat === "3rd") i = 15
      if (repeat === "last") i = pastDate.getDate()
      pastDate.setDate(i)
      while (pastDate.getDay() !== eventDateOne.getDay()) {
        if (repeat === "last") i -= 1
        else i += 1
        pastDate.setDate(i)
      }
    }

    return pastDate.getTime()
  }
  global.renderTaskSelector = (myThis, selection, caption) => {

    return (<TouchableOpacity onPress={() => { myThis.setState({ taskSelection: selection }) }}>
      {myThis.state.taskSelection == selection
        ? <View style={{ backgroundColor: "black", padding: 1, paddingHorizontal: 3, borderRadius: 5 }}>
          <Text style={{ fontSize: 10, fontWeight: "bold", color: "#A8CF45" }}>{caption}</Text>
        </View>
        : <Text style={{ fontSize: 10, margin: 1 }}>{caption}</Text>}
    </TouchableOpacity>)
  }
  global.renderEvents = (myThis, eventArray, title) => {
    return (<View>
      {!!title && <Text style={{ color: "#000", fontSize: 10, fontWeight: "bold", marginTop: 8 }}>{title.toUpperCase?.()}</Text>}
      {eventArray.map(event => {
        const n = myThis.state.myNotifications?.[event.xID]
        const dateOne = new Date(event.eventMillisOne)
        let hours = dateOne.getHours()
        let minutes = dateOne.getMinutes()
        if (hours < 10) hours = "0" + hours
        if (minutes < 10) minutes = "0" + minutes
        let timeString = ""
        if (dateOne < global.addDays(new Date(), 7)) timeString += global.dayStrings[dateOne?.getDay?.() || 0]?.substring?.(0, 3)
        else timeString += (dateOne.getMonth() + 1) + "/" + dateOne.getDate()
        timeString += " " + (hours) + ":" + (minutes) + " -"
        return (
          <TouchableOpacity
            key={event.xID + n}
            onPress={() => {
              //global.navigateChatGroup(myThis.props.navigation, { group: event })
              myThis.props.navigation.setParams({ group: event })
              myThis.setState({ activeGroup: event })
            }}
          >
            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", height: 22 }}>
              <Text numberOfLines={1} style={{ flex: 1, color: "#000", fontSize: 12 }}>🕑 {timeString} {event.groupTitle}</Text>
              <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>

                <View
                  style={{
                    marginRight: 6, justifyContent: "flex-start", alignItems: "center",
                    flexDirection: "row", minWidth: 20
                  }}
                >
                  {event.xIsMuted && <Image
                    style={{ height: 14, width: 14, tintColor: "#334", marginTop: 2 }}
                    source={require("../images/icons/Muted.png")}
                  />}
                </View>
                <View
                  style={{
                    marginRight: 6, justifyContent: "flex-start", alignItems: "center",
                    flexDirection: "row", minWidth: 45, marginTop: 2
                  }}
                >
                  {event.nOfComments >= 0 && <Image
                    style={{ height: 13, width: 14, marginRight: 4, tintColor: "#334" }}
                    source={require("../images/icons/Chat.png")}
                  />}
                  {event.nOfComments >= 0 && <Text style={{ fontSize: 10, color: "#334", fontWeight: "500" }}>{event.nOfComments || 0}</Text>}
                  {n > 0 && <View style={{ backgroundColor: "#334", height: 15, width: 22, borderRadius: 8, alignItems: "center", marginLeft: 8, borderWidth: 1, borderColor: "#000", justifyContent: "center" }}>
                    <Text style={{ color: "#A8CF45", fontSize: 10, fontWeight: "bold" }}>{n}</Text>
                  </View>}
                </View>
              </View>
            </View>
          </TouchableOpacity>)
      })}
    </View>)
  }
  global.renderTasks = (myThis, taskArray, title) => {
    return (<View>
      {!!title && <Text style={{ color: "#000", fontSize: 10, fontWeight: "bold", marginTop: 8 }}>{title.toUpperCase?.()}</Text>}
      {taskArray.map(task => {
        const n = myThis.state.myNotifications?.[task.xID]
        const dateOne = new Date(task.taskMillisOne)
        let hours = dateOne.getHours()
        let minutes = dateOne.getMinutes()
        if (hours < 10) hours = "0" + hours
        if (minutes < 10) minutes = "0" + minutes
        const timeString = (hours) + ":" + (minutes)
        let oStatus = "open"
        if (Date.now() > task.taskMillisOne) oStatus = "overdue"

        return (
          <TouchableOpacity
            key={task.xID + n}
            onPress={() => {
              //global.navigateChatGroup(myThis.props.navigation, { group: task })
              myThis.props.navigation.setParams({ group: task })
              myThis.setState({ activeGroup: task })
            }}
          >
            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", height: 22 }}>
              <Face pictureURL={task.ownerPictureURL} />
              <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
                <Text numberOfLines={1} style={{ flex: 1, color: "#000", fontSize: 12 }}> {task.groupTitle}</Text>
                {task.taskAssignees.map(pID => {
                  const status = task.taskAssigneesDone.includes(pID) ? "completed" : oStatus
                  if (status == "completed") return
                  return <Face profileID={pID} status={status} key={pID} />
                })}
                {/* {task.taskAssigneesDone.length > 0 && <Text style={{ color: "#000", fontSize: 16, marginRight:-1 }}>✓</Text>} */}
                {task.taskAssignees.map(pID => {
                  const status = task.taskAssigneesDone.includes(pID) ? "completed" : oStatus
                  if (status != "completed") return
                  return <Face profileID={pID} status={status} key={pID} />
                })}
              </View>
              <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>

                <View
                  style={{
                    marginRight: 6, justifyContent: "flex-start", alignItems: "center",
                    flexDirection: "row", minWidth: 20
                  }}
                >
                  {task.xIsMuted && <Image
                    style={{ height: 14, width: 14, tintColor: "#334", marginTop: 2 }}
                    source={require("../images/icons/Muted.png")}
                  />}
                </View>
                <View
                  style={{
                    marginRight: 6, justifyContent: "flex-start", alignItems: "center",
                    flexDirection: "row", minWidth: 45, marginTop: 2
                  }}
                >
                  {task.nOfComments >= 0 && <Image
                    style={{ height: 13, width: 14, marginRight: 4, tintColor: "#334" }}
                    source={require("../images/icons/Chat.png")}
                  />}
                  {task.nOfComments >= 0 && <Text style={{ fontSize: 10, color: "#334", fontWeight: "500" }}>{task.nOfComments || 0}</Text>}
                  {n > 0 && <View style={{ backgroundColor: "#334", height: 15, width: 22, borderRadius: 8, alignItems: "center", marginLeft: 8, borderWidth: 1, borderColor: "#000", justifyContent: "center" }}>
                    <Text style={{ color: "#A8CF45", fontSize: 10, fontWeight: "bold" }}>{n}</Text>
                  </View>}
                </View>
              </View>
            </View>
          </TouchableOpacity>)
      })
      }
    </View >)
  }
  global.renderAllTasksAndEvents = (myThis, takeFromGlobal) => {

    //console.log("renderAllTasksAndEvents")

    const currentEvents = takeFromGlobal ? (global.communityEventsCurrent || []) : myThis.state.currentEvents
    const futureEvents = takeFromGlobal ? (global.communityEventsFuture || []) : myThis.state.futureEvents
    const pastEvents = takeFromGlobal ? (global.communityEventsPast || []) : myThis.state.pastEvents

    currentEvents.sort((a, b) => { return (a.eventMillisOne - b.eventMillisOne) })
    futureEvents.sort((a, b) => { return (a.eventMillisOne - b.eventMillisOne) })
    pastEvents.sort((a, b) => { return (a.eventMillisOne - b.eventMillisOne) })

    let futureEventNotifications = 0, pastEventNotifications = 0
    futureEvents.forEach(event => {
      if (myThis.state.myNotifications?.[event.xID]) futureEventNotifications += myThis.state.myNotifications?.[event.xID]
    })
    pastEvents.forEach(event => {
      if (myThis.state.myNotifications?.[event.xID]) pastEventNotifications += myThis.state.myNotifications?.[event.xID]
    })

    const isMyTask = (t) => {
      if (t.taskAssignees.includes(global.myProfileID) && !t.taskAssigneesDone.includes(global.myProfileID)) return true
      if (t.moderatorProfileIDs.includes(global.myProfileID)) return true
      return false
    }
    const my_openTasks = takeFromGlobal ? (global.communityTasksOpen || []) : myThis.state.openTasks
    const my_completedTasks = takeFromGlobal ? (global.communityTasksCompleted || []) : myThis.state.completedTasks
    const my_overdueTasks = takeFromGlobal ? (global.communityTasksOverdue || []) : myThis.state.overdueTasks
    let openTasks = my_openTasks.filter(t => isMyTask(t))
    let overdueTasks = my_overdueTasks.filter(t => isMyTask(t))
    let completedTasks = my_completedTasks.filter(t => isMyTask(t))
    const isNoTasksForYou = ((openTasks.length == 0 && overdueTasks.length == 0 && completedTasks.length == 0)
      && (my_openTasks.length > 0 || my_overdueTasks.length > 0 || my_completedTasks.length > 0))
    if (myThis.state.taskSelection == "ALL") {
      openTasks = my_openTasks
      overdueTasks = my_overdueTasks
      completedTasks = my_completedTasks
    }
    //console.log({ my_completedTasks, my_openTasks, my_overdueTasks })

    return (<View key={myThis}>{currentEvents.length == 0 && !myThis.state.showAllEvents &&
      (futureEvents.length > 0 ? <TouchableOpacity activeOpacity={1}
        style={{ paddingTop: 12 }}
        onPress={() => { myThis.setState({ showAllEvents: !myThis.state.showAllEvents }) }}>
        <View style={{ marginTop: 2, borderColor: "#303034", borderWidth: 0, padding: 5, borderRadius: 7, flexDirection: "row", alignItems: "center" }}>
          <Text style={{ color: "#aab", fontSize: 12 }}>{futureEvents.length} future event{futureEvents.length > 1 && "s"}</Text>
          {futureEventNotifications > 0 && <View style={{ backgroundColor: "#A8CF45", height: 17, width: 22, borderRadius: 8, alignItems: "center", marginLeft: 8, borderWidth: 1, borderColor: "#A8CF45", justifyContent: "center" }}>
            <Text style={{ color: "#000", fontSize: 10 }}>{futureEventNotifications}</Text>
          </View>}
        </View>
      </TouchableOpacity>
        : pastEvents.length > 0 && <TouchableOpacity activeOpacity={1}
          style={{ paddingTop: 12 }}
          onPress={() => { myThis.setState({ showAllEvents: !myThis.state.showAllEvents }) }}>
          <View style={{ marginTop: 2, borderColor: "#303034", borderWidth: 0, padding: 5, borderRadius: 7, flexDirection: "row", alignItems: "center" }}>
            <Text style={{ color: "#aab", fontSize: 12 }}>{pastEvents.length} past event{pastEvents.length > 1 && "s"}</Text>
            {pastEventNotifications > 0 && <View style={{ backgroundColor: "#A8CF45", height: 17, width: 22, borderRadius: 8, alignItems: "center", marginLeft: 8, borderWidth: 1, borderColor: "#A8CF45", justifyContent: "center" }}>
              <Text style={{ color: "#000", fontSize: 10 }}>{pastEventNotifications}</Text>
            </View>}
          </View>
        </TouchableOpacity>)}

      {(myThis.state.showAllEvents || currentEvents.length > 0) && <View style={{ marginTop: 2, backgroundColor: "#A8CF45", opacity: 0.75, borderWidth: 0, padding: 5, borderRadius: 7 }}>
        {currentEvents.length > 0 && global.renderEvents(myThis, currentEvents)}
        {!myThis.state.showAllEvents ? (futureEvents.length > 0 ? <TouchableOpacity
          onPress={() => { myThis.setState({ showAllEvents: true }) }}
          style={{ marginTop: 2, justifyContent: "flex-end", flexDirection: "row", alignItems: "center" }}>
          {(futureEventNotifications + pastEventNotifications) > 0 && <View style={{ backgroundColor: "#000", height: 17, width: 22, borderRadius: 8, alignItems: "center", marginRight: 5, borderWidth: 1, borderColor: "#000", justifyContent: "center" }}>
            <Text style={{ color: "#A8CF45", fontSize: 10, fontWeight: "bold" }}>{futureEventNotifications + pastEventNotifications}</Text>
          </View>}
          <Text style={{ color: "#000", fontSize: 10 }}>{futureEvents.length} future event{futureEvents.length > 1 && "s"} <Text style={{ fontSize: 9 }}>&gt;</Text>  </Text>
        </TouchableOpacity>
          : pastEvents.length > 0 && <TouchableOpacity
            onPress={() => { myThis.setState({ showAllEvents: true }) }}
            style={{ marginTop: 2, justifyContent: "flex-end", flexDirection: "row", alignItems: "center" }}>
            {(pastEventNotifications) > 0 && <View style={{ backgroundColor: "#000", height: 17, width: 22, borderRadius: 8, alignItems: "center", marginRight: 5, borderWidth: 1, borderColor: "#000", justifyContent: "center" }}>
              <Text style={{ color: "#A8CF45", fontSize: 10, fontWeight: "bold" }}>{pastEventNotifications}</Text>
            </View>}
            <Text style={{ color: "#000", fontSize: 10 }}>{pastEvents.length} past event{futureEvents.length > 1 && "s"} <Text style={{ fontSize: 9 }}>&gt;</Text>  </Text>
          </TouchableOpacity>)
          : <View>
            {futureEvents.length > 0 && global.renderEvents(myThis, futureEvents, "Future Events")}
            {pastEvents.length > 0 && global.renderEvents(myThis, pastEvents, "Past Events")}
            <TouchableOpacity
              onPress={() => { myThis.setState({ showAllEvents: false }) }}
              style={{ paddingTop: 2, alignItems: "flex-end" }}>
              <Text style={{ color: "#334", fontSize: 10 }}>Show less ^  </Text>
            </TouchableOpacity>
          </View>}
      </View>}

      {openTasks.length == 0 && overdueTasks.length == 0 && !myThis.state.showAllTasks &&
        completedTasks.length > 0 && <TouchableOpacity activeOpacity={1}
          style={{ paddingTop: 12 }}
          onPress={() => { myThis.setState({ showAllTasks: !myThis.state.showAllTasks }) }}>
          <View style={{ marginTop: 2, borderColor: "#303034", borderWidth: 0, padding: 5, borderRadius: 7 }}>
            <Text style={{ color: "#aab", fontSize: 12 }}>{completedTasks.length} completed task{completedTasks.length > 1 && "s"}</Text>
          </View>
        </TouchableOpacity>}

      {isNoTasksForYou && !myThis.state.showAllTasks && <TouchableOpacity activeOpacity={1}
        style={{ paddingTop: 12 }}
        onPress={() => { myThis.setState({ showAllTasks: !myThis.state.showAllTasks }) }}>
        <View style={{ marginTop: 2, borderColor: "#303034", borderWidth: 0, padding: 5, borderRadius: 7 }}>
          <Text style={{ color: "#aab", fontSize: 12 }}>No task for you</Text>
        </View>
      </TouchableOpacity>}

      {(myThis.state.showAllTasks || openTasks.length > 0 || overdueTasks.length > 0) && <View style={{ marginTop: 2, backgroundColor: "#A8CF45", opacity: 0.75, borderWidth: 0, padding: 5, borderRadius: 7 }}>
        <View style={{ alignSelf: "center", flexDirection: "row", alignItems: "center" }}>
          {global.renderTaskSelector(myThis, "MY", "  MY TASKS  ")}
          {global.renderTaskSelector(myThis, "ALL", "  ALL TASKS  ")}
        </View>
        {overdueTasks.length > 0 && global.renderTasks(myThis, overdueTasks, "Overdue Tasks")}
        {openTasks.length > 0 && global.renderTasks(myThis, openTasks, "Open Tasks")}
        {!myThis.state.showAllTasks ? (completedTasks.length > 0 && <TouchableOpacity
          onPress={() => { myThis.setState({ showAllTasks: true }) }}
          style={{ marginTop: 2, alignItems: "flex-end" }}>
          <Text style={{ color: "#000", fontSize: 10 }}>{completedTasks.length} completed task{completedTasks.length > 1 && "s"} <Text style={{ fontSize: 9 }}>&gt;</Text>  </Text>
        </TouchableOpacity>)
          : <View>
            {completedTasks.length > 0 && global.renderTasks(myThis, completedTasks, "Completed Tasks")}
            <TouchableOpacity
              onPress={() => { myThis.setState({ showAllTasks: false, taskSelection: "MY", }) }}
              style={{ paddingTop: 2, alignItems: "flex-end" }}>
              <Text style={{ color: "#334", fontSize: 10 }}>Show less ^  </Text>
            </TouchableOpacity>
          </View>}
      </View>}
    </View>)
  }
  global.setDiscussionFeed = async (minutes, limit) => {
    //minutes of feed age that is accepted // limit: max number of results
    if (minutes && minutes > 0) {
      const msThreshold = minutes * 60 * 1000;
      const now = new Date();
      const msSinceLastUpdated = now - global.discussionFeedLastUpdated;
      if (msSinceLastUpdated <= msThreshold) {
        // feed still accurate / still within minutes threshold
        return true;
      }
    }
    try {
      if (!global.myUID || !global.idToken) await global.timeout(1000);
      if (!global.myUID || !global.idToken) await global.timeout(1000);
      if (!global.myUID || !global.idToken) await global.timeout(3000);
      if (!global.myUID || !global.idToken) await global.timeout(10000);
      if (!global.myUID || !global.idToken) return false;
      if (!global.location) await global.setLocation();
      const r = await fetch(global.cloudFunctionURL + "findGroups", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          isWeb: true,
          isWeb: true,
          limit,
          type: "myFeed",
          coords: global.location.coords,
          myUID: global.myUID,
          idToken: global.idToken,
        }),
      });
      const r2 = await r.json();

      //console.log("Received groups. ", r2);
      if (r2.msg === "SUCCESS") {
        if (Array.isArray(r2.groups)) {
          const groups = r2.groups.filter(group => { return (group.aType != "event" && group.aType != "task") })

          const events = r2.groups.filter(group => { return (group.aType == "event") })
          let currentEvents = [], futureEvents = [], pastEvents = []
          events.forEach(event => {
            const o = event.eventMillisOne || 0
            const n = new Date() * 1
            let o2 = o
            if (event.eventOptions?.repeat && event.eventMillisOne) {
              o2 = global.calculateLastOccurrence(o, event.eventOptions?.repeat)
            }
            if ((o > (n - 2 * 3600000)) && (o <= (n + 22 * 3600000))) currentEvents.push(event)
            else if ((o2 > (n - 2 * 3600000)) && (o2 <= (n + 22 * 3600000))) currentEvents.push(event)
            else if (o > (n + 22 * 3600000)) futureEvents.push(event)
            else pastEvents.push(event)
          })

          const tasks = r2.groups.filter(group => { return (group.aType == "task") })
          let openTasks = [], completedTasks = [], overdueTasks = []
          tasks.forEach(task => {
            const assigneesNotDone = task.taskAssignees.filter(a => !task.taskAssigneesDone.includes(a))
            const o = task.taskMillisOne || 0
            const n = new Date() * 1
            if (o > n) openTasks.push(task)
            else if (assigneesNotDone.length === 0) completedTasks.push(task)
            else overdueTasks.push(task)
          })
          await AsyncStorage.setItem("discussionFeed", JSON.stringify(groups));
          await AsyncStorage.setItem("communityEventsCurrent", JSON.stringify(currentEvents))
          await AsyncStorage.setItem("communityEventsFuture", JSON.stringify(futureEvents))
          await AsyncStorage.setItem("communityEventsPast", JSON.stringify(pastEvents))
          await AsyncStorage.setItem("communityTasksOpen", JSON.stringify(openTasks))
          await AsyncStorage.setItem("communityTasksCompleted", JSON.stringify(completedTasks))
          await AsyncStorage.setItem("communityTasksOverdue", JSON.stringify(overdueTasks))
          global.discussionFeed = groups
          global.communityEventsCurrent = currentEvents
          global.communityEventsFuture = futureEvents
          global.communityEventsPast = pastEvents
          global.communityTasksOpen = openTasks
          global.communityTasksCompleted = completedTasks
          global.communityTasksOverdue = overdueTasks

          global.discussionFeedLastUpdated = new Date();
          return true;
        } else return false;
      } else {
        console.warn("findGroups returned " + r2.msg);
        console.log("findGroups returned " + r2.msg, r2);
        return false;
      }
    } catch (err) {
      global.warn?.(err, "findGroups*")
      console.warn("findGroups catch Error*");
      console.log("findGroups Error*");
      return false;
    }
  };

  global.followProfile = async (doFollow, targetProfileID) => {
    console.log("global.followProfile called: ", doFollow, targetProfileID)
    //doFollow is true for follow and false for unfollow
    if (!global.myUID || !global.myProfileID) await global.timeout(5000);
    if (!global.myUID || !global.myProfileID) return false;
    if (!targetProfileID) return false;
    if (targetProfileID == global.myProfileID) return false;
    if (global.myFollowingIDs) {
      if (doFollow) global.myFollowingIDs.push(targetProfileID);
      else
        global.myFollowingIDs = global.myFollowingIDs.filter((id) => {
          return id !== targetProfileID;
        });
    }
    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "followProfile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          isWeb: true,
          follower: global.myUID,
          followerProfile: global.myProfileID,
          targetProfile: targetProfileID,
          action: doFollow ? "Follow" : "Unfollow",
          myUID: global.myUID,
          idToken: global.idToken,
        }),
      });
      const r2 = await r.json();
      console.log("followProfile returned " + r2.msg);
      if (r2.msg === "UPDATE_COMPLETED") {
        console.log("Follow request completed (UPDATE_COMPLETED)")
        return true;
      } else {
        alert("Follow Request failed");
        if (global.myFollowingIDs) {
          if (!doFollow) global.myFollowingIDs.push(targetProfileID);
          else
            global.myFollowingIDs = global.myFollowingIDs.filter((id) => {
              return id !== targetProfileID;
            });
        }
        return false;
      }
    } catch (err) {
      alert("Request not successful.");
      console.log(err);
      if (global.myFollowingIDs) {
        if (!doFollow) global.myFollowingIDs.push(targetProfileID);
        else
          global.myFollowingIDs = global.myFollowingIDs.filter((id) => {
            return id !== targetProfileID;
          });
      }
      return false;
    }
  };

  global.shareSocial = async (message) => {
    try {
      const result = await Share.share({ message });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      global.warn(error, "shareSocial");
    }
  }

  //ASYNCSTORAGE OF GROUPCHAT COMMENTS
  // one directory of all containerIDs (asyncstorage key:allKey)
  // several containers, one per group joined (asyncstorage key:key)
  // every container looks like {xID1:{}, xID2:{}, ..., timestamp:FIRESTORE_TIMESTAMP}

  // initialData like {xID1:{}, xID2:{}, ..., timestamp:FIRESTORE_TIMESTAMP}
  // (no need to call this from outside)
  global.asyncCreateContainer = async (containerID, initialData) => {
    if (!global.myUID) return -1;
    const allKey = "allCON" + global.myUID.substring(4, 10);
    const key = "CON" + global.myUID.substring(4, 10) + containerID;
    try {
      //key
      await AsyncStorage.setItem(key, JSON.stringify(initialData));
      //allKey
      let all = [];
      const allJSON = await AsyncStorage.getItem(allKey);
      if (allJSON !== null) {
        all = JSON.parse(allJSON);
        if (!all) {
          console.warn("asyncCreateContainer issue");
          return -1;
        }
        all.push(containerID);
      }
      await AsyncStorage.setItem(allKey, JSON.stringify(all));
      return 1;
    } catch (err) {
      console.log("asyncCreateContainer error", err);
      console.warn("asyncCreateContainer error");
      return -1;
    }
  };
  // call this to remove all data from a container
  global.asyncDeleteContainer = async (containerID) => {
    if (!global.myUID) return -1;
    if (!containerID || typeof containerID != "string") {
      console.warn("Incorrect asyncDeleteContainer parameter");
      return -1;
    }
    const allKey = "allCON" + global.myUID.substring(4, 10);
    const key = "CON" + global.myUID.substring(4, 10) + containerID;
    try {
      //key
      await AsyncStorage.removeItem(key);
      //allKey
      let all = [];
      const allJSON = await AsyncStorage.getItem(allKey);
      if (allJSON !== null) {
        all = JSON.parse(allJSON);
        if (!all) {
          console.warn("asyncDeleteContainer issue");
          return -1;
        }
        all = all.filter((c) => {
          return c != containerID;
        });
      }
      await AsyncStorage.setItem(allKey, JSON.stringify(all));
      return 1;
    } catch (err) {
      console.log("asyncDeleteContainer error", err);
      console.warn("asyncDeleteContainer error");
      return -1;
    }
  };
  //call this to save data objects in a container (container can be a groupChat).
  // dataArray like this: [{xID:xxx, ...},{xID:xxx, ...}]
  global.asyncAddMultipleToContainer = async (dataArray, containerID) => {
    if (
      !containerID ||
      typeof containerID != "string" ||
      !Array.isArray(dataArray)
    ) {
      console.warn("Incorrect asyncAddMultipleToContainer parameter");
      return -1;
    }
    if (!global.myUID) return -1;
    const key = "CON" + global.myUID.substring(4, 10) + containerID;
    try {
      //key
      const storedJSON = await AsyncStorage.getItem(key);
      let stored = JSON.parse(storedJSON);
      if (!stored) {
        const res = await global.asyncCreateContainer(containerID, {});
        if (res != 1) {
          console.warn("asyncSaveMultiple issue");
          return -1;
        }
        stored = {};
      }
      dataArray.forEach((data) => {
        stored[data.xID] = data;
      });
      await AsyncStorage.setItem(key, JSON.stringify(stored));
      return 1;
    } catch (err) {
      console.log("asyncSaveMultiple error", err);
      console.warn("asyncSaveMultiple error");
      return -1;
    }
  };
  // call this to delete data elements from a container.
  // idArray like this: [xxx,xxx,...]       //???: ,"timestamp"]
  global.asyncDeleteMultipleFromContainer = async (idArray, containerID) => {
    if (
      !containerID ||
      typeof containerID != "string" ||
      !Array.isArray(idArray)
    ) {
      console.warn("Incorrect asyncDeleteMultipleFromContainer parameter");
      return -1;
    }
    if (!global.myUID) return -1;
    const key = "CON" + global.myUID.substring(4, 10) + containerID;
    try {
      const storedJSON = await AsyncStorage.getItem(key);
      let stored = JSON.parse(storedJSON);
      if (!stored) stored = {};
      idArray.forEach((id) => {
        delete stored[id];
      });
      await AsyncStorage.setItem(key, JSON.stringify(stored));
      return 1;
    } catch (err) {
      console.log("asyncDeleteMultiple error", err);
      console.warn("asyncDeleteMultiple error");
      return -1;
    }
  };
  // call this to get all data in a stored container
  // returns {xID1:{}, xID2:{}, ...,  timestamp:FIRESTORE_TIMESTAMP}
  global.asyncGetContainer = async (containerID) => {
    if (!containerID || typeof containerID != "string") {
      console.warn("Incorrect asyncGetContainer parameter");
      return -1;
    }
    if (!global.myUID) return -1;
    const key = "CON" + global.myUID.substring(4, 10) + containerID;
    try {
      const storedJSON = await AsyncStorage.getItem(key);
      let stored = JSON.parse(storedJSON);
      if (!stored) {
        console.log("Trying to get container which is not yet stored.");
        return -1;
      } else return stored;
    } catch (err) {
      console.log("asyncGet error", err);
      console.warn("asyncGet error");
      return -1;
    }
  };
  // call this to get all data in a stored container as an array
  // returns [{xID:xxx, ...}, {xID:xxx, ...}, ...], no timestamp
  global.asyncGetContainerAsArray = async (containerID) => {
    if (!containerID || typeof containerID != "string") {
      console.warn("Incorrect asyncGetContainerAsArray parameter");
      return -1;
    }
    const containerOb = await global.asyncGetContainer(containerID);
    const array = [];
    let keys = Object.keys(containerOb);
    keys.map((key) => {
      const value = containerOb[key];
      if (key != "timestamp" && typeof value == "object" && value) {
        value.xID = key;
        array.push(value);
      }
    });
    if (!Array.isArray(array)) {
      console.warn("error in asyncGetContainerAsArray");
      return [];
    }
    return array;
  };
  // only upon logout
  global.asyncDeleteAllContainers = async () => {
    if (!global.myUID) return -1;
    const allKey = "allCON" + global.myUID.substring(4, 10);
    try {
      //allKey
      let all = [];
      const allJSON = await AsyncStorage.getItem(allKey);
      if (allJSON !== null) {
        all = JSON.parse(allJSON);
        if (!all) {
          console.warn("asyncDeleteAllContainers issue");
          return -1;
        }
      }
      await AsyncStorage.removeItem(allKey);
      //key
      promises = [];
      all.forEach((containerID) => {
        const key = "CON" + global.myUID.substring(4, 10) + containerID;
        promises.push(AsyncStorage.removeItem(key));
      });
      await Promise.all(promises);
      return 1;
    } catch (err) {
      console.log("asyncDeleteAllContainers error", err);
      console.warn("asyncDeleteAllContainers error");
      return -1;
    }
  };
  // returns [xxx,xxx]
  // not used yet
  global.getAllContainerIDsAsync = async () => {
    if (!global.myUID) return -1;
    const allKey = "allCON" + global.myUID.substring(4, 10);
    try {
      let all = [];
      const allJSON = await AsyncStorage.getItem(allKey);
      if (allJSON !== null) {
        all = JSON.parse(allJSON);
        if (!all) {
          console.warn("getAllContainerIDs issue");
          return -1;
        }
      }
      return all;
    } catch (err) {
      console.log("getAllContainerIDsAsync error", err);
      return -1;
    }
  };

  //returns an array of postIDs
  global.getRecommendationsAsync = async (length, params) => {
    if (typeof length !== "number") {
      console.warn("Incorrect getRecommendationsAsync parameter");
      return [];
    }
    try {
      //RECOMMENDATIONS look like this: [ {postID:String, timeSetMS:Number(miliseconds), weight:Number}, ...]
      const storedJSON = await AsyncStorage.getItem("RECOMMENDATIONS");
      if (!storedJSON) return []
      let REC = JSON.parse(storedJSON);
      if (!REC) console.warn("getRecommendationsAsync error1")
      if (!REC) return []
      const allPostIDs = REC.map(R => {
        return R.postID
      })
      const nowDate = new Date()
      const nowMS = nowDate.getTime();
      const allPostIDsUnique = allPostIDs.filter((id, i, self) => self.indexOf(id) === i)
      //console.log({ allPostIDsUnique })
      const scoredRecommendations = allPostIDsUnique.map(id => {
        const scoresForThisItem = REC.map(R => {
          if (R.postID == id) {
            const miliseconds = R.timeSetMS;
            const msDifference = nowMS - miliseconds
            const daysDifference = msDifference / (1000 * 60 * 60 * 24)
            let itemScore = (10 + (100 / (daysDifference + 2))) * R.weight
            if (params?.rotateFast) itemScore = (100 / (daysDifference + 0.01)) * R.weight
            if (params?.getLatest) itemScore = (100 / (daysDifference + 0.01))
            return itemScore
          }
        })
        //console.log({ scoresForThisItem })
        if (scoresForThisItem.length < 1) console.warn("Error YY7")
        let score = 0;
        scoresForThisItem.forEach(itemScore => {
          if (itemScore) score += itemScore
        })
        return { postID: id, score }
      })
      //console.log({ scoredRecommendations })
      scoredRecommendations.sort((a, b) => {
        return b.score - a.score
      })
      const resultArray = scoredRecommendations.map(rec => {
        return rec.postID
      })
      if (resultArray.length > length) resultArray.length = length
      return resultArray

    } catch (err) {
      console.log("getRecommendationsAsync error", err);
      console.warn("getRecommendationsAsync error");
      return [];
    }
  };

  //sets recommended items
  //returns true or false
  global.setRecommendationsAsync = async (postIDArray, weight) => {
    // console.log("setRecommendationsAsync",postIDArray, weight)
    if (typeof weight !== "number" || !Array.isArray(postIDArray)) {
      console.warn("Incorrect setRecommendationsAsync parameter");
      return [];
    }
    try {
      //RECOMMENDATIONS look like this: [ {postID:String, timeSetMS:Number(miliseconds), weight:Number}, ...]
      const storedJSON = await AsyncStorage.getItem("RECOMMENDATIONS");
      let REC = []
      if (storedJSON) REC = JSON.parse(storedJSON);
      const nowDate = new Date()
      const nowMS = nowDate.getTime();
      REC = REC.filter(R => {
        const miliseconds = R.timeSetMS
        const msDifference = nowMS - miliseconds
        const daysDifference = msDifference / (1000 * 60 * 60 * 24)
        if (daysDifference > 100) return false
        else return true
      })
      postIDArray.forEach(postID => {
        REC.push({ postID, timeSetMS: nowMS, weight })
      })
      await AsyncStorage.setItem("RECOMMENDATIONS", JSON.stringify(REC));
      return true;
    } catch (err) {
      console.log("setRecommendationsAsync error", err);
      console.warn("setRecommendationsAsync error");
      return false;
    }
  };

  // reports anonymized usage statistics
  global.reportStats = async (event, detail1, detail2) => {
    console.log("Called " + event);
    try {
      await global.timeout(2000);
      if (global.devMode) return;
      if (!global.globalsSet) await globals.timeout(30000);
      if (!global.globalsSet || !global.myUID) return false;
      let nowDate = new Date();

      // generate userInfo
      const userInfo = {
        hasBusiness: global.myBusinessName ? true : false,
        hasWebsite: global.hasNoWebsiteYet ? false : true,
        hasOffers:
          global.myPostIDs &&
          Array.isArray(global.myPostIDs) &&
          global.myPostIDs.length > 0,
        platform: Platform.OS,
        countryCurrency: global.myLocalCurrency || "NGN",
      };

      // get aID
      let aID = "";
      const key = "aID" + global.myUID.substring(4, 10);
      const bufferKey = "aIDbuffer" + global.myUID.substring(4, 10);
      const aID_JSON = await AsyncStorage.getItem(key);
      if (aID_JSON) {
        aID = JSON.parse(aID_JSON);
      } else {
        //if it does not exist, get and store generated aID and create buffer
        userInfo.creationMonth = nowDate.getMonth() + 1;
        userInfo.creationYear = nowDate.getFullYear();
        await global.fetchIdToken()
        const r = await fetch(global.cloudFunctionURL + "reportStats", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            isWeb: true,
            isWeb: true,
            action: "generate",
            userInfo,
            myUID: global.myUID,
            idToken: global.idToken,
          }),
        });
        const r2 = await r.json();
        if (r2.msg === "SUCCESS" && r2.aID) {
          aID = r2.aID;
          await AsyncStorage.setItem(key, JSON.stringify(r2.aID));
          await AsyncStorage.setItem(bufferKey, JSON.stringify([]));
        } else {
          console.warn("ERROR in reportStats! Check backend");
          return -1;
        }
      }

      // get stat buffer and attach event
      let buffer = [];
      const bufferJSON = await AsyncStorage.getItem(bufferKey);
      if (bufferJSON !== null) {
        buffer = JSON.parse(bufferJSON);
        if (!buffer) {
          console.warn("reportStats issue");
          return false;
        }
      }
      buffer.push({
        event,
        detail1,
        detail2,
        dateCreated: nowDate,
      });

      // if >x items: send. Also if send: check if userInfo has changed and if so, update
      if (buffer.length < 5) {
        await AsyncStorage.setItem(bufferKey, JSON.stringify(buffer));
      } else {
        const userInfoKey = "aIDUserInfo" + global.myUID.substring(4, 10);
        let ui = {};
        const uiJSON = await AsyncStorage.getItem(userInfoKey);
        if (uiJSON !== null) {
          ui = JSON.parse(uiJSON);
          if (!ui) {
            console.warn("reportStats issue2");
            return false;
          }
        }
        let userInfoHasChanged = false;
        if (ui.hasBusiness !== userInfo.hasBusiness) userInfoHasChanged = true;
        if (ui.OS !== userInfo.OS) userInfoHasChanged = true;
        if (ui.hasOffers !== userInfo.hasOffers) userInfoHasChanged = true;
        if (ui.platform !== userInfo.platform) userInfoHasChanged = true;
        if (ui.countryCurrency !== userInfo.countryCurrency)
          userInfoHasChanged = true;

        await global.fetchIdToken()
        const r = await fetch(global.cloudFunctionURL + "reportStats", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            isWeb: true,
            isWeb: true,
            action: "report",
            aID,
            events: buffer,
            userInfo,
            ...(userInfoHasChanged && { userInfoHasChanged }),
            myUID: global.myUID,
            idToken: global.idToken,
          }),
        });
        const r2 = await r.json();
        if (r2.msg === "SUCCESS") {
          await AsyncStorage.setItem(bufferKey, JSON.stringify([]));
          if (userInfoHasChanged)
            await AsyncStorage.setItem(userInfoKey, JSON.stringify(userInfo));
        } else {
          await AsyncStorage.setItem(bufferKey, JSON.stringify(buffer));
        }
      }
      //call backend
    } catch (err) {
      console.log("updateUsageCount ERROR", err);
      return false;
    }
  };

  global.navigateProfile = async (navigation, profileID) => {
    try {
      let route = "meProfile";
      if (navigation.state.routeName.startsWith("home")) route = "homeProfile";
      if (navigation.state.routeName.startsWith("chat")) route = "messageProfile";
      if (navigation.state.routeName.startsWith("me")) route = "meProfile";
      if (navigation.state.routeName.startsWith("explore")) route = "exploreProfile";
      if (navigation.state.routeName.startsWith("jobs")) route = "meProfile";
      let handoverID = profileID;
      if (!global.myProfileID) await global.timeout(1000)
      if (!global.myProfileID) await global.timeout(1000)
      if (!global.myProfileID) await global.timeout(1000)
      if (handoverID == global.myProfileID) handoverID = null
      navigation.navigate({
        routeName: route, params: { profileID: handoverID }, key: handoverID || "0",
      });
    } catch (e) { global?.warn?.(e, "E-np") }
  }

  global.navigateChatGroup = async (navigation, params) => {
    try {
      let route = "meGroupDetail";
      if (navigation.state.routeName.startsWith("home")) route = "homeGroupDetail";
      if (navigation.state.routeName.startsWith("chat")) route = "chatGroupDetail";
      if (navigation.state.routeName.startsWith("message")) route = "chatGroupDetail";
      navigation.navigate({
        routeName: route, params, key: params?.group?.xID || "0",
      });
    } catch (e) { global?.warn?.(e, "E-ncg") }
  }

  global.navigateCommunity = async (navigation, params) => {
    try {
      let route = "homeCommunity"
      if (navigation.state.routeName.startsWith("home")) route = "homeCommunity"
      if (navigation.state.routeName.startsWith("chat")) route = "chatCommunity"
      navigation.navigate({
        routeName: route, params, key: params?.group?.xID || "0",
      });
    } catch (e) { global?.warn?.(e, "E-nc") }
  }

  global.addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  global.openPermissionSettings = async (type) => {
    try {
      if (Platform.OS == "ios") {
        Linking.openURL('app-settings:');
      }
      if (Platform.OS == "android") {
        const pkg = Constants.manifest?.releaseChannel
          ? Constants.manifest?.android?.package
          : 'host.exp.exponent';
        console.log({ pkg })
        if (type == "notifications") {
          IntentLauncherAndroid.startActivityAsync(
            IntentLauncherAndroid.ACTION_APP_NOTIFICATION_SETTINGS,
            { "android.provider.extra.APP_PACKAGE": pkg }
          );
        } else if (type == "location") {
          IntentLauncher.startActivityAsync(IntentLauncher.ACTION_LOCATION_SOURCE_SETTINGS);
        } else {
          Linking.openSettings()
        }
      }
    } catch (e) { global?.warn?.(e, "E-ops") }
  }

  global.renderYoutubeModal = (youtubeID, onClose) => {
    const sHeight = Dimensions.get("window").height;
    const sWidth = Dimensions.get("window").width;
    const fHeight = Math.max(sHeight - 125, sWidth - 20 + 250);
    return (<Modal animationType="fade" transparent={false} visible={true} onRequestClose={() => { onClose() }}>
      <View style={{ backgroundColor: "#000", flex: 1, paddingTop: 20 }}>
        <View
          style={{
            alignItems: "center",
            justifyContent: "flex-end",
            height: "100%",
            backgroundColor: "#000"
          }}
        >
          <WebView
            originWhitelist={['*']}
            style={{ width: sWidth, height: sHeight, marginVertical: ((sHeight - sWidth * 0.7) / 2) }}
            source={{ uri: 'https://www.youtube.com/embed/' + youtubeID }}
            javaScriptEnabled={true}
            domStorageEnabled={true}
            useWebKit={true}
          />
          <TouchableOpacity
            style={{
              position: "absolute",
              borderWidth: 0,
              backgroundColor: "#000",
              borderColor: "rgba(0,0,0,0.2)",
              alignItems: "center",
              justifyContent: "center",
              width: 35,
              top: sHeight - fHeight - 15,
              left: 5,
              height: 25,
              borderRadius: 35
            }}
            onPress={() => {
              onClose()
            }}
          >
            <Image
              style={{ width: 35, height: 35, opacity: 1 }}
              source={require("../images/icons/CancelWhite.png")}
            />
          </TouchableOpacity>
        </View>
      </View>
    </Modal>)
  }
}

global.ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;
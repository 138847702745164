import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  Modal,
  Platform,
  Dimensions,
  ActivityIndicator,
  SafeAreaView,
  ImageBackground,
  TextInput,
} from "react-native";
import * as Permissions from "expo-permissions";
import * as Location from "expo-location";
import * as Haptics from 'expo-haptics';
import firebase from "firebase";
import "firebase/firestore";
import Moment from "react-moment";
import FullscreenImage from "../FullscreenImage"
import CachedImage from "../../shared/CachedImage";
const gStyles = global.gStyles //    "../../styles/gStyles";
//import { Touchable } from "react-native";
//import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";
import FeedMenu from "../../Me/Communities/FeedMenu"

const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;

export default class BlogPost extends Component {
  state = {
    titleHeight: 50,
    pressedLike: false,
    pressedDislike: false,
    pressedJoin: this.props.amJoiner,
    showAllTrue: false,
    comment: "",
    watchingYoutube: false,
    showFeedMenu: false,
    showEditComment: this.props.editComment,
    showFullscreenImage: false,
    totalHeight: 0,
  };

  constructor(props) {
    super(props);
  }
  // async componentDidMount() {
  //   console.log("items from blogScreens", this.props.chatGroup);
  // }

  renderMain = (x) => {
    const imWidth = (Dimensions.get("window").width - 40) * 0.4;
    const imFontSize =
      x.groupTitle.length > 60
        ? 14
        : x.groupTitle.length > 40
          ? 16
          : x.groupTitle.length > 20
            ? 18
            : 22;
    const nImFontSize = x.groupTitle.length > 20 ? 18 : 22;
    const mainHeight = (imWidth / 16) * 9;

    return (
      <View
        style={{
          justifyContent: "space-between",
          alignItems: "center",

          marginVertical: 5,
        }}
      >
        <TouchableOpacity
          style={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
          activeOpacity={1}
          onPress={() => {
            if (x.groupYoutubeID) this.setState({ watchingYoutube: true })
            else this.setState({ showFullscreenImage: true })
          }}>
          <CachedImage
            style={{
              width: Dimensions.get("window").width * 0.9,
              height: 150,
              paddingRight: 8,
              borderRadius: 8.5,
            }}
            source={{ uri: x.groupPictureURL }}
            key={x.groupPictureURL}
          />
          {!!x.groupYoutubeID && <View
            style={{
              borderRadius: 14,
              paddingLeft: 6,
              height: 55,
              justifyContent: "center",
              alignItems: "center",
              width: 55,
              bottom: 92,
              marginBottom: -55
            }}
          >
            <Text style={{ color: "#333", fontSize: 42 }}>{"▶️"}</Text>
          </View>}
        </TouchableOpacity>

        <View style={{ margin: 10, padding: 10 }}>
          <Text
            style={{
              color: "white",
              fontWeight: "bold",
              fontFamily: "ProximaNovaA-Bold",
              fontSize: 28,
              marginVertical: 10,
            }}
          >
            {x.groupTitle}
          </Text>
          {/* <View
            style={{
              flexDirection: "row",
              width: Dimensions.get("window").width * 0.85,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                flexDirection: "row",

                alignItems: "center",
              }}
            >
              <Image
                style={{
                  width: 10,
                  height: 10,

                  // borderRadius: 3,
                }}
                source={require("../../images/icons/Heart.png")}
              />
              <Text
                style={{
                  color: "#A9D046",
                  fontFamily: "ProximaNovaA-Regular",
                  fontSize: 10,
                  padding: 3,
                }}
              >
                {x.nOfLikes} Likes
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                width: "40%",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity>
                  <MaterialCommunityIcons
                    name="share-outline"
                    size={12}
                    color="#A9D046"
                  />
                </TouchableOpacity>
                <Text
                  style={{
                    color: "#A9D046",
                    fontFamily: "ProximaNovaA-Regular",
                    fontSize: 12,
                    padding: 3,
                  }}
                >
                  share
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity>
                  <AntDesign name="download" size={12} color="#A9D046" />
                </TouchableOpacity>
                <Text
                  style={{
                    color: "#A9D046",
                    fontFamily: "ProximaNovaA-Regular",
                    fontSize: 12,
                    padding: 3,
                  }}
                >
                  save
                </Text>
              </View>
            </View>
          </View> */}

          <Text
            style={{
              color: "#F0F4F7",
              marginVertical: 4,
              fontFamily: "ProximaNovaA-Regular",
              fontSize: 17,
              // lineHeight: 7,
            }}
          >
            {x.groupDetails}
          </Text>
        </View>
      </View>
    );
  };

  showAll = () => {
    this.setState({ showAllTrue: !this.state.showAllTrue });
  };

  renderBottom = (x) => {
    let numberOfLineBreaks = x.groupTitle.split("\n").length - 1;

    return (
      <View
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          paddingBottom: 10,
        }}
      >
        {x.aOpenness !== "public" && (
          <View
            style={{
              marginRight: 6,
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "row",
              minWidth: 45,
            }}
          >
            <Image
              style={{
                height: 16,
                width: 20,
                marginRight: 4,
                tintColor: "#889",
              }}
              source={require("../../images/icons/People.png")}
            />
            <Text style={{ fontSize: 10, color: "#889", fontWeight: "500" }}>
              {x.nOfJoiners || 0}
            </Text>
            {Array.isArray(x.xJoinerIDs) && (
              <View style={{ marginLeft: 5, width: "75%", height: 28 }}>
                <ScrollView horizontal>
                  {x.xJoinerIDs.map((jID) => {
                    return (
                      <Member
                        profileID={jID}
                        faceOnly
                        key={jID}
                        many={x.xJoinerIDs.length > 6}
                        navigation={this.props.navigation}
                      />
                    );
                  })}
                </ScrollView>
              </View>
            )}
          </View>
        )}
        <View
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            minWidth: SCREEN_WIDTH * 0.5,
          }}
        >
          <View
            style={{
              marginRight: 6,
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              minWidth: 20,
            }}
          >
            <Image
              style={{
                height: 16,
                width: 17,
                marginRight: 4,
                tintColor: "#889",
              }}
              source={require("../../images/icons/Chat.png")}
            />
            <Text style={{ fontSize: 10, color: "#889", fontWeight: "500" }}>
              {x.nOfComments || 0}
            </Text>
          </View>
          {(x.groupTitle.length > 105 ||
            numberOfLineBreaks > 1 ||
            x.groupDetails.length > 43) && (
              <View>
                {/* <TouchableOpacity
                onPress={() => {
                  this.showAll();
                }}
              >
                <Text style={{ color: "#889" }}>
                  {this.state.showAllTrue ? "Collapse" : "Show all"}
                </Text>
              </TouchableOpacity> */}
              </View>
            )}
        </View>
        {/* <View style={{ flex: 1 }} /> */}

        {/* <View
        style={{
          marginRight: 6, justifyContent: "flex-start", alignItems: "center",
          flexDirection: "row", minWidth: 45
        }}
      >
        <Image
          style={{ height: 16, width: 17, marginRight: 4, tintColor: "#889" }}
          source={require("../../images/icons/Heart.png")}
        />
        <Text style={{ fontSize: 10, color: "#889", fontWeight: "500" }}>{x.nOfTotalLikes || 0}</Text>
      </View> */}
        {/* <View style={{ flex: 1 }} /> */}
        {/* {!this.state.pressedJoin && !this.props.amJoiner ? (
          <View
            style={{
              marginRight: 6,
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "row"
            }}
          >
            <TouchableOpacity
              style={global.gStyles.buttonX}
              onPress={() => {
                this.props.join(true);
              }}
            >
              <Text style={[global.gStyles.buttontX, { paddingHorizontal: 15 }]}>
                + JOIN
              </Text>
            </TouchableOpacity>
          </View>
        ) : this.props.view !== "LIST" ? (
          <View
            style={{
              marginRight: 6,
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "row"
            }}
          >
            <TouchableOpacity
              style={global.gStyles.buttonXW}
              onPress={() => {
                this.props.join(false);
              }}
            >
              <Text style={[global.gStyles.buttontXW, { paddingHorizontal: 15 }]}>
                LEAVE
              </Text>
            </TouchableOpacity>
          </View>
        ) : (
              <View style={{ marginRight: 6, width: 30, height: 20 }} />
            )} */}
      </View>
    );
  };

  render() {
    const x = this.props.chatGroup;
    // const lob = () => this.state.showEditComment
    // console.log('voooooo', lob().editComment());
    return (
      <SafeAreaView
        style={{
          // alignItems: "center",
          paddingHorizontal: 0,
          paddingVertical: 8,
          borderBottomWidth: 0.5,
          borderBottomColor: "#889",
        }}
        onLayout={(event) => {
          this.setState({ totalHeight: event.nativeEvent.layout.height || 1500 })
        }}
      >
        {this.props.amModerator && this.props.comments.length !== 0 && (
          <View>
            {!this.props.aaModerator ? (
              <View>
                <TouchableOpacity onPress={(x) => {
                  this.setState({ showFeedMenu: true, });
                  ///Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy)
                }}>
                  <View style={{ alignItems: "flex-end", justifyContent: "flex-end", paddingRight: 15 }}>
                    <Text style={{ fontWeight: "bold", fontSize: 24, color: "#ccd", marginTop: 4, }}>
                      ⋮
                    </Text>
                  </View>
                </TouchableOpacity>

                <FeedMenu
                  showEditComment
                  showMenuModal={this.state.showFeedMenu}
                  chatGroup={x}
                  refresh={this.props.refresh}
                  editComment={this.state.showEditComment}
                  onDone={() => { this.setState({ showFeedMenu: false }) }}
                  navigation={this.props.navigation} />

                {this.props.renderDeleteAll && <TouchableOpacity
                  onPress={() => {
                    this.props.editGroup({ action: "deleteAllComments" });
                  }}
                  style={{
                    borderColor: "#a33",
                    padding: 5,
                    margin: 0,
                    alignSelf: "flex-start"
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      height: 22,
                      width: "40%",
                      alignSelf: "center",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Image
                      style={{
                        width: 16,
                        height: 16,
                        marginRight: 3,
                        tintColor: "#a33"
                      }}
                      source={require("../../images/icons/Delete.png")}
                    />
                    <Text style={{ color: "#a33" }}>Delete All (Admin)</Text>
                  </View>
                </TouchableOpacity>}
              </View>
            ) : (
              <View
                style={{
                  flexDirection: "row", marginTop: 20, alignItems: "center", justifyContent: "center"
                }}
              >
                <ActivityIndicator size="small" color="#f00" animating={true} />
              </View>
            )}
          </View>
        )}
        <View
          style={{
            ...Platform.select({
              ios: { height: 55 },
              android: { height: 75 },
            }),
          }}
        />
        {!!this.state.watchingYoutube && global.renderYoutubeModal(x.groupYoutubeID,
          () => { this.setState({ watchingYoutube: false }) })}

        <View style={{ height: 15 }} />
        {this.renderMain(x)}

        {![
          "In45VOmKmFb23miG65GA",
          "UJcndGGQbpj6qMGC3nuX",
          "hDnIoiiWEVnMYVzxJGi3",
        ].includes(x.xID) && this.renderBottom(x)}

        {/* <View
          style={{
            borderColor: "#555",
            borderWidth: 0.4,
            width: SCREEN_WIDTH * 0.9,
          }}
        /> */}
        <ImageBackground
          style={{
            position: "absolute", top: 0, left: 0, right: 0, bottom: 0,
            width: Dimensions.get("window").width,
            height: this.state.totalHeight,
            zIndex: -1,
            opacity: this.props.blog?.blogBackgroundPictureDarken ? 0.2 : 1,
            resizeMode: "cover"
          }}
          source={{ uri: this.props.blog?.blogBackgroundPictureURL || "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2Fwood1280.jpg?alt=media&token=55b7c07a-3f36-4cab-b1bc-ae9668ad40e9" }}
        />

        <FullscreenImage on={this.state.showFullscreenImage} pictureURL={x.groupPictureURL}
          cached onClose={() => { this.setState({ showFullscreenImage: false }) }} />

      </SafeAreaView>
    );
  }
}

// class Member extends Component {
//   state = {
//     pictureURL: "",
//     name: "",
//     businessName: "",
//   };

//   async componentDidMount() {
//     try {
//       const profileDoc = await firebase
//         .firestore()
//         .collection("Profiles")
//         .doc(this.props.profileID)
//         .get();
//       const profile = profileDoc.data();
//       this.setState({
//         pictureURL: profile.profilePictureURL || "",
//         name: profile.profileName || "- No Name -",
//         businessName: profile.profileNameBusiness || "",
//       });
//     } catch (e) {
//       global.warn(e, "cdm_Member");
//     }
//   }

//   render() {
//     if (this.props.faceOnly)
//       return (
//         <TouchableOpacity
//           onPress={() => {
//             global.navigateProfile(this.props.navigation, this.props.profileID);
//           }}
//         >
//           <View
//             style={{
//               height: 28,
//               width: this.props.many ? 22 : 35,
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//           >
//             {this.state.pictureURL ? (
//               <Image
//                 style={{
//                   width: 25,
//                   height: 25,
//                   borderRadius: 12.5,
//                   opacity: 0.65,
//                 }}
//                 source={{ uri: this.state.pictureURL }}
//               />
//             ) : (
//               <View
//                 style={{
//                   width: 25,
//                   height: 25,
//                   borderRadius: 12.5,
//                   backgroundColor: "#668",
//                 }}
//               />
//             )}
//           </View>
//         </TouchableOpacity>
//       );
//     return (
//       <View
//         style={{
//           height: 35,
//           flexDirection: "row",
//           alignItems: "center",
//           marginLeft: 10,
//           alignSelf: "stretch",
//         }}
//       >
//         <View style={{ width: 33, justifyContent: "center" }}>
//           {this.state.pictureURL ? (
//             <Image
//               style={{ width: 25, height: 25, borderRadius: 12.5 }}
//               source={{ uri: this.state.pictureURL }}
//             />
//           ) : (
//             <View
//               style={{
//                 width: 25,
//                 height: 25,
//                 borderRadius: 12.5,
//                 backgroundColor: "#668",
//               }}
//             />
//           )}
//         </View>
//         <View style={{ flex: 1 }}>
//           <Text style={{ color: "#aab", fontSize: 13 }}>{this.state.name}</Text>
//           <Text style={{ color: "#889", fontSize: 10 }}>
//             {this.state.businessName}
//           </Text>
//         </View>
//       </View>
//     );
//   }
// }

import React, { Component } from 'react';
import { StyleSheet, Text, View, Image, Modal, Alert, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Location from 'expo-location';
import * as Permissions from 'expo-permissions';
import MapScreen from './mapScreen';

export default class CurrentLocation extends React.Component {

  state = {
    location: {
      coords: {
        latitude: 6.465422,
        longitude: 3.406448
      }
    },
    address: 'Lagos, Nigeria',
    fullAddressData: [],
    showModal: false,
    mapCoords: null,
    mapAddress: '',
    locationLoaded: false,
    showSavedLocations: false,
    savedLocations: [{
      address: 'bode thomas',
      coords: {
        latitude: 6.465422,
        longitude: 3.406448
      }
    }]
  }


  async respondAfterFiveSecs() {
    await new Promise(resolve => { setTimeout(resolve, 6000); });
    if (!this.state.locationLoaded) {
      this.props.onPermissionNotGranted();
    }
    console.log('5 seconds timeout ended');
  }

  _getLocationAsync = async () => {
    //console.log('ANYTHING!');
    let location = null;

    try {
      //console.log('ANYTHING!');
      if (!this.props.initialCoords || this.props.overrideInitialCoords) {
        if (this.props.initialCoords) {
          this.setState({
            location: {
              coords: this.props.initialCoords
            }
          });
          let lat = Math.round(this.props.initialCoords.latitude * 1000000) / 1000000;
          let lng = Math.round(this.props.initialCoords.longitude * 1000000) / 1000000;
          console.log('lat and lng: ', lat, lng);

          let address = await fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lng + "&key=" + "AIzaSyDxNz_ZMcLHWYEiVNW6kIH4PyI7e0NifKo");
          let addressJSON = await address.json();

          await this.setState({ fullAddressData: addressJSON });

          let ADDRESS = (addressJSON && addressJSON.results && addressJSON.results.length > 0) ? addressJSON.results[0].formatted_address : "Unknown Location";
          await this.setState({ address: ADDRESS });
        }

        let { status } = await Permissions.askAsync(Permissions.LOCATION);
        if (status !== 'granted') {

          let HideLocationAlert = await AsyncStorage.getItem("HideLocationAlert");

          if (HideLocationAlert === "true") {
            await new Promise(resolve => { setTimeout(resolve, 50); });
            Alert.alert(
              "You have not enabled location permissions. (Phone settings).",
              "",
              [
                {
                  text: "Don't show this again",
                  onPress: () => AsyncStorage.setItem("HideLocationAlert", "true"),
                  style: "cancel"
                },
                {
                  text: "OK",
                  onPress: () => {
                    console.log("OK Pressed");
                  }
                }
              ],
              { cancelable: false }
            );
            // alert('You have not enabled location permissions. (Phone settings)');
            await new Promise(resolve => { setTimeout(resolve, 50); });

          }
          if (this.props.onPermissionNotGranted) { this.props.onPermissionNotGranted() };
          return;
        }
        this.respondAfterFiveSecs();
        location = await Location.getCurrentPositionAsync({ accuracy: 3, maximumAge: 60000 });

        //alert('the location is' + location.coords.longitude);
        await this.setState({ location, locationLoaded: true });
        //console.log('ANYTHING!');
      }
      else {
        await this.setState({
          location: {
            coords: this.props.initialCoords
          }
        });
        location = this.state.location;
      }
      //let address = await Expo.Location.reverseGeocodeAsync(location.coords);

      let lat = Math.round(location.coords.latitude * 1000000) / 1000000;
      let lng = Math.round(location.coords.longitude * 1000000) / 1000000;
      console.log('lat and lng: ', lat, lng);
      //let address = await fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng=6.518,3.37629&key=AIzaSyDxNz_ZMcLHWYEiVNW6kIH4PyI7e0NifKo");

      let address = await fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lng + "&key=" + "AIzaSyDxNz_ZMcLHWYEiVNW6kIH4PyI7e0NifKo");
      let addressJSON = await address.json();
      //console.log('addressdata is: ', address );
      //console.log('address returned from Expo.Location.reverseGeocodeAsync for location: ', location.coords, ' is: ', addressJSON.results[0].formatted_address);

      await this.setState({ fullAddressData: addressJSON });

      let ADDRESS = (addressJSON && addressJSON.results && addressJSON.results.length > 0) ? addressJSON.results[0].formatted_address : "Unknown Location";
      await this.setState({ address: ADDRESS });
      this.props.onUpdate(ADDRESS, location.coords);
      //console.log('the address data is', this.state.address);
      //console.log('the address data is', this.state.address, ' \nfull address data is ', this.state.fullAddressData);

    } catch (err) {
      await new Promise(resolve => { setTimeout(resolve, 50); });
      alert(err);
      await new Promise(resolve => { setTimeout(resolve, 50); });
    };

  }


  getCoordinates = () => {

    if (this.state.location == null) { return null }
    else {
      const coords = {
        longitude: 0,
        latitude: 0
      };
      coords.latitude = this.state.location.coords.latitude;
      coords.longitude = this.state.location.coords.longitude;
      console.log('getCoordinates coordinates are: ', this.state.location.coords.latitude, this.state.location.coords.longitude);
      return coords;
    }
  }

  async  componentDidMount() {
    try {
      this._getLocationAsync();
    } catch (err) {
      console.log('\n curentLocation.js error ', err);
    }
  }

  showSavedAddress = () => {
    console.log('showSavedAddress running...')
    this.state.savedLocations.map((location, i) => {
      return (
        <Text
          key={i}
          style={{ fontSize: 15, color: 'black' }}
        >{location.address}</Text>
      );
    })
  }


  render() {
    return (
      <View style={styles.container}>
        <TouchableOpacity style={{ flex: 1, alignSelf: 'center' }}
          onPress={() => { this.setState({ showModal: true }) }}>
          <View style={{
            flex: 1, justifyContent: 'center', alignSelf: 'center',
            paddingVertical: this.props.padding ? this.props.padding : 5, paddingHorizontal: 5
          }}>

            <Text numberOfLines={1} style={{
              color: this.props.textColor ? this.props.textColor : "black",
              textAlign: 'center', fontSize: this.props.textSize ? this.props.textSize : 12,
              fontWeight: this.props.fontWeight ? this.props.fontWeight : "normal"
            }}>
              {this.state.address}
            </Text>

          </View>
        </TouchableOpacity>
        <View style={{ width: 30, paddingVertical: this.props.padding ? this.props.padding : 5, paddingHorizontal: 5 }}>
          <TouchableOpacity
            onPress={() => { this.setState({ showModal: true }) }}
          >
            <Image
              style={{
                width: 20, height: 20, alignSelf: 'center',
                ...(global.app == "speakers" && { tintColor: "#bbc" }),
                ...(this.props.iconColor && { tintColor: this.props.iconColor })
              }}
              source={require('../../images/icons/Change446.png')}
            />
          </TouchableOpacity>
        </View>

        <Modal
          animationType="slide"
          transparent={false}
          visible={this.state.showModal}
          onRequestClose={() => {
            //leave this here
          }}>
          <MapScreen
            getUpdatedLocation={(address, coords) => {
              this.setState({
                mapCoords: coords,
                mapAddress: address
              });
              console.log('mapScreen data successfully passed! address: ', this.state.mapAddress, ' coordinates passed: ', this.state.mapCoords);
            }
            }
            addresstoMapScreen={this.getCoordinates()}
          />

          <View style={{ width: '15%', margin: 20, marginTop: -130, flexDirection: 'row' }}>
            <TouchableOpacity
              style={{ backgroundColor: 'grey', borderRadius: 5, width: 70, justifyContent: 'center' }}
              onPress={() => { this.setState({ showModal: false }) }}>
              <Text style={{
                fontSize: 15,
                color: 'white',
                fontWeight: 'bold',
                padding: 5,
                textAlign: 'center'
              }}
              >Cancel</Text>
            </TouchableOpacity>
            {!!this.state.mapCoords && <TouchableOpacity
              style={{ backgroundColor: 'green', borderRadius: 5, width: 50, marginLeft: 10, justifyContent: 'center' }}
              onPress={async () => {
                await this.setState({ showModal: false });
                let a = this.state.mapCoords;
                let b = this.state.mapAddress;
                await this.setState({
                  location: {
                    coords: a
                  }, address: b
                });
                this.props.onUpdate(this.state.address, this.state.location.coords);
              }}>
              <Text style={{
                fontSize: 15,
                color: 'white',
                fontWeight: 'bold',
                padding: 5,
                textAlign: 'center'
              }}
              >Set</Text>
            </TouchableOpacity>}
          </View>



        </Modal>
      </View>

    )
  }

};

let styles = {

  container: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'stretch',
    paddingHorizontal: 10,
    flexDirection: 'row'


  },
};

import React, { Component } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  Image,
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import distance from "../../shared/distances";
import * as Location from 'expo-location';

export default class Author extends Component {
  state = {
    profileID: "",
    name: "",
    nameBusiness: "",
    profilePictureURL: "",
    jobsDone: "",
    jointContacts: [],
    badges: [],
    usesACN: "UNKNOWN",
    workLocation: {},
    workLocationLatitude: null,
    workLocationLongitude: null,
    myLocation: {
      coords: {
        latitude: 6.465422,
        longitude: 3.406448
      }
    },
    myLocationLoaded: false,
    justFollowed: false
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 200); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 500); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 1000); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 4000); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 8000); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 10000); });
    if (!global.myUID) await global.setGlobals();

    this.getMyLocation();
    this.unsubscribeFirestore = firebase
      .firestore()
      .collection("Profiles")
      .doc(this.props.profileID)
      .onSnapshot(async s => {
        //let connected = await NetInfo.isConnected;
        if (true) {
          this.updateProvider(s);
        }
      });
  }
  getMyLocation = async () => {
    const myLocation = await Location.getCurrentPositionAsync({ accuracy: 3, maximumAge: 60000 });
    // console.log(
    //   "My location: ",
    //   myLocation.coords.longitude,
    //   myLocation.coords.latitude
    // );
    if (myLocation && myLocation.coords && myLocation.coords.latitude)
      this.setState({ myLocation, myLocationLoaded: true });
  };

  fetchJointItems = async () => {
    try {
      if (!global.myProfileID) await global.timeout?.(1000)
      if (!global.myProfileID) await global.timeout?.(1000)
      if (!global.myProfileID) return
      console.log(
        "Fetching JointItems",
        global.myUID,
        global.myProfileID,
        this.state.profileID
      );
      const r = await fetch(global.cloudFunctionURL + "getJointItems", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          myUID: global.myUID,
          idToken: global.idToken,
          myProfileID: global.myProfileID,
          targetProfileID: this.state.profileID
        })
      });
      const r2 = await r.json();

      if (r2.msg === "SUCCESS") {
        //console.log("SUCCESS!!!!!", r2);
        if (r2.contactsFollowing != null) {
          this.setState({
            jointContacts: r2.contactsFollowing
          });
        }
      }
      //console.log("SUCCESS??", r2);
    } catch (error) {
      console.log(error);
    }
  };

  componentWillUnmount() {
    console.log("Component Author unmount");
    this.unsubscribeFirestore();
  }

  updateProvider = async profileSnapshot => {
    try {
      let profile = profileSnapshot.data();
      let profileID = profileSnapshot.id;
      let name = profile.profileName || "";
      let profilePictureURL = profile.profilePictureURL || "";
      let nameBusiness = profile.profileNameBusiness || "";
      let jobsDone = profile.infoJobsDoneCount || 0;
      let badges = profile.infoBadges || [];
      let usesACN = profile.usesACN || false;

      if (badges == null) {
        badges = [];
      }

      await this.setState({
        profileID,
        name,
        nameBusiness,
        profilePictureURL,
        jobsDone,
        badges,
        usesACN,
        workLocation: {
          address: profile.locationWorkAddress || "",
          description: profile.locationWorkDescription || "",
          coords: {
            latitude: profile.locationWork
              ? profile.locationWork._lat
              : null,
            longitude: profile.locationWork
              ? profile.locationWork._long
              : null
          }
        },
        workLocationLatitude: profile.locationWork
          ? profile.locationWork._lat
          : null,
        workLocationLongitude: profile.locationWork
          ? profile.locationWork._long
          : null
      });
      this.props.onLoad();
      //await this.fetchJointItems();
      //xx set joint followers here
      //xx send chatID up if exists
      if (profile.profilePhoneNumber) {
        this.props.onGetPhoneNumber(profile.profilePhoneNumber);
      }
      this.props.onGetProfileID(
        this.state.profileID,
        this.state.name,
        this.state.nameBusiness,
        this.state.profilePictureURL,
        this.state.workLocation,
        this.state.usesACN
      );
    } catch (err) {
      console.log(err);
      alert("Could not fetch result. Check your internet connection");
      console.log("Slow internet? Could not fetch author", err);
    }
  };

  render() {
    const badges = {
      Quality: require("../../images/badges/badgeQuality.png"),
      Affordability: require("../../images/badges/badgeAffordability.png"),
      Professionality: require("../../images/badges/badgeProfessionality.png"),
      Timeliness: require("../../images/badges/badgeTimeliness.png")
    };

    if (this.props.type == "Post") {
      return (
        <View
          style={{
            flexDirection: "row",
            flex: 1,
            height: 65,
            //width: 100,
            alignItems: "center",
            paddingLeft: 6,
            paddingRight: 6,
            shadowOpacity: 0.5,
            shadowOffset: { width: 0, height: 3 },
            borderBottomColor: "#555555",
            borderWidth: 0
          }}
        >
          <View style={{ width: 42, marginRight: 8 }}>
            {!!this.state.profilePictureURL && (<View>
              <View
                style={{
                  marginTop: (this.state.badges && this.state.badges.length > 0) ? 5 : 0,
                  width: 44,
                  height: 44,
                  marginRight: 7,
                  borderRadius: 22,
                  marginBottom: -43,
                  backgroundColor: "white",
                  opacity: 0.2
                }} />
              <Image
                style={{
                  marginTop: (this.state.badges && this.state.badges.length > 0) ? 5 : 0,
                  width: 42,
                  height: 42,
                  paddingRight: 8,
                  marginLeft: 1,
                  borderRadius: 21,
                }}
                source={{
                  uri: this.state.profilePictureURL
                }}
              /></View>
            )}
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: -5
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {this.state.badges.map(badge => {
                  return (
                    <Image
                      key={"b_" + badge}
                      style={{
                        width: 10,
                        height: 10
                      }}
                      source={badges[badge]}
                    />
                  );
                })}
              </View>
            </View>
          </View>
          <View
            style={{
              flexDirection: "column",
              flex: 1,
              paddingLeft: 2
            }}
          >
            <Text
              style={{
                fontSize:
                  this.state.name.length > 31
                    ? 10
                    : this.state.name.length > 24
                      ? 12
                      : 14,
                color: "white",
                fontWeight: "bold"
              }}
            >
              {this.state.name}
            </Text>
            <Text
              style={{
                fontSize: this.state.nameBusiness.length > 24 ? 9 : 12,
                color: "white",
                fontWeight: "bold"
              }}
            >
              {this.state.nameBusiness}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              paddingHorizontal: 8,
              alignItems: "baseline",
              justifyContent: "flex-end"
            }}
          >
            {!!this.state.myLocationLoaded && !!this.state.workLocationLatitude && (
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Image
                  style={{
                    width: 15,
                    height: 16,
                    marginRight: 7,
                    marginBottom: 0
                  }}
                  source={require("../../images/icons/Location.png")}
                />
                <Text
                  style={{ fontSize: 10, color: "white", fontWeight: "bold" }}
                >
                  {Math.round(
                    distance(
                      this.state.workLocationLatitude,
                      this.state.workLocationLongitude,
                      this.state.myLocation.coords.latitude,
                      this.state.myLocation.coords.longitude,
                      "K"
                    ) * 1
                  ) /
                    1 +
                    " km"}
                </Text>
              </View>
            )}
          </View>
        </View>
      );
    } else {
      return (
        <TouchableOpacity
          onPress={async () => {
            if (!this.props.profileID) {
              return;
            }

            this.props.onNavigate();

            await new Promise(resolve => {
              setTimeout(resolve, 50);
            });

            let route = "meProfile";
            if (this.props.navigation.state.routeName === "homeMain") {
              route = "homeProfile";
            }
            if (this.props.navigation.state.routeName === "chatDetail") {
              route = "messageProfile";
            }
            if (this.props.navigation.state.routeName === "homeChat") {
              route = "homeProfile";
            }
            if (this.props.navigation.state.routeName === "meChat") {
              route = "meProfile";
            }
            if (this.props.navigation.state.routeName === "jobsChat") {
              route = "jobsProfile";
            }

            let handoverID = this.props.profileID;
            if (handoverID == global.myProfileID) {
              handoverID = null; // xx TBD: paste this everywhere
            }
            this.props.navigation.navigate(route, {
              profileID: handoverID
            });
          }}
          style={{ flex: 1, justifyContent: "flex-start" }}
        >
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              height: 65,
              paddingTop: 0,
              //width: 100,
              alignItems: "flex-start",
              paddingLeft: 6,
              paddingRight: 6

              // borderColor:"#fff",
              // borderBottomColor: "#ccc",
              // borderWidth: 5,
              // borderTopRightRadius: 30,
              // borderTopLeftRadius: 30,
            }}
          >
            <View style={{ width: 50, marginRight: 8, paddingTop: 6 }}>
              {!!this.state.profilePictureURL && (<View>
                <View
                  style={{
                    marginTop: (this.state.badges && this.state.badges.length > 0) ? 5 : 0,
                    width: 52,
                    height: 52,
                    marginRight: 7,
                    borderRadius: 26,
                    marginLeft: -2,
                    marginTop: -5,
                    marginBottom: -51,
                    backgroundColor: "white",
                    opacity: 0.2
                  }} />
                <Image
                  style={{
                    marginTop: (this.state.badges && this.state.badges.length > 0) ? 5 : 0,
                    width: 50,
                    height: 50,
                    paddingRight: 8,
                    marginLeft: -1,
                    borderRadius: 25,
                  }}
                  source={{
                    uri: this.state.profilePictureURL
                  }}
                /></View>
              )}
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: -6
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  {this.state.badges.map(badge => {
                    return (
                      <Image
                        key={"b_" + badge}
                        style={{
                          width: 12,
                          height: 12
                        }}
                        source={badges[badge]}
                      />
                    );
                  })}
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: "column",
                flex: 1,
                paddingHorizontal: 2,
                alignSelf: "center"
              }}
            >
              <Text
                style={{
                  fontSize:
                    this.state.name.length > 28
                      ? 10
                      : this.state.name.length > 26
                        ? 12
                        : 14,
                  color: "white",
                  fontWeight: "bold"
                }}
              >
                {this.state.name}
              </Text>
              <Text
                style={{
                  fontSize: this.state.nameBusiness.length > 25 ? 10 : 12,
                  color: "white",
                  fontWeight: "bold"
                }}
              >
                {this.state.nameBusiness}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "column",
                paddingHorizontal: 8,
                alignItems: "baseline",
                justifyContent: "flex-end"
              }}
            >
              {!!this.state.myLocationLoaded && !!this.state.workLocationLatitude && (
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Image
                    style={{
                      width: 15,
                      height: 16,
                      marginRight: 7,
                      marginBottom: 0
                    }}
                    source={require("../../images/icons/Location.png")}
                  />
                  <Text
                    style={{ fontSize: 10, color: "white", fontWeight: "bold" }}
                  >
                    {Math.round(
                      distance(
                        this.state.workLocationLatitude,
                        this.state.workLocationLongitude,
                        this.state.myLocation.coords.latitude,
                        this.state.myLocation.coords.longitude,
                        "K"
                      ) * 1
                    ) /
                      1 +
                      " km"}
                  </Text>
                </View>
              )}
            </View>
            {!global.myFollowingIDs?.includes?.(this.props.profileID) && !this.state.justFollowed && this.props.profileID !== global.myProfileID && <TouchableOpacity
              onPress={() => {
                global.followProfile?.(true, this.props.profileID);
                this.setState({ justFollowed: true })
              }}
              style={{
                alignSelf: "center", width: 55, height: 25, borderRadius: 15,
                borderWidth: 0.7, borderColor: "#fff", alignItems: "center", justifyContent: "center"
              }}>
              <Text style={{ color: "#fff", fontSize: 12 }}>Follow</Text>
            </TouchableOpacity>}
            {this.state.justFollowed && <Image
              style={{ width: 20, height: 25, marginRight: 7, alignSelf: "center" }}
              source={require("../../images/icons/Following.png")}
            />}
            <TouchableOpacity
              style={{ alignSelf: "center", padding: 8, paddingBottom: 10 }}
              onPress={() => {
                this.props.onPressMenu();
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  marginTop: 7,
                  marginLeft: 6,
                  fontSize: 24,
                  color: "#fff",
                  shadowOpacity: 1,
                  elevation: 1
                }}
              >
                ⋮
              </Text>
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
      );
    }
  }
}

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Dimensions,
  Platform,
  Image,
  SectionList,
  Alert,
  Modal
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import { NavigationEvents } from "react-navigation";
import CurrentLocation from "../../../../shared/Map/currentLocationV2";
import CurrentLocationModal from "../../../../shared/Map/currentLocationV2Modal";
import { Message, SystemMessage, TypeBox } from "../../../../Messages/ChatDetailScreen"



class Selector extends Component {
  render() {
    let height =
      this.props.text.length < 45
        ? 30
        : 45 + (this.props.text.length - 45) * 0.33;
    let circle = filled => {
      if (filled) {
        return (
          <View
            style={{
              width: 20,
              height: 20,
              borderColor: "#999",
              backgroundColor: "#999",
              borderWidth: 1,
              borderRadius: 10
            }}
          />
        );
      } else {
        return (
          <View
            style={{
              width: 20,
              height: 20,
              borderColor: "#556",
              borderWidth: 1,
              borderRadius: 10
            }}
          />
        );
      }
    };

    return (
      <View
        style={{
          height: height,
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        {circle(this.props.selected)}
        <Text style={{ marginLeft: 10, color: "#999" }}>{this.props.text}</Text>
      </View>
    );
  }
}
class Selector2 extends PureComponent {
  state = {};

  render() {
    return (
      <View style={{ flexDirection: "row", height: 55, justifyContent: "center", alignItems: "center", borderTopWidth: 0, borderColor: "#333", paddingBottom: 0, paddingTop: 10 }}>
        <TouchableOpacity onPress={() => this.props.onPress("Numbers")}>
          <View
            style={{ height: 30, width: 80, margin: 5, borderRadius: 20, borderTopEndRadius: 0, borderBottomEndRadius: 0, borderColor: "#aaa", borderWidth: 1, backgroundColor: this.props.view == "Numbers" ? "#aaa" : "#131313", justifyContent: "center", alignItems: "center" }}
          >
            <Text
              style={{ color: this.props.view == "Numbers" ? "#000" : "#eee" }}
            >
              Numbers
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this.props.onPress("Conference")}>
          <View
            style={{
              backgroundColor: this.props.view == "Conference" ? "#aaa" : "#131313", height: 30, width: 80, margin: 5, borderColor: "#aaa", borderWidth: 1, justifyContent: "center", alignItems: "center"
            }}
          >
            <Text
              style={{ color: this.props.view == "Conference" ? "#000" : "#eee" }}
            >
              Conference
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this.props.onPress("Locations")}>
          <View
            style={{
              backgroundColor: this.props.view == "Locations" ? "#aaa" : "#131313", height: 30, width: 80, margin: 5, borderTopEndRadius: 20, borderBottomEndRadius: 20, borderColor: "#aaa", borderWidth: 1, justifyContent: "center", alignItems: "center"
            }}
          >
            <Text
              style={{ color: this.props.view == "Locations" ? "#000" : "#eee" }}
            >
              Locations
            </Text>
          </View>
        </TouchableOpacity>
      </View>);
  }
}

export default class GuideMessageScreen extends Component {
  static navigationOptions = {
    headerTransparent: false,
    title: "Send Baba Oja Messages",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45"
    },
    headerStyle: {
      backgroundColor: "#000"
      //height: 15,
    }
  };

  state = {
    selector2Selection: "None",
    //actionTarget: "none!",
    number: global.walletType == "ACN" ? "+233" : "+234",
    text: "",
    radius: "5: 5 km",
    showModalRadius: false,
    location: global.location,
    count: "??",
    counted: false,
    businessesOnly: false,
    contactCustomerCareButton: false,
    activityIndicatorAnimating: false,
    showLocationModal: false,
    previewedMessage: null,
    youtubeID: "", youtubeThumbnail: "", youtubeTitle: "",
    urlTitle: "", urlThumbnail: "", urlLink: "",
    editMessage: true,

    aChannel: "loading",
    modalAVisible: false,
    activeAModal: "",
    activeASelection: [],

    channelSelection: [{
      type: "loading",
      title: "Loading...",
      description: ""
    }],
  };


  async componentDidMount() {
    try {
      this.loadChannels()
    } catch (err) {
      console.log("Error", err);
    }
  }

  componentWillUnmount() {
    //this.unsubscribeFirestore();
  }

  loadChannels = async () => {
    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "settings", { // this loads my settings - change in case they become individualized
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          action: "get",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS" && r2.response) {
        if (r2.response?.labels) {
          const channelSelection = [{
            type: "mandatory",
            title: "Mandatory",
            description: "Send to all users"
          }]
          const keys = Object.keys(r2.response?.labels)
          keys.forEach(key => {
            channelSelection.push({
              type: key,
              title: r2.response?.labels?.[key],
              description: "Send to users interested in this"
            })
          })
          this.setState({ channelSelection, aChannel: "mandatory" })
        }
      } else return alert("Channels not loaded")
    } catch (error) {
      global.warn(error, "loadChannels")
    }
  }

  seeReach = async () => {
    if (this.state.activityIndicatorAnimating) return;
    try {
      let filterBy = "location"
      if (this.state.selector2Selection == "Numbers") filterBy = "number"
      if (this.state.selector2Selection == "Conference") filterBy = "ikParticipants"
      if (filterBy == "location" && (!this.state.location.coords.latitude || !this.state.radius)) { alert("Select Location"); return }
      if (filterBy == "number" && (!this.state.number || !this.state.number.startsWith("+"))) { alert("Enter a number starting with +"); return }
      this.setState({ activityIndicatorAnimating: true });
      const r = await fetch(global.cloudFunctionURL + "sendGuideMessage2", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "count",
          messageData: { text: "---------------" },
          filterBy,
          ...(filterBy == "number" && { number: this.state.number }),
          ...(filterBy == "location" && { location: this.state.location, radius: this.state.radius }),
          channel: this.state.aChannel,
          ...(this.state.businessesOnly && { businessesOnly: true }),
          ...(this.state.contactCustomerCareButton && { contactCustomerCareButton: true }),
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ activityIndicatorAnimating: false });

      if (r2.msg === "SUCCESS") {
        this.setState({ count: r2.count, counted: true })
      } else {
        alert("Backend error. Request failed.");
      }
    } catch (err) {
      alert("Request not successful.");
      console.log(err);
      this.setState({ activityIndicatorAnimating: false });
    }
  }
  sendMessage = async () => {
    if (this.state.activityIndicatorAnimating) return;
    // if (this.state.previewedMessage?.text?.length < 15) { alert("Text too short"); return }
    if (!this.state.number || !this.state.number.startsWith("+")) { alert("Enter a number starting with +"); return }
    let alertMessage = "Send previewed message to " + (this.state.count) + " Wakanda users?"
    Alert.alert(
      "Sure?",
      alertMessage,
      [
        { text: "NO", onPress: () => console.log("Cancel Pressed"), style: "cancel" },
        { text: "YES", onPress: () => { this.sendMessageExecute(); } }
      ],
      { cancelable: false }
    );

  }
  sendMessageExecute = async () => {
    try {
      let filterBy = "location"
      if (this.state.selector2Selection == "Numbers") filterBy = "number"
      if (this.state.selector2Selection == "Conference") filterBy = "ikParticipants"
      if (filterBy == "location" && (!this.state.location.coords.latitude || !this.state.radius)) { alert("Select Location"); return }
      if (filterBy == "number" && (!this.state.number || !this.state.number.startsWith("+"))) { alert("Enter a number starting with +"); return }

      this.setState({ activityIndicatorAnimating: true });
      const r = await fetch(global.cloudFunctionURL + "sendGuideMessage2", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "send",
          messageData: this.state.previewedMessage,
          filterBy,
          ...(filterBy == "number" && { number: this.state.number }),
          ...(filterBy == "location" && { location: this.state.location, radius: this.state.radius }),
          channel: this.state.aChannel,
          ...(this.state.businessesOnly && { businessesOnly: true }),
          ...(this.state.contactCustomerCareButton && { contactCustomerCareButton: true }),
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ activityIndicatorAnimating: false, counted: false });
      await global.timeout(50)
      if (r2.msg === "SUCCESS") {
        let msg = ""
        if (r2.count == 1) msg = "Success. Sent to 1 user"
        else if (r2.count < 700) msg = "Success. Sent to " + r2.count + " users (some may have disabled notifications)"
        else msg = "Success. Sending to " + r2.count + " users. It may take a few minutes till it reaches everyone"
        alert(msg);
      } else if (r2.msg === "NO_USER_FOUND") {
        alert("No matching user found");
      } else {
        alert("Backend error. Request failed.");
      }
    } catch (err) {
      alert("Send Request not successful.");
      console.log(err);
      this.setState({ activityIndicatorAnimating: false });
    }
  }


  //pressedSend = async (text, pictureURL, contactID, location, inquiry, postID, document) => {
  pressedSend = async (data) => {

    try {
      const addThis = {
        text: data.text,
        ...(this.state.youtubeID && { youtubeID: this.state.youtubeID }),
        ...(this.state.youtubeTitle && { youtubeTitle: this.state.youtubeTitle }),
        ...(this.state.youtubeThumbnail && { youtubeThumbnailURL: this.state.youtubeThumbnail }),
        ...(this.state.urlTitle && { urlTitle: this.state.urlTitle }),
        ...(this.state.urlThumbnail && { urlThumbnail: this.state.urlThumbnail }),
        ...(this.state.urlLink && { urlLink: this.state.urlLink }),
      };
      if (data.pictureURL) addThis.pictureURL = data.pictureURL;
      if (data.video) addThis.video = data.video;
      if (data.contactID) addThis.contactID = data.contactID;
      if (data.postID) addThis.postID = data.postID;
      if (data.location) addThis.location = data.location;
      if (data.inquiry) addThis.inquiry = data.inquiry;
      if (data.document) addThis.document = data.document;

      if (data.uploadFunction) { // case VOICE MESSAGE
        const voiceURL = await data.uploadFunction()
        console.log("voiceURL: ", voiceURL)
        addThis.text = ""
        addThis.voiceURL = voiceURL
        addThis.voiceDuration = data.voiceDuration
      }

      this.setState({ previewedMessage: addThis, editMessage: false });
      //console.log({ addThis })
    } catch (e2) {
      alert(e2);
      console.log(e2);
    }
    //this.refs["myTypeBox"].setNativeProps({text: "aa" });
  };

  updateInput = (youtubeID, youtubeThumbnail, youtubeTitle) => {
    this.setState({ youtubeID, youtubeThumbnail, youtubeTitle, urlTitle: null, urlThumbnail: null, urlLink: null })
  }
  updateUrlInput = (urlTitle, urlThumbnail, urlLink) => {
    this.setState({ urlTitle, urlThumbnail, urlLink, youtubeID: null, youtubeThumbnail: null, youtubeTitle: null })
  }

  render() {
    const m = this.state.previewedMessage
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "flex-start",
          backgroundColor: "#131313",
          paddingHorizontal: 5,
          alignSelf: "stretch",
        }}
      >
        {this.state.editMessage ? (<View style={{ alignSelf: "stretch", justifyContent: "flex-start", paddingTop: 25 }}>
          <Text style={{ color: "#008866", fontWeight: "bold" }}>1.  Type message</Text>
          <Text style={{ color: "#008866" }}>2.  Select recipients</Text>
          <Text style={{ color: "#008866" }}>3.  Send</Text>
          <View style={{ height: 40 }} />
          <TypeBox
            ref={ref => (this.myTypeBox = ref)}
            onPressSend={this.pressedSend}
            getYoutubeDetail={this.updateInput}
            getUrlDetail={this.updateUrlInput}
            navigation={this.props.navigation}
            chatID={"BABAOJACHAT"}
            onNewPicture={pictureURL => {
              this.pressedSend({ text: "", pictureURL });
            }}
            onBlur={() => { }}
          />
        </View>) :
          <ScrollView style={{ alignSelf: "stretch", }}
            ref={ref => (this.myScrollView = ref)}
            showsVerticalScrollIndicator={false}
          >
            {!!this.state.previewedMessage && <SystemMessage text="Message Preview" />}
            {!!this.state.previewedMessage && <Message
              authorMe={true}
              read={false}
              text={m.text}
              pictureURL={m.pictureURL}
              document={m.document}
              contactID={m.contactID}
              postID={m.postID}
              youtubeID={m.youtubeID}
              youtubeTitle={m.youtubeTitle}
              youtubeThumbnailURL={m.youtubeThumbnailURL}
              urlTitle={m.urlTitle}
              urlThumbnail={m.urlThumbnail}
              urlLink={m.urlLink}
              inquiry={m.inquiry}
              location={m.location}
              followOnMessage={false}
              disableReply={true}
              time={"now"}
              picURL={"https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FBabaOja.jpg?alt=media&token=268bd424-1621-49d4-8562-70cf2167a0d6"}
              pending={false}
              renderContent={true}
              navigation={this.props.navigation}
            />}

            <TouchableOpacity
              style={[global.gStyles.buttonXW, { width: 120, marginBottom: 25, marginTop: 12, alignSelf: "center" }]}
              onPress={() => { this.setState({ editMessage: true }) }}
            >
              <Text style={global.gStyles.buttontXW}>Rewrite Message</Text>
            </TouchableOpacity>

            {/* <TextInput
            onChangeText={text => {
              this.setState({ text });
            }}
            multiline={true}
            numberOfLines={6}
            style={{
              marginTop: 10,
              marginBottom: 20,
              color: "#ffffff",
              borderColor: "grey",
              borderWidth: 0,
              paddingLeft: 3,
              borderRadius: 5,
              height: 180,
              alignSelf: "stretch",
              backgroundColor: "#223"
            }}
            maxLength={1000}
            underlineColorAndroid="transparent"
            defaultValue={this.state.text}
            rowSpan={5}
            bordered
          /> */}


            <TouchableOpacity onPress={() => this.setState({ modalAVisible: true, activeAModal: "aChannel", activeASelection: this.state.channelSelection, counted: false })}>
              <ASelector selection={this.state.channelSelection} selected={this.state.aChannel} />
            </TouchableOpacity>

            <TouchableOpacity onPress={() => { this.setState({ businessesOnly: !this.state.businessesOnly, counted: false }) }}>
              <CheckboxSelector
                selected={this.state.businessesOnly}
                text={"Businesses / Shop Owners only"} />
            </TouchableOpacity>

            <TouchableOpacity onPress={() => { this.setState({ contactCustomerCareButton: !this.state.contactCustomerCareButton, counted: false }) }}>
              <CheckboxSelector
                selected={this.state.contactCustomerCareButton}
                text={"Add button \"Contact Customer Care\" "} />
            </TouchableOpacity>

            <Selector2 view={this.state.selector2Selection} onPress={(s) => { this.setState({ selector2Selection: s, counted: false }) }} />

            {this.state.selector2Selection == "Numbers" && <View style={{
              alignSelf: "stretch",
              justifyContent: "space-between"
            }}>
              <Text style={{ marginVertical: 10, color: "#999" }}>Send to phone numbers starting with...</Text>
              <TextInput
                onChangeText={number => {
                  this.setState({ number, counted: false });
                }}
                onFocus={() => { this.myScrollView?.scrollToEnd?.({ animated: true }) }}
                keyboardType="phone-pad"
                style={{
                  color: "#ffffff",
                  borderColor: "grey",
                  borderWidth: 0,
                  paddingLeft: 3,
                  borderRadius: 5,
                  alignSelf: "stretch",
                  width: "100%",
                  height: 35,
                  backgroundColor: "#223"
                }}
                maxLength={1000}
                underlineColorAndroid="transparent"
                defaultValue={this.state.number}
                bordered
              />
              {/* <TouchableOpacity
              onPress={() => {
                this.setState({ actionTarget: "all" });
              }}
            >
              <Selector
                selected={this.state.actionTarget == "all"}
                text="Phone numbers starting with this"
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                this.setState({ actionTarget: "number" });
              }}
            >
              <Selector
                selected={this.state.actionTarget == "number"}
                text="Single user with this number"
              />
            </TouchableOpacity> */}
            </View>}

            {this.state.selector2Selection == "Conference" && <View style={{
              alignSelf: "stretch",
              justifyContent: "space-between"
            }}>
              <Text style={{ marginVertical: 10, color: "#999" }}>Send to all Innovation Kitchen conference participants</Text>
            </View>}

            {this.state.selector2Selection == "Locations" && <View style={{
              alignSelf: "stretch",
              justifyContent: "space-between"
            }}>
              <View style={{ margin: 10, flexDirection: "row", alignItems: "center" }}>
                <Text style={{ color: "#ccc", fontSize: 14, marginRight: 10 }}>Select users within</Text>
                <TouchableOpacity
                  onPress={() => this.setState({ showModalRadius: true })}
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <View
                    style={{
                      color: "#ffffff", borderColor: "#334", borderWidth: 1, padding: 3, borderRadius: 15, width: 100, backgroundColor: "#000", alignItems: "center",
                    }}
                  >
                    <Text style={{ color: "#ccc", fontSize: 14 }}>{this.state.radius.substring(2, 99)}</Text>
                  </View>
                </TouchableOpacity>
                <Text style={{ color: "#ccc", fontSize: 14, marginLeft: 10 }}>near...</Text>
              </View>
              <CurrentLocation
                onPressEdit={() => { this.setState({ showLocationModal: true }) }}
                setLiveLocation={false}
                textColor={"#ccc"}
                location={this.state.location || global.location}
                textColor={"#fff"}
                textSize={12}
                //changeColor={"#ccc"}
                padding={0}
              />
              {/* <View style={{ margin: 10, flexDirection: "row", alignItems: "center" }}>
              <Text style={{ color: "white", fontSize: 14 }}>Radius (km):</Text>
              <TextInput
                onChangeText={radius => {
                  this.setState({ radius });
                }}
                keyboardType="numeric"
                style={{
                  color: "#ffffff",
                  borderColor: "grey",
                  borderWidth: 0,
                  paddingLeft: 3,
                  borderRadius: 5,
                  alignSelf: "stretch",
                  width: "25%",
                  marginLeft: 10,
                  height: 35,
                  backgroundColor: "#223"
                }}
                maxLength={10}
                underlineColorAndroid="transparent"
                defaultValue={this.state.radius}
                bordered
              />
            </View> */}
            </View>}

            {!this.state.activityIndicatorAnimating ?
              <View style={{ height: 20 }} /> :
              <ActivityIndicator
                size="small"
                color="#FFFFFF"
                animating={true}
              />}

            {this.state.selector2Selection != "None" && <View>
              {!this.state.counted ?
                (<TouchableOpacity
                  style={global.gStyles.button}
                  onPress={this.seeReach}
                >
                  <Text style={global.gStyles.buttontext}>See Potential Reach</Text>
                </TouchableOpacity>)
                : (<View>
                  <View style={[global.gStyles.button, { backgroundColor: "#1313" }]}>
                    <Text style={{ color: "#A8CF45" }}>This message would reach {this.state.count} users. {this.state.aChannel !== "mandatory" && "\n(some may've deactivated this channel)"}</Text>
                  </View>
                  <TouchableOpacity
                    style={[global.gStyles.buttonXLW, { borderRadius: 5 }]}
                    onPress={this.sendMessage}
                  >
                    <Text style={[global.gStyles.buttontXLW]}>Send Message</Text>
                  </TouchableOpacity>
                </View>)}
            </View>}
            <View style={{ height: 200 }} />
            <NavigationEvents
              onDidFocus={async payload => {
                await global.timeout(500)
                let contactID = this.props.navigation.getParam("contactID", false);
                if (contactID) {
                  this.pressedSend({ text: "", contactID });
                  this.props.navigation.setParams({
                    contactID: false
                  });
                }
                let location = this.props.navigation.getParam("location", false);
                if (location) {
                  this.pressedSend({ text: "", location });
                  this.props.navigation.setParams({
                    location: false
                  });
                }
                let postID = this.props.navigation.getParam("postID", false);
                if (postID) {
                  this.pressedSend({ text: "", postID });
                  this.props.navigation.setParams({
                    postID: false
                  });
                }
              }}
            />
          </ScrollView>}

        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.showModalRadius}
          onRequestClose={() => {
            this.setState({ showModalRadius: false });
          }}
        >
          <TouchableOpacity
            onPress={() =>
              this.setState({ showModalRadius: false })
            }
            style={{ height: 200 }}
          />
          <View
            style={{
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ccd"
              }}
            >
              <View style={{ paddingTop: 50 }}>
                <Text style={{ fontSize: 30 }}>Select a radius</Text>
              </View>
              <View style={{ marginTop: 35, marginBottom: 5 }}>
              </View>
              <SectionList
                renderItem={({ item, index, section }) => (
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({
                        showModalRadius: false,
                        radius: item,
                        counted: false
                      });
                    }}
                    style={{
                      flex: 1,
                      paddingTop: 10,
                      paddingBottom: 10,
                      alignItems: "center",
                      width: Dimensions.get("window").width,
                      backgroundColor: "white",
                      borderWidth: 1,
                      borderColor: "#ccd"
                    }}
                  >
                    <Text style={{ fontSize: 18 }} key={index}>
                      {item.substring(2, 99)}
                    </Text>
                  </TouchableOpacity>
                )}
                renderSectionHeader={({ section: { title } }) => (
                  <Text style={{ fontWeight: "bold" }}>{title}</Text>
                )}
                sections={[
                  {
                    title: "",
                    data: [
                      "1: 5000 km",
                      "2: 1000 km",
                      "3: 150 km",
                      "4: 30 km",
                      "5: 5 km",
                      "6: 1 km",
                      "7: 150 m",
                      "8: 30 m",
                    ]
                  }
                ]}
                keyExtractor={(item, index) => item + index}
              />
            </View>
            {/* <TouchableOpacity
                    style={{
                      position: "absolute",
                      borderWidth: 0,
                      borderColor: "rgba(0,0,0,0.2)",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 40,
                      top: 0,
                      left: 3,
                      height: 59,
                      backgroundColor: "#ccc",
                      borderRadius: 30
                    }}
                    onPress={() =>
                      this.setState({ showModalRadius: false })
                    }
                  >
                    <Text style={{ fontSize: 26, color: "#000" }} />
                  </TouchableOpacity> */}
          </View>
        </Modal>
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.modalAVisible}
          onRequestClose={() => {
            this.setState({ modalAVisible: false });
          }}
        >
          <TouchableOpacity
            onPress={() =>
              this.setState({ modalAVisible: false })
            }
            style={{ width: "100%" }}
          />
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              marginTop: 120,
              width: "100%",
              justifyContent: "flex-end"
            }}
          >
            <View
              style={{
                alignItems: "center", justifyContent: "flex-end", backgroundColor: "#ccd", borderTopEndRadius: 15, borderTopLeftRadius: 15, width: "100%"
              }}
            >
              <View style={{ paddingTop: 25 }}>
                <Text style={{ fontSize: 24, fontWeight: "bold", color: "#223" }}>{this.state.activeAModal == "aChannel" ? "Select Channel" : "Select"}</Text>
              </View>
              <View style={{ marginTop: 15, marginBottom: 5 }}>
              </View>
              <ScrollView style={{ width: "100%" }}>
                {this.state.activeASelection.map((x) => {
                  return <TouchableOpacity key={x.type} onPress={() => { this.setState({ aChannel: x.type, modalAVisible: false }) }}>
                    <ASelectorW ob={x} />
                  </TouchableOpacity>
                })}
                <View style={{ paddingTop: 25 }} />
              </ScrollView>
            </View>
          </View>
        </Modal>
        {!!this.state.showLocationModal && <CurrentLocationModal
          location={this.state.location}
          onCancel={() => { this.setState({ showLocationModal: false }) }}
          onSelect={(coords, address) => {
            // console.log("upda", address, coords);
            this.setState({
              showLocationModal: false,
              location: {
                address: address,
                coords: coords
              }
            });
          }}
        />}
      </View>);
  }
}

class ASelector extends PureComponent {
  render() {
    let ob = {}
    this.props.selection.forEach((o) => {
      if (o.type == this.props.selected) ob = o
    })
    return (
      <View style={{
        alignItems: "center", justifyContent: "center", height: 50,
        flexDirection: "row", padding: 3,
        borderRadius: 7.5, width: "100%", backgroundColor: "#282830",
        marginBottom: 7, marginTop: 3
      }}>
        <View style={{ alignItems: "flex-start", justifyContent: "center", flex: 1, paddingLeft: 10 }}>
          <Text style={{ color: "#ccd", fontSize: 18, fontWeight: "bold" }}>{ob.title}</Text>
          <Text style={{ color: "#99a", fontSize: 14 }}>{ob.description}</Text>
        </View>
        <View style={{ alignItems: "center", justifyContent: "center", width: 50 }}>
          <Text style={{ color: "#ccc", fontSize: 16, fontWeight: "bold", color: "#889" }}>▼</Text>
        </View>
      </View>
    );
  }
}
class ASelectorW extends PureComponent {
  render() {
    const ob = this.props.ob
    return (
      <View style={{
        alignItems: "center", justifyContent: "center", height: 50,
        flexDirection: "row", padding: 3,
        borderRadius: 7.5, width: "100%", backgroundColor: "#ccd",
        marginVertical: 5, borderTopWidth: 1, borderTopColor: "#aab"
      }}>
        <View style={{ alignItems: "flex-start", justifyContent: "center", flex: 1, paddingLeft: 10 }}>
          <Text style={{ color: "#223", fontSize: 18, fontWeight: "bold" }}>{ob.title}</Text>
          <Text style={{ color: "#778", fontSize: 14 }}>{ob.description}</Text>
        </View>
        <View style={{ alignItems: "center", justifyContent: "center", width: 50 }}>
          <Text style={{ color: "#333", fontSize: 20, fontWeight: "bold", color: "#99b" }}></Text>
        </View>
      </View>
    );
  }
}

class CheckboxSelector extends Component {
  render() {
    let height =
      this.props.text.length < 45
        ? 30
        : 45 + (this.props.text.length - 45) * 0.33;
    let circle = filled => {
      if (filled) {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#9999a4", backgroundColor: "#334", borderWidth: 0, borderRadius: 10 }}>
            <Text style={{ fontSize: 24, marginTop: -5, color: global.app == "speakers" ? "#fff" : "#ccd" }}>✓</Text>
          </View>
        );
      } else {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#556", borderWidth: 1, borderRadius: 10 }} />
        );
      }
    };

    return (
      <View style={{ height: height, flexDirection: "row", alignItems: "center" }}>
        {circle(this.props.selected)}
        <Text style={{ marginLeft: 10, color: global.app == "speakers" ? "#fff" : "#ccd", fontWeight: this.props.selected ? "normal" : "normal" }}>{this.props.text}</Text>
      </View>
    );
  }
}
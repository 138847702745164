import React, { Component } from "react";
import {
    Text,
    View,
    StyleSheet,
    TextInput,
    Image,
    Platform,
    TouchableOpacity,
    ScrollView,
    Dimensions
} from "react-native";
import { LinearGradient } from 'expo-linear-gradient'
import CurrentLocation from "../../shared/Map/currentLocationV2";
import { add } from "react-native-reanimated";



export default class JobScreen4 extends Component {
    state = {
        showForm: false,
        addressDescription: "eg. opposite St Finbarrs rd beside the Montgomery Vault",
    };


    onChangeAddress = async (text) => {

        try {
            console.log('address entered is:', text);
            this.props.editDeliveryAddressDetails(text)

        }
        catch (err) {
            console.log('error is: ', err)
        }
    }

    onChangeAddressDescription = async (text) => {

        try {
            console.log('address entered is:', text);
            await this.setState({ addressDescription: text })
            this.props.editAddressDescriptionDetails(text)
        }
        catch (err) {
            console.log('error is: ', err)
        }

    }

    componentDidMount = () => {

    };

    render() {
        const screenWidth = Math.round(Dimensions.get('window').width);
        if (!this.state.showForm) return (
            <View style={{ flex: 1, alignItems: "center", width: screenWidth }}>
                <ScrollView>
                    <View style={{ alignItems: "center", marginTop: 30, width: "100%" }}>
                        <Text style={styles.textB}>Delivery address</Text>
                        <View style={{ marginTop: 40, }}>
                            <View style={{ alignItems: 'center', width: '100%' }}>
                                <TouchableOpacity
                                    onPress={() => { this.setState({ showForm: true }) }}>
                                    <LinearGradient
                                        colors={['#707070', '#282828']}
                                        style={{
                                            width: 160, borderWidth: 1, borderColor: '#00FF99', borderRadius: 30,
                                            height: 75, justifyContent: 'center', marginTop: 10, alignItems: 'center',
                                            backgroundColor: '#00FF99', padding: 10
                                        }}>
                                        <Text style={{ fontSize: 20, color: '#00FF99', textAlign: "center" }}>Add Delivery Address</Text>
                                    </LinearGradient>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </ScrollView>
            </View>
        );

        if (this.state.showForm) return (
            <View style={{ flex: 1, alignItems: "center", top: 0, width: screenWidth }}>
                <ScrollView ref={ref => (this.theScrollView = ref)}>
                    <View style={{ alignItems: "center", top: 30, width: "100%" }}>
                        <Text style={styles.text}>Delivery address</Text>
                        <View style={{ top: 40, position: "absolute" }}>
                            <Text style={styles.text3}>Address:</Text>
                        </View>

                        <View style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            height: 60,
                            borderWidth: 3,
                            borderColor: "#00FF99",
                            width: "80%",
                            marginTop: 60,
                            position: "relative",
                            backgroundColor: '#808080'
                        }}>
                            <View style={{ width: "100%", height: 30 }}>
                                <CurrentLocation
                                    onPressEdit={() => { this.props.showLocationModal() }}
                                    setLiveLocation={true}
                                    textColor={"#00FF99"}
                                    iconColor={"#00FF99"}
                                    changeColor={"#00FF99"}
                                    location={this.props.location || global.location}
                                    textSize={12}
                                    //changeColor={"#ccc"}
                                    padding={0}
                                />
                            </View>

                        </View>


                        {/* <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                height: 60,
                                borderWidth: 3,
                                borderColor: "#00FF99",
                                width: "80%",
                                marginTop: 60,
                                position: "relative"
                            }}
                        >
                            <View style={{ width: "80%" }}>
                           
                                 <Text
                                    style={{
                                        color: "#00FF99",
                                        fontWeight: "bold",
                                        paddingLeft: 10,
                                        paddingVertical: 5,
                                        textAlign: "center",
                                        fontSize: 14
                                    }}
                                >
                                    {this.state.deliveryAddress}
                                </Text> 
                                
                            </View> 
                                <View
                                    style={{
                                        width: "20%",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                >
                                    <TouchableOpacity>
                                        <Image
                                            style={{ height: 20, width: 20 }}
                                            source={require("./assets/pencil-edit-button-white.png")}
                                        />
                                    </TouchableOpacity>
                                </View>
                            </View> */}




                        <View style={{ marginTop: 20, position: "relative" }}>
                            <Text style={styles.text3}>Description:</Text>
                        </View>

                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                height: 60,
                                borderWidth: 3,
                                borderColor: "#00FF99",
                                width: "80%",
                                marginTop: 20,
                                position: "relative"
                            }}
                        >
                            <TextInput
                                // placeholder={this.state.addressDescription}
                                // placeholderTextColor={"#ccc"}
                                multiline={true}
                                onFocus={() => { this.theScrollView?.scrollToEnd?.({ animated: false }); }}
                                style={{
                                    width: "80%",
                                    //height: 30,
                                    paddingHorizontal: 10,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#00FF99",
                                    fontSize: 12
                                }}
                                onChangeText={(text) => this.onChangeAddressDescription(text)}
                            />
                        </View>
                        <View style={{ marginTop: 20, position: "relative" }}>
                            {/* <TouchableOpacity
                                onPress={() => {

                                    this.props.editLocationDetails('', '')
                                }}>
                                <Text style={styles.text2}>
                                    Skip
                           </Text>
                            </TouchableOpacity> */}
                        </View>
                        <TouchableOpacity
                            onPress={() => {
                                this.props.editDeliveryAddressDetails('')
                                this.props.editAddressDescriptionDetails('')
                                this.setState({
                                    location: {
                                        deliveryAddress: '',
                                        coords: null
                                    },
                                    showForm: false
                                })
                            }}>
                            <LinearGradient
                                colors={['#707070', '#282828']}
                                style={{
                                    width: 160, borderWidth: 1, borderColor: '#FF9999', borderRadius: 30,
                                    height: 75, justifyContent: 'center', marginTop: 10, alignItems: 'center',
                                    backgroundColor: '#00FF99', padding: 10
                                }}>
                                <Text style={{ fontSize: 15, color: '#FF9999', textAlign: "center" }}>No Delivery Address</Text>
                            </LinearGradient>
                        </TouchableOpacity>
                    </View>




                    <View style={styles.bottom}>
                        <Text style={styles.text4}>
                            Tap the pencil icon to choose a different address. You can choose
                            your already saved profile address, use the address displayed or
                            type in a new one.
                        </Text>
                    </View>
                </ScrollView>
            </View>
        );
    }
}

const styles = ({
    text: {
        color: "#fff",
        fontSize: 20,
        fontWeight: "bold",
        textAlign: "center",
        paddingHorizontal: 20
    },
    text2: {
        color: "#ffff00",
        fontSize: 17,
        textAlign: "center",
        fontWeight: "bold",
        paddingHorizontal: 40,
        paddingTop: 20
    },
    text3: {
        color: "#bbb",
        fontSize: 15,
        textAlign: "center",
        paddingHorizontal: 40,
        paddingVertical: 10
    },
    text4: {
        color: "grey",
        fontSize: 14,
        textAlign: "center",
        paddingVertical: 10,
        paddingHorizontal: 50
    },

    textB: {
        color: '#fff',
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        paddingHorizontal: 20

    },
    bottom: {
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 150,
        marginTop: 30
    },
});

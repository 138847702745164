import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  Linking,
  ActivityIndicator
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
const gStyles = global.gStyles //    "../../styles/gStyles";
import distance from "../../shared/distances";



export default class Contact extends Component {
  state = {
    contactProperties: {
      opponentProfileID: null,
      opponentName: "✧ ✧ ✧ ✧ ✧ ✧ ✧ ✧ ✧ ✧",
      opponentNameBusiness: "✧ ✧ ✧ ✧ ✧ ✧ ✧",
      opponentProfilePictureURL: "",
      opponentLocationWork: null
    },
    activityIndicatorAnimating: false,
    aaOnPress: false,
    tmpFollowing: false,
    hidden: false
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    if (this.props.contactID) {
      try {
        this.unsubscribeFirestore = firebase
          .firestore()
          .collection("Profiles")
          .doc(this.props.contactID)
          .onSnapshot(this.updateContact);
      } catch (e) {
        console.log(e)
      }
    }
    else if (this.props.profile) {
      p = this.props.profile;
      let opponentProfileID = p.xID;
      let opponentName = p.profileName;
      let opponentProfilePictureURL = p.profilePictureURL;
      let opponentNameBusiness = p.profileNameBusiness || "";
      let opponentLocationWork = p.locationWork || null;

      let contactProperties = {
        opponentProfileID,
        opponentName,
        opponentNameBusiness,
        opponentProfilePictureURL,
        opponentLocationWork
      };

      //console.log("contactProperties", contactProperties);
      await this.setState({
        contactProperties
      });
      this.checkSearchTerm()
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  // componentWillUpdate() {
  //   this.checkSearchTerm()
  // }

  updateContact = async contactSnapshot => {
    console.log("Update of list of contacts triggered. (Contacts Screen)");
    try {
      let contact = contactSnapshot.data();

      let opponentProfileID = contactSnapshot.id;
      let opponentName = contact.profileName;
      let opponentProfilePictureURL = contact.profilePictureURL;
      let opponentNameBusiness = contact.profileNameBusiness || "";
      let opponentLocationWork = contact.locationWork || null;

      let contactProperties = {
        opponentProfileID,
        opponentName,
        opponentNameBusiness,
        opponentProfilePictureURL,
        opponentLocationWork
      };

      //console.log("contactProperties", contactProperties);
      await this.setState({ contactProperties });
      this.checkSearchTerm()

    } catch (err) {
      console.log("Slow internet. Could not fetch contacts (meContact)", err);
      alert("Could not fetch contacts. Check your internet connection");
    }

    //Image.prefetch(url);
  };

  // delayedUnimate = async () => {
  //   await new Promise(resolve => {
  //     setTimeout(resolve, 2000);
  //   });
  //   this.setState({ activityIndicatorAnimating: false });
  // }

  checkSearchTerm = () => {
    let hidden = true;
    if (this.props.searchTerm && this.props.searchTerm.length > 0) {
      if (this.state.contactProperties.opponentName && this.state.contactProperties.opponentName.toLowerCase().includes(this.props.searchTerm)) {
        hidden = false
      }
      if (this.state.contactProperties.opponentNameBusiness && this.state.contactProperties.opponentNameBusiness.toLowerCase().includes(this.props.searchTerm)) {
        hidden = false
      }
    } else hidden = false
    if (this.state.hidden != hidden) this.setState({ hidden })
  }

  delayedUnimate = async () => {
    await new Promise(resolve => { setTimeout(resolve, 2800); });
    this.setState({ tmpFollowing: true, activityIndicatorAnimating: false });
    await new Promise(resolve => { setTimeout(resolve, 8000); });
    this.setState({ tmpFollowing: false });
  }

  callFollow = async follow => {
    if (this.state.contactProperties.opponentProfileID == global.myProfileID) return;
    if (!this.state.activityIndicatorAnimating) {
      try {
        this.setState({ activityIndicatorAnimating: true });

        // console.log(
        //   "follower: ",
        //   global.myUID,
        //   "followerProfile: ",
        //   global.myProfileID,
        //   "targetProfile: ",
        //   this.state.profileID,
        //   "action: ",
        //   follow ? "Follow" : "Unfollow"
        // );

        const r = await fetch(global.cloudFunctionURL + "followProfile", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            follower: global.myUID,
            followerProfile: global.myProfileID,
            targetProfile: this.state.contactProperties.opponentProfileID,
            action: follow ? "Follow" : "Unfollow",
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
        const r2 = await r.json();


        if (r2.msg === "UPDATE_COMPLETED") {
          this.delayedUnimate();
        } else {
          alert("Follow Request failed");
          this.setState({ activityIndicatorAnimating: false });
        }
      } catch (err) {
        alert("Request not successful.");
        console.log(err);
        this.setState({ activityIndicatorAnimating: false });
      }
    }
  };

  render() {
    this.checkSearchTerm()
    if (this.state.hidden) return (<View />);
    if (this.state.aaOnPress) return (<View
      style={{
        flex: 1,
        height: this.props.height || 65,
        ...(!this.props.noBackgroundColor && { backgroundColor: "black" }),
        alignItems: "center", justifyContent: "center",
        paddingHorizontal: this.props.paddingHorizontal ? this.props.paddingHorizontal : 11,
        borderBottomColor: "#333",
        borderBottomWidth: this.props.noBorder ? 0 : 1
      }}
    >
      <ActivityIndicator size="small" color="#909099" animating={true} />
    </View>);
    let notMyProfile = true;
    if (global.myProfileID == this.state.contactProperties.opponentProfileID) notMyProfile = false
    let distString = null;
    if (this.props.showDistance && global.location && global.location.coords && this.state.contactProperties.opponentLocationWork)
      distString = Math.round(
        distance(
          this.state.contactProperties.opponentLocationWork._latitude, this.state.contactProperties.opponentLocationWork._longitude,
          global.location.coords.latitude, global.location.coords.longitude, "K") * 1) / 1 + " km"
    // if (this.state.contactProperties.opponentLocationWork) console.log(";;;;;;;;;;DIST;;;;", distString, this.props.showDistance, global.location, this.state.contactProperties.opponentLocationWork, distance(
    //   this.state.contactProperties.opponentLocationWork._latitude, this.state.contactProperties.opponentLocationWork._longitude,
    //   global.location.coords.latitude, global.location.coords.longitude, "K"))
    return (
      <TouchableOpacity
        onPress={async () => {
          if (this.state.aaOnPress) return;
          if (this.props.onPress) {
            this.props.onPress(this.state.contactProperties);
            await global.timeout(500)
            this.setState({ aaOnPress: true })
            if (!this.props.noAA) await global.timeout(5000)
            this.setState({ aaOnPress: false })
            return
          }
          if (!this.state.contactProperties.opponentProfileID) {
            return
          }
          let handoverID = this.state.contactProperties.opponentProfileID;
          this.props.navigation.navigate("meProfile", {
            profileID: handoverID
          });
        }}
      >
        <View
          style={[
            {
              flexDirection: "row",
              flex: 1,
              height: this.props.height || 65,
              ...(!this.props.noBackgroundColor && { backgroundColor: "black" }),
              alignItems: "center",
              paddingHorizontal: this.props.paddingHorizontal ? this.props.paddingHorizontal : 11,
              shadowOpacity: 0.5,
              shadowOffset: { width: 0, height: 3 },
              borderBottomColor: "#333",
              borderBottomWidth: this.props.noBorder ? 0 : 1
            }
          ]}
        >
          {!!this.state.contactProperties.opponentProfilePictureURL ? (
            <Image
              style={{ width: 42, height: 42, borderRadius: 21 }}
              source={{
                uri: this.state.contactProperties.opponentProfilePictureURL
              }}
            />
          ) : (
              <View style={{ width: 42, height: 42, borderRadius: 21, backgroundColor: "#223", borderWidth: 0.5, borderColor: "#446" }} />
            )}
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View
              style={{
                flexDirection: "column",
                flex: 1,
                paddingHorizontal: 8
              }}
            >
              <Text style={{ fontSize: 14, color: "#ccccd5" }}>
                {this.state.contactProperties.opponentName}
              </Text>
              {this.state.contactProperties.opponentNameBusiness != "" && (
                <Text style={{ fontSize: 11, color: "#ccccd5" }}>
                  {this.state.contactProperties.opponentNameBusiness}
                </Text>
              )}
            </View>
            {distString && <View
              style={{ alignItems: "center", justifyContent: "center", }}
            >
              <TouchableOpacity
                style={{ alignItems: "center", justifyContent: "center", paddingHorizontal: 8 }}
                onPress={() => {
                  let l = this.state.contactProperties;
                  if (l.opponentLocationWorkAddress) Linking.openURL(`http://maps.google.com/?q=${l.opponentLocationWorkAddress}`);
                  else if (l.opponentLocationWork) Linking.openURL(`http://maps.google.com/?ll=${l.opponentLocationWork._latitude},${l.opponentLocationWork._longitude}`);
                }}
              >
                <Image
                  style={{
                    width: 12,
                    height: 15,
                    marginRight: 3,
                    tintColor: "#ccccd5",
                  }}
                  source={require("../../images/icons/Location.png")}
                />
                <Text style={{ color: "#ccccd5", fontSize: 10 }}>{distString}</Text>
              </TouchableOpacity>
            </View>}
            {!!notMyProfile && <View style={{ width: 60, justifyContent: "center" }}>
              {(this.props.following || this.state.tmpFollowing) ? (<View
                style={{ alignItems: "center", marginBottom: 4 }}
              >
                <Image
                  style={{
                    width: 16,
                    height: 20,
                    marginRight: 3,
                    tintColor: "#ccccd5",
                  }}
                  source={require("../../images/icons/Following.png")}
                />
                <Text style={{ color: "#ccccd5", fontSize: 10 }}>Following</Text>
              </View>)
                : !this.state.activityIndicatorAnimating ? (<TouchableOpacity
                  style={[global.gStyles.buttonXW, { marginTop: 5 }]}
                  onPress={() => {
                    this.callFollow(true);
                  }}
                >
                  <Text style={global.gStyles.buttontXW}>
                    FOLLOW
                  </Text>
                </TouchableOpacity>)
                  :
                  (<ActivityIndicator
                    size="small"
                    color="#FFFFFF"
                    animating={true}
                    style={{ marginRight: 12, marginTop: 2 }}
                  />)
              }
            </View>}
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}
import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  SafeAreaView,
  Keyboard,
  ActivityIndicator,
  Dimensions,
  Platform,
  ScrollView,
  Image,
  Alert,
  Modal
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";

import GetPicture from "../../shared/Camera/GetPicture";
import CurrentLocation from "../../shared/Map/currentLocationV2";
import CurrentLocationModal from "../../shared/Map/currentLocationV2Modal";
const gStyles = global.gStyles //    "../../styles/gStyles";

const tStyle = { color: "#A8CF45", fontSize: 16, fontWeight: "800", textAlign: "left", marginTop: 11, marginBottom: 7 }


export default class MeCommunityUpdateScreen extends Component {
  static navigationOptions = {
    title: "Community",
    headerTransparent: true,
    headerTintColor: "#A8CF45",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1,
      marginTop: 20,
    }
  };

  state = {}; // will be declared in cdm
  community = {}

  opennessSelection = [{
    type: "PUBLIC",
    title: "Public",
    description: "Anybody can find and join this community"
  },
  {
    type: "PRIVATE",
    title: "Private",
    description: "The community is only for selected members"
  }]

  componentDidMount() {
    const action = this.props.navigation.getParam("action", "new")
    if (action == "edit") {
      this.community = this.props.navigation.getParam("community", {})
    }
    let openness = "PUBLIC"
    if (action == "new") {
      openness = this.props.navigation.getParam("openness", "PUBLIC")
    }

    this.setState({
      action,
      name: this.community.name || "",
      description: this.community.description || "",
      //interests: this.community.interests || ["Cars", "Food"],
      showCategories: false,
      category1: this.community.category1 || 'Choose a category',
      picture: this.community.pictureURL || null,
      backgroundPictureURL: this.community.communityBackgroundPictureURL || null,
      openness,
      keyboardSpacerHeight: 56,
      activityIndicatorAnimating: false,
      modalActivityIndicatorAnimating: false,
      showGetPicture: false,
      showGetBackgroundPicture: false,
      modalVisible: false,
      location: this.community.locationCoords ? { coords: this.community.locationCoords, address: "Show on map" } : global.myLocation || {},
      showLocationModal: false,
      modalAVisible: false,
      activeAModal: "",
      activeASelection: [],
    })
  };

  toggleCategory = () => {
    this.setState({ showCategories: !this.state.showCategories })
  }

  chooseCategory = (cat1) => {
    this.setState({ category1: cat1, showCategories: false })
  }

  activateASelection = (ob) => {
    //console.log("this.state.activeAModal", this.state.activeAModal)
    this.setState({ modalAVisible: false })
    const type = ob.type
    console.log("selected type", type)
    if (this.state.activeAModal == "openness") {
      this.setState({ openness: type })
      // if (this.state.activeAModal == "aType") {
      //   this.setState({ aType: type })
      // }
    }
  }

  createCommunity = async () => {
    if (this.state.activityIndicatorAnimating) { return }
    if (this.state.category1 == null) { alert('Select a category'); return }
    if (!this.state.name) { alert('Select the community name'); return }
    if (!this.state.description) { alert('Enter a description'); return }
    if (!this.state.backgroundPictureURL) { alert('Select a logo'); return }

    try {
      this.setState({ activityIndicatorAnimating: true });
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "createCommunity", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          name: this.state.name,
          description: this.state.description,
          pictureURL: this.state.picture,
          openness: this.state.openness,
          backgroundPictureURL: this.state.backgroundPictureURL,
          coords: this.state.location.coords,
          myProfileID: global.myProfileID,
          myUID: global.myUID,
          idToken: global.idToken,
          category1: this.state.category1
        })
      });
      const r2 = await r.json();

      // if success, navigate to Offers screen
      console.log("Received response. ", r2.msg);
      this.setState({ activityIndicatorAnimating: false });
      if (r2.msg === "SUCCESS") {
        this.props.navigation.goBack()
        await global.timeout(500)
        alert("Community created")
      }
      else {
        alert("Not successful, try again")
      }
    } catch (e2) {
      alert("An error occurred. Please try again");
      global.warn(e2, "createCommunity");
      this.setState({ activityIndicatorAnimating: false });
    }
  }
  updateCommunity = async () => {
    if (this.state.activityIndicatorAnimating) { return }
    if (this.state.category1 == null) { alert('Select a category'); return }
    if (!this.state.name) { alert('Community name is required'); return }
    if (!this.state.description) { alert('A description is required'); return }
    if (!this.state.backgroundPictureURL) { alert('Select a logo'); return }

    try {
      this.setState({ activityIndicatorAnimating: true });
      const r = await fetch(global.cloudFunctionURL + "updateCommunity", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "edit",
          communityID: this.community.xID,
          changes: {
            name: this.state.name,
            description: this.state.description,
            pictureURL: this.state.picture,
            backgroundPictureURL: this.state.backgroundPictureURL,
            coords: this.state.location.coords,
            category1: this.state.category1
          },
          myUID: global.myUID,
          idToken: global.idToken,

        })
      });
      const r2 = await r.json();

      // if success, navigate to Offers screen
      console.log("Received response. ", r2.msg);
      this.setState({ activityIndicatorAnimating: false });
      if (r2.msg === "SUCCESS") {
        this.props.navigation.goBack()
        await global.timeout(500)
        alert("Community updated")
      }
      else {
        alert("Not successful, try again")
      }
    } catch (e2) {
      alert("An error occurred. Please try again");
      global.warn(e2, "updateCommunity");
      this.setState({ activityIndicatorAnimating: false });
    }
  }

  render() {
    if (!this.state.action) return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "black" }}>
        <Text style={{ color: "#555" }}>Loading...</Text>
      </View >
    );
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#000" }}>
        <ScrollView ref={ref => (this.scrollView = ref)}
          style={{ backgroundColor: "black", flex: 1 }}>
          {/***** Background Image *****/}
          <View
            style={{
              height: 180,
              justifyContent: "flex-start",
              backgroundColor: "transparent",
              alignItems: "center",
              marginBottom: 0
              //shadowOpacity: 0,
              //shadowOffset: { width: 0, height: 1 },
              //shadowColor: "white"
            }}
          >
            {/* {!!this.state.backgroundPictureURL && (
              <Image
                key={
                  this.state.backgroundPictureURL ||
                  "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/testDummies%2FwBack2.png?alt=media&token=471d98ea-66b4-4753-93e4-4150a417e680"
                }
                style={{ width: Dimensions.get("window").width, height: 180 }}
                source={{
                  uri:
                    this.state.backgroundPictureURL ||
                    "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/testDummies%2FwBack2.png?alt=media&token=471d98ea-66b4-4753-93e4-4150a417e680"
                }}
              />
            )} */}
          </View>
          <View
            style={{
              flex: 1,
              backgroundColor: "#222",
              justifyContent: "space-between",

            }}
          >

          </View>
          <View
            style={{
              ...Platform.select({
                ios: {
                  height: 0,
                  marginBottom: -100
                },
                android: {
                  height: 85,
                  backgroundColor: "rgba(44, 44, 44, 0.3)",
                  marginTop: -180,
                  marginBottom: -5
                }
              })
            }}
          />
          {/* <View
            style={{
              alignItems: "flex-end",
              marginBottom: 30,
              marginRight: 10
            }}
          >
            <TouchableOpacity
              onPress={() => {
                this.setState({ showGetBackgroundPicture: true });
                
              }}
              style={{
                backgroundColor: "rgba(220, 220, 220, 0.8)",
                padding: 3,
                borderRadius: 5
              }}
            >
            </TouchableOpacity>
          </View> */}
          <View
            style={{
              height: 102,
              alignItems: "center"
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: 102,
                height: 102,
                borderRadius: 32,
                backgroundColor: "#FFFFFF",
                shadowOpacity: 1,
                shadowColor: "white",
                shadowOffset: { height: 0, width: 0 },
                shadowRadius: 32
              }}
            >
              {!!this.state.backgroundPictureURL && (
                <Image
                  key={this.state.backgroundPictureURL}
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 32
                  }}
                  source={{
                    uri: this.state.backgroundPictureURL
                  }}
                />
              )}
            </View>
          </View>

          <View
            style={{ alignItems: "center", marginTop: -35, marginBottom: 20 }}
          >
            {this.state.showGetPicture && (
              <GetPicture
                offerAPI
                onDone={async pictureURL => {
                  this.setState({ showGetPicture: false, picture: pictureURL, backgroundPictureURL: pictureURL });
                  console.log("PictureURL", pictureURL);
                }}
                item={"COMMUNITY"}
              />
            )}
            {/* {this.state.showGetBackgroundPicture && (
              <GetPicture
                onDone={async backgroundPictureURL => {
                  this.setState({ showGetBackgroundPicture: false, backgroundPictureURL: backgroundPictureURL });
                  console.log("backgroundPictureURL", backgroundPictureURL);
                }}
                item={"PROFILE_BACKGROUND_PICTURE"}
              />
            )} */}
            <TouchableOpacity
              onPress={() => {
                this.setState({ showGetPicture: true });
                // this.props.navigation.navigate("meTakePicture", {
                //   item: "PROFILE_PICTURE",
                //   nextScreen: "meProfileUpdate"
                // });
              }}
              style={{
                backgroundColor: "rgba(220, 220, 220, 0.8)",
                padding: 3,
                borderRadius: 5
              }}
            >
              <Text style={{ color: "black" }}>Change</Text>
            </TouchableOpacity>
          </View>

          {/***** Name *****/}
          <View
            style={{
              alignItems: "flex-start",
              marginHorizontal: 20,
              flex: 1,
              alignItems: "stretch",

            }}
          >

            <Text style={[tStyle, { alignSelf: "flex-start" }]}>Title</Text>

            <TextInput
              placeholder="Community name"
              placeholderTextColor="#666666"
              onChangeText={name => {
                this.setState({ name });
              }}
              onFocus={() => {
                this.scrollView.scrollTo({ x: 0, y: 50, animated: true });
              }}
              style={{

                color: "#ffffff",
                borderColor: "grey",
                borderWidth: 0,
                paddingLeft: 20,
                borderRadius: 10,
                height: 45,
                backgroundColor: "#334",


              }}
              underlineColorAndroid="transparent"
              maxLength={32}
              defaultValue={this.state.name}
              bordered
            />
          </View>



          {/***** Community Description *****/}
          <View
            style={{
              alignItems: "flex-start",
              marginHorizontal: 20,
              flex: 1,
              alignItems: "stretch",
              marginVertical: 1,
            }}
          >
            <Text style={[tStyle, { alignSelf: "flex-start" }]}>Description</Text>
            <TextInput
              multiline={true}
              placeholder="Community description"
              placeholderTextColor="#666666"
              onChangeText={description => {
                this.setState({ description });
              }}
              onFocus={() => {
                this.scrollView.scrollTo({ x: 0, y: 50, animated: true });
              }}
              style={{
                marginBottom: 5,
                color: "#ffffff",
                borderColor: "grey",
                borderWidth: 0,
                paddingLeft: 20,
                borderRadius: 10,
                height: 120,
                backgroundColor: "#334",
              }}
              underlineColorAndroid="transparent"
              defaultValue={this.state.description}
              bordered
            />

            {this.state.action == "new" && <View>
              <Text style={[tStyle, { alignSelf: "flex-start" }]}>Type</Text>
              <TouchableOpacity onPress={() => this.setState({ modalAVisible: true, activeAModal: "openness", activeASelection: this.opennessSelection })}>
                <ASelector selection={this.opennessSelection} selected={this.state.openness} />
              </TouchableOpacity>
              {/* {this.state.openness == "PRIVATE" && <Text style={{ color: "#666666" }}>You can add members after you create the community</Text>} */}
            </View>}

            <Text style={[tStyle, { alignSelf: "flex-start" }]}>Location</Text>
            <View style={{ borderRadius: 7.5, width: "100%", backgroundColor: "#18181f", }}>
              <CurrentLocation
                onPressEdit={() => { this.setState({ showLocationModal: true }) }}
                setLiveLocation={true}
                textColor={"#ccd"}
                iconColor={"#ccd"}
                location={this.state.location || global.location}
                textSize={12}
                //changeColor={"#ccc"}
                padding={0}
              />
              <View style={{ height: 2 }} />
            </View>
            <View>
              <TouchableOpacity style={{ borderRadius: 7.5, width: "100%", backgroundColor: "#18181f", marginVertical: 10 }} onPress={() => { this.toggleCategory() }} >
                <Text style={{ color: '#aab', padding: 8, alignSelf: 'center' }}>{this.state.category1}</Text>
              </TouchableOpacity>
              <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>

                {this.state.showCategories && global.categoryKeys.map(cat1 => {
                  return (
                    <View key={cat1}>
                      <TouchableOpacity style={{ justifyContent: 'flex-end', height: 30, width: 120, backgroundColor: '#222222', margin: 7, borderRadius: 10 }} onPress={() => { this.chooseCategory(cat1) }} >
                        <Text style={{ alignSelf: 'center', color: '#ffffff', fontWeight: 'bold', marginBottom: 5 }}>{cat1} </Text>
                      </TouchableOpacity>
                    </View>
                  )

                })}
              </View>

            </View>

            <View style={{ height: 30 }} />
            {!this.state.activityIndicatorAnimating ? <TouchableOpacity
              onPress={() => { this.state.action == "new" ? this.createCommunity() : this.updateCommunity() }}
            >
              <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: 120, alignSelf: "center", alignItems: "center" }]} >
                <Text style={{ fontSize: 14, fontWeight: "bold", color: "#000" }}>{this.state.action == "new" ? "OK, Create" : "Update"}</Text>
              </View>
            </TouchableOpacity> :
              <ActivityIndicator
                size="large"
                color="#A8CF45"
                style={{ alignSelf: "center" }}
                animating={true}
              />}
          </View>
          {/* <TouchableOpacity style={global.gStyles.button2} onPress={() => this.createCommunity()}>
            <View style={{ flexDirection: "row" }}>
              {!!this.state.activityIndicatorAnimating ? (
                <ActivityIndicator
                  size="small"
                  color="#000"
                  style={{
                    marginRight: 20
                  }}
                  animating={true}
                />
              ) : (
                  <View style={{ width: 40, height: 20 }} />
                )}

              <View style={{ width: 44 }} />
            </View>
          </TouchableOpacity> */}
        </ScrollView>

        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.modalAVisible}
          onRequestClose={() => { this.setState({ modalAVisible: false }); }}
        >
          <TouchableOpacity
            onPress={() => this.setState({ modalAVisible: false })}
            style={{ flex: 1 }}
          />
          <View
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <View
              style={{
                alignItems: "center", justifyContent: "center", backgroundColor: "#ccd", borderTopEndRadius: 15, borderTopLeftRadius: 15
              }}
            >
              <View style={{ paddingTop: 25 }}>
                <Text style={{ fontSize: 20, fontWeight: "bold", color: "#223" }}>{this.state.activeAModal == "openness" ? "Select who can join" : "Select Type"}</Text>
              </View>
              <View style={{ marginTop: 15, marginBottom: 5 }}>
              </View>
              {this.state.activeASelection.map((x) => {
                return <TouchableOpacity key={x.type} onPress={() => { this.activateASelection(x) }}>
                  <ASelectorW ob={x} />
                </TouchableOpacity>
              })}
              <View style={{ paddingTop: 25 }} />
            </View>
          </View>
        </Modal>

        {!!this.state.showLocationModal && <CurrentLocationModal
          onCancel={() => { this.setState({ showLocationModal: false }) }}
          onSelect={(coords, address) => {
            // console.log("upda", address, coords);
            this.setState({
              showLocationModal: false,
              location: {
                address: address,
                coords: coords
              }
            });
          }}
        />}
      </SafeAreaView>
    );
  }
}

class ASelector extends PureComponent {
  render() {
    let ob = {}
    this.props.selection.forEach((o) => {
      if (o.type == this.props.selected) ob = o
    })
    return (
      <View style={{
        alignItems: "center", justifyContent: "center", height: 85,
        flexDirection: "row", padding: 3,
        borderRadius: 10, width: "100%", backgroundColor: "#18181f",
        marginBottom: 7, marginTop: 3
      }}>
        <View style={{ alignItems: "flex-start", justifyContent: "center", flex: 1, paddingLeft: 10 }}>
          <Text style={{ color: "#ccd", fontSize: 18, fontWeight: "bold" }}>{ob.title}</Text>
          <Text style={{ color: "#99a", fontSize: 14 }}>{ob.description}</Text>
        </View>
        <View style={{ alignItems: "center", justifyContent: "center", width: 50 }}>
          <Text style={{ color: "#ccc", fontSize: 16, fontWeight: "bold", color: "#889" }}>▼</Text>
        </View>
      </View>
    );
  }
}
class ASelectorW extends PureComponent {
  render() {
    const ob = this.props.ob
    return (
      <View style={{
        alignItems: "center", justifyContent: "center", height: 100,
        flexDirection: "row", padding: 3,
        borderRadius: 7.5, width: "100%", backgroundColor: "#ccd",
        marginVertical: 5, borderTopWidth: 1, borderTopColor: "#aab"
      }}>
        <View style={{ alignItems: "flex-start", justifyContent: "center", flex: 1, paddingLeft: 10 }}>
          <Text style={{ color: "#223", fontSize: 18, fontWeight: "bold" }}>{ob.title}</Text>
          <Text style={{ color: "#778", fontSize: 14 }}>{ob.description}</Text>
        </View>
        <View style={{ alignItems: "center", justifyContent: "center", width: 50 }}>
          <Text style={{ color: "#333", fontSize: 20, fontWeight: "bold", color: "#99b" }}></Text>
        </View>
      </View>
    );
  }
}

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Modal,
  Platform,
  Linking,
  Animated,
  Alert,
  Dimensions,
} from "react-native";
import MapResults from "./mapResultsHealth"
import firebase from "firebase";
import "firebase/firestore";
//import { CheckBox } from 'react-native-elements'
import yesTick from '../../../images/icons/yes_tick.png'




class FadeInView extends React.Component {
  state = {
    opacity: new Animated.Value(0),
  }

  componentDidMount = () => {
    Animated.timing(this.state.opacity, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }

  render() {
    return (
      <Animated.View
        onLoad={this.onLoad}
        {...this.props}
        style={[
          {
            opacity: this.state.opacity,
            transform: [
              {
                scale: this.state.opacity.interpolate({
                  inputRange: [0, 3],
                  outputRange: [0.85, 1],
                })
              },
            ],
          },
          this.props.style,
        ]}
      />
    );
  }
}



export default class HomeFoodScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Results",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "red",
    }
  };
  state = {
    resultLocations: [],
    currentLocation: [],
    modalVisible: false,
    modalVisible2: false,
    modalVisible3: false,
    facilities: [],
    filteredResults: [],
    coords: {},
    choice1: 0,
    choice2: 0,
    choice3: 0,
    choice4: 0,
    choice5: 0,
    choice6: 0,
    choice7: 0,
    choice8: 0,
    choice9: 0,
    choice10: 0,
    number: 60,
    filter: "",
    aFilter: {
      "contact_phone": [],
      "functional_status": [],
      "lga_name": [],
      "ownership": [],
      "state_name": [],
      "type": [],
      "category": []
    },
    showLocations: false,
    chosenLocations: {},
    checked: false,
    locations: ["Lagos", "Oyo", "Ogun", "Osun", "Kaduna", "FCT"],
    markerName: "",
    markerAddress: "",
    showMarkerDetails: false
    /* filterTerms: {
      "status_functional": `item.properties.functional_status == "Functional"`,
      "status_unknown": `item.properties.functional_status == "Unknown"`,
      "show_private_ownership": `item.properties.ownership == "Private"`,
      "show_with_contacts_only": `item.properties.contact_phone !== null`,
      "military_health_centers": `item.properties.category == "Military and Paramilitary Clinic"`,
      "facility_type_primary": `item.properties.type == "Primary"`,
      "facility_type_secondary": `item.properties.type == "Secondary"`,
      "facility_type_tertiary": `item.properties.type == "Tertiary"`,
    } */
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    try {
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 200); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 400); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 500); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 500); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 600); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 1000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 2000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 4000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 5000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 8000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 10000); });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 10000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 100000); });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID)
        return;
      await global.setLocationFast(5);
      //this.removeElement(["Functional", "Unknown"], "Unknown")
      this.getFacilities(this.state.number)



    } catch (err) {
      if (global.reportError) {
        global.reportError(err, "catches", "homeFood");
      }
    }
  }


  componentWillUnmount() {
    console.log("Component healthFacilities will unmount");
    this.unsubscribeFirestore();
  }


  getFacilities = async (amount) => {
    this.setState({ activityIndicatorAnimating: true });
    let type = this.props.navigation.getParam("type", "center")
    console.log("fetching Facilities of type " + type)
    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "findFacilities", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          amount,
          type,
          coords: global.location.coords,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ activityIndicatorAnimating: false });

      if (r2.msg === "SUCCESS") {
        let sorted = r2.results.sort((a, b) => {
          return a.xDist - b.xDist
        })
        console.log("R2", r2)
        this.getCoordinates(sorted)
        this.setState({ facilities: sorted, filteredResults: sorted, modalVisible3: false })
        this.setState({ choice1: 0, choice2: 0, choice3: 0, choice4: 0, choice5: 0, choice6: 0, choice7: 0, choice8: 0, choice9: 0, choice10: 0, })
        /* if (this.state.filter != "") {
          let filtered = []
          sorted.forEach(item => {
            if (eval(this.state.filter)) {
              filtered.push(item)
            }
          })
          console.log(" the filter supplied is ", this.state.filter, " the filtered results are: ", filtered)
          this.getCoordinates(filtered)
          this.setState({ facilities: filtered, filteredResults: filtered,modalVisible3: false })
          this.setState({ choice1: 0, choice2: 0, choice3: 0, choice4: 0, choice5: 0, choice6: 0, choice7: 0, choice8: 0, choice9: 0, choice10: 0, })

        } else {
          this.getCoordinates(sorted)
          this.setState({ facilities: sorted, filteredResults: sorted,modalVisible3: false })
          this.setState({ choice1: 0, choice2: 0, choice3: 0, choice4: 0, choice5: 0, choice6: 0, choice7: 0, choice8: 0, choice9: 0, choice10: 0, })
        } */


      } else alert("Error loading data.");
    } catch (err) {
      console.warn("Error ix7")
      console.log("Error ix7", err)
      this.setState({ activityIndicatorAnimating: false });
      if (global.reportError) {
        global.reportError(err, "catches", "healthFacilities");
      }
    }
  };



  getAddress = async (lat, long) => {
    let coords = { lat, long }
    const address = await global.getAddress(coords)
    console.log(" address obtained is ", address)
    return JSON.stringify(address)
  }



  removeElement(array, elem) {
    let final = ""
    var index = array.indexOf(elem);
    if (index > -1) {
      final = array.splice(index, 1);
      //console.log(" what has been removed is ", final, " what is left is ", array)
      return array
    }
  }




  addToQuery = (num) => {
    let data = this.state.aFilter

    if (num == 1) {
      if (this.state.choice1 == 0) {
        data["functional_status"].push("Functional")
        this.setState({ aFilter: data, choice1: 1 })
      } else {
        let a = this.removeElement(data["functional_status"], "Functional")
        data["functional_status"] = a
        this.setState({ aFilter: data, choice1: 0 })
      }
    } else if (num == 2) {
      if (this.state.choice2 == 0) {
        data["functional_status"].push("Unknown")
        this.setState({ aFilter: data, choice2: 2 })
      } else {
        let a = this.removeElement(data["functional_status"], "Unknown")
        data["functional_status"] = a
        this.setState({ aFilter: data, choice2: 0 })
      }
    } else if (num == 3) {
      if (this.state.choice3 == 0) {
        data["ownership"].push("Private")
        this.setState({ aFilter: data, choice3: 3 })
      } else {
        let a = this.removeElement(data["ownership"], "Private")
        data["ownership"] = a
        this.setState({ aFilter: data, choice3: 0 })
      }
    } else if (num == 4) {
      if (this.state.choice4 == 0) {
        data["ownership"].push("Public")
        this.setState({ aFilter: data, choice4: 4 })
      } else {
        let a = this.removeElement(data["ownership"], "Public")
        data["ownership"] = a
        this.setState({ aFilter: data, choice4: 0 })
      }
    } else if (num == 5) {
      if (this.state.choice5 == 0) {
        data["ownership"].push("Others")
        this.setState({ aFilter: data, choice5: 5 })
      } else {
        let a = this.removeElement(data["ownership"], "Others")
        data["ownership"] = a
        this.setState({ aFilter: data, choice5: 0 })
      }
    } else if (num == 6) {
      if (this.state.choice6 == 0) {
        data["contact_phone"].push("Available")
        this.setState({ aFilter: data, choice6: 6 })
      } else {
        let a = this.removeElement(data["contact_phone"], "Available")
        data["contact_phone"] = a
        this.setState({ aFilter: data, choice6: 0 })
      }
    } else if (num == 7) {
      if (this.state.choice7 == 0) {
        data["contact_phone"].push(null)
        this.setState({ aFilter: data, choice7: 7 })
      } else {
        let a = this.removeElement(data["contact_phone"], null)
        data["contact_phone"] = a
        this.setState({ aFilter: data, choice7: 0 })
      }
    } else if (num == 8) {
      if (this.state.choice8 == 0) {
        data["type"].push("Primary")
        this.setState({ aFilter: data, choice8: 8 })
      } else {
        let a = this.removeElement(data["type"], "Primary")
        data["type"] = a
        this.setState({ aFilter: data, choice8: 0 })
      }
    } else if (num == 9) {
      if (this.state.choice9 == 0) {
        data["type"].push("Secondary")
        this.setState({ aFilter: data, choice9: 9 })
      } else {
        let a = this.removeElement(data["type"], "Secondary")
        data["type"] = a
        this.setState({ aFilter: data, choice9: 0 })
      }
    } else if (num == 10) {
      if (this.state.choice10 == 0) {
        data["type"].push("Tertiary")
        this.setState({ aFilter: data, choice10: 10 })
      } else {
        let a = this.removeElement(data["type"], "Tertiary")
        data["type"] = a
        this.setState({ aFilter: data, choice10: 0 })
      }
    }
    //console.log(" aFilter is now ", this.state.aFilter)
  }



  buildFilter = (filter) => {
    let query = {};
    for (let keys in filter) {
      if (filter[keys].constructor === Array && filter[keys].length > 0) {
        query[keys] = filter[keys];
      }
    }
    console.log("query built is ", query)
    return query;
  }


  filterData = () => {
    let query = this.buildFilter(this.state.aFilter)
    let data = this.state.facilities
    const filteredData = data.filter((item) => {
      for (let key in query) {
        /* if (item.properties[key] !== null) {
          return true
        } */
        if (item.properties[key] === undefined || !query[key].includes(item.properties[key])) {
          return false;
        }
      }
      return true;
    });
    console.log("")
    this.setState({ filteredResults: filteredData, modalVisible3: false })
    return filteredData;
  };

  /* 
  
    this.addToQuery = (num) => {
      console.log(" setfilter function called...")
      let filter = this.state.filter
      let len = filter.length
      let newString=""
      let criteria = ""
      let filterTerms = {
        "status_functional": `item.properties.functional_status == "Functional"`,
        "status_unknown": `item.properties.functional_status == "Unknown"`,
        "show_private_ownership": `item.properties.ownership == "Private"`,
        "show_with_contacts_only": `item.properties.contact_phone !== null`,
        "military_health_centers": `item.properties.category == "Military and Paramilitary Clinic"`,
        "facility_type_primary": `item.properties.type == "Primary"`,
        "facility_type_secondary": `item.properties.type == "Secondary"`,
        "facility_type_tertiary": `item.properties.type == "Tertiary"`,
      }
  
  
      if (num == 1) {
        criteria = filterTerms["status_functional"]
        console.log(" criteria is...", criteria)
        this.setState({choice1: this.state.choice1 == 1?0:1})
        if (len > 0 && this.state.choice1 == 1) {
          newString =  filter.split(criteria).join(' ')
          console.log("newstring is ", newString, " filter is ", filter)
          this.setState({ filter: newString});
        }else{
          this.setState({filter: this.state.filter + criteria});
          console.log("filter is ", filter )
        }
      }
  
    }
   */



  getCoordinates = (results) => {

    let locations = []

    results.forEach(item => {
      locations.push({
        locationWork: {
          _latitude: item.properties.latitude,
          _longitude: item.properties.longitude
        },
        name: item.properties.name,
        address: (item.properties.ward_name || "") + ", " + (item.properties.lga_name || "")
      })

    })
    //console.log(" resultlocations  are ", answer)
    this.setState({ resultLocations: locations })

  }




  circle(filled) {
    if (filled) {
      return (
        <View style={{ width: 20, height: 20, borderColor: 'red', borderWidth: 1, padding: 2, borderRadius: 10, justifyContent: "center", alignItems: "center" }}>
          <View style={{ width: 14, height: 14, backgroundColor: 'red', borderRadius: 7 }}></View>
        </View>);

    } else {
      return (<View style={{ width: 20, height: 20, borderColor: 'red', borderWidth: 1, borderRadius: 10 }}></View>);
    }
  }



  render() {
    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: "#fff"
        }}
      >
        <StatusBar barStyle="dark-content" />

        <View
          style={{
            backgroundColor: "#fff",
            flex: 1
          }}
        >
          <View
            style={{
              backgroundColor: "#fff",
              borderBottomColor: "#988",
              borderBottomWidth: 1,
              ...Platform.select({
                ios: {
                  height: 55
                },
                android: {
                  height: 75
                }
              })
            }}
          />
          <ScrollView>
            <View style={{ justifyContent: "center", padding: 20 }}>
              <View style={{ backgroundColor: "#e7e7e7", borderRadius: 15, padding: 15, }}>
                <View style={{ borderBottomWidth: 1, borderColor: '#3C3C3C', marginBottom: 10, flexDirection: "row", justifyContent: "space-between", paddingBottom: 5 }}>
                  <Text style={{ color: "red", fontWeight: "bold", fontSize: 13, marginVertical: 10 }}>Results</Text>
                  {/* <TouchableOpacity
                    onPress={() => {
                      this.setState({ modalVisible3: true })
                    }}
                    style={{ padding: 10, backgroundColor: "red", paddingTop: 12, borderRadius: 10 }}>
                    <Text style={{ color: "white", fontSize: 10 }}>filter: All</Text>
                  </TouchableOpacity> */}

                  <TouchableOpacity
                    onPress={() => {
                      this.setState({ modalVisible: true })
                    }}
                    style={{ padding: 10, backgroundColor: "red", borderRadius: 10, paddingTop: 12 }}>
                    <Text style={{ color: "white", fontSize: 10 }}>View all on Map</Text>
                  </TouchableOpacity>
                </View>


                {this.state.filteredResults.map((item, id) => {
                  let lat = item.geometry.coordinates[1]
                  let long = item.geometry.coordinates[0]
                  let coords = { lat, long }
                  //console.log(" lat and long  are ", lat, long)

                  return (
                    <View key={id}>
                      <View style={{ padding: 10, paddingVertical: 20, backgroundColor: "#d7d7d7", marginVertical: 5, marginTop: 0, borderRadius: 10, }}>
                        <View
                          style={{ padding: 10, paddingBottom: 0, paddingTop: 0, marginVertical: 5, marginTop: 0, borderRadius: 10, flexDirection: "row", justifyContent: "space-between" }}>
                          <Text style={{ fontSize: 11, color: "#444", fontWeight: "bold" }}> {item.properties.name}</Text>
                          {item.properties.contact_phone !== null && <Text style={{ fontSize: 10, color: "#444", }}> {item.properties.contact_phone} </Text>}
                        </View>
                        <View style={{ padding: 10, paddingBottom: 0, marginVertical: 5, }}>
                          <Text style={{ fontSize: 11, color: "#444", }}> {item.properties.ward_name}, {item.properties.lga_name}</Text>
                          <Text style={{ fontSize: 10, color: "#444", }}> {item.xDist.toFixed(1)}km away</Text>
                        </View>
                        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                          <TouchableOpacity
                            style={{ padding: 10, paddingBottom: 0, marginVertical: 5, }}
                            onPress={() => {
                              let coords = []
                              coords.push({
                                locationWork: {
                                  _latitude: item.properties.latitude,
                                  _longitude: item.properties.longitude
                                },
                                name: item.properties.name || "",
                                address: (item.properties.ward_name || "") + ", " + (item.properties.lga_name || "")
                              })
                              this.setState({ modalVisible2: true, currentLocation: coords })
                            }}>
                            <Text style={{ fontSize: 10, color: 'red' }}>View on map</Text>
                          </TouchableOpacity>
                          <TouchableOpacity style={{ padding: 10, paddingBottom: 0, marginVertical: 5, }}
                            onPress={() => {
                              Linking.openURL(
                                `http://maps.google.com/?ll=${item.properties.latitude},${item.properties.longitude}`
                              )
                            }}>
                            <Text style={{ fontSize: 10, color: 'grey', fontWeight: "bold" }}> Get Directions</Text>
                          </TouchableOpacity>
                        </View>

                      </View>

                    </View>
                  )
                })}
                {this.state.facilities.length == 0 ? <Text> Loading...</Text>
                  : <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <TouchableOpacity
                      style={{ padding: 10, backgroundColor: "grey", borderRadius: 10, width: "66%" }}
                      onPress={async () => {
                        let num = this.state.number; num = num + 20;
                        this.setState({ number: num })
                        //console.log(" new num is ", num)
                        await this.getFacilities(num)
                        this.filterData()
                      }}>
                      <Text style={{ color: "white", fontSize: 10 }}>{this.state.filteredResults.length} results found. Tap to load more...</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{ padding: 10, backgroundColor: "red", borderRadius: 10, width: "30%", justifyContent: "center" }}
                      onPress={() => {
                        this.setState({ modalVisible3: true })
                      }}>
                      <Text style={{ color: "white", fontSize: 10, textAlign: "center" }}> filter </Text>
                    </TouchableOpacity>
                  </View>}
              </View>
            </View>

            <Modal
              animationType="slide"
              transparent={true}
              visible={this.state.modalVisible}
              onRequestClose={() => {
                Alert.alert('Modal has been closed.');
              }}>
              <View>
                {global.location && global.location.coords &&
                  <MapResults
                    addresstoMapScreen={global.location.coords}
                    resultArray={this.state.resultLocations}
                    mapDelta={0.02}
                    navigation={this.props.navigation || ""}
                    category1={"Health" || ""}
                    markerDetailsDisplay={(name, address) => {
                      this.setState({ markerName: name, markerAddress: address, showMarkerDetails: true })
                    }}
                  />}
              </View>
              <TouchableOpacity onPress={() => { this.setState({ modalVisible: false, showMarkerDetails:false }) }}
                style={{ position: "absolute", top: 30, right: 20, paddingHorizontal: 20, paddingVertical: 10, backgroundColor: "rgba(255,255,255,0.7)", borderRadius: 10 }}>
                <Text>close</Text>
              </TouchableOpacity>

              {this.state.showMarkerDetails && <FadeInView>
                <View style={{ justifyContent: "center", alignItems: "center", alignSelf: "center", position: "absolute", bottom: 20,  backgroundColor: "white", padding: 20, borderRadius: 20, width: "90%", }}>
                  <FadeInView>
                    <View style={{ borderColor: '#3C3C3C', padding: 5, borderRadius: 5, justifyContent:"center" }}>
                      <Text style={{ paddingLeft: 10, color: "grey", fontSize: 13, fontWeight:"bold" }}>{this.state.markerName || "// No details provided"}</Text>
                      <Text style={{ paddingLeft: 10, color: "grey", fontSize: 11, }}>{this.state.markerAddress || "// No details provided"}</Text>
                    </View>
                  </FadeInView>
                  <TouchableOpacity onPress={() => { this.setState({ showMarkerDetails: false }) }}
                    style={{ paddingHorizontal: 10, paddingVertical: 5, backgroundColor: "#777", borderRadius: 5, marginTop: 10 }}>
                    <Text style={{ fontSize: 10, color: "white" }}>close</Text>
                  </TouchableOpacity>
                </View>
              </FadeInView>}
            </Modal>



            <Modal
              animationType="slide"
              transparent={true}
              visible={this.state.modalVisible2}
              onRequestClose={() => {
                Alert.alert('Modal has been closed.');
              }}>
              <View>
                {global.location && global.location.coords &&
                  <MapResults
                    addresstoMapScreen={global.location.coords}
                    resultArray={this.state.currentLocation}
                    mapDelta={0.02}
                    navigation={this.props.navigation || ""}
                    category1={"Health" || ""}
                    markerDetailsDisplay={(name, address) => {
                      this.setState({ markerName: name, markerAddress: address, showMarkerDetails: true })
                    }}
                  />}
              </View>
              <TouchableOpacity onPress={() => { this.setState({ modalVisible2: false, showMarkerDetails:false }) }}
                style={{
                  right: 20, position: "absolute", top: 30, paddingHorizontal: 20, paddingVertical: 10,
                  backgroundColor: "rgba(255,255,255,0.7)", borderRadius: 10
                }}>
                <Text>close</Text>
              </TouchableOpacity>
              {this.state.showMarkerDetails && <FadeInView>
                <View style={{ justifyContent: "center", alignItems: "center", alignSelf: "center", position: "absolute",  bottom: 20, backgroundColor: "white", padding: 20, borderRadius: 20, width: "90%", }}>

                  <FadeInView>
                    <View style={{ borderColor: '#3C3C3C', padding: 5, borderRadius: 5, justifyContent:"center" }}>
                      <Text style={{ paddingLeft: 10, color: "grey",fontSize: 13, fontWeight:"bold" }}>{this.state.markerName || "// No details provided"}</Text>
                      <Text style={{ paddingLeft: 10, color: "grey", fontSize: 11, }}>{this.state.markerAddress || "// No details provided"}</Text>
                    </View>
                  </FadeInView>
                  <TouchableOpacity onPress={() => { this.setState({ showMarkerDetails: false }) }}
                    style={{ paddingHorizontal: 10, paddingVertical: 5, backgroundColor: "#777", borderRadius: 5, marginTop: 10 }}>
                    <Text style={{ fontSize: 10, color: "white" }}>close</Text>
                  </TouchableOpacity>
                </View>
              </FadeInView>}
            </Modal>



            <Modal
              animationType="slide"
              transparent={true}
              visible={this.state.modalVisible3}
              onRequestClose={() => {
                Alert.alert('Modal has been closed.');
              }}>
              <View style={{ flex: 1, justifyContent: "center", alignItems: "center", height: 400, width: "100%" }}>

                <View style={{ backgroundColor: "white", padding: 20, borderRadius: 20, width: "80%" }}>
                  <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderBottomWidth: 1, borderColor: '#3C3C3C', marginBottom: 10 }}>
                    <Text>Filter results</Text>
                    <TouchableOpacity onPress={() => { this.setState({ modalVisible3: false }) }}
                      style={{ paddingHorizontal: 10, paddingVertical: 5, backgroundColor: "#777", borderRadius: 5, marginBottom: 5 }}>
                      <Text style={{ fontSize: 10, color: "white" }}>close</Text>
                    </TouchableOpacity>
                  </View>

                  {!this.state.showLocations && <View id="row1" style={{ flexDirection: "row", backgroundColor: "#f8f8f8", justifyContent: "space-between", borderRadius: 10 }}>
                    <View id="status" style={{ padding: 5, }}>
                      <Text style={{ paddingLeft: 10, color: "grey", fontSize: 10, fontWeight: "bold" }}>Status</Text>
                      <View style={{ padding: 20, paddingTop: 10, alignItems: "stretch", borderRightWidth: 1, borderColor: '#3C3C3C', }} >
                        <TouchableOpacity style={{ flexDirection: "row", marginVertical: 2, alignItems: "center" }}
                          onPress={() => { this.addToQuery(1) }}>{this.circle(this.state.choice1 == 1 ? true : false)}
                          <Text style={{ color: "grey", marginLeft: 10, fontSize: 11 }}>Functional</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => { this.addToQuery(2) }} style={{ flexDirection: "row", marginVertical: 2, alignItems: "center" }} >{this.circle(this.state.choice2 == 2 ? true : false)}
                          <Text style={{ color: "grey", marginLeft: 10, fontSize: 11 }}>Unknown</Text>
                        </TouchableOpacity>
                      </View>
                    </View>

                    <View id="ownership" style={{ borderColor: '#3C3C3C', padding: 5, borderRadius: 5 }}>
                      <Text style={{ paddingLeft: 10, color: "grey", fontSize: 10, fontWeight: "bold" }}>Ownership</Text>
                      <View style={{ padding: 20, paddingBottom: 10, paddingTop: 10, alignItems: "stretch", }} >
                        <TouchableOpacity onPress={() => { this.addToQuery(3) }} style={{ flexDirection: "row", marginVertical: 2, alignItems: "center" }} >{this.circle(this.state.choice3 == 3 ? true : false)}
                          <Text style={{ color: "grey", marginLeft: 10, fontSize: 11 }}>Private</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => { this.addToQuery(4) }} style={{ flexDirection: "row", marginVertical: 2, alignItems: "center" }} >{this.circle(this.state.choice4 == 4 ? true : false)}
                          <Text style={{ color: "grey", marginLeft: 10, fontSize: 11 }}>Public</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => { this.addToQuery(5) }} style={{ flexDirection: "row", marginVertical: 2, alignItems: "center" }} >{this.circle(this.state.choice5 == 5 ? true : false)}
                          <Text style={{ color: "grey", marginLeft: 10, fontSize: 11 }}>Others</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>}


                  {!this.state.showLocations && <View id="row2" style={{ flexDirection: "row", backgroundColor: "#f8f8f8", justifyContent: "space-between", borderRadius: 10, marginTop: 5 }}>
                    {/* <View id="contact_available" style={{ padding: 5, paddingRight: 10 }}>
                      <Text style={{ paddingLeft: 10, color: "grey", fontSize: 10, fontWeight: "bold" }}>Contact Numbers</Text>
                      <View style={{ padding: 20, paddingTop: 10, alignItems: "stretch", borderRightWidth: 1, borderColor: '#3C3C3C', }} >
                        <TouchableOpacity onPress={() => { this.addToQuery(6) }} style={{ flexDirection: "row", marginVertical: 2, alignItems: "center" }} >{this.circle(this.state.choice6 == 6 ? true : false)}
                          <Text style={{ color: "grey", marginLeft: 10, fontSize: 11 }}>Available</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => { this.addToQuery(7) }} style={{ flexDirection: "row", marginVertical: 2, alignItems: "center" }} >{this.circle(this.state.choice7 == 7 ? true : false)}
                          <Text style={{ color: "grey", marginLeft: 10, fontSize: 11 }}>contact n/a</Text>
                        </TouchableOpacity>
                      </View>
                    </View> */}

                    <View id="facility_type" style={{ borderColor: '#3C3C3C', padding: 5, borderRadius: 5, width: "50%" }}>
                      <Text style={{ paddingLeft: 10, color: "grey", fontSize: 10, fontWeight: "bold" }}>Facility Type</Text>
                      <View style={{ paddingLeft: 20, paddingBottom: 10, paddingTop: 10, alignItems: "stretch", }} >
                        <TouchableOpacity onPress={() => { this.addToQuery(8) }} style={{ flexDirection: "row", marginVertical: 2, alignItems: "center" }} >{this.circle(this.state.choice8 == 8 ? true : false)}
                          <Text style={{ color: "grey", marginLeft: 10, fontSize: 11 }}>Primary</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => { this.addToQuery(9) }} style={{ flexDirection: "row", marginVertical: 2, alignItems: "center" }} >{this.circle(this.state.choice9 == 9 ? true : false)}
                          <Text style={{ color: "grey", marginLeft: 10, fontSize: 11 }}>Secondary</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => { this.addToQuery(10) }} style={{ flexDirection: "row", marginVertical: 2, alignItems: "center" }} >{this.circle(this.state.choice10 == 10 ? true : false)}
                          <Text style={{ color: "grey", marginLeft: 10, fontSize: 11 }}>Tertiary</Text>
                        </TouchableOpacity>
                      </View>
                    </View>

                  </View>}


                  <View id="row3" style={{ flexDirection: "row", backgroundColor: "#f8f8f8", justifyContent: "space-between", borderRadius: 10, marginTop: 5 }}>
                    <View id="locations" style={{ borderColor: '#3C3C3C', padding: 5, borderRadius: 5, width: "100%" }}>
                      <Text style={{ paddingLeft: 10, color: "grey", fontSize: 10, fontWeight: "bold" }}>Location(s)</Text>
                      <View style={{ paddingLeft: 10, paddingBottom: 10, paddingTop: 10, width: '100%' }} >
                        {this.state.showLocations && <TouchableOpacity onPress={() => { this.setState({ showLocations: false }) }}
                          style={{ position: "absolute", top: -10, right: 5, marginLeft: 10, paddingHorizontal: 10, paddingVertical: 5, backgroundColor: "blue", borderRadius: 5 }}>
                          <Text style={{ fontSize: 11, color: "white" }}> {" < "}</Text>
                        </TouchableOpacity>}
                        {!this.state.showLocations && <TouchableOpacity
                          onPress={() => { this.setState({ showLocations: true }) }}
                          style={{ flexDirection: "row", backgroundColor: "#e2e3e3", justifyContent: "space-between", padding: 5, paddingHorizontal: 10, marginVertical: 2, alignItems: "center", borderRadius: 3 }} >
                          <Text style={{ color: "grey", fontSize: 11 }}> {this.state.aFilter.state_name.toString() || "Near You"} </Text>
                          <Text style={{ fontSize: 10, color: "grey", }}>{this.state.showLocations == true ? "^" : "⌄"}</Text>
                        </TouchableOpacity>}

                        {this.state.showLocations && <Text style={{ color: "grey", fontSize: 11, marginVertical: 10, marginTop: -5 }}> ---- tap to choose --- </Text>}

                        {this.state.showLocations && <View style={{ justifyContent: "space-evenly", flexWrap: "wrap", flexDirection: "row" }}>

                          {this.state.locations.map((item, id) => {
                            /* let locations = {}
                            locations[item] =  */
                            return (
                              <TouchableOpacity key={id} style={{ flexDirection: "row", marginVertical: 5, alignItems: "center", width: "40%" }}
                                onPress={() => {
                                  let data = this.state.aFilter
                                  let cLocations = this.state.chosenLocations
                                  if (this.state.chosenLocations[item] !== "chosen") {
                                    data["state_name"].push(item)
                                    cLocations[item] = "chosen"
                                    this.setState({ aFilter: data, chosenLocations: cLocations })
                                  } else {
                                    let a = this.removeElement(data["state_name"], item)
                                    data["state_name"] = a
                                    cLocations[item] = "not chosen"
                                    this.setState({ aFilter: data, chosenLocations: cLocations })
                                  }
                                }}>
                                {this.state.chosenLocations[item] == "chosen" ? <Image style={{ width: 15, height: 15 }} source={require("../../../images/icons/yes_tick.png")}></Image> : <Text></Text>}
                                <Text style={{ color: "grey", marginLeft: 10, fontSize: 12 }}>{item}</Text>
                              </TouchableOpacity>
                            )
                          })}

                        </View>}

                      </View>
                    </View>
                  </View>



                  <View style={{ flexDirection: "row", justifyContent: "flex-end", marginTop: 10 }}>
                    <TouchableOpacity
                      onPress={() => {
                        this.setState({
                          choice1: 0, choice2: 0, choice3: 0, choice4: 0, choice5: 0, choice6: 0, choice7: 0, choice8: 0, choice9: 0, choice10: 0,
                          filteredResults: this.state.facilities,
                          showLocations: false,
                          chosenLocations: {},
                          number: 60,
                          aFilter: {
                            "contact_phone": [],
                            "functional_status": [],
                            "lga_name": [],
                            "ownership": [],
                            "state_name": [],
                            "type": [],
                            "category": []
                          },
                        })
                      }}
                      style={{ marginRight: 10, paddingHorizontal: 10, paddingVertical: 5, backgroundColor: "#777", borderRadius: 5, marginBottom: 5 }}>
                      <Text style={{ color: "white", fontSize: 11 }}> Reset </Text>
                    </TouchableOpacity>


                    {!!this.state.activityIndicatorAnimating ? (
                      <View
                        style={{
                          paddingHorizontal: 20, backgroundColor: "red", borderRadius: 5, marginBottom: 5
                        }}
                      >
                        <ActivityIndicator
                          size="small"
                          color="#fff"
                          style={{
                            opacity: 1
                          }}
                          animating={true}
                        />
                      </View>
                    ) : <TouchableOpacity
                      onPress={async () => {
                        //let results =  this.filterData(query) 
                        this.setState({ activityIndicatorAnimating: true, number: 500 })
                        await this.getFacilities(500)
                        this.filterData()
                        //this.setState({ filteredResults: this.filterData(), number:1000 })
                      }}
                      style={{ paddingHorizontal: 10, paddingVertical: 5, backgroundColor: "red", borderRadius: 5, marginBottom: 5 }}>
                        <Text style={{ color: "white", fontSize: 11 }}> Submit </Text>
                      </TouchableOpacity>}

                  </View>
                </View>
              </View>

            </Modal>
          </ScrollView>
        </View>
      </SafeAreaView>
    );
  }
}

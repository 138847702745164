import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  ScrollView,
  Modal,
  TouchableWithoutFeedback,
  ActivityIndicator,
  Dimensions,
  Platform,
  SafeAreaView
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import Moment from "react-moment";
import FundMyAccount from "./fundMyAccount";
import Pay from "./pay";
import PayToBankAccount from "./payToBankAccount";
import SelectBank from "./selectBank";
import RecipientSelector from "./selectRecipient";

class Transaction extends PureComponent {
  state = {
    type: null,
    amSender: false,
    opponentProfileID: null,
    opponentName: "********",
    opponentNameBusiness: "************",
    opponentProfilePictureURL: null,
    remarks: "",
    date: null,
    amount: -0.0,
    invisible: false,
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    const data = this.props.data;
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 200); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 500); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 1000); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 2000); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 3000); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 4000); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 5000); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 8000); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 10000); });
    if (!global.myUID || !global.myProfileID) await global.setGlobals();

    let opponentProfileID;
    if (data.sender == global.myProfileID) {
      this.setState({ amSender: true });
      opponentProfileID = data.recipient;
    } else {
      if (
        data.recipient != global.myProfileID &&
        data.type != "INITIALIZATION"
      ) {
        console.log("WARNING. Transaction record broken. Investigate!");
        alert("Error displaying transaction record");
        return;
      }
      this.setState({ amSender: false });
      opponentProfileID = data.sender;
    }
    if (
      data.type == "TRANSFER" ||
      data.type == "TRANSFER_TO_ESCROW" ||
      data.type == "TRANSFER_FROM_ESCROW" ||
      data.type == "TRANSFER_FROM_ESCROW_SUCCESS"
    ) {
      try {
        const proDoc = await firebase
          .firestore()
          .collection("Profiles")
          .doc(opponentProfileID)
          .get();
        this.updateProfile(proDoc)

        // this.unsubscribeFirestore = firebase
        //   .firestore()
        //   .collection("Profiles")
        //   .doc(opponentProfileID)
        //   .onSnapshot(async s => {
        //     this.updateProfile(s);
        //   });
      } catch (e) {
        console.log(e)
      }
    }
    if (data.type == "INPAYMENT") {
      this.setState({
        opponentName: "ACCOUNT FUNDING",
        opponentNameBusiness: "",
        opponentProfilePictureURL:
          "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FFunding.png?alt=media&token=d1beeafd-f555-4eb3-883f-036d08f4154d"
      });
    }
    if (data.type == "OUTPAYMENT") {
      this.setState({
        opponentName: "PAYMENT TO BANK ACCOUNT",
        opponentNameBusiness: "",
        opponentProfilePictureURL:
          "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FPayout.png?alt=media&token=677e4479-1aa9-481e-b6cb-641681864258"
      });
    }
    if (data.type == "INITIALIZATION") {
      this.setState({
        opponentName: "WALLET SETUP",
        opponentNameBusiness: "",
        opponentProfilePictureURL:
          "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FStar.png?alt=media&token=dad9e07a-c6d2-4526-907e-04858ec6861b"
      });
    }
    if (data.type == "INVISIBLE(ESCROW)") {
      this.setState({
        invisible: true
      });
      return;
    }
    const remarks = data.remarks || "";
    let amount = data.balanceChange;
    if (
      data.type == "TRANSFER_TO_ESCROW" ||
      data.type == "TRANSFER_FROM_ESCROW"
    ) {
      amount = -data.escrowChange;
    }
    this.setState({
      remarks: remarks.toString(),
      amount,
      date: data.creationDate.toDate(),
      type: data.type,
      opponentProfileID
    });

    this.rerenderRegularly()
  }

  componentWillUnmount() {
    // this.unsubscribeFirestore();
  }

  updateProfile = async profileSnapshot => {
    let opponentProfile = profileSnapshot.data();
    let opponentName = opponentProfile.profileName;
    let opponentProfilePictureURL = opponentProfile.profilePictureURL;
    let opponentNameBusiness = opponentProfile.profileNameBusiness;

    this.setState({
      opponentName,
      opponentNameBusiness,
      opponentProfilePictureURL
    });
  };

  rerenderRegularly = async () => {
    // while (true){
    //   await global.timeout(1000000)
    //   this.forceUpdate()
    // }
  }

  render() {
    B = props => <Text style={{ fontWeight: "bold" }}>{props.children}</Text>; //just for reference (delete?)

    const calendarStrings = {
      sameDay: "LT",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      lastDay: "[Yesterday]",
      lastWeek: "dddd",
      sameElse: "DD-MM-YYYY"
    };

    colT = this.state.amount < 0 ? "#0059A8" : "#00A859";
    newM = ""; // could change to (new) for unseen transactions

    lineBreaks = !this.state.remarks ? 0 : this.state.remarks.split("\n").length - 1;
    text =
      lineBreaks < 2
        ? this.state.remarks || ""
        : this.state.remarks.split("\n")[0] + "...";

    let overallStyle = { width: Dimensions.get("window").width };
    if (this.state.invisible)
      overallStyle = { width: Dimensions.get("window").width, height: 0 };

    return (
      <TouchableOpacity
        style={overallStyle}
        onPress={() => {
          if (this.state.opponentProfileID) {
            if (this.state.opponentProfileID != "CARD" && this.state.opponentProfileID != "BANK_ACCOUNT") {
              this.props.navigation.navigate("meProfile", {
                profileID: this.state.opponentProfileID
              });
            }
          }
        }}
        onLongPress={() => {
          if (
            //global.devMode ||
            global.myUID == "+2348181088440" ||
            global.myUID == "+2340000000009"
          ) {
            alert(this.state.opponentProfileID);
          }
        }}
      >
        <View
          style={{
            flexDirection: "row",
            flex: 1,
            height: 75,
            backgroundColor: "#000",
            alignItems: "center",
            paddingLeft: 11,
            paddingRight: 11,
            shadowOpacity: 0.5,
            shadowOffset: { width: 0, height: 3 },
            borderBottomColor: "#555555",
            borderWidth: 1
          }}
        >
          <View style={{ width: 58 }}>
            {!!this.state.opponentProfilePictureURL && (
              <View>
                <Image
                  style={{
                    width: 50,
                    height: 50,
                    paddingRight: 8,
                    borderRadius: 3
                  }}
                  source={{
                    uri: this.state.opponentProfilePictureURL
                  }}
                />
              </View>
            )}
          </View>
          <View style={{ flexDirection: "column", flex: 1 }}>
            <View style={{ flexDirection: "row" }}>
              <View
                style={{
                  flexDirection: "column",
                  flex: 1,
                  paddingHorizontal: 8
                }}
              >
                <Text style={{ fontSize: 11, color: colT }}>
                  {this.state.opponentName + newM}
                </Text>
                {!!this.state.opponentNameBusiness && (
                  <Text style={{ fontSize: 11, color: colT }}>
                    {this.state.opponentNameBusiness}
                  </Text>
                )}
              </View>
              <View
                style={{
                  flexDirection: "row",
                  paddingHorizontal: 8,
                  alignItems: "baseline",
                  justifyContent: "flex-end"
                }}
              >
                <View>
                  <Text
                    style={{
                      fontSize: 10,
                      color: colT,
                      fontStyle: "italic",
                      textAlign: "right",
                      marginBottom: 10
                    }}
                  >
                    {this.state.date != null && (
                      <Moment element={Text} calendar={calendarStrings}>
                        {this.state.date}
                      </Moment>
                    )}
                  </Text>
                  {this.props.walletType == "NGN" && <Text
                    style={{ fontSize: 16, color: colT, fontWeight: "800", textAlign: "right" }}
                  >
                    {this.state.amount} ₦
                  </Text>}
                  {this.props.walletType == "ACN" && <Text
                    style={{ fontSize: 16, color: colT, fontWeight: "800", textAlign: "right" }}
                  >
                    {this.state.amount.toLocaleString('en')} ₳
                    </Text>}
                  {this.props.walletType == "ACN" &&
                    <View style={{ flexDirection: "row", justifyContent: "space-between", backgroundColor: colT, borderRadius: 25, paddingHorizontal: 2, paddingTop: 1, paddingBottom: 1 }}>
                      <Text style={{ fontSize: 10, paddingBottom: 1 }} >≈</Text>
                      <Text style={{ fontSize: 11, fontWeight: "800", }} >
                        {(this.state.amount * global.xRates[global.myLocalCurrency || "GHS"]).toFixed(2).toLocaleString('en')} ₵
                        </Text>
                    </View>}
                </View>
              </View>
            </View>
            <View style={{ paddingHorizontal: 8 }}>
              <Text style={{ fontSize: 10, color: colT, fontStyle: "italic" }}>
                {this.state.remarks.length > 120 ? text + "..." : text}
                {/* {this.state.remarks} */}
              </Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

export default class MeWalletScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    return {
      headerTransparent: true,

      headerRight: () => {
        return (
          <View style={{ marginRight: 10, elevation: 4 }}>
            <TouchableOpacity onPress={navigation.getParam("optionMenu")}>
              <View style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#000",
                //borderWidth: 1,
                //borderColor: "#668",
                width: 75,
                height: 35,
                borderRadius: 18,
                borderColor: "#444",
                paddingHorizontal: 8,
                paddingVertical: 5
              }}>
                <Text
                  style={{
                    fontWeight: "bold", fontSize: 24, color: "#008866", shadowOpacity: 1,
                    elevation: 1, paddingRight: 6, paddingTop: Platform.OS == "ios" ? 5 : 0
                  }}
                >
                  ⋮
            </Text>
                <Text style={{ fontSize: 12, color: "#008866", fontWeight: "bold" }}>
                  More
            </Text>
              </View>
            </TouchableOpacity>
          </View>
        )
      }
    };
  };

  state = {
    walletType: null,

    walletBalance: 0,
    walletAmountInEscrowByYou: -1,
    walletAmountInEscrowForYou: -1,
    coinBalance: 0,
    coinAmountInEscrowByYou: -1,
    coinAmountInEscrowForYou: -1,
    coinResetAfterBlock: null,
    blockedUntil: null,
    blockedByUserUntil: null,
    blockedBySystemUntil: null,
    promtChangePassword: false,
    transactions: {},
    myName: "*****",
    bankCode: null,
    bankName: null,
    activityIndicatorAnimating: false,
    showFundModal: false,
    showPayModal: false,
    showMenuModal: false,
    showSelectBankModal: false,
    showPayToBankAccountModal: false,
    loading: true,
    loadingPaymentRecord: true
  };

  constructor() {
    super();
    const firestore = firebase.firestore();

    this.unsubscribeFirestore = () => { return; };
    this.unsubscribeFirestore2 = null;
  }
  async componentDidMount() {
    this.props.navigation.setParams({
      optionMenu: this.optionMenu
    });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 1000) });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 2000) });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 3000) });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 4000) });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 5000) });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 9000) });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 9000) });
    if (!global.myUID) {
      console.log("Error. No global UID found. setting globals again");
      await global.setGlobals();
    }
    try {
      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .onSnapshot(this.updateUser);
      this.unsubscribeFirestore2 = firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .collection("Payments")
        .doc("record")
        .onSnapshot(this.updatePaymentRecord);
    } catch (error) {
      console.log(error)
    }
    if (global.walletType) {
      this.setState({ walletType: global.walletType })
    } else {
      while (!global.walletType) {
        await new Promise(resolve => { setTimeout(resolve, 500); });
      }
      this.setState({ walletType: global.walletType })
    }
  }
  componentWillUnmount() {
    this.unsubscribeFirestore();
    this.unsubscribeFirestore2();
  }

  updateUser = async snapshot => {
    const user = snapshot.data();
    if (!user) return;
    const walletBalance = user.walletBalance - user.walletAmountInEscrowByYou;
    const coinBalance = user.coinBalance - user.coinAmountInEscrowByYou;
    let blockedUntil = null;
    let blockedByUserUntil = null;
    let blockedBySystemUntil = null;
    const now = new Date()
    if (user.coinBlockedByUserUntil && !(user.coinBlockedByUserUntil.toDate() < now)) {
      blockedByUserUntil = user.coinBlockedByUserUntil.toDate()
      blockedUntil = blockedByUserUntil
    }
    if (user.coinBlockedBySystemUntil && !(user.coinBlockedBySystemUntil.toDate() < now)) {
      blockedBySystemUntil = user.coinBlockedBySystemUntil.toDate()
      blockedUntil = blockedBySystemUntil
    }
    if (blockedBySystemUntil && blockedByUserUntil) blockedUntil = Math.max(blockedBySystemUntil, blockedByUserUntil)

    if (typeof walletBalance == "number") { // works also for ACN as walletBalance will be 0
      this.setState({
        walletBalance: walletBalance || 0,
        walletAmountInEscrowByYou: user.walletAmountInEscrowByYou || 0,
        walletAmountInEscrowForYou: user.walletAmountInEscrowForYou || 0,
        coinBalance: coinBalance || 0,
        coinAmountInEscrowByYou: user.coinAmountInEscrowByYou || 0,
        coinAmountInEscrowForYou: user.coinAmountInEscrowForYou || 0,
        coinResetAfterBlock: user.coinResetAfterBlock,
        blockedUntil,
        blockedByUserUntil,
        blockedBySystemUntil,
        loading: false
      });
      console.log("New wallet data", {
        walletBalance: walletBalance || 0,
        walletAmountInEscrowByYou: user.walletAmountInEscrowByYou || 0,
        walletAmountInEscrowForYou: user.walletAmountInEscrowForYou || 0,
        coinBalance: coinBalance || 0,
        coinAmountInEscrowByYou: user.coinAmountInEscrowByYou || 0,
        coinAmountInEscrowForYou: user.coinAmountInEscrowForYou || 0,
      })
      global.walletBalance = walletBalance || 0
      global.walletAmountInEscrowByYou = user.walletAmountInEscrowByYou || 0
      global.walletAmountInEscrowForYou = user.walletAmountInEscrowForYou || 0
      global.coinBalance = coinBalance || 0
      global.coinAmountInEscrowByYou = user.coinAmountInEscrowByYou || 0
      global.coinAmountInEscrowForYou = user.coinAmountInEscrowForYou || 0
      global.coinResetAfterBlock = user.coinResetAfterBlock
      global.coinLastResetDate = user.coinLastResetDate ? user.coinLastResetDate.toDate() : null,
        global.blockedUntil = blockedUntil
      global.coinSignKeySet = user.coinSignKey
      global.coinSignKey = user.coinSignKey

    }
    global.walletType = user.usesACN ? "ACN" : "NGN"
    global.myBlacklist = user.blacklist || [];
    global.myBlocklist = user.blocklist || [];
    global.myBlackedlist = user.blackedlist || [];
    global.myBlockedlist = user.blockedlist || [];
    global.myProfileID = user.idProfile;
    global.myHealthFileID = user.idFile;
    global.amDoctor = user.isVerifiedDoctor;
    global.myHealthProfileID = global.amDoctor ? user.idHealthProfile : null;
    global.amAdmin = user.isAdmin || false;
    global.myLocalCurrency = user.localCurrency || "NGN"
    if (user.websiteTrialJustSelected) global.websiteTrialJustSelected = user.websiteTrialJustSelected
    else global.websiteTrialJustSelected = null
    if (user.pushToken) {
      global.myPushToken = user.pushToken;
      global.myPushBadge = user.pushBadge
    }
    global.amApprover = user.isApprover ? true : false;
    if (user.websiteJustPurchased || user.websiteTrialJustSelected > 0 || user.idShop) global.hasNoWebsiteYet = false
    else global.hasNoWebsiteYet = true

    global.myInterests = user.interests || []
    global.myCommunities = user.communities || []
    global.myTimeCreated = user.timeCreated?.toDate?.()
    if (!("interests" in user)) global.myInterestsNeverSet = true
    else global.myInterestsNeverSet = false

    if (user.rndDiscounts) {
      if (global.rndDiscounts !== user.rndDiscounts) global.rndDiscountsNeedsFetch = true
      if (global.rndDiscountsNeedsFetch == true) this.fetchMyDiscounts()
      global.rndDiscounts = user.rndDiscounts
    };
    global.hasSignupReferrals = user.hasSignupReferrals
  }
  updatePaymentRecord = async snapshot => {
    const record = snapshot.data();
    const transactions = record.transactions;
    this.setState({
      transactions,
      loadingPaymentRecord: false,
      randomValue: record.randomValue
    });
  };
  fetchMyDiscounts = async () => {
    console.log("Fetching my Discounts")
    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "discount", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isWeb:true,
          action: "getMyCustomerDiscounts",
          myUID: global.myUID,
          idToken: global.idToken,
        }),
      });
      const r2 = await r.json();
      if (r2.msg !== "SUCCESS") global.rndDiscountsNeedsFetch = true
      else {
        if (r2.response && r2.response.discounts) {
          global.myDiscounts = r2.response.discounts
          //console.log("MyDiscounts updated:", global.myDiscounts)
          global.rndDiscountsNeedsFetch = false
        }
      }
    } catch (e) {
      console.log("Error fetching discounts!!")
      global.rndDiscountsNeedsFetch = true
    }
  }

  optionMenu = () => {
    this.setState({ showMenuModal: true });
  };

  callPay = async () => { // copy of this function in VoiceMain
    if (global.walletType == "ACN") {
      const now = new Date()
      if ((this.state.blockedUntil > now)) {
        alert("Your wallet is currently blocked for outgoing payments.")
        return;
      }
      if (global.coinResetAfterBlock) {
        alert("Sign out of Wakanda and sign in again to be able to make payments again")
        return;
      }
      if ((!global.coinSignKeySet)) {
        this.props.navigation.navigate("walletSetup", { screen: "0" });
        return;
      }
    }
    //this.props.navigation.navigate("meSelectContact", {
    this.props.navigation.navigate("walletSelectContact", {
      purpose: "Pay"
      //nextScreen: "meWallet"
    });
  };

  callOutpay = async () => {
    if (this.state.walletType == "ACN") return;
    this.setState({ showSelectBankModal: true, showMenuModal: false });
  };

  render() {
    const now = new Date()
    let blockedString = "";
    if (this.state.blockedUntil) {
      const diff = this.state.blockedUntil.getTime() - now.getTime()
      console.log("diff", diff)
      if (diff > 100000000) blockedString = Math.round(diff / 86400000) + " days."
      else blockedString = Math.round(diff / 3600000) + " hours."
    }
    console.log("blockedString", blockedString)
    console.log("this.state.blockedUntil", this.state.blockedUntil)
    console.log("blockedBySystemUntil", this.state.blockedBySystemUntil)
    console.log("blockedByUserUntil", this.state.blockedByUserUntil)

    if (!this.state.walletType) return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "#000" }} >
        <ActivityIndicator size="large" color="#0D6833" />
      </View>
    )
    else return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#000000" }}>
        <View style={{ flex: 1, backgroundColor: "black" }}>
          {Platform.OS === "android" && <View style={{ height: 20 }} />}
          {/***** ₳ Wallet *****/}
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              margin: 30,
              marginTop: 50
            }}
          >
            <TouchableOpacity
              onPress={this.callPay}
              style={{
                alignItems: "center",
                justifyContent: "flex-end",
                backgroundColor: "#000",
                borderWidth: 1,
                width: 55,
                height: 55,
                borderRadius: 18,
                borderColor: "#444",
                paddingHorizontal: 8,
                paddingVertical: 5
              }}
            >
              <Image
                style={{
                  width: 25,
                  height: 25,
                  marginBottom: 4
                }}
                source={require("../../images/icons/Payout.png")}
              />
              <Text
                style={{
                  fontSize: 12,
                  color: "#bbb",
                  fontWeight: "bold"
                }}
              >
                Pay
              </Text>
            </TouchableOpacity>

            <View
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <View>
                <View style={{ flexDirection: "row", marginBottom: 8, alignItems: "flex-end", justifyContent: "center", marginTop: 15 }}>
                  <Image
                    style={{ width: 30, height: 24, marginLeft: 5, }}
                    source={require("../../images/logos/home_logo.png")}
                  />
                  <Text style={{ fontSize: 14, color: "white", paddingRight: 13, fontWeight: "bold" }}>WALLET</Text>
                </View>
                {!this.state.loading ? (
                  <View >
                    {this.state.walletType == "NGN" && <Text
                      style={{ fontSize: 24, fontWeight: "bold", color: "#00A859" }}
                    >
                      {this.state.walletBalance.toLocaleString('en')} ₦
                  </Text>}
                    {this.state.walletType == "ACN" && <Text
                      style={{ fontSize: 24, fontWeight: "bold", color: "#00A859" }}
                    >
                      {Math.round(this.state.coinBalance).toLocaleString('en')} ₳
                    </Text>}
                    {this.state.walletType == "ACN" &&
                      <View style={{ flexDirection: "row", justifyContent: "space-between", backgroundColor: "#00A859", borderRadius: 25, paddingHorizontal: 3, paddingTop: 1, paddingBottom: 1 }}>
                        <Text style={{ textAlign: "center", fontSize: 12, paddingTop: 1 }} >≈</Text>
                        <Text style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }} >
                          {(Math.round(100 * this.state.coinBalance * global.xRates[global.myLocalCurrency || "GHS"]) / 100).toLocaleString('en')} ₵
                        </Text>
                      </View>}
                  </View>
                ) : (
                  <ActivityIndicator
                    size="small"
                    color="#00A859"
                    style={{}}
                    animating={true}
                  />
                )}
              </View>
            </View>
            <TouchableOpacity
              onPress={async () => {
                if (!global.myUID) {
                  await global.setGlobals();
                }
                if (!global.myUID) {
                  return
                }
                if (global.walletType == "ACN") {
                  this.props.navigation.navigate("walletTrader");
                }
                if (global.walletType == "NGN") {
                  this.setState({ showFundModal: true });
                }
              }}
              style={{
                alignItems: "center",
                justifyContent: "flex-end",
                backgroundColor: "#000",
                borderWidth: 1,
                borderColor: "#668",
                width: 55,
                height: 55,
                borderRadius: 18,
                borderColor: "#444",
                paddingHorizontal: 8,
                paddingVertical: 5
              }}
            >
              <Image
                style={{
                  width: 25,
                  height: 25,
                  marginBottom: 4
                }}
                source={require("../../images/icons/Funding.png")}
              />
              <Text
                style={{
                  fontSize: 12,
                  color: "#bbb",
                  fontWeight: "bold"
                }}
              >
                Fund
              </Text>
            </TouchableOpacity>
          </View>
          {!!this.state.blockedUntil && <View style={{ backgroundColor: "#800", width: "100%", height: 20, borderTopWidth: 4, borderTopColor: "gray" }}>
            <Text
              style={{
                color: "#fff",
                fontSize: 12,
                alignSelf: "center",
                marginTop: 0
              }}
            >
              Wallet blocked. No outgoing payments for {blockedString}
            </Text>
          </View>}

          {/* {!!this.state.xxxxxtbd && <View style={{ backgroundColor: "#404", width: "100%", height: 20, borderTopWidth: 4, borderTopColor: "gray" }}>
            <Text
              style={{
                color: "#fff",
                fontSize: 12,
                alignSelf: "center",
                marginTop: 0
              }}
            >
              Wallet unblocked. Press Pay to set new password
            </Text>
          </View>} */}

          {/****** Last Transactions ******/}
          <View
            style={{
              flex: 4,
              backgroundColor: "#000000",
              alignItems: "center",
              justifyContent: "center",
              borderBottomWidth: 4, //xxx
              borderBottomColor: "gray",
              borderTopWidth: 4,
              borderTopColor: "gray"
            }}
          >
            {/* <View style={{ backgroundColor: "#000", width: "100%" }}>
              <Text
                style={{
                  color: "#888",
                  fontSize: 11,
                  alignSelf: "center",
                  marginTop: 0
                }}
              >
                Last 30 transactions:
              </Text>
            </View> */}
            <ScrollView>
              <View>
                {this.state.transactions.length > 1 ? (
                  this.state.transactions.map((t, i) => {
                    return (
                      <Transaction
                        key={i + " " + Math.round(this.state.randomValue)}
                        data={t}
                        navigation={this.props.navigation}
                        walletType={this.state.walletType}
                      />
                    );
                  })
                ) : (
                  <View>
                    {!this.state.loadingPaymentRecord ? (
                      <Text style={{ color: "white" }}>
                        No transactions yet
                      </Text>
                    ) : (
                      <Text style={{ color: "white" }}>Loading...</Text>
                    )}
                  </View>
                )}
              </View>
            </ScrollView>
          </View>

          {/****** ESCROW ******/}
          <View
            style={{
              flex: 1,
              backgroundColor: "#000000",
              alignItems: "center",
              justifyContent: "center",
              borderBottomWidth: 0,
              borderBottomColor: "grey"
            }}
          >
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
              }}
              onPress={() => {
                this.props.navigation.navigate("jobsMain");
              }}
            >
              <View style={{ flex: 1, alignItems: "center" }}>
                <Text style={{ fontSize: 12, color: "white" }}>
                  In Escrow For You
                </Text>
                {!this.state.loading ? (<View>
                  {this.state.walletType == "NGN" && <Text
                    style={{ fontSize: 20, fontWeight: "bold", color: "#00A859" }}
                  >
                    {this.state.walletAmountInEscrowForYou} ₦
                  </Text>}
                  {this.state.walletType == "ACN" &&
                    <Text
                      style={{ fontSize: 20, fontWeight: "bold", color: "#00A859" }}
                    >
                      {this.state.coinAmountInEscrowForYou.toFixed(0).toLocaleString('en')} ₳
                  </Text>}
                  {this.state.walletType == "ACN" &&
                    <View style={{ flexDirection: "row", justifyContent: "space-between", backgroundColor: '#00A859', borderRadius: 25, paddingHorizontal: 3, paddingTop: 1, paddingBottom: 1 }}>
                      <Text style={{ fontSize: 12, paddingBottom: 1 }} >≈</Text>
                      <Text style={{ fontSize: 14, fontWeight: "800", }} >
                        {(this.state.coinAmountInEscrowForYou * global.xRates[global.myLocalCurrency || "GHS"]).toFixed(2).toLocaleString('en')} ₵
                        </Text>
                    </View>}
                </View>
                ) : (
                  <ActivityIndicator
                    size="small"
                    color="#00A859"
                    style={{}}
                    animating={true}
                  />
                )}
              </View>
              <View style={{ flex: 1, alignItems: "center" }}>
                <Text style={{ fontSize: 12, color: "white" }}>
                  In Escrow By You
                </Text>
                {!this.state.loading ? (<View>
                  {this.state.walletType == "NGN" && <Text
                    style={{ fontSize: 20, fontWeight: "bold", color: "#0059A8" }}
                  >
                    {this.state.walletAmountInEscrowByYou} ₦
                  </Text>}
                  {this.state.walletType == "ACN" &&
                    <Text
                      style={{ fontSize: 20, fontWeight: "bold", color: "#0059A8" }}
                    >
                      {this.state.coinAmountInEscrowByYou.toFixed(0).toLocaleString('en')} ₳
                  </Text>}
                  {this.state.walletType == "ACN" &&
                    <View style={{ flexDirection: "row", justifyContent: "space-between", backgroundColor: '#0059A8', borderRadius: 25, paddingHorizontal: 3, paddingTop: 1, paddingBottom: 1 }}>
                      <Text style={{ fontSize: 12, paddingBottom: 1 }} >≈</Text>
                      <Text style={{ fontSize: 14, fontWeight: "800", }} >
                        {(this.state.coinAmountInEscrowByYou * global.xRates[global.myLocalCurrency || "GHS"]).toFixed(2).toLocaleString('en')} ₵
                        </Text>
                    </View>}
                </View>
                ) : (
                  <ActivityIndicator
                    size="small"
                    color="#00A859"
                    style={{}}
                    animating={true}
                  />
                )}
              </View>
            </TouchableOpacity>
          </View>

          {/* ***** Loans *****
        <View
          style={{
            flex: 1,
            backgroundColor: "#000000",
            alignItems: "center",
            justifyContent: "center",
            borderBottomWidth: 1,
            borderBottomColor: "grey"
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <Text style={{ color: "white", fontSize: 18 }}>Loans</Text>
          </View>
        </View> */}
        </View>
        <Modal
          animationType="fade"
          transparent={true}
          visible={this.state.showFundModal}
          onRequestClose={() => {
            //this.setState({showFundModal: false});
          }}
        >
          <FundMyAccount
            onClose={message => {
              this.setState({ showFundModal: false });
            }}
            onCloseFail={async message => {
              this.setState({ showFundModal: false });
              await new Promise(resolve => {
                setTimeout(resolve, 500);
              });
              alert("Error...")
              alert("Error message from bank: " + message);
            }}
            onCloseSuccess={async () => {
              this.setState({ showFundModal: false });
              await new Promise(resolve => {
                setTimeout(resolve, 500);
              });
              alert("Account successfully funded.");
            }}
            walletBalance={this.state.walletBalance}
            myName={this.state.myName}
          />
        </Modal>
        <Modal
          animationType="fade"
          transparent={true}
          visible={this.state.showSelectBankModal}
          onRequestClose={() => {
            //this.setState({showFundModal: false});
          }}
        >
          <SelectBank
            onClose={() => {
              this.setState({ showSelectBankModal: false });
            }}
            onSelect={(bankCode, bankName) => {
              this.setState({
                showSelectBankModal: false,
                showPayToBankAccountModal: true,
                bankCode,
                bankName
              });
            }}
          />
        </Modal>
        <Modal
          animationType="fade"
          transparent={true}
          visible={this.state.showPayToBankAccountModal}
          onRequestClose={() => {
            //this.setState({showFundModal: false});
          }}
        >
          <PayToBankAccount
            onClose={() => {
              this.setState({ showPayToBankAccountModal: false });
              console.log("PayToBankAccount called onClose()");
            }}
            onCloseSuccess={async () => {
              this.setState({ showPayToBankAccountModal: false });
              await new Promise(resolve => {
                setTimeout(resolve, 500);
              });
              alert("Payout successful");
              console.log("Alerting -Payout successful-...");
            }}
            walletBalance={this.state.walletBalance}
            bankName={this.state.bankName}
            bankCode={this.state.bankCode}
          />
        </Modal>
        <Modal
          animationType="fade"
          transparent={true}
          visible={this.state.showPayModal}
          onRequestClose={() => {
            //this.setState({showFundModal: false});
          }}
        >
          <Pay
            onClose={() => {
              this.setState({ showPayModal: false });
            }}
            walletBalance={this.state.walletBalance}
          />
        </Modal>

        <Modal
          animationType="fade"
          transparent={true}
          visible={this.state.showMenuModal}
          style={{}}
          onRequestClose={() => {
            //alert("Modal has been closed.");
          }}
        >
          <TouchableWithoutFeedback
            onPress={() => this.setState({ showMenuModal: false })}
          >
            <View
              style={{
                height: "100%",
                width: "100%",
                alignItems: "flex-end"
              }}
            >
              <View
                style={{
                  marginRight: 20,
                  marginTop: 75,
                  height: global.walletType == "ACN" ? 150 : 50,
                  width: 200,
                  backgroundColor: "#222",
                  borderRadius: 5,
                  borderColor: "#000",
                  borderWidth: 1
                }}
              >
                {global.walletType == "NGN" && <TouchableOpacity
                  style={{
                    height: 50,
                    width: 200,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 15,
                    borderBottomWidth: 1,
                    borderColor: "#000"
                  }}
                  onPress={async () => {
                    this.callOutpay();
                  }}
                >
                  <Text style={{ color: "#ccc", fontWeight: "bold" }}>
                    Pay To Bank
                  </Text>
                </TouchableOpacity>}
                {global.walletType == "ACN" && <TouchableOpacity
                  style={{
                    height: 50,
                    width: 200,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 15,
                    borderBottomWidth: 1,
                    borderColor: "#000"
                  }}
                  onPress={async () => {
                    this.setState({ showMenuModal: false });
                    await global.timeout(50)
                    this.props.navigation.navigate("walletTrader");
                  }}
                >
                  <Text style={{ color: "#ccc", fontWeight: "bold" }}>
                    Find ACoin Trader
                  </Text>
                </TouchableOpacity>}
                {global.walletType == "ACN" && <TouchableOpacity
                  style={{
                    height: 50,
                    width: 200,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 15,
                    borderBottomWidth: 1,
                    borderColor: "#000"
                  }}
                  onPress={async () => {
                    this.setState({ showMenuModal: false });
                    const now = new Date()
                    await global.timeout(50)
                    if ((this.state.blockedUntil > now)) {
                      alert("Your wallet is currently blocked for outgoing payments.")
                      return;
                    }
                    this.props.navigation.navigate("walletSetup");
                  }}
                >
                  <Text style={{ color: "#ccc", fontWeight: "bold" }}>
                    Change Wallet Password
                  </Text>
                </TouchableOpacity>}
                {global.walletType == "ACN" && <TouchableOpacity
                  style={{
                    height: 50,
                    width: 200,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 15,
                    borderBottomWidth: 1,
                    borderColor: "#000"
                  }}
                  onPress={async () => {
                    this.setState({ showMenuModal: false });
                    await global.timeout(50)
                    this.props.navigation.navigate("walletAbout");
                  }}
                >
                  <Text style={{ color: "#ccc", fontWeight: "bold" }}>
                    About ACoin
                  </Text>
                </TouchableOpacity>}
              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      </SafeAreaView>
    );
  }
}

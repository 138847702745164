import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  Platform,
  Keyboard,
  Button,
  KeyboardAvoidingView,
  TextInput,
  Modal,
  Linking,
  ActivityIndicator,
} from "react-native";
import Moment from "react-moment";
import DateTimePicker from '@react-native-community/datetimepicker';
import firebase from "firebase";
import "firebase/firestore";
const gStyles = global.gStyles //    "../../../../styles/gStyles";
import CachedImage from "../../../../shared/CachedImage"
import BottomMenu from "../../../../shared/BottomMenu"
import DoBoost from "./DoBoost"



class Record extends Component {
  state = {
    showDetails: false,
    notes: null,
    noteText: "",
    aa: false,
    snoozeDate: null,
    boostModal: false,
    overwrittenBoostFactor: null,// will be set if the boost factor is adjusted
    isNewCode: false,
    showDatePicker: false,
    hidden: false
  };

  constructor() {
    super();
    const nowDate = new Date()
    this.tomorrowDate = new Date(nowDate.getTime() + 1 * 60000 * 60 * 24);
  }

  async componentDidMount() {
  }

  componentWillUnmount() {
  }


  submitNote = async () => {
    if (!this.state.noteText) return alert("No text entered")
    Keyboard.dismiss();
    const noteText = this.state.noteText
    this.setState({ aa: true })

    try {
      await global.fetchIdToken()
      const r = await fetch(
        global.cloudFunctionURL + "setCRM",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            action: "addNote",
            noteData: { text: this.state.noteText },
            recordID: this.props.record.uid,
            myUID: global.myUID,
            idToken: global.idToken
          })
        }
      );
      const r2 = await r.json();
      this.setState({ aa: false });
      if (r2.msg === "SUCCESS") {
        this.setState({ noteText: "", notes: r2.notes })
      } else {
        alert("Server error. Unable to send " + this.state.noteText.substring(0, 70))
      }
    } catch (e2) {
      alert("Error. Could not send " + this.state.noteText.substring(0, 70))
      this.setState({ aa: false });
      console.log(e2);
    }
  }

  snooze = async () => {
    if (!this.state.snoozeDate) return;
    if (this.state.snoozeDate < new Date()) return alert("Select a date in the future")
    Keyboard.dismiss();
    this.setState({ hidden: true })

    try {
      if (this.state.noteText) await this.submitNote()

      const r = await fetch(
        global.cloudFunctionURL + "setCRM",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            action: "snooze",
            snoozeDate: this.state.snoozeDate,
            recordID: this.props.record.uid,
            overwriteExpirationDate: this.props.record.shopIsExpiring,
            myUID: global.myUID,
            idToken: global.idToken
          })
        }
      );
      const r2 = await r.json();
      if (r2.msg === "SUCCESS") {
        //
      } else {
        alert("Server error. Unable to send " + this.state.noteText.substring(0, 70))
        this.setState({ hidden: false });
      }
    } catch (e2) {
      alert("Error. Could not send " + this.state.noteText.substring(0, 70))
      this.setState({ hidden: false });
      console.log(e2);
    }
  }

  done = async () => {
    Keyboard.dismiss();
    this.setState({ hidden: true })

    try {
      if (this.state.noteText) await this.submitNote()

      const r = await fetch(
        global.cloudFunctionURL + "setCRM",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            action: "done",
            recordID: this.props.record.uid,
            overwriteExpirationDate: this.props.record.shopIsExpiring,
            myUID: global.myUID,
            idToken: global.idToken
          })
        }
      );
      const r2 = await r.json();
      if (r2.msg === "SUCCESS") {
        //
      } else {
        alert("Server error. Unable to set to Done. ")
        this.setState({ hidden: false });
      }
    } catch (e2) {
      alert("Error. Unable to set to Done. ")
      this.setState({ hidden: false });
      console.log(e2);
    }
  }

  getBoostSmiley = () => {
    let factor = this.state.overwrittenBoostFactor || this.props.record.boostFactor
    if (this.state.overwrittenBoostFactor == 0) factor = 0
    return (factor == 1) ? "" :
      factor >= 4 ? "🤩" :
        factor >= 2 ? "👍" :
          factor < 1 ? "💩" : "1-2"
  }

  renderNote = (note) => {
    return (<View key={note.id} style={{ width: "100%", padding: 5, borderWidth: 0, borderColor: "#334", marginVertical: 6 }}>
      <View style={{ flexDirection: "row" }}>
        {note.authorPictureURL ? (
          <CachedImage
            style={{ width: 16, height: 16, borderRadius: 8 }}
            source={{ uri: note.authorPictureURL }}
            key={note.authorPictureURL}
          />
        ) : (
          <View style={{ width: 16, height: 16, borderRadius: 8, backgroundColor: "#445" }} />
        )}
        <Text style={{ color: "#bbc", marginLeft: 8, fontSize: 10 }}><Moment fromNow element={Text}>{note.timeCreated}</Moment></Text>
      </View>
      <Text style={{ color: "#bbc", marginLeft: 22 }}>{note.noteData.text || "Error"}</Text>
    </View>)
  }

  renderDatePicker = () => {
    return (
      <View style={{ backgroundColor: "white" }}>

        <DateTimePicker
          testID="dateTimePicker"
          timeZoneOffsetInMinutes={0}
          value={this.state.snoozeDate}
          mode={"date"}
          is24Hour={true}
          display="default"
          onChange={async (event, selectedDate) => {
            const theDate = selectedDate || this.tomorrowDate;
            //console.log("theDate", theDate, event)

            if (event.type == "set") { // Android
              await this.setState({ snoozeDate: theDate, showDatePicker: false })
              this.snooze()
            } else if (event.type == "dismissed") { // Android
              this.setState({ showDatePicker: false })
            } else { // ios
              this.setState({ snoozeDate: theDate })
            }
          }}
        />
        {Platform.OS == "ios" && <View style={{ flexDirection: "row", justifyContent: "space-around", alignItems: "center", height: 80 }}>
          <Button onPress={() => { this.setState({ showDatePicker: false }) }} title="Cancel" />
          <Button onPress={() => { this.snooze() }} title="Snooze" />
        </View>}
      </View>)
  }

  renderTypeField = () => {
    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            paddingHorizontal: 5,
            marginTop: 5
          }}
        >
          {global.myProfilePictureURL ? (
            <CachedImage
              style={{ width: 25, height: 25, borderRadius: 12.5, marginRight: 6 }}
              key={global.myProfilePictureURL}
              source={{ uri: global.myProfilePictureURL }}
            />
          ) : (
            <View style={{ width: 25, height: 25, borderRadius: 12.5, backgroundColor: "#668", marginRight: 6 }} />
          )}
          <TextInput
            onFocus={() => { }}
            onChangeText={noteText => {
              this.setState({ noteText });
            }}
            value={this.state.noteText}
            placeholder={"Add a note..."}
            placeholderTextColor={"#aab"}
            style={{ textAlign: "center", color: "#ffffff", flex: 1, fontSize: 12, borderRadius: 12.5, backgroundColor: "#334", paddingRight: 65, paddingLeft: 5, minHeight: 25 }}
            multiline
            maxLength={2000}
            underlineColorAndroid="transparent"
            defaultValue={this.state.term}
            keyboardShouldPersistTaps={"always"}
            keyboardDismissMode={"on-drag"}
            keyboardAppearance={"dark"}
            autoCompleteType={"off"}
          //clearButtonMode={"always"}
          />
          {!this.state.aa && <TouchableOpacity
            style={{
              alignItems: "center", justifyContent: "center", padding: 1, marginLeft: -40, width: 40, height: 24.5, borderTopEndRadius: 12.5, borderBottomEndRadius: 12.5, borderLeftWidth: 0.5, borderColor: "#667", backgroundColor: "#445"
            }}
            onPress={() => this.submitNote()}
          >
            <Image
              style={{
                width: 12, height: 12, marginRight: 1, tintColor: "white"
              }}
              source={require("../../../../images/icons/Send.png")}
            />
          </TouchableOpacity>}
        </View>
      </View>
    );
  }

  renderDetails = (notes) => {

    return (<View style={{ marginTop: 5 }}>
      {this.state.aa ?
        <View style={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center", width: "100%", height: 30 }}>
          <ActivityIndicator size="small" color="#bbc" />
          <Text style={{ color: "#bbc", marginLeft: 8, fontSize: 10 }}>Submitting...</Text>
        </View>
        :
        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", marginVertical: 5 }}>
          <View>
            <TouchableOpacity
              onPress={() => {
                if (!this.props.record.profileID) return;
                this.props.navigation.navigate("meProfile", { profileID: this.props.record.profileID });
              }}
              style={[global.gStyles.buttonXW, { margin: 3, minWidth: 120, borderRadius: 2 }]}>
              <Text style={global.gStyles.buttontXW}>Show Profile</Text>
            </TouchableOpacity>
          </View>
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              onPress={() => { this.done() }}
              style={[global.gStyles.buttonXW, { margin: 3, minWidth: 65 }]}>
              <Text style={global.gStyles.buttontXW}>✓ Done</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => { this.setState({ showDatePicker: true, snoozeDate: this.tomorrowDate }) }}
              style={[global.gStyles.buttonX, { margin: 3, minWidth: 65 }]}>
              <Text style={global.gStyles.buttontX}>⏲ Snooze</Text>
            </TouchableOpacity>
          </View>
        </View>}
      {this.state.showDatePicker && this.renderDatePicker()}
      {this.props.record.shopID && <View style={{ borderRadius: 8, borderWidth: 0, borderColor: "#778", padding: 5 }}>
        <View style={{ flexDirection: "row" }}>
          <View style={{ height: 16, borderRadius: 2, backgroundColor: "#889", alignItems: "center", justifyContent: "center", marginRight: 5 }}>
            <Text style={{ fontSize: 10, fontWeight: "bold", marginHorizontal: 2 }}>www</Text>
          </View>
          <TouchableOpacity onPress={() => Linking.openURL(this.props.record.shopURL)}>
            <Text style={{ color: "#bbc", textDecorationLine: "underline" }}>{this.props.record.shopURL}</Text>
          </TouchableOpacity>
        </View>
        <Text style={{ color: "#bbc", marginTop: 2, fontSize: 10, marginLeft: 35 }}>Created <Moment fromNow element={Text}>
          {this.props.record.shopTimeCreated}
        </Moment></Text>
        {/* <Text style={{ color: "#bbc", marginTop: 2, fontSize: 10, marginLeft: 35 }}>Last updated <Moment fromNow element={Text}>
          {this.props.record.shopLastUpdated}
        </Moment></Text> */}
        {this.props.record.shopTLDStatus && <Text style={{ fontSize: 10, marginLeft: 35, color: (this.props.record.shopTLDStatus == "OPEN") ? "red" : (this.props.record.shopTLDStatus == "IN_PROGRESS") ? "blue" : "#bbc", marginTop: 2 }}>Website Setup Status: {this.props.record.shopTLDStatus}</Text>}
        {this.props.record.shopHasCart && <Text style={{ fontSize: 10, marginLeft: 35, color: "#bbc", marginTop: 2 }}>Shopping Cart enabled</Text>}
      </View>}
      <View style={{ borderRadius: 8, borderWidth: 0, borderColor: "#778", padding: 5 }}>
        <View style={{ flexDirection: "row", width: "100%", flex: 1 }}>
          <View style={{ height: 16, borderRadius: 2, alignItems: "center", justifyContent: "center", marginRight: 5 }}>
            <Text style={{ color: "#bbc", fontSize: 9, fontWeight: "bold", marginHorizontal: 2 }}>RANK</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={{ color: "#bbc", fontSize: 12 }}>General Score: {this.props.record.score}</Text>
            <Text style={{ color: "#bbc", fontSize: 12 }}>Boosting: {this.getBoostSmiley() || "-"}</Text>
          </View>
          <View>
            <TouchableOpacity
              onPress={() => { this.setState({ boostModal: true }) }}
              style={[global.gStyles.buttonXW, { margin: 3, minWidth: 65 }]}>
              <Text style={global.gStyles.buttontXW}>Boost</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      {notes.length > 0 && notes.map(note => {
        return this.renderNote(note)
      })}
      <View style={{ height: 10 }}></View>
      {this.renderTypeField()}
      <View style={{ height: 20 }} />
      {/* <TouchableOpacity
        onPress={() => { this.setState({ showDetails: false }) }}
        style={[global.gStyles.buttonXW, { margin: 15, marginTop: 20, borderColor: "#556", borderTopColor: "#131313", borderTopEndRadius: 0, borderTopLeftRadius: 0, marginHorizontal: -15 }]}>
        <Text style={global.gStyles.buttontXW}>Hide Details</Text>
      </TouchableOpacity> */}
      <Modal
        animationType="fade"
        transparent={true}
        visible={this.state.boostModal}
        onRequestClose={() => {
          //alert("Modal has been closed.");
        }}
      >
        <DoBoost
          boostFactor={this.state.overwrittenBoostFactor || this.props.record.boostFactor}
          profileID={this.props.record.profileID}
          setBoostFactor={(overwrittenBoostFactor, notes) => {
            this.setState({ overwrittenBoostFactor })
            if (notes) this.setState({ notes })
          }}
          closeModal={() => { this.setState({ boostModal: false }) }}
          navigation={this.props.navigation}
        />
      </Modal>
    </View >
    );
  }

  render() {
    let notes = this.props.record.record.notes || []
    if (this.state.notes) notes = this.state.notes // if new notes come in, they are written to the state

    if (this.state.hidden)
      return (
        <View style={{ height: 0, width: 0, opacity: 0 }} />
      )

    return (
      <TouchableOpacity
        onPress={() => { this.setState({ showDetails: !this.state.showDetails }) }}
      >
        <View
          style={[
            { backgroundColor: "black", padding: 3, paddingLeft: 11, paddingRight: 11, shadowOpacity: 0.5, shadowOffset: { width: 0, height: 3 }, borderBottomColor: "#333", borderBottomWidth: 1, }
          ]}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            {!!this.props.record.profilePictureURL ? (
              <Image
                style={{ width: 42, height: 42, borderRadius: 4 }}
                source={{
                  uri: this.props.record.profilePictureURL
                }}
              />
            ) : (
              <View style={{ width: 42, height: 42, borderRadius: 4, backgroundColor: "#445" }} />
            )}
            <View
              style={{ flex: 1, paddingHorizontal: 8 }}
            >
              <Text style={{ fontSize: 12, color: "#bbbbc5" }}>
                {this.props.record.profileName}
              </Text>
              {this.props.record.profileNameBusiness && (
                <Text style={{ fontSize: 12, color: "#bbbbc5" }}>
                  {this.props.record.profileNameBusiness}
                </Text>
              )}
              {(this.props.record.record.lastSnoozedTill || this.props.record.shopRenewalDate) ? <View>
                {this.props.record.shopRenewalDate && <Text style={this.props.record.shopIsExpiring ? { fontSize: 12, fontWeight: "bold", color: "#0088cc" } : { fontSize: 10, color: "#56565a" }}>
                  Website Renewal due <Moment fromNow element={Text}>
                    {this.props.record.shopRenewalDate}
                  </Moment>
                </Text>}
                {this.props.record.record.lastSnoozedTill && <Text style={{ fontSize: 12, fontWeight: "bold", color: "#cc8800" }}>
                  Snoozed for <Moment fromNow element={Text}>
                    {this.props.record.record.lastSnoozedTill}
                  </Moment>
                </Text>}
                <Text style={{ fontSize: 10, color: "#56565a" }}>
                  Signup <Moment fromNow element={Text}>
                    {this.props.record.timeFirstSignin}
                  </Moment>
                </Text>
                <Text style={{ fontSize: 10, color: "#56565a" }}>
                  Last Active <Moment fromNow element={Text}>
                    {this.props.record.timeLastActive}
                  </Moment>
                </Text>
              </View>
                : <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
                  <Text style={{ fontSize: 12, color: "#A8CF45" }}>
                    <Moment fromNow element={Text}>
                      {this.props.record.timeFirstSignin}
                    </Moment>
                  </Text>
                  <Text style={{ fontSize: 10, color: "#56565a" }}> (Signup)</Text>
                </View>}

            </View>
            {!!this.getBoostSmiley() &&
              <TouchableOpacity onPress={() => { alert("This is the boost status") }}
                style={{ width: 18, height: 18, alignItems: "center", justifyContent: "center", marginLeft: 4 }}>
                <Text
                  style={{ fontSize: 14, fontWeight: "bold", paddingBottom: Platform.OS == "android" ? 2 : 0 }}>
                  {this.getBoostSmiley()}
                </Text>
              </TouchableOpacity>}

            {(this.props.record.isUsingWallet) &&
              <TouchableOpacity onPress={() => { alert("₳: User have money in his wallet") }}
                style={{ width: 16, height: 16, borderRadius: 8, backgroundColor: "#889", alignItems: "center", justifyContent: "center", marginLeft: 4 }}>
                <Text
                  style={{ fontSize: 14, fontWeight: "bold", paddingBottom: Platform.OS == "android" ? 2 : 0 }}>
                  ₳
              </Text>
              </TouchableOpacity>}

            {(this.props.record.shopID) &&
              <View style={{ height: 16, borderRadius: 2, backgroundColor: "#889", alignItems: "center", justifyContent: "center", marginLeft: 4 }}>
                <Text style={{ fontSize: 10, fontWeight: "bold", marginHorizontal: 2 }}>www</Text>
              </View>}
            {(notes.length > 0) &&
              <View style={{ margin: 6, justifyContent: "flex-end", alignItems: "center", flexDirection: "row" }}>
                <Image
                  style={{ height: 16, width: 17, marginRight: 4, tintColor: "#889" }}
                  source={require("../../../../images/icons/Chat.png")}
                />
                <Text style={{ fontSize: 10, color: "#889", fontWeight: "500" }}>{notes.length}</Text>
              </View>}
          </View>
          {this.state.showDetails && this.renderDetails(notes)}
        </View>
      </TouchableOpacity>
    );
  }
}



export default class CRMScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "CRM",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };
  state = {
    records: [],
    nOfRecordsShown: 100,
    country: "All",
    view: "CRM",
    showCountryMenu: false,
    showViewMenu: false,
    status: "Relevant",
    showStatusMenu: false,
    loading: true,
    loadingMore: true
  };
  constructor() {
    super();
  }

  componentDidMount = async () => {

    try {
      this.setState({ loading: true });

      let countryCurrencies = ["NGN", "GHS"]
      if (this.state.country == "NG+") countryCurrencies = ["NGN"]
      if (this.state.country == "GH") countryCurrencies = ["GHS"]

      let statusSearched = "NEW"
      if (this.state.status == "Snoozed") statusSearched = "SNOOZED"
      if (this.state.status == "Done") statusSearched = "DONE"

      await global.fetchIdToken()
      let r = await fetch(global.cloudFunctionURL + "getCRM", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          amount: 15, // only relevant in CRM view
          countryCurrencies,
          statusSearched,
          view: this.state.view,
          myUID: global.myUID,
          idToken: global.idToken,
        })
      });
      let r2 = await r.json();

      if (r2.msg === "SUCCESS") {
        //console.log("Received CRM records (Success)", r2.records)
        console.log("Received 15 CRM records (Success)")

        this.setState({ records: r2.records, loading: false })
      } else {
        alert("Server Error");
      }

      //if CRM view do again, now with higher amount
      if (this.state.view == "CRM") {
        r = await fetch(global.cloudFunctionURL + "getCRM", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            amount: 600,
            countryCurrencies,
            view: this.state.view,
            statusSearched,
            myUID: global.myUID,
            idToken: global.idToken,
          })
        });
        r2 = await r.json();

        if (r2.msg === "SUCCESS") {
          //console.log("Received CRM records (Success)", r2.records)
          console.log("Received 600 CRM records (Success)")

          this.setState({ records: r2.records, loadingMore: false })
        } else {
          alert("Server Error");
        }
      }
    } catch (err) {
      console.log(err);
      alert("Error fetching data")
    }
  }

  componentWillUnmount() {
  }

  renderFilters = () => {
    return (
      <View style={{ height: 50, width: "100%", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
        <TouchableOpacity onPress={() => { this.setState({ showViewMenu: true }) }}
          style={{ width: 110, borderWidth: 1, height: 25, borderRadius: 13, borderColor: "#bbbbc5", backgroundColor: "#bbbbc5", alignItems: "center", justifyContent: "center", marginRight: 10 }}>
          <Text style={{ color: "#000", fontSize: 12 }}>{this.state.view || ""}</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => { this.setState({ showCountryMenu: true }) }}
          style={{ width: 110, borderWidth: 1, height: 25, borderRadius: 13, borderColor: "#bbbbc5", alignItems: "center", justifyContent: "center", marginRight: 10 }}>
          <Text style={{ color: "#bbbbc5", fontSize: 12 }}>Country: {this.state.country || ""}</Text>
        </TouchableOpacity>
        {this.state.view == "CRM" && <TouchableOpacity onPress={() => { this.setState({ showStatusMenu: true }) }}
          style={{ width: 110, borderWidth: 1, height: 25, borderRadius: 13, borderColor: "#bbbbc5", alignItems: "center", justifyContent: "center", marginRight: 10 }}>
          <Text style={{ color: "#bbbbc5", fontSize: 12 }}>Status: {this.state.status || ""}</Text>
        </TouchableOpacity>}
      </View>
    )
  }

  render() {

    if (this.state.loading) return (<View style={{ height: "100%", backgroundColor: "#131313", alignItems: "center", justifyContent: "center" }}>
      <ActivityIndicator size="large" color="#bbc" />
    </View>)

    let records = []
    if (this.state.records) records = global.duplicate(this.state.records)
    records.length = (this.state.nOfRecordsShown || 200)
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#131313" }}>
        <View style={{ backgroundColor: "#131313", flex: 1 }}>
          <View style={{ height: Platform.OS == "ios" ? 40 : 70 }} />
          {this.renderFilters()}
          <KeyboardAvoidingView
            behavior={Platform.OS == "ios" ? "padding" : "height"}>
            <ScrollView>
              {Platform.OS == "android" && <View style={{ height: 10 }} />}
              <View>
                {records.length > 0 ? (<View>
                  {records.map((c, i) => {
                    return (
                      <Record
                        key={c.uid}
                        record={c}
                        navigation={this.props.navigation}
                      />
                    );
                  })}
                  {(this.state.loadingMore) && <View style={{ height: 60, width: "100%", alignItems: "center", justifyContent: "center" }}>
                    <ActivityIndicator size="small" color="#bbc" />
                    <Text style={{ color: "#889", fontSize: 10, marginTop: 4 }}>Loading more...</Text>
                  </View>}
                  {(this.state.records.length > records.length) && <View style={{ height: 200, width: "100%" }}>
                    <View style={{
                      height: 70, alignItems: "center", backgroundColor: "#000",
                      borderBottomEndRadius: 40, borderBottomLeftRadius: 40, justifyContent: "center",
                    }}>
                      <TouchableOpacity onPress={() => {
                        this.setState({ nOfRecordsShown: (this.state.nOfRecordsShown + 100) })
                      }}
                        style={[global.gStyles.buttonXLW, { margin: 20, width: 225, alignSelf: "center", borderColor: "#888", height: 35 }]}>
                        <Text style={global.gStyles.buttontXLW}>Show more</Text>
                      </TouchableOpacity>
                    </View>
                  </View>}
                </View>
                ) : (
                  <View style={{ height: 300, alignItems: "center", justifyContent: "center" }}>
                    <Text style={{ color: "#999" }}>All set - nothing to work on</Text>
                  </View>
                )}
              </View>
              <View style={{ height: 300 }} />
            </ScrollView>
          </KeyboardAvoidingView>
          {/* {!this.state.loading && <TouchableOpacity
            style={{ borderWidth: 1, borderColor: "rgba(0,0,0,0.2)", alignItems: "center", justifyContent: "center", width: 70, position: "absolute", bottom: 5, right: 15, height: 70, backgroundColor: "#A8CF45", borderRadius: 70, shadowOpacity: 1, shadowRadius: 3, elevation: 4 }}
            onPress={() => this.newCode()}
          >
            <Text style={{ fontSize: 36, fontWeight: "200", marginBottom: 2 }}>
              +
            </Text>
          </TouchableOpacity>} */}
        </View>
        <BottomMenu items={["All", "NG+", "GH"]}
          visible={this.state.showCountryMenu}
          onSelectItem={async (item) => { await this.setState({ country: item }); this.componentDidMount() }}
          onClose={() => { this.setState({ showCountryMenu: false }) }}
          title={"Select country"}
        />
        <BottomMenu items={["Relevant", "Snoozed", "Done"]}
          itemDescriptions={["Your Inbox. Customers you should work on", "All snoozed customers", "Done customers only"]}
          visible={this.state.showStatusMenu}
          onSelectItem={async (item) => { await this.setState({ status: item }); this.componentDidMount() }}
          onClose={() => { this.setState({ showStatusMenu: false }) }}
          title={""}
        />
        <BottomMenu items={["CRM", "Boosted", "Downboosted"]}
          visible={this.state.showViewMenu}
          onSelectItem={async (item) => { await this.setState({ view: item }); this.componentDidMount() }}
          onClose={() => { this.setState({ showViewMenu: false }) }}
          title={"Select view"}
        />
      </SafeAreaView>
    );
  }
}


import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  Linking,
  Platform,
  Dimensions,
  ActivityIndicator,
  Image,
  Keyboard,
  TextInput,
  Alert
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Audio } from 'expo-av';
import firebase from "firebase";
import "firebase/firestore";
import { HeaderBackButton } from "react-navigation-stack";
import { WebView } from "react-native-webview";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import PushNotificationHandler from "../../../Messages/PushNotificationHandler"
import { DoctorProfile } from "./HealthRecordScreen"


import HealthChat from "./HealthChat"

export default class HealthConsultScreenText extends Component {
  static navigationOptions = ({ navigation }) => {
    const navigateBack = navigation.getParam("navigateBack", () => { navigation.goBack() })
    return {
      tabBarVisible: false,
      headerTransparent: true,
      title: navigation.getParam("title", "Consultation") || "Consultation",
      headerTintColor: navigation.getParam("headerTintColor", "black") || "black",
      headerLeft: props => (
        <HeaderBackButton
          {...props}
          onPress={() => { navigateBack() }} />),
      headerTitleStyle: {
        fontWeight: "bold",
        // color: "red",
      }
    }
  };

  state = {
    screen: "LOADING", // can be "TERMS", "FORM", "CONSULTATION", "FEEDBACK"

    generalInformation: {},
    consultationInformation: {},
    myFileLoaded: false,
    hasFilledAdvancedFormBefore: false,

    age: "",
    gender: "",
    complaint: "",
    genotype: "",
    bloodGroup: "",
    hasHypertension: false,
    hasDiabetes: false,
    hasAsthma: false,
    hasOtherPreconditions: false,
    otherPreconditions: "",
    allergies: "?",
    surgeries: "?",
    medication: "?",

    patientsWaiting: [],
    doctorsOnline: [],
    activeSession: null,
    waitingLoaded: false,
    showBlogInfoPopup: true,
    joiningConsultation: false,
    playingSound: false,

    showChat: false,
    notificationCount: 0,

    keyboardHeight: 0
  };
  sWidth = Dimensions.get("window").width
  sHeight = Dimensions.get("window").height

  async componentDidMount() {
    try {
      if (!global.myHealthFileID) this.setState({ screen: "TERMS" })
      else {
        this.loadMyFile()
      }

      this.loadRooms()
      this.props.navigation.setParams({ navigateBack: this.navigateBack })
      await AsyncStorage.setItem("lastHealthType", "text");

      await global.timeout(1000)
      this.keyboardDidShowListener = Keyboard.addListener(
        "keyboardDidShow",
        this._keyboardDidShow
      );
      this.keyboardDidHideListener = Keyboard.addListener(
        "keyboardDidHide",
        this._keyboardDidHide
      );
    } catch (e) {
      global.warn(e, "cdm_HealthConsultText")
    }
  }

  componentWillUnmount() {
    console.log("HealthConsultText unmounting")
    if (this.keyboardDidShowListener) this.keyboardDidShowListener.remove();
    if (this.keyboardDidHideListener) this.keyboardDidHideListener.remove();
  }

  _keyboardDidShow = async e => {
    if (Platform.OS == "ios") this.setState({ keyboardHeight: e.endCoordinates.height - 45 });
    else this.setState({ keyboardHeight: 20 });
  };

  _keyboardDidHide = () => {
    this.setState({ keyboardHeight: 0 });
  };

  navigateBack = () => {
    if (this.state.screen == "CONSULTATION")
      return Alert.alert("End Consultation?", "",
        [{ text: "Cancel", onPress: () => { } },
        { text: "No", onPress: () => { this.props.navigation.goBack() } },
        { text: "Yes", onPress: async () => { await this.setState({ screen: "FEEDBACK" }); this.endConsultation(); } }],
        { cancelable: true }
      );
    else this.props.navigation.goBack()
  }

  loadRooms = async () => {
    try {
      await firebase
        .firestore()
        .collection("Health")
        .doc("data")
        .collection("HealthRooms")
        .doc("+NGGeneral")
        .onSnapshot(this.updateRoom)
    } catch (error) {
      global.warn(error, "HCT_922")
    }
  }

  loadMyFile = async () => {
    try {
      const fDoc = await firebase
        .firestore()
        .collection("Health")
        .doc("data")
        .collection("HealthFiles")
        .doc(global.myHealthFileID)
        .get()
      const f = fDoc.data()
      const generalInformation = f.generalInformation || {}
      this.setState(generalInformation)
      this.setState({
        generalInformation, myFileLoaded: true,
        age: generalInformation.age || "", gender: generalInformation.gender || "",
        hasFilledAdvancedFormBefore: ((generalInformation.genotype && generalInformation.genotype != "?") || generalInformation.allergies && generalInformation.allergies != "?")
      })
      this.loadMore()
    } catch (error) {
      global.warn(error, "HCT_924")
    }
  }

  updateRoom = async (roomDoc) => {
    try {
      if (!roomDoc) return
      const room = roomDoc.data()
      if (!room) return;
      let activeSession = null
      const prevActiveSession = global.duplicate(this.state.activeSession)
      const prevWaitingLoaded = this.state.waitingLoaded
      if (room.activeSessions[global.myHealthFileID]) activeSession = room.activeSessions[global.myHealthFileID]

      await this.setState({ patientsWaiting: room.patientsWaiting, doctorsOnline: room.doctorsOnline, activeSession, waitingLoaded: true })

      //console.log({ prevActiveSession, prevWaitingLoaded, activeSession, stateActiveSession: this.state.activeSession })

      if (!prevWaitingLoaded && activeSession !== null) {// patient re-joining consultation
        this.setState({ showChat: true })
      }
      if (prevWaitingLoaded && activeSession !== null && prevActiveSession === null && !activeSession.timePatientJoined) {// doctor has started session
        await global.timeout(100)
        alert("A doctor has joined.")
        await global.timeout(100)
        this.playOneTimeSound()
        await global.timeout(100)
        this.setState({ showChat: true })
        await global.timeout(13000)
        await this.joinConsultation("join")
      }
      if (activeSession == null && prevActiveSession !== null) { // doctor has ended session
        this.setState({ screen: "FEEDBACK", endedSession: prevActiveSession })
      }

      //if (this.state.screen == "WAITING" && activeSession && !activeSession.timePatientJoined && !this.state.dontPlaySoundAgain) this.playSound()
    } catch (error) {
      global.warn(error, " HCT_923")
    }
  }

  playOneTimeSound = async () => {
    if (this.state.playingSound) return;
    try {
      await this.setState({ playingSound: true })
      this.theBing = new Audio.Sound();
      await this.theBing.loadAsync(require("../../../images/sounds/ring.mp3"));
      await this.theBing.setVolumeAsync(1);

      await this.theBing.setPositionAsync(0);
      await this.theBing.playAsync();

      await global.timeout(2100)
      if (!this.state.playingSound) return;
      await this.theBing.stopAsync();
      await this.setState({ playingSound: false })
    } catch (error) {
      global.warn(error, "HCTAudio")
    }
  }

  loadMore = async () => {
    try {
      while (!this.state.waitingLoaded) await global.timeout(300)
      this.props.navigation.setParams({ headerTintColor: "black" })
      const myWaiting = this.state.patientsWaiting.filter(patient => { return (patient.healthFileID == global.myHealthFileID) })
      if (myWaiting.length > 0 || (this.state.activeSession && this.state.activeSession.recordID)) {
        this.setState({ screen: "CONSULTATION" }) // already ongoing chat //
        // if (this.state.activeSession && !this.state.activeSession.timePatientJoined) alert("A doctor has joined")
      }
      else this.setState({ screen: "FORM" })
    } catch (error) {
      global.warn(error, " HCT_loadMore")
    }
  }

  renderConsultation() {
    const nOfDoctorsOnline = Array.isArray(this.state.doctorsOnline) ? this.state.doctorsOnline.length : 0
    const nOfPatientsBeforeYou = Array.isArray(this.state.patientsWaiting) ? this.state.patientsWaiting.length - 1 : "??"
    let patientString = "Please wait, the doctor will start the consultation any minute"
    if (nOfPatientsBeforeYou > 0) patientString = "Please wait until there is no more patients ahead of you"
    if (nOfPatientsBeforeYou > (nOfDoctorsOnline * 3)) patientString = "This may take a little longer... wait now or try at a different time"

    let recordID = this.state.activeSession?.recordID
    if (!recordID) { //text chat only - edit record already when Doctor has not joined - CHECK!!
      const myWaiting = this.state.patientsWaiting.filter(patient => { return (patient.healthFileID == global.myHealthFileID) })
      if (myWaiting.length > 0) recordID = myWaiting[0].recordID
    }
    const noDoctorYet = (this.state.activeSession?.recordID) ? false : true

    if (!this.state.waitingLoaded) return (<View style={{ flexDirection: "row", height: "100%", width: '100%', justifyContent: "center", alignItems: "center", }}>
      <ActivityIndicator size="small" color="#444" style={{ marginRight: 8 }} />
      <Text style={{ color: "#444" }}>Loading Waiting Room...</Text>
    </View>)

    return (<View style={{ backgroundColor: "#fff", height: "100%", width: "100%" }}>

      {/* Text Chat specific Background */}
      <TouchableOpacity onPress={() => { this.setState({ showChat: false }) }} style={{ flex: 1 }} activeOpacity={1}>
        <View style={{ backgroundColor: this.state.bgToggled ? "#faa" : "#fff", height: "100%", width: "100%" }}>
          <View
            style={{ borderColor: "#312", borderRadius: 0, borderWidth: 0, margin: 0, position: "absolute", top: 0, left: 0, width: this.sWidth, height: 180 }}
          >
            <Image style={{ width: "100%", alignSelf: "center", height: 150, justifyContent: "flex-end", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
              source={require("../../../images/images/DD.jpeg")} />
            <View style={{ marginTop: -50, height: 80, backgroundColor: "#69c", paddingLeft: 5 }} >
              {!this.state.activeSession ? <View>
                <View style={{ flexDirection: "row", justifyContent: "space-between", paddingHorizontal: 5 }}>
                  <View style={{ padding: 5, paddingBottom: 0, flexDirection: "row", alignItems: "center" }} >
                    <Text style={{ fontSize: 32, fontWeight: "bold", color: nOfPatientsBeforeYou > 3 ? "#faa" : "#afa" }}>{nOfPatientsBeforeYou}</Text>
                    <Text style={{ fontSize: 16, marginLeft: 5, color: "white", fontWeight: "bold" }}>patients ahead of you</Text>
                  </View>
                  <View style={{ padding: 5, paddingBottom: 0, flexDirection: "row", alignItems: "center" }} >
                    <Text style={{ fontSize: 32, color: "#afa" }}>{nOfDoctorsOnline > 0 && "✓"}</Text>
                    <Text style={{ fontSize: 16, color: "white", fontWeight: "bold" }}>{nOfDoctorsOnline} Doctor{nOfDoctorsOnline == 1 ? "" : "s"} Online</Text>
                  </View>
                </View>
                <Text style={{ fontSize: 12, color: "white", marginLeft: 10 }}>{patientString}</Text>
              </View>
                : <View style={{ alignSelf: "center", height: 80, justifyContent: "center" }}>
                  <Text style={{ color: "white", fontSize: Platform.OS == "ios" ? 24 : 20, fontWeight: "100" }}>Consultation Active</Text>
                </View>}
            </View>
          </View>
          <View style={{ marginTop: 180 }}>

            <PushNotificationHandler />


            {!this.state.advancedFormSubmitted && <KeyboardAwareScrollView
              enableOnAndroid={true}
              extraHeight={100}
              extraScrollHeight={100}
              //enableAutomaticScroll={true}
              style={{ height: "100%" }}
              showsVerticalScrollIndicator={false}
            >
              {this.renderAdvancedForm()}
              <View style={{ height: 100 }} />
            </KeyboardAwareScrollView>}

            {!!this.state.activeSession?.doctorProfileID ? <View>
              <Text style={{ color: "red", fontSize: 14, marginBottom: 5, marginTop: 19, fontWeight: "bold" }}>Doctor</Text>
              <DoctorProfile healthProfileID={this.state.activeSession.doctorProfileID} navigation={this.props.navigation} hideNoDoctorMessage />
            </View>
              : <View style={{ height: 50, justifyContent: "center" }}>
                <Text style={{ color: "gray", fontSize: 12, marginBottom: 0, marginTop: 5 }}>No doctor attended</Text>
              </View>}

            {this.state.activeSession && this.state.activeSession.timePatientJoined && <View>
              <View style={{ height: 200, marginTop: 20, justifyContent: "center", padding: 5, alignItems: "center" }} >
                <TouchableOpacity
                  style={{
                    height: 50, borderRadius: 25, alignItems: "center", justifyContent: "center",
                    paddingHorizontal: 30, borderWidth: 1, borderColor: "red", marginTop: 35
                  }}
                  onPress={() => { this.endConsultation() }}
                >
                  <Text style={{ color: "red", fontWeight: "bold", fontSize: 20 }}>End Consultation</Text>
                </TouchableOpacity>
              </View>
            </View>}
          </View>
        </View>

        {(!!recordID) && <HealthChat recordID={recordID}
          setNotifications={(n) => { this.setState({ notificationCount: n }) }}
          visible={this.state.showChat}
          noDoctorYet={noDoctorYet}
          addedStyle={{
            backgroundColor: "#fee", borderRadius: 10, borderColor: "#800", borderWidth: 1, shadowOpacity: 1
          }}
        />}
        <View style={{ position: "absolute", bottom: this.state.keyboardHeight || 0, left: 0, width: 52, height: 65, alignItems: "center", flexDirection: "row" }}>
          <TouchableOpacity onPress={() => {
            if (this.state.showChat) this.setState({ showChat: false, keyboardHeight: 0 });
            else this.setState({ showChat: true, notificationCount: 0 });
          }}>
            <View style={{ height: 50, width: 50, borderRadius: 25, backgroundColor: "white", justifyContent: "center", alignItems: "center", borderWidth: this.state.showChat ? .5 : 5, borderColor: "#000" }}>
              <Image
                style={{ width: 25, height: 25, tintColor: "red" }}
                source={require("../../../images/tabNavigator/Messages.png")}
              />
              {this.state.notificationCount >= 1 && (
                <View
                  style={{
                    backgroundColor: "red", height: 21.875, width: 27.5, borderRadius: 11, alignSelf: "flex-end", alignItems: "center", marginTop: -36, marginRight: -8, marginLeft: 10, marginBottom: 10, borderWidth: 0.5, borderColor: "#fff", justifyContent: "center"
                  }}
                >
                  <Text style={{ color: "white", fontWeight: "bold", fontSize: 14 }}>
                    {this.state.notificationCount}
                  </Text>
                </View>
              )}
            </View>
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    </View>)
  }

  renderAdvancedForm = () => {
    let infoString = "While you are waiting for the doctor to join you, please tell more about yourself."
    if (this.state.hasFilledAdvancedFormBefore) infoString = "While you are waiting, please check below information and update where needed"
    let buttonString = "Submit"
    if (this.state.hasFilledAdvancedFormBefore) buttonString = "OK. Submit"

    if (!this.state.myFileLoaded) return (<View style={{ flexDirection: "row", height: "100%", width: '100%', justifyContent: "center", alignItems: "center", }}>
      <ActivityIndicator size="small" color="#444" style={{ marginRight: 8 }} />
      <Text style={{ color: "#444" }}>Loading Health Information...</Text>
    </View>)
    return (<View style={{ margin: 10 }}>
      <Text style={{ color: "#000", fontSize: 22, fontWeight: "bold", marginVertical: 10 }}>Medical Details</Text>
      <Text style={{ color: "#000", fontSize: 14, fontWeight: "bold" }}>{infoString}</Text>

      <AdvancedFormContent t={this} />

      <TouchableOpacity onPress={() => {
        //this.setState({ screen: "WAITING" })
        this.submitAdvancedForm()
      }}>
        <View style={[global.gStyles.buttonXL, {
          backgroundColor: "red", paddingVertical: 2.5, paddingHorizontal: 22, marginTop: 40,
          flexDirection: "row", width: "87%", alignSelf: "center", alignItems: "center"
        }]} >
          <Text style={{ fontSize: 16, fontWeight: "bold", color: "#fff" }}>{buttonString}</Text>
        </View>
      </TouchableOpacity>
      <View style={{ height: 150 }} /></View>)
  }

  openTC = () => {
    Linking.openURL("https://www.african-founders.com/wakanda-doctordial-tc")
  }

  createFile = async () => {
    try {
      this.setState({ screen: "LOADING" })
      const r = await fetch(global.cloudFunctionURL + "createHealthFile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        this.setState({ myFileLoaded: true })
        this.loadMore()
        return;
      }

      alert("Currently not possible. Try again later")
      this.props.navigation.goBack()
    } catch (e) {
      alert("System error. Check your network connection")
      global.warn(e, 'HCTcreateFile');
    }
  }

  joinConsultation = async (action) => {
    try {
      if (this.state.joiningConsultation) return
      this.setState({ joiningConsultation: true })
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "joinConsultation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          healthRoomID: "+NGGeneral",
          action,
          isTextChat: true,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ joiningConsultation: false })
      if (r2.msg == "SUCCESS") {
        if (action == "leaveQueue") {
          return this.props.navigation.goBack()
        }
        if (action == "join") {
          // const recordDoc = await firebase
          //   .firestore()
          //   .collection("Health")
          //   .doc("data")
          //   .collection("HealthRecords")
          //   .doc(this.state.activeSession.recordID)
          //   .get();
          // const record = recordDoc.data()

          // this.setState({ screen: "CONSULTATION" })
          // this.props.navigation.setParams({ headerTintColor: "white" })
        }
        return;
      }

      alert("Server error - try again.")
    } catch (e) {
      alert("System error. Check your network connection and try again")
      global.warn(e, 'HCTerrorx8');
    }
  }

  endConsultation = async () => {
    try {
      if (this.state.screen !== "FEEDBACK") this.setState({ screen: "LOADING", endedSession: this.state.activeSession })
      const r = await fetch(global.cloudFunctionURL + "joinConsultation", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          healthRoomID: "+NGGeneral",
          action: "leaveConsultation",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        alert("Consultation ended. You will find the record in your personal health file")
        await global.timeout(300)
        this.setState({ screen: "FEEDBACK" })
        return;
      } else {
        this.props.navigation.goBack()
      }

      alert("Server error - try again.")
    } catch (e) {
      alert("System error. Check your network connection.")
      global.warn(e, 'HCT_errorx8');
    }
  }

  submitAdvancedForm = async () => {
    try {
      this.setState({ screen: "LOADING" })
      const { age, gender, bloodGroup, genotype, hasHypertension, hasDiabetes, hasAsthma, hasOtherPreconditions, otherPreconditions, allergies, surgeries, medication } = this.state
      const generalInformation = { age, gender, bloodGroup, genotype, hasHypertension, hasDiabetes, hasAsthma, hasOtherPreconditions, otherPreconditions: hasOtherPreconditions ? otherPreconditions : "", allergies, surgeries, medication }
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "updateHealthFile", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          requestBy: "patient",
          generalInformation,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        this.setState({ advancedFormSubmitted: true })
      } else alert("A system error occurred - Try again.")
      if (this.state.screen == "LOADING") this.setState({ screen: "CONSULTATION" })
    } catch (e) {
      if (this.state.screen == "LOADING") this.setState({ screen: "CONSULTATION" })
      alert("System error - Check network connection and try again")
      global.warn(e, 'HCTsubmitAdvancedForm-errorx');
    }
  }

  submitFeedbackForm = async () => {
    if (!this.state.feedbackDoc && !this.state.feedbackService && !this.state.feedbackText) return alert("You have not entered any information")

    try {
      this.setState({ screen: "LOADING" })
      const r = await fetch(global.cloudFunctionURL + "giveConsultationFeedback", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          data: {
            feedbackDoc: this.state.feedbackDoc,
            feedbackService: this.state.feedbackService,
            feedbackText: this.state.feedbackText
          },
          session: this.state.endedSession || { error: "No Ended Session Found" },
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        this.props.navigation.goBack()
        await global.timeout(200)
        alert("Thanks for your feedback!")

        return;
      }

      alert("A system error occurred. Try again.")
      this.setState({ screen: "FORM" })
    } catch (e) {
      this.setState({ screen: "FORM" })
      alert("System error. Check network connection and try again")
      global.warn(e, 'HCTsubmitFormerrorx');
    }
  }

  submitForm = async () => {
    if (!this.state.age) return alert("Enter your age")
    if (!this.state.gender) return alert("Specify your gender")

    let generalInformationHasUpdated = false
    if (this.state.generalInformation.age !== this.state.age) generalInformationHasUpdated = true
    if (this.state.generalInformation.gender !== this.state.gender) generalInformationHasUpdated = true

    try {
      this.setState({ screen: "LOADING" })
      const r = await fetch(global.cloudFunctionURL + "joinWaitingRoom", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          healthRoomID: "+NGGeneral",
          consultationInformation: { complaint: this.state.complaint },
          ...(generalInformationHasUpdated) && { generalInformation: { age: this.state.age, gender: this.state.gender } },
          isTextChat: true,
          //isIOS: true,
          //isIOS: (Platform.OS == "ios"), // set to true if a non-webview option for android is offered later (in case of issues). In that case, reactivate all lines with this.showInWebview
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        this.setState({ screen: "CONSULTATION" })
        this.props.navigation.setParams({ headerTintColor: "black" })
        return;
      }

      alert("A system error occurred. Try again.")
      this.setState({ screen: "FORM" })
    } catch (e) {
      this.setState({ screen: "FORM" })
      alert("System error. Check network connection and try again")
      global.warn(e, 'HCTsubmitForm');
    }
  }


  renderForm() {
    if (!this.state.myFileLoaded) return (<View style={{ flexDirection: "row", height: "100%", width: '100%', justifyContent: "center", alignItems: "center", }}>
      <ActivityIndicator size="small" color="#444" style={{ marginRight: 8 }} />
      <Text style={{ color: "#444" }}>Loading Health Information...</Text>
    </View>)
    return (<View style={{ backgroundColor: "#fff", height: "100%", width: "100%" }}>
      <Image style={{ width: "100%", alignSelf: "center", height: 150, justifyContent: "flex-end", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
        source={require("../../../images/images/DD.jpeg")} />
      <View style={{ borderColor: "#999", borderWidth: 0, margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20, flex: 1 }}>
        <KeyboardAwareScrollView
          enableOnAndroid={true}
          extraHeight={100}
          //enableAutomaticScroll={true}
          showsVerticalScrollIndicator={false}
          style={{ flex: 1 }}
        >
          <Text style={{ color: "#000", fontSize: 22, fontWeight: "bold", marginVertical: 10 }}>Your Details</Text>
          <Text style={{ color: "#000", fontSize: 14, fontWeight: "bold" }}>Tell us basic information about your health so the doctor is prepared...</Text>
          <View style={{ width: "100%", alignItems: "stretch" }}>
            <Text style={{ color: "red", fontSize: 14, marginBottom: 5, marginTop: 30, fontWeight: "bold" }}>Your Complaint</Text>
            <MyTextInput
              onChangeText={complaint => { this.setState({ complaint }); }}
              value={this.state.complaint}
              minHeight={35}
            />
            <Text style={{ color: "gray", fontSize: 12, marginBottom: 5, fontStyle: "italic" }}>In case of emergency, visit a hospital</Text>
            <Text style={{ color: "red", fontSize: 14, marginBottom: 5, marginTop: 45, fontWeight: "bold" }}>Age</Text>
            <MyTextInput
              onChangeText={age => { this.setState({ age }); }}
              value={this.state.age} keyboardType="numeric"
            />
            <Text style={{ color: "red", fontSize: 14, marginBottom: 5, marginTop: 45, fontWeight: "bold" }}>Gender</Text>
            {(!this.state.gender || this.state.gender == "male") && <TouchableOpacity onPress={() => { this.setState({ gender: !this.state.gender ? "male" : "" }) }}>
              <CheckboxSelector selected={this.state.gender == "male"} text={"male"} />
            </TouchableOpacity>}
            {(!this.state.gender || this.state.gender == "female") && <TouchableOpacity onPress={() => { this.setState({ gender: !this.state.gender ? "female" : "" }) }}>
              <CheckboxSelector selected={this.state.gender == "female"} text={"female"} />
            </TouchableOpacity>}
          </View>
          <TouchableOpacity onPress={() => {
            this.submitForm()
          }}>
            <View style={[global.gStyles.buttonXL, {
              backgroundColor: "red", paddingVertical: 2.5, paddingHorizontal: 22, marginTop: 40,
              flexDirection: "row", width: "87%", alignSelf: "center", alignItems: "center"
            }]} >
              <Text style={{ fontSize: 16, fontWeight: "bold", color: "#fff" }}>{"Submit"}</Text>
            </View>
          </TouchableOpacity>
          <View style={{ height: 100 }} />
        </KeyboardAwareScrollView>
      </View>
    </View >)
  }

  renderFeedback() {
    return (<View style={{ backgroundColor: "#fff", width: "100%", flex: 1 }}>
      <Image style={{ width: "100%", alignSelf: "center", height: 150, justifyContent: "flex-end", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
        source={require("../../../images/images/DD.jpeg")} />
      <View style={{ borderColor: "#999", borderWidth: 0, marginHorizontal: 30, borderRadius: 16, flex: 1 }}>
        <KeyboardAwareScrollView
          enableOnAndroid={true}
          resetScrollToCoords={{ x: 0, y: 0 }}
          extraScrollHeight={200}
          //enableAutomaticScroll={true}
          showsVerticalScrollIndicator={false}
          style={{ flex: 1 }}
        >
          <Text style={{ color: "#000", fontSize: 22, fontWeight: "bold", marginBottom: 10, marginTop: 30 }}>Your Feedback</Text>
          <Text style={{ color: "#000", fontSize: 14, fontWeight: "bold" }}>Help us improve this service</Text>
          <View style={{ width: "100%", alignItems: "stretch" }}>
            <Text style={{ color: "red", fontSize: 16, marginTop: 30, fontWeight: "bold" }}>How was the consultation?</Text>
            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity onPress={() => { this.setState({ feedbackDoc: this.state.feedbackDoc == "😡" ? "" : "😡" }) }}>
                <SmileySelector selected={this.state.feedbackDoc == "😡"} text={"😡"} />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => { this.setState({ feedbackDoc: this.state.feedbackDoc == "😠" ? "" : "😠" }) }}>
                <SmileySelector selected={this.state.feedbackDoc == "😠"} text={"😠"} />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => { this.setState({ feedbackDoc: this.state.feedbackDoc == "😐" ? "" : "😐" }) }}>
                <SmileySelector selected={this.state.feedbackDoc == "😐"} text={"😐"} />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => { this.setState({ feedbackDoc: this.state.feedbackDoc == "🙂" ? "" : "🙂" }) }}>
                <SmileySelector selected={this.state.feedbackDoc == "🙂"} text={"🙂"} />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => { this.setState({ feedbackDoc: this.state.feedbackDoc == "😍" ? "" : "😍" }) }}>
                <SmileySelector selected={this.state.feedbackDoc == "😍"} text={"😍"} />
              </TouchableOpacity>
            </View>
            <Text style={{ color: "red", fontSize: 16, marginTop: 45, fontWeight: "bold" }}>How do you like this service?</Text>
            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity onPress={() => { this.setState({ feedbackService: this.state.feedbackService == "😡" ? "" : "😡" }) }}>
                <SmileySelector selected={this.state.feedbackService == "😡"} text={"😡"} />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => { this.setState({ feedbackService: this.state.feedbackService == "😠" ? "" : "😠" }) }}>
                <SmileySelector selected={this.state.feedbackService == "😠"} text={"😠"} />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => { this.setState({ feedbackService: this.state.feedbackService == "😐" ? "" : "😐" }) }}>
                <SmileySelector selected={this.state.feedbackService == "😐"} text={"😐"} />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => { this.setState({ feedbackService: this.state.feedbackService == "🙂" ? "" : "🙂" }) }}>
                <SmileySelector selected={this.state.feedbackService == "🙂"} text={"🙂"} />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => { this.setState({ feedbackService: this.state.feedbackService == "😍" ? "" : "😍" }) }}>
                <SmileySelector selected={this.state.feedbackService == "😍"} text={"😍"} />
              </TouchableOpacity>
            </View>
            <Text style={{ color: "red", fontSize: 16, marginTop: 45, fontWeight: "bold", marginBottom: 5 }}>Comments</Text>
            <MyTextInput
              onChangeText={feedbackText => { this.setState({ feedbackText }); }}
              value={this.state.feedbackText}
            />
          </View>
          <TouchableOpacity onPress={() => {
            this.submitFeedbackForm()
          }}>
            <View style={[global.gStyles.buttonXL, {
              backgroundColor: "red", paddingVertical: 2.5, paddingHorizontal: 22, marginTop: 40,
              flexDirection: "row", width: "87%", alignSelf: "center", alignItems: "center"
            }]} >
              <Text style={{ fontSize: 16, fontWeight: "bold", color: "#fff" }}>{"Submit"}</Text>
            </View>
          </TouchableOpacity>
          <View style={{ height: 50 }} />
        </KeyboardAwareScrollView>
      </View>
    </View >)
  }

  renderTerms() {
    let TC = props => <Text onPress={this.openTC} style={{ color: "#900" }}>{props.children}</Text>
    return (<View style={{ backgroundColor: "#fff", height: "100%", width: "100%", paddingTop: 110 }}>
      <View style={{ borderColor: "#999", borderWidth: 0, margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20, overflow: "hidden" }}>
        <Text style={{ color: "#000", fontSize: 18, fontWeight: "bold" }}>How It Works</Text>
        <NumberedPoint n={"1."} style={{ color: "#000", fontSize: 16, marginTop: 20 }}>
          Tell us basic information so the doctor is prepared</NumberedPoint>
        <NumberedPoint n={"2."} style={{ color: "#000", fontSize: 16, marginTop: 20 }}>
          Consult a doctor via text chat</NumberedPoint>
        <NumberedPoint n={"3."} style={{ color: "#000", fontSize: 16, marginTop: 20 }}>
          Your consultation notes will be stored in your personal health file for your and the doctor's reference</NumberedPoint>
        <NumberedPoint n={"4."} style={{ color: "#000", fontSize: 16, marginTop: 20 }}>
          You can follow up with the doctor for 8 days via text chat</NumberedPoint>
        <Text style={{ color: "#000", fontSize: 12, marginTop: 20 }}>By continuing, you agree to the <TC>Terms & Conditions</TC>. All consultations are between you and the doctor; Wakanda only verifies that the doctors are licensed medical practitioners.</Text>
      </View>
      <TouchableOpacity onPress={() => { this.createFile() }}>
        <View style={[global.gStyles.buttonXL, {
          backgroundColor: "red", paddingVertical: 2.5, paddingHorizontal: 22, margin: 5,
          flexDirection: "row", width: "87%", alignSelf: "center", alignItems: "center"
        }]} >
          <Text style={{ fontSize: 16, fontWeight: "bold", color: "#fff" }}>{"Agree & Continue"}</Text>
        </View>
      </TouchableOpacity>
    </View >)
  }

  renderLoading() {
    return (<View style={{ backgroundColor: "#fff", height: "100%", width: "100%", alignItems: "center", justifyContent: "center" }}>
      <Image style={{ borderRadius: 17, height: 100, width: 200, marginBottom: -65 }}
        source={require("../../../images/images/DD.jpeg")} />
      <ActivityIndicator size="large" color="white" animating={true} />
      <Text style={{ color: "#888", fontSize: 18, fontStyle: "italic", marginTop: 50 }}>Just a second...</Text>
    </View>)
  }

  render() {
    if (this.state.screen == "CONSULTATION") return this.renderConsultation()
    if (this.state.screen == "TERMS") return this.renderTerms()
    if (this.state.screen == "FORM") return this.renderForm()
    if (this.state.screen == "FEEDBACK") return this.renderFeedback()
    if (this.state.screen == "LOADING") return this.renderLoading()
    return (<View />)
  }
}

export class AdvancedFormContent extends Component {
  render() {
    const t = this.props.t
    return (<View style={{ width: "100%", alignItems: "stretch" }}>
      {this.props.view == "file" && <View>
        <Text style={{ color: "red", fontSize: 14, marginBottom: 5, marginTop: 45, fontWeight: "bold" }}>Age</Text>
        <MyTextInput
          onChangeText={age => { t.setState({ age }); }}
          value={t.state.age} keyboardType="numeric"
        />
        <Text style={{ color: "red", fontSize: 14, marginBottom: 5, marginTop: 45, fontWeight: "bold" }}>Gender</Text>
        {(!t.state.gender || t.state.gender == "male") && <TouchableOpacity onPress={() => { t.setState({ gender: !t.state.gender ? "male" : "" }) }}>
          <CheckboxSelector selected={t.state.gender == "male"} text={"male"} />
        </TouchableOpacity>}
        {(!t.state.gender || t.state.gender == "female") && <TouchableOpacity onPress={() => { t.setState({ gender: !t.state.gender ? "female" : "" }) }}>
          <CheckboxSelector selected={t.state.gender == "female"} text={"female"} />
        </TouchableOpacity>}
      </View>}

      <Text style={{ color: "red", fontSize: 14, marginBottom: 5, marginTop: 30, fontWeight: "bold" }}>Your Genotype</Text>
      {
        (!t.state.genotype || t.state.genotype == "AA") && <TouchableOpacity onPress={() => { t.setState({ genotype: !t.state.genotype ? "AA" : "" }) }}>
          <CheckboxSelector selected={t.state.genotype == "AA"} text={"AA"} />
        </TouchableOpacity>
      }
      {
        (!t.state.genotype || t.state.genotype == "AS") && <TouchableOpacity onPress={() => { t.setState({ genotype: !t.state.genotype ? "AS" : "" }) }}>
          <CheckboxSelector selected={t.state.genotype == "AS"} text={"AS"} />
        </TouchableOpacity>
      }
      {
        (!t.state.genotype || t.state.genotype == "SS") && <TouchableOpacity onPress={() => { t.setState({ genotype: !t.state.genotype ? "SS" : "" }) }}>
          <CheckboxSelector selected={t.state.genotype == "SS"} text={"SS"} />
        </TouchableOpacity>
      }
      {
        (!t.state.genotype || t.state.genotype == "SC") && <TouchableOpacity onPress={() => { t.setState({ genotype: !t.state.genotype ? "SC" : "" }) }}>
          <CheckboxSelector selected={t.state.genotype == "SC"} text={"SC"} />
        </TouchableOpacity>
      }
      {
        (!t.state.genotype || t.state.genotype == "AC") && <TouchableOpacity onPress={() => { t.setState({ genotype: !t.state.genotype ? "AC" : "" }) }}>
          <CheckboxSelector selected={t.state.genotype == "AC"} text={"AC"} />
        </TouchableOpacity>
      }
      {
        (!t.state.genotype || t.state.genotype == "I'm not sure") && <TouchableOpacity onPress={() => { t.setState({ genotype: !t.state.genotype ? "I'm not sure" : "" }) }}>
          <CheckboxSelector selected={t.state.genotype == "I'm not sure"} text={"I'm not sure"} />
        </TouchableOpacity>
      }
      <Text style={{ color: "red", fontSize: 14, marginBottom: 5, marginTop: 45, fontWeight: "bold" }}>Your Blood Group</Text>
      {
        (!t.state.bloodGroup || t.state.bloodGroup == "A") && <TouchableOpacity onPress={() => { t.setState({ bloodGroup: !t.state.bloodGroup ? "A" : "" }) }}>
          <CheckboxSelector selected={t.state.bloodGroup == "A"} text={"A"} />
        </TouchableOpacity>
      }
      {
        (!t.state.bloodGroup || t.state.bloodGroup == "B") && <TouchableOpacity onPress={() => { t.setState({ bloodGroup: !t.state.bloodGroup ? "B" : "" }) }}>
          <CheckboxSelector selected={t.state.bloodGroup == "B"} text={"B"} />
        </TouchableOpacity>
      }
      {
        (!t.state.bloodGroup || t.state.bloodGroup == "AB") && <TouchableOpacity onPress={() => { t.setState({ bloodGroup: !t.state.bloodGroup ? "AB" : "" }) }}>
          <CheckboxSelector selected={t.state.bloodGroup == "AB"} text={"AB"} />
        </TouchableOpacity>
      }
      {
        (!t.state.bloodGroup || t.state.bloodGroup == "O") && <TouchableOpacity onPress={() => { t.setState({ bloodGroup: !t.state.bloodGroup ? "O" : "" }) }}>
          <CheckboxSelector selected={t.state.bloodGroup == "O"} text={"O"} />
        </TouchableOpacity>
      }
      {
        (!t.state.bloodGroup || t.state.bloodGroup == "I'm not sure.") && <TouchableOpacity onPress={() => { t.setState({ bloodGroup: !t.state.bloodGroup ? "I'm not sure." : "" }) }}>
          <CheckboxSelector selected={t.state.bloodGroup == "I'm not sure."} text={"I'm not sure."} />
        </TouchableOpacity>
      }
      <Text style={{ color: "red", fontSize: 14, marginBottom: 5, marginTop: 45, fontWeight: "bold" }}>Have you been diagnosed of the following before?</Text>
      <TouchableOpacity onPress={() => { t.setState({ hasHypertension: !t.state.hasHypertension }) }}>
        <CheckboxSelector selected={t.state.hasHypertension} text={"Hypertension"} />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => { t.setState({ hasDiabetes: !t.state.hasDiabetes }) }}>
        <CheckboxSelector selected={t.state.hasDiabetes} text={"Diabetes"} />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => { t.setState({ hasAsthma: !t.state.hasAsthma }) }}>
        <CheckboxSelector selected={t.state.hasAsthma} text={"Asthma"} />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => { t.setState({ hasOtherPreconditions: !t.state.hasOtherPreconditions }) }}>
        <CheckboxSelector selected={t.state.hasOtherPreconditions} text={"Others"} />
      </TouchableOpacity>
      {t.state.hasOtherPreconditions && <View style={{ marginLeft: 32 }}>
        <MyTextInput
          onChangeText={otherPreconditions => { t.setState({ otherPreconditions }); }}
          value={t.state.otherPreconditions} placeholer={"Please specify..."} />
      </View>}
      <Text style={{ color: "red", fontSize: 14, marginBottom: 5, marginTop: 45, fontWeight: "bold" }}>{(t.state.allergies == "?" || t.state.allergies == "None.") ? "Do you have allergies?" : "Your Allergies"}</Text>
      {
        t.state.allergies == "?" && <TouchableOpacity onPress={() => { t.setState({ allergies: "" }) }}>
          <CheckboxSelector selected={t.state.allergies == "Yes"} text={"Yes"} />
        </TouchableOpacity>
      }
      {
        (t.state.allergies == "?" || t.state.allergies == "None.") && <TouchableOpacity onPress={() => { t.setState({ allergies: t.state.allergies == "None." ? "?" : "None." }) }}>
          <CheckboxSelector selected={t.state.allergies == "None."} text={"No"} />
        </TouchableOpacity>
      }
      {
        (t.state.allergies != "?" && t.state.allergies != "None.") && <MyTextInput
          onChangeText={allergies => { t.setState({ allergies }); }}
          value={t.state.allergies} placeholer={"Please specify..."}
        />
      }
      <Text style={{ color: "red", fontSize: 14, marginBottom: 5, marginTop: 45, fontWeight: "bold" }}>{(t.state.surgeries == "?" || t.state.surgeries == "None.") ? "Have you had any surgeries before?" : "Your Surgeries"}</Text>
      {
        t.state.surgeries == "?" && <TouchableOpacity onPress={() => { t.setState({ surgeries: "" }) }}>
          <CheckboxSelector selected={t.state.surgeries == "Yes"} text={"Yes"} />
        </TouchableOpacity>
      }
      {
        (t.state.surgeries == "?" || t.state.surgeries == "None.") && <TouchableOpacity onPress={() => { t.setState({ surgeries: t.state.surgeries == "None." ? "?" : "None." }) }}>
          <CheckboxSelector selected={t.state.surgeries == "None."} text={"No"} />
        </TouchableOpacity>
      }
      {
        (t.state.surgeries != "?" && t.state.surgeries != "None.") && <MyTextInput
          onChangeText={surgeries => { t.setState({ surgeries }); }}
          value={t.state.surgeries} placeholer={"Please specify..."}
        />
      }
      <Text style={{ color: "red", fontSize: 14, marginBottom: 5, marginTop: 45, fontWeight: "bold" }}>{(t.state.medication == "?" || t.state.medication == "None.") ? "Are you currently on any Medication?" : "Your Medication"}</Text>
      {
        t.state.medication == "?" && <TouchableOpacity onPress={() => { t.setState({ medication: "" }) }}>
          <CheckboxSelector selected={t.state.medication == "Yes"} text={"Yes"} />
        </TouchableOpacity>
      }
      {
        (t.state.medication == "?" || t.state.medication == "None.") && <TouchableOpacity onPress={() => { t.setState({ medication: t.state.medication == "None." ? "?" : "None." }) }}>
          <CheckboxSelector selected={t.state.medication == "None."} text={"No"} />
        </TouchableOpacity>
      }
      {
        (t.state.medication != "?" && t.state.medication != "None.") && <MyTextInput
          onChangeText={medication => { t.setState({ medication }); }}
          value={t.state.medication} placeholer={"Please specify..."}
        />
      }
    </View >)
  }
}

class NumberedPoint extends PureComponent {
  render() {
    return (
      <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
        <Text style={[this.props.style, { marginRight: 10 }]}>{this.props.n}</Text>
        <Text style={[this.props.style, { marginRight: 15 }]}>{this.props.children}</Text>
      </View>
    );
  }
}

class MyTextInput extends PureComponent {
  render() {
    return (
      <TextInput
        onChangeText={this.props.onChangeText}
        value={this.props.value}
        placeholder={this.props.placeholer || ""}
        placeholderTextColor={"#999"}
        style={{ fontSize: 16, borderColor: "#aaa", borderRadius: 15, borderBottomWidth: 0.6, borderRadius: 0.3, backgroundColor: "white", minHeight: this.props.minHeight || 30, paddingVertical: 5, justifyContent: "center" }}
        multiline
        maxLength={20000}
        underlineColorAndroid="transparent"
        keyboardShouldPersistTaps={"always"}
        keyboardDismissMode={"on-drag"}
        keyboardType={this.props.keyboardType}
        autoCompleteType={"off"}
        autoCapitalize={'none'}
        autoCorrect={false}
      //clearButtonMode={"always"}
      />
    );
  }
}

class CheckboxSelector extends PureComponent {
  render() {
    let height =
      this.props.text.length < 45
        ? 30
        : 45 + (this.props.text.length - 45) * 0.33;
    let circle = filled => {
      if (filled) {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#000", backgroundColor: "#fff", borderWidth: 0, borderRadius: 10 }}>
            <Text style={{ fontSize: 24, marginTop: -5, color: this.props.color || "#000" }}>✓</Text>
          </View>
        );
      } else {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#000", borderWidth: 0.7, borderRadius: 10 }} />
        );
      }
    };

    return (
      <View style={{ height: height, flexDirection: "row", alignItems: "center" }}>
        {circle(this.props.selected)}
        <Text style={{ marginLeft: 10, fontSize: 14, color: this.props.color || "#000", fontWeight: this.props.selected ? "bold" : "normal" }}>{this.props.text}</Text>
      </View>
    );
  }
}

class SmileySelector extends PureComponent {
  render() {
    const height = 60;
    return (
      <View style={{ height: height, justifyContent: "center" }}>
        <Text style={{ marginHorizontal: 10, fontSize: this.props.selected ? 48 : 24 }}>{this.props.text}</Text>
      </View>
    );
  }
}

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  TouchableHighlight,
  TouchableWithoutFeedback,
  ScrollView,
  SectionList,
  SafeAreaView,
  Keyboard,
  ActivityIndicator,
  Dimensions,
  Platform,
  Image,
  Alert,
  Modal
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";

export default class DashboardScreen extends Component {
  static navigationOptions = {
    headerTransparent: false,
    title: "Dashboard",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45"
    },
    headerStyle: {
      backgroundColor: "#131313"
      //height: 15,
    }
  };

  state = {
    data: { Loading: "Please wait..." },
  };

  async componentDidMount() {
    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "getNetworkStats", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          networkID: this.props.navigation.getParam('networkID'),
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();

      if (r2.msg === "SUCCESS") {
        this.setState({ data: r2.data })
      } else {
        this.setState({ data: { Error: "Backend Error." } })
      }
    } catch (err) {
      this.setState(data({ Error: "Ensure internet connection and try reloading this screen" }))
      console.log(err);
    }
  }

  componentWillUnmount() {
    //this.unsubscribeFirestore();
  }



  render() {

    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#241924"
        }}
      >
        <ScrollView
          style={{
            flex: 1,
            width: "100%",
            paddingHorizontal: 5,
            marginTop: 1
          }}
        >
          <Text selectable={true} style={{ color: "#ccc" }}>{JSON.stringify(this.state.data, null, '....')}</Text>
        </ScrollView>
      </View>

    );
  }
}

import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  TextInput,
  Image,
  TouchableOpacity,
  Keyboard,
  Dimensions,
  Platform,
  Modal,
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import CachedImage from "../../shared/CachedImage"

export class GetAPIPicture extends Component {

  state = {
    loadingCache: true,
    keyword: "default",
    suggestions: this.props.blogs ? blogSuggestions : [],
    selectedPicture: null,
    searchTerm: "",
    hasScrolled: false,
    hasScrolledFar: false,
    keyboardShowing: false
  };
  cache = {}

  async componentDidMount() {
    try {
      const docName = !this.props.backgrounds ? "apiPictureCache" : "apiBackgroundPictureCache"
      const cDoc = await firebase
        .firestore()
        .collection("ServerClientReadOnly")
        .doc(docName)
        .get();
      const c = cDoc.data()
      if (c.results) this.cache = c.results
      this.setState({ loadingCache: false })
      this.getSuggestions("default")
    } catch (e) {
      global.warn(e, "API-CDM")
    }

    this.keyboardDidShowListener = Keyboard.addListener("keyboardDidShow", this._keyboardDidShow);
    this.keyboardDidHideListener = Keyboard.addListener("keyboardDidHide", this._keyboardDidHide);
  }

  componentWillUnmount() {
    if (this.keyboardDidShowListener) this.keyboardDidShowListener.remove();
    if (this.keyboardDidHideListener) this.keyboardDidHideListener.remove();
  }

  _keyboardDidShow = async e => {
    this.setState({ keyboardShowing: true })
  };

  _keyboardDidHide = () => {
    this.setState({ keyboardShowing: false })
  };

  getSuggestions = async (keyword) => {
    try {
      let sText = keyword.toLowerCase()
      sText = sText.replace(/[^a-z\d\s]+/gi, "") //only letters, numbers and white spaces
      this.setState({ keyword: sText })
      let suggestions;

      if (this.cache?.[sText]) {
        console.log("Found " + sText + " in cache")
        suggestions = this.cache?.[sText]
      }
      else {
        sText = sText.replace(/\s/g, "+") //replace white spaces with +
        console.log(sText)
        if (!sText) return
        if (sText == "default") {
          if (this.props.backgrounds) sText = "dark+backgrounds"
          else sText = ""
        }
        let url = 'https://pixabay.com/api/?key=20216206-5bb4d8d8187fe87389cec910d&lang=en&safesearch=true&page=1&per_page=180&q=' + sText
        if (this.props.backgrounds) url = url + '&category=backgrounds'
        const r = await fetch(url)
        const r2 = await r.json();
        console.log({ r2 })
        suggestions = r2?.hits
        if (this.props.blogs && sText == "") suggestions = [...blogSuggestions, ...suggestions]
      }

      if (suggestions) this.setState({ suggestions })
      this.myScrollView?.scrollTo?.({ x: 0, y: 0, animated: false });
    } catch (e) {
      global.warn(e, "getAPISuggestions")
    }
  }

  componentWillUnmount() {
  }

  search = async (searchTerm) => {
    try {
      this.setState({ searchTerm })
      const nowDate = new Date();
      this.lastimeTyped = nowDate
      await global?.timeout?.(1000)
      if (nowDate == this.lastimeTyped) {
        this.getSuggestions(searchTerm.toLowerCase())
      }
    } catch (e) {
      global.warn(e, "gr-search")
    }
  }

  uploadPicture = async () => {
    try {
      //this.setState({ processing: true })
      const uri = global.lastRecordingURI
      const blob = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          resolve(xhr.response);
        };
        xhr.onerror = function (e) {
          console.log(e);
          reject(new TypeError("Network request failed."));
        };
        xhr.responseType = "blob";
        xhr.open("GET", uri, true);
        xhr.send(null);
      });
      console.log("Uploading to Storage")

      const path = "VoiceMessages/" +
        Math.floor(Math.random() * 89999999999 + 10000000000)
        + uri.substring(uri.length - 7)
      console.log("Path: ", path)
      const ref = firebase
        .storage()
        .ref()
        .child(path);
      await ref.put(blob);
      const upURL = await ref.getDownloadURL();
      console.log("upURL", upURL)

      await FileSystem.deleteAsync(uri)
      global.lastRecordingURI = ""
      return upURL
    } catch (error) {
      global.warn(error, "CDM-uploadVoiceMessage");
      const info = await FileSystem.getInfoAsync(this.recording.getURI())
      await FileSystem.deleteAsync(info.uri)
      this.recording = null
      global.lastRecordingURI = ""
      return false
    }
  }

  renderSearchBar = () => {
    return (<View
      style={{
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: Platform.OS == "ios" ? "85%" : "85%"
      }}
    >
      <TextInput
        style={{
          alignSelf: "stretch", height: 30, margin: 10, marginLeft: 0, color: "#fff", backgroundColor: "#889", borderRadius: 15, borderBottomWidth: 0, paddingLeft: 10, flex: 1
        }}
        placeholder="Search Images (Pixabay)"
        placeholderTextColor="#eee"
        textAlign="center"
        underlineColorAndroid="transparent"
        underlineColor={"transparent"}
        onChangeText={searchTerm => this.search(searchTerm)}
        ref={component => (this.myTextInputBlue = component)}
        // clearButtonMode="always" //xx how set colour?
        value={this.state.searchTerm}
        keyboardShouldPersistTaps={'always'}
        keyboardDismissMode={'on-drag'}
        keyboardAppearance={"dark"}
        returnKeyType={"done"}
        maxLength={50}
        onSubmitEditing={() => { Keyboard.dismiss() }}
        autoCompleteType={'off'}
        autoCapitalize={'none'}
        autoCorrect={false}
      //onFocus={() => { if (Platform.OS == "android") this.myTextInputBlue?.focus?.() }}
      />
      <TouchableOpacity style={{ marginLeft: -50, width: 50, height: 50, alignItems: "center", justifyContent: "center", paddingRight: 10, paddingBottom: 3 }}
        onPress={() => { this.setState({ searchTerm: "" }); this.myTextInputBlue?.focus?.() }}>
        <Text style={{ color: "white", fontSize: 24, color: "#667" }}>×</Text>
      </TouchableOpacity>
    </View>)
  }

  renderSmiley = (smiley, keyword) => {
    return <TouchableOpacity onPress={() => {
      this.setState({ showSearch: false, searchTerm: "" })
      this.getSuggestions(keyword)
    }}
      style={{ ...(this.state.keyword == keyword && { backgroundColor: "#fff", borderRadius: 5 }) }}
    >
      <Text style={{ fontSize: 36 }}>{smiley}</Text>
    </TouchableOpacity>
  }


  render() {
    const sWidth = Dimensions.get("window").width;
    const sHeight = Dimensions.get("window").height;
    const vHeight = sHeight - 30 - 50 - 60
    const topSpacerHeight = (this.state.selectedPicture || this.state.keyboardShowing) ? 30 : 30 + vHeight / 3
    const selectedHeight = vHeight / 3
    let selectedWidth = sWidth - 4
    if (this.state.selectedPicture) {
      selectedWidth = this.state.selectedPicture.width / this.state.selectedPicture.height * selectedHeight
      selectedWidth = Math.min(selectedWidth, (sWidth - 4))
    }
    return (<Modal
      animationType="fade"
      transparent={true}
      visible={true}
      onRequestClose={() => {
        this.props.onDone()
      }}
    >
      <View style={{ flex: 1 }}>
        <TouchableOpacity
          onPress={() => this.props.onDone()}
          style={{ height: topSpacerHeight, width: "100%", opacity: 0.7, backgroundColor: "#222" }}
        />
        <View style={{
          flex: 1,
          alignItems: "flex-start", backgroundColor: "#000", borderWidth: 2, borderColor: "#889",
          borderTopEndRadius: 15, borderTopLeftRadius: 15
        }}>
          <View style={{ height: 48 }} />
          <View style={{ flex: 1, alignItems: "center" }}>
            {!!this.state.selectedPicture && <Image
              source={{ uri: this.state.selectedPicture.url }}
              style={{ width: selectedWidth, height: selectedHeight }}
            />}

            <ScrollView
              showsVerticalScrollIndicator={false}
              ref={ref => (this.myScrollView = ref)}
              scrollEventThrottle={100}
              onScroll={(e) => {
                //images of hidden items should only be loaded when scrolling starts
                if (e.nativeEvent.contentOffset.y >= 800) {
                  this.setState({ hasScrolled: true, hasScrolledFar: true });
                } else if (e.nativeEvent.contentOffset.y >= 10) {
                  this.setState({ hasScrolled: true });
                } else this.setState({ hasScrolled: false, hasScrolledFar: false })
              }}>

              <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                {this.state.suggestions.map((s, i) => {
                  let numberRendering = this.state.hasScrolled ? 48 : 12
                  if (this.state.hasScrolledFar) numberRendering = 180
                  if (i < numberRendering) return <TouchableOpacity
                    key={s.webformatURL} activeOpacity={1}
                    onPress={() => {
                      //console.log(s.webformatURL)
                      const url = s.webformatURL//.replace("640.", "960.")
                      console.log(url)
                      const selectedPicture = {
                        webURL: s.webformatURL || "",
                        url,
                        height: s.webformatHeight || 100,
                        width: s.webformatWidth || 100
                      }
                      this.setState({ selectedPicture })
                    }}
                  >
                    <View style={{ height: vHeight / 6, width: sWidth / 3 - 2, backgroundColor: s.avgColor || "#" + Math.round(Math.random * 899999 + 100000) }}>

                      {!!s.previewURL && <Image
                        style={{ height: vHeight / 6, width: sWidth / 3 - 2 }}
                        source={{ uri: s.previewURL || "" }}
                      />}

                    </View>
                  </TouchableOpacity>
                })}
                {this.state.suggestions?.length == 0 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((s) => {

                  const backgroundColor = ("#" + Math.round(Math.random() * 899999 + 100000))
                  return <View key={s}
                    style={{
                      height: vHeight / 6, width: sWidth / 3 - 2,
                      backgroundColor
                    }} />

                })
                }
              </View>
            </ScrollView>
          </View>

          <View style={{ backgroundColor: "#000", opacity: 0.9, height: 25, width: 150, zIndex: 5, marginTop: -25 }}>
            <CachedImage style={{ height: 25, width: 150, tintColor: "white" }} source={{ uri: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FPixabay.png?alt=media&token=63b90d22-ede5-48c6-a42a-f95c07477f70' }} />
          </View>

          <View style={{
            height: 60, width: "100%", padding: 5, alignItems: "center", flexDirection: "row", justifyContent: "space-around",

          }}>
            {this.renderSmiley("🌍", "africa people")}
            {global.myLocalCurrency == "GHS" ? this.renderSmiley("🇬🇭", "ghana") : this.renderSmiley("🇳🇬", "nigeria")}
            {this.renderSmiley("💼", "business")}
            {this.renderSmiley("⭐", "default")}
            {this.renderSmiley("🦁", "animal")}
            {this.renderSmiley("😎", "cool")}
            {this.renderSmiley("😍", "love")}
          </View>

        </View>
        <View style={{
          position: "absolute", top: topSpacerHeight, left: 0, height: 50, width: "100%",
          padding: 5, alignItems: "center", flexDirection: "row", justifyContent: "space-between",
          borderWidth: 2, borderColor: "#889", backgroundColor: "#222226", borderTopEndRadius: 15, borderTopLeftRadius: 15
        }}>
          <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>

            <TouchableOpacity
              style={{ paddingRight: 10, width: 45 }}
              onPress={() => { this.props.onDone() }}
            >
              <Image
                style={{ width: 35, height: 35, opacity: 1, }}
                source={require("../../images/icons/CancelWhite.png")}
              />
            </TouchableOpacity>
            {!this.state.showSearch ? <TouchableOpacity
              style={{ paddingRight: 10 }}
              onPress={async () => {
                await this.setState({ showSearch: true })
                await global.timeout(100)
                this.myTextInputBlue?.focus?.()
              }}
            >
              <Image
                style={{ width: 25, height: 25, opacity: 1, tintColor: "#fff" }}
                source={require("../../images/icons/Search.png")}
              />
            </TouchableOpacity>
              :
              this.renderSearchBar()}
          </View>
          {!!this.state.selectedPicture && <TouchableOpacity
            style={{
              alignItems: "center", alignSelf: "center", padding: 1,
              backgroundColor: "#A8CF45", flexDirection: "row",
              marginHorizontal: 4.5, width: 100, height: 32,
              justifyContent: "center", borderRadius: 20
            }}
            onPress={() => {
              this.props.onDone(this.state.selectedPicture)
            }}
          >
            <Text style={{ fontWeight: "bold", marginTop: 1 }}>✓ Use this</Text>

          </TouchableOpacity>}
        </View>
      </View>
    </Modal >)
  }
}

const blogSuggestions = [{
  webformatURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fadult-1807500_1920.jpg?alt=media&token=1be3b13f-00ff-4d84-b22f-a08879f72d2e',
  previewURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fadult-1807500_1920.jpg?alt=media&token=1be3b13f-00ff-4d84-b22f-a08879f72d2e',
  webformatHeight: 480,
  webformatWidth: 720
}, {
  webformatURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fbackground-5060836_1920.jpg?alt=media&token=41d179e1-2c6f-4e69-8502-ab21036622c5',
  previewURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fbackground-5060836_1920.jpg?alt=media&token=41d179e1-2c6f-4e69-8502-ab21036622c5',
  webformatHeight: 480,
  webformatWidth: 720
}, {
  webformatURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fcanon-5158887_1920.jpg?alt=media&token=ec5de412-edf3-4559-9d32-22d2b5dcc5cd',
  previewURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fcanon-5158887_1920.jpg?alt=media&token=ec5de412-edf3-4559-9d32-22d2b5dcc5cd',
  webformatHeight: 480,
  webformatWidth: 720
}, {
  webformatURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fcomputer-768696_1920.jpg?alt=media&token=dfa4db03-fac0-46cd-bc30-a812a45cb219',
  previewURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fcomputer-768696_1920.jpg?alt=media&token=dfa4db03-fac0-46cd-bc30-a812a45cb219',
  webformatHeight: 480,
  webformatWidth: 720
}, {
  webformatURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fkayak-5543935_1920.jpg?alt=media&token=e4b8d916-2747-471f-a2a7-10dde166c9da',
  previewURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fkayak-5543935_1920.jpg?alt=media&token=e4b8d916-2747-471f-a2a7-10dde166c9da',
  webformatHeight: 480,
  webformatWidth: 720
}, {
  webformatURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Flaptop-4840790_1920.jpg?alt=media&token=6429e535-6756-415f-85bb-aa1fd00943e6',
  previewURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Flaptop-4840790_1920.jpg?alt=media&token=6429e535-6756-415f-85bb-aa1fd00943e6',
  webformatHeight: 480,
  webformatWidth: 720
}, {
  webformatURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fmicrophone-5046876_1920.jpg?alt=media&token=38b298c3-a73e-47f8-bb48-9b5d9c7819a7',
  previewURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fmicrophone-5046876_1920.jpg?alt=media&token=38b298c3-a73e-47f8-bb48-9b5d9c7819a7',
  webformatHeight: 480,
  webformatWidth: 720
}, {
  webformatURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fvinyl-4808792_1920.jpg?alt=media&token=48fb51f4-d011-4205-90a1-57d2598b13fd',
  previewURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fvinyl-4808792_1920.jpg?alt=media&token=48fb51f4-d011-4205-90a1-57d2598b13fd',
  webformatHeight: 480,
  webformatWidth: 720
}, {
  webformatURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fvenice-5082785_1920.jpg?alt=media&token=4c2f8d96-f4fb-46f6-a536-65fb4242409f',
  previewURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fvenice-5082785_1920.jpg?alt=media&token=4c2f8d96-f4fb-46f6-a536-65fb4242409f',
  webformatHeight: 480,
  webformatWidth: 720
}, {
  webformatURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Futah-5641320_1920.jpg?alt=media&token=f6565d80-c6d0-4fb4-a692-859542ad35d4',
  previewURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Futah-5641320_1920.jpg?alt=media&token=f6565d80-c6d0-4fb4-a692-859542ad35d4',
  webformatHeight: 480,
  webformatWidth: 720
}, {
  webformatURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fpeople-3104635_1920.jpg?alt=media&token=5491ffba-0b62-467f-be4f-f647023ed483',
  previewURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fpeople-3104635_1920.jpg?alt=media&token=5491ffba-0b62-467f-be4f-f647023ed483',
  webformatHeight: 480,
  webformatWidth: 720
}, {
  webformatURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fmountains-5477320_1920.jpg?alt=media&token=72e2fec2-9eef-4a0f-8050-e7f7a1df019b',
  previewURL: 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fmountains-5477320_1920.jpg?alt=media&token=72e2fec2-9eef-4a0f-8050-e7f7a1df019b',
  webformatHeight: 480,
  webformatWidth: 720
}]
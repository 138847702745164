
import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
  Platform,
  Animated,
  Modal,
  Alert,
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import * as MediaLibrary from 'expo-media-library';
import * as Permissions from 'expo-permissions';
import * as FileSystem from 'expo-file-system';
import { PinchGestureHandler } from 'react-native-gesture-handler'
import CachedImage from "../shared/CachedImage"


export default class FullscreenImage extends Component {

  state = {
    downloadingImage: false,
    hideLoadingPicture: false,
    fullImageWidth: Dimensions.get("window").width,
    fullImageHeight: Dimensions.get("window").width,
  }

  _baseScale = new Animated.Value(1);
  _pinchScale = new Animated.Value(1);
  _scale = Animated.multiply(this._baseScale, this._pinchScale);
  _lastScale = 1;
  // _x = new Animated.Value(1);
  // _y = new Animated.Value(1);
  xy = new Animated.ValueXY({ x: 0, y: 0 })

  _onPinchGestureEvent = Animated.event(
    [{ nativeEvent: { scale: this._pinchScale } }],
    { useNativeDriver: true }
  );

  componentDidMount() {
    this.calculateFullscreenRatio()
  }

  calculateFullscreenRatio = async () => {
    const setFullscreenRatio = (w, h) => {
      let fullImageWidth = Dimensions.get("window").width
      let fullImageHeight = h * fullImageWidth / w
      if (fullImageHeight > Dimensions.get("window").height) {
        fullImageWidth = fullImageWidth * (Dimensions.get("window").height - 100) / fullImageHeight
        fullImageHeight = (Dimensions.get("window").height - 100)
      }
      //console.log({ fullImageHeight, fullImageWidth })
      this.setState({ fullImageHeight, fullImageWidth })
    }
    try {
      Image.getSize(this.props.pictureURL, setFullscreenRatio, () => { })
    } catch (e) {
      global.warn(e, "calculateFullscreenRatio")
    }
  }

  saveToGallery = (uri) => {
    this.setState({ downloadingImage: true })
    let fileUri = FileSystem.documentDirectory + 'post.jpeg';
    FileSystem.downloadAsync(uri, fileUri)
      .then(async ({ uri }) => {
        const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
        if (status === "granted") {
          console.log('Downloading Image ', uri);
          const asset = await MediaLibrary.createAssetAsync(uri);
          //console.log('assestsssss', asset);
          await MediaLibrary.createAlbumAsync("Wakanda Downloads", asset, false);
          Alert.alert("Success", "Image was successfully downloaded!");
          this.setState({ downloadingImage: false })
        }
        this.setState({ downloadingImage: false })
      })
      .catch(error => {
        global.warn(error, "saveToGallery");
        Alert.alert("Error", "Couldn't download photo");
        this.setState({ downloadingImage: false })
      });
  }

  _onPinchHandlerStateChange = event => {
    if (true) {
      if (!this.state.hideLoadingPicture) this.setState({ hideLoadingPicture: true })
      console.log(event.nativeEvent)
      this._lastScale *= event.nativeEvent.scale;
      this._baseScale.setValue(1);
      this._baseScale.setValue(this._lastScale); // comment this out to scale back to original size on release
      this._pinchScale.setValue(1);
    }
  };

  render() {
    if (!this.props.on) return <View />
    return <Modal
      //animationType="fade"
      transparent={true}
      visible={true}
      onRequestClose={() => {
        this.props.onClose()
      }
      }
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: Dimensions.get("window").height,
        backgroundColor: "rgba(0,0,0,.7)"
      }}
    >
      <TouchableOpacity
        activeOpacity={0.9}
        onPress={() => { this.props.onClose() }}
        style={{
          flex: 1,
          justifyContent: "center",
          backgroundColor: "rgba(0,0,0,1)"
        }}
      >
        <View
          style={{
            height: 200,
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: -200
          }}
        >
          {!this.state.hideLoadingPicture && <Text
            style={{ color: "#aaa", fontSize: 14, fontStyle: "italic" }}
          >
            Loading Picture
                </Text>}
        </View>
        {this.state.downloadingImage ?
          <View
            style={{
              height: 265,
              position: 'absolute',
              zIndex: 5,
              bottom: 10,
              right: 15
            }}
          >
            <ActivityIndicator
              size="small"
              color="#A8CF45"
              animating={true}
            />
          </View>
          :
          <TouchableOpacity style={{ position: 'absolute', zIndex: 5, bottom: 10, right: 5, padding: 10 }}
            onPress={() => {
              Alert.alert(
                "Download image to gallery?",
                "",
                [{ text: "Cancel", onPress: () => { } },
                { text: "Yes", onPress: () => { this.saveToGallery(this.props.pictureURL) } }],
                { cancelable: false })
            }}
          >
            <Image
              style={{
                width: 30,
                height: 30,
                marginRight: 1,
              }}
              source={require("../images/icons/DownloadImage.png")}
            />
          </TouchableOpacity>}
        <PinchGestureHandler
          onGestureEvent={this._onPinchGestureEvent}
          onHandlerStateChange={this._onPinchHandlerStateChange}>
          <Animated.View style={{
            alignSelf: "center",
            width: this.state.fullImageWidth,
            height: this.state.fullImageHeight,
            // transform: [{ translateX: this._x }, { translateY: this._y }],
            ... this.xy.getLayout()
          }} collapsable={false}>
            {!this.props.cached ? <Animated.Image
              style={[
                { height: this.state.fullImageHeight, width: this.state.fullImageWidth },
                { transform: [{ perspective: 200 }, { scale: this._scale }], },
              ]}
              source={{ uri: this.props.pictureURL }}
            />
              :
              <Animated.View
                style={[
                  { height: this.state.fullImageHeight, width: this.state.fullImageWidth },
                  { transform: [{ perspective: 200 }, { scale: this._scale }], },
                ]}
                source={{ uri: this.props.pictureURL }}
              >
                <CachedImage
                  key={this.props.pictureURL}
                  style={{ height: this.state.fullImageHeight, width: this.state.fullImageWidth }}
                  source={{ uri: this.props.pictureURL }} />
              </Animated.View>}
          </Animated.View>
        </PinchGestureHandler>
      </TouchableOpacity>
      {/* </TouchableWithoutFeedback> */}
    </Modal >
  }
}
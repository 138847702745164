import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  TextInput,
  Image,
  TouchableOpacity,
  SafeAreaView,
  Platform,
  ActivityIndicator
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";




export default class NewChatScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Select Contact",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };
  state = {
    contactIDs: []
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    try {
      this.unsubscribeFirestore = await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .collection("Refs")
        .doc("contactRefs")
        .onSnapshot(this.updateContacts);
    } catch (err) {
      alert("Error initiating database connection");
      console.log("Error initiating database connection (NewChat)");
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
    console.log("Component NewChat will unmount");
  }

  
}


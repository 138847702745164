import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ImageBackground,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  Modal,
  ActivityIndicator,
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import firebase from "firebase";
import "firebase/firestore";

const gStyles = global.gStyles //    "../../styles/gStyles";

export default class MeSettingsScreen extends Component {
  static navigationOptions = {
    headerShown: false
  };

  state = {
    showModal: false,
    immediateSignoutPossible: global.coinResetAfterBlock,
    aa: true,
  }

  async componentDidMount() {

    try {
      if (!global.myUID) {
        this.props.navigation.goBack()
      }

      const myUserDoc = firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .onSnapshot(this.updateUser);

      global.signout = async () => {
        const key = "aID" + global.myUID.substring(4, 10)
        const bufferKey = "aIDbuffer" + global.myUID.substring(4, 10)
        const userInfoKey = "aIDUserInfo" + global.myUID.substring(4, 10)
        global.myUID = null;
        global.idToken = null;
        global.myProfileID = null;
        global.globalsSet = false
        global.myName = null;
        global.myBusinessName = null;
        global.myTimeCreated = null;
        global.myLocationWork = null;
        global.myLocationWorkAddress = "";
        global.myProfilePictureURL = null;
        global.myServicesOffered = [];
        global.myJobQualifications = [];
        global.myJobQualificationsStatus = "";
        global.myNetworks = [];
        global.myPostIDs = [];
        global.myFollowingIDs = [];
        global.myFollowerIDs = [];
        global.myPushToken = null;
        global.messageNotifications = {};
        global.jobNotifications = {};
        global.messageOpen = "";
        global.myBlacklist = [];
        global.myBlocklist = [];
        global.myBlackedlist = [];
        global.myBlockedlist = [];
        global.loadingSuccessChatMain = null; //needed?
        global.amApprover = false;
        global.walletType = null;
        //global.coinKeySet = null;
        global.coinSignKey = null;
        global.coinSignKeySet = null;
        global.myLocalCurrency = null;
        global.hasNoWebsiteYet = null;
        global.walletBalance = null;
        global.walletAmountInEscrowByYou = null;
        global.walletAmountInEscrowForYou = null;
        global.coinBalance = null;
        global.coinAmountInEscrowByYou = null;
        global.coinAmountInEscrowForYou = null;
        global.coinResetAfterBlock = null;
        global.coinLastResetDate = null;
        global.blockedUntil = null;
        global.localResultsList = null;
        global.localResultsDate = null;
        global.networks = null;
        global.networkRequests = null;
        global.networkAdminRights = null;
        global.globalsSet = false;
        global.myHealthFileID = null;
        global.amDoctor = null;
        global.myHealthProfileID = null;
        global.healthChatNotifications = { patient: {}, doctor: {} }
        global.myDiscounts = []
        try {
          await global.asyncDeleteAllContainers();
          await AsyncStorage.setItem("loanFormLast", "")
          await AsyncStorage.setItem("localResultsString", "")
          await AsyncStorage.setItem("localResultsDateString", "")
          await AsyncStorage.removeItem("hideBOGuide-chat")
          await AsyncStorage.removeItem("hideBOGuide-location")
          await AsyncStorage.removeItem("hideBOGuide-newFeatures16")
          await AsyncStorage.removeItem("hideBOGuide-hello")
          await AsyncStorage.removeItem("discussionFeed")
          await AsyncStorage.removeItem(key)
          await AsyncStorage.removeItem(bufferKey)
          await AsyncStorage.removeItem(userInfoKey)
          await AsyncStorage.removeItem("RECOMMENDATIONS")
          await AsyncStorage.removeItem("lastViewedPostIDs")
        } catch (err) {
          console.log(err)
        }
        firebase.auth().signOut();
      }
    } catch (err) {
      console.log("Error wwwe/", err);
    }
  }

  updateUser = async userSnapshot => {
    const user = userSnapshot.data()
    if (user.coinResetAfterBlock) this.setState({ immediateSignoutPossible: true })
    else this.setState({ immediateSignoutPossible: false })
  };

  callSwitchPhone = async () => {
    console.log("Switch phones pressed")
    this.props.navigation.navigate("walletSetup", { screen: "11" })
    await global.timeout(250)
    this.setState({ showModal: false })
  }

  callHideMyBusiness = async () => { // copied from meProfileScreen
    //xx TBD xx: What if a user wants no info to be displayed
    try {
      this.setState({ aa: true });
      objectOfChange = {
        profile: global.myProfileID,
        hideBusinessProfile: true,
        myUID: global.myUID,
        idToken: global.idToken
      };
      const r = await fetch(global.cloudFunctionURL + "updateProfile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(objectOfChange)
      });
      const r2 = await r.json();

      this.setState({ aa: false });

      this.setModalVisible(false);

      if (r2.msg === "PROFILE_HIDDEN") {
        console.log("Profile hidden " + objectOfChange);
        this.props.navigation.goBack();
      } else {
        console.log("Error, ", r2.msg);
      }
    } catch (err) {
      alert("An error occurred.");
      global.warn(err, "MeProfileUpdate2")
      this.setState({ aa: false });
    }
  };

  render() {

    if (this.state.aa) return (<View style={{ width: "100%", flex: 1, justifyContent: "center", backgroundColor: "black", alignItems: "center" }}>
      <ActivityIndicator
        size="large"
        color="#ffa"
        style={{
          marginBottom: 20,
          alignSelf: "center"
        }}
        animating={true}
      />
      <Text style={{ color: "#ffa", marginBottom: 10 }}>Deleting data...</Text>
      <Text style={{ color: "#ffa" }}>This may take a bit longer, please be patient...</Text>
    </View>
    )

    return (
      <View style={{ flex: 1, backgroundColor: "#131313" }}>
        <View style={{ height: 100 }} />
        <ScrollView>
          <View style={{ height: 20 }} />
          <View style={{ backgroundColor: "#131313" }}>
            <View
              style={global.gStyles.me}
            >
              <TouchableOpacity
                style={{}}
                onPress={async () => {

                  if (global.walletType != "ACN" || this.state.immediateSignoutPossible || !global.coinSignKey) {
                    try {
                      await global.signout()
                    }
                    catch (e) {
                      console.warn("Error during signout. Data could not be deleted")
                      console.log("Error during signout. Data could not be deleted", e)
                    }
                  } else this.setState({ showModal: true })
                }}
              >
                <Text style={{ color: "#a66", fontSize: 18 }}>Sign Out</Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>

        <Modal animationType="fade" transparent={false} visible={this.state.showModal}
          onRequestClose={() => { this.setState({ showModal: false }); }}>
          <View style={{ backgroundColor: "#fff", flex: 1, paddingTop: 20 }}>
            <View style={{ height: 50, alignSelf: "flex-start", justifyContent: "center", paddingTop: 10 }}>
              <TouchableOpacity
                style={{ width: 50, alignItems: "center", justifyContent: "center" }}
                onPress={() => { this.setState({ showModal: false }) }}
              >
                <View style={{ height: 30, width: 30, backgroundColor: "#400", borderRadius: 15 }}>
                  <Image source={require("../../images/icons/CancelWhite.png")} style={{ height: 30, width: 30 }} />
                </View>
              </TouchableOpacity>
            </View>
            <ScrollView style={{ flex: 1, paddingHorizontal: 15 }}>
              <Text style={{ fontSize: 24, color: "#222", paddingBottom: 5, marginTop: 20, justifyContent: "flex-start", textAlign: "center", fontWeight: "bold" }}>
                Sign Out?
              </Text>
              <Text style={{ fontSize: 14, color: "#222", paddingBottom: 5, marginTop: 20, justifyContent: "flex-start" }} >
                You do not need to sign out unless you want to switch phones.
              </Text>
              <TouchableOpacity
                onPress={this.callSwitchPhone}
                style={[global.gStyles.button, { marginTop: 25, backgroundColor: "#400" }]}
              >
                <Text style={[global.gStyles.buttontext, { color: "white" }]}>
                  Sign out and switch phones
                    </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => { global.signout() }}
                style={[global.gStyles.button, { marginTop: 25, backgroundColor: "#400" }]}
              >
                <Text style={[global.gStyles.buttontext, { color: "white" }]}>
                  Sign out, I will sign back in to this phone later
                    </Text>
              </TouchableOpacity>
            </ScrollView>
            <View style={{ height: 120, width: "100%", position: "absolute", bottom: 0, justifyContent: "center", paddingHorizontal: 15 }}>
              <Text style={{ color: "#444" }}>ⓘ If you switch phones, you will need to enter your wallet password so that your wallet can be accessed on the new device.</Text>
            </View>
          </View>
        </Modal>
      </View>
    );
  }
}
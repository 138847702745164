import React, { Component } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  SectionList,
  Platform,
  Dimensions,
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";

export default class SelectBank extends Component {
  banksNG = [
    { "Id": 1, "Code": "044", "Name": "Access Bank", "IsMobileVerified": null, "branches": null },
    { "Id": 2, "Code": "023", "Name": "Citi Bank", "IsMobileVerified": null, "branches": null },
    { "Id": 3, "Code": "063", "Name": "Access Bank PLC (Diamond)", "IsMobileVerified": null, "branches": null },
    { "Id": 4, "Code": "050", "Name": "EcoBank PLC", "IsMobileVerified": null, "branches": null },
    { "Id": 5, "Code": "011", "Name": "First Bank PLC", "IsMobileVerified": null, "branches": null },
    { "Id": 6, "Code": "214", "Name": "First City Monument Bank", "IsMobileVerified": null, "branches": null },
    { "Id": 7, "Code": "070", "Name": "Fidelity Bank", "IsMobileVerified": null, "branches": null },
    { "Id": 8, "Code": "058", "Name": "Guaranty Trust Bank", "IsMobileVerified": null, "branches": null },
    { "Id": 9, "Code": "076", "Name": "Polaris bank", "IsMobileVerified": null, "branches": null },
    { "Id": 10, "Code": "221", "Name": "Stanbic IBTC Bank", "IsMobileVerified": null, "branches": null },
    { "Id": 11, "Code": "068", "Name": "Standard Chaterted bank PLC", "IsMobileVerified": null, "branches": null },
    { "Id": 12, "Code": "232", "Name": "Sterling Bank PLC", "IsMobileVerified": null, "branches": null },
    { "Id": 13, "Code": "033", "Name": "United Bank for Africa", "IsMobileVerified": null, "branches": null },
    { "Id": 14, "Code": "032", "Name": "Union Bank PLC", "IsMobileVerified": null, "branches": null },
    { "Id": 15, "Code": "035", "Name": "Wema Bank PLC", "IsMobileVerified": null, "branches": null },
    { "Id": 16, "Code": "057", "Name": "Zenith bank PLC", "IsMobileVerified": null, "branches": null },
    { "Id": 17, "Code": "215", "Name": "Unity Bank PLC", "IsMobileVerified": null, "branches": null },
    { "Id": 18, "Code": "101", "Name": "ProvidusBank PLC", "IsMobileVerified": null, "branches": null },
    { "Id": 183, "Code": "082", "Name": "Keystone Bank", "IsMobileVerified": null, "branches": null },
    { "Id": 184, "Code": "301", "Name": "Jaiz Bank", "IsMobileVerified": null, "branches": null },
    { "Id": 186, "Code": "030", "Name": "Heritage Bank", "IsMobileVerified": null, "branches": null }
  ];

  render() {
    return (
      <View>
        <TouchableOpacity
          onPress={() => this.props.onClose()}
          style={{ height: 60 }}
        />
        <View
          style={{
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <View
            style={{
              justifyContent: "center",
              alignItems: "center", backgroundColor: "rgba(0,0,0,.75)"
            }}
          >
            <View style={{ paddingTop: 20 }}>
              <Text style={{ fontSize: 30, color: "white" }}>
                Select Recipient Bank
              </Text>
            </View>
            <View style={{ marginTop: 0, marginBottom: 5 }} />
            <View style={{ flex: 1, paddingBottom: 125 }}>
              <SectionList
                renderItem={({ item, index, section }) => (
                  <TouchableOpacity
                    onPress={() => {
                      const bank = this.banksNG.filter(bank => bank.Name == item);
                      const code = bank[0].Code;
                      const name = bank[0].Name;
                      this.props.onSelect(code, name);
                    }}
                    style={{
                      flex: 1,
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 20,
                      width: Dimensions.get("window").width,
                      backgroundColor: "#112",
                      borderWidth: 1,
                      borderColor: "#223"
                    }}
                  >
                    <Text style={{ fontSize: 16, color: "white" }} key={index}>
                      {item}
                    </Text>
                  </TouchableOpacity>
                )}
                renderSectionHeader={({ section: { title } }) => (
                  <Text style={{ fontWeight: "bold" }}>{title}</Text>
                )}
                sections={[
                  {
                    title: "",
                    data: this.banksNG.map(bank => bank.Name)
                  }
                ]}
                keyExtractor={(item, index) => item + index}
              />
            </View>
          </View>
        </View>
      </View>
    );
  }
}

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  Image,
  ImageBackground,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Dimensions,
} from "react-native";
import { NavigationEvents } from "react-navigation";
import firebase from "firebase";
import "firebase/firestore";
import CachedImage from "../../shared/CachedImage"
const gStyles = global.gStyles //    "../../styles/gStyles"

const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;


export default class MeBlogsScreen extends Component {
  static navigationOptions = {
    headerTransparent: true,
    headerTitle: "My Blogs",
    headerTintColor: "#A8CF45",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1,
      elevation: 1
    },
  };

  state = {
    blogs: [],
    loading: true
  }

  componentDidMount = () => {
    this.loadBlogs()
  }

  loadBlogs = async () => {
    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "blog", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "getMyBlogs",
          myUID: global.myUID,
          idToken: global.idToken,
        }),
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        if (Array.isArray(r2.response.myBlogs))
          this.setState({ blogs: r2.response.myBlogs })
      }
      this.setState({ loading: false })
    } catch (e) {
      global.warn(e, "BlogsManage-loadBlogs")
    }
  }

  componentWillUnmount = () => {
  }

  render() {
    if (this.state.loading) return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "#131313" }}>
        <ActivityIndicator size="large" color="#889" />
        <Text style={{ marginTop: 12, color: "#66666a" }}>Loading my Blogs</Text>
      </View>
    );

    return (
      <View style={{ flex: 1, backgroundColor: "#131313", paddingTop: 115 }}>
        <ScrollView style={{ flex: 1 }}>
          {this.state.blogs?.length == 0 && <View style={{ backgroundColor: "#333",borderWidth:2,borderColor:"#666", borderRadius: 12, padding: 16, margin: 20, paddingLeft: 32, alignSelf: "stretch" }}>
            <Text style={{ color: "#b0b4b7", fontStyle: "italic", fontSize: 15, marginBottom: 2 }}>                 Your opinion matters...</Text>
            <Text style={{ color: "#d0d5d9", fontStyle: "italic", fontWeight: "bold", fontSize: 16, marginBottom: 32 }}>                      Let the world know!</Text>
            <Text style={{ color: "#e0e5e9", fontStyle:"italic", fontWeight: "bold", fontSize: 18, marginBottom: 8 }}>
              Create your own Blog
            </Text>
            <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#e0e5e9", fontStyle:"italic" }}>
              Professional
              </BulletPoint>
            <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#e0e5e9", fontStyle:"italic" }}>
              Live in seconds</BulletPoint>
            <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#e0e5e9", fontStyle:"italic" }}>
              Your Blog Website coming soon</BulletPoint>
            <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#e0e5e9", fontStyle:"italic" }}>
              FREE</BulletPoint>
            {/* <TouchableOpacity
              onPress={() => { this.setState({ showJoinModal: true }); }}
              style={[global.gStyles.button, { marginTop: 20 }]}
            >
              <Text style={global.gStyles.buttontext}>JOIN NOW</Text>
            </TouchableOpacity> */}
            <ImageBackground
              style={{

                position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: -1
              }}
              source={{ uri: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2Fwood1280.jpg?alt=media&token=55b7c07a-3f36-4cab-b1bc-ae9668ad40e9" }}
            />
          </View>}
          {this.state.blogs.map(blog => {
            return (<TouchableOpacity key={blog.xID}
              onPress={() => { this.props.navigation.navigate("meBlogEdit", { blogID: blog.xID }) }}
              style={{
                minHeight: 90,
                borderBottomWidth: 0,
                borderBottomColor: "#606072",
                alignItems: "center",
                width: SCREEN_WIDTH * 0.9,

                margin: 6,
                borderRadius: 12,
                backgroundColor: "#242424",
                padding: 10,
                marginBottom: 20,
                alignSelf: "center",

                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <CachedImage
                  style={{
                    width: 100,
                    height: 75,
                    borderRadius: 10,
                    marginRight: 15
                  }}
                  source={{ uri: blog.blogPictureURL }}
                  resizeMode="cover"
                />
                <View>
                  <Text style={{ color: "#eef", fontSize: 16, fontWeight: "bold", marginBottom: 5 }}>{blog.blogTitle}</Text>
                  <Text style={{ color: "#aab", fontSize: 12 }}>{blog.blogSubtitle}</Text>
                </View>
              </View>
              <View style={{ alignSelf: "center", width: "6%", justifyContent: "center" }} >
                <Text style={{ fontSize: 20, color: "#aab", marginBottom: 2 }}>{">"}</Text>
              </View>
            </TouchableOpacity>)
          })}
          <TouchableOpacity
            style={{
              width: SCREEN_WIDTH * 0.9,
              height: 50,
              backgroundColor: "#A9D046",
              borderRadius: 12,
              padding: 10,
              alignSelf: "center",
              marginTop: 25,
              alignItems: "center",
              justifyContent: "center",
            }}
            onPress={() => { this.props.navigation.navigate("meBlogNew") }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontFamily: "ProximaNovaA-Bold",
                fontSize: 15,
              }}
            >
              +  Create a blog
              </Text>
          </TouchableOpacity>
        </ScrollView>
        <NavigationEvents
          onDidFocus={async (payload) => {
            console.log("Reloading Content")
            this.loadBlogs()
          }}
        />
      </View>
    );
  }
}

class BulletPoint extends PureComponent {
  render() {
    return (
      <View style={{ flexDirection: "row", justifyContent: "flex-start", marginLeft: 5 }}>
        <Text style={[this.props.style, { marginRight: 8 }]}>✓</Text>
        <Text style={this.props.style}>{this.props.children}</Text>
      </View>
    );
  }
}
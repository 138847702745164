import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  SafeAreaView,
  Platform,
  Dimensions,
  ScrollView,
  Linking
} from "react-native";
import Constants from 'expo-constants';
import firebase from "firebase";
import "firebase/firestore";
const gStyles = global.gStyles //    "../../../styles/gStyles";

export default class MeAdminScreen extends Component {

  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Wakanda Admin",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45"
    }
  };

  state = {
    amAdmin: false,
    amApprover: false
  };

  componentDidMount = async () => {

    // //alert("A")
    // let largeRefArray = await firebase.firestore()
    //     .collection("Profiles")
    //     .where("profileServicesOffered","array-contains","Handyman" )
    //     .limit(10)
    //     .get();
    // console.log("A" + largeRefArray.size)
    // console.log("A" + largeRefArray.size)
    // console.log("A" + largeRefArray.size)
    // console.log("A" + largeRefArray.size)
    // console.log("A" + largeRefArray.size)
    // console.log("A" + largeRefArray.size)
    // console.log("A" + largeRefArray.size)
    // console.log("A" + largeRefArray.size)



    let userDoc = await firebase
      .firestore()
      .collection("Users")
      .doc(global.myUID)
      .get();
    this.setState({
      amApprover: userDoc.data().isApprover === true ? true : false,
      amAdmin: userDoc.data().isAdmin === true ? true : false
    });
  };



  render() {
    const xHeight = 45;

    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#131313" }}>
        <View
          style={{
            flex: 1,
            backgroundColor: "#131313",
            justifyContent: "space-between"
          }}
        >
          <ScrollView style={{ backgroundColor: "#131313" }}>
            <View
              style={{
                ...Platform.select({
                  ios: {
                    height: 42
                  },
                  android: {
                    height: 70
                  }
                }),
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 8,
                borderBottomColor: "#444",
                borderBottomWidth: 1
              }}
            >
            </View>

            <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => {
                this.props.navigation.navigate("crm");
              }}
            >
              <Text style={global.gStyles.meText}>
                CRM
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => {
                this.props.navigation.navigate("dashboard");
              }}
            >
              <Text style={global.gStyles.meText}>
                Statistics Dashboard
              </Text>
            </TouchableOpacity>

            {/* {this.renderO()} */}

            <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => {
                this.props.navigation.navigate("websitesAdmin");
              }}
            >
              <Text style={global.gStyles.meText}>
                Website Registrations
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => {
                this.props.navigation.navigate("websiteCodesAdmin");
              }}
            >
              <Text style={global.gStyles.meText}>
                Website Referral Codes
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => {
                this.props.navigation.navigate("referrerDashboard", { country: "NGN" });
              }}
            >
              <Text style={global.gStyles.meText}>
                Signup Dashboard NG
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => {
                this.props.navigation.navigate("referrerDashboard", { country: "GHS" });
              }}
            >
              <Text style={global.gStyles.meText}>
                Signup Dashboard GH
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => {
                this.props.navigation.navigate("merchantDashboard");
              }}
            >
              <Text style={global.gStyles.meText}>
                ACoin Merchants
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => {
                this.props.navigation.navigate("guideMessages");
              }}
            >
              <Text style={global.gStyles.meText}>Baba Oja Messages</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => {
                this.props.navigation.navigate("postApprove");
              }}
            >
              <Text style={global.gStyles.meText}>Offer Approvals</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => {
                this.props.navigation.navigate("staffWebsitesDashboard");
              }}
            >
              <Text style={global.gStyles.meText}>Staff Websites (restricted)</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => {
                this.props.navigation.navigate("appAdminAssign");
              }}
            >
              <Text style={global.gStyles.meText}>
                App Admin Roles
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => {
                this.props.navigation.navigate("healthAdmin");
              }}
            >
              <Text style={global.gStyles.meText}>
                DoctorDial
              </Text>
            </TouchableOpacity>
          </ScrollView>
        </View>
      </SafeAreaView>
    );
  }
}

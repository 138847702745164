
import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  TextInput,
  SafeAreaView,
  Alert,
  Keyboard,
  Platform,
  Modal,
  ActivityIndicator
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
const gStyles = global.gStyles //    "../../../../styles/gStyles"


class Contact extends Component {
  state = {
    contactProperties: {
      opponentProfileID: null,
      opponentName: "********",
      opponentNameBusiness: "************",
      opponentProfilePictureURL: null
    },
    showAction: false,
    showRejectModal: false,
    rejectReason: "",
    aa: false
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  componentDidMount() {
    this.unsubscribeFirestore = firebase
      .firestore()
      .collection("Profiles")
      .doc(this.props.contactID)
      .onSnapshot(this.updateContact);
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  updateContact = async contactSnapshot => {
    console.log("Update of list of contacts triggered. (Contact in NetworkApprove)");
    try {
      let contact = contactSnapshot.data();

      let opponentProfileID = contactSnapshot.id;
      let opponentName = contact.profileName;
      let opponentProfilePictureURL = contact.profilePictureURL;
      let opponentNameBusiness = contact.profileNameBusiness;
      if (!contact.profileNameBusiness) {
        opponentNameBusiness = "";
      }

      let contactProperties = {
        opponentProfileID,
        opponentName,
        opponentNameBusiness,
        opponentProfilePictureURL
      };

      //console.log("contactProperties", contactProperties);
      this.setState({
        contactProperties
      });
    } catch (err) {
      console.log("Slow internet. Could not fetch contacts (meContact)", err);
      alert("Could not fetch contacts. Check your internet connection");
    }
  };

  setStatus = (action) => {
    if (action == "approve") {
      Alert.alert("Approve Membership Request?", "",
        [{ text: "Cancel", onPress: () => { } },
        { text: "Yes", onPress: () => { this.executeSetStatus(action); } }],
        { cancelable: true }
      );
    }
    if (action == "reject") {
      if (!this.state.rejectReason) return alert("Enter a rejection reason");
      Alert.alert("Reject Membership Request?", "",
        [{ text: "Cancel", onPress: () => { } },
        { text: "Yes", onPress: () => { this.executeSetStatus(action); } }],
        { cancelable: true }
      );
    }
  }

  executeSetStatus = async (action) => {
    try {
      this.setState({ aa: true, showRejectModal: false })
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "setMembershipRequest", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action,
          networkID: this.props.networkID,
          ...(action == "reject" && { rejectReason: this.state.rejectReason }),
          myUID: global.myUID,
          idToken: global.idToken,
        })
      });
      const r2 = await r.json();
      this.setState({ aa: false })

      if (r2.msg === "SUCCESS") {
        alert("Success")
        this.props.update();
      } else {
        alert("Server Error");
      }
    } catch (err) {
      console.log(err);
      this.setState({ aa: false })
      alert("General Error");
    }
  }

  renderTextAdder = (text) => {
    return (<View style={{ height: 30, justifyContent: "center" }}>
      <TouchableOpacity style={global.gStyles.buttonXW}
        onPress={() => { this.setState({ rejectReason: this.state.rejectReason + " " + text }); }}
      >
        <Text style={global.gStyles.buttontXW}>{text}</Text>
      </TouchableOpacity>
    </View>)
  }

  render() {
    activeRequest = this.props.rData[this.props.rData.length - 1]
    milliseconds = activeRequest.timeCreated._seconds * 1000 + activeRequest.timeCreated._nanoseconds / 1000000
    activeTimeCreated = new Date(milliseconds)



    var ar = [1, 2, 3, 4, 5, 6];
    ar.pop(); // returns 6
    console.log(ar); // [1, 2, 3, 4, 5]
    console.log("this.props.rData", this.props.rData)



    ////    :[{status:open/approved/rejected, timeCreated:xxxxxx, rejectReason:”No sufficient .. provided”, requestText:””}]

    return (<View>
      <TouchableOpacity
        onPress={() => {
          if (!this.state.contactProperties.opponentProfileID) {
            return
          }
          let handoverID = this.state.contactProperties.opponentProfileID;
          this.props.navigation.navigate("meProfile", {
            profileID: handoverID
          });
        }}
      >
        <View
          style={[
            {
              flexDirection: "row",
              flex: 1,
              paddingVertical: 3,
              backgroundColor: "black",
              alignItems: "center",
              paddingLeft: 11,
              paddingRight: 11,
              shadowOpacity: 0.5,
              shadowOffset: { width: 0, height: 3 },
              borderBottomColor: "#333",
              borderBottomWidth: this.state.showAction ? 0 : 1
            }
          ]}
        >
          {!!this.state.contactProperties.opponentProfilePictureURL ? (
            <Image
              style={{ width: 42, height: 42, borderRadius: 4, alignSelf: "flex-start" }}
              source={{
                uri: this.state.contactProperties.opponentProfilePictureURL
              }}
            />
          ) : (
              <View style={{ width: 42, height: 42, borderRadius: 4, backgroundColor: "#444" }} />
            )}
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View
              style={{
                flexDirection: "column",
                flex: 1,
                paddingHorizontal: 8
              }}
            >
              <Text style={{ fontSize: 14, color: "white" }}>
                {this.state.contactProperties.opponentName}
              </Text>
              <Text style={{ fontSize: 12, color: "white", marginBottom: 4 }}>
                {this.state.contactProperties.opponentNameBusiness}
              </Text>
              {/* {this.state.contactProperties.opponentNameBusiness != "" && (
                <Text style={{ fontSize: 11, color: "white" }}>
                  {this.state.contactProperties.opponentNameBusiness}
                </Text>
              )} */}
              <Text style={{ fontSize: 12, color: "#008866", fontWeight: "bold", marginBottom: 4 }}>Request Details:</Text>
              <Text style={{ fontSize: 12, color: "#008866", marginBottom: 4 }}>{(activeTimeCreated) ? activeTimeCreated.toDateString() : "unknown"}</Text>
              <Text style={{ fontSize: 12, color: "#008866" }}>{activeRequest.requestText}</Text>
              {(this.props.rData.length > 1 && this.state.showAction) && this.props.rData.slice(0).reverse().map((r, i) => {
                if (i == 0) return (<View />);
                milliseconds = r.timeCreated._seconds * 1000 + r.timeCreated._nanoseconds / 1000000
                timeCreated = new Date(milliseconds)
                return (<View key={r.profileID} style={{ margin: 4, borderWidth: 0.5, borderColor: "#999", backgroundColor: "#111", padding: 2 }}>
                  <Text style={{ fontSize: 12, color: "#999", fontWeight: "bold", marginBottom: 4 }}>Previous Request:</Text>
                  <Text style={{ fontSize: 12, color: "#999", marginBottom: 4 }}>{timeCreated.toDateString()}</Text>
                  <Text style={{ fontSize: 12, color: "#999", marginBottom: 4 }}>{r.status.toUpperCase()} {r.status == "rejected" && "(Reason: " + r.rejectReason + ")"}</Text>
                  <Text style={{ fontSize: 12, color: "#999" }}>{r.requestText}</Text>
                </View>)
              })}

            </View>
          </View>
          <TouchableOpacity
            onPress={() => this.setState({ showAction: !this.state.showAction })}
            style={{ height: 50, width: 50, alignItems: "center", justifyContent: "center" }}>
            <Image style={{ width: 30, height: 30 }} source={require("../../../../images/icons/Change.png")} />
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
      {!!this.state.showAction && !this.state.aa && <View style={{ flexDirection: "row", height: 60, backgroundColor: "black", borderBottomColor: "#333", borderBottomWidth: 1, justifyContent: "space-around", padding: 3 }}>
        <View style={{ width: 130, margin: 10 }}>
          <TouchableOpacity style={global.gStyles.buttonX}
            onPress={() => { this.setState({ showRejectModal: true }); }}
          >
            <Text style={global.gStyles.buttontX}>REJECT</Text>
          </TouchableOpacity>
        </View>
        <View style={{ width: 130, margin: 10 }}>
          <TouchableOpacity style={global.gStyles.buttonX}
            onPress={() => { this.setStatus("approve"); }}
          >
            <Text style={global.gStyles.buttontX}>APPROVE</Text>
          </TouchableOpacity>
        </View>
      </View>}
      {!!this.state.aa && <View style={{ height: 60, backgroundColor: "black", borderBottomColor: "#333", borderBottomWidth: 1, justifyContent: "center", alignItems: "center", padding: 3 }}>
        <ActivityIndicator size="large" color="#008866" animating={true}
        />
      </View>}
      <Modal
        animationType="fade"
        transparent={true}
        visible={this.state.showRejectModal}
        onRequestClose={() => {
          //alert("Modal has been closed.");
        }}
      >
        <View style={{ backgroundColor: "rgba(0,0,0,.9)" }}>
          <View style={{ height: 10 }} />
          <TouchableOpacity
            activeOpacity={1}
            onPress={Keyboard.dismiss}
            accessible={false}
          >
            <ScrollView style={{ marginTop: 50, paddingHorizontal: 25 }}>
              <Text
                style={{
                  fontSize: 16,
                  color: "#ccc",
                  paddingBottom: 10,
                  borderBottomColor: "#FFFFFF",
                  borderBottomWidth: 0,
                  marginTop: 10,
                  justifyContent: "flex-start"
                }}
              >
                Rejection Reason
                  </Text>
              <TextInput
                onChangeText={rejectReason => {
                  this.setState({ rejectReason });
                }}
                multiline={true}
                numberOfLines={3}
                style={{
                  marginBottom: 20,
                  color: "#ffffff",
                  paddingLeft: 3,
                  borderRadius: 5,
                  height: 90,
                  backgroundColor: "#334"
                }}
                maxLength={500}
                underlineColorAndroid="transparent"
                defaultValue={this.state.rejectReason}
                rowSpan={3}
                bordered
              />
              <View
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 5,
                  marginBottom: 10
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    color: "#ccc",
                    paddingBottom: 10,
                    borderBottomColor: "#FFFFFF",
                    borderBottomWidth: 0,
                    marginTop: 10,
                    justifyContent: "flex-start"
                  }}
                >
                  Examples:
                    </Text>
                {this.renderTextAdder("Professional Qualification not provided")}
                {this.renderTextAdder("Verification Fee not provided")}
              </View>
              <View style={{ height: 50 }} />

              <View style={{ height: 30 }} />
              <TouchableOpacity
                style={global.gStyles.buttonXL}
                onPress={() => { this.setStatus("reject") }}
              >
                <Text style={global.gStyles.buttontXL}>REJECT</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{ alignItems: "center", marginTop: 20, marginBottom: 20 }}
                onPress={() => {
                  this.setState({ showRejectModal: false });
                }}
              >
                <Text style={{ color: "#999" }}>Cancel</Text>
              </TouchableOpacity>
              <View style={{ height: 150 }} />
            </ScrollView>
          </TouchableOpacity>
        </View>
      </Modal>
    </View>
    );
  }
}


export default class NetworkApproveScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Membership Approvals",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };

  state = {
    profileData: [],
    loading: true,
    requestStatus: "open"
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    this.getRequests("open")
  }

  getRequests = async (requestType) => {
    try {
      this.setState({ loading: true })
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "getMembershipRequests", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          requestType,
          networkID: this.props.navigation.getParam('networkID'),
          myUID: global.myUID,
          idToken: global.idToken,
        })
      });
      const r2 = await r.json();
      console.log(r2)

      if (r2.msg === "SUCCESS") {
        const profileData = r2.profileData
        console.log("Success, retreived data:", profileData)
        profileData.sort(function (a, b) {

          if (!a.requestsData) return 1
          if (!b.requestsData) return -1
          return a.requestsData[a.requestsData.length - 1].timeCreated - b.requestsData[b.requestsData.length - 1].timeCreated
        });
        this.setState({ profileData: profileData, loading: false, requestType: requestType })
      } else {
        alert("Server Error");
      }
    } catch (err) {
      console.log(err);
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
    console.log("Component Contacts will unmount");
  }

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#222" }}>
        <View style={{ backgroundColor: "#222", flex: 1 }}>
          <View style={{ ...Platform.select({ ios: { height: 42 }, android: { height: 70 } }) }} />
          <ScrollView>
            <View>
              {(!this.state.loading && this.state.profileData.length > 0) ? (
                this.state.profileData.map(d => {
                  return (
                    <Contact
                      key={d.profileID}
                      contactID={d.profileID}
                      rData={d.requestsData}
                      networkID={this.props.navigation.getParam('networkID')}
                      navigation={this.props.navigation}
                      update={() => {
                        this.setState({ loading: true })
                        this.getRequests(this.state.requestStatus)
                      }}
                    />
                  );
                })
              ) : (
                  <View
                    style={{
                      height: 300,
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    {!!this.state.loading ? (
                      <ActivityIndicator
                        size="large"
                        color="#bbc"
                        style={{
                          opacity: 1
                        }}
                        animating={true}
                      />
                    ) : (
                        <Text style={{ color: "#999" }}>No requests pending</Text>
                      )}
                  </View>
                )}
            </View>
          </ScrollView>
        </View>
      </SafeAreaView>
    );
  }
}

// ************************ HOW TO USE ***********************************************************

// Prop category1 sets the category1
// Prop category2 sets the category2
// Prop categories limit the range of sectable categories (optional).
//    -> It expects a "ServicesOffered" object (see object "categories" below)
// Prop onSelectCategory returns (category1, category2) to its parent on change (MANDATORY)
//    -> will return (null, null) when "x" is pressed.
// Prop text sets the text (default: "What are you looking for?"  (optional)
// Prop noText hides the text if true  (optional)
// Proo textSize sets the size of text (optional)
// DEPRECATED for Education Prop backgroundColor (optional, defaults to black)
// Prop backButton shows backButton instead of editButton
// Prop showButton DEPRECATED shows a "Find worker" button. DEPRECATED (Was only used by home screen. (optional)
// Prop onPressButton DEPRECATED executes when button is pressed (optional)
// Prop initialCategories DEPRECATED sets the categories (optional)
//    -> It expects {category1, category2}

import React, { Component } from "react";
import {
  Platform,
  Text,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  Dimensions
} from "react-native";

export default class CategorySelector extends Component {
  state = {
  };

  images = {
    Primary: require("../custom/Education/images/services/Primary.png"),
    Secondary: require("../custom/Education/images/services/Secondary.png"),
    Tertiary: require("../custom/Education/images/services/Tertiary.png"),
  };

  categoryMap = {
    Primary: "Teaching - Primary",
    Secondary: "Teaching - Secondary",
    Tertiary: "Teaching - Tertiary",
  };
  educationCategories = [
    "Primary",
    "Secondary",
    "Tertiary",
  ]
  reverseCategoryMap = {}

  componentDidMount() {
    //reverse map
    const obj = this.categoryMap
    var new_obj = {};
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop))
        new_obj[obj[prop]] = prop;
    }
    this.reverseCategoryMap = new_obj;
  }

  render() {
    const iWidth = Dimensions.get("window").width / 7;

    renderHomeSelection = (type) => {
      let keys = this.educationCategories
      let array;
      if (type == "newPost") array = keys.map(key => {
        return (
          <View
            key={key}
            style={{
              justifyContent: "center", alignItems: "center", width: Dimensions.get("window").width * .9, borderRadius: 20,
            }}
          >
            <TouchableOpacity
              style={{ alignItems: "center", width: "100%", flexDirection: "row", alignItems: "flex-start" }}
              onPress={() => {
                this.props.onSelectCategory("Professionals", this.categoryMap[key]);
              }}
            >
              <Image
                style={{
                  width: 35, height: 35, marginRight: 20, marginVertical: 2, borderRadius: 8
                }}
                source={this.images[key]}
              />
              <View style={{ flex: 1, marginTop: 2, }}>
                <Text style={{ color: "white", fontSize: 20, fontFamily: Platform.OS === "ios" ? 'Chalkduster' : 'normal', marginBottom: 7 }}>
                  {key + " Teacher"}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        );
      })
      else array = keys.map(key => {
        return (
          <View
            key={key}
            style={{
              justifyContent: "center", alignItems: "center", width: Dimensions.get("window").width * .9, borderRadius: 20,
            }}
          >
            <TouchableOpacity
              style={{ alignItems: "center", width: "100%", flexDirection: "row", alignItems: "flex-start" }}
              onPress={() => {
                this.props.onSelectCategory("Professionals", this.categoryMap[key]);
              }}
            >
              <Image
                style={{
                  width: 75, height: 75, marginRight: 20, marginVertical: 25, borderRadius: 20
                }}
                source={this.images[key]}
              />
              <View style={{ flex: 1, marginTop: 25, }}>
                <Text style={{ color: "white", fontSize: 20, fontFamily: Platform.OS === "ios" ? 'Chalkduster' : 'normal', marginBottom: 7 }}>
                  {key + " Teacher"}
                </Text>
                <Text style={{ color: "white", fontSize: 12, fontFamily: Platform.OS === "ios" ? 'Chalkduster' : 'normal' }}>
                  {key == "Primary" && "Hire a qualified teacher to tutor"}
                  {key == "Secondary" && "Hire a qualified teacher to tutor"}
                  {key == "Tertiary" && "Find a professor / lecturer etc"}
                </Text>
                <Text style={{ color: "white", fontSize: 12, fontFamily: Platform.OS === "ios" ? 'Chalkduster' : 'normal' }}>
                  {key == "Primary" && "your children in Primary School"}
                  {key == "Secondary" && "your children in Secondary School"}
                  {key == "Tertiary" && "for mentoring / advise / speeches"}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        );
      });
      return array;
    };

    if (!this.props.category2) return (
      <View
        style={{
          height: this.props.type == "newPost" ? 100 : Dimensions.get("window").height - 100,
          marginTop: this.props.type == "newPost" ? 80 : Platform.OS == 'ios' ? 0 : (this.props.backButton && this.props.category2) ? 24 : 0,
          justifyContent: "center",
          alignItems: "center",
          alignItems: "stretch",
        }}
      >
        <View
          style={{
            flex: 280,
            alignItems: "center"
          }}
        >
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {!this.props.noText && <Text
                style={{
                  color: "#fff",
                  fontSize: this.props.textSize ? this.props.textSize : 16,
                  fontStyle: "italic",
                  marginTop: 15
                }}
              >
                {this.props.text
                  ? this.props.text
                  : "What are you looking for?"}
              </Text>}
              <View>
                {(this.props.type != "newPost") && <Text style={{ color: "white", fontSize: Platform.OS === "ios" ? 32 : 24, fontFamily: Platform.OS === "ios" ? 'Chalkduster' : 'normal', marginBottom: 7 }}>
                  Find a...
                </Text>}
                {renderHomeSelection(this.props.type)}
                <View style={{ height: 120 }} />
              </View>
            </View>
          </View>
        </View>
      </View>
    );

    let cat2String = this.props.category2;
    if (this.props.category2 == "Teaching - Primary") cat2String = "Primary Teacher"
    if (this.props.category2 == "Teaching - Secondary") cat2String = "Secondary Teacher"
    if (this.props.category2 == "Teaching - Tertiary") cat2String = "Tertiary Teacher"

    if (this.props.category2) return (
      <View
        style={{
          height: this.props.category2 ? 75 : Dimensions.get("window").height - 20,
          marginTop: Platform.OS == 'ios' ? 0 : (this.props.backButton && this.props.category2) ? 24 : 0,
          justifyContent: "center",
          alignItems: "center",
          alignItems: "stretch",
        }}
      >
        <View
          style={{
            flex: this.props.category2 ? 160 : 280,
            alignItems: "center"
          }}
        >
          <TouchableOpacity
            onPress={() => {
              this.props.onSelectCategory(null, null);
            }}
            style={{
              flexDirection: "row",
              flex: 1,
              alignItems: "center",
              width: Dimensions.get("window").width
            }}
          >
            {!!this.props.backButton && <View
              style={{
                //flex: 11,
                alignItems: "center",
                justifyContent: "center",
                height: 25,
                width: 25,
                marginLeft: 8,
              }}
            >
              <Text style={{ fontSize: 45, color: "#fff", marginTop: -18 }}>‹</Text>
            </View>}
            <View style={{ flex: 30, alignItems: "center" }}>
              <Image
                style={{
                  width: iWidth,
                  height: iWidth,
                  margin: 2,
                  borderRadius: iWidth / 3
                }}
                source={this.images[this.reverseCategoryMap[this.props.category2]]}
              />
            </View>
            <View style={{ flex: 80 }}>
              {/* <Text style={{ color: "white", fontSize: 10 }}>
                    {this.props.category1}
                  </Text> */}
              <Text style={{ color: "white", fontSize: 18, fontFamily: Platform.OS === "ios" ? 'Chalkduster' : 'normal' }}>
                {cat2String}
              </Text>
            </View>
            {!this.props.backButton && <View
              style={{
                //flex: 11,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 18,
                height: 25,
                width: 25,
                marginRight: 13,
                backgroundColor: "#000"
              }}
            >
              {/* <Text style={{ color: "white", fontSize: 18, fontWeight: "bold" }}>X</Text> */}
              <Image
                style={{ width: 20, height: 20 }}
                source={require("../images/icons/Change.png")}
              />
            </View>}
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

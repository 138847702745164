import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  Modal,
  Image,
  ScrollView,
  SafeAreaView,
  Keyboard,
  Platform,
  ActivityIndicator
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";


class Contact extends Component {
  state = {
    // activityIndicatorAnimating: false
  };

  constructor() {
    super();
    this.unsubscribeFirestore = () => { return; };
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  callProfile = () => {
    this.props.navigation.navigate("meProfile", {
      profileID: this.props.profile.xID
    });
  }

  render() {
    return (
      <TouchableOpacity
        onPress={() => {
          this.callProfile()
        }}
      >
        <View
          style={[
            {
              flexDirection: "row",
              flex: 1,
              height: 65,
              backgroundColor: "black",
              alignItems: "center",
              paddingLeft: 11,
              paddingRight: 11,
              shadowOpacity: 0.5,
              shadowOffset: { width: 0, height: 3 },
              borderBottomColor: "#333",
              borderWidth: 1,
            }
          ]}
        >
          {!!this.props.profile.profilePictureURL ? <Image
            style={{
              width: 42,
              height: 42,
              paddingRight: 30,
              borderRadius: 4.5
            }}
            source={{
              uri: this.props.profile.profilePictureURL
            }}
          /> : <View
              style={{
                width: 42,
                height: 42,
                paddingRight: 30,
                borderRadius: 4.5,
                backgroundColor: "#444",
                borderColor: "white"
              }}
            />}
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View
              style={{
                flexDirection: "column",
                flex: 1,
                paddingHorizontal: 8
              }}
            >
              <Text style={{ fontSize: 14, color: "white" }}>
                {this.props.profile.profileName}
              </Text>
              {this.props.profile.profileNameBusiness != "" && (
                <Text style={{ fontSize: 11, color: "white" }}>
                  {this.props.profile.profileNameBusiness}
                </Text>
              )}
              <Text style={{ fontSize: 11, color: "#ccc" }}>
                {this.props.profile.profileTraderDescription}
              </Text>
            </View>
            <View>
              {/* <ActivityIndicator
                size="small"
                color="#FFFFFF"
                //animating={this.state.activityIndicatorAnimating}
                style={{
                  opacity: this.state.activityIndicatorAnimating ? 1.0 : 0.0
                }}
                animating={true}
              /> */}
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}


export default class WalletTraderScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    return {
      title: "Find ACoin Trader",
      headerTransparent: true,
      headerTintColor: "#A8CF45",
      headerTitleStyle: {
        fontWeight: "bold",
        color: "#A8CF45",
        shadowOpacity: 1
      }
    };
  };

  state = {
    traderProfiles: "LOADING",
    error: false
  };

  constructor() {
    super();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    try {
      const profileCollection = await firebase.firestore().collection("Profiles")
        .where("isActiveTrader", "==", true).where("localCurrency", "==", global.myLocalCurrency).get()
      const profiles = [];
      profileCollection.forEach(pDoc => {
        const p = pDoc.data()
        p["xID"] = pDoc.id
        profiles.push(p)
      })
      if (profiles.length < 1) this.setState({ traderProfiles: "NO_TRADER_FOUND" })
      else this.setState({ traderProfiles: profiles })
    } catch (err) {
      console.log(err)
      this.setState({ error: true })
    }
  }




  render() {
    if (this.state.error) {
      return (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "black" }}>
          <Text style={{ color: "#777" }}>Error. Try again.</Text>
        </View>
      );
    }
    if (this.state.traderProfiles == "LOADING") return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "#000" }} >
        <ActivityIndicator size="large" color="#0D6833" />
      </View>
    )
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#000000" }}>
        <View style={{ marginTop: Platform.OS == "ios" ? 50 : 85, paddingHorizontal: 25, flex: 1 }}>
          <Text
            style={{
              fontSize: 14,
              color: "#aaa",
              paddingBottom: 5,
              marginTop: 20,
              marginBottom: 5,
              fontWeight: "bold"
            }}
          >
            VERIFIED TRADERS
              </Text>

          <ScrollView>
            {this.state.traderProfiles == "NO_TRADER_FOUND" ? (
              <Text style={{ color: "gray", textAlign: "center" }}>
                No trader found in your location. Contact customer care.
                </Text>
            ) : (
                <View>
                  {this.state.traderProfiles.map((profile, i) => {
                    return (<Contact
                      key={i}
                      profile={profile}
                      navigation={this.props.navigation}
                    />)
                  }
                  )}
                </View>
              )}
          </ScrollView>
          <Text
            style={{
              position: "absolute",
              height: 150,
              bottom: 0,
              left: 0,
              fontSize: 14,
              color: "#aaa",
              padding: 15,
              marginLeft: 10,
              justifyContent: "center",

            }}
          >
            ⓘ Traders will be able to change your local currency to ACoin at the official exchange rate.
            To trade ACoin into local currency, ask them for their exchange rate which may depend on availability.
              </Text>
        </View>
      </SafeAreaView>
    );
  }
}

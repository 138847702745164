
import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  Dimensions,
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import firebase from "firebase";
import "firebase/firestore";
class MapScreen extends Component { render() { return (<View />) } };
import Post from "../../../Home/Post/Post";


export default class AroundScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Around Me",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 0
    }
  };
  state = {
    loading: true,
    //mapDelta: 0.25,
    region: {
      latitude: 6.6,
      longitude: 3.3,
      latitudeDelta: 0.1,
      longitudeDelta: 0.1
    },
    providers: [],
    offers: [],
    view1: "All",
    view2: "All",
    view3: "All",
    view3BackgroundColor: "rgba(128, 200, 128,1)",
    view3BorderColor: "rgba(102, 256, 102,1)",
    activePostID: null,
    activePostData: null,
    showMap: false,
    error: ""
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    try {
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 200); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 400); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 400); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 400); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 400); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 400); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 400); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 400); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 500); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 500); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 600); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 1000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 2000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 4000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 5000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 8000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 10000); });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 10000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 100000); });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID)
        return;

      const w11 = global.setLocationFast(10);
      const aroundResultsJSON = await AsyncStorage.getItem("AroundResults")
      const aroundResults = JSON.parse(aroundResultsJSON)
      //const aroundLocationJSON = await AsyncStorage.getItem("AroundResultsLocation")
      if (aroundResults?.providers) this.setState({ providers: aroundResults.providers })
      if (aroundResults?.offers) this.setState({ offers: aroundResults.offers })

      await w11
      if (!global.location || !global.location.coords) await new Promise(resolve => { setTimeout(resolve, 4000); });
      if (!global.location || !global.location.coords) return;

      await this.setState({
        region: {
          latitude: global.location.coords.latitude || 6.6,
          longitude: global.location.coords.longitude || 3.3,
          latitudeDelta: 0.1,
          longitudeDelta: 0.1
        },
        showMap: true
      })

      this.updateMap()

    } catch (err) {
      console.log(err)
      if (global.reportError) {
        global.reportError(err, "catches", "AroundScreen");
      }
    }
  }



  componentWillUnmount() {
    console.log("Component exploreMain will unmount");
    this.unsubscribeFirestore();
  }

  updateMap = async () => {
    try {
      const coords = (this.state.region && this.state.region.latitude && this.state.region.longitude)
        ? { latitude: this.state.region.latitude, longitude: this.state.region.longitude }
        : global.location ? global.location.coords : { latitude: 6.6, longitude: 3.3 }
      //this.lastLoadedRegion = this.state.region
      this.lastloadedDate = new Date()
      this.setState({ updating: true });
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "findExplore", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          ...((this.state.view2 == "All")
            ? { type: "All" }
            : {
              type: "Category",
              category1: this.state.view2,
              category2: this.state.view3 == "All" ? null : this.state.view3,
            }),
          coords,
          delta: this.state.region ? (this.state.region.longitudeDelta || 0.1) : 0.1,
          myUID: global.myUID,
          idToken: global.idToken,
        })
      });
      const r2 = await r.json();

      console.log("Received response from findExplore", r2.msg);
      this.setState({ updating: false });
      if (r2.msg === "SUCCESS") {
        this.setState({ providers: r2.providers || [], offers: r2.offers || [], error: "" });

        this.mostWest = this.state.offers.reduce((acc, o) => {
          if (!o.assignedLocationGeo) return acc
          return Math.min(acc, o.assignedLocationGeo._longitude);
        }, 9999);
        this.mostEast = this.state.offers.reduce((acc, o) => {
          if (!o.assignedLocationGeo) return acc
          return Math.max(acc, o.assignedLocationGeo._longitude);
        }, -9999);
        this.mostNorth = this.state.offers.reduce((acc, o) => {
          if (!o.assignedLocationGeo) return acc
          return Math.min(acc, o.assignedLocationGeo._latitude);
        }, 9999);
        this.mostSouth = this.state.offers.reduce((acc, o) => {
          if (!o.assignedLocationGeo) return acc
          return Math.max(acc, o.assignedLocationGeo._latitude);
        }, -9999);
        //console.log({ mostSouth, mostNorth, mostWest, mostEast })

        await AsyncStorage.setItem("AroundResults", JSON.stringify({ providers: r2.providers || [], offers: r2.offers || [] }))
        await AsyncStorage.setItem("AroundResultsLocation", JSON.stringify(coords))

        return;
      } else this.setState({ error: "Not updated" });
    } catch (e2) {
      this.setState({ error: "Error loading", updating: false });
      console.log(e2);
    }
  }

  changeRegion = async (region) => {
    try {
      this.lastChanged = new Date()
      await global.timeout(300)
      const now = new Date()
      if ((now - this.lastChanged) < 295) return;
      await this.setState({ region })


      if ((now - this.lastloadedDate) > 1000)
        if (region.latitude < this.mostNorth || region.latitude > this.mostSouth ||
          region.longitude < this.mistWest || region.longitude > this.mostEast)
          this.updateMap()
    } catch (e) {
      console.log("Error 23s", e)
    }
  }


  render() {

    return (
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: global.app == "health" ? "#fff" : "#131313"
        }}
      >
        <StatusBar barStyle="light-content" />

        <View
          style={{
            backgroundColor: "#131313",
            flex: 1
          }}
        >
          <View
            style={{
              backgroundColor: "#131313",
              borderBottomColor: "#555",
              borderBottomWidth: 1,
              ...Platform.select({
                ios: {
                  height: 55
                },
                android: {
                  height: 75
                }
              })
            }}
          />
          {this.state.showMap && <View>
            <MapView
              style={{
                width: Dimensions.get('window').width,
                height: Dimensions.get('window').height - 150,
              }}
              //provider = { MapView.PROVIDER_GOOGLE }
              customMapStyle={generatedMapStyle}
              showUserLocation={true}
              initialRegion={this.state.region}
              onRegionChange={this.changeRegion}

            // latitudeDelta: 0.0922/5,
            // longitudeDelta: 0.0421/5}}
            >

              {["All", "Providers"].includes(this.state.view1) && this.state.providers.map(marker => {
                const delta = this.state.region ? this.state.region.longitudeDelta || 0.1 : 0.1
                const deltaFactor = Math.min(3, 0.1 / delta)
                const SIZE = Math.max(24, (Math.min(44, 44 * 5 / 100 * deltaFactor * (marker.trustScore || 0))))

                const lat = marker.locationWork._latitude
                const lon = marker.locationWork._longitude

                let showPicture = false
                if (this.state.view1 == "Providers" && SIZE >= 8 &&
                  (lat <= (this.state.region.latitude + this.state.region.latitudeDelta / 1.9)
                    && lat >= (this.state.region.latitude - this.state.region.latitudeDelta / 1.9)
                    && lon <= (this.state.region.longitude + this.state.region.longitudeDelta / 1.9)
                    && lon >= (this.state.region.longitude - this.state.region.longitudeDelta / 1.9))) showPicture = true
                return (<MapView.Marker
                  key={marker.xID}
                  //title ={marker.title}
                  //pinColor ={marker.color}
                  //description = {marker.description}
                  //coordinate={{ latitude: 6.63, longitude: 3.33 }}
                  coordinate={{ latitude: marker.locationWork._latitude, longitude: marker.locationWork._longitude }}
                  onPress={
                    () => {
                      this.props.navigation.navigate("homeProfile", { profileID: marker.xID })
                    }
                  }
                >
                  <View>
                    {showPicture ? <View style={{ width: 42, marginRight: 8 }}>
                      {!!marker.profilePictureURL &&
                        <View>
                          <View
                            style={{
                              marginTop: 5,
                              width: SIZE,
                              height: SIZE,
                              paddingRight: 8,
                              borderRadius: SIZE / 2,
                              backgroundColor: "white"
                            }} />
                          <Image
                            style={{
                              marginTop: -(SIZE - 1),
                              marginLeft: 1,
                              width: (SIZE - 2),
                              height: (SIZE - 2),
                              paddingRight: 8,
                              borderRadius: (SIZE - 2) / 2
                            }}
                            source={{ uri: marker.profilePictureURL }} /></View>
                      }
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: -5
                        }}
                      >
                      </View>
                    </View>
                      : <View style={{ width: 20, height: 20, justifyContent: "center", alignItems: "center" }}>
                        {marker.trustScore > 0 ? <View style={{ width: 8, height: 8, borderRadius: 4, backgroundColor: 'rgba(128, 128,200,1)' }} />
                          : <View style={{ width: 4, height: 4, borderRadius: 2, backgroundColor: 'rgba(128, 128,200,1)' }} />}
                      </View>}
                  </View>
                </MapView.Marker>)
              })}

              {["All", "Offers"].includes(this.state.view1) && this.state.offers.map(marker => {
                const delta = this.state.region ? this.state.region.longitudeDelta || 0.1 : 0.1
                const deltaFactor = Math.min(3, 0.1 / delta)
                let SIZE = Math.max(0, (Math.min(63, 63 * 3 * deltaFactor * (marker.score || 0))))
                const lat = marker.assignedLocationGeo._latitude
                const lon = marker.assignedLocationGeo._longitude

                if (lat <= (this.state.region.latitude + this.state.region.latitudeDelta / 1.9)
                  && lat >= (this.state.region.latitude - this.state.region.latitudeDelta / 1.9)
                  && lon <= (this.state.region.longitude + this.state.region.longitudeDelta / 1.9)
                  && lon >= (this.state.region.longitude - this.state.region.longitudeDelta / 1.9))
                  return (<MapView.Marker
                    key={marker.xID}
                    tracksInfoWindowChanges={false}
                    //title ={marker.title}
                    //pinColor ={marker.color}
                    //description = {marker.description}
                    // image={{uri: marker.postPictureURL}}
                    //coordinate={{latitude: 6.63, longitude:3.33}}
                    coordinate={{
                      latitude:
                        marker.assignedLocationGeo._latitude,// + Math.random() / 400,
                      longitude:
                        marker.assignedLocationGeo._longitude,// + Math.random() / 400
                    }}

                    onPress={() => {
                      this.setState({ activePostID: marker.xID, activePostData: marker })
                      // this.props.navigation.navigate("homeProfile", {
                      //   profileID: marker.authorProfileID
                      // });
                    }}
                  >
                    <View>
                      <View style={{ width: SIZE, marginRight: 8 }}>
                        {!!marker.postPictureURL && (
                          <View>
                            {SIZE > 10 ? <Image
                              style={{
                                marginTop: 0,
                                marginLeft: 0,
                                width: SIZE,
                                height: SIZE,
                                paddingRight: 0,
                                borderRadius: SIZE / 6
                              }}
                              source={{ uri: marker.postPictureURL }}
                            /> : <View style={{ width: 5, height: 5, borderRadius: 10, backgroundColor: "rgba(200, 128, 128,1)" }} />}
                          </View>
                        )}
                      </View>
                    </View>
                  </MapView.Marker>)
              })}

              <MapView.Marker
                title='you'
                pinColor='green'
                description="your location"
                key={"centerMarker"}
                coordinate={global.location ? (global.location.coords || { latitude: 6.5, longitude: 3.3 }) : { latitude: 6.5, longitude: 3.3 }}
              >
              </MapView.Marker>
            </MapView>

            {!!this.state.activePostID && <Post
              postID={this.state.activePostID}
              data={this.state.activePostData}
              onHideDetail={() => {
                this.setState({ activePostID: null });
              }}
              key={this.state.activePostID}
              navigation={this.props.navigation}
              relevantStatus={"LIVE"}
              onIsNotRelevant={() => {
                console.log(
                  "Check this!! onIsNotRelevant is called in posts.js"
                );
                //this.removePost(2);
              }}
            />}

          </View>}
          <View style={{
            position: "absolute", top: 75, left: 5, alignSelf: "stretch",
            height: 40, alignItems: "center", flexDirection: "row"
          }}>
            <ScrollView horizontal showsHorizontalScrollIndicator={false} contentContainerStyle={{ alignItems: "center" }}>
              {["All", "Food", "Fashion", "Beauty"].map((c, i) => {
                const sel = c == this.state.view2
                let backgroundColor = "rgba(128, 200, 128,1)", borderColor = "rgba(102, 256, 102,1)"
                if (i == 1) { backgroundColor = "rgba(172, 172, 112,1)"; borderColor = "rgba(196, 196, 86,1)" }
                if (i == 2) { backgroundColor = "rgba(128, 156, 172,1)"; borderColor = "rgba(102, 128,256,1)" }
                if (i == 3) { backgroundColor = "rgba(156, 128, 172,1)"; borderColor = "rgba(128, 102,256,1)" }
                if (i == 4) { backgroundColor = "rgba(156, 172, 128,1)"; borderColor = "rgba(128, 256,102,1)" }
                if (i == 5) { backgroundColor = "rgba(128, 172, 156,1)"; borderColor = "rgba(102,256, 128,1)" }
                if (i == 6) { backgroundColor = "rgba(172, 128, 156,1)"; borderColor = "rgba(256,102, 128,1)" }
                if (i == 7) { backgroundColor = "rgba(172, 156, 128,1)"; borderColor = "rgba(256, 128,102,1)" }
                if (i == 8) { backgroundColor = "rgba(156, 128, 172,1)"; borderColor = "rgba(128, 102,256,1)" }
                if (i == 9) { backgroundColor = "rgba(156, 172, 128,1)"; borderColor = "rgba(128, 256,102,1)" }
                if (i == 10) { backgroundColor = "rgba(128, 172, 156,1)"; borderColor = "rgba(102,256, 128,1)" }
                if (i == 11) { backgroundColor = "rgba(172, 128, 156,1)"; borderColor = "rgba(256,102, 128,1)" }
                if (i == 12) { backgroundColor = "rgba(172, 156, 128,1)"; borderColor = "rgba(256, 128,102,1)" }

                return (
                  <TouchableOpacity key={c} onPress={async () => {
                    await this.setState({
                      view2: c,
                      view3: "All",
                      view3BackgroundColor: backgroundColor,
                      view3BorderColor: borderColor
                    });
                    this.updateMap()
                  }}>
                    <View style={{
                      width: sel ? 75 : 60, height: sel ? 40 : 30, borderWidth: sel ? 5 : 0, borderColor,
                      borderRadius: 20, backgroundColor,
                      justifyContent: "center", alignItems: "center", marginHorizontal: 2,
                    }}>
                      <Text style={{ color: "#fff", fontWeight: "bold", fontSize: 13 }}>{c}</Text>
                    </View>
                  </TouchableOpacity>)
              })}
            </ScrollView>
          </View>

          {this.state.view2 != "All" && <View style={{
            position: "absolute", top: 125, left: 5, alignSelf: "stretch",
            height: 40, alignItems: "center", flexDirection: "row"
          }}>
            <ScrollView horizontal showsHorizontalScrollIndicator={false} contentContainerStyle={{ alignItems: "center" }}>
              {([...["All"], ...(global.categories ? global.categories[this.state.view2] || [] : [])]).map((c, i) => {
                const sel = c == this.state.view3
                return (
                  <TouchableOpacity key={c} onPress={async () => {
                    await this.setState({ view3: c });
                    this.updateMap()
                  }}>
                    <View style={{
                      paddingHorizontal: 2, minWidth: 40, height: sel ? 40 : 30, borderWidth: sel ? 5 : 0, borderColor: this.state.view3BorderColor,
                      borderRadius: 20, backgroundColor: this.state.view3BackgroundColor,
                      justifyContent: "center", alignItems: "center", marginHorizontal: 2,
                    }}>
                      <Text style={{ color: "#fff", fontWeight: "bold", fontSize: 13 }}>{c}</Text>
                    </View>
                  </TouchableOpacity>)
              })}
            </ScrollView>
          </View>}

          {this.state.updating ? <View style={{
            position: "absolute", top: this.state.view2 == "All" ? 125 : 175, left: 0, width: Dimensions.get('window').width,
            height: 40, alignItems: "center", justifyContent: "center", flexDirection: "row"
          }}>
            <ActivityIndicator size="small" color="#909099" animating={true} />
            <Text style={{ color: "#909099", marginLeft: 8 }}>Refreshing</Text>
          </View>
            : !!this.state.error && <View style={{
              position: "absolute", top: this.state.view2 == "All" ? 125 : 175, left: 0, width: Dimensions.get('window').width,
              height: 40, alignItems: "center", justifyContent: "center", flexDirection: "row"
            }}>
              <Text style={{ color: "#909099", marginLeft: 8 }}>{this.state.error}</Text>
            </View>}

          <View style={{
            position: "absolute", bottom: 15, left: 5, alignSelf: "stretch",
            height: 40, alignItems: "center", flexDirection: "row"
          }}>
            <TouchableOpacity
              onPress={() => { this.setState({ view1: "All" }) }}>
              <View style={{
                width: 100, height: 30, borderWidth: 0,
                ...(this.state.view1 == "All" && { width: 110, height: 40, borderWidth: 5, }),
                borderColor: "rgba(102, 256, 102,1)",
                borderRadius: 20, backgroundColor: "rgba(128, 200, 128,1)",
                justifyContent: "center", alignItems: "center", marginHorizontal: 5,
              }}>
                <Text style={{ color: "#fff", ...(this.state.view1 == "All" && { fontWeight: "bold" }), fontSize: 16 }}>All</Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => { this.setState({ view1: "Offers" }) }}>
              <View style={{
                width: 100, height: 30, borderWidth: 0,
                ...(this.state.view1 == "Offers" && { width: 110, height: 40, borderWidth: 5, }),
                borderColor: "rgba(256,102,102,1)",
                borderRadius: 20, backgroundColor: "rgba(200, 128, 128,0.95)",
                justifyContent: "center", alignItems: "center", marginHorizontal: 5,
              }}>
                <Text style={{ color: "#fff", ...(this.state.view1 == "Offers" && { fontWeight: "bold" }), fontSize: 16 }}>Offers</Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => { this.setState({ view1: "Providers" }) }}>
              <View style={{
                width: 100, height: 30, borderWidth: 0,
                ...(this.state.view1 == "Providers" && { width: 110, height: 40, borderWidth: 5, }),
                borderColor: "rgba(102,102,256,1)",
                borderRadius: 20, backgroundColor: "rgba(128, 128,200,0.95)",
                justifyContent: "center", alignItems: "center", marginHorizontal: 5,
              }}>
                <Text style={{ color: "#fff", ...(this.state.view1 == "Providers" && { fontWeight: "bold" }), fontSize: 16 }}>Providers</Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </SafeAreaView >
    );
  }
}

const generatedMapStyle = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#242f3e"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#746855"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#242f3e"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#d59563"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#d59563"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#263c3f"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#6b9a76"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#38414e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#212a37"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9ca5b3"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#746855"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#1f2835"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#f3d19c"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#2f3948"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#d59563"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#17263c"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#515c6d"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#17263c"
      }
    ]
  }
];
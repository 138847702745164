//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
// everything is an EXACT copy from Wakanda Market and "synced"
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

import React, { Component } from "react";
import {
  Text,
  View,
  TextInput,
  Image,
  ImageBackground,
  TouchableOpacity,
  ActivityIndicator,
  Keyboard,
  Platform,
  Linking,
  Modal,
  Dimensions,
  SectionList,
  TouchableWithoutFeedback,
} from "react-native";
import BOGuide from "./shared/Guide/BOGuide"
import { NavigationEvents } from "react-navigation";


export default class WelcomeScreenBO extends Component {
  static navigationOptions = {
    tabBarVisible: false
  };

  state = {
  };

  async componentDidMount() {
    this.keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      this._keyboardDidShow
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      this._keyboardDidHide
    );
  }

  componentWillUnmount() {
    console.log("Component signin will unmount");

    if (this.keyboardDidShowListener) this.keyboardDidShowListener.remove();
    if (this.keyboardDidHideListener) this.keyboardDidHideListener.remove();
  }

  _keyboardDidShow = async () => {
    console.log("Keyboard Shown");
    if (Platform.OS === "ios") {
      this.setState({ keyboardSpacerHeight: 100 });
    }
  };

  _keyboardDidHide = () => {
    console.log("Keyboard Hidden");
    this.setState({ keyboardSpacerHeight: 0 });
  };


  render() {
    return (
      <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
        <View style={{ flex: 1, backgroundColor: "black" }}>
          <View style={{ flex: 1 }}>
            <Image
              style={{
                width: 450, height: 360, margin: 70, opacity: 1, position: "absolute", top: 5, left: 5,
              }}
              source={require("./images/logos/home_logo.png")}
            />
            <BOGuide type="welcome"
              onDone={() => { console.log("ondone"); this.props.navigation.navigate("signin") }}
            />
          </View>
          <NavigationEvents
            onDidFocus={async payload => {
              this.setState({ showBO: true })
            }}
          />
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

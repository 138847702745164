import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  Platform,
  ActivityIndicator
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";



class Contact extends Component {
  state = {
    contactProperties: {
      opponentProfileID: null,
      opponentName: "********",
      opponentNameBusiness: "************",
      opponentProfilePictureURL: null
    },
    nOfReferrals: -1,
    activityIndicatorAnimating: false,
    tmpFollowing: false,
    visible:true
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  componentDidMount() {
    this.unsubscribeFirestore = firebase
      .firestore()
      .collection("Profiles")
      .doc(this.props.contactID)
      .onSnapshot(this.updateContact);

    this.setState({
      nOfReferrals: Object.keys(this.props.rData).length - 1
    })
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  updateContact = async contactSnapshot => {
    console.log("Update of list of contacts triggered. (Contacts Screen)");
    try {
      let contact = contactSnapshot.data();

      let opponentProfileID = contactSnapshot.id;
      let opponentName = contact.profileName;
      let opponentProfilePictureURL = contact.profilePictureURL;
      let opponentNameBusiness = contact.profileNameBusiness;
      if (!contact.profileNameBusiness) {
        opponentNameBusiness = "";
      }

      let contactProperties = {
        opponentProfileID,
        opponentName,
        opponentNameBusiness,
        opponentProfilePictureURL
      };

      const country = this.props.navigation.getParam("country", null)
      if (country == "NGN") {
        if (contact.localCurrency && country != contact.localCurrency) {
          this.setState({ visible: false })
        }
      } else
        if (!contact.localCurrency || country != contact.localCurrency) {
          this.setState({ visible: false })
        }

      //console.log("contactProperties", contactProperties);
      this.setState({
        contactProperties
      });
    } catch (err) {
      console.log("Slow internet. Could not fetch contacts (meContact)", err);
      alert("Could not fetch contacts. Check your internet connection");
    }
  };



  render() {
    let referralsThisMonth = 0
    let referralsLastMonth = 0
    let referralsRest = 0
    let nowDate = new Date()
    let nowMonth = nowDate.getMonth()
    let nowYear = nowDate.getFullYear()
    let lastMonth = nowMonth == 0 ? 11 : nowMonth - 1
    let lastYear = nowMonth == 0 ? nowYear - 1 : nowYear
    let lastlastMonth = lastMonth == 0 ? 11 : lastMonth - 1
    let lastlastYear = lastMonth == 0 ? lastYear - 1 : lastYear
    let keys = Object.keys(this.props.rData)

    keys.map(key => {
      console.log("KEY", key)
      if (key != "xID") {
        console.log(key, this.props.rData[key])
        const dat = new Date(this.props.rData[key])
        const m = dat.getMonth()
        const y = dat.getFullYear()
        if (m == nowMonth && y == nowYear) referralsThisMonth += 1
        else if (m == lastMonth && y == lastYear) referralsLastMonth += 1
        else referralsRest += 1
      }
    })

    if (!this.state.visible)return(<View style={{}}/>)

    return (
      <TouchableOpacity
        onPress={() => {
          if (!this.state.contactProperties.opponentProfileID) {
            return
          }
          let handoverID = this.state.contactProperties.opponentProfileID;
          this.props.navigation.navigate("meProfile", {
            profileID: handoverID
          });
        }}
      >
        <View
          style={[
            {
              flexDirection: "row",
              flex: 1,
              height: 86,
              backgroundColor: "black",
              alignItems: "center",
              paddingLeft: 11,
              paddingRight: 11,
              shadowOpacity: 0.5,
              shadowOffset: { width: 0, height: 3 },
              borderBottomColor: "#333",
              borderBottomWidth: 1
            }
          ]}
        >
          {!!this.state.contactProperties.opponentProfilePictureURL ? (
            <Image
              style={{ width: 42, height: 42, borderRadius: 4 }}
              source={{
                uri: this.state.contactProperties.opponentProfilePictureURL
              }}
            />
          ) : (
              <View style={{ width: 42, height: 42, borderRadius: 4, backgroundColor: "#000" }} />
            )}
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View
              style={{
                flexDirection: "column",
                flex: 1,
                paddingHorizontal: 8
              }}
            >
              <Text style={{ fontSize: 14, color: "white", marginBottom: 4 }}>
                {this.state.contactProperties.opponentName}
              </Text>
              {/* {this.state.contactProperties.opponentNameBusiness != "" && (
                <Text style={{ fontSize: 11, color: "white" }}>
                  {this.state.contactProperties.opponentNameBusiness}
                </Text>
              )} */}
              <Text style={{ fontSize: 10, color: "#008866" }}>
                Signups: {this.state.nOfReferrals}
              </Text>
              <Text style={{ fontSize: 10, color: "#008866" }}>
                ...this month: {referralsThisMonth}
              </Text>
              <Text style={{ fontSize: 10, color: "#008866" }}>
                ...last month: {referralsLastMonth}
              </Text>
              <Text style={{ fontSize: 10, color: "#008866" }}>
                ...earlier: {referralsRest}
              </Text>

            </View>
          </View>
          <TouchableOpacity
            onPress={() => this.props.navigation.navigate("referrerDashboardDetail", { rData: this.props.rData, name: this.state.contactProperties.opponentName })}
            style={{ height: 50, width: 50, alignItems: "center", justifyContent: "center" }}>
            <Text style={{ color: "white", fontSize: 34 }}>></Text></TouchableOpacity>
        </View>
      </TouchableOpacity>
    );
  }
}



export default class ReferrerDashboardScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Signup Dashboard",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };
  state = {
    data: [],
    loading: true
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    try {
      this.setState({ activityIndicatorAnimating: true });

      const r = await fetch(global.cloudFunctionURL + "getReferrerStats", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          myUID: global.myUID,
          idToken: global.idToken,
        })
      });
      const r2 = await r.json();
      this.setState({ activityIndicatorAnimating: false });


      if (r2.msg === "SUCCESS") {
        console.log("Received Success, result:", r2.data)
        this.setState({ data: r2.data, loading: false })
      } else {
        alert("Server Error");
      }
    } catch (err) {
      console.log(err);
      this.setState({ activityIndicatorAnimating: false });
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
    console.log("Component Contacts will unmount");
  }


  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#222" }}>
        <View style={{ backgroundColor: "#222", flex: 1 }}>
          <View
            style={{
              height: 40
            }}
          />
          {/* <Selector
            view={this.state.viewSelection}
            onPress={viewSelection => {
              this.setState({ viewSelection });
            }}
          /> */}
          <ScrollView>
            <View>
              {this.state.data.length > 0 ? (
                this.state.data.map(r => {
                  return (
                    <Contact
                      key={r.xID}
                      contactID={r.xID}
                      rData={r}
                      navigation={this.props.navigation}
                    />
                  );
                })
              ) : (
                  <View
                    style={{
                      height: 300,
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    {!!this.state.loading ? (
                      <ActivityIndicator
                        size="large"
                        color="#008866"
                        style={{
                          opacity: 1
                        }}
                        animating={true}
                      />
                    ) : (
                        <Text style={{ color: "#999" }}>No referrers yet</Text>
                      )}
                  </View>
                )}
            </View>
          </ScrollView>
        </View>
      </SafeAreaView>
    );
  }
}

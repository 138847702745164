import React, { Component } from 'react'
import { Text, View, StyleSheet, ActivityIndicator, Image, ScrollView, TouchableOpacity, Dimensions } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient'

export default class JobScreen5 extends Component {
    state = {
        total: 0,
        deliveryAddress: "deliveryAddress",
        addressDescription: " addressDescription",
        itemTitle: "itemTitle",
        itemDescription: "itemDescription",
        currency: '',
        installment1: 0,
        deliveryFee: 0
    }


    componentDidMount = async () => {

        await this.setState({
            total: this.props.totalCost,

            deliveryAddress: this.props.deliveryAddress,
            addressDescription: this.props.addressDescription,
            itemTitle: this.props.itemTitle,
            itemDescription: this.props.itemDescription,
            installment1: this.props.installment1,
            deliveryFee: this.props.deliveryFee
        })
    }

    renderPriceTable = (position) => {
        //prices in currency
        const inst1 = this.props.installment1 || 0
        const inst2 = this.props.totalCost - inst1 - this.props.deliveryFee
        const charge = inst1 + inst2
        const delivery = this.props.deliveryFee || 0
        const total = charge + delivery
        const cur = global.currencySymbols[this.props.currency]

        //prices in local currency
        const showACN = global.walletType == "ACN" && this.props.currency == "ACN"
        let inst1L, inst2L, chargeL, deliveryL, totalL, curL
        if (showACN) {
            inst1L = this.localAmount(inst1)
            inst2L = this.localAmount(inst2)
            chargeL = this.localAmount(charge)
            deliveryL = this.localAmount(delivery)
            totalL = this.localAmount(total)
            curL = global.currencySymbols[global.myLocalCurrency]
        }

        if (position == 'details') {
            if (this.state.jobProperties.systemStatusJob == 'ONGOING' && this.state.amCustomer) {
                return (
                    <View style={{}} />)
            }
        }

        return (
            <View style={{ width: showACN ? "90%" : "55%", marginVertical: 16, marginLeft: 16 }}>
                {inst1 == 0 && <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "90%" }}>
                    <Text style={{ color: '#c0c0cc', fontSize: 11, padding: 3, width: "45%" }}>Charge</Text>
                    <View style={{ width: showACN ? "30%" : "55%" }}>
                        <Text style={{ color: '#c0c0cc', fontSize: 11, padding: 3, width: "100%", textAlign: 'right', }}>{charge} {cur}</Text>
                    </View>
                    {showACN && <View style={{ width: "25%", backgroundColor: "#c0c0cc", borderRadius: 10, margin: 2, marginTop: -4, }}><Text style={{ color: '#000', fontSize: 11, padding: 1, paddingRight: 5, textAlign: 'right', }}>{chargeL} {curL}</Text></View>}
                </View>}

                {inst1 > 0 && <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "90%" }}>
                    <Text style={{ color: '#c0c0cc', fontSize: 11, padding: 3, width: "45%" }}>1st Installment</Text>
                    <View style={{ width: showACN ? "30%" : "55%" }}>
                        <Text style={{ color: '#c0c0cc', fontSize: 11, padding: 3, width: "100%", textAlign: 'right', }}>{inst1} {cur}</Text>
                    </View>
                    {showACN && <View style={{ backgroundColor: "#c0c0cc", borderRadius: 10, margin: 2, marginTop: -4, width: "25%" }}><Text style={{ color: '#000', fontSize: 11, padding: 1, paddingRight: 5, textAlign: 'right', }}>{inst1L} {curL}</Text></View>}
                </View>}

                {inst1 > 0 && <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "90%" }}>
                    <Text style={{ color: '#c0c0cc', fontSize: 11, padding: 3, width: "45%" }}>2nd Installment</Text>
                    <View style={{ width: showACN ? "30%" : "55%" }}>
                        <Text style={{ color: '#c0c0cc', fontSize: 11, padding: 3, width: "100%", textAlign: 'right' }}>{inst2} {cur}</Text>
                    </View>
                    {showACN && <View style={{ backgroundColor: "#c0c0cc", borderRadius: 10, margin: 2, marginTop: -4, width: "25%" }}><Text style={{ color: '#000', fontSize: 11, padding: 1, paddingRight: 5, textAlign: 'right', }}>{inst2L} {curL}</Text></View>}
                </View>}
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "90%" }}>
                    <Text style={{ color: '#c0c0cc', fontSize: 11, padding: 3, width: "45%" }}>Delivery Fee</Text>
                    <View style={{ width: showACN ? "30%" : "55%" }}>
                        <Text style={{ color: '#c0c0cc', fontSize: 11, padding: 3, width: "100%", textAlign: 'right' }}>{delivery} {cur}</Text>
                    </View>
                    {showACN && <View style={{ backgroundColor: "#c0c0cc", borderRadius: 10, margin: 2, marginTop: -4, width: "25%" }}><Text style={{ color: '#000', fontSize: 11, padding: 1, paddingRight: 5, textAlign: 'right', }}>{deliveryL} {curL}</Text></View>}
                </View>
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "90%", borderTopWidth: 0.5, borderTopColor: '#909099', paddingTop: 7 }}>
                    <Text style={{ color: '#c0c0cc', fontSize: 11, padding: 3, width: "45%", fontWeight: "bold" }}>Total</Text>
                    <View style={{ width: showACN ? "30%" : "55%" }}>
                        <Text style={{ color: '#c0c0cc', fontSize: 11, padding: 3, width: "100%", textAlign: 'right', fontWeight: "bold" }}>{total} {cur}</Text>
                    </View>
                    {showACN && <View style={{ backgroundColor: "#c0c0cc", borderRadius: 10, margin: 2, marginTop: -4, width: "25%" }}><Text style={{ color: '#000', fontSize: 11, padding: 1, paddingRight: 5, textAlign: 'right', }}>{totalL} {curL}</Text></View>}
                </View>
            </View>
        )
    }
    localAmount = (amountACN) => {
        let localAmount = amountACN * global.xRates[global.myLocalCurrency]
        return Math.round(localAmount)
        //return Math.round(localAmount * 100) / 100
        //return localAmount
    }

    render() {
        const screenWidth = Math.round(Dimensions.get('window').width);
        const currency = global.currencySymbols[this.props.currency];
        let showItem = false;
        if (this.props.itemDescription || this.props.itemTitle) showItem = true
        return (
            <View style={{ flex: 1, alignItems: 'flex-start', width: screenWidth }}>
                <ScrollView>
                    <View style={{ paddingVertical: 20, alignItems: 'center', top: 10 }}>
                        <Text style={styles.text}>
                            Order Summary
                    </Text>
                        <Text style={styles.text2}>Details</Text>
                        <Text style={styles.text3}>{this.props.jobDescription || "-"}</Text>
                        {!!this.props.pictureURL && <View style={{
                            height: 100, width: 100, alignSelf: 'center', backgroundColor: 'black',
                            borderRadius: 10, marginTop: 10
                        }}>
                            <Image source={{ uri: this.props.pictureURL }}
                                style={{
                                    height: 100, width: 100, alignSelf: 'center', borderRadius: 10
                                }} />
                        </View>}
                        {showItem ? <Text style={styles.text3}>{this.props.itemTitle || this.props.itemDescription}</Text> : <View style={{ height: 20 }} />}
                        {!!this.props.itemQty && <Text style={styles.text3}>Quantity: {this.props.itemQty}</Text>}
                        <Text style={styles.text2}>Total Charge</Text>
                        {(this.props.deliveryFee == 0 && this.props.installment1 == 0) ? <View><Text style={styles.text3}>{currency} {this.props.totalCost}</Text>{this.props.currency == "ACN" && <Text style={styles.text3}>≈ {global.currencySymbols[global.myLocalCurrency]} {this.localAmount(this.props.totalCost)}</Text>}</View> : this.renderPriceTable()}
                        <Text style={styles.text2}>Delivery Address</Text>
                        <Text style={styles.text3}>{this.props.deliveryAddress || '/ / none provided'} {"//"} {this.props.addressDescription}</Text>
                    </View>
                    <View style={{ alignItems: 'center', width: '100%' }}>
                        {!this.props.activityIndicatorAnimating ? <TouchableOpacity
                            onPress={() => { this.props.orderNow() }}>
                            <LinearGradient
                                colors={['#707070', '#282828']}
                                style={{
                                    width: 100, borderWidth: 1, borderColor: '#00FF99', borderRadius: 30,
                                    height: 60, justifyContent: 'center', marginTop: 10, alignItems: 'center',
                                    backgroundColor: '#00FF99'
                                }}>
                                <Text style={styles.text4} style={{ color: '#00FF99' }}>Order Now</Text>
                            </LinearGradient>
                        </TouchableOpacity> : <View style={{ width: 100, height: 60, alignItems: 'center', justifyContent: 'center' }}>
                                <ActivityIndicator size="small" color="#ffff00" animating={true} />
                            </View>}
                        <View style={{ height: 350 }} />
                    </View>
                    <View style={styles.bottom}>
                        <Text style={styles.text4}>
                            See order summary here. You can go back to make changes to any of the elements or send the order.
                </Text>
                    </View>
                </ScrollView>
            </View>
        )
    }
}


const styles = ({
    text: {
        color: '#fff',
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        paddingHorizontal: 20

    },
    text2: {
        color: '#ffff00',
        fontSize: 15,
        textAlign: 'center',
        fontWeight: 'bold',
        paddingHorizontal: 30,
        paddingTop: 10

    },
    text3: {
        color: '#bbb',
        fontSize: 14,
        textAlign: 'center',
        paddingHorizontal: 30,
        paddingVertical: 5

    },
    text4: {
        color: 'grey',
        fontSize: 14,
        textAlign: 'center',
        paddingVertical: 10,
        paddingHorizontal: 30

    },
    bottom: {
        marginTop: 30,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "#333"
    }

})

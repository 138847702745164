import React, { Component } from "react";
import {
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Image,
  Dimensions,
  ActivityIndicator
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import Moment from "react-moment";
import * as Location from 'expo-location';

import Post from "./Post";
import SwipeableView from "./SwipeableView";
import FadeInView from "./FadeInView";

export default class Posts extends Component {
  constructor() {
    super();
    // const firestore = firebase.firestore();
    // const fSettings = { timestampsInSnapshots: true };
    // firestore.settings(fSettings);
  }

  state = {
    posts: [],
    // posts: [
    //   "22prf7sBkOJo7Z2FuBFG",
    //   "Ru5NFZmkjA68O5H8quyn",
    //   "P8pbK75TfIdxx9fYyXh5",
    //   "22prf7sBkOJo7Z2FuBFG",
    //   "Ru5NFZmkjA68O5H8quyn",
    //   "22prf7sBkOJo7Z2FuBFG",
    //   "P8pbK75TfIdxx9fYyXh5",
    //   "Ru5NFZmkjA68O5H8quyn",
    //   "22prf7sBkOJo7Z2FuBFG",
    //   "Ru5NFZmkjA68O5H8quyn",
    //   "22prf7sBkOJo7Z2FuBFG",
    //   "P8pbK75TfIdxx9fYyXh5",
    //   "Ru5NFZmkjA68O5H8quyn",
    //   "22prf7sBkOJo7Z2FuBFG",
    //   "Ru5NFZmkjA68O5H8quyn"
    // ],
    showPostDetail: false,
    showLove: false
    //browseAction: "SWIPE"
  };

  async componentDidMount() {
    // const tmpArray = this.props.newResultArray.concat(
    //   this.props.likedResultArray
    // );
    // this.setState({ posts: tmpArray });
    this.setState({ posts: this.props.newResultArray });
  }

  componentWillUnmount() {
    //this.unsubscribeFirestore();
  }

  displayAction = async direction => {
    if (direction == "right") {
      this.setState({ showLove: true });
      await new Promise(resolve => {
        setTimeout(resolve, 1000);
      });
      this.setState({ showLove: false });
    } else {
      this.setState({ showDislike: true });
      await new Promise(resolve => {
        setTimeout(resolve, 1000);
      });
      this.setState({ showDislike: false });
    }
  };

  nextCard = async direction => {
    //alert("Next card")

    let posts = this.state.posts;
    const postToUpdate = posts.shift();
    if (direction == "skip" && postToUpdate) {
      posts.push(postToUpdate);
    }
    this.setState({ posts });
    console.log("Updating swipes for ", postToUpdate.xID);

    let action = "";
    if (direction == "right") { //  SwipeRight
      action = "like"
    }
    if (direction == "left") { //   SwipeLeft
      action = "dislike"
    }
    if (direction == "skip") return;

    try {
      const r = await fetch(global.cloudFunctionURL + "likePost", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action,
          postID: postToUpdate.xID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
    } catch (e) {
      global.warn(error, "likePostSwipe")
    }
  };

  removePost = i => {
    let posts = this.state.posts;
    postRemoved = posts.splice(i, 1);
    this.setState({ posts });
    console.log("Removed post ", postRemoved.xID);
  };

  render() {
    const windowHeight = Dimensions.get("window").height;
    let x300 = windowHeight >= 680 ? 300 : 280;
    let likedTop = windowHeight >= 650 ? 115 : 75;
    if (windowHeight > 725) likedTop = 175;
    // let noNewOffers = "No new offers in this category";
    // if (!this.props.category2) {
    //   noNewOffers = "No new offers";
    // }
    let noNewOffers = "Reload page to see more.";
    if (global.app == "speakers") {
      noNewOffers = "No work samples near you found"
    }
    if (this.props.category2) {
      x300 = 300;
    }
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          width: "100%"
        }}
      >
        {this.state.posts.length >= 3 && (
          <View
            style={{
              width: 300,
              height: 300,
              marginBottom: -300,
              borderRadius: 30,
              backgroundColor: "#455",
              transform: x300 == 300 ? [] : [{ scale: x300 / 300 }]
              // transform:[{scale:.5}]
            }}
          >
            <Post
              postID={this.state.posts[2].xID}
              data={this.state.posts[2]}
              key={this.state.posts[2].xID}
              navigation={this.props.navigation}
              showDetail={false}
              relevantStatus={"LIVE"}
              onIsNotRelevant={() => {
                console.log(
                  "Check this!! onIsNotRelevant is called in posts.js"
                );
                //this.removePost(2);
              }}
            />
          </View>
        )}
        {this.state.posts.length >= 2 && (
          <View
            style={{
              width: 300,
              height: 300,
              marginBottom: -300,
              borderRadius: 30,
              backgroundColor: "#455",
              transform: x300 == 300 ? [] : [{ scale: x300 / 300 }]
            }}
          >
            <Post
              postID={this.state.posts[1].xID}
              data={this.state.posts[1]}
              key={this.state.posts[1].xID}
              navigation={this.props.navigation}
              showDetail={false}
              relevantStatus={"LIVE"}
              onIsNotRelevant={() => {
                console.log(
                  "Check this!! onIsNotRelevant is called in posts.js"
                );
                //this.removePost(1);
              }}
            />
          </View>
        )}
        <View
          style={{
            width: x300,
            height: x300,
            marginBottom: x300 == 300 ? -300 : -x300 - 10,
            marginTop: x300 == 300 ? 0 : 10,
            borderRadius: 30,
            backgroundColor: "#000",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Image
            style={{ width: 100, height: 60, opacity: (global.app == "speakers" || global.app == "artisan") ? 0 : 1.0 }}
            source={require("../../images/logos/home_logo.png")}
          />
        </View>
        <SwipeableView
          onRelease={a => {
            this.nextCard(a);
            this.displayAction(a);
          }}
          onClick={() => {
            this.setState({ showPostDetail: true });
          }}
        >
          {this.state.posts.length >= 1 ? (
            <View
              style={{
                width: 300,
                height: 300,
                borderRadius: 30,
                backgroundColor: "#455",
                transform: x300 == 300 ? [] : [{ scale: x300 / 300 }]
              }}
            >
              <Post
                postID={this.state.posts[0].xID}
                data={this.state.posts[0]}
                key={this.state.posts[0].xID + this.state.showPostDetail}
                navigation={this.props.navigation}
                showDetail={this.state.showPostDetail}
                onHideDetail={() => {
                  this.setState({ showPostDetail: false });
                }}
                relevantStatus={"LIVE"}
                onIsNotRelevant={() => {
                  console.log(
                    "Check this!! onIsNotRelevant is called in posts.js"
                  );
                  //this.removePost(0);
                }}
              />
            </View>
          ) : (
              <View
                style={{
                  height: 300,
                  width: 300,
                  justifyContent: "center",
                  alignItems: "center",
                  transform: x300 == 300 ? [] : [{ scale: x300 / 300 }]
                }}
              >
                <View style={{ height: 200 }} />
                {
                  <Text
                    style={{ color: "#668", fontSize: 16, fontStyle: "italic" }}
                  >
                    {noNewOffers}
                  </Text>
                }
              </View>
            )}
        </SwipeableView>

        {/* <View
          style={{
            flexDirection: "row",
            position: "absolute",
            right: 25,
            top: Dimensions.get("window").height > 680 ? 15 : 6,
            width: 120,
            height: 25
          }}
        >
          <TouchableOpacity
            onPress={() => {
              this.setState({ browseAction: "SWIPE" });
              this.props.onToggleView("SWIPE")
            }}
            activeOpacity={0.6}
            style={{
              backgroundColor:
                this.state.browseAction == "SWIPE" ? "#446" : "#223",
              opacity: 0.8,
              width: 59,
              alignItems: "center",
              justifyContent: "center",
              borderBottomLeftRadius: 9,
              borderTopLeftRadius: 9,
              borderColor: "#669",
              borderWidth: 1
            }}
          >
            <Text
              style={{
                color: this.state.browseAction == "SWIPE" ? "#fff" : "#fff"
              }}
            >
              Swipe
            </Text>
          </TouchableOpacity>
          <View style={{ width: 2 }} />
          <TouchableOpacity
            onPress={() => {
              console.log(this.state.browseAction);
              this.setState({ browseAction: "MAP" });
              this.props.onToggleView("MAP")
            }}
            activeOpacity={0.6}
            style={{
              backgroundColor:
                this.state.browseAction == "MAP" ? "#446" : "#223",
              opacity: 0.8,
              width: 59,
              alignItems: "center",
              justifyContent: "center",
              borderBottomRightRadius: 9,
              borderTopRightRadius: 9,
              borderColor: "#669",
              borderWidth: 1
            }}
          >
            <Text
              style={{
                color: this.state.browseAction == "MAP" ? "#fff" : "#fff"
              }}
            >
              Map
            </Text>
          </TouchableOpacity>
        </View> */}
        <View
          style={{
            position: "absolute",
            width: "100%",
            bottom:
              Dimensions.get("window").height > 735 || this.props.category2
                ? 15
                : 2,
            left: 0,
            backgroundColor: "rgba(34,34,52,.5)",
            height: 30,
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center"
          }}
        >
          <TouchableOpacity
            style={{ flexDirection: "row", alignItems: "center" }}
            onPress={() => {
              this.nextCard("left");
            }}
          >
            <Image
              source={require("../../images/icons/SwipeLeft.png")}
              style={{
                height: 20,
                width: 13.2,
                marginHorizontal: 8,
                transform: [{ scale: 1.2 }]
              }}
            />
            <View
              style={{
                marginLeft: -21.2,
                height: 20,
                width: 13.2,
                marginHorizontal: 8,
                backgroundColor: "rgba(34,34,52,.65)",
                transform: [{ scale: 1.2 }]
              }}
            />
            <Text style={{ color: "#668" }}>DISLIKE</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{ flexDirection: "row", alignItems: "center" }}
            onPress={() => {
              this.nextCard("skip");
            }}
          >
            <Text style={{ color: "#668" }}>NEXT</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginHorizontal: 8
            }}
            onPress={() => {
              this.nextCard("right");
            }}
          >
            <Text style={{ color: "#668" }}>LIKE</Text>
            <Image
              source={require("../../images/icons/SwipeRight.png")}
              style={{
                height: 20,
                width: 13.2,
                marginHorizontal: 8,
                transform: [{ scale: 1.2 }]
              }}
            />
            <View
              style={{
                marginLeft: -21.2,
                height: 20,
                width: 13.2,
                marginHorizontal: 8,
                backgroundColor: "rgba(34,34,52,.65)",
                transform: [{ scale: 1.2 }]
              }}
            />
          </TouchableOpacity>
        </View>
        {!!this.state.showLove && (
          <View
            style={{
              position: "absolute",
              height: 80,
              width: 160,
              right: 15,
              top: likedTop,
              alignItems: "center"
            }}
          >
            <Image
              style={{
                width: 80,
                height: 70,
                marginRight: 3,
                borderRadius: 3
              }}
              source={require("../../images/icons/Heart.png")}
            />
            <Text
              style={{
                fontSize: 32,
                color: "white",
                fontWeight: "800",
                textAlign: "center"
              }}
            >
              LIKED
            </Text>
          </View>
        )}
        {!!this.state.showDislike && (
          <View
            style={{
              position: "absolute",
              height: 80,
              width: 160,
              left: 10,
              top: likedTop,
              alignItems: "center"
            }}
          >
            <Text style={{ fontSize: 60, fontWeight: "bold" }}>👎</Text>
            <Text
              style={{
                fontSize: 32,
                color: "white",
                fontWeight: "800",
                textAlign: "center"
              }}
            >
              DISLIKED
            </Text>
          </View>
        )}
      </View>
    );
  }
}

//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
// Screen is duplicated between Wakanda Health and Market
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

import React, { Component } from "react";
import {
  Text,
  Image,
  View,
  TouchableOpacity,
  SafeAreaView,
  Platform,
  ActivityIndicator,
  TouchableHighlight,
  Dimensions,
  Modal,
  Keyboard,
} from "react-native";
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import { Camera } from 'expo-camera';
import * as Permissions from 'expo-permissions';
const gStyles = global.gStyles //    "../../styles/gStyles";
import firebase from "firebase";
import "firebase/firestore";
import CameraScreen from "./CameraScreen";
import { TextInput } from "react-native-gesture-handler";
import { GetAPIPicture } from "./APIPictures"

export default class GetPicture extends Component {
  state = {
    cameraRollStatus: global.cameraRollStatus,
    cameraRollGranted: global.cameraRollStatus == "granted",

    image: null,
    imageWidth: 300,
    imageHeight: 300,
    text: '',
    cameraStatus: global.cameraStatus,
    hasCameraPermission: null,
    type: Camera.Constants.Type.back,
    item: "PROFILE_PICTURE",

    mainModalVisible: true,
    activeScreen: "CHOOSE", // can be "TAKE_PICTURE"
    previewVisible: false,
    showGetAPIPicture: false,
    activityIndicatorAnimating: false
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    // const fSettings = { timestampsInSnapshots: true };
    // firestore.settings(fSettings);
    this.getCameraRollStatus();
    this.getCameraStatus();
  }

  getCameraRollStatus = async () => {
    //const { status } = await Permissions.getAsync(Permissions.CAMERA_ROLL);
    // console.log("camera roll status:", status);
    await this.setState({
      cameraRollStatus: "granted",
      cameraRollGranted: true
    });
    // let string =
    //   "STATUS: " + status + " (" + this.state.cameraRollGranted + ")";
    // alert(string);
  };

  getCameraStatus = async () => {
    const { status } = await Permissions.getAsync(Permissions.CAMERA);
    console.log("camera status:", status);
    global.cameraStatus = status;
    this.setState({
      hasCameraPermission: status === "granted",
      cameraStatus: status
    });
  };

  setCameraRollStatus = async () => {
    //const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
    global.cameraRollStatus = "granted";
    console.log("camera roll status:", status);
    await this.setState({
      cameraRollStatus: "granted",
      cameraRollGranted: true
    });
  };

  setCameraStatus = async () => {
    const { status } = await Permissions.askAsync(Permissions.CAMERA);
    console.log("camera status:", status);
    await this.setState({
      hasCameraPermission: status === "granted",
      cameraStatus: status
    });
  };

  titleMessage = "Take a picture";
  supposedWidth = 600;
  supposedHeight = 600;

  async componentDidMount() {
    try {
      const item = this.props.item;
      this.setState({ item });
      if (item === "PROFILE_PICTURE") {
        this.setState({ type: Camera.Constants.Type.front });
      }

      if (item == "CHAT_PICTURE") {
        this.titleMessage = "Take or select a picture";
        this.supposedHeight = 800;
        this.supposedWidth = 800;
      }
      if (item == "INQUIRY_PICTURE") {
        this.titleMessage = "Take or select a picture";
        this.supposedHeight = 800;
        this.supposedWidth = 800;
      }
      if (item == "INQUIRY_IMAGE") {
        this.titleMessage = "Attach a picture to your inquiry";
        this.supposedHeight = 800;
        this.supposedWidth = 800;
      }
      if (item == "PROFILE_BACKGROUND_PICTURE") {
        this.titleMessage = "Change my background picture";
        this.supposedHeight = 500;
        this.supposedWidth = 800;
      }
      if (item == "PROFILE_PICTURE") {
        this.titleMessage = "Change my profile picture";
        this.supposedHeight = 400;
        this.supposedWidth = 400;
      }
      if (item == "HEALTH_PROFILE_PICTURE") {
        this.titleMessage = "Change my profile picture";
        this.supposedHeight = 400;
        this.supposedWidth = 400;
      }
      if (item == "CENTER_BACKGROUND_PICTURE") {
        this.titleMessage = "Change background picture";
        this.supposedHeight = 500;
        this.supposedWidth = 800;
      }
      if (item == "CENTER_PICTURE") {
        this.titleMessage = "Change logo";
        this.supposedHeight = 400;
        this.supposedWidth = 400;
      }
      if (item == "COMMUNITY_BACKGROUND_PICTURE") {
        this.titleMessage = "Change background picture";
        this.supposedHeight = 500;
        this.supposedWidth = 800;
      }
      if (item == "COMMUNITY_PICTURE") {
        this.titleMessage = "Change community logo";
        this.supposedHeight = 400;
        this.supposedWidth = 400;
      }
      if (item == "POST") {
        this.titleMessage = (global.app == "speakers") ? "Add a Picture" : "Snap your offer";
        this.supposedHeight = 800;
        this.supposedWidth = 800;
      }
      if (item == "COMMUNITY") {
        this.titleMessage = "Add a Picture";
        this.supposedHeight = 800;
        this.supposedWidth = 800;
      }
      if (item == "WEBSITE_BACKGROUND_PICTURE") {
        this.titleMessage = "Change Background Image";
        this.supposedHeight = 720;
        this.supposedWidth = 1920;
      }
      if (item == "WEBSITE_FRONT_PICTURE") {
        this.titleMessage = "Front Image";
        this.supposedHeight = 1200;
        this.supposedWidth = 1200;
      }
      if (item == "WEBSITE_LOGO_PICTURE") {
        this.titleMessage = "Website Logo";
        this.supposedHeight = 1000;
        this.supposedWidth = 1000;
      }
      if (item == "WEBSITE_PROMO_PICTURE") {
        this.titleMessage = "Website Logo";
        this.supposedHeight = 476;
        this.supposedWidth = 1400;
      }
      if (item == "LARGE_PICTURE") {
        this.titleMessage = "Change Image";
        this.supposedHeight = 1600;
        this.supposedWidth = 1600;
      }
      if (item == "LARGE_WIDE_PICTURE") {
        this.titleMessage = "Change Image";
        this.supposedHeight = 1200;
        this.supposedWidth = 2200;
      }
    } catch (err) {
      global.warn(err, "GetPic1")
      alert("error", err);
    }
  }

  componentWillUnmount() {
    //TBD xx: Unmount Camera
  }

  setPreviewVisible(visible) {
    this.setState({ previewVisible: visible });
  }

  upload = async uri => {
    try {
      this.setState({ activityIndicatorAnimating: true });
      let imgPath = "";
      if (this.state.item === "CHAT_PICTURE") {
        let chatID;
        if (this.props.chatID) {
          chatID = this.props.chatID;
        } else {
          chatID = "SomeChat";
        }
        imgPath =
          "ChatPictures/" +
          chatID +
          "__" +
          Math.floor(Math.random() * 8999999999999999 + 1000000000000000);
        +".jpg";
      } else if (this.state.item === "HCHAT_PICTURE") {
        let chatID;
        if (this.props.chatID) {
          chatID = this.props.chatID;
        } else {
          chatID = "SomeHChat";
        }
        imgPath =
          "ChatPictures/H/" +
          chatID +
          "__" +
          Math.floor(Math.random() * 8999999999999999 + 1000000000000000);
        +".jpg";
      } else {
        if (this.state.item === "POST") {
          imgPath =
            "Posts/" +
            global.myProfileID +
            "__" +
            Math.floor(Math.random() * 8999999999999999 + 1000000000000000);
          +".jpg";
        } else {
          if (this.state.item === "COMMUNITY") {
            imgPath =
              "Posts/c_" +
              global.myProfileID +
              "__" +
              Math.floor(Math.random() * 8999999999999999 + 1000000000000000);
            +".jpg";
          } else {
            if (
              this.state.item === "INQUIRY_PICTURE" ||
              this.state.item === "INQUIRY_IMAGE"
            ) {
              imgPath =
                "Inquiries/" +
                global.myProfileID +
                "__" +
                Math.floor(Math.random() * 8999999999999999 + 1000000000000000);
              +".jpg";
            } else {
              if (
                this.state.item === "CENTER_PICTURE" ||
                this.state.item === "CENTER_BACKGROUND_PICTURE" ||
                this.state.item === "COMMUNITY_PICTURE" ||
                this.state.item === "COMMUNITY_BACKGROUND_PICTURE"
              ) {
                imgPath =
                  "Centers/" +
                  global.myCenterID +
                  "__" +
                  Math.floor(Math.random() * 8999999999999999 + 1000000000000000);
                +".jpg";
              } else {
                if (this.state.item === "HEALTH_PROFILE_PICTURE") {
                  imgPath =
                    "HealthProfilePictures/" +
                    global.myHealthProfileID +
                    "__" +
                    Math.floor(Math.random() * 8999999999999999 + 1000000000000000);
                  +".jpg";
                } else {
                  imgPath =
                    "Pictures/" + this.state.item + global.myProfileID + ".jpg";
                }
              }
            }
          }
        }
      }

      // const response = await fetch(uri);
      // const blob = await response.blob();
      const blob = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          resolve(xhr.response);
        };
        xhr.onerror = function (e) {
          console.log(e);
          reject(new TypeError("Network request failed"));
        };
        xhr.responseType = "blob";
        xhr.open("GET", uri, true);
        xhr.send(null);
      });

      const ref = firebase
        .storage()
        .ref()
        .child(imgPath);
      const snapshot = await ref.put(blob);
      const pictureURL = await ref.getDownloadURL();

      if (this.state.item === "PROFILE_PICTURE" || this.state.item === "PROFILE_BACKGROUND_PICTURE") {
        const functionBody = {
          profile: global.myProfileID,
          myUID: global.myUID,
          idToken: global.idToken
        }
        if (this.state.item === "PROFILE_PICTURE") functionBody.picture = pictureURL
        if (this.state.item === "PROFILE_BACKGROUND_PICTURE") functionBody.backgroundPicture = pictureURL

        try {
          this.setState({ activityIndicatorAnimating: true });
          await global.fetchIdToken()
          const r = await fetch(global.cloudFunctionURL + "updateProfile", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(functionBody)
          });
          const r2 = await r.json();

          this.setState({ activityIndicatorAnimating: false });
          if (r2.msg !== "PROFILE_UPDATED") {
            alert("Upload not successful");
          }
        } catch (err) {
          //alert("An error occurred. Check internet connection " + err.toString());
          global.warn(err, "GetPic2")
          this.setState({ activityIndicatorAnimating: false });
        }
        // if (this.state.item === "PROFILE_PICTURE") {
        //   firebase
        //     .firestore()
        //     .collection("Profiles")
        //     .doc(global.myProfileID)
        //     .update({ profilePictureURL: pictureURL });
        //   global.myProfilePictureURL = pictureURL;
        // }

        // if (this.state.item === "PROFILE_BACKGROUND_PICTURE") {
        //   firebase
        //     .firestore()
        //     .collection("Profiles")
        //     .doc(global.myProfileID)
        //     .update({ profilePictureBackgroundURL: pictureURL });
        // }
      }
      if (this.state.item === "HEALTH_PROFILE_PICTURE") {
        try {
          this.setState({ activityIndicatorAnimating: true });
          await global.fetchIdToken()
          const r = await fetch(global.cloudFunctionURL + "updateHealthProfile", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              isWeb: true,
              action: "update",
              data: { profilePictureURL: pictureURL },
              myUID: global.myUID,
              idToken: global.idToken
            })
          });
          const r2 = await r.json();

          this.setState({ activityIndicatorAnimating: false });
          if (r2.msg !== "SUCCESS") {
            alert("Upload not successful!");
          }
        } catch (err) {
          alert("An error occurred - Check internet connection " + err.toString());
          global.warn(err, "GetPic3")
          this.setState({ activityIndicatorAnimating: false });
        }
      }

      this.setState({ activityIndicatorAnimating: false });
      this.setPreviewVisible(false);

      this.props.onDone(pictureURL, this.state.text);
    } catch (err) {
      this.setState({ activityIndicatorAnimating: false });
      alert("Error while updating. Check internet connection and try again " + err.toString());
      global.warn(err, "GetPic4")
    }
  };

  optimizeImage = async photo => {
    let sizedImage;
    let compressRatio;
    if (Platform.OS === "ios") {
      compressRatio = 0.3;
    } else {
      compressRatio = 0.5;
    }

    let newWidth = Math.min(this.supposedWidth, photo.width);

    sizedImage = await ImageManipulator.manipulateAsync(
      photo.uri,
      [{ resize: { width: newWidth } }],
      { compress: compressRatio, base64: true }
    );

    if (!this.props.noCrop && sizedImage.height + 50 > this.supposedHeight) {
      sizedImage = await ImageManipulator.manipulateAsync(
        sizedImage.uri,
        [
          {
            crop: {
              originX: 0,
              originY: (sizedImage.height - this.supposedHeight) / 2,
              width: sizedImage.width,
              height: this.supposedHeight
            }
          }
        ],
        { compress: compressRatio, base64: true }
      );
    }
    return sizedImage;
  };

  pickImage = async () => {
    try {
      await this.setCameraRollStatus();
      if (!this.state.cameraRollGranted) {
        alert(
          "You have not given permission to access the camera roll. You can change this in your phone settings"
        );
      } else {
        let compressRatio;
        if (Platform.OS === "ios") {
          compressRatio = 0.3;
        } else {
          compressRatio = 0.5;
        }

        let cropperHeight = 40;
        if (this.props.item == "PROFILE_BACKGROUND_PICTURE" || this.props.item == "WEBSITE_BACKGROUND_PICTURE" || this.props.item == "WEBSITE_PROMO_PICTURE") {
          cropperHeight = 25
        }
        let result = await ImagePicker.launchImageLibraryAsync({
          allowsEditing: !this.props.noCrop,
          aspect: [40, cropperHeight],
          base64: true,
          quality: compressRatio
        });
        console.log(result?.uri);

        console.log({ wO: result.width, hO: result.height })

        if (!result.cancelled) {
          let optimizedImage = await this.optimizeImage(result);

          console.log({ w: optimizedImage.width, h: optimizedImage.height })

          this.setState({
            image: optimizedImage.uri,
            imageWidth: optimizedImage.width || 300, imageHeight: optimizedImage.height || 300
          });
          this.setPreviewVisible(true);
        }
      }
    } catch (err) {
      console.log({ err })
      const msg = err?.message
      alert(msg ? ("Error: " + msg) : "An error occurred!");
      global.warn(err, "GetPic6")
    }
  };

  render() {
    let previewWidth = (this.state.item == "PROFILE_BACKGROUND_PICTURE" || this.state.item == "WEBSITE_BACKGROUND_PICTURE" || this.state.item == "WEBSITE_PROMO_PICTURE")
      ? Dimensions.get("window").width : 300
    let previewHeight = (this.state.item == "PROFILE_BACKGROUND_PICTURE" || this.state.item == "WEBSITE_BACKGROUND_PICTURE" || this.state.item == "WEBSITE_PROMO_PICTURE")
      ? 180 : 300
    if (this.props.noCrop) {
      if (this.state.imageHeight > this.state.imageWidth) {
        previewHeight = Dimensions.get("window").height >= 700 ? 350 : Dimensions.get("window").height >= 600 ? 280 : 180
        previewWidth = 350 / this.state.imageHeight * this.state.imageWidth
      } else {
        previewWidth = 350
        previewHeight = 350 / this.state.imageWidth * this.state.imageHeight
      }
    }
    console.log({ previewHeight, previewWidth })

    return (
      <Modal
        animationType="slide"
        transparent={global.app == "health"}
        visible={this.state.mainModalVisible}
        onRequestClose={() => {
          //alert("Modal has been closed.");
        }}
      >
        {this.state.activeScreen == "CHOOSE" && !this.state.previewVisible && (
          <View
            style={!global.isTablet
              ? {
                flex: 1,
                backgroundColor: global.app == "health" ? "#fff" : "#000",
                justifyContent: "center",
                alignItems: "center"
              }
              : {
                flex: 1,
                backgroundColor: global.app == "health" ? "#fff" : "#000",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                width: 400,
                margin: Dimensions.get("window").height >= 750 ? 100 : 0,
                borderWidth: 15,
                borderColor: "#800",
                borderRadius: 50
              }
            }
          >
            <TouchableOpacity
              style={{
                position: "absolute",
                borderWidth: 0,
                borderColor: "rgba(0,0,0,0.2)",
                alignItems: "center",
                justifyContent: "center",
                width: 25,
                top: Platform.OS === "ios" ? 50 : 25,
                left: 15,
                height: 25,
                backgroundColor: global.app == "health" ? "#ff0000" : global.app == "artisan" ? "#ffd307" : "#008866",
                borderRadius: 3
              }}
              onPress={() => {
                this.props.onDone(null);
              }}
            >
              <Image
                style={{ width: 43, height: 43, opacity: 1 }}
                source={
                  global.app == "health"
                    ? require("../../images/icons/CancelWhite.png")
                    : require("../../images/icons/CancelBlack.png")
                }
              />
            </TouchableOpacity>
            <View style={{ height: global.app == "health" ? 250 : 200 }}>
              {(global.app == "market" || global.app == "health") && <Image
                style={{
                  width: global.app == "health" ? 111 : 150,
                  height: global.app == "health" ? 111 : 100,
                  alignSelf: "center",
                  marginBottom: global.app == "health" ? 35 : 2
                }}
                source={require("../../images/logos/home_logo.png")}
              />}
              {(global.app == "artisan") && <Image
                style={{
                  width: 180,
                  height: 180,
                  alignSelf: "center",
                  marginBottom: -20,
                  marginTop: -30
                }}
                source={require("../../custom/Artisan/images/logos/TAN-logo.png")}
              />}
              {(global.app == "education") && <Image
                style={{
                  width: 180,
                  height: 180,
                  alignSelf: "center",
                  marginBottom: -20,
                  marginTop: -30,
                  tintColor: "white"
                }}
                source={require("../../custom/Education/images/logos/WE-Logo.png")}
              />}
              <Text
                style={{
                  fontSize: 24,
                  color: global.app == "health" ? "#ff0000" : global.app == "artisan" ? "#ffd307" : "#008866"
                }}
              >
                {this.titleMessage}
              </Text>
            </View>
            <View style={{ height: 50 }}>
              <TouchableOpacity
                onPress={async () => {
                  if (this.state.cameraStatus != "granted") {
                    await this.setCameraStatus();
                  }
                  if (this.state.cameraStatus == "denied") {
                    alert(
                      "You have not given permission to use the camera. To take pictures, change this in the system settings of your phone!"
                    );
                  } else {
                    this.setState({ activeScreen: "TAKE_PICTURE" });
                  }
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    color: global.app == "health" ? "black" : "white"
                  }}
                >
                  Take a Picture
                </Text>
              </TouchableOpacity>
            </View>
            <View style={{ height: 40 }}>
              <Text
                style={{
                  fontSize: 12,
                  color: global.app == "health" ? "black" : "white"
                }}
              >
                or
              </Text>
            </View>
            <View style={{ height: 50 }}>
              <TouchableOpacity onPress={this.pickImage}>
                <Text
                  style={{
                    fontSize: 20,
                    color: global.app == "health" ? "black" : "white"
                  }}
                >
                  Upload a Picture
                </Text>
              </TouchableOpacity>
            </View>
            <View style={{ height: 50, justifyContent: "center" }} />
            {this.props.offerAPI && < View style={{ height: 50 }}>
              <Text
                style={{
                  fontSize: 12, marginTop: -42, marginBottom: 30, alignSelf: "center",
                  color: global.app == "health" ? "black" : "white"
                }}
              >
                or
              </Text>
              <TouchableOpacity onPress={() => { this.setState({ showGetAPIPicture: true }) }}>
                <Text
                  style={{
                    fontSize: 20,
                    color: global.app == "health" ? "black" : "white"
                  }}
                >
                  Select a Picture
                </Text>
              </TouchableOpacity>
            </View>}
            {this.state.showGetAPIPicture && (
              <GetAPIPicture
                backgrounds={this.state.pictureToChange == "background"}
                blogs={this.state.pictureToChange == "blog"}
                onDone={pic => {
                  console.log("APIPicture", pic);
                  if (pic?.url)
                    this.props.onDone(pic.url, this.state.text);
                  else this.setState({ showGetAPIPicture: false });
                }}
              />
            )}
          </View>
        )
        }
        {
          !!this.state.previewVisible && (
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => { Keyboard.dismiss() }}
              style={{ paddingTop: 45, flex: 1, backgroundColor: "#111" }}>
              <View style={{ justifyContent: "center", alignItems: "center" }}>
                <Image
                  source={{ uri: this.state.image }}
                  style={{
                    width: previewWidth, height: previewHeight,
                    borderRadius: this.state.item == "PROFILE_PICTURE" ? 66 : 0
                  }}
                />
                <View style={{ height: 40 }} />
                {!this.state.activityIndicatorAnimating ? (
                  <View style={{ width: '50%' }}>
                    {this.props.chatGroup && <TextInput
                      onFocus={() => { }}
                      onChangeText={text => {
                        this.setState({ text });
                      }}
                      value={this.state.text}
                      placeholder={"Add a Caption..."}
                      placeholderTextColor={"#aab"}
                      style={{
                        // textAlign: "center",
                        color: "#ffffff",
                        borderBottomWidth: 1,
                        borderBottomColor: '#fff',
                        marginBottom: 20,
                        fontSize: 12,
                        paddingRight: 5,
                        paddingLeft: 5,
                        minHeight: 25
                      }}
                      multiline
                      maxLength={2000}
                      underlineColorAndroid="transparent"
                      keyboardShouldPersistTaps={"always"}
                      keyboardDismissMode={"on-drag"}
                      keyboardAppearance={"dark"}
                      autoCompleteType={"off"}
                    />}
                    <TouchableOpacity
                      style={[global.gStyles.button, { margin: 21 }]}
                      onPress={async () => {
                        await this.upload(this.state.image);
                      }}
                    >
                      <Text style={global.gStyles.buttontext}>
                        {this.state.item == "CHAT_PICTURE"
                          ? "OK, send this picture"
                          : "Fine, upload this!"}
                      </Text>
                    </TouchableOpacity>
                  </View>
                ) : (
                  <View
                    style={{
                      width: "100%",
                      height: 65,
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <ActivityIndicator
                      size="small"
                      color="#FFFFFF"
                      style={{
                        opacity: this.state.activityIndicatorAnimating ? 1.0 : 0.0
                      }}
                      animating={true}
                    />
                  </View>
                )}
                <View style={{ height: 40 }} />
                <TouchableHighlight
                  onPress={() => {
                    this.setPreviewVisible(!this.state.previewVisible);
                  }}
                >
                  <Text style={{ color: "white", fontSize: 16 }}>Try again</Text>
                </TouchableHighlight>
              </View>
            </TouchableOpacity>
          )
        }
        {
          this.state.activeScreen == "TAKE_PICTURE" &&
          !this.state.previewVisible && (
            <View style={{ flex: 1, alignItems: "center", width: "100%" }}>
              <CameraScreen
                item={this.state.item}
                onPictureSaved={async photo => {
                  const optimizedImage = await this.optimizeImage(photo);
                  this.setState({
                    image: optimizedImage.uri,
                    previewVisible: true
                  });
                }}
                onClose={() => {
                  this.props.onDone(null);
                }}
                type={(this.state.item == "PROFILE_PICTURE" || this.state.item == "HEALTH_PROFILE_PICTURE") ? "front" : "back"}
              />
            </View>
          )
        }
      </Modal >
    );
  }
}

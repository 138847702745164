import React, { Component } from "react";
import {
  View,
  Image,
  Text,
  ImageBackground,
  TouchableOpacity,
  ActivityIndicator,
  StatusBar,
  Dimensions,
  Alert,
  Platform
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
//import { Notifications } from "expo";
//import * as Permissions from 'expo-permissions';
import firebase from "firebase";
import "firebase/firestore";
import CategorySelectorMarket from "../V1CategorySelector";
import CurrentLocation from "../../shared/Map/currentLocation";
//import Guide from "../shared/guide";
//import Tabsguide from "../shared/tabsguide";
//import Guide from "../shared/lottieguide";
import Posts from "../Post/Posts";
const gStyles = global.gStyles

export default class HomeHotNotScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    return {
      tabBarVisible: false,
      title: "Hot or Not",
      headerTransparent: true,
      headerRight: () => {
        return (
          <TouchableOpacity
            style={{
              alignItems: "center",
              justifyContent: "center",
              //backgroundColor: "#A8CF45",
              paddingHorizontal: 21,
              flexDirection: "row",
              borderRadius: 3,
              paddingVertical: 3
            }}
            onPress={navigation.getParam("toggleFilter")}
          >
            <Image
              style={{ width: 35, height: 35, marginRight: 3, marginBottom: 3, tintColor: "#A8CF45" }}
              source={require("../../images/icons/Filter.png")}
            />
            {/* <Text style={{ fontSize: 12, color: "#A8CF45", fontWeight: "bold" }}>
              Filter</Text> */}
          </TouchableOpacity>
        )
      },
    }
  };

  //state is from old homeMainScreen - most is irrelevant
  state = {
    showSearchResults: false,
    dots: [],
    lineDrawn: [],
    ballPosition: { x: 0, y: 0 },
    category1: "",
    category2: "",
    location: {
      coords: {
        latitude: 6.465422,
        longitude: 3.406448
      }
    },
    currentLocationLoaded: false,
    globalsSet: false,
    action: "BROWSE",
    browseAction: "SWIPE",
    findAction: "LIST",
    resultIDs: [],
    resultArray: [],
    mapDelta: 0.25,
    postDelta: 0.28,
    results: [],
    postNewResultArray: [],
    postLikedResultArray: [],
    nOfProvidersContacted: 0,
    showForm: false,
    isConnected: true,
    activityIndicatorAnimating: false,
    splashScreen: true,
    sortBy: "Latest",
    country: global.myLocalCurrency ? global.myLocalCurrency == "GHS" ? "Ghana" : "Nigeria" : "All",
    showInfo: false,
    showFilter: false,
  };

  constructor() {
    super();

    this.unsubscribeFirestoreProfile = () => { return; };
    this.unsubscribeFirestoreToken = () => { return; };
    const firestore = firebase.firestore();




  }

  async componentDidMount() {
    try {
      this.props.navigation.setParams({ toggleFilter: () => { this.setState({ showFilter: !this.state.showFilter }) } });
      this.browsePosts()

      const hasReadHotNotInfo = await AsyncStorage.getItem("hasReadHotNotInfo")
      if (hasReadHotNotInfo == "true") {
        //
      } else {
        this.setState({ showInfo: true })
      }
    } catch (err) {
      console.log(err);
    }
  }

  componentWillUnmount() {
    console.log("Unmounting HotNot");
  }

  browsePosts = async () => {

    console.log("Starting to call findPosts2");
    try {
      this.setState({ activityIndicatorAnimating: true });
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "findPosts2", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          isWeb: true,
          orderBy: "TOSWIPE",
          coords: global.location ? global.location.coords : {},
          countryCurrency: (this.state.country == "Nigeria") ? "NGN" : (this.state.country == "Ghana") ? "GHS" : "ALL",
          sortBy: this.state.sortBy,
          ...(this.state.category1 && { category1: this.state.category1 }),
          ...(this.state.category2 && { category2: this.state.category2 }),
          amount: 100, //needed, but will be ignored
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();

      // if success, navigate to Offers screen
      console.log("Received response. ", r2.msg);
      this.setState({ activityIndicatorAnimating: false });
      if (r2.msg === "SUCCESS") {
        console.log("Successful response. ", r2.results);
        this.setState({
          results: r2.results,
        });
        if (r2.results.length == 0) alert("No results found")
        return;
      } else {
        console.log(r2.msg);
        alert("Unsuccessful. " + r2.msg);
      }
    } catch (e2) {
      alert("An error occurred. Please try again");
      console.log(e2);
      this.setState({ activityIndicatorAnimating: false });
    }
  };


  renderSortButton = (status) => {
    const selected = (status == this.state.sortBy)
    return (<TouchableOpacity
      onPress={async () => {
        await this.setState({ sortBy: status, showFilter: false })
        this.browsePosts()
      }}
      style={{
        alignItems: "center", justifyContent: "center", height: 30, padding: 5, backgroundColor: "#026",
        marginHorizontal: 5, borderRadius: 5, borderWidth: selected ? 3 : 0.3, borderColor: "#fff"
      }}>
      <Text style={{ color: "#eee", fontSize: 11 }}>{status}</Text>
    </TouchableOpacity>)
  }
  renderCountryButton = (status) => {
    const selected = (status == this.state.country)
    return (<TouchableOpacity
      onPress={async () => {
        await this.setState({ country: status, showFilter: false })
        this.browsePosts()
      }}
      style={{
        alignItems: "center", justifyContent: "center", height: 30, padding: 5, backgroundColor: "#026",
        marginHorizontal: 5, borderRadius: 5, borderWidth: selected ? 3 : 0.3, borderColor: "#fff"
      }}>
      <Text style={{ color: "#eee", fontSize: 11 }}>{status}</Text>
    </TouchableOpacity>)
  }

  render() {
    CategorySelector = CategorySelectorMarket

    return (
      <View style={{ backgroundColor: "#000", flex: 1, width: "100%" }}>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#000",
            marginTop: 0,
            paddingTop: 80
          }}
        >
        </View>

        <View
          style={{
            // flexDirection: "row",
            // backgroundColor: "#000",
            // height: this.state.action == "INIT" ? 0 : 30,
            // alignItems: "center",
            // borderColor: this.state.action == "INIT" ? "#000" : "#223",
            // borderBottomWidth: global.app == "education" ? 0.5 : global.app == "speakers" ? 0 : 2,
            // borderTopWidth: global.app == "education" ? 0.5 : global.app == "speakers" ? 0 : 2,
            // marginTop: -5,
            // marginBottom: this.state.category2 ? -5 : 0,
            // opacity: this.state.action == "INIT" ? 0 : 1
          }}
        >
          {/* {this.state.action != "INIT" && global.app != "speakers" && (
            <Image
              style={{
                width: 15,
                height: 20,
                marginLeft: 22,
                opacity: 1,
                ...(global.app == "speakers" && { tintColor: "#bbc" })
              }}
              source={require("../images/icons/Location446.png")}
            />
          )} */}
          {/* <TouchableOpacity
            style={{ height: 40, width: 50, backgroundColor: "yellow" }}
            onPress={() => {
              console.log(this.state.resultArray, this.state.mapDelta);
            }}
          /> */}
          {/* {!!this.state.lastLocationLoaded && this.state.location.coords && (
            <CurrentLocation
              textColor={(global.app == "education") ? "#000" : (global.app == "speakers") ? "#bbc" : "#66a"}
              textSize={global.app == "speakers" ? 14 : 12}
              fontWeight={global.app == "speakers" ? "bold" : null}
              changeColor={"#ccc"}
              padding={0}
              initialCoords={this.state.location.coords}
              overrideInitialCoords={true}
              onUpdate={async (address, coords) => {
                //console.log("upda", address, coords);
                await this.setState({
                  location: {
                    address: address,
                    coords: coords
                  },
                  currentLocationLoaded: true
                });
                global.location = {
                  address: address,
                  coords: coords
                }
                if (this.state.action == "BROWSE") this.browsePosts();
                if (this.state.action == "FIND") this.findProvider({ valNearYou: 0.5, valAffordable: 0.5, valQuality: 0.5, valProfessional: 0.5, requirements: "", coords: this.state.location.coords });

                //refresh selected tab
                if (this.state.action == "INIT" && this.state.globalsSet) {
                  if (global.a.initialAction != "BROWSE") {
                  } else await this.browsePosts("HIDE_AA");
                  //global.initialPostsLoaded = true
                }
                if (this.state.action == "FIND") {
                  this.findProvider({
                    valNearYou: 0.5,
                    valAffordable: 0.5,
                    valQuality: 0.5,
                    valProfessional: 0.5,
                    requirements: "",
                    coords: this.state.location.coords
                  });
                }
                try {
                  AsyncStorage.setItem("latitude", coords.latitude.toString());
                  AsyncStorage.setItem(
                    "longitude",
                    coords.longitude.toString()
                  );
                } catch (err) {
                  console.log(err);
                }
              }}
              onPermissionNotGranted={async () => {
                //console.log("upda", address, coords);
                this.setState({
                  currentLocationLoaded: true
                });
                if (this.state.action == "INIT" && this.state.globalsSet) {
                  if (global.a.initialAction != "BROWSE") {
                    this.setState({ action: global.a.initialAction })
                  } else await this.browsePosts("HIDE_AA");
                  //global.initialPostsLoaded = true
                }
                if (this.state.action == "FIND") {
                  this.findProvider({
                    valNearYou: 0.5,
                    valAffordable: 0.5,
                    valQuality: 0.5,
                    valProfessional: 0.5,
                    requirements: "",
                    coords: this.state.location.coords
                  });
                }
              }}
            />
          )} */}
        </View>

        {
          !!this.state.activityIndicatorAnimating ? (
            <View
              style={{
                backgroundColor: "#000",
                justifyContent: "center",
                alignItems: "center",
                padding: 15,
                flex: 1
              }}
            >
              <ActivityIndicator
                size="large"
                color="#668"
                style={{
                  opacity: 1
                }}
                animating={true}
              />
            </View>
          ) : (
            <View
              //{...this.myPanResponder.panHandlers}
              style={{
                flex: 20,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#000"
              }}
            >
              {!!global.myUID && (
                <Posts
                  key={
                    "k" + this.state.results.length
                  }
                  category1={this.state.category1}
                  category2={this.state.category2}
                  navigation={this.props.navigation}
                  likedResultArray={this.state.postLikedResultArray}
                  newResultArray={this.state.results}
                // onToggleView={browseAction => {
                //   this.setState({ browseAction });
                // }}
                />
              )}
              {!!this.state.showFilter && <View style={{
                position: "absolute", bottom: 0, left: 0, flex: 1, height: "100%", width: "100%", backgroundColor: "#000"
              }}>
                <View style={{
                  flex: 1, backgroundColor: "#334", justifyContent: "space-between",
                  alignItems: "center", alignSelf: "center", width: "90%", borderRadius: 20,
                  borderWidth: 8, borderColor: "#ccc", marginTop: 20, marginBottom: Platform.OS == "ios" ? 20 : 0
                }}
                >
                  <View style={{ flex: 1, alignSelf: "stretch", marginLeft: 10 }}>
                    {/* <Text
                        style={{ fontSize: 28, alignSelf: "center", fontWeight: "bold", color: "#ccc", marginBottom: 50, marginTop: 7.5 }}
                      >Filter</Text> */}
                    <Text
                      style={{ marginTop: 65, marginBottom: 0, fontSize: 18, color: "#ccc", fontWeight: "bold" }}
                    >Sort By</Text>
                    <View style={{
                      flexDirection: "row", height: 60, borderBottomColor: "#333", borderBottomWidth: 1, alignItems: "center", padding: 3
                    }}>
                      {this.renderSortButton("Latest")}
                      {this.renderSortButton("Closest")}
                    </View>
                    <Text
                      style={{ marginTop: 20, marginBottom: 0, fontSize: 18, color: "#ccc", fontWeight: "bold" }}
                    >Country</Text>
                    <View style={{
                      flexDirection: "row", height: 60, borderBottomColor: "#333", borderBottomWidth: 1, alignItems: "center", padding: 3
                    }}>
                      {this.renderCountryButton("All")}
                      {this.renderCountryButton("Nigeria")}
                      {this.renderCountryButton("Ghana")}
                    </View>
                    <Text
                      style={{ fontSize: 18, marginTop: 20, color: "#ccc", fontWeight: "bold" }}
                    >Category</Text>
                    <View style={{ marginLeft: !!this.state.category2 ? 50 : 0 }}>
                      <CategorySelector
                        //showButton={!this.state.showForm}
                        noText={true}
                        backButton={true}
                        backgroundColor={"#334"}
                        category1={this.state.category1}
                        category2={this.state.category2}
                        onSelectCategory={async (category1, category2) => {
                          await this.setState({ category1, category2 });
                          if (category1 && category2) {
                            this.browsePosts();
                            this.setState({ showFilter: false })
                          }
                        }}
                      />
                    </View>
                  </View>
                  {/* <View style={{ height: 75, justifyContent: "center", alignItems: "center" }}>
                      <TouchableOpacity style={[global.gStyles.buttonXL, { paddingVertical: 5, paddingHorizontal: 22 }]}
                        onPress={() => {
                          this.setState({ showFilter: false })
                        }}>
                        <Text style={{ fontSize: 20, fontWeight: "bold", color: "#000" }}>
                          ok</Text>
                      </TouchableOpacity>
                    </View> */}
                  <TouchableOpacity
                    style={{
                      position: "absolute", borderWidth: 0, borderColor: "rgba(0,0,0,0.2)", alignItems: "center", justifyContent: "center", width: 30, top: 12, left: 6, height: 30, backgroundColor: "#A8CF45", borderRadius: 3
                    }}
                    onPress={() => { this.setState({ showFilter: false }) }}
                  >
                    <Image
                      style={{ width: 50, height: 50, opacity: 1, tintColor: "#334" }}
                      source={require("../../images/icons/CancelBlack.png")}
                    />
                  </TouchableOpacity>
                </View>
              </View>}
              {!!this.state.showInfo && <View style={{
                position: "absolute", bottom: 0, left: 0, flex: 1, height: "100%", width: "100%", backgroundColor: "#000"
              }}>
                <View style={{
                  flex: 1, backgroundColor: "#334", justifyContent: "space-between",
                  alignItems: "center", alignSelf: "center", width: "90%", borderRadius: 20,
                  borderWidth: 8, borderColor: "#ccf", marginTop: 20, marginBottom: Platform.OS == "ios" ? 20 : 0
                }}
                >
                  <View style={{ flex: 1, alignSelf: "stretch", marginLeft: 10 }}>
                    {/* <Text
                        style={{ fontSize: 28, alignSelf: "center", fontWeight: "bold", color: "#ccc", marginBottom: 50, marginTop: 7.5 }}
                      >Filter</Text> */}
                    <Text
                      style={{ marginTop: 15, marginBottom: 40, fontSize: 10, color: "#AAA", fontWeight: "bold", alignSelf: "center" }}
                    > </Text>
                    <Image
                      style={{ alignSelf: "center", margin: 10, width: 40, height: 60, opacity: 1, tintColor: "#fff" }}
                      source={require("../../images/icons/SwipeRight.png")}
                    />
                    <Text
                      style={{ marginBottom: 40, fontSize: 18, color: "#fff", alignSelf: "center" }}
                    >swipe right if it's HOT</Text>
                    <Image
                      style={{ alignSelf: "center", margin: 10, width: 40, height: 60, opacity: 1, tintColor: "#fff" }}
                      source={require("../../images/icons/SwipeLeft.png")}
                    />
                    <Text
                      style={{ marginBottom: 10, fontSize: 18, color: "#fff", alignSelf: "center" }}
                    >swipe left if it's NOT</Text>
                  </View>
                  <View style={{ height: 75, justifyContent: "center", alignItems: "center" }}>
                    <TouchableOpacity style={[global.gStyles.buttonXL, { paddingVertical: 5, paddingHorizontal: 22 }]}
                      onPress={async () => {
                        try {
                          this.setState({ showInfo: false });
                          await AsyncStorage.setItem("hasReadHotNotInfo", "true")
                        } catch (e) { console.log(e) }
                      }}>
                      <Text style={{ fontSize: 20, fontWeight: "bold", color: "#000" }}>
                        OK, got it!</Text>
                    </TouchableOpacity>
                  </View>
                  <TouchableOpacity
                    style={{
                      position: "absolute", borderWidth: 0, borderColor: "rgba(0,0,0,0.2)", alignItems: "center", justifyContent: "center", width: 30, top: 12, left: 6, height: 30, backgroundColor: "#A8CF45", borderRadius: 3
                    }}
                    onPress={() => { this.setState({ showInfo: false }) }}>
                    <Image
                      style={{ width: 50, height: 50, opacity: 1, tintColor: "#334" }}
                      source={require("../../images/icons/CancelBlack.png")}
                    />
                  </TouchableOpacity>
                </View>
              </View>}
            </View>
          )
        }
      </View>
    );
  }
}

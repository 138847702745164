//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
// everything is an EXACT copy from Wakanda Market and "synced"
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

import React, { Component } from "react";
import {
  Text,
  View,
  TextInput,
  Image,
  ImageBackground,
  TouchableOpacity,
  Alert,
  ActivityIndicator,
  Keyboard,
  Platform,
  Dimensions,
  TouchableWithoutFeedback
} from "react-native";
import Constants from 'expo-constants';
import styles from "./styles";
import firebase from "firebase";
import "firebase/firestore";
import { NavigationEvents } from "react-navigation";

export default class VerifyOTPScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false
  };
  state = {
    phoneNumber: "",
    code: "",
    activityIndicatorAnimating: false,
    activityIndicatorAnimatingCall: false,
    keyboardSpacerHeight: 0,
    success: false,

    canGetNewCode: false,
    canGetCall: false
  };

  async componentDidMount() {
    this.attempts = 0
    this.keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      this._keyboardDidShow
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      this._keyboardDidHide
    );
  }

  componentWillUnmount() {
    console.log("Component signin will unmount");
    if (this.keyboardDidShowListener) this.keyboardDidShowListener.remove();
    if (this.keyboardDidHideListener) this.keyboardDidHideListener.remove();
  }

  _keyboardDidShow = async () => {
    console.log("Keyboard Shown");
    if (Platform.OS === "ios") {
      this.setState({ keyboardSpacerHeight: 135 });
    }
    //this.state.chatMessages.push({text: "Hello", author: 1, timeSent: 1})
  };

  _keyboardDidHide = () => {
    console.log("Keyboard Hidden");
    this.setState({ keyboardSpacerHeight: 0 });
  };

  handleResendTimeout = async () => {
    this.setState({ canGetNewCode: false, canGetCall: false })
    await new Promise(resolve => { setTimeout(resolve, 20000); });
    this.setState({ canGetNewCode: true })
    await new Promise(resolve => { setTimeout(resolve, 10000); });
    this.setState({ canGetCall: true })
  }

  callVerify = async () => {
    if (this.state.activityIndicatorAnimating) return;
    if (!global.isConnected) {
      alert("No internet connection");
      return;
    }
    try {
      this.setState({ activityIndicatorAnimating: true });
      await this.setState({
        phoneNumber: this.props.navigation.getParam("phoneNumber", "0")
        //thisIsANewUser: this.props.navigation.getParam("thisIsANewUser", false)
      });
      const r = await fetch(global.cloudFunctionURL + "verifyOTP", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          isWeb: true,
          isWeb: true,
          phoneNumber: this.state.phoneNumber,
          code: this.state.code,
          deviceName: Constants.deviceName || null,
          expoID: Constants.installationId,
          app: global.app || "unknown"
        }),
      });
      const r2 = await r.json();
      global.reportOnboarding(30)

      this.setState({ activityIndicatorAnimating: false });
      if (r2.msg === "SUCCESSFUL_OTP_AUTHENTICATION") {
        //store token /||/ xx CHANGE THIS!
        // await AsyncStorage.setItem("afbase_token", r2.token);
        // await AsyncStorage.setItem("myPhoneNumber", this.state.phoneNumber);

        this.setState({ success: true });
        await firebase.auth().signInWithCustomToken(r2.token);
        console.log("Successfully authenticated with Firebase");
      }
      if (r2.msg === "INCORRECT_OTP_CODE") {
        if (this.attempts == 0) {
          alert("Incorrect code.");
          this.attempts = 1
        } else {
          alert("Incorrect code. NOTE: Further failed attempts may result in blocking you for 1 day");
          this.attempts += 1
        }
        return;
      }
      if (r2.msg === "INVALID_OTP_CODE") {
        if (this.attempts == 0) {
          alert("Invalid code.");
          this.attempts = 1
        } else {
          alert("Invalid code. NOTE: Further failed attempts may result in blocking you for 1 day");
          this.attempts += 1
        }
        return;
      }
      if (r2.msg === "BLOCKED_365") {
        alert("Signin blocked after too many failed attempts.");
        return;
      }
      if (r2.msg === "BLOCKED_1") {
        alert("Signin blocked for one day. Too many failed attempts");
        return;
      }
      //alert("Error : " + r2.msg);
      console.log(r2.msg);
    } catch (e2) {
      alert("An error has occurred. " + e2); // String(e2.json()));
      this.setState({ activityIndicatorAnimating: false });
      console.log(e2);
      if (global.reportError) global.reportError(e2, "catches", "verifyOTP");
    }
  };

  callCallMe = async () => {
    await this.setState({
      phoneNumber: this.props.navigation.getParam("phoneNumber", "0000000000")
    });
    Alert.alert(
      "Phone number correct? " + this.state.phoneNumber,
      "",
      [
        {
          text: "No",
          onPress: () => {
            this.props.navigation.navigate("signin");
          }
        },
        {
          text: "Yes",
          onPress: () => {
            this.callCallMe2();
          }
        }
      ],
      { cancelable: false }
    );
  };

  callCallMe2 = async () => {
    await new Promise(resolve => { setTimeout(resolve, 150); });
    Alert.alert(
      "We will now call you. Be prepared to note the code.",
      "",
      [
        {
          text: "Cancel",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel"
        },
        {
          text: "OK",
          onPress: () => {
            this.callCallMe3();
          }
        }
      ],
      { cancelable: true }
    );
  };
  callCallMe3 = async () => {
    if (
      this.state.phoneNumber.endsWith("0000000009") ||
      this.state.phoneNumber.endsWith("0000000008") ||
      this.state.phoneNumber.endsWith("0000000007") ||
      this.state.phoneNumber.endsWith("0000000006") ||
      this.state.phoneNumber.endsWith("1234567890") ||
      !this.state.phoneNumber.startsWith("+") ||
      this.state.phoneNumber.length < 10
    ) {
      alert("Call not possible for this phone number");
      return;
    }
    if (!global.isConnected) {
      alert("You are not connected to the internet.");
      return;
    }

    let appVersion = "";
    if (Constants.manifest.version) {
      appVersion = Constants.manifest.version;
    }

    try {
      this.setState({ activityIndicatorAnimatingCall: true });
      const r = await fetch(global.cloudFunctionURL + "signin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          isWeb: true,
          isWeb: true,
          phoneNumber: this.state.phoneNumber,
          expoID: Constants.installationId,
          mode: "call",
          deviceInfo: {
            deviceName: Constants.deviceName || "-",
            expoVersion: Constants.expoVersion || "-",
            deviceYearClass: Constants.deviceYearClass || "-",
            platform: Constants.platform || "-",
            appVersion
          },
          app: global.app || "unknown"
        })
      });
      const r2 = await r.json();
      //AsyncStorage.setItem("myPhoneNumber", phone);

      console.log(r2.msg);
      await new Promise(resolve => { setTimeout(resolve, 2000); });
      this.setState({ activityIndicatorAnimatingCall: false });
      if (
        r2.msg === "OTP_SENT_NEW_USER_CREATED" ||
        r2.msg === "OTP_SENT_EXISTING_USER"
      ) {
        return;
      }
      alert("Error: " + r2.msg);
    } catch (e2) {
      alert("An error occurred.");
      if (global.reportError) global.reportError(err, "catches", "signin2");
      console.log(e2);
      this.setState({ activityIndicatorAnimatingCall: false });
    }
  };

  render() {
    return (
      <TouchableWithoutFeedback accessible={false}>
        <View style={styles.container}>
          <View style={styles.regform}>
            {(global.app == "market" || global.app == "health" || global.app == "education") && <Image
              style={{
                width: global.app == "health" ? 800 : (global.app == "education") ? 654 : 600,
                height: (global.app == "education") ? 460 : 400,
                marginRight: 70,
                opacity: 0.15,
                position: "absolute",
                bottom: 5,
                left: 5,
                ...(global.app == "education" && { tintColor: "#88f" })
              }}
              source={require("../images/logos/home_logo.png")}
            />}
            {(global.app == "artisan") && <Image
              style={{
                width: 600,
                height: 600,
                margin: 0,
                opacity: 0.075,
                position: "absolute",
                bottom: 1,
                left: 5
              }}
              source={require("../custom/Artisan/images/logos/TAN-logo2.png")}
            />}

            <Text style={styles.header2}>{!this.state.success ? "Verify it´s you" : ""}</Text>
            <Text
              style={{
                fontSize: 16,
                color: "white",
                marginBottom: 0,
                marginTop: 50,
                textAlign: "center"
              }}
            >
              {(!this.state.success && !this.state.activityIndicatorAnimating && !this.state.activityIndicatorAnimatingCall)
                ? `We have sent a verification code to your phone number.`
                : " "}
            </Text>
            <ActivityIndicator
              size="small"
              color="#FFFFFF"
              style={{
                opacity: this.state.activityIndicatorAnimating ? 1.0 : 0.0,
                marginBottom: 10
              }}
              animating={true}
            />
            {(!this.state.success && !this.state.activityIndicatorAnimating && !this.state.activityIndicatorAnimatingCall) ? (
              <View
                style={{
                  flexDirection: "row",
                  height: 40,
                  marginBottom: 12,
                  backgroundColor: "#aaa",
                  borderRadius: 20,
                  borderBottomWidth: 0,
                  width: 275
                }}
              >
                <TextInput
                  style={styles.textinput}
                  // autoFocus={true}
                  placeholder="enter code here "
                  placeholderTextColor="#000"
                  underlineColor={"transparent"}
                  underlineColorAndroid="transparent"
                  keyboardType="numeric"
                  onChangeText={code => {
                    this.setState({ code });
                  }}
                  ref={ref => (this.myTextInput = ref)}
                />
                {this.state.code.length > 5 &&
                  !this.state.activityIndicatorAnimating && (
                    <TouchableOpacity
                      onPress={this.callVerify}
                      style={{
                        alignItems: "center",
                        alignSelf: "flex-start",
                        padding: 1,
                        backgroundColor: "#A8CF45",// global.app=="artisan"?"#ffd307":  "#A8CF45",
                        marginLeft: 4.5,
                        width: 47,
                        height: 40,
                        justifyContent: "center",
                        borderTopEndRadius: 20,
                        borderBottomEndRadius: 20,
                        borderColor: "gray",
                        borderLeftWidth: 1
                      }}
                    >
                      <Image
                        style={{
                          width: 18,
                          height: 18,
                          marginRight: 1
                        }}
                        source={require("../images/icons/Send.png")}
                      />
                    </TouchableOpacity>
                  )}
              </View>
            ) : (!!this.state.success) ? (
              <View
                style={{
                  flexDirection: "row",
                  height: 100,
                  marginBottom: 72,
                  borderBottomWidth: 0,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ color: "#ccc", fontSize: 18 }}>
                  ✓ Starting app...
                </Text>
              </View>
            ) : (!!this.state.activityIndicatorAnimating) ? (
              <View
                style={{
                  height: 100,
                  marginBottom: 72,
                  borderBottomWidth: 0,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ color: "#ccc", fontSize: 18 }}>
                  Verifying Code
                </Text>
              </View>
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  height: 100,
                  marginBottom: 72,
                  borderBottomWidth: 0,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ color: "#ccc", fontSize: 18 }}>
                  Calling you now...
                </Text>
              </View>
            )}
          </View>

          {!this.state.success && !this.state.activityIndicatorAnimating && !this.state.activityIndicatorAnimatingCall && <TouchableOpacity
            onPress={() => {
              this.props.navigation.navigate("signin");
            }}
          >
            <Text> </Text>
            <Text style={[styles.belowText, { color: global.app == "artisan" ? "#A8CF45" : "#595", marginTop: 15 }]}>
              {!!this.state.canGetNewCode ? "Send me a new code" : " "}
            </Text>
          </TouchableOpacity>}
          {!this.state.success && !this.state.activityIndicatorAnimating && !this.state.activityIndicatorAnimatingCall && !!this.state.canGetCall && <TouchableOpacity
            onPress={() => {
              this.callCallMe();
            }}
          >
            <Text> </Text>
            <Text style={[styles.belowText, { color: global.app == "artisan" ? "#A8CF45" : "#595", marginTop: -25 }]}>
              {!!this.state.canGetCall ? "Call me instead" : " "}
            </Text>
          </TouchableOpacity>}
          <View style={{ height: this.state.keyboardSpacerHeight }} />
          {(global.app == "artisan" || global.app == "education") && <View
            style={{
              position: "absolute",
              bottom: Platform.OS === "ios" ? 100 : 25,
              width: "100%",
              justifyContent: "center",
              alignSelf: "center"
            }}
          >
            <View style={{ flexDirection: "row", marginBottom: 8, alignItems: "flex-end", justifyContent: "center", marginTop: 15 }}>
              <Text style={{ fontSize: 14, color: "#ccc", fontWeight: "bold" }} >powered by</Text>
              <Image
                style={{ width: 30, height: 24, marginLeft: 5, }}
                source={require("../images/logos/home_logo.png")}
              />
              <Text style={{ fontSize: 14, color: "#ccc", fontWeight: "bold" }}>WAKANDA</Text>
            </View>
          </View>}
          {global.app == "education" && <ImageBackground
            style={{
              width: Dimensions.get("window").width, height: Dimensions.get("window").height,
              position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: -1
            }}
            source={require("../custom/Education/images/logos/WE-Background.jpg")}
          />}
          <ImageBackground
            style={{
              width: Dimensions.get("window").width, height: Dimensions.get("window").height,
              position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: -1, opacity: 0.15
            }}
            source={require("../custom/Speakers/images/logos/SC-Background.jpg")}
          />
          <NavigationEvents
            onDidFocus={async payload => {
              this.handleResendTimeout()
              //this.myTextInput?.focus?.()
            }}
          />
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  Alert,
  Dimensions,
} from "react-native";
import distance from "../../../shared/distances";
import * as Font from "expo-font";
import firebase from "firebase";
import "firebase/firestore";

import * as Find from "../../Find/findFunctions";
import Post from "../../Post/Post";

// const shops = [
//   {
//     id: 1,
//     image: require("../../../images/homePageIcons/secondRowImages/headPhones/HeadPhone.png"),
//     Text: "Black and Gold Headphone",
//     price: "N5,000"
//   },
//   {
//     id: 2,
//     image: require("../../../images/homePageIcons/secondRowImages/lapTops/LapTops.png"),
//     Text: "Brand New 2015 Macbook Pro",
//     price: "N300,000"
//   },
//   {
//     id: 3,
//     image: require("../../../images/homePageIcons/secondRowImages/Speaker/Speaker.png"),
//     Text: "ASUS  Computer   Speaker",
//     price: "N12,000"
//   }
// ];

const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;

export default class ShopPreview extends Component {
  state = {
    providers: [],
    showDetail: false,
    detailPostResult: null,
    loading: true,
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => {
      return;
    };
  }

  async componentDidMount() {
    try {
      if (!global.myUID || !global.location || !global.idToken)
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID || !global.location || !global.idToken)
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID || !global.location || !global.idToken)
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID || !global.location || !global.idToken)
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID || !global.location || !global.idToken)
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID || !global.location || !global.idToken)
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID || !global.location || !global.idToken)
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID || !global.location || !global.idToken)
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID || !global.location || !global.idToken)
        await new Promise((resolve) => {
          setTimeout(resolve, 1500);
        });
      if (!global.myUID || !global.location || !global.idToken)
        await new Promise((resolve) => {
          setTimeout(resolve, 2500);
        });

      await global.setLocationFast(10);

      const res = await Find.findProvidersAsync({
        amount: 6,
        category1: this.props.providerCategory1,
        category2: this.props.providerCategory2,
      });
      if (res !== -1) {
        //priotize provider with profilePicture
        const providersWith = res.filter((p) => {
          return p.profilePictureURL ? true : false;
        });
        const providersWithout = res.filter((p) => {
          return p.profilePictureURL ? false : true;
        });
        const providers = [...providersWith, ...providersWithout];
        providers.length = 3;
        this.setState({ providers, loading: false });
        //console.log("my providers", this.state.providers);
      }
    } catch (e) {
      console.warn("Error 923432");
      console.log("Error 923432", e);
    }
  }
  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  render() {
    if (this.state.loading)
      return (
        <View
          style={{
            height: 150,
            alignItems: "center",
            justifyContent: "center",
            //backgroundColor: "#fff"
          }}
        >
          <ActivityIndicator size="small" color="#000" />
        </View>
      );
    return (
      <View
        style={{
          backgroundColor: "#FFFFFF",
          // minhHeight: 10,
          // width: SCREEN_HEIGHT - 480,
          width: "95%",
          borderRadius: 12,
          alignItems: "center",
          justifyContent: "center",
          margin: 15,
          alignSelf: "center",
          shadowColor: "#000",
          shadowOpacity: 0.05,
          bottom: 50,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            // backgroundColor: "yellow",
            width: "100%",
            // margin: 20
          }}
        >
          <View>
            <Text
              style={{
                fontSize: this.props.fontSize,
                fontFamily: "ProximaNovaA-Bold",
                color: "#333333",
                left: 25,
              }}
            >
              {this.props.description}
            </Text>
          </View>
          <TouchableOpacity
            style={{}}
            onPress={() => {
              this.props.navigation.navigate("homeProviderResults", {
                category1: this.props.providerCategory1,
                category2: this.props.providerCategory2,
              });
              global.reportStats(
                "pressed_Provider_SeeAll",
                this.props.providerCategory1,
                this.props.providerCategory2
              );
            }}
          >
            <View
              style={{
                padding: 10,
                margin: 10,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontSize: this.props.fontSize,
                  fontFamily: "ProximaNovaA-Regular",
                  color: "#707070",
                  // right: 30
                }}
              >
                See All
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            justifyContent: "center",
          }}
        >
          {this.state.providers.map((item, i) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  this.props.navigation.navigate("homeProfile", {
                    profileID: item.xID,
                  });
                }}
                key={i}
                style={{
                  backgroundColor: "#F5F5F5",
                  height: 160,
                  width: "28%",
                  borderRadius: 12,
                  margin: 5,
                }}
              >
                <Image
                  style={{
                    width: "100%",
                    height: 92,
                    borderRadius: 12,

                    // shadowColor: "#000",
                    // shadowOpacity: 0.5
                  }}
                  source={{ uri: item.profilePictureURL }}
                  resizeMode="cover"
                />

                <View style={{ marginVertical: 13, alignSelf: "center" }}>
                  <Text
                    style={{
                      bottom: 6,
                      fontFamily: "ProximaNovaA-Bold",
                      fontSize: 12,
                      margin: 0,
                      padding: 0,
                    }}
                    numberOfLines={2}
                  >
                    {item.profileNameBusiness}
                  </Text>
                </View>

                {item.locationWork._latitude != null && (
                  <View
                    style={{
                      flex: 1,
                      // justifyContent: "center",
                      // alignItems: "center",
                      position: "absolute",
                      top: "90%",
                      left: 10,
                    }}
                  >
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                        bottom: 5,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#707070",
                          fontFamily: "ProximaNovaA-Regular",
                        }}
                      >
                        {Math.round(
                          distance(
                            item.locationWork._latitude,
                            item.locationWork._longitude,
                            global.location.coords.latitude,
                            global.location.coords.longitude,
                            "K"
                          ) * 10
                        ) /
                          10 +
                          " km"}
                      </Text>
                      <Image
                        style={{
                          width: 15,
                          height: 16.5,
                          marginRight: 0,
                          tintColor: "#707070",
                        }}
                        source={require("../../../images/icons/Location.png")}
                      />
                    </View>
                  </View>
                )}
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
    );
  }
}

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  Linking,
  Platform,
  Dimensions,
  ActivityIndicator,
  Image,
  Keyboard,
  TextInput,
  Alert
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

const sWidth = Dimensions.get("window").width
const sHeight = Dimensions.get("window").height

export default class DoctorNotes extends Component {
  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  state = {
    note: "LOADING",
    record: {},
    hasUnsavedChanges: false,
    recordExists: true,
    isCreatingRecord: false,
    keyboardHeight: 0,
    loading: true,

    doctorName: "",
    doctorPictureURL: null
  }

  async componentDidMount() {

    this.keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      this._keyboardDidShow
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      this._keyboardDidHide
    );

    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "getHealthRecords", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          parentRecordID: this.props.parentRecordID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        let record = {}
        if (r2.records && r2.records.length > 0) {
          r2.records.forEach(rec => {
            if (rec.type == "Consultation Doctor Note") record = rec
          })
        }
        if (record.data != undefined) this.setState({ record, note: record.data.note || "", loading: false, hasUnsavedChanges: false, recordExists: true })
        else return this.setState({ loading: false, note: "", record: {}, recordExists: false })

        const profileDoc = await firebase
          .firestore()
          .collection("Health")
          .doc("data")
          .collection("HealthProfiles")
          .doc(record.doctorProfileID)
          .get()
        const profile = (profileDoc.exists) ? profileDoc.data() : {}
        this.setState({ doctorPictureURL: profile.profilePictureURL, doctorName: profile.profileName || "unknown author" })
        console.log({ doctorPictureURL: profile.profilePictureURL, doctorName: profile.profileName || "unknown author" })


      } else return alert("Backend error")

    } catch (err) {
      alert("Could not fetch doctor notes. Check your internet connection");
      console.log("Could not fetch doctor notes. Check your internet connection", err);
    }
  }

  async componentWillUnmount() {
    console.log("Component DoctorNotes will unmount");
    if (this.keyboardDidShowListener) this.keyboardDidShowListener.remove();
    if (this.keyboardDidHideListener) this.keyboardDidHideListener.remove();
    this.unsubscribeFirestore();
  }

  _keyboardDidShow = async e => {
    this.setState({ keyboardHeight: e.endCoordinates.height - 45 });
    //this.scrollView.scrollToEnd({ animated: true });
  };

  _keyboardDidHide = async () => {
    this.setState({ keyboardHeight: 0 });
    // await global.timeout(100)
    // if (Platform.OS == "ios") this.scrollView.scrollToEnd({ animated: false });
  };


  saveNote = async () => {
    const { recordExists, hasUnsavedChanges } = this.state
    try {
      this.setState({ recordExists: true, hasUnsavedChanges: false, isCreatingRecord: true })
      const action = !recordExists ? "create" : "update";
      const r = await fetch(global.cloudFunctionURL + "setHealthRecord", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          action,
          data: { note: this.state.note },
          ...(action == "update" && { recordID: this.state.record.xID }),
          ...(action == "create" && { type: "Consultation Doctor Note" }),
          ...(action == "create" && { parentRecordID: this.props.parentRecordID }),
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        if (action == "create") this.setState({ record: r2.record })
      } else {
        this.setState({ recordExists, hasUnsavedChanges, isCreatingRecord: false })
        return alert("Saving notes failed. Backend error")
      }
    } catch (err) {
      this.setState({ recordExists, hasUnsavedChanges, isCreatingRecord: false })
      alert("Could not save doctor notes. Check your internet connection");
      console.log("Could not save doctor notes. Check your internet connection", err);
    }
  }
  deleteNote = async () => {
    const { record, note, recordExists, hasUnsavedChanges, doctorName, doctorPictureURL } = this.state
    try {
      this.setState({ record: {}, note: "", recordExists: false, hasUnsavedChanges: false, doctorName: "", doctorPictureURL: null })
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "setHealthRecord", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          action: "delete",
          recordID: this.state.record.xID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg !== "SUCCESS") {
        this.setState({ record, note, recordExists, hasUnsavedChanges, doctorName, doctorPictureURL })
        return alert("Deleting notes failed. Backend error")
      }
    } catch (err) {
      this.setState({ record, note, recordExists, hasUnsavedChanges, doctorName, doctorPictureURL })
      alert("Could not delete doctor notes. Check your internet connection");
      console.log("Could not delete doctor notes. Check your internet connection", err);
    }
  }


  render() {
    if (!this.props.visible) return (<View />)
    if (this.state.loading) return (<View style={{ position: "absolute", top: 80, left: 0, width: "100%", }}>
      <View style={{ width: "100%", minHeight: 120, backgroundColor: "#368", alignItems: "center", justifyContent: "center" }}>
        <Text style={{ color: "#bbc", fontSize: 10, }}>Loading Doctor Notes...</Text>
      </View>
    </View>)
    const bottomRightString = this.state.hasUnsavedChanges ? "Unsaved Changes" : this.state.recordExists ? "Saved." : "";
    const isEditable = (!this.state.recordExists || this.state.isCreatingRecord ||
      (this.state.record.status == "OPEN" && this.state.record.doctorProfileID == global.myHealthProfileID && !!global.myHealthProfileID))

    return (
      <View style={{ position: "absolute", top: 80, left: 0, width: "100%", }}>
        <View style={{ width: "100%", minHeight: 120, backgroundColor: "#368" }}>
          <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", alignSelf: "stretch", marginTop: 10, }}>
            <Text style={{ color: "white", fontSize: 12, marginLeft: 15 }}>Doctor Notes 🔒{!isEditable && "    CLOSED"}</Text>
            <View style={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
              {!!this.state.doctorPictureURL && <Image
                style={{ width: 20, height: 20, borderRadius: 10 }}
                source={{ uri: this.state.doctorPictureURL }}
              />}
              <Text style={{ color: "white", fontSize: 12, marginLeft: 4, marginRight: 15 }}>{this.state.doctorName}</Text>
            </View>
          </View>
          {isEditable ? <TextInput
            onChangeText={note => { this.setState({ note, hasUnsavedChanges: true }); }}
            // onFocus={() => {this.scrollView.scrollTo({ x: 0, y: 200, animated: true });}}
            style={{
              marginHorizontal: 15, marginVertical: 5, color: "#000", padding: 5, flex: 1, borderRadius: 10,
              minHeight: 36, maxHeight: (sHeight - this.state.keyboardHeight - 188), backgroundColor: "#fff"
            }}
            value={this.state.note}
            placeholder={"Type Doctor Notes here..."}
            placeholderTextColor={"#aab"}
            multiline
            underlineColorAndroid="transparent"
            defaultValue={this.state.note}
            autoCompleteType={"off"}
            autoCorrect={false}
          /> :
            <TouchableOpacity style={{ height: 800, backgroundColor: "white", margin: 10 }} activeOpacity={0}>
              <ScrollView style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
                <View style={{ alignSelf: "stretch" }}>
                  <Text>{this.state.note}</Text>
                </View>
                <View style={{ height: 150, alignSelf: "stretch" }} />
              </ScrollView>
            </TouchableOpacity>
          }
          <View style={{ marginTop: -25, height: 25, alignSelf: "flex-end", marginRight: 23 }}>
            <Text style={{ color: "#889", fontSize: 10, fontStyle: "italic" }}>{bottomRightString}</Text>
          </View>
          {(this.state.hasUnsavedChanges || this.state.recordExists)
            ? <View style={{ alignSelf: "flex-end", flexDirection: "row", alignItems: "center", marginRight: 15, marginBottom: 5 }}>
              {this.state.recordExists
                && <TouchableOpacity onPress={() => { this.deleteNote() }}
                  style={{ borderColor: "white", borderWidth: 0.75, padding: 2, width: 55, alignItems: "center", borderRadius: 18 }}>
                  <Text style={{ fontSize: 12, color: "#fff" }}>Delete</Text>
                </TouchableOpacity>}
              {this.state.hasUnsavedChanges
                && <TouchableOpacity onPress={() => { this.saveNote() }}
                  style={{ backgroundColor: "red", padding: 3, width: 55, alignItems: "center", borderRadius: 18, marginLeft: 10 }}>
                  <Text style={{ fontSize: 12, color: "#fff" }}>Save</Text>
                </TouchableOpacity>}
            </View>
            : <View style={{ height: 15 }} />}

        </View>
      </View>
    )
  }
}
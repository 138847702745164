import firebase from "./afbase-config";
//import 'firebase/firestore';

import React, { PureComponent } from "react";

class lastAmount extends PureComponent {
  // state = {
  //   lastAmount: 0
  // };

  // ref = null;

  // componentDidMount() {
  //   this.ref = firebase.firestore().collection("user").doc("+2348181088440");

  //   this.ref.onSnapshot("value", data => {
  //     if (!data) {
  //       return;
  //     }

  //     const snapshot = data.val();

  //     if (snapshot === null) {
  //       return;
  //     }

  //     this.setState({ lastAmount: Number(snapshot) });
  //   });
  // }

  // componentWillUnmount() {
  //   if (this.ref) {
  //     this.ref.off();
  //   }
  // }

  // render() {
  //   return this.props.children(this.state.lastAmount);
    
  // }
}

export default lastAmount;
// import firebase from "./config";

// import React, { PureComponent } from "react";

// class lastAmount extends PureComponent {
//   state = {
//     lastAmount: 0
//   };

//   ref = null;

//   componentDidMount() {
//     this.ref = firebase.database().ref("/test/lastAmount");

//     this.ref.on("value", data => {
//       if (!data) {
//         return;
//       }

//       const snapshot = data.val();

//       if (snapshot === null) {
//         return;
//       }

//       this.setState({ lastAmount: Number(snapshot) });
//     });
//   }

//   componentWillUnmount() {
//     if (this.ref) {
//       this.ref.off();
//     }
//   }

//   render() {
//     return this.props.children(this.state.lastAmount);
    
//   }
// }

// export default lastAmount;

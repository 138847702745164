import React, { Component } from "react";
import {
  Text,
  View,
  TextInput,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  TouchableWithoutFeedback,
  Keyboard,
  SafeAreaView,
  StyleSheet,
  ImageBackground,
  Dimensions
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import GetPicture from "../../shared/Camera/GetPicture";
let NAME = ''
export default class MeEnterDetailsScreen extends Component {
  static navigationOptions = {
    headerShown: false,
    tabBarVisible: false,
    headerMode: "none",
    navigationOptions: {
      headerVisible: false
    }
  };
  state = {
    name: "",
    showGetPicture: false,
    activityIndicatorAnimating: false,
    keyboardSpacerHeight: 0
  };

  async componentDidMount() {
    this.keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      this._keyboardDidShow
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      this._keyboardDidHide
    );
    AsyncStorage.setItem("hideGuidehome", "false");
    AsyncStorage.setItem("hideGuideprofile", "false");
    AsyncStorage.setItem("hideGuidenewJob", "false");
    AsyncStorage.setItem("hideGuideresults", "false");

  }

  componentWillUnmount() {
    console.log("Component signin will unmount");
    if (this.keyboardDidShowListener) this.keyboardDidShowListener.remove();
    if (this.keyboardDidHideListener) this.keyboardDidHideListener.remove();
  }

  _keyboardDidShow = async e => {
    this.setState({ keyboardSpacerHeight: 80 });
  };

  _keyboardDidHide = () => {
    this.setState({ keyboardSpacerHeight: 0 });
  };

  callSet = async () => {
    try {
      this.setState({ activityIndicatorAnimating: true });
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "updateProfile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          isWeb: true,
          profile: global.myProfileID,
          name: this.state.name,
          //phoneNumber: global.myUID,
          myUID: global.myUID,
          idToken: global.idToken,
        })
      });
      const r2 = await r.json();

      this.setState({ activityIndicatorAnimating: false });
      if (r2.msg === "PROFILE_UPDATED") {
        console.log("Profile set with name " + this.state.name);
        this.setState({ showGetPicture: true });
      }
    } catch (err) {
      alert("An error occurred. Check internet connection!");
      console.log(err);
      this.setState({ activityIndicatorAnimating: false });
    }
  };

  styles = {
    buttontext: {
      fontWeight: "bold",
      color: "#000"
    },
    button: {
      alignSelf: "stretch",
      alignItems: "center",
      padding: 20,
      backgroundColor: "#A8CF45",
      marginTop: 30
    },
    textinput: {
      alignSelf: "stretch",
      height: 40,
      marginBottom: 30,
      color: "#FFFFFF",
      borderBottomColor: "#FFFFFF",
      backgroundColor: "#aaa",
      borderRadius: 15,
      borderBottomEndRadius: this.state.name.length > 0 ? 0 : 15,
      borderTopEndRadius: this.state.name.length > 0 ? 0 : 15,
      borderBottomWidth: 0,
      color: "black",
      paddingHorizontal: 10
    },
    header: {
      fontSize: 24,
      color: "#FFFFFF",
      paddingBottom: 10,
      marginBottom: 40
    },
    header2: {
      fontSize: 24,
      color: "#FFFFFF",
      paddingBottom: 10,
      marginBottom: 10
    },
    regform: {
      alignSelf: "stretch",
      // marginLeft: 40,
      // marginRight: 40,
    },
    container: {
      flex: 1,
      justifyContent: "center",
      alignSelf: 'center',
      alignItems: "center",
      //backgroundColor: "black", // "#00A859",
      paddingLeft: 1,
      paddingRight: 1,
      borderBottomColor: "black",
      maxWidth: 400
    }
  };
  render() {
    const animating = this.state.activityIndicatorAnimating;
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#000000" }}>
        <TouchableWithoutFeedback accessible={false}>
          <View style={this.styles.container}>
            <View
              style={{
                flex: 12,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {(global.app == "market" || global.app == "health") && <Image
                style={{ width: 160, height: 100 }}
                source={require("../../images/logos/home_logo.png")}
              />}
              {(global.app == "artisan") && <Image
                style={{
                  width: 150,
                  height: 150,
                  alignSelf: "center",
                  marginBottom: -20,
                  marginTop: -30
                }}
                source={require("../../custom/Artisan/images/logos/TAN-logo.png")}
              />}
              {(global.app == "speakers") && <Image
                style={{
                  width: 60,
                  height: 60,
                  alignSelf: "center",
                  tintColor: "#fff"
                }}
                source={require("../../custom/Speakers/images/logos/SC-Logo.png")}
              />}
              {(global.app == "education") && <Image
                style={{
                  width: 150,
                  height: 150,
                  alignSelf: "center",
                  marginBottom: -20,
                  marginTop: -30,
                  tintColor: "white"
                }}
                source={require("../../custom/Education/images/logos/WE-Logo.png")}
              />}
            </View>
            <Text style={[this.styles.header, { color: global.app == "artisan" ? "#ffd307" : global.app == "speakers" ? "#fff" : "#008866" }]}>
              {global.app != "artisan" ? global.app == "speakers" ? "Welcome to Speakers Connect" : "Welcome to Wakanda!" : "Welcome!"}
            </Text>
            <View style={[this.styles.regform, { flex: 22 }]}>
              <ActivityIndicator
                size="small"
                color="#FFFFFF"
                style={{
                  opacity: this.state.activityIndicatorAnimating ? 1.0 : 0.0,
                  marginBottom: 10
                }}
                animating={true}
              />
              <View style={{ flexDirection: "row" }}>
                <View style={{ padding: 1, alignItems: "center", flex: 1 }}>
                  <TextInput
                    style={[this.styles.textinput]}
                    placeholder="Enter your personal name here"
                    placeholderTextColor="#000"
                    //keyboardType="phone-pad"
                    textAlign="center"
                    underlineColor={"transparent"}
                    underlineColorAndroid="transparent"
                    onChangeText={name => this.setState({ name })}
                    returnKeyType="send"
                    onSubmitEditing={this.callSet}
                  />
                </View>
                {this.state.name.length > 0 && (
                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      alignSelf: "flex-start",
                      padding: 1,
                      backgroundColor: "#A8CF45",
                      marginHorizontal: 4.5,
                      width: 40,
                      height: 40,
                      justifyContent: "center",
                      borderTopEndRadius: 20,
                      borderBottomEndRadius: 20
                    }}
                    onPress={this.callSet}
                  >
                    <Image
                      style={{
                        width: 18,
                        height: 18,
                        marginRight: 1
                      }}
                      source={require("../../images/icons/Send.png")}
                    />
                  </TouchableOpacity>
                )}
              </View>
              {/* <TouchableOpacity style={styles.button} onPress={this.callSet}>
                <Text style={styles.buttontext}>SET!</Text>
              </TouchableOpacity> */}
            </View>
            <View style={{ height: this.state.keyboardSpacerHeight }} />
            {this.state.showGetPicture && (
              <GetPicture
                onDone={async pictureURL => {
                  this.setState({ showGetPicture: false });
                  console.log("PictureURL", pictureURL);
                  await new Promise(resolve => { setTimeout(resolve, 100); });
                  if (!Array.isArray(global.myDiscounts)) await new Promise(resolve => { setTimeout(resolve, 1000); });
                  if (global.hasSignupReferrals)
                    this.props.navigation.navigate("homeNewReferrals");
                  else this.props.navigation.navigate("homeMain", { justSignedIn: true });
                }}
                item={"PROFILE_PICTURE"}
              />
            )}
          </View>
        </TouchableWithoutFeedback>
        <ImageBackground
          style={{
            width: Dimensions.get("window").width, height: Dimensions.get("window").height,
            position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: -1, opacity: 0.15
          }}
          source={require("../../custom/Speakers/images/logos/SC-Background.jpg")}
        />
      </SafeAreaView>
    );
  }
}



import React, { Component } from "react";
import {
    Text,
    View,
    ScrollView,
    Image,
    TouchableOpacity,
    SafeAreaView,
    StatusBar,
    Platform,
    ActivityIndicator
} from "react-native";
import NetInfo from '@react-native-community/netinfo';
import firebase from "firebase";
import "firebase/firestore";
import { NavigationEvents } from 'react-navigation'

import Post from "../../Home/Post/Post";

export default class ChatGroupSelectOffer extends Component {
    static navigationOptions = {
        tabBarVisible: false,
        headerTransparent: true,
        title: "My Offers",
        headerTitleStyle: {
            fontWeight: "bold",
            color: "#A8CF45",
            shadowOpacity: 1
        }
    };
    state = {
        data: null,
        showPostDetail: false,
        postIDs: [],
        loading: true,
        selected: false,
        selectedIDs: [],
        count: 0
    };
    constructor() {
        super();
        const firestore = firebase.firestore();
        //const fSettings = {timestampsInSnapshots: true };
        //firestore.settings(fSettings);
        this.unsubscribeFirestore = () => { return; };
    }

    async componentDidMount() {
        try {
            if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 200); });
            if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 500); });
            if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 1000); });
            if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 2000); });
            if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 2000); });
            if (!global.myUID) await global.setGlobals();

            this.unsubscribeFirestore = await firebase
                .firestore()
                .collection("Profiles")
                .doc(global.myProfileID)
                .onSnapshot(async s => {
                    this.updatePosts(s);
                });
            global.loadingSuccessMePosts = true;
        } catch (err) {
            global.loadingSuccessMePosts = false;
            global.warn(err, "ChatGroupSelectOfferA1")
        }
    }

    componentWillUnmount() {
        this.unsubscribeFirestore();
    }

    updatePosts = async profileSnapshot => {
        try {
            if (profileSnapshot.data().profileNameBusiness) {
                let ids = profileSnapshot.data().postIDs;
                this.sortablePostIDs = ids;
                this.setState({ postIDs: ids, loading: false });

            } else {
                this.setState({ hasBusiness: false, loading: false });
            }
        } catch (err) {
            console.log("Error updating posts (probably network)");
        }
    };

    sortablePostIDs = [];
    lastUpdated = {};
    reorder = (id, timestamp) => {
        this.lastUpdated[id] = timestamp;
        this.sortablePostIDs.sort((a, b) => {
            if (!a in this.lastUpdated) {
                this.lastUpdated[a] = 0;
            }
            if (!b in this.lastUpdated) {
                this.lastUpdated[b] = 0;
            }
            return this.lastUpdated[b] - this.lastUpdated[a];
        });
        this.setState({
            postIDs: this.sortablePostIDs
        });
    };

    selectedPost = (id) => {
        let idArray = this.state.selectedIDs
        if (idArray.includes(id)) {
            idArray.splice(idArray.indexOf(id), 1)
            this.setState({ selected: false, count: this.state.count - 1 })
        }
        else {
            this.setState({ selected: true, count: this.state.count + 1 })
            idArray.push(id)
        }
        this.setState({ selectedIDs: idArray })
    }

    render() {

        let group = this.props.navigation.getParam('group')
        return (
            <SafeAreaView style={{ flex: 1, backgroundColor: "#131313" }}>
                <StatusBar barStyle="light-content" />

                <View style={{
                    backgroundColor: "#131313", flex: 1,
                }}>
                    <View
                        style={{
                            backgroundColor: "#131313",
                            borderBottomColor: "#555555",
                            borderBottomWidth: 1,
                            ...Platform.select({
                                ios: {
                                    height: 50
                                },
                                android: {
                                    height: 75
                                }
                            })
                        }}
                    />
                    {this.state.count > 0 && <Text style={{ fontSize: 20, color: '#fff' }}>{this.state.count == 1 ? `${this.state.count} offer selected` : `${this.state.count} offers selected`}</Text>}
                    <ScrollView contentContainerStyle={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        flexWrap: 'wrap'
                    }}>
                        {this.state.postIDs.map(id => {
                            return (<TouchableOpacity
                                onPress={() => {
                                    this.selectedPost(id)
                                }}
                                style={{
                                    width: 300,
                                    height: 300,
                                    margin: -65,
                                    transform: [{ scale: 0.5 }],
                                }}
                                key={id}
                            >
                                <Post
                                    key={id}
                                    postID={id}
                                    navigation={this.props.navigation}
                                    showDetail={this.state.showPostDetail}
                                    hideAuthor={true}
                                    onHideDetail={this.state.showPostDetail}
                                    relevantStatus={"LIVE"}
                                    onIsNotRelevant={() => {
                                        // this.setState({ visible: false });
                                    }}
                                    loadingColor={"#333"}
                                    onDataLoaded={data => {

                                        this.setState({ data });
                                    }}
                                />
                                {this.state.selectedIDs.includes(id) &&
                                    <View style={{
                                        alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.7)', width: 300, height: 300, position: 'absolute', borderRadius: 25
                                    }}>
                                        <Image source={require('../../images/icons/yes_tick.png')} style={{ width: 100, height: 100, }} />
                                    </View>}
                            </TouchableOpacity>
                            );
                        })}
                        <View style={{ height: 80 }} />
                    </ScrollView>
                    <View style={{ flexDirection: 'row', justifyContent: "space-between", marginBottom: 20 }}>
                        <TouchableOpacity onPress={() => { this.props.navigation.navigate('chatGroupDetail', { group: group }) }} style={{ marginLeft: 10 }} >
                            <Text style={{ color: '#fff', fontSize: 20 }}>Cancel</Text>
                        </TouchableOpacity>
                        {this.state.selectedIDs.length >= 1 &&
                            <TouchableOpacity onPress={() => { this.props.navigation.navigate('chatGroupDetail', { postIDs: this.state.selectedIDs, group: group }), this.setState({ selectedIDs: [], count: 0 }) }} style={{ marginRight: 10 }}>
                                <Text style={{ color: '#fff', fontSize: 20 }}>Select</Text>
                            </TouchableOpacity>}
                    </View>
                    {this.state.postIDs.length == 0 && (
                        <View
                            style={{
                                flex: 4,
                                alignItems: "center",
                                justifyContent: "flex-start"
                            }}
                        >
                            {!this.state.loading ? (
                                <View style={{ alignItems: "center" }}>
                                    <Image
                                        source={require("../../images/icons/Post.png")}
                                        style={{ opacity: 0.4, marginBottom: 10 }}
                                    />
                                    <Text
                                        style={{
                                            color: "#999",
                                            fontSize: 18,
                                            fontStyle: "italic",
                                            padding: 25,
                                            textAlign: "center"
                                        }}
                                    >
                                        {this.state.hasBusiness
                                            ? "No " + (global.a.offers || "offers") + " yet"
                                            : "Once you open a shop in your profile, you can post " + (global.a.offers || "offers") + " here."}
                                    </Text>
                                </View>
                            ) : (
                                    <ActivityIndicator
                                        size="large"
                                        color="#A8CF45"
                                        style={{
                                            opacity: 1
                                        }}
                                        animating={true}
                                    />
                                )}
                        </View>
                    )}
                    <NavigationEvents
                        onDidFocus={async payload => {
                            //this.setState({ showNotificationHandler: true });
                        }}
                    />
                </View>
            </SafeAreaView >
        );
    }
}
import React, { Component, PureComponent } from "react";
import {
  Platform,
  StyleSheet,
  Text,
  TextInput,
  View,
  ScrollView,
  Dimensions,
  Image,
  ImageBackground,
  Keyboard,
  Modal,
  ActivityIndicator,
  Alert,
  SectionList,
  TouchableOpacity,
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
//import { Slider } from "react-native-elements";
class Slider extends Component { render() { return (<View />) } };
import firebase from "firebase";
import "firebase/firestore";
import CategorySelector from "../CategorySelectorV2"
import CurrentLocation from "../../shared/Map/currentLocationV2";
import CurrentLocationModal from "../../shared/Map/currentLocationV2Modal";
import GetPicture from "../../shared/Camera/GetPicture";

class CheckboxSelector extends Component {
  render() {
    let height =
      this.props.text.length < 45
        ? 30
        : 45 + (this.props.text.length - 45) * 0.33;
    let circle = filled => {
      if (filled) {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#9999a4", backgroundColor: "#334", borderWidth: 0, borderRadius: 10 }}>
            <Text style={{ fontSize: 24, marginTop: -5, color: global.app == "speakers" ? "#fff" : "#ccd" }}>✓</Text>
          </View>
        );
      } else {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#556", borderWidth: 0, borderRadius: 10 }} />
        );
      }
    };

    return (
      <View style={{ height: height, flexDirection: "row", alignItems: "center" }}>
        {circle(this.props.selected)}
        <Text style={{ marginLeft: 10, color: global.app == "speakers" ? "#fff" : "#ccd", fontWeight: this.props.selected ? "normal" : "normal" }}>{this.props.text}</Text>
      </View>
    );
  }
}

export default class SpecialRequestScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Special Request",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      fontSize: 20,
      shadowOpacity: 1
    }
  };
  constructor(props) {
    super(props);
    this.state = {
      category1: "",
      category2: "",
      location: global.location ? { coords: global.location.coords, address: "Loading Location" } : {
        coords: {
          latitude: 6.465422,
          longitude: 3.406448
        },
        address: "Loading Location"
      },
      value1: 0.5,
      value2: 0.5,
      value3: 0.5,
      value4: 0.5,
      valueAll: 4,
      checked: true,
      color: "green",
      text: "",
      showPreferenceSelection: false,
      showSkillSelection: global.app == "speakers",
      budget: "",
      budgetCurrency: global.myLocalCurrency || "NGN",
      skillsSelected: global.skills,
      keyboardSpacerHeight: 0,
      pictureURL: null,
      showGetPicture: false,
      modalCurrencyVisible: false,
      showLocationModal: false,
      currencies: [],
      activityIndicatorAnimating: false,

      view: "MAIN",
      numberOfResults: 0
    };

    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    this.keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      this._keyboardDidShow
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      this._keyboardDidHide
    );
    const category1 = this.props.navigation.getParam("category1", null);
    const category2 = this.props.navigation.getParam("category2", null);
    if (category1) this.setState({ category1 })
    if (category2) this.setState({ category2 })

    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 200); });
    if (!global.myUID || !global.walletType) await new Promise(resolve => { setTimeout(resolve, 500); });
    if (!global.myUID || !global.walletType) await new Promise(resolve => { setTimeout(resolve, 1000); });
    if (!global.myUID || !global.walletType) await new Promise(resolve => { setTimeout(resolve, 2000); });
    if (!global.myUID || !global.walletType) await new Promise(resolve => { setTimeout(resolve, 3000); });
    if (!global.myUID || !global.walletType) await new Promise(resolve => { setTimeout(resolve, 4000); });
    if (!global.myUID || !global.walletType) await new Promise(resolve => { setTimeout(resolve, 5000); });
    if (!global.myUID || !global.walletType) await new Promise(resolve => { setTimeout(resolve, 8000); });
    if (!global.myUID || !global.walletType) await new Promise(resolve => { setTimeout(resolve, 10000); });
    if (!global.myUID || !global.walletType) await global.setGlobals();
    if (global.walletType) {
      if (global.walletType == "ACN") this.setState({ budgetCurrency: "GHS", currencies: ["ACN: ACoin", "GHS: Ghana Cedi", "NGN: Nigerian Naira"] })
      if (global.walletType == "NGN") this.setState({ budgetCurrency: "NGN", currencies: ["NGN: Nigerian Naira", "GHS: Ghana Cedi"] })
    } else {
      while (!global.walletType) {
        await new Promise(resolve => { setTimeout(resolve, 800); });
      }
      if (global.walletType == "ACN") this.setState({ budgetCurrency: "GHS", currencies: ["ACN: ACoin", "GHS: Ghana Cedi", "NGN: Nigerian Naira"] })
      if (global.walletType == "NGN") this.setState({ budgetCurrency: "NGN", currencies: ["NGN: Nigerian Naira", "GHS: Ghana Cedi"] })
    }
    if (!global.location) {
      await global.setLocationFast(5)
    }
    const address = await global.getAddress()
    this.setState({ location: { coords: global.location.coords, address } })

  }

  componentWillUnmount() {
    console.log("Component signin will unmount");

    if (this.keyboardDidShowListener) this.keyboardDidShowListener.remove();
    if (this.keyboardDidHideListener) this.keyboardDidHideListener.remove();
  }

  _keyboardDidShow = async e => {
    console.log("Keyboard Shown");
    if (this.myScrollView) this.myScrollView.scrollTo({ x: 0, y: 800, animated: true });
    if (Platform.OS === "ios") {
      this.setState({ keyboardSpacerHeight: e.endCoordinates.height - 70 });
    }
  };

  _keyboardDidHide = () => {
    console.log("Keyboard Hidden");
    this.setState({ keyboardSpacerHeight: 0 });
  };

  callRequest = () => {
    const budget = Number(this.state.budget) || 0
    if (this.state.activityIndicatorAnimating) return
    if (!this.state.category1) return alert("Select a provider category at the top of the screen");
    if (!this.state.location) return alert("Enter a location");
    if (!this.state.text || this.state.text.length < 7) return alert("Please enter your requirements!")
    if (!global.myUID) return alert("Error. Restart the app if this persists.");
    if (this.state.category2 == "Public Speakers" && (!budget || budget == NaN || (typeof budget != "number") || !(budget > 0))) {
      if (typeof budget != "number") return alert("Budget must be a number")
      else return alert("Enter your budget")
    }
    if ((this.state.category2 == "Public Speakers" || this.state.category2 == "Teachers - Primary" || this.state.category2 == "Teachers - Secondary" || this.state.category2 == "Teachers - Tertiary")
      && (this.state.skillsSelected && this.state.skillsSelected.length == 0)) {
      return alert("Select at least one skill")
    }

    const data = {
      valNearYou: this.state.value1,
      valAffordable: this.state.value2,
      valQuality: this.state.value3,
      valProfessional: this.state.value4,
      inquiryText: this.state.text,
      inquiryImage: this.state.pictureURL,
      budget: budget,
      ...(budget > 0 && { budgetCurrency: this.state.budgetCurrency || "NGN" }),
      skills: this.state.category2 ? this.state.skillsSelected[this.state.category2.replace(/[^a-zA-Z]/g, "")] : null
    };

    if (!global.isConnected) return alert("No internet connection")
    Alert.alert(
      "Send this request to ˜20 matching providers now?", "",
      [
        { text: "Cancel", onPress: () => console.log("Cancel Pressed"), style: "cancel" },
        { text: "YES", onPress: () => { this.callRequestExecute(data); } }
      ],
      { cancelable: false }
    );
  };

  // callRequestExecute = async data => {
  //   console.log("SENDING REQUEST::", {
  //     coords: this.state.location.coords,
  //     valNearYou: data.valNearYou,
  //     valAffordable: data.valAffordable,
  //     valQuality: data.valQuality,
  //     valProfessional: data.valProfessional,
  //     category1: this.state.category1,
  //     category2: this.state.category2,
  //     myUID: global.myUID,
  //     idToken: global.idToken,
  //     inquiryText: data.inquiryText ? data.inquiryText : null,
  //     inquiryImageURL: data.inquiryImage ? data.inquiryImage : null,
  //     ...((data.skills) && {
  //       budget: data.budget || 0,
  //       budgetCurrency: data.budgetCurrency || "NGN",
  //       skills: data.skills
  //     }),
  //   })
  // }
  callRequestExecute = async data => {
    console.log("Starting to call findProviders");
    try {
      this.setState({ activityIndicatorAnimating: true });

      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "findProviders2", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          amount: 30,
          coords: this.state.location.coords,
          valNearYou: data.valNearYou,
          valAffordable: data.valAffordable,
          valQuality: data.valQuality,
          valProfessional: data.valProfessional,
          category1: this.state.category1,
          category2: this.state.category2,
          myUID: global.myUID,
          idToken: global.idToken,
          inquiryText: data.inquiryText ? data.inquiryText : null,
          inquiryImageURL: data.inquiryImage ? data.inquiryImage : null,
          ...((data.skills) && {
            budget: data.budget || 0,
            budgetCurrency: data.budgetCurrency || "NGN",
            skills: data.skills
          }),
        })
      });
      const r2 = await r.json();

      // if success, show results
      console.log("Received response. ", r2.msg);
      this.setState({ activityIndicatorAnimating: false });
      if (r2.msg === "SUCCESS") {
        console.warn("Success response, unexpected here!");
      } else if (r2.msg === "INQUIRIES_CREATED") {
        console.log("Successful inquiry creation. response: ", r2);
        console.log(
          "number of providers contacted: ",
          r2.nOfProvidersContacted
        );
        if (r2.nOfProvidersContacted == 0) {
          alert("Unfortunately, we did not find a suitable provider for your request.");
          return;
        }
        this.setState({ view: "RESULT", numberOfResults: r2.nOfProvidersContacted })
        return;
      } else {
        console.log(r2.msg);
        await new Promise(resolve => {
          setTimeout(resolve, 50);
        });
        alert("Request failed. Try again later.");
        await new Promise(resolve => {
          setTimeout(resolve, 50);
        });
      }
    } catch (e2) {
      await new Promise(resolve => { setTimeout(resolve, 50); });
      alert("An error occurred. Please try again");
      await new Promise(resolve => { setTimeout(resolve, 50); });
      console.log(e2);
      this.setState({ activityIndicatorAnimating: false });
    }
  };

  adjustPickers = (value, picker) => {
    let sum = 2;
    let delta = 0;
    switch (picker) {
      case 1:
        sum = value + this.state.value2 + this.state.value3 + this.state.value4;
        delta = sum - 2;
        new2 = Math.min(Math.max(this.state.value2 - delta / 3, 0), 1);
        new3 = Math.min(Math.max(this.state.value3 - delta / 3, 0), 1);
        new4 = Math.min(Math.max(this.state.value4 - delta / 3, 0), 1);
        this.setState({
          value1: value,
          value2: new2,
          value3: new3,
          value4: new4
        });
        break;
      case 2:
        sum = value + this.state.value1 + this.state.value3 + this.state.value4;
        delta = sum - 2;
        new1 = Math.min(Math.max(this.state.value1 - delta / 3, 0), 1);
        new3 = Math.min(Math.max(this.state.value3 - delta / 3, 0), 1);
        new4 = Math.min(Math.max(this.state.value4 - delta / 3, 0), 1);
        this.setState({
          value1: new1,
          value2: value,
          value3: new3,
          value4: new4
        });
        break;
      case 3:
        sum = value + this.state.value1 + this.state.value2 + this.state.value4;
        delta = sum - 2;
        new1 = Math.min(Math.max(this.state.value1 - delta / 3, 0), 1);
        new2 = Math.min(Math.max(this.state.value2 - delta / 3, 0), 1);
        new4 = Math.min(Math.max(this.state.value4 - delta / 3, 0), 1);
        this.setState({
          value1: new1,
          value2: new2,
          value3: value,
          value4: new4
        });
        break;
      case 4:
        sum = value + this.state.value1 + this.state.value2 + this.state.value3;
        delta = sum - 2;
        new1 = Math.min(Math.max(this.state.value1 - delta / 3, 0), 1);
        new2 = Math.min(Math.max(this.state.value2 - delta / 3, 0), 1);
        new3 = Math.min(Math.max(this.state.value3 - delta / 3, 0), 1);
        this.setState({
          value1: new1,
          value2: new2,
          value3: new3,
          value4: value
        });
        break;
    }
  };

  togglePreferenceView = () => {
    if (this.state.showPreferenceSelection == true) {
      this.setState({ showPreferenceSelection: false });
    } else {
      this.setState({ showPreferenceSelection: true });
    }
  };

  render() {

    let budgetSuggestion = this.state.budgetCurrency == "GHS" ? "e.g. 500" : "e.g. 70000"

    let viewProviderPreferences = true
    if (this.state.category2 == "Public Speakers" || this.state.category2 == "Teaching - Primary" ||
      this.state.category2 == "Teaching - Secondary" || this.state.category2 == "Teaching - Tertiary") {
      viewProviderPreferences = false
    }
    //ALTERNATIVE:  const keys = Object.keys(global.skills)
    //ALTERNATIVE:  const cat2s = this.state.category2 ? this.state.category2.replace(/[^a-zA-Z]/g, "") : "";
    //ALTERNATIVE:  if (keys.includes(cat2s)) {viewProviderPreferences = false}
    let viewSelectImage = true
    if (this.state.category2 == "Public Speakers" || this.state.category2 == "Teaching - Primary" ||
      this.state.category2 == "Teaching - Secondary" || this.state.category2 == "Teaching - Tertiary") {
      viewSelectImage = false
    }
    let viewSkills = false
    if (this.state.category2 == "Public Speakers" || this.state.category2 == "Teaching - Primary" ||
      this.state.category2 == "Teaching - Secondary" || this.state.category2 == "Teaching - Tertiary") {
      viewSkills = true
    }
    let viewBudget = false
    if (this.state.category2 == "Public Speakers" || this.state.category2 == "Teaching - Primary" ||
      this.state.category2 == "Teaching - Secondary" || this.state.category2 == "Teaching - Tertiary") {
      viewBudget = true
    }
    let yourBudgetString = "Your Budget:"
    if (this.state.category2 == "Teaching - Primary") {
      yourBudgetString = "Your Budget (per hour):"
      budgetSuggestion = this.state.budgetCurrency == "GHS" ? "e.g. 40" : "e.g. 4000"
    }
    if (this.state.category2 == "Teaching - Secondary") {
      yourBudgetString = "Your Budget (per hour):"
      budgetSuggestion = this.state.budgetCurrency == "GHS" ? "e.g. 50" : "e.g. 6000"
    }
    if (this.state.category2 == "Teaching - Tertiary") {
      yourBudgetString = "Your Budget (per hour):"
      budgetSuggestion = this.state.budgetCurrency == "GHS" ? "e.g. 200" : "e.g. 20000"
    }

    let placeholderString = "E.g. I need locally used Corolla 2008-2011, good condition."
    if (this.state.category1 == "Handyman") placeholderString = "E.g. I need a professional plumber on Wednesday and Thursday"
    if (this.state.category1 == "Professionals") placeholderString = "E.g. I need a motivational speaker for our annual company event on December 11"
    if (this.state.category2 == "Public Speakers") placeholderString = "E.g. I need a motivational speaker for our annual company event on December 12"
    if (this.state.category2 == "Legal") placeholderString = "E.g. I need a lawyer specialized in small business setup"
    if (this.state.category2 == "Marketing") placeholderString = "E.g. I need somebody to plan a nationwide radio campaign"
    if (this.state.category2 == "Digital Marketing") placeholderString = "E.g. I need a YouTobe campaign for my Wakanda website"
    if (this.state.category2 == "Graphics Design") placeholderString = "E.g. I need a graphics designer skilled in animation"
    if (this.state.category2 == "Writing / Content") placeholderString = "E.g. I need someone to write my fashion blog content"
    if (this.state.category2 == "Translation") placeholderString = "E.g. I need a translator English-French for business meetings"
    if (this.state.category2 == "Video") placeholderString = "E.g. I need a professional wedding movie, the wedding is on April 5th"
    if (this.state.category2 == "Animation") placeholderString = "E.g. I need someone to create an animated product ad"
    if (this.state.category2 == "Photography") placeholderString = "E.g. I need a wedding photographer"
    if (this.state.category2 == "Music") placeholderString = "E.g. I need a DJ for our wedding"
    if (this.state.category2 == "Marketing Materials, Printing, Branding") placeholderString = "E.g. How much is 500 flyers A5 color double-sided?"
    if (this.state.category2 == "Software Development") placeholderString = "E.g. I need an NodeJS developer, minimum 2 years experience"
    if (this.state.category2 == "Website Development") placeholderString = "E.g. I need a wedding website"
    if (this.state.category2 == "App Development") placeholderString = "E.g. I need a react native frontend developer, minimum 3 years experience"
    if (this.state.category2 == "Computer & IT Services") placeholderString = "E.g. I need someone who can install and maintain shop management software"
    if (this.state.category2 == "PR") placeholderString = "E.g. I need someone to write press releases for beauty products, 2 days per week"
    if (this.state.category2 == "General Business") placeholderString = "E.g. I need a professional business advisor in the field of ..."
    if (this.state.category2 == "Facility Management") placeholderString = "E.g. I need someone handling fuel and gas supply for our office"
    if (this.state.category2 == "Teaching - Primary") placeholderString = "E.g. I need an maths teacher to help my son with homework twice a week"
    if (this.state.category2 == "Teaching - Secondary") placeholderString = "E.g. I need an maths teacher to help my son with homework twice a week"
    if (this.state.category2 == "Teaching - Tertiary") placeholderString = "E.g. I need expert advise on the subject of general economics for my business"
    if (this.state.category2 == "Training & Education") placeholderString = "E.g. I need a sales trainer for a technology company"
    if (this.state.category2 == "Training - Soft Skills") placeholderString = "E.g. I need a sales trainer for a technology company"
    if (this.state.category2 == "Coaching") placeholderString = "E.g. I need a wholistic business and life coach"
    if (this.state.category2 == "Visa & Concierge") placeholderString = "E.g. I need a business visa to Canada"
    if (this.state.category2 == "Events & Catering") placeholderString = "E.g. I need a professional wedding organizer"
    if (this.state.category2 == "Wedding Planning") placeholderString = "E.g. I need a professional wedding planner"
    if (this.state.category2 == "Trade Services") placeholderString = "E.g. Looking for an advisor with import/export experience"
    if (this.state.category2 == "Financial & Investment") placeholderString = "E.g. I need somebody experienced with corporate retirement schemes"
    if (this.state.category2 == "Insurances") placeholderString = "E.g. I need fire insurance for our office"
    if (this.state.category2 == "Loans") placeholderString = "E.g. I need a loan of xxx for 3 months"
    if (this.state.category2 == "Business Consulting") placeholderString = "E.g. I need an experienced HR consultant to hire a sales force"
    if (this.state.category2 == "IT Consulting") placeholderString = "E.g. I need an SAP CRM consultant"
    if (this.state.category2 == "Fun / Entertainment") placeholderString = "E.g. I need a comedian or singer for our wedding"
    if (this.state.category2 == "Other Services") placeholderString = "E.g. I need an experienced HR consultant to hire a sales force"

    if (this.state.category1 == "Health") placeholderString = "E.g. I need a ... doctor to treat my ... pain."
    if (this.state.category2 == "Pharmacies") placeholderString = "E.g. I need Malanil 500mg by today."
    if (this.state.category2 == "Labs") placeholderString = "E.g. How much is a CT scan?"
    if (this.state.category2 == "General Doctors / Primary Clinics") placeholderString = "E.g. I need a doctor to treat my headache"

    if (this.state.category1 == "Beauty") placeholderString = "E.g. I need 10m fabrics in our green/white."
    if (this.state.category1 == "Commercial") placeholderString = "E.g. I need 5 machines of type ... (new or used) by the end of the month."
    if (this.state.category1 == "Repair_Refill") placeholderString = "E.g. I need somebody who can replace my phone screen (model ...)."
    if (this.state.category1 == "Food") placeholderString = "E.g. I need a caterer for my event of 100 people on December 6th."
    if (this.state.category2 == "Cakes & Bakery") placeholderString = "E.g. I need a birthday cake by tomorrow."
    if (this.state.category1 == "Art") placeholderString = "E.g. Anybody selling violins?"
    if (this.state.category1 == "Travel") placeholderString = "E.g. Anybody offers trips to the North?"
    if (this.state.category1 == "Home") placeholderString = "E.g. I need an event location for a workshop of 100 people on December 8th."

    // || category2 == "Spare Parts" || category2 == "Car Insurance" || category2 == "Car Loans" || category2 == "Catering") {
    // return this.setState({ action: "SPECIAL" });

    let titleStyle = { color: "#A8CF45", fontSize: 16, fontWeight: "bold", marginTop: 20 }

    if (this.state.view == "RESULT") return (
      <View style={{ backgroundColor: "#223", paddingLeft: 10, paddingRight: 10, justifyContent: "center", alignItems: "center", height: "100%" }}>
        <Text style={{ color: "#bbc", fontSize: 20, textAlign: "center", marginBottom: 10 }}>Success.</Text>
        <Text style={{ color: "#bbc", fontSize: 16, textAlign: "center", marginBottom: 25 }}>Your request has been sent to {this.state.numberOfResults} providers.</Text>
        <Text style={{ color: "#889", fontSize: 16, textAlign: "center", marginBottom: 25 }}>If they are interested, they will contact you and you can have a discussion or order their service.</Text>
      </View>);
    return (
      <View style={{ backgroundColor: "#223", height: "100%", ...Platform.select({ ios: { paddingTop: Dimensions.get("window").height >= 800 ? 88 : 65 }, android: { paddingTop: 85 } }) }}>
        <ScrollView style={{ width: "100%", padding: 16 }}
          //keyboardShouldPersistTaps={'always'}
          keyboardDismissMode={'on-drag'}
          ref={ref => (this.myScrollView = ref)}
          scrollEventThrottle={0}
          onScroll={(e) => {
            if (Platform.OS == 'android') Keyboard.dismiss()
            let paddingToBottom = 100;
            paddingToBottom += e.nativeEvent.layoutMeasurement.height;
            if (e.nativeEvent.contentOffset.y >= e.nativeEvent.contentSize.height - paddingToBottom) {
              if (this.moreToLoad) {
                this.loadPostResults2()
                this.moreToLoad = false
              }
            }
          }}
        >
          <View style={{ marginVertical: 10 }}>
            <Text style={{ color: "#bbc", fontSize: 14 }}>Specify your needs below and suitable providers will message you.</Text>
          </View>
          <Text
            underlineColorAndroid="transparent"
            style={[titleStyle]}
          >Category:
          </Text>
          <View style={{ marginRight: -15 }}>
            <CategorySelector
              noText={true}
              //text={"Select a Category"}
              backButton={false}
              backgroundColor={"#223"}
              category1={this.state.category1}
              category2={this.state.category2}
              immediatelyRequestCategory2={(this.state.category1 && !this.state.category2)}
              onSelectCategory={async (category1, category2) => {
                this.setState({ category1, category2 });
              }}
            />
          </View>
          <View style={{ height: 5 }} />


          <Text
            underlineColorAndroid="transparent"
            style={titleStyle}
          >
            Location:
          </Text>
          <CurrentLocation
            onPressEdit={() => { this.setState({ showLocationModal: true }) }}
            setLiveLocation={false}
            textColor={"#ccc"}
            location={this.state.location}
            textColor={"#fff"}
            textSize={12}
            //changeColor={"#ccc"}
            padding={0}
          />


          <View style={styles.header}>
            <Text
              underlineColorAndroid="transparent"
              style={titleStyle}
            >
              Your Requirements:
          </Text>
          </View>
          <View>
            <TextInput
              returnKeyType={"done"}
              //onSubmitEditing={this.callRequest}
              onChangeText={text => this.setState({ text })}
              textAlignVertical="top"
              underlineColorAndroid="transparent"
              multiline={true}
              numberOfLines={2}
              style={styles.textArea}
              bordered
              keyboardAppearance={"dark"}
              placeholder={placeholderString}
              placeholderTextColor="#557"
            />
          </View>

          {viewBudget && <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 10, marginTop: -10 }}>
            <Text
              underlineColorAndroid="transparent"
              style={{ color: "#fff", fontSize: 14 }}
            >{yourBudgetString || "Your Budget:"}</Text>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <TouchableOpacity
                onPress={() => this.setState({ modalCurrencyVisible: true })}
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <View
                  style={{
                    color: "#ffffff", borderColor: "#334", borderWidth: 1, padding: 3, borderRadius: 15, width: 30, backgroundColor: "#000", alignItems: "center", marginHorizontal: 1
                  }}
                >
                  <Text style={{ color: "#ccc" }}>{global.currencySymbols[this.state.budgetCurrency]}</Text>
                </View>
              </TouchableOpacity>
              <TextInput
                onChangeText={budget => {
                  this.setState({ budget });
                }}
                placeholder={budgetSuggestion}
                placeholderTextColor={"#557"}
                keyboardType="numeric"
                style={{
                  color: "#ffffff",
                  padding: 3,
                  borderRadius: 5,
                  width: 100,
                  backgroundColor: "#000",
                  textAlign: "center"
                }}
                maxLength={50}
                underlineColorAndroid="transparent"
                returnKeyType={"done"}
                defaultValue={this.state.budget}
                bordered
              />
            </View>
          </View>}

          {viewSelectImage && <View style={{ flex: 1, padding: 4, backgroundColor: '#333851', marginBottom: 10, borderRadius: 5 }}>
            {this.state.showGetPicture && (

              <GetPicture
                onDone={async pictureURL => {
                  this.setState({ showGetPicture: false });
                  console.log("PictureURL", pictureURL);
                  if (pictureURL) {
                    this.setState({ pictureURL: null });
                    await new Promise(resolve => {
                      setTimeout(resolve, 50);
                    });
                    this.setState({ pictureURL });
                  }
                }}
                item={"INQUIRY_PICTURE"}
              />
            )}

            <TouchableOpacity
              style={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 5
              }}
              onPress={() => { this.setState({ showGetPicture: true }) }}
            >
              <View style={{ alignItems: "flex-end" }}>
                <Image
                  style={{ width: 25, height: 25, marginRight: 2 }}
                  source={require("../../images/icons/Attach.png")}
                />
              </View>
              <View>
                <Text underlineColorAndroid="transparent" style={styles.header3}>
                  Attach an Image
            </Text>
              </View>
              {!!this.state.pictureURL && <View style={{ alignItems: "flex-end" }}>
                <View
                  style={{ width: 60, height: 60, marginLeft: 10, marginBottom: -60, backgroundColor: "#333", borderWidth: 2, borderColor: 'white' }}
                  source={{ uri: this.state.pictureURL }}
                />
                <Image
                  style={{ width: 60, height: 60, marginLeft: 10, borderWidth: 2, borderColor: 'white' }}
                  source={{ uri: this.state.pictureURL }}
                />
              </View>}
              {!!this.state.pictureURL && <TouchableOpacity onPress={() => { this.setState({ pictureURL: null }) }} style={{ alignItems: "center", justifyContent: "center" }}>
                <Image
                  style={{ width: 27, height: 27, marginLeft: 10 }}
                  source={require("../../images/icons/Trash.png")}
                />

              </TouchableOpacity>}

            </TouchableOpacity>
          </View>}


          {viewProviderPreferences && <TouchableOpacity
            style={{ flexDirection: "row" }}
            onPress={this.togglePreferenceView}
          >
            <View style={{ flex: 7 }}>
              <Text underlineColorAndroid="transparent" style={styles.header2}>
                Specify Provider Preferences
            </Text>
            </View>
            <View style={{ flex: 3, alignItems: "flex-end" }}>
              <Image
                style={{ width: 20, height: 20, tintColor: "#ccc", marginRight: 3 }}
                source={require("../../images/icons/adjust-panel.png")}
              />
            </View>
          </TouchableOpacity>}

          {viewProviderPreferences && this.state.showPreferenceSelection && (
            <View>
              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 3 }}>
                  <Text
                    style={{
                      color: "#C0C0C0",
                      fontSize: 14,
                      textAlign: "justify",
                      lineHeight: 30
                    }}
                  >
                    Near you
                </Text>
                </View>
                <View style={{ flex: 7 }}>
                  <Slider
                    ref={ref => (this.slider1 = ref)}
                    thumbStyle={{
                      height: 15,
                      width: 15,
                      marginTop: -1,
                      borderColor: "#A8CF45",
                      borderWidth: 2
                    }}
                    minimumTrackTintColor={"#A8CF45"}
                    trackStyle={{ height: 1 }}
                    thumbTintColor={"#000"}
                    value={this.state.value1}
                    onValueChange={value => {
                      this.adjustPickers(value, 1);
                    }}
                  />
                </View>
              </View>

              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 3 }}>
                  <Text
                    style={{
                      color: "#C0C0C0",
                      fontSize: 14,
                      textAlign: "justify",
                      lineHeight: 30
                    }}
                  >
                    Affordable
                </Text>
                </View>
                <View style={{ flex: 7 }}>
                  <Slider
                    thumbStyle={{
                      height: 15,
                      width: 15,
                      marginTop: -1,
                      borderColor: "#A8CF45",
                      borderWidth: 2
                    }}
                    minimumTrackTintColor={"#A8CF45"}
                    trackStyle={{ height: 1 }}
                    thumbTintColor={"#000"}
                    value={this.state.value2}
                    onValueChange={value => {
                      this.adjustPickers(value, 2);
                    }}
                  />
                </View>
              </View>

              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 3 }}>
                  <Text
                    style={{
                      color: "#C0C0C0",
                      fontSize: 14,
                      textAlign: "justify",
                      lineHeight: 30
                    }}
                  >
                    Quality
                </Text>
                </View>
                <View style={{ flex: 7 }}>
                  <Slider
                    thumbStyle={{
                      height: 15,
                      width: 15,
                      marginTop: -1,
                      borderColor: "#A8CF45",
                      borderWidth: 2
                    }}
                    minimumTrackTintColor={"#A8CF45"}
                    trackStyle={{ height: 1 }}
                    thumbTintColor={"#000"}
                    value={this.state.value3}
                    onValueChange={value => {
                      this.adjustPickers(value, 3);
                    }}
                  />
                </View>
              </View>

              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 3 }}>
                  <Text
                    style={{
                      color: "#C0C0C0",
                      fontSize: 14,
                      textAlign: "justify",
                      lineHeight: 30
                    }}
                  >
                    Professional
                </Text>
                </View>
                <View style={{ flex: 7 }}>
                  <Slider
                    thumbStyle={{
                      height: 15,
                      width: 15,
                      marginTop: -1,
                      borderColor: "#A8CF45",
                      borderWidth: 2
                    }}
                    minimumTrackTintColor={"#A8CF45"}
                    trackStyle={{ height: 1 }}
                    thumbTintColor={"#000"}
                    value={this.state.value4}
                    onValueChange={value => {
                      this.adjustPickers(value, 4);
                    }}
                  />
                </View>
              </View>
            </View>
          )}

          {viewSkills && <TouchableOpacity
            style={{ flexDirection: "row" }}
            onPress={() => this.setState({ showSkillSelection: !this.state.showSkillSelection })}
          >
            <View style={{ flex: 7 }}>
              <Text underlineColorAndroid="transparent" style={styles.header2}>
                {!this.state.showSkillSelection ? "▶" : "▼"} Specify field of expertise
            </Text>
            </View>
            {/* <View style={{ flex: 3, alignItems: "flex-end" }}>
              <Image
                style={{ width: 20, height: 20, tintColor: "#ccc", marginRight: 3 }}
                source={require("../../images/icons/adjust-panel.png")}
              />
            </View> */}
          </TouchableOpacity>}

          {viewSkills && this.state.showSkillSelection && <View style={{}}>
            {this.state.category2 && global.skills[this.state.category2.replace(/[^a-zA-Z]/g, "")].map((skill) => {
              return (
                <TouchableOpacity key={skill} activeOpacity={1}
                  onPress={() => {
                    cat2s = this.state.category2 ? this.state.category2.replace(/[^a-zA-Z]/g, "") : "-"
                    if (this.state.category2 && this.state.skillsSelected[cat2s].includes(skill)) {
                      const newArray = this.state.skillsSelected[cat2s].filter((s) => { return (s != skill) })
                      const newOb = global.duplicate(this.state.skillsSelected)
                      newOb[cat2s] = newArray
                      this.setState({ skillsSelected: newOb });
                    } else {
                      const newArray = this.state.skillsSelected[cat2s].filter((s) => { return true })
                      newArray.push(skill)
                      const newOb = global.duplicate(this.state.skillsSelected)
                      newOb[cat2s] = newArray
                      this.setState({ skillsSelected: newOb });
                    }
                  }}
                >
                  <CheckboxSelector
                    selected={this.state.category2 && this.state.skillsSelected[this.state.category2.replace(/[^a-zA-Z]/g, "")].includes(skill)}
                    text={skill}
                  />
                </TouchableOpacity>
              )
            })}
          </View>}


          {/* <View style={{ flexDirection: 'row', width: '100%', backgroundColor: '#fff', alignSelf: 'center', marginTop: 10 }}>
          <CurrentLocation
            onUpdate={(address, coords)=> {this.setState({
              location: {address: address, coords: coords}
            })}} />
        </View> */}
          {!this.state.activityIndicatorAnimating ? <TouchableOpacity
            style={[styles.button, global.gStyles.buttonXL, { padding: 0 }]}
            onPress={this.callRequest}
          >
            <Text style={styles.buttontext}>SEND REQUEST</Text>
          </TouchableOpacity> :
            <View style={[styles.button, global.gStyles.buttonXL, { padding: 0, opacity: 0.5 }]}><ActivityIndicator size="small" color="#131313" animating={true} /></View>
          }
          <View style={{ marginBottom: 45 }}>
            <Text style={{ color: "#fff", fontSize: 14 }}>ⓘ Your requirements will be sent to 20 {global.app == "speakers" ? "speakers" : "providers"} around you who can then respond.</Text>
          </View>
        </ScrollView>
        {global.app == "speakers" && <ImageBackground
          style={[styles.imageFixed, styles.imageContainter, { zIndex: -1, opacity: 0.25 }]}
          source={require("../../custom/Speakers/images/logos/SC-Background.jpg")}
        />}
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.modalCurrencyVisible}
          onRequestClose={() => {
            this.setState({ modalCurrencyVisible: false });
          }}
        >
          <TouchableOpacity
            onPress={() =>
              this.setState({ modalCurrencyVisible: false })
            }
            style={{ height: 200 }}
          />
          <View
            style={{
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ccd"
              }}
            >
              <View style={{ paddingTop: 50 }}>
                <Text style={{ fontSize: 30 }}>Select a currency</Text>
              </View>
              <View style={{ marginTop: 35, marginBottom: 5 }}>
                {/* <Image
                    style={{ width: 100, height: 100, borderRadius: 33 }}
                    source={this.images[this.state.category1]}
                  /> */}
              </View>
              <SectionList
                renderItem={({ item, index, section }) => (
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({
                        modalCurrencyVisible: false,
                        budgetCurrency: item.substring(0, 3)
                      });
                    }}
                    style={{
                      flex: 1,
                      paddingTop: 10,
                      paddingBottom: 10,
                      //alignItems: "center",
                      paddingHorizontal: 10,
                      width: Dimensions.get("window").width,
                      backgroundColor: "white",
                      borderWidth: 1,
                      borderColor: "#ccd"
                    }}
                  >
                    <Text style={{ fontSize: 18 }} key={index}>
                      {item}
                    </Text>
                  </TouchableOpacity>
                )}
                renderSectionHeader={({ section: { title } }) => (
                  <Text style={{ fontWeight: "bold" }}>{title}</Text>
                )}
                sections={[
                  {
                    title: "",
                    data: this.state.currencies
                  }
                ]}
                keyExtractor={(item, index) => item + index}
              />
            </View>
          </View>
        </Modal>
        {!!this.state.showLocationModal && <CurrentLocationModal
          location={this.state.location}
          onCancel={() => { this.setState({ showLocationModal: false }) }}
          onSelect={(coords, address) => {
            // console.log("upda", address, coords);
            this.setState({
              showLocationModal: false,
              location: {
                address: address,
                coords: coords
              }
            });
          }}
        />}
      </View>
    );
  }
}



const styles = ({
  container: {
    flex: 1
  },
  header: {
    paddingBottom: 10,
    marginTop: 10,
    alignItems: "stretch",
    alignSelf: "stretch"
  },

  textArea: {
    marginBottom: 20,
    color: "rgb(255,255,255)",
    borderColor: "#669",
    borderWidth: 0,
    paddingLeft: 5,
    borderRadius: 5,
    height: 120,
    backgroundColor: "#000"
  },
  header2: {
    fontSize: 14,
    color: "#ffffff",
    paddingBottom: 15,
    marginTop: 8
  },
  header3: {
    fontSize: 14,
    color: "#fff",

  },

  text: {
    color: "#C0C0C0",
    fontSize: 14,
    textAlign: "justify",
    lineHeight: 30,
    flex: 3
  },
  buttontext: {
    fontWeight: "bold",
    color: "#000"
  },
  button: {
    alignSelf: "stretch",
    alignItems: "center",
    padding: 15,
    backgroundColor: "#A8CF45",
    borderRadius: 5,
    marginBottom: 35,
    marginTop: 20
  },
  imageContainter: {
    width: Dimensions.get("window").width, //for full screen
    height: Dimensions.get("window").height //for full screen
  },
  imageFixed: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
});

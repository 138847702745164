import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  SafeAreaView,
  Keyboard,
  ActivityIndicator,
  Dimensions,
  Platform,
  ScrollView,
  Image,
  Alert,
  Modal
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";

import GetPicture from "../../shared/Camera/GetPicture";
import CurrentLocation from "../../shared/Map/currentLocationV2";
import CurrentLocationModal from "../../shared/Map/currentLocationV2Modal";
const gStyles = global.gStyles //    "../../styles/gStyles";
import { GetAPIPicture } from "../../shared/Camera/APIPictures"

const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;

const tStyle = { color: "#A8CF45", fontSize: 16, fontWeight: "800", textAlign: "left", marginTop: 11, marginBottom: 7 }


export default class MeBlogNewScreen extends Component {
  static navigationOptions = {
    title: "Blog",
    headerTransparent: true,
    headerTintColor: "#A8CF45",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1,
      marginTop: 20,
    }
  };

  state = {}; // will be declared in cdm
  blog = {}

  // opennessSelection = [{
  //   type: "PUBLIC",
  //   title: "Public",
  //   description: "Anybody can find and join this community"
  // },
  // {
  //   type: "PRIVATE",
  //   title: "Private",
  //   description: "The community is only for selected members"
  // }]

  componentDidMount() {
    this.loadBackgrounds()
    const action = this.props.navigation.getParam("action", "new")
    if (action == "edit") {
      this.blog = this.props.navigation.getParam("blog", {})
      if (!this.blog) this.props.navigation.navigate.goBack()
    }

    this.setState({
      action,
      title: this.blog.blogTitle || "",
      subtitle: this.blog.blogSubtitle || "",
      description: this.blog.blogDescription || "",
      //interests: this.blog.interests || ["Cars", "Food"],
      showCategories: false,
      category1: this.blog.category1 || 'Choose a category',
      pictureURL: this.blog.blogPictureURL || null,
      backgroundPictureURL: this.blog.blogBackgroundPictureURL || 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2Fwood1280.jpg?alt=media&token=55b7c07a-3f36-4cab-b1bc-ae9668ad40e9',
      headerPictureURL: this.blog.blogHeaderPictureURL || 'https://firebasestorage.googleapis.com/v0/b/wakandamarketweb.appspot.com/o/Blog%20Photos%2Fadult-1807500_1920.jpg?alt=media&token=1be3b13f-00ff-4d84-b22f-a08879f72d2e',
      backgroundPictureDarken: this.blog.blogBackgroundPictureDarken || false,
      //openness: "PUBLIC",
      keyboardSpacerHeight: 56,
      activityIndicatorAnimating: false,
      showGetPicture: false,
      showGetAPIPicture: false,
      pictureToChange: "",
      modalVisible: false,
      location: this.blog.locationCoords ? { coords: this.blog.locationCoords, address: this.blog.locationAddress || "Show on map" } : global.myLocation || {},
      showLocationModal: false,
      //modalAVisible: false,
      //activeAModal: "",
      //activeASelection: [],
    })
  };

  loadBackgrounds = async () => {
    try {


    } catch (e) { global.warn(e, 'loadBackgrounds') }
  }

  toggleCategory = () => {
    this.setState({ showCategories: !this.state.showCategories })
  }

  chooseCategory = (cat1) => {
    this.setState({ category1: cat1, showCategories: false })
  }

  // activateASelection = (ob) => {
  //   //console.log("this.state.activeAModal", this.state.activeAModal)
  //   this.setState({ modalAVisible: false })
  //   const type = ob.type
  //   console.log("selected type", type)
  //   if (this.state.activeAModal == "openness") {
  //     this.setState({ openness: type })
  //     // if (this.state.activeAModal == "aType") {
  //     //   this.setState({ aType: type })
  //     // }
  //   }
  // }

  createBlog = async () => {
    if (this.state.activityIndicatorAnimating) { return }
    if (this.state.category1 == null) { alert('Select a category'); return }
    if (!this.state.title) { alert('Select the blog title'); return }
    if (!this.state.description) { alert('Enter a description'); return }
    if (!this.state.pictureURL) { alert('Select a logo'); return }

    try {
      this.setState({ activityIndicatorAnimating: true });
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "blog", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "create",
          blogTitle: this.state.title,
          blogSubtitle: this.state.subtitle || "",
          blogDescription: this.state.description || "",
          blogPictureURL: this.state.pictureURL,
          // openness: this.state.openness,
          blogBackgroundPictureURL: this.state.backgroundPictureURL,
          blogHeaderPictureURL: this.state.headerPictureURL,
          blogBackgroundPictureDarken: this.state.backgroundPictureDarken,
          location: this.state.location,
          myProfileID: global.myProfileID,
          category1: this.state.category1,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();

      // if success, navigate to Offers screen
      console.log("Received response. ", r2.msg);
      this.setState({ activityIndicatorAnimating: false });
      if (r2.msg === "SUCCESS") {
        this.props.navigation.goBack()
        await global.timeout(500)
        alert("Blog created")
      }
      else {
        alert("Not successful, try again")
      }
    } catch (e2) {
      alert("An error occurred. Please try again");
      global.warn(e2, "createBlog");
      this.setState({ activityIndicatorAnimating: false });
    }
  }
  updateBlog = async () => {
    if (this.state.activityIndicatorAnimating) { return }
    if (!this.blog.xID) { return }
    if (!this.state.category1) { alert('Select a category'); return }
    if (!this.state.title) { alert('BlogTitle is required'); return }
    if (!this.state.description) { alert('A description is required'); return }
    if (!this.state.pictureURL) { alert('Select a blog picture'); return }

    try {
      this.setState({ activityIndicatorAnimating: true });
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "blog", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "editBlog",
          blogID: this.blog.xID,
          changes: {
            blogTitle: this.state.title,
            blogSubtitle: this.state.subtitle || "",
            blogDescription: this.state.description || "",
            blogPictureURL: this.state.pictureURL,
            blogBackgroundPictureURL: this.state.backgroundPictureURL,
            blogHeaderPictureURL: this.state.headerPictureURL,
            blogBackgroundPictureDarken: this.state.backgroundPictureDarken,
            location: this.state.location,
            category1: this.state.category1
          },
          myUID: global.myUID,
          idToken: global.idToken,

        })
      });
      const r2 = await r.json();

      // if success, navigate to Offers screen
      console.log("Received response. ", r2.msg);
      this.setState({ activityIndicatorAnimating: false });
      if (r2.msg === "SUCCESS") {
        this.props.navigation.goBack()
        await global.timeout(500)
        alert("Blog updated")
      }
      else {
        alert("Not successful, try again")
      }
    } catch (e2) {
      alert("An error occurred. Please try again");
      global.warn(e2, "updateBlog");
      this.setState({ activityIndicatorAnimating: false });
    }
  }

  render() {
    if (!this.state.action) return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "black" }}>
        <Text style={{ color: "#555" }}>Loading...</Text>
      </View >
    );
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#000" }}>
        <ScrollView ref={ref => (this.scrollView = ref)}
          style={{ backgroundColor: "black", flex: 1 }}>
          {/***** Background Image *****/}
          <View
            style={{
              height: 180,
              justifyContent: "flex-start",
              backgroundColor: "transparent",
              alignItems: "center",
              marginBottom: 0
            }}
          >

          </View>
          <View
            style={{
              flex: 1,
              backgroundColor: "#222",
              justifyContent: "space-between",

            }}
          >

          </View>
          <View
            style={{
              ...Platform.select({
                ios: {
                  height: 0,
                  marginBottom: -100
                },
                android: {
                  height: 85,
                  backgroundColor: "rgba(44, 44, 44, 0.3)",
                  marginTop: -180,
                  marginBottom: -5
                }
              })
            }}
          />
          <View
            style={{
              height: 152,
              alignItems: "center"
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: SCREEN_WIDTH * 0.9 + 2,
                height: 152,
                borderRadius: 32,
                backgroundColor: "#888",
                shadowOpacity: 1,
                shadowColor: "white",
                shadowOffset: { height: 0, width: 0 },
                shadowRadius: 32
              }}
            >
              {!!this.state.pictureURL && (
                <Image
                  key={this.state.pictureURL}
                  style={{
                    width: SCREEN_WIDTH * 0.9,
                    height: 150,
                    borderRadius: 32
                  }}
                  source={{
                    uri: this.state.pictureURL
                  }}
                />
              )}
            </View>
          </View>

          <View
            style={{ alignItems: "center", marginTop: -35, marginBottom: 20 }}
          >
            {this.state.showGetPicture && (
              <GetPicture
                onDone={async pictureURL => {
                  if (pictureURL) {
                    if (this.state.pictureToChange == "blog") this.setState({ pictureURL });
                    if (this.state.pictureToChange == "background") this.setState({ backgroundPictureURL: pictureURL, backgroundPictureDarken: true });
                    if (this.state.pictureToChange == "header") this.setState({ headerPictureURL: pictureURL });
                  }
                  this.setState({ showGetPicture: false });
                  console.log("PictureURL", pictureURL);
                }}
                item={this.state.pictureToChange == "header" ? "LARGE_WIDE_PICTURE" : "LARGE_PICTURE"}
                noCrop
              />
            )}

            <View style={{ alignItems: "center", marginTop: -16, marginBottom: 1 }}>
              <TouchableOpacity
                onPress={() => {
                  this.setState({ showGetAPIPicture: true, pictureToChange: "blog" });
                }}
                style={{
                  backgroundColor: "rgba(220, 220, 220, 0.8)",
                  padding: 3,
                  borderRadius: 5,
                }}
              >
                <Text style={{ color: "black" }}> Select Picture </Text>
              </TouchableOpacity>
              <View style={{ height: 15 }} />
              <TouchableOpacity
                onPress={() => {
                  this.setState({ showGetPicture: true, pictureToChange: "blog" });
                }}
                style={{
                  backgroundColor: "rgba(220, 220, 220, 0.8)",
                  padding: 3,
                  borderRadius: 5
                }}
              >
                <Text style={{ color: "black" }}>Upload Picture</Text>
              </TouchableOpacity>
            </View>
          </View>

          {/***** Title *****/}
          <View
            style={{
              alignItems: "flex-start",
              marginHorizontal: 20,
              flex: 1,
              alignItems: "stretch",

            }}
          >
            <Text style={[tStyle, { alignSelf: "flex-start" }]}>Title</Text>
            <TextInput
              placeholder="Blog Title"
              placeholderTextColor="#666666"
              onChangeText={title => {
                this.setState({ title });
              }}
              onFocus={() => {
                this.scrollView.scrollTo({ x: 0, y: 50, animated: true });
              }}
              style={{
                color: "#ffffff",
                borderColor: "grey",
                borderWidth: 0,
                paddingLeft: 20,
                borderRadius: 10,
                height: 45,
                backgroundColor: "#334",
              }}
              underlineColorAndroid="transparent"
              maxLength={32}
              defaultValue={this.state.title}
              bordered
            />
          </View>

          {/***** Subtitle *****/}
          <View
            style={{
              alignItems: "flex-start",
              marginHorizontal: 20,
              flex: 1,
              alignItems: "stretch",
            }}
          >

            <Text style={[tStyle, { alignSelf: "flex-start" }]}>Subtitle</Text>

            <TextInput
              placeholder="Blog Subtitle"
              placeholderTextColor="#666666"
              onChangeText={subtitle => {
                this.setState({ subtitle });
              }}
              onFocus={() => {
                this.scrollView.scrollTo({ x: 0, y: 50, animated: true });
              }}
              style={{

                color: "#ffffff",
                borderColor: "grey",
                borderWidth: 0,
                paddingLeft: 20,
                borderRadius: 10,
                height: 45,
                backgroundColor: "#334",


              }}
              underlineColorAndroid="transparent"
              maxLength={80}
              defaultValue={this.state.subtitle}
              bordered
            />
          </View>



          {/***** Blog Description *****/}
          <View
            style={{
              alignItems: "flex-start",
              marginHorizontal: 20,
              flex: 1,
              alignItems: "stretch",
              marginVertical: 1,
            }}
          >
            <Text style={[tStyle, { alignSelf: "flex-start" }]}>Description</Text>
            <TextInput
              multiline={true}
              placeholder="Blog Description"
              placeholderTextColor="#666666"
              onChangeText={description => {
                this.setState({ description });
              }}
              onFocus={() => {
                this.scrollView.scrollTo({ x: 0, y: 50, animated: true });
              }}
              style={{
                marginBottom: 5,
                color: "#ffffff",
                borderColor: "grey",
                borderWidth: 0,
                paddingLeft: 20,
                borderRadius: 10,
                height: 120,
                backgroundColor: "#334",
              }}
              underlineColorAndroid="transparent"
              defaultValue={this.state.description}
              bordered
            />

            {/* {this.state.action == "new" && <View>
              <Text style={[tStyle, { alignSelf: "flex-start" }]}>Type</Text>
              <TouchableOpacity onPress={() => this.setState({ modalAVisible: true, activeAModal: "openness", activeASelection: this.opennessSelection })}>
                <ASelector selection={this.opennessSelection} selected={this.state.openness} />
              </TouchableOpacity>
            </View>} */}

            <Text style={[tStyle, { alignSelf: "flex-start" }]}>{"Location  &  Category"}</Text>
            <View style={{ borderRadius: 7.5, width: "100%", backgroundColor: "#18181f", }}>
              <CurrentLocation
                onPressEdit={() => { this.setState({ showLocationModal: true }) }}
                setLiveLocation={true}
                textColor={"#ccd"}
                iconColor={"#ccd"}
                location={this.state.location || global.location}
                textSize={12}
                //changeColor={"#ccc"}
                padding={0}
              />
              <View style={{ height: 2 }} />
            </View>
            <View>
              <TouchableOpacity style={{ borderRadius: 7.5, width: "100%", backgroundColor: "#18181f", marginVertical: 10 }} onPress={() => { this.toggleCategory() }} >
                <Text style={{ color: '#aab', padding: 8, alignSelf: 'center' }}>{this.state.category1}</Text>
              </TouchableOpacity>
              <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>

                {this.state.showCategories && global.categoryKeys.map(cat1 => {
                  return (
                    <View key={cat1}>
                      <TouchableOpacity style={{ justifyContent: 'flex-end', height: 30, width: 120, backgroundColor: '#222222', margin: 7, borderRadius: 10 }} onPress={() => { this.chooseCategory(cat1) }} >
                        <Text style={{ alignSelf: 'center', color: '#ffffff', fontWeight: 'bold', marginBottom: 5 }}>{cat1} </Text>
                      </TouchableOpacity>
                    </View>
                  )
                })}
              </View>
            </View>

            {/***** Background Picture *****/}
            <View
              style={{
                alignItems: "flex-start",
                marginTop: 7,
                flex: 1,
                alignItems: "stretch",
              }}
            >
              <Text style={[tStyle, { alignSelf: "flex-start" }]}>Background Picture (for mobile)</Text>

              {!!this.state.backgroundPictureURL ? <Image
                key={this.state.backgroundPictureURL}
                style={{
                  width: SCREEN_WIDTH * 0.9,
                  height: 200,
                  borderRadius: 32,
                  opacity: this.state.backgroundPictureDarken ? 0.5 : 1
                }}
                source={{
                  uri: this.state.backgroundPictureURL
                }}
              />
                :
                <View
                  style={{
                    width: SCREEN_WIDTH * 0.9,
                    height: 200,
                    borderRadius: 32,
                    backgroundColor: "#f0f4f7"
                  }}
                />
              }
              <View style={{ alignItems: "center", marginTop: -45, marginBottom: 8 }}>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showGetAPIPicture: true, pictureToChange: "background" });
                  }}
                  style={{
                    backgroundColor: "rgba(220, 220, 220, 0.8)",
                    padding: 3,
                    borderRadius: 5
                  }}
                >
                  <Text style={{ color: "black" }}> Select Picture </Text>
                </TouchableOpacity>
                <View style={{ height: 15 }} />
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showGetPicture: true, pictureToChange: "background" });
                  }}
                  style={{
                    backgroundColor: "rgba(220, 220, 220, 0.8)",
                    padding: 3,
                    borderRadius: 5
                  }}
                >
                  <Text style={{ color: "black" }}>Upload Picture</Text>
                </TouchableOpacity>
              </View>
            </View>
            <TouchableOpacity onPress={() => {
              if (!this.state.backgroundPictureDarken) this.setState({ backgroundPictureDarken: true })
              else Alert.alert("Sure?", "We strongly recommend you keep the background dark.",
                [{ text: "Keep Darkening", onPress: () => { } },
                { text: "I know what I'm doing", onPress: () => { this.setState({ backgroundPictureDarken: false }) } }],
                { cancelable: true });
            }}>
              <View style={{ height: 40 }} >
                <CheckboxSelector selected={this.state.backgroundPictureDarken} text={"Darken background"} />
              </View>
            </TouchableOpacity>

            {/***** Header Picture *****/}
            <View
              style={{
                alignItems: "flex-start",
                marginTop: 7,
                flex: 1,
                alignItems: "stretch",
              }}
            >
              <Text style={[tStyle, { alignSelf: "flex-start" }]}>Header Picture (for web)</Text>

              {!!this.state.headerPictureURL ? <Image
                key={this.state.headerPictureURL}
                style={{
                  width: SCREEN_WIDTH * 0.9,
                  height: 150,
                  borderRadius: 32
                }}
                source={{
                  uri: this.state.headerPictureURL
                }}
              /> :
                <View
                  style={{
                    width: SCREEN_WIDTH * 0.9,
                    height: 150,
                    borderRadius: 32,
                    backgroundColor: "#f0f4f7"
                  }}
                />
              }
              <View style={{ alignItems: "center", marginTop: -45, marginBottom: 30 }}>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showGetAPIPicture: true, pictureToChange: "header" });
                  }}
                  style={{
                    backgroundColor: "rgba(220, 220, 220, 0.8)",
                    padding: 3,
                    borderRadius: 5
                  }}
                >
                  <Text style={{ color: "black" }}> Select Picture </Text>
                </TouchableOpacity>
                <View style={{ height: 15 }} />
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showGetPicture: true, pictureToChange: "header" });
                  }}
                  style={{
                    backgroundColor: "rgba(220, 220, 220, 0.8)",
                    padding: 3,
                    borderRadius: 5
                  }}
                >
                  <Text style={{ color: "black" }}>Upload Picture</Text>
                </TouchableOpacity>
              </View>
            </View>

            <View style={{ height: 30 }} />
            {!this.state.activityIndicatorAnimating ? <TouchableOpacity
              onPress={() => { this.state.action == "new" ? this.createBlog() : this.updateBlog() }}
            >
              <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: 120, alignSelf: "center", alignItems: "center" }]} >
                <Text style={{ fontSize: 14, fontWeight: "bold", color: "#000" }}>{this.state.action == "new" ? "OK, Create" : "Update"}</Text>
              </View>
            </TouchableOpacity>
              :
              <ActivityIndicator
                size="large"
                color="#A8CF45"
                style={{ alignSelf: "center" }}
                animating={true}
              />}
          </View>
          <View style={{ height: 100 }} />
        </ScrollView>

        {/* <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.modalAVisible}
          onRequestClose={() => { this.setState({ modalAVisible: false }); }}
        >
          <TouchableOpacity
            onPress={() => this.setState({ modalAVisible: false })}
            style={{ flex: 1 }}
          />
          <View
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <View
              style={{
                alignItems: "center", justifyContent: "center", backgroundColor: "#ccd", borderTopEndRadius: 15, borderTopLeftRadius: 15
              }}
            >
              <View style={{ paddingTop: 25 }}>
                <Text style={{ fontSize: 20, fontWeight: "bold", color: "#223" }}>{this.state.activeAModal == "openness" ? "Select who can join" : "Select Type"}</Text>
              </View>
              <View style={{ marginTop: 15, marginBottom: 5 }}>
              </View>
              {this.state.activeASelection.map((x) => {
                return <TouchableOpacity key={x.type} onPress={() => { this.activateASelection(x) }}>
                  <ASelectorW ob={x} />
                </TouchableOpacity>
              })}
              <View style={{ paddingTop: 25 }} />
            </View>
          </View>
        </Modal> */}

        {
          !!this.state.showLocationModal && <CurrentLocationModal
            onCancel={() => { this.setState({ showLocationModal: false }) }}
            onSelect={(coords, address) => {
              // console.log("upda", address, coords);
              this.setState({
                showLocationModal: false,
                location: {
                  address: address,
                  coords: coords
                }
              });
            }}
          />
        }
        {this.state.showGetAPIPicture && (
          <GetAPIPicture
            backgrounds={this.state.pictureToChange == "background"}
            blogs={this.state.pictureToChange == "blog"}
            onDone={pic => {
              if (pic?.url) {
                if (this.state.pictureToChange == "blog") this.setState({ pictureURL: pic.url });
                if (this.state.pictureToChange == "background") this.setState({ backgroundPictureURL: pic.url, backgroundPictureDarken: true });
                if (this.state.pictureToChange == "header") this.setState({ headerPictureURL: pic.url });
              }
              this.setState({ showGetAPIPicture: false });
              console.log("APIPicture", pic);
            }}
          />
        )}
      </SafeAreaView>
    );
  }
}

// class ASelector extends PureComponent {
//   render() {
//     let ob = {}
//     this.props.selection.forEach((o) => {
//       if (o.type == this.props.selected) ob = o
//     })
//     return (
//       <View style={{
//         alignItems: "center", justifyContent: "center", height: 85,
//         flexDirection: "row", padding: 3,
//         borderRadius: 10, width: "100%", backgroundColor: "#18181f",
//         marginBottom: 7, marginTop: 3
//       }}>
//         <View style={{ alignItems: "flex-start", justifyContent: "center", flex: 1, paddingLeft: 10 }}>
//           <Text style={{ color: "#ccd", fontSize: 18, fontWeight: "bold" }}>{ob.title}</Text>
//           <Text style={{ color: "#99a", fontSize: 14 }}>{ob.description}</Text>
//         </View>
//         <View style={{ alignItems: "center", justifyContent: "center", width: 50 }}>
//           <Text style={{ color: "#ccc", fontSize: 16, fontWeight: "bold", color: "#889" }}>▼</Text>
//         </View>
//       </View>
//     );
//   }
// }
// class ASelectorW extends PureComponent {
//   render() {
//     const ob = this.props.ob
//     return (
//       <View style={{
//         alignItems: "center", justifyContent: "center", height: 100,
//         flexDirection: "row", padding: 3,
//         borderRadius: 7.5, width: "100%", backgroundColor: "#ccd",
//         marginVertical: 5, borderTopWidth: 1, borderTopColor: "#aab"
//       }}>
//         <View style={{ alignItems: "flex-start", justifyContent: "center", flex: 1, paddingLeft: 10 }}>
//           <Text style={{ color: "#223", fontSize: 18, fontWeight: "bold" }}>{ob.title}</Text>
//           <Text style={{ color: "#778", fontSize: 14 }}>{ob.description}</Text>
//         </View>
//         <View style={{ alignItems: "center", justifyContent: "center", width: 50 }}>
//           <Text style={{ color: "#333", fontSize: 20, fontWeight: "bold", color: "#99b" }}></Text>
//         </View>
//       </View>
//     );
//   }
// }

class CheckboxSelector extends PureComponent {
  render() {
    let height =
      this.props.text.length < 45
        ? 30
        : 45 + (this.props.text.length - 45) * 0.33;
    let circle = filled => {
      if (filled) {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#9999a4", backgroundColor: "#334", borderWidth: 0, borderRadius: 10 }}>
            <Text style={{ fontSize: 24, marginTop: -5, color: this.props.color || "#ccd" }}>✓</Text>
          </View>
        );
      } else {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#ccd", borderWidth: 0.7, borderRadius: 10 }} />
        );
      }
    };

    return (
      <View style={{ height: height, flexDirection: "row", alignItems: "center" }}>
        {circle(this.props.selected)}
        <Text style={{ marginLeft: 10, color: this.props.color || "#ccd", fontWeight: this.props.selected ? "normal" : "normal" }}>{this.props.text}</Text>
      </View>)
  }
}
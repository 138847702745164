// ************************ HOW TO USE ***********************************************************

// Prop category1 sets the category1
// Prop category2 sets the category2
// Prop categories limit the range of sectable categories (optional).
//    -> It expects a "ServicesOffered" object (see object "categories" below)
// Prop onSelectCategory returns (category1, category2) to its parent on change (MANDATORY)
//    -> will return (null, null) when "x" is pressed.
// Prop text sets the text (default: "What are you looking for?"  (optional)
// Prop noText hides the text if true  (optional)
// Proo textSize sets the size of text (optional)
// Prop backgroundColor (optional, defaults to black)
// Prop backButton shows backButton instead of editButton
// Prop showButton DEPRECATED shows a "Find worker" button. DEPRECATED (Was only used by home screen. (optional)
// Prop onPressButton DEPRECATED executes when button is pressed (optional)
// Prop initialCategories DEPRECATED sets the categories (optional)
//    -> It expects {category1, category2}

import React, { Component } from "react";
import {
  Platform,
  Text,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  Dimensions
} from "react-native";

export default class CategorySelector extends Component {
  state = {
  };

  images = {
    AC: require("../custom/Artisan/images/services/AC.png"),
    Appliances: require("../custom/Artisan/images/services/Appliances.png"),
    Curtains: require("../custom/Artisan/images/services/Curtains.png"),
    Doors: require("../custom/Artisan/images/services/Doors.png"),
    Electrical: require("../custom/Artisan/images/services/Electrical.png"),
    Flooring: require("../custom/Artisan/images/services/Flooring.png"),
    Fumigation: require("../custom/Artisan/images/services/Fumigation.png"),
    Furniture: require("../custom/Artisan/images/services/Furniture.png"),
    Gardening: require("../custom/Artisan/images/services/Gardening.png"),
    Generator: require("../custom/Artisan/images/services/Generator.png"),
    Other: require("../custom/Artisan/images/services/Other.png"),
    Painting: require("../custom/Artisan/images/services/Painting.png"),
    Plumbing: require("../custom/Artisan/images/services/Plumbing.png"),
    Walls: require("../custom/Artisan/images/services/Walls.png"),
  };

  categoryMap = {
    AC: "AC",
    Electrical: "Electrician",
    Plumbing: "Plumbing",
    Painting: "Painting",
    Generator: "Generator",
    Appliances: "Appliances",
    Fumigation: "Fumigation",
    Curtains: "Curtains, Blinds",
    Doors: "Doors, Knobs, Locks",
    Flooring: "Flooring",
    Walls: "Walls & Ceiling",
    Furniture: "Furniture Assembly",
    Gardening: "Gardening",
    Other: "General Work",
  };
  artisanCategories = [
    "AC",
    "Electrical",
    "Plumbing",
    "Painting",
    "Generator",
    "Appliances",
    "Fumigation",
    "Curtains",
    "Doors",
    "Flooring",
    "Walls",
    "Furniture",
    "Gardening",
    "Other",
  ]
  reverseCategoryMap = {}

  componentDidMount() {
    //reverse map
    const obj = this.categoryMap
    var new_obj = {};
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop))
        new_obj[obj[prop]] = prop;
    }
    this.reverseCategoryMap = new_obj;
  }

  render() {
    const iWidth = Dimensions.get("window").width / 7;

    renderCategories = () => {
      let keys = this.artisanCategories

      let array = keys.map(key => {
        return (
          <View
            key={key}
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <TouchableOpacity
              style={{ justifyContent: "center", alignItems: "center" }}
              onPress={() => {
                this.props.onSelectCategory("Handyman", this.categoryMap[key]);
              }}
            >
              <Image
                style={{
                  width: iWidth,
                  height: iWidth,
                  margin: 2,
                  borderRadius: iWidth / 3
                }}
                source={this.images[key]}
              />
              <Text style={{ color: "white", fontSize: 10 }}>{key}</Text>
            </TouchableOpacity>
          </View>
        );
      });
      return array;
    };

    return (
      <View
        style={{
          height: this.props.category2 ? 75 : this.props.noText ? iWidth * 1.7 : 150,
          marginTop: Platform.OS == 'ios' ? 0 : (this.props.backButton && this.props.category2) ? 24 : 0,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: this.props.backgroundColor
            ? this.props.backgroundColor
            : "#000",
          alignItems: "stretch",
        }}
      >
        <View
          style={{
            flex: this.props.category2 ? 160 : 280,
            alignItems: "center"
          }}
        >
          {!this.props.category2 ? (
            <View
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <View
                style={{
                  backgroundColor: this.props.backgroundColor
                    ? this.props.backgroundColor
                    : "#000",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                {!this.props.noText && <Text
                  style={{
                    color: "#fff",
                    fontSize: this.props.textSize ? this.props.textSize : 16,
                    fontStyle: "italic",
                    marginTop: 15
                  }}
                >
                  {this.props.text
                    ? this.props.text
                    : "What are you looking for?"}
                </Text>}
                <View style={{ height: iWidth * 1.7 }}>
                  <ScrollView horizontal>{renderCategories()}</ScrollView>
                </View>
              </View>
            </View>
          ) : (
              <TouchableOpacity
                onPress={() => {
                  this.props.onSelectCategory(null, null);
                }}
                style={{
                  flexDirection: "row",
                  flex: 1,
                  alignItems: "center",
                  width: Dimensions.get("window").width
                }}
              >
                {!!this.props.backButton && <View
                  style={{
                    //flex: 11,
                    alignItems: "center",
                    justifyContent: "center",
                    height: 25,
                    width: 25,
                    marginLeft: 8,
                    backgroundColor: "#000"
                  }}
                >
                  <Text style={{ fontSize: 45, color: "#fff", marginTop: -18 }}>‹</Text>
                </View>}
                <View style={{ flex: 30, alignItems: "center" }}>
                  <Image
                    style={{
                      width: iWidth,
                      height: iWidth,
                      margin: 2,
                      borderRadius: iWidth / 3
                    }}
                    source={this.images[this.reverseCategoryMap[this.props.category2]]}
                  />
                </View>
                <View style={{ flex: 80 }}>
                  {/* <Text style={{ color: "white", fontSize: 10 }}>
                    {this.props.category1}
                  </Text> */}
                  <Text style={{ color: "white", fontSize: 18 }}>
                    {this.props.category2}
                  </Text>
                </View>
                {!this.props.backButton && <View
                  style={{
                    //flex: 11,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 18,
                    height: 25,
                    width: 25,
                    marginRight: 13,
                    backgroundColor: "#000"
                  }}
                >
                  {/* <Text style={{ color: "white", fontSize: 18, fontWeight: "bold" }}>X</Text> */}
                  <Image
                    style={{ width: 20, height: 20 }}
                    source={require("../images/icons/Change.png")}
                  />
                </View>}
              </TouchableOpacity>
            )}
        </View>
      </View>
    );
  }
}

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  TextInput,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Keyboard,
  Dimensions,
  KeyboardAvoidingView,
  ActivityIndicator,
  Platform,
  Linking,
  Modal,
  Alert,
  StyleSheet
} from "react-native";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import { Link } from 'react-router-dom'
import { WebView } from 'react-native-webview'
import AsyncStorage from '@react-native-async-storage/async-storage';
const gStyles = global.gStyles //    "../../styles/gStyles";
import firebase from "firebase";
import "firebase/firestore";
import { StatusBar } from 'expo-status-bar';
import Constants from "expo-constants";
import * as Haptics from 'expo-haptics';
import { NavigationEvents } from "react-navigation";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Moment from "react-moment";
import { LinearGradient } from 'expo-linear-gradient'
import MapScreen from "../../shared/Map/mapScreen";
import Post from "../../Home/Post/Post";
import CurrentLocation from "../../shared/Map/currentLocation";
import GetPicture from "../../shared/Camera/GetPicture";
import { GetReaction, VideoMessage, calculateVideoSize } from "../Reaction";
import { RecordMessage, VoiceMessage } from "../VoiceMessage";
import CachedImage from "../../shared/CachedImage";
import * as DocumentPicker from 'expo-document-picker';
import Group from "./Group";
import BlogPost from "./BlogPost";
import { Contact, SinglePost } from '../ChatDetailScreen'
import { getLinkPreview } from 'link-preview-js';
import FullscreenImage from "../FullscreenImage"
import FeedMenu from "../../Me/Communities/FeedMenu"
import BackButton from "../../images/icons/AndroidBack.png"
class TypeField extends Component {
  state = {
    text: "",
    aa: false,
    showGetPicture: false,
    showGetReaction: false,
    pictureURL: "",
    contactID: "",
    document: null,
    postIDs: [],
    showAttachMenu: false,
    showMapModal: false,
    showContactModal: false,
    showOffersModal: false,
    showUploadingDocModal: false,
    mapCoords: null,
    mapAddress: null,
    youtubeID: null,
    youtubeThumbnail: null,
    youtubeTitle: null,
    showYouTube: false,
    urlTitle: null,
    urlLink: null,
    urlThumbnail: null,
    video: null,
    showURL: false
  };
  activeDocumentUploadID = ""


  send = async (data) => {
    const w1 = global.fetchIdToken()
    if (data?.uploadFunction) { // case VOICE MESSAGE
      const voiceURL = await data.uploadFunction()
      console.log("voiceURL: ", voiceURL)
      this.setState({ voiceURL: voiceURL, voiceDuration: data.voiceDuration })
    }
    if (!this.state.text && !this.state.pictureURL && !this.state.postIDs.length && !this.state.location && !this.state.contactID && !this.state.document && !this.state.video && !this.state.voiceURL) return;
    if (this.state.aa) return;
    this.setState({ aa: true });

    this.props.dismissKeyboard();
    const nowStamp = firebase.firestore.Timestamp.now();

    this.props.addTemporaryComment({
      parentID: this.props.parentID,
      messageData: {
        text: this.state.text,
        pictureURL: this.state.pictureURL,
        location: {
          coords: this.state.location ? this.state.location.coords : null,
          address: this.state.location ? this.state.location.address : null,
        },
        contactID: this.state.contactID,
        postIDs: this.state.postIDs,
        document: this.state.document,
        youtubeID: this.state.youtubeID,
        youtubeThumbnail: this.state.youtubeThumbnail,
        youtubeTitle: this.state.youtubeTitle,
        urlTitle: this.state.urlTitle,
        urlLink: this.state.urlLink,
        urlThumbnail: this.state.urlThumbnail,
        video: this.state.video,
        voiceURL: this.state.voiceURL,
        voiceDuration: this.state.voiceDuration
      },
      aStatus: "LIVE",
      authorProfileID: global.myProfileID,
      authorName: global.myName || "",
      authorNameBusiness: global.myBusinessName || "",
      authorPictureURL: global.myProfilePictureURL || "",
      authorCommunities: global.myCommunities || [],
      authorNetworks: global.myNetworks || [],
      timeCreated: nowStamp,
      nOfComments: 0,
      nOfLikes: 0,
      nOfDislikes: 0,
      uploading: true,
      xID: "TEMP",
      modalAVisible: false,
    });

    // console.log('inside sendddd', this.state.video);
    const messageData = {
      ...(this.state.text && { text: this.state.text }),
      ...(this.state.pictureURL && { pictureURL: this.state.pictureURL }),
      ...(this.state.location && { location: this.state.location }),
      ...(this.state.contactID && { contactID: this.state.contactID }),
      ...(this.state.postIDs.length > 0 && { postIDs: this.state.postIDs }),
      ...(this.state.document && { document: this.state.document }),
      ...(this.state.youtubeID && { youtubeID: this.state.youtubeID }),
      ...(this.state.youtubeThumbnail && { youtubeThumbnail: this.state.youtubeThumbnail }),
      ...(this.state.youtubeTitle && { youtubeTitle: this.state.youtubeTitle }),
      ...(this.state.urlTitle && { urlTitle: this.state.urlTitle }),
      ...(this.state.urlLink && { urlLink: this.state.urlLink }),
      ...(this.state.urlThumbnail && { urlThumbnail: this.state.urlThumbnail }),
      ...(this.state.video && { video: this.state.video }),
      ...(this.state.voiceURL && { voiceURL: this.state.voiceURL }),
      ...(this.state.voiceDuration && { voiceDuration: this.state.voiceDuration }),
    }
    const showURL = this.state.showURL
    try {
      this.setState({
        text: "", location: null, contactID: false, postIDs: [], youtubeID: null, pictureURL: '',
        youtubeThumbnail: null, youtubeTitle: null, showYouTube: false, urlTitle: null, urlLink: null, urlThumbnail: null, showURL: false, document: null, video: null, voiceDuration: null, voiceURL: ""
      });
      await w1
      const r = await fetch(global.cloudFunctionURL + "newComment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          isWeb: true,
          messageData,
          chatGroupID: this.props.chatGroup.xID,
          parentID: this.props.parentID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ aa: false });

      if (r2.msg !== "SUCCESS") {
        this.recoverMessageData(messageData, showURL)
        return alert("Comment failed. Try again.");
      }
      // SUCCESS
      if (this.props.parentID && this.props.parentID !== "ROOT")
        this.props.resetTypeField()

      let isFullScreen = (this.props.navigation.getParam("group", null)) ? true : false
      if (!isFullScreen && !this.props.isVideoComments) {
        global.navigateChatGroup(this.props.navigation, { group: this.props.chatGroup })
      }

    } catch (e2) {
      this.recoverMessageData(messageData, showURL)
      global.warn(e2, "ERROR%3S");
      this.setState({ aa: false });
      //alert("Oops - Message might not be sent.")
    }
  };
  recoverMessageData = (md, showURL) => {
    this.setState({
      ...(md.text && { text: md.text }),
      ...(md.pictureURL && { pictureURL: md.pictureURL }),
      ...(md.location && { location: md.location }),
      ...(md.contactID && { contactID: md.contactID }),
      ...(md.postIDs?.length > 0 && { postIDs: md.postIDs }),
      ...(md.document && { document: md.document }),
      ...(md.youtubeID && { youtubeID: md.youtubeID }),
      ...(md.youtubeThumbnail && { youtubeThumbnail: md.youtubeThumbnail }),
      ...(md.youtubeTitle && { youtubeTitle: md.youtubeTitle }),
      ...(md.urlTitle && { urlTitle: md.urlTitle }),
      ...(md.urlLink && { urlLink: md.urlLink }),
      ...(md.urlThumbnail && { urlThumbnail: md.urlThumbnail }),
      ...(md.video && { video: md.video }),
      ...(md.voiceURL && { voiceURL: md.voiceURL }),
      ...(md.voiceDuration && { voiceDuration: md.voiceDuration }),
      showURL
    })
  }

  updateTextInput = async (text) => {
    this.setState({ text })
    let youtubeID = "", searchString = ""
    if (text.toLowerCase().includes("youtu.be/")) searchString = "youtu.be/"
    if (text.toLowerCase().includes("youtube.com/watch?v=")) searchString = "youtube.com/watch?v="
    if (text.toLowerCase().includes("www.youtube.com/watch?app=desktop&v=")) searchString = "https://www.youtube.com/watch?app=desktop&v="

    if (searchString) {
      let textPostion = text.toLowerCase().indexOf(searchString)
      let idPosition = textPostion + searchString.length
      youtubeID = text.substring(idPosition, idPosition + 11)
    }

    if (youtubeID?.length == 11) {
      let isCorrect = true, r2
      try {
        const fetchString = "https://www.youtube.com/oembed?url=http://www.youtube.com/watch?v=" + youtubeID
        const r = await fetch(fetchString, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });
        r2 = await r.json();
      } catch (e) {
        isCorrect = false
      }

      if (isCorrect) await this.setState({ youtubeID: youtubeID, youtubeThumbnail: r2.thumbnail_url, youtubeTitle: r2?.title, urlThumbnail: null, urlTitle: null, urlLink: null, showURL: false, showYouTube: true })
      if (!isCorrect) await this.setState({ youtubeID: null, showYouTube: false, showYouTubeLoading: false })
    } else {
      await this.setState({ youtubeID: null, showYouTube: false, showYouTubeLoading: false })
      if (text.toLowerCase().includes('www.') && !text.includes("http:") && !text.includes("https:")) {
        text = text.toLowerCase().replace("www.", "http://www.")
      }
      getLinkPreview(text)
        .then(async (data) => {
          await this.setState({ urlThumbnail: data?.images[0], urlTitle: data?.title, urlLink: data?.url, showURL: true })
        })
        .catch((err) => { this.setState({ urlThumbnail: null, urlTitle: null, urlLink: null, showURL: false }) })

    }
  }

  executeAttachDocument = async (params) => {
    //xx tbd: delete uploaded file if upload was cancelled but still went through
    //xx tbd: delete uploaded files after 30 days or so (with users aware of it)
    const { type, uri, name, nameString, size, sizeString } = params
    try {
      this.setState({ showUploadingDocModal: true });
      const thisDocumentUploadID = Math.floor(Math.random() * 8999999999999999 + 1000000000000000);
      this.activeDocumentUploadID = thisDocumentUploadID

      const fileArray = name.split(".")
      let fileType = fileArray[fileArray.length - 1]
      if (fileType.length > 7) fileType = ""

      const storagePath =
        "ChatGroupDocuments/" +
        this.props.chatGroup.xID.substring(0, 10) +
        "__" +
        Math.floor(Math.random() * 89999999 + 10000000) + (fileType ? ("." + fileType) : "");

      const blob = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          resolve(xhr.response);
        };
        xhr.onerror = function (e) {
          console.log(e);
          reject(new TypeError("Network request failed"));
        };
        xhr.responseType = "blob";
        xhr.open("GET", uri, true);
        xhr.send(null);
      });

      const ref = firebase
        .storage()
        .ref()
        .child(storagePath);
      const snapshot = await ref.put(blob);
      const documentURL = await ref.getDownloadURL();

      if (this.state.showUploadingDocModal && this.activeDocumentUploadID == thisDocumentUploadID) { // execute only if upload hasn't been cancelled
        const document = { url: documentURL, name, type: fileType, size: sizeString }
        this.setState({ document })
        await this.send()
        this.setState({ showUploadingDocModal: false })
      }
    } catch (err) {
      this.setState({ showUploadingDocModal: false });
      await global.timeout(200)
      alert("Error while uploading. Check internet connection and try again");
      global.warn(err, "UpDoc1")
    }
  }

  attachContact = async () => {
    this.props.navigation.navigate("messageSelectContact", {
      input: this.state.profileID,
      purpose: "SelectContactToShare",
      nextScreen: this.props.navigation.state.routeName,
      group: this.props.chatGroup
    });
  }
  attachDocument = async () => {
    try {
      //if (Platform.OS == "ios") await global.timeout(400)
      // alert('launching attachDocument...')
      const { type, uri, name, size } = await DocumentPicker.getDocumentAsync()
      if (type == 'success') {
        await global.timeout(100)
        // console.log("Document selected for sharing", { type, uri, name, size })
        if (size > 20000000) return alert("File must be smaller than 20 MB")

        const sizeString = Math.round(size / 100000) / 10 + " MB"
        const nameString = name ? name.substring(0, 40) : "-"
        /* Alert.alert("Share Document?", nameString + " (" + sizeString + ")",
          [{ text: "Cancel", onPress: () => { } },
          { text: "Yes", onPress: () => { this.executeAttachDocument({ type, uri, name, nameString, size, sizeString }) } }],
          { cancelable: true }); */
        this.executeAttachDocument({ type, uri, name, nameString, size, sizeString })
      } else {
        console.log("No document picked, status " + (type || "unknown!"))
      }
    } catch (e) { global.warn(e, "Error attachDocument") }
  };

  render() {
    return (
      <View>
        {this.props.chatGroup?.options?.sortChildrenBy === "TIME_ASCENDING" && this.state.aa && (
          <View
            style={{
              flex: 1,
              // marginBottom: 10,
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <ActivityIndicator
              size="small"
              color="#fff"
              style={{
                opacity: 1
              }}
              animating={true}
            />
          </View>
        )
        }
        <View style={[this.state.text.length == 0 ? { paddingTop: 3 } : { paddingTop: 10 }, {
          flexDirection: "row", alignSelf: "stretch",
          alignItems: "center", paddingBottom: 10, ...this.props.backgroundColor && { backgroundColor: this.props.backgroundColor }
        }]}>
          <View
            style={{
              flexDirection: "row",
              // width: "100%",
              alignSelf: "stretch",
              alignItems: "center",
              paddingHorizontal: 5,
              flex: 1008866
            }}
          >
            {global.myProfilePictureURL ? (
              <CachedImage
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 15,
                  marginRight: 6
                }}
                source={{ uri: global.myProfilePictureURL }}
                key={global.myProfilePictureURL}
              />
            ) : (
              <View
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 15,
                  backgroundColor: "#668",
                  marginRight: 6
                }}
              />
            )}
            <View style={{ alignItems: "center", flex: 1 }}>
              {
                (this.state.showYouTube || this.state.showURL) &&
                <View
                  style={{ bottom: 3, alignSelf: "center", marginLeft: 20, marginRight: 15, width: '100%', borderRadius: 10, backgroundColor: '#445', position: 'relative' }} >
                  <View style={{ flexDirection: 'row', display: 'flex', }}>
                    <View style={{
                      justifyContent: 'center', alignItems: 'center',
                    }}>
                      <Image
                        style={[{
                          width: 100,
                          height: 70,
                        },
                        ]}
                        source={{
                          uri: this.state.showYouTube ? this.state.youtubeThumbnail : this.state.urlThumbnail
                        }}
                      />
                      {this.state.youtubeID && <View
                        style={{
                          position: 'absolute', backgroundColor: "rgba(198,198,198,0.6)", borderRadius: 20, paddingLeft: 6, paddingBottom: 1,
                          height: 40, justifyContent: 'center', alignItems: 'center', width: 40,
                        }}>
                        <Text style={{ color: "#000", fontSize: 25 }} >
                          {"▶️"}
                        </Text>
                      </View>}
                    </View>
                    <View style={{ marginLeft: 3, paddingTop: 5, width: '60%' }}>
                      <Text style={{ opacity: 0.8, fontSize: 12, color: '#e6e6e6', fontStyle: "italic", fontWeight: "bold", }} numberOfLines={3} >{this.state.showYouTube ? this.state.youtubeTitle : this.state.urlTitle}</Text>
                    </View>
                  </View>
                  {/* <View style={{ height: 10, width: '100%' }} /> */}
                  <TouchableOpacity style={{
                    padding: 20, right: 0, position: "absolute", flex: 1, height: '100%', alignItems: 'center', justifyContent: 'center'
                  }}
                    onPress={async () => {
                      this.setState({ youtubeID: null, youtubeThumbnail: null, youtubeTitle: null, urlTitle: null, urlThumbnail: null, urlLink: null, showYouTube: false, showURL: false })
                      await new Promise(resolve => {
                        setTimeout(resolve, 100);
                      });
                    }}>
                    <Text style={{ color: "#fff", fontWeight: 'bold', fontSize: 20, alignSelf: 'center' }} >x</Text>
                  </TouchableOpacity>
                </View>
              }
              <TextInput
                onFocus={() => { }}
                onChangeText={text => {
                  this.updateTextInput(text)
                }}
                value={this.state.text}
                placeholder={this.props.placeholder || "Type here..."}
                placeholderTextColor={"#aab"}
                style={{
                  alignSelf: "stretch",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: 12,
                  borderRadius: 15,
                  backgroundColor: "#445",
                  paddingRight: 65,
                  paddingLeft: 5,
                  paddingTop: 7,
                  minHeight: 30,
                  paddingBottom: 7
                }}
                multiline
                textAlignVertical={"center"}
                maxLength={2000}
                underlineColorAndroid="transparent"
                defaultValue={this.state.term}
                //  keyboard ShouldPersistTaps={"always"}
                //   keyboardDismissMode={"on-drag"}
                keyboardAppearance={"dark"}
                autoCompleteType={"off"}
              //clearButtonMode={"always"}
              />
            </View>
            <TouchableOpacity
              style={{
                // width: 70,
                paddingHorizontal: 10,
                padding: 1,
                alignItems: "center",
                justifyContent: "center",
                marginLeft: this.state.text?.length > 0 ? -75 : 0,
                marginRight: this.state.text?.length > 0 ? 45 : 0
              }}
              onPress={async () => {
                this.setState({ showAttachMenu: true });
              }}
            >
              <Image
                style={{
                  width: 16,
                  height: 16,
                  marginRight: 1,
                  tintColor: "#fff"
                }}
                source={require("../../images/icons/Attach.png")}
              />
            </TouchableOpacity>
            {this.state.text.length == 0 && <TouchableOpacity
              style={{
                alignItems: "center",
                padding: 1,
                paddingRight: 10,
                paddingLeft: 5,
                height: 30,
                justifyContent: "center",
              }}
              onPress={() => {
                this.setState({ showGetReaction: true });
              }}
            >
              <Image
                style={{
                  width: 18,
                  height: 18,
                  tintColor: "#fff",
                }}
                source={require("../../images/icons/React.png")}
              />
            </TouchableOpacity>}
            {this.state.text.length == 0 && <View style={{ width: 40, paddingBottom: 43 }} />}
            {this.state.text.length == 0 && <RecordMessage onSend={this.send} type={"chatGroup"} />}
            {/* {Platform.OS == "ios" && this.state.text.length == 0 && <View style={{ width: 40, paddingBottom: 43 }} />}
          {Platform.OS == "ios" && this.state.text.length == 0 && <RecordMessage onSend={this.send} type={"chatGroup"} />} */}
          </View>
          {
            this.state.text.length > 0 && (<TouchableOpacity
              style={{
                alignItems: "center",
                justifyContent: "center",
                padding: 1,
                marginLeft: -40,
                width: 40,
                alignSelf: "stretch",
                borderTopEndRadius: 12.5,
                borderBottomEndRadius: 12.5,
                borderLeftWidth: 0.5,
                borderColor: "#667",
                backgroundColor: "#445"
              }}
              onPress={() => this.send()}
            >
              <Image
                style={{
                  width: 12,
                  height: 12,
                  marginRight: 1,
                  tintColor: "white"
                }}
                source={require("../../images/icons/Send.png")}
              />
            </TouchableOpacity>)
          }
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.showAttachMenu}
            style={{}}
            onRequestClose={() => {
              //alert("Modal has been closed.");
            }}
          >
            <TouchableWithoutFeedback
              onPress={() => this.setState({ showAttachMenu: false })}
            >
              <View
                style={{
                  flex: 1,
                  height: "100%",
                  width: "100%",
                }}
              >
                <View
                  style={{
                    flex: 1,
                    padding: 20, alignItems: 'center', justifyContent: "center",
                    marginTop: 450,
                    backgroundColor: "rgba(0,0,00,.8)",
                    borderRadius: 5,
                    borderTopColor: "#A8CF45",
                    borderWidth: 1
                  }}
                >
                  <TouchableOpacity
                    style={{
                      height: 50,
                      width: 200,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 15,
                    }}
                    onPress={async () => {
                      this.setState({ showAttachMenu: false });
                      await new Promise(resolve => {
                        setTimeout(resolve, 100);
                      });
                      this.setState({ showGetPicture: true });
                    }}
                  >
                    <Text style={{ color: "#ccc", fontWeight: "bold" }}>
                      Share Picture
                    </Text>
                  </TouchableOpacity>
                  {/*  <TouchableOpacity
                    style={{
                      height: 50,
                      width: 200,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 15,
                      borderTopWidth: 1,
                      borderColor: "#A8CF45",
                    }}
                    onPress={async () => {
                      this.setState({ showAttachMenu: false });
                      await new Promise(resolve => {
                        setTimeout(resolve, 100);
                      });
                      this.attachContact()
                    }}
                  >
                    <Text style={{ color: "#ccc", fontWeight: "bold" }}>
                      Share Contact
                  </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      height: 50,
                      width: 200,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 15,
                      borderTopWidth: 1,
                      borderColor: "#A8CF45",
                    }}
                    onPress={async () => {
                      this.setState({ showAttachMenu: false });
                      await new Promise(resolve => {
                        setTimeout(resolve, 100);
                      });
                      let route = "messageSelectOffer";
                      if (this.props.navigation.state.routeName && this.props.navigation.state.routeName.startsWith("message")) route = "messageSelectOffer";
                      if (this.props.navigation.state.routeName && this.props.navigation.state.routeName.startsWith("me")) route = "meSelectOffer";
                      if (this.props.navigation.state.routeName && this.props.navigation.state.routeName.startsWith("home")) route = "homeSelectOffer";
                      this.props.navigation.navigate(route, {
                        group: this.props.chatGroup
                      });
                    }}
                  >
                    <Text style={{ color: "#ccc", fontWeight: "bold" }}>
                      Share Offer
                  </Text>
                  </TouchableOpacity> 
                  <TouchableOpacity
                    style={{
                      height: 50,
                      width: 200,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 15,
                      borderTopWidth: 1,
                      borderColor: "#A8CF45"
                    }}
                    onPress={async () => {
                      this.props.navigation.getParam("group", null) ?
                        (this.setState({ showAttachMenu: false }),
                          await new Promise(resolve => {
                            setTimeout(resolve, 100);
                          }),
                          this.setState({ showMapModal: true })) :
                        (
                          this.props.navigation.navigate("homeGroupDetail", { group: this.props.chatGroup, title: " " }),
                          this.setState({ showAttachMenu: false }),
                          await new Promise(resolve => {
                            setTimeout(resolve, 100);
                          }),
                          this.setState({ showMapModal: true })
                        )
                    }}
                  >
                    <Text style={{ color: "#ccc", fontWeight: "bold" }}>
                      Share Location
                  </Text>
                  </TouchableOpacity> */}
                  <TouchableOpacity
                    style={{
                      height: 50,
                      width: 200,
                      alignItems: "center",
                      borderTopWidth: 1,
                      borderColor: "#A8CF45",
                      justifyContent: "center",
                      borderRadius: 15
                    }}
                    onPress={async () => {
                      this.setState({ showAttachMenu: false });
                      await new Promise(resolve => {
                        setTimeout(resolve, Platform.OS == "ios" ? 500 : 100);
                      });
                      this.attachDocument();
                    }}
                  >
                    <Text style={{ color: "#ccc", fontWeight: "bold" }}>
                      Share Document
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </TouchableWithoutFeedback>
          </Modal>
          <Modal
            animationType="slide"
            transparent={false}
            visible={this.state.showMapModal}
            onRequestClose={() => {
              //leave this here
            }}
          >
            <MapScreen
              getUpdatedLocation={(address, coords) => {
                this.setState({
                  mapCoords: coords,
                  mapAddress: address
                });
              }}
            />

            <View
              style={{
                height: "5%",
                width: "15%",
                margin: 20,
                marginTop: -100,
                flexDirection: "row"
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.setState({ showMapModal: false });
                }}
              >
                <Text
                  style={{
                    fontSize: 15,
                    color: "white",
                    fontWeight: "bold",
                    backgroundColor: "grey",
                    padding: 5,
                    borderRadius: 5
                  }}
                >
                  Cancel
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={async () => {
                  await this.setState({ showMapModal: false });
                  let a = this.state.mapCoords;
                  let b = this.state.mapAddress;
                  await this.setState({
                    location: {
                      coords: a,
                      address: b
                    },
                  });
                  this.send();
                }}
              >
                <Text
                  style={{
                    fontSize: 15,
                    color: "white",
                    fontWeight: "bold",
                    backgroundColor: "green",
                    padding: 5,
                    borderRadius: 5,
                    marginLeft: 15
                  }}
                >
                  Set
                </Text>
              </TouchableOpacity>
            </View>
          </Modal>
          <Modal
            animationType="slide"
            transparent={false}
            transparent={true}
            visible={this.state.showContactModal}
            onRequestClose={() => {
              //leave this here
            }}
          >
            <View
              onPress={() => this.setState({ showContactModal: false })}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', flex: 1, }}
            >
              <View
                style={{
                  flex: 1,
                  padding: 20, alignItems: 'center', justifyContent: "center",
                  marginTop: 450,
                  backgroundColor: 'white',
                  borderRadius: 5,
                  borderColor: "#A8CF45",
                  borderWidth: 1
                }}
              >
                <View>
                  <Contact
                    contactID={this.state.contactID}
                  />
                </View>
                <View style={{ marginTop: 10 }}>
                  <TextInput
                    onFocus={() => { }}
                    onChangeText={text => {
                      this.setState({ text });
                    }}
                    value={this.state.text}
                    placeholder={"Enter a caption here..."}
                    placeholderTextColor={"#aab"}
                    style={{
                      width: 200,
                      textAlign: "left",
                      color: "#000",
                      borderBottomWidth: 1,
                      fontSize: 12,
                      paddingRight: 5,
                      paddingLeft: 5,
                    }}
                    multiline
                    maxLength={2000}
                    underlineColorAndroid="transparent"
                    defaultValue={this.state.term}
                    // keyboardShouldPersistTaps={"always"}
                    // keyboardDismissMode={"on-drag"}
                    keyboardAppearance={"dark"}
                    autoCompleteType={"off"}
                  //clearButtonMode={"always"}
                  />
                </View>
                <View
                  style={{
                    width: "100%",
                    margin: 20,
                    flexDirection: "row",
                    justifyContent: 'space-between'

                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({ showContactModal: false });
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 15,
                        color: "white",
                        fontWeight: "bold",
                        backgroundColor: "grey",
                        padding: 5,
                        borderRadius: 15
                      }}
                    >
                      Cancel
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={async () => { this.setState({ showContactModal: false }), await this.send() }}>
                    <Text
                      style={{
                        fontSize: 15,
                        color: "white",
                        fontWeight: "bold",
                        backgroundColor: "green",
                        padding: 5,
                        borderRadius: 5,
                        marginLeft: 15
                      }}>Send</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={this.state.showUploadingDocModal}
            style={{}}
            onRequestClose={() => {
              //alert("Modal has been closed.");
            }}
          >
            <TouchableWithoutFeedback
            // onPress={() => this.setState({ showUploadingDocModal: false })}
            >
              <View
                style={{
                  height: "100%",
                  width: "100%",
                  alignItems: "flex-end",
                  justifyContent: "flex-end"
                }}
              >
                <View
                  style={{
                    flex: 1,
                    width: "92%",
                    alignSelf: "center",
                    marginVertical: 20,
                    backgroundColor: "rgba(0,0,00,.9)",
                    borderRadius: 5,
                    borderColor: "#008866",
                    borderWidth: 1,
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <ActivityIndicator size="large" color={"#9ab"} />
                  <Text style={{ color: "#ccc", fontWeight: "bold", fontSize: 24, marginTop: 12 }}>
                    Uploading Document
                  </Text>
                  <Text style={{ color: "#ccc", fontWeight: "bold", marginTop: 12 }}>
                    This may take a bit...
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({ showUploadingDocModal: false })
                      this.activeDocumentUploadID = 0
                    }}
                    style={{ marginTop: 75 }}>
                    <Text style={{ color: "#ccc", fontWeight: "bold", }}>Cancel</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </TouchableWithoutFeedback>
          </Modal>
          {
            this.state.showGetPicture && (
              <GetPicture
                chatGroup={true}
                onDone={async (url, text) => {
                  this.setState({ showGetPicture: false });
                  await global.timeout(100);
                  if (url) {
                    await this.setState({ pictureURL: url, text: text });
                    this.send();
                  }
                }}
                item={"POST"}
                noCrop
              />
            )
          }
          {this.state.showGetReaction && (
            <GetReaction
              onDone={async (reaction) => {
                this.setState({ showGetReaction: false });
                // console.log("reaction", reaction);
                if (reaction) {
                  await this.setState({ video: reaction })
                  await this.send();
                }
              }}
            />
          )}
        </View>
        {this.props.chatGroup?.options?.sortChildrenBy !== "TIME_ASCENDING" && this.state.aa && (
          <View
            style={{
              flex: 1,
              // marginBottom: 10,
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <ActivityIndicator
              size="small"
              color="#fff"
              style={{
                opacity: 1
              }}
              animating={true}
            />
          </View>
        )
        }
        <NavigationEvents
          onDidFocus={async payload => {

            await new Promise(resolve => {
              setTimeout(resolve, 500);
            });
            if (this.state.aa) {
              await new Promise(resolve => {
                setTimeout(resolve, 1000);
              });
            }
            if (this.state.aa) {
              await new Promise(resolve => {
                setTimeout(resolve, 1000);
              });
            }
            if (this.state.aa) {
              await new Promise(resolve => {
                setTimeout(resolve, 1000);
              });
            }
            if (this.state.aa) {
              await new Promise(resolve => {
                setTimeout(resolve, 1000);
              });
            }
            if (this.state.aa) {
              await new Promise(resolve => {
                setTimeout(resolve, 1000);
              });
            }
            if (this.state.aa) {
              await new Promise(resolve => {
                setTimeout(resolve, 1000);
              });
            }
            let contactID = this.props.navigation.getParam("contactID", false) || false;
            if (contactID) {
              this.setState({ contactID, showContactModal: true });
              await this.props.navigation.setParams({
                contactID: false
              });
            }
            let postIDs = this.props.navigation.getParam("postIDs", false);
            if (postIDs) {
              this.setState({ postIDs })
              this.send()
              await this.props.navigation.setParams({
                postID: false
              });
            }
          }}
        />
      </View >
    );
  }
}

class Comments extends Component {
  state = {
    showDeleteIcon: false
  }
  updateComment = async (like, commentID) => {
    this.props.updateLikes(like, commentID);
    // let likes = global.duplicate(this.props.likes);
    // const likesToUpdate = likes.shift();
    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "updateComment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          isWeb: true,
          chatGroupID: this.props.chatGroup.xID,
          action: like ? "like" : "dislike",
          myUID: global.myUID,
          commentID: commentID,
          idToken: global.idToken
        })
      });

      const r2 = await r.json();

      if (r2.msg !== "SUCCESS") {
        this.setState({ error: "Could not get updates", loading: false });
        return;
      }
    } catch (err) {
      global.warn(err, "updateComment");
    }
  };
  render() {
    if (!this.props.comments) return <View />;
    let isFullScreen = ((this.props.navigation.getParam("group", null)) || this.props.isVideoComments) ? true : false
    // return (<View />) //delete this line
    return (
      <KeyboardAwareScrollView
        showsVerticalScrollIndicator={false}
        enableOnAndroid={isFullScreen}
        extraScrollHeight={Platform.OS !== "ios" ? 100 : 250}
        extraHeight={Platform.OS !== "ios" ? 0 : 0}
        //resetScrollToCoords={{ x: 0, y: 0 }}

        //extraHeight={10}
        //enableAutomaticScroll={(Platform.OS === 'ios')}
        style={{ width: "100%" }}
      >
        <StatusBar style="light" />
        <View
          style={{ backgroundColor: this.props.backgroundColor, width: "100%" }}
        >
          {!!this.props.chatGroup && this.props.navigation.getParam("group", null) && (!!this.props.navigation.getParam("isBlogPost", false) || (!!this.props.chatGroup.groupYoutubeID) || (this.props.chatGroup.aType == "poll") || (this.props.chatGroup.aType == "event") || (this.props.chatGroup.aType == "task")) && (
            <KeyboardAwareScrollView
              showsVerticalScrollIndicator={false}
              // enableOnAndroid={isFullScreen}
              extraScrollHeight={Platform.OS !== "ios" ? -100 : -250}
              extraHeight={Platform.OS !== "ios" ? 0 : 0}
              //resetScrollToCoords={{ x: 0, y: 0 }}

              //extraHeight={10}
              //enableAutomaticScroll={(Platform.OS === 'ios')}
              style={{ width: "100%", backgroundColor: "#222224" }}
            >
              {(!!this.props.navigation.getParam("isBlogPost", false))
                ? <BlogPost
                  comments={this.props.comments}
                  amModerator={this.props.amModerator}
                  aaModerator={this.props.aaModerator}
                  editComment={() => { this.setState({ showDeleteIcon: true }) }}
                  renderDeleteAll={this.state.showDeleteIcon && true}
                  editGroup={args => {
                    this.props.editGroup(args);
                  }}
                  chatGroup={this.props.chatGroup}
                  blog={this.props.navigation.getParam("blog", {})}
                  view={"DETAIL"}
                  join={arg => { }}
                  amJoiner={true}
                  refresh={this.props.refreshGroup}
                  navigation={this.props.navigation}
                />
                : (!this.props.chatGroup.groupYoutubeID || (this.props.chatGroup.groupYoutubeID && isMobile)) && <Group
                  chatGroup={this.props.chatGroup}
                  view={"DETAIL"}
                  join={arg => { }}
                  amJoiner={true}
                  editComment={() => { this.props.showDeleteIcon }}
                  amModerator={this.props.amModerator}
                  backgroundColor={this.props.navigation.getParam("titleBackgroundColor", null)}
                  refresh={this.props.refreshGroup}
                  navigation={this.props.navigation}
                />}
              <View style={{ paddingLeft: 10, paddingTop: 5, bottom: 0, ...this.props.backgroundColor && { backgroundColor: this.props.backgroundColor } }}>
                {this.props.activeComment === "ROOT" && <TypeField
                  chatGroup={this.props.chatGroup}
                  parentID={"ROOT"}
                  addTemporaryComment={comment => {
                    this.props.addTemporaryComment(comment);
                  }}
                  dismissKeyboard={() => {
                    //Keyboard.dismiss();
                  }}
                  resetTypeField={() => { }}
                  navigation={this.props.navigation}
                  placeholder="Leave a comment..."
                  isVideoComments={this.props.isVideoComments}
                  backgroundColor={this.props.backgroundColor}
                />}
              </View>
            </KeyboardAwareScrollView>
          )}
          {this.props.comments.map((comment, i) => {
            return (
              <View key={i}>
                <Comment
                  likeIDs={this.props.likeIDs}
                  dislikeIDs={this.props.dislikeIDs}
                  comment={comment}
                  updateComment={(args, commentID) => {
                    this.updateComment(args, commentID);
                  }}
                  editGroup={args => {
                    this.props.editGroup(args);
                  }}
                  newComment={args => {
                    this.props.newComment(args)
                  }}
                  showDeleteIcon={this.state.showDeleteIcon == true ? this.state.showDeleteIcon : this.props.showDeleteIcon}
                  commentID={this.props.commentID}
                  metaUpdates={this.props.metaUpdates}
                  chatGroup={this.props.chatGroup}
                  activeComment={this.props.activeComment}
                  activateComment={this.props.activateComment}
                  showReplyButton={this.props.showReplyButton}
                  replyComment={commentID => this.props.replyComment(commentID)}
                  generalComment={() => this.props.generalComment()}
                  addTemporaryComment={comment => {
                    this.props.addTemporaryComment(comment);
                  }}
                  lastViewedCommentTimeMilliseconds={this.props.lastViewedCommentTimeMilliseconds}
                  aaModerator={this.props.aaModerator}
                  amModerator={this.props.amModerator}
                  backgroundColor={this.props.backgroundColor}
                  allowAbout={this.props.allowAbout}
                  renderVideo={(this.props.comments?.length - i) < (Platform.OS == "ios" ? 6 : 2)} // Android can crash when playing several videos at a time
                  isVideoComments={this.props.isVideoComments}
                  navigation={this.props.navigation}
                />
              </View>
            );
          })}
          <View style={{ height: (this.props.navigation.getParam("group", false)) ? this.props.activeComment == "ROOT" ? 120 : 0 : 0 }} />
        </View>
      </KeyboardAwareScrollView>
    );
  }
}

class Comment extends Component {
  state = {
    showFullscreenImage: false,
    watchingYoutube: false,
    playingYoutubeID: "",
  };

  openURL = (link) => {
    if (link.startsWith("https://wakandamarket.com/community/") && link.length == 56) {
      const communityID = link.substring(36, 56)
      global.navigateCommunity(this.props.navigation, { communityID })
    }
    else Linking.openURL(link);
  }

  renderLink = (text) => {
    let link = text
    if (text.toLowerCase().startsWith("www.")) link = "http://" + text
    return (<Text style={{ color: "#79f" }} onPress={() => { this.openURL(link); }}>{text}</Text>)
  }
  renderText = (text) => {
    let t = text.toLowerCase()
    // console.log("renderText", text, t)

    // CASE text starts with link
    if (t.startsWith("http://") || t.startsWith("https://") || t.startsWith("www.")) {
      // console.log("CASE text starts with link")
      const linkArray = text.split(" ", 1)
      let link = linkArray[0]
      const linkArray2 = text.split("\n", 1)
      if (linkArray2[0].length < link.length) link = linkArray2[0]
      // console.log("link and remainder", link, text.substring(link.length, 999999999))
      if (link.length == text.length) return this.renderLink(link)
      return (<Text>{this.renderLink(link)}{this.renderText(text.substring(link.length, 999999999))}</Text>)
    }

    // CASE text contains no links
    if (!(t.includes(" http://") || t.includes(" https://") || t.includes(" www.")))
      return (<Text>{text}</Text>)

    // CASE text contains Links
    // console.log("CASE text contains Links")
    let position1 = t.indexOf("http://")
    let position2 = t.indexOf("https://")
    let position3 = t.indexOf("www.")
    if (position1 == -1) position1 = 9999999999
    if (position2 == -1) position2 = 9999999999
    if (position3 == -1) position3 = 9999999999
    const position = Math.min(position1, position2, position3)
    // console.log("position", position, position1, position2, position3)
    // console.log("splits", text.substring(0, position), text.substring(position, 999999999))
    return (<Text>{this.renderText(text.substring(0, position))}{this.renderText(text.substring(position, 999999999))}</Text>)
  }

  renderNameLine = (y, type, isRoot) => {
    const pictureWidth = isRoot ? 25 : 20
    const fontSize = isRoot ? 14 : 12
    const imageSize = isRoot ? 20 : 16
    return (<View>
      <View
        style={{
          flexDirection: "row",
          width: "100%",
          justifyContent: 'flex-start', //'space-between',
          alignItems: "center",
          flex: 1
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: "center", paddingHorizontal: 5 }}>
          <TouchableOpacity activeOpacity={1} onPress={() => {
            if (this.props.allowAbout) global.navigateProfile(this.props.navigation, y.authorProfileID)
          }}>
            {y.authorPictureURL ? (
              <Image
                style={{ width: pictureWidth, height: pictureWidth, borderRadius: pictureWidth / 2 }}
                source={{ uri: y.authorPictureURL || "" }}
              />
            ) : (
              <View
                style={{
                  width: pictureWidth,
                  height: pictureWidth,
                  borderRadius: pictureWidth / 2,
                  backgroundColor: "#668"
                }}
              />
            )}
          </TouchableOpacity>
          {!!y.authorName && (
            <View style={{ marginLeft: 5 }}>
              <Text style={{ fontSize, color: (type == "DOCTOR") ? "red" : "#fff" }}>{y.authorName}</Text>
            </View>
          )}
        </View>

        {(type == "DOCTOR") && <View style={{ flexDirection: 'row', marginLeft: 8 }}>
          <Image
            style={{ width: imageSize, height: imageSize }}
            source={require('../../images/icons/HealthProfessional.png')}
          />
          <Text style={{ fontSize, textAlign: 'center', color: "red" }}>Verified Doctor</Text>
        </View>}
      </View>
    </View>)
  }

  playYoutube = async (id) => {
    this.setState({ playingYoutubeID: id, watchingYoutube: true })
  }

  render() {
    const y = this.props.comment;
    let isFullScreen = (this.props.navigation.getParam("group", null)) ? true : false
    const isDoctor = (this.props.chatGroup.options && this.props.chatGroup.options.highlightVerifiedMedicalPractitioners && y.authorIsDoctor)
    const showNameLine = isDoctor // can have more cases in the future

    const commentID = y.xID;
    if (!commentID) alert(";;")
    let nOfLikes = 0;
    let nOfDislikes = 0;
    const keys = Object.keys(this.props.metaUpdates);

    if (keys.includes(commentID)) {
      if (commentID != "TEMP") {
        if (this.props.metaUpdates[commentID])
          nOfLikes = this.props.metaUpdates[commentID].nOfLikes || 0;
        if (this.props.metaUpdates[commentID])
          nOfDislikes = this.props.metaUpdates[commentID].nOfDislikes || 0;
      }
    }

    const milliseconds = y.timeCreated._seconds
      ? y.timeCreated._seconds * 1000 + y.timeCreated._nanoseconds / 1000000
      : y.timeCreated.seconds * 1000 + y.timeCreated.nanoseconds / 1000000;
    const timeCreated = new Date(milliseconds);
    const timeCreatedCopy = new Date(milliseconds);
    const milliAfterTenMins = timeCreatedCopy.setSeconds(timeCreatedCopy.getSeconds() + 600)
    const nowMillis = new Date().getTime()

    const sWidth = Dimensions.get("window").width;
    const sHeight = Dimensions.get("window").height;

    return (
      <View
        style={{ paddingHorizontal: 10, paddingVertical: 5, marginTop: 0 }}
      >

        {y.aStatus === "LIVE" ? (
          y.parentID === "ROOT" ? (
            <global.ConditionalWrapper
              condition={((milliseconds > this.props.lastViewedCommentTimeMilliseconds) && (y.authorProfileID !== global.myProfileID))}
              wrapper={children => <View
                style={{ backgroundColor: 'rgb(255,255,204)', borderRadius: 5, }}
              //colors={['#444466', '#222233', '#111118', 'transparent', 'transparent']}
              // colors={['#556620', '#273310', '#131605', 'transparent', 'transparent']}
              //start={{ x: 0, y: 0 }}
              //end={{ x: 1, y: 1 }}
              >{children}</View>}
            >
              <View style={{
                marginTop: 5, // only for root 
              }}>
                {!!showNameLine && (this.renderNameLine(y, "DOCTOR", true))}
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    paddingHorizontal: 5,
                    flex: 1,
                    backgroundColor: '#4B4A4A',
                    borderRadius: 5,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    padding: 5,
                    borderWidth: 1,
                    borderColor: '#4B4A4A',
                    borderLeftColor: '#E8EDD1',
                    paddingLeft: 5
                  }}
                >
                  <View >
                    <TouchableOpacity activeOpacity={1} onPress={() => {
                      if (this.props.allowAbout) global.navigateProfile(this.props.navigation, y.authorProfileID)
                    }}>
                      {(y.authorPictureURL && !showNameLine) ? (
                        <Image
                          style={{ width: 40, height: 40, borderRadius: 20 }}
                          source={{ uri: y.authorPictureURL || "" }}
                        />
                      ) : (
                        <View
                          style={{
                            width: 30,
                            height: 30,
                            borderRadius: 15,
                            ...(!showNameLine && { backgroundColor: "#668" })
                          }}
                        />
                      )}
                    </TouchableOpacity>
                  </View>
                  <View
                    style={{ flexDirection: "column", marginLeft: 7, width: "78%" }}
                  >
                    {!!y.messageData.video && <VideoMessage
                      video={y.messageData.video}
                      playOnStart={false} //alternative: for the last 2-6 comments
                      // playOnStart={this.props.renderVideo}
                      authorMe={y.authorName}
                    />}

                    {!!y.messageData.pictureURL && (
                      <TouchableOpacity
                        onPress={() => this.setState({ showFullscreenImage: true })}
                      >
                        <CachedImage
                          key={y.messageData.pictureURL}
                          style={{
                            width: isFullScreen ? Math.min(Dimensions.get("window").width * .65, 400) : Dimensions.get("window").width * .55,
                            height: isFullScreen ? Math.min(Dimensions.get("window").width * .65 / 2.5, 400 / 2.5) : Dimensions.get("window").width * .55 / 2.5,
                            marginRight: 5,
                            borderRadius: 5
                            // borderColor: this.props.backgroundColor
                          }}
                          source={{ uri: y.messageData.pictureURL }}
                        />
                      </TouchableOpacity>
                    )}
                    {!!y.messageData.text && (
                      <View>
                        <Text style={{ color: "#fff", fontSize: 14 }} selectable={true}>{this.renderText(y.messageData.text)}</Text>
                      </View>
                    )}
                    {!!y.messageData.contactID && (
                      <View style={{ marginTop: 15, width: '70%' }}>
                        <Contact
                          contactID={y.messageData.contactID}
                          navigation={this.props.navigation}
                        />
                      </View>
                    )}
                    {!!y.messageData.voiceURL && <VoiceMessage
                      voiceURL={y.messageData.voiceURL}
                      voiceDuration={y.messageData.voiceDuration}
                      playOnStart={this.props.renderVideo}
                      type={"chatGroup"}
                    />}
                    {!y.messageData.contactID && !y.messageData.text && !y.messageData.pictureURL && !!y.messageData.postIDs && !!y.messageData.postIDs.length > 0 && (
                      y.messageData.postIDs.map((postID) => {
                        return (
                          <View style={{
                            marginTop: 40, marginLeft: 50, width: 250,
                            height: 150
                          }}
                            key={postID}
                          >
                            <SinglePost
                              style={{ width: '40%' }}
                              postID={postID}
                              navigation={this.props.navigation}
                            />
                          </View>
                        )
                      })
                    )}
                    {!!y.messageData.location && !!y.messageData.location.address && (
                      <View >
                        <Text style={{ color: "#fff", marginBottom: 5, fontSize: 11 }}>
                          LOCATION SHARED:
                        </Text>
                        <a href={`http://maps.google.com/?q=${y.messageData.location.address}`} target='_blank'
                          style={{ flexDirection: 'row' }}
                        ><Image
                            style={{
                              width: 16,
                              height: 16,
                              marginRight: 5,
                              tintColor: "#a33",
                              alignSelf: 'center'
                            }}
                            source={require("../../images/icons/Location.png")}
                          />
                          <Text style={{ color: "#88c", marginBottom: 5, fontSize: 14 }}>
                            {y.messageData.location.address}
                          </Text>
                        </a>
                      </View>
                    )}

                    {!!y.messageData.youtubeID && (<TouchableOpacity
                      onPress={() => {
                        if (y.messageData.youtubeID) { this.playYoutube(y.messageData.youtubeID); }
                      }}
                    >
                      <View
                        style={{ alignItems: 'center', flex: 1, paddingTop: 10 }}
                      >
                        <View style={{
                          justifyContent: 'center', alignItems: 'center'
                        }}>
                          <Image
                            style={{
                              width: 265,
                              height: 200,
                            }}
                            source={{
                              uri: y.messageData.youtubeThumbnail
                            }}
                          /><View
                            style={{
                              position: 'absolute', backgroundColor: "rgba(198,198,198,0.6)", borderRadius: 20, paddingLeft: 6, paddingBottom: 1,
                              height: 40, justifyContent: 'center', alignItems: 'center', width: 40,
                            }}>
                            <Text style={{ color: "#000", fontSize: 25 }} >
                              {"▶️"}
                            </Text>
                          </View>
                        </View>
                        <Text style={{ color: '#fff', opacity: 0.8, fontSize: 16, marginTop: 5, fontStyle: "italic", fontWeight: "bold" }} numberOfLines={1}>{y.messageData.youtubeTitle}</Text>
                      </View>
                    </TouchableOpacity>)}


                    {!!y.messageData.urlLink && <a href={y.messageData.urlLink} target='_blank'
                    >
                      <View style={{ flex: 1, flexDirection: 'row', paddingTop: 10, width: '80%' }}>
                        <Image
                          style={{
                            width: 50,
                            height: 50,
                            marginRight: 5,
                          }}
                          source={{
                            uri: y.messageData.urlThumbnail
                          }}
                        />
                        <Text style={{ color: "#fff", opacity: 0.8, fontSize: 16, marginTop: 5, fontStyle: "italic", fontWeight: "bold" }} numberOfLines={1}>{y.messageData.urlTitle}</Text>
                      </View>
                    </a>}

                    {!!y.messageData.document && (
                      <View style={{ width: '100%' }}>
                        <Text style={{ color: "#fff", marginBottom: 5, fontSize: 11 }}>DOCUMENT SHARED:</Text>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                          <View style={{ width: '10%', height: '100%' }}>
                            <Image
                              style={{ width: 25, height: 25, marginRight: 2, tintColor: "#fff" }}
                              source={require("../../images/icons/Attach.png")}
                            />
                          </View>
                          <View style={{ overflow: 'hidden', paddingRight: 10, textOverflow: 'ellipsis', width: '90%' }}>
                            {y.messageData?.document?.url && <a href={y.messageData.document.url} target='_blank'
                            >
                              <Text numberOfLines={1} style={{ color: "#88c", marginBottom: 0 }}>{y.messageData.document.name || "?"}</Text>
                              <Text numberOfLines={1} style={{ color: "#fff", marginBottom: 5, fontSize: 11 }}>{y.messageData.document.size || "Unknown Size"}  -  {y.messageData.document.type || "Unknown Type (!)"}</Text>
                            </a>}
                          </View>
                        </View>
                      </View>
                    )}

                    <View
                      style={{
                        marginTop: 2,
                        marginBottom: 5,
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{ color: "#889", fontSize: 12, marginRight: 10 }}
                      >
                        {y.timeCreated && (
                          <Moment fromNow element={Text}>
                            {timeCreated.getTime()}
                          </Moment>
                        )}
                      </Text>
                      {nOfLikes !== 0 && (
                        <Text
                          style={{ color: "#889", fontSize: 12, marginRight: 10 }}
                        >
                          {nOfLikes == 1 ? `${nOfLikes} like` : `${nOfLikes} likes`}
                        </Text>
                      )}
                      {nOfDislikes !== 0 && (
                        <Text
                          style={{ color: "#889", fontSize: 12, marginRight: 10 }}
                        >
                          {nOfDislikes == 1
                            ? `${nOfDislikes} dislike`
                            : `${nOfDislikes} dislikes`}
                        </Text>
                      )}
                      {((this.props.amModerator && this.props.showDeleteIcon) || (y.authorProfileID === global.myProfileID && nowMillis <= milliAfterTenMins)) && (
                        <View>
                          {!this.props.aaModerator && commentID && (commentID !== "TEMP") && (
                            <TouchableOpacity
                              onPress={() => {
                                if (y.authorProfileID === global.myProfileID) {
                                  Alert.alert("Delete comment?", "", [
                                    { text: "Cancel", onPress: () => { }, style: "cancel" },
                                    {
                                      text: "Yes",
                                      onPress: () => {
                                        this.props.newComment({
                                          authorProfileID: y.authorProfileID,
                                          commentID: commentID,
                                          action: "delete",
                                        })
                                      }
                                    }], { cancelable: false });
                                } else {
                                  this.props.editGroup({
                                    action: "deleteComment",
                                    commentID: commentID
                                  });
                                }
                              }}
                            >
                              <Image
                                style={{
                                  width: 13,
                                  height: 13,
                                  marginRight: 10,
                                  tintColor: "#a33"
                                }}
                                source={require("../../images/icons/Delete.png")}
                              />
                            </TouchableOpacity>
                          )}
                        </View>
                      )}
                      {this.props.showReplyButton && (
                        <TouchableOpacity
                          onPress={() => {
                            this.props.replyComment(commentID);
                          }}
                        >
                          <Text style={{ fontSize: 12, color: "#889" }}>Reply</Text>
                        </TouchableOpacity>
                      )}
                    </View>
                  </View>
                  {commentID != "TEMP" && <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginRight: 20
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => this.props.updateComment(true, commentID)}
                    >
                      <Image
                        style={{
                          width: 15,
                          height: 15,
                          marginRight: 10,
                          tintColor: this.props.likeIDs.includes(commentID)
                            ? "#A8CF45"
                            : "#CAC8C8"
                        }}
                        source={require("../../images/icons/ThumbUp.png")}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => this.props.updateComment(false, commentID)}
                    >
                      <Image
                        style={{
                          width: 15,
                          height: 15,
                          tintColor: this.props.dislikeIDs.includes(commentID)
                            ? "#A8CF45"
                            : "#CAC8C8"
                        }}
                        source={require("../../images/icons/ThumbDown.png")}
                      />
                    </TouchableOpacity>
                  </View>}
                </View>
              </View>
            </global.ConditionalWrapper>
          ) : (<View style={{
            backgroundColor: '#525252', marginTop: -10, paddingTop: 10, borderWidth: 1, borderColor: '#525252', borderLeftColor: '#AA98D0',
          }}>
            <View style={{ marginLeft: 35 }}>
              <global.ConditionalWrapper
                condition={((milliseconds > this.props.lastViewedCommentTimeMilliseconds) && (y.authorProfileID !== global.myProfileID))}
                wrapper={children => <View
                  style={{ backgroundColor: '#444466', }}
                //colors={['#444466', '#222233', '#111118', 'transparent', 'transparent']}
                // colors={['#556620', '#273310', '#131605', 'transparent', 'transparent']}
                //start={{ x: 0, y: 0 }}
                //end={{ x: 1, y: 1 }}
                >{children}</View>}
              >
                {!!showNameLine && (this.renderNameLine(y, "DOCTOR", false))}
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 0,
                    //width: "100%",
                    paddingHorizontal: 5,
                    flex: 1
                  }}
                >
                  <View>
                    <TouchableOpacity activeOpacity={1} onPress={() => {
                      if (this.props.allowAbout) global.navigateProfile(this.props.navigation, y.authorProfileID)
                    }}>
                      {(y.authorPictureURL && !showNameLine) ? (
                        <Image
                          style={{ width: 30, height: 30, borderRadius: 15 }}
                          source={{ uri: y.authorPictureURL || "" }}
                        />
                      ) : (
                        <View
                          style={{
                            width: 20,
                            height: 20,
                            borderRadius: 10,
                            ...(!showNameLine && { backgroundColor: "#668" })
                          }}
                        />
                      )}
                    </TouchableOpacity>
                  </View>
                  <View
                    style={{
                      flexDirection: "column",
                      marginLeft: 5,
                      width: "79.8%",
                      //paddingHorizontal: 20
                    }}
                  >
                    {!!y.messageData.video && <VideoMessage
                      video={y.messageData.video}
                      playOnStart={false} //alternative: for the last 2-6 comments
                      // playOnStart={this.props.renderVideo}
                      authorMe={y.authorName}
                    />}
                    {!!y.messageData.pictureURL && (
                      <TouchableOpacity
                        onPress={() => this.setState({ showFullscreenImage: true })}
                      >
                        <CachedImage
                          key={y.messageData.pictureURL}
                          style={{
                            width: isFullScreen ? Math.min(Dimensions.get("window").width * .6, 400) : Dimensions.get("window").width * .5,
                            height: isFullScreen ? Math.min(Dimensions.get("window").width * .6 / 2.5, 400 / 2.5) : Dimensions.get("window").width * .5 / 2.5,
                            marginRight: 5,
                            borderRadius: 5
                            // borderColor: this.props.backgroundColor
                          }}
                          source={{ uri: y.messageData.pictureURL }}
                        />
                      </TouchableOpacity>
                    )}
                    {!!y.messageData.text && (
                      <View>
                        <Text style={{ color: "#fff", fontSize: 12 }} selectable={true}>
                          {this.renderText(y.messageData.text)}
                        </Text>
                      </View>
                    )}
                    {!!y.messageData.contactID && (
                      <View style={{ marginTop: 15, width: '70%' }}>
                        <Contact
                          contactID={y.messageData.contactID}
                          navigation={this.props.navigation}
                        />
                      </View>
                    )}
                    {!!y.messageData.voiceURL && <VoiceMessage
                      voiceURL={y.messageData.voiceURL}
                      voiceDuration={y.messageData.voiceDuration}
                      playOnStart={this.props.renderVideo}
                      type={"chatGroup"}
                    />}
                    {!y.messageData.contactID && !y.messageData.text && !y.messageData.pictureURL && !!y.messageData.postIDs && !!y.messageData.postIDs.length > 0 && (
                      y.messageData.postIDs.map((postID) => {
                        return (
                          <View style={{
                            marginTop: 40, marginLeft: 50, width: 250,
                            height: 150,
                          }}
                            key={postID}
                          >
                            <SinglePost
                              postID={postID}
                              navigation={this.props.navigation}
                            />
                          </View>
                        )
                      })
                    )}
                    {!!y.messageData.location && !!y.messageData.location.address && (
                      <View>
                        <Text style={{ color: "#fff", marginBottom: 5, fontSize: 11 }}>
                          LOCATION SHARED:
                        </Text>
                        <a
                          href={`http://maps.google.com/?q=${y.messageData.location.address}`} target='_blank'
                          style={{ flexDirection: 'row' }}
                        ><Image
                            style={{
                              width: 12,
                              height: 12,
                              marginRight: 5,
                              tintColor: "#a33",
                              alignSelf: 'center'
                            }}
                            source={require("../../images/icons/Location.png")}
                          />
                          <Text style={{ color: "#88c", marginBottom: 5, fontSize: 12 }}>
                            {y.messageData.location.address}
                          </Text>
                        </a>
                      </View>
                    )}
                    {!!y.messageData.youtubeID && (<TouchableOpacity
                      onPress={() => {
                        if (y.messageData.youtubeID) { this.playYoutube(y.messageData.youtubeID); }
                      }}
                    >
                      <View
                        style={{ alignItems: 'center', flex: 1, paddingTop: 10 }}
                      >
                        <View style={{
                          justifyContent: 'center', alignItems: 'center'
                        }}>
                          <Image
                            style={{
                              width: 265,
                              height: 200,
                            }}
                            source={{
                              uri: y.messageData.youtubeThumbnail
                            }}
                          /><View
                            style={{
                              position: 'absolute', backgroundColor: "rgba(198,198,198,0.6)", borderRadius: 20, paddingLeft: 6, paddingBottom: 1,
                              height: 40, justifyContent: 'center', alignItems: 'center', width: 40,
                            }}>
                            <Text style={{ color: "#000", fontSize: 25 }} >
                              {"▶️"}
                            </Text>
                          </View>
                        </View>
                        <Text style={{ color: '#fff', opacity: 0.8, fontSize: 16, marginTop: 5, fontStyle: "italic", fontWeight: "bold" }} numberOfLines={1}>{y.messageData.youtubeTitle}</Text>
                      </View>
                    </TouchableOpacity>)}


                    {!!y.messageData.urlLink && <a to={y.messageData.urlLink} target='_blank' >
                      <View style={{ flex: 1, flexDirection: 'row', paddingTop: 10, width: '80%' }}>
                        <Image
                          style={{
                            width: 50,
                            height: 50,
                            marginRight: 5,
                          }}
                          source={{
                            uri: y.messageData.urlThumbnail
                          }}
                        />
                        <Text style={{ color: '#fff', opacity: 0.8, fontSize: 16, marginTop: 5, fontStyle: "italic", fontWeight: "bold" }} numberOfLines={1}>{y.messageData.urlTitle}</Text>
                      </View>
                      <Text selectable={true}>{this.renderText(y.messageData.text)}</Text>
                    </a>}
                    {!!y.messageData.document && (
                      <View>
                        <Text style={{ color: "#fff", marginBottom: 5, fontSize: 11 }}>DOCUMENT SHARED:</Text>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                          <Image
                            style={{ width: 25, height: 25, marginRight: 2, tintColor: "#fff" }}
                            source={require("../../images/icons/Attach.png")}
                          />
                          <View style={{}}>
                            {y.messageData?.document?.url && <a href={y.messageData.document.url} target='_blank'>
                              <Text style={{ color: "#88c", marginBottom: 0 }}>{y.messageData.document.name || "?"}</Text>
                              <Text style={{ color: "#fff", marginBottom: 5, fontSize: 11 }}>{y.messageData.document.size || "Unknown Size"}  -  {y.messageData.document.type || "Unknown Type (!)"}</Text>
                            </a>}
                          </View>
                        </View>
                      </View>
                    )}
                    <View
                      style={{
                        marginTop: 2,
                        marginBottom: 5,
                        flexDirection: "row",
                        alignItems: "center"
                      }}
                    >
                      <Text
                        style={{ color: "#889", fontSize: 11, marginRight: 10 }}
                      >
                        {y.timeCreated && (
                          <Moment fromNow element={Text}>
                            {timeCreated.getTime()}
                          </Moment>
                        )}
                      </Text>
                      {nOfLikes !== 0 && (
                        <Text
                          style={{ color: "#889", fontSize: 11, marginRight: 10 }}
                        >
                          {nOfLikes == 1
                            ? `${nOfLikes} like`
                            : `${nOfLikes} likes`}
                        </Text>
                      )}
                      {nOfDislikes !== 0 && (
                        <Text
                          style={{ color: "#889", fontSize: 11, marginRight: 10 }}
                        >
                          {nOfDislikes == 1
                            ? `${nOfDislikes} dislike`
                            : `${nOfDislikes} dislikes`}
                        </Text>
                      )}
                      {((this.props.amModerator && this.props.showDeleteIcon) || (y.authorProfileID === global.myProfileID && nowMillis <= milliAfterTenMins)) && (
                        <View>
                          {!this.props.aaModerator && (
                            <TouchableOpacity
                              onPress={() => {
                                if (y.authorProfileID === global.myProfileID) {
                                  Alert.alert("Delete comment?", "", [
                                    { text: "Cancel", onPress: () => { }, style: "cancel" },
                                    {
                                      text: "Yes",
                                      onPress: () => {
                                        this.props.newComment({
                                          authorProfileID: y.authorProfileID,
                                          commentID: commentID,
                                          action: "delete",
                                        })
                                      }
                                    }], { cancelable: false });
                                } else {
                                  this.props.editGroup({
                                    action: "deleteComment",
                                    commentID: commentID
                                  });
                                }
                              }}
                            >
                              <Image
                                style={{
                                  width: 12,
                                  height: 12,
                                  marginRight: 9,
                                  tintColor: "#a33"
                                }}
                                source={require("../../images/icons/Delete.png")}
                              />
                            </TouchableOpacity>
                          )}
                        </View>
                      )}
                      {/* <TouchableOpacity
                    onPress={() => {
                      this.props.replyComment(commentID);
                    }}
                  >
                    <Text
                      style={{ color: "#fff", fontSize: 12, color: "#889" }}
                    >
                      Reply
                    </Text>
                  </TouchableOpacity> */}
                    </View>
                  </View>
                  {commentID != "TEMP" && <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginRight: 20
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => this.props.updateComment(true, commentID)}
                    >
                      <Image
                        style={{
                          width: 15,
                          height: 15,
                          marginRight: 10,
                          tintColor: this.props.likeIDs.includes(commentID)
                            ? "#A8CF45"
                            : "#CAC8C8"
                        }}
                        source={require("../../images/icons/ThumbUp.png")}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => this.props.updateComment(false, commentID)}
                    >
                      <Image
                        style={{
                          width: 15,
                          height: 15,
                          tintColor: this.props.dislikeIDs.includes(commentID)
                            ? "#A8CF45"
                            : "#CAC8C8"
                        }}
                        source={require("../../images/icons/ThumbDown.png")}
                      />
                    </TouchableOpacity>
                  </View>}
                </View>
              </global.ConditionalWrapper>
            </View>
          </View>
          )) : (
          <View />
        )
        }
        {
          this.state.showFullscreenImage &&
          <FullscreenImage on={this.state.showFullscreenImage} pictureURL={y.messageData.pictureURL}
            cached onClose={() => { this.setState({ showFullscreenImage: false }) }} />
        }

        <Modal
          animationType="fade"
          visible={this.state.watchingYoutube}
          onRequestClose={() => {
            this.setState({ watchingYoutube: false });
          }}
        >
          <View
            style={{
              alignItems: "center",
              justifyContent: "flex-end",
              height: "100%",
              backgroundColor: "#000"
            }}
          >
            <WebView
              originWhitelist={['*']}
              style={{ width: sWidth, height: sHeight, marginVertical: ((sHeight - sWidth * 0.7) / 2) }}
              source={{ uri: 'https://www.youtube.com/embed/' + this.state.playingYoutubeID }}
              javaScriptEnabled={true}
              domStorageEnabled={true}
              useWebKit={true}
            />
            <TouchableOpacity
              style={{
                position: "absolute",
                borderWidth: 0,
                backgroundColor: "#000",
                borderColor: "rgba(0,0,0,0.2)",
                alignItems: "center",
                justifyContent: "center",
                width: 35,
                top: 50,
                left: 5,
                height: 25,
                borderRadius: 35
              }}
              onPress={() => {
                this.setState({ watchingYoutube: false });
              }}
            >
              <Image
                style={{ width: 35, height: 35, opacity: 1 }}
                source={require("../../images/icons/CancelWhite.png")}
              />
            </TouchableOpacity>
          </View>
        </Modal>
        {
          this.props.activeComment === commentID && (
            <View>
              <TouchableOpacity
                style={{
                  position: "absolute",
                  marginTop: 5,
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 3.5,
                  borderRadius: 15,
                  borderWidth: 0.5,
                  borderColor: "#667",
                  backgroundColor: "#445",
                  marginTop: 14
                }}
                onPress={() => {
                  this.props.generalComment();
                }}
              >
                <Image
                  style={{ width: 12, height: 12, tintColor: "#A8CF45" }}
                  source={require("../../images/icons/LeftArrow.png")}
                />
              </TouchableOpacity>
              <View style={{ marginLeft: 28 }}>
                <TypeField
                  chatGroup={this.props.chatGroup}
                  parentID={(!!y.parentID && y.parentID !== "ROOT") ? y.parentID : commentID}
                  resetTypeField={() => {
                    this.props.activateComment("ROOT")
                  }}
                  addTemporaryComment={comment => {
                    this.props.addTemporaryComment(comment);
                  }}
                  dismissKeyboard={() => {
                    // Keyboard.dismiss();
                  }}
                  isVideoComments={this.props.isVideoComments}
                  navigation={this.props.navigation}
                />
              </View>
            </View>
          )
        }
      </View >
    );
  }
}

export default class ChatGroupDetailScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    return {
      headerTransparent: true,
      headerTintColor: "#A8CF45",
      title: navigation.getParam("title") || "",
      headerTitleStyle: {
        fontWeight: "bold",
        color: "#A8CF45",
        shadowOpacity: 1,
        elevation: 1
      }
    };
  };
  state = {
    comments: [],
    metaUpdates: {},
    amJoiner: false,
    loading: true,
    groupUpdated: false,
    error: false,
    aa: false,
    aaModerator: false,
    likeIDs: [],
    dislikeIDs: [],
    activeComment: "ROOT",
    showInputBox: true,
    showReplyButton: true,
    allowAbout: true,
    lastViewedCommentTimeMilliseconds: null,
    x: null,
    showFeedMenu: false,
    showEditComment: false
  };

  constructor(props) {
    super(props);
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => {
      return;
    };
    this.unsubscribeFirestore2 = () => {
      return;
    };
    global.lastReadCounter = 0; //?
  }

  async componentDidMount() {
    try {
      const x =
        this.props.navigation.getParam("group", null) || this.props.group;

      const title = this.props.navigation.getParam("title", null)
      if (title) this.props.navigation.setParams({ title })
      this.setState({
        amJoiner: x.xAmJoiner || false,
        jointJoinerIDs: x.xJointJoinerIDs || []
      });
      this.x = x
      this.setState({ x })

      if (!x.xAmJoiner && x.aOpenness !== "public") {
        if (
          x.aOpenness == "communityOnly" &&
          global.myCommunities.includes(x.communityID)
        ) {
          //fine
        } else {
          // console.log("Access denied for group " + x.xID + ". " + x.communityID ? (x.communityID + "Not in global.myCommunities: ", global.myCommunities) : "")
          // console.log("(Access issue with aOpenness: " + x.aOpenness + ")")
          this.props.navigation.goBack();
          await global.timeout(400);
          alert("Access issue.");
          return;
        }
      }

      // get stored comments
      let comments = await global.asyncGetContainer(x.xID);

      let loadOnlyUpdates = true;
      let startingFromTimestamp = null;
      if (comments == -1 || !comments.timestamp) {
        loadOnlyUpdates = false;
        comments = {};
      } else {
        let commentsArray = await global.asyncGetContainerAsArray(x.xID);
        if (
          commentsArray &&
          commentsArray != -1 &&
          Array.isArray(commentsArray)
        )
          this.setState({ comments: commentsArray });

        startingFromTimestamp = comments.timestamp.timestamp;
        let lastViewedCommentTimeMilliseconds = startingFromTimestamp?._seconds
          ? startingFromTimestamp?._seconds * 1000 + startingFromTimestamp?._nanoseconds / 1000000
          : startingFromTimestamp?.seconds * 1000 + startingFromTimestamp?.nanoseconds / 1000000;
        this.setState({ lastViewedCommentTimeMilliseconds })
      }

      this.markGroupAsRead(x)
      this.loadMyLikes();

      // Hide TextInput for broadcasts (non Moderator)
      let amAdmin = false;
      if (
        x.moderatorProfileIDs &&
        global.myProfileID &&
        x.moderatorProfileIDs.includes(global.myProfileID)
      )
        amAdmin = true;
      if (x.aType == "broadcast" || x.aType == "broadcastWithResponses") {
        this.setState({ showReplyButton: false });
        if (amAdmin == false) {
          this.setState({ showInputBox: false });
        }
      }

      // get latest or all comments
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "getComments", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          isWeb: true,
          chatGroupID: x.xID,
          //...(startingFromTimestamp && {startingFromTimestamp}),
          myUID: global.myUID,
          idToken: global.idToken
        })
      });

      const r2 = await r.json();

      if (r2.msg !== "SUCCESS") {
        this.setState({ error: "Could Not get updates", loading: false });
        return;
      }
      // SUCCESS

      if (loadOnlyUpdates) {
        // set timestamp just retreived
        await global.asyncAddMultipleToContainer(
          [{ xID: "timestamp", timestamp: r2.timestamp }],
          x.xID
        );
        if (r2.comments && Array.isArray(r2.comments))
          await global.asyncAddMultipleToContainer(r2.comments, x.xID);
      } else {
        const data = { timestamp: r2.timestamp };
        r2.comments.forEach(c => {
          data[c.xID] = c;
        });
        await global.asyncCreateContainer(x.xID, data);
        AsyncStorage.setItem(
          "lastRefreshAllTimestamp" + x.xID,
          JSON.stringify(r2.timestamp)
        );
      }

      comments = await global.asyncGetContainerAsArray(x.xID);
      if (comments && comments != -1 && Array.isArray(comments))
        this.setState({ comments });
      this.setState({ loading: false });

      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("ChatGroups")
        .doc(x.xID)
        .collection("Details")
        .doc("recentUpdates")
        .onSnapshot(this.updateUpdates);
      this.unsubscribeFirestore2 = firebase
        .firestore()
        .collection("ChatGroups")
        .doc(x.xID)
        .collection("Details")
        .doc("metaUpdates")
        .onSnapshot(this.updateMetaUpdates);
    } catch (e) {
      console.log(e);
      this.setState({ error: "Error: Could not get Updates" });
    }
  }

  async componentWillUnmount() {
    this.unsubscribeFirestore();
    this.unsubscribeFirestore2();
  }

  updateUpdates = async uDoc => {
    // console.log("getting recentUpdates");
    const u = uDoc.data();
    if (!u.comments || !Array.isArray(u.comments)) return;
    if (u.comments.length > 0) {
      const x = this.x
      const newComments = u.comments || [];
      const nowStamp = firebase.firestore.Timestamp.now();

      await global.asyncAddMultipleToContainer(newComments, x.xID);
      //most recent timestamp in the recentUpdates doc
      let timestamp = nowStamp;
      let latestOne = { timestamp: nowStamp, seconds: 0 };
      u.comments.forEach(y => {
        const ySeconds = y.timeCreated.seconds
          ? y.timeCreated.seconds
          : y.timeCreated._seconds;
        // console.log("YSECONDS", ySeconds);
        if (ySeconds >= latestOne.seconds) {
          // console.log("HIGHER", y);
          latestOne = { timestamp: y.timeCreated, seconds: ySeconds };
        }
      });
      if (latestOne.timestamp) timestamp = latestOne.timestamp;
      else console.warn("Weird error 4235");
      await global.asyncAddMultipleToContainer(
        [{ xID: "timestamp", timestamp }],
        x.xID
      );
      const comments = await global.asyncGetContainerAsArray(x.xID);
      if (comments && comments != -1 && Array.isArray(comments))
        this.setState({ comments });
      this.markGroupAsRead(x)
    }

    this.setState({ loading: false, error: false });
  };

  updateMetaUpdates = async uDoc => {
    console.log("getting metaUpdates");

    const u = uDoc.data();
    if (!u.metaUpdates) return;
    const x = this.x

    if (u.metaUpdates) this.setState({ metaUpdates: u.metaUpdates });
    // console.log("getting metaUpdates", u.metaUpdates);

    if (u.timeLastRefreshAll) {
      let lastRefreshAllTimestamp = null;
      const myJSON = await AsyncStorage.getItem(
        "lastRefreshAllTimestamp" + x.xID
      );
      if (myJSON !== null) lastRefreshAllTimestamp = JSON.parse(myJSON);
      let needsRefreshAll = true;
      if (lastRefreshAllTimestamp) {
        const secondsServer = u.timeLastRefreshAll.seconds
          ? u.timeLastRefreshAll.seconds
          : u.timeLastRefreshAll._seconds;
        const secondsLocal = lastRefreshAllTimestamp.seconds
          ? lastRefreshAllTimestamp.seconds
          : lastRefreshAllTimestamp._seconds;
        if (secondsServer <= secondsLocal) needsRefreshAll = false;
      }
      if (needsRefreshAll) {
        //REFRESH ALL
        console.log(
          "Server requested for refresh of all comments. Starting...."
        );
        if (this.unsubscribeFirestore) this.unsubscribeFirestore();
        if (this.unsubscribeFirestore2) this.unsubscribeFirestore2();



        try {
          await global.fetchIdToken()
          const r = await fetch(global.cloudFunctionURL + "getComments", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              isWeb: true,
              chatGroupID: x.xID,
              myUID: global.myUID,
              idToken: global.idToken
            })
          });

          const r2 = await r.json();
          // console.log("response from getComments", r2);

          if (r2.msg !== "SUCCESS") {
            this.setState({ error: "Error. Please refresh this page." });
            return;
          }

          // SUCCESS
          const data = { timestamp: r2.timestamp };
          r2.comments.forEach(c => {
            data[c.xID] = c;
          });

          await global.asyncDeleteContainer(x.xID);
          await global.asyncCreateContainer(x.xID, data);

          let comments = await global.asyncGetContainerAsArray(x.xID);
          if (comments && comments != -1 && Array.isArray(comments))
            this.setState({ comments });

          await AsyncStorage.setItem(
            "lastRefreshAllTimestamp" + x.xID,
            JSON.stringify(u.timeLastRefreshAll)
          );
          console.log("REFRESH_ALL done.");

          this.unsubscribeFirestore = firebase
            .firestore()
            .collection("ChatGroups")
            .doc(x.xID)
            .collection("Details")
            .doc("recentUpdates")
            .onSnapshot(this.updateUpdates);
          this.unsubscribeFirestore2 = firebase
            .firestore()
            .collection("ChatGroups")
            .doc(x.xID)
            .collection("Details")
            .doc("metaUpdates")
            .onSnapshot(this.updateMetaUpdates);

        } catch (err) {
          console.log(err)
        }
      }
    }

    if (u.timeLastRefreshGroup) {
      // console.log("u.timeLastRefreshGroup exists", u.timeLastRefreshGroup)
      let lastRefreshGroupTimestamp = null;
      const myJSON = await AsyncStorage.getItem(
        "lastRefreshGroupTimestamp" + x.xID
      );
      if (myJSON !== null) lastRefreshGroupTimestamp = JSON.parse(myJSON);
      // console.log({ lastRefreshGroupTimestamp })
      let needsRefreshGroup = true;
      if (lastRefreshGroupTimestamp) {
        const secondsServer = u.timeLastRefreshGroup.seconds
          ? u.timeLastRefreshGroup.seconds
          : u.timeLastRefreshGroup._seconds;
        const secondsLocal = lastRefreshGroupTimestamp.seconds
          ? lastRefreshGroupTimestamp.seconds
          : lastRefreshGroupTimestamp._seconds;
        if (secondsServer <= secondsLocal) needsRefreshGroup = false;
      }
      if (!this.state.groupUpdated) needsRefreshGroup = true // XX will this work for more than one update?
      if (needsRefreshGroup) {
        const r = await fetch(global.cloudFunctionURL + "findGroups", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            isWeb: true,
            isWeb: true,
            type: "groupIDs",
            groupIDs: [x.xID],
            myUID: global.myUID,
            idToken: global.idToken,
          }),
        });
        const r2 = await r.json();
        if (r2.msg == "SUCCESS" && r2.groups?.[0]) {
          this.x = r2.groups[0]
          this.setState({ x: r2.groups[0], groupUpdated: true });
          AsyncStorage.setItem(
            "lastRefreshGroupTimestamp" + x.xID,
            JSON.stringify(r2.timestamp)
          );
        } else global.warn("Error during refreshGroup", "refreshGroup")
      }
      this.setState({ aaModerator: false });
    };
  }

  loadMyLikes = async () => {
    try {
      const lDoc = await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .collection("Refs")
        .doc("groupLikes")
        .get();
      if (!lDoc) return;
      const l = lDoc.data();
      if (!l) return;
      this.setState({ likeIDs: l.likeIDs });
      this.setState({ dislikeIDs: l.dislikeIDs });
    } catch (e) {
      console.warn("Error 223");
      console.log("Error 223", e);
    }
  };
  updateLikes = (like, commentID) => {
    let likes = global.duplicate(this.state.likeIDs);
    let dislikes = global.duplicate(this.state.dislikeIDs);

    if (like === true) {
      likes.push(commentID);
      dislikes = dislikes.filter(id => {
        return id !== commentID;
      });
    } else {
      dislikes.push(commentID);
      likes = likes.filter(id => {
        return id !== commentID;
      });
    }
    this.setState({ likeIDs: likes, dislikeIDs: dislikes });
  };

  markGroupAsRead = async (x) => {
    try {
      await global.fetchIdToken()
      await fetch(global.cloudFunctionURL + "joinGroup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          isWeb: true,
          chatGroupID: x.xID,
          action: "markRead",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      console.log("MarkRead done")
    } catch (e2) {
      global.warn(e2, "mgaa")
    }
  };

  join = async join => {
    if (this.state.loading) return;
    try {
      const x = this.x
      this.setState({ loading: true });

      const r = await fetch(global.cloudFunctionURL + "joinGroup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          isWeb: true,
          chatGroupID: x.xID,
          action: join ? "join" : "leave",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ loading: false });

      if (r2.msg === "SUCCESS" || r2.msg == "ALREADY_JOINED") {
        this.setState({ amJoiner: join }); //new
        global.chatGroupMainHasUpdates = true;
        if (join) {
          this.setState({ loading: true });
          const r = await fetch(global.cloudFunctionURL + "getComments", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              isWeb: true,
              chatGroupID: x.xID,
              myUID: global.myUID,
              idToken: global.idToken
            })
          });

          const r2 = await r.json();
          //console.log("response from getComments", r2)

          if (r2.msg !== "SUCCESS") {
            this.setState({ error: "Could not get updates", loading: false });
            return;
          }

          // SUCCESS
          const data = { timestamp: r2.timestamp };
          r2.comments.forEach(c => {
            data[c.xID] = c;
          });
          await global.asyncCreateContainer(x.xID, data);
          AsyncStorage.setItem(
            "lastRefreshAllTimestamp" + x.xID,
            JSON.stringify(r2.timestamp)
          );

          comments = await global.asyncGetContainerAsArray(x.xID);
          if (comments && comments != -1 && Array.isArray(comments))
            this.setState({ comments });
          this.setState({ loading: false });

          if (this.unsubscribeFirestore) this.unsubscribeFirestore();
          if (this.unsubscribeFirestore2) this.unsubscribeFirestore2();
          this.unsubscribeFirestore = firebase
            .firestore()
            .collection("ChatGroups")
            .doc(x.xID)
            .collection("Details")
            .doc("recentUpdates")
            .onSnapshot(this.updateUpdates);
          this.unsubscribeFirestore2 = firebase
            .firestore()
            .collection("ChatGroups")
            .doc(x.xID)
            .collection("Details")
            .doc("metaUpdates")
            .onSnapshot(this.updateMetaUpdates);
        } else {
          //leave success
          global.chatGroupMainHasUpdates = true;
          await global.asyncDeleteContainer(x.xID);
          this.props.navigation.goBack();
        }
      } else {
        alert("An error occurred.");
        return;
      }
    } catch (error) {
      console.log("An error occured", error);
      alert("An error occurred!");
      this.setState({ aa: false, loading: false });
    }
  };

  refreshGroup = async () => {
    const x = this.x
    try {
      const r = await fetch(global.cloudFunctionURL + "findGroups", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          isWeb: true,
          type: "groupIDs",
          groupIDs: [x.xID],
          myUID: global.myUID,
          idToken: global.idToken,
        }),
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS" && r2.groups?.[0]) {
        this.x = r2.groups[0]
        await this.setState({ x: r2.groups[0] });
      }
    } catch (e) {
      global.warn(e, "refreshGroup")
    }
  }

  //expects data { }
  //data.action: "edit", "invite", "ban", "deactivate", "reactivate", "deleteComment", "deleteAllComments", "addModerator", "removeModerator"
  //data.targetProfileID IF action=="invite", "ban", "addModerator", or "removeModerator"
  //data.commentID IF action=="deleteComment"
  editGroup = async data => {
    if (!data || !data.action) return;
    const x = this.x
    let amModerator = false;
    if (
      x.moderatorProfileIDs &&
      global.myProfileID &&
      x.moderatorProfileIDs.includes(global.myProfileID)
    )
      amModerator = true;
    if (!amModerator) {
      alert("Authentication error. Restart and try again");
      return;
    }
    // this.setState({aaModerator: true });
    if (this.state.loading) await global.timeout(500);
    if (this.state.loading) await global.timeout(500);
    if (this.state.loading) await global.timeout(500);
    if (this.state.loading) await global.timeout(500);
    if (this.state.loading) await global.timeout(1500);
    if (this.state.loading) {
      alert("Error, Try Again.");
      this.setState({ aaModerator: false });
      return;
    }
    if (data.action == "deleteComment" || data.action == "deleteAllComments") {
      Alert.alert(
        "Delete " + ((data.action == "deleteAllComments") ? "all messages?" : "this message?"),
        "Are you sure you want to " + ((data.action == "deleteAllComments") ? "delete all messages?" : "delete this message?"),
        [
          {
            text: "Cancel",
            onPress: () => {
              this.setState({ aaModerator: false });
            },
            style: "cancel"
          },
          {
            text: "Yes",
            onPress: () => {
              this.executeEditGroup(data);
            }
          }
        ],
        { cancelable: false }
      );
    }
  };
  executeEditGroup = async data => {
    const x = this.x

    this.setState({ aaModerator: true, loading: true });
    try {
      const r = await fetch(global.cloudFunctionURL + "editGroup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          isWeb: true,
          chatGroupID: x.xID,
          action: data.action,
          ...(data.targetProfileID && {
            targetProfileID: data.targetProfileID
          }),
          ...(data.commentID && { commentID: data.commentID }),
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (data.action != "deleteComment" && data.action != "deleteAllComments")
        this.setState({ aaModerator: false });
      // console.log("editGroup response", r2.msg);

      if (r2.msg !== "SUCCESS") {
        this.setState({ loading: false })
        alert("Action failed. Try again.");
      } else alert("Action successful");
      this.setState({ aaModerator: false });
    } catch (e2) {
      console.warn("ERROR#1 ");
      console.log("ERROR#1 ", e2);
      this.setState({ aaModerator: false, loading: false });
    }
  };

  newComment = async data => {

    const x = this.x
    this.setState({ loading: true });

    try {
      const r = await fetch(global.cloudFunctionURL + "newComment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          isWeb: true,
          chatGroupID: x.xID,
          action: data.action,
          ...(data.commentID && { commentID: data.commentID }),
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();

      if (r2.msg !== "SUCCESS") {
        this.setState({ loading: false })
        alert("Action failed. Try again.");
      } else alert("Action successful");
    } catch (e2) {
      console.warn("ERROR#1 ");
      console.log("ERROR#1 ", e2);
      this.setState({ loading: false });
    }
  }

  addTemporaryComment = async comment => {
    const comments = global.duplicate(this.state.comments);
    comments.push(comment);
    this.setState({ comments });
  };

  replyComment = (commentID, newComments) => {
    let myChildren = []

    if (this.x?.options?.sortChildrenBy == "TIME_ASCENDING") myChildren = newComments?.filter(y => {
      return y.parentID == commentID;
    });

    this.setState({ activeComment: myChildren?.length > 0 ? myChildren[myChildren.length - 1].xID : commentID })
  };
  generalComment = () => {
    this.setState({ activeComment: "ROOT" });
  };

  render() {
    const x = this.x
    if (!x) return <View />

    let newComments = [];
    let rootComments = this.state.comments.filter(y => {
      return y.parentID == "ROOT";
    });
    let childComments = this.state.comments.filter(y => {
      return y.parentID !== "ROOT";
    });
    // sort newComments by time and if specified, by nOfLikes
    // if sortRootBy === "LIKES" it will always sort
    rootComments.sort((a, b) => {
      let alikes = 0;
      let blikes = 0;
      let adislikes = 0;
      let bdislikes = 0;
      if (x.options.sortRootBy === "LIKES") {
        if (this.state.metaUpdates[a.xID]) {
          alikes = this.state.metaUpdates[a.xID].nOfLikes;
          adislikes = this.state.metaUpdates[a.xID].nOfDislikes;
        }
        if (this.state.metaUpdates[b.xID]) {
          blikes = this.state.metaUpdates[b.xID].nOfLikes;
          bdislikes = this.state.metaUpdates[b.xID].nOfDislikes;
        }
      }
      if (blikes - bdislikes == alikes - adislikes) {
        if (a.timeCreated && b.timeCreated) {
          let secondsA = a.timeCreated._seconds
            ? a.timeCreated._seconds
            : a.timeCreated.seconds;
          let secondsB = b.timeCreated._seconds
            ? b.timeCreated._seconds
            : b.timeCreated.seconds;
          if (x.options.sortRootBy === "TIME_ASCENDING")
            return secondsA - secondsB;
          else return secondsB - secondsA;
        } else return 0;
      }
      return blikes - bdislikes - (alikes - adislikes);
    });
    childComments.sort((a, b) => {
      let alikes = 0;
      let blikes = 0;
      let adislikes = 0;
      let bdislikes = 0;
      if (x.options.sortChildrenBy === "LIKES") {
        if (this.state.metaUpdates[a.xID]) {
          alikes = this.state.metaUpdates[a.xID].nOfLikes;
          adislikes = this.state.metaUpdates[a.xID].nOfDislikes;
        }
        if (this.state.metaUpdates[b.xID]) {
          blikes = this.state.metaUpdates[b.xID].nOfLikes;
          bdislikes = this.state.metaUpdates[b.xID].nOfDislikes;
        }
      }
      if (blikes - bdislikes == alikes - adislikes) {
        if (a.timeCreated && b.timeCreated) {
          let secondsA = a.timeCreated._seconds
            ? a.timeCreated._seconds
            : a.timeCreated.seconds;
          let secondsB = b.timeCreated._seconds
            ? b.timeCreated._seconds
            : b.timeCreated.seconds;
          if (x.options.sortChildrenBy === "TIME_ASCENDING")
            return secondsA - secondsB;
          else return secondsB - secondsA;
        } else return 0;
      }
      return blikes - bdislikes - (alikes - adislikes);
    });
    rootComments.forEach(rootComment => {
      newComments.push(rootComment);
      if (rootComment.aStatus === "LIVE") {
        const myChildren = childComments.filter(y => {
          return y.parentID == rootComment.xID;
        });
        newComments.push(...myChildren);
      }
    });
    let amModerator = false;
    if (
      x.moderatorProfileIDs &&
      global.myProfileID &&
      x.moderatorProfileIDs.includes(global.myProfileID)
    )
      amModerator = true;

    // Cut length to two if in preview mode

    if (this.props.navigation.getParam("group", null)) {
      // newComments
    } else {
      newComments = rootComments.filter(y => { return (y.aStatus == "LIVE") });
      if (!this.props.isVideoComments && newComments.length > 2) newComments.length = 2;
    }

    return (
      // <TouchableWithoutFeedback onPress={() => { Keyboard.dismiss(); }}>
      <View
        style={{
          flex: 1,
          backgroundColor: this.props.backgroundColor,
          //width: "100%",
          maxWidth: 500,
          height: "100%",
          // backgroundColor: 'red'
        }}
      >
        {(isMobile) && <View style={{ position: 'absolute', top: 5, left: 5, zIndex: 999 }}>
          <TouchableOpacity onPress={this.props.goBack}>
            <Image
              style={[{
                width: 20,
                height: 20,
                tintColor: '#A8CF45'
              },
              ]}
              source={require("../../images/icons/AndroidBack.png")}
            />
          </TouchableOpacity>

        </View>}
        {this.props.navigation.getParam("group", null) && (
          <View style={{ height: Constants.statusBarHeight }} />
          // <View style={{ height: Platform.OS == "ios" ? 42 : 50 }} />
        )}

        {(isMobile) && (!this.x.groupYoutubeID) && this.props.navigation.getParam("group", null) && !this.props.navigation.getParam("isBlogPost", false) && !(this.x?.aType == "poll") && !(this.x?.aType == "event") && !(this.x?.aType == "task") && (
          <Group
            chatGroup={x}
            view={"DETAIL"}
            join={arg => {
              this.join(arg);
            }}
            editComment={() => { this.setState({ showEditComment: true }) }}
            amJoiner={this.state.amJoiner}
            amModerator={amModerator}
            backgroundColor={this.props.navigation.getParam("titleBackgroundColor", null)}
            refresh={this.refreshGroup}
            navigation={this.props.navigation}
          />
        )}
        {amModerator && (this.state.comments.length !== 0 && (
          <View>
            {!this.state.aaModerator ? (
              <View>
                {this.state.showEditComment && <TouchableOpacity
                  onPress={() => {
                    this.editGroup({ action: "deleteAllComments" });
                  }}
                  style={{
                    borderColor: "#a33",
                    padding: 5,
                    margin: 0,
                    alignSelf: "flex-start"
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      height: 22,
                      width: "40%",
                      alignSelf: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      style={{
                        width: 16,
                        height: 16,
                        marginRight: 3,
                        tintColor: "#a33"
                      }}
                      source={require("../../images/icons/Delete.png")}
                    />
                    <Text style={{ color: "#a33" }}>Delete All (Admin)</Text>
                  </View>
                </TouchableOpacity>}
              </View>
            ) : (
              <View
                style={{
                  flexDirection: "row", marginTop: 20, alignItems: "center", justifyContent: "center"
                }}
              >
                <ActivityIndicator size="small" color="#f00" animating={true} />
              </View>
            )}
          </View>
        ))}

        {this.state.loading && !this.state.aaModerator && (
          <View
            style={{
              flexDirection: "row", height: 22, alignItems: "center", justifyContent: "center"
            }}
          >
            <ActivityIndicator size="small" color="#778" />
          </View>
        )}
        {this.state.error && (
          <View
            style={{
              flexDirection: "row",
              height: 22,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#131313"
            }}
          >
            <Text style={{ color: "#955" }}>{this.state.error}</Text>
          </View>
        )}
        <Comments
          likeIDs={this.state.likeIDs}
          dislikeIDs={this.state.dislikeIDs}
          updateLikes={(args, commentID) => this.updateLikes(args, commentID)}
          editGroup={args => {
            this.editGroup(args);
          }}
          newComment={args => {
            this.newComment(args)
          }}
          comments={newComments}
          chatGroup={x}
          showDeleteIcon={this.state.showEditComment}
          metaUpdates={this.state.metaUpdates}
          activeComment={this.state.activeComment}
          activateComment={(commentID) => { this.setState({ activeComment: commentID }) }}
          showReplyButton={this.state.showReplyButton}
          replyComment={commentID => this.replyComment(commentID, newComments)}
          generalComment={() => this.generalComment()}
          addTemporaryComment={comment => {
            this.addTemporaryComment(comment);
          }}
          lastViewedCommentTimeMilliseconds={this.state.lastViewedCommentTimeMilliseconds}
          aaModerator={this.state.aaModerator}
          amModerator={amModerator}
          backgroundColor={this.props.backgroundColor}
          allowAbout={this.state.allowAbout}
          isVideoComments={this.props.isVideoComments}
          refreshGroup={this.refreshGroup}
          navigation={this.props.navigation}
        />

        <KeyboardAvoidingView
          style={{
            // paddingHorizontal: 10,
            // paddingVertical: 5,
            marginTop: 10
          }}
          behavior={(this.props.navigation.getParam("group", false)) ? Platform.OS == "ios" ? "padding" : null : null}
          enabled={(this.props.navigation.getParam("group", false)) ? Platform.OS == "ios" : false}
        >
          {this.state.activeComment === "ROOT" && this.state.showInputBox && (!this.props.navigation.getParam("isBlogPost", false) && !(x?.aType == "poll") && !(x?.groupYoutubeID) && !(x?.aType == "event") && !(x?.aType == "task")) && (
            <TypeField
              chatGroup={x}
              parentID={"ROOT"}
              addTemporaryComment={comment => {
                this.addTemporaryComment(comment);
              }}
              dismissKeyboard={() => {
                //Keyboard.dismiss();
              }}
              resetTypeField={() => { }}
              isVideoComments={this.props.isVideoComments}
              navigation={this.props.navigation}
            />
          )}
          {
            !this.props.navigation.getParam("group", null) && (
              <View style={{ marginBottom: 20 }} />
            )
          }
        </KeyboardAvoidingView>
      </View>
      // </TouchableWithoutFeedback>
    );
  }
}
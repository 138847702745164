/**
 * @format
 * @flow
 */
import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  Platform,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
  LogBox,
} from "react-native";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import * as Font from "expo-font";
import AsyncStorage from '@react-native-async-storage/async-storage';
// import {decode, encode} from 'base-64'
import { BrowserRouter } from 'react-router-dom';
import * as ErrorRecovery from "expo-error-recovery";
import Constants from "expo-constants";
import * as Location from "expo-location";
import NetInfo from "@react-native-community/netinfo";
import { createAppContainer } from "react-navigation";
import { createMaterialTopTabNavigator } from "react-navigation-tabs";
import { createStackNavigator } from "react-navigation-stack";
import { NavigationActions } from 'react-navigation';
import firebase from "firebase";
import "firebase/firestore";
import _ from "lodash"; //xx delete in production see below
////+///import { NavigationActions } from 'react-navigation';
////+///import VoiceMain from "./Screens/shared/Voice/VoiceMain"

// const defaultHandler =
//   (ErrorUtils.getGlobalHandler && ErrorUtils.getGlobalHandler()) ||
//   ErrorUtils._globalHandler;

import WelcomeScreen from "./Screens/WelcomeScreen";
import WelcomeScreenBO from "./Screens/WelcomeScreenBO";
import WelcomeScreenArtisan from "./Screens/custom/Artisan/replacementScreens/WelcomeScreenArtisan";
import WelcomeScreenEducation from "./Screens/custom/Education/replacementScreens/WelcomeScreenEducation";
import WelcomeScreenSpeakers from "./Screens/custom/Speakers/replacementScreens/WelcomeScreenSpeakers";
import SigninScreen from "./Screens/AuthScreen/SigninScreen";
import VerifyOTPScreen from "./Screens/AuthScreen/VerifyOTPScreen";
import MeEnterDetailsScreen from "./Screens/Me/newUserOnboarding/MeEnterDetailsScreen";
import MeNewReferralsScreen from "./Screens/Me/newUserOnboarding/MeNewReferralsScreen";
import HomeMainScreen from "./Screens/Home/HomeMainScreen";
//import V1HomeMainScreen from "./Screens/Home/V1HomeMainScreen";
import HomeResultsScreen from "./Screens/Home/HomeResultsScreen";
import HomeProviderResultsScreen from "./Screens/Home/HomeProviderResultsScreen";
import HomeElectronicsScreen from "./Screens/Home/Apps/HomeElectronicsScreen";
import HomeFashionScreen from "./Screens/Home/Apps/HomeFashionScreen";
import HomeFoodScreen from "./Screens/Home/Apps/HomeFoodScreen";
import HomeHotNotScreen from "./Screens/Home/Apps/HomeHotNotScreen";
import HomeHealthScreen from "./Screens/Home/Apps/Health/HomeHealthScreen";
import HomeServicesScreen from "./Screens/Home/Apps/HomeServicesScreen";
import ServicesDetailScreen from "./Screens/Home/Apps/Services/ServicesDetailScreen";
import NewsScreen from "./Screens/Home/Apps/News/NewsScreen";
import HomeBeautyScreen from "./Screens/Home/Apps/HomeBeautyScreen";
import OfferResultScreen from "./Screens/Home/Apps/OfferResultScreen";
import ServicesPreviewScreen from "./Screens/Home/Apps/Services/ServicesPreviewScreen";
import Home_Grooming from "./Screens/Home/Apps/Services/Home_Grooming";
import HomeJobsScreen from "./Screens/Home/Apps/Jobs/HomeJobsScreen";
import HomeInnovationKitchenScreen from "./Screens/Home/Apps/InnovationKitchen/HomeInnovationKitchenScreen";
import HomeBlogScreen from "./Screens/Home/Apps/HomeBlogScreen";
import HomeSellersScreen from "./Screens/Home/Apps/HomeSellersScreen";
import HomeSellersLoanScreen from "./Screens/Home/Apps/HomeSellersLoanScreen";
import HomeInviteScreen from "./Screens/Home/Apps/HomeInviteScreen";
import InnovationKitchenAgendaScreen from "./Screens/Home/Apps/InnovationKitchen/InnovationKitchenAgendaScreen";
import InnovationKitchenAdminScreen from "./Screens/Home/Apps/InnovationKitchen/InnovationKitchenAdminScreen";
import HealthFacilitiesScreen from "./Screens/Home/Apps/Health/HealthFacilitiesScreen";
import HealthConsultScreen from "./Screens/Home/Apps/Health/HealthConsultScreen";
import HealthConsultScreenText from "./Screens/Home/Apps/Health/HealthConsultScreenText";
import DoctorConsultScreen from "./Screens/Home/Apps/Health/DoctorConsultScreen";
import HealthFileScreen from "./Screens/Home/Apps/Health/HealthFileScreen";
import HealthRecordsScreen from "./Screens/Home/Apps/Health/HealthRecordsScreen";
import HealthRecordScreen from "./Screens/Home/Apps/Health/HealthRecordScreen";
import HealthProfileScreen from "./Screens/Home/Apps/Health/HealthProfileScreen";
import HealthProfileUpdateScreen from "./Screens/Home/Apps/Health/HealthProfileUpdateScreen";
import AroundScreen from "./Screens/Home/Apps/Around/AroundScreen";
// import InquiryResultsScreen from "./Screens/Home/Inquiry/InquiryResultsScreen";
import SpecialRequestScreen from "./Screens/Home/SpecialRequest/SpecialRequestScreen";
import ExploreMainScreen from "./Screens/Explore/ExploreMainScreen";
import JobsMainScreen from "./Screens/Jobs/JobsMainScreen";
import JobsDetailScreen from "./Screens/Jobs/JobsDetailScreen";
import JobsNewScreen from "./Screens/Jobs/newOrder/JobsNewScreen";
// import JobsNewScreen from "./Screens/Jobs/JobsNewScreen";
import ChatMainScreen from "./Screens/Messages/ChatMainScreen";
import ChatDetailScreen from "./Screens/Messages/ChatDetailScreen";
import ChatGroupMainScreen from "./Screens/Messages/Groups/ChatGroupMainScreen";
import ChatGroupDetailScreen from "./Screens/Messages/Groups/ChatGroupDetailScreen";
import ChatGroupNewScreen from "./Screens/Messages/Groups/ChatGroupNewScreen";
import ChatGroupSelectOfferScreen from "./Screens/Messages/Groups/ChatGroupSelectOffer";
//import NotificationsScreen from "./Screens/NotificationsScreen";
import MeScreen from "./Screens/Me/MeScreen";
import MeProfileScreen from "./Screens/Me/Profile/MeProfileScreen";
import MeProfileUpdateScreen from "./Screens/Me/Profile/MeProfileUpdateScreen";
import MeReportScreen from "./Screens/Me/Profile/MeReportScreen";
import MeWalletScreen from "./Screens/Me/Wallet/MeWalletScreen";
import MePayScreen from "./Screens/Me/Wallet/MePayScreen";
import WalletSetupScreen from "./Screens/Me/Wallet/WalletSetupScreen";
import WalletTraderScreen from "./Screens/Me/Wallet/WalletTraderScreen";
import WalletAboutScreen from "./Screens/Me/Wallet/WalletAboutScreen";
import MeContactsScreen from "./Screens/Me/Contacts/MeContactsScreen";
import SelectContactScreen from "./Screens/shared/SelectContactScreen";
import MeAddContactsScreen from "./Screens/Me/Contacts/MeAddContactsScreen";
import MeAddReferrerScreen from "./Screens/Me/Help/MeAddReferrerScreen";
import MeCommunitiesScreen from "./Screens/Me/Communities/MeCommunitiesScreen";
import MeCommunityScreen from "./Screens/Me/Communities/MeCommunityScreen";
import MeCommunityUpdateScreen from "./Screens/Me/Communities/MeCommunityUpdateScreen";
import MeCommunityNewDiscussionScreen from "./Screens/Me/Communities/MeCommunityNewDiscussionScreen";
import CommunitySettingsScreen from "./Screens/Me/Communities/CommunitySettingsScreen";
import MeNetworksScreen from "./Screens/Me/Networks/MeNetworksScreen";
import MeNetworkScreen from "./Screens/Me/Networks/MeNetworkScreen";
import MeNetworkAdminScreen from "./Screens/Me/Networks/Admin/MeNetworkAdminScreen";
import NetworkMessageScreen from "./Screens/Me/Networks/Admin/Messages/NetworkMessagesScreen";
import NetworkDashboardScreen from "./Screens/Me/Networks/Admin/Dashboard/NetworkDashboardScreen";
import NetworkApprovalsScreen from "./Screens/Me/Networks/Admin/Approvals/NetworkApproveScreen";
import NetworkVerificationScreen from "./Screens/Me/Networks/Admin/Verification/NetworkVerificationScreen";
import MePostsScreen from "./Screens/Me/Posts/MePostsScreen";
import MePostsRearrangeScreen from "./Screens/Me/Posts/MePostsRearrangeScreen";
import MeSavedPostsScreen from "./Screens/Me/MeSavedPostsScreen";
import NewPostScreen from "./Screens/Me/Posts/NewPostScreen";
//import CameraScreen from "./Screens/shared/Camera/CameraScreen";
import PostApproveScreen from "./Screens/Me/Help/Admin/Approvals/PostApproveScreen";
//import MeStellarDashboardScreen from "./Screens/Me/MeStellarDashboardScreen";
import MeSettingsScreen from "./Screens/Me/Settings/MeSettingsScreen";
import BOSettingsScreen from "./Screens/Me/Settings/BOSettingsScreen";
import MeInterestsScreen from "./Screens/Me/Interests/MeInterestsScreen";
import MeDiscountsScreen from "./Screens/Me/Discounts/MeDiscountsScreen";
import MeBlogsManageScreen from "./Screens/Me/Blog/MeBlogsManageScreen";
import MeBlogEditScreen from "./Screens/Me/Blog/MeBlogEditScreen";
import MeBlogNewScreen from "./Screens/Me/Blog/MeBlogNewScreen";
import MeBlogPostEditScreen from "./Screens/Me/Blog/MeBlogPostEditScreen";
import MeSocialStatusScreen from "./Screens/Me/SocialStatus/MeSocialStatusScreen";
import MeAdminScreen from "./Screens/Me/Help/Admin/MeAdminScreen";
import MeHelpScreen from "./Screens/Me/Help/MeHelpScreen";
import MeWebsiteScreen from "./Screens/Me/Website/MeWebsiteScreen";
import MeWebsiteManagementScreen from "./Screens/Me/Website/MeWebsiteManagementScreen";
import GuideMessagesScreen from "./Screens/Me/Help/Admin/GuideMessages/GuideMessagesScreen";
import MerchantDashboardScreen from "./Screens/Me/Help/Admin/Merchants/MarchantDashboardScreen";
import AppAdminAssignScreen from "./Screens/Me/Help/Admin/AppAdmin/AppAdminAssignScreen";
import StaffWebsitesDashboardScreen from "./Screens/Me/Help/Admin/StaffWebsites/StaffWebsitesDashboardScreen";
import DashboardScreen from "./Screens/Me/Help/Admin/Dashboard/DashboardScreen";
import ReferrerDashboardScreen from "./Screens/Me/Help/Admin/ReferrerDashboard/ReferrerDashboardScreen";
import ReferrerDashboardDetailScreen from "./Screens/Me/Help/Admin/ReferrerDashboard/ReferrerDashboardDetailScreen";
import websitesAdmin from "./Screens/Me/Help/Admin/Websites/WebsitesAdminScreen";
import websiteCodesAdmin from "./Screens/Me/Help/Admin/WebsiteCodes/WebsiteCodesAdminScreen";
import CRMScreen from "./Screens/Me/Help/Admin/CRM/CRMScreen";
import HealthAdminScreen from "./Screens/Me/Help/Admin/Health/HealthAdminScreen";
import RecordVideo from "./Screens/shared/Camera/RecordVideo";
import LastAmount from "./firebase/handling";
import WebMainScreen from './Screens/Web/WebMainScreen'
import Header from './Screens/Web/Header'
import MHeader from './Screens/Web/mHeader'
const iconHeight = 20;

//global.cloudFunctionURL="http://localhost:5000/afbase-7ee60/us-central1/";
global.cloudFunctionURL =
  "https://us-central1-afbase-7ee60.cloudfunctions.net/";
global.isTablet = false; // calculate this in the future
//global.devMode = true; !!done in componentDidMount (delete this line)

////////CHANGE ONLY IF YOU KNOW WHAT YOU'RE DOING //////////////////
global.app = "market"; // market or health or artisan or education or speakers
////////CHANGE ONLY IF YOU KNOW WHAT YOU'RE DOING //////////////////
global.reportOnboarding = () => {

}
global.gStyles = {
  buttontX: {
    color: "#000",
    fontSize: 10
  },
  buttonX: {
    height: 24,
    borderRadius: 16,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
    paddingHorizontal: 5,
    backgroundColor: "#A8CF45",
  },
  buttontXW: {
    color: "#ccccd5",
    fontSize: 10
  },
  buttonXW: {
    height: 24,
    borderRadius: 16,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
    paddingHorizontal: 5,
    borderColor: "#ccccd5",
    borderWidth: 0.5,
  },
  buttontXL: {
    color: "#000",
    fontSize: 14
  },
  buttonXL: {
    height: 40,
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
    paddingHorizontal: 20,
    backgroundColor: "#A8CF45",
  },
  buttontXLW: {
    color: "#ccccd5",
    fontSize: 14
  },
  buttonXLW: {
    height: 40,
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
    paddingHorizontal: 20,
    borderColor: "#ccccd5",
    borderWidth: 0.5,
  },
  buttontext: {
    fontWeight: "bold",
    color: "#000",
    fontSize: 14
  },
  button: {
    alignSelf: "stretch",
    alignItems: "center",
    padding: 13,
    backgroundColor: "#A8CF45",
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 5
  },
  me: {
    paddingLeft: 14,
    height: 40,
    backgroundColor: "#222224",
    alignItems: "center",
    borderBottomColor: "#333335",
    borderBottomWidth: 1,
    borderTopWidth: 0,
    flexDirection: "row"
  },
  meText: {
    color: "#dde",
    fontSize: 18
  },
  container: { //NOTYETUSED
    flex: 1,
    justifyContent: "center",
    backgroundColor: "#00A859",
    paddingLeft: 60,
    paddingRight: 60
  }
};

global.reportError = async (error, type, subtype) => {
  //disabled for WEB right now
  return
}
global.reportErrorORIGINAL = async (error, type, subtype) => {
  if (!global.devMode) {
    try {
      console.log("REPORTING ERROR (global.reportError)");
      if (error.toString()) {
        error = error.toString();
      }
      let appVersion = "";
      if (Constants?.manifest?.version) {
        appVersion = Constants.manifest.version;
      }
      const r = await fetch(global.cloudFunctionURL + "reportError", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          isWeb: true,
          myUID: global.myUID || "unknown",
          ...(type && { type }),
          ...(subtype && { subtype }),
          error,
          info: {
            deviceName: Constants.deviceName || "-",
            expoVersion: Constants.expoVersion || "-",
            deviceYearClass: Constants.deviceYearClass || "-",
            platform: Constants.platform || "-",
            appVersion,
          },
        }),
      });
      const r2 = await r.json();

      // if success, navigate to Offers screen
      console.log("ReportError response: ", r2.msg);
    } catch (error) {
      console.log("Error trying to report error in global.reportError");
    }
  }
};

// if (!global.btoa) {  global.btoa = encode }

// if (!global.atob) { global.atob = decode }

const SigninNavigator = createAppContainer(
  createMaterialTopTabNavigator(
    {
      /* welcome:
        global.app == "artisan"
          ? WelcomeScreenArtisan
          : global.app == "education"
            ? WelcomeScreenEducation
            : global.app == "speakers"
              ? WelcomeScreenSpeakers
              : WelcomeScreen, */
      // bo: WelcomeScreenBO,
      signin: SigninScreen,
      verifyOTP: VerifyOTPScreen,
    },
    {
      ////// top-level config (welcome, auth,..)
      //initialRouteName: startScreen,
      swipeEnabled: false,
      defaultNavigationOptions: {
        tabBarVisible: false,
      },
      tabBarPosition: "top",
      tabBarOptions: {
        keyboardHidesTabBar: true,
        labelStyle: {
          fontSize: 2,
        },
        tabStyle: {
          // width: 100
        },
        style: {
          backgroundColor: "black", //used?
        },
      },
    }
  )
);

const MainNavigator = createAppContainer(
  createMaterialTopTabNavigator(
    {
      home: {
        screen: createStackNavigator(
          {
            homeMain: WebMainScreen,
            homeCommunities: MeCommunitiesScreen,
            // homeMain: HomeMainScreen,
            // // homeMain: global.app == "market" ? HomeMainScreen : V1HomeMainScreen,
            // homeElectronics: HomeElectronicsScreen,
            // homeFashion: HomeFashionScreen,
            // homeFood: HomeFoodScreen,
            // homeHotNot: HomeHotNotScreen,
            // homeServices: HomeServicesScreen,
            // servicesDetail: ServicesDetailScreen,
            // servicesPreview: ServicesPreviewScreen,
            // home_Grooming: Home_Grooming,
            // homeHealth: HomeHealthScreen,
            // homeNews: NewsScreen,
            // homeBeauty: HomeBeautyScreen,
            // homeBlog: HomeBlogScreen,
            // homeSellers: HomeSellersScreen,
            // homeSellersLoan: HomeSellersLoanScreen,
            // homeInvite: HomeInviteScreen,
            // healthFacilities: HealthFacilitiesScreen,
            // healthConsult: HealthConsultScreen,
            // healthConsultText: HealthConsultScreenText,
            // doctorConsult: DoctorConsultScreen,
            // healthFile: HealthFileScreen,
            // healthRecords: HealthRecordsScreen,
            // healthRecord: HealthRecordScreen,
            // healthProfile: HealthProfileScreen,
            // healthProfileUpdate: HealthProfileUpdateScreen,
            // homeJobs: HomeJobsScreen,
            // homeInnovationKitchen: HomeInnovationKitchenScreen,
            // innovationKitchenAgenda: InnovationKitchenAgendaScreen,
            // innovationKitchenAdmin: InnovationKitchenAdminScreen,
            // homeResults: HomeResultsScreen,
            // homeProviderResults: HomeProviderResultsScreen,

            // //offer result Screen
            // offerResults: OfferResultScreen,
            // //inquiryResults: InquiryResultsScreen,
            // homeSpecialRequest: SpecialRequestScreen,
            // homeProfile: MeProfileScreen,
            // homeProfileUpdate: MeProfileUpdateScreen,
            // homePosts: MePostsScreen,
            // homeNewPost: NewPostScreen,
            // homeEnterDetails: MeEnterDetailsScreen,
            // homeNewReferrals: MeNewReferralsScreen,
            // homeChat: ChatDetailScreen, // not used any more... all chats in messages tab
            // homeJobsNew: JobsNewScreen,
            homeGroupDetail: ChatGroupDetailScreen,
            // homeSelectOffer: ChatGroupSelectOfferScreen,
            homeCommunities: MeCommunitiesScreen,
            homeCommunity: MeCommunityScreen,
            homeCommunityUpdate: MeCommunityUpdateScreen,
            homeCommunityNewDiscussion: MeCommunityNewDiscussionScreen,
            homeCommunitySettings: CommunitySettingsScreen,
            homeEnterDetails: MeEnterDetailsScreen,
            homeNewReferrals: MeNewReferralsScreen,
            // homeAround: AroundScreen,
            // homeSelectContact: SelectContactScreen,
          },
          {
            ////// home config // tbd
            defaultNavigationOptions: {
              tabBarVisible: false,
              headerBackTitle: null,
              headerBackTitleVisible: false,
              headerStyle: {
                backgroundColor: "transparent",
              },
              headerShown: false,
              headerTitleStyle: {
                color: "#A8CF45",
              },
              headerBackTitleStyle: {},
              headerTintColor: "#A8CF45",
            },
          }
        ),
        // home icon
        navigationOptions: ({ navigation }) => {
          let notifications = navigation.getParam("notifications", 0);
          //const parent = navigation.dangerouslyGetParent()
          return {
            tabStyle: { backgroundColor: "#339" },
            tabBarIcon: ({ tintColor }) => (
              <View>
                <Image
                  style={{
                    width: iconHeight * 1.1,
                    height: iconHeight * 1.1,
                    tintColor,
                  }}
                  source={require("./Screens/images/tabNavigator/Home.png")}
                />
                {notifications >= 1 && (
                  <View
                    style={{
                      backgroundColor: "#900",
                      height: 13,
                      width: 13,
                      borderRadius: 8,
                      alignSelf: "flex-end",
                      marginTop: -24,
                      marginRight: -5,
                      marginBottom: 10,
                      borderWidth: 0.5,
                      borderColor: "#000",
                    }}
                  />
                )}
              </View>
            ),
          };
        },
      },

      // explore: {
      //   screen: createStackNavigator(
      //     {
      //       exploreMain: ExploreMainScreen,
      //       exploreProfile: MeProfileScreen,
      //     },
      //     {
      //       ////// explore config
      //       defaultNavigationOptions: {
      //         headerBackTitle: null,
      //         headerBackTitleVisible: false,
      //         headerStyle: {
      //           backgroundColor: "transparent"
      //         },
      //         headerTitleStyle: {
      //           color: "#A8CF45"
      //         },
      //         headerBackTitleStyle: {},
      //         headerTintColor: "#A8CF45"
      //       }
      //     }
      //   ),
      //   // explore icon
      //   navigationOptions: ({ navigation }) => {
      //     let notifications = navigation.getParam("notifications", 0);
      //     return {
      //       tabStyle: { backgroundColor: "#339" },
      //       tabBarIcon: ({ tintColor }) => (
      //         <View>
      //           {/* <Image
      //           style={{
      //             width: iconHeight,
      //             height: iconHeight
      //           }}
      //           source={require("./Screens/images/tabNavigator/Explore.png")}
      //         /> */}
      //           <View
      //             style={{
      //               width: iconHeight + 4,
      //               height: iconHeight + 8,
      //               //borderRadius: iconHeight / 2 + 1,
      //               // backgroundColor: "#0D6833",
      //               alignItems: "flex-start",
      //               justifyContent: "flex-start",
      //               marginTop: -4,
      //             }}
      //           >
      //             <Text
      //               style={{
      //                 fontSize: 26, fontWeight: "bold", paddingBottom: 16,
      //                 color: tintColor
      //               }}>
      //               ☆
      //             </Text>
      //           </View>
      //           {notifications >= 1 && (
      //             <View
      //               style={{
      //                 backgroundColor: "#900",
      //                 height: 17,
      //                 width: 22,
      //                 borderRadius: 8,
      //                 alignSelf: "flex-end",
      //                 alignItems: "center",
      //                 marginTop: -29,
      //                 marginRight: -5,
      //                 marginLeft: 10,
      //                 marginBottom: 10,
      //                 borderWidth: 0.5,
      //                 borderColor: "#000",
      //                 justifyContent: "center"
      //               }}
      //             >
      //               <Text style={{ color: "#ded", fontSize: 10 }}>
      //                 {notifications}
      //               </Text>
      //             </View>
      //           )}
      //         </View>)
      //     };
      //   }
      // },

      // jobs: {
      //   screen: createStackNavigator(
      //     {
      //       jobsMain: JobsMainScreen,
      //       jobsDetail: JobsDetailScreen,
      //       jobsNew: JobsNewScreen,
      //       jobsProfile: MeProfileScreen,
      //     },
      //     {
      //       ////// jobs config
      //       defaultNavigationOptions: {
      //         headerBackTitle: null, // ENABLE HERE
      //         headerBackTitleVisible: false,
      //         headerStyle: {
      //           backgroundColor: "transparent",
      //         },
      //         headerTitleStyle: {
      //           color: "#A8CF45",
      //         },
      //         headerBackTitleStyle: {},
      //         headerTintColor: "#A8CF45",
      //       },
      //     }
      //   ),
      //   // jobs icon
      //   navigationOptions: ({ navigation }) => {
      //     let notifications = navigation.getParam("notifications", 0);
      //     return {
      //       tabBarIcon: ({ tintColor }) => (
      //         <View>
      //           <Image
      //             style={{
      //               width: iconHeight * 1.1,
      //               height: iconHeight * 1.021,
      //               tintColor,
      //             }}
      //             source={require("./Screens/images/tabNavigator/Jobs.png")}
      //           />
      //           {notifications >= 1 && (
      //             <View
      //               style={{
      //                 backgroundColor: "#900",
      //                 height: 17,
      //                 width: 22,
      //                 borderRadius: 8,
      //                 alignSelf: "flex-end",
      //                 alignItems: "center",
      //                 marginTop: -29,
      //                 marginRight: -5,
      //                 marginLeft: 10,
      //                 marginBottom: 10,
      //                 borderWidth: 0.5,
      //                 borderColor: "#000",
      //                 justifyContent: "center",
      //               }}
      //             >
      //               <Text style={{ color: "#ded", fontSize: 10 }}>
      //                 {notifications}
      //               </Text>
      //             </View>
      //           )}
      //         </View>
      //       ),
      //       // ) : (
      //       //     <View style={{}}>
      //       //       <Image
      //       //         style={{
      //       //           width:
      //       //             notifications == 0
      //       //               ? iconHeight * 1.1
      //       //               : (iconHeight * 1.1) / 2, //android
      //       //           height: notifications == 0 ? iconHeight : iconHeight / 2, //android
      //       //           tintColor
      //       //         }}
      //       //         source={require("./Screens/images/tabNavigator/Jobs.png")}
      //       //       />
      //       //       {notifications >= 1 && (
      //       //         <View
      //       //           style={{
      //       //             backgroundColor: "#900", //android
      //       //             height: 13, //android,
      //       //             width: 18, // android,
      //       //             borderRadius: 8, //android
      //       //             alignSelf: "flex-end", //android
      //       //             alignItems: "center", //android
      //       //             marginTop: -19, //android
      //       //             marginRight: 0, //android
      //       //             marginLeft: 6, //android
      //       //             borderWidth: 0.5, //android
      //       //             borderColor: "#000", //android
      //       //             justifyContent: "center" //android
      //       //           }}
      //       //         >
      //       //           <Text style={{ color: "#ded", fontSize: 10 }}>
      //       //             {notifications}
      //       //           </Text>
      //       //         </View>
      //       //       )}
      //       //     </View>
      //       //   )
      //     };
      //   },
      // },

      // wallet: {
      //   screen: createStackNavigator(
      //     {
      //       walletMain: MeWalletScreen,
      //       walletSelectContact: SelectContactScreen,
      //       walletPay: MePayScreen,
      //       walletSetup: WalletSetupScreen,
      //       walletTrader: WalletTraderScreen,
      //       walletAbout: WalletAboutScreen,
      //     },
      //     {
      //       ////// wallet config
      //       defaultNavigationOptions: {
      //         tabBarVisible: false,
      //         headerBackTitle: null, // ENABLE HERE
      //         headerBackTitleVisible: false,
      //         // headerStyle: {
      //         //   backgroundColor: "transparent"
      //         // },
      //         // headerTitleStyle: {
      //         //   color: "#A8CF45"
      //         // },
      //         // headerBackTitleStyle: {},
      //         // headerTintColor: "#A8CF45"
      //       },
      //     }
      //   ),
      //   // wallet icon
      //   navigationOptions: ({ navigation }) => {
      //     let notifications = navigation.getParam("notifications", 0);
      //     return {
      //       tabBarIcon: ({ tintColor }) => (
      //         <View>
      //           <View
      //             style={{
      //               width: iconHeight + 2,
      //               height: iconHeight + 2,
      //               borderRadius: iconHeight / 2 + 1,
      //               backgroundColor: tintColor,
      //               // backgroundColor: "#0D6833",
      //               alignItems: "center",
      //               justifyContent: "center",
      //             }}
      //           >
      //             <Text
      //               style={{
      //                 fontSize: 16.5,
      //                 fontWeight: "bold",
      //                 paddingLeft: Platform.OS == "android" ? 0 : 0.9,
      //                 paddingBottom: Platform.OS == "android" ? 2 : 0,
      //               }}
      //             >
      //               ₳
      //             </Text>
      //           </View>

      //           {notifications >= 1 && (
      //             <View
      //               style={{
      //                 backgroundColor: "#900",
      //                 height: 17,
      //                 width: 22,
      //                 borderRadius: 8,
      //                 alignSelf: "flex-end",
      //                 alignItems: "center",
      //                 marginTop: -29,
      //                 marginRight: -5,
      //                 marginLeft: 10,
      //                 marginBottom: 10,
      //                 borderWidth: 0.5,
      //                 borderColor: "#000",
      //                 justifyContent: "center",
      //               }}
      //             >
      //               <Text style={{ color: "#ded", fontSize: 10 }}>
      //                 {notifications}
      //               </Text>
      //             </View>
      //           )}
      //         </View>
      //       ),
      //     };
      //   },
      // },

      // messages: {
      //   screen: createStackNavigator(
      //     {
      //       chatMain: ChatMainScreen,
      //       chatDetail: ChatDetailScreen,
      //       messageSelectContact: SelectContactScreen,
      //       messageProfile: MeProfileScreen,
      //       messageReport: MeReportScreen,
      //       messageJobsNew: JobsNewScreen, // in folder Jobs
      //       chatGroupMain: ChatGroupMainScreen,
      //       chatGroupDetail: ChatGroupDetailScreen,
      //       messageSelectOffer: ChatGroupSelectOfferScreen,
      //       chatGroupNew: ChatGroupNewScreen,
      //       chatCommunities: MeCommunitiesScreen,
      //       chatCommunity: MeCommunityScreen,
      //       chatCommunityUpdate: MeCommunityUpdateScreen,
      //       chatCommunityNewDiscussion: MeCommunityNewDiscussionScreen,
      //       chatCommunitySettings: CommunitySettingsScreen,
      //       chatGroupDetail: ChatGroupDetailScreen,
      //       chatBOSettings: BOSettingsScreen,
      //       chatBlog: HomeBlogScreen,
      //     },
      //     {
      //       ////// messages config
      //       defaultNavigationOptions: {
      //         headerBackTitle: null, // ENABLE HERE
      //         headerBackTitleVisible: false,
      //         headerStyle: {
      //           backgroundColor: "transparent",
      //         },
      //         headerTitleStyle: {
      //           color: "#A8CF45",
      //         },
      //         headerBackTitleStyle: {},
      //         headerTintColor: "#A8CF45",
      //       },
      //     }
      //   ),
      //   // messages icon
      //   navigationOptions: ({ navigation }) => {
      //     let notifications = navigation.getParam("notifications", 0);
      //     return {
      //       tabBarIcon: ({ tintColor }) => (
      //         <View>
      //           <Image
      //             style={{
      //               width: iconHeight,
      //               height: iconHeight,
      //               tintColor,
      //             }}
      //             source={require("./Screens/images/tabNavigator/Messages.png")}
      //           />
      //           {notifications >= 1 && (
      //             <View
      //               style={{
      //                 backgroundColor: "#900",
      //                 height: 17,
      //                 width: 22,
      //                 borderRadius: 8,
      //                 alignSelf: "flex-end",
      //                 alignItems: "center",
      //                 marginTop: -29,
      //                 marginRight: -5,
      //                 marginLeft: 10,
      //                 marginBottom: 10,
      //                 borderWidth: 0.5,
      //                 borderColor: "#000",
      //                 justifyContent: "center",
      //               }}
      //             >
      //               <Text style={{ color: "#ded", fontSize: 10 }}>
      //                 {notifications}
      //               </Text>
      //             </View>
      //           )}
      //         </View>
      //       ),
      //     };
      //   },
      // },
      // //notifications: NotificationsScreen,

      //   me: {
      //     screen: createStackNavigator(
      //       {
      //         meMain: MeScreen,
      //         meProfile: MeProfileScreen,
      //         meProfileUpdate: MeProfileUpdateScreen,
      //         meReport: MeReportScreen,
      //         meWallet: MeWalletScreen,
      //         mePay: MePayScreen,
      //         meContacts: MeContactsScreen,
      //         meAddContacts: MeAddContactsScreen,
      //         meCommunities: MeCommunitiesScreen,
      //         meCommunity: MeCommunityScreen,
      //         meCommunityUpdate: MeCommunityUpdateScreen,
      //         meCommunityNewDiscussion: MeCommunityNewDiscussionScreen,
      //         meCommunitySettings: CommunitySettingsScreen,
      //         meNetwork: MeNetworkScreen,
      //         meNetworks: MeNetworksScreen,
      //         meNetworkAdmin: MeNetworkAdminScreen,
      //         networkMessage: NetworkMessageScreen,
      //         networkDashboard: NetworkDashboardScreen,
      //         networkApprovals: NetworkApprovalsScreen,
      //         networkVerification: NetworkVerificationScreen,
      //         meWebsite: MeWebsiteScreen,
      //         meWebsiteManagement: MeWebsiteManagementScreen,
      //         meSavedPosts: MeSavedPostsScreen,
      //         mePosts: MePostsScreen,
      //         newPost: NewPostScreen,
      //         mePostsRearrange: MePostsRearrangeScreen,
      //         meOrders: JobsMainScreen,
      //         meAdmin: MeAdminScreen,
      //         meHelp: MeHelpScreen,
      //         meAddReferrer: MeAddReferrerScreen,
      //         websitesAdmin: websitesAdmin,
      //         websiteCodesAdmin: websiteCodesAdmin,
      //         dashboard: DashboardScreen,
      //         referrerDashboard: ReferrerDashboardScreen,
      //         referrerDashboardDetail: ReferrerDashboardDetailScreen,
      //         guideMessages: GuideMessagesScreen,
      //         merchantDashboard: MerchantDashboardScreen,
      //         appAdminAssign: AppAdminAssignScreen,
      //         staffWebsitesDashboard: StaffWebsitesDashboardScreen,
      //         meHelp: MeHelpScreen,
      //         postApprove: PostApproveScreen,
      //         crm: CRMScreen,
      //         healthAdmin: HealthAdminScreen,
      //         //meStellarDashboard: MeStellarDashboardScreen,
      //         meSettings: MeSettingsScreen,
      //         meBOSettings: BOSettingsScreen,
      //         meInterests: MeInterestsScreen,
      //         meDiscounts: MeDiscountsScreen,
      //         meEnterDetails: MeEnterDetailsScreen,
      //         MeNewReferrals: MeNewReferralsScreen,
      //         meChat: ChatDetailScreen, // not used any more... all chats in messages tab
      //         meJobsNew: JobsNewScreen,
      //         meSelectContact: SelectContactScreen,
      //         meWalletTrader: WalletTraderScreen,
      //         meGroupDetail: ChatGroupDetailScreen,
      //         meSelectOffer: ChatGroupSelectOfferScreen,
      //         meBlog: HomeBlogScreen,
      //         meBlogsManage: MeBlogsManageScreen,
      //         meBlogNew: MeBlogNewScreen,
      //         meBlogEdit: MeBlogEditScreen,
      //         meBlogPostEdit: MeBlogPostEditScreen,
      //         meSocialStatus: MeSocialStatusScreen,
      //         recordVideo: RecordVideo,
      //         //meCamera: CameraScreen
      //       },
      //       {
      //         ////// me config
      //         defaultNavigationOptions: {
      //           headerBackTitle: null, // ENABLE HERE
      //           headerBackTitleVisible: false,
      //           headerStyle: {
      //             backgroundColor: "transparent",
      //           },
      //           headerTitleStyle: {
      //             color: "#A8CF45",
      //           },
      //           headerBackTitleStyle: {},
      //           headerTintColor: "#A8CF45",
      //         },
      //       }
      //     ),
      //     // me icon
      //     navigationOptions: ({ navigation }) => {
      //       let notifications = navigation.getParam("notifications", 0);
      //       return {
      //         tabBarIcon: ({ tintColor }) => (
      //           <View>
      //             <Image
      //               style={{
      //                 width: iconHeight,
      //                 height: iconHeight,
      //                 tintColor,
      //               }}
      //               source={require("./Screens/images/tabNavigator/Me.png")}
      //             />
      //             {notifications >= 1 && (
      //               <View
      //                 style={{
      //                   backgroundColor: "#900",
      //                   height: 17,
      //                   width: 22,
      //                   borderRadius: 8,
      //                   alignSelf: "flex-end",
      //                   alignItems: "center",
      //                   marginTop: -29,
      //                   marginRight: -5,
      //                   marginLeft: 10,
      //                   marginBottom: 10,
      //                   borderWidth: 0.5,
      //                   borderColor: "#000",
      //                   justifyContent: "center",
      //                 }}
      //               >
      //                 <Text style={{ color: "#ded", fontSize: 10 }}>
      //                   {notifications}
      //                 </Text>
      //               </View>
      //             )}
      //           </View>
      //         ),
      //       };
      //     },
      //   },
      // },
      // {
      //   //////main config
      //   tabBarPosition: "bottom",
      //   swipeEnabled: false,
      //   tabBarOptions: {
      //     keyboardHidesTabBar: true,
      //     pressColor: "#fff",
      //     tintColor: "red",
      //     activeTintColor: "#A8CF45",
      //     //inactiveTintColor: '#555',  // change here
      //     labelStyle: {
      //       //fontSize: 10
      //     },
      //     tabStyle: {
      //       //width: 100
      //     },
      //     style: {
      //       backgroundColor: "black",
      //       // backgroundColor: "black",
      //       height: Dimensions.get("window").height >= 800 ? 77 : 49, //filter for iPhone X and Tabs
      //     },
      //     // tabBarComponent: ({ navigation, ...rest }) => {
      //     //   //const filteredTabNavigatorRoutes = navigation.state.routes.filter(route => isRouteAllowed(route));
      //     //   const filteredTabNavigatorRoutes = navigation.state.routes;
      //     //   return (
      //     //     <MaterialTopTabBar
      //     //       {...rest}
      //     //       navigation={{
      //     //         ...navigation,
      //     //         state: { ...navigation.state, routes: filteredTabNavigatorRoutes },
      //     //       }}
      //     //     />
      //     //   )
      //     // },
      //     //tabBarComponent: props => <MaterialTopTabBar {...props} style={{ borderTopColor: '#605F60',backgroundColor: '#605F60' }} />,
      //     // tabBarComponent: props => {
      //     //   const backgroundColor = props.position.interpolate({
      //     //     inputRange: [0, 1, 2],
      //     //     outputRange: ['orange', 'white', 'green'],
      //     //   })
      //     //   return (
      //     //     <TabBarBottom
      //     //       {...props}
      //     //       style={{ backgroundColor: backgroundColor }}
      //     //     />
      //     //   );
      //     // },
      //     labelStyle: {
      //       color: "#aaa",
      //     },
      //     indicatorStyle: {
      //       backgroundColor: "#000",
      //     },
      //     showIcon: true,
      //     showLabel: false,
      //   },
    }
  )
);

// const defaultErrorHandler = ErrorUtils.getGlobalHandler();
// const myErrorHandler = async (e, isFatal) => {
//   // e: the error throwed
//   // isFatal: if the error is fatal and will kill the app
//   // define your code here...
//   // after all, if you want to forward to default error handler
//   // just call the variable we stored in the previous step
//   alert("newERROR")
//   console.log("newERROR")
//   console.log("newERROR")
//   console.log("newERROR")
//   console.log("newERROR")
//   console.log("newERROR")
//   console.log("newERROR")
//   await AsyncStorage.setItem("wakandaFatalError", e);
//   await AsyncStorage.setItem("wakandaFatalErrortst", "MyError2.0");
//   defaultErrorHandler(e, isFatal);
// };
// ErrorUtils.setGlobalHandler(myErrorHandler);

// const defaultHandler =
//   (ErrorUtils.getGlobalHandler && ErrorUtils.getGlobalHandler()) ||
//   ErrorUtils._globalHandler;
// const customErrorHandler = async (err, isFatal) => {
//   await AsyncStorage.setItem(
//     "lastError",
//     JSON.stringify(err, Object.getOwnPropertyNames(err))
//   );
//   return defaultHandler(err, isFatal);
// };
// ErrorUtils.setGlobalHandler(customErrorHandler);

export default class App extends Component {
  state = {
    authChecked: false,
    signedIn: false,
    isConnected: true,
    viewSelection: "Feed"
  };

  constructor() {
    super();

    if (!global.location)
      global.location = { coords: { latitude: 6.7, longitude: 3.4 } };
    if (!global.myLocation)
      global.myLocation = { coords: { latitude: 6.7, longitude: 3.4 } };

    //global.tabBarIconDefaultColor = "#555";
    // global.tabBarIconDefaultColor = "#0D6833"; // Original WM
    //global.tabBarIconDefaultColor = "#42CBA6"; // New green on frontscreen
    //global.tabBarIconColor = global.tabBarIconDefaultColor;
    //global.tabBarBackgroundColor = "black"; // doesn't work
    this.unsubscribeFirestore = () => {
      return;
    };
    this.unsubscribeFirestore2 = () => {
      return;
    };
    this.unsubscribeNetInfo = () => {
      return;
    };
    const firestore = firebase.firestore();
    if (global.firestoreHasPersisted && global.devMode)
      console.log("Hot Reload - Firestore Persistence enabling skipped");
    else {
      console.log("Enabling Firestore Persistence");
      global.firestoreHasPersisted = true;
      firestore.enablePersistence().catch(function (err) {
        if (err.code == "failed-precondition") {
          console.log(
            "Error with firebase persistence (failed-precondition: multiple logins?)",
            err
          );
        } else if (err.code == "unimplemented") {
          console.log(
            "Error with firebase persistence (unimplemented: wrong browser??)",
            err
          );
        }
      });
    }
    global.SWIDTH = Dimensions.get("window").width
    global.SHEIGHT = Dimensions.get("window").height
    this.recording = null;
  }

  async componentDidMount() {
    console.log("App did mount.");
    this.setState({ authChecked: false });
    Font.loadAsync({
      "ProximaNovaA-Bold": require("./assets/font/ProximaNovaA-Bold.ttf"),
      "ProximaNovaA-Regular": require("./assets/font/ProximaNovaA-Regular.ttf"),
    });
    // this.prepareLocation();




    try {
      this.unsubscribeFirestore = firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          console.log("User is signed in ");
          this.setState({ signedIn: true, authChecked: true });
        } else {
          console.log("User is not signed in");
          this.setState({ signedIn: false, authChecked: true });
        }
      });
      console.log("Firebase authentication request without errors");
    } catch (err) {
      alert("Signin Error");
      console.log("Signin Error", err);
    }

    this.unsubscribeNetInfo = NetInfo.addEventListener((state) => {
      console.log(
        "Is connected?",
        state.isConnected,
        "Connection type",
        state.type
      );
      this.setState({ isConnected: state.isConnected });
      global.isConnected = state.isConnected;
    });
    global.devMode = Constants?.manifest?.packagerOpts?.dev ? true : false;

    // xx maybe confirm that the below is just because of using the firebase web sdk
    //LogBox.ignoreLogs(["Setting a timer", "Error enabling offline"]);
    const _console = _.clone(console);
    console.warn = (message) => {
      if (
        message.indexOf("Setting a timer") <= -1 &&
        message.indexOf("Error enabling offline storage") <= -1 &&
        global.devMode
      ) {
        _console.warn(message);
      }
      if (
        !message.startsWith("Error WM - ") &&
        !global.devMode &&
        global.reportError
      )
        global.reportError?.(
          message,
          "warn",
          message.replace(/[^a-zA-Z0-9]/g, "").substring(0, 30)
        );
    };
    console.log = (...messages) => {
      if (global.devMode) {
        _console.log(...messages);
      }
    };

    // **********************************************************************************
    // ErrorReport: this is set if the app has crashed with a fatal JS error and has been reloaded
    // **********************************************************************************
    if (this.props.exp.errorRecovery) {
      let uid;
      try {
        uid = await firebase.auth().currentUser.uid;
      } catch (e) {
        console.log(
          "ErrorReporting: 1st attempt of myUID failed. No problem yet"
        );
      }
      if (!uid) {
        await new Promise((resolve) => {
          setTimeout(resolve, 2000);
        });
        try {
          uid = await firebase.auth().currentUser.uid;
        } catch (e) {
          console.log(
            "ErrorReporting: 2nd attempt of myUID failed. No problem yet"
          );
        }
      }
      if (!uid) {
        await new Promise((resolve) => {
          setTimeout(resolve, 12000);
        });
        try {
          uid = await firebase.auth().currentUser.uid;
        } catch (e) {
          console.log(
            "ErrorReporting: 3rd attempt of myUID failed. Trying to submit error without myUID"
          );
        }
      }
      if (!uid) {
        if (this.state.signedIn) {
          if (this.state.authChecked) {
            uid = "UNKNOWN_SIGNEDIN_AUTHCHECKED";
          } else {
            uid = "UNKNOWN_SIGNEDIN_!AUTHCHECKED";
          }
        } else {
          if (this.state.authChecked) {
            uid = "UNKNOWN_!SIGNEDIN_AUTHCHECKED";
          } else {
            uid = "UNKNOWN_!SIGNEDIN_!AUTHCHECKED";
          }
        }
      }
      if (!global.devMode) {
        try {
          let appVersion = "";
          if (Constants.manifest.version) {
            appVersion = Constants.manifest.version;
          }

          const r = await fetch(global.cloudFunctionURL + "reportError", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              isWeb: true,
              isWeb: true,
              myUID: uid || "N.N.",
              error: this.props.exp.errorRecovery,
              info: {
                deviceName: Constants.deviceName || "-",
                expoVersion: Constants.expoVersion || "-",
                deviceYearClass: Constants.deviceYearClass || "-",
                platform: Constants.platform || "-",
                appVersion,
              },
            }),
          });
          const r2 = await r.json();

          // if success, navigate to Offers screen
          console.log("ReportError response: ", r2.msg);
        } catch (error) {
          console.log(
            "Error trying to report error (ReportError), Trying simpler one"
          );
          try {
            const r = await fetch(global.cloudFunctionURL + "reportError", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                isWeb: true,
                isWeb: true,
                myUID: "FALLBACK_REPORT",
                error: this.props.exp.errorRecovery,
              }),
            });
          } catch (error) {
            console.log("Error trying to report error (ReportError)... again");
          }
        }
      }

      // fetch here
      //alert("Your app restarted due to abnormal circumstances.");
      console.log(
        "app restarted due to abnormal circumstances. initialProps.exp:",
        this.props.exp
      );
    } else {
      console.log("No error recovery props found");
    }
    ErrorRecovery.setRecoveryProps({
      theError: "TheDefaultError, happens if no componentDidCatch",
    });

    //if (ErrorUtils._globalHandler) {
    // this.defaultHandler =
    //   (ErrorUtils.getGlobalHandler && ErrorUtils.getGlobalHandler()) ||
    //   ErrorUtils._globalHandler;
    // ErrorUtils.setGlobalHandler(async (error, isFatal) => {
    //   ErrorRecovery.setRecoveryProps({ handlerErr: error.toString() });
    //   if (!isFatal) {
    //     if (global.reportError) {
    //       await global.reportError(error, "nonFatal_fromErrorHandler");
    //     }
    //   }
    //   this.defaultHandler(error, isFatal); //after you're finished, call the defaultHandler so that react-native also gets the error
    // });
    //}
  }

  componentWillUnmount() {
    //alert("Unmount");
    console.log("Unmounting app.js");
    this.unsubscribeFirestore();
    this.unsubscribeFirestore2();
    this.unsubscribeNetInfo();
  }

  async componentDidCatch(error, errorInfo) {
    //catches mainly JavaScript fatal errors. Sets them as initialProps so that ErrorRecovery (above) can send them to Firestore once the app has restarted.

    // try {
    //   const r = await fetch(global.cloudFunctionURL + "reportError", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json"
    //     },
    //     body: JSON.stringify({ isWeb:true,
    //       myUID: global.myUID,
    //       error: {
    //         error,
    //         errorString:error.toString(),
    //         errorInfo,
    //         jError,
    //         jErrorInfo,
    //         thisIsSentBeforeRestart: true
    //       }
    //     })
    //   });
    //   const r2 = await r.json();

    //   // if success, navigate to Offers screen
    //   console.log("ReportError response: ", r2);
    // } catch (error) {
    //   console.log("Error trying to report error (ReportError)");
    // }

    ErrorRecovery.setRecoveryProps({ err: error.toString() });
    console.log("CRASH", error);
    //call standard error handler by Expo
    if (Platform.OS === "ios") {
      // ios would else get stuck on a white screen
      // defaultHandler(error.toString(), true); //true for isFatal
    }
  }

  prepareLocation = async () => {
    global.locationLastUpdated = new Date();
    const latitudeS = await AsyncStorage.getItem("latitude");
    const longitudeS = await AsyncStorage.getItem("longitude");
    if (latitudeS && longitudeS) {
      const latitude = Number(latitudeS);
      const longitude = Number(longitudeS);
      global.location = { coords: { latitude, longitude } };
      global.myLocation = { coords: { latitude, longitude } };
      //console.log("global location: ", global.location)
    }
    if (Platform.OS == "android") {
      const locationOn = await Location.hasServicesEnabledAsync();
      if (!locationOn) return;
    }
    // try {
    //   const { status } = await Permissions.getAsync(Permissions.LOCATION)
    //   if (status !== "granted") return;
    // } catch (err) {
    //   console.log(err)
    // }
    if (!global.location) this.getCurrentLocation1();
    this.getCurrentLocation2();
  };

  getCurrentLocation1 = async () => {
    try {
      const myLocation = await Location.getCurrentPositionAsync({
        accuracy: 3,
        maximumAge: 60000,
      });
      if (myLocation && myLocation.coords) {
        global.location = myLocation;
        global.myLocation = myLocation;
      } else console.log("Error. Wrong location format..", myLocation);
      //console.log("global location: ", global.location)
    } catch (err) {
      console.log("error cc1", err);
    }
  };
  getCurrentLocation2 = async () => {
    try {
      const myLocation = await Location.getCurrentPositionAsync({
        accuracy: 5,
      });
      if (
        myLocation &&
        myLocation.coords &&
        myLocation.coords.latitude &&
        myLocation.coords.longitude
      ) {
        global.location = myLocation;
        global.myLocation = myLocation;
        await AsyncStorage.setItem(
          "latitude",
          myLocation.coords.latitude.toString()
        );
        await AsyncStorage.setItem(
          "longitude",
          myLocation.coords.longitude.toString()
        );
      } else console.log("Error. Wrong location format", myLocation);
      //console.log("global location: ", global.location)
    } catch (err) {
      console.log("error cc2", err);
    }
  };

  render() {
    if (!this.state.authChecked) {
      return (
        <BrowserRouter>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "black",
            }}
          >
            {/* <Image
            style={{ width: 100, height: 100 }}
            source={require("./AF.png")}
          /> */}
            <Image
              style={{
                width: 100,
                height: 80,
                margin: 30,
                marginLeft: 30,
                //opacity: 0.75,

                //tintColor: "#fff"
              }}
              source={require("./Screens/images/logos/home_logo.png")}
            />
            <View style={{ marginRight: 10, opacity: 0.5 }}>
              <ActivityIndicator size="small" color="#00a649" />
            </View>
          </View>
        </BrowserRouter>
      );
    } else {
      if (this.state.signedIn) {
        if (isMobile) {
          return (
            <BrowserRouter>
              <View style={{ flex: 1, }}>
                <View style={{ position: 'absolute', top: 0, width: '100%', height: 50, zIndex: 2, backgroundColor: 'black' }}>
                  <MHeader
                    // key={this.state.viewSelection}
                    view={this.state.viewSelection}
                    onPress={viewSelection => {
                      this.setState({ viewSelection })
                    }}
                    nav={(routeName, params, key) => {
                      this.navigator && this.navigator.dispatch(
                        NavigationActions.navigate({ routeName, params: params || {}, key: key || "defaultKey" })
                      );
                    }} />
                </View>

                <MainNavigator
                  ref={(nav) => {
                    this.navigator = nav;
                  }}
                  tabBarOptions={{ keyboardHidesTabBar: true }}
                  onNavigationStateChange={(prevState, currentState, action) => {
                    function getRoute(navigationState) {
                      if (!navigationState) {
                        return null;
                      }
                      const route = navigationState.routes[navigationState.index];
                      // dive into nested navigators
                      if (route.routes) {
                        return getRoute(route);
                      }
                      return route.routeName;
                    }
                    const route = getRoute(currentState);
                    global.activeRoute = route;
                  }}
                />
                {!this.state.isConnected && (
                  <View
                    style={{
                      position: "absolute",
                      // bottom: Dimensions.get("window").height >= 800 ? 78 : 48,
                      bottom: 0,
                      height: 25,
                      width: "100%",
                      backgroundColor: "rgba(128,0,0,1)",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text style={{ color: "#fff" }}>No internet connection</Text>
                  </View>
                )}

                {/* <VoiceMain nav={(routeName, params, key) => {
              this.navigator && this.navigator.dispatch(
                NavigationActions.navigate({ routeName, params: params || {}, key: key || "defaultKey" })
              );
            }} /> */}
                {/*  <View style={{
                height: 50,
                backgroundColor: 'black',
                position: 'absolute',
                top: 0,
                width: '100%', alignItems: "center",
                justifyContent: "center",
              }}> 

              </View>*/}
              </View>
            </BrowserRouter>
          )
        }
        return (
          <BrowserRouter>
            <View style={{ flex: 1, }}>
              <View style={{ position: 'absolute', top: 0, width: '100%', height: 50, zIndex: 2, backgroundColor: 'black' }}>
                <Header
                  // key={this.state.viewSelection}
                  view={this.state.viewSelection}
                  onPress={viewSelection => {
                    this.setState({ viewSelection })
                  }}
                  nav={(routeName, params, key) => {
                    this.navigator && this.navigator.dispatch(
                      NavigationActions.navigate({ routeName, params: params || {}, key: key || "defaultKey" })
                    );
                  }} />
              </View>

              <MainNavigator
                ref={(nav) => {
                  this.navigator = nav;
                }}
                tabBarOptions={{ keyboardHidesTabBar: true }}
                onNavigationStateChange={(prevState, currentState, action) => {
                  function getRoute(navigationState) {
                    if (!navigationState) {
                      return null;
                    }
                    const route = navigationState.routes[navigationState.index];
                    // dive into nested navigators
                    if (route.routes) {
                      return getRoute(route);
                    }
                    return route.routeName;
                  }
                  const route = getRoute(currentState);
                  global.activeRoute = route;
                }}
              />
              {!this.state.isConnected && (
                <View
                  style={{
                    position: "absolute",
                    // bottom: Dimensions.get("window").height >= 800 ? 78 : 48,
                    bottom: 0,
                    height: 25,
                    width: "100%",
                    backgroundColor: "rgba(128,0,0,1)",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ color: "#fff" }}>No internet connection</Text>
                </View>
              )}

              {/* <VoiceMain nav={(routeName, params, key) => {
              this.navigator && this.navigator.dispatch(
                NavigationActions.navigate({ routeName, params: params || {}, key: key || "defaultKey" })
              );
            }} /> */}
              {/*  <View style={{
                height: 50,
                backgroundColor: 'black',
                position: 'absolute',
                top: 0,
                width: '100%', alignItems: "center",
                justifyContent: "center",
              }}> 

              </View>*/}
            </View>
          </BrowserRouter>
        );
      } else {
        return <BrowserRouter><SigninNavigator /></BrowserRouter>;
      }
    }
  }
}

const styles = {
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F5FCFF",
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    margin: 10,
  },
};

// class ExampleBoundary extends Component {
//     constructor(props) {
//         super(props);
//         this.state = { error: null };
//     }

//     componentDidCatch(error, errorInfo) {
//       this.setState({ error });
//       Sentry.withScope(scope => {
//         Object.keys(errorInfo).forEach(key => {
//           scope.setExtra(key, errorInfo[key]);
//         });
//         Sentry.captureException(error);
//       });
//     }

//     render() {
//         if (this.state.error) {
//             //render fallback UI
//             return (
//               <a onClick={() => Sentry.showReportDialog()}>Report feedback</a>
//             );
//         } else {
//             //when there's not an error, render children untouched
//             return this.props.children;
//         }
//     }
// }

// class ErrorBoundary extends Component {
//   constructor (props) {
//     super(props)
//     this.state = { hasError: false }
//   }
//   componentDidCatch (error, info) {
//     this.setState({ hasError: true })
//   }
//   render () {
//     if (this.state.hasError) {
//       return <h1>Something went wrong.</h1>
//     }
//     return this.props.children
//   }
// }

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  Alert,
  Dimensions,
} from "react-native";
import data from "../imageData";
import * as Font from "expo-font";
import { LinearGradient } from "expo-linear-gradient";
import { Feather } from "@expo/vector-icons";
import { SvgCss } from "react-native-svg";
import firebase from "firebase";
import "firebase/firestore";
import CachedImage from "../../../shared/CachedImage";
// import CachedImage from "../../shared/CachedImage";

// import data from "./imageData";
// import ProductPreview from "./shared/ProductPreview";
// import CommunityPreview from "./shared/CommunityPreview";
// import ShopPreview from "./shared/ShopPreview";

const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;

const renderData = [
  {
    title: "Salons & Barbers",
    image: require("../../../images/home_grooming/barber.jpg"),
    globalCategory: "Beauty",
  },

  {
    title: "Taylors & Fashion Design",
    image: require("../../../images/home_grooming/tailoringNaija.jpg"),
    globalCategory: "Fashion",
  },

  {
    title: "Catering",
    image: require("../../../images/home_grooming/catering.jpeg"),
    globalCategory: "Food",
  },

  {
    title: "Cleaning",
    image: require("../../../images/home_grooming/cleaner.jpg"),
    globalCategory: "Home",
  },
  {
    title: "Drycleaning",
    image: require("../../../images/home_grooming/DryCleaning.jpg"),
    globalCategory: "Home",
  },
];

export default class ServicesDetailScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    return {
      tabBarVisible: false,
      headerTransparent: true,
      title: "Home & Grooming",
      headerTitleStyle: {
        fontWeight: "bold",
        color: "#333333",
        shadowOpacity: 0.1,
      },
    };
  };
  constructor(props) {
    super(props);
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => {
      return;
    };
  }
  state = {
    assetsLoaded: false,
    category1: [],
    category2: null,
    showMore: false,
  };

  async componentDidMount() {
    console.log("global", global.categories["Home"]);
    await Font.loadAsync({
      "ProximaNovaA-Regular": require("../../../../assets/font/ProximaNovaA-Regular.ttf"),
      "ProximaNovaA-Bold": require("../../../../assets/font/ProximaNovaA-Bold.ttf"),
    });
    this.setState({ assetsLoaded: true });

    try {
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 200);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 400);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 600);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 1000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 2000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 4000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 5000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 8000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 10000);
        });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 10000);
        });
      if (!global.myUID || !global.myProfileID)
        await new Promise((resolve) => {
          setTimeout(resolve, 100000);
        });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID) return;
    } catch (err) {
      if (global.reportError) {
        global.reportError(err, "catches", "homeServices");
      }
    }
  }
  toggleCategory = () => {
    this.setState({
      showMore: !this.state.showMore,
    });
    console.log("toggle button handler: " + this.state.showCategory);
  };

  componentWillUnmount() {
    console.log("Component ServicesDetailScreen will unmount");
    this.unsubscribeFirestore();
  }

  render() {
    let category1Array = this.state.category1;
    // console.log("new category state", category1Array);
    let newCategory1 = [];
    let restCategory = [];
    if (category1Array.length > 12) {
      if (!this.state.showMore) {
        newCategory1 = category1Array.slice(0, 12);
      } else {
        newCategory1 = category1Array;
      }
    } else {
      newCategory1 = category1Array;
    }

    // console.log("new category rest  state", restCategory);
    return (
      <ScrollView
        onScroll={(e) => {
          try {
            // if (e.nativeEvent.contentOffset.y >= 55)
            //   this.props.navigation.setParams({ hideTitle: true });
            // else this.props.navigation.setParams({ hideTitle: false });
          } catch (e) {
            console.warn("caught setParam/onScroll error");
            console.log("onScroll error", e);
          }
        }}
        scrollEventThrottle={1000}
      >
        <LinearGradient
          colors={[
            "#935FF0",
            "#935FF0",
            "#F5F5F5",
            "#F5F5F5",
            "#F5F5F5",
            "#F5F5F5",
            "#F5F5F5",
            "#F5F5F5",
            "#F5F5F5",
            "#F5F5F5",
          ]}
          style={{
            flex: 1,
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            height: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
        <StatusBar barStyle="light-content" />
        <View
          style={{
            backgroundColor: "F5F5F5",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              backgroundColor: "F5F5F5",
              borderBottomColor: "#555",
              alignItems: "center",
              justifyContent: "center",

              borderBottomWidth: 1,
              ...Platform.select({
                ios: {
                  height: Dimensions.get("window").height >= 800 ? 88 : 65,
                },
                android: { height: 85 },
              }),
            }}
          />

          <View
            style={{
              width: 340,
              justifyContent: "center",
              marginHorizontal: 10,
              // marginTop: 10
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginHorizontal: 8,
                paddingHorizontal: 8,
                flexWrap: "wrap",
                // bottom: 50,
                alignItems: "center",
              }}
            >
              {renderData.map((item) => {
                return (
                  <TouchableOpacity
                    style={{
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: 25,
                    }}
                    key={item.title}
                    onPress={() => {
                      this.props.navigation.push("servicesPreview", {
                        params: {
                          category2: item.title,
                          image: "", //used to be item.image, but that would crash after other images are online URLs now
                          category1: item.globalCategory,
                        },
                      });
                    }}
                  >
                    <View style={{ paddingTop: 7, marginTop: 7 }}>
                      <Image
                        style={{
                          width: 140,
                          height: 100,
                          borderRadius: 12,
                          borderColor: "black",
                          borderWidth: 0.05,
                          shadowColor: "#000",
                          shadowOpacity: 0.3,
                        }}
                        source={item.image}
                        resizeMode="cover"
                      />
                    </View>

                    <View
                      style={{
                        width: 109,
                        alignItems: "center",
                        // backgroundColor: "black",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          fontFamily: "ProximaNovaA-Regular",
                          color: "#333333",
                          marginBottom: 12,
                          top: 10,
                          position: "absolute",
                        }}
                      >
                        {item.title}
                      </Text>
                    </View>
                  </TouchableOpacity>
                );
              })}
            </View>
          </View>
        </View>
      </ScrollView>
    );
  }
}

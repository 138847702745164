
import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  TextInput,
  Linking
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import AsyncStorage from '@react-native-async-storage/async-storage';
import DateTimePicker from '@react-native-community/datetimepicker';
import { WebView } from "react-native-webview";
import { NavigationEvents } from "react-navigation";
import Moment from "react-moment";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { SvgCss } from "react-native-svg";
const gStyles = global.gStyles //    "../../styles/gStyles";


export default class HomeSellersLoanScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Your Loan",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };
  state = {
    screen: "SELECT", // can be "FORM_PH", "WEBVIEW_PH", "SUBMITTED_PH" 
    hasBusiness: true,
    hasWebsite: false,
    websiteName: "",
    firstName: "",
    lastName: "",
    email: "",
    aa: false,
    availabilityStatus: "checking", // can be "checking", "ok" or a message to display
    profileLoaded: false,
    showDatePicker: Platform.OS == "ios",
    birthDate: new Date()
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
    this.unsubscribeFirestore2 = () => { return; };
  }

  async componentDidMount() {
    try {
      const roundedDate = new Date()
      this.timezoneOffset = roundedDate.getTimezoneOffset()

      if (!global.myUID || !global.myProfileID || !global.globalsSet) await global.timeout(3500)
      if (!global.myUID || !global.myProfileID || !global.globalsSet) this.props.navigation.goBack();

      let firstName = ""
      let lastName = ""
      if (global.myName) {
        const nameArr = global.myName.split(" ")
        firstName = nameArr[0]
        nameArr.forEach((n, i) => {
          if (i !== 0) {
            if (i === 1) lastName = lastName + n
            else lastName = lastName + (" " + n)
          }
        })
      }
      this.setState({
        hasBusiness: global.myBusinessName ? true : false,
        hasWebsite: global.hasNoWebsiteYet ? false : true,
        firstName,
        lastName
      })

      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Profiles")
        .doc(global.myProfileID)
        .onSnapshot(this.updateProfile)
      this.unsubscribeFirestore2 = firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .onSnapshot(this.updateUser)

    } catch (err) {
      if (global.reportError) {
        global.reportError(err, "catches", "homeSellers");
      }
    }
  }

  updateProfile = async (pDoc) => {
    try {
      if (!pDoc) return;
      const p = pDoc.data()
      if (p.profileNameBusiness) this.setState({ hasBusiness: true })
      else this.setState({ hasBusiness: false })
      this.setState({
        websiteName: p.profileWebsiteURL || "",
        trustScore: p.trustScore || -999,
        trustScoreBadge: p.trustScoreBadge || "Unknown",
        trustScoreDetails: p.trustScoreDetails || [],
        phoneNumber: p.profilePhoneNumber || 0,
      })

      const storedJSON = await AsyncStorage.getItem("loanFormLast");
      if (storedJSON) {
        let l = JSON.parse(storedJSON);
        this.setState({
          address: l.address,
          city: l.city,
          state: l.state,
          zip: l.zipCode,
          birthDate: new Date(l.birthDate * 1000),
          gender: l.gender?.toLowerCase?.(),
          bType: l.businessType,
          bvn: l.bvn,
          email: l.email
        })
        console.log("bdbdbdbd", l.birthDate)
      }
      this.setState({ profileLoaded: true })
      this.profilePhoneNumber = p.profilePhoneNumber

    } catch (err) {
      console.warn("error in homeSellersLoan2")
      console.log(err)
      if (global.reportError) {
        global.reportError(err, "catches", "homeSellersLoan2");
      }
    }
  }

  updateUser = async (uDoc) => {
    // try {
    //   if (!uDoc) return;
    //   const u = uDoc.data()

    //   if (u.idShop && !this.shopLoaded) {
    //     const shopDoc = await firebase
    //       .firestore()
    //       .collection("Shops")
    //       .doc(u.idShop)
    //       .get()
    //     if (shopDoc) {
    //       this.shop = shopDoc.data()
    //       this.shopLoaded = true
    //     }
    //   }

    //   if (u.websiteJustPurchased || u.websiteTrialJustSelected > 0 || u.idShop) this.setState({ hasWebsite: true })
    //   else this.setState({ hasWebsite: false })
    //   if (u.idShop && this.shop && this.shop.renewalDate) {
    //     const nowDate = new Date()
    //     const renewalDate = this.shop.renewalDate.toDate()
    //     if (nowDate > renewalDate) this.setState({ websiteExpired: true })
    //     else this.setState({ websiteExpired: false })
    //   } else this.setState({ websiteExpired: false })

    //   this.setState({ loadingUser: false })
    // } catch (err) {
    //   console.warn("error in homeSellers3")
    //   console.log(err)
    //   if (global.reportError) {
    //     global.reportError(err, "catches", "homeSellers3");
    //   }
    // }
  }

  componentWillUnmount() {
    console.log("Component homeSellersLoan will unmount");
    this.unsubscribeFirestore();
    this.unsubscribeFirestore2();
    this.calculateLoanData(true)
  }

  calculateLoanData = async (doStore) => {
    try {
      const l = {}
      l.address = this.state.address
      l.city = this.state.city
      l.country = 'nigeria'
      l.state = this.state.state
      l.zipCode = this.state.zip
      l.birthDate = (Date.parse(this.state.birthDate) / 1000)//ms
      l.firstName = this.state.firstName
      l.lastName = this.state.lastName
      l.gender = this.state.gender?.toUpperCase?.()
      l.businessType = this.state.bType
      l.businessName = global.myBusinessName
      l.bvn = this.state.bvn
      l.cartAmount = this.state.amount
      l.customerActivationDate = global.myTimeCreated ? (Date.parse(global.myTimeCreated) / 1000) : 0
      l.customerCreatedDate = l.customerActivationDate
      l.email = this.state.email
      l.phoneNumber = this.state.phoneNumber?.substring?.(1, 20)

      console.log("BDBDBDBD", l.birthDate)

      if (doStore) await AsyncStorage.setItem("loanFormLast", JSON.stringify(l));
      return l
    } catch (e) { global.warn("e", "calculateLoanData"); return false }
  }

  checkAvailability = async () => {
    try {
      this.setState({ availabilityStatus: "checking" })

      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "loan", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "checkAvailability",
          loan: "PH_002",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        if (r2.max) this.setState({ max: r2.max })
        if (r2.userMessage)
          this.setState({ availabilityStatus: userMessage })
        else this.setState({ availabilityStatus: "ok" })
      } else this.setState({ availabilityStatus: "An error occurred. Try again." })
    } catch (e) {
      this.setState({ availabilityStatus: "Could not check availability" })
      global.warn("e", "checkLoanAvailability"); return false
    }
  }

  showWebview = async () => {
    try {
      const l = await this.calculateLoanData(true)

      if (!this.state.email || !this.state.email.includes("@") || !this.state.email.includes(".")) return alert("Please enter your e-mail address")
      if (!this.state.amount) return alert("Enter the amount you want to request")
      if (Number(this.state.amount) < 200) return alert("Enter a valid amount")
      if (!this.state.firstName) return alert("Please enter your first name")
      if (!this.state.lastName) return alert("Please enter your last name")
      if (this.state.gender !== "male" && this.state.gender !== "female") return alert("Please select a gender")
      if (!this.state.address || this.state.address.length < 5) return alert("Please enter your address")
      if (!this.state.zip || this.state.zip.length < 4) return alert("Please enter your zip code")
      if (!this.state.city) return alert("Please enter your city")
      if (!this.state.state) return alert("Please enter your state")
      if (!this.state.bvn) return alert("Please enter your BVN")
      if (this.state.bvn.length != 11) return alert("BVN needs to be an 11-digit number")
      if (isNaN(this.state.bvn)) return alert("BVN must be a number")
      if (!this.state.bType) return alert("Please select a business type")
      const nowDate = new Date()
      const date16 = global.addDays(nowDate, -5840)//16 years ago
      if (!this.state.birthDate || Date.parse(this.state.birthDate) > Date.parse(global.addDays(new Date(), -3650))) return alert("Please select a valid birth date")

      const lKeys = Object.keys(l)
      console.log(lKeys)
      let url = "https://wakandaloans.web.app/?"
      lKeys.forEach(key => {
        const value = l[key].toString().replaceAll("/", " ").replaceAll("&", " ").replaceAll("?", " ")
        url = url + key + "=" + value + "&&"
      })
      //url = url.slice(0, -2)
      console.log(url)

      this.setState({ aa: true })
      await global.timeout(3000)
      const loan = "PH_002"

      const r = await fetch(global.cloudFunctionURL + "loan", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "applyForInstant",
          loan,
          application: l,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ aa: false })
      if (r2.msg == "SUCCESS" && r2.token) {
        //global.reportStats("pressed_Loan_Submit", loan);
        url = url + "totalCount=" + (r2.application.totalCount || 0)
        url = url + "&&totalAmount=" + (r2.application.totalAmount || 0)
        url = url + "&&token=" + r2.token
        url = url + "&&applicationID=" + r2.applicationID
        this.url = url
        console.log(url)
        this.setState({ screen: "WEBVIEW_PH" })
      } else {
        const msg = r2.userMessage || "An error occurred"
        alert(msg)
      }
    } catch (e) {
      alert("System error. Check network connection and try again")
      global.warn(e, 'LoanSubmitFormError');
      this.setState({ aa: false })
    }
  }

  submit = async (loan) => {
    if (!this.state.email || !this.state.email.includes("@") || !this.state.email.includes(".")) return alert("Please enter your e-mail address")
    if (!this.state.firstName) return alert("Please enter your first name")
    if (!this.state.lastName) return alert("Please enter your last name")
    try {
      this.setState({ aa: true })
      await global.timeout(3000)

      const r = await fetch(global.cloudFunctionURL + "loan", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "apply",
          email: this.state.email,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          loan,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ aa: false })
      if (r2.msg == "SUCCESS") {
        global.reportStats("pressed_Loan_Submit", loan);
        this.setState({ screen: "SUBMITTED_PH" })
      } else {
        const msg = r2.userMessage || "An error occurred"
        alert(msg)
      }
    } catch (e) {
      alert("System error. Check network connection and try again")
      global.warn(e, 'LoanSubmitFormError');
      this.setState({ aa: false })
    }
  }

  bTypes = ['BAKERY', 'COMPUTER_SALE', 'PETROL_DIESEL_SUPPLY', 'E_COMMERCE', 'ELECTRIC_APPLIANCE_SALES', 'FARM_AGRICULTURE', 'FAST_MOVING_CONSUMER_GOODS', 'GROCERY_SUPPLY', 'LUXURY_GOODS', 'MOBILE_MONEY', 'OIL_GAS', 'ONLINE_BILLING_PLATFORM', 'PHARMACY', 'PHONE_SALES_SMS', 'SUPER_MARKET', 'TELECOMMUNICATIONS', 'TEXTILE_DESIGNS', 'TRAVEL_AGENCY', 'OTHER']


  render() {
    if (!this.state.profileLoaded) return (
      <View style={{ height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: "#282828" }}>
        <View style={{ opacity: 0.5, marginBottom: 30 }}><SvgCss height="120" width="120" xml={global.shopIcon} /></View>
        <ActivityIndicator size="large" color="#bbb" />
      </View>
    );
    if (this.state.screen == "SELECT") return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#282828" }}>
        <StatusBar barStyle="light-content" />

        <View
          style={{ backgroundColor: "#282828", flex: 1 }}
        >
          <View
            style={{
              backgroundColor: "#282828",
              borderBottomColor: "#555",
              borderBottomWidth: 1,
              ...Platform.select({ ios: { height: 55 }, android: { height: 75 } })
            }}
          />
          <ScrollView>
            {/* <View style={{ position: "absolute", top: 0, left: 20, opacity: 0.05 }}>
                <SvgCss height="800" width="800" xml={global.shopIcon} />
              </View> */}
            {/* <Text style={{ color: "#fff", fontSize: 14, marginLeft: 10, fontStyle: "italic" }}>Offering Goods or Services on Wakanda is easy:</Text>*/}

            <View style={{ height: 5 }} />
            <Text style={{ marginTop: 30, color: "#fff", fontSize: 14, marginLeft: 20, opacity: 0.7 }}>You qualify for the following loan:</Text>
            <View style={{ height: 5 }} />
            {/* <Text style={{ color: "#fff", fontSize: 14 }}>Here is how we help your business thrive:</Text> */}
            <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20 }}>
              <Image source={{ uri: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FPH.png?alt=media&token=2ce08a61-2aa7-4915-a31a-ff49b7df1f1d" }}
                style={{ height: 35, width: 165 }} />
              {/* <Text style={{ color: "#fff", fontSize: 18, fontWeight: "bold" }}>1.  Open a Shop on Wakanda Market </Text> */}
              <View style={{ padding: 10, paddingLeft: 20, paddingRight: 20 }}>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  6 % monthly interest and fees</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Instant availabllity</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Zero collateral: Only requires BVN and personal data</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  30 days tenor</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Provided by PayHippo Ltd.</BulletPoint>
              </View>
              {this.state.availabilityStatus == "ok" ? <TouchableOpacity
                onPress={() => {
                  global.reportStats("pressed_Loan_PH");
                  this.setState({ screen: "FORM_PH" })
                }}
              >
                <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Apply Now</Text>
                </View>
              </TouchableOpacity>
                : (this.state.availabilityStatus == "checking") ? <View>
                  <View style={[global.gStyles.buttonXL, { backgroundColor: "transparent", paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                    <Text style={{ fontSize: 16, fontWeight: "bold", color: "#888" }}>Checking ...</Text>
                  </View>
                </View>
                  : <View>
                    <View style={[global.gStyles.buttonXL, { backgroundColor: "transparent", paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                      <Text style={{ fontSize: 16, fontWeight: "bold", color: "#a44" }}>{this.state.availabilityStatus || "Error"}</Text>
                    </View>
                  </View>}
              {this.state.max && (this.state.max < 50000) && <Text style={{ fontSize: 12, color: "#888" }}>Maximum amount currently available: {this.state.max}</Text>}
            </View>

          </ScrollView>
        </View>
        <NavigationEvents
          onDidFocus={async payload => {
            this.checkAvailability()
          }}
        />
      </SafeAreaView>
    );
    if (this.state.screen == "FORM_PH") return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#282828" }}>
        <StatusBar barStyle="light-content" />

        <View
          style={{ backgroundColor: "#282828", flex: 1 }}
        >
          <View
            style={{
              backgroundColor: "#282828",
              borderBottomColor: "#555",
              borderBottomWidth: 1,
              ...Platform.select({ ios: { height: 55 }, android: { height: 75 } })
            }}
          />
          <KeyboardAwareScrollView
            enableOnAndroid={true}
            extraHeight={100}
            //enableAutomaticScroll={true}
            showsVerticalScrollIndicator={false}
            style={{ flex: 1 }}
          >
            {/* <View style={{ position: "absolute", top: 0, left: 20, opacity: 0.05 }}>
                <SvgCss height="800" width="800" xml={global.shopIcon} />
              </View> */}
            {/* <Text style={{ color: "#fff", fontSize: 14, marginLeft: 10, fontStyle: "italic" }}>Offering Goods or Services on Wakanda is easy:</Text>*/}

            <View style={{ height: 5 }} />
            <Text style={{ marginTop: 30, color: "#fff", fontSize: 14, marginLeft: 20, opacity: 0.7 }}>You qualify for the following loan:</Text>
            <View style={{ height: 5 }} />
            {/* <Text style={{ color: "#fff", fontSize: 14 }}>Here is how we help your business thrive:</Text> */}
            <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20 }}>
              <Image source={{ uri: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FPH.png?alt=media&token=2ce08a61-2aa7-4915-a31a-ff49b7df1f1d" }}
                style={{ height: 35, width: 165 }} />
              {/* <Text style={{ color: "#fff", fontSize: 18, fontWeight: "bold" }}>1.  Open a Shop on Wakanda Market </Text> */}
              <View style={{ padding: 10, paddingLeft: 20, paddingRight: 20 }}>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  6 % monthly interest and fees</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Instant availabllity</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Zero collateral: Only requires BVN and personal data</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  30 days tenor</BulletPoint>
                <BulletPoint style={{ fontSize: 14, marginBottom: 5, color: "#ccc" }}>
                  Provided by PayHippo Ltd.</BulletPoint>
              </View>
              <View style={{ height: 25 }} />
              <Text
                style={{ fontSize: 14, color: "#A8CF45", paddingBottom: 5, marginTop: 20, justifyContent: "flex-start" }}
              >
                Amount requested (NGN):
            </Text>
              <MyTextInput
                onChangeText={amount => { this.setState({ amount }); }}
                maxLength={7}
                keyboardType={"numeric"}
                value={this.state.amount}
              />
              <Text
                style={{ fontSize: 14, color: "#A8CF45", paddingBottom: 5, marginTop: 0, justifyContent: "flex-start" }}
              >
                Business Name:
            </Text>
              <Text
                style={{ fontSize: 14, color: "#bbc", paddingBottom: 10, marginTop: 0, justifyContent: "flex-start" }}
              >
                {global.myBusinessName}
              </Text>
              <Text
                style={{ fontSize: 14, color: "#A8CF45", paddingBottom: 5, marginTop: 0, justifyContent: "flex-start" }}
              >
                Phone Number:
            </Text>
              {!this.profilePhoneNumber?.startsWith?.("+234") ? <MyTextInput
                onChangeText={phoneNumber => { this.setState({ phoneNumber }); }}
                maxLength={8}
                placeholder="e.g. 123456"
                value={this.state.phoneNumber}
              />
                : <Text
                  style={{ fontSize: 14, color: "#bbc", paddingBottom: 10, marginTop: 0, justifyContent: "flex-start" }}
                >
                  {this.profilePhoneNumber}
                </Text>}
              <Text
                style={{ fontSize: 14, color: "#A8CF45", paddingBottom: 5, marginTop: 0, justifyContent: "flex-start" }}
              >
                First name:
            </Text>
              <MyTextInput
                onChangeText={firstName => { this.setState({ firstName }); }}
                maxLength={80}
                value={this.state.firstName}
              />
              <Text
                style={{ fontSize: 14, color: "#A8CF45", paddingBottom: 5, marginTop: 0, justifyContent: "flex-start" }}
              >
                Last name:
            </Text>
              <MyTextInput
                onChangeText={lastName => { this.setState({ lastName }); }}
                maxLength={80}
                value={this.state.lastName}
              />
              <Text
                style={{ fontSize: 14, color: "#A8CF45", paddingBottom: 5, marginTop: 0, justifyContent: "flex-start" }}
              >
                Gender:
            </Text>
              {(!this.state.gender || this.state.gender == "male") && <TouchableOpacity onPress={() => { this.setState({ gender: !this.state.gender ? "male" : "" }) }}>
                <CheckboxSelector selected={this.state.gender == "male"} text={"male"} />
              </TouchableOpacity>}
              {(!this.state.gender || this.state.gender == "female") && <TouchableOpacity onPress={() => { this.setState({ gender: !this.state.gender ? "female" : "" }) }}>
                <CheckboxSelector selected={this.state.gender == "female"} text={"female"} />
              </TouchableOpacity>}
              <View style={{ height: 4 }} />
              <Text
                style={{ fontSize: 14, color: "#A8CF45", paddingBottom: 0, marginTop: 0, justifyContent: "flex-start" }}
              >
                Birth Date:
            </Text>

              {this.state.showDatePicker ? <DateTimePicker
                testID="dateTimePicker"
                timeZoneOffsetInMinutes={-this.timezoneOffset}
                value={this.state.birthDate}
                mode={"date"}
                display="default"
                onChange={async (task, birthDate) => {
                  if (task.type == "dismissed") { // Android
                    this.setState({ showDatePicker: false })
                  } else {
                    if (task.type == "set") { // Android
                      await this.setState({ showDatePicker: false })
                    }
                    if (birthDate) this.setState({ birthDate })
                  }
                }} />
                : <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <TouchableOpacity onPress={() => { this.setState({ showDatePicker: true, datePickerType: "date" }) }}>
                    <View style={{
                      paddingHorizontal: 7, color: "#fff", borderRadius: 5, height: 35, borderColor: "#334", borderWidth: 2,
                      alignItems: "center", justifyContent: "center"
                    }}>
                      <Text style={{ color: "#ccd", fontSize: 20 }}><Moment format={"MMM Do YYYY"} element={Text}>
                        {this.state.birthDate}</Moment></Text>
                    </View>
                  </TouchableOpacity>
                  <View style={{ height: 11, width: 11 }} />
                </View>}

              <Text
                style={{ fontSize: 14, color: "#A8CF45", paddingBottom: 5, marginTop: 0, justifyContent: "flex-start" }}
              >
                E-mail address:
            </Text>
              <MyTextInput
                onChangeText={email => { this.setState({ email }); }}
                maxLength={80} placeholder={"e.g. user@gmail.com"}
                value={this.state.email}
              />
              <Text
                style={{ fontSize: 14, color: "#A8CF45", paddingBottom: 5, marginTop: 0, justifyContent: "flex-start" }}
              >
                Address:
            </Text>
              <MyTextInput
                onChangeText={address => { this.setState({ address }); }}
                maxLength={160} placeholder={"e.g. 12 Awolowo Rd"}
                value={this.state.address}
              />
              <Text
                style={{ fontSize: 14, color: "#A8CF45", paddingBottom: 5, marginTop: 0, justifyContent: "flex-start" }}
              >
                City:
            </Text>
              <MyTextInput
                onChangeText={city => { this.setState({ city }); }}
                maxLength={80}
                placeholder={"e.g. Lagos"}
                value={this.state.city}
              />
              <Text
                style={{ fontSize: 14, color: "#A8CF45", paddingBottom: 5, marginTop: 0, justifyContent: "flex-start" }}
              >
                State:
            </Text>
              <MyTextInput
                onChangeText={state => { this.setState({ state }); }}
                maxLength={80}
                placeholder={"e.g. Lagos"}
                value={this.state.state}
              />
              <Text
                style={{ fontSize: 14, color: "#A8CF45", paddingBottom: 5, marginTop: 0, justifyContent: "flex-start" }}
              >
                ZIP Code:
            </Text>
              <MyTextInput
                onChangeText={zip => { this.setState({ zip }); }}
                maxLength={8}
                placeholder="e.g. 123456"
                value={this.state.zip}
              />
              <Text
                style={{ fontSize: 14, color: "#A8CF45", paddingBottom: 5, marginTop: 0, justifyContent: "flex-start" }}
              >
                BVN:
            </Text>
              <MyTextInput
                onChangeText={bvn => { this.setState({ bvn }); }}
                maxLength={12}
                placeholder="e.g. 12345678901"
                value={this.state.bvn}
              />
              <Text
                style={{ fontSize: 14, color: "#A8CF45", paddingBottom: 5, marginTop: 0, justifyContent: "flex-start" }}
              >
                Business Type (select one):
            </Text>
              {this.bTypes.map(bType => {
                return ((!this.state.bType || this.state.bType == bType) && <TouchableOpacity onPress={() => { this.setState({ bType: !this.state.bType ? bType : "" }) }} key={bType + this.state.bType || ""}>
                  <CheckboxSelector selected={this.state.bType == bType} text={bType} />
                </TouchableOpacity>)
              })}


              <Text
                style={{ fontSize: 14, color: "#ccc", paddingBottom: 20, marginTop: 10, justifyContent: "flex-start" }}
              >
                PayHippo will receive above information and your Wakanda order history. In a final step, you will need to confirm your phone number.
            </Text>
              {!this.state.aa ? <TouchableOpacity
                onPress={() => { this.showWebview() }}
              >
                <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Apply Now</Text>
                </View>
              </TouchableOpacity>
                : <ActivityIndicator size="small" color="#ccc" style={{ alignSelf: "center", paddingVertical: 25 }} />}
            </View>
            <View style={{ height: 160 }} />
          </KeyboardAwareScrollView>
        </View >
      </SafeAreaView >
    );
    if (this.state.screen == "WEBVIEW_PH") return (<View style={{ backgroundColor: "#282828", height: "100%", width: "100%" }}>
      <View style={{ height: 100 }} />
      <WebView
        originWhitelist={['*']}
        style={{ width: global.SWIDTH, height: global.SHEIGHT - 200 }}
        source={{ uri: this.url || "" }}
        javaScriptEnabled={true}
        domStorageEnabled={true}
        allowsInlineMediaPlayback={true}
        allowsFullscreenVideo={true}
        allowUniversalAccessFromFileURLs={true}
        mediaPlaybackRequiresUserAction={false}
        useWebKit={true}
      />
    </View>)

    if (this.state.screen == "SUBMITTED_PH") return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#282828" }}>
        <StatusBar barStyle="light-content" />

        <View
          style={{ backgroundColor: "#282828", flex: 1 }}
        >
          <View
            style={{
              backgroundColor: "#282828",
              borderBottomColor: "#555",
              borderBottomWidth: 1,
              ...Platform.select({ ios: { height: 55 }, android: { height: 75 } })
            }}
          />
          <ScrollView>
            {/* <View style={{ position: "absolute", top: 0, left: 20, opacity: 0.05 }}>
                <SvgCss height="800" width="800" xml={global.shopIcon} />
              </View> */}
            {/* <Text style={{ color: "#fff", fontSize: 14, marginLeft: 10, fontStyle: "italic" }}>Offering Goods or Services on Wakanda is easy:</Text>*/}

            <View style={{ height: 50 }} />
            {/* <Text style={{ color: "#fff", fontSize: 14 }}>Here is how we help your business thrive:</Text> */}
            <View style={{ backgroundColor: "#000", margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20 }}>
              <Text style={{ color: "#ccc", fontSize: 20, textAlign: "center", marginBottom: 10 }}>✔ Success.</Text>
              <Text style={{ color: "#ccc", fontSize: 16, textAlign: "center" }}>Your request has been submitted,</Text>
              <Text style={{ color: "#ccc", fontSize: 16, textAlign: "center", marginBottom: 10 }}>PayHippo will contact you shortly at {this.state.email}.</Text>
            </View>
          </ScrollView>
        </View>
      </SafeAreaView >
    );
  }
}

class BulletPoint extends PureComponent {
  render() {
    return (
      <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
        <Text style={[this.props.style, { marginRight: 6 }]}>{`\u2022`}</Text>
        <Text style={this.props.style}>{this.props.children}</Text>
      </View>
    );
  }
}

class CheckboxSelector extends PureComponent {
  render() {
    let height =
      this.props.text.length < 45
        ? 30
        : 45 + (this.props.text.length - 45) * 0.33;
    let circle = filled => {
      if (filled) {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#bbc", backgroundColor: "#000", borderWidth: 0, borderRadius: 10 }}>
            <Text style={{ fontSize: 24, marginTop: -5, color: this.props.color || "#bbc" }}>✓</Text>
          </View>
        );
      } else {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#bbc", borderWidth: 0.7, borderRadius: 10 }} />
        );
      }
    };

    return (
      <View style={{ height: height, flexDirection: "row", alignItems: "center" }}>
        {circle(this.props.selected)}
        <Text style={{ marginLeft: 10, fontSize: 14, color: this.props.color || "#bbc", fontWeight: this.props.selected ? "bold" : "normal" }}>{this.props.text}</Text>
      </View>
    );
  }
}

class MyTextInput extends PureComponent {
  render() {
    return (
      <TextInput
        onChangeText={this.props.onChangeText}
        value={this.props.value}
        placeholder={this.props.placeholder || ""}
        placeholderTextColor={"#99a"}
        style={{ marginBottom: 10, color: "#ffffff", paddingLeft: 3, borderRadius: 5, height: 36, backgroundColor: "#334" }}




        //multiline
        maxLength={this.props.maxLength || 20000}
        underlineColorAndroid="transparent"
        keyboardShouldPersistTaps={"always"}
        keyboardDismissMode={"on-drag"}
        keyboardType={this.props.keyboardType}
        autoCompleteType={"off"}
        autoCapitalize={'none'}
        returnKeyType={"done"}
        autoCorrect={false}
        bordered
      //clearButtonMode={"always"}
      />
    );
  }
}
import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  Image,
  ImageBackground,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  Modal,
  Platform,
  ActivityIndicator,
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import firebase from "firebase";
import "firebase/firestore";
import { NavigationEvents } from "react-navigation";

const gStyles = global.gStyles //    "../styles/gStyles";
import CachedImage from "../shared/CachedImage"


export default class OfferCounter extends Component {

  state = {
    jobIDs: [],
    count: 0
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    try {
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 200); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 500); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 1000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 2000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 3000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 4000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 5000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 8000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 10000); });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();

      this.startListening()

    } catch (err) {
      //alert("Connection lost");
      global.warn(err, "Error initiating database connection (orderCounter)");
    }
  }

  startListening = async () => {
    try {
      this.unsubscribeFirestore = await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .collection("Refs")
        .doc("jobRefs")
        .onSnapshot(this.updateJobs);
    } catch (err) {
      //alert("Connection lost");
      global.warn(err, "ListenOrderCounter");
    }
  }

  componentWillUnmount() {
    console.log("Component orderCounter will unmount");
    this.unsubscribeFirestore();
  }

  updateJobs = async jobRefSnapshot => {
    let ids = jobRefSnapshot.data().jobIDs;

    let inactiveJobIDsJSON = await AsyncStorage.getItem("inactiveJobIDs");
    if (inactiveJobIDsJSON) {
      let inactiveJobIDs = JSON.parse(inactiveJobIDsJSON);
      if (Array.isArray(inactiveJobIDs)) {
        ids = ids.filter(id => { return (!inactiveJobIDs.includes(id)) })
      }
    }

    this.setState({ jobIDs: ids });
  };


  render() {
    return (<View
      style={{
        marginLeft: 8, height: 22, width: 32, borderRadius: 16, alignItems: "center", justifyContent: "center",
        backgroundColor: this.state.count > 0 ? "#900" : "transparent",
        borderWidth: this.state.count > 0 ? 0.5 : 0, borderColor: "#000",
      }}
    >
      {this.state.count > 0 && <Text style={{ color: "#fff", fontSize: 12 }}>{this.state.count}</Text>}

      {this.state.jobIDs.map(jobID => {
        return (
          <Job jobID={jobID}
            key={jobID}
            onReportCount={count => {
             // console.log("C" + count);
              this.setState({ count })
            }}
            navigation={this.props.navigation}
          />)
      })}
    </View>)
  }
}


class Job extends PureComponent {
  state = {
    hasUnreadJobs: false,
    needsAction: false,
    amCustomer: false,
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
    this.lastReorderRequest = null
    this.id = null;
    this.envelope = null
    this.wap = "";
    this.actionToPerform = "";
    global.Buffer = require('buffer').Buffer;
  }

  async componentDidMount() {
    global.jobNotifications = {};
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 200); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 500); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 1000); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 2000); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 3000); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 4000); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 5000); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 8000); });
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 10000); });
    if (!global.myUID) await global.setGlobals();

    this.unsubscribeFirestore = firebase
      .firestore()
      .collection("Jobs")
      .doc(this.props.jobID)
      .onSnapshot(this.updateJob);
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  updateJob = async jobSnapshot => {
    //console.log("Job update triggered.");
    try {
      let job = jobSnapshot.data();

      let amCustomer = true
      if (job.participantProfileIDCustomer !== global.myProfileID) {
        amCustomer = false
      }

      let nOfUnreadJobs = 0;
      if (job.systemStatusJob == "PENDING" && !amCustomer) { nOfUnreadJobs = 1 }
      if (job.systemStatusJob == "ONGOING") { nOfUnreadJobs = 1 }
      //if (job.systemStatusJob =="DECLINED") {nOfUnreadJobs = 1}
      //if (job.systemStatusJob =="CANCELLED BY CUSTOMER") {nOfUnreadJobs = 1}
      if (job.systemStatusJob == "COMPLETED_PENDING_REVIEW" && amCustomer) { nOfUnreadJobs = 1 }

      if (
        job.systemStatusJob == "COMPLETED" ||
        job.systemStatusJob == "DECLINED" ||
        job.systemStatusJob == "CANCELLED_BY_PROVIDER" ||
        job.systemStatusJob == "CANCELLED_BY_CUSTOMER" ||
        job.systemStatusJob == "CANCELLED_BEFORE_ACCEPT"
      ) {
        await global.timeout(50 + Math.round(Math.random() * 10000))
        let newInactiveJobIDs = [this.props.jobID]
        let inactiveJobIDsJSON = await AsyncStorage.getItem("inactiveJobIDs");
        if (inactiveJobIDsJSON) {
          const inactiveJobIDs = JSON.parse(inactiveJobIDsJSON);
          if (Array.isArray(inactiveJobIDs)) {
            inactiveJobIDs.push(this.props.jobID)
            newInactiveJobIDs = inactiveJobIDs
          }
        }
        await AsyncStorage.setItem(
          "inactiveJobIDs", JSON.stringify(newInactiveJobIDs));
        // console.log({ newInactiveJobIDs })
      }


      this.setState({ needsAction: nOfUnreadJobs });
      global.jobNotifications[this.props.jobID] = nOfUnreadJobs;
      let totalCount = 0;
      for (var el in global.jobNotifications) {
        if (global.jobNotifications.hasOwnProperty(el)) {
          totalCount += parseFloat(global.jobNotifications[el]);
        }
      }
      const parent = this.props.navigation.dangerouslyGetParent();
      parent.setParams({ notifications: totalCount });
      this.props.onReportCount(totalCount)
      global.pushBadgeJobs = totalCount || 0
      global.updatePushBadge()
    } catch (err) {
      global.warn(err, "E3546v")
      //alert("Error fetching job");
    }
  };




  render() {
    return (<View />)
  }
}
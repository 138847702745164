import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  SafeAreaView,
  Platform,
  Dimensions,
  Linking
} from "react-native";
import Constants from 'expo-constants';
import firebase from "firebase";
import "firebase/firestore";
const gStyles = global.gStyles //    "../../../styles/gStyles";

export default class MeNetworkAdminScreen extends Component {

  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Admin Panel",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45"
    }
  };

  state = {
    amAdmin: false,
    amApprover: false
  };

  componentDidMount = async () => {

    // //alert("A")
    // let largeRefArray = await firebase.firestore()
    //     .collection("Profiles")
    //     .where("profileServicesOffered","array-contains","Handyman" )
    //     .limit(10)
    //     .get();
    // console.log("A" + largeRefArray.size)
    // console.log("A" + largeRefArray.size)
    // console.log("A" + largeRefArray.size)
    // console.log("A" + largeRefArray.size)
    // console.log("A" + largeRefArray.size)
    // console.log("A" + largeRefArray.size)
    // console.log("A" + largeRefArray.size)
    // console.log("A" + largeRefArray.size)



    let userDoc = await firebase
      .firestore()
      .collection("Users")
      .doc(global.myUID)
      .get();
    this.setState({
      amApprover: userDoc.data().isApprover === true ? true : false,
      amAdmin: userDoc.data().isAdmin === true ? true : false
    });
  };



  render() {
    const xHeight = 45;

    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#131313" }}>
        <View
          style={{
            flex: 1,
            backgroundColor: "#131313",
            justifyContent: "space-between"
          }}
        >
          <View style={{ backgroundColor: "#131313" }}>
            <View
              style={{
                ...Platform.select({
                  ios: {
                    height: 42
                  },
                  android: {
                    height: 70
                  }
                }),
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: 8,
                borderBottomColor: "#444",
                borderBottomWidth: 1
              }}
            >
            </View>


            {/* {this.renderO()} */}

            <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => {
                this.props.navigation.navigate("networkApprovals", { networkID: this.props.navigation.getParam('networkID') });
              }}
            >
              <Text style={global.gStyles.meText}>Membership Approvals</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => {
                alert("Go to the member's profile and press Verify in the context menu")
                //this.props.navigation.navigate("networkVerifications", { networkID: this.props.navigation.getParam('networkID') });
              }}
            >
              <Text style={global.gStyles.meText}>Member Verifications of Qualification</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => {
                alert("Feature coming soon")
                // this.props.navigation.navigate("networkMessage", { networkID: this.props.navigation.getParam('networkID') });
              }}
            >
              <Text style={global.gStyles.meText}>Message To Members</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={global.gStyles.me}
              onPress={() => {
                this.props.navigation.navigate("networkDashboard", { networkID: this.props.navigation.getParam('networkID') });
              }}
            >
              <Text style={global.gStyles.meText}>
                Statistics Dashboard
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </SafeAreaView>
    );
  }
}

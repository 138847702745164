
import React, { Component } from "react";
import {
  Platform,
  Image,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ActivityIndicator,
  ScrollView,
  Dimensions,
  Keyboard,
} from "react-native";
import * as Location from 'expo-location';
import * as Permissions from 'expo-permissions';
import firebase from "firebase";
import "firebase/firestore";
const gStyles = global.gStyles //    "../../styles/gStyles";
import Post from "../Post/Post";
import distance from "../../shared/distances";
import Contact from "../../Me/Contacts/Contact"
import CategorySelector from "../CategorySelectorV2"


export default class Search extends Component {
  state = {
    term: "",
    categoryResults: [],
    profileResults: [],
    postResults: [],
    postResults2: [],
    showProfileResultsFirst: true,
    showAllProfiles: false,
    showDetail: false,
    detailPostResult: null,
    followerIDs: [],
    followingIDs: [],
    loadingContacts: true,
    loadingMoreProfiles: false,
    aa: false,
    aa2: false
  };

  moreToLoad = true
  moreProfilesToLoad = false

  constructor() {
    super();
    this.reverseCategoryMap = {}
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    //reverse map
    if (global.app == "artisan") {
      let obj = this.artisanCategoryMap
      var new_obj = {};
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
          new_obj[obj[prop]] = prop;
      }
      this.reverseCategoryMap = new_obj;
    }

    if (global.app == "education") {
      let obj = this.educationCategoryMap
      var new_obj = {};
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
          new_obj[obj[prop]] = prop;
      }
      this.reverseCategoryMap = new_obj;
    }

    try {
      let { status } = await Permissions.askAsync(Permissions.LOCATION);
      if (status == 'granted') {
        // this.getCurrentLocation1()
        // this.getCurrentLocation2()
      }
      if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 1000) });
      if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 2000) });
      if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 4000) });
      if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 8000) });
      this.unsubscribeFirestore = await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .collection("Refs")
        .doc("contactRefs")
        .onSnapshot(this.updateContacts);
    } catch (err) {
      console.log("Error initiating database connection (Search-CC)", err);
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
    console.log("Component Search will unmount");
  }

  updateContacts = async contactRefSnapshot => {
    let fingIDs = contactRefSnapshot.data().followingProfileIDs;
    let ferIDs = contactRefSnapshot.data().followerProfileIDs;
    if (fingIDs) {
      this.setState({ followingIDs: fingIDs, loadingContacts: false });
      global.myFollowingIDs = fingIDs
    }
    if (ferIDs) {
      this.setState({ followerIDs: ferIDs, loadingContacts: false });
      global.myFollowerIDs = ferIDs
    }
  };

  images = (global.app == "market") ? {
    Electronics: require("../../images/categories/Electronics.png"),
    Home: require("../../images/categories/Home.png"),
    Fashion: require("../../images/categories/Fashion.png"),
    Cars: require("../../images/categories/Cars.png"),
    Food: require("../../images/categories/Food.png"),
    Beauty: require("../../images/categories/Beauty.png"),
    Professionals: require("../../images/categories/Professionals.png"),
    Repair_Refill: require("../../images/categories/Repair_Refill.png"),
    Handyman: require("../../images/categories/Handyman.png"),
    QuickJobs: require("../../images/categories/QuickJobs.png"),
    Health: require("../../images/categories/Health.png"),
    Art: require("../../images/categories/Art.png"),
    Housing: require("../../images/categories/Housing.png"),
    Travel: require("../../images/categories/Travel.png"),
    Commercial: require("../../images/categories/Commercial.png")
  } : (global.app == "artisan") ? {
    AC: require("../../custom/Artisan/images/services/AC.png"),
    Appliances: require("../../custom/Artisan/images/services/Appliances.png"),
    Curtains: require("../../custom/Artisan/images/services/Curtains.png"),
    Doors: require("../../custom/Artisan/images/services/Doors.png"),
    Electrical: require("../../custom/Artisan/images/services/Electrical.png"),
    Flooring: require("../../custom/Artisan/images/services/Flooring.png"),
    Fumigation: require("../../custom/Artisan/images/services/Fumigation.png"),
    Furniture: require("../../custom/Artisan/images/services/Furniture.png"),
    Gardening: require("../../custom/Artisan/images/services/Gardening.png"),
    Generator: require("../../custom/Artisan/images/services/Generator.png"),
    Other: require("../../custom/Artisan/images/services/Other.png"),
    Painting: require("../../custom/Artisan/images/services/Painting.png"),
    Plumbing: require("../../custom/Artisan/images/services/Plumbing.png"),
    Walls: require("../../custom/Artisan/images/services/Walls.png"),
  } : {
    Primary: require("../../custom/Education/images/services/Primary.png"),
    Secondary: require("../../custom/Education/images/services/Secondary.png"),
    Tertiary: require("../../custom/Education/images/services/Tertiary.png"),
  };

  artisanCategoryMap = {
    AC: "AC",
    Electrical: "Electrician",
    Plumbing: "Plumbing",
    Painting: "Painting",
    Generator: "Generator",
    Appliances: "Appliances",
    Fumigation: "Fumigation",
    Curtains: "Curtains, Blinds",
    Doors: "Doors, Knobs, Locks",
    Flooring: "Flooring",
    Walls: "Walls & Ceiling",
    Furniture: "Furniture Assembly",
    Gardening: "Gardening",
    Other: "General Work",
  };
  educationCategoryMap = {
    Primary: "Teaching - Primary",
    Secondary: "Teaching - Secondary",
    Tertiary: "Teaching - Tertiary",
  };


  // getCurrentLocation1 = async () => {
  //   try {
  //     const myLocation = await Location.getCurrentPositionAsync({ accuracy: 3, maximumAge: 60000 });
  //     global.myLocation = myLocation
  //   } catch (err) {
  //     console.log("error c", err)
  //   }
  // }
  // getCurrentLocation2 = async () => {
  //   try {
  //     const myLocation = await Location.getCurrentPositionAsync({ accuracy: 5 });
  //     global.myLocation = myLocation
  //   } catch (err) {
  //     console.log("error c", err)
  //   }
  // }

  loadProfileResults = async (originalTerm, isImmediate) => {
    this.moreProfilesToLoad = false;
    const term = originalTerm.toLowerCase().replace(/[^a-z0-9]/g, "")
    if (term.length < 3) return;

    if (!isImmediate) { // only execute after user hasn't typed for 1 sec
      await global.timeout(1000)
      const now = new Date()
      if ((now - this.lastChanged) < 995) return;
    }

    try {
      const r = await fetch(global.cloudFunctionURL + "findContact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          phoneNumber: term,
          limit: 4,
          ...(global.myLocation && { coords: global.myLocation.coords }),
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      this.profileSearchTerm = term
      const r2 = await r.json();

      if (r2.msg === "CONTACTS_FOUND") {
        if (this.props.showResults) {
          this.moreProfilesToLoad = !r2.wasExhaustive
          this.setState({ profileResults: r2.contacts, showProfileResultsFirst: r2.closestDistance < 20, showAllProfiles: false });
        }
        return;
      } else {
        //if (r2.msg === "NO_CONTACT_FOUND") {
        this.moreProfilesToLoad = false
        this.setState({ profileResults: [], showAllProfiles: false });
        return;
      }

      // const length = Math.min(12, term.length)
      // const proCol = await firebase
      //   .firestore()
      //   .collection("Profiles")
      //   .where(`yNameBusiness.y${length}`, '==', term)
      //   .where("locationWorkHash",">=", myHash)
      //   .limit(4)
      //   .get();
      // console.log("SIZE", proCol.size);

      // const profileResults = [];
      // proCol.forEach(async proDoc => {
      //   const profile = proDoc.data()
      //   profile.xID = proDoc.id
      //   profileResults.push(profile)
      // });

      // if (profileResults.length > 0) {
      //   profileResults.sort((a, b) => {
      //     let aDist = 999
      //     let bDist = 999
      //     if (global.myLocation && a.locationWork) {
      //       const aLat = a.locationWork.latitude
      //       const aLon = a.locationWork.longitude
      //       aDist = Math.round(distance(aLat, aLon, global.myLocation.coords.latitude, global.myLocation.coords.longitude, "K"))
      //     }
      //     if (global.location && b.loc) {
      //       const bLat = b.locationWork.latitude
      //       const bLon = b.locationWork.longitude
      //       bDist = Math.round(distance(bLat, bLon, global.myLocation.coords.latitude, global.myLocation.coords.longitude, "K"))
      //     }
      //     if (aLat == 6.6) aDist += 50
      //     if (bLat == 6.6) bDist += 50
      //     return (b.z - bDist) - (a.z - aDist);
      //   });
      //     let dist = 999
      //     if (global.myLocation && profileResults[0].locationWork) {
      //       const lat = profileResults[0].locationWork.latitude
      //       const lon = profileResults[0].locationWork.longitude
      //       aDist = Math.round(distance(lat, lon, global.myLocation.coords.latitude, global.myLocation.coords.longitude, "K"))
      //     }
      //     if (dist < 20) this.setState({ profileResults, showProfileResultsFirst: true, showAllProfiles: false });
      //     else this.setState({ profileResults, showProfileResultsFirst: false, showAllProfiles: false });
      //   } else this.setState({ profileResults: [], showProfileResultsFirst: false, showAllProfiles: false });
      // }
    } catch (e2) {
      console.log("ERROR% ", e2);
    }
  }
  loadProfileResults2 = async () => {
    if (!this.profileSearchTerm) return;
    const term = this.profileSearchTerm.toLowerCase().replace(/[^a-z0-9]/g, "")
    if (term.length < 3) return;
    console.log("loadProfileResults2")
    this.setState({ showAllProfiles: true, loadingMoreProfiles: true })

    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "findContact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          phoneNumber: term,
          limit: 99,
          ...(global.myLocation && { coords: global.myLocation.coords }),
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      this.profileSearchTerm = term
      const r2 = await r.json();

      if (r2.msg === "CONTACTS_FOUND") {
        this.moreProfilesToLoad = false
        if (this.props.showResults) {
          if (!r2.wasExhaustive) {
            if (global.myLocation) alert("Too many profiles, specify search")
            else alert("Too many profiles. Turn on location or specify search.")
          }
          this.setState({ profileResults: r2.contacts, showAllProfiles: true, loadingMoreProfiles: false, });
        }
        return;
      }
      else {
        //if (r2.msg === "NO_CONTACT_FOUND") {
        this.moreProfilesToLoad = false
        this.setState({ profileResults: [], showAllProfiles: false, loadingMoreProfiles: false, });
        return;
      }
    } catch (e2) {
      console.log("ERROR%2 ", e2);
      this.setState({ loadingMoreProfiles: false })
    }
  }

  loadPostResults = async (originalTerm, isImmediate) => {
    const term = originalTerm.toLowerCase().replace(/[^a-z]/g, "")
    if (term.length < 4) return;

    if (!isImmediate) { // only execute server request after user hasn't typed for 1 sec
      await global.timeout(1000)
      const now = new Date()
      if ((now - this.lastChanged) < 995) return;
    }

    let filteredLocalResultList = []
    console.log('term', term)
    if (global.localResultsList) {
      filteredLocalResultList = global.localResultsList.filter((p) => (
        (p.cat1.toLowerCase().replace(/[^a-z]/g, "").includes(term)) ||
        (p.cat2.toLowerCase().replace(/[^a-z]/g, "").includes(term)) ||
        (p.t.toLowerCase().replace(/[^a-z]/g, "").includes(term))))
    }
    filteredLocalResultList.sort(function (a, b) {
      return b.z - a.z;
    });
    if (filteredLocalResultList.length > 30) {
      filteredLocalResultList.length = 30
    }
    this.setState({ postResults: filteredLocalResultList, postResults2: [] })

    if (!global.myProfileID || !global.myUID) return
    try {
      this.setState({ aa: true });
      const r = await fetch(global.cloudFunctionURL + "searchIndex", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          searchTerm: term,
          amount: 30,
          coords: global.location ? global.location.coords ? global.location.coords : null : null,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      this.setState({ aa: false });
      const r2 = await r.json();
      //console.log("r2", r2)
      this.moreToLoad = true;
      this.moreToLoadTerm = term
      if (r2.msg == "SUCCESS") {
        if (this.props.showResults) {
          if (r2.results.length < 30) this.moreToLoad = false
          const newList = filteredLocalResultList
          r2.results.forEach(r => {
            if (newList.filter(l => l.xID == r.xID).length == 0)
              newList.push(r)
          })
          newList.sort((a, b) => {
            let aDist = 999
            let bDist = 999
            if (global.location && a.loc)
              aDist = Math.round(distance(a.loc._latitude, a.loc._longitude, global.location.coords.latitude, global.location.coords.longitude, "K"))
            if (global.location && b.loc)
              bDist = Math.round(distance(b.loc._latitude, b.loc._longitude, global.location.coords.latitude, global.location.coords.longitude, "K"))
            if (a.loc._latitude == 6.6) aDist += 50
            if (b.loc._latitude == 6.6) bDist += 50
            return (b.z - bDist) - (a.z - aDist);
          });
          this.setState({ postResults: newList })
          const firstResults = newList.slice(0, 3)
          const SecondResults = newList.slice(3, 7)
          global.setRecommendationsAsync(firstResults, 0.2)
          global.setRecommendationsAsync(firstResults, 0.1)
        }
      } else {
        console.log("Error calling searchIndex", r2);
      }
    } catch (err) {
      console.log("ERROR:", err);
      this.setState({ aa: false });
    }
  }

  loadPostResults2 = async () => {
    console.log("CALLED loadPostResults2")
    const term = this.moreToLoadTerm
    if (!term || term.length < 4) return;
    console.log("EXECUTING loadPostResults2")

    let filteredLocalResultList = []
    if (global.localResultsList) {
      filteredLocalResultList = global.localResultsList.filter((p) => (
        (p.cat1.toLowerCase().replace(/[^a-z]/g, "").includes(term)) ||
        (p.cat2.toLowerCase().replace(/[^a-z]/g, "").includes(term)) ||
        (p.t.toLowerCase().replace(/[^a-z]/g, "").includes(term))))
    }
    filteredLocalResultList.sort(function (a, b) {
      return b.z - a.z;
    });
    let offsetLocalResultList = filteredLocalResultList.slice(30, 530)

    this.setState({ postResults2: offsetLocalResultList })

    if (!global.myProfileID || !global.myUID) return
    try {
      this.setState({ aa2: true });
      const r = await fetch(global.cloudFunctionURL + "searchIndex", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          searchTerm: term,
          amount: 500,
          offset: 30,
          coords: global.location ? global.location.coords ? global.location.coords : null : null,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      this.setState({ aa2: false });
      const r2 = await r.json();
      //console.log("r2", r2)
      this.moreToLoad = true;
      if (r2.msg == "SUCCESS") {
        if (this.props.showResults) {
          if (r2.results.length < 500) this.moreToLoad = false
          const newList = offsetLocalResultList
          r2.results.forEach(r => {
            if (newList.filter(l => l.xID == r.xID).length == 0)
              newList.push(r)
          })
          newList.sort((a, b) => {
            let aDist = 999
            let bDist = 999
            if (global.location && a.loc)
              aDist = Math.round(distance(a.loc._latitude, a.loc._longitude, global.location.coords.latitude, global.location.coords.longitude, "K"))
            if (global.location && b.loc)
              bDist = Math.round(distance(b.loc._latitude, b.loc._longitude, global.location.coords.latitude, global.location.coords.longitude, "K"))
            if (a.loc._latitude == 6.6) aDist += 50
            if (b.loc._latitude == 6.6) bDist += 50
            return (b.z - bDist) - (a.z - aDist);
          });
          this.setState({ postResults2: newList })
        }
      } else {
        console.log("Error calling searchIndex2", r2);
      }
    } catch (err) {
      console.log("ERROR:", err);
      this.setState({ aa2: false });
    }
  }

  changeTerm = (originalTerm, isImmediate) => {
    let term = originalTerm.toLowerCase().replace(/[^a-zA-Z-]/g, "");
    if (term == "car") term = "cars"
    const termForState = originalTerm.replace(/[^a-zA-Z0-9-\s]/g, "")
    this.setState({ term: termForState })

    this.lastChanged = new Date()
    this.loadPostResults(originalTerm, isImmediate || false)
    this.loadProfileResults(originalTerm, isImmediate || false)

    if (term.length < 2) {
      this.setState({ categoryResults: [], postResults: [], postResults2: [], profileResults: [], showAllProfiles: false }) //this is NOT equal to clearState!
    } else {
      const tags = global.tagKeys.filter((tag) => tag.startsWith(term.trim().replace(" ", "-")))
      //console.log("TAGS ", tags)

      const categoryResults = []
      tags.forEach(tag => {
        if (global.tags[tag]) {
          global.tags[tag].forEach(tagResult => {
            if (categoryResults.filter(cRes => (cRes.cat1 == tagResult.cat1 && cRes.cat2 == tagResult.cat2)).length == 0)
              categoryResults.push(tagResult)
          })
        }
      })
      if (term == "art") categoryResults.sort((a, b) => { if (b.cat1.toLowerCase() == "art") return 1; return -1 })
      if (categoryResults.length > 11) categoryResults.length = 10
      //console.log("RESULTS ", categoryResults)
      this.setState({ categoryResults })
      this.myScrollView.scrollTo({ x: 0, y: 0, animated: false });


      //const localResults = global.localResults.filter(post => post.postTitle.toLowerCase().includes(localTerm))
      //console.log("LOCALRESULTS", localResults)
    }
  }

  hideSearchResults = () => { // e.g. when you press back
    this.props.setShowResults(false);
    Keyboard.dismiss();
    this.clearState();
  }
  clearState = () => { // e.g. when you press x
    this.setState({ term: "", categoryResults: [], postResults: [], postResults2: [], profileResults: [], showAllProfiles: false });
  }





  renderCategory = (cat1, cat2) => {
    console.log("REVERSE", this.reverseCategoryMap)
    let iWidth = 60;
    return (
      <TouchableOpacity
        onPress={async () => {
          this.hideSearchResults();
          this.props.navigation.navigate("meResults", { category1: cat1, category2: cat2 })
        }}
        key={"k" + cat1 + cat2}
        style={{ flexDirection: "row", height: iWidth + 20, backgroundColor: "#202033", borderRadius: 10, padding: 6, marginTop: 10, marginHorizontal: 10, alignItems: "center", alignSelf: "stretch", justifyContent: "space-between" }
        }
      >
        <View style={{ width: iWidth + 5, alignItems: "center", justifyContent: "center" }}>
          <Image
            style={{ width: iWidth, height: iWidth, marginRight: 8, borderRadius: iWidth / 3 }}
            source={this.images[cat1]}
          />
        </View>
        <View style={{ flex: 80 }}>
          <Text style={{ color: "#808088", fontSize: 10 }}>
            {cat1}
          </Text>
          <Text style={{ color: "#ccccd5", fontSize: 16, fontWeight: "600" }}>
            {cat2}
          </Text>
        </View>
        <View
          style={{ alignItems: "center", justifyContent: "center", width: 120, marginRight: 5 }}
        >
          <TouchableOpacity
            onPress={async () => {
              this.hideSearchResults();
              this.props.navigation.navigate("homeProviderResults", { category1: cat1, category2: cat2 })
            }}
            style={{
              backgroundColor: "#A8CF45", height: 24, width: 115, borderRadius: 16, alignItems: "center", justifyContent: "center", margin: 4
              // borderWidth: 0.5,
              // borderColor: "#000",
            }}
          >
            <Text style={{ color: "#000", fontSize: 10 }}>FIND PROVIDERS</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              this.hideSearchResults();
              this.props.navigation.navigate("homeSpecialRequest", { category1: cat1, category2: cat2 })
            }}
            style={{
              borderColor: "#ccccd5", borderWidth: 0.5, height: 24, width: 115, borderRadius: 16, alignItems: "center", justifyContent: "center", margin: 4
              // borderWidth: 0.5,
              // borderColor: "#000",
            }}
          >
            <Text style={{ color: "#ccccd5", fontSize: 10 }}>{global.a.specialRequest ? global.a.specialRequest.toUpperCase() : "SPECIAL REQUEST"}</Text>
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    )
  }

  renderPostResult = (result, isLast) => {
    let B = props => <Text style={{ fontWeight: "bold" }}>{props.children}</Text>; //just for reference (delete?)

    let pString = Math.round(result.p).toString();
    if (pString.length > 8) {
      pString = pString.slice(0, pString.length - 6) + "M";
    } else if (pString.length > 6) {
      pString = Number(pString.slice(0, pString.length - 5)) / 10;
      pString = pString.toString() + "M";
    } else if (pString.length > 4) {
      pString = pString.slice(0, pString.length - 3) + "K";
    }

    let pCurrency = result.pc || "NGN";
    let pCurrencyString = global.currencySymbols[pCurrency]

    let priceString = pCurrencyString + " " + pString;

    //localString is acnString
    let localString = ""
    // xx possibly remove NGN from last section of the following line  to show less 
    // xx possibly replace last section of the following line with global.xRates[post.postPriceCurrency||"NGN"] to show ACN for all (also old) NGN posts 
    if (global.walletType == "ACN" && !(result.pc == "ACN") && result.pt != "Free" && result.p > 0 && global.xRates[result.pc || "NGN"]) {
      const lPrice = Math.round(result.p / global.xRates[result.pc || "NGN"])
      const lCurrency = global.currencySymbols["ACN"]
      let lString = Math.round(lPrice).toLocaleString(0);
      localString = "≈ " + lCurrency + " " + lString
    }
    let beforeString = ""
    let afterString = ""
    if (result.pt && result.pt != "Fixed Price") {
      if (result.pt == "Starting From") {
        beforeString = "Starting From"
      } else {
        afterString = "(" + result.pt + ")";
      }
      if (result.pt == "Free") {
        priceString = "FREE";
        localString = ""
        afterString = ""
      }
      if (result.pt == "NONE") {
        priceString = "";
        localString = ""
        afterString = ""
      }
    }

    return (
      <TouchableOpacity
        onPress={() => {
          this.setState({ showDetail: true, detailPostResult: result })
        }}
        key={"P" + result.xID}
        style={{ flexDirection: "row", height: 100, paddingVertical: 2, borderBottomWidth: isLast ? 0 : 0.5, borderBottomColor: "#606072", alignItems: "center", alignSelf: "stretch", justifyContent: "space-between" }}>
        <View style={{ width: 85, marginRight: 8 }}>
          {!!result.url && (<View>
            <Image
              style={{
                width: 85,
                height: 85,
                paddingRight: 8,
                borderRadius: 8.5
              }}
              source={{
                uri: result.url
              }}
            />
            {!!result.authorURL && <Image
              style={{
                marginTop: -84,
                width: 30,
                height: 30,
                marginLeft: 1,
                marginBottom: 54,
                borderRadius: 15,
                borderWidth: 1,
                borderColor: "#ccd",
              }}
              source={{ uri: result.authorURL }}
            />}

          </View>
          )}
        </View>
        <View
          style={{
            flex: 1,
            height: 100,
            justifyContent: "space-between",
            paddingHorizontal: 8
          }}
        >
          <View style={{ marginTop: 8 }}>
            <Text numberOfLines={2} style={[{ fontSize: 13, letterSpacing: 0.36, color: "#fff", fontWeight: "500" }]}>
              {result.t}
            </Text>
          </View>
          <View style={{ marginBottom: 8 }}>
            {beforeString != "" && <Text style={[{ fontSize: 10, color: "#ccccd5" }]}>
              {beforeString}
            </Text>}
            <Text style={[{
              fontSize: 16,
              fontWeight: "bold",
              letterSpacing: 0.48,
              textAlign: "left",
              color: "#A8CF45",
              opacity: 0.8
            }]}>
              {priceString}
            </Text>
            {localString != "" && <Text style={[{
              fontSize: 12,
              fontWeight: "bold",
              letterSpacing: 0.48,
              textAlign: "left",
              color: "#A8CF45",
              opacity: 0.8
            }]}>
              {localString}
            </Text>}
            <Text style={[{ fontSize: 10, fontWeight: "500", color: "#ccccd5" }]}>
              {afterString}
            </Text>
          </View>
        </View>
        <View
          style={{ width: 80, alignItems: "flex-end", justifyContent: "center" }}
        >
          {!!global.location && result.loc && result.loc._latitude != 6.6 && (
            <View
              style={{ justifyContent: "flex-end", alignItems: "center", flexDirection: "row" }}
            >
              <Image
                style={{ width: 15, height: 16.5, marginRight: 4, tintColor: "#aaaab3" }}
                source={require("../../images/icons/Location.png")}
              />
              <Text style={{ fontSize: 10, color: "#aaaab3", fontWeight: "500" }}>
                {Math.round(distance(result.loc._latitude, result.loc._longitude, global.location.coords.latitude, global.location.coords.longitude, "K"
                ) * 1) / 1 + " km"}
              </Text>
            </View>)}
        </View>
      </TouchableOpacity>
    )
  }

  renderProfiles = () => {
    let results = []
    if (this.state.showAllProfiles) results = this.state.profileResults
    else results = this.state.profileResults.slice(0, 3)
    return (<View
      style={{ backgroundColor: "#20202f", borderRadius: 10, paddingVertical: 6, marginHorizontal: 10, marginTop: 10, alignItems: "center", alignSelf: "stretch", justifyContent: "center" }}>
      {results.map((result, i) => {
        return this.renderProfile(result, i == results.length - 1)
      })}
      {!this.state.showAllProfiles && !!this.moreProfilesToLoad && <TouchableOpacity
        onPress={() => { this.loadProfileResults2() }}
        style={{ borderColor: "#ccccd5", borderWidth: 0.5, height: 24, width: 115, borderRadius: 16, alignItems: "center", justifyContent: "center", margin: 4 }}
      >
        <Text style={{ color: "#ccccd5", fontSize: 10 }}>SEE ALL</Text>
      </TouchableOpacity>}
      {!!this.state.loadingMoreProfiles && <ActivityIndicator size="small" color="#909099" animating={true} />}
    </View>)
  }

  renderProfile = (profile, isLast) => {
    return (<View style={{ alignSelf: "stretch", borderBottomColor: "#333", borderBottomWidth: isLast ? 0 : 1 }} key={profile.xID}>
      <Contact
        profile={profile}
        following={this.state.followingIDs.includes(profile.xID)}
        followed={this.state.followerIDs.includes(profile.xID)}
        height={50}
        paddingHorizontal={8}
        noBackgroundColor={true}
        noBorder={true}
        showDistance={true}
        navigation={this.props.navigation}
        onPress={() => { this.props.navigation.navigate("homeProfile", { profileID: profile.xID }) }}
      /></View>)
  }





  render() {
    const sWidth = Dimensions.get("window").width
    const sHeight = Dimensions.get("window").height

    if (this.state.term.length > 0) console.log("TERM LENGTH", this.state.term, this.state.term.length)

    return (
      <View
        style={{
          //justifyContent: "center",
          alignItems: "center",
          width: "100%"
        }}
      >
        <View style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: 'center',
          borderRadius: 15,
          height: 30,
          backgroundColor: "#fff",
          marginTop: Platform.OS == "ios" ? 5 : 35,
          // marginTop: Platform.OS == "ios" ? 5 : this.props.showResults ? 97 : 35,
          width: "90%"
        }}>
          {!!this.props.showResults && <TouchableOpacity onPress={this.hideSearchResults} style={{ paddingHorizontal: 10 }}>
            <Text style={{ fontSize: 39, color: "#A8CF45", marginTop: -12 }}>‹</Text>
          </TouchableOpacity>}
          <TextInput
            onFocus={() => { this.props.setShowResults(true) }}
            onChangeText={this.changeTerm}
            placeholder={"What are you looking for?"}
            placeholderTextColor={"#aab"}
            style={{
              textAlign: 'center',
              color: "#001",
              height: 30,
              flex: 1,
            }}
            maxLength={45}
            ref={(input) => { this.myTextInput = input; }}
            onSubmitEditing={() => { Keyboard.dismiss() }}
            underlineColorAndroid="transparent"
            defaultValue={this.state.term}
            keyboardShouldPersistTaps={'always'}
            keyboardDismissMode={'on-drag'}
            keyboardAppearance={"dark"}
            returnKeyType={"done"}
            autoCompleteType={'off'}
            autoCapitalize={'none'}
            autoCorrect={false}
          //clearButtonMode={"always"}
          />
          {!!this.props.showResults && <TouchableOpacity onPress={async () => {
            this.clearState();
            this.myTextInput.focus();
            this.myScrollView.scrollTo({ x: 0, y: 0, animated: false });
          }} style={{ paddingHorizontal: 10 }}>
            <Text style={{ fontSize: 30, color: "#A8CF45", marginTop: -6 }}>×</Text>
          </TouchableOpacity>}
        </View>
        {!!this.props.showResults && <View>
          <View style={{ width: sWidth, height: 8 }} />
          <ScrollView
            keyboardShouldPersistTaps={'always'}
            keyboardDismissMode={'on-drag'}
            style={{ width: "100%", backgroundColor: "#000" }}
            ref={ref => (this.myScrollView = ref)}
            scrollEventThrottle={0}
            onScroll={(e) => {
              if (Platform.OS == 'android') Keyboard.dismiss()
              let paddingToBottom = 100;
              paddingToBottom += e.nativeEvent.layoutMeasurement.height;
              if (e.nativeEvent.contentOffset.y >= e.nativeEvent.contentSize.height - paddingToBottom) {
                if (this.moreToLoad) {
                  this.loadPostResults2()
                  this.moreToLoad = false
                }
              }
            }}
          >
            {this.state.term == "" && <View style={{}}>
              <CategorySelector
                noText={true}
                //text={"Select a Category"}
                backButton={false}
                //backgroundColor={"#000"}
                category1={this.state.category1}
                category2={this.state.category2}
                onSelectCategory={async (category1, category2) => {
                  this.changeTerm(category2, true)
                }}
              />
            </View>}
            {this.state.categoryResults.length > 0 && this.state.categoryResults.map(result => {
              return this.renderCategory(result.cat1, result.cat2)
            })}
            {/* <Text style={{ color: "pink" }}>{this.state.postResults ? this.state.postResults.length : "-"}</Text> */}
            {!!this.state.showProfileResultsFirst && this.state.profileResults.length > 0 &&
              this.renderProfiles()}
            {this.state.aa && <ActivityIndicator size="small" color="#909099" animating={true} />}
            {this.state.postResults.length > 0 &&
              <View
                style={{ backgroundColor: "#131318", borderRadius: 10, paddingHorizontal: 6, margin: 10, marginBottom: 0, alignItems: "center", alignSelf: "stretch" }}>
                {this.state.postResults.map((result, i) => {
                  return this.renderPostResult(result, i == this.state.postResults.length - 1)
                })}
              </View>}
            {!this.state.showProfileResultsFirst && this.state.profileResults.length > 0 &&
              this.renderProfiles()}
            {this.state.aa2 && <ActivityIndicator size="small" color="#909099" animating={true} />}
            {this.state.postResults2.length > 0 &&
              <View
                style={{ backgroundColor: "#131318", borderRadius: 10, paddingHorizontal: 6, margin: 10, alignItems: "center", alignSelf: "stretch" }}>
                {this.state.postResults2.map((result, i) => {
                  return this.renderPostResult(result, i == this.state.postResults2.length - 1)
                })}
              </View>}
            <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
              <View style={{ width: sWidth, height: (this.state.postResults.length + this.state.postResults2.length) > 2 ? sHeight - 500 : sHeight - 250 }} />
            </TouchableWithoutFeedback>
          </ScrollView>
          {
            this.state.showDetail && (
              <Post
                postID={this.state.detailPostResult.xID}
                postResult={this.state.detailPostResult}
                //data={this.props.data}
                key={this.state.detailPostResult.xID + this.state.showDetail}
                navigation={this.props.navigation}
                showDetail={this.state.showDetail}
                onHideDetail={() => {
                  this.setState({ showDetail: false });
                }}
                relevantStatus={"LIVE"}
                onIsNotRelevant={() => { alert("Offer not available at the moment") }}
              />
            )
          }
        </View>}
      </View>
    );
  }
}

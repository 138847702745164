import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  TextInput,
  SafeAreaView,
  SectionList,
  Keyboard,
  Platform,
  Modal,
  Alert,
  Dimensions,
  ActivityIndicator
} from "react-native";
import Moment from "react-moment";
import firebase from "firebase";
import "firebase/firestore";
const gStyles = global.gStyles //    "../../../../styles/gStyles";
import Contact from "../../../Contacts/Contact"
import { DoctorProfile } from "../../../../Home/Apps/Health/HealthRecordScreen"

class SearchBar extends Component {
  state = {
    searchTerm: "",
    countryCode: global.walletType == "ACN" ? "+233" : "+234",
    modalVisible: false
  };

  callSearch = () => {

    let submissionSearchTerm = "";
    const searchLetters = this.state.searchTerm.replace(/[^a-zA-Z]/g, "")

    if (searchLetters.length > 0) { //is a business name
      submissionSearchTerm = this.state.searchTerm

    } else { //is a number
      let enteredNumber = this.state.searchTerm.replace(/[^\d]/g, "");
      if (enteredNumber.startsWith("00")) {
        alert("Invalid phone number"); return;
      }
      if (enteredNumber.startsWith("0")) {
        enteredNumber = enteredNumber.slice(1);
      }
      submissionSearchTerm = this.state.countryCode + enteredNumber;
      //console.log("pn", submissionSearchTerm, this.state.countryCode, enteredNumber)
    }
    this.props.onPress(submissionSearchTerm);
  }

  render() {
    const countryCodes = global.countryCodes

    let searchLetters = this.state.searchTerm.replace(/[^a-zA-Z]/g, "")
    let showLeft = !(searchLetters.length > 0) //Shows +234 immediately
    //showLeft = !(searchLetters.length > 0 || this.state.searchTerm.length == 0) //Shows +234 after first number typed

    return (
      <View
        style={{
          flexDirection: "row",
          alignSelf: "center",
          height: 40,
          width: "90%",
          backgroundColor: "#aaa",
          borderRadius: 20,
          borderBottomWidth: 0,
        }}
      >
        {!!showLeft && <TouchableOpacity
          onPress={() => {
            this.setState({ modalVisible: true });
          }}
          style={{
            alignItems: "center",
            alignSelf: "flex-start",
            padding: 1,
            borderRightWidth: 1,
            borderRightColor: "gray",
            marginRight: 4.5,
            width: 65,
            height: 40,
            justifyContent: "center",
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            borderColor: "gray"
          }}
        >
          <Text>{this.state.countryCode}</Text>
        </TouchableOpacity>}
        <TextInput
          style={{
            alignSelf: "stretch",
            height: 40,
            marginBottom: 20,
            color: "#FFFFFF",
            backgroundColor: "#aaa",
            borderRadius: 33,
            borderBottomWidth: 0,
            color: "black",
            paddingLeft: 10,
            flex: 1
          }}
          placeholder="Search Phone Number / Business Name"
          placeholderTextColor="#000"
          //keyboardType="numeric"
          underlineColorAndroid="transparent"
          underlineColor={"transparent"}
          returnKeyType="search"
          onChangeText={searchTerm => this.setState({ searchTerm })}
          onSubmitEditing={this.callSearch}
          ref={component => (this.myTextInput = component)}
          // clearButtonMode="always" //xx how set colour?
          value={this.state.text}
          keyboardDismissMode={'on-drag'}
          keyboardAppearance={"dark"}
          autoCompleteType={'off'}
          autoCapitalize={'none'}
          autoCorrect={false}
        />

        {this.state.searchTerm.length > 4 && (
          <TouchableOpacity
            style={{
              alignItems: "center",
              alignSelf: "flex-start",
              padding: 1,
              backgroundColor: "#A8CF45",
              marginLeft: 4.5,
              width: 47,
              height: 40,
              justifyContent: "center",
              borderTopEndRadius: 20,
              borderBottomEndRadius: 20,
              borderColor: "gray",
              borderLeftWidth: 1
            }}
            onPress={this.callSearch}
          >
            <Image
              style={{
                width: 18,
                height: 18,
                marginRight: 1
              }}
              source={require("../../../../images/icons/Search.png")}
            />
          </TouchableOpacity>
        )}
        <Modal
          animationType="slide"
          transparent={false}
          visible={this.state.modalVisible}
          onRequestClose={() => {
            this.setState({ modalVisible: false });
          }}
        >
          <TouchableOpacity
            onPress={() => this.setState({ modalVisible: false })}
            style={{
              height: 50,
              backgroundColor: "#000"
            }}
          />
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#000"
            }}
          >
            <View
              style={{
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <View style={{ paddingTop: 20 }}>
                <Text style={{ fontSize: 30, color: "white" }}>
                  Select Country
                  </Text>
              </View>
              <View style={{ marginTop: 0, marginBottom: 5 }} />
              <SectionList
                renderItem={({ item, index, section }) => (
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({
                        modalVisible: false,
                        countryCode: item.split("(")[1].split(")")[0]
                      });
                    }}
                    style={{
                      flex: 1,
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 20,
                      width: Dimensions.get("window").width,
                      backgroundColor: "#112",
                      borderWidth: 1,
                      borderColor: "#223"
                    }}
                  >
                    <Text
                      style={{ fontSize: 16, color: "white" }}
                      key={index}
                    >
                      {item}
                    </Text>
                  </TouchableOpacity>
                )}
                renderSectionHeader={({ section: { title } }) => (
                  <Text style={{ fontWeight: "bold" }}>{title}</Text>
                )}
                sections={[
                  {
                    title: "",
                    data: countryCodes
                  }
                ]}
                keyExtractor={(item, index) => item + index}
              />
            </View>
          </View>
          <TouchableOpacity
            style={{
              position: "absolute",
              borderWidth: 0,
              backgroundColor: "#556",
              borderColor: "rgba(0,0,0,0.2)",
              alignItems: "center",
              justifyContent: "center",
              width: 20,
              top: 20,
              left: 20,
              height: 25,
              borderRadius: 30
            }}
            onPress={() => this.setState({ modalVisible: false })}
          >
            <Image
              style={{ width: 25, height: 25, opacity: 1 }}
              source={require("../../../../images/icons/CancelBlack.png")}
            />
            {/* <Text style={{ fontSize: 26, color: "#666" }}>x</Text> */}
          </TouchableOpacity>
        </Modal>
      </View>
    );
  }
}



export default class HealthAdminScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Health Admin",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
    }
  };

  state = {
    activityIndicatorAnimating: false,
    activityIndicatorAnimating2: false,
    resultID: 0,
    resultProfiles: [],
    resultType: null,
    noContactFound: false,
    showSearchBar: true,

    patientsWaiting: [],
    doctorsOnline: [], //healthProfileIDs
    activeSessions: [],
    waitingLoaded: false,
    roomStatus: "CLOSED",
    aaRoomStatus: false,

    manageLoaded: false,
    allDoctors: [], //objects {healthProfileID, profileID}

    feedback: {},
    submissions: [],
    feedbackLoaded: false,

    screen: "MAIN"
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    try {
      global.fetchIdToken()
      await firebase
        .firestore()
        .collection("Health")
        .doc("data")
        .collection("HealthRooms")
        .doc("+NGGeneral")
        .onSnapshot(this.updateRoom)
    } catch (error) {
      global.warn(error, "Error a92")
    }
  }

  updateRoom = async (roomDoc) => {
    try {
      if (!roomDoc) return
      const room = roomDoc.data()
      if (!room) return;

      this.setState({
        patientsWaiting: room.patientsWaiting, doctorsOnline: room.doctorsOnline,
        waitingLoaded: true, roomStatus: room.roomStatus
      })

      const activeSessions = []
      const keys = Object.keys(room.activeSessions)
      keys.forEach(key => {
        activeSessions.push(room.activeSessions[key].doctorProfileID || "")
      })
      this.setState({ activeSessions })
    } catch (error) {
      global.warn(error, "a93")
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  search = async term => {
    try {
      this.setState({ activityIndicatorAnimating: true });
      const r = await fetch(
        global.cloudFunctionURL + "findContact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            phoneNumber: term,
            myUID: global.myUID,
            idToken: global.idToken
          })
        }
      );
      const r2 = await r.json();
      Keyboard.dismiss();

      console.log("Received contact. ", r2.msg);
      this.setState({ activityIndicatorAnimating: false });
      if (r2.msg === "NO_CONTACT_FOUND") {
        this.setState({ noContactFound: true });
        return;
      }
      if (r2.msg === "CONTACT_FOUND") {
        this.setState({ resultID: 0, resultType: null, noContactFound: false });
        await global.timeout(50)
        this.setState({ resultID: r2.contactID, resultType: "singleContact", noContactFound: false });
        return;
      }
      if (r2.msg === "CONTACTS_FOUND") {
        this.setState({ resultProfiles: [], resultType: "multipleContacts", noContactFound: false });
        await global.timeout(50)
        this.setState({ resultProfiles: r2.contacts, resultType: "multipleContacts", noContactFound: false });
        return;
      }
      alert("Error: " + r2.msg);
    } catch (e2) {
      alert("An error occurred. Check internet connection.");
      console.log(e2);
      this.setState({ activityIndicatorAnimating: false });
    }
  };

  healthAdmin = async (action, profileID, healthProfileID) => {
    let msg = "You are granting this person the verified doctor rights on Wakanda"
    if (action == "removeVerifiedDoctor") msg = "You are removing this person's verified doctor rights on Wakanda"
    Alert.alert("Sure?", msg,
      [{ text: "Cancel", onPress: () => { } },
      { text: "Yes", onPress: () => { this.healthAdminExecute(action, profileID, healthProfileID); } }],
      { cancelable: true }
    );
  }

  healthAdminExecute = async (action, profileID, healthProfileID) => {
    try {
      const screen = this.state.screen
      this.setState({ screen: "LOADING" })
      const r = await fetch(global.cloudFunctionURL + "healthAdmin", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          action,
          profileID,
          healthProfileID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        alert("Success")
        this.loadManage()
      } else alert("A backend error occurred - Try again.")
      if (this.state.screen == "LOADING") this.setState({ screen })
    } catch (e) {
      if (this.state.screen == "LOADING") this.setState({ screen })
      alert("System error - Check network connection and try again?")
      global.warn(e, 'hA1');
    }
  }

  loadManage = async () => {
    try {
      this.setState({ manageLoaded: false, screen: "MANAGE" })
      const r = await fetch(global.cloudFunctionURL + "healthAdmin", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          action: "getVerifiedDoctors",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        this.setState({ manageLoaded: true, allDoctors: r2.resultObjects })
      } else {
        alert("A backend error occurred - Try again.")
      }
    } catch (e) {
      alert("System error - Check network connection and try again?")
      global.warn(e, 'hA2');
    }
  }

  loadFeedback = async () => {
    try {
      this.setState({ feedbackLoaded: false, screen: "FEEDBACK" })
      const r = await fetch(global.cloudFunctionURL + "healthAdmin", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          action: "getFeedback",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        this.setState({ feedbackLoaded: true, feedback: r2.feedback, submissions: r2.submissions })
      } else {
        alert("A backend error occurred - Try again.")
      }
    } catch (e) {
      alert("System error - Check network connection and try again?")
      global.warn(e, 'hA2');
    }
  }

  changeRoomStatus = async () => {
    try {
      this.setState({ aaRoomStatus: true })
      const r = await fetch(global.cloudFunctionURL + "startConsultation", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          healthRoomID: "+NGGeneral",
          action: "changeRoomStatus",
          roomStatus: this.state.roomStatus == "OPEN" ? "CLOSED" : "OPEN",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ aaRoomStatus: false })
      if (r2.msg == "SUCCESS") {
      } else {
        this.props.navigation.goBack()
        await global.timeout(100)
        alert("Error while changing room status")
      }
    } catch (e) {
      this.props.navigation.goBack()
      console.log(e)
      await global.timeout(100)
      alert("Error while changing room status.")
    }
  }


  renderMain = () => {
    if (!this.state.waitingLoaded) return this.renderLoading()

    return (<View style={{ backgroundColor: "#222", height: "100%", width: "100%" }}>
      <View
        style={{
          borderBottomColor: "#555555", borderBottomWidth: 0,
          ...Platform.select({ ios: { height: 70 }, android: { height: 100 } })
        }}
      />
      <ScrollView>
        <TouchableOpacity
          onPress={async () => { this.loadManage() }}
          style={{ backgroundColor: "#A8CF45", opacity: 0.5, borderRadius: 20, padding: 6, width: "95%", margin: 15, justifyContent: "center", alignSelf: "center", alignItems: "center" }}
        >
          <Text style={{ color: "#1C1B1B", fontWeight: "bold", fontSize: 14, }}>Manage Doctors</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={async () => { this.loadFeedback() }}
          style={{ backgroundColor: "#A8CF45", opacity: 0.5, borderRadius: 20, padding: 6, width: "95%", margin: 15, justifyContent: "center", alignSelf: "center", alignItems: "center", marginTop: 0, marginBottom: 25 }}
        >
          <Text style={{ color: "#1C1B1B", fontWeight: "bold", fontSize: 14, }}>See Feedback</Text>
        </TouchableOpacity>

        <View style={{ borderColor: "#566", height: 1, borderWidth: 1, marginBottom: 6 }} />
        <View style={{ alignItems: "center" }}><Text style={{ color: "white", fontSize: 20, marginTop: 3, fontWeight: "bold" }}>WAITING ROOM</Text></View>
        <TouchableOpacity onPress={() => { this.changeRoomStatus() }} style={{ paddingLeft: 10, paddingTop: 10, justifyContent: "center", }}>
          <Switch animating={this.state.aaRoomStatus} selected={this.state.roomStatus == "OPEN"} text={this.state.roomStatus == "OPEN" ? "Room open, accepting further patients          " : "Room closed for furhter patients          "} />
        </TouchableOpacity>

        {(Array.isArray(this.state.activeSessions) && this.state.activeSessions.length == 0) && <View style={{ height: 100, justifyContent: "center", padding: 5, flexDirection: "row", alignItems: "center" }} >
          <Text style={{ fontSize: 14, color: "#ccc" }}>   No active consultation</Text>
        </View>}
        {(Array.isArray(this.state.activeSessions) && this.state.activeSessions.length > 0) && <View><Text style={{ color: "white", fontSize: 16, marginTop: 25, fontWeight: "bold" }}>Active Sessions:</Text></View>}
        {(Array.isArray(this.state.activeSessions) && this.state.activeSessions.length > 0) && this.state.activeSessions.map(hpID => {
          return (<View key={hpID} style={{ padding: 4, margin: 4, alignSelf: "stretch", backgroundColor: "#ccc", borderRadius: 10, flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
              <DoctorProfile healthProfileID={hpID} navigation={this.props.navigation} />
            </View>
          </View>)
        })}
        {this.state.patientsWaiting.length == 0 && <View style={{ height: 100, justifyContent: "center", padding: 5, flexDirection: "row", alignItems: "center" }} >
          <Text style={{ fontSize: 36, color: "#8f8" }}>{"✓"}</Text>
          <Text style={{ fontSize: 18, color: "white" }}>No patient waiting</Text>
        </View>}
        {this.state.patientsWaiting.length > 0 && <View><Text style={{ color: "white", fontSize: 16, marginTop: 25, fontWeight: "bold" }}>Patient Queue:</Text></View>}
        {this.state.patientsWaiting.length > 0 && this.state.patientsWaiting.map(pw => { return (<PatientWaiting key={pw.healthFileID} patient={pw} navigation={this.props.navigation} />) })}

        {this.state.doctorsOnline.length > 0 && <View>
          <Text style={{ color: "white", fontSize: 16, marginTop: 25, marginBottom: 0, fontWeight: "bold" }}>Doctors Online:</Text>
          {this.state.doctorsOnline.map(doc => {
            return (<View key={doc.healthProfileID} style={{ padding: 4, margin: 4, alignSelf: "stretch", backgroundColor: "#ccc", borderRadius: 10 }}>
              <DoctorProfile healthProfileID={doc.healthProfileID} navigation={this.props.navigation} />
            </View>)
          })}
        </View>}
      </ScrollView>
    </View>)
  }

  renderManage = () => {
    if (!this.state.manageLoaded) return this.renderLoading()

    return (<View style={{ backgroundColor: "#222", height: "100%", width: "100%" }}>
      <View
        style={{
          borderBottomColor: "#555555", borderBottomWidth: 0,
          ...Platform.select({ ios: { height: 75 }, android: { height: 105 } })
        }}
      />
      <ScrollView>
        <TouchableOpacity
          onPress={async () => { this.setState({ screen: "ADD" }) }}
          style={{ borderRadius: 20, padding: 6, marginHorizontal: 4, justifyContent: "center", alignSelf: "flex-end", alignItems: "center", marginBottom: 5 }}
        >
          <Text style={{ color: "#fff", fontWeight: "bold", fontSize: 16, }}>+ ADD</Text>
        </TouchableOpacity>
        {(Array.isArray(this.state.allDoctors) && this.state.allDoctors.length == 0) && <View style={{ height: 100, justifyContent: "center", padding: 5, flexDirection: "row", alignItems: "center" }} >
          <Text style={{ fontSize: 14, color: "#ccc" }}>   No verified doctors</Text>
        </View>}
        {(Array.isArray(this.state.activeSessions) && this.state.activeSessions.length > 0) && <View><Text style={{ color: "white", fontSize: 16, fontWeight: "bold" }}>Verified Doctors:</Text></View>}
        {(Array.isArray(this.state.allDoctors) && this.state.allDoctors.length > 0) && this.state.allDoctors.map(ob => {
          return (<View key={ob.healthProfileID} style={{ padding: 4, margin: 4, alignSelf: "stretch", backgroundColor: "#ccc", borderRadius: 10 }}>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1 }}>
                <DoctorProfile healthProfileID={ob.healthProfileID} navigation={this.props.navigation} />
              </View>
              <View style={{ width: 35, alignItems: "center", justifyContent: "center" }}>
                <TouchableOpacity onPress={() => { this.healthAdmin("removeVerifiedDoctor", null, ob.healthProfileID) }}>
                  <View style={{ borderRadius: 10, padding: 4 }} >
                    <Text style={{ fontSize: 14, fontWeight: "bold", color: "#000" }}>X</Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
            <Contact
              following={[]}
              followed={[]}
              contactID={ob.profileID}
              navigation={this.props.navigation}
            />
          </View>)
        })}
        <View style={{ height: 200 }} />
      </ScrollView>
    </View>)
  }

  renderFeedback = () => {
    if (!this.state.feedbackLoaded) return this.renderLoading()

    const serviceRating = Math.round(this.state.feedback.allServiceRating * 100) / 100
    const docRating = Math.round(this.state.feedback.allDocRating * 100) / 100

    return (<View style={{ backgroundColor: "#222", height: "100%", width: "100%" }}>
      <View
        style={{
          borderBottomColor: "#555555", borderBottomWidth: 0,
          ...Platform.select({ ios: { height: 75 }, android: { height: 105 } })
        }}
      />
      <ScrollView>
        <Text style={{ color: "#fff", fontSize: 22, fontWeight: "bold", marginBottom: 10, marginTop: 30 }}>Your Feedback</Text>
        <Text style={{ color: "#fff", fontSize: 14, fontWeight: "bold" }}>Help us improve this service</Text>
        <View style={{ width: "100%", alignItems: "stretch" }}>
          <Text style={{ color: "red", fontSize: 16, marginTop: 30, fontWeight: "bold" }}>How was the consultation? (1-5)</Text>
          <Text style={{ color: "#fff", fontSize: 16, marginTop: 3, fontWeight: "bold" }}>{docRating} <Text style={{ color: "#fff", fontSize: 10 }}>(average of {this.state.feedback.allDocCount} submissions)</Text></Text>

          <Text style={{ color: "red", fontSize: 16, marginTop: 45, fontWeight: "bold" }}>How do you like this service? (1-5)</Text>
          <Text style={{ color: "#fff", fontSize: 16, marginTop: 3, fontWeight: "bold" }}>{serviceRating} <Text style={{ color: "#fff", fontSize: 10 }}>(average of {this.state.feedback.allServiceCount} submissions)</Text></Text>

          {(Array.isArray(this.state.submissions) && this.state.submissions.length == 0) && <View style={{ height: 100, justifyContent: "center", padding: 5, flexDirection: "row", alignItems: "center" }} >
            <Text style={{ fontSize: 14, color: "#ccc" }}>   No submissions yet</Text>
          </View>}
          {(Array.isArray(this.state.submissions) && this.state.submissions.length > 0) && <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
            <Text style={{ color: "white", fontSize: 16, marginTop: 25, fontWeight: "bold" }}>Recent Submissions:</Text>
            <Text style={{ color: "white", fontSize: 16, marginTop: 25, fontWeight: "bold" }}>😡😠😐🙂😍</Text></View>}
          {(Array.isArray(this.state.submissions) && this.state.submissions.length > 0) && this.state.submissions.map((sub, i) => {
            return (<View key={sub.profileID + i} style={{ padding: 4, margin: 4, alignSelf: "stretch", backgroundColor: "#aaa", borderRadius: 10 }}>
              <Submission submission={sub} navigation={this.props.navigation} />
            </View>)
          })}
        </View>
      </ScrollView>
    </View>)
  }

  renderLoading() {
    return (<View style={{ backgroundColor: "#222", height: "100%", width: "100%", alignItems: "center", justifyContent: "center" }}>
      <Image style={{ borderRadius: 17, height: 100, width: 200, marginBottom: -65 }}
        source={require("../../../../images/images/DD.jpeg")} />
      <ActivityIndicator size="large" color="white" animating={true} />
      <Text style={{ color: "#aaa", fontSize: 18, fontStyle: "italic", marginTop: 50 }}>One moment in time...</Text>
    </View>)
  }

  renderSingleContact = () => {
    return <ScrollView>
      {this.state.noContactFound && (
        <View
          style={{
            height: 300,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Text style={{ color: "white" }}>No result found.</Text>
        </View>
      )}
      {!this.state.noContactFound &&
        this.state.resultID != 0 && (
          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
              <Contact
                following={[]}
                followed={[]}
                contactID={this.state.resultID}
                navigation={this.props.navigation}
              />
            </View>
            <View style={{ width: 80, alignItems: "flex-end", justifyContent: "center", backgroundColor: "black" }}>
              <TouchableOpacity onPress={() => { this.healthAdmin("addVerifiedDoctor", this.state.resultID, null) }}>
                <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 14, fontWeight: "bold", color: "#000" }}>Add</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        )
      }
    </ScrollView>
  }

  renderMultipleContacts = () => {
    let title = this.state.showSearchBar ? "profiles found" : "phone contacts found on Wakanda"
    if (this.state.resultProfiles.length == 1) title = this.state.showSearchBar ? "profile found" : "phone contact found on Wakanda"
    return <ScrollView>
      {this.state.noContactFound && (
        <View
          style={{
            height: 300,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Text style={{ color: "white" }}>No result found.</Text>
        </View>
      )}
      {!this.state.noContactFound &&
        this.state.resultProfiles && (
          <View style={{ marginBottom: 10 }}>
            <Text style={{ color: "gray", fontSize: 18, marginLeft: 2, marginBottom: 6, alignSelf: "center" }}>{this.state.resultProfiles.length} {title}</Text>
            {this.state.resultProfiles.map((profile, i) => {
              return (<View style={{ flexDirection: "row" }} key={profile.xID}>
                <View style={{ flex: 1 }}>
                  <Contact
                    profile={profile}
                    following={[]}
                    followed={[]}
                    navigation={this.props.navigation}
                  />
                </View>
                <View style={{ width: 80, alignItems: "flex-end", justifyContent: "center", backgroundColor: "black" }}>
                  <TouchableOpacity onPress={() => { this.healthAdmin("addVerifiedDoctor", profile.xID, null) }}>
                    <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: "100%", alignSelf: "center", alignItems: "center" }]} >
                      <Text style={{ fontSize: 14, fontWeight: "bold", color: "#000" }}>Add</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>)


            })}
          </View>
        )}
    </ScrollView>
  }

  renderAdd() {
    if (this.state.resultID == 0 && this.state.noContactFound == false) {
      //??
    }

    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#222" }}>
        <View style={{ backgroundColor: "#222", flex: 1 }}>
          <View
            style={{
              borderBottomColor: "#555555",
              borderWidth: 0,
              ...Platform.select({
                ios: {
                  height: 55
                },
                android: {
                  height: 105
                }
              })
            }}
          />
          {!!this.state.showSearchBar && <SearchBar
            onPress={text => {
              this.search(text);
            }}
          />}
          <ActivityIndicator
            size="small"
            color="#FFFFFF"
            style={{
              opacity: this.state.activityIndicatorAnimating ? 1.0 : 0.0
            }}
            animating={true}
          />
          {this.state.resultType !== "multipleContacts" ? this.renderSingleContact() : this.renderMultipleContacts()}


        </View>
      </SafeAreaView>
    );
  }

  render() {
    if (this.state.screen == "MAIN") return this.renderMain()
    if (this.state.screen == "MANAGE") return this.renderManage()
    if (this.state.screen == "FEEDBACK") return this.renderFeedback()
    if (this.state.screen == "ADD") return this.renderAdd()
    if (this.state.screen == "LOADING") return this.renderLoading()
    return (<View />)
  }
}

class Switch extends PureComponent {
  render() {
    let height =
      this.props.text.length < 45
        ? 30
        : 45 + (this.props.text.length - 45) * 0.33;
    let circle = filled => {
      if (this.props.animating)
        return (
          <View style={{ width: 37, height: 20, borderRadius: 10 }}>
            <ActivityIndicator size="small" color="#faa" />
          </View>
        );
      if (filled) {
        return (
          <View style={{ width: 38.4, height: 21.4, borderRadius: 10.7, borderWidth: 0.7, backgroundColor: "#fff", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", padding: 2.5 }}>
            <View style={{ width: 15, height: 15, borderRadius: 7.5, backgroundColor: "#368", opacity: 0.5 }} />
          </View>
        );
      } else {
        return (
          <View style={{ width: 37, height: 20, borderRadius: 10, borderWidth: 0.7, borderColor: "#faa", flexDirection: "row", alignItems: "center", padding: 2 }}>
            <View style={{ width: 15, height: 15, backgroundColor: "#faa", borderRadius: 7.5 }} />
          </View>

        );
      }
    };

    return (
      <View style={{ height: height, flexDirection: "row", alignItems: "center" }}>
        {circle(this.props.selected)}
        <Text style={{ marginLeft: 10, fontSize: 14, color: this.props.selected ? "#fff" : "#faa" }}>{this.props.text}</Text>
      </View>
    );
  }
}

class PatientWaiting extends PureComponent {

  render() {
    const waitingSinceDate = (this.props.patient && global.stampToDate) && global.stampToDate(this.props.patient.waitingSince)

    return (<View style={{ padding: 4, margin: 4, alignSelf: "stretch", backgroundColor: "#aaa", borderRadius: 10 }}>
      <View style={{ alignSelf: "stretch", flexDirection: "row" }}>
        <View>
          <Text style={{ fontSize: 12, marginBottom: 3 }}> 🕑<Moment fromNow element={Text}>{waitingSinceDate}</Moment></Text>
        </View>
      </View>
    </View>);
  }
}

class Submission extends Component {
  state = { name: " ✧ ✧ ✧ ✧ ✧ ✧", pictureURL: null }
  async componentDidMount() {
    try {
      if (this.props.submission.profileID) {
        const profileDoc = await firebase
          .firestore()
          .collection("Profiles")
          .doc(this.props.submission.profileID)
          .get()
        const profile = profileDoc.data()
        this.setState({ pictureURL: profile.profilePictureURL, name: profile.profileName || "--" })
      }
    } catch (error) {
      global.warn(error, "ha3");
    }
  }
  render() {
    const sub = this.props.submission || {}
    const timeCreated = (global.stampToDate) && global.stampToDate(sub.timeCreated)
    const startedString = !sub.session.timePatientJoined ? "(Never joined consultation)" : ""

    return (<View style={{ minHeight: 100, padding: 4, margin: 4, alignSelf: "stretch", backgroundColor: "#ccc", borderRadius: 10 }}>
      <TouchableOpacity onPress={() => { this.props.navigation.navigate("meProfile", { profileID: sub.profileID }) }}>
        <View style={{ minHeight: 55, alignSelf: "stretch", flexDirection: "row" }}>
          {this.state.pictureURL ? <Image
            style={{ width: 35, height: 35, borderRadius: 17.5, margin: 5 }}
            source={{ uri: this.state.pictureURL }}
          /> : <View style={{ width: 35, height: 35, borderRadius: 17.5, margin: 5, backgroundColor: "gray" }} />}
          <View>
            <Text style={{ marginBottom: 2, fontWeight: "bold" }}>{this.state.name}</Text>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: 12, marginBottom: 3 }}>🕑 <Moment fromNow ago element={Text}>{timeCreated}</Moment>  {startedString}</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: 12, marginBottom: 3 }}>Liked Consultation? {sub.feedbackDoc}      Liked Service? {sub.feedbackService}</Text>
            </View>
            <Text style={{ fontSize: 10, fontStyle: "italic", marginRight: 44 }} numberOfLines={5}>{sub.feedbackText}</Text>
          </View>
        </View>
      </TouchableOpacity>
    </View >);
  }
}
import React, { Component } from "react";
import { View, Image, Text, Platform } from "react-native";
import * as FileSystem from "expo-file-system";

export default class CachedImage extends Component {
  state = {

  };
  async componentDidMount() {

  }

  render() {
    const { style } = this.props;

    return <Image
      style={style}
      source={this.props.source}
      resizeMode={this.props.resizeMode}
    />
  }
}


import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  Alert,
  Platform,
  ActivityIndicator
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";



class Contact extends Component {
  state = {
    contactProperties: {
      opponentProfileID: null,
      opponentName: "********",
      opponentNameBusiness: "************",
      opponentProfilePictureURL: null
    },
    showAction: false,
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  componentDidMount() {
    this.unsubscribeFirestore = firebase
      .firestore()
      .collection("Profiles")
      .doc(this.props.contactID)
      .onSnapshot(this.updateContact);
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  updateContact = async contactSnapshot => {
    console.log("Update of list of contacts triggered. (Contact in WebsitesAdmin)");
    try {
      let contact = contactSnapshot.data();

      let opponentProfileID = contactSnapshot.id;
      let opponentName = contact.profileName;
      let opponentProfilePictureURL = contact.profilePictureURL;
      let opponentNameBusiness = contact.profileNameBusiness;
      if (!contact.profileNameBusiness) {
        opponentNameBusiness = "";
      }

      let contactProperties = {
        opponentProfileID,
        opponentName,
        opponentNameBusiness,
        opponentProfilePictureURL
      };

      //console.log("contactProperties", contactProperties);
      this.setState({
        contactProperties
      });
    } catch (err) {
      console.log("Slow internet. Could not fetch contacts (meContact)", err);
      alert("Could not fetch contacts. Check your internet connection");
    }
  };

  setStatus = (status) => {
    Alert.alert("Change Status?", "You will change the status to " + status,
      [{ text: "No", onPress: () => { } },
      { text: "Yes", onPress: () => { this.executeSetStatus(status); } }],
      { cancelable: true }
    );
  }

  executeSetStatus = async (tldStatus) => {
    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "setWebsiteStatus", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          myUID: global.myUID,
          idToken: global.idToken,
          shopName: this.props.rData.xID,
          tldStatus: tldStatus,
        })
      });
      const r2 = await r.json();

      if (r2.msg === "SUCCESS") {
        alert("Success")
        this.props.update();
      } else {
        alert("Server Error");
      }
    } catch (err) {
      console.log(err);
      alert("General Error");
    }
  }

  renderStatusButton = (status) => {
    if (status == this.props.rData.tldStatus) return (<View />)
    return (<TouchableOpacity
      onPress={() => { this.setStatus(status) }}
      style={{ alignItems: "center", justifyContent: "center", height: 30, padding: 5, backgroundColor: "#026", marginHorizontal: 5, borderRadius: 5 }}>
      <Text style={{ color: "#eee", fontSize: 11 }}>{status}</Text>
    </TouchableOpacity>)
  }

  render() {
    const renewalDate = this.props.rData.renewalDate
    return (<View>
      <TouchableOpacity
        onPress={() => {
          if (!this.state.contactProperties.opponentProfileID) {
            return
          }
          let handoverID = this.state.contactProperties.opponentProfileID;
          this.props.navigation.navigate("meProfile", {
            profileID: handoverID
          });
        }}
      >
        <View
          style={[
            {
              flexDirection: "row",
              flex: 1,
              paddingVertical: 3,
              backgroundColor: "black",
              alignItems: "center",
              paddingLeft: 11,
              paddingRight: 11,
              shadowOpacity: 0.5,
              shadowOffset: { width: 0, height: 3 },
              borderBottomColor: "#333",
              borderBottomWidth: this.state.showAction ? 0 : 1
            }
          ]}
        >
          {!!this.state.contactProperties.opponentProfilePictureURL ? (
            <Image
              style={{ width: 42, height: 42, borderRadius: 4 }}
              source={{
                uri: this.state.contactProperties.opponentProfilePictureURL
              }}
            />
          ) : (
              <View style={{ width: 42, height: 42, borderRadius: 4, backgroundColor: "#444" }} />
            )}
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View
              style={{
                flexDirection: "column",
                flex: 1,
                paddingHorizontal: 8
              }}
            >
              <Text style={{ fontSize: 14, color: "white" }}>
                {this.state.contactProperties.opponentName}
              </Text>
              <Text style={{ fontSize: 12, color: "white", marginBottom: 4 }}>
                {this.state.contactProperties.opponentNameBusiness}
              </Text>
              {/* {this.state.contactProperties.opponentNameBusiness != "" && (
                <Text style={{ fontSize: 11, color: "white" }}>
                  {this.state.contactProperties.opponentNameBusiness}
                </Text>
              )} */}
              <Text style={{ fontSize: 12, color: "#008866", fontWeight: "bold" }}>
                Website Status: {this.props.rData.tldStatus}
              </Text>
              <Text style={{ fontSize: 12, color: "#008866", fontWeight: "bold" }}>
                Website: {this.props.rData.tld}
              </Text>
              <Text style={{ fontSize: 12, color: "#008866" }}>
                {this.props.rData.shopCategory == "education" ? "wakanda-education.com/" : "wakanda-market.com/"}{this.props.rData.xID}
              </Text>
              <Text style={{ fontSize: 12, color: "#008866" }}>
                expires: {renewalDate}
              </Text>
              <Text style={{ fontSize: 12, color: "#008866" }}>
                {this.props.rData.shopCategory == "education" ? "wakanda-education" : "wakanda-market"} status: {this.props.rData.status}
              </Text>

            </View>
          </View>
          <TouchableOpacity
            onPress={() => this.setState({ showAction: !this.state.showAction })}
            style={{ height: 50, width: 50, alignItems: "center", justifyContent: "center" }}>
            <Image style={{ width: 30, height: 30 }} source={require("../../../../images/icons/Change.png")} />
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
      {!!this.state.showAction && <View style={{ height: 60, backgroundColor: "black", borderBottomColor: "#333", borderBottomWidth: 1, justifyContent: "space-around", padding: 3 }}>
        <Text style={{ fontSize: 12, color: "#ccc" }}>
          Change status to...
              </Text>
        <View style={{ flexDirection: "row" }}>
          {this.renderStatusButton("OPEN")}
          {this.renderStatusButton("IN_REGISTRATION")}
          {this.renderStatusButton("LIVE")}
          {/* {this.renderStatusButton("CLOSED")} */}
        </View>
      </View>}
    </View>
    );
  }
}



export default class WebsitesAdminScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Websites Dashboard",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#008866",
      shadowOpacity: 1
    }
  };
  state = {
    data: [],
    loading: true,
    tldStatus: "OPEN"
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    this.getStats("OPEN")
  }

  getStats = async (tldStatus) => {
    try {
      this.setState({ loading: true })
      const r = await fetch(global.cloudFunctionURL + "getWebsiteStats", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          myUID: global.myUID,
          idToken: global.idToken,
          tldStatus: tldStatus
        })
      });
      const r2 = await r.json();
      console.log(r2)

      if (r2.msg === "SUCCESS" || r2.msg === "NO_WEBSITE_FOUND") {
        const data = r2.data
        data.sort(function (a, b) {
          return b.renewalDate - a.renewalDate
        });
        console.log("Success, result:", data)
        this.setState({ data: data, loading: false, tldStatus: tldStatus })
      } else {
        alert("Server Error");
      }
    } catch (err) {
      console.log(err);
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
    console.log("Component Contacts will unmount");
  }

  renderStatusButton = (status) => {
    return (<TouchableOpacity
      onPress={() => { this.getStats(status) }}
      style={{ alignItems: "center", justifyContent: "center", height: 30, padding: 5, backgroundColor: "#026", marginHorizontal: 5, borderRadius: 5, borderWidth: this.state.tldStatus == status ? 3 : 0.5, borderColor: this.state.tldStatus == status ? "#eee" : "#048" }}>
      <Text style={{ color: "#eee", fontSize: 11 }}>{status}</Text>
    </TouchableOpacity>)
  }

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#222" }}>
        <View style={{ backgroundColor: "#222", flex: 1 }}>
          <View style={{ ...Platform.select({ ios: { height: 42 }, android: { height: 70 } }), }} />
          <View style={{
            flexDirection: "row", height: 60, backgroundColor: "#222", borderBottomColor: "#333", borderBottomWidth: 1, justifyContent: "center", alignItems: "center", padding: 3
          }}>
            {this.renderStatusButton("OPEN")}
            {this.renderStatusButton("IN_REGISTRATION")}
            {this.renderStatusButton("LIVE")}
            {this.renderStatusButton("CLOSED")}
          </View>
          <ScrollView>
            <View>
              {this.state.data.length > 0 ? (
                this.state.data.map(r => {
                  return (
                    <Contact
                      key={r.xID}
                      contactID={r.profileID}
                      rData={r}
                      navigation={this.props.navigation}
                      update={() => {
                        this.getStats(this.state.tldStatus)
                      }}
                    />
                  );
                })
              ) : (
                  <View
                    style={{
                      height: 300,
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    {!!this.state.loading ? (
                      <ActivityIndicator
                        size="large"
                        color="#008866"
                        style={{
                          opacity: 1
                        }}
                        animating={true}
                      />
                    ) : (
                        <Text style={{ color: "#999" }}>No websites</Text>
                      )}
                  </View>
                )}
            </View>
          </ScrollView>
        </View>
      </SafeAreaView>
    );
  }
}

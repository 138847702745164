import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ScrollView,
  SafeAreaView,
  Keyboard,
  ActivityIndicator,
  Dimensions,
  Platform,
  Image,
  Alert,
  Modal
} from "react-native";
import { WebView } from 'react-native-webview';
import firebase from "firebase";
import { HeaderBackButton } from "react-navigation-stack";
import "firebase/firestore";
const gStyles = global.gStyles //    "../../styles/gStyles";
import CurrentLocation from "../../shared/Map/currentLocation";
import Moment from "react-moment";
import BottomMenu from "../../shared/BottomMenu"
import GetPicture from "../../shared/Camera/GetPicture";
import GetVideo from "../../shared/Camera/GetVideo";
import DateTimePicker from '@react-native-community/datetimepicker';



export default class MeCommunityNewDiscussionScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    const navigateBack = navigation.getParam("navigateBack", navigation.goBack) || navigation.goBack
    return {
      title: navigation.getParam("title", "Discussion"),
      headerTransparent: true,
      headerTintColor: "#A8CF45",
      headerLeft: props => (
        <HeaderBackButton
          {...props}
          onPress={() => { navigateBack() }} />),
      headerTitleStyle: {
        fontWeight: "800",
        color: "#A8CF45",
        fontSize: 16,
        shadowOpacity: 1
      },
      headerRight: () => {
        const { params = {} } = navigation.state
        if (navigation.getParam("action", "") == "edit")
          if (!params.aaUpdate) return (
            <TouchableOpacity onPress={navigation.getParam("callUpdate")}
              style={{
                alignItems: "center", justifyContent: "center", backgroundColor: "#A8CF45",
                paddingHorizontal: 15, marginRight: 15, flexDirection: "row", borderRadius: 20, paddingVertical: 8
              }}
            >
              <Text style={{ fontSize: 16, color: "#000", fontWeight: "bold" }}>
                Update
            </Text>
            </TouchableOpacity>)
          else return (
            <ActivityIndicator size="small" color="#A8CF45" style={{ alignSelf: "center", marginRight: 35 }} animating={true} />)
      },
    };
  };

  state = {
    loading: true,

    // done below to aviod duplication. (remove this)

  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.community = {}
  }

  async componentDidMount() {
    const action = this.props.navigation.getParam("action", "new")
    const group = this.props.navigation.getParam("group", {})
    const type = this.props.navigation.getParam("type", "forum")
    this.community = this.props.navigation.getParam("community")

    if (action == "edit") this.props.navigation.setParams({ callUpdate: this.callUpdate });
    if (type == "event" || group?.aType == "event") this.props.navigation.setParams({ title: "Event" });
    if (type == "task" || group?.aType == "task") this.props.navigation.setParams({ title: "Task" });
    if (type == "poll" || group?.aType == "poll") this.props.navigation.setParams({ title: "Poll" });

    if (!this.community) {
      if ((action == "edit" || action == "editMembers") && group.communityID) {
        const communityDoc = await firebase
          .firestore()
          .collection("Communities")
          .doc(group.communityID)
          .get()
        if (!communityDoc.exists) this.props.navigation.goBack()
        this.community = communityDoc.data()
      } else {
        await global.timeout(1000)
        this.props.navigation.goBack()
        await global.timeout(300)
        alert("Currently not possible")
        return
      }
    }
    let isPollWithVotes = false, isEndedPoll = false
    if (action == "edit" && group?.aType == "poll") {
      if (group.pollHasEnded) {
        isEndedPoll = true
        // this.props.navigation.goBack()
        // await global.timeout(500)
        // alert("Polls has ended")
        // return
      }
      if (Array.isArray(group.pollResultsCondensed) && group.pollResultsCondensed.reduce((accumulator, currentValue) => accumulator + currentValue) > 0) {
        isPollWithVotes = true
        // this.props.navigation.goBack()
        // await global.timeout(500)
        // alert("Polls can't be edited after the first votes are in")
        // return
      }
    }
    const nowStamp = await firebase.firestore.Timestamp.now();
    const roundedDate = nowStamp.toDate()
    this.timezoneOffset = roundedDate.getTimezoneOffset()
    roundedDate.setHours(roundedDate.getHours() + (type == "task" ? 25 : 1));
    roundedDate.setMinutes(0, 0, 0); // Resets also seconds and milliseconds
    const defaultPictureURL = type == "event" ? "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/API%2F15522372370501206000.jpg?alt=media"
      : type == "task" ? "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/API%2F11292240243081982000.jpg?alt=media"
        : null

    console.log({ action })
    this.setState({
      action,
      isPollWithVotes, isEndedPoll,
      title: group.groupTitle || "",
      details: group.groupDetails || "",
      pictureURL: group.groupPictureURL || defaultPictureURL,
      youtubeID: group.groupYoutubeID || null,
      aSpecialCategory: group.aSpecialCategory || "general",
      aType: group.aType || type,
      aOpenness: group.aOpenness || "communityOnly",
      networkID: group.networkID || null,
      communityID: group.communityID || null,
      location: group.location || global.myLocation || {},
      options: group.options || { sortChildrenBy: "TIME_ASCENDING" },
      nOfComments: group.nOfComments || 0,
      nOfDirectComments: group.nOfDirectComments || 0,
      nOfTotalLikes: group.nOfTotalLikes || 0,
      nOfJoiners: group.nOfJoiners || 0,
      topics: group.topics || [],
      closedMemberIDs: group.xJoinerIDs || (global.myProfileID ? [global.myProfileID] : []),
      pollAnswers: group.pollAnswers || ["", "", ""],
      pollOptions: group.pollOptions || { individualVotesVisible: true, allowMultiple: false },
      pollResultsCondensed: group.pollResultsCondensed || [],
      eventDateOne: group.groupTitle ? new Date(group.eventMillisOne) : roundedDate,
      //eventDateNextReminder: group.groupTitle ? new Date(group.eventMillisNextReminder) : roundedDate,
      eventOptions: group.eventOptions || { repeat: null, reminderMinutesBefore: 0 },
      showEventPicker: Platform.OS == "ios",
      eventPickerType: Platform.OS == "ios" ? "datetime" : "date",
      taskDateOne: group.groupTitle ? new Date(group.taskMillisOne) : roundedDate,
      taskOptions: group.taskOptions || { reminderMinutesBefore: 0, repeat: "daily" },
      showTaskPicker: Platform.OS == "ios",
      taskPickerType: Platform.OS == "ios" ? "datetime" : "date",
      taskAssignees: group.taskAssignees || [],
      // taskAssigneesDone: group.taskAssignees || [],
      addMedia: (group.groupPictureURL || defaultPictureURL || group.groupYoutubeID) ? true : false,
      showGetPicture: false,
      showGetVideo: false,
      activityIndicatorAnimating: false,
      loading: false,
      error: false,
      watchingYoutube: false,
      playingYoutubeID: "",
      modalAVisible: false,
      activeAModal: "",
      activeASelection: [],
      showOpennessSelector: (this.community.openness == "PRIVATE"),
      showRepeatMenu: false,
      showRepeatTaskMenu: false,
      screen: action == "editMembers" ? "SELECT_MEMBERS" : "DEFAULT"
    })
    this.groupID = group.xID
  }
  async componentDidUpdate(prevProps, prevState) {
    if (prevState.screen != "DEFAULT" && this.state.screen == "DEFAULT")
      this.props.navigation.setParams({ navigateBack: null })
    if (prevState.screen == "DEFAULT" && this.state.screen != "DEFAULT")
      this.props.navigation.setParams({
        navigateBack: () => {
          if (this.state.aType == "task" && this.state.screen == "SELECT_MEMBERS") {
            let taskAssignees = global.duplicate(this.state.taskAssignees)
            taskAssignees = taskAssignees.filter(aID => this.state.closedMemberIDs.includes(aID))
            if (taskAssignees.length !== this.state.taskAssignees.length) this.setState({ taskAssignees })
          }
          if (this.state.aType == "task" && this.state.screen == "SELECT_ASSIGNEES") {
            let closedMemberIDs = global.duplicate(this.state.closedMemberIDs)
            this.state.taskAssignees.forEach(aID => { if (!closedMemberIDs.includes(aID)) closedMemberIDs.push(aID) })
            if (closedMemberIDs.length !== this.state.closedMemberIDs.length) this.setState({ closedMemberIDs })
          }
          this.setState({ screen: "DEFAULT" })
        }
      })
  }

  playYoutube = (id) => {
    this.setState({ playingYoutubeID: id, watchingYoutube: true })
  }

  activateASelection = (ob) => {
    console.log("this.state.activeAModal", this.state.activeAModal)
    this.setState({ modalAVisible: false })
    const type = ob.type
    console.log("type", type)
    if (this.state.activeAModal == "aOpenness") {
      this.setState({ aOpenness: type })
      if (type == "closed" && this.state.closedMemberIDs?.length < 2) {
        this.setState({ screen: "SELECT_MEMBERS" })
      }
    }
    if (this.state.activeAModal == "aType") {
      this.setState({ aType: type })
      if (type == "event" && !this.state.pictureURL)
        this.setState({ addMedia: true, pictureURL: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/API%2F15522372370501206000.jpg?alt=media" })
      if (type == "task" && !this.state.pictureURL)
        this.setState({ addMedia: true, pictureURL: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/API%2F11292240243081982000.jpg?alt=media" })
      if (type == "event") this.props.navigation.setParams({ title: "Event" });
      if (type == "task") this.props.navigation.setParams({ title: "Task" });
      if (type == "poll") this.props.navigation.setParams({ title: "Poll" });
      if (type == "forum") this.props.navigation.setParams({ title: "Discussion" });

    }
  }

  callCreate = async () => {
    if (!this.state.aType) return alert("No type selected");
    if (!this.state.aOpenness) return alert("Select open vs. private");
    // if (!this.state.location || !this.state.location.coords || this.state.location.coords.latitude == 6.55) return alert("You need to specify the location");
    if (!this.state.title || this.state.title.length < 2) return alert("Enter a title / name");
    if (this.state.aOpenness == "closed" && this.state.closedMemberIDs?.length == 0) return alert("Select Members")
    if (this.state.aType == "task" && this.state.taskAssignees.length < 1) return alert("You need to assign people to the task")
    if (global.myUID && !this.state.activityIndicatorAnimating) {
      const pollAnswers = this.state.pollAnswers.filter(a => a !== "")
      if (this.state.aType == "poll" && pollAnswers.length < 1) return alert("No answer entered")
      const communityID = this.props.navigation.getParam("communityID")
      try {
        this.setState({ activityIndicatorAnimating: true });
        const r = await fetch(global.cloudFunctionURL + "newGroup", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            isWeb: true,
            title: this.state.title,
            details: this.state.details,
            pictureURL: this.state.pictureURL,
            ...(this.state.youtubeID && { youtubeID: this.state.youtubeID }),
            aSpecialCategory: this.state.aSpecialCategory,
            aType: this.state.aType,
            aOpenness: this.state.aOpenness,
            ...(this.state.aOpenness == "closed" && { joinerIDs: this.state.closedMemberIDs }),
            ...(this.state.aType == "poll" && {
              pollAnswers,
              pollOptions: this.state.pollOptions
            }),
            ...(this.state.aType == "event" && {
              eventMillisOne: this.state.eventDateOne ? Date.parse(this.state.eventDateOne) : null,
              //eventMillisNextReminder: this.state.eventDateNextReminder ? Date.parse(this.state.eventDateNextReminder) : null,
              eventOptions: this.state.eventOptions
            }),
            ...(this.state.aType == "task" && {
              taskMillisOne: this.state.taskDateOne ? Date.parse(this.state.taskDateOne) : null,
              taskOptions: this.state.taskOptions,
              taskAssignees: this.state.taskAssignees,
              // taskAssigneesDone: this.state.taskAssigneesDone,
            }),
            networkID: this.state.networkID,
            communityID: communityID,
            coords: global.location.coords || { latitude: 6.6, longitude: 3.3 },
            options: this.state.options,
            topics: [],
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
        await this.setState({ activityIndicatorAnimating: false });
        const r2 = await r.json();
        if (r2.msg == "SUCCESS") {
          // alert
          // initiateApproval (including push notification for approver OR chat to approver)
          // navigate back

          console.log("Group Created");
          this.props.navigation.goBack();
        } else {
          alert("Error. Try again");
          console.log("Error while calling callCreate", r2);
        }
      } catch (err) {
        alert("Connection error.");
        console.log(err);
        this.setState({ activityIndicatorAnimating: false });
      }
    }
  };
  callUpdate = async () => {
    if (this.state.aOpenness == "closed" && this.state.closedMemberIDs?.length == 0) return alert("Select Members")
    // if (!this.state.location || !this.state.location.coords || this.state.location.coords.latitude == 6.55) return alert("You need to specify the location");
    if (!this.state.title || this.state.title.length < 2) return alert("Enter a title / name");
    if (this.state.aOpenness == "closed" && this.state.closedMemberIDs?.length == 0) return alert("Select Members")
    const pollAnswers = this.state.pollAnswers.filter(a => a !== "")
    if (this.state.aType == "poll" && pollAnswers.length < 1) return alert("No answer entered")
    if (this.state.aType == "task" && this.state.taskAssignees.length < 1) return alert("You need to assign people to the task")
    if (global.myUID && !this.state.activityIndicatorAnimating) {
      try {
        this.setState({ activityIndicatorAnimating: true });
        this.props.navigation.setParams({ aaUpdate: true })
        const r = await fetch(global.cloudFunctionURL + "editGroup", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            isWeb: true,
            action: "edit",
            chatGroupID: this.groupID,
            title: this.state.title,
            details: this.state.details,
            pictureURL: this.state.pictureURL,
            youtubeID: this.state.youtubeID,
            aSpecialCategory: this.state.aSpecialCategory,
            aType: this.state.aType,
            aOpenness: this.state.aOpenness,
            ...(this.state.aOpenness == "closed" && { joinerIDs: this.state.closedMemberIDs }),
            ...(this.state.aType == "poll" && {
              pollAnswers: this.state.pollAnswers,
              pollOptions: this.state.pollOptions
            }),
            ...(this.state.aType == "event" && {
              eventMillisOne: this.state.eventDateOne ? Date.parse(this.state.eventDateOne) : null,
              //eventMillisNextReminder: this.state.eventDateNextReminder ? Date.parse(this.state.eventDateNextReminder) : null,
              eventOptions: this.state.eventOptions
            }),
            ...(this.state.aType == "task" && {
              taskMillisOne: this.state.taskDateOne ? Date.parse(this.state.taskDateOne) : null,
              taskOptions: this.state.taskOptions,
              taskAssignees: this.state.taskAssignees,
              // taskAssigneesDone: this.state.taskAssigneesDone,
            }),
            //coords: global.location.coords,
            options: this.state.options,
            topics: [],
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
        const r2 = await r.json();
        if (r2.msg == "SUCCESS") {
          // alert
          // initiateApproval (including push notification for approver OR chat to approver)
          // navigate back

          console.log("Group Updated");
          if (this.props.navigation.getParam("delayGoBack", false)) await global.timeout(500)
          this.props.navigation.goBack();
          //this.props.navigation.setParams({ aaUpdate: false })
          //await this.setState({ activityIndicatorAnimating: false });
        } else {
          await this.setState({ activityIndicatorAnimating: false });
          this.props.navigation.setParams({ aaUpdate: false })
          await global.timeout(50)
          alert("Error. Try again");
          console.log("Error while calling callUpdate", r2);
        }
      } catch (err) {
        alert("Connection error.");
        console.log(err);
        this.setState({ activityIndicatorAnimating: false });
        this.props.navigation.setParams({ aaUpdate: false })
      }
    }
  };
  callUpdateMembers = async () => {
    if (global.myUID && !this.state.activityIndicatorAnimating) {
      try {
        this.setState({ loading: true });
        const r = await fetch(global.cloudFunctionURL + "editGroup", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            isWeb: true,
            action: "edit",
            chatGroupID: this.groupID,
            joinerIDs: this.state.closedMemberIDs,
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
        await this.setState({ loading: false });
        const r2 = await r.json();
        if (r2.msg == "SUCCESS") {
          console.log("Group Updated");
          this.props.navigation.goBack();
          await global.timeout(350)
          alert("Update successful")
        } else {
          alert("Error. Try again");
          console.log("Error while calling callUpdateMembers", r2);
        }
      } catch (err) {
        alert("Connection error.");
        global.warn(err, "cum-new");
        this.setState({ loading: false });
      }
    }
  };
  callDelete = (alertString) => {
    if (!global.myUID || this.state.activityIndicatorAnimating) return alert("Error")
    Alert.alert(alertString + "?", "This will be permanent",
      [{ text: "No", onPress: () => { } },
      { text: "Yes", onPress: () => { this.callDeleteExecute() } }],
      { cancelable: true });
  }
  callDeleteExecute = async () => {
    try {
      this.setState({ activityIndicatorAnimating: true });
      const r = await fetch(global.cloudFunctionURL + "editGroup", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          isWeb: true,
          action: "delete",
          chatGroupID: this.groupID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      this.setState({ activityIndicatorAnimating: false });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        // alert
        // initiateApproval (including push notification for approver OR chat to approver)
        // navigate back

        console.log("Group Deleted");
        this.props.navigation.goBack();
        await global.timeout(350)
        alert("Deleted successfully")
      } else {
        alert("Error. Try again");
        console.log("Error", r2);
      }
    } catch (err) {
      alert("Connection error.");
      global.warn(err, "CD_7");
      this.setState({ activityIndicatorAnimating: false });
    }
  };

  addAnswer = () => {
    const pollAnswers = global.duplicate(this.state.pollAnswers)
    pollAnswers.push("")
    this.setState({ pollAnswers })
  }

  removeAnswer = (i) => {
    let pollAnswers = global.duplicate(this.state.pollAnswers)
    pollAnswers = pollAnswers.filter((ans, j) => {
      return j !== i
    })
    this.setState({ pollAnswers })
  }

  render() {
    const sWidth = Dimensions.get("window").width;
    const sHeight = Dimensions.get("window").height;
    const opennessSelection = [{
      type: "communityOnly",
      title: "👥 All Members",
      description: "Visible to all members of " + (this.community?.name) || "this community"
    },
    {
      type: "closed",
      title: "🔒 Selected Members",
      description: "Only visible to community members you select"
    }]
    // const opennessSelection = [{
    //   type: "public",
    //   title: "Public",
    //   description: "Can be found by people around you, your followers, your communities"
    // },
    // {
    //   type: "closed",
    //   title: "Closed",
    //   description: "Only visible to people you invite"
    // },
    // {
    //   type: "communityOnly",
    //   title: "Community Only",
    //   description: "Accessible to people in the community you specify"
    // }]

    const typeSelection = [{
      type: "forum",
      title: "💬  Discussion Forum",
      description: "Discuss, chat, comment"
    }, {
      type: "poll",
      title: "🗳️  Poll",
      description: "Start a survey and get votes"
    }, {
      type: "event",
      title: "🕑  Event",
      description: "Schedule an event and remind people"
    }, {
      type: "task",
      title: "📋  Task",
      description: "Assign a task to community members or yourself"
      // {
      //   type: "broadcast",
      //   title: "Broadcast",
      //   description: "Only you can send messages"
      // }, {
      //   type: "broadcastWithResponses",
      //   title: "Broadcast with comments",
      //   description: "Only you can send messages, others can respond."
    }]
    const reminderSelected = this.state.eventOptions?.reminderMinutesBefore > 0 || this.state.eventOptions?.reminderMinutesBefore === 0
    const taskReminderSelected = this.state.taskOptions?.reminderMinutesBefore > 0 || this.state.taskOptions?.reminderMinutesBefore === 0

    let everyString = "", xthString = ""
    if (this.state.aType == "event") {
      const weekday = global.dayStrings[this.state.eventDateOne.getDay()]
      const day = this.state.eventDateOne.getDate()
      xthString = "1st"
      if (day > 7 && day <= 14) xthString = "2nd"
      if (day > 14 && day <= 21) xthString = "3rd"
      if (day > 21) xthString = "last"
      everyString = "every " + xthString + " " + weekday
    }
    let repeatString = this.state.eventOptions?.repeat || ""
    if (["1st", "2nd", "3rd", "last"].includes(repeatString)) repeatString = everyString

    let deleteString = "Delete Discussion"
    if (this.state.aType == "event") deleteString = "Delete Event"
    if (this.state.aType == "task") deleteString = "Delete Task"
    if (this.state.aType == "poll") deleteString = "Delete Poll"

    if (this.state.loading) return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "black" }} >
        <Text style={{ color: "#555" }}>Loading...</Text>
      </View>
    );
    if (this.state.error) return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "black" }}>
        <Text style={{ color: "#555" }}>Error. Try again.</Text>
      </View>
    );
    if (this.state.isPollWithVotes || this.state.isEndedPoll) return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "black" }}>
        <Text style={{ color: "#bbb", marginBottom: 100, fontWeight: "bold", fontSize: 22, textAlign: "center" }}>{this.state.isPollWithVotes ? "Polls can't be edited \nonce the first votes are in." : "Poll has ended."}</Text>
        {!this.state.activityIndicatorAnimating ? <View>
          <TouchableOpacity
            onPress={() => { this.props.navigation.goBack() }}
          >
            <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: 150, alignSelf: "center", alignItems: "center" }]} >
              <Text style={{ fontSize: 20, fontWeight: "bold", color: "#000" }}>Go Back</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => { this.callDelete("Delete Poll") }}>
            <View style={[global.gStyles.buttonXLW, { borderWidth: 0, borderColor: "#f88", paddingVertical: 2.5, marginTop: 35, marginBottom: 10, flexDirection: "row", alignSelf: "center", alignItems: "center" }]} >
              <Text style={{ fontSize: 20, color: "#722" }}>Delete Poll</Text>
            </View>
          </TouchableOpacity>
        </View> :
          <ActivityIndicator size="large" color="#A8CF45" style={{ alignSelf: "center" }} animating={true} />}
      </View>
    );

    const tStyle = { color: "#A8CF45", fontSize: 16, fontWeight: "800", textAlign: "left", marginTop: 11, marginBottom: 2 }

    if (this.state.screen == "DEFAULT") return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#000000" }}>
        <TouchableWithoutFeedback
          onPress={Keyboard.dismiss}
          accessible={false}
        >
          <ScrollView style={{ marginTop: 50, paddingHorizontal: 10 }}
            scrollEventThrottle={1000}
            onScroll={(e) => { Keyboard.dismiss() }}>
            {Platform.OS === "android" && <View style={{ height: 25 }} />}
            <Text style={tStyle}>{this.state.aType == "poll" ? "Question" : "Title"}</Text>
            <TextInput
              onChangeText={title => {
                this.setState({ title });
              }}
              placeholder={this.state.aType == "group" ? "" : ""}
              placeholderTextColor={"#66a"}
              style={{
                color: "#ffffff", borderColor: "grey", borderWidth: 0, paddingLeft: 3, borderRadius: 5, height: this.state.title.length > 100 ? 75 : this.state.title.length > 60 ? 60 : this.state.title.length > 30 ? 45 : 30, backgroundColor: "#223"
              }}
              maxLength={1000}
              numberOfLines={4}
              multiline={true}
              underlineColorAndroid="transparent"
              defaultValue={this.state.title}
              keyboardDismissMode={'on-drag'}
              keyboardAppearance={"dark"}
            />
            {this.state.aType == "poll" && <Text style={tStyle}>Answers</Text>}
            {this.state.aType == "poll" && this.state.pollAnswers.map((answer, i) => {
              return (<View key={this.state.pollAnswers?.[i] + i || i}
                style={{ flexDirection: "row", alignItems: "center", marginBottom: 5, alignSelf: "stretch", justifyContent: "space-between" }}
              >
                <TextInput
                  onChangeText={answer => {
                    this.state.pollAnswers[i] = answer //experimental
                  }}
                  placeholder={"Answer " + (i + 1)}
                  placeholderTextColor={"#66a"}
                  style={{
                    flex: 1, color: "#ffffff", borderColor: "grey", borderWidth: 0, paddingLeft: 3, borderRadius: 5, height: 30, backgroundColor: "#223"
                  }}
                  maxLength={85}
                  underlineColorAndroid="transparent"
                  defaultValue={this.state.pollAnswers[i]}
                  keyboardDismissMode={'on-drag'}
                  keyboardAppearance={"dark"}
                />
                <TouchableOpacity
                  onPress={() => { this.removeAnswer(i) }}
                  style={{ width: 35, height: 35, justifyContent: "center", alignItems: "center" }}>
                  <Image
                    style={{ width: 20, height: 20, tintColor: "rgba(155, 155, 178, 0.8)" }}
                    source={require("../../images/icons/CancelWhite.png")}
                  />
                </TouchableOpacity>
              </View>)
            })}
            {this.state.aType == "poll" && <View>
              <TouchableOpacity
                onPress={() => { this.addAnswer() }}
                style={[global.gStyles.buttonXW, { width: 120, marginBottom: 10 }]}
              >
                <Text style={global.gStyles.buttontXW}>+  Add Answer</Text>
              </TouchableOpacity>

              <Text style={tStyle}>Options</Text>
              <TouchableOpacity onPress={() => {
                const pollOptions = global.duplicate(this.state.pollOptions)
                pollOptions.allowMultiple = !this.state.pollOptions.allowMultiple
                this.setState({ pollOptions })
              }}>
                <View style={{ height: 30 }} >
                  <CheckboxSelector selected={this.state.pollOptions.allowMultiple} text={"Allow people to choose multiple answers"} />
                </View>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {
                const pollOptions = global.duplicate(this.state.pollOptions)
                pollOptions.individualVotesVisible = !this.state.pollOptions.individualVotesVisible
                this.setState({ pollOptions })
              }}>
                <View style={{ height: 30 }} >
                  <CheckboxSelector selected={this.state.pollOptions.individualVotesVisible} text={"Individual Votes Visible"} />
                </View>
              </TouchableOpacity>
            </View>}

            {this.state.aType == "task" && <View>

              <Text style={tStyle}>Assignees</Text>
              {this.state.taskAssignees.length >= 1 ? <TouchableOpacity
                onPress={() => { this.setState({ screen: "SELECT_ASSIGNEES" }) }}
                style={{ height: 35, alignSelf: "stretch" }}>
                <ScrollView horizontal>
                  {this.state.taskAssignees.map(aID => {
                    return <Member profileID={aID} faceOnly key={aID}
                      selected={this.state.taskAssignees.includes(aID)}
                      navigation={this.props.navigation} />
                  })}
                </ScrollView>
              </TouchableOpacity>
                : <TouchableOpacity
                  onPress={() => { this.setState({ screen: "SELECT_ASSIGNEES" }) }}
                  style={[global.gStyles.buttonX, { width: 120, marginTop: 11, marginBottom: 5 }]}
                >
                  <Text style={global.gStyles.buttontX}>Select Now!</Text>
                </TouchableOpacity>}

              <Text style={tStyle}>Due Date</Text>
              {this.state.showTaskPicker ? <DateTimePicker
                testID="dateTimePicker"
                timeZoneOffsetInMinutes={-this.timezoneOffset}
                value={this.state.taskDateOne}
                mode={this.state.taskPickerType}
                is24Hour={true}
                display="default"
                //minimumDate={`new `Date()}
                minuteInterval={5}
                onChange={async (task, taskDateOne) => {
                  while (taskDateOne < new Date()) {
                    taskDateOne = global.addDays(taskDateOne, 1)
                  }
                  if (task.type == "dismissed") { // Android
                    this.setState({ showTaskPicker: false })
                  } else {
                    if (task.type == "set") { // Android
                      await this.setState({ showTaskPicker: false })
                    }
                    if (taskDateOne) this.setState({ taskDateOne })
                  }
                }} />
                : <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <TouchableOpacity onPress={() => { this.setState({ showTaskPicker: true, taskPickerType: "date" }) }}>
                    <View style={{
                      paddingHorizontal: 7, color: "#fff", borderRadius: 5, height: 35, borderColor: "#334", borderWidth: 2,
                      alignItems: "center", justifyContent: "center"
                    }}>
                      <Text style={{ color: "#ccd", fontSize: 20 }}><Moment format={"MMM Do YYYY"} element={Text}>
                        {this.state.taskDateOne}</Moment></Text>
                    </View>
                  </TouchableOpacity>
                  <View style={{ height: 11, width: 11 }} />
                  <TouchableOpacity onPress={() => { this.setState({ showTaskPicker: true, taskPickerType: "time" }) }}>
                    <View style={{
                      paddingHorizontal: 7, color: "#fff", borderRadius: 5, height: 35, borderColor: "#334", borderWidth: 2,
                      alignItems: "center", justifyContent: "center",
                    }}>
                      <Text style={{ color: "#ccd", fontSize: 20 }}><Moment format={"HH:mm"} element={Text}>
                        {this.state.taskDateOne}</Moment></Text>
                    </View>
                  </TouchableOpacity>
                </View>}

              <Text style={tStyle}>Options</Text>
              <TouchableOpacity onPress={() => {
                const taskOptions = global.duplicate(this.state.taskOptions)
                if (!taskReminderSelected) {
                  taskOptions.reminderMinutesBefore = 0
                  taskOptions.repeat = "daily"
                } else {
                  taskOptions.reminderMinutesBefore = null
                  taskOptions.repeat = null
                }
                this.setState({ taskOptions })
              }}>
                <View style={{ height: 30, flexDirection: "row", alignItems: "center" }} >
                  <CheckboxSelector selected={taskReminderSelected} text={"Remind assignees "} />
                  {taskReminderSelected && (
                    <View style={{ height: 30, flexDirection: "row", alignItems: "center" }} >
                      <TextInput
                        onChangeText={reminderMinutesBefore => {
                          const taskOptions = global.duplicate(this.state.taskOptions)
                          taskOptions.reminderMinutesBefore = Number(reminderMinutesBefore) || 0
                          this.setState({ taskOptions })
                        }}
                        onBlur={() => {
                          const taskOptions = global.duplicate(this.state.taskOptions)
                          if (typeof this.state.taskOptions.reminderMinutesBefore == "number")
                            taskOptions.reminderMinutesBefore = Math.round(this.state.taskOptions.reminderMinutesBefore / 5) * 5
                          this.setState({ taskOptions })
                        }}
                        style={{ minWidth: 50, color: "#fff", borderRadius: 5, height: 30, backgroundColor: "#223" }}
                        maxLength={4}
                        textAlign={'center'}
                        underlineColorAndroid="transparent"
                        value={this.state.taskOptions.reminderMinutesBefore?.toString?.() || ""}
                        keyboardType="numeric"
                        keyboardDismissMode={'on-drag'}
                        keyboardAppearance={"dark"}
                      />
                      <Text style={{ color: this.props.color || "#ccd", }}>{" minutes before the due date"}</Text>
                    </View>)}
                </View>
              </TouchableOpacity>
              {(!!this.state.taskOptions.reminderMinutesBefore || this.state.taskOptions.reminderMinutesBefore == 0) && <TouchableOpacity onPress={() => {
                const taskOptions = global.duplicate(this.state.taskOptions)
                if (!taskOptions.repeat) taskOptions.repeat = "daily"
                else taskOptions.repeat = null
                this.setState({ taskOptions })
              }}>
                <View style={{ height: 30, flexDirection: "row", alignItems: "center" }} >
                  <CheckboxSelector selected={!!this.state.taskOptions.repeat} text={"Remind overdue assignees "} />
                  {!!this.state.taskOptions.repeat && (
                    <TouchableOpacity onPress={() => { this.setState({ showRepeatMenu: true }) }}>
                      <View style={{ height: 30, flexDirection: "row", alignItems: "center" }} >
                        <View style={{
                          paddingHorizontal: 7, color: "#fff", borderRadius: 5, height: 30, borderColor: "#334", borderWidth: 2,
                          alignItems: "center", justifyContent: "center"
                        }}>
                          <BottomMenu items={["hourly", "daily", "weekdays", "weekly", "monthly", "never"]}
                            visible={this.state.showRepeatMenu}
                            onSelectItem={async (item) => {
                              const taskOptions = global.duplicate(this.state.taskOptions)
                              let newRepeat = item == "never" ? null : item
                              taskOptions.repeat = newRepeat
                              this.setState({ taskOptions })
                            }}
                            onClose={() => { this.setState({ showRepeatMenu: false }) }}
                            title={"Remind assignees..."}
                          />
                          <Text style={{ color: "#ccd" }}>{this.state.taskOptions.repeat}</Text>
                        </View>
                      </View>
                    </TouchableOpacity>)}
                </View>
              </TouchableOpacity>}
            </View>}

            {this.state.aType == "event" && <View>
              <Text style={tStyle}>Time</Text>
              {this.state.showEventPicker ? <DateTimePicker
                testID="dateTimePicker"
                timeZoneOffsetInMinutes={-this.timezoneOffset}
                value={this.state.eventDateOne}
                mode={this.state.eventPickerType}
                is24Hour={true}
                display="default"
                //minimumDate={new Date()}
                minuteInterval={5}
                onChange={async (event, eventDateOne) => {
                  while (eventDateOne < new Date()) {
                    eventDateOne = global.addDays(eventDateOne, 1)
                  }
                  if (event.type == "dismissed") { // Android
                    this.setState({ showEventPicker: false })
                  } else {
                    if (event.type == "set") { // Android
                      await this.setState({ showEventPicker: false })
                    }
                    if (eventDateOne) this.setState({ eventDateOne })
                    if (this.state.aType == "event") {
                      if (["1st", "2nd", "3rd", "last"].includes(this.state.eventOptions?.repeat)) {
                        const day = eventDateOne.getDate()
                        let newRepeat = "1st"
                        if (day > 7 && day <= 14) newRepeat = "2nd"
                        if (day > 14 && day <= 21) newRepeat = "3rd"
                        if (day > 21) newRepeat = "last"
                        if (this.state.eventOptions?.repeat !== newRepeat) {
                          const eventOptions = global.duplicate(this.state.eventOptions)
                          eventOptions.repeat = newRepeat
                          this.setState({ eventOptions })
                        }
                      }
                    }
                  }
                }} />
                : <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <TouchableOpacity onPress={() => { this.setState({ showEventPicker: true, eventPickerType: "date" }) }}>
                    <View style={{
                      paddingHorizontal: 7, color: "#fff", borderRadius: 5, height: 35, borderColor: "#334", borderWidth: 2,
                      alignItems: "center", justifyContent: "center"
                    }}>
                      <Text style={{ color: "#ccd", fontSize: 20 }}><Moment format={"MMM Do YYYY"} element={Text}>
                        {this.state.eventDateOne}</Moment></Text>
                    </View>
                  </TouchableOpacity>
                  <View style={{ height: 11, width: 11 }} />
                  <TouchableOpacity onPress={() => { this.setState({ showEventPicker: true, eventPickerType: "time" }) }}>
                    <View style={{
                      paddingHorizontal: 7, color: "#fff", borderRadius: 5, height: 35, borderColor: "#334", borderWidth: 2,
                      alignItems: "center", justifyContent: "center",
                    }}>
                      <Text style={{ color: "#ccd", fontSize: 20 }}><Moment format={"HH:mm"} element={Text}>
                        {this.state.eventDateOne}</Moment></Text>
                    </View>
                  </TouchableOpacity>
                </View>
              }

              <Text style={tStyle}>Options</Text>
              <TouchableOpacity onPress={() => {
                const eventOptions = global.duplicate(this.state.eventOptions)
                if (!eventOptions.repeat) eventOptions.repeat = "daily"
                else eventOptions.repeat = null
                this.setState({ eventOptions })
              }}>
                <View style={{ height: 30, flexDirection: "row", alignItems: "center" }} >
                  <CheckboxSelector selected={!!this.state.eventOptions.repeat} text={"Repeat "} />
                  {!!this.state.eventOptions.repeat && (
                    <TouchableOpacity onPress={() => { this.setState({ showRepeatMenu: true }) }}>
                      <View style={{ height: 30, flexDirection: "row", alignItems: "center" }} >
                        <View style={{
                          paddingHorizontal: 7, color: "#fff", borderRadius: 5, height: 30, borderColor: "#334", borderWidth: 2,
                          alignItems: "center", justifyContent: "center"
                        }}>
                          <BottomMenu items={["daily", "weekdays", "weekly", "monthly", everyString, "yearly", "never"]}
                            visible={this.state.showRepeatMenu}
                            onSelectItem={async (item) => {
                              const eventOptions = global.duplicate(this.state.eventOptions)
                              let newRepeat = item == "never" ? null : item
                              if (item == everyString) newRepeat = xthString
                              eventOptions.repeat = newRepeat
                              this.setState({ eventOptions })
                            }}
                            onClose={() => { this.setState({ showRepeatMenu: false }) }}
                            title={"Repeat this event..."}
                          />
                          <Text style={{ color: "#ccd" }}>{repeatString}</Text>
                        </View>
                      </View>
                    </TouchableOpacity>)}
                </View>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {
                const eventOptions = global.duplicate(this.state.eventOptions)
                if (!reminderSelected) eventOptions.reminderMinutesBefore = 0
                else eventOptions.reminderMinutesBefore = null
                this.setState({ eventOptions })
              }}>
                <View style={{ height: 30, flexDirection: "row", alignItems: "center" }} >
                  <CheckboxSelector selected={reminderSelected} text={"Remind everyone "} />
                  {reminderSelected && (
                    <View style={{ height: 30, flexDirection: "row", alignItems: "center" }} >
                      <TextInput
                        onChangeText={reminderMinutesBefore => {
                          const eventOptions = global.duplicate(this.state.eventOptions)
                          eventOptions.reminderMinutesBefore = Number(reminderMinutesBefore) || 0
                          this.setState({ eventOptions })
                        }}
                        onBlur={() => {
                          const eventOptions = global.duplicate(this.state.eventOptions)
                          if (typeof this.state.eventOptions.reminderMinutesBefore == "number")
                            eventOptions.reminderMinutesBefore = Math.round(this.state.eventOptions.reminderMinutesBefore / 5) * 5
                          this.setState({ eventOptions })
                        }}
                        style={{ minWidth: 50, color: "#fff", borderRadius: 5, height: 30, backgroundColor: "#223" }}
                        maxLength={4}
                        textAlign={'center'}
                        underlineColorAndroid="transparent"
                        value={this.state.eventOptions.reminderMinutesBefore?.toString?.() || ""}
                        keyboardType="numeric"
                        keyboardDismissMode={'on-drag'}
                        keyboardAppearance={"dark"}
                      />
                      <Text style={{ color: this.props.color || "#ccd", }}>{" minutes before the event"}</Text>
                    </View>)}
                </View>
              </TouchableOpacity>
            </View>}

            {this.state.aType !== "poll" && <Text style={tStyle}>Description (optional)</Text>}
            {this.state.aType !== "poll" && <TextInput
              onChangeText={details => {
                this.setState({ details });
              }}
              multiline={true}
              placeholder={""}
              placeholderTextColor={"#66a"}
              numberOfLines={10}
              style={{
                color: "#ffffff", borderColor: "grey", borderWidth: 0, paddingLeft: 3, borderRadius: 5, height: this.state.details.length > 250 ? 200 : this.state.details.length > 150 ? 160 : 120, backgroundColor: "#223",
              }}
              maxLength={5000}
              underlineColorAndroid="transparent"
              defaultValue={this.state.details}
              rowSpan={10}
              keyboardDismissMode={'on-drag'}
              keyboardAppearance={"dark"}
            />}

            {!!this.state.addMedia ? <View style={{ alignItems: "center", justifyContent: "center" }}>
              <Text style={[tStyle, { alignSelf: "flex-start" }]}>Picture / Video</Text>
              <TouchableOpacity
                onPress={() => {
                  this.setState({ showGetPicture: true });
                }}>
                {!!this.state.pictureURL ? <View>
                  <View
                    style={{ width: sWidth - 20, height: (sWidth - 20) * 9 / 16, borderRadius: 5, alignItems: "center", justifyContent: "center", marginBottom: -((sWidth - 20) * 9 / 16) }}
                  >
                    <Text style={{ color: "#555" }}>Loading...</Text>
                  </View>
                  <Image
                    key={this.state.pictureURL}
                    style={{ width: sWidth - 20, height: (sWidth - 20) * 9 / 16, borderRadius: 5, borderWidth: 1, borderColor: "#222" }}
                    source={{ uri: this.state.pictureURL }}
                  />
                  {/* <View
                      style={{ width: sWidth - 20, height: ((sWidth - 20) * 9 / 16) + 15, marginTop: -((sWidth - 20) * 9 / 16) - 15, borderRadius: 5, alignItems: "flex-end" }}
                    >
                      <TouchableOpacity style={{ height: 55, width: 55, alignItems: "flex-end" }}>
                        <Image
                          style={{ width: 35, height: 35, opacity: 1, tintColor: "rgba(220, 220, 220, 0.8)" }}
                          source={require("../../images/icons/CancelWhite.png")}
                        />
                      </TouchableOpacity>
                    </View> */}
                </View> :
                  <View
                    style={{ width: sWidth - 20, height: (sWidth - 20) * 9 / 16, borderRadius: 5, borderColor: "#aaa", borderWidth: 1, borderStyle: "dashed" }}
                  />
                }
              </TouchableOpacity>
              {this.state.showGetPicture && (
                <GetPicture
                  offerAPI
                  onDone={url => {
                    if (url) { this.setState({ pictureURL: url }); }
                    console.log("PictureURL", url);
                    this.setState({ showGetPicture: false });
                  }}
                  item={"POST"}
                />
              )}
              {this.state.showGetVideo && (
                <GetVideo
                  onDone={(youtubeID, thumbURL) => {
                    if (youtubeID && thumbURL) { this.setState({ pictureURL: thumbURL, youtubeID }); }
                    console.log("Gotten YouTube video..", youtubeID, thumbURL);
                    this.setState({ showGetVideo: false });
                  }}
                  item={"POST"}
                />
              )}
              {!!this.state.youtubeID && <TouchableOpacity style={{
                marginTop: -(220), width: 220, height: 220, alignItems: "center", justifyContent: "center"
              }}
                onPress={() => { this.playYoutube(this.state.youtubeID) }}>
                <View
                  style={{
                    backgroundColor: "rgba(198,198,198,0.95)", borderRadius: 25, paddingLeft: Platform.OS == "ios" ? 3 : 3.5, paddingBottom: Platform.OS == "ios" ? 0.5 : 2.5,
                    height: 50, justifyContent: 'center', alignItems: 'center', width: 50,
                  }}>
                  <Text style={{ color: "#000", fontSize: 36 }} >
                    {"▶"}
                  </Text>
                </View>
              </TouchableOpacity>}
              <TouchableOpacity
                onPress={() => { this.setState({ showGetVideo: true }); }}
                style={{ backgroundColor: "rgba(220, 220, 220, 0.8)", marginTop: -42, marginBottom: 19, padding: 3, borderRadius: 5 }}
              >
                <Text style={{ color: "black", minWidth: 100, textAlign: "center" }}>{this.state.youtubeID ? 'Change Video' : 'Add Video'}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => { this.setState({ showGetPicture: true }); }}
                style={{ backgroundColor: "rgba(220, 220, 220, 0.8)", marginTop: -11.5, padding: 3, borderRadius: 5 }}
              >
                <Text style={{ color: "black", minWidth: 100, textAlign: "center" }}>{this.state.pictureURL ? this.state.youtubeID ? 'Change Thumbnail' : 'Change Picture' : 'Add Picture'}</Text>
              </TouchableOpacity>
            </View>
              : <TouchableOpacity
                onPress={() => { this.setState({ addMedia: true }); }}
                style={[global.gStyles.buttonXW, { width: 120, marginTop: 11, marginBottom: 5 }]}
              >
                <Text style={global.gStyles.buttontXW}>Add Picture / Video</Text>
              </TouchableOpacity>}

            <Text style={[tStyle, { alignSelf: "flex-start" }]}>Type</Text>

            <TouchableOpacity onPress={() => {
              if (this.state.action == "edit") alert("Type cannot be changed")
              else this.setState({ modalAVisible: true, activeAModal: "aType", activeASelection: typeSelection })
            }}>
              <ASelector selection={typeSelection} selected={this.state.aType} />
            </TouchableOpacity>

            {this.state.showOpennessSelector && <View>
              <TouchableOpacity onPress={() => this.setState({ modalAVisible: true, activeAModal: "aOpenness", activeASelection: opennessSelection })}>
                <ASelector selection={opennessSelection} selected={this.state.aOpenness} />
              </TouchableOpacity>
              {this.state.closedMemberIDs.length > 1 && this.state.aOpenness == "closed" && <TouchableOpacity
                onPress={() => { this.setState({ screen: "SELECT_MEMBERS" }) }}
                style={{ height: 35, alignSelf: "stretch" }}>
                <ScrollView horizontal>
                  {this.state.closedMemberIDs.map(mID => {
                    return <Member profileID={mID} faceOnly key={mID}
                      selected={this.state.closedMemberIDs.includes(mID)}
                      navigation={this.props.navigation} />
                  })}
                </ScrollView>
              </TouchableOpacity>}
            </View>}

            {/* <Text style={[tStyle, { alignSelf: "flex-start" }]}>Location</Text>
              <View style={{ borderRadius: 7.5, width: "100%", backgroundColor: "#18181f", }}>
                <CurrentLocation
                  textColor={"#ccd"}
                  initialCoords={global.location ? this.state.location.coords : null}
                  onUpdate={(address, coords) => {
                    console.log("upda", address, coords);
                    this.setState({
                      location: { address: address, coords: coords }
                    });
                  }}
                  iconColor={"#889"}
                />
                <View style={{ height: 2 }} />
              </View> */}

            <View
              style={{
                marginLeft: 5,
                marginRight: 5,
                marginTop: 0,
                marginBottom: 10
              }}
            >
              <View style={{ height: 30 }} />
              {!this.state.activityIndicatorAnimating ? <View>
                {this.state.action !== "edit" ? <TouchableOpacity
                  onPress={this.state.action == "edit" ? this.callUpdate : this.callCreate}
                >
                  <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: 150, alignSelf: "center", alignItems: "center" }]} >
                    <Text style={{ fontSize: 20, fontWeight: "bold", color: "#000" }}>{this.state.action == "edit" ? "Update" : "Create"}</Text>
                  </View>
                </TouchableOpacity>
                  : <TouchableOpacity style={{ marginTop: 20 }}
                    onPress={() => { this.callDelete(deleteString) }}
                  >
                    <View style={[global.gStyles.buttonXLW, { borderWidth: 0, borderColor: "#f88", paddingVertical: 2.5, marginTop: 15, marginBottom: 15, flexDirection: "row", alignSelf: "center", alignItems: "center" }]} >
                      <Text style={{ fontSize: 20, color: "#722" }}>{deleteString}</Text>
                    </View>
                  </TouchableOpacity>}
              </View> :
                <ActivityIndicator size="large" color="#A8CF45" style={{ alignSelf: "center" }} animating={true} />}
            </View>

            <Modal
              animationType="slide"
              transparent={true}
              visible={this.state.modalAVisible}
              onRequestClose={() => { this.setState({ modalAVisible: false }); }}
            >
              <TouchableOpacity
                onPress={() => this.setState({ modalAVisible: false })}
                style={{ flex: 1 }}
              />
              <View
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <View
                  style={{
                    alignItems: "center", justifyContent: "center", backgroundColor: "#ccd", borderTopEndRadius: 15, borderTopLeftRadius: 15
                  }}
                >
                  <View style={{ paddingTop: 25 }}>
                    <Text style={{ fontSize: 24, fontWeight: "bold", color: "#223" }}>{this.state.activeAModal == "aOpenness" ? "Select who can participate" : "Select Type"}</Text>
                  </View>
                  <View style={{ marginTop: 15, marginBottom: 5 }}>
                  </View>
                  {this.state.activeASelection.map((x) => {
                    return <TouchableOpacity key={x.type} onPress={() => { this.activateASelection(x) }}>
                      <ASelectorW ob={x} />
                    </TouchableOpacity>
                  })}
                  <View style={{ paddingTop: 25 }} />
                </View>
              </View>
            </Modal>
            <Modal
              animationType="fade"
              visible={this.state.watchingYoutube}
              onRequestClose={() => {
                this.setState({ watchingYoutube: false });
              }}
            >
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "flex-end",
                  height: "100%",
                  backgroundColor: "#000"
                }}
              >
                <WebView
                  originWhitelist={['*']}
                  style={{ width: sWidth, height: sHeight, marginVertical: ((sHeight - sWidth * 0.7) / 2) }}
                  source={{ uri: 'https://www.youtube.com/embed/' + this.state.playingYoutubeID }}
                  javaScriptEnabled={true}
                  domStorageEnabled={true}
                  useWebKit={true}
                />
                <TouchableOpacity
                  style={{
                    position: "absolute",
                    borderWidth: 0,
                    backgroundColor: "#000",
                    borderColor: "rgba(0,0,0,0.2)",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 35,
                    top: 65,
                    padding: 10,
                    left: 5,
                    height: 25,
                    borderRadius: 35
                  }}
                  onPress={() => {
                    this.setState({ watchingYoutube: false });
                  }}
                >
                  <Image
                    style={{ width: 35, height: 35, opacity: 1 }}
                    source={require("../../images/icons/CancelWhite.png")}
                  />
                </TouchableOpacity>
              </View>
            </Modal>
          </ScrollView>
        </TouchableWithoutFeedback>
      </SafeAreaView >
    );

    if (this.state.screen == "SELECT_MEMBERS") return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#000000" }}>
        <TouchableWithoutFeedback
          onPress={Keyboard.dismiss}
          accessible={false}
        >
          <View style={{ flex: 1 }}>
            <ScrollView style={{ marginTop: 50, paddingHorizontal: 10, flex: 1 }}
              scrollEventThrottle={1000}
              onScroll={(e) => { Keyboard.dismiss() }}>
              {Platform.OS === "android" && <View style={{ height: 25 }} />}
              <Text style={tStyle}>Select Members</Text>
              <Member profileID={global.myProfileID} selected={true} key={"me"} navigation={this.props.navigation} />
              {(this.community?.memberProfileIDs || []).map(pID => {
                if (pID == global.myProfileID) return <View key={"meToo"} />
                return <TouchableOpacity key={pID}
                  onPress={() => {
                    let closedMemberIDs = global.duplicate(this.state.closedMemberIDs)
                    if (closedMemberIDs.includes(pID))
                      closedMemberIDs = closedMemberIDs.filter((id) => { return (id != pID) })
                    else closedMemberIDs.push(pID)
                    this.setState({ closedMemberIDs })
                  }}
                ><Member profileID={pID}
                  selected={this.state.closedMemberIDs.includes(pID)}
                  navigation={this.props.navigation} />
                </TouchableOpacity>
              })}
              <View style={{ marginLeft: 5, marginRight: 5, marginTop: 0, marginBottom: 10 }} />
            </ScrollView>
            {this.state.closedMemberIDs.length > 1 && <View style={{ height: 65, alignItems: "center", justifyContent: "center", alignSelf: "stretch" }}>
              {!this.state.activityIndicatorAnimating ? <TouchableOpacity
                onPress={() => {
                  if (this.state.aType == "task") {
                    let taskAssignees = global.duplicate(this.state.taskAssignees)
                    taskAssignees = taskAssignees.filter(aID => this.state.closedMemberIDs.includes(aID))
                    if (taskAssignees.length !== this.state.taskAssignees.length) this.setState({ taskAssignees })
                  }
                  if (this.state.action == "editMembers") {
                    this.callUpdateMembers()
                  } else this.setState({ screen: "DEFAULT" })
                }}
              >
                <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: 150, alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 20, fontWeight: "bold", color: "#000" }}>Select</Text>
                </View>
              </TouchableOpacity> :
                <ActivityIndicator size="large" color="#A8CF45" style={{ alignSelf: "center" }} animating={true} />}
            </View>}
          </View>
        </TouchableWithoutFeedback>
      </SafeAreaView>
    );

    if (this.state.screen == "SELECT_ASSIGNEES") return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#000000" }}>
        <TouchableWithoutFeedback
          onPress={Keyboard.dismiss}
          accessible={false}
        >
          <View style={{ flex: 1 }}>
            <ScrollView style={{ marginTop: 50, paddingHorizontal: 10, flex: 1 }}
              scrollEventThrottle={1000}
              onScroll={(e) => { Keyboard.dismiss() }}>
              {Platform.OS === "android" && <View style={{ height: 25 }} />}
              <Text style={tStyle}>Select Assignees</Text>
              {(this.community?.memberProfileIDs || []).map(pID => {
                return <TouchableOpacity key={pID}
                  onPress={() => {
                    let taskAssignees = global.duplicate(this.state.taskAssignees)
                    if (taskAssignees.includes(pID))
                      taskAssignees = taskAssignees.filter((id) => { return (id != pID) })
                    else taskAssignees.push(pID)
                    this.setState({ taskAssignees })
                  }}
                ><Member profileID={pID}
                  selected={this.state.taskAssignees.includes(pID)}
                  navigation={this.props.navigation} />
                </TouchableOpacity>
              })}
              <View style={{ marginLeft: 5, marginRight: 5, marginTop: 0, marginBottom: 10 }} />
            </ScrollView>
            {this.state.taskAssignees.length >= 1 && <View style={{ height: 65, alignItems: "center", justifyContent: "center", alignSelf: "stretch" }}>
              {!this.state.activityIndicatorAnimating ? <TouchableOpacity
                onPress={() => {
                  if (this.state.aType == "task") {
                    let closedMemberIDs = global.duplicate(this.state.closedMemberIDs)
                    this.state.taskAssignees.forEach(aID => { if (!closedMemberIDs.includes(aID)) closedMemberIDs.push(aID) })
                    if (closedMemberIDs.length !== this.state.closedMemberIDs.length) this.setState({ closedMemberIDs })
                  }
                  this.setState({ screen: "DEFAULT" })
                }}
              >
                <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: 150, alignSelf: "center", alignItems: "center" }]} >
                  <Text style={{ fontSize: 20, fontWeight: "bold", color: "#000" }}>Select</Text>
                </View>
              </TouchableOpacity> :
                <ActivityIndicator size="large" color="#A8CF45" style={{ alignSelf: "center" }} animating={true} />}
            </View>}
          </View>
        </TouchableWithoutFeedback>
      </SafeAreaView>
    );
  }
}

class Member extends Component {
  state = {
    pictureURL: "", name: "", businessName: ""
  }

  async componentDidMount() {
    try {
      const profileDoc = await firebase
        .firestore()
        .collection("Profiles")
        .doc(this.props.profileID)
        .get()
      const profile = profileDoc.data()
      this.setState({
        pictureURL: profile.profilePictureURL || "",
        name: profile.profileName || "- No Name -",
        businessName: profile.profileNameBusiness || ""
      })
    } catch (e) {
      global.warn(e, "cdm_Member")
    }
  }

  renderCircle = filled => {
    if (filled) {
      return (
        <View style={{ width: 20, height: 20, borderColor: "#9999a4", backgroundColor: "#334", borderWidth: 0, borderRadius: 10 }}>
          <Text style={{ fontSize: 24, marginTop: -5, color: this.props.color || "#ccd" }}>✓</Text>
        </View>
      );
    } else {
      return (
        <View style={{ width: 20, height: 20, borderColor: "#ccd", borderWidth: 0.7, borderRadius: 10 }} />
      );
    }
  };

  render() {
    if (this.props.faceOnly) return (
      <View style={{ height: 35, width: 35, alignItems: "center", justifyContent: "center" }}>
        {this.state.pictureURL ? <Image
          style={{ width: 25, height: 25, borderRadius: 12.5, }}
          source={{ uri: this.state.pictureURL }}
        /> : <View style={{
          width: 25, height: 25, borderRadius: 12.5, backgroundColor: "#668"
        }} />}
      </View>
    )
    return (
      <View style={{ height: 35, flexDirection: "row", alignItems: "center", marginLeft: 10, alignSelf: "stretch" }}>
        <View style={{ width: 33, justifyContent: "center" }}>
          {this.renderCircle(this.props.selected)}
        </View>
        <View style={{ width: 33, justifyContent: "center" }}>
          {this.state.pictureURL ? <Image
            style={{ width: 25, height: 25, borderRadius: 12.5, }}
            source={{ uri: this.state.pictureURL }}
          /> : <View style={{
            width: 25, height: 25, borderRadius: 12.5, backgroundColor: "#668"
          }} />}
        </View>
        <View style={{ flex: 1 }}>
          <Text style={{ color: "#aab", fontSize: 13 }}>{this.state.name}</Text>
          <Text style={{ color: "#889", fontSize: 10 }}>{this.state.businessName}</Text>
        </View>
      </View>)
  }
}

//selection selected
class ASelector extends PureComponent {
  render() {
    let ob = {}
    this.props.selection.forEach((o) => {
      if (o.type == this.props.selected) ob = o
    })
    return (
      <View style={{
        alignItems: "center", justifyContent: "center", height: 85,
        flexDirection: "row", padding: 3,
        borderRadius: 7.5, width: "100%", backgroundColor: "#18181f",
        marginBottom: 7, marginTop: 3
      }}>
        <View style={{ alignItems: "flex-start", justifyContent: "center", flex: 1, paddingLeft: 10 }}>
          <Text style={{ color: "#ccd", fontSize: 18, fontWeight: "bold" }}>{ob.title}</Text>
          <Text style={{ color: "#99a", fontSize: 14 }}>{ob.description}</Text>
        </View>
        <View style={{ alignItems: "center", justifyContent: "center", width: 50 }}>
          <Text style={{ color: "#ccc", fontSize: 16, fontWeight: "bold", color: "#889" }}>▼</Text>
        </View>
      </View>
    );
  }
}
class ASelectorW extends PureComponent {
  render() {
    const ob = this.props.ob
    return (
      <View style={{
        alignItems: "center", justifyContent: "center", height: 100,
        flexDirection: "row", padding: 3,
        borderRadius: 7.5, width: "100%", backgroundColor: "#ccd",
        marginVertical: 5, borderTopWidth: 1, borderTopColor: "#aab"
      }}>
        <View style={{ alignItems: "flex-start", justifyContent: "center", flex: 1, paddingLeft: 10 }}>
          <Text style={{ color: "#223", fontSize: 18, fontWeight: "bold" }}>{ob.title}</Text>
          <Text style={{ color: "#778", fontSize: 14 }}>{ob.description}</Text>
        </View>
        <View style={{ alignItems: "center", justifyContent: "center", width: 50 }}>
          <Text style={{ color: "#333", fontSize: 20, fontWeight: "bold", color: "#99b" }}></Text>
        </View>
      </View>
    );
  }
}

class Selector extends Component {
  render() {
    let height =
      this.props.text.length < 45
        ? 30
        : 45 + (this.props.text.length - 45) * 0.33;
    let circle = filled => {
      if (filled) {
        return (
          <View
            style={{ width: 20, height: 20, borderColor: "#9999a4", backgroundColor: "#9999a4", borderWidth: 1, borderRadius: 10 }}
          />
        );
      } else {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#556", borderWidth: 1, borderRadius: 10 }} />
        );
      }
    };

    return (
      <View style={{ height: height, flexDirection: "row", alignItems: "center" }}>
        {circle(this.props.selected)}
        <Text style={{ marginLeft: 10, color: "#999" }}>{this.props.text}</Text>
      </View>
    );
  }
}

class CheckboxSelector extends PureComponent {
  render() {
    let height =
      this.props.text.length < 45
        ? 30
        : 45 + (this.props.text.length - 45) * 0.33;
    let circle = filled => {
      if (filled) {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#9999a4", backgroundColor: "#334", borderWidth: 0, borderRadius: 10 }}>
            <Text style={{ fontSize: 24, marginTop: -5, color: this.props.color || "#ccd" }}>✓</Text>
          </View>
        );
      } else {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#ccd", borderWidth: 0.7, borderRadius: 10 }} />
        );
      }
    };

    return (
      <View style={{ height: height, flexDirection: "row", alignItems: "center" }}>
        {circle(this.props.selected)}
        <Text style={{ marginLeft: 10, color: this.props.color || "#ccd", fontWeight: this.props.selected ? "normal" : "normal" }}>{this.props.text}</Text>
      </View>)
  }
}
import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  Linking,
  Modal,
  Alert,
  Dimensions,
} from "react-native";
import * as Font from "expo-font";
import { LinearGradient } from "expo-linear-gradient";
import { WebView } from "react-native-webview";
import { AntDesign } from "@expo/vector-icons";
import { SvgCss } from "react-native-svg";
import firebase from "firebase";
import "firebase/firestore";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import CachedImage from "../../../shared/CachedImage";

import data from "../imageData";
import ProductPreview from "../shared/ProductPreview";
import CommunityPreview from "../shared/CommunityPreview";
import ShopPreview from "../shared/ShopPreview";
import ChatGroup from "../../../Messages/Groups/ChatGroupDetailScreen";
import JobsPreview from "../shared/JobsPreview";

const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;

// const hiring = require("../../../images/jobPageIcons/weHire2.png");
// const quickJob = require("../../../images/jobPageIcons/studentHire.png");
// const innovation = require("../../../images/jobPageIcons/InnovationKitchen.png");

export default class HomeJobsScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    const category2 = navigation.getParam("category2", "Jobs");
    const hideTitle = navigation.getParam("hideTitle", false);
    return {
      tabBarVisible: false,
      headerTransparent: true,
      title: hideTitle ? "" : category2,
      headerTitleStyle: {
        fontWeight: "bold",
        color: "#F5F5F5",
        shadowOpacity: 0.1,
      },
    };
  };

  state = {
    showModalWorker: false,
    showModalProfessional: false,

    assetsLoaded: false,

    category2: null,
    showCategory: false,
    promotionProfileID: null,
    promotionImageURL: null,
    promotionBannerComponents: [],
    isModalVisible: false,

    forumGroup: null, //Jobs Forum data object
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => {
      return;
    };
  }

  async componentDidMount() {
    this.loadPromotions();

    await Font.loadAsync({
      "ProximaNovaA-Regular": require("../../../../assets/font/ProximaNovaA-Regular.ttf"),
      "ProximaNovaA-Bold": require("../../../../assets/font/ProximaNovaA-Bold.ttf"),
    });
    const category2 = this.props.navigation.getParam("category2", null);
    this.setState({ category2 });
    console.log("my jobs category", category2);

    this.setState({ assetsLoaded: true });
    try {
      if (!global.myUID || !global.myProfileID)
        await global.timeout(200);
      if (!global.myUID || !global.myProfileID)
        await global.timeout(400);
      if (!global.myUID || !global.myProfileID)
        await global.timeout(500);
      if (!global.myUID || !global.myProfileID)
        await global.timeout(500);
      if (!global.myUID || !global.myProfileID)
        await global.timeout(600);
      if (!global.myUID || !global.myProfileID)
        await global.timeout(1000);
      if (!global.myUID || !global.myProfileID)
        await global.timeout(2000);
      if (!global.myUID || !global.myProfileID)
        await global.timeout(4000);
      if (!global.myUID || !global.myProfileID)
        await global.timeout(5000);
      if (!global.myUID || !global.myProfileID)
        await global.timeout(8000);
      if (!global.myUID || !global.myProfileID)
        await global.timeout(10000);
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID || !global.myProfileID)
        await global.timeout(10000);
      if (!global.myUID || !global.myProfileID)
        await global.timeout(100000);
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID) return;

      this.loadGroups();
    } catch (err) {
      if (global.reportError) {
        global.reportError(err, "catches", "homeJobs");
      }
    }
  }

  componentWillUnmount() {
    console.log("Component HomeJobsScreen will unmount");
    this.unsubscribeFirestore();
  }

  loadPromotions = async () => {
    try {
      if (!global.myUID)
        global.timeout(1000);
      if (!global.myUID)
        global.timeout(2000);
      if (!global.myUID)
        global.timeout(4000);
      if (!global.myUID)
        global.timeout(8000);
      const promotionsDoc = await firebase
        .firestore()
        .collection("ServerClientReadOnly")
        .doc("promotions")
        .get();
      const promotions = promotionsDoc.data();
      const banners = (global.myLocalCurrency == "GHS") ? promotions.ghBannersJobs : promotions.topBannersJobs
      const newPromotions = banners.map((item) => {
        item.randomValue = Math.random();
        return item;
      });
      newPromotions.sort((a, b) => {
        return b.randomValue - a.randomValue;
      });
      this.setState({
        // promotionProfileID: promotions.topBannersElectronics[0].profileID,
        // promotionImageURL: promotions.topBannersElectronics[0].imageURL,
        promotionBannerComponents: newPromotions,
      });

    } catch (err) {
      console.log("Error initiating database connection (Search-CC)", err);
    }
  };

  toggleCategory = () => {
    this.setState({
      showCategory: !this.state.showCategory,
    });
    console.log("toggle button handler: " + this.state.showCategory);
  };

  loadGroups = async (limit) => {
    // this.setState({ activityIndicatorAnimating: true });
    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "findGroups", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isWeb:true,
          // limit,
          type: "groupIDs",
          groupIDs: ["yhujXG86zX1ddsWqXmAF"],
          // coords: global.location.coords,
          myUID: global.myUID,
          idToken: global.idToken,
        }),
      });
      const r2 = await r.json();

      if (r2.msg === "SUCCESS") {
        this.setState({ forumGroup: r2.groups[0] });
      } else alert("Error loading data.");
    } catch (err) {
      if (global.reportError) {
        global.reportError(err, "catches", "homeJobsForum");
      }
    }
  };

  renderLoadingGroup = () => {
    return (
      <View
        style={{
          height: 40,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text style={{ color: "#bcb", fontSize: 12 }}>LOADING</Text>
      </View>
    );
  };

  render() {
    const sWidth = Dimensions.get("window").width;
    const sHeight = Dimensions.get("window").height;
    //console.log("my image", data.images["Phones"]);
    if (this.state.assetsLoaded) {
      //console.log("loaded");
      return (
        <KeyboardAwareScrollView
          showsVerticalScrollIndicator={false}
          enableOnAndroid={true}
          extraHeight={Platform.OS == "ios" ? 100 : 0}
          extraScrollHeight={Platform.OS !== "ios" ? 200 : 0}
          //resetScrollToCoords={{ x: 0, y: 0 }}
          // onScroll={(e) => {
          //   try {
          //     // if (e.nativeEvent.contentOffset.y >= 55)
          //     //   this.props.navigation.setParams({ hideTitle: true });
          //     // else this.props.navigation.setParams({ hideTitle: false });
          //   } catch (e) {
          //     console.warn("caught setParam/onScroll error")
          //     console.log("onScroll error", e)
          //   }
          // }}
          // scrollEventThrottle={1000}
          contentConatainerStyle={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LinearGradient
            colors={[
              "#4D3576",
              "#1C1B1B",
              "#1C1B1B",
              "#1C1B1B",
              "#1C1B1B",
              "#1C1B1B",
              "#1C1B1B",
              "#1C1B1B",
              "#1C1B1B",
            ]}
            style={{
              flex: 1,
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              height: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
          <StatusBar barStyle="light-content" />

          <View
            style={{
              // backgroundColor: "#1C1B1B",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                backgroundColor: "F5F5F5",
                borderBottomColor: "#555",
                alignItems: "center",
                justifyContent: "center",

                // borderBottomWidth: 1,
                ...Platform.select({
                  ios: {
                    height: Dimensions.get("window").height >= 800 ? 88 : 65,
                  },
                  android: { height: 85 },
                }),
              }}
            />

          </View>

          <View>
            {Array.isArray(this.state.promotionBannerComponents) && this.state.promotionBannerComponents.map(item => {
              return (
                <TouchableOpacity
                  key={item.profileID || item.url}
                  onPress={() => {
                    if (item.url) {
                      Linking.openURL(item.url)
                    }
                    else if (item.profileID) {
                      let handoverID = item.profileID;
                      this.props.navigation.navigate("meProfile", {
                        profileID: handoverID
                      });
                    }
                    global.reportStats("pressed_Jobs_Banner", (item.profileID || item.url), item.imageURL)
                  }}
                >
                  {item.imageURL && (
                    <Image
                      style={{ width: 358, height: 180, bottom: 15 }}
                      source={{
                        uri: item.imageURL
                      }}
                      resizeMode="contain"
                    />
                  )}
                </TouchableOpacity>
              );
            })}
          </View>

          <View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 8,
                margin: 8,
                marginTop: 25,
                marginBottom: 0
              }}
            >
              <Text
                style={{
                  color: "#F5F5F5",
                  fontWeight: "bold",
                  marginBottom: 0,
                  fontSize: 22,
                  fontFamily: "ProximaNovaA-Bold",
                }}
              >
                QuickJobs
              </Text>

              <TouchableOpacity
                onPress={async () => {
                  if (!global.myBusinessName) {
                    alert("Before you can post a job, you need to open a shop on Wakanda")
                    await global.timeout(500)
                    this.props.navigation.navigate("homeProfile")
                  }
                  else this.props.navigation.navigate("homePosts", { action: "CREATE", category1: "QuickJobs" })
                }}
              >
                <Text
                  style={{
                    color: "#A8CF45",
                    fontWeight: "bold",
                    marginBottom: 0,
                    fontSize: 18,
                    fontFamily: "ProximaNovaA-Bold",
                  }}
                >
                  POST JOB +
                  </Text>
              </TouchableOpacity>

            </View>
          </View>

          <JobsPreview
            navigation={this.props.navigation}
          />

          <View
            style={{
              backgroundColor: "#1C1B1B",
              borderRadius: 20,
              paddingHorizontal: 3,
              marginHorizontal: 3,
              paddingVertical: 12,
              marginVertical: 12,
              width: "95%",
              marginTop: 35,
              alignSelf: "center",
            }}
          >
            <View
              style={{
                paddinghorizontal: 15,
                marginHorizontal: 15,
                marginTop: 5,
                paddingBottom: 5,
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: 14,
                }}
              >
                Need a Job?
              </Text>
            </View>

            <TouchableOpacity
              onPress={() => {
                this.setState({ showModalWorker: true })
                global.reportStats("pressed_Jobs_ImAWorker")
              }}
              style={{
                backgroundColor: "#A8CF45",
                borderRadius: 10,
                padding: 12,
                paddingLeft: 20,
                margin: 15,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  //marginBottom: 10,
                  fontSize: 14,
                }}
              >
                I am a Worker
              </Text>

              {/* modal start */}

              <View
                style={{
                  // padding: 25,

                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Modal
                  animationType={"fade"}
                  transparent={true}
                  visible={this.state.showModalWorker}
                  onRequestClose={() => {
                    // Alert.alert("Modal has now been closed.");
                  }}
                >
                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#000000",
                      opacity: 1,

                      height: sHeight,
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: "#1C1B1B",
                        borderRadius: 15,

                        paddingVertical: 12,
                        marginVertical: 12,
                        width: "95%",
                        alignSelf: "center",
                        opacity: 2,
                        // alignItems: "center",
                        // justifyContent: "center",
                      }}
                    >
                      <View
                        style={{
                          bottom: 11,
                          backgroundColor: "#333333",
                          // width: "100%",
                          borderTopLeftRadius: 15,
                          borderTopRightRadius: 15,
                          alignItems: "center",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: 10,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            color: "#A8CF45",
                            fontFamily: "ProximaNovaA-Bold",
                            padding: 5,
                            margin: 5,
                          }}
                        >
                          Be A Worker
                        </Text>
                        <TouchableOpacity onPress={() => { this.setState({ showModalWorker: false }) }}>
                          <AntDesign
                            name="closecircleo"
                            size={20}
                            color="#A8CF45"
                            style={{
                              padding: 5,
                              margin: 5,
                              left: 19,
                              bottom: 19,
                            }}
                          />
                        </TouchableOpacity>
                      </View>

                      {Array.isArray(global.myJobQualifications) && global.myJobQualificationsStatus == "SEEKING" && global.myJobQualifications.length > 0 ? <View>
                        <View style={{ width: "55%", padding: 10, margin: 10 }}>
                          <Text
                            style={{
                              fontSize: 14,
                              color: "#F5F5F5",
                              fontFamily: "ProximaNovaA-Regular",
                            }}
                          >
                            Employers can already find you for these jobs:
                        </Text>
                        </View>

                        {global.myJobQualifications && global.myJobQualifications.map(q => {
                          return (
                            <Text
                              key={q}
                              style={{
                                color: "#F5F5F5",
                                fontSize: 14,
                                margin: 4,
                                marginLeft: 20,
                                fontFamily: "ProximaNovaA-Regular",
                              }}
                            >
                              {q}
                            </Text>)
                        })}
                        <TouchableOpacity
                          style={[styles.button, global.gStyles.buttonXL, { padding: 0, margin: 25 }]}
                          onPress={async () => {
                            this.setState({ showModalWorker: false })
                            await global.timeout(100)
                            this.props.navigation.navigate("homeProfileUpdate")
                          }}
                        >
                          <Text style={styles.buttontext}>Edit</Text>
                        </TouchableOpacity>
                      </View>

                        :

                        <View>
                          <View style={{ width: "80%", padding: 10, margin: 10 }}>
                            <Text
                              style={{
                                fontSize: 14,
                                color: "#F5F5F5",
                                fontFamily: "ProximaNovaA-Regular",
                              }}
                            >
                              Add your job qualifications to your profile so that employers can find you.
                    </Text>
                          </View>

                          <TouchableOpacity
                            style={[styles.button, global.gStyles.buttonXL, { padding: 0, margin: 25 }]}
                            onPress={async () => {
                              this.setState({ showModalWorker: false })
                              await global.timeout(100)
                              this.props.navigation.navigate("homeProfileUpdate")
                            }}
                          >
                            <Text style={styles.buttontext}>Edit Profile</Text>
                          </TouchableOpacity>
                        </View>}

                    </View>
                  </View>
                </Modal>

                <TouchableOpacity>
                  <AntDesign
                    name="right"
                    size={20}
                    color="#1C1B1B"
                  />
                </TouchableOpacity>
              </View>

              {/* modal end */}
              {/* <TouchableOpacity>
                <AntDesign
                  name="right"
                  size={20}
                  color="#1C1B1B"
                  // onPress={() => props.navigation.goBack()}
                />
              </TouchableOpacity> */}
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => {
                this.setState({ showModalProfessional: true })
                global.reportStats("pressed_Jobs_IOfferProfessionalServices")
              }}
              style={{
                backgroundColor: "#A8CF45",
                borderRadius: 10,
                padding: 12,
                paddingLeft: 20,
                marginTop: 0,
                margin: 15,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                // width: "90%",
              }}
            >
              <Text
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  //marginBottom: 10,
                  fontSize: 14,
                  textAlign: "center",
                }}
              >
                I Offer Professional Services
              </Text>

              {/* modal start */}

              <View
                style={{
                  // padding: 25,

                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Modal
                  animationType={"fade"}
                  transparent={true}
                  visible={this.state.showModalProfessional}
                  onRequestClose={() => {
                    //   Alert.alert("Modal has now been closed.");
                  }}
                >
                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#000000",
                      opacity: 1,

                      height: sHeight,
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: "#1C1B1B",
                        borderRadius: 15,

                        paddingVertical: 12,
                        marginVertical: 12,
                        width: "95%",
                        alignSelf: "center",
                        opacity: 2,
                        // alignItems: "center",
                        // justifyContent: "center",
                      }}
                    >
                      <View
                        style={{
                          bottom: 11,
                          backgroundColor: "#333333",
                          // width: "100%",
                          borderTopLeftRadius: 15,
                          borderTopRightRadius: 15,
                          alignItems: "center",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          // paddingLeft: 20,
                          padding: 10,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            color: "#A8CF45",
                            fontFamily: "ProximaNovaA-Bold",
                            padding: 5,
                            margin: 5,
                          }}
                        >
                          Offer Professional Services
                        </Text>
                        <TouchableOpacity onPress={() => { this.setState({ showModalProfessional: false }) }}>
                          <AntDesign
                            name="closecircleo"
                            size={20}
                            color="#A8CF45"
                            style={{
                              padding: 5,
                              margin: 5,
                              left: 19,
                              bottom: 19,
                            }}
                          />
                        </TouchableOpacity>
                      </View>

                      {!global.myBusinessName ? <View
                        style={{
                          alignItems: "flex-start",
                          justifyContent: "space-evenly",
                          // width: "90%",
                        }}
                      >
                        <View style={{ padding: 15, marigin: 15 }}>
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#F5F5F5",
                              fontFamily: "ProximaNovaA-Regular",
                            }}
                          >
                            1. Open a shop on Wakanda
                          </Text>
                        </View>

                        <View style={{ padding: 15, marigin: 15 }}>
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#F5F5F5",
                              fontFamily: "ProximaNovaA-Regular",
                            }}
                          >
                            2. <Text>Add services to your shop profile</Text>
                          </Text>
                        </View>
                        <View
                          style={{
                            padding: 15,
                            marigin: 15,
                            width: "80%",
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#F5F5F5",
                              fontFamily: "ProximaNovaA-Regular",
                              paddingRight: 2,
                            }}
                          >
                            3.
                          </Text>
                          <Text
                            style={{
                              fontSize: 16,
                              color: "#F5F5F5",
                              fontFamily: "ProximaNovaA-Regular",
                            }}
                          >
                            People who look for what you offer will be able to
                            find and contact you.
                          </Text>
                        </View>

                        <TouchableOpacity
                          onPress={async () => {
                            this.setState({ showModalProfessional: false })
                            await global.timeout(100)
                            this.props.navigation.navigate("homeProfileUpdate", { isNewBusiness: true })
                          }}
                          style={{
                            backgroundColor: "#A8CF45",
                            borderRadius: 10,
                            padding: 12,
                            margin: 15,

                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              color: "#1C1B1B",
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          >
                            OPEN A SHOP
                        </Text>
                        </TouchableOpacity>
                      </View>

                        :

                        <View
                          style={{
                            alignItems: "flex-start",
                            justifyContent: "space-evenly",
                            // width: "90%",
                          }}
                        >
                          <View style={{ padding: 15, marigin: 15 }}>
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#F5F5F5",
                                fontFamily: "ProximaNovaA-Regular",
                              }}
                            >
                              Your shop on Wakanda is live.
                          </Text>
                            <Text
                              style={{
                                fontSize: 16,
                                color: "#F5F5F5",
                                fontFamily: "ProximaNovaA-Regular",
                              }}
                            >
                              {(global.myServicesOffered && global.myServicesOffered.Professionals && global.myServicesOffered.Professionals.length > 0)
                                ? "People can find you for these professional services:"
                                : "Add your professional services so that people can find what you offer"}
                            </Text>
                          </View>
                          {global.myServicesOffered && global.myServicesOffered.Professionals && global.myServicesOffered.Professionals.map(service => {
                            return (
                              <Text
                                key={service}
                                style={{
                                  color: "#F5F5F5",
                                  fontSize: 16,
                                  margin: 4,
                                  marginLeft: 15,
                                  fontFamily: "ProximaNovaA-Regular",
                                }}
                              >
                                {service}
                              </Text>)
                          })}

                          <TouchableOpacity
                            onPress={async () => {
                              this.setState({ showModalProfessional: false })
                              await global.timeout(100)
                              this.props.navigation.navigate("homeProfileUpdate")
                            }}
                            style={{
                              backgroundColor: "#A8CF45",
                              borderRadius: 10,
                              padding: 12,
                              margin: 15,

                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                color: "#1C1B1B",
                                fontWeight: "bold",
                                fontSize: 14,
                              }}
                            >
                              Edit Services
                        </Text>
                          </TouchableOpacity>
                        </View>}
                    </View>
                  </View>
                </Modal>

                <AntDesign
                  name="right"
                  size={20}
                  color="#1C1B1B"
                />

                {/* modal end */}
              </View>

            </TouchableOpacity>
          </View>

          <View
            style={{
              backgroundColor: "#1C1B1B",
              borderRadius: 20,
              paddingHorizontal: 3,
              marginHorizontal: 3,
              paddingVertical: 12,
              marginVertical: 12,
              width: "95%",
              alignSelf: "center",
            }}
          >
            <View
              style={{
                paddinghorizontal: 15,
                marginHorizontal: 15,
                marginTop: 5,
                paddingBottom: 5,
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: 14,
                }}
              >
                Hiring?
              </Text>
            </View>

            <TouchableOpacity
              onPress={() => {
                this.props.navigation.navigate("homeProviderResults", { type: "FIND_WORKER" })
                global.reportStats("pressed_Jobs_FindAWorker")
              }}
              style={{
                backgroundColor: "#A8CF45",
                borderRadius: 10,
                padding: 12,
                paddingLeft: 20,
                margin: 15,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: "#1C1B1B",
                  fontWeight: "bold",
                  //marginBottom: 10,
                  fontSize: 14,
                }}
              >
                Find a Worker
              </Text>
              <AntDesign
                name="right"
                size={20}
                color="#1C1B1B"
              // onPress={() => props.navigation.goBack()}
              />
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => {
                this.props.navigation.navigate("homeSpecialRequest", {
                  category1: "Professionals"
                });
                global.reportStats("pressed_Jobs_FindProfessionalServices")
              }}
              style={{
                backgroundColor: "#A8CF45",
                borderRadius: 10,
                padding: 12,
                paddingLeft: 20,
                margin: 15,
                marginTop: 0,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: "#1C1B1B",
                  fontWeight: "bold",
                  //marginBottom: 10,
                  fontSize: 14,
                  textAlign: "center",
                }}
              >
                Find Professional Services
              </Text>
              <AntDesign
                name="right"
                size={20}
                color="#1C1B1B"
              // onPress={() => props.navigation.goBack()}
              />
            </TouchableOpacity>
          </View>

          {/* <View style={{ top: 50 }}>
            <CommunityPreview
              communityCategory={this.state.category2 || "Jobs"}
              navigation={this.props.navigation}
            />
          </View> */}

          <View
            style={{
              backgroundColor: "#1C1B1B",
              borderRadius: 20,
              padding: 10,
              margin: 10,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 5,
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontWeight: "bold",
                  marginBottom: 5,
                  fontSize: 14,
                }}
              >
                Jobs Forum
              </Text>
              {this.state.forumGroup && (
                <TouchableOpacity
                  onPress={() => {
                    this.props.navigation.navigate("homeGroupDetail", {
                      group: this.state.forumGroup,
                      title: " ",
                    });
                  }}
                >
                  <Text
                    style={{
                      marginBottom: 10,
                      color: "#A8CF45",
                      fontWeight: "bold",
                      fontSize: 14,
                      fontFamily: "ProximaNovaA-Bold",
                    }}
                  >
                    See All
                  </Text>
                </TouchableOpacity>
              )}
            </View>
            {/* {this.state.forumGroup && <View style={{ justifyContent: "space-between", padding: 5 }}>
                <Text style={{ color: "#faa", fontSize: 12 }}>
                  ⚠ No selling ⚠ Remain polite
                </Text>
                <Text style={{ color: "#faa", fontSize: 12 }}>
                  ⚠ Medical advice only by verified doctors
                </Text>
              </View>} */}
            {this.state.forumGroup ? (
              <ChatGroup
                backgroundColor="#1C1B1B"
                navigation={this.props.navigation}
                group={this.state.forumGroup}
              />
            ) : (
                this.renderLoadingGroup()
              )}
          </View>

          <TouchableOpacity
            style={{}}
            onPress={() => {
              Linking.openURL("https://www.quickjobs.ng/blog")
              global.reportStats("pressed_Jobs_QuickJobsBlog")
            }}
          >
            {/* <WebView
              originWhitelist={["*"]}
              style={{ width: sWidth, height: sWidth * 8, marginVertical: 40 }}
              source={{ uri: "https://www.quickjobs.ng/blog" }}
              javaScriptEnabled={true}
              domStorageEnabled={true}
              useWebKit={true}
              scrollEnabled={false}
            /> */}
          </TouchableOpacity>
        </KeyboardAwareScrollView>
      );
    } else {
      return (
        <View style={styles.container}>
          <ActivityIndicator />
          <StatusBar barStyle="default" />
        </View>
      );
    }
  }
}


import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  Linking,
  Platform,
  Dimensions,
  ActivityIndicator,
  KeyboardAvoidingView,
  Image,
  Alert,
  Keyboard,
  TextInput
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import Moment from "react-moment";
import { WebView } from "react-native-webview";
//import * as Permissions from 'expo-permissions'
import { Camera } from 'expo-camera';
import { Audio } from 'expo-av';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import HealthChat from "./HealthChat"
import HealthFile from "./HealthFileScreen"
import DoctorNotes from "./DoctorNotes"
import { DoctorProfile } from "./HealthRecordScreen"

//import { Audio } from 'expo-av';


export default class DoctorConsultScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    return {
      tabBarVisible: false,
      headerTransparent: true,
      title: navigation.getParam("title", "Consultation") || "Consultation",
      headerTintColor: navigation.getParam("headerTintColor", "black") || "black",
      headerTitleStyle: {
        fontWeight: "bold",
        color: "red",
      }
    }
  };

  state = {
    screen: "PERMISSIONS", // can be "WAITING", "CONSULTATION",  "PERMISSIONS",
    statusCamera: null,
    statusRecording: null,
    permissionsLoaded: false,

    age: "",
    gender: "",
    complaint: "",
    genotype: "",
    bloodGroup: "",
    hasHypertension: false,
    hasDiabetes: false,
    hasAsthma: false,
    hasOtherPreconditions: false,
    otherPreconditions: "",
    allergies: "",

    patientsWaiting: [],
    doctorsOnline: [],
    activeSessions: [],
    activeRecordID: null,
    activeRecordIsTextChat: false,
    activeFileID: null,
    activeConsultationURL: null,
    waitingLoaded: false,
    roomStatus: "CLOSED",
    aaRoomStatus: false,
    doctorStatus: "INACTIVE",
    aaDoctorStatus: false,

    healthChatNotifications: global.healthChatNotifications || {},
    stateCounter: 100,

    showChat: false,
    showFile: false,
    showNotes: false,
    notificationCount: 0,

    keyboardHeight: 0,
    keyboardShown: false
  };

  async componentDidMount() {
    this.checkPrerequisites()
    this.loadRooms()
    this.loadNotifications()

    await global.timeout(1000)
    this.keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      this._keyboardDidShow
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      this._keyboardDidHide
    );
  }

  componentWillUnmount() {
    if (this.keyboardDidShowListener) this.keyboardDidShowListener.remove();
    if (this.keyboardDidHideListener) this.keyboardDidHideListener.remove();
  }

  _keyboardDidShow = async e => {
    if (Platform.OS == "ios") this.setState({ keyboardHeight: e.endCoordinates.height - 45 });
    this.setState({ keyboardShown: true })
  };

  _keyboardDidHide = () => {
    this.setState({ keyboardHeight: 0 });
    this.setState({ keyboardShown: false })
  };

  loadRooms = async () => {
    try {
      await firebase
        .firestore()
        .collection("Health")
        .doc("data")
        .collection("HealthRooms")
        .doc("+NGGeneral")
        .onSnapshot(this.updateRoom)
    } catch (error) {
      global.warn(error, "Error 922")
    }
  }

  updateRoom = async (roomDoc) => {
    try {
      if (!roomDoc) return
      const room = roomDoc.data()
      if (!room) return;
      // let myActiveSessions = []
      // const keys = Object.keys(room.activeSessions)
      // keys.forEach(key => {
      //   const sess = room.activeSessions[key]
      //   if (sess.doctorProfileID == global.myHealthProfileID) {
      //     sess.healthFileID = key
      //     myActiveSessions.push(sess)
      //   }
      // })
      let activeSessions = []
      const keys = Object.keys(room.activeSessions)
      keys.forEach(key => {
        const sess = room.activeSessions[key]
        sess.healthFileID = key
        if (sess.doctorProfileID == global.myHealthProfileID) activeSessions.push(sess)
      })
      const doctorsOnlineFiltered = room.doctorsOnline.filter(d => { return d.healthProfileID == global.myHealthProfileID })
      const doctorStatus = doctorsOnlineFiltered.length > 0 ? "ACTIVE" : "INACTIVE"
      this.setState({
        patientsWaiting: room.patientsWaiting, doctorsOnline: room.doctorsOnline,
        activeSessions, waitingLoaded: true, roomStatus: room.roomStatus, doctorStatus
      })
    } catch (error) {
      global.warn(error, "923")
    }
  }

  changeRoomStatus = async () => {
    try {
      this.setState({ aaRoomStatus: true })
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "startConsultation", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          healthRoomID: "+NGGeneral",
          action: "changeRoomStatus",
          roomStatus: this.state.roomStatus == "OPEN" ? "CLOSED" : "OPEN",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ aaRoomStatus: false })
      if (r2.msg == "SUCCESS") {
      } else {
        this.props.navigation.goBack()
        await global.timeout(100)
        alert("Error while changing room status")
      }
    } catch (e) {
      this.props.navigation.goBack()
      console.log(e)
      await global.timeout(100)
      alert("Error while changing room status.")
    }
  }
  changeDoctorStatus = async () => {
    try {
      this.setState({ aaDoctorStatus: true })
      const r = await fetch(global.cloudFunctionURL + "startConsultation", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          healthRoomID: "+NGGeneral",
          action: "changeDoctorStatus",
          doctorStatus: this.state.doctorStatus == "ACTIVE" ? "INACTIVE" : "ACTIVE",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ aaDoctorStatus: false })
      if (r2.msg == "SUCCESS") {
      } else {
        this.props.navigation.goBack()
        await global.timeout(100)
        alert("Error while changing doctor status")
      }
    } catch (e) {
      this.props.navigation.goBack()
      console.log(e)
      await global.timeout(100)
      alert("Error while changing doctor status.")
    }
  }

  loadNotifications = async () => {
    try {
      if (!global.myHealthFileID) await global.timeout(7000)
      if (!global.myUID) await global.timeout(7000)
      if (!global.myHealthFileID) return;
      if (!global.myUID) return;
      await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .collection("Refs")
        .doc("healthChatRefs")
        .onSnapshot(this.updateNotifications)
    } catch (error) {
      global.warn(error, "9fD")
    }
  }

  updateNotifications = async (refDoc) => {
    try {
      if (!refDoc) return
      const n = refDoc.data()
      if (!n) return;

      this.setState({ healthChatNotifications: n, stateCounter: (this.state.stateCounter + 1) })
      global.healthChatNotifications = n
    } catch (error) {
      global.warn(error, "10fD")
    }
  }

  renderConsultation() {
    const sWidth = Dimensions.get("window").width
    const sHeight = Dimensions.get("window").height

    return (<View style={{ backgroundColor: !this.state.activeRecordIsTextChat ? "#333" : "#368", height: "100%", width: "100%" }}>
      {!this.state.activeRecordIsTextChat && <WebView
        originWhitelist={['*']}
        style={{ width: sWidth, height: sHeight - 65 }}
        source={{ uri: this.state.activeConsultationURL || "" }}
        javaScriptEnabled={true}
        domStorageEnabled={true}
        allowsInlineMediaPlayback={true}
        allowsFullscreenVideo={true}
        allowUniversalAccessFromFileURLs={true}
        mediaPlaybackRequiresUserAction={false}
        useWebKit={true}
      />}
      {/* //  TextChat  // */}
      {!!this.state.activeRecordID && <HealthChat recordID={this.state.activeRecordID}
        setNotifications={(n) => { this.setState({ notificationCount: n }) }}
        visible={this.state.showChat} isTextChat={this.state.activeRecordIsTextChat} />}
      {!!this.state.activeRecordID && <View style={{ position: "absolute", bottom: this.state.showChat ? (this.state.keyboardHeight || 0) : 0, left: 0, width: 65, height: 65, alignItems: "center", flexDirection: "row" }}>
        <TouchableOpacity onPress={() => {
          if (this.state.showChat) this.setState({ showChat: false, keyboardHeight: 0 });
          else this.setState({ showChat: true, showFile: false, showNotes: false, notificationCount: 0 });
        }}>
          <View style={{ height: 50, width: 50, borderRadius: 25, backgroundColor: this.state.showChat ? "red" : "white", justifyContent: "center", alignItems: "center", borderWidth: 0.5, borderColor: "#000" }}>
            <Image style={{ width: 25, height: 25, tintColor: this.state.showChat ? "white" : "red" }} source={require("../../../images/tabNavigator/Messages.png")} />
            {this.state.notificationCount >= 1 && (
              <View
                style={{
                  backgroundColor: "red", height: 21.875, width: 27.5, borderRadius: 11, alignSelf: "flex-end", alignItems: "center", marginTop: -36, marginRight: -8, marginLeft: 10, marginBottom: 10, borderWidth: 0.5, borderColor: "#fff", justifyContent: "center"
                }}
              >
                <Text style={{ color: "white", fontWeight: "bold", fontSize: 13 }}>
                  {this.state.notificationCount}
                </Text>
              </View>
            )}
          </View>
        </TouchableOpacity>
      </View>}

      {/* //  File  // */}
      {this.state.activeFileID && <View style={{ position: "absolute", bottom: 100, left: 10, width: this.state.showFile ? (sWidth - 20) : 0, height: 500, alignItems: "stretch" }}>
        <HealthFile healthFileID={this.state.activeFileID} isPreview
          //increaseNotifications={(n) => { this.setState({ notificationCount: (this.state.notificationCount + n) }) }}
          hide={!this.state.showFile} navigation={this.props.navigation} />
      </View>}
      {this.state.activeFileID && <View style={{ position: "absolute", bottom: 55, left: 0, width: 65, height: 65, alignItems: "center", flexDirection: "row" }}>
        <TouchableOpacity onPress={() => {
          if (this.state.showFile) this.setState({ showFile: false });
          else this.setState({ showFile: true, showChat: false, showNotes: false, notificationCount: 0 });
        }}>
          <View style={{ height: 50, width: 50, borderRadius: 25, backgroundColor: this.state.showFile ? "red" : "white", justifyContent: "center", alignItems: "center", borderWidth: 0.5, borderColor: "#000" }}>
            <Image style={{ width: 27, height: 22, tintColor: this.state.showFile ? "white" : "red" }} source={require("../../../images/icons/File.png")} />
            {/* {this.state.notificationCount >= 1 && (
              <View style={{ backgroundColor: "red", height: 21.875, width: 27.5, borderRadius: 11, alignSelf: "flex-end", alignItems: "center", marginTop: -36, marginRight: -8, marginLeft: 10, marginBottom: 10, borderWidth: 0.5, borderColor: "#fff", justifyContent: "center"}} >
                <Text style={{ color: "white", fontWeight: "bold", fontSize: 13 }}>{this.state.notificationCount}</Text>
              </View>
            )} */}
          </View>
        </TouchableOpacity>
      </View>}

      {/* //  Doctor Notes  // */}
      {!!this.state.activeRecordID && <DoctorNotes parentRecordID={this.state.activeRecordID}
        visible={this.state.showNotes} />}
      {!!this.state.activeRecordID && <View style={{ position: "absolute", bottom: this.state.showNotes ? (this.state.keyboardHeight || 111) : 111, left: 0, width: 65, height: 65, alignItems: "center", flexDirection: "row" }}>
        <TouchableOpacity onPress={() => {
          if (this.state.showNotes) this.setState({ showNotes: false, keyboardHeight: 0 });
          else this.setState({ showNotes: true, showFile: false, showChat: false });
        }}>
          <View style={{ height: 50, width: 50, borderRadius: 25, backgroundColor: this.state.showNotes ? "red" : "white", justifyContent: "center", alignItems: "center", borderWidth: 0.5, borderColor: "#000" }}>
            <Image style={{ width: 25, height: 25, tintColor: this.state.showNotes ? "white" : "red" }} source={require("../../../images/icons/Note.png")} />
            {/* {this.state.notificationCount >= 1 && (
              <View style={{ backgroundColor: "red", height: 21.875, width: 27.5, borderRadius: 11, alignSelf: "flex-end", alignItems: "center", marginTop: -36, marginRight: -8, marginLeft: 10, marginBottom: 10, borderWidth: 0.5, borderColor: "#fff", justifyContent: "center"}}>
                <Text style={{ color: "white", fontWeight: "bold", fontSize: 13 }}>
                  {this.state.notificationCount}
                </Text>
              </View>
            )} */}
          </View>
        </TouchableOpacity>
      </View>}

    </View>)
  }

  renderWaiting() {
    const sWidth = Dimensions.get("window").width
    const sHeight = Dimensions.get("window").height
    const nOfDoctorsOnline = Array.isArray(this.state.doctorsOnline) ? this.state.doctorsOnline.length : 0

    if (!this.state.waitingLoaded) return (<View style={{ flexDirection: "row", height: "100%", width: '100%', justifyContent: "center", alignItems: "center", backgroundColor: "#69c" }}>
      <ActivityIndicator size="small" color="#444" style={{ marginRight: 8 }} />
      <Text style={{ color: "#444" }}>Loading Waiting Room...</Text>
    </View>)

    return (<View style={{ backgroundColor: "#368", height: "100%", width: "100%" }}>
      <View style={{ borderColor: "#312", borderRadius: 0, borderWidth: 0, margin: 0 }}>
        <Image style={{ width: "100%", alignSelf: "center", height: 150, justifyContent: "flex-end", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
          source={require("../../../images/images/DD.jpeg")} />
        <View style={{ marginTop: -50, height: 50, backgroundColor: "rgba(255,0,0,0.75)", paddingLeft: 5 }} >
          <Text style={{ color: "white", fontSize: 20, fontWeight: "bold" }}>Waiting Room</Text>
          <Text style={{ fontSize: 16, fontWeight: "bold" }}>Doctor's View</Text>
        </View>
        <ScrollView>
          <TouchableOpacity onPress={() => { this.changeRoomStatus() }} style={{ paddingLeft: 10, paddingTop: 10, justifyContent: "center", }}>
            <Switch animating={this.state.aaRoomStatus} selected={this.state.roomStatus == "OPEN"} text={this.state.roomStatus == "OPEN" ? "Room open, accepting further patients          " : "Room closed for furhter patients          "} />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => { this.changeDoctorStatus() }} style={{ paddingLeft: 10, justifyContent: "center", }}>
            <Switch animating={this.state.aaDoctorStatus} selected={this.state.doctorStatus == "ACTIVE"} text={this.state.doctorStatus == "ACTIVE" ? "I am attending to patients          " : "I'm not attending to patients          "} />
          </TouchableOpacity>

          {(Array.isArray(this.state.activeSessions) && this.state.activeSessions.length == 0) && <View style={{ height: 100, justifyContent: "center", padding: 5, flexDirection: "row", alignItems: "center" }} >
            <Text style={{ fontSize: 18, color: "white" }}>   No active consultation</Text>
          </View>}
          {(Array.isArray(this.state.activeSessions) && this.state.activeSessions.length > 0) && <View><Text style={{ color: "white", fontSize: 16, marginTop: 25, fontWeight: "bold" }}>Active Sessions:</Text></View>}
          {(Array.isArray(this.state.activeSessions) && this.state.activeSessions.length > 0) && this.state.activeSessions.map(sess => { return (<ActiveSession key={sess.healthFileID + this.state.stateCounter} session={sess} startConsultation={this.startConsultation} navigation={this.props.navigation} healthChatNotifications={this.state.healthChatNotifications} />) })}
          {this.state.patientsWaiting.length == 0 && <View style={{ height: 100, justifyContent: "center", padding: 5, flexDirection: "row", alignItems: "center" }} >
            <Text style={{ fontSize: 36, color: "#8f8" }}>{"✓"}</Text>
            <Text style={{ fontSize: 18, color: "white" }}>No patient waiting</Text>
          </View>}
          {this.state.patientsWaiting.length > 0 && <View><Text style={{ color: "white", fontSize: 16, marginTop: 25, fontWeight: "bold" }}>Patient Queue:</Text></View>}
          {this.state.patientsWaiting.length > 0 && this.state.patientsWaiting.map(pw => { return (<PatientWaiting key={pw.healthFileID + this.state.stateCounter} patient={pw} startConsultation={this.startConsultation} navigation={this.props.navigation} healthChatNotifications={this.state.healthChatNotifications} />) })}

          {this.state.doctorsOnline.length > 0 && <View>
            <Text style={{ color: "white", fontSize: 16, marginTop: 25, marginBottom: 0, fontWeight: "bold" }}>Doctors Online:</Text>
            {this.state.doctorsOnline.map(doc => {
              return (<View key={doc.healthProfileID} style={{ padding: 4, margin: 4, alignSelf: "stretch", backgroundColor: "#ccc", borderRadius: 10 }}>
                <DoctorProfile healthProfileID={doc.healthProfileID} navigation={this.props.navigation} />
              </View>)
            })}
          </View>}
          <View style={{ height: 200 }} />
        </ScrollView>
      </View>
    </View>)
  }

  openTC = () => {
    Linking.openURL(global.termsConditionsLink || "https://www.african-founders.com/terms-and-conditions")
  }

  checkPrerequisites = async () => {
    try {
      if (!global.amDoctor) {
        alert("Access issue")
        await global.timeout(50)
        return this.props.navigation.goBack()
      }
      if (!global.myHealthProfileID) {
        alert("Please create a doctor profile first")
        await global.timeout(50)
        return this.props.navigation.goBack()
      }

      this.checkPermissions()
    } catch (e) {
      global.warn(e, 'checkTerms errory');
    }
  }
  checkPermissions = async () => {
    try {
      const a = await Camera.getPermissionsAsync()
      const b = await Audio.getPermissionsAsync()
      this.setState({ statusCamera: a.status, statusRecording: b.status, permissionsLoaded: true })
      //console.log({ a, b })
      if (a.status !== "granted" || b.status !== "granted") this.setState({ screen: "PERMISSIONS" })
      else {
        this.props.navigation.setParams({ title: " " })
        if (!this.state.waitingLoaded) this.setState({ screen: "LOADING" })
        while (!this.state.waitingLoaded) await global.timeout(200)
        this.setState({ screen: "WAITING" })
      }
    } catch (e) {
      global.warn(e, 'checkPermissions errory');
    }
  }
  enableCameraPermissions = async () => {
    try {
      const { status } = await Camera.requestPermissionsAsync();
      if (status !== "granted")
        alert("Enable Camera permissions for Wakanda in the system settings of your phone")
      this.checkPermissions()
    } catch (e) {
      global.warn(e, 'enableCameraPermissions errorx');
    }
  }
  enableRecordingPermissions = async () => {
    try {
      // const { status } = await Permissions.askAsync(Permissions.AUDIO_RECORDING);
      const { status } = await Audio.requestPermissionsAsync();
      if (status !== "granted")
        alert("Enable Microphone permissions for Wakanda in the system settings of your phone")
      this.checkPermissions()
    } catch (e) {
      global.warn(e, 'enableRecordingPermissions errorx');
    }
  }

  startConsultation = async (action, healthFileID, sessionID, isTextChat) => {
    if (healthFileID == global.myHealthFileID) return alert("You cannot consult yourself")
    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "startConsultation", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          healthRoomID: "+NGGeneral",
          healthFileID: healthFileID,
          action,
          // isIOS: true,
          isIOS: (Platform.OS == "ios"),
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {

        if (action == "start" || action == "re-join") {
          const tt = r2.token
          const tr = r2.sessionID || sessionID // comes from r2 if "start"
          const wc = r2.wc
          const is1Or2 = Platform.OS === "ios" ? "2" : "1"
          this.setState({ activeRecordID: wc.substring(20, 40), activeFileID: healthFileID, activeRecordIsTextChat: isTextChat })
          const activeConsultationURL = "https://afdoctordial.web.app/" + tt + "/" + tr + "/" + wc + "/" + is1Or2
          if (Platform.OS === "ios" && !isTextChat) {
            Linking.openURL(activeConsultationURL)
          }
          else {
            this.props.navigation.setParams({ headerTintColor: "white" })
            this.setState({ screen: "CONSULTATION", activeConsultationURL })
          }
          return;
        }
      } else alert("Currently not possible. Try again")
    } catch (e) {
      alert("System error. Check your network connection")
      global.warn(e, 'startConsultation errorx');
    }
  }






  renderPermissions() {

    if (!this.state.permissionsLoaded) return (<View style={{ flexDirection: "row", height: "100%", width: '100%', justifyContent: "center", alignItems: "center", }}>
      <ActivityIndicator size="small" color="#444" style={{ marginRight: 8 }} />
      <Text style={{ color: "#444" }}>Loading Permissions...</Text>
    </View>)
    return (<View style={{ backgroundColor: "#fff", height: "100%", width: "100%", paddingTop: 110 }}>
      <View style={{ borderColor: "#999", borderWidth: 0, margin: 9, marginHorizontal: 15, borderRadius: 16, padding: 15, paddingBottom: 20, paddingTop: 20, overflow: "hidden" }}>
        <Text style={{ color: "#000", fontSize: 24, fontWeight: "bold", marginBottom: 20 }}>Permissions</Text>
        <Text style={{ color: "#000", fontSize: 16, fontWeight: "bold" }}>For video consultation to work, we need to access microphone and camera</Text>

        <View style={{ height: 50 }} />
        {this.state.statusRecording == "granted" ?
          <NumberedPoint n={"✓"} style={{ color: "#000", fontSize: 16, marginTop: 40 }}>
            Microphone permission granted</NumberedPoint>
          : <TouchableOpacity onPress={() => { this.enableRecordingPermissions() }}>
            <View style={[global.gStyles.buttonXL, {
              backgroundColor: "red", paddingVertical: 2.5, paddingHorizontal: 22, marginTop: 40,
              flexDirection: "row", width: "87%", alignSelf: "center", alignItems: "center"
            }]} >
              <Text style={{ fontSize: 16, fontWeight: "bold", color: "#fff" }}>Allow Microphone</Text>
            </View>
          </TouchableOpacity>}
        {this.state.statusCamera == "granted" ?
          <NumberedPoint n={"✓"} style={{ color: "#000", fontSize: 16, marginTop: 40 }}>
            Camera permission granted</NumberedPoint>
          : <TouchableOpacity onPress={() => { this.enableCameraPermissions() }}>
            <View style={[global.gStyles.buttonXL, {
              backgroundColor: "red", paddingVertical: 2.5, paddingHorizontal: 22, marginTop: 40,
              flexDirection: "row", width: "87%", alignSelf: "center", alignItems: "center"
            }]} >
              <Text style={{ fontSize: 16, fontWeight: "bold", color: "#fff" }}>Allow Camera</Text>
            </View>
          </TouchableOpacity>}
      </View>
    </View >)
  }

  renderLoading() {
    return (<View style={{ backgroundColor: "#fff", height: "100%", width: "100%", alignItems: "center", justifyContent: "center" }}>
      <Image style={{ borderRadius: 17, height: 100, width: 200, marginBottom: -65 }}
        source={require("../../../images/images/DD.jpeg")} />
      <ActivityIndicator size="large" color="white" animating={true} />
      <Text style={{ color: "#888", fontSize: 18, fontStyle: "italic", marginTop: 50 }}>Just a second...</Text>
    </View>)
  }

  render() {
    if (this.state.screen == "CONSULTATION") return this.renderConsultation()
    if (this.state.screen == "WAITING") return this.renderWaiting()
    if (this.state.screen == "PERMISSIONS") return this.renderPermissions()
    if (this.state.screen == "LOADING") return this.renderLoading()
    return (<View />)
  }
}


class NumberedPoint extends PureComponent {
  render() {
    return (
      <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
        <Text style={[this.props.style, { marginRight: 10 }]}>{this.props.n}</Text>
        <Text style={[this.props.style, { marginRight: 15 }]}>{this.props.children}</Text>
      </View>
    );
  }
}

class MyTextInput extends PureComponent {
  render() {
    return (
      <TextInput
        onChangeText={this.props.onChangeText}
        value={this.props.value}
        placeholder={""}
        placeholderTextColor={"#999"}
        style={{ fontSize: 16, borderColor: "#aaa", borderRadius: 15, borderBottomWidth: 0.6, borderRadius: 0.3, backgroundColor: "white", minHeight: this.props.minHeight || 30, paddingVertical: 5, justifyContent: "center" }}
        multiline
        maxLength={20000}
        underlineColorAndroid="transparent"
        keyboardShouldPersistTaps={"always"}
        keyboardDismissMode={"on-drag"}
        autoCompleteType={"off"}
      //clearButtonMode={"always"}
      />
    );
  }
}

class PatientWaiting extends Component {
  state = { loading: false, name: " ✧ ✧ ✧ ✧ ✧ ✧", pictureURL: null, generalInformation: { age: "✧", gender: "✧ ✧" }, complaint: " ", isTextChat: false }
  async componentDidMount() {
    try {
      if (this.props.patient.name) this.setState({ name: this.props.patient.name })
      const wRecordDoc = firebase
        .firestore()
        .collection("Health")
        .doc("data")
        .collection("HealthRecords")
        .doc(this.props.patient.recordID)
        .get()
      const fileDoc = await firebase
        .firestore()
        .collection("Health")
        .doc("data")
        .collection("HealthFiles")
        .doc(this.props.patient.healthFileID)
        .get()
      const file = fileDoc.data()
      this.setState({ generalInformation: file.generalInformation })
      const recordDoc = await wRecordDoc
      const record = recordDoc.data()
      this.setState({ complaint: record.data.complaint || "-", isTextChat: !!(record.isTextChat) })
      const profileDoc = await firebase
        .firestore()
        .collection("Profiles")
        .doc(record.patientProfileID)
        .get()
      const profile = profileDoc.data()
      this.setState({ pictureURL: profile.profilePictureURL, name: profile.profileName || "--" })
    } catch (error) {
      global.warn(error, "sdf2");
    }
  }
  render() {
    // let waitingSinceDate = new Date(1970, 0, 1);
    // if (this.props.patient && this.props.patient.waitingSince) waitingSinceDate = this.props.patient.waitingSince.toDate()
    const waitingSinceDate = (this.props.patient && global.stampToDate) && global.stampToDate(this.props.patient.waitingSince)
    const unreadMessages = this.props.healthChatNotifications ? (this.props.healthChatNotifications.doctor?.[this.props.session?.recordID] || 0) : 0

    // const waitingSinceDate = new Date(1970, 0, 1);
    // if (this.props.patient.waitingSince && this.props.patient.waitingSince.seconds) waitingSinceDate.setSeconds(this.props.patient.waitingSince.seconds);
    // else if (this.props.patient.waitingSince && this.props.patient.waitingSince._seconds) waitingSinceDate.setSeconds(this.props.patient.waitingSince._seconds);

    return (<View style={{ minHeight: 100, padding: 4, margin: 4, alignSelf: "stretch", backgroundColor: "#fff", borderRadius: 10 }}>
      <View style={{ minHeight: 55, alignSelf: "stretch", flexDirection: "row" }}>
        <View style={{ height: 45, width: 25, marginRight: 8, justifyContent: "center" }}>{this.state.isTextChat
          ? (<View><Image style={{ width: 24, height: 24, tintColor: "#888" }} source={require("../../../images/tabNavigator/Messages.png")} />
            {unreadMessages >= 1 && (
              <View style={{ backgroundColor: "red", height: 21.875, width: 27.5, borderRadius: 11, alignSelf: "flex-end", alignItems: "center", marginTop: -36, marginRight: -8, marginLeft: 10, marginBottom: 10, borderWidth: 0.5, borderColor: "#fff", justifyContent: "center" }}>
                <Text style={{ color: "white", fontWeight: "bold", fontSize: 14 }}>{unreadMessages}</Text>
              </View>)}
          </View>)
          : (<Text style={{ fontSize: 25 }}>📺</Text>)}</View>
        <View style={{ minHeight: 55, alignSelf: "stretch", flexDirection: "row" }}>
          {this.state.pictureURL ? <Image
            style={{ width: 35, height: 35, borderRadius: 17.5, margin: 5 }}
            source={{ uri: this.state.pictureURL }}
          /> : <View style={{ width: 35, height: 35, borderRadius: 17.5, margin: 5, backgroundColor: "gray" }} />}
          <View>
            <Text style={{ marginBottom: 2, fontWeight: "bold" }}>{this.state.name} ({this.state.generalInformation.age}, {this.state.generalInformation.gender})</Text>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: 12, marginBottom: 3 }}>🕑 <Moment fromNow ago element={Text}>{waitingSinceDate}</Moment></Text>
            </View>
            <Text style={{ fontSize: 10, fontStyle: "italic", marginRight: 44 }} numberOfLines={5}>{this.state.complaint}</Text>
          </View>
        </View>
      </View>
      {!this.state.loading ? <View style={{ height: 35, flexDirection: "row", justifyContent: "space-between", alignSelf: "stretch" }}>
        <TouchableOpacity
          style={{
            height: 25, borderRadius: 13, margin: 5, alignItems: "center", justifyContent: "center",
            paddingHorizontal: 10, borderColor: "#855", borderWidth: 0.5,
          }}
          onPress={() => { this.props.navigation.navigate("healthFile", { healthFileID: this.props.patient.healthFileID }) }}
        >
          <Text style={{ color: "#855", fontSize: 12 }}>See File</Text>
        </TouchableOpacity>
        <View style={{ height: 35, flexDirection: "row", justifyContent: "flex-end" }}>
          {/* <TouchableOpacity
          style={{
            height: 25, borderRadius: 13, margin: 5, alignItems: "center", justifyContent: "center",
            paddingHorizontal: 10, backgroundColor: "red",
          }}
          onPress={async () => { this.setState({ loading: true }); await this.props.startConsultation("start", this.props.patient.healthFileID,null); this.setState({ loading: false }); }}
        >
          <Text style={{ color: "white", fontSize: 12 }}>Remove</Text>
        </TouchableOpacity> */}
          <TouchableOpacity
            style={{
              height: 25, borderRadius: 13, margin: 5, alignItems: "center", justifyContent: "center",
              paddingHorizontal: 10, backgroundColor: "red",
            }}
            onPress={async () => { this.setState({ loading: true }); await this.props.startConsultation("start", this.props.patient.healthFileID, null, this.state.isTextChat); this.setState({ loading: false }); }}
          >
            <Text style={{ color: "white", fontSize: 12 }}>Start Consultation</Text>
          </TouchableOpacity>
        </View>
      </View>
        : <ActivityIndicator size="small" color="#444" style={{ margin: 5 }} />}
    </View >);
  }
}

class ActiveSession extends Component {
  state = { loading: false, name: " ✧ ✧ ✧ ✧ ✧ ✧", pictureURL: null, generalInformation: { age: "✧", gender: "✧ ✧" }, complaint: " ", isTextChat: false }
  async componentDidMount() {
    try {
      if (this.props.session.name) this.setState({ name: this.props.session.name })
      const wRecordDoc = firebase
        .firestore()
        .collection("Health")
        .doc("data")
        .collection("HealthRecords")
        .doc(this.props.session.recordID)
        .get()
      const fileDoc = await firebase
        .firestore()
        .collection("Health")
        .doc("data")
        .collection("HealthFiles")
        .doc(this.props.session.healthFileID)
        .get()
      const file = fileDoc.data()
      this.setState({ generalInformation: file.generalInformation })
      const recordDoc = await wRecordDoc
      const record = recordDoc.data()
      this.setState({ complaint: record.data.complaint || "-", isTextChat: !!(record.isTextChat) })
      const profileDoc = await firebase
        .firestore()
        .collection("Profiles")
        .doc(record.patientProfileID)
        .get()
      const profile = profileDoc.data()
      this.setState({ pictureURL: profile.profilePictureURL, name: profile.profileName || "--" })
    } catch (error) {
      global.warn(error, "ssdfl")
    }
  }
  render() {
    // let waitingSinceDate = new Date(1970, 0, 1);
    // if (this.props.session && this.props.session.timeCreated) waitingSinceDate = this.props.session.timeCreated.toDate()
    const waitingSinceDate = (this.props.session && global.stampToDate) && global.stampToDate(this.props.session.timeCreated)
    const patientJoinedDate = (this.props.session && global.stampToDate) && global.stampToDate(this.props.session.timePatientJoined)
    const unreadMessages = this.props.healthChatNotifications ? (this.props.healthChatNotifications.doctor?.[this.props.session?.recordID] || 0) : 0

    // let patientJoinedDate = new Date(1970, 0, 1);
    // if (this.props.session && this.props.session.timePatientJoined) patientJoinedDate = this.props.session.timePatientJoined.toDate()
    // const waitingSinceDate = new Date(1970, 0, 1);
    // if (this.props.session.timeCreated && this.props.session.timeCreated.seconds) waitingSinceDate.setSeconds(this.props.session.timeCreated.seconds);
    // else if (this.props.session.timeCreated && this.props.session.timeCreated._seconds) waitingSinceDate.setSeconds(this.props.session.timeCreated._seconds);
    // const patientJoinedDate = new Date(1970, 0, 1);
    // if (this.props.session.timePatientJoined && this.props.session.timePatientJoined.seconds) patientJoinedDate.setSeconds(this.props.session.timePatientJoined.seconds);
    // else if (this.props.session.timePatientJoined && this.props.session.timePatientJoined._seconds) patientJoinedDate.setSeconds(this.props.session.timePatientJoined._seconds);

    return (<View style={{ minHeight: 100, padding: 4, margin: 4, alignSelf: "stretch", backgroundColor: "#fff", borderRadius: 10 }}>
      <View style={{ minHeight: 55, alignSelf: "stretch", flexDirection: "row" }}>
        <View style={{ height: 45, width: 25, marginRight: 8, justifyContent: "center" }}>{this.state.isTextChat
          ? (<View><Image style={{ width: 24, height: 24, tintColor: "#888" }} source={require("../../../images/tabNavigator/Messages.png")} />
            {unreadMessages >= 1 && (
              <View style={{ backgroundColor: "red", height: 21.875, width: 27.5, borderRadius: 11, alignSelf: "flex-end", alignItems: "center", marginTop: -36, marginRight: -8, marginLeft: 10, marginBottom: 10, borderWidth: 0.5, borderColor: "#fff", justifyContent: "center" }}>
                <Text style={{ color: "white", fontWeight: "bold", fontSize: 14 }}>{unreadMessages}</Text>
              </View>)}
          </View>)
          : (<Text style={{ fontSize: 25 }}>📺</Text>)}</View>
        <View style={{ minHeight: 55, alignSelf: "stretch", flexDirection: "row" }}>
          {this.state.pictureURL ? <Image
            style={{ width: 35, height: 35, borderRadius: 17.5, margin: 5 }}
            source={{ uri: this.state.pictureURL }}
          /> : <View style={{ width: 35, height: 35, borderRadius: 17.5, margin: 5, backgroundColor: "gray" }} />}
          <View>
            <Text style={{ marginBottom: 2, fontWeight: "bold" }}>{this.state.name} ({this.state.generalInformation.age}, {this.state.generalInformation.gender})</Text>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ fontSize: 12, marginBottom: 3 }}>🕑 <Moment fromNow element={Text}>{waitingSinceDate}</Moment></Text>
              {!this.state.isTextChat && (this.props.session.timePatientJoined ? <Text style={{ fontSize: 12, }}>  -  Patient joined <Moment fromNow element={Text}>{patientJoinedDate}</Moment></Text>
                : <Text style={{ fontSize: 12, color: "#800" }}>  -  Patient hasn't joined</Text>)}
              {/* <Text style={{fontSize:12}}>Waiting for <Moment fromNow ago element={Text}>{waitingSinceDate}</Moment></Text> */}
            </View>
            <Text style={{ fontSize: 10, fontStyle: "italic", marginRight: 44 }} numberOfLines={5}>{this.state.complaint}</Text>
          </View>
        </View>
      </View>
      {!this.state.loading ? <View style={{ height: 35, flexDirection: "row", justifyContent: "space-between", alignSelf: "stretch" }}>
        <TouchableOpacity
          style={{
            height: 25, borderRadius: 13, margin: 5, alignItems: "center", justifyContent: "center",
            paddingHorizontal: 10, borderColor: "#855", borderWidth: 0.5,
          }}
          onPress={() => { this.props.navigation.navigate("healthFile", { healthFileID: this.props.session.healthFileID }) }}
        >
          <Text style={{ color: "#855", fontSize: 12 }}>See File</Text>
        </TouchableOpacity>
        <View style={{ height: 35, flexDirection: "row", justifyContent: "flex-end" }}>
          <TouchableOpacity
            style={{
              height: 25, borderRadius: 13, margin: 5, alignItems: "center", justifyContent: "center",
              paddingHorizontal: 10, backgroundColor: "red",
            }}
            onPress={async () => {
              this.setState({ loading: true }); await this.props.startConsultation("re-join", this.props.session.healthFileID, this.props.session.sessionID, this.state.isTextChat); this.setState({ loading: false });
            }}
          >
            <Text style={{ color: "white", fontSize: 12 }}>Join Consultation</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              height: 25, borderRadius: 13, margin: 5, alignItems: "center", justifyContent: "center",
              paddingHorizontal: 10, backgroundColor: "red",
            }}
            onPress={async () => { this.setState({ loading: true }); await this.props.startConsultation("end", this.props.session.healthFileID, null, this.state.isTextChat); this.setState({ loading: false }); }}
          >
            <Text style={{ color: "white", fontSize: 12 }}>End Consultation</Text>
          </TouchableOpacity>
        </View>
      </View>
        : <ActivityIndicator size="small" color="#444" style={{ margin: 5 }} />}
    </View >);
  }
}

class Switch extends PureComponent {
  render() {
    let height =
      this.props.text.length < 45
        ? 30
        : 45 + (this.props.text.length - 45) * 0.33;
    let circle = filled => {
      if (this.props.animating)
        return (
          <View style={{ width: 37, height: 20, borderRadius: 10 }}>
            <ActivityIndicator size="small" color="#faa" />
          </View>
        );
      if (filled) {
        return (
          <View style={{ width: 38.4, height: 21.4, borderRadius: 10.7, borderWidth: 0.7, backgroundColor: "#fff", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", padding: 2.5 }}>
            <View style={{ width: 15, height: 15, borderRadius: 7.5, backgroundColor: "#368", opacity: 0.5 }} />
          </View>
        );
      } else {
        return (
          <View style={{ width: 37, height: 20, borderRadius: 10, borderWidth: 0.7, borderColor: "#faa", flexDirection: "row", alignItems: "center", padding: 2 }}>
            <View style={{ width: 15, height: 15, backgroundColor: "#faa", borderRadius: 7.5 }} />
          </View>

        );
      }
    };

    return (
      <View style={{ height: height, flexDirection: "row", alignItems: "center" }}>
        {circle(this.props.selected)}
        <Text style={{ marginLeft: 10, fontSize: 14, color: this.props.selected ? "#fff" : "#faa" }}>{this.props.text}</Text>
      </View>
    );
  }
}

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  Keyboard,
  Linking,
  Alert,
  KeyboardAvoidingView,
  Dimensions,
} from "react-native";
import { WebView } from 'react-native-webview';
import firebase from "firebase";
import "firebase/firestore";
import {
  preventScreenCaptureAsync,
  allowScreenCaptureAsync,
  usePreventScreenCapture,
} from 'expo-screen-capture';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import ChatGroup from "../../../Messages/Groups/ChatGroupDetailScreen";
import ProductPreview from "../shared/ProductPreview";
import BlogPreview from "../shared/BlogPreview";

export default class HomeHealthScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Health",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "red",
    },
    headerTintColor: "black"
  };
  state = {
    broadcastGroup: null,
    roomLoaded: false,
    doctorsAreOnline: false,
    consultationActive: false,
    consultationActiveIsText: false,
    nPatientsWaiting: 0,
    nActiveSessions: 0,
    notificationsLoaded: false,
    patientNotifications: 0,
    doctorNotifications: 0,
    aaDoc: false,
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {

    try {
      preventScreenCaptureAsync();
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 200); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 400); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 500); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 500); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 600); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 1000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 2000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 4000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 5000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 8000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 10000); });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 10000); });
      if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 100000); });
      if (!global.myUID || !global.myProfileID) await global.setGlobals();
      if (!global.myUID)
        return;

      this.loadGroups() // forum
      this.loadRooms() // consultation room
      this.loadNotifications()

      StatusBar.setBarStyle('dark-content');


    } catch (err) {
      if (global.reportError) {
        global.reportError(err, "catches", "homeHealth");
      }
    }
  }



  async componentWillUnmount() {
    try {
      await allowScreenCaptureAsync()
      console.log("Component homeHealth will unmount");
      this.unsubscribeFirestore();
    } catch (e) {
      global.warn(e, "healthUnmount")
    }
  }

  loadGroups = async limit => {
    // this.setState({ activityIndicatorAnimating: true });
    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "findGroups", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          isWeb: true,
          // limit,
          type: "groupIDs",
          groupIDs: ["nidfKSIaadLP5otAmwS1"],
          // coords: global.location.coords,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();

      if (r2.msg === "SUCCESS") {
        this.setState({ broadcastGroup: r2.groups[0] })
      } else alert("Error loading data.");
    } catch (err) {
      if (global.reportError) {
        global.reportError(err, "catches", "homeInnovationKitchen");
      }
    }
  };

  loadRooms = async () => {
    try {
      await firebase
        .firestore()
        .collection("Health")
        .doc("data")
        .collection("HealthRooms")
        .doc("+NGGeneral")
        .onSnapshot(this.updateRoom)
    } catch (error) {
      global.warn(error, "Error 90")
    }
  }

  updateRoom = async (roomDoc) => {
    try {
      if (!roomDoc) return
      const room = roomDoc.data()
      if (!room) return;

      let nActiveSessions = 0
      if (room.activeSessions && typeof room.activeSessions == "object")
        Object.keys(room.activeSessions).forEach(key => {
          if (room.activeSessions[key].doctorProfileID == global.myHealthProfileID) nActiveSessions += 1
        })

      let consultationActive = false
      let consultationActiveIsText = false
      const myWaiting = room.patientsWaiting?.filter(patient => { return (patient.healthFileID == global.myHealthFileID) })
      if (myWaiting?.length > 0) {
        consultationActive = true
        consultationActiveIsText = myWaiting[0].isTextChat ? true : false
      }
      let activeSession = null
      if (room.activeSessions?.[global.myHealthFileID]) activeSession = room.activeSessions[global.myHealthFileID]
      if (!!activeSession) {
        consultationActive = true
        consultationActiveIsText = activeSession.isTextChat ? true : false
      }

      this.setState({
        roomLoaded: true, doctorsAreOnline: room.roomStatus == "OPEN", consultationActive, consultationActiveIsText,
        nActiveSessions, nPatientsWaiting: room.patientsWaiting ? (room.patientsWaiting.length || 0) : 0
      })
    } catch (error) {
      global.warn(error, "Error 92")
    }
  }

  loadNotifications = async () => {
    if (!global.myHealthFileID) await global.timeout(7000)
    if (!global.myHealthFileID) return;
    try {
      await firebase
        .firestore()
        .collection("Users")
        .doc(global.myUID)
        .collection("Refs")
        .doc("healthChatRefs")
        .onSnapshot(this.updateNotifications)
    } catch (error) {
      global.warn(error, "9f")
    }
  }

  updateNotifications = async (refDoc) => {
    try {
      if (!refDoc) return
      const n = refDoc.data()
      if (!n) return;

      let patientNotifications = 0
      for (let [key, value] of Object.entries(n.patient)) {
        patientNotifications += (value || 0);
      }
      let doctorNotifications = 0
      for (let [key, value] of Object.entries(n.doctor)) {
        doctorNotifications += (value || 0);
      }

      this.setState({ notificationsLoaded: true, patientNotifications, doctorNotifications })
      global.healthChatNotifications = n
    } catch (error) {
      global.warn(error, "10f")
    }
  }

  setUpDoctorProfile = async () => {
    if (!global.amDoctor) return;
    if (this.state.aaDoc) return
    try {
      this.setState({ aaDoc: true })
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "updateHealthProfile", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          action: "create",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        if (!global.myHealthProfileID) await global.timeout(1000)
        if (!global.myHealthProfileID) await global.timeout(1000)
        if (!global.myHealthProfileID) await global.timeout(1000)
        if (!global.myHealthProfileID) await global.timeout(1000)
        if (!global.myHealthProfileID) await global.timeout(1000)
        if (!global.myHealthProfileID) await global.timeout(3000)
        this.setState({ aaDoc: false })
        if (!global.myHealthProfileID) {
          this.props.navigation.goBack()
          await global.timeout(200)
          return alert("Profile created. Restart app to continue.")
        }
        this.props.navigation.navigate("healthProfile")
        await global.timeout(200)
        this.props.navigation.navigate("healthProfileUpdate")
      } else {
        this.setState({ aaDoc: false })
        return alert("Backend error...")
      }
    } catch (error) {
      this.setState({ aaDoc: false })
      global.warn(error, "727")
      alert("error...")
    }
  }


  renderLoadingGroup = () => {
    return (
      <View style={{ height: 40, width: "100%", alignItems: "center", justifyContent: "center" }}>
        <Text style={{ color: "#cbc", fontSize: 12 }}>LOADING</Text>
      </View>
    )
  }

  render() {
    const sWidth = Dimensions.get("window").width
    const cur = global.currencySymbols[global.myLocalCurrency || "NGN"]
    return (
      <SafeAreaView style={{ flex: 1, width: "100%", backgroundColor: "#ccc", paddingTop: 25 }} >
        <StatusBar barStyle="dark-content" />
        <View style={{ backgroundColor: "#fff", flex: 1 }}>
          <View
            style={{ backgroundColor: "#fff", borderBottomColor: "#988", borderBottomWidth: 1, ...Platform.select({ ios: { height: 45 }, android: { height: 50 } }) }}
          />
          <KeyboardAwareScrollView
            enableOnAndroid={true}
            extraHeight={Platform.OS == "ios" ? 100 : 0}
            extraScrollHeight={Platform.OS !== "ios" ? 200 : 0}
            //resetScrollToCoords={{ x: 0, y: 0 }}
            showsVerticalScrollIndicator={false}
          >
            {global.amDoctor && !!global.myHealthProfileID && <View style={{ width: "100%", minHeight: 150, backgroundColor: "#368" }}>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ color: "white", fontSize: 12, margin: 6 }}>Doctor's Desk</Text>
                <View style={{ flexDirection: "row", marginTop: 15, marginRight: 15, }}>
                  <TouchableOpacity onPress={() => { this.props.navigation.navigate("healthRecords") }} style={{ flexDirection: "row", alignItems: "center", marginLeft: 10, }}>
                    <Image style={{ width: 28, height: 22, tintColor: "white", marginHorizontal: 8 }} source={require("../../../images/icons/File.png")} />
                    <View>
                      <Text style={{ color: "white", fontSize: 12 }}>My Patient</Text>
                      <Text style={{ color: "white", fontSize: 12 }}>Records</Text>
                    </View>
                  </TouchableOpacity>
                  {this.state.doctorNotifications > 0 && <TouchableOpacity onPress={() => { this.props.navigation.navigate("healthRecords") }} style={{ flexDirection: "row", alignItems: "center", marginLeft: 10, }}>
                    <Image style={{ width: 20, height: 20, tintColor: "#fff" }} source={require("../../../images/tabNavigator/Messages.png")} />
                    <View style={{
                      backgroundColor: "red", height: 17, width: 22, borderRadius: 8, alignSelf: "flex-end", alignItems: "center",
                      marginTop: -29, marginLeft: -8, marginBottom: 10, borderWidth: 1, borderColor: "red", justifyContent: "center"
                    }}>
                      <Text style={{ color: "#fff", fontSize: 10 }}>{this.state.doctorNotifications}</Text>
                    </View>
                  </TouchableOpacity>}
                  <TouchableOpacity onPress={() => { this.props.navigation.navigate("healthProfile") }} style={{ flexDirection: "row", alignItems: "center", marginLeft: 10, }}>
                    <Image style={{ width: 22, height: 22, tintColor: "white", marginHorizontal: 8 }} source={require("../../../images/icons/Doctor.png")} />
                    <View>
                      <Text style={{ color: "white", fontSize: 12 }}>My Health</Text>
                      <Text style={{ color: "white", fontSize: 12 }}>Profile</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
              <TouchableOpacity
                style={{
                  height: 35, borderRadius: 10, alignItems: "center", justifyContent: "center",
                  paddingHorizontal: 10, margin: 10, marginTop: 30, backgroundColor: "red",
                  flexDirection: "row"
                }}
                onPress={() => { this.props.navigation.navigate("doctorConsult") }}
              >
                <Text style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>See Waiting Room{!this.state.roomLoaded && " ..."}</Text>
                {(this.state.nPatientsWaiting > 0) && <View style={{
                  backgroundColor: "white", height: 17, minWidth: 17, borderRadius: 8, alignItems: "center",
                  marginTop: 0, marginLeft: 10, borderWidth: 1, borderColor: "white", justifyContent: "center"
                }}>
                  <Text style={{ color: "red", fontSize: 14, fontWeight: "bold" }}>{this.state.nPatientsWaiting}</Text>
                </View>}
                {(this.state.nActiveSessions > 0) && <View style={{
                  backgroundColor: "white", height: 17, minWidth: 17, borderRadius: 8, alignItems: "center",
                  marginTop: 0, marginLeft: 10, borderWidth: 1, borderColor: "white", justifyContent: "center"
                }}>
                  <Text style={{ color: "red", fontSize: 10, fontWeight: "bold" }}>📞</Text>
                </View>}
              </TouchableOpacity>
            </View>}
            {global.amDoctor && !global.myHealthProfileID && <View style={{ width: "100%", minHeight: 150, backgroundColor: "#368" }}>
              <Text style={{ color: "white", fontSize: 12, margin: 6 }}>Doctor's Desk</Text>
              <Text style={{ color: "white", fontSize: 16, margin: 15, marginBottom: 5 }}>Thank you for becoming a doctor on DoctorDial!</Text>
              <Text style={{ color: "white", fontSize: 16, margin: 15, marginTop: 5 }}>To start, set up your health profile.</Text>
              <TouchableOpacity
                style={{
                  height: 35, borderRadius: 10, alignItems: "center", justifyContent: "center",
                  paddingHorizontal: 10, margin: 10, backgroundColor: "red",
                }}
                onPress={() => { this.setUpDoctorProfile() }}
              >
                <Text style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>{this.state.aaDoc ? "Please wait..." : "Set up Profile"}</Text>
              </TouchableOpacity>
            </View>}

            {global.myHealthFileID && <View style={{ height: 65, alignSelf: "stretch", backgroundColor: "white", alignItems: "center", flexDirection: "row" }}>
              <TouchableOpacity onPress={() => { this.props.navigation.navigate("healthFile", { healthFileID: global.myHealthFileID }) }} style={{ flexDirection: "row", alignItems: "center" }}>
                <Image style={{ width: 28, height: 22, tintColor: "red", marginHorizontal: 8 }} source={require("../../../images/icons/File.png")} />
                <View>
                  <Text style={{ color: "gray", fontSize: 12 }}>My Personal</Text>
                  <Text style={{ color: "gray", fontSize: 12 }}>Health File</Text>
                </View>
              </TouchableOpacity>
              {this.state.patientNotifications > 0 && <TouchableOpacity onPress={() => { this.props.navigation.navigate("healthFile", { healthFileID: global.myHealthFileID }) }} style={{ flexDirection: "row", alignItems: "center", marginLeft: 15 }}>
                <Image style={{ width: 18, height: 18, tintColor: "gray", marginBottom: 0 }} source={require("../../../images/tabNavigator/Messages.png")} />
                <View style={{ backgroundColor: "red", height: 17, width: 22, borderRadius: 8, alignSelf: "flex-end", alignItems: "center", marginTop: -22, marginLeft: -6, marginBottom: 10, borderWidth: 1, borderColor: "red", justifyContent: "center" }}>
                  <Text style={{ color: "#ded", fontSize: 10 }}>{this.state.patientNotifications}</Text>
                </View>
              </TouchableOpacity>}
            </View>}

            <View
              style={{ borderColor: "#312", borderRadius: 20, borderWidth: 3, margin: 10 }}
            >
              <Image style={{ width: "100%", alignSelf: "center", height: 150, justifyContent: "flex-end", borderTopLeftRadius: 17, borderTopRightRadius: 17 }}
                source={require("../../../images/images/DD.jpeg")} />
              <View style={{ marginTop: -42, height: 42, backgroundColor: "rgba(255,0,0,0.75)", paddingLeft: 5, justifyContent: "center" }} >
                <Text style={{ color: "white", fontSize: 26, fontWeight: "bold" }}>Consult a Doctor</Text>
              </View>
              {/* <View style={{ marginTop: -50, height: 50, backgroundColor: "rgba(255,0,0,0.75)", paddingLeft: 5 }} >
                <Text style={{ color: "white", fontSize: 20, fontWeight: "bold" }}>Consult a Doctor</Text>
                <Text style={{ fontSize: 16, fontWeight: "bold" }}>Free Immediate Video Chat</Text>
              </View> */}
              {!this.state.roomLoaded && <View style={{ height: 100, justifyContent: "center", padding: 5, flexDirection: "row", alignItems: "center" }} >
                <Text style={{ fontSize: 12, color: "gray" }}> Loading... </Text>
              </View>}
              {this.state.roomLoaded && this.state.doctorsAreOnline && !this.state.consultationActive && <View style={{ height: 165, justifyContent: "center", padding: 5, alignItems: "center" }} >
                <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 15 }}>
                  <Text style={{ fontSize: 22, color: "green" }}>✓</Text>
                  <Text style={{ fontSize: 12, color: "green", marginLeft: 2 }}>Doctors Online</Text>
                </View>
                <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 15, justifyContent: "space-around", alignSelf: "stretch" }}>
                  <View >
                    <Text style={{ fontSize: 18, fontWeight: "bold" }}>Text Chat</Text>
                    <Text style={{ fontSize: 14 }}><Text style={{ textDecorationLine: "line-through", }}></Text>{cur} 0.00 (limited offer)</Text>
                  </View>
                  <TouchableOpacity
                    style={{
                      height: 35, borderRadius: 10, alignItems: "center", justifyContent: "center",
                      paddingHorizontal: 10, marginLeft: 35, backgroundColor: "red",
                    }}
                    onPress={() => { this.props.navigation.navigate("healthConsultText") }}
                  >
                    <Text style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>Consult Now</Text>
                  </TouchableOpacity>
                </View>
                <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 15, justifyContent: "space-around", alignSelf: "stretch" }}>
                  <View >
                    <Text style={{ fontSize: 18, fontWeight: "bold" }}>Video Chat</Text>
                    <Text style={{ fontSize: 14 }}><Text style={{ textDecorationLine: "line-through", }}></Text>{cur} 0.00 (limited offer)</Text>
                  </View>
                  <TouchableOpacity
                    style={{
                      height: 35, borderRadius: 10, alignItems: "center", justifyContent: "center",
                      paddingHorizontal: 10, marginLeft: 35, backgroundColor: "red",
                    }}
                    onPress={() => { this.props.navigation.navigate("healthConsult") }}
                  >
                    <Text style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>Consult Now</Text>
                  </TouchableOpacity>
                </View>
              </View>}
              {/* {this.state.roomLoaded && this.state.doctorsAreOnline && <View style={{ height: 100, justifyContent: "center", padding: 5, flexDirection: "row", alignItems: "center" }} >
                <Text style={{ fontSize: 36, color: "green" }}>✓</Text>
                <Text style={{ fontSize: 18, fontWeight: "bold", marginLeft: 8 }}>Doctors Online</Text>
                <TouchableOpacity
                  style={{
                    height: 35, borderRadius: 10, alignItems: "center", justifyContent: "center",
                    paddingHorizontal: 10, marginLeft: 35, backgroundColor: "red",
                  }}
                  onPress={() => { this.props.navigation.navigate("healthConsult") }}
                >
                  <Text style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>Consult Now</Text>
                </TouchableOpacity>
              </View>} */}
              {this.state.roomLoaded && this.state.consultationActive && <View style={{ height: 100, justifyContent: "center", padding: 5, flexDirection: "row", alignItems: "center" }} >
                <Text style={{ fontSize: 24, color: "black" }}>👨‍⚕️</Text>
                <Text style={{ fontSize: 18, fontWeight: "bold", marginLeft: 4 }}>Consultation Active</Text>
                <TouchableOpacity
                  style={{
                    height: 35, borderRadius: 10, alignItems: "center", justifyContent: "center",
                    paddingHorizontal: 10, marginLeft: 35, backgroundColor: "red",
                  }}
                  onPress={() => { this.props.navigation.navigate(this.state.consultationActiveIsText ? "healthConsultText" : "healthConsult") }}
                >
                  <Text style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>      Join!      </Text>
                </TouchableOpacity>
              </View>}
              {this.state.roomLoaded && !this.state.doctorsAreOnline && !this.state.consultationActive && <View style={{ height: 100, justifyContent: "center", padding: 5, alignItems: "center" }} >
                <View style={{ flexDirection: "row", alignItems: "center", marginRight: 50 }}>
                  <Text style={{ fontSize: 32, marginRight: 22 }}>🚫</Text>
                  <View>
                    <Text style={{ fontSize: 16, color: "#000", fontWeight: "bold" }}>Currently not available</Text>
                    <Text style={{ fontSize: 12, color: "#555" }}>Doctors are offline or busy.</Text>
                    <Text style={{ fontSize: 12, color: "#555" }}>Please check back another time</Text>
                  </View>
                </View>
              </View>}
              {/* //The following was only used in v 1.2.4 - delete soon // */}
              {/* {this.state.roomLoaded && <View style={{ height: 100, justifyContent: "center", padding: 5, alignItems: "center" }} >
                <View style={{ flexDirection: "row", alignItems: "center", marginRight: 50 }}>
                  <Text style={{ fontSize: 32, marginRight: 22 }}>🚫</Text>
                  <View>
                    <Text style={{ fontSize: 16, color: "#000", fontWeight: "bold" }}>Update required</Text>
                    <Text style={{ fontSize: 12, color: "#555" }}>Update Wakanda Market on Google Play</Text>
                    <Text style={{ fontSize: 12, color: "#555" }}>to use this service.</Text>
                  </View>
                </View>
              </View>} */}
            </View>

            <BlogPreview blogID={"HU3Rz3peh9ApW91Q4LDm"}
              title='  Health Blog' />

            <View style={{ backgroundColor: "#312", borderRadius: 6, padding: 10, margin: 10, marginTop: 5 }}>
              <View style={{ flexDirection: "row", justifyContent: "space-between", padding: 5 }}>
                <Text style={{ color: "white", fontWeight: "bold", marginBottom: 10, fontSize: 14 }}>
                  Health Forum
                </Text>
                {this.state.broadcastGroup && (
                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.navigate("homeGroupDetail", { group: this.state.broadcastGroup, title: " ", titleBackgroundColor: "#613" });
                      global.reportStats("pressed_Health_SeeForum")
                    }}
                  >
                    <Text style={{ color: "red", fontWeight: "bold", marginBottom: 10, fontSize: 14 }}>
                      See All
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
              {this.state.broadcastGroup ? (
                <ChatGroup
                  backgroundColor="#312"
                  navigation={this.props.navigation}
                  group={this.state.broadcastGroup}
                />
              ) : this.renderLoadingGroup()}
              {this.state.broadcastGroup && <View style={{ justifyContent: "space-between", alignItems: "center", padding: 5 }}>
                <Text style={{ color: "#faa", fontSize: 12 }}>
                  ⚠ No selling ⚠ Remain polite
                </Text>
                <Text style={{ color: "#faa", fontSize: 12 }}>
                  ⚠ Medical advice only by verified doctors
                </Text>
              </View>}
            </View>

            {(global.myLocalCurrency !== "GHS") && <View>
              <TouchableOpacity style={{ padding: 10, margin: 10, backgroundColor: "red", borderRadius: 8 }} onPress={() => {
                this.props.navigation.navigate("healthFacilities", { type: "center" })
                global.reportStats("pressed_Health_FindCenter")
              }} >
                <Text style={{ color: "#fff" }}>Find a health center near you</Text>
              </TouchableOpacity>
              <TouchableOpacity style={{ padding: 10, margin: 10, backgroundColor: "red", borderRadius: 8 }} onPress={() => {
                this.props.navigation.navigate("healthFacilities", { type: "pharmacy" })
                global.reportStats("pressed_Health_FindPharmacy")
              }}>
                <Text style={{ color: "#fff" }}>Find a pharmacy near you </Text>
              </TouchableOpacity>
              <TouchableOpacity style={{ padding: 10, margin: 10, backgroundColor: "red", borderRadius: 8 }} onPress={() => {
                this.props.navigation.navigate("healthFacilities", { type: "lab" })
                global.reportStats("pressed_Health_FindLab")
              }}>
                <Text style={{ color: "#fff" }}>Find a lab near you </Text>
              </TouchableOpacity>
            </View>}

            {(global.myLocalCurrency == "GHS") && <View>
              <TouchableOpacity style={{ padding: 10, margin: 10, backgroundColor: "red", borderRadius: 8 }} onPress={() => {
                Linking.openURL("tel:193");
                global.reportStats("pressed_Health_FindAmbulanceGhana")
              }} >
                <Text style={{ color: "#fff" }}>Call an ambulance (Ghana)</Text>
              </TouchableOpacity>
            </View>}

            <TouchableOpacity style={{ margin: 10, backgroundColor: "red", borderRadius: 8, marginBottom: 10 }} onPress={() => {
              global.reportStats("pressed_Health_DDBanner1")
              Linking.openURL("https://www.doctordial.com.ng")
            }
            }>
              <Image style={{ width: "100%", alignSelf: "center", height: 125, borderRadius: 8, justifyContent: "flex-end", padding: 8 }}
                source={require("../../../images/images/DD.jpeg")} />
              <View style={{ padding: 6, marginTop: -56, alignItems: "center", backgroundColor: "rgba(255,0,0,0.75)", borderRadius: 15, marginHorizontal: 38 }}>
                <Text style={{ color: "white", fontSize: 18, fontWeight: "bold" }}>Check your risk for COVID-19</Text>
                <Text style={{ fontSize: 16, fontWeight: "bold" }}>Free Symptom Checker</Text>
              </View>
            </TouchableOpacity>

            <View style={{ height: 30 }} />
            <ProductPreview
              searchTerm={"Health"}
              navigation={this.props.navigation}
            />
            <Text style={{ color: "gray", marginHorizontal: 60, marginBottom: 18, marginTop: -70, fontSize: 12 }}>
              ⓘ  Wakanda does not certify quality and authenticity of marketplace products.
                </Text>

            <TouchableOpacity style={{}} onPress={() => {
              Linking.openURL("https://www.google.com/covid19/")
              global.reportStats("pressed_Health_GoogleCovid19")
            }
            }>
              {/* <WebView
                originWhitelist={['*']}
                style={{ width: sWidth, height: sWidth * 2, marginVertical: 10 }}
                source={{ uri: 'https://www.google.com/covid19/' }}
                javaScriptEnabled={true}
                domStorageEnabled={true}
                useWebKit={true}
                scrollEnabled={false}
              /> */}
            </TouchableOpacity>

            <TouchableOpacity style={{}} onPress={() => {
              Linking.openURL("https://google.org/crisisresponse/covid19-map")
              global.reportStats("pressed_Health_GoogleCovid19Map")
            }
            }>
              {/* <WebView
                originWhitelist={['*']}
                style={{ width: sWidth, height: sWidth * 1.33, marginVertical: 10 }}
                source={{ uri: 'https://google.org/crisisresponse/covid19-map' }}
                javaScriptEnabled={true}
                domStorageEnabled={true}
                useWebKit={true}
                scrollEnabled={false}
              /> */}
            </TouchableOpacity>

            <View style={{ height: 350 }} />
          </KeyboardAwareScrollView>
        </View >
      </SafeAreaView >
    );
  }
}


import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  Linking,
  Platform,
  Dimensions,
  ActivityIndicator,
  KeyboardAvoidingView,
  Image,
  Keyboard,
  TextInput
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import Moment from "react-moment";
import { NavigationEvents } from "react-navigation";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
//import * as Permissions from 'expo-permissions'
import HealthChat from "./HealthChat"
import { RecordStatus } from "./HealthRecordScreen"
import { AdvancedFormContent } from "./HealthConsultScreen"

//import { Audio } from 'expo-av';


export default class HealthFileScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    return {
      tabBarVisible: false,
      headerTransparent: true,
      title: navigation.getParam("title", "") || "",
      headerTintColor: "black",
      headerTitleStyle: {
        fontWeight: "bold",
        color: "red",
      }
    }
  };

  state = {
    screen: "LOADING", // can be "MAIN",
    fileLoaded: false,
    recordsLoaded: false,
    file: {},
    records: [],

    age: "",
    gender: "",
    complaint: "",
    genotype: "",
    bloodGroup: "",
    hasHypertension: false,
    hasDiabetes: false,
    hasAsthma: false,
    hasOtherPreconditions: false,
    otherPreconditions: "",
    allergies: "?",
    surgeries: "?",
    medication: "?",

    isEditing: false,
    aaRefresh: false
  };
  healthFileID = ""

  async componentDidMount() {
    if (this.props.isPreview) {
      this.isFullScreen = false
      this.healthFileID = this.props.healthFileID || ""
    } else {
      this.isFullScreen = true
      this.healthFileID = this.props.navigation.getParam("healthFileID", "")
    }

    if (!global.myUID) await global.timeout(4000)
    if (!global.amDoctor && (this.healthFileID !== global.myHealthFileID)) {
      this.props.navigation.goBack()
      alert("Access issue")
    }

    this.loadHealthFile()
    this.loadHealthRecords()
  }

  componentWillUnmount() {
    if (this.keyboardDidShowListener) this.keyboardDidShowListener.remove();
    if (this.keyboardDidHideListener) this.keyboardDidHideListener.remove();
  }

  loadHealthFile = async () => {
    try {
      const fileDoc = await firebase
        .firestore()
        .collection("Health")
        .doc("data")
        .collection("HealthFiles")
        .doc(this.healthFileID)
        .get()
      const file = fileDoc.data()
      this.setState({ file, fileLoaded: true, screen: "MAIN" })
      if (file.generalInformation) this.setState(file.generalInformation) //correct
    } catch (error) {
      global.warn(error, "Error 710")
    }
  }

  loadHealthRecords = async () => {
    if (!this.healthFileID) return;
    try {
      const r = await fetch(global.cloudFunctionURL + "getHealthRecords", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          healthFileID: this.healthFileID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        this.setState({ records: r2.records, recordsLoaded: true })
      } else return alert("Backend error")
    } catch (error) {
      global.warn(error, "Error 711")
    }
  }

  submitGeneralInformation = async () => {
    if (!this.healthFileID) return;
    try {
      this.setState({ screen: "LOADING" })
      const { age, gender, bloodGroup, genotype, hasHypertension, hasDiabetes, hasAsthma, hasOtherPreconditions, otherPreconditions, allergies, surgeries, medication } = this.state
      const generalInformation = { age, gender, bloodGroup, genotype, hasHypertension, hasDiabetes, hasAsthma, hasOtherPreconditions, otherPreconditions: hasOtherPreconditions ? otherPreconditions : "", allergies, surgeries, medication }
      const r = await fetch(global.cloudFunctionURL + "updateHealthFile", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          requestBy: (global.myHealthFileID == this.healthFileID) ? "patient" : "doctor",
          generalInformation,
          healthFileID: this.healthFileID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ screen: "MAIN" })
      if (r2.msg == "SUCCESS") {
        this.loadHealthFile()
        this.setState({ isEditing: false })
      } else return alert("Backend error...")
    } catch (error) {
      global.warn(error, "Error 721")
    }
  }


  renderMain() {
    let { age, gender, bloodGroup, genotype, hasHypertension, hasDiabetes, hasAsthma, hasOtherPreconditions, otherPreconditions, allergies, surgeries, medication, } = this.state
    let precString = (hasHypertension ? "Hypertension, " : "") + (hasDiabetes ? "Diabetes, " : "") + (hasAsthma ? "Asthma, " : "") + (hasOtherPreconditions ? ("Others: " + (otherPreconditions || "-") + "  ") : "")
    if (precString.length > 1) precString = precString.substring(0, precString.length - 2)
    const sTitle = { color: "red", fontSize: 14, marginTop: 9, fontWeight: "bold" }
    const sValue = { color: "black", fontSize: 14, }

    return (<View style={{ backgroundColor: "#fff", height: "100%", width: "100%" }}>
      <View style={{ borderColor: "#312", borderRadius: 0, borderWidth: 0, }}>
        {this.isFullScreen && <View>
          <Image style={{ width: "100%", alignSelf: "center", height: 150, justifyContent: "flex-end", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
            source={require("../../../images/images/DD.jpeg")} />
          <View style={{ marginTop: -50, height: 50, backgroundColor: "rgba(255,0,0,0.75)", justifyContent: "center", alignItems: "center", flexDirection: "row" }} >
            <Image style={{ width: 28, height: 22, tintColor: Platform.OS == "ios" ? "#faa" : "#fcc", marginRight: 8 }} source={require("../../../images/icons/File.png")} />
            <Text style={{ color: "white", fontSize: Platform.OS == "ios" ? 28 : 22, fontWeight: "100", marginRight: 30 }}> Health File</Text>
            {/* <Text style={{ fontSize: 16, fontWeight: "bold" }}> </Text> */}
          </View>
        </View>}
        <KeyboardAwareScrollView
          enableOnAndroid={true}
          extraHeight={150}
          //enableAutomaticScroll={true}
          showsVerticalScrollIndicator={false}
        >
          {this.state.file && <View style={{ margin: 20 }}>
            <View style={{ minHeight: 65, flexDirection: "row" }}>
              <ProfileRow file={this.state.file} />
              <TouchableOpacity style={{ alignItems: "center", justifyContent: "center", height: 43, width: 38, paddingRight: 3, paddingTop: 8 }}
                onPress={() => { this.setState({ isEditing: true }) }}>
                <Image style={{ width: 35, height: 35, tintColor: "red" }} source={require("../../../images/icons/Change.png")} />
              </TouchableOpacity>
            </View>

            {this.state.isEditing ? <View>

              <AdvancedFormContent view={"file"} t={this} />

              <TouchableOpacity onPress={() => {
                this.submitGeneralInformation()
              }}>
                <View style={[global.gStyles.buttonXL, {
                  backgroundColor: "red", paddingVertical: 2.5, paddingHorizontal: 22, marginTop: 40,
                  flexDirection: "row", width: "87%", alignSelf: "center", alignItems: "center"
                }]} >
                  <Text style={{ fontSize: 16, fontWeight: "bold", color: "#fff" }}>Update</Text>
                </View>
              </TouchableOpacity>
            </View>

              : (<View>
                <Text style={sTitle}>Blood Group</Text>
                <Text style={sValue}>{bloodGroup || "-"}</Text>
                <Text style={sTitle}>Genotype</Text>
                <Text style={sValue}>{genotype || "-"}</Text>
                <Text style={sTitle}>Preconditions</Text>
                <Text style={sValue}>{precString || "-"}</Text>
                <Text style={sTitle}>Allergies</Text>
                <Text style={sValue}>{allergies || "-"}</Text>
                <Text style={sTitle}>Surgeries</Text>
                <Text style={sValue}>{surgeries || "-"}</Text>
                <Text style={sTitle}>Medication</Text>
                <Text style={sValue}>{medication || "-"}</Text>
              </View>)}
          </View>}

          {(!this.state.recordsLoaded) && <View style={{ flexDirection: "row", height: 100, width: '100%', justifyContent: "center", alignItems: "center" }}>
            <ActivityIndicator size="small" color="#444" style={{ marginRight: 8 }} />
            <Text style={{ color: "#444" }}>Loading Records...</Text>
          </View>}
          {this.state.recordsLoaded && <View style={{ margin: 15 }}>
            {(this.state.records.length == 0) && <View style={{ height: 100, justifyContent: "center", padding: 5, flexDirection: "row", alignItems: "center" }} >
              <Text style={{ fontSize: 18, color: "gray" }}>No Records yet</Text>
            </View>}
            {(this.state.records.length > 0) && <View style={{ margin: 10, marginTop: this.state.isEditing ? 125 : 15, marginLeft: 4 }}><Text style={{ color: "black", fontSize: 20, fontWeight: "bold" }}>Records:</Text></View>}
            {(this.state.records.length > 0) && this.state.records.map((rec, i) => { return (<Record key={rec.xID} record={rec} navigation={this.props.navigation} isFirst={i == 0} file={this.state.file} />) })}
          </View>}
          {this.state.aaRefresh && <View style={{ alignSelf: "center" }}><Text style={{ fontSize: 12, color: "gray", marginTop: 20 }}>Updating ...</Text></View>}
          <View style={{ height: 200 }} />
        </KeyboardAwareScrollView>
        <NavigationEvents
          onDidFocus={async payload => {
            await this.setState({ aaRefresh: true })
            await global.timeout(50)
            await this.setState({ aaRefresh: false })
            // const recordsLoaded = this.state.recordsLoaded
            // await this.setState({ recordsLoaded: false })
            // await global.timeout(50)
            // await this.setState({ recordsLoaded: (recordsLoaded || this.state.recordsLoaded) })
          }}
        />
      </View>
    </View>)
  }






  renderLoading() {
    return (<View style={{ backgroundColor: "#fff", height: "100%", width: "100%", alignItems: "center", justifyContent: "center" }}>
      <Image style={{ borderRadius: 17, height: 100, width: 200, marginBottom: -65 }}
        source={require("../../../images/images/DD.jpeg")} />
      <ActivityIndicator size="large" color="white" animating={true} />
    </View>)
  }

  render() {
    if (this.props.hide) return (<View />)

    if (this.state.screen == "MAIN") return this.renderMain()
    if (this.state.screen == "LOADING") return this.renderLoading()
    return (<View />)
  }
}

class ProfileRow extends Component {
  state = { name: " ✧ ✧ ✧ ✧ ✧ ✧", pictureURL: null }
  async componentDidMount() {
    try {
      const profileDoc = await firebase
        .firestore()
        .collection("Profiles")
        .doc(this.props.file.profileID)
        .get()
      const profile = profileDoc.data()
      this.setState({ pictureURL: profile.profilePictureURL, name: profile.profileName || "--" })
    } catch (error) {
      global.warn(error, "sdfv");
    }
  }
  render() {
    const file = this.props.file
    if (!file.generalInformation) return (<View style={{ height: 100, width: 200, backgroundColor: "red" }} />)
    let { age, gender, } = file.generalInformation
    if (file.generalInformation) {
      gender = file.generalInformation.gender;
      age = file.generalInformation.age
    }
    const timeCreated = (file && global.stampToDate) && global.stampToDate(file.timeCreated)

    return (<View style={{ minHeight: 100, flex: 1, backgroundColor: "#fff", borderRadius: 0 }}>
      <View style={{ minHeight: 65, alignSelf: "stretch", flexDirection: "row" }}>
        {this.state.pictureURL ? <Image
          style={{ width: 55, height: 55, borderRadius: 28 }}
          source={{ uri: this.state.pictureURL }}
        /> : <View style={{ width: 55, height: 55, borderRadius: 28, backgroundColor: "gray" }} />}
        <View style={{ flex: 1, marginLeft: 10 }}>
          <Text style={{ marginBottom: 2, fontWeight: "bold" }}>{this.state.name} ({age}, {gender})</Text>
          <View style={{ flexDirection: "row" }}>
            <Text style={{ fontSize: 12, marginBottom: 3 }}>File created <Moment fromNow element={Text}>{timeCreated}</Moment></Text>
          </View>
        </View>
      </View>
    </View>);
  }
}

export class Record extends Component {
  state = { name: "", pictureURL: "" }
  async componentDidMount() {
    try {
      if (this.props.showPatient) {
        const profileDoc = await firebase
          .firestore()
          .collection("Profiles")
          .doc(this.props.record.patientProfileID)
          .get()
        const profile = profileDoc.data()
        this.setState({ pictureURL: profile.profilePictureURL || "", name: profile.profileName || "No name provided" })
      } else {
        const profileDoc = await firebase
          .firestore()
          .collection("Health")
          .doc("data")
          .collection("HealthProfiles")
          .doc(this.props.record.doctorProfileID)
          .get()
        const profile = profileDoc.data()
        this.setState({ pictureURL: profile.profilePictureURL || "", name: profile.profileName || "No name provided" })
      }
    } catch (error) {
      global.warn(error, "Error sddx");
    }
  }
  render() {
    const record = this.props.record
    const createdDate = (record && global.stampToDate) && global.stampToDate(record.timeCreated)
    let nUnseenMessages = 0
    if (global.healthChatNotifications && typeof global.healthChatNotifications == "object")
      if (global.healthChatNotifications[this.props.showPatient ? "doctor" : "patient"] && typeof global.healthChatNotifications[this.props.showPatient ? "doctor" : "patient"] == "object")
        nUnseenMessages = global.healthChatNotifications[this.props.showPatient ? "doctor" : "patient"][record.xID] || 0;

    return (<TouchableOpacity onPress={() => { this.props.navigation.navigate("healthRecord", { record, file: this.props.file, fileID: record.fileID }) }}>
      <View style={{ minHeight: 90, paddingVertical: 8, marginHorizontal: 4, paddingHorizontal: 4, alignSelf: "stretch", borderColor: "#777", borderBottomWidth: 0.5, borderTopWidth: this.props.isFirst ? 0.5 : 0 }}>
        <View style={{ justifyContent: "space-between", flexDirection: "row" }}>
          <View style={{ alignSelf: "stretch", flexDirection: "row" }}>
            {this.state.pictureURL ? <Image
              style={{ width: 35, height: 35, borderRadius: 17.5 }}
              source={{ uri: this.state.pictureURL }}
            /> : <View style={{ width: 35, height: 35, borderRadius: 17.5, backgroundColor: "gray" }} />}
            <View style={{ marginLeft: 5 }}>
              <Text style={{ marginBottom: -2, fontWeight: "bold", fontSize: 16 }}>{this.state.name}</Text>
              <Text style={{ marginBottom: 4, }}>{record.type}</Text>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ fontSize: 12, marginBottom: 3 }}>🕑 <Moment fromNow element={Text}>{createdDate}</Moment></Text>
              </View>
              {record.chatMessages && record.chatMessages.length > 0 && <Text style={{ fontSize: 12, }}>{record.chatMessages.length} messages</Text>}
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            {!!nUnseenMessages && <View style={{ marginLeft: 5, pading: 3, minWidth: 20, height: 20, backgroundColor: "red", borderRadius: 15, alignItems: "center", justifyContent: "center" }}>
              <Text style={{ fontSize: 14, color: "white", fontWeight: "bold" }}>{nUnseenMessages}</Text>
            </View>}
            <View style={{ marginLeft: 5 }}>
              <RecordStatus small status={record.status || ""} />
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>);
  }
}


import React, { Component } from "react";
import {
  TouchableOpacity,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  View,
  Image,
  Alert,
  Dimensions,
  ActivityIndicator,
  ScrollView
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import CachedImage from "../../shared/CachedImage"

export default class CommunitySettingsScreen extends Component {
  static navigationOptions = ({ navigation }) => {
    const title = navigation.getParam("title", "Community Preferences");
    return {
      tabBarVisible: false,
      headerTransparent: true,
      title,
      headerTitleStyle: {
        fontWeight: "bold",
        color: "#A8CF45",
      }
    };
  };

  state = {
    mutedDiscussion: false,
    mutedComment: false,

    loading: true,
    error: null,
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
  }

  async componentDidMount() {
    this.community = this.props.navigation.getParam("community", null);
    if (!this.community) return this.props.navigation.goBack()

    try {
      const r = await fetch(global.cloudFunctionURL + "joinCommunity", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          action: "getSettings",
          communityID: this.community.xID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        const { mutedDiscussion, mutedComment } = r2
        this.setState({ mutedDiscussion, mutedComment, loading: false })
      } else this.setState({ error: "Could not load settings" })
    } catch (error) {
      global.warn(error, "cdm_css")
      this.setState({ error: "Could not load settings - check your internet connection" })
    }
    this.setState({ loading: false })
  }



  changeSetting = async (type) => {
    if (!type) return alert("ERROR")
    if (this["aa" + type]) return
    this["aa" + type] = true
    try {
      let action = ""
      const { mutedDiscussion, mutedComment } = this.state
      if (type == "discussion" && !this.state.mutedDiscussion) {
        this.setState({ mutedDiscussion: true })
        action = "muteNewDiscussions"
      }
      if (type == "discussion" && this.state.mutedDiscussion) {
        this.setState({ mutedDiscussion: false })
        action = "unmuteNewDiscussions"
      }
      if (type == "comment" && !this.state.mutedComment) {
        this.setState({ mutedComment: true })
        action = "muteNewComments"
      }
      if (type == "comment" && this.state.mutedComment) {
        this.setState({ mutedComment: false })
        action = "unmuteNewComments"
      }
      const r = await fetch(global.cloudFunctionURL + "joinCommunity", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          action,
          communityID: this.community.xID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this["aa" + type] = false
      if (r2.msg == "SUCCESS") {
        console.log("Updated Settings - " + action)
      } else {
        this.setState({ mutedDiscussion, mutedComment })
        return alert("Error updating")
      }
    } catch (error) {
      global.warn(error, "loadMySettings")
      alert("Oops - changing settings did not work")
      this["aa" + type] = false
    }
  }


  renderSwitch = (activated) => {
    if (activated) {
      return (
        <View style={{ width: 38.4, height: 21.4, borderRadius: 10.7, borderWidth: 0.7, backgroundColor: "#aca", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", padding: 2.5 }}>
          <View style={{ width: 15, height: 15, borderRadius: 7.5, backgroundColor: "#272", opacity: 0.5 }} />
        </View>
      );
    } else {
      return (
        <View style={{ width: 37, height: 20, borderRadius: 10, borderWidth: 0.7, borderColor: "#777778", flexDirection: "row", alignItems: "center", padding: 2 }}>
          <View style={{ width: 15, height: 15, backgroundColor: "#777778", borderRadius: 7.5 }} />
        </View>

      );
    }
  }

  renderMain = () => {
    const fSiz = global.SWIDTH > 390 ? 16 : 14
    return (
      <View style={{ backgroundColor: "#000", flex: 1, height: "100%" }}>
        <View style={{ backgroundColor: "#000", borderBottomColor: "#55555a", borderBottomWidth: 1, ...Platform.select({ ios: { height: Dimensions.get("window").height >= 800 ? 88 : 65 }, android: { height: 85 } }) }} />
        {/* <Text style={{ color: "#bbbbc5", fontSize: 15, fontWeight: "bold", margin: 20 }}>What do you want to be notified about?</Text> */}
        <View style={{ height: 30 }} />
        <ScrollView>
          <TouchableOpacity activeOpacity={0.8}
            onPress={async () => { this.changeSetting("discussion") }}
            style={{ flexDirection: "row", paddingHorizontal: 10, backgroundColor: "#202020", height: 40, justifyContent: "space-between", alignItems: "center", marginBottom: 10, marginHorizontal: 20, paddingLeft: 12, borderRadius: 8 }}>
            <Text style={{ color: "#bbbbc5", fontSize: fSiz }}>🔔 Get notified about new discussions</Text>
            {this.renderSwitch(!this.state.mutedDiscussion)}
          </TouchableOpacity>
          <TouchableOpacity activeOpacity={0.8}
            onPress={async () => { this.changeSetting("comment") }}
            style={{ flexDirection: "row", paddingHorizontal: 10, backgroundColor: "#202020", height: 40, justifyContent: "space-between", alignItems: "center", marginBottom: 10, marginHorizontal: 20, paddingLeft: 12, borderRadius: 8 }}>
            <Text style={{ color: "#bbbbc5", fontSize: fSiz }}>🔔 Get notified about new comments</Text>
            {this.renderSwitch(!this.state.mutedComment)}
          </TouchableOpacity>
          {/* <TouchableOpacity activeOpacity={0.8}
            onPress={async () => { this.changeSetting("comment") }}
            style={{ flexDirection: "row", paddingHorizontal: 10, backgroundColor: "#202020", height: 40, justifyContent: "space-between", alignItems: "center", marginBottom: 10, marginHorizontal: 20, paddingLeft: 12, borderRadius: 8 }}>
            <Text style={{ color: "#bbbbc5", fontSize: 14 }}>📌 Pin community on messages screen</Text>
            {this.renderSwitch(!this.state.mutedComment)}
          </TouchableOpacity> */}
        </ScrollView>
      </View >)
  }

  render() {
    if (this.state.error) return (
      <View style={{ height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: "#131313" }}>
        <Text style={{ color: "#aab" }}>{this.state.error}</Text>
      </View >
    );
    if (this.state.loading) return (
      <View style={{ height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: "#131313" }}>
        <ActivityIndicator size="small" color="#aab" />
      </View>
    );
    return this.renderMain()
  }
}

//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
// Screen always "synced" between Wakanda Market and Health
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  TextInput,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Keyboard,
  Dimensions,
  KeyboardAvoidingView,
  ActivityIndicator,
  Platform,
  Linking,
  Alert,
  Modal
} from "react-native";
const gStyles = global.gStyles //    "../../../styles/gStyles";
import firebase from "firebase";
import "firebase/firestore";
import { NavigationEvents } from "react-navigation";
import MapScreen from "../../../shared/Map/mapScreen";
import GetPicture from "../../../shared/Camera/GetPicture";
import { Message, SystemMessage } from "../../../Messages/ChatDetailScreen"
import { RecordMessage, VoiceMessage } from "../../../Messages/VoiceMessage";
import * as DocumentPicker from 'expo-document-picker';


class TypeBox extends Component {
  state = {
    text: "",
    showAttachMenu: false,
    showMapModal: false,
    mapCoords: null,
    mapAddress: null,
    showUploadingDocModal: false,
    youtubeID: null,
    showYouTube: false,
  };
  send = async data => {
    try {
      if (!data.text || data.text.length == 0) {
        alert("No message entered");
        return;
      }
      this.props.onPressSend(data);
      this.myTextInput?.setNativeProps?.({ text: "" });
      this.setState({ text: "", showYouTube: false, showURL: false });
    } catch (err) {
      console.log("Known error (functionality not broken)", err); //xx
    }
  };
  attachPicture = async () => {
    this.setState({ showGetPicture: true });
  };
  attachDocument = async () => {
    try {
      if (Platform.OS == "ios") await global.timeout(400)
      const { type, uri, name, size } = await DocumentPicker.getDocumentAsync()
      if (type == 'success') {
        await global.timeout(100)
        console.log("Document selected for sharing", { type, uri, name, size })
        if (size > 20000000) return alert("File must be smaller than 20 MB")

        const sizeString = Math.round(size / 100000) / 10 + " MB"
        const nameString = name ? name.substring(0, 40) : "-"
        Alert.alert("Share Document?", nameString + " (" + sizeString + ")",
          [{ text: "Cancel", onPress: () => { } },
          { text: "Yes", onPress: () => { this.executeAttachDocument({ type, uri, name, nameString, size, sizeString }) } }],
          { cancelable: true });
      } else {
        console.log("No document picked, status " + (type || "unknown!"))
      }
    } catch (e) { global.warn(e, "Error attachDocument") }
  };
  executeAttachDocument = async (params) => {
    //xx tbd: delete uploaded file if upload was cancelled but still went through
    //xx tbd: delete uploaded files after 30 days or so (with users aware of it)
    const { type, uri, name, nameString, size, sizeString } = params
    try {
      this.setState({ showUploadingDocModal: true });
      const thisDocumentUploadID = Math.floor(Math.random() * 8999999999999999 + 1000000000000000);
      this.activeDocumentUploadID = thisDocumentUploadID

      const fileArray = name.split(".")
      let fileType = fileArray[fileArray.length - 1]
      if (fileType.length > 7) fileType = ""

      const storagePath =
        "ChatDocumentsH/" +
        this.props.recordID?.substring(0, 5) +
        "__" +
        Math.floor(Math.random() * 89999999 + 10000000) + (fileType ? ("." + fileType) : "");

      const blob = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          resolve(xhr.response);
        };
        xhr.onerror = function (e) {
          console.log(e);
          reject(new TypeError("Network request failed"));
        };
        xhr.responseType = "blob";
        xhr.open("GET", uri, true);
        xhr.send(null);
      });

      const ref = firebase
        .storage()
        .ref()
        .child(storagePath);
      const snapshot = await ref.put(blob);
      const documentURL = await ref.getDownloadURL();

      if (this.state.showUploadingDocModal && this.activeDocumentUploadID == thisDocumentUploadID) { // execute only if upload hasn't been cancelled
        const document = { url: documentURL, name, type: fileType, size: sizeString }
        this.props.onPressSend({ text: "", document })

        this.setState({ showUploadingDocModal: false });
      }
    } catch (err) {
      this.setState({ showUploadingDocModal: false });
      await global.timeout(200)
      alert("Error while uploading. Check internet connection and try again");
      global.warn(err, "UpDoc1")
    }
  }

  render() {
    return (
      <View style={{ flexDirection: "row", padding: 1 }}>
        <View
          style={{
            backgroundColor: "#fff",
            borderRadius: 25,
            borderWidth: 0.5, borderColor: "#000",
            flexDirection: "row",
            alignSelf: "stretch",
            marginLeft: 60,
            marginBottom: 7,
            flex: 1008866
          }}
        >
          <View style={{ padding: 1, alignItems: "center", flex: 1 }}>
            <TextInput
              style={{
                alignSelf: "stretch",
                height: 45,
                color: "#000",
                paddingLeft: 10
              }}
              placeholder="Type a message"
              placeholderTextColor="red"
              //textAlign= "center"
              underlineColorAndroid="transparent"
              underlineColor={"transparent"}
              returnKeyType="send"
              onChangeText={text => this.setState({ text })}
              onSubmitEditing={() => this.send({ text: this.state.text })}
              ref={component => (this.myTextInput = component)}
              // clearButtonMode="always" //xx how set color?
              value={this.state.text}
            />
          </View>

          <TouchableOpacity
            style={{
              alignItems: "center",
              alignSelf: "flex-start",
              padding: 1,
              marginHorizontal: 4.5,
              width: 30,
              height: 45,
              justifyContent: "center",
              borderTopEndRadius: 20,
              borderBottomEndRadius: 20
            }}
            onPress={() => {
              this.setState({ showAttachMenu: true });
              //this.attachPicture();
            }}
          >
            <Image
              style={{
                width: 24,
                height: 24,
                marginRight: 8,
                tintColor: "red"
              }}
              source={require("../../../images/icons/Attach.png")}
            />
          </TouchableOpacity>
          {Platform.OS == "ios" && <View style={{ width: 42 }} />}
          {Platform.OS == "ios" && <RecordMessage onSend={this.send} type={"health"} />}
        </View>
        {this.state.text.length > 0 && (
          <TouchableOpacity
            style={{
              alignItems: "center",
              alignSelf: "flex-end",
              padding: 1,
              backgroundColor: "red",
              marginHorizontal: 4.5,
              width: 45,
              height: 47,
              marginBottom: 7,
              justifyContent: "center",
              borderTopEndRadius: 25,
              borderBottomEndRadius: 25
            }}
            onPress={() => this.send({ text: this.state.text })}
          >
            <Image
              style={{
                width: 20,
                height: 20,
                marginRight: 1,
                tintColor: "white"
              }}
              source={require("../../../images/icons/Send.png")}
            />
          </TouchableOpacity>
        )}
        {this.state.showGetPicture && (
          <GetPicture
            onDone={pictureURL => {
              this.setState({ showGetPicture: false });
              console.log("PictureURL", pictureURL);
              if (pictureURL) {
                this.props.onNewPicture(pictureURL);
              }
            }}
            item={"HCHAT_PICTURE"}
            chatID={"rec_" + this.props.recordID}
          />
        )}
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.showAttachMenu}
          style={{}}
          onRequestClose={() => {
            //alert("Modal has been closed.");
          }}
        >
          <TouchableWithoutFeedback
            onPress={() => this.setState({ showAttachMenu: false })}
          >
            <View
              style={{
                height: "100%",
                width: "100%",
                alignItems: "flex-end",
                justifyContent: "flex-end"
              }}
            >
              <View
                style={{
                  marginRight: 20,
                  marginBottom: 100,
                  height: 150,
                  width: 200,
                  backgroundColor: "rgba(256,256,256,.9)",
                  borderRadius: 5,
                  borderColor: "red",
                  borderWidth: 1
                }}
              >
                <TouchableOpacity
                  style={{
                    height: 50,
                    width: 200,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 15,
                    borderBottomWidth: 1,
                    borderColor: "red"
                  }}
                  onPress={async () => {
                    this.setState({ showAttachMenu: false });
                    await new Promise(resolve => {
                      setTimeout(resolve, 100);
                    });
                    this.attachPicture();
                  }}
                >
                  <Text style={{ color: "#003", fontWeight: "bold" }}>
                    Share Picture
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    height: 50,
                    width: 200,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 15,
                    borderBottomWidth: 1,
                    borderColor: "red"
                  }}
                  onPress={async () => {
                    this.setState({ showAttachMenu: false });
                    await new Promise(resolve => {
                      setTimeout(resolve, Platform.OS == "ios" ? 500 : 100);
                    });
                    this.attachDocument();
                  }}
                >
                  <Text style={{ color: "#003", fontWeight: "bold" }}>
                    Share Document
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    height: 50,
                    width: 200,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 15,
                    borderBottomWidth: 1,
                    borderColor: "red"
                  }}
                  onPress={async () => {
                    await this.setState({ showAttachMenu: false });
                    await new Promise(resolve => {
                      setTimeout(resolve, 100);
                    });
                    this.setState({ showMapModal: true });
                  }}
                >
                  <Text style={{ color: "#003", fontWeight: "bold" }}>
                    Share Location
                    </Text>
                </TouchableOpacity>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
        <Modal
          animationType="slide"
          transparent={false}
          visible={this.state.showMapModal}
          onRequestClose={() => {
            //leave this here
          }}
        >
          <MapScreen
            getUpdatedLocation={(address, coords) => {
              this.setState({
                mapCoords: coords,
                mapAddress: address
              });
              // console.log(
              //   "mapScreen data successfully passed! address: ",
              //   this.state.mapAddress,
              //   " coordinates passed: ",
              //   this.state.mapCoords
              // );
            }}
          //addresstoMapScreen={this.getCoordinates()}
          />

          <View
            style={{
              height: "5%",
              width: "15%",
              margin: 20,
              marginTop: -100,
              flexDirection: "row"
            }}
          >
            <TouchableOpacity
              onPress={() => {
                this.setState({ showMapModal: false });
              }}
            >
              <Text
                style={{
                  fontSize: 15,
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "grey",
                  padding: 5,
                  borderRadius: 5
                }}
              >
                Cancel
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={async () => {
                await this.setState({ showMapModal: false });
                let a = this.state.mapCoords;
                let b = this.state.mapAddress;
                await this.setState({
                  location: { coords: a },
                  address: b
                });
                this.props.onPressSend({
                  text: "", location: {
                    address: this.state.address,
                    coords: this.state.location.coords
                  }
                });
              }}
            >
              <Text
                style={{
                  fontSize: 15,
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "green",
                  padding: 5,
                  borderRadius: 5,
                  marginLeft: 15
                }}
              >
                Set
              </Text>
            </TouchableOpacity>
          </View>
        </Modal>
        <Modal
          animationType="fade"
          transparent={true}
          visible={this.state.showUploadingDocModal}
          style={{}}
          onRequestClose={() => {
            //alert("Modal has been closed.");
          }}
        >
          <TouchableWithoutFeedback
          // onPress={() => this.setState({ showUploadingDocModal: false })}
          >
            <View
              style={{
                height: "100%",
                width: "100%",
                alignItems: "flex-end",
                justifyContent: "flex-end"
              }}
            >
              <View
                style={{
                  flex: 1,
                  width: "92%",
                  alignSelf: "center",
                  marginVertical: 20,
                  backgroundColor: "rgba(0,0,00,.9)",
                  borderRadius: 5,
                  borderColor: "#008866",
                  borderWidth: 1,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <ActivityIndicator size="large" color={"#9ab"} />
                <Text style={{ color: "#ccc", fontWeight: "bold", fontSize: 24, marginTop: 12 }}>
                  Uploading Document
                  </Text>
                <Text style={{ color: "#ccc", fontWeight: "bold", marginTop: 12 }}>
                  This may take a bit...
                  </Text>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showUploadingDocModal: false })
                    this.activeDocumentUploadID = 0
                  }}
                  style={{ marginTop: 75 }}>
                  <Text style={{ color: "#ccc", fontWeight: "bold", }}>Cancel</Text>
                </TouchableOpacity>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      </View>
    );
  }
}


export default class HealthChat extends Component {

  state = {
    chatMessages: [],
    nOfUnread: 0,
    sentBuffer: [],
    isDoctor: false,
    complaint: "",
    myPictureURL: global.myProfilePictureURL,
    lastRead: null,

    keyboardSpacerHeight: 0,
    keyboardHeight: 0,
    chatProperties: {
      opponentName: "********",
      opponentProfilePictureURL: null,
    },
    youtubeID: null,
    youtubeTitle: null,
    youtubeThumbnailURL: null,
    urlTitle: null,
    urlThumbnail: null,
    urlLink: null,

    replyMessage: null,

    showTypeBox: false,
    showGetPicture: false,
    screenFocused: true,
    messagesLoaded: false
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };

    this.nOfRead = 0
  }

  async componentDidMount() {

    this.keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      this._keyboardDidShow
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      this._keyboardDidHide
    );

    try {

      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Health")
        .doc("data")
        .collection("HealthRecords")
        .doc(this.props.recordID)
        .onSnapshot(this.updateRecord);
    } catch (err) {
      alert("Could not fetch data. Check your internet connection");
      console.log("Slow internet?", err);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && !prevProps.visible) {
      //reset unread count
      this.nOfRead = (this.state.chatMessages.length || 0) + this.state.complaint ? 1 : 0
      this.props.setNotifications(0)
      this.markRecordAsRead()

    }
  }

  async componentWillUnmount() {
    console.log("Component HealthChat will unmount");
    if (this.keyboardDidShowListener) this.keyboardDidShowListener.remove();
    if (this.keyboardDidHideListener) this.keyboardDidHideListener.remove();
    this.unsubscribeFirestore();
  }

  _keyboardDidShow = async e => {
    if (Platform.OS == "ios") this.setState({ keyboardHeight: e.endCoordinates.height - 45 });
    // console.log("Keyboard Shown", e, Dimensions.get("window").height, "-", Dimensions.get("screen").height);
    if (Platform.OS === "ios") {
      if (Dimensions.get("window").height > 800) this.setState({ keyboardSpacerHeight: 85 });
      else this.setState({ keyboardSpacerHeight: 60 });
    } else {
      if (true) { // was: Platform.Version <= 19
        this.setState({ keyboardSpacerHeight: 80, keyboardHeight: 20 });
      }
    }
    await new Promise(resolve => { setTimeout(resolve, 50); });
    if (this.scrollView !== undefined && this.scrollView !== null) this.scrollView.scrollToEnd({ animated: true });
  };


  _keyboardDidHide = async () => {
    // console.log("Keyboard Hidden");
    this.setState({ keyboardSpacerHeight: 0 });
    this.setState({ keyboardHeight: 0 });
    await global.timeout(100)
    if (Platform.OS == "ios" && this.scrollView !== undefined && this.scrollView !== null) this.scrollView.scrollToEnd({ animated: false });
  };

  sendMessage = async (data) => {
    console.log("Sending chat message");

    try {
      if (!this.state.messagesLoaded) await global.timeout(2000)
      if (!this.state.messagesLoaded) await global.timeout(4000)
      let now = await firebase.firestore.Timestamp.now();
      let addThis = {
        text: data.text,
        author: this.state.isDoctor ? "doctor" : "patient",
        timeSent: now,
        pending: true,
        ...(this.state.youtubeID && { youtubeID: this.state.youtubeID }),
        ...(this.state.youtubeTitle && { youtubeTitle: this.state.youtubeTitle }),
        ...(this.state.youtubeThumbnail && { youtubeThumbnailURL: this.state.youtubeThumbnail }),
        ...(this.state.urlTitle && { urlTitle: this.state.urlTitle }),
        ...(this.state.urlThumbnail && { urlThumbnail: this.state.urlThumbnail }),
        ...(this.state.urlLink && { urlLink: this.state.urlLink }),
        ...(this.state.replyMessage && { replyMessage: this.state.replyMessage }),
      };

      if (data.pictureURL) addThis.pictureURL = data.pictureURL;
      if (data.video) addThis.video = data.video;
      if (data.contactID) addThis.contactID = data.contactID;
      if (data.postID) addThis.postID = data.postID;
      if (data.location) addThis.location = data.location;
      if (data.document) addThis.document = data.document;

      await this.setState({
        sentBuffer: [...this.state.sentBuffer, addThis], replyMessage: null
      });

      if (data.uploadFunction) { // case VOICE MESSAGE
        const voiceURL = await data.uploadFunction()
        console.log("voiceURL: ", voiceURL)
        addThis.text = ""
        addThis.voiceURL = voiceURL
        addThis.voiceDuration = data.voiceDuration
      }

      if (!global.myUID) await global.timeout(8000)
      await fetch(global.cloudFunctionURL + "sendConsultationMessage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          messageData: addThis,
          recordID: this.props.recordID,
          action: "send",
          noDoctorYet: this.props.noDoctorYet, // for text chat only
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
    } catch (e2) {
      alert(e2);
      console.log(e2);
    }
  };

  markRecordAsRead = async () => {
    if (!this.state.messagesLoaded) await global.timeout(2000)
    if (!this.state.messagesLoaded) await global.timeout(7000)
    if (!this.state.messagesLoaded) return;

    if (typeof global.healthChatNotifications != "object" || global.healthChatNotifications == null) return;
    if (typeof global.healthChatNotifications.patient != "object" || global.healthChatNotifications.patient == null) return;
    if (typeof global.healthChatNotifications.doctor != "object" || global.healthChatNotifications.doctor == null) return;
    if (this.state.isDoctor && !global.healthChatNotifications.doctor[this.props.recordID]) return; // (already 0)
    if (!this.state.isDoctor && !global.healthChatNotifications.patient[this.props.recordID]) return; // (already 0)

    try {
      await fetch(global.cloudFunctionURL + "sendConsultationMessage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          recordID: this.props.recordID,
          action: "markRead",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
    } catch (e2) {
      global.warn(e2, "y34")
    }
  };

  updateRecord = async querySnapshot => {
    const record = querySnapshot.data()
    const chatMessages = record.chatMessages
    const complaint = record.data.complaint

    if (complaint) this.setState({ complaint })

    if (!this.state.messagesLoaded) { // first time
      let isDoctor, myAuthorID
      if (record.patientProfileID == global.myProfileID) {
        isDoctor = false;
        myAuthorID = "patient"
      } else if (global.amDoctor) {
        // } else if (record.doctorProfileID == global.myHealthProfileID) {
        isDoctor = true;
        myAuthorID = "doctor";
        if (!global.myHealthPictureURL) this.loadMyHealthProfile()
      } else {
        return alert("Error loading chat.")
      }
      let showTypeBox = false
      if ((record.status == "OPEN" || record.status == "CREATED_BY_PATIENT")
        && ((!!global.myProfileID && record.patientProfileID == global.myProfileID)
          || global.amDoctor && record.doctorProfileID == global.myHealthProfileID)) {
        showTypeBox = true
      }

      this.setState({ isDoctor, myAuthorID, showTypeBox })
      this.loadOpponent(record, isDoctor)
    }
    let needsRecordMarkedAsRead = false
    if (chatMessages.length > 0) {
      const newSentBuffer = this.state.sentBuffer.filter(message => {
        let returnValue = true
        chatMessages.forEach(msg => {
          if (msg.text == message.text) returnValue = false
        })
        return returnValue;
      })

      if (chatMessages.length > this.state.chatMessages.length && this.props.visible) needsRecordMarkedAsRead = true

      this.setState({ chatMessages, sentBuffer: newSentBuffer })
    }
    if (!!record.lastRead) this.setState({ lastRead: record.lastRead })
    await this.setState({ messagesLoaded: true, })

    //unread message count
    const nOfTotal = (chatMessages.length || 0) + 1//(complaint ? 1 : 0)
    if (this.props.visible) this.nOfRead = nOfTotal
    else this.props.setNotifications(nOfTotal - this.nOfRead)

    if (needsRecordMarkedAsRead) this.markRecordAsRead() // happening after messagesLoaded==true
  };

  loadOpponent = async (record, isDoctor) => {
    try {
      if (!isDoctor) {
        if (record.doctorProfileID) {
          const profileDoc = await firebase
            .firestore()
            .collection("Health")
            .doc("data")
            .collection("HealthProfiles")
            .doc(record.doctorProfileID)
            .get()
          if (profileDoc) {
            const profile = profileDoc.data()
            this.setState({ chatProperties: { opponentName: profile.profileName, opponentProfilePictureURL: profile.profilePictureURL, } })
          }
        }
      } else {
        const profileDoc = await firebase
          .firestore()
          .collection("Profiles")
          .doc(record.patientProfileID)
          .get()
        if (profileDoc) {
          const profile = profileDoc.data()
          this.setState({ chatProperties: { opponentName: profile.profileName, opponentProfilePictureURL: profile.profilePictureURL, } })
        }
      }
    } catch (error) {
      global.warn(error, "Error9lo")
    }
  }

  loadMyHealthProfile = async () => {
    try {
      const profileDoc = await firebase
        .firestore()
        .collection("Health")
        .doc("data")
        .collection("HealthProfiles")
        .doc(global.myHealthProfileID)
        .get()
      if (profileDoc) {
        const profile = profileDoc.data()
        global.myHealthPictureURL = profile.profilePictureURL || ""
        this.setState({ myPictureURL: global.myHealthPictureURL })
      }
    } catch (error) {
      global.warn(error, "lmhp")
    }
  }

  deleteMessage = (m) => {
    Alert.alert("Delete Message?", "",
      [{ text: "Cancel", onPress: () => { } },
      { text: "Yes", onPress: () => { this.executeDeleteMessage(m) } }],
      { cancelable: true });
  }
  executeDeleteMessage = async (m) => {
    try {
      this.setState({ aaDelete: true })
      if (!global.myUID) return
      const r = await fetch(global.cloudFunctionURL + "sendConsultationMessage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          messageData: m,
          recordID: this.props.recordID,
          action: "delete",
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      this.setState({ aaDelete: false })
      if (r2.userMsg) alert(r2.userMsg)
      if (r2.msg == "SUCCESS") {
        //
      }
    } catch (e2) {
      alert("Error deleting");
      this.setState({ aaDelete: false })
      console.log(e2);
    }
  }

  createMessageList = () => {
    let lastAuthor = 0;
    let sameAuthor = false;
    let lastDate = new Date("2000-01-02 10:34:23");
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    return ["@complaint@", ...this.state.chatMessages, ...this.state.sentBuffer].map((m, i) => {
      if (m == "@complaint@") return <Message
        type={"health"}
        authorMe={!this.state.isDoctor}
        key={"mComplaint"}
        text={"Complaint: " + this.state.complaint || ""}
        time={""}
        picURL={!this.state.isDoctor ? global.myProfilePictureURL : this.state.chatProperties.opponentProfilePictureURL || ""}
        navigation={this.props.navigation}
        replyMessage={m.replyMessage}
        replyMessageAuthorMe={m.replyMessage?.author == this.state.myAuthorID}
        replyToThis={() => { this.setState({ replyMessage: { text: "Complaint: " + this.state.complaint || "", author: "patient" } }); }}
        disableDelete={true}
        disableReply={!this.state.showTypeBox}
      />
      // if (m == "@complaint@" && this.state.complaint) return <SystemMessage key={"sm_complaint"} text={"Complaint: " + this.state.complaint} />

      if (lastAuthor === m.author) {
        sameAuthor = true;
      } else {
        sameAuthor = false;
      }
      lastAuthor = m.author;

      let sysMsg = null;
      let mDate = (global.stampToDate) && global.stampToDate(m.timeSent)

      // // let lastDate = firebase.firestore.FieldValue.serverTimestamp();
      if (
        lastDate.getDate() == mDate.getDate() &&
        lastDate.getMonth() == mDate.getMonth()
      ) {
        sysMsg = <View key={"v_" + i} />;
      } else {
        let dateString = String(
          mDate.getDate() +
          "-" +
          months[mDate.getMonth()] +
          "-" +
          mDate.getFullYear()
        );
        sysMsg = <SystemMessage key={"sm_" + i} text={dateString} />;
      }
      let hoursString = "00:00am";
      let leadingZero = mDate.getMinutes() < 10 ? "0" : "";
      if (mDate.getHours() < 12) {
        hoursString =
          mDate.getHours() +
          ":" +
          leadingZero +
          mDate.getMinutes() +
          "am";
      } else {
        if (mDate.getHours() < 13) {
          hoursString =
            mDate.getHours() +
            ":" +
            leadingZero +
            mDate.getMinutes() +
            "pm";
        } else {
          hoursString =
            mDate.getHours() -
            12 +
            ":" +
            leadingZero +
            mDate.getMinutes() +
            "pm";
        }
      }
      lastDate = mDate;

      const authorMe = this.state.isDoctor ? (m.author == "doctor") : (m.author == "patient")
      return [
        // this.props.showSystemMessages ? sysMsg : <View />,
        <Message
          type={"health"}
          authorMe={authorMe}
          key={i + m.text?.substring?.(0, 30) || ""}
          text={m.text}
          pictureURL={m.pictureURL}
          voiceURL={m.voiceURL}
          voiceDuration={m.voiceDuration}
          video={m.video}
          contactID={m.contactID}
          postID={m.postID}
          inquiry={m.inquiry}
          location={m.location}
          document={m.document}
          youtubeID={m.youtubeID}
          youtubeTitle={m.youtubeTitle}
          youtubeThumbnailURL={m.youtubeThumbnailURL}
          urlTitle={m.urlTitle}
          urlThumbnail={m.urlThumbnail}
          urlLink={m.urlLink}
          followOnMessage={sameAuthor}
          time={hoursString}
          picURL={authorMe ? this.state.myPictureURL : this.state.chatProperties.opponentProfilePictureURL || ""}
          pending={m.pending}
          renderContent={true}
          renderVideo={false}
          navigation={this.props.navigation}
          read={(authorMe) && !m.pending
            && (this.state.lastRead?.[this.state.isDoctor ? "patient" : "doctor"]
              && (m.timeSent.toDate() < this.state.lastRead[this.state.isDoctor ? "patient" : "doctor"].toDate()))}
          replyMessage={m.replyMessage}
          replyMessageAuthorMe={m.replyMessage?.author == this.state.myAuthorID}
          replyToThis={() => { this.setState({ replyMessage: m }); }}
          disableDelete={!this.state.isDoctor || m.author !== "doctor" || !this.state.showTypeBox}
          disableReply={!this.state.showTypeBox}
          deleteThis={() => { this.deleteMessage(m) }}
        />
      ];
    });
  };
  createReplyMessage = () => {
    const m = this.state.replyMessage || {}
    return [
      <View style={{ height: 200 }} />,
      <View style={{ flexDirection: "row", marginHorizontal: 30, justifyContent: "space-between", alignItems: "flex-end", }}>
        <View style={{}}><Text style={{ color: "#000", fontSize: 18, fontWeight: "bold", marginBottom: 10 }}>Replying to </Text></View>
        <TouchableOpacity style={{ paddingHorizontal: 20, paddingVertical: 10 }} onPress={() => { this.setState({ replyMessage: null }) }}>
          <Image
            style={{ width: 35, height: 35, opacity: 1 }}
            source={require("../../../images/icons/CancelBlack.png")}
          />
        </TouchableOpacity>
      </View>,
      <Message
        isReplyMessage={true}
        type={"health"}
        disableReply={true}
        authorMe={m.author == this.state.myAuthorID}
        text={m.text}
        pictureURL={m.pictureURL}
        video={m.video}
        voiceURL={m.voiceURL}
        voiceDuration={m.voiceDuration}
        document={m.document}
        contactID={m.contactID}
        postID={m.postID}
        youtubeID={m.youtubeID}
        youtubeTitle={m.youtubeTitle}
        youtubeThumbnailURL={m.youtubeThumbnailURL}
        urlTitle={m.urlTitle}
        urlThumbnail={m.urlThumbnail}
        urlLink={m.urlLink}
        // inquiry={m.inquiry}
        location={m.location}
        followOnMessage={true}
        time={""}
        picURL={""}
        renderContent={true}
        renderVideo={false}
        navigation={this.props.navigation}
        // replyMessage={m.replyMessage}
        heightCalculated={(h) => { if (h !== this.state.replyMessageHeight) this.setState({ replyMessageHeight: h }) }}
        //replyToThis={() => { this.setState({ replyMessage: m }) }}
        disableDelete
      />
    ];
  };

  updateInput = (youtubeID, youtubeThumbnail, youtubeTitle) => {
    this.setState({ youtubeID, youtubeThumbnail, youtubeTitle, urlTitle: null, urlThumbnail: null, urlLink: null })
  }
  updateUrlInput = (urlTitle, urlThumbnail, urlLink) => {
    this.setState({ urlTitle, urlThumbnail, urlLink, youtubeID: null, youtubeThumbnail: null, youtubeTitle: null })
  }

  render() {
    const sWidth = Dimensions.get("window").width
    const sHeight = Dimensions.get("window").height
    if (!this.props.visible) return <View />
    return (
      <View style={this.props.style ? this.props.style : {
        position: "absolute", bottom: this.state.keyboardHeight, left: 0, width: "100%",
        height: !this.props.visible ? 0 : ((sHeight - 65 - this.state.keyboardHeight) / ((this.state.keyboardHeight || this.props.isTextChat) ? 1 : 2)),
        ...(this.props.addedStyle && { ...this.props.addedStyle })
      }}>
        <KeyboardAvoidingView
          behavior={Platform.OS == "ios" ? "padding" : null}
          style={{
            flex: 1,
          }}
        >
          <View
            style={{
              flex: 1
            }}
          >
            <View
              style={{
                height: 1,
              }}
            />
            {/* <ChatHeader navigation={this.props.navigation} /> */}
            <ScrollView
              ref={ref => (this.scrollView = ref)}
              onContentSizeChange={(contentWidth, contentHeight) => {
                this.scrollView.scrollToEnd({ animated: true });
              }}
              style={{
                flex: 1
              }}
            >
              <View style={{ height: 150 }} />
              {!this.state.replyMessage && this.createMessageList()}
              {!this.state.replyMessage && this.state.aaDelete && <ActivityIndicator size="small" color="#630d1a" animating={true} />}
              {!!this.state.replyMessage && this.createReplyMessage()}
            </ScrollView>
            {this.state.showTypeBox && (
              <TypeBox
                ref={ref => (this.myTypeBox = ref)}
                onPressSend={this.sendMessage}
                navigation={this.props.navigation}
                recordID={this.props.recordID}
                getYoutubeDetail={this.updateInput}
                getUrlDetail={this.updateUrlInput}
                onNewPicture={pictureURL => {
                  this.sendMessage({ text: "", pictureURL });
                }}
                replyingToMessage={!!this.state.replyMessage}
                onBlur={() => { this.setState({ replyMessage: null }) }}
              />
            )}
            {/* <View
              style={{
                height: this.state.keyboardSpacerHeight - 65
              }}
            /> */}
          </View>

          {/* <NavigationEvents
          onDidFocus={async payload => {
            this.setState({ screenFocused: true });

            await new Promise(resolve => {
              setTimeout(resolve, 500);
            });
            if (!this.state.messagesLoaded) {
              await new Promise(resolve => {
                setTimeout(resolve, 1000);
              });
            }
            if (!this.state.messagesLoaded) {
              await new Promise(resolve => {
                setTimeout(resolve, 1000);
              });
            }
            if (!this.state.messagesLoaded) {
              await new Promise(resolve => {
                setTimeout(resolve, 1000);
              });
            }
            if (!this.state.messagesLoaded) {
              await new Promise(resolve => {
                setTimeout(resolve, 1000);
              });
            }
            if (!this.state.messagesLoaded) {
              await new Promise(resolve => {
                setTimeout(resolve, 1000);
              });
            }
            if (!this.state.messagesLoaded) {
              await new Promise(resolve => {
                setTimeout(resolve, 1000);
              });
            }
            if (!this.state.messagesLoaded) {
              return;
            }

            if (this.props.navigation.getParam("chatID", null) != null) {
              global.messageOpen = this.props.navigation.getParam(
                "chatID",
                null
              );
            }

            let text = this.props.navigation.getParam("text", null);
            if (text) {
              this.sendMessage(text, pictureURL);
              this.props.navigation.setParams({
                text: false
              });
            }
            let pictureURL = this.props.navigation.getParam(
              "pictureURL",
              false
            );
            if (pictureURL) {
              this.sendMessage("", pictureURL, false, false, false, false);
              this.props.navigation.setParams({
                pictureURL: false
              });
            }
            let contactID = this.props.navigation.getParam("contactID", false);
            if (contactID) {
              this.sendMessage("", false, contactID, false, false, false);
              this.props.navigation.setParams({
                contactID: false
              });
            }
            let location = this.props.navigation.getParam("location", false);
            if (location) {
              this.sendMessage("", false, false, location, false, false);
              this.props.navigation.setParams({
                location: false
              });
            }

            let postID = this.props.navigation.getParam("postID", false);
            if (postID) {
              this.sendMessage("", false, false, false, false, postID);
              this.props.navigation.setParams({
                postID: false
              });
            }
            let inquiryImageURL = this.props.navigation.getParam(
              "inquiryImageURL",
              false
            );
            if (inquiryImageURL) {
              this.sendMessage("", inquiryImageURL, false, false, false, false);
              this.props.navigation.setParams({
                inquiryImageURL: false
              });
            }

            this.scrollView.scrollToEnd({ animated: true });

            let report = this.props.navigation.getParam("report", null);
            if (report) {
              this.props.navigation.setParams({
                report: false
              });
              await new Promise(resolve => {
                setTimeout(resolve, 500);
              });
              alert(
                "Your report has been filed. We will look into it and take action when appropriate."
              );
            }
          }}
        />
        <NavigationEvents
          onWillBlur={async payload => {
            this.setState({ screenFocused: false });
            global.messageOpen = "";
          }}
        /> */}
        </KeyboardAvoidingView>
      </View>
    );
  }
}

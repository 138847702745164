import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  Animated,
  Platform,
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Permissions from 'expo-permissions';
import * as Location from 'expo-location';


class FadeInView extends React.Component {
  state = {
    fadeAnim: new Animated.Value(0), // Initial value for opacity: 0
    timerDone: false,
  };

  async componentDidMount() {
    await new Promise(resolve => {
      setTimeout(resolve, 1500);
    });
    this.setState({ timerDone: true });

    Animated.timing(
      // Animate over time
      this.state.fadeAnim, // The animated value to drive
      {
        toValue: 1, // Animate to opacity: 1 (opaque)
        duration: 500, // Make it take a while
        useNativeDriver: true, //TEST THIS!!!
      }
    ).start(); // Starts the animation
  }

  render() {
    let { fadeAnim } = this.state;

    // if (Platform.OS === "ios") {
    if (true) {
      return (
        <Animated.View // Special animatable View
          style={{
            ...this.props.style,
            opacity: fadeAnim,
            backgroundColor: "transparent" // Bind opacity to animated value
          }}
        >
          {this.props.children}
        </Animated.View>
      );
    } else {
      return (
        <View>
          {!!this.state.timerDone && <View>{this.props.children}</View>}
        </View>
      );
    }
  }
}

export default class BOGuide extends Component {
  state = {
    viewGuide: false,
    screen: "HELLO", //can be "MAIN" "NOLOCATION"
    screenWelcome: "LOCATION", //can be "CHAT" // type welcome has several main screens

    locationStatus: "",
    pushStatus: "",
    activityIndicatorAnimating: false,
  }

  async componentDidMount() {
    if (this.props.type == "chat" || this.props.type == "welcome") await this.getPushNotificationStatusAsync();
    if (this.props.type == "location" || this.props.type == "welcome") await this.getLocationStatusAsync();
    let hide = false, hideHello = false
    if (this.props.type !== "welcome") {
      hide = await AsyncStorage.getItem("hideBOGuide-" + this.props.type);
      hideHello = await AsyncStorage.getItem("hideBOGuide-hello");
    }

    if (this.props.type == "chat" && this.state.pushStatus == "granted") return
    if (this.props.type == "location" && this.state.locationStatus == "granted") return
    if (this.props.type == "welcome" && this.state.locationStatus == "granted") {
      if (this.state.pushStatus == "granted")
        this.setState({ screenWelcome: "EXPLAIN" })
      else this.setState({ screenWelcome: "CHAT" })
    }

    if (!hide) {
      this.setState({
        ...(!hideHello ? { screen: "HELLO" } : { screen: "MAIN" }),
        viewGuide: true
      });
    }
  }

  onDone = async (wasPositive) => {
    try {
      await AsyncStorage.setItem("hideBOGuide-" + this.props.type, "true");
      await AsyncStorage.setItem("hideBOGuide-hello", "true");
      this.props.onDone?.(wasPositive)
      this.setState({ viewGuide: false })
    } catch (e) { console.log(e) }
  }



  getLocationStatusAsync = async () => {
    try {
      const { status } = await Permissions.getAsync(Permissions.LOCATION);
      console.log({ locationStatus: status })
      await this.setState({ locationStatus: status, loadingLocationStatus: false });
    } catch (e) { console.log(e) }
  }


  getPushNotificationStatusAsync = async () => {
    try {
      const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
      console.log({ pushStatus: existingStatus })
      await this.setState({ pushStatus: existingStatus, loading: false });
    } catch (e) { console.log(e) }
  };

  registerForPushNotificationsAsync = async () => {
    console.log("global.myPushToken", global.myPushToken);
    try {
      this.setState({ activityIndicatorAnimating: true });
      const { status: existingStatus } = await Permissions.getAsync(
        Permissions.NOTIFICATIONS
      );
      let finalStatus = existingStatus;

      if (existingStatus !== "granted") {
        const { status } = await Permissions.askAsync(
          Permissions.NOTIFICATIONS
        );
        finalStatus = status;
      }

      // Stop here if the user did not grant permissions
      if (finalStatus !== "granted") {
        this.setState({ activityIndicatorAnimating: false });
        alert(
          "Push notifications are not enabled. Enable permission in your System Settings > Apps"
        );
        if (this.props.type == "welcome") this.setState({ screenWelcome: "EXPLAIN" })
        else this.onDone?.(false)
        return;
      } else {
        this.setState({ pushStatus: "granted" });
        global.setMyPushTokenIfNeeded(); // defined in HomeMainScreen
        this.setState({ activityIndicatorAnimating: false });
        if (this.props.type == "welcome") this.setState({ screenWelcome: "EXPLAIN" })
        else this.onDone?.(true)
      }
    } catch (err) {
      console.log(err);
      alert("Error. Please check your connection");
      this.setState({ activityIndicatorAnimating: false });
      if (this.props.type == "welcome") this.setState({ screenWelcome: "EXPLAIN" })
      else {
        this.setState({ viewGuide: false }); this.props.onDone?.(false)
      }
    }
  };

  allowLocation = async () => {
    try {
      this.setState({ activityIndicatorAnimating: true })
      await this.setLocation()
      this.setState({ activityIndicatorAnimating: false })
      if (this.props.type == "welcome") {
        if (this.state.pushStatus !== "granted") this.setState({ screenWelcome: "CHAT" })
        else this.setState({ screenWelcome: "EXPLAIN" })
      } else this.onDone?.(true)
    } catch (err) {
      console.log(err);
      alert("Error. Please check your connection");
      this.setState({ activityIndicatorAnimating: false });
      if (this.props.type == "welcome") {
        if (this.state.pushStatus !== "granted") this.setState({ screenWelcome: "CHAT" })
        else this.setState({ screenWelcome: "EXPLAIN" })
      } else { this.props.onDone?.(false); this.setState({ viewGuide: false }) }
    }
  };

  setLocation = async (minutes) => {
    try {
      const { status } = await Permissions.askAsync(Permissions.LOCATION);

      if (status !== "granted") {
        if (!global.location) {
          global.location = {
            coords: { latitude: 6.61, longitude: 3.31 },
            address: "Lagos, Nigeria",
          };
          global.myLocation = global.location;
        }
        return false;
      }
      const myLocation = await Location.getCurrentPositionAsync({
        accuracy: 5,
      });
      if (
        myLocation &&
        myLocation.coords &&
        myLocation.coords.latitude &&
        myLocation.coords.longitude
      ) {
        global.myLocation = myLocation;
        global.location = myLocation;
        global.locationLastUpdated = new Date();
        AsyncStorage.setItem("latitude", myLocation.coords.latitude.toString());
        AsyncStorage.setItem(
          "longitude",
          myLocation.coords.longitude.toString()
        );
        return true;
      } else {
        console.log("Error. Wrong location format", myLocation);
        return false;
      }
      //console.log("global location: ", global.location)
    } catch (err) {
      if (!global.location) {
        global.location = {
          coords: { latitude: 6.6, longitude: 3.3 },
          address: "Lagos, Nigeria",
        };
        global.myLocation = global.location;
      }
      console.log(
        "error cx33: Location not found despite permission granted",
        err
      );
      console.warn("cx33: Location not found despite permission granted");
      return false;
    }
  }

  renderHello = () => {

    let text1 = "Hello there!"
    let text2 = "I'm Baba Oja."
    let text3 = "I will guide you through your experience on Wakanda."
    return (<View>
      <View style={{ justifyContent: "center", paddingRight: 30, paddingLeft: 40 }}>
        <Text
          style={{
            fontFamily: "ProximaNovaA-Bold", fontSize: 38,
            alignSelf: "flex-start", marginBottom: 3, color: "#A8CF45",
          }}
        >
          {text1}
        </Text>
        <Text
          style={{
            color: "#fff", fontSize: 22, fontWeight: "bold",
            alignSelf: "flex-start", fontFamily: "ProximaNovaA-Bold",
            marginBottom: 6
          }}
        >
          {text2}
        </Text>
        <Text
          style={{
            color: "#fff", fontSize: 18,
            alignSelf: "flex-start", fontFamily: "ProximaNovaA-Regular",
          }}
        >
          {text3}
        </Text>
      </View>
      {this.state.activityIndicatorAnimating ?
        <ActivityIndicator size="large" color="#A8CF45" style={{ alignSelf: "center", marginTop: 30 }} />
        : <View>
          <TouchableOpacity
            onPress={() => { this.setState({ screen: "MAIN" }) }}
            style={{
              marginTop: 35, width: global.SWIDTH * 0.6, height: 50,
              backgroundColor: "#A9D046", borderRadius: 12, padding: 10,
              alignSelf: "center", alignItems: "center", justifyContent: "center",
            }}
          >
            <Text style={{ fontWeight: "bold", fontFamily: "ProximaNovaA-Bold", fontSize: 18, }}>
              OK  {">>"}</Text>
          </TouchableOpacity>
        </View>}
    </View>)
  }

  renderChat = () => {

    let text1 = "Wakanda is social!"
    let text3 = "We’re a chat app. \n\nEnable push notifications to engage in communities, chat up your friends, or talks to sellers."
    return (<View>
      <View style={{ justifyContent: "center", paddingRight: 20, paddingLeft: 40, paddingTop: 40 }}>
        <Text
          style={{
            fontFamily: "ProximaNovaA-Bold", fontSize: 32,
            alignSelf: "flex-start", marginBottom: 8, color: "#A8CF45",
          }}
        >
          {text1}
        </Text>
        <Text
          style={{
            color: "#fff", fontSize: 16,
            alignSelf: "flex-start", fontFamily: "ProximaNovaA-Regular",
          }}
        >
          {text3}
        </Text>
      </View>
      {this.state.activityIndicatorAnimating ?
        <View style={{ alignSelf: "center", marginTop: 50 }} />
        : <View>
          <TouchableOpacity
            onPress={() => { this.registerForPushNotificationsAsync() }}
            style={{
              marginTop: 40, width: global.SWIDTH * 0.6, height: 50,
              backgroundColor: "#A9D046", borderRadius: 12, padding: 10,
              alignSelf: "center", alignItems: "center", justifyContent: "center",
            }}
          >
            <Text style={{ fontWeight: "bold", fontFamily: "ProximaNovaA-Bold", fontSize: 18, }}>
              Enable Notifications</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              if (this.props.type == "welcome") this.setState({ screenWelcome: "EXPLAIN" })
              else {
                this.setState({ viewGuide: false });
                this.onDone?.(false)
              }
            }}
            style={{
              marginTop: 20, width: global.SWIDTH * 0.6, height: 30,
              alignSelf: "center", alignItems: "center", justifyContent: "center",
            }}
          >
            <Text style={{ fontWeight: "bold", color: "#888", fontFamily: "ProximaNovaA-Regular", fontSize: 16, }}>
              Don't enable</Text>
          </TouchableOpacity>
        </View>}
    </View >)
  }

  renderExplain = () => {

    let text1 = "Let's get started!"
    let text3 = "We need to verify your phone number to be sure it's you... \n\nThen you're part of Wakanda!"
    return (<View>
      <View style={{ justifyContent: "center", paddingRight: 20, paddingLeft: 40, paddingTop: 40 }}>
        <Text
          style={{
            fontFamily: "ProximaNovaA-Bold", fontSize: 32,
            alignSelf: "flex-start", marginBottom: 8, color: "#A8CF45",
          }}
        >
          {text1}
        </Text>
        <Text
          style={{
            color: "#fff", fontSize: 16,
            alignSelf: "flex-start", fontFamily: "ProximaNovaA-Regular",
          }}
        >
          {text3}
        </Text>
      </View>
      <TouchableOpacity
        onPress={() => { this.onDone() }}
        style={{
          marginTop: 35, width: global.SWIDTH * 0.6, height: 50,
          backgroundColor: "#A9D046", borderRadius: 12, padding: 10,
          alignSelf: "center", alignItems: "center", justifyContent: "center",
        }}
      >
        <Text style={{ fontWeight: "bold", fontFamily: "ProximaNovaA-Bold", fontSize: 18, }}>
          OK  {">>"}</Text>
      </TouchableOpacity>
    </View >)
  }

  renderLocation = () => {

    let text1 = "Wakanda is local!"
    let text3 = "We connect you with the shops you can trust near you. \n\nEnable location to show nearby offers, providers, services, jobs and more."
    return (<View>
      <View style={{ justifyContent: "center", paddingRight: 20, paddingLeft: 40, paddingTop: 40 }}>
        <Text
          style={{
            fontFamily: "ProximaNovaA-Bold", fontSize: 32,
            alignSelf: "flex-start", marginBottom: 8, color: "#A8CF45",
          }}
        >
          {text1}
        </Text>
        <Text
          style={{
            color: "#fff", fontSize: 16,
            alignSelf: "flex-start", fontFamily: "ProximaNovaA-Regular",
          }}
        >
          {text3}
        </Text>
      </View>
      {this.state.activityIndicatorAnimating ?
        <View style={{ alignSelf: "center", marginTop: 50 }} />
        : <View>
          <TouchableOpacity
            onPress={() => { this.allowLocation() }}
            style={{
              marginTop: 40, width: global.SWIDTH * 0.6, height: 50,
              backgroundColor: "#A9D046", borderRadius: 12, padding: 10,
              alignSelf: "center", alignItems: "center", justifyContent: "center",
            }}
          >
            <Text style={{ fontWeight: "bold", fontFamily: "ProximaNovaA-Bold", fontSize: 18, }}>
              Allow Location</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              if (this.props.type == "welcome") {
                if (this.state.pushStatus !== "granted")
                  this.setState({ screenWelcome: "CHAT" })
                else this.setState({ screenWelcome: "EXPLAIN" })
              }
              else {
                this.setState({ viewGuide: false });
                this.onDone?.(false)
              }
            }}
            style={{
              marginTop: 20, width: global.SWIDTH * 0.6, height: 30,
              alignSelf: "center", alignItems: "center", justifyContent: "center",
            }}
          >
            <Text style={{ fontWeight: "bold", color: "#888", fontFamily: "ProximaNovaA-Regular", fontSize: 16, }}>
              Don't allow</Text>
          </TouchableOpacity>
        </View>}
    </View>)
  }

  renderContent = () => {
    if (this.state.screen == "HELLO") return this.renderHello()
    //else: screen=="MAIN"
    if (this.props.type == "chat") return this.renderChat()
    if (this.props.type == "location") return this.renderLocation()

    if (this.props.type == "welcome" && this.state.screenWelcome == "LOCATION") return this.renderLocation()
    if (this.props.type == "welcome" && this.state.screenWelcome == "CHAT") return this.renderChat()
    if (this.props.type == "welcome" && this.state.screenWelcome == "EXPLAIN") return this.renderExplain()
  }

  render() {
    console.log(this.state)

    if (!this.state.viewGuide)
      return <View />;

    let overallHeight = 300
    if (this.props.type == "chat") overallHeight = 360
    if (this.props.type == "location") overallHeight = 360
    if (this.props.type == "welcome" && this.state.screenWelcome == "LOCATION") overallHeight = 360
    if (this.props.type == "welcome" && this.state.screenWelcome == "CHAT") overallHeight = 360
    if (this.props.type == "welcome" && this.state.screenWelcome == "EXPLAIN") overallHeight = 330
    if (this.state.screen == "HELLO") overallHeight = 300

    return (
      <FadeInView style={{
        position: "absolute", top: 0, left: 0, height: global.SHEIGHT, width: global.SWIDTH
      }}>
        <View style={{ backgroundColor: "rgba(0,0,0,0.7)", flex: 1, justifyContent: "center" }}>
          <Image
            style={{
              width: 120,
              height: 150,
              margin: 1,
              zIndex: 2
            }}
            source={require("../../images/images/BABAOJA.png")}
          />
          <View
            style={{
              width: global.SWIDTH - 45 - 10 + (global.SWIDTH < 400 ? 20 : 0), marginTop: -63, height: overallHeight,
              margin: 1, left: global.SWIDTH < 400 ? 34 : 45, opacity: 1, backgroundColor: "#000", borderRadius: 80,
              borderTopLeftRadius: 30, borderTopEndRadius: 80, borderBottomEndRadius: 80,
              justifyContent: "center", borderWidth: 3, borderColor: "#A8CF45",
              shadowOpacity: 1, shadowColor: "#A8CF45"
            }}
          >
            {this.renderContent()}
          </View>
        </View>
      </FadeInView >
    );
  }
}

import React, { Component } from "react";
import {
  Text,
  View,
  SafeAreaView,
  Button,
  Image,
  TouchableOpacity,
  Linking
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";


class Community extends Component {
  state = {
    communityID: '',
    name: '',
    description: '',
    pictureURL: '',
    managerProfileID: ''
  }

  componentDidMount() {
    this.unsubscribeFirestore = firebase
      .firestore()
      .collection("Communities")
      .doc(this.props.communityID)
      .onSnapshot(this.updateCommunity)
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  updateCommunity = async communitySnapshot => {
    try {
      let community = communitySnapshot.data();
      let communityName = community.name || '';
      let communityPictureURL = community.communityPictureURL || '';
      let communityDescription = community.description || ''
      let communityID = communitySnapshot.id
      let managerProfileID = community.managerProfileID

      console.log(community)


      this.setState({
        name: communityName,
        pictureURL: communityPictureURL,
        description: communityDescription,
        communityID,
        managerProfileID

      })
    }
    catch (err) {
      console.log("Slow Internet")
    }
  }
  render() {
    console.log(this.state.pictureURL)
    return (

      <TouchableOpacity onPress={() => {
        if (!this.state.communityID) {
          return
        }
        let handoverID = this.state.communityID;
        let mgrID = this.state.managerProfileID
        let membersID = this.state.memberProfileIDs
        this.props.navigation.navigate("meCommunity", { communityID: handoverID, managerID: mgrID, memberProfileIDs: membersID })

      }}>
        <View style={{
          flexDirection: 'row',
          height: 92,
          backgroundColor: "black",
          alignItems: "flex-start",
          marginTop: 5,
          marginHorizontal: 5,
          paddingLeft: 11,
          paddingRight: 11,
          shadowOpacity: 0.5,
          shadowOffset: { width: 0, height: 3 },
          borderBottomColor: "#333",
          borderRadius: 4,
        }}>
          <View
            style={
              {
                flex: 1,
                justifyContent: 'center',
                marginVertical: 15,
              }
            }
          >
            {!!this.state.pictureURL ? (
              <Image
                style={{ width: 82, height: 82, borderRadius: 82 / 2 }}
                source={{
                  uri: this.state.pictureURL
                }}
              />
            ) : (
                <View style={{ width: 82, height: 82, borderRadius: 82 / 2, backgroundColor: "#fff" }} />
              )}

          </View>
          <View style={{ flex: 3, padding: 10, }}>
            <View><Text style={{ color: '#fff', fontWeight: 'bold', }}> {this.state.name}</Text></View>
            <View><Text style={{ color: '#777' }}> {this.state.description}</Text></View>
          </View>
        </View>
      </TouchableOpacity>

    );
  }
}



export default class MeNetworkScreen extends Component {
  static navigationOptions = {
    title: 'My Communities',
    headerStyle: {
      backgroundColor: "#222"
      //height: 15,
    },
  };

  state = {
    communityIDs: [],

  }



  componentDidMount = () => {
    //console.log("navigation prop ", this.props.navigation);
    this.unsubscribeFirestore = firebase
      .firestore()
      .collection("Users")
      .doc(global.myUID)
      .collection("Refs")
      .doc("communityRefs")
      .onSnapshot((data) => {
        this.updateCommunities(data)
      })
  };

  updateCommunities = (data) => {
    this.setState({ communityIDs: data.data().communityIDs })
    console.log(data.data())
  }




  render() {
    console.log(this.state.communityIDs)
    const xHeight = 80;
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#222" }}>
        {this.state.communityIDs.map((communityID, id) => {
          return (
            <View key={id}>
              <Community
                communityID={communityID}
                navigation={this.props.navigation} />
            </View>
          )

        })}


      </SafeAreaView>
    );
  }
}


import React, { Component } from "react";
import { StyleSheet, View, Text, ScrollView, Linking, TouchableOpacity, Dimensions } from "react-native";
import { LinearGradient } from 'expo-linear-gradient'
import firebase from "firebase";
import "firebase/firestore";
import EscrowModal from "../../Me/Wallet/EscrowModal";

//import Swiper from "react-native-swiper";
import Screen1 from "./jobScreen1";
import Screen2 from "./jobScreen2";
import Screen3 from "./jobScreen3";
import Screen4 from "./jobScreen4";
import Screen5 from "./jobScreen5";
import CurrentLocationModal from "../../shared/Map/currentLocationV2Modal";



const getColor = a => {
  console.log("background color is now ", a);
  return a;
};

class ScreenSelector extends Component {
  state = {};
  screenWidth = Dimensions.get("window").width

  render() {
    let width = 30
    //let height = 0
    let selectedScreen = Math.round((this.props.scrollPosition + this.screenWidth) / this.screenWidth)
    let numberStyle = { height: 30, width, margin: 5, borderWidth: 0, borderRadius: 15, justifyContent: "center", alignItems: "center" }
    return (
      <View style={{ flexDirection: "row", height: 40, justifyContent: "center", alignItems: "center", backgroundColor: "#333" }}>
        <TouchableOpacity onPress={() => this.props.onPress("<")}>
          <LinearGradient
            colors={['#707070', '#282828']}
            style={{
              borderWidth: 1, borderColor: '#00FF99', borderRadius: 15,
              height: 30, justifyContent: 'center', alignItems: 'center', width: 30,
              backgroundColor: '#00FF99'
            }}>
            <Text style={{ color: "#888" }} >
              {"<"}
            </Text>
          </LinearGradient>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this.props.onPress(1)}>
          <View style={numberStyle} >
            <Text style={{ color: "#888", ...(selectedScreen == 1 && { fontWeight: "bold", color: "#00FF99" }) }} >
              1
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this.props.onPress(2)}>
          <View style={numberStyle} >
            <Text style={{ color: "#888", ...(selectedScreen == 2 && { fontWeight: "bold", color: "#00FF99" }) }} >
              2
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this.props.onPress(3)}>
          <View style={numberStyle} >
            <Text style={{ color: "#888", ...(selectedScreen == 3 && { fontWeight: "bold", color: "#00FF99" }) }} >
              3
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this.props.onPress(4)}>
          <View style={numberStyle} >
            <Text style={{ color: "#888", ...(selectedScreen == 4 && { fontWeight: "bold", color: "#00FF99" }) }} >
              4
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this.props.onPress(5)}>
          <View style={numberStyle} >
            <Text style={{ color: "#888", ...(selectedScreen == 5 && { fontWeight: "bold", color: "#00FF99" }) }} >
              5
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this.props.onPress(">")}>
          <LinearGradient
            colors={['#707070', '#282828']}
            style={{
              borderWidth: 1, borderColor: '#00FF99', borderRadius: 15,
              height: 30, justifyContent: 'center', alignItems: 'center', width: 30,
              backgroundColor: '#00FF99'
            }}>
            <Text style={{ color: "#888" }} >
              {">"}
            </Text>
          </LinearGradient>
        </TouchableOpacity>
      </View>)
  }
}

export default class JobsNewScreen extends Component {
  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }
  static navigationOptions = ({ navigation }) => {
    return {
      tabBarVisible: false,
      headerTransparent: false,
      title: "Order from " + navigation.getParam('providerProfileName', ''),
      headerTitleStyle: {
        fontWeight: "bold",
        color: "#A8CF45",
      },
      headerStyle: {
        backgroundColor: "#333333"
      }
    }
  };

  state = {
    bColor: "grey",
    itemTitle: "Game of Thrones Season 1-8",
    itemDescription: "GoT Season 1 - 8 Blueray Collection",
    deliveryAddress: '',
    deliveryCoords: null,
    addressDescription: '',
    showLocationModal: false,
    multipartPayment: true,
    postHasDelivery: false, // not needed?
    itemCost: 0, // will be the job charge (excluding delivery fee)
    priceDiscount: 0,
    itemCurrency: "NGN", // will be the currency used to enter (ACN or NGN)
    itemQty: "1",
    deliveryFee: 0,
    totalCost: 0,
    pictureURL: "",
    myCurrency: global.walletType == "ACN" ? "ACN" : "NGN",
    paymentParts: {
      one: 0,
      two: 0
    },
    width: 0,

    postID: "",
    providerProfileID: null,
    providerName: "",
    providerBusinessName: "",
    providerPictureURL: "",
    providerPhoneNumber: "000",
    providerLocation: {
      coords: {
        latitude: 0,
        longitude: 0
      },
      address: "",
      description: ""
    },

    jointChatID: null,
    contactsFollowing: [],
    loading: false,
    error: false,
    activityIndicatorAnimating: false,
    orderAA: false,
    showEscrowModal: false,
    scrollPosition: 0,

    jobDescription: ""
  };

  componentDidMount = async () => {
    const now = new Date()
    if (global.blockedUntil > now) {
      alert("You cannot order while your wallet is temporarily blocked")
      await global.timeout(100)
      this.props.navigation.goBack()
      return;
    }
    const postHasDelivery = this.props.navigation.getParam('hasDelivery', false) || false;
    let itemCost = this.props.navigation.getParam('price', 0);
    let itemCurrency = this.props.navigation.getParam('priceCurrency', "NGN");
    const postID = this.props.navigation.getParam('postID', '');
    const pictureURL = this.props.navigation.getParam('pictureURL', '');
    let priceDiscount = this.props.navigation.getParam('priceDiscount', '');
    const itemTitle = this.props.navigation.getParam('title', '');
    const itemDescription = this.props.navigation.getParam('text', '');
    const providerName = this.props.navigation.getParam('providerProfileName', '');
    const providerBusinessName = this.props.navigation.getParam('providerBusinessName', '');
    const providerPictureURL = this.props.navigation.getParam('providerProfilePictureURL', '');
    const providerProfileID = this.props.navigation.getParam('providerProfileID', '');
    const providerPhoneNumber = this.props.navigation.getParam('providerPhoneNumber', '');
    const providerLocation = this.props.navigation.getParam('providerLocation', ''); // not delivered from profile screen yet. Not used yet though
    if (itemCost < 0) itemCost = 0
    if (!global.walletType) this.setState({ error: true })
    await this.setState({ postHasDelivery, itemCost, itemCurrency, totalCost: itemCost, postID, pictureURL, itemTitle, itemDescription, providerName, providerBusinessName, providerPictureURL, providerProfileID, providerPhoneNumber, providerLocation, priceDiscount })
    if (itemCurrency != this.state.myCurrency) {
      if (this.state.myCurrency == "NGN") {
        alert("You cannot order this offer as its currency is " + itemCurrency)
        this.setState({ error: true })
      } else {
        //case myCurrency=="ACN"
        if (!global.xRates[itemCurrency]) this.setState({ error: true })
        itemCost = Math.round(itemCost / global.xRates[itemCurrency])
        if (priceDiscount) priceDiscount = Math.round(priceDiscount / global.xRates[itemCurrency])
        itemCurrency = "ACN"
        this.setState({ itemCost, totalCost: itemCost, itemCurrency })
        if (priceDiscount) this.setState({ priceDiscount })
      }
    }
    //console.log("STATE", this.state)
    try {
      if (!providerProfileID) alert("System error. Order not possible")
      this.fetchJointItems()
      if (!providerName || !providerPhoneNumber) {
        this.setState({ loading: true })
        const profileDoc = await firebase
          .firestore()
          .collection("Profiles")
          .doc(providerProfileID)
          .get();
        const profile = profileDoc.data();
        this.setState({
          loading: false,
          providerName: profile.profileName || "",
          providerBusinessName: profile.profileNameBusiness || "",
          providerPictureURL: profile.profilePictureURL || "",
          providerPhoneNumber: profile.profilePhoneNumber || "",
          providerLocation: {
            address: profile.locationWorkAddress || "",
            description: profile.locationWorkDescription || "",
            coords: {
              latitude: profile.locationWork
                ? profile.locationWork._lat
                : null,
              longitude: profile.locationWork
                ? profile.locationWork._long
                : null
            }
          },
        })
      }
    } catch (err) {
      console.log("Error fetching offering", err);
    }
  }

  // updatePost = (snapshot)=>{
  //   const post = snapshot.data()

  // }

  componentWillUnmount() {
    console.log("Component Author unmount");
    this.unsubscribeFirestore();
  }

  /*  this.setState({ bColor: this.props.backgroundColor });
   console.log(
     "props color acquired for background and sent to getColor ",
     this.props.backgroundColor
   );
   BCOLOR = this.props.backgroundColor;
   */

  // let cost = this.state.itemCost;

  // await this.setState({
  //   paymentParts: {
  //     one: cost
  //   },

  // });

  // console.log(
  //   "the first installment passed as props is the default item cost; ",
  //   this.state.paymentParts.one,
  //   "item cost is",
  //   this.state.itemCost
  // );

  fetchJointItems = async () => {
    try {
      if (!global.myProfileID) await global.timeout?.(1000)
      if (!global.myProfileID) await global.timeout?.(4000)
      if (!global.myProfileID) return
      const r = await fetch(global.cloudFunctionURL + "getJointItems", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          myUID: global.myUID,
          idToken: global.idToken,
          myProfileID: global.myProfileID,
          targetProfileID: this.state.providerProfileID
        })
      });
      const r2 = await r.json();

      if (r2.msg === "SUCCESS") {
        //console.log("SUCCESS!!!!!", r2);
        this.setState({
          jointChatID: r2.jointChatID,
          contactsFollowing: r2.contactsFollowing
        });
      }
      //console.log("SUCCESS??", r2);
    } catch (error) {
      console.log(error);
    }
  };

  callCall = async () => {
    if (this.state.loading) {
      await global.timeout(1000)
      if (this.state.loading) {
        await global.timeout(1000)
        if (this.state.loading) {
          return
        }
      }
    }
    //alert("Call clicked");
    if (this.state.providerPhoneNumber != "") {
      this.setState({ loading: true });
      Linking.openURL("tel:" + this.state.providerPhoneNumber);
      this.setState({ loading: false });
    } else {
      alert("No phone number found.");
    }
  };

  callChat = async () => {
    let chatID = "";
    let chatProperties = {
      opponentProfileID: this.state.providerProfileID,
      opponentName: this.state.providerName,
      opponentNameBusiness: this.state.providerBusinessName || "",
      opponentProfilePictureURL: this.state.providerPictureURL || ""
    };

    if (this.state.jointChatID !== "") {
      chatID = this.state.jointChatID;
    } else {
      //create a new chat
      this.setState({ activityIndicatorAnimating: true });
      try {
        const r = await fetch(global.cloudFunctionURL + "newChat", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            profileID1: global.myProfileID,
            profileID2: this.state.providerProfileID,
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
        const r2 = await r.json();
        chatID = r2.chatID;
        this.setState({ activityIndicatorAnimating: false });
      } catch (error) {
        this.setState({ activityIndicatorAnimating: false });
        console.log(error);
      }
    }
    // navigate to chatID
    if (chatID) {
      if (chatID != "") {
        this.props.navigation.navigate("chatMain", {
          redirect: true,
          redirectParams: {
            chatID,
            chatProperties
          }
        });
      }
    }
  };

  orderNow = async () => {
    if (this.state.itemCost < 100) return alert("Charge must be at least 100")
    if (this.state.itemCost < this.state.paymentParts.one) return alert("1st installment too high")

    this.setState({ showEscrowModal: true })
  }

  executeOrderNow = async () => {
    try {
      let x = this.props.navigation.getParam('providerProfileID', '');
      console.log('x is: ', x);
      await this.setState({ providerProfileID: x });

      this.setState({ orderAA: true });

      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "createJob",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            jobCharge: this.state.itemCost,
            jobChargeOnArrival: this.state.paymentParts.one || 0,
            jobChargeCurrency: this.state.itemCurrency,
            jobHasDelivery: this.state.deliveryFee > 0,
            jobChargeDeliveryFee: this.state.deliveryFee,
            //jobDate: this.state.jobDate,
            jobDescription: this.state.jobDescription,
            jobLocationGeo: this.state.deliveryCoords,
            jobLocationAddress: this.state.deliveryAddress,
            jobLocationAddressDescription: this.state.addressDescription,
            jobPostID: this.state.postID || null,
            ...((this.state.itemQty && this.state.postID) && { jobQuantity: this.state.itemQty }),
            myUID: global.myUID,
            idToken: global.idToken,
            myProfileID: global.myProfileID,
            providerProfileID: this.state.providerProfileID
          })
        }
      );

      console.log({
        jobCharge: this.state.itemCost,
        jobChargeOnArrival: this.state.paymentParts.one || 0,
        jobHasDelivery: this.state.deliveryFee > 0,
        jobChargeDeliveryFee: this.state.deliveryFee,
        //jobDate: this.state.jobDate,
        jobDescription: this.state.jobDescription,
        jobLocationGeo: this.state.deliveryCoords,
        jobLocationAddress: this.state.deliveryAddress,
        jobLocationAddressDescription: this.state.addressDescription,
        myUID: global.myUID,
        idToken: global.idToken,
        myProfileID: global.myProfileID,
        providerProfileID: this.state.providerProfileID
      });
      const r2 = await r.json();

      this.setState({ orderAA: false });

      if (r2.msg !== "SUCCESS") {
        console.log("NOT SUCCESSFUL", r2.msg);
        alert('Submission not successful');
        global.reportStats("pressed_OrderNow_Failed1", this.state.providerProfileID, this.state.totalCost)
      }

      else {
        this.props.navigation.goBack()
        this.props.navigation.navigate("jobsMain");
        await global.timeout(500)
        alert("Order sent")
        console.log("SUCCESSFULLY CREATED A NEW JOB, ", r2.msg);
      }
      global.reportStats("pressed_OrderNow", this.state.providerProfileID, this.state.totalCost)
    } catch (err) {
      alert(err);
      console.log(err);
      this.setState({ orderAA: false });
      global.reportStats("pressed_OrderNow_Failed2", this.state.providerProfileID, this.state.totalCost)
    }
  }

  onNavigateScreen = (screen) => {
    const sWidth = Dimensions.get("window").width
    const maxScrollPosition = sWidth * 4 // Change if you add a screen (#screens-1)
    if (screen == "<") this.sv.scrollTo({ x: Math.max(0, this.state.scrollPosition - sWidth), y: 0, animated: true })
    if (screen == ">") this.sv.scrollTo({ x: Math.min(maxScrollPosition, this.state.scrollPosition + sWidth), y: 0, animated: true })
    if (typeof screen == "number") this.sv.scrollTo({ x: (screen - 1) * sWidth, y: 0, animated: true })
  }

  changeQty = (qString) => {
    let q = Number(qString)
    if (!q) q = 1
    let itemCost = q * (Number(this.props.navigation.getParam('price', 0)) || 0)
    const priceCurrency = this.props.navigation.getParam('priceCurrency', "NGN");
    if (priceCurrency !== "NGN" && priceCurrency !== "ACN") itemCost = Math.round(itemCost / global.xRates["GHS"])
    const totalCost = itemCost + (this.state.deliveryFee || 0)

    this.setState({ itemCost, totalCost, itemQty: !Number(qString) ? qString.replace(/[^0-9]/g, "") : q.toString() })
  }



  handleScroll = (event) => {
    this.setState({ scrollPosition: (event.nativeEvent.contentOffset.x) })
  }

  render() {
    if (this.state.error) return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "black" }}>
        <Text style={{ color: "#777" }}>Error. Try again.</Text>
      </View>
    );

    //calculate discount
    let showDiscount = false;
    let discountValue = 0
    let discountValueProvider = 0 //new
    let discountValueWakanda = 0 //new
    let discountCurrency = "₦"
    const now = new Date()
    const discountsOnPost = (global.myDiscounts && this.state.providerProfileID) ? global.myDiscounts.filter(d => {
      if (d.postID == this.state.postID || (d.postID == "ALL" && d.providerProfileID == this.state.providerProfileID)) {
        if (!d.timeOfExpirationMillis || d.timeOfExpirationMillis > now.getTime())
          return true
      }
    }) : [];
    //console.log({ discountsOnPost })
    if (this.state.priceDiscount) {
      discountsOnPost.push({ discountNGN: this.state.priceDiscount, discountWakandaNGN: 0 })
      if (this.state.itemCurrency == "NGN") discountsOnPost.push({ discountNGN: this.state.priceDiscount, discountWakandaNGN: 0 })
      else if (this.state.itemCurrency == "ACN") discountsOnPost.push({ discountACN: this.state.priceDiscount, discountWakandaACN: 0 })
      else if (this.state.itemCurrency == "GHS") discountsOnPost.push({ discountGHS: this.state.priceDiscount, discountWakandaGHS: 0 })

      //console.log('discountsOnPost is now ', discountsOnPost)
    }
    if (discountsOnPost.length > 0) {
      // if (global.walletType == "ACN" && this.state.itemCurrency == "ACN") {
      //   discountsOnPost.sort((a, b) => { return (b.discountACN - a.discountACN) })
      //   if (discountsOnPost[0].discountACN > 0) {
      //     showDiscount = true
      //     discountValue = discountsOnPost[0].discountACN
      //     discountCurrency = "₳"
      //   }
      // }
      if (global.walletType == "NGN" && (this.state.itemCurrency == "NGN" || !this.state.itemCurrency)) {
        //console.log("HIIII")
        discountsOnPost.sort((a, b) => { return ((b.discountNGN + b.discountWakandaNGN) - (a.discountNGN + a.discountWakandaNGN)) })
        if ((discountsOnPost[0].discountNGN + discountsOnPost[0].discountWakandaNGN) > 0) {
          showDiscount = true
          //console.log("HIIII new")
          discountValue = discountsOnPost[0].discountNGN + discountsOnPost[0].discountWakandaNGN
          discountValueProvider = discountsOnPost[0].discountNGN //new
          discountValueWakanda = discountsOnPost[0].discountWakandaNGN //new
          discountCurrency = "₦"
        }
      } else {
        if (this.state.priceDiscount) {
          showDiscount = true
          discountValue = this.state.priceDiscount
          discountValueProvider = this.state.priceDiscount
          discountValueWakanda = 0
          discountCurrency = global.currencySymbols[this.state.itemCurrency || "NGN"]
        }
      }
    }
    let adjustedDiscountValue = 0;
    if (this.state.itemCurrency == "NGN" || this.state.itemCurrency == "ACN") adjustedDiscountValue = Math.min(discountValue, Math.max(0, (this.state.itemCost - 100)))
    else adjustedDiscountValue = Math.min(discountValue, Math.max(0, (this.state.itemCost - 120 * global.xRates[this.state.itemCurrency])))
    if (!(adjustedDiscountValue > 0)) showDiscount = false

    // const adjustedDiscountValue = Math.min(discountValue, Math.max(0, (this.state.itemCost - 100)))
    // if (!(adjustedDiscountValue > 0)) showDiscount = false

    console.log({ totalCost: this.state.totalCost, showDiscount, discountValue, itemCost: this.state.itemCost })
    console.log({ a: this.state.totalCost - (showDiscount ? Math.min(discountValue, Math.max(0, (this.state.itemCost - 100))) : 0) })

    return (
      <View style={{ flex: 1 }}>
        <ScreenSelector onPress={this.onNavigateScreen} scrollPosition={this.state.scrollPosition} />
        {/* <TouchableOpacity style={{ height: 30, width: 30, backgroundColor: "yellow" }} onPress={() => this.sv.scrollTo({ x: 2 * Dimensions.get("window").width, y: 0, animated: true })} /> */}
        <ScrollView horizontal pagingEnabled
          showsHorizontalScrollIndicator={true}
          ref={ref => (this.sv = ref)}
          scrollEventThrottle={0}
          onScroll={this.handleScroll}
          style={{ flex: 1, backgroundColor: "#333" }}>
          <View style={styles.slide1}>
            <Screen1
              showDiscount={showDiscount}
              discountNGN={discountValueProvider || 0}
              discountWakandaNGN={discountValueWakanda || 0}
              discountCurrency={discountCurrency}
              discount={showDiscount ? (this.state.itemCost ? `${Math.min(discountValue, Math.max(0, (this.state.itemCost - 100)))}` : (discountValue || 0)) : 0}
              providerPictureURL={this.state.providerPictureURL}
              providerBusinessName={this.state.providerBusinessName}
              providerName={this.state.providerName}
              activityIndicatorAnimating={this.state.activityIndicatorAnimating}
              loading={this.state.loading}
              callCall={this.callCall}
              callChat={this.callChat}
            />
          </View>

          <View style={styles.slide1}>
            <Screen2
              navigation={this.props.navigation}
              itemTitle={this.state.itemTitle}
              itemDescription={this.state.itemDescription}
              pictureURL={this.state.pictureURL}
              onUpdateDescription={(jobDescription) => { this.setState({ jobDescription }) }}
              showDiscount={showDiscount}
              discount={showDiscount ? `${discountCurrency} ${Math.min(discountValue, Math.max(0, (this.state.itemCost - 100)))}` : ''}
              quantity={this.state.itemQty}
              onChangeQty={this.changeQty}
            />
          </View>

          <View style={styles.slide1}>
            <Screen3
              showDiscount={showDiscount}
              discount={showDiscount ? (this.state.itemCost ? `${Math.min(discountValue, Math.max(0, (this.state.itemCost - 100)))}` : (discountValue || 0)) : 0}
              currency={this.state.itemCurrency}
              itemCost={this.state.itemCost}
              deliveryFee={this.state.deliveryFee}
              multipartPayment={this.state.multipartPayment}
              installmentOne={this.state.paymentParts.one}
              payInParts={async (a, b, c) => {
                await this.setState({
                  paymentParts: {
                    one: a,
                    two: b
                  },
                  multipartPayment: c
                });
                console.log(
                  "Payment Installments passed up are: Payment One: ",
                  this.state.paymentParts.one,
                  " Payment Two: ",
                  this.state.paymentParts.two
                );
              }}
              editPriceDetails={async (a, b, c) => {
                await this.setState({ itemCost: a, deliveryFee: b, totalCost: c });
                console.log(
                  "new price details passed up are: ItemCost: ",
                  this.state.itemCost,
                  " deliveryFee: ",
                  this.state.deliveryFee,
                  " totalCost: ",
                  this.state.totalCost
                );
              }}
            />
          </View>

          <View style={styles.slide1}>
            <Screen4
              deliveryAddress={this.state.deliveryAddress}
              addressDescription={this.state.addressDescription}
              editDeliveryCoords={(deliveryCoords) => { this.setState({ deliveryCoords }) }}
              editDeliveryAddressDetails={(deliveryAddress) => {
                this.setState({ deliveryAddress });
              }}
              editAddressDescriptionDetails={(addressDescription) => {
                this.setState({ addressDescription });
              }}
              showLocationModal={() => { this.setState({ showLocationModal: true }) }}
              location={{ address: this.state.deliveryAddress, coords: this.state.deliveryCoords }}
            />
          </View>

          <View style={styles.slide1}>
            <Screen5
              showDiscount={showDiscount}
              discount={showDiscount ? Math.min(discountValue, Math.max(0, (this.state.itemCost - 100))) : 0}
              pictureURL={this.state.pictureURL}
              totalCost={this.state.totalCost - (showDiscount ? Math.min(discountValue, Math.max(0, (this.state.itemCost - 100))) : 0)}
              itemQty={this.state.postID ? this.state.itemQty : ""}
              currency={this.state.itemCurrency}
              installment1={this.state.paymentParts.one}
              deliveryFee={this.state.deliveryFee}
              deliveryAddress={this.state.deliveryAddress}
              addressDescription={this.state.addressDescription}
              itemTitle={this.state.itemTitle}
              itemDescription={this.state.itemDescription}
              jobDescription={this.state.jobDescription}
              orderNow={this.orderNow}
              activityIndicatorAnimating={this.state.orderAA}
            />
          </View>
          <EscrowModal
            showModal={this.state.showEscrowModal}
            jobCharge={this.state.totalCost - Math.min(discountValue, Math.max(0, (this.state.itemCost - 100)))}
            navigation={this.props.navigation}
            onClose={() => {
              this.setState({ showEscrowModal: false });
            }}
            onPayNow={() => {
              this.setState({ showEscrowModal: false });
              //xxx TBD implement escrow payment backend here
              this.executeOrderNow()
              //alert("PAID");
            }}
          />
        </ScrollView>
        {!!this.state.showLocationModal && <CurrentLocationModal
          location={{ address: this.state.deliveryAddress, coords: this.state.deliveryCoords }}
          onCancel={() => { this.setState({ showLocationModal: false }) }}
          onSelect={(deliveryCoords, deliveryAddress) => {
            try {
              //console.log({ deliveryCoords, deliveryAddress })
              this.setState({
                showLocationModal: false,
                deliveryCoords,
                deliveryAddress
              });
            } catch (err) {
              console.log('error in currentlocation is: ', err)
              console.warn("ERR 345m")
            }
          }}
        />}
      </View>
    );
  }
}

const styles = ({
  wrapper: {},
  slide1: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#333",
    width: "100%"
  }
});

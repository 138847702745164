import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  TouchableHighlight,
  TouchableWithoutFeedback,
  ScrollView,
  ActivityIndicator,
  Platform,
  Linking,
  Image,
  Dimensions
} from "react-native";

import firebase from "firebase";
import "firebase/firestore";

const gStyles = global.gStyles //    "../../../../styles/gStyles";


export default class DoBoost extends Component {
  state = {
    aa: false,
  };

  boost = async (factor) => {
    if (!global.amAdmin) return alert("Error")
    try {
      this.setState({ aa: true })

      const r = await fetch(global.cloudFunctionURL + "setBoosting", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          action: "boost",
          boostFactor: factor,
          targetProfileID: this.props.profileID,
          noteData: { text: factor == 1 ? "Removed boosting." : "Changed boosting to " + this.getBoostSmiley(factor) }, //+ " (" + factor + ")"
          myUID: global.myUID,
          idToken: global.idToken,
        })
      });
      const r2 = await r.json();

      if (r2.msg != "SUCCESS") {
        alert("Error boosting provider")
      } else {
        if (this.props.setBoostFactor) this.props.setBoostFactor(factor, r2.notes) // just to change static content
        this.props.closeModal()
      }
      this.setState({ aa: false })
    } catch (error) {
      global.warn(error, "doBoost");
      this.setState({ aa: false })
      alert("Error. Check network connection")
    }
  }

  getBoostSmiley = (newFactor) => {
    let factor = newFactor || this.props.boostFactor
    if (newFactor == 0) factor = 0
    return (factor == 1) ? "NONE" :
      factor >= 4 ? "🤩" :
        factor >= 2 ? "👍" :
          factor < 1 ? "💩" : "???"
  }

  render() {
    if (this.state.aa) {
      return (
        <View style={{ backgroundColor: "rgba(0,0,0,.5)", height: "100%", width: "100%", alignItems: "center" }}>
          <Text style={{ fontSize: 28, color: "#ccc", marginTop: 205, alignSelf: "center", fontWeight: "bold" }}>
            Updating</Text>
          <TouchableOpacity
            style={{ alignItems: "center", marginTop: 200, marginBottom: 20 }}
            onPress={() => { this.props.closeModal(); }}
          >
            <Text style={{ color: "#b9b9c1" }}>Dismiss</Text>
          </TouchableOpacity>
        </View >
      )
    }
    return (<View style={{ backgroundColor: "rgba(0,0,0,.9)", height: "100%" }}>
      <TouchableWithoutFeedback
        //onPress={Keyboard.dismiss}
        accessible={false}
      >
        <ScrollView style={{ margin: 25 }}>
          <Text style={{ fontSize: 28, color: "#ccc", marginVertical: 25, alignSelf: "flex-start", fontWeight: "bold" }}>
            Boosting</Text>
          <View style={{ alignSelf: "stretch", justifyContent: "center", marginHorizontal: 40, borderBottomColor: "white", borderBottomWidth: 0, height: 40 }}>
            <Text style={{ fontSize: 22, color: "white", alignSelf: "center", fontWeight: "bold" }}>
              Current boosting: {this.getBoostSmiley()}
            </Text>
          </View>
          <View style={{ height: 50 }} />
          {this.props.boostFactor != 2 && <View style={{ marginBottom: 30 }}>
            <TouchableOpacity style={global.gStyles.button}
              onPress={() => { this.boost(2) }}
            >
              <Text style={global.gStyles.buttontext}>👍Boost</Text>
            </TouchableOpacity>
            <Text style={{ fontSize: 14, color: "#b9b9c1", justifyContent: "flex-start" }}>
              Boosted Providers will appear higher in search results, especially on the website.</Text>
          </View>}
          {this.props.boostFactor != 4 && <View style={{ marginBottom: 30 }}>
            <TouchableOpacity style={global.gStyles.button}
              onPress={() => { this.boost(4) }}
            >
              <Text style={global.gStyles.buttontext}>🤩Super-Boost</Text>
            </TouchableOpacity>
            <Text style={{ fontSize: 14, color: "#b9b9c1", justifyContent: "flex-start" }}>
              Super-Boosted Providers will appear first on the website.</Text>
          </View>}
          {this.props.boostFactor != 1 && <View style={{ marginBottom: 30 }}>
            <TouchableOpacity style={global.gStyles.button}
              onPress={() => { this.boost(1) }}
            >
              <Text style={global.gStyles.buttontext}>Remove Boosting</Text>
            </TouchableOpacity>
            <Text style={{ fontSize: 14, color: "#b9b9c1", justifyContent: "flex-start" }}>
              The provider will be appear in search results depending on relevance.</Text>
          </View>}
          {this.props.boostFactor != 0 && <View style={{ marginBottom: 30 }}>
            <TouchableOpacity style={global.gStyles.button}
              onPress={() => { this.boost(0) }}
            >
              <Text style={global.gStyles.buttontext}>💩Downboost</Text>
            </TouchableOpacity>
            <Text style={{ fontSize: 14, color: "#b9b9c1", justifyContent: "flex-start" }}>
              Downboosted Providers will not appear in searches. Use this for explicit content or non-professional behaviour.</Text>
          </View>}
          <TouchableOpacity
            style={{ alignItems: "center", marginTop: 50, marginBottom: 20 }}
            onPress={() => { this.props.closeModal(); }}
          >
            <Text style={{ color: "#b9b9c1" }}>Cancel</Text>
          </TouchableOpacity>
        </ScrollView>
      </TouchableWithoutFeedback>
    </View>)
  }
}
import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  TouchableHighlight,
  TouchableWithoutFeedback,
  ScrollView,
  SafeAreaView,
  ActivityIndicator,
  Platform,
  Linking,
  Image,
  Modal,
  Dimensions
} from "react-native";
import * as Location from 'expo-location';
import * as Permissions from 'expo-permissions';
import Constants from 'expo-constants';
import {
  NavigationEvents,
} from "react-navigation";
//import firebase from "../../../firebase/afbase-config";
import firebase from "firebase";
import "firebase/firestore";
import distance from "../../shared/distances";
//import Guide from "../../shared/guide";
import Post from "../../Home/Post/Post";
import CachedImage from "../../shared/CachedImage"
import DoBoost from "../Help/Admin/CRM/DoBoost"
const gStyles = global.gStyles //    "../../styles/gStyles";

// xx TBD Hide my Posts when hiding my business
// xx TBD Hide my Posts when hiding my business
// xx TBD Hide my Posts when hiding my business
// xx TBD Hide my Posts when hiding my business
// xx TBD Hide my Posts when hiding my business
// xx TBD Hide my Posts when hiding my business

class SinglePost extends PureComponent {
  state = {
    visible: true,
    showPostDetail: false
  };

  render() {
    // const iWidth = Dimensions.get("window").width / 2;
    // const colF = this.props.colF;
    // const Nope = (
    //   <View
    //     style={{ height: 50, justifyContent: "center", alignItems: "center" }}
    //   >
    //     <Text style={{ color: colF }}>Currently no offerings.</Text>
    //   </View>
    // );

    // if (!this.props.postIDs) {
    //   // xx redo
    //   return Nope;
    // }

    if (Platform.OS == "ios")
      return (
        <View
          style={{
            width: this.state.visible ? 155 : 0,
            height: 200,
            transform: [{ scale: 0.5 }]
          }}
        >
          {!!this.state.visible && (
            <TouchableOpacity
              onPress={() => {
                if (!this.state.showPostDetail) {
                  this.setState({ showPostDetail: true });
                }
              }}
              style={{ width: this.state.visible ? 300 : 0, height: 300 }}
            >
              <Post
                postID={this.props.postID}
                key={this.props.postID + this.state.showPostDetail}
                navigation={this.props.navigation}
                hideAuthor={true}
                showDetail={this.state.showPostDetail}
                onHideDetail={() => {
                  this.setState({ showPostDetail: false });
                }}
                relevantStatus={"LIVE"}
                onIsNotRelevant={() => {
                  this.setState({ visible: false });
                }}
              />
            </TouchableOpacity>
          )}
        </View>
      );
    else
      return (
        <View
          style={{
            width: this.state.visible ? 300 : 0,
            height: 300,
            transform: [{ scale: 0.5 }],
            marginLeft: -100,
            marginRight: -45,
            marginTop: -25
          }}
        >
          {!!this.state.visible && (
            <TouchableOpacity
              onPress={() => {
                if (!this.state.showPostDetail) {
                  this.setState({ showPostDetail: true });
                }
              }}
              style={{ width: this.state.visible ? 300 : 0, height: 300 }}
            >
              <Post
                postID={this.props.postID}
                key={this.props.postID + this.state.showPostDetail}
                navigation={this.props.navigation}
                hideAuthor1={true}
                showDetail={this.state.showPostDetail}
                onHideDetail={() => {
                  this.setState({ showPostDetail: false });
                }}
                relevantStatus={"LIVE"}
                onIsNotRelevant={() => {
                  this.setState({ visible: false });
                }}
              />
            </TouchableOpacity>
          )}
        </View>
      );
  }
}
class MyPosts extends PureComponent {
  render() {
    // const iWidth = Dimensions.get("window").width / 2;
    // const colF = this.props.colF;
    // const Nope = (
    //   <View
    //     style={{ height: 50, justifyContent: "center", alignItems: "center" }}
    //   >
    //     <Text style={{ color: colF }}>Currently no offerings.</Text>
    //   </View>
    // );

    // if (!this.props.postIDs) {
    //   // xx redo
    //   return Nope;
    // }
    if (!this.props.postIDs) return;
    if (Platform.OS == "ios")
      return (
        <ScrollView
          horizontal
          style={{
            marginLeft: -50,
            marginTop: this.props.postIDs.length > 0 ? -30 : 0
          }}
        >
          <View style={{ width: 10 }} />
          {this.props.postIDs.map(postID => {
            return (
              <SinglePost
                postID={postID}
                key={postID}
                navigation={this.props.navigation}
              />
            );
          })}
          <View style={{ width: 35 }} />
        </ScrollView>
      );
    else
      return (
        <ScrollView
          horizontal
          style={{ marginLeft: 0, marginTop: -30, marginBottom: -75 }}
        >
          <View style={{ width: 25 }} />
          {this.props.postIDs.map(postID => {
            return (
              <SinglePost
                postID={postID}
                key={postID}
                navigation={this.props.navigation}
              />
            );
          })}
          <View style={{ width: 130 }} />
        </ScrollView>
      );
  }
}

class NetworkVerification extends Component {
  state = {
    uri: null,
    name: "..."
  }
  async componentDidMount() {
    try {
      let net = await global.getNetwork(this.props.verification.by)
      if (!net) { //this should not be necessary
        console.log("WARNING. Code not working as expected.")
        const netDoc = await firebase
          .firestore()
          .collection("Networks")
          .doc(this.props.verification.by)
          .get();
        net = netDoc.data()
      }
      if (!net) return;

      if (net.networkLogoURL) this.setState({ uri: net.networkLogoURL })
      if (net.networkName) this.setState({ name: net.networkName })
    } catch (e) {
      console.log("ERROR", e)
    }
  }
  render() {
    let ver = this.props.verification
    return (
      <View style={{ borderRadius: 5, padding: 2, flexDirection: "row", alignItems: "center", backgroundColor: "rgba(22,66,44,1)", marginVertical: 3, marginLeft: 0, marginRight: 20 }}>
        {!!this.state.uri && <CachedImage
          style={{ width: 36, height: 26, marginRight: 5, resizeMode: "contain" }}
          source={{ uri: this.state.uri }}
        />}
        <Text style={{ color: this.props.colF, fontSize: 12, flexWrap: "wrap", fontWeight: "bold", marginRight: 50 }}>Verified as {ver.type} by {this.state.name}</Text>
      </View>
    )
  }
}

class MyServicesOffered extends PureComponent {
  render() {
    const iWidth = Dimensions.get("window").width / 15;
    const colF = this.props.colF || "#bbc";
    const Nope = (
      <View
        style={{ height: 50, justifyContent: "center", alignItems: "center" }}
      >
        <Text style={{ color: colF }}>
          Currently not offering any services.
        </Text>
      </View>
    );

    const images = {
      Electronics: require("../../images/categories/Electronics.png"),
      Home: require("../../images/categories/Home.png"),
      Fashion: require("../../images/categories/Fashion.png"),
      Cars: require("../../images/categories/Cars.png"),
      Food: require("../../images/categories/Food.png"),
      Beauty: require("../../images/categories/Beauty.png"),
      Professionals: require("../../images/categories/Professionals.png"),
      Repair_Refill: require("../../images/categories/Repair_Refill.png"),
      Handyman: require("../../images/categories/Handyman.png"),
      QuickJobs: require("../../images/categories/QuickJobs.png"),
      Health: require("../../images/categories/Health.png"),
      Art: require("../../images/categories/Art.png"),
      Housing: require("../../images/categories/Housing.png"),
      Travel: require("../../images/categories/Travel.png"),
      Commercial: require("../../images/categories/Commercial.png")
    };

    if (!this.props.services) {
      return Nope;
    }

    let keys = Object.keys(this.props.services);

    if (keys.length == 0) {
      return Nope;
    }

    let array = [];
    keys.forEach(cat1 => {
      this.props.services[cat1].forEach(cat2 => {
        array.push(
          <TouchableOpacity
            key={cat2}
            style={{
              flexDirection: "row",
              flex: 1,
              width: Dimensions.get("window").width,
              marginVertical: 2
            }}
            activeOpacity={!this.props.isMyProfile ? 0.2 : 1}
            onPress={() => {
              if (!this.props.isMyProfile) {
                // this also checks whether blocked
                let route = null;
                if (this.props.navigation.state.routeName === "homeProfile") {
                  route = "homeJobsNew";
                }
                if (this.props.navigation.state.routeName === "jobsProfile") {
                  route = "jobsNew";
                }
                if (
                  this.props.navigation.state.routeName === "messageProfile"
                ) {
                  route = "messageJobsNew";
                }
                if (this.props.navigation.state.routeName === "meProfile") {
                  route = "meJobsNew";
                }
                this.props.navigation.navigate(route, {
                  providerProfileID: this.props.profileID,
                  offeredCategories: this.props.offeredCategories,
                  providerProfileName: this.state.name,
                  category1: cat1,
                  category2: cat2
                });
              }
            }}
          >
            <View style={{ width: iWidth + 15, alignItems: "flex-start" }}>
              <Image
                style={{
                  width: iWidth, height: iWidth, margin: 2, borderRadius: iWidth / 3
                }}
                source={images[cat1]}
              />
            </View>
            <View style={{ flex: 80 }}>
              <Text style={{ color: colF, fontSize: 10 }}>{cat1}</Text>
              <Text style={{ color: colF, fontSize: 14 }}>{cat2}</Text>
              {this.props.verifiedSkills[cat2.replace(/[^a-zA-Z]/g, "")] && this.props.verifiedSkills[cat2.replace(/[^a-zA-Z]/g, "")].map((ver, i) => {
                return (<NetworkVerification key={ver.type + i} verification={ver} backgroundColor={"#264"} colF={colF} />)
              })}
            </View>
            {/* <TouchableOpacity
                onPress={() => {
                  this.setState({ category1: null, category2: null });
                  this.props.onSelectCategory(null, null);
                }}
                style={{
                  flex: 11,
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 12,
                  height: 35,
                  marginRight: 9,
                  backgroundColor: "#444"
                }}
              >
                <Text
                  style={{ color: "white", fontSize: 18, fontWeight: "bold" }}
                >
                  X
                </Text>
              </TouchableOpacity> */}
          </TouchableOpacity>
        );
      });
    });

    if (array.length == 0) {
      return Nope;
    }
    return array;
  }
}

class BulletPoint extends PureComponent {
  render() {
    return (
      <View style={{ flexDirection: "row", justifyContent: "flex-start", marginRight: 15 }}>
        <Text style={[this.props.style, { marginRight: 6 }]}>{`\u2022`}</Text>
        <Text style={this.props.style}>{this.props.children}</Text>
      </View>
    );
  }
}

class Follower extends Component {
  state = {
    pictureURL: "",
    name: "*****"
  };

  async componentDidMount() {
    // let isConnected = await NetInfo.isConnected.fetch();
    // if (!isConnected) {
    //   console.log(
    //     "No network while rendering Follower (isConnected is false). Still trying"
    //   );
    // }
    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 200); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 500); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 1000); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 2000); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 3000); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 5000); });
    if (!global.myUID || !global.myProfileID) await global.setGlobals();
    if (!global.myUID || !global.myProfileID) return alert("No connection")
    try {
      let followerSnapshot = await firebase
        .firestore()
        .collection("Profiles")
        .doc(this.props.profileID)
        .get();
      let follower = followerSnapshot.data();
      let name = follower.profileName.replace(/ .*/, ""); // == first word
      // if (name.length>11){
      //   name = name.substring(0,9)+"...";
      // }
      let pictureURL = follower.profilePictureURL;
      this.setState({ pictureURL, name });
    } catch (err) {
      console.log("Error loading follower (Profile)");
    }
  }

  render() {
    return (
      <View style={{ alignItems: "center" }}>
        <View
          style={{
            backgroundColor: this.props.colF,
            margin: 2,
            height: 66,
            width: 66,
            borderRadius: 33,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {this.state.pictureURL != "" && (
            <Image
              style={{
                marginTop: 1,
                marginBottom: 2,
                width: 60,
                height: 60,
                borderRadius: 30
                //backgroundColor: "black"
              }}
              source={{
                uri: this.state.pictureURL
              }}
            />
          )}
        </View>
        <Text style={{ color: this.props.colF }}>{this.state.name}</Text>
      </View>
    );
  }
}

class MyFollowers extends Component {
  render() {
    return (
      <ScrollView horizontal>
        {this.props.followers.map(f => (
          <TouchableOpacity
            key={f}
            onPress={() => {
              const a = Math.floor(Math.random() * 899999 + 100000);
              this.props.navigation.navigate({
                routeName: "meProfile",
                params: { profileID: f },
                key: "Mutual" + a
              });
            }}
          >
            <Follower profileID={f} colF={this.props.colF || "#bbb"} />
          </TouchableOpacity>
        ))}
      </ScrollView>
    );
  }
}

export default class MeProfileScreen extends Component {
  initialState = {
    profileID: null,
    name: "*********",
    businessName: "***************",
    jobsDoneCount: 0,
    trustScoreBadge: "",
    trustScoreDetails: [],
    showUnfollow: false,
    picture: null,
    backgroundPicture: null,
    description: "",
    traderDescription: "",
    isActiveTrader: false,
    servicesOffered: null,
    skills: [],
    verifiedSkills: {},
    jobQualifications: null,
    socials: null,
    postIDs: [],
    pictureSize: 1,
    badges: [],
    phoneNumber: "",
    workLocationAddress: null,
    workLocationLatitude: null,
    workLocationLongitude: null,
    myWalletBalance: 0,
    amountToPay: 0,
    myLocation: null,
    jointChatID: "",
    contactsFollowing: [],
    isMyProfile: true,
    isMedical: false,
    networks: [],
    canVerifyProfile: false,
    blocked: false,
    blacked: false,
    blockingYou: false,
    blackingYou: false,
    following: null,
    showMyServices: true,
    showMyPosts: true,
    modalBusinessVisible: false,
    modalPage: 1,
    modalBlockVisible: false,
    modalUnblockVisible: false,
    modalMenuVisible: false,
    modalAdminVisible: false,
    modalMenuEditVisible: false,
    modalMenuShareVisible: false,
    modalTrustVisible: false,
    usesACN: null,
    locationPermissionGranted: false,
    activityIndicatorAnimating: false,
    loading: true,

    boostFactor: 1
  };

  state = this.initialState

  static navigationOptions = ({ navigation }) => {
    return {
      title: "Profile",
      headerTransparent: true,
      headerTintColor: "#A8CF45",
      headerTitleStyle: {
        fontWeight: "bold",
        color: "#A8CF45",
        shadowOpacity: 1,
        elevation: 1
      },

      headerRight: () => {
        let isMyProfile = false
        if (!navigation.getParam("profileID", null)) isMyProfile = true
        return (
          <View style={{ marginRight: isMyProfile ? 15 : 10, elevation: 4, flexDirection: "row", alignItems: "center" }}>
            <TouchableOpacity
              onPress={navigation.getParam("optionMenuShare")}
              style={{
                height: 36,
                width: 36,
                paddingRight: 15,
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Image
                style={{ shadowColor: "black", shadowOpacity: 1, width: 28, height: 28, tintColor: "#A8CF45" }}
                source={require("../../images/icons/Share.png")}
              />
            </TouchableOpacity>
            {isMyProfile ? <View style={{}}>
              <TouchableOpacity
                onPress={navigation.getParam("optionMenuEdit")}
                style={{
                  height: 36,
                  width: 36,
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {/* <Text
              style={{
                fontWeight: "bold",
                fontSize: 16,
                color: "#A8CF45",
                shadowOpacity: 1,
                elevation: 1
              }}
            >
              Edit
            </Text> */}
                <Image
                  style={{ shadowColor: "black", shadowOpacity: 1, width: 28, height: 28, tintColor: "#A8CF45" }}
                  source={require("../../images/icons/Change.png")}
                />
              </TouchableOpacity>
            </View>
              :
              <View style={{}}>
                <TouchableOpacity onPress={navigation.getParam("optionMenu")}>
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 24,
                      color: "#A8CF45",
                      shadowOpacity: 1,
                      elevation: 1,
                      padding: 10,
                      paddingTop: Platform.OS == "ios" ? 14 : 10
                    }}
                  >
                    ⋮
            </Text>
                </TouchableOpacity>
              </View>}
          </View>
        )
      }

    };
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    this.props.navigation.setParams({
      optionMenu: this.optionMenu
    });
    this.props.navigation.setParams({
      optionMenuEdit: this.optionMenuEdit
    });
    this.props.navigation.setParams({
      optionMenuShare: this.optionMenuShare
    });
    // let isConnected = await NetInfo.isConnected.fetch();
    // console.log("(Profile) Internet Connection: " + isConnected);
    // //check if profileID has been given, else show own profile
    // if (!global.myProfileID) {
    //   console.log("CHANGE CODE! Globals not set!");
    // }
    if (!global.myUID || !global.myProfileID || !global.myBlacklist) await new Promise(resolve => { setTimeout(resolve, 200); });
    if (!global.myUID || !global.myProfileID || !global.myBlacklist) await new Promise(resolve => { setTimeout(resolve, 500); });
    if (!global.myUID || !global.myProfileID || !global.myBlacklist) await new Promise(resolve => { setTimeout(resolve, 1000); });
    if (!global.myUID || !global.myProfileID || !global.myBlacklist) await new Promise(resolve => { setTimeout(resolve, 1000); });
    if (!global.myUID || !global.myProfileID || !global.myBlacklist) await new Promise(resolve => { setTimeout(resolve, 2000); });
    if (!global.myUID || !global.myProfileID || !global.myBlacklist) await global.setGlobals();
    global.fetchIdToken()


    let pID = global.myProfileID;
    try {
      let action = this.props.navigation.getParam("action", null);
      if (action == "OPEN_A_SHOP") {
        await global.timeout(100);
        this.setModalBusinessVisible(true);
      }

      let paramID = this.props.navigation.getParam("profileID", null);
      if (paramID) {
        pID = paramID;
        if (pID != global.myProfileID) {
          this.setState({ isMyProfile: false });
        }
        if (
          global.myBlackedlist &&
          global.myBlockedlist &&
          global.myBlacklist &&
          global.myBlocklist
        ) {
          this.setState({
            blocked: global.myBlocklist.includes(paramID),
            blacked: global.myBlacklist.includes(paramID),
            blockingYou: global.myBlockedlist.includes(paramID),
            blackingYou: global.myBlackedlist.includes(paramID)
          });
        }
      }
      //alert (paramID);
      //this.myUID = await firebase.auth().currentUser.uid;
      this.fRef = await firebase
        .firestore()
        .collection("Profiles")
        .doc(pID); // xx change
      this.unsubscribeFirestore = this.fRef.onSnapshot(this.onCollectionUpdate);

      //find out if I follow the profile
      if (pID != global.myProfileID) {
        let myContactsSnapshot = await firebase
          .firestore()
          .collection("Users")
          .doc(global.myUID)
          .collection("Refs")
          .doc("contactRefs")
          .get();
        let myFollows = myContactsSnapshot.data().followingProfileIDs;
        if (myFollows.includes(pID)) {
          this.setState({ following: true });
          //alert("Following")
        } else {
          this.setState({ following: false });
        }
        global.reportStats("viewed_Profile", pID)
      }
    } catch (err) {
      alert("Error loading profile. Check internet connection.");
      console.log("Error loading profile. Check internet connection.", err);
    }
    this.setLocationPermission();
    if (!this.state.isMyProfile) {
      this.fetchJointItems();
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  // fetchPosts = async pID => {
  //   try {
  //     let postsBunch = await firebase
  //       .firestore()
  //       .collection("Posts")
  //       .where("authorProfileID", "==", pID)
  //       .where("systemStatus", "==", "LIVE")
  //       .get();
  //     let posts = [];

  //     console.log("postsBunch.size", postsBunch.size);
  //     if (postsBunch.size > 0) {
  //       postsBunch.forEach(post => {
  //         posts.push(post);
  //       });
  //     } else {
  //       posts = "NONE";
  //     }
  //     console.log("Fetched Posts:", posts);
  //     this.setState({ posts });
  //   } catch (error) {
  //     console.log("Error fetching posts", error);
  //   }
  // };

  fetchJointItems = async () => {
    try {
      if (!global.myProfileID) await global.timeout?.(1000)
      if (!global.myProfileID) await global.timeout?.(2500)
      if (!global.myProfileID) return
      const r = await fetch(global.cloudFunctionURL + "getJointItems", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          myUID: global.myUID,
          idToken: global.idToken,
          myProfileID: global.myProfileID,
          targetProfileID: this.state.profileID
        })
      });
      const r2 = await r.json();

      if (r2.msg === "SUCCESS") {
        //console.log("SUCCESS!!!!!", r2);
        this.setState({
          jointChatID: r2.jointChatID,
          contactsFollowing: r2.contactsFollowing
        });
      }
      //console.log("SUCCESS??", r2);
    } catch (error) {
      global.warn(error, "profileMFJ");
    }
  };

  setLocationPermission = async () => {
    if (global.myLocation) this.setState({ myLocation: global.myLocation })
    const w1 = global.setLocationFast(5);
    const { status } = await Permissions.askAsync(Permissions.LOCATION);
    console.log("location permission status:", status);
    await w1;
    // console.log(
    //   "My location: ",
    //   myLocation.coords.longitude,
    //   myLocation.coords.latitude
    // );
    this.setState({ locationPermissionGranted: status === "granted" });
    if (status === "granted" && global.location.coords) this.setState({ myLocation: global.myLocation });
  };

  onCollectionUpdate = querySnapshot => {
    if (!querySnapshot) return;
    const query = querySnapshot.data();
    if (!query) return;

    // calculate Skills
    const obOfChange = {}
    const skills = []
    if (query.profileServicesOffered) {
      let servicesOfferedKeys = Object.keys(query.profileServicesOffered)
      servicesOfferedKeys.forEach(key => {
        query.profileServicesOffered[key].forEach(cat2 => {
          let cat2s = cat2.replace(/[^a-zA-Z]/g, "")
          if (global.skillKeys.includes(cat2s)) {
            skills.push(cat2)
            obOfChange["skills" + cat2s] = query["skills" + cat2s] || [];
          }
        })
      })
    }

    const profileID = this.props.navigation.getParam("profileID", '');
    if (profileID == 'F4C7tJCPqxdpawvBpd2B') {
      query.profilePhoneNumber = ((global.myLocalCurrency == "GHS") ? "+233545414599" : "+2348020510968")
      // query.profilePhoneNumber = ((global.myLocalCurrency == "GHS") ? "+233545414599" : "+2349099992564")
    }

    this.setState({
      loading: false,
      name: query.profileName,
      businessName: query.profileNameBusiness || "",
      picture: query.profilePictureURL,
      backgroundPicture: query.profilePictureBackgroundURL,
      description: query.profileDescription || "",
      ... (query.isActiveTrader && { isActiveTrader: query.isActiveTrader, traderDescription: query.profileTraderDescription }),
      servicesOffered: query.profileServicesOffered,
      jobQualifications: query.jobQualifications,
      socials: query.profileSocials,
      ...(query.profileWebsiteURL && query.profileWebsiteExpirationDate &&
        (query.profileWebsiteExpirationDate.toDate() > new Date()) && { websiteURL: query.profileWebsiteURL }),
      skills,
      ...obOfChange,
      verifiedSkills: query.verifiedSkills || {},
      networks: query.networks || [],
      postIDs: query.postIDs,
      phoneNumber: query.profilePhoneNumber || "",
      badges: query.infoBadges || [],
      jobsDoneCount: query.infoJobsDoneCount || 0,
      trustScoreBadge: query.trustScoreBadge || 0,
      trustScoreDetails: query.trustScoreDetails || [],
      profileID: querySnapshot.id,
      workLocationAddress: query.locationWorkAddress
        ? query.locationWorkAddress
        : null,
      workLocationLatitude: query.locationWork ? query.locationWork._lat : null,
      workLocationLongitude: query.locationWork
        ? query.locationWork._long
        : null,
      usesACN: query.usesACN || false,
      boostFactor: query.boostFactor == 0 ? 0 : query.boostFactor || 1
    });

    if (global.networkAdminRights) {
      global.networkAdminRights.forEach(network => {
        if (query.networks && query.networks.includes(network)) {
          this.setState({ canVerifyProfile: true })
        }
      })
    }
    //xx change this once certificates established
    if (query.profileServicesOffered) {
      if (query.profileServicesOffered["Health"]) {
        if (query.profileServicesOffered["Health"].length > 0) {
          this.setState({ isMedical: true });
        }
      }
    }
  };

  socialImages = {
    twitter: require("../../images/website/twitter.png"),
    linkedIn: require("../../images/website/linkedIn.png"),
    facebook: require("../../images/website/facebook.png"),
    instagram: require("../../images/website/instagram.png"),
    wakanda: require("../../images/website/wakanda.png"),
  };

  callCall = () => {
    //alert("Call clicked");
    if (this.state.phoneNumber != "" && !this.state.blockingYou) {
      this.setState({ activityIndicatorAnimating: true });
      Linking.openURL("tel:" + this.state.phoneNumber);
      global.reportStats("pressed_Call", this.state.profileID, "PROFILE")
      this.setState({ activityIndicatorAnimating: false });
      if (global.myProfileID !== this.state.profileID && this.state.businessName) fetch(global.cloudFunctionURL + "reportEvent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ isWeb:true,
          event: "call",
          profileID: this.state.profileID,
          userInfo: {
            firstName: global.myName?.split?.(" ")[0],
            appVersion: Constants?.manifest?.version || "0"
          },
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
    } else {
      alert("No phone number found.");
    }
  };

  callRequestService = () => {
    if (this.state.usesACN && global.walletType == "NGN") {
      alert("You cannot use our escrow service with providers outside Nigeria for now. Contact them directly!")
      return
    }
    if (!this.state.usesACN && global.walletType == "ACN") {
      alert("You cannot use our escrow service with Nigerian providers for now. Contact them directly!")
      return
    }
    global.reportStats("pressed_Order", this.state.profileID, "PROFILE")

    let route = null;
    if (this.props.navigation.state.routeName === "homeProfile") {
      route = "homeJobsNew";
    }
    if (this.props.navigation.state.routeName === "jobsProfile") {
      route = "jobsNew";
    }
    if (this.props.navigation.state.routeName === "messageProfile") {
      route = "messageJobsNew";
    }
    if (this.props.navigation.state.routeName === "meProfile") {
      route = "meJobsNew";
    }
    console.log(
      "blockingYou, blackingYou; ",
      this.state.blockingYou,
      this.state.blackingYou
    );
    if (!this.state.blockingYou && !this.state.blackingYou) {
      this.props.navigation.navigate(route, {
        providerProfileID: this.state.profileID,
        offeredCategories: this.state.servicesOffered,
        providerProfileName: this.state.name,
        providerBusinessName: this.state.businessName,
        providerProfilePictureURL: this.state.picture,
        providerPhoneNumber: this.state.phoneNumber
      });
    } else {
      alert("Currently not possible");
    }
  };

  callChat = async () => {
    let chatID = "";
    let chatProperties = {
      opponentProfileID: this.state.profileID,
      opponentName: this.state.name,
      opponentNameBusiness: this.state.businessName,
      opponentProfilePictureURL: this.state.picture
    };

    if (!this.state.picture) {
      chatProperties["opponentProfilePictureURL"] = "";
    }
    if (!this.state.businessName) {
      chatProperties["opponentNameBusiness"] = "";
    }

    if (this.state.jointChatID !== "") {
      chatID = this.state.jointChatID;
    } else {
      //create a new chat
      this.setState({ activityIndicatorAnimating: true });
      try {
        const r = await fetch(global.cloudFunctionURL + "newChat", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            profileID1: global.myProfileID,
            profileID2: this.state.profileID,
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
        const r2 = await r.json();
        chatID = r2.chatID;
        this.setState({ activityIndicatorAnimating: false });
      } catch (error) {
        this.setState({ activityIndicatorAnimating: false });
        console.log(error);
      }
    }

    // navigate to chatID
    if (chatID) {
      if (chatID != "") {
        this.props.navigation.navigate("chatMain", {
          redirect: true,
          redirectParams: {
            chatID,
            chatProperties
          }
        });
        global.reportStats("pressed_Order", this.state.profileID, "PROFILE")
      }
    }
  };

  callFollow = async follow => {
    if (!this.state.activityIndicatorAnimating) {
      try {
        this.setState({ activityIndicatorAnimating: true });

        // console.log(
        //   "follower: ",
        //   global.myUID,
        //   "followerProfile: ",
        //   global.myProfileID,
        //   "targetProfile: ",
        //   this.state.profileID,
        //   "action: ",
        //   follow ? "Follow" : "Unfollow"
        // );

        const r = await fetch(global.cloudFunctionURL + "followProfile", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            follower: global.myUID,
            followerProfile: global.myProfileID,
            targetProfile: this.state.profileID,
            action: follow ? "Follow" : "Unfollow",
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
        const r2 = await r.json();

        this.setState({ activityIndicatorAnimating: false });

        if (r2.msg === "UPDATE_COMPLETED") {
          this.setState({ following: follow });
        } else {
          alert("Follow Request failed"); // xxhide?
        }
      } catch (err) {
        alert("Request not successful.");
        console.log(err);
        this.setState({ activityIndicatorAnimating: false });
      }
    }
  };

  callBlock = async action => {
    // call cloud function
    if (!this.state.activityIndicatorAnimating) {
      try {
        this.setState({ activityIndicatorAnimating: true });

        const r = await fetch(global.cloudFunctionURL + "blockProfile", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            myProfileID: global.myProfileID,
            targetProfileID: this.state.profileID,
            myUID: global.myUID,
            idToken: global.idToken,
            action
          })
        });
        const r2 = await r.json();

        this.setState({ activityIndicatorAnimating: false });

        if (r2.msg === "SUCCESS") {
          // change state & global
          if (action == "Block") {
            if (global.myBlocklist) {
              global.myBlocklist.push(this.state.profileID);
            }
            this.setState({ blocked: true });
          }
          if (action == "Black") {
            if (global.myBlacklist) {
              global.myBlacklist.push(this.state.profileID);
            }
            this.setState({ blacked: true });
          }
          if (action == "Unblock") {
            if (global.myBlocklist) {
              global.myBlocklist = global.myBlocklist.filter(i => {
                return i != this.state.profileID;
              });
            }
            this.setState({ blocked: false });
          }
          if (action == "Unblack") {
            if (global.myBlacklist) {
              global.myBlacklist = global.myBlacklist.filter(i => {
                return i != this.state.profileID;
              });
            }
            this.setState({ blacked: false });
          }
        } else {
          alert("An error occurred. Check internet connection and try again");
        }
      } catch (err) {
        console.log("Error during callBlock", err);
        this.setState({ activityIndicatorAnimating: false });
        await new Promise(resolve => {
          setTimeout(resolve, 200);
        });
        alert("An error occurred. Check internet connection and try again");
      }
    }
  };

  callMap = () => {
    let q = this.state.workLocationAddress;
    // if (Platform.OS === "ios") {
    //   if (this.state.workLocationLatitude) {
    //     Linking.openURL(
    //       `http://maps.apple.com/?ll=${this.state.workLocationLatitude},${
    //         this.state.workLocationLongitude
    //       }`
    //     );
    //   } else {
    //     if (this.state.workLocationAddress) {
    //       Linking.openURL(`http://maps.apple.com/?q=${q}`);
    //     }
    //   }
    // } else {
    if (this.state.workLocationAddress) {
      Linking.openURL(`http://maps.google.com/?q=${q}`);
    } else {
      if (this.state.workLocationLatitude) {
        Linking.openURL(
          `http://maps.google.com/?ll=${this.state.workLocationLatitude},${this.state.workLocationLongitude
          }`
        );
      }
    }
  };

  optionMenu = () => {
    this.setState({ modalMenuVisible: true });
  };
  optionMenuEdit = () => {
    this.setState({ modalMenuEditVisible: true });
  };
  optionMenuShare = () => {
    this.setState({ modalMenuShareVisible: true });
  };

  setModalBusinessVisible(visible) {
    this.setState({ modalBusinessVisible: visible });
  }

  shareSocial = async () => {
    if (!this.state.businessName || (!this.state.profileID && !this.state.isMyProfile)) return alert("Error. Refresh and try again.")
    const message = this.state.businessName + " on Wakanda: https://wakandamarket.com/shop/" + (this.state.profileID || global.myProfileID)
    if (Platform.OS == "ios") {
      this.setState({ menuVisible: false, });
    } else {
      this.setState({ modalMenuShareVisible: false });
      await new Promise(resolve => { setTimeout(resolve, 500); });
    }
    global.shareSocial(message)
  };

  openSocial = (s) => {
    let url = "";
    if (s == "wakanda") {
      url = "https://www.wakandamarket.com/shop/" + (global.myProfileID || "")
    } else {
      url = this.state.socials[s]
      const urlStrings = url?.split('/');
      const handle = urlStrings?.[urlStrings?.length - 1]

      if (s == "twitter") {
        url = "https://twitter.com/" + handle
      }
      if (s == "instagram") {
        url = "https://www.instagram.com/" + handle
      }
      if (s == "facebook") {
        url = "https://www.facebook.com/" + handle
      }
      if (s == "linkedIn") {
        if (url.includes("/company/")) url = "https://www.linkedin.com/company/" + handle
        else url = "https://www.linkedin.com/in/" + handle
      }
    }


    Linking.openURL(url)

    if (this.state.profileID !== global.myProfileID) global.reportStats("pressed_Profile_Socials", this.state.profileID, s)
  }

  render() {
    const a = this.state.pictureSize;
    const badges = {
      Quality: require("../../images/badges/badgeQuality.png"),
      Affordability: require("../../images/badges/badgeAffordability.png"),
      Professionality: require("../../images/badges/badgeProfessionality.png"),
      Timeliness: require("../../images/badges/badgeTimeliness.png")
    };
    const icons = {
      Call: require("../../images/icons/Call.png"),
      Chat: require("../../images/icons/Chat.png"),
      RequestService: require("../../images/icons/RequestService.png"),
      Pay: require("../../images/icons/Pay.png"),
      Location: require("../../images/icons/Location.png"),
      Following: require("../../images/icons/Following.png")
    };
    const colF = "#ccd";
    let colTrustScore = "#ccd"
    if (this.state.trustScoreBadge == "High") colTrustScore = "#93CF93"
    if (this.state.trustScoreBadge == "Very High") colTrustScore = "#93CF93"
    if (this.state.trustScoreBadge == "Low") colTrustScore = "#CF9393"
    if (this.state.trustScoreBadge == "New Provider") colTrustScore = "#CFCF93"
    if (this.state.trustScoreBadge == "OK") colTrustScore = "#CFCF93"

    if (this.state.loading) {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "black"
          }}
        >
          <ActivityIndicator
            size="large"
            color="#A8CF45"
            style={{
              opacity: 1
            }}
            animating={true}
          />
        </View>
      );
    } else {
      let blockText = "Block this user?";
      if (this.state.profileName) {
        blockText = "Block " + this.state.profileName.replace(/ .*/, "") + "?";
      }
      return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#000000" }}>
          <ScrollView style={{ backgroundColor: "black", flex: 1 }}>
            <NavigationEvents
              onDidFocus={async payload => {
                //xx replace this code when stack navigator is understood
                // or put it under componentWillUpdate?
                //check if this screen needs to be rerendered, then rerender
                try {
                  if (
                    this.props.navigation.getParam("profileID", "none") !=
                    "none" &&
                    this.state.profileID !=
                    this.props.navigation.getParam("profileID", null)
                  ) {
                    await this.unsubscribeFirestore();
                    await this.setState(this.initialState);
                    this.componentDidMount();
                  }
                } catch (err) {
                  console.log("EA 55", err);
                  console.warn("EA 55");
                }

                console.log("profile reloaded");
                //this.props.navigation.pop();
                //console.log("will focus", payload);
              }}
            />
            {/***** Background Image *****/}
            <View
              style={{
                height: 180,
                justifyContent: "flex-start",
                backgroundColor: "transparent",
                alignItems: "center",
                marginBottom: 0
                //shadowOpacity: 0,
                //shadowOffset: { width: 0, height: 1 },
                //shadowColor: "white"
              }}
            >
              {!!this.state.backgroundPicture && (
                <Image
                  key={this.state.backgroundPicture}
                  style={{ width: Dimensions.get("window").width, height: 180 }}
                  source={{
                    uri: this.state.backgroundPicture
                  }}
                />
              )}
            </View>
            <View
              style={{
                ...Platform.select({
                  ios: {
                    height: 0
                  },
                  android: {
                    height: 180,
                    backgroundColor: "rgba(44, 44, 44, 0.3)",
                    marginTop: -180,
                    marginBottom: 0
                  }
                })
              }}
            />

            {/***** Profile Image *****/}
            <View
              style={{
                marginTop: -52 * a,
                height: 104 * a,
                alignItems: "center"
              }}
            >
              <View>
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    width: 104 * a,
                    height: 104 * a,
                    borderRadius: 32 * a,
                    backgroundColor: "#000",
                    shadowOpacity: 1,
                    shadowColor: "white",
                    shadowOffset: { height: 0, width: 0 },
                    shadowRadius: 32 * a
                  }}
                />
                {this.state.picture != null && [
                  <Image
                    key={this.state.picture + "2"}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      width: 104 * a,
                      height: 104 * a,
                      borderRadius: 32 * a,
                      marginBottom: -102 * a,
                      marginTop: -104 * a,
                      opacity: 0.8
                      //backgroundColor: "black"
                    }}
                    source={{
                      uri: this.state.picture
                    }}
                    key={"key001"}
                  />,
                  <TouchableOpacity
                    key={"key002"}
                    onPress={() => {
                      this.setState({
                        pictureSize: this.state.pictureSize == 1 ? 2 : 1
                      });
                    }}
                  >
                    <Image
                      key={this.state.picture}
                      style={{
                        width: 100 * a,
                        height: 100 * a,
                        borderRadius: 30 * a,
                        marginLeft: 2 * a
                      }}
                      source={{
                        uri: this.state.picture
                      }}
                    />
                  </TouchableOpacity>
                ]}
                <View
                  style={[
                    {
                      marginBottom: -100 * a,
                      width: 100 * a,
                      height: 100 * a,
                      borderRadius: 30 * a,
                      marginLeft: 2 * a,
                      marginTop: 2 * a,
                      backgroundColor: "rgba(0,0,0,.2)"
                    },
                    this.state.picture != null && {
                      backgroundColor: "rgba(0,0,0,0.1)"
                    }
                  ]}
                />
              </View>
            </View>

            {/***** Profile Names *****/}
            <View style={{ alignItems: "center", marginTop: 20 }}>
              <global.ConditionalWrapper
                condition={!!this.state.isMyProfile && !!this.state.phoneNumber}
                wrapper={children => <TouchableOpacity onPress={() => { alert("Your phone number: " + this.state.phoneNumber) }}
                  style={{ justifyContent: "center", alignItems: "center" }} >
                  {children}</TouchableOpacity>}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Text style={{ fontSize: 18, fontWeight: "bold", color: colF }}>
                    {this.state.name}
                  </Text>
                </View>
                <Text style={{ fontSize: 12, color: colF }}>
                  {this.state.businessName}
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 5
                  }}
                >
                  {this.state.badges.map(badge => {
                    return (
                      <Image
                        key={"b_" + badge}
                        style={{
                          width: 21,
                          height: 21
                        }}
                        source={badges[badge]}
                      />
                    );
                  })}
                </View>
              </global.ConditionalWrapper>
            </View>

            {/***** Follow Button Line *****/}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                height: 80
              }}
            >
              {!!this.state.trustScoreBadge &&
                this.state.businessName != "" &&
                this.state.profileID != "F4C7tJCPqxdpawvBpd2B" && (
                  <TouchableOpacity
                    onPress={() => { this.setState({ modalTrustVisible: true }) }}
                    style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
                  >
                    <Text
                      style={{ fontSize: 12, color: colF }}
                    >
                      Trust Score
                        </Text>
                    <Text
                      style={{ fontSize: 13, fontWeight: "bold", marginTop: 2, color: colTrustScore }}
                    >
                      {this.state.trustScoreBadge} ⓘ
                    </Text>
                  </TouchableOpacity>
                )}
              {/* {!!this.state.businessName &&
                this.state.businessName != "" &&
                this.state.profileID != "F4C7tJCPqxdpawvBpd2B" && (
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 18,
                        color: colF
                      }}
                    >
                      {this.state.jobsDoneCount}
                    </Text>
                    <Text
                      style={{
                        fontSize: 12,
                        color: colF
                      }}
                    >
                      Jobs done
                    </Text>
                  </View>
                )} */}
              {!this.state.isMyProfile && !this.state.blocked && (
                <View style={{ flex: 1, marginLeft: 22, marginRight: 22 }}>
                  <View style={{}}>
                    {this.state.following ? (
                      <View style={{}}>
                        <TouchableOpacity
                          style={{ alignItems: "center", marginBottom: 4 }}
                          onPress={() => {
                            this.setState({
                              showUnfollow: !this.state.showUnfollow
                            });
                          }}
                        >
                          <Image
                            style={{
                              width: 20,
                              height: 25,
                              marginRight: 7,
                              tintColor: colF
                            }}
                            source={icons["Following"]}
                          />
                          <Text style={{ color: colF, fontSize: 12 }}>
                            Following
                          </Text>
                        </TouchableOpacity>

                        {/***** Unfollow button *****/}
                        {this.state.showUnfollow && (
                          <View style={{ padding: 0, marginHorizontal: 0 }}>
                            <TouchableOpacity
                              onPress={() => {
                                this.setState({ showUnfollow: false });
                                this.callFollow(false);
                              }}
                              style={{
                                alignSelf: "stretch",
                                alignItems: "center",
                                marginBottom: 5,
                                marginTop: 5,
                                backgroundColor: "#400",
                                padding: 7,
                                borderRadius: 3
                              }}
                            >
                              <Text
                                style={[
                                  global.gStyles.buttontext,
                                  {
                                    color: colF,
                                    fontWeight: "normal",
                                    fontSize: 12
                                  }
                                ]}
                              >
                                Unfollow
                              </Text>
                            </TouchableOpacity>
                          </View>
                        )}
                      </View>
                    ) : (
                      this.state.following != null && (
                        <TouchableOpacity
                          style={global.gStyles.button}
                          onPress={() => {
                            this.callFollow(true);
                          }}
                        >
                          <Text style={global.gStyles.buttontext}>Follow</Text>
                        </TouchableOpacity>
                      )
                    )}
                  </View>
                </View>
              )}
              {this.state.myLocation != null &&
                this.state.workLocationLatitude != null &&
                this.state.locationPermissionGranted && (
                  <TouchableOpacity
                    onPress={() => this.callMap()}
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Image
                        style={{
                          width: 20,
                          height: 22,
                          marginRight: 7,
                          marginBottom: 0,
                          tintColor: colF
                        }}
                        source={icons["Location"]}
                      />
                      <Text style={{ fontSize: 12, color: colF }}>
                        {Math.round(
                          distance(
                            this.state.workLocationLatitude,
                            this.state.workLocationLongitude,
                            this.state.myLocation.coords.latitude,
                            this.state.myLocation.coords.longitude,
                            "K"
                          ) * 10
                        ) /
                          10 +
                          " km"}
                      </Text>
                    </View>
                  </TouchableOpacity>
                )}
            </View>
            <View
              style={{
                paddingLeft:
                  Dimensions.get("window").height > 800 && Platform.OS === "ios"
                    ? 5
                    : 12,
                paddingRight:
                  Dimensions.get("window").height > 800 && Platform.OS === "ios"
                    ? 5
                    : 12
              }}
            >
              {!!this.state.activityIndicatorAnimating && (
                <View style={{ marginTop: -20 }}>
                  <ActivityIndicator
                    size="small"
                    color="#FFFFFF"
                    //animating={this.state.activityIndicatorAnimating}
                    style={{
                      opacity: this.state.activityIndicatorAnimating ? 1.0 : 0.0
                    }}
                    animating={true}
                  />
                </View>
              )}
              {!!this.state.blocked && (
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    height: 70
                  }}
                >
                  <Text style={{ color: "#a00" }}>
                    You have blocked this user.
                  </Text>
                  <TouchableOpacity
                    style={{
                      height: 30,
                      margin: 5,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 5,
                      borderWidth: 0,
                      backgroundColor: "#A8CF45",
                      borderColor: "#900"
                    }}
                    onPress={async () => {
                      this.setState({ modalMenuVisible: false });
                      await new Promise(resolve => {
                        setTimeout(resolve, 100);
                      });
                      this.setState({ modalUnblockVisible: true });
                    }}
                  >
                    <Text
                      style={{
                        color: "#000",
                        fontSize: 12,
                        fontWeight: "bold",
                        padding: 5
                      }}
                    >
                      Unblock
                    </Text>
                  </TouchableOpacity>
                </View>
              )}
              {!!this.state.blacked && (
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    height: 70
                  }}
                >
                  <Text style={{ color: "#a00" }}>
                    You do not accept jobs from this user.
                  </Text>
                  {!this.state.activityIndicatorAnimating && (
                    <TouchableOpacity
                      style={{
                        height: 30,
                        margin: 5,
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 5,
                        borderWidth: 0,
                        backgroundColor: "#A8CF45",
                        borderColor: "#900"
                      }}
                      onPress={() => {
                        this.callBlock("Unblack");
                      }}
                    >
                      <Text
                        style={{
                          color: "#000",
                          fontSize: 12,
                          fontWeight: "bold",
                          padding: 5
                        }}
                      >
                        Accept jobs again
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              )}
              {/***** Call Chat Pay Order Line *****/}
              {!this.state.isMyProfile && !this.state.blocked && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#222",
                    height: 70,
                    borderTopWidth: 0,
                    marginBottom: 10,
                    borderBottomColor: "#444",
                    borderTopColor: "#444",
                    borderRadius: 24
                  }}
                >
                  {/***** Call *****/}
                  {this.state.phoneNumber != "" && (
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          this.callCall();
                        }}
                        style={{
                          alignItems: "center",
                          justifyContent: "flex-end",
                          backgroundColor: "#000",
                          width: 55,
                          height: 55,
                          borderRadius: 18,
                          borderWidth: 0,
                          borderColor: "#444",
                          paddingHorizontal: 8,
                          paddingVertical: 5
                        }}
                      >
                        <Image
                          style={{
                            width: 25,
                            height: 25,
                            marginBottom: 4
                          }}
                          source={icons["Call"]}
                        />
                        <Text
                          style={{
                            fontSize: 12,
                            color: colF,
                            fontWeight: "bold"
                          }}
                        >
                          Call
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )}
                  {/***** Chat *****/}
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        this.callChat();
                      }}
                      style={{
                        alignItems: "center",
                        justifyContent: "flex-end",
                        backgroundColor: "#000",
                        width: 55,
                        height: 55,
                        borderRadius: 18,
                        borderWidth: 0,
                        borderColor: "#444",
                        paddingHorizontal: 8,
                        paddingVertical: 5
                      }}
                    >
                      <Image
                        style={{
                          width: 17,
                          height: 17,
                          marginBottom: 5.5
                        }}
                        source={icons["Chat"]}
                      />
                      <Text
                        style={{
                          fontSize: 12,
                          color: colF,
                          fontWeight: "bold"
                        }}
                      >
                        Chat
                      </Text>
                    </TouchableOpacity>
                  </View>
                  {/********** Pay **********/}
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    {
                      <TouchableOpacity
                        onPress={() => {
                          if (this.state.usesACN && global.walletType == "NGN") {
                            alert("You cannot pay providers outside Nigeria for now.")
                            return
                          }
                          if (!this.state.usesACN && global.walletType == "ACN") {
                            alert("You cannot pay Nigerian providers for now.")
                            return
                          }
                          this.props.navigation.navigate("mePay", {
                            action: "payment",
                            recipientProfileID: this.state.profileID,
                            recipientName: this.state.name,
                            nextScreen: "meProfile"
                          });
                          global.reportStats("pressed_Pay", this.state.profileID, "PROFILE")
                        }}
                        style={{
                          alignItems: "center",
                          justifyContent: "flex-end",
                          backgroundColor: "#000",
                          width: 55,
                          height: 55,
                          borderRadius: 18,
                          borderWidth: 0,
                          borderColor: "#444",
                          paddingHorizontal: 8,
                          paddingVertical: 5
                        }}
                      >
                        <Image
                          style={{
                            width: 20,
                            height: 13,
                            marginBottom: 7.5
                          }}
                          source={icons["Pay"]}
                        />
                        <Text
                          style={{
                            fontSize: 12,
                            color: colF,
                            fontWeight: "bold"
                          }}
                        >
                          Pay
                        </Text>
                      </TouchableOpacity>
                    }
                  </View>
                  {/***** Request Service *****/}
                  {this.state.businessName != "" &&
                    this.state.servicesOffered != null && (
                      <View
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            this.callRequestService();
                          }}
                          style={{
                            alignItems: "center",
                            justifyContent: "flex-end",
                            backgroundColor: "#000",
                            width: 55,
                            height: 55,
                            borderRadius: 18,
                            borderWidth: 0,
                            borderColor: "#444",
                            paddingHorizontal: 8,
                            paddingVertical: 5
                          }}
                        >
                          <Image
                            style={{
                              width: 21,
                              height: 21,
                              marginBottom: 2.5
                            }}
                            source={icons["RequestService"]}
                          />
                          <Text
                            style={{
                              fontSize: 12,
                              color: colF,
                              fontWeight: "bold"
                            }}
                          >
                            Order
                          </Text>
                        </TouchableOpacity>
                      </View>
                    )}
                </View>
              )}
              {/***** Disclaimer for Unverified Health Providers *****/}
              {!!this.state.isMedical && (
                <View
                  style={{
                    backgroundColor: "#300",
                    height: 24, //30,
                    borderRadius: 2,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  {/* <Text style={{ color: colF, fontSize: 12 }}>
                    This profile is not yet verified.
                  </Text> */}
                  <Text style={{ color: colF, fontSize: 12 }}>
                    ⓘ Only use health services you trust and report fake profiles.
                  </Text>
                </View>
              )}
              {/***** Description Text *****/}
              {this.state.businessName != null &&
                this.state.businessName != "" &&
                this.state.description != null &&
                this.state.description != "" && (
                  <View
                    style={{
                      justifyContent: "space-around",
                      paddingLeft: 2,
                      paddingRight: 2,
                      backgroundColor: "#000",
                      height:
                        this.state.description.length <= 200
                          ? 110
                          : 150 + 0.55 * (this.state.description.length - 200),
                      borderTopWidth: 0,
                      borderTopColor: "#A8CF45",
                      borderBottomWidth: 1,
                      borderBottomColor: "#444"
                    }}
                  >
                    <Text
                      style={{
                        //textAlign: "center",
                        color: colF,
                        fontSize: this.state.description.length <= 160 ? 14 : 14
                      }}
                    >
                      {this.state.description}
                    </Text>
                  </View>
                )}
              {/***** ACoin Trader Description Text *****/}
              {this.state.isActiveTrader &&
                (
                  <View
                    style={{
                      justifyContent: "space-around",
                      paddingLeft: 2,
                      paddingRight: 2,
                      backgroundColor: "#000",
                      borderBottomWidth: 1,
                      borderBottomColor: "#444"
                    }}
                  >
                    <View style={{ flexDirection: "row", marginTop: 16, alignItems: "flex-end" }}>
                      <Image
                        style={{
                          width: 25,
                          height: 20,
                          marginRight: 5,
                        }}
                        source={require("../../images/logos/home_logo.png")}
                      />
                      <Text
                        style={{
                          fontSize: 14,
                          color: colF,
                          fontWeight: "bold"
                        }}
                      >
                        VERIFIED ACOIN TRADER
              </Text>
                    </View>
                    <Text
                      style={{
                        //textAlign: "center",
                        color: colF,
                        fontSize: 14,
                        marginTop: 10,
                        marginBottom: 20
                      }}
                    >
                      {this.state.traderDescription}
                    </Text>
                  </View>
                )}

              {/***** Posts *****/}
              {this.state.businessName != null &&
                !this.state.blackingYou &&
                !this.state.blockingYou &&
                this.state.businessName != "" && (
                  <View style={{ marginVertical: 5 }}>
                    {!!this.state.postIDs && this.state.postIDs != [] && this.state.postIDs.length > 0 && (
                      <TouchableOpacity
                        onPress={() => {
                          if (this.state.isMyProfile) {
                            this.props.navigation.navigate("mePosts");
                          }
                        }}
                        style={{ paddingTop: 20 }}
                      >
                        <View style={{ flexDirection: "row" }}>
                          <Text
                            style={{
                              color: "#A8CF45",
                              fontSize: 14,
                              marginBottom: 0
                            }}
                          >
                            My {global.a.offers || "Offers"}
                          </Text>
                          {/* {!!this.state.isMyProfile && <Image
                            style={{ width: 18, height: 18, marginLeft: 8 }}
                            source={require("../../images/icons/Change.png")}
                          />} */}
                        </View>
                      </TouchableOpacity>
                    )}
                    <MyPosts
                      postIDs={this.state.postIDs}
                      navigation={this.props.navigation}
                      isMyProfile={
                        this.state.isMyProfile ||
                        this.state.blackingYou ||
                        this.state.blockingYou
                      }
                    />
                  </View>
                )}

              {/***** Job Qualifications *****/}
              {!!this.state.jobQualifications && this.state.jobQualifications.length > 0 && <View>
                <View style={{ paddingTop: 20, paddingBottom: 10 }}>
                  <Text style={{ color: "#A8CF45", fontSize: 14, marginBottom: 5 }}>My Job Qualifications</Text>
                </View>
                {this.state.jobQualifications.map(jq => {
                  return (<View key={jq}>
                    <View style={{ paddingTop: -10, paddingBottom: 10, flexDirection: "row" }}>
                      <Text style={{ color: colF, fontSize: 14 }}>{jq}</Text>
                    </View>
                  </View>)
                })}
              </View>}

              {/***** Services Offered *****/}
              {this.state.businessName != null &&
                this.state.businessName != "" &&
                this.state.profileID != "F4C7tJCPqxdpawvBpd2B" && (
                  <View style={{ marginVertical: 5 }}>
                    {this.state.servicesOffered != null && (
                      <View>
                        {global.app == "speakers" ? (<View />) : !this.state.showMyServices ? (
                          <TouchableOpacity
                            style={{ paddingTop: 20, paddingBottom: 20 }}
                            onPress={() => {
                              this.setState({ showMyServices: true });
                            }}
                          >
                            <Text
                              style={{
                                color: "#A8CF45",
                                fontSize: 14,
                                marginBottom: 5
                              }}
                            >
                              ▷ Show My Services
                            </Text>
                          </TouchableOpacity>
                        ) : (
                          <View>
                            {/* <TouchableOpacity
                                style={{ paddingTop: 20, paddingBottom: 10 }}
                                onPress={() => {
                                  this.setState({ showMyServices: false });
                                }}
                              >
                                <Text
                                  style={{
                                    color: "#A8CF45",
                                    fontSize: 14,
                                    marginBottom: 5
                                  }}
                                >
                                  ▽ My Services
                              </Text>
                              </TouchableOpacity> */}
                            <Text
                              style={{ color: "#A8CF45", fontSize: 14, marginBottom: 5, paddingTop: 20, paddingBottom: 10 }}
                            >My Services</Text>
                            <MyServicesOffered
                              services={this.state.servicesOffered}
                              verifiedSkills={this.state.verifiedSkills}
                              colF={colF}
                              navigation={this.props.navigation}
                              isMyProfile={
                                this.state.isMyProfile ||
                                this.state.blackingYou ||
                                this.state.blockingYou
                              }
                              profileID={this.state.profileID}
                              offeredCategories={this.state.servicesOffered}
                            />
                          </View>
                        )}

                        {/***** Skills *****/}
                        {!!this.state.skills && this.state.skills.length > 0 && this.state.skills.map(cat2 => {
                          let cat2s = cat2.replace(/[^a-zA-Z]/g, "")
                          let expertiseString = "My " + cat2 + " Expertise"
                          if (cat2 == "Public Speakers") expertiseString = "My Speaker Expertise"
                          if (cat2 == "Teaching - Primary") expertiseString = "My Primary Teaching Expertise"
                          if (cat2 == "Teaching - Secondary") expertiseString = "My Secondary Teaching Expertise"
                          if (cat2 == "Teaching - Tertiary") expertiseString = "My Tertiary Teaching Expertise"
                          return (<View key={cat2s}>
                            <View style={{ paddingTop: 20, paddingBottom: 10 }}>
                              <Text style={{ color: "#A8CF45", fontSize: 14, marginBottom: 5 }}>{expertiseString}</Text>
                            </View>
                            <View style={{ paddingTop: -10, paddingBottom: 10, flexDirection: "row" }}>
                              <Text style={{ color: colF, fontSize: 14 }}>
                                {this.state["skills" + cat2s].map((skill, i) => {
                                  return skill + ((i == (this.state["skills" + cat2s].length - 1)) ? "" : ", ")
                                })}
                              </Text>
                            </View>
                          </View>)
                        })}

                        {/***** Location *****/}
                        {!!this.state.workLocationAddress && (
                          <View>
                            <View style={{ paddingTop: 15, paddingBottom: 10 }}>
                              <Text
                                style={{
                                  color: "#A8CF45",
                                  fontSize: 14,
                                  marginBottom: 5
                                }}
                              >
                                My Location
                              </Text>
                            </View>
                            <TouchableOpacity onPress={() => this.callMap()}>
                              <Text style={{ color: colF }}>
                                {this.state.workLocationAddress}
                              </Text>
                            </TouchableOpacity>
                          </View>
                        )}
                      </View>
                    )}
                  </View>
                )}
              {(this.state.websiteURL || (this.state.socials && Object.keys(this.state.socials).length > 0)) &&
                <View style={{ marginTop: 20, paddingTop: 10, paddingBottom: 10, borderTopWidth: 1, borderTopColor: "#444", backgroundColor: "#212121" }} >

                  {/***** More *****/}
                  {/* {((this.state.socials && Object.keys(this.state.socials).length > 0) || this.state.websiteURL) &&
                <View style={{ paddingTop: 20, paddingBottom: 10 }}>
                  <Text style={{ color: "#A8CF45", fontSize: 14 }}>More</Text>
                </View>} */}

                  {/***** My Website *****/}
                  {(this.state.websiteURL) && <View style={{ width: "100%", alignItems: "center" }}>
                    <TouchableOpacity style={{ paddingTop: 7, paddingBottom: 7 }}
                      onPress={() => {
                        let url = this.state.websiteURL
                        if (!url.startsWith("http:")) url = "http://" + url
                        Linking.openURL(url)
                        if (this.state.profileID !== global.myProfileID) global.reportStats("pressed_Profile_Website", this.state.profileID)
                      }}>
                      <Text style={{ color: "#0088cc", fontSize: 14 }}>{this.state.websiteURL}</Text>
                    </TouchableOpacity>
                  </View>}

                  {/***** Socials *****/}
                  {(this.state.socials && Object.keys(this.state.socials).length > 0) && <View style={{ width: "100%", alignItems: "center" }}>
                    <ScrollView horizontal
                      style={{ margin: 5 }}>
                      {Object.keys(this.state.socials).map((s, i) => {
                        return (<View key={s} style={{ flexDirection: "row" }}>
                          {i == 0 && <TouchableOpacity onPress={() => {
                            this.openSocial("wakanda")
                          }}>
                            <Image
                              style={{ width: 30, height: 30, marginRight: 15 }}
                              source={this.socialImages["wakanda"]}
                            />
                          </TouchableOpacity>}
                          <TouchableOpacity onPress={() => {
                            this.openSocial(s)
                          }}>
                            <Image
                              style={{ width: 30, height: 30, marginRight: 15 }}
                              source={this.socialImages[s]}
                            />
                          </TouchableOpacity>
                        </View>)
                      })}
                    </ScrollView>
                  </View>}
                </View>}



              {this.state.contactsFollowing.length > 0 && (
                <View
                  style={{
                    marginVertical: 15,
                    borderTopWidth: 1,
                    borderTopColor: "#444",
                    paddingTop: 10
                  }}
                >
                  <Text
                    style={{ color: "#999", fontSize: 14, marginBottom: 5 }}
                  >
                    MUTUAL FOLLOWERS ({this.state.contactsFollowing.length})
                  </Text>

                  <MyFollowers
                    followers={this.state.contactsFollowing}
                    colF={colF}
                    navigation={this.props.navigation}
                  />
                </View>
              )}
            </View>

          </ScrollView>
          {/***** Guide (first time only) *****/}
          {/* {!this.state.isMyProfile && !!this.state.businessName && (
            <Guide
              name={"profile"}
              marginBottom={0}
              height={120}
              offsetLion={-30}
              text={`This is a provider profile.
            
            If you like the provider, call or chat with them to discuss your needs. Once you agree, press order!`}
            />
          )} */}
          {/***** Offer your services box *****/}
          {
            !!this.state.isMyProfile && !!this.state.businessName == "" && (
              <View style={{ backgroundColor: "#aaa", padding: 16, margin: 20 }}>
                <Text style={{ color: "black", fontWeight: "bold", fontSize: 16 }}>
                  {global.a.doYouSellString || "Do you sell goods or services?"}
                </Text>
                <Text style={{ color: "black", fontSize: 12, marginTop: 2 }}>
                  {global.a.becomeAProviderString || "Become a provider on Wakanda now."}
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    this.setModalBusinessVisible(true);
                  }}
                  style={[global.gStyles.button, { marginTop: 20 }]}
                >
                  <Text style={global.gStyles.buttontext}>Open a shop!</Text>
                </TouchableOpacity>
              </View>
            )
          }
          <Modal
            animationType="fade"
            transparent={true}
            visible={this.state.modalMenuVisible}
            style={{}}
            onRequestClose={() => {
              //alert("Modal has been closed.");
            }}
          >
            <TouchableWithoutFeedback
              onPress={() => this.setState({ modalMenuVisible: false })}
            >
              <View
                style={{
                  height: "100%",
                  width: "100%",
                  alignItems: "flex-end"
                }}
              >
                <View
                  style={{
                    marginRight: 20,
                    marginTop: 60,
                    //height: 150 + (this.state.canVerifyProfile ? 50 : 0), // +50 if amAdmin
                    width: 200,
                    backgroundColor: "rgba(0,0,00,.9)",
                    borderRadius: 5,
                    borderColor: "#ccc",
                    borderWidth: 1
                  }}
                >
                  <TouchableOpacity
                    style={{
                      height: 50,
                      width: 200,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 15,
                      borderBottomWidth: 1,
                      borderColor: "#ccc"
                    }}
                    onPress={async () => {
                      this.setState({ modalMenuVisible: false });
                      await new Promise(resolve => {
                        setTimeout(resolve, 100);
                      });
                      if (!this.state.blocked) {
                        this.setState({ modalBlockVisible: true });
                      } else {
                        this.setState({ modalUnblockVisible: true });
                      }
                    }}
                  >
                    <Text style={{ color: "#ccc", fontWeight: "bold" }}>
                      {!this.state.blocked ? "Block User" : "Unblock User"}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      height: 50,
                      width: 200,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 15
                    }}
                    onPress={async () => {
                      this.setState({ modalMenuVisible: false });
                      await new Promise(resolve => {
                        setTimeout(resolve, 30);
                      });
                      this.props.navigation.navigate("meReport", {
                        profileID: this.state.profileID,
                        profileName: this.state.name,
                        profileBusinessName: this.state.businessName,
                        profilePicture: this.state.picture,
                        profileBackgroundPicture: this.state.backgroundPicture,
                        profileDescription: this.state.description,
                        isProfileReport: true
                      });
                    }}
                  >
                    <Text style={{ color: "#ccc", fontWeight: "bold" }}>
                      Report User
                    </Text>
                  </TouchableOpacity>
                  {!!global.amAdmin && <TouchableOpacity
                    style={{
                      height: 50,
                      width: 200,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 15,
                      borderTopWidth: 1,
                      borderColor: "#ccc"
                    }}
                    onPress={async () => {
                      this.setState({ modalMenuVisible: false });
                      await new Promise(resolve => { setTimeout(resolve, 30); });
                      this.setState({ modalAdminVisible: true });
                    }}
                  >
                    <Text style={{ color: "#ccc", fontWeight: "bold" }}>
                      Admin
                    </Text>
                  </TouchableOpacity>}
                  {!!this.state.canVerifyProfile && <TouchableOpacity
                    style={{
                      height: 50,
                      width: 200,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 15,
                      borderTopWidth: 1,
                      borderColor: "#ccc"
                    }}
                    onPress={async () => {
                      this.setState({ modalMenuVisible: false });
                      await new Promise(resolve => { setTimeout(resolve, 30); });
                      let networkIDs = []
                      if (!global.networkAdminRights) return;
                      global.networkAdminRights.forEach(networkID => {
                        if (this.state.networks && this.state.networks.includes(networkID)) {
                          networkIDs.push(networkID)
                        }
                      })

                      this.props.navigation.navigate("networkVerification", {
                        profileID: this.state.profileID,
                        profileName: this.state.name,
                        profileBusinessName: this.state.businessName,
                        profilePicture: this.state.picture,
                        profileBackgroundPicture: this.state.backgroundPicture,
                        networkIDs,
                      });
                    }}
                  >
                    <Text style={{ color: "#8a8", fontWeight: "bold" }}>
                      Verify Profile
                    </Text>
                  </TouchableOpacity>}
                </View>
              </View>
            </TouchableWithoutFeedback>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={this.state.modalMenuEditVisible}
            onRequestClose={() => {
              //alert("Modal has been closed.");
            }}
          >
            <TouchableWithoutFeedback
              onPress={() => this.setState({ modalMenuEditVisible: false })}
            >
              <View
                style={{
                  height: "100%",
                  width: "100%",
                  alignItems: "flex-end"
                }}
              >
                <View
                  style={{
                    marginRight: 20,
                    marginTop: 60,
                    height: 100,
                    width: 200,
                    backgroundColor: "rgba(0,0,0,.9)",
                    borderRadius: 5,
                    borderColor: "#ccc",
                    borderWidth: 1
                  }}
                >
                  <TouchableOpacity
                    style={{
                      height: 50,
                      width: 200,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 15,
                      borderBottomWidth: 1,
                      borderColor: "#ccc"
                    }}
                    onPress={async () => {
                      this.setState({ modalMenuEditVisible: false });
                      await new Promise(resolve => {
                        setTimeout(resolve, 100);
                      });
                      let route = "meProfileUpdate"
                      if (this.props.navigation.state && this.props.navigation.state.routeName && this.props.navigation.state.routeName.startsWith("home"))
                        route = "homeProfileUpdate"
                      this.props.navigation.navigate(route);
                    }}
                  >
                    <Text style={{ color: "#ccc", fontWeight: "bold" }}>
                      Edit Profile
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      height: 50,
                      width: 200,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 15,
                      borderBottomWidth: 1,
                      borderColor: "#ccc"
                    }}
                    onPress={async () => {
                      this.setState({ modalMenuEditVisible: false });
                      await new Promise(resolve => {
                        setTimeout(resolve, 100);
                      });
                      this.props.navigation.navigate("mePosts");
                    }}
                  >
                    <Text style={{ color: "#ccc", fontWeight: "bold" }}>
                      Edit {(global.app == "speakers" || global.app == "artisan") ? "Samples / Offers" : "Offers"}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </TouchableWithoutFeedback>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={this.state.modalMenuShareVisible}
            onRequestClose={() => {
              //alert("Modal has been closed.");
            }}
          >
            <TouchableWithoutFeedback
              onPress={() => this.setState({ modalMenuShareVisible: false })}
            >
              <View
                style={{
                  height: "100%",
                  width: "100%",
                  alignItems: "flex-end"
                }}
              >
                <View
                  style={{
                    marginRight: 20,
                    marginTop: 60,
                    height: !!this.state.businessName ? 100 : 50,
                    width: 200,
                    backgroundColor: "rgba(0,0,0,.9)",
                    borderRadius: 5,
                    borderColor: "#ccc",
                    borderWidth: 1
                  }}
                >
                  <TouchableOpacity
                    style={{
                      height: 50,
                      width: 200,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 15,
                      borderBottomWidth: 1,
                      borderColor: "#ccc",
                      flexDirection: "row",
                    }}
                    onPress={async () => {
                      this.setState({ modalMenuShareVisible: false });
                      await new Promise(resolve => { setTimeout(resolve, 100); });
                      this.props.navigation.navigate("meSelectContact", {
                        input: this.state.profileID,
                        purpose: "ShareGivenContact"
                      });
                    }}
                  >
                    <Image
                      style={{ width: 42, height: 30, marginRight: 15, tintColor: "#fff" }}
                      source={require("../../images/logos/home_logo.png")}
                    />
                    <Text style={{ color: "#ccc", fontWeight: "bold" }}>
                      Forward
                    </Text>
                  </TouchableOpacity>
                  {!!this.state.businessName && <TouchableOpacity
                    style={{
                      height: 50,
                      width: 200,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 15,
                      borderBottomWidth: 1,
                      borderColor: "#ccc",
                      flexDirection: "row"
                    }}
                    onPress={() => { this.shareSocial() }}
                  >
                    <Image
                      style={{ width: 30, height: 30, marginRight: 19, tintColor: "#fff" }}
                      source={require("../../images/icons/Share.png")}
                    />
                    <Text style={{ color: "#ccc", fontWeight: "bold", marginRight: 8 }}>
                      Share
                    </Text>
                  </TouchableOpacity>}
                </View>
              </View>
            </TouchableWithoutFeedback>
          </Modal>
          <Modal
            animationType="slide"
            transparent={false}
            visible={this.state.modalBusinessVisible}
            onRequestClose={() => {
              //alert("Modal has been closed.");
            }}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: "#ccc",
                justifyContent: "center"
              }}
            >
              {this.state.modalPage == 1 ? (
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                    margin: 25
                  }}
                >
                  {global.app == "speakers" ? <View>
                    <Text style={{ fontSize: 24, alignSelf: "center" }}>
                      Open a Shop
                  </Text>
                    <View style={{ height: 40 }} />
                    <BulletPoint style={{ fontSize: 16, marginBottom: 5 }}>
                      You tell us your fields of speaking expertise and
                      your minimum speaker's fee
                  </BulletPoint>
                    <BulletPoint style={{ fontSize: 16, marginBottom: 5 }}>
                      We will regularly contact you with customers that look for
                      what you offer
                  </BulletPoint>
                    <BulletPoint style={{ fontSize: 16, marginBottom: 5 }}>
                      If a customer orders your service, you can select to be
                      protected by the Wakanda Escrow Service
                  </BulletPoint>
                    <BulletPoint style={{ fontSize: 16, marginBottom: 5 }}>
                      Customers who like your work will give good
                      feedback and you will receive more and better requests
                  </BulletPoint>
                    <BulletPoint style={{ fontSize: 16, marginBottom: 5 }}>
                      In addition, you can post videos as work samples
                  </BulletPoint>
                  </View>
                    :
                    <View>
                      <Text style={{ fontSize: 24, alignSelf: "center" }}>
                        Open a Shop
                  </Text>
                      <View style={{ height: 40 }} />
                      <BulletPoint style={{ fontSize: 16, marginBottom: 5 }}>
                        You tell us the category of goods or services you offer
                  </BulletPoint>
                      <BulletPoint style={{ fontSize: 16, marginBottom: 5 }}>
                        We will regularly contact you with customers that look for
                        what you offer
                  </BulletPoint>
                      <BulletPoint style={{ fontSize: 16, marginBottom: 5 }}>
                        If customers like your work, they will give good
                        feedback and you will receive more and better requests
                  </BulletPoint>
                      <BulletPoint style={{ fontSize: 16, marginBottom: 5 }}>
                        In addition, you can post offers
                  </BulletPoint>
                    </View>}
                  <View style={{ height: 40 }} />
                  <TouchableOpacity
                    style={[global.gStyles.button, { margin: 21 }]}
                    onPress={async () => {
                      this.setState({ modalPage: 2 });
                    }}
                  >
                    <Text style={global.gStyles.buttontext}>
                      Okay, I want to open a shop
                    </Text>
                  </TouchableOpacity>
                  <View style={{ height: 40 }} />
                  <TouchableHighlight
                    onPress={async () => {
                      this.setModalBusinessVisible(false);
                      await new Promise(resolve => {
                        setTimeout(resolve, 90);
                      });
                      this.setState({ modalPage: 1 });
                    }}
                    style={{ alignSelf: "center" }}
                  >
                    <Text style={{ color: "black", fontSize: 16 }}>
                      Maybe later
                    </Text>
                  </TouchableHighlight>
                </View>
              ) : (
                <ScrollView
                  style={{
                    padding: 25,
                    backgroundColor: "#dbb",
                    borderRadius: 15,
                    height: "90%"
                  }}
                >
                  <View style={{ height: 60 }} />
                  <Text style={{ fontSize: 24, alignSelf: "center" }}>
                    Agreement
                  </Text>
                  <View style={{ height: 25 }} />
                  <Text style={{ fontSize: 14 }}>
                    When offering your goods and services{(global.app == "market" || global.app == "education") && " on Wakanda"}, your
                      phone number will be accessible to people who search for your
                      goods, services, or business name so they can contact you.
                  </Text>
                  <Text style={{ fontSize: 2 }} />
                  <Text style={{ fontSize: 14 }}>
                    Customers interested in your work will contact you. You are
                      not allowed to contact unknown {(global.app == "market" || global.app == "education") && "Wakanda "}users to
                      advertise your goods or services.
                  </Text>
                  <Text style={{ fontSize: 2 }} />
                  <Text style={{ fontSize: 14 }}>
                    Your profile information must be an honest representation of
                    your business.
                  </Text>
                  <Text style={{ fontSize: 2 }} />
                  <Text style={{ fontSize: 14 }}>
                    If you accept orders from customers, you have to fulfill
                    them unless there is convincing reason. Every customer will
                    be able to rate your work: The better your reviews, the more
                    jobs you will get in the future.
                  </Text>
                  <Text style={{ fontSize: 2 }} />
                  <Text style={{ fontSize: 14 }}>
                    All users have the chance to block users and report
                    fraudulent, misleading, insulting or other inappropriate
                    behavior or information. We will look into such reports and
                    act within 24 hours.
                  </Text>
                  <Text style={{ fontSize: 2 }} />
                  <View style={{ flexDirection: "row" }}>
                    <Text style={{ fontSize: 14 }}>I agree to the </Text>
                    <TouchableOpacity
                      onPress={() =>
                        Linking.openURL(
                          global.termsConditionsLink || "https://www.african-founders.com/terms-and-conditions"
                        )
                      }
                    >
                      <Text style={{ color: "blue" }}>
                        Terms and Conditions
                      </Text>
                    </TouchableOpacity>
                    <Text style={{ fontSize: 14 }}>.</Text>
                  </View>
                  <View style={{ height: 25 }} />
                  <TouchableOpacity
                    style={[global.gStyles.button, { margin: 21 }]}
                    onPress={async () => {
                      this.setModalBusinessVisible(false);
                      this.setState({ modalPage: 1 });
                      // xx tbd: unmount this screen (but why??)
                      await new Promise(resolve => {
                        setTimeout(resolve, 500);
                      });
                      let route = "meProfileUpdate"
                      if (this.props.navigation.state && this.props.navigation.state.routeName && this.props.navigation.state.routeName.startsWith("home"))
                        route = "homeProfileUpdate"
                      this.props.navigation.navigate(route, { isNewBusiness: true });
                    }}
                  >
                    <Text style={global.gStyles.buttontext}>Okay, I agree</Text>
                  </TouchableOpacity>
                  <View style={{ height: 25 }} />
                  <TouchableHighlight
                    onPress={async () => {
                      this.setModalBusinessVisible(false);
                      await new Promise(resolve => {
                        setTimeout(resolve, 900);
                      });
                      this.setState({ modalPage: 1 });
                      alert(
                        global.app == "market" ? "Reminder: By using Wakanda, you agree to its Terms and Conditions" : "Reminder: By using this app, you agree to its Terms and Conditions"
                      );
                    }}
                    style={{ alignSelf: "center" }}
                  >
                    <Text style={{ color: "black", fontSize: 14 }}>Cancel</Text>
                  </TouchableHighlight>
                  <View style={{ height: 100 }} />
                </ScrollView>
              )}
            </View>
          </Modal>

          {/* Modal BLOCK USER */}
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.modalBlockVisible}
            onRequestClose={() => {
              //alert("Modal has been closed.");
            }}
          >
            <View
              style={{
                flex: 1,
                justifyContent: "center"
              }}
            >
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "flex-start",
                  backgroundColor: "#ccc",
                  margin: 10,
                  padding: 15,
                  borderRadius: 15
                }}
              >
                <Text
                  style={{
                    fontSize: 24,
                    marginBottom: 10,
                    marginTop: 10,
                    alignSelf: "center"
                  }}
                >
                  {blockText}
                </Text>
                <View style={{ height: 40 }} />
                <BulletPoint style={{ fontSize: 18, marginBottom: 10 }}>
                  You will not see any messages by this user.
                </BulletPoint>
                <BulletPoint style={{ fontSize: 18, marginBottom: 10 }}>
                  You will not be able to call each other.
                </BulletPoint>
                <BulletPoint style={{ fontSize: 18, marginBottom: 10 }}>
                  You will not show up in each other´s search results.
                </BulletPoint>
                {/* COMPLETE BLOCKING ALTERNATIVE <Text style={{ fontSize: 16, marginBottom: 10 }}>
                  You will not be able to see each other´s profile any more
                </Text>
                <Text style={{ fontSize: 16, marginBottom: 10 }}>
                  You will not be able to chat or call each other any more
                </Text>
                <Text style={{ fontSize: 16, marginBottom: 10 }}>
                  You will not show up in each other´s search results any more
                </Text>
                <Text style={{ fontSize: 16, marginBottom: 10 }}>
                  You will not be able to find each other any more
                </Text> */}
                <View style={{ height: 40 }} />
                {!this.state.activityIndicatorAnimating ? (
                  <TouchableOpacity
                    style={[
                      global.gStyles.button,
                      { margin: 21, backgroundColor: "#400" }
                    ]}
                    onPress={async () => {
                      await this.callBlock("Block");
                      await new Promise(resolve => {
                        setTimeout(resolve, 80);
                      });
                      this.setState({ modalBlockVisible: false });
                    }}
                  >
                    <Text
                      style={[
                        global.gStyles.buttontext,
                        { color: "#ccc", fontSize: 18 }
                      ]}
                    >
                      Block this user
                    </Text>
                  </TouchableOpacity>
                ) : (
                  <ActivityIndicator
                    style={{ alignSelf: "center" }}
                    size="small"
                    color="#000"
                    animating={true}
                  />
                )}
                <View style={{ height: 25 }} />
                <TouchableHighlight
                  onPress={() => {
                    this.setState({ modalBlockVisible: false });
                  }}
                  style={{ alignSelf: "center" }}
                >
                  <Text style={{ color: "black", fontSize: 18 }}>Cancel</Text>
                </TouchableHighlight>
                <View style={{ height: 15 }} />
              </View>
            </View>
          </Modal>

          {/* Modal UN-BLOCK USER */}
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.modalUnblockVisible}
            onRequestClose={() => {
              //alert("Modal has been closed.");
            }}
          >
            <View
              style={{
                flex: 1,
                justifyContent: "center"
              }}
            >
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "flex-start",
                  backgroundColor: "#ccc",
                  margin: 10,
                  padding: 15,
                  borderRadius: 15
                }}
              >
                <Text
                  style={{
                    fontSize: 24,
                    marginBottom: 10,
                    alignSelf: "center"
                  }}
                >
                  Unblock this user?
                </Text>
                <View style={{ height: 40 }} />
                <BulletPoint style={{ fontSize: 18, marginBottom: 10 }}>
                  You will see messages by this user again.
                </BulletPoint>
                {/* <BulletPoint style={{ fontSize: 18, marginBottom: 10 }}>
                  You will not be able to call each other any more.
                </BulletPoint> */}
                <BulletPoint style={{ fontSize: 18, marginBottom: 10 }}>
                  You will show up in each other´s search results again.
                </BulletPoint>
                <View style={{ height: 40 }} />
                {!this.state.activityIndicatorAnimating ? (
                  <TouchableOpacity
                    style={[global.gStyles.button, { margin: 21 }]}
                    onPress={async () => {
                      this.callBlock("Unblock");
                      await new Promise(resolve => {
                        setTimeout(resolve, 80);
                      });
                      this.setState({ modalUnblockVisible: false });
                    }}
                  >
                    <Text style={[global.gStyles.buttontext, { fontSize: 18 }]}>
                      Unblock this user
                    </Text>
                  </TouchableOpacity>
                ) : (
                  <ActivityIndicator
                    style={{ alignSelf: "center" }}
                    size="small"
                    color="#000"
                    animating={true}
                  />
                )}
                <View style={{ height: 25 }} />
                <TouchableHighlight
                  onPress={() => {
                    this.setState({ modalUnblockVisible: false });
                  }}
                  style={{ alignSelf: "center" }}
                >
                  <Text style={{ color: "black", fontSize: 18 }}>Cancel</Text>
                </TouchableHighlight>
                <View style={{ height: 15 }} />
              </View>
            </View>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={this.state.modalAdminVisible}
            onRequestClose={() => {
              //alert("Modal has been closed.");
            }}
          >
            <DoBoost
              boostFactor={this.state.boostFactor}
              profileID={this.state.profileID}
              closeModal={() => { this.setState({ modalAdminVisible: false }) }}
              navigation={this.props.navigation}
            />
          </Modal>

          {/* Modal Show TrustScore Details */}
          <Modal
            animationType="fade"
            transparent={true}
            visible={this.state.modalTrustVisible}
            onRequestClose={() => {
              //
            }}
          >
            <TouchableWithoutFeedback
              onPress={() => { this.setState({ modalTrustVisible: false }); }}
            >
              <View
                style={{ flex: 1, justifyContent: "center" }}
              >
                <View
                  style={{
                    justifyContent: "center", alignItems: "flex-start", backgroundColor: "#333",
                    margin: 10, padding: 15, borderRadius: 15
                  }}
                >
                  <View
                    style={{ alignSelf: "center", justifyContent: "center", alignItems: "center", marginTop: 15 }}
                  >
                    <Text style={{ fontSize: 28, color: colF, fontWeight: "bold" }}>
                      Trust Score
                        </Text>
                    <Text style={{ fontSize: 36, fontWeight: "bold", color: colTrustScore, marginTop: -5 }}>
                      {this.state.trustScoreBadge}
                    </Text>
                  </View>
                  <View style={{ height: 40 }} />
                  {this.state.trustScoreDetails.map(item => {
                    let color = "#ccd", icon = " \u2022" // default esp. for yellow
                    if (item.color == "green") color = "#93CF93"
                    if (item.color == "orange") color = "#CF9393"
                    if (item.color == "red") color = "#CF9393"
                    if (item.color == "green") icon = "☆"
                    if (item.color == "orange") icon = " ↯"
                    if (item.color == "red") icon = " ↯"
                    return (<View style={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginRight: 15, marginBottom: 20 }} key={item.text}>
                      <Text style={{ width: 24, color, fontSize: 18 }}>{icon}</Text>
                      <Text style={{ fontSize: 18, color }}>{item.text}</Text>
                    </View>)
                  })}
                  <View style={{ height: 40 }} />
                  <TouchableOpacity
                    style={{
                      position: "absolute", alignItems: "center", justifyContent: "center",
                      width: 26, top: 8, left: 8, height: 26, backgroundColor: colF, borderRadius: 3
                    }}
                    onPress={() => { this.setState({ modalTrustVisible: false }) }}
                  >
                    <Image
                      style={{ width: 44, height: 44, opacity: 1, tintColor: "#333" }}
                      source={require("../../images/icons/CancelBlack.png")}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </TouchableWithoutFeedback>
          </Modal>
        </SafeAreaView >
      );
    }
  }
}

import React, { Component } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  Modal
} from 'react-native'

export default class BottomMenu extends Component {

  state = {
  }
  async componentDidMount() {
  }

  render() {
    // const { style } = this.props

    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={this.props.visible}
        onRequestClose={() => {
          this.props.onClose()
        }}
      >
        <TouchableOpacity
          onPress={() =>
            this.props.onClose()
          }
          style={{ flex: 1 }}
        />
        <View
          style={{
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <View
            style={{
              alignItems: "center", justifyContent: "center", backgroundColor: "#ccd", borderTopEndRadius: 15, borderTopLeftRadius: 15
            }}
          >
            <View style={{ paddingTop: 25 }}>
              <Text style={{ fontSize: 24, fontWeight: "bold", color: "#223" }}>{this.props.title || ""}</Text>
            </View>
            <View style={{ marginTop: 15, marginBottom: 5 }}>
            </View>
            {this.props.items && this.props.items.map((x, i) => { 
              return <TouchableOpacity key={x} onPress={() => { this.props.onSelectItem(x); this.props.onClose() }}>
                <View style={{
                  alignItems: "center", justifyContent: "center", height: this.props.items.length > 10 ? 30 : this.props.items.length > 4 ? 50 : 80,
                  flexDirection: "row", padding: 3,
                  borderRadius: 7.5, width: "100%", backgroundColor: "#ccd",
                  marginVertical: 5, borderTopWidth: 1, borderTopColor: "#aab"
                }}>
                  <View style={{ alignItems: "center", justifyContent: "center", flex: 1, paddingLeft: 10 }}>
                    <Text style={{ color: "#223", fontSize: 18, fontWeight: "bold" }}>{x}</Text>
                    {this.props.itemDescriptions && < Text style={{ color: "#778", fontSize: 14 }}>{this.props.itemDescriptions[i]}</Text>}
                  </View>
                  {/* <View style={{ alignItems: "center", justifyContent: "center", width: 50 }}>
                    <Text style={{ color: "#333", fontSize: 20, fontWeight: "bold", color: "#99b" }}></Text>
                  </View> */}
                </View>
              </TouchableOpacity>
            })}
            <View style={{ paddingTop: 25 }} />
          </View>
        </View>
      </Modal >
    );
  }
}



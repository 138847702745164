//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
// Screen always "synced" between Wakanda Market and Health
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
  Platform,
  Alert,
  Linking
} from "react-native";
const gStyles = global.gStyles //    "../styles/gStyles";
import firebase from "firebase";
import "firebase/firestore";
import { Audio } from 'expo-av';
import * as Permissions from 'expo-permissions'; //remove once new expo sdk
import * as FileSystem from 'expo-file-system';
import * as Haptics from 'expo-haptics';



export class RecordMessage extends Component {
  state = {
    isRecording: false,
    showRecordingIndicator: false,
    durationString: "0:00",
    audioJustRecorded: false,
    playingRecording: false,
    isUploading: false,
    processing: false,
  };

  async componentWillUnmount() {
    if (this.playback) this.stopPlayback(true)
    if (this.recording) {
      try {
        await this.recording?.stopAndUnloadAsync?.();
      } catch (e) {
        global.warn(e, "exxx2");
      }
    }
  }

  animateIsRecordingIndicator = async () => {
    try {
      while (this.state.isRecording) {
        await global.timeout(700)
        this.setState({ showRecordingIndicator: !this.state.showRecordingIndicator })
      }
    } catch (e) { global.warn?.(e, "animateIsRecordingIndicator") }
  }

  pressedRecord = async () => {
    if (this.state.isRecording) {
      console.log("isRecording and pressedRecord?")
      return;
    }
    console.log("Starting to record")
    try {
      if (this.recording) {
        console.log("A recording already exists. trying to unload it now.")
        await this.recording.unloadAsync?.()
      }
    } catch (error) { console.warn(error, "RM-pressedRecord1"); }
    try {
      console.log("Starting to record - 2")
      this.recording = null
      if (this.permissionGranted) return this.startRecording()
      //handle permissions
      const { status, canAskAgain } = await Permissions.getAsync(Permissions.AUDIO_RECORDING);
      if (status == "granted") {
        this.permissionGranted = true
        return this.startRecording()
      }
      Alert.alert("Allow Recording?", "To send a voice message, Wakanda needs to access your microphone",
        [{ text: "Cancel", onPress: () => { return } },
        { text: "Yes", onPress: () => { this.setRecordingPermissions(status, canAskAgain); } }],
        { cancelable: true }
      );
    } catch (error) { console.warn(error, "RM-pressedRecord"); }
  }

  setRecordingPermissions = async (status, canAskAgain) => {
    try {
      let request
      if (status == "denied" && !canAskAgain) {
        global.openPermissionSettings()
        return
      } else {
        request = await Permissions.askAsync(Permissions.AUDIO_RECORDING);
      }
      if (request.status != 'granted') {
        alert("Microphone Access not enabled")
        return;
      } else {
        this.permissionGranted = true
        return
      }
    } catch (error) { console.warn(error, "RM-setRecordingPermissions"); }
  }

  startRecording = async () => {
    try {
      const nowDate = new Date()
      this.dateRecordingStarted = nowDate
      this.animateIsRecordingIndicator()
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
        playsInSilentModeIOS: true,
        shouldDuckAndroid: true,
        interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
        playThroughEarpieceAndroid: false,
        // playThroughEarpieceAndroid: true,
        staysActiveInBackground: false,
      });
      const recording = new Audio.Recording();
      console.log("new Audio created")
      const recordingOptions = {
        // android not currently in use, but parameters are required
        android: {
          extension: '.m4a',
          outputFormat: Audio.RECORDING_OPTION_ANDROID_OUTPUT_FORMAT_MPEG_4,
          audioEncoder: Audio.RECORDING_OPTION_ANDROID_AUDIO_ENCODER_AAC,
          sampleRate: 5512,
          numberOfChannels: 2, // 1?
          bitRate: 128000,
        },
        ios: {
          extension: '.wav',
          audioQuality: Audio.RECORDING_OPTION_IOS_AUDIO_QUALITY_MIN,
          sampleRate: 5512,
          numberOfChannels: 1,
          bitRate: 128000,
          linearPCMBitDepth: 16,
          linearPCMIsBigEndian: false,
          linearPCMIsFloat: false,
        },
      };
      console.log("recordingOptions defined")
      // await recording.prepareToRecordAsync(Audio.RECORDING_OPTIONS_PRESET_HIGH_QUALITY);
      await recording?.prepareToRecordAsync?.(recordingOptions);
      console.log("Audio prepared")
      ///Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy)
      await recording?.startAsync();
      this.setState({ isRecording: true });
      console.log("Recording started")
      this.recording = recording;
    } catch (error) {
      console.warn(error, "RM-startRecording");
      //this.setState({ isRecording: false })
      await this.recording?.stopAndUnloadAsync?.();
    }
  }

  releasedRecording = async () => {
    if (this.state.processing) {
      console.log("Released while processing??")
      return
    }
    if (!this.permissionGranted) {
      console.log("No permission yet granted??")
      this.setState({ isRecording: false, isUploading: false, audioJustRecorded: false, processing: false })
      return
    }
    const now = new Date()
    try {
      if ((now - this.dateRecordingStarted) < 1500) {
        console.log("Released soon. Staying with recording")
        return
      }
      this.setState({ processing: true })
      if (!this.state.isRecording) {
        for (let i; i < 10; i++) {
          if (!this.state.isRecording) await global.timeout(1000)
        }
        console.log("stopped without starting to record - waiting")
        if (!this.state.isRecording) {
          console.log("Weird - stopped still without starting to record")
          this.setState({ isRecording: false, isUploading: false, audioJustRecorded: false, processing: false })
          return
        }
      }
      if (!this.recording) await global.timeout(1000)
      if (!this.recording) {
        this.setState({ isRecording: false, isUploading: false, audioJustRecorded: false, processing: false })
        return alert("Recording error")
      }
    } catch (e) {
      global.warn(e, "CD-releasedRecording1")
      this.setState({ isRecording: false, isUploading: false, audioJustRecorded: false, processing: false })
    }

    try {
      if (Platform.OS == "android") { //wait at least about 10 seconds before stopping to record // weird android behaviour
        if ((now - this.dateRecordingStarted) < 10000) {
          console.log("paused")
          await global.timeout(300)
          //if (Platform.Version >= 24) await this.recording?.pauseAsync?.()
        }
        if ((now - this.dateRecordingStarted) < 6000) {
          await global.timeout(400)
        }
        if ((now - this.dateRecordingStarted) < 3000) {
          await global.timeout(1500)
        }
      }
      await this.recording.stopAndUnloadAsync?.();
      const status = await this.recording.getStatusAsync()
      const ms = status.durationMillis || 0
      const min = (Math.floor(ms / 1000 / 60)).toString()
      let sec = (Math.floor((ms / 1000) % 60)).toString();
      if (sec < 10) sec = "0" + sec
      this.setState({ durationString: min + ":" + sec })

    } catch (e) {
      global.warn(e, "exxx4");
      this.setState({ isRecording: false, isUploading: false, audioJustRecorded: false, processing: false })
      this.recording?.unloadAsync?.()
      this.recording = null
    }

    try {
      const info = await FileSystem.getInfoAsync(this.recording.getURI());
      console.log(`FILE INFO: ${JSON.stringify(info)}`);
      global.lastRecordingURI = info.uri;
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: false,
        interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
        playsInSilentModeIOS: true,
        shouldDuckAndroid: true,
        interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
        playThroughEarpieceAndroid: false,
        staysActiveInBackground: false,
      });
      const { sound, status } = await this.recording.createNewLoadedSoundAsync()
      this.playback = sound
      this.setState({ isRecording: false, audioJustRecorded: true, processing: false })
    } catch (error) {
      global.warn(error, "RM-releasedRecording");
      this.setState({ isRecording: false, isUploading: false, audioJustRecorded: false, processing: false })
      // this.releasedRecording();
      // this.resetRecording();
      const info = await FileSystem.getInfoAsync(this.recording?.getURI?.())
      await FileSystem.deleteAsync(info?.uri)
      this.recording = null
    }
  }

  sendJustRecordedAudio = async () => {
    try {
      this.playback?.stopAsync?.()
    } catch (error) {
      global.warn(error, "CDM-releasedRecording1");
      this.setState({ isRecording: false, isUploading: false, processing: false })
    }
    if (!global.lastRecordingURI) {
      console.log("Cannot send, no uri existing")
      return
    }

    const uploadVoiceMessage = async () => {
      try {
        //this.setState({ processing: true })
        const uri = global.lastRecordingURI
        const blob = await new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function () {
            resolve(xhr.response);
          };
          xhr.onerror = function (e) {
            console.log(e);
            reject(new TypeError("Network request failed."));
          };
          xhr.responseType = "blob";
          xhr.open("GET", uri, true);
          xhr.send(null);
        });
        console.log("Uploading to Storage")

        const path = "VoiceMessages/" +
          Math.floor(Math.random() * 89999999999 + 10000000000)
          + uri.substring(uri.length - 7)
        console.log("Path: ", path)
        const ref = firebase
          .storage()
          .ref()
          .child(path);
        await ref.put(blob);
        const upURL = await ref.getDownloadURL();
        console.log("upURL", upURL)

        await FileSystem.deleteAsync(uri)
        global.lastRecordingURI = ""
        return upURL
      } catch (error) {
        global.warn(error, "CDM-uploadVoiceMessage");
        const info = await FileSystem.getInfoAsync(this.recording.getURI())
        await FileSystem.deleteAsync(info.uri)
        this.recording = null
        global.lastRecordingURI = ""
        return false
      }
    }
    this.props.onSend?.({
      text: "▶️  Sending Voice Message    ",
      voiceDuration: this.state.durationString,
      uploadFunction: uploadVoiceMessage,
    })
    this.setState({ isRecording: false, isUploading: false, audioJustRecorded: false, processing: false })
  }

  startPlayback = async (params) => {
    try {
      if (this.state.playingRecording) return this.stopPlayback()
      this.setState({ playingRecording: true })
      await this.playback.playAsync();
      await global.timeout(200)
      let isLooping = true
      while (isLooping) {
        //console.log("playing")
        const status = await this.playback.getStatusAsync()
        if (!status.isPlaying) isLooping = false
        await global.timeout(200)
      }
      await this.playback?.stopAsync?.();
      console.log("Done playing")
      this.setState({ playingRecording: false })
    } catch (e) {
      global.warn(e, "RM-startPlayback")
      this.stopPlayback()
    }
  }

  stopPlayback = async (alsoUnload) => {
    try {
      console.log("stopping playback")
      await this.playback?.stopAsync?.();
      this.setState({ playingRecording: false })
      if (alsoUnload) this.voice?.unloadAsync?.();
    } catch (e) {
      global.warn(e, "RM-stopPlayback")
    }
  }

  deletePlayback = async (params) => {
    try {
      if (this.state.playingRecording) this.stopPlayback()
      const info = await FileSystem.getInfoAsync(this.recording?.getURI?.())
      await FileSystem.deleteAsync(info?.uri)
      this.setState({ audioJustRecorded: false })
      this.recording = null
    } catch (e) {
      global.warn(e, "RM-deletePlayback")
    }
  }

  passiveStyle = {
    position: "absolute", right: 0, top: 0,
    flexDirection: "row", padding: 1, height: 52, width: 52, marginBottom: 1, marginRight: 1,
    alignItems: "center", justifyContent: "flex-end"
  }
  activeStyle = {
    ...this.passiveStyle, width: this.props.type == "chatGroup" ? 305 : 350,
    borderColor: "#fff", borderWidth: 1, height: 50, borderRadius: 25,
    backgroundColor: "#000", justifyContent: "space-between"
  }

  render() {
    if (this.state.processing) return (<View style={this.activeStyle}>
      <View />
      <ActivityIndicator size="small" color="#889" />
      <View />
    </View>)

    return (<View style={(!this.state.audioJustRecorded && !this.state.isRecording) ? this.passiveStyle : this.activeStyle}>

      {!this.state.audioJustRecorded && this.state.isRecording && <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Text style={{ color: "#fff", fontWeight: "bold", marginLeft: 15 }}>🎤 Recording Message</Text>
        {this.state.showRecordingIndicator && <Text style={{ fontSize: 8, marginTop: 3 }}>      🔴</Text>}
      </View>}

      {!this.state.audioJustRecorded &&
        <TouchableOpacity
          activeOpacity={1}
          style={{
            alignItems: "center",
            padding: 1,
            paddingHorizontal: 10,
            height: 50,
            justifyContent: "center",
            width: this.state.isRecording ? 95 : 50,
            height: this.state.isRecording ? 50 : 50,
          }}
          onPress={() => {
            console.log("onPress")
          }}
          onPressIn={() => {
            console.log("onPressIn")
            this.pressedRecord();
          }}
          onPressOut={() => {
            console.log("onPressOut")
            this.releasedRecording()
          }}
        >
          {!this.state.isRecording ? <Image
            style={{
              width: 24,
              height: 24,
              tintColor: this.props.type == "chatGroup" ? "#fff" : "#026550",
              marginRight: 5,
              marginBottom: this.props.type == "chatGroup" ? 5 : 0
            }}
            source={require("../images/icons/Microphone.png")}
          /> : <View
            style={[global.gStyles.buttonX, { margin: 3, minWidth: 75, height: 28, borderRadius: 14, marginRight: 15 }]}>
              <Text style={[global.gStyles.buttontX, { fontSize: 12, fontWeight: "bold" }]}>■  Stop</Text>
            </View>}
        </TouchableOpacity>}

      {this.state.audioJustRecorded &&
        [<TouchableOpacity key={"1"} onPress={() => {
          this.deletePlayback()
        }}>
          <Image
            style={{ width: 35, height: 35, margin: 10 }}
            source={require("../images/icons/CancelWhite.png")}
          />
        </TouchableOpacity>,
        <View key={"2"} style={{ flexDirection: "row", alignItems: "center" }}>
          <TouchableOpacity
            onPress={() => {
              !this.state.playingRecording ? this.startPlayback() :
                this.stopPlayback()
            }}
            style={{
              height: 31, width: 31, borderRadius: 16, borderColor: "#fff", borderWidth: 0,
              alignItems: "center", justifyContent: "center", paddingLeft: 2
            }}>
            {!this.state.playingRecording ? <Text style={{ color: "#fff", fontSize: 22 }}>{"▶"}</Text>
              : <Text style={{ color: "#fff", fontSize: 15, fontWeight: "bold", marginRight: 1 }}>{"| |"}</Text>}
          </TouchableOpacity>
          <View style={{ marginLeft: 4 }}>
            <Text style={{ fontSize: 12, color: "#fff" }}>Voice Message</Text>
            <Text style={{ fontSize: 12, color: "#fff" }}>({this.state.durationString})</Text>
          </View>
        </View>,
        <TouchableOpacity key={"3"}
          onPress={() => { this.sendJustRecordedAudio() }}
          style={{
            alignItems: "center",
            alignSelf: "center",
            padding: 1,
            backgroundColor: "#A8CF45",
            paddingHorizontal: 6,
            height: 49,
            minWidth: 50,
            justifyContent: "center",
            borderTopEndRadius: 25,
            borderBottomEndRadius: 25,
            marginRight: -1
          }}
        >
          <Image
            style={{
              width: 18,
              height: 18,
              marginRight: 1
            }}
            source={require("../images/icons/Send.png")}
          />
        </TouchableOpacity>]}
    </View>)
  }
}


export class VoiceMessage extends Component {

  state = {
    loadingVoice: false,
    voiceLoaded: false,
    userPlaysVoice: false,
  }

  componentDidMount() {
    if (this.props.voiceURL && this.props.playOnStart) {
      this.loadVoice()
    }
  }

  async componentWillUnmount() {
    try {
      if (this.voice) {
        if (this.state.userPlaysVoice) {
          await this.stopVoice()
        }
        await this.voice.stopAndUnloadAsync?.()
      }
    } catch (e) {
      global.warn(e, "msg-cwUnmount")
    }
  }

  stopVoice = async () => {
    console.log("stopVoice called")
    try {
      await this.voice?.stopAsync?.();
      this.setState({ userPlaysVoice: false })
    } catch (e) {
      global.warn(e, "msg-stopVoice")
    }
  }

  loadVoice = async () => {
    try {
      console.log("loadVoice called")
      if (this.state.loadingVoice) {
        console.log("Already loading voice")
        return
      }
      let needsToLoad = !this.state.voiceLoaded
      if (needsToLoad) {
        this.setState({ loadingVoice: true })
        console.log("creating voice")
        const { sound } = await Audio.Sound.createAsync(
          { uri: this.props.voiceURL }
        );
        this.voice = sound
        this.setState({ loadingVoice: false, voiceLoaded: true })
        console.log("done creating voice")
      }
      if (this.state.userPlaysVoice) {
        console.log("playing voice")
        await this.voice.playAsync();
        if (needsToLoad) {
          for (let i = 0; i += 200; i < 3500) {
            await global.timeout(200)
            const status = await this.voice.getStatusAsync()
            if (status.isPlaying) i = 99999
          }
        }
        let isLooping = true
        while (isLooping) {
          console.log("playing ***")
          await global.timeout(200)
          const status = await this.voice.getStatusAsync()
          if (!status.isPlaying) isLooping = false
        }
        console.log("Done playing")
        this.stopVoice()
      }
    } catch (e) { global.warn(e, "msg-loadVoice") }
  }

  render() {
    return (
      <View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TouchableOpacity
            onPress={async () => {
              await this.setState({ userPlaysVoice: !this.state.userPlaysVoice })
              if (this.state.userPlaysVoice) this.loadVoice();
              else this.stopVoice()
            }}
            style={{
              height: 31, width: 31, borderRadius: 16, borderColor: "#000", borderWidth: 0,
              alignItems: "center", justifyContent: "center", paddingLeft: 2
            }}>
            {!this.state.userPlaysVoice ? <Text style={{ color: this.props.type === "chatGroup" ? "#fff" : "#000", fontSize: 22 }}>{"▶"}</Text>
              : this.state.loadingVoice ? <ActivityIndicator size="small" color={this.props.type === "chatGroup" ? "#fff" : "#000"} animating />
                : <Text style={{ color: this.props.type === "chatGroup" ? "#fff" : "#000", fontSize: 15, fontWeight: "bold", marginRight: 1 }}>{"| |"}</Text>}
          </TouchableOpacity>
          <View style={{ marginLeft: 4 }}>
            <Text style={{ fontSize: 12, color: this.props.type === "chatGroup" ? "#fff" : "#000" }}>Voice Message</Text>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text style={{ fontSize: 12, color: this.props.type === "chatGroup" ? "#fff" : "#000" }}>{this.state.loadingVoice && "Loading "}({this.props.voiceDuration})</Text>
            </View>
          </View>
        </View>
      </View>
    )
  }
}
import React, { Component } from 'react'
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  StyleSheet
} from "react-native";
import * as Permissions from 'expo-permissions';




// Popup that appears at the bottom of the screen showing which location is used (with buttons for hide, change, try again)
// Typically popping up in case location could not be retrieved
//
//props: msDelay (number) miliseconds of delay before popup should potentially start
export default class CurrentLocationModal extends Component {

  state = {
  }
  async componentDidMount() {
    try {
      if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 5000); });
      if (!global.myUID) return;

      if (this.props.setLiveLocation) this.check()

    } catch (e) {
      console.warn('currentLocationV2 error');
      console.log('currentLocationV2 error:', e);
    }
  }

  check = async () => {
    try {
      const { status } = await Permissions.getAsync(Permissions.LOCATION)
      if (status !== "granted") {
        if (!global.location) {
          global.location = { coords: { latitude: 6.6, longitude: 3.3 } }
          global.myLocation = global.location
        }
        // const address = await global.getAddress()
        // this.setState({ address })
        return;
      } else {
        const locationFound = await global.setLocationFast(5)
        if (!locationFound) {
          // const address = await global.getAddress()
          // this.setState({ address })
        }
      }
    } catch (e) {
      console.warn('LocationCheck2 error');
      console.log('LocationCheck2 error:', e);
    }
  }

  render() {
    const marginBottom = 0 //+ (Dimensions.get("window").height >= 800 ? 78 : 48)
    const height = 80
    return (
      <View style={styles.container}>
        <TouchableOpacity style={{ flex: 1, alignSelf: 'center' }}
          onPress={() => { this.props.onPressEdit() }}>
          <View style={{
            flex: 1, justifyContent: 'center', alignSelf: 'center',
            paddingVertical: this.props.padding ? this.props.padding : 5, paddingHorizontal: 5
          }}>

            <Text numberOfLines={1} style={{
              color: this.props.textColor ? this.props.textColor : "black",
              textAlign: 'center', fontSize: this.props.textSize ? this.props.textSize : 12,
              fontWeight: this.props.fontWeight ? this.props.fontWeight : "normal"
            }}>
              {this.props.location.address || "Unknown Location"}
            </Text>

          </View>
        </TouchableOpacity>
        <View style={{ width: 30, paddingVertical: this.props.padding ? this.props.padding : 5, paddingHorizontal: 5 }}>
          <TouchableOpacity
            onPress={() => { this.props.onPressEdit() }}
          >
            <Image
              style={{
                width: 20, height: 20, alignSelf: 'center',
                ...(global.app == "speakers" && { tintColor: "#bbc" }),
                ...(this.props.iconColor && { tintColor: this.props.iconColor })
              }}
              source={require('../../images/icons/Change446.png')}
            />
          </TouchableOpacity>
        </View>
      </View>
    )
  }
}

let styles = {

  container: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'stretch',
    paddingHorizontal: 10,
    flexDirection: 'row'


  },
};

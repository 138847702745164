import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  SafeAreaView,
  Image,
  StatusBar,
  TouchableOpacity,
  ScrollView,
  Platform,
  Linking
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";

class TitleButton extends PureComponent {
  render() {
    return (
      <TouchableOpacity style={{ paddingHorizontal: 10, paddingVertical: 6, marginTop: 12, alignSelf: "flex-start", borderRadius: 8, borderWidth: 0.5, borderColor: "#A8CF45" }}
        onPress={() => Linking.openURL("https://www.african-founders.com/single-post/2019/06/19/Introducing-ACoin")}
      >
        <Text style={[this.props.style, { color: "#fff", alignSelf: "center", fontWeight: "bold", }]}>
          {this.props.children}
        </Text>
      </TouchableOpacity>
    );
  }
}
class Title extends PureComponent {
  render() {
    return (
      <Text style={[this.props.style, { color: "#fff", marginVertical: 6, marginTop: 15 }]}>
        {this.props.children}
      </Text>
    );
  }
}
class BulletPoint extends PureComponent {
  render() {
    return (
      <View style={{ flexDirection: "row", width: "90%", justifyContent: "flex-start" }}>
        <Text style={[this.props.style, { marginRight: 6, color: "#A8CF45", }]}>{`\u2022`}</Text>
        <Text style={[this.props.style, { color: "white", fontSize: 12, flexWrap: "wrap" }]}>{this.props.children}</Text>
      </View>
    );
  }
}
class BulletPoint2 extends PureComponent {
  render() {
    return (
      <View style={{ flexDirection: "row", width: "90%", justifyContent: "flex-start" }}>
        <Text style={[this.props.style, { color: "white", fontSize: 12, flexWrap: "wrap" }]}>{this.props.children}</Text>
      </View>
    );
  }
}

export default class WalletAboutScreen extends Component {
  static navigationOptions = {
    title: "",
    headerTransparent: true,
    headerTintColor: "#A8CF45",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };

  state = {

  }

  constructor() {
    super();
    this.unsubscribeFirestore = () => { return; };
  }


  render() {
    const xHeight = 60;
    if (this.state.screen != "main" && Platform.OS == "android") return (this.renderDetailsAndroid(this.state.screen))
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#131313" }}>
        <StatusBar barStyle="light-content" />
        <ScrollView>
          <View style={{ flex: 1, backgroundColor: "#131313", }}>
        <View style={{ position: "absolute", top: 0, width: "100%", left: 0, height: 400 }}>
          <Image style={{ width: "100%", height: 300, opacity: 0.2, }}
            source={require("../../images/logos/home_logo.png")}
          /></View>
            <View style={{ flexDirection: "row", marginTop: 52, alignItems: "center", marginLeft: 8 }}>
              <Text style={{ color: "white", fontSize: 22, fontWeight: "bold" }}>Wakanda </Text>
              <Text style={{ color: "white", fontSize: 16 }}>is built on</Text>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center", marginLeft: 8 }}>
              <Text style={{ color: "white", fontSize: 26, fontWeight: "bold" }}>ACoin</Text>
              <Text style={{ color: "white", fontSize: 18, }}>, a cryptocurrency for Africa</Text>
            </View>
            <View style={{ marginLeft: 30, marginTop: 20, alignSelf: "stretch", }}>
              <Title>ACoin (₳) is a purpose-built, asset-backed cryptocurrency based on the Stellar Protocol. It combines the simplicity of a mobile app with the potential of a modern cryptocurrency.</Title>
              <Title>ACoin is</Title>
              <BulletPoint>simple: You use it right inside Wakanda</BulletPoint>
              <BulletPoint>efficient: Transactions are fast and free of charge</BulletPoint>
              <BulletPoint>secure: It uses blockchain in combination with a central backend</BulletPoint>
              <BulletPoint>easy to get: Contact any of the listed traders to get ACoin into your wallet</BulletPoint>
              <BulletPoint>African: The value of ACoin is mainly based on the currencies with which wallets are funded and Stellar Lumens.</BulletPoint>
              <Title>Money in your Wakanda wallet is stored in ACoin. Offers in local currency automatically convert at the exchange rate at order time.</Title>
              <TitleButton>Find out more!</TitleButton>
              <Title>Current Exchange rate:</Title>
              <BulletPoint2>1 {global.myLocalCurrency} = {Math.round(10000/global.xRates[global.myLocalCurrency])/10000} ACN</BulletPoint2>
              <BulletPoint2>1 ACN = {Math.round(10000*global.xRates[global.myLocalCurrency])/10000} {global.myLocalCurrency}</BulletPoint2>
              <View style={{ height: 20 }} />
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

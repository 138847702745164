import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  ScrollView,
  SectionList,
  SafeAreaView,
  Keyboard,
  ActivityIndicator,
  Dimensions,
  Platform,
  Image,
  Alert,
  Modal
} from "react-native";
//import firebase from "../../../firebase/afbase-config";
import firebase from "firebase";
import "firebase/firestore";

// import CurrentLocation from "../../shared/Map/currentLocation";
import CurrentLocation from "../../shared/Map/currentLocationV2";
import CurrentLocationModal from "../../shared/Map/currentLocationV2Modal";
import GetPicture from "../../shared/Camera/GetPicture";
import PushNotificationHandler from "../../Messages/PushNotificationHandler"
const gStyles = global.gStyles //    "../../styles/gStyles";

class CheckboxSelector extends PureComponent {
  render() {
    let height =
      this.props.text.length < 45
        ? 30
        : 45 + (this.props.text.length - 45) * 0.33;
    let circle = filled => {
      if (filled) {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#9999a4", backgroundColor: "#334", borderWidth: 0, borderRadius: 10 }}>
            <Text style={{ fontSize: 24, marginTop: -5, color: this.props.color || "#ccd" }}>✓</Text>
          </View>
        );
      } else {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#ccd", borderWidth: 0.7, borderRadius: 10 }} />
        );
      }
    };

    return (
      <View style={{ height: height, flexDirection: "row", alignItems: "center" }}>
        {circle(this.props.selected)}
        <Text style={{ marginLeft: 10, color: this.props.color || "#ccd", fontWeight: this.props.selected ? "normal" : "normal" }}>{this.props.text}</Text>
      </View>
    );
  }
}

class MyServicesEditor extends Component {
  state = {
    category1: null,
    category2: null,
    selectorVisible: false,
    modalVisible: false,
  };

  categories = global.categories

  images = (global.app == "market") ? {
    Electronics: require("../../images/categories/Electronics.png"),
    Home: require("../../images/categories/Home.png"),
    Fashion: require("../../images/categories/Fashion.png"),
    Cars: require("../../images/categories/Cars.png"),
    Food: require("../../images/categories/Food.png"),
    Beauty: require("../../images/categories/Beauty.png"),
    Professionals: require("../../images/categories/Professionals.png"),
    Repair_Refill: require("../../images/categories/Repair_Refill.png"),
    Handyman: require("../../images/categories/Handyman.png"),
    QuickJobs: require("../../images/categories/QuickJobs.png"),
    Health: require("../../images/categories/Health.png"),
    Art: require("../../images/categories/Art.png"),
    Housing: require("../../images/categories/Housing.png"),
    Travel: require("../../images/categories/Travel.png"),
    Commercial: require("../../images/categories/Commercial.png")
  } : (global.app == "artisan") ? {
    AC: require("../../custom/Artisan/images/services/AC.png"),
    Appliances: require("../../custom/Artisan/images/services/Appliances.png"),
    Curtains: require("../../custom/Artisan/images/services/Curtains.png"),
    Doors: require("../../custom/Artisan/images/services/Doors.png"),
    Electrical: require("../../custom/Artisan/images/services/Electrical.png"),
    Flooring: require("../../custom/Artisan/images/services/Flooring.png"),
    Fumigation: require("../../custom/Artisan/images/services/Fumigation.png"),
    Furniture: require("../../custom/Artisan/images/services/Furniture.png"),
    Gardening: require("../../custom/Artisan/images/services/Gardening.png"),
    Generator: require("../../custom/Artisan/images/services/Generator.png"),
    Other: require("../../custom/Artisan/images/services/Other.png"),
    Painting: require("../../custom/Artisan/images/services/Painting.png"),
    Plumbing: require("../../custom/Artisan/images/services/Plumbing.png"),
    Walls: require("../../custom/Artisan/images/services/Walls.png"),
  } : (global.app == "education") ? {
    Primary: require("../../custom/Education/images/services/Primary.png"),
    Secondary: require("../../custom/Education/images/services/Secondary.png"),
    Tertiary: require("../../custom/Education/images/services/Tertiary.png"),
  } : {};

  artisanCategoryMap = {
    AC: "AC",
    Electrical: "Electrician",
    Plumbing: "Plumbing",
    Painting: "Painting",
    Generator: "Generator",
    Appliances: "Appliances",
    Fumigation: "Fumigation",
    Curtains: "Curtains, Blinds",
    Doors: "Doors, Knobs, Locks",
    Flooring: "Flooring",
    Walls: "Walls & Ceiling",
    Furniture: "Furniture Assembly",
    Gardening: "Gardening",
    Other: "General Work",
  };
  artisanCategories = [
    "AC",
    "Electrical",
    "Plumbing",
    "Painting",
    "Generator",
    "Appliances",
    "Fumigation",
    "Curtains",
    "Doors",
    "Flooring",
    "Walls",
    "Furniture",
    "Gardening",
    "Other",
  ];
  educationCategoryMap = {
    Primary: "Teaching - Primary",
    Secondary: "Teaching - Secondary",
    Tertiary: "Teaching - Tertiary",
  };
  educationCategories = [
    "Primary",
    "Secondary",
    "Tertiary",
  ];
  reverseCategoryMap = {}

  componentDidMount() {
    //reverse map
    if (global.app == "artisan") {
      const obj = this.artisanCategoryMap
      var new_obj = {};
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
          new_obj[obj[prop]] = prop;
      }
      this.reverseCategoryMap = new_obj;
    }
    if (global.app == "education") {
      const obj = this.educationCategoryMap
      var new_obj = {};
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
          new_obj[obj[prop]] = prop;
      }
      this.reverseCategoryMap = new_obj;
    }
  }

  render() {
    const iWidth = Dimensions.get("window").width / 7.5;
    const Nope = (
      <View
        key={"myNope"}
        style={{ height: 50, justifyContent: "center", alignItems: "center" }}
      >
        <Text style={{ color: "#f00", fontSize: 18 }}>
          Currently not offering any services!
        </Text>
        <Text style={{ color: "#f00" }}>
          To be able to receive requests, add a service.
        </Text>
      </View>
    );

    renderEducationCategories = () => {

      let array = this.educationCategories.map(key => {
        return (
          <View
            key={key}
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <TouchableOpacity
              style={{ justifyContent: "center", alignItems: "center" }}
              // onPress={() => {
              //   this.setState({ category1: key, modalVisible: true });
              // }}
              onPress={() => {
                const item = this.educationCategoryMap[key]
                let newObject = {};
                if (this.props.services) {
                  newObject = this.props.services;
                }
                if (newObject["Professionals"]) {
                  if (!newObject["Professionals"].includes(item))
                    newObject["Professionals"].push(item);
                } else {
                  newObject["Professionals"] = [item];
                }
                this.setState({
                  selectorVisible: false
                });

                this.props.onUpdate(newObject);
              }}
            >
              <Image
                style={{
                  width: iWidth * 1.5,
                  height: iWidth * 1.5,
                  margin: 2,
                  borderRadius: (iWidth * 1.5) / 3
                }}
                source={this.images[key]}
              />
              <Text style={{ color: "#ccc" }}>{key}</Text>
            </TouchableOpacity>
          </View>
        );
      });
      return array;
    };

    renderArtisanCategories = () => {

      let array = this.artisanCategories.map(key => {
        return (
          <View
            key={key}
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <TouchableOpacity
              style={{ justifyContent: "center", alignItems: "center" }}
              // onPress={() => {
              //   this.setState({ category1: key, modalVisible: true });
              // }}
              onPress={() => {
                const item = this.artisanCategoryMap[key]
                let newObject = {};
                if (this.props.services) {
                  newObject = this.props.services;
                }
                if (newObject["Handyman"]) {
                  if (!newObject["Handyman"].includes(item))
                    newObject["Handyman"].push(item);
                } else {
                  newObject["Handyman"] = [item];
                }
                this.setState({
                  selectorVisible: false
                });
                // console.log('newObject')
                // console.log(newObject)
                this.props.onUpdate(newObject);
              }}
            >
              <Image
                style={{
                  width: iWidth * 1.5,
                  height: iWidth * 1.5,
                  margin: 2,
                  borderRadius: (iWidth * 1.5) / 3
                }}
                source={this.images[key]}
              />
              <Text style={{ color: "#ccc" }}>{key}</Text>
            </TouchableOpacity>
          </View>
        );
      });
      return array;
    };

    renderCategories = () => {
      let keys = Object.keys(this.categories);

      let array = keys.map(key => {
        return (
          <View
            key={key}
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <TouchableOpacity
              style={{ justifyContent: "center", alignItems: "center" }}
              onPress={() => {
                this.setState({ category1: key, modalVisible: true });
              }}
            >
              <Image
                style={{
                  width: iWidth * 1.5,
                  height: iWidth * 1.5,
                  margin: 2,
                  borderRadius: (iWidth * 1.5) / 3
                }}
                source={this.images[key]}
              />
              <Text style={{ color: "#ccc" }}>{key}</Text>
            </TouchableOpacity>
          </View>
        );
      });
      return array;
    };

    const AddService = () => {
      if (this.state.selectorVisible) {
        return AddSelector;
      } else {
        return AddButton;
      }
    };

    const AddButton = (
      <View key={"myAddButton"} style={{ flex: 1, alignItems: "center" }}>
        <TouchableOpacity
          onPress={() => {
            this.setState({ selectorVisible: true });
          }}
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 18,
            borderWidth: 0.5,
            borderColor: "#ccccd5",
            height: 35,
            width: 160,
            margin: 15,
            backgroundColor: "#000"
          }}
        >
          <Text style={{ color: "#ccccd5", fontSize: 14 }}>ADD A SERVICE</Text>
        </TouchableOpacity>
      </View>
    );

    const AddSelector = (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#000",
          alignItems: "stretch"
        }}
        key={"myAddSelector"}
      >
        <View
          style={{
            marginTop: 20,
            marginBottom: 5
          }}
        >
          <Text style={{ color: "#ccc" }}>
            What service do you want to add?
          </Text>
        </View>
        {/* <Text style={{ color: "white", marginTop:0 }}> What are you looking for?</Text> */}
        <View style={{ flex: 200 }}>
          <ScrollView horizontal style={{ flex: 1 }}>
            {(global.app == "artisan") ? renderArtisanCategories() : (global.app == "education") ? renderEducationCategories() : renderCategories()}
          </ScrollView>
        </View>

        <Modal
          animationType="slide"
          transparent={false}
          visible={this.state.modalVisible}
          onRequestClose={() => {
            //handled by 'X'
          }}
        >
          <View style={{ height: 35, backgroundColor: "#ccc" }} />
          <TouchableOpacity
            onPress={() => {
              this.setState({ modalVisible: false });
            }}
            style={{ backgroundColor: "#ccc" }}
          >
            <Text style={{ marginLeft: 20, color: "#555", fontSize: 44 }}>
              X
            </Text>
          </TouchableOpacity>
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ccc"
            }}
          >
            <View style={{ marginBottom: 15, marginTop: 0 }}>
              <Text style={{ fontSize: 30 }}>Select a category</Text>
            </View>
            <View style={{ marginTop: 35, marginBottom: 5 }}>
              <Image
                style={{ width: 100, height: 100, borderRadius: 33 }}
                source={this.images[this.state.category1]}
              />
            </View>
            <View style={{ marginBottom: 30 }}>
              <Text style={{ fontSize: 24 }}>{this.state.category1}</Text>
            </View>
            {this.state.category1 == "Health" && (
              <View style={{ marginBottom: 20, marginTop: -20 }}>
                <Text style={{ fontSize: 16, color: "darkred" }}>
                  These services will require verification.
                </Text>
              </View>
            )}
            <SectionList
              style={{ flex: 1 }}
              renderItem={({ item, index, section }) => (
                <TouchableOpacity
                  onPress={() => {
                    let newObject = {};
                    if (this.props.services) {
                      newObject = this.props.services;
                    }
                    if (newObject[this.state.category1]) {
                      if (!newObject[this.state.category1].includes(item))
                        newObject[this.state.category1].push(item);
                    } else {
                      newObject[this.state.category1] = [item];
                    }

                    this.setState({
                      modalVisible: false,
                      selectorVisible: false
                    });

                    this.props.onUpdate(newObject);
                  }}
                  style={{
                    flex: 1,
                    paddingTop: 10,
                    paddingBottom: 10,
                    alignItems: "center",
                    width: Dimensions.get("window").width,
                    backgroundColor: "white",
                    borderWidth: 1,
                    borderColor: "#ccc"
                  }}
                >
                  <Text style={{ fontSize: 18 }} key={index}>
                    {item}
                  </Text>
                </TouchableOpacity>
              )}
              renderSectionHeader={({ section: { title } }) => (
                <Text style={{ fontWeight: "bold" }}>{title}</Text>
              )}
              sections={[{ data: this.categories[this.state.category1] }]}
              keyExtractor={(item, index) => item + index}
            />
          </View>
        </Modal>
      </View>
    );

    if (!this.props.services) {
      return [Nope, AddService()];
    }

    let keys = Object.keys(this.props.services);

    if (keys.length == 0) {
      return [Nope, AddService()];
    }


    let array = [];
    keys.forEach(cat1 => {
      this.props.services[cat1].forEach(cat2 => {
        if (global.app != "education" || global.skillKeys.includes(cat2.replace(/[^a-zA-Z]/g, "")))
          array.push(
            <View
              key={cat2}
              style={{
                flexDirection: "row",
                flex: 1,
                alignItems: "center",
                width: Dimensions.get("window").width,
                marginVertical: 2,
              }}
            >
              <View style={{ width: iWidth + 15, alignItems: "flex-start" }}>
                <Image
                  style={{
                    width: iWidth,
                    height: iWidth,
                    margin: 2,
                    borderRadius: iWidth / 3
                  }}
                  source={this.images[(global.app == "artisan" || global.app == "education") ? this.reverseCategoryMap[cat2] : cat1]}
                />
              </View>
              <View style={{ flex: 80 }}>
                <Text style={{ color: "white", fontSize: 20 }}>{(global.app != "artisan" && global.app != "education") && cat1}</Text>
                <Text style={{ color: "white", fontSize: 12 }}>{cat2}</Text>
                {cat1 == "Health" && (
                  <Text style={{ color: "red", fontSize: 12 }}>
                    This service requires verification.
                  </Text>
                )}
              </View>
              <TouchableOpacity
                onPress={() => {
                  let reducedArray = this.props.services[cat1].filter(item => {
                    return item !== cat2;
                  });
                  let returnObject = this.props.services;
                  returnObject[cat1] = reducedArray;

                  this.props.onUpdate(returnObject);
                }}
                style={{
                  flex: 11,
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 18,
                  borderWidth: 0.0,
                  borderColor: "#ccccd5",
                  height: 35,
                  marginRight: 9,

                }}
              >
                {/* <Text
                style={{ color: "#ccccd5", fontSize: 30 }}
              >
                ×
              </Text> */}
                <Image
                  style={{ width: 35, height: 35, marginRight: 2, backgroundColor: "#ccccd5", borderRadius: 30 }} //try borderRadius:30
                  source={require("../../images/icons/CancelBlack.png")}
                />
              </TouchableOpacity>
            </View>
          );
      });
    });

    if (array.length == 0) {
      return [Nope, AddService()];
    }
    return [array, AddService()];
  }
}

export default class ProfileUpdateScreen extends Component { //class MeProfileUpdateScreen
  static navigationOptions = {
    title: "",
    headerTransparent: true,
    headerTintColor: "#A8CF45",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };
  state = {
    name: "",
    businessName: "",
    //jobsDoneCount: 0,
    //aboutMe: "Welcome",
    description: "",
    isActiveTrader: false,
    traderDescription: "",
    picture: null,
    backgroundPicture: null,
    servicesOffered: null,
    jobQualifications: null,
    jobQualificationsStatus: null,
    viewJobQualifications: false,
    editJobQualifications: false,
    socials: null,
    viewSocials: false,
    location: "LOADING",
    keyboardSpacerHeight: 56,
    activityIndicatorAnimating: false,
    modalActivityIndicatorAnimating: false,
    isBusiness: false,
    isNewBusiness: false,
    showGetPicture: false,
    showGetBackgroundPicture: false,
    modalVisible: false,

    currencies: [],
    modalCurrencyVisible: false,
    showLocationModal: false
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    const stateChange = {}
    global.skillKeys.forEach(skillKey => {
      stateChange["skills" + skillKey] = global.skills[skillKey]
      stateChange["skillsSelected" + skillKey] = []
      stateChange["skillsMinFee" + skillKey] = "0"
      stateChange["skillsMinFeeCurrency" + skillKey] = global.myLocalCurrency || "NGN"
    })
    this.setState(stateChange)

    if (!global.myUID) await new Promise(resolve => { setTimeout(resolve, 200); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 500); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 1000); });
    if (!global.myUID || !global.myProfileID) await new Promise(resolve => { setTimeout(resolve, 3000); });
    if (!global.myUID || !global.myProfileID) return alert("No connection")
    global.fetchIdToken()

    //check if profileID has been given, else show own profile
    //1. Load profile info once
    let profileSnapshot = null;
    try {
      profileSnapshot = await firebase
        .firestore()
        .collection("Profiles")
        .doc(global.myProfileID)
        .get();
      const profile = await profileSnapshot.data();

      let objectOfChange = { name: profile.profileName };
      if (profile.profileNameBusiness) {
        objectOfChange["businessName"] = profile.profileNameBusiness;
        this.setState({ isBusiness: true });
      }
      if (profile.profilePictureURL) {
        objectOfChange["picture"] = profile.profilePictureURL;
      }
      if (profile.profilePictureBackgroundURL) {
        objectOfChange["backgroundPicture"] =
          profile.profilePictureBackgroundURL;
      }
      if (profile.profileDescription) {
        objectOfChange["description"] = profile.profileDescription;
      }
      if (profile.profileTraderDescription && profile.isActiveTrader) {
        objectOfChange["traderDescription"] = profile.profileTraderDescription;
      }
      if (profile.profileServicesOffered) {
        objectOfChange["servicesOffered"] = profile.profileServicesOffered;
      }
      if (profile.jobQualifications) {
        objectOfChange["jobQualifications"] = profile.jobQualifications;
      }
      if (profile.jobQualificationsStatus) {
        objectOfChange["jobQualificationsStatus"] = profile.jobQualificationsStatus;
      }
      if (profile.profileSocials) {
        objectOfChange["socials"] = profile.profileSocials;
      }
      if (profile.profileWebsiteURL) {
        objectOfChange["websiteURL"] = profile.profileWebsiteURL;
      }
      if (
        profile.profileWebsiteURL && profile.profileWebsiteExpirationDate &&
        (profile.profileWebsiteExpirationDate.toDate() < new Date())) {
        objectOfChange["websiteHasExpired"] = true;
      }
      if (global.app == "speakers") {
        let isNewBusiness = await this.props.navigation.getParam("isNewBusiness", null);
        if (isNewBusiness || profile.profileNameBusiness) {
          if (!objectOfChange.servicesOffered) objectOfChange.servicesOffered = {}
          if (!objectOfChange.servicesOffered.Professionals) objectOfChange.servicesOffered.Professionals = []
          if (!objectOfChange.servicesOffered.Professionals.includes("Public Speakers")) objectOfChange.servicesOffered.Professionals.push("Public Speakers")
        }
      }
      global.skillKeys.forEach(skillKey => {
        if (profile["skills" + skillKey]) {
          objectOfChange["skillsSelected" + skillKey] = profile["skills" + skillKey];
        }
        if (profile["skillsMinFee" + skillKey]) {
          objectOfChange["skillsMinFee" + skillKey] = profile["skillsMinFee" + skillKey] + "";
          objectOfChange["skillsMinFeeCurrency" + skillKey] = (profile["skillsMinFeeCurrency" + skillKey] || global.myLocalCurrency) || "NGN";
        }
      })
      if (profile.locationWork) {
        objectOfChange["location"] = {
          coords: {
            latitude: profile.locationWork._lat,
            longitude: profile.locationWork._long
          },
          address: profile.locationWorkAddress || ""
        };
      } else {
        const re = await global.setLocation(5)
        if (re == -1 || !global.location || !global.location.coords) {
          objectOfChange["location"] = {
            coords: {
              latitude: 6.6,
              longitude: 3.3
            },
            address: "Lagos, Nigeria"
          }
        } else {
          let address = await global.getAddress()
          if (!address) address = "Unknown Location"
          objectOfChange["location"] = {
            coords: global.location.coords,
            address
          }
        }
      }

      this.setState(objectOfChange);

      if (profile.isActiveTrader) this.setState({ isActiveTrader: profile.isActiveTrader });

      //2. set a listener on the pictures so that they update when you change them
      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Profiles")
        .doc(global.myProfileID)
        .onSnapshot(this.onCollectionUpdate);

      //Check if user just tapped "Provide my Services on HelpOga"
      let isNewBusiness = await this.props.navigation.getParam(
        "isNewBusiness",
        null
      );
      if (isNewBusiness) {
        this.setState({ isNewBusiness, isBusiness: true });
      }
    } catch (err) {
      global.warn(err, "MeProfileUpdate1")
      alert("Error... ");
    }
    this.keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      this._keyboardDidShow
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      this._keyboardDidHide
    );

    if (global.walletType) {
      if (global.walletType == "ACN") this.setState({ currencies: ["ACN: ACoin", "GHS: Ghana Cedi", "NGN: Nigerian Naira"] })
      if (global.walletType == "NGN") this.setState({ currencies: ["NGN: Nigerian Naira", "GHS: Ghana Cedi"] })
    } else this.setState({ currencies: ["NGN: Nigerian Naira", "GHS: Ghana Cedi"] })
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
    if (this.keyboardDidShowListener) this.keyboardDidShowListener.remove();
    if (this.keyboardDidHideListener) this.keyboardDidHideListener.remove();
  }

  onCollectionUpdate = querySnapshot => {
    const query = querySnapshot.data();
    this.setState({
      picture: query.profilePictureURL,
      backgroundPicture: query.profilePictureBackgroundURL
    });
  };

  _keyboardDidShow = async e => {
    if (Platform.OS === "ios") {
      this.setState({ keyboardSpacerHeight: e.endCoordinates.height - 45 });
    } else {
      if (Platform.Version <= 19) {
        this.setState({ keyboardSpacerHeight: e.endCoordinates.height - 10 });
      }
    }
    //this.scrollView.scrollToEnd({ animated: false });
    //this.state.chatMessages.push({text: "Hello", author: 1, timeSent: 1})
  };

  _keyboardDidHide = () => {
    this.setState({ keyboardSpacerHeight: 56 });
  };

  setModalVisible(visible) {
    this.setState({ modalVisible: visible });
  }

  callHideMyBusiness = async () => {
    if (this.state.modalActivityIndicatorAnimating) return;
    //xx TBD xx: What if a user wants no info to be displayed
    try {
      this.setState({ modalActivityIndicatorAnimating: true });
      objectOfChange = {
        profile: global.myProfileID,
        hideBusinessProfile: true,
        myUID: global.myUID,
        idToken: global.idToken
      };
      const r = await fetch(global.cloudFunctionURL + "updateProfile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(objectOfChange)
      });
      const r2 = await r.json();

      this.setState({ modalActivityIndicatorAnimating: false });

      this.setModalVisible(false);

      if (r2.msg === "PROFILE_HIDDEN") {
        console.log("Profile hidden " + objectOfChange);
        this.props.navigation.goBack();
      } else {
        console.log("Error, ", r2.msg);
      }
    } catch (err) {
      alert("An error occurred.");
      global.warn(err, "MeProfileUpdate2")
      this.setState({ modalActivityIndicatorAnimating: false });
    }
  };

  callSet = async () => {

    if (this.state.activityIndicatorAnimating) return;

    if (this.state.isBusiness && this.state.businessName == "") {
      alert("Enter a business name!");
      return;
    }
    if (this.state.isBusiness && !this.state.location) {
      alert("You need to set your business location!");
      return;
    }
    if (this.state.isBusiness && (this.state.servicesOffered && this.state.servicesOffered.Professionals && this.state.servicesOffered.Professionals.includes("Public Speakers") && (!this.state.skillsSelectedPublicSpeakers || this.state.skillsSelectedPublicSpeakers.length == 0))) {
      return alert("You need to specify your speaking expertise")
    }
    if (this.state.isBusiness && (this.state.servicesOffered && this.state.servicesOffered.Professionals && this.state.servicesOffered.Professionals.includes("Teaching - Primary") && (!this.state.skillsSelectedTeachingPrimary || this.state.skillsSelectedTeachingPrimary.length == 0))) {
      return alert("You need to specify your primary teaching expertise")
    }
    if (this.state.isBusiness && (this.state.servicesOffered && this.state.servicesOffered.Professionals && this.state.servicesOffered.Professionals.includes("Teaching - Secondary") && (!this.state.skillsSelectedTeachingSecondary || this.state.skillsSelectedTeachingSecondary.length == 0))) {
      return alert("You need to specify your secondary teaching expertise")
    }
    if (this.state.isBusiness && (this.state.servicesOffered && this.state.servicesOffered.Professionals && this.state.servicesOffered.Professionals.includes("Teaching - Tertiary") && (!this.state.skillsSelectedTeachingTertiary || this.state.skillsSelectedTeachingTertiary.length == 0))) {
      return alert("You need to specify your tertiary teaching expertise")
    }
    if (this.state.isBusiness && (this.state.servicesOffered && this.state.servicesOffered.Professionals && this.state.servicesOffered.Professionals.includes("Public Speakers") && (isNaN(this.state["skillsMinFeePublicSpeakers"])))) {
      return alert("Your speaker minimum fee is not a number")
    }
    if (this.state.isBusiness && (this.state.servicesOffered && this.state.servicesOffered.Professionals && this.state.servicesOffered.Professionals.includes("Teaching - Primary") && (isNaN(this.state["skillsMinFeeTeachingPrimary"])))) {
      return alert("Your primary teaching fee is not a number")
    }
    if (this.state.isBusiness && (this.state.servicesOffered && this.state.servicesOffered.Professionals && this.state.servicesOffered.Professionals.includes("Teaching - Secondary") && (isNaN(this.state["skillsMinFeeTeachingSecondary"])))) {
      return alert("Your secondary teaching fee is not a number")
    }
    if (this.state.isBusiness && (this.state.servicesOffered && this.state.servicesOffered.Professionals && this.state.servicesOffered.Professionals.includes("Teaching - Tertiary") && (isNaN(this.state["skillsMinFeeTeachingTertiary"])))) {
      return alert("Your tertiary teaching fee is not a number")
    }
    if (
      this.state.isBusiness &&
      (this.state.servicesOffered == null || this.state.servicesOffered == {})
    ) {
      await Alert.alert(
        "Really continue?",
        "You can only receive job requests if you add a service",
        [
          {
            text: "Cancel",
            onPress: () => {
              return;
            },
            style: "cancel"
          },
          {
            text: "Yes",
            onPress: () => {
              this.executeSet();
            }
          }
        ]
      );
    } else {
      if (this.state.isBusiness && !this.state.description) {
        await Alert.alert("Continue?", "Your description is empty", [
          {
            text: "Cancel",
            onPress: () => {
              return;
            },
            style: "cancel"
          },
          {
            text: "Yes",
            onPress: () => {
              this.setState({ description: "-" })
              this.executeSet();
            }
          }
        ]);
      } else {
        this.executeSet();
      }
    }
  };

  executeSet = async () => {
    try {
      this.setState({ activityIndicatorAnimating: true });
      let objectOfChange = {
        profile: global.myProfileID,
        name: this.state.name,
        myUID: global.myUID,
        idToken: global.idToken
      };
      objectOfChange["description"] = this.state.description;
      if (this.state.traderDescription.length > 0) {
        objectOfChange["traderDescription"] = this.state.traderDescription;
      }
      objectOfChange["servicesOffered"] = this.state.servicesOffered;

      let servicesOffered2Array = []
      if (this.state.servicesOffered) {
        let servicesOfferedKeys = Object.keys(this.state.servicesOffered)
        servicesOfferedKeys.forEach(key => {
          this.state.servicesOffered[key].forEach(cat2 => {
            servicesOffered2Array.push(cat2.replace(/[^a-zA-Z]/g, ""))
          })
        })
      }

      global.skillKeys.forEach(skillKey => {
        if (servicesOffered2Array.includes(skillKey)) {
          objectOfChange["skills" + skillKey] = this.state["skillsSelected" + skillKey];
          objectOfChange["skillsMinFee" + skillKey] = Number(this.state["skillsMinFee" + skillKey]);
          objectOfChange["skillsMinFeeCurrency" + skillKey] = this.state["skillsMinFeeCurrency" + skillKey];;
        }
      })

      if (this.state.jobQualifications) {
        objectOfChange["jobQualifications"] = this.state.jobQualifications;
      }
      if (this.state.jobQualificationsStatus) {
        objectOfChange["jobQualificationsStatus"] = this.state.jobQualificationsStatus;
      }
      if (this.state.socials) {
        objectOfChange["socials"] = this.state.socials;
      }

      if (this.state.businessName.length > 0) {
        objectOfChange["businessName"] = this.state.businessName;
      }
      // if (global.myUID) {
      //   if (global.myUID != "+2340000000009") {
      //     objectOfChange["phoneNumber"] = global.myUID;
      //   }
      // }
      if (this.state.location) {
        if (this.state.businessName.length > 0 || (Array.isArray(this.state.jobQualifications) && this.state.jobQualificationsStatus.length > 0)) {
          // const loc = await new firebase.firestore.GeoPoint(
          //   this.state.location.coords.latitude,
          //   this.state.location.coords.longitude
          // );
          // objectOfChange["locationWork"] = loc;

          objectOfChange["coords"] = {
            latitude: this.state.location.coords.latitude,
            longitude: this.state.location.coords.longitude
          };
          if (this.state.location.address) {
            objectOfChange["address"] = this.state.location.address;
          }
        }
      }
      await new Promise(resolve => {
        setTimeout(resolve, 500);
      });
      console.log("objectOfChange", objectOfChange);

      // if (this.state.location) {
      //   objectOfChange["coords"] = { latitude: this.state.location.coords.latitude, longitude: this.state.location.coords.longitude },;
      // }

      //console.log("objectOfChange", objectOfChange);
      const r = await fetch(global.cloudFunctionURL + "updateProfile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(objectOfChange)
      });
      const r2 = await r.json();

      this.setState({ activityIndicatorAnimating: false });
      if (r2.msg === "PROFILE_UPDATED") {
        console.log("Profile updated " + objectOfChange);
        await new Promise(resolve => { setTimeout(resolve, 300); });
        global.setGlobals();
        await new Promise(resolve => { setTimeout(resolve, 200); });
        this.props.navigation.goBack();
        await new Promise(resolve => { setTimeout(resolve, 200); });

      }
    } catch (err) {
      alert("Error. Please check your internet connection");
      global.warn(err, "MeProfileUpdate3")
      this.setState({ activityIndicatorAnimating: false });
    }
  };

  renderSkills = (skillKey) => {
    let myExpertiseStrings = {
      PublicSpeakers: "My Speaking Expertise:",
      TeachingPrimary: "My Primary Teaching Expertise:",
      TeachingSecondary: "My Secondary Teaching Expertise:",
      TeachingTertiary: "My Tertiary Teaching Expertise:"
    }
    let myExpertiseString = myExpertiseStrings[skillKey] || "My " + skillKey + " Expertise:"
    let myBudgetStrings = {
      PublicSpeakers: "My Minimum Speaker Fee:",
      TeachingPrimary: "My Minimum Hourly Fee for Primary Teaching (optional):",
      TeachingSecondary: "My Minimum Hourly Fee for Secondary Teaching (optional):",
      TeachingTertiary: "My Minimum Hourly Fee for Tertiary Teaching (optional):"
    }
    let myBudgetString = myBudgetStrings[skillKey] || "My " + skillKey + " Minimum Fee:"

    return (<View key={skillKey} style={{ marginBottom: 15, marginTop: 8, paddingHorizontal: 15 }}>
      <View style={{}}>
        <Text
          style={{
            fontSize: 14, color: "#A8CF45", marginBottom: 5, marginTop: 20, paddingHorizontal: -10, justifyContent: "flex-start"
          }}
        >{myExpertiseString}</Text>
        {(global.skills[skillKey] || []).map((skill) => {
          return (
            <TouchableOpacity key={skill} activeOpacity={1}
              onPress={() => {
                if (this.state["skillsSelected" + skillKey].includes(skill)) {
                  const newArray = this.state["skillsSelected" + skillKey].filter((s) => { return (s != skill) })
                  const stateChange = {}
                  stateChange["skillsSelected" + skillKey] = newArray
                  this.setState(stateChange);
                } else {
                  const newArray = this.state["skillsSelected" + skillKey].filter((s) => { return true })
                  newArray.push(skill)
                  const stateChange = {}
                  stateChange["skillsSelected" + skillKey] = newArray
                  this.setState(stateChange);
                }
              }}
            >
              <CheckboxSelector
                selected={this.state["skillsSelected" + skillKey].includes(skill)}
                text={skill}
              />
            </TouchableOpacity>
          )
        })}
        <Text
          style={{
            fontSize: 14, color: "#A8CF45", marginBottom: 5, marginTop: 10, marginBottom: 10, justifyContent: "flex-start"
          }}>{myBudgetString}</Text>
        <View style={{ flexDirection: "row", alignItems: "center", alignSelf: "stretch", marginBottom: 10 }}>
          <TextInput
            onChangeText={minFee => {
              const stateChange = {}
              if (minFee != "0" && minFee.startsWith("0")) minFee = minFee.substring(1, 50)
              stateChange["skillsMinFee" + skillKey] = minFee
              this.setState(stateChange);
            }}
            placeholderTextColor={"#000"}
            keyboardType="numeric"
            style={{
              color: "#ffffff",
              padding: 3,
              marginRight: 8,
              borderRadius: 5,
              width: 120,
              height: 30,
              textAlign: "center",
              backgroundColor: "#334"
            }}
            // onFocus={() => {
            //   this.scrollView.scrollTo({ x: 0, y: 2000, animated: true });
            // }}
            maxLength={50}
            underlineColorAndroid="transparent"
            keyboardAppearance={"dark"}
            defaultValue={this.state["skillsMinFee" + skillKey]}
            bordered
          />
          <TouchableOpacity
            onPress={() => {
              this.currSkillKey = skillKey
              this.setState({ modalCurrencyVisible: true })
            }}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <View
              style={{
                color: "#ffffff", borderColor: "#334", borderWidth: 1, padding: 3, borderRadius: 15, width: 30, height: 30, backgroundColor: "#000", alignItems: "center", marginHorizontal: 1, justifyContent: "center"
              }}
            >
              <Text style={{ color: "#ccc" }}>{global.currencySymbols[this.state["skillsMinFeeCurrency" + skillKey]]}</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>)
  }

  render() {
    let servicesOffered2Array = []
    if (this.state.servicesOffered) {
      servicesOfferedKeys = Object.keys(this.state.servicesOffered)
      servicesOfferedKeys.forEach(key => {
        this.state.servicesOffered[key].forEach(cat2 => {
          servicesOffered2Array.push(cat2.replace(/[^a-zA-Z]/g, ""))
        })
      })
    }
    let allJobQualifications = [...(global.jobQualifications || []), ...(this.state.jobQualifications || [])]
    allJobQualifications = allJobQualifications.filter((v, i, a) => a.indexOf(v) === i);


    //console.log("KKKKKSTATEKKK", this.state)


    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#000000" }}>
        <ScrollView
          ref={ref => (this.scrollView = ref)}
          style={{ backgroundColor: "black", flex: 1 }}
        >
          {/***** Background Image *****/}
          <View
            style={{
              height: 180,
              justifyContent: "flex-start",
              backgroundColor: "transparent",
              alignItems: "center",
              marginBottom: 0
              //shadowOpacity: 0,
              //shadowOffset: { width: 0, height: 1 },
              //shadowColor: "white"
            }}
          >
            {!!this.state.backgroundPicture && (
              <Image
                key={
                  this.state.backgroundPicture ||
                  "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/testDummies%2FwBack2.png?alt=media&token=471d98ea-66b4-4753-93e4-4150a417e680"
                }
                style={{ width: Dimensions.get("window").width, height: 180 }}
                source={{
                  uri:
                    this.state.backgroundPicture ||
                    "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/testDummies%2FwBack2.png?alt=media&token=471d98ea-66b4-4753-93e4-4150a417e680"
                }}
              />
            )}
          </View>
          <View
            style={{
              ...Platform.select({
                ios: {
                  height: 0,
                  marginBottom: -100
                },
                android: {
                  height: 85,
                  backgroundColor: "rgba(44, 44, 44, 0.3)",
                  marginTop: -180,
                  marginBottom: -5
                }
              })
            }}
          />
          <View
            style={{
              alignItems: "flex-end",
              marginBottom: 30,
              marginRight: 10
            }}
          >
            <TouchableOpacity
              onPress={() => {
                this.setState({ showGetBackgroundPicture: true });
              }}
              style={{
                backgroundColor: "rgba(220, 220, 220, 0.8)",
                padding: 3,
                borderRadius: 5
              }}
            >
              <Text style={{ color: "black" }}>Change</Text>
            </TouchableOpacity>
          </View>

          {/***** Profile Image *****/}
          <View
            style={{
              height: 102,
              alignItems: "center"
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: 102,
                height: 102,
                borderRadius: 32,
                backgroundColor: "#FFFFFF",
                shadowOpacity: 1,
                shadowColor: "white",
                shadowOffset: { height: 0, width: 0 },
                shadowRadius: 32
              }}
            >
              {!!this.state.picture && (
                <Image
                  key={this.state.picture}
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 32
                  }}
                  source={{
                    uri: this.state.picture
                  }}
                />
              )}
            </View>
          </View>
          <View
            style={{ alignItems: "center", marginTop: -35, marginBottom: 20 }}
          >
            {this.state.showGetPicture && (
              <GetPicture
                onDone={async pictureURL => {
                  this.setState({ showGetPicture: false });
                  console.log("PictureURL", pictureURL);
                }}
                item={"PROFILE_PICTURE"}
              />
            )}
            {this.state.showGetBackgroundPicture && (
              <GetPicture
                onDone={async pictureURL => {
                  this.setState({ showGetBackgroundPicture: false });
                  console.log("PictureURL", pictureURL);
                }}
                item={"PROFILE_BACKGROUND_PICTURE"}
              />
            )}
            <TouchableOpacity
              onPress={() => {
                this.setState({ showGetPicture: true });
              }}
              style={{
                backgroundColor: "rgba(220, 220, 220, 0.8)",
                padding: 3,
                borderRadius: 5
              }}
            >
              <Text style={{ color: "black" }}>Change</Text>
            </TouchableOpacity>
          </View>

          {/***** Name *****/}
          <View
            style={{
              alignItems: "flex-start",
              marginHorizontal: 20,
              flex: 1,
              alignItems: "stretch"
            }}
          >
            {this.state.isNewBusiness && (
              <Text
                style={{
                  fontSize: 14,
                  color: "#f00",
                  paddingBottom: 10,
                  borderBottomColor: "#FFFFFF",
                  borderBottomWidth: 0,
                  marginTop: 20,
                  justifyContent: "flex-start"
                }}
              >
                You are now a provider on {global.a.appName2 || "Wakanda"}. To start receiving requests, enter below information!
              </Text>
            )}
            <Text
              style={{
                fontSize: 14,
                color: "#A8CF45",
                paddingBottom: 10,
                borderBottomColor: "#FFFFFF",
                borderBottomWidth: 0,
                marginTop: 20,
                justifyContent: "flex-start"
              }}
            >
              My name:
            </Text>
            <TextInput
              onChangeText={name => {
                this.setState({ name });
              }}
              onFocus={() => {
                this.scrollView.scrollTo({ x: 0, y: 200, animated: true });
              }}
              style={{
                marginBottom: 5,
                color: "#ffffff",
                borderColor: "grey",
                borderWidth: 0,
                paddingLeft: 3,
                borderRadius: 5,
                height: 36,
                backgroundColor: "#334"
              }}
              underlineColorAndroid="transparent"
              maxLength={32}
              defaultValue={this.state.name}
              keyboardAppearance={"dark"}
              returnKeyType={"done"}
              bordered
            />
          </View>

          {/*****************************************************/}
          {/***** Show following fields only for businesses *****/}
          {/*****************************************************/}
          {this.state.isBusiness && (
            <View>
              {/***** Business Name *****/}
              <Text />
              <View
                style={{
                  //alignItems: "flex-start",
                  marginHorizontal: 20,
                  flex: 1,
                  alignItems: "stretch"
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    color: "#A8CF45",
                    paddingBottom: 10,
                    borderBottomColor: "#FFFFFF",
                    borderBottomWidth: 0,
                    marginTop: 20,
                    justifyContent: "flex-start"
                  }}
                >
                  My business name:
                </Text>
                <TextInput
                  onChangeText={businessName => {
                    this.setState({ businessName });
                  }}
                  onFocus={() => {
                    this.scrollView.scrollTo({ x: 0, y: 320, animated: true });
                  }}
                  style={{
                    marginBottom: 20,
                    color: "#ffffff",
                    borderColor: "grey",
                    borderWidth: 0,
                    paddingLeft: 3,
                    borderRadius: 5,
                    height: 36,
                    backgroundColor: "#334"
                  }}
                  underlineColorAndroid="transparent"
                  maxLength={40}
                  defaultValue={this.state.businessName}
                  keyboardAppearance={"dark"}
                  returnKeyType={"done"}
                  bordered
                />
              </View>

              {!!this.state.businessName && <PushNotificationHandler />}

              {/***** Description *****/}
              <View
                style={{
                  marginHorizontal: 20,
                  flex: 1,
                  alignItems: "stretch"
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    color: "#A8CF45",
                    paddingBottom: 10,
                    borderBottomColor: "#FFFFFF",
                    borderBottomWidth: 0,
                    marginTop: 20,
                    justifyContent: "flex-start"
                  }}
                >
                  About me / my business:
                </Text>
                <TextInput
                  onChangeText={description => {
                    this.setState({ description });
                  }}
                  onFocus={() => {
                    this.scrollView.scrollTo({ x: 0, y: 440, animated: true });
                  }}
                  multiline={true}
                  numberOfLines={6}
                  style={{
                    marginBottom: 20,
                    color: "#ffffff",
                    borderColor: "grey",
                    borderWidth: 0,
                    paddingLeft: 3,
                    borderRadius: 5,
                    height: 180,
                    backgroundColor: "#334"
                  }}
                  maxLength={400}
                  underlineColorAndroid="transparent"
                  defaultValue={this.state.description}
                  rowSpan={5}
                  keyboardAppearance={"dark"}
                  returnKeyType={"done"}
                  bordered
                />
              </View>

              {/***** ACoin Trader Description *****/}
              {!!this.state.isActiveTrader && <View
                style={{
                  marginHorizontal: 20,
                  flex: 1,
                  alignItems: "stretch"
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    color: "#A8CF45",
                    borderBottomColor: "#FFFFFF",
                    borderBottomWidth: 0,
                    marginTop: 20,
                    justifyContent: "flex-start"
                  }}
                >
                  ACoin Trader: What do you trade?
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    color: "#656565",
                    paddingBottom: 10,
                    borderBottomColor: "#FFFFFF",
                    borderBottomWidth: 0,
                    justifyContent: "flex-start"
                  }}
                >
                  e.g. Mobile Money, Cash. Both at my shop and online.
                </Text>
                <TextInput
                  onChangeText={traderDescription => {
                    this.setState({ traderDescription });
                  }}
                  onFocus={() => {
                    this.scrollView.scrollTo({ x: 0, y: 450, animated: true });
                  }}
                  multiline={true}
                  numberOfLines={2}
                  style={{
                    marginBottom: 20,
                    color: "#ffffff",
                    borderColor: "grey",
                    borderWidth: 0,
                    paddingLeft: 3,
                    borderRadius: 5,
                    height: 40,
                    backgroundColor: "#334"
                  }}
                  maxLength={400}
                  underlineColorAndroid="transparent"
                  defaultValue={this.state.traderDescription}
                  rowSpan={2}
                  bordered
                />
              </View>}

              {/***** My location *****/}
              <View style={{ marginVertical: 15, flex: 1 }}>
                <Text
                  style={{
                    fontSize: 14,
                    color: "#A8CF45",
                    paddingBottom: 10,
                    borderBottomColor: "#FFFFFF",
                    borderBottomWidth: 0,
                    paddingHorizontal: 5,
                    marginTop: 20,
                    justifyContent: "flex-start"
                  }}
                >
                  My business location:
                </Text>
                {this.state.location != "LOADING" && (
                  <View style={{ paddingHorizontal: 5 }}>
                    <CurrentLocation
                      onPressEdit={() => { this.setState({ showLocationModal: true }) }}
                      setLiveLocation={false}
                      textColor={"#fff"}
                      location={this.state.location}
                      textColor={"#fff"}
                    />
                  </View>
                )}
              </View>
              {global.app != "speakers" && <Text
                style={{
                  fontSize: 14,
                  color: "#A8CF45",
                  borderBottomColor: "#FFFFFF",
                  borderBottomWidth: 0,
                  marginTop: 20,
                  paddingHorizontal: 5,
                  justifyContent: "flex-start"
                }}
              >
                My Services:
              </Text>}

              {/***** Services Offered *****/}
              {global.app != "speakers" && <View style={{ marginBottom: 15, marginTop: 8, paddingHorizontal: 5 }}>
                <MyServicesEditor
                  services={this.state.servicesOffered}
                  onUpdate={servicesOffered => {
                    console.log(":::", servicesOffered)
                    this.setState({ servicesOffered });
                  }}
                />
              </View>}

              {/***** Skills Offered *****/}
              {global.skillKeys.map(skillKey => {
                return (servicesOffered2Array && servicesOffered2Array.includes(skillKey)) &&
                  this.renderSkills(skillKey)
              })}

              {/***** Hide My Business *****/}
              {!this.state.isNewBusiness ? (
                <View
                  style={{ backgroundColor: "#444", padding: 16, margin: 18, marginVertical: 50 }}
                >
                  <Text style={{ color: "black" }}>
                    If you are away for some time or don´t want to provide
                    services, you can hide your business profile.
                  </Text>
                  <Text style={{ color: "black" }} />
                  <TouchableOpacity
                    onPress={() => this.setModalVisible(true)}
                    style={[
                      global.gStyles.button,
                      { marginTop: 20, backgroundColor: "#400" }
                    ]}
                  >
                    <Text style={[global.gStyles.buttontext, { color: "#888" }]}>
                      Hide my business
                    </Text>
                  </TouchableOpacity>
                </View>
              ) :
                <View style={{ height: 0 }} />}
            </View>
          )}

          {/***** Job Qualifications *****/}
          {((!this.state.jobQualifications || this.state.jobQualifications.length == 0) && !this.state.viewJobQualifications) ? (<View
            style={{ height: 120, justifyContent: "center", alignItems: "center", marginTop: 15 }}
          >
            <Text style={{ color: "#bb0", fontSize: 18 }}>No Job Qualifications</Text>
            <Text style={{ color: "#bb0" }}>If you are looking for a job, add your qualifications</Text>
            <View style={{ alignItems: "center" }}>
              <TouchableOpacity
                onPress={() => {
                  if (!this.state.jobQualifications) this.setState({ jobQualifications: [] });
                  this.setState({ viewJobQualifications: true, editJobQualifications: true });
                  if (!this.state.jobQualificationsStatus) this.setState({ jobQualificationsStatus: "SEEKING" });
                  // if (!this.state.jobQualificationsStatus) this.setState({ jobQualificationsStatus: "NOT_SEEKING" });
                }}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 18,
                  borderWidth: 0.5,
                  borderColor: "#ccccd5",
                  height: 35,
                  width: 240,
                  margin: 15,
                  backgroundColor: "#000"
                }}
              >
                <Text style={{ color: "#ccccd5", fontSize: 14 }}>ADD JOB QUALIFICATIONS</Text>
              </TouchableOpacity>
            </View>
          </View>)
            :
            <View>
              {!!this.state.editJobQualifications ? <View>
                <View style={{ paddingTop: 20, paddingBottom: 10 }}>
                  <Text style={{ color: "#A8CF45", fontSize: 14, marginBottom: 5, marginLeft: 15 }}>My Job Qualifications</Text>
                </View>
                {allJobQualifications.map(jq => {
                  return (<View key={jq} style={{ marginBottom: 0, marginTop: 0, paddingHorizontal: 15 }}>
                    <View style={{}}>
                      <TouchableOpacity key={jq} activeOpacity={1}
                        onPress={() => {
                          if (!this.state.jobQualifications) {
                            this.setState({ jobQualifications: [jq] });
                            return;
                          }
                          if (Array.isArray(this.state.jobQualifications) && this.state.jobQualifications.includes(jq)) {
                            const newArray = this.state.jobQualifications.filter((q) => { return (q != jq) })
                            this.setState({ jobQualifications: newArray });
                            return;
                          } else if (Array.isArray(this.state.jobQualifications) && !this.state.jobQualifications.includes(jq)) {
                            const newArray = this.state.jobQualifications.filter((q) => { return true })
                            newArray.push(jq)
                            this.setState({ jobQualifications: newArray });
                            return;
                          }
                        }}
                      >
                        <CheckboxSelector
                          selected={Array.isArray(this.state.jobQualifications) && this.state.jobQualifications.includes(jq)}
                          text={jq}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>)
                })}
                <View style={{ marginTop: 15 }}>
                  <TouchableOpacity key={"status"} activeOpacity={1} style={{ marginLeft: 15 }}
                    onPress={() => {
                      if (!this.state.jobQualificationsStatus || this.state.jobQualificationsStatus == "NOT_SEEKING") {
                        this.setState({ jobQualificationsStatus: "SEEKING" });
                        return;
                      } else this.setState({ jobQualificationsStatus: "NOT_SEEKING" });
                    }}
                  >
                    <CheckboxSelector
                      selected={this.state.jobQualificationsStatus && this.state.jobQualificationsStatus == "SEEKING"}
                      text={"Enable employers to find and contact me"}
                      color="yellow"
                    />
                  </TouchableOpacity>
                </View>
              </View>
                :
                (<View
                  style={{ marginTop: 15 }}
                >
                  <Text style={{ color: "#A8CF45", fontSize: 14, marginBottom: 5, marginLeft: 15 }}>My Job Qualifications</Text>
                  {this.state.jobQualifications.map(jq => {
                    return (<View key={jq}>
                      <View style={{ paddingTop: -10, paddingBottom: 10, flexDirection: "row", marginLeft: 20 }}>
                        <Text style={{ color: '#ccccd5', fontSize: 14 }}>{jq}</Text>
                      </View>
                    </View>)
                  })}
                  <TouchableOpacity key={"status2"} activeOpacity={1} style={{ marginLeft: 15 }}
                    onPress={() => {
                      if (!this.state.jobQualificationsStatus || this.state.jobQualificationsStatus == "NOT_SEEKING") {
                        this.setState({ jobQualificationsStatus: "SEEKING" });
                        return;
                      } else this.setState({ jobQualificationsStatus: "NOT_SEEKING" });
                    }}
                  >
                    <CheckboxSelector
                      selected={this.state.jobQualificationsStatus && this.state.jobQualificationsStatus == "SEEKING"}
                      text={"Enable employers to find and contact me"}
                      color="yellow"
                    />
                  </TouchableOpacity>
                  <View style={{ alignItems: "center" }}>
                    <TouchableOpacity
                      onPress={() => {
                        this.setState({ editJobQualifications: true });
                        if (!this.state.jobQualificationsStatus) this.setState({ jobQualificationsStatus: "NOT_SEEKING" });
                      }}
                      style={{
                        alignItems: "center", justifyContent: "center", borderRadius: 18, borderWidth: 0.5, borderColor: "#ccccd5", height: 35, width: 240, margin: 15, backgroundColor: "#000"
                      }}
                    >
                      <Text style={{ color: "#ccccd5", fontSize: 14 }}>EDIT JOB QUALIFICATIONS</Text>
                    </TouchableOpacity>
                  </View>
                </View>)}
            </View>}

          {/***** My location if not a Business *****/}
          {(!this.state.isBusiness
            && Array.isArray(this.state.jobQualifications)
            && (this.state.jobQualifications.length > 0)
            && this.state.jobQualificationsStatus == "SEEKING")
            && <View style={{ marginVertical: 15, flex: 1 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#A8CF45",
                  paddingBottom: 10,
                  borderBottomColor: "#FFFFFF",
                  borderBottomWidth: 0,
                  paddingHorizontal: 5,
                  marginTop: 20,
                  justifyContent: "flex-start"
                }}
              >
                My location (for job searches):
                </Text>
              {this.state.location != "LOADING" && (
                <View style={{ paddingHorizontal: 5 }}>
                  <CurrentLocation
                    onPressEdit={() => { this.setState({ showLocationModal: true }) }}
                    setLiveLocation={false}
                    textColor={"#fff"}
                    location={this.state.location}
                    textColor={"#fff"}
                  />
                </View>
              )}
            </View>}

          {/***** Website *****/}
          {/* {this.state.websiteURL && < View style={{ marginTop: 20 }}>
            <Text style={{ color: "#A8CF45", fontSize: 14, marginBottom: 5, marginLeft: 15 }}>My Website</Text>
            <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 5, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 10, justifyContent: "flex-start" }} >
              {this.state.websiteURL}</Text>
          </View>} */}

          {/***** Socials *****/}
          {((!this.state.socials || Object.keys(this.state.socials).length == 0) && !this.state.viewSocials) ? (<View
            style={{ height: 120, justifyContent: "center", alignItems: "center", marginTop: 15 }}
          >
            <Text style={{ color: "#bbbbc5", fontSize: 18 }}>No Social Media Accounts linked</Text>
            <Text style={{ color: "#bbbbc5", textAlign: "center" }}>Optionally link your facebook, instagram, twitter or linkedIn account on your profile</Text>
            <View style={{ alignItems: "center" }}>
              <TouchableOpacity
                onPress={async () => {
                  await this.setState({ viewSocials: true, socials: {} });
                  await global.timeout(150)
                  this.scrollView.scrollTo({ x: 0, y: 1500, animated: true });
                }}
                style={{
                  alignItems: "center", justifyContent: "center", borderRadius: 18, borderWidth: 0.5, borderColor: "#ccccd5", height: 35, width: 240, margin: 15, backgroundColor: "#000"
                }}
              >
                <Text style={{ color: "#ccccd5", fontSize: 14 }}>Link Social Media Accounts</Text>
              </TouchableOpacity>
            </View>
          </View>)
            : <View>
              <View style={{ paddingTop: 20 }}>
                <Text style={{ color: "#A8CF45", fontSize: 14, marginBottom: 5, marginLeft: 15 }}>My Social Media Accounts</Text>
              </View>
              <View style={{ marginHorizontal: 25 }}>
                <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 5, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 10, justifyContent: "flex-start" }} >
                  My Instagram Link or Handle</Text>
                <TextInput
                  onChangeText={text => {
                    const newSocials = global.duplicate(this.state.socials);
                    newSocials.instagram = text;
                    this.setState({ socials: newSocials });
                  }}
                  onFocus={async () => { await global.timeout(1500); this.scrollView.scrollTo({ x: 0, y: 2000, animated: true }); }}
                  style={{ marginBottom: 10, color: "#ffffff", paddingLeft: 3, borderRadius: 5, height: 25, backgroundColor: "#334" }}
                  maxLength={300}
                  placeholder={"e.g. @wakandamarketapp"}
                  placeholderTextColor={"#889"}
                  underlineColorAndroid="transparent"
                  defaultValue={this.state.socials.instagram}
                  returnKeyType={"done"}
                  bordered
                />
                <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 5, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 10, justifyContent: "flex-start" }} >
                  My Twitter Link or Handle</Text>
                <TextInput
                  onChangeText={text => {
                    const newSocials = global.duplicate(this.state.socials);
                    newSocials.twitter = text;
                    this.setState({ socials: newSocials });
                  }}
                  onFocus={async () => { await global.timeout(1500); this.scrollView.scrollTo({ x: 0, y: 2000, animated: true }); }}
                  style={{ marginBottom: 10, color: "#ffffff", paddingLeft: 3, borderRadius: 5, height: 25, backgroundColor: "#334" }}
                  maxLength={300}
                  placeholder={"e.g. @AfricanFounders"}
                  placeholderTextColor={"#889"}
                  underlineColorAndroid="transparent"
                  defaultValue={this.state.socials.twitter}
                  returnKeyType={"done"}
                  bordered
                />
                <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 5, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, justifyContent: "flex-start" }} >
                  My Facebook Link or Username</Text>
                <TextInput
                  onChangeText={text => {
                    const newSocials = global.duplicate(this.state.socials);
                    newSocials.facebook = text;
                    this.setState({ socials: newSocials });
                  }}
                  onFocus={async () => { await global.timeout(1500); this.scrollView.scrollTo({ x: 0, y: 2000, animated: true }); }}
                  style={{ marginBottom: 10, color: "#ffffff", paddingLeft: 3, borderRadius: 5, height: 25, backgroundColor: "#334" }}
                  maxLength={300}
                  placeholder={"e.g. www.facebook.com/wakandamrkt/"}
                  placeholderTextColor={"#889"}
                  underlineColorAndroid="transparent"
                  defaultValue={this.state.socials.facebook}
                  returnKeyType={"done"}
                  bordered
                />
                <Text style={{ flex: 1, fontSize: 16, color: "#ccc", paddingBottom: 5, borderBottomColor: "#FFFFFF", borderBottomWidth: 0, marginTop: 10, justifyContent: "flex-start" }} >
                  My LinkedIn Link</Text>
                <TextInput
                  onChangeText={text => {
                    const newSocials = global.duplicate(this.state.socials);
                    newSocials.linkedIn = text;
                    this.setState({ socials: newSocials });
                  }}
                  onFocus={async () => { await global.timeout(1500); this.scrollView.scrollTo({ x: 0, y: 2000, animated: true }); }}
                  style={{ marginBottom: 10, color: "#ffffff", paddingLeft: 3, borderRadius: 5, height: 25, backgroundColor: "#334" }}
                  maxLength={300}
                  placeholder={"e.g. www.linkedin.com/company/african-founders"}
                  placeholderTextColor={"#889"}
                  underlineColorAndroid="transparent"
                  defaultValue={this.state.socials.linkedIn}
                  returnKeyType={"done"}
                  bordered
                />
              </View>
            </View>}
          <View style={{ height: 150 }} />

        </ScrollView>
        <View
          style={{
            marginLeft: 20,
            marginRight: 20,
            marginTop: 10,
            marginBottom: 10,
            height: this.state.keyboardSpacerHeight
          }}
        >
          <TouchableOpacity style={global.gStyles.button} onPress={this.callSet}>
            <View style={{ flexDirection: "row" }}>
              {!!this.state.activityIndicatorAnimating ? (
                <ActivityIndicator
                  size="small"
                  color="#000"
                  style={{
                    marginRight: 20
                  }}
                  animating={true}
                />
              ) : (
                <View style={{ width: 40, height: 20 }} />
              )}
              <Text style={global.gStyles.buttontext}>UPDATE PROFILE</Text>
              <View style={{ width: 44 }} />
            </View>
          </TouchableOpacity>
        </View>
        <Modal
          animationType="slide"
          transparent={false}
          visible={this.state.modalVisible}
          onRequestClose={() => {
            //alert("Modal has been closed.");
          }}
        >
          <View
            style={{
              paddingTop: 62,
              flex: 1,
              backgroundColor: "#ccc",
              justifyContent: "center"
            }}
          >
            <View
              style={{
                justifyContent: "center",
                alignItems: "flex-start",
                margin: 25
              }}
            >
              <Text style={{ fontSize: 24, alignSelf: "center" }}>
                Hide my business?
              </Text>
              <View style={{ height: 40 }} />
              <Text style={{ fontSize: 16 }}>
                You will not receive any further jobs and your posts and
                business profile will be hidden.
              </Text>
              <Text style={{ fontSize: 16 }}>
                You will still be able to use {global.a.app || "Wakanda"} as a customer and chat
                with your contacts.
              </Text>
              <View style={{ height: 40 }} />
              {!this.state.modalActivityIndicatorAnimating ? (
                <TouchableOpacity
                  style={[global.gStyles.button, { margin: 21 }]}
                  onPress={() => {
                    //this.setModalVisible(false);
                    // xx tbd: unmount this screen
                    this.callHideMyBusiness();
                  }}
                >
                  <Text style={global.gStyles.buttontext}>
                    Okay, hide my business.
                  </Text>
                </TouchableOpacity>
              ) : (
                <View><ActivityIndicator
                  size="small"
                  color="#400"
                  style={{
                    margin: 0,
                    alignSelf: "center"
                  }}
                  animating={true}
                />
                  <Text style={{ color: "#400" }}>This may take a bit longer, please be patient...</Text>
                </View>
              )}
              <View style={{ height: 40 }} />
              <TouchableOpacity
                activeOpacity={0.4}
                onPress={() => {
                  this.setModalVisible(false);
                }}
                style={{ alignSelf: "center" }}
              >
                <Text style={{ color: "black", fontSize: 16 }}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.modalCurrencyVisible}
          onRequestClose={() => {
            this.setState({ modalCurrencyVisible: false });
          }}
        >
          <TouchableOpacity
            onPress={() =>
              this.setState({ modalCurrencyVisible: false })
            }
            style={{ height: 200 }}
          />
          <View
            style={{
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ccd"
              }}
            >
              <View style={{ paddingTop: 50 }}>
                <Text style={{ fontSize: 30 }}>Select a currency</Text>
              </View>
              <View style={{ marginTop: 35, marginBottom: 5 }}>
                {/* <Image
                    style={{ width: 100, height: 100, borderRadius: 33 }}
                    source={this.images[this.state.category1]}
                  /> */}
              </View>
              <SectionList
                renderItem={({ item, index, section }) => (
                  <TouchableOpacity
                    onPress={() => {
                      const stateChange = { modalCurrencyVisible: false }
                      stateChange["skillsMinFeeCurrency" + this.currSkillKey] = item.substring(0, 3)
                      this.setState(stateChange);
                    }}
                    style={{
                      flex: 1,
                      paddingTop: 10,
                      paddingBottom: 10,
                      //alignItems: "center",
                      paddingHorizontal: 10,
                      width: Dimensions.get("window").width,
                      backgroundColor: "white",
                      borderWidth: 1,
                      borderColor: "#ccd"
                    }}
                  >
                    <Text style={{ fontSize: 18 }} key={index}>
                      {item}
                    </Text>
                  </TouchableOpacity>
                )}
                renderSectionHeader={({ section: { title } }) => (
                  <Text style={{ fontWeight: "bold" }}>{title}</Text>
                )}
                sections={[
                  {
                    title: "",
                    data: this.state.currencies
                  }
                ]}
                keyExtractor={(item, index) => item + index}
              />
            </View>
          </View>
        </Modal>
        {
          !!this.state.showLocationModal && <CurrentLocationModal
            onCancel={() => { this.setState({ showLocationModal: false }) }}
            onSelect={(coords, address) => {
              // console.log("upda", address, coords);
              this.setState({
                showLocationModal: false,
                location: {
                  address: address,
                  coords: coords
                }
              });
            }}
          />
        }
      </SafeAreaView >
    );
  }
}

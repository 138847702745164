import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ScrollView,
  SafeAreaView,
  Keyboard,
  ActivityIndicator,
  Dimensions,
  Platform,
  Image,
  Alert,
  Modal
} from "react-native";
import { WebView } from 'react-native-webview';
import firebase from "firebase";
import { HeaderBackButton } from "react-navigation-stack";
import "firebase/firestore";
const gStyles = global.gStyles //    "../../styles/gStyles";
import CurrentLocation from "../../shared/Map/currentLocation";
import GetPicture from "../../shared/Camera/GetPicture";
import GetVideo from "../../shared/Camera/GetVideo";


export default class MeBlogPostEdit extends Component {
  static navigationOptions = ({ navigation }) => {
    const navigateBack = navigation.getParam("navigateBack", navigation.goBack) || navigation.goBack
    return {
      title: "Blog Post",
      headerTransparent: true,
      headerTintColor: "#A8CF45",
      headerLeft: props => (
        <HeaderBackButton
          {...props}
          onPress={() => { navigateBack() }} />),
      headerTitleStyle: {
        fontWeight: "800",
        color: "#A8CF45",
        fontSize: 16,
        shadowOpacity: 1
      }
    };
  };

  state = {
    // State is set below!
    loading: true
    // State is set below!
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.blog = {}
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    //this.unsubscribe = null;
  }

  async componentDidMount() {
    const action = this.props.navigation.getParam("action", "new")
    const group = this.props.navigation.getParam("blogPost", {})
    this.blog = this.props.navigation.getParam("blog")
    if (!this.blog || !this.blog.xID) {
      this.props.navigation.goBack()
      await global.timeout(800)
      alert("Something went wrong - try again!")
    }
    console.log({ action })
    this.setState({
      action,
      title: group.groupTitle || "",
      details: group.groupDetails || "",
      pictureURL: group.groupPictureURL || null,
      youtubeID: group.groupYoutubeID || null,
      aSpecialCategory: group.aSpecialCategory || "general",
      aType: group.aType || "forum",
      aOpenness: group.aOpenness || "public",
      networkID: group.networkID || null,
      communityID: group.communityID || null,
      location: group.location || global.myLocation || {},
      options: group.options || { sortChildrenBy: "TIME_ASCENDING" },
      nOfComments: group.nOfComments || 0,
      nOfDirectComments: group.nOfDirectComments || 0,
      nOfTotalLikes: group.nOfTotalLikes || 0,
      nOfJoiners: group.nOfJoiners || 0,
      topics: group.topics || [],
      closedMemberIDs: group.xJoinerIDs || (global.myProfileID ? [global.myProfileID] : []),

      addMedia: (group.groupPictureURL || group.groupYoutubeID) ? true : false,
      showGetPicture: false,
      showGetVideo: false,
      activityIndicatorAnimating: false,
      loading: false,
      error: false,
      watchingYoutube: false,
      playingYoutubeID: "",
      // modalAVisible: false,
      // activeAModal: "",
      // activeASelection: [],
      // showOpennessSelector: (this.blog.openness == "PRIVATE"),
    })
    this.groupID = group.xID
  }
  // async componentDidUpdate(prevProps, prevState) {
  //   if (prevState.screen != "DEFAULT" && this.state.screen == "DEFAULT")
  //     this.props.navigation.setParams({ navigateBack: null })
  //   if (prevState.screen == "DEFAULT" && this.state.screen != "DEFAULT")
  //     this.props.navigation.setParams({ navigateBack: () => { this.setState({ screen: "DEFAULT" }) } })
  // }

  playYoutube = (id) => {
    this.setState({ playingYoutubeID: id, watchingYoutube: true })
  }

  // activateASelection = (ob) => {
  //   console.log("this.state.activeAModal", this.state.activeAModal)
  //   this.setState({ modalAVisible: false })
  //   const type = ob.type
  //   console.log("type", type)
  //   if (this.state.activeAModal == "aOpenness") {
  //     this.setState({ aOpenness: type })
  //     if (type == "closed" && this.state.closedMemberIDs?.length < 2) {
  //       this.setState({ screen: "SELECT_MEMBERS" })
  //     }
  //     if (this.state.activeAModal == "aType") {
  //       this.setState({ aType: type })
  //     }
  //   }
  // }

  callCreate = async () => {
    // if (!this.state.aType) return alert("No type selected");
    // if (!this.state.aOpenness) return alert("Select open vs. private");
    if (!this.state.location || !this.state.location.coords || this.state.location.coords.latitude == 6.55)
      return alert("You need to specify the location of your offer");
    if (!this.state.title || this.state.title.length < 2) return alert("Enter a post title");
    if (!this.state.pictureURL) return alert("You need to add a picture or video");

    if (global.myUID && !this.state.activityIndicatorAnimating) {
      try {
        this.setState({ activityIndicatorAnimating: true });
        const r = await fetch(global.cloudFunctionURL + "newGroup", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ isWeb:true,
            blogID: this.blog.xID,
            title: this.state.title,
            details: this.state.details,
            pictureURL: this.state.pictureURL,
            ...(this.state.youtubeID && { youtubeID: this.state.youtubeID }),
            aSpecialCategory: this.state.aSpecialCategory,
            aType: this.state.aType,
            aOpenness: this.state.aOpenness,
            ...(this.state.aOpenness == "closed" && { joinerIDs: this.state.closedMemberIDs }),
            networkID: this.state.networkID,
            coords: global.location.coords,
            options: this.state.options,
            topics: [],
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
        await this.setState({ activityIndicatorAnimating: false });
        const r2 = await r.json();
        if (r2.msg == "SUCCESS") {
          const r3 = await fetch(global.cloudFunctionURL + "blog", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ isWeb:true,
              action: "addPost",
              blogID: this.blog.xID,
              blogPostID: r2.chatGroupID,
              myUID: global.myUID,
              idToken: global.idToken
            })
          });
          this.setState({ activityIndicatorAnimating: false });
          const r4 = await r3.json();
          if (r4.msg == "SUCCESS") {
            console.log("Post Created");
            this.props.navigation.goBack();
            await global.timeout(800)
            alert("Post created")
            return
          }
        }
        alert("Error. Try again");
        console.log("Error while calling callCreate", r2);
        await this.setState({ activityIndicatorAnimating: false });
      } catch (err) {
        alert("Connection error.");
        global.warn(err, "createBlogPost");
        this.setState({ activityIndicatorAnimating: false });
      }
    }
  };
  callUpdate = async () => {
    if (global.myUID && !this.state.activityIndicatorAnimating) {
      try {
        this.setState({ activityIndicatorAnimating: true });
        const r = await fetch(global.cloudFunctionURL + "editGroup", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ isWeb:true,
            action: "edit",
            chatGroupID: this.groupID,
            title: this.state.title,
            details: this.state.details,
            pictureURL: this.state.pictureURL,
            youtubeID: this.state.youtubeID,
            aSpecialCategory: this.state.aSpecialCategory,
            aType: this.state.aType,
            aOpenness: this.state.aOpenness,
            // ...(this.state.aOpenness == "closed" && { joinerIDs: this.state.closedMemberIDs }),
            //coords: global.location.coords,
            options: this.state.options,
            topics: [],
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
        await this.setState({ activityIndicatorAnimating: false });
        const r2 = await r.json();
        if (r2.msg == "SUCCESS") {
          // alert
          // initiateApproval (including push notification for approver OR chat to approver)
          // navigate back

          console.log("Group Updated");
          this.props.navigation.goBack();
          await global.timeout(800)
          alert("Post updated")
        } else {
          alert("Error. Try again");
          console.log("Error while calling callUpdate", r2);
        }
      } catch (err) {
        alert("Connection error..");
        global.warn(err, "updateBlogPost");
        this.setState({ activityIndicatorAnimating: false });
      }
    }
  };

  callDelete = () => {
    if (!global.myUID || this.state.activityIndicatorAnimating) return alert("Error")
    Alert.alert("Delete Blog Post?", "This will be permanent",
      [{ text: "No", onPress: () => { } },
      { text: "Yes", onPress: () => { this.callDeleteExecute() } }],
      { cancelable: true });
  }
  callDeleteExecute = async () => {
    try {
      if (!this.blog.xID || !this.groupID) return alert("Cannot delete - go back and try again")
      this.setState({ activityIndicatorAnimating: true });
      const r = await fetch(global.cloudFunctionURL + "editGroup", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isWeb:true,
          action: "delete",
          chatGroupID: this.groupID,
          myUID: global.myUID,
          idToken: global.idToken
        })
      });
      const r2 = await r.json();
      if (r2.msg == "SUCCESS") {
        const r3 = await fetch(global.cloudFunctionURL + "blog", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ isWeb:true,
            action: "removePost",
            blogID: this.blog.xID,
            blogPostID: this.groupID,
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
        this.setState({ activityIndicatorAnimating: false });
        const r4 = await r3.json();
        if (r4.msg == "SUCCESS") {
          console.log("Post Deleted");
          this.props.navigation.goBack();
          await global.timeout(750)
          alert("Blog Post deleted")
        } else {
          alert("Error. Try again.");
          console.log("Error", r2);
        }
      } else {
        alert("Error. Try again");
        console.log("Error", r2);
      }
      this.setState({ activityIndicatorAnimating: false });
    } catch (err) {
      alert("Connection error.");
      global.warn(err, "deleteBlogPost");
      this.setState({ activityIndicatorAnimating: false });
    }
  };

  render() {
    const sWidth = Dimensions.get("window").width;
    const sHeight = Dimensions.get("window").height;
    // const opennessSelection = [{
    //   type: "communityOnly",
    //   title: "All Members",
    //   description: "Visible to all members of " + (this.blog?.name) || "this community"
    // },
    // {
    //   type: "closed",
    //   title: "Selected Members",
    //   description: "Only visible to community members you select"
    // }]
    // const opennessSelection = [{
    //   type: "public",
    //   title: "Public",
    //   description: "Can be found by people around you, your followers, your communities"
    // },
    // {
    //   type: "closed",
    //   title: "Closed",
    //   description: "Only visible to people you invite"
    // },
    // {
    //   type: "communityOnly",
    //   title: "Community Only",
    //   description: "Accessible to people in the community you specify"
    // }]

    // const typeSelection = [{
    //   type: "forum",
    //   title: "Forum",
    //   description: "Anybody can contribute"
    // },
    // {
    //   type: "broadcast",
    //   title: "Broadcast",
    //   description: "Only you can send messages"
    // }, {
    //   type: "broadcastWithResponses",
    //   title: "Broadcast with comments",
    //   description: "Only you can send messages, others can respond."
    // }]

    if (this.state.loading) return (
      <View
        style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "black" }}
      >
        <Text style={{ color: "#555" }}>Loading...</Text>
      </View >
    );
    if (this.state.error) {
      return (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "black" }}
        >
          <Text style={{ color: "#555" }}>Error. Try again.</Text>
        </View>
      );
    } else {
      const tStyle = { color: "#A8CF45", fontSize: 16, fontWeight: "800", textAlign: "left", marginTop: 11, marginBottom: 2 }

      return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#000000" }}>
          <TouchableWithoutFeedback
            onPress={Keyboard.dismiss}
            accessible={false}
          >
            <ScrollView style={{ marginTop: 50, paddingHorizontal: 10 }}
              scrollEventThrottle={1000}
              onScroll={(e) => { Keyboard.dismiss() }}>
              {Platform.OS === "android" && <View style={{ height: 25 }} />}
              <Text style={tStyle}>Title</Text>
              <TextInput
                onChangeText={title => {
                  this.setState({ title });
                }}
                placeholder={""}
                placeholderTextColor={"#66a"}
                style={{
                  color: "#ffffff", borderColor: "grey", borderWidth: 0, paddingLeft: 3, borderRadius: 5, height: this.state.title.length > 100 ? 75 : this.state.title.length > 60 ? 60 : this.state.title.length > 30 ? 45 : 30, backgroundColor: "#223"
                }}
                maxLength={1000}
                numberOfLines={4}
                multiline={true}
                underlineColorAndroid="transparent"
                defaultValue={this.state.title}
                keyboardDismissMode={'on-drag'}
                keyboardAppearance={"dark"}
              />
              <Text style={tStyle}>Text</Text>
              <TextInput
                onChangeText={details => {
                  this.setState({ details });
                }}
                multiline={true}
                placeholder={""}
                placeholderTextColor={"#66a"}
                numberOfLines={10}
                style={{
                  color: "#ffffff", borderColor: "grey", borderWidth: 0, paddingLeft: 3, borderRadius: 5, height: this.state.details.length > 250 ? 200 : this.state.details.length > 150 ? 160 : 120, backgroundColor: "#223",
                }}
                maxLength={5000}
                underlineColorAndroid="transparent"
                defaultValue={this.state.details}
                rowSpan={10}
                keyboardDismissMode={'on-drag'}
                keyboardAppearance={"dark"}
              />
              {!!this.state.addMedia ? <View style={{ alignItems: "center", justifyContent: "center" }}>
                <Text style={[tStyle, { alignSelf: "flex-start" }]}>Picture / Video</Text>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ showGetPicture: true });
                  }}>
                  {!!this.state.pictureURL ? <View>
                    <View
                      style={{ width: sWidth - 20, height: (sWidth - 20) * 9 / 16, borderRadius: 5, alignItems: "center", justifyContent: "center", marginBottom: -((sWidth - 20) * 9 / 16) }}
                    >
                      <Text style={{ color: "#555" }}>Loading...</Text>
                    </View>
                    <Image
                      key={this.state.pictureURL}
                      style={{ width: sWidth - 20, height: (sWidth - 20) * 9 / 16, borderRadius: 5, borderWidth: 1, borderColor: "#222" }}
                      source={{ uri: this.state.pictureURL }}
                    />
                    {/* <View
                      style={{ width: sWidth - 20, height: ((sWidth - 20) * 9 / 16) + 15, marginTop: -((sWidth - 20) * 9 / 16) - 15, borderRadius: 5, alignItems: "flex-end" }}
                    >
                      <TouchableOpacity style={{ height: 55, width: 55, alignItems: "flex-end" }}>
                        <Image
                          style={{ width: 35, height: 35, opacity: 1, tintColor: "rgba(220, 220, 220, 0.8)" }}
                          source={require("../../images/icons/CancelWhite.png")}
                        />
                      </TouchableOpacity>
                    </View> */}
                  </View> :
                    <View
                      style={{ width: sWidth - 20, height: (sWidth - 20) * 9 / 16, borderRadius: 5, borderColor: "#aaa", borderWidth: 1, borderStyle: "dashed" }}
                    />
                  }
                </TouchableOpacity>
                {this.state.showGetPicture && (
                  <GetPicture
                    offerAPI
                    onDone={url => {
                      if (url) { this.setState({ pictureURL: url }); }
                      console.log("PictureURL", url);
                      this.setState({ showGetPicture: false });
                    }}
                    noCrop
                    item={"LARGE_WIDE_PICTURE"}
                  />
                )}
                {this.state.showGetVideo && (
                  <GetVideo
                    onDone={(youtubeID, thumbURL) => {
                      if (youtubeID && thumbURL) { this.setState({ pictureURL: thumbURL, youtubeID }); }
                      console.log("Gotten YouTube video..", youtubeID, thumbURL);
                      this.setState({ showGetVideo: false });
                    }}
                    item={"POST"}
                  />
                )}
                {!!this.state.youtubeID && <TouchableOpacity style={{
                  marginTop: -(220), width: 220, height: 220, alignItems: "center", justifyContent: "center"
                }}
                  onPress={() => { this.playYoutube(this.state.youtubeID) }}>
                  <View
                    style={{
                      backgroundColor: "rgba(198,198,198,0.95)", borderRadius: 25, paddingLeft: Platform.OS == "ios" ? 3 : 3.5, paddingBottom: Platform.OS == "ios" ? 0.5 : 2.5,
                      height: 50, justifyContent: 'center', alignItems: 'center', width: 50,
                    }}>
                    <Text style={{ color: "#000", fontSize: 36 }} >
                      {"▶"}
                    </Text>
                  </View>
                </TouchableOpacity>}
                <TouchableOpacity
                  onPress={() => { this.setState({ showGetVideo: true }); }}
                  style={{ backgroundColor: "rgba(220, 220, 220, 0.8)", marginTop: -42, marginBottom: 19, padding: 3, borderRadius: 5 }}
                >
                  <Text style={{ color: "black", minWidth: 100, textAlign: "center" }}>{this.state.youtubeID ? 'Change Video' : 'Add Video'}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => { this.setState({ showGetPicture: true }); }}
                  style={{ backgroundColor: "rgba(220, 220, 220, 0.8)", marginTop: -11.5, padding: 3, borderRadius: 5 }}
                >
                  <Text style={{ color: "black", minWidth: 100, textAlign: "center" }}>{this.state.pictureURL ? this.state.youtubeID ? 'Change Thumbnail' : 'Change Picture' : 'Add Picture'}</Text>
                </TouchableOpacity>
              </View>
                : <TouchableOpacity
                  onPress={() => { this.setState({ addMedia: true }); }}
                  style={[global.gStyles.buttonXW, { width: 120, marginTop: 11, marginBottom: 5 }]}
                >
                  <Text style={global.gStyles.buttontXW}>Add Picture / Video</Text>
                </TouchableOpacity>}

              {/* {this.state.showOpennessSelector && <View>
                <Text style={[tStyle, { alignSelf: "flex-start" }]}>Type</Text>
                <TouchableOpacity onPress={() => this.setState({ modalAVisible: true, activeAModal: "aOpenness", activeASelection: opennessSelection })}>
                  <ASelector selection={opennessSelection} selected={this.state.aOpenness} />
                </TouchableOpacity>
                {this.state.closedMemberIDs.length > 1 && this.state.aOpenness == "closed" && <TouchableOpacity
                  onPress={() => { this.setState({ screen: "SELECT_MEMBERS" }) }}
                  style={{ height: 35, alignSelf: "stretch" }}>
                  <ScrollView horizontal>
                    {this.state.closedMemberIDs.map(mID => {
                      return <Member profileID={mID} faceOnly key={mID}
                        selected={this.state.closedMemberIDs.includes(mID)}
                        navigation={this.props.navigation} />
                    })}
                  </ScrollView>
                </TouchableOpacity>}
                <TouchableOpacity onPress={() => this.setState({ modalAVisible: true, activeAModal: "aType", activeASelection: typeSelection })}>
                  <ASelector selection={typeSelection} selected={this.state.aType} />
                </TouchableOpacity>
              </View>} */}

              {/* <Text style={[tStyle, { alignSelf: "flex-start" }]}>Location</Text>
              <View style={{ borderRadius: 7.5, width: "100%", backgroundColor: "#18181f", }}>
                <CurrentLocation
                  textColor={"#ccd"}
                  initialCoords={global.location ? this.state.location.coords : null}
                  onUpdate={(address, coords) => {
                    console.log("upda", address, coords);
                    this.setState({
                      location: { address: address, coords: coords }
                    });
                  }}
                  iconColor={"#889"}
                />
                <View style={{ height: 2 }} />
              </View> */}

              {/* <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TouchableHighlight
                  onPress={() => {
                    this.setState({ hasDelivery: !this.state.hasDelivery });
                  }}
                >
                  <Selector
                    selected={this.state.hasDelivery}
                    text={!this.state.hasDelivery ? "Offer Delivery" : "This product can be delivered"}
                  />
                </TouchableHighlight>
              </View> */}
              <View
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 0,
                  marginBottom: 10
                }}
              >
                <View style={{ height: 30 }} />
                {!this.state.activityIndicatorAnimating ? <View>
                  <TouchableOpacity
                    onPress={this.state.action == "edit" ? this.callUpdate : this.callCreate}
                  >
                    <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 22, flexDirection: "row", width: 150, alignSelf: "center", alignItems: "center" }]} >
                      <Text style={{ fontSize: 20, fontWeight: "bold", color: "#000" }}>{this.state.action == "edit" ? "Update" : "Create"}</Text>
                    </View>
                  </TouchableOpacity>
                  {this.state.action == "edit" && <TouchableOpacity
                    onPress={this.callDelete}
                  >
                    <View style={[global.gStyles.buttonXLW, { borderWidth: 0, borderColor: "#f88", paddingVertical: 2.5, marginTop: 35, marginBottom: 10, flexDirection: "row", alignSelf: "center", alignItems: "center" }]} >
                      <Text style={{ fontSize: 20, color: "#722", fontWeight: "bold" }}>Delete Post</Text>
                    </View>
                  </TouchableOpacity>}
                </View> :
                  <ActivityIndicator
                    size="large"
                    color="#A8CF45"
                    style={{ alignSelf: "center" }}
                    animating={true}
                  />}
              </View>

              {/* <Modal
                animationType="slide"
                transparent={true}
                visible={this.state.modalAVisible}
                onRequestClose={() => { this.setState({ modalAVisible: false }); }}
              >
                <TouchableOpacity
                  onPress={() => this.setState({ modalAVisible: false })}
                  style={{ flex: 1 }}
                />
                <View
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <View
                    style={{
                      alignItems: "center", justifyContent: "center", backgroundColor: "#ccd", borderTopEndRadius: 15, borderTopLeftRadius: 15
                    }}
                  >
                    <View style={{ paddingTop: 25 }}>
                      <Text style={{ fontSize: 24, fontWeight: "bold", color: "#223" }}>{this.state.activeAModal == "aOpenness" ? "Select who can participate" : "Select Type"}</Text>
                    </View>
                    <View style={{ marginTop: 15, marginBottom: 5 }}>
                    </View>
                    {this.state.activeASelection.map((x) => {
                      return <TouchableOpacity key={x.type} onPress={() => { this.activateASelection(x) }}>
                        <ASelectorW ob={x} />
                      </TouchableOpacity>
                    })}
                    <View style={{ paddingTop: 25 }} />
                  </View>
                </View>
              </Modal> */}
              <Modal
                animationType="fade"
                visible={this.state.watchingYoutube}
                onRequestClose={() => {
                  this.setState({ watchingYoutube: false });
                }}
              >
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "flex-end",
                    height: "100%",
                    backgroundColor: "#000"
                  }}
                >
                  <WebView
                    originWhitelist={['*']}
                    style={{ width: sWidth, height: sHeight, marginVertical: ((sHeight - sWidth * 0.7) / 2) }}
                    source={{ uri: 'https://www.youtube.com/embed/' + this.state.playingYoutubeID }}
                    javaScriptEnabled={true}
                    domStorageEnabled={true}
                    useWebKit={true}
                  />
                  <TouchableOpacity
                    style={{
                      position: "absolute",
                      borderWidth: 0,
                      backgroundColor: "#000",
                      borderColor: "rgba(0,0,0,0.2)",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 35,
                      top: 65,
                      padding: 10,
                      left: 5,
                      height: 25,
                      borderRadius: 35
                    }}
                    onPress={() => {
                      this.setState({ watchingYoutube: false });
                    }}
                  >
                    <Image
                      style={{ width: 35, height: 35, opacity: 1 }}
                      source={require("../../images/icons/CancelWhite.png")}
                    />
                  </TouchableOpacity>
                </View>
              </Modal>
            </ScrollView>
          </TouchableWithoutFeedback>
        </SafeAreaView>
      );
    }
  }
}

class Member extends Component {
  state = {
    pictureURL: "", name: "", businessName: ""
  }

  async componentDidMount() {
    try {
      const profileDoc = await firebase
        .firestore()
        .collection("Profiles")
        .doc(this.props.profileID)
        .get()
      const profile = profileDoc.data()
      this.setState({
        pictureURL: profile.profilePictureURL || "",
        name: profile.profileName || "- No Name -",
        businessName: profile.profileNameBusiness || ""
      })
    } catch (e) {
      global.warn(e, "cdm_Member")
    }
  }

  renderCircle = filled => {
    if (filled) {
      return (
        <View style={{ width: 20, height: 20, borderColor: "#9999a4", backgroundColor: "#334", borderWidth: 0, borderRadius: 10 }}>
          <Text style={{ fontSize: 24, marginTop: -5, color: this.props.color || "#ccd" }}>✓</Text>
        </View>
      );
    } else {
      return (
        <View style={{ width: 20, height: 20, borderColor: "#ccd", borderWidth: 0.7, borderRadius: 10 }} />
      );
    }
  };

  render() {
    if (this.props.faceOnly) return (
      <View style={{ height: 35, width: 35, alignItems: "center", justifyContent: "center" }}>
        {this.state.pictureURL ? <Image
          style={{ width: 25, height: 25, borderRadius: 12.5, }}
          source={{ uri: this.state.pictureURL }}
        /> : <View style={{
          width: 25, height: 25, borderRadius: 12.5, backgroundColor: "#668"
        }} />}
      </View>
    )
    return (
      <View style={{ height: 35, flexDirection: "row", alignItems: "center", marginLeft: 10, alignSelf: "stretch" }}>
        <View style={{ width: 33, justifyContent: "center" }}>
          {this.renderCircle(this.props.selected)}
        </View>
        <View style={{ width: 33, justifyContent: "center" }}>
          {this.state.pictureURL ? <Image
            style={{ width: 25, height: 25, borderRadius: 12.5, }}
            source={{ uri: this.state.pictureURL }}
          /> : <View style={{
            width: 25, height: 25, borderRadius: 12.5, backgroundColor: "#668"
          }} />}
        </View>
        <View style={{ flex: 1 }}>
          <Text style={{ color: "#aab", fontSize: 13 }}>{this.state.name}</Text>
          <Text style={{ color: "#889", fontSize: 10 }}>{this.state.businessName}</Text>
        </View>
      </View>)
  }
}

//selection selected
class ASelector extends PureComponent {
  render() {
    let ob = {}
    this.props.selection.forEach((o) => {
      if (o.type == this.props.selected) ob = o
    })
    return (
      <View style={{
        alignItems: "center", justifyContent: "center", height: 85,
        flexDirection: "row", padding: 3,
        borderRadius: 7.5, width: "100%", backgroundColor: "#18181f",
        marginBottom: 7, marginTop: 3
      }}>
        <View style={{ alignItems: "flex-start", justifyContent: "center", flex: 1, paddingLeft: 10 }}>
          <Text style={{ color: "#ccd", fontSize: 18, fontWeight: "bold" }}>{ob.title}</Text>
          <Text style={{ color: "#99a", fontSize: 14 }}>{ob.description}</Text>
        </View>
        <View style={{ alignItems: "center", justifyContent: "center", width: 50 }}>
          <Text style={{ color: "#ccc", fontSize: 16, fontWeight: "bold", color: "#889" }}>▼</Text>
        </View>
      </View>
    );
  }
}
class ASelectorW extends PureComponent {
  render() {
    const ob = this.props.ob
    return (
      <View style={{
        alignItems: "center", justifyContent: "center", height: 100,
        flexDirection: "row", padding: 3,
        borderRadius: 7.5, width: "100%", backgroundColor: "#ccd",
        marginVertical: 5, borderTopWidth: 1, borderTopColor: "#aab"
      }}>
        <View style={{ alignItems: "flex-start", justifyContent: "center", flex: 1, paddingLeft: 10 }}>
          <Text style={{ color: "#223", fontSize: 18, fontWeight: "bold" }}>{ob.title}</Text>
          <Text style={{ color: "#778", fontSize: 14 }}>{ob.description}</Text>
        </View>
        <View style={{ alignItems: "center", justifyContent: "center", width: 50 }}>
          <Text style={{ color: "#333", fontSize: 20, fontWeight: "bold", color: "#99b" }}></Text>
        </View>
      </View>
    );
  }
}

class Selector extends Component {
  render() {
    let height =
      this.props.text.length < 45
        ? 30
        : 45 + (this.props.text.length - 45) * 0.33;
    let circle = filled => {
      if (filled) {
        return (
          <View
            style={{ width: 20, height: 20, borderColor: "#9999a4", backgroundColor: "#9999a4", borderWidth: 1, borderRadius: 10 }}
          />
        );
      } else {
        return (
          <View style={{ width: 20, height: 20, borderColor: "#556", borderWidth: 1, borderRadius: 10 }} />
        );
      }
    };

    return (
      <View style={{ height: height, flexDirection: "row", alignItems: "center" }}>
        {circle(this.props.selected)}
        <Text style={{ marginLeft: 10, color: "#999" }}>{this.props.text}</Text>
      </View>
    );
  }
}
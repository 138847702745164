import React from 'react';
import { StyleSheet, View, Image, Animated, TouchableWithoutFeedback, Text, TouchableOpacity } from 'react-native';
import * as Permissions from 'expo-permissions';
class MapScreen extends React.Component { render() { return (<View />) } }; // was not here before???? how could it work?


class FadeInView extends React.Component {
  state = {
    opacity: new Animated.Value(0),
  }

  componentDidMount = () => {
    Animated.timing(this.state.opacity, {
      toValue: 1,
      duration: 500,
      useNativeDriver: true,
    }).start();
  }

  render() {
    return (
      <Animated.View
        onLoad={this.onLoad}
        {...this.props}
        style={[
          {
            opacity: this.state.opacity,
            transform: [
              {
                scale: this.state.opacity.interpolate({
                  inputRange: [0, 3],
                  outputRange: [0.85, 1],
                })
              },
            ],
          },
          this.props.style,
        ]}
      />
    );
  }
}


export default class MapResults extends React.Component {

  state = {
    location: {
      coords: {
        longitude: 0,
        latitude: 0
      }
    },
    longitude: 3.41642,
    latitude: 6.45929,
    address: ' ',
    showAddresses: false,
    height: 50,
    markerMoved: false,
    fadeValue: new Animated.Value(0),
    profilePictureURL: "../../../images/badges/badgeQuality.png",
    badges: ['Quality'],
    markerName: "",
    markerAddress: "",
    showMarkerDetails: false
  };

  markers = [
    {
      id: 0,
      latlng: {
        latitude: 6.565152930572403,
        longitude: 3.368903137743473
      },
      title: "CK the Handyman",
      description: "I make epic stuff!",
      color: 'red'
    },
    {
      latlng: {
        latitude: 6.566312372170375,
        longitude: 3.366072066128254
      },
      title: "Sabalo Plumber",
      description: " I fix shit",
      color: 'blue'
    },
    {
      latlng: {
        latitude: 6.572260088564558,
        longitude: 3.369828499853611
      },
      title: "Biddy Lord of PlumbForteShire",
      description: " I fix shit",
      color: 'orange'
    },
    {
      latlng: {
        latitude: 6.5701817100029585,
        longitude: 3.363848514854908
      },
      title: "Denrele the fixer",
      description: "Bring me stuff that doesn't work",
      color: 'pink'
    },
    {
      latlng: {
        latitude: 6.57118326306385,
        longitude: 3.367142267525196
      },
      title: "MK the Builder",
      description: "I can work for free",
      color: 'pink'
    }]

  _getLocationAsync = async () => {
    /*  const badges = [{
       Quality: require("../../images/badges/badgeQuality.png"),
       Affordability: require("../../images/badges/badgeAffordability.png"),
       Professionality: require("../../images/badges/badgeProfessionality.png"),
       Timeliness: require("../../images/badges/badgeTimeliness.png")
     }];
    this.setState({badges:badges}); */


    let { status } = await Permissions.askAsync(Permissions.LOCATION);
    if (status !== 'granted') {
      //xxx alert('Location Permissions not granted!');
      return;
    }
    try {
      //console.log('this.props.addresstoMapScreen is: ',this.props.addresstoMapScreen);

      if (this.props.addresstoMapScreen == null || this.props.addresstoMapScreen == undefined) {
        let location = await Location.getCurrentPositionAsync({ accuracy: 5 });
        await this.setState({
          location: {
            coords: {
              latitude: location.coords.latitude,
              longitude: location.coords.longitude
            }
          }
        });
      } else {

        let coords = this.props.addresstoMapScreen
        await this.setState({
          location: {
            coords: {
              latitude: coords.latitude,
              longitude: coords.longitude
            }
          }
        });

      }
      //console.log('Your location coordinates are Points:', location.coords);

    } catch (err) {
      await new Promise(resolve => {
        setTimeout(resolve, 50);
      });
      alert(err);
      await new Promise(resolve => {
        setTimeout(resolve, 50);
      });
    };

  }

  async  componentDidMount() {

    try {
      this._getLocationAsync();
    } catch (err) {
      console.log('\n mapScreen.js error ', err);
    }
  }








  render() {
    const badges = {
      Quality: require("../../../images/badges/badgeQuality.png"),
      Affordability: require("../../../images/badges/badgeAffordability.png"),
      Professionality: require("../../../images/badges/badgeProfessionality.png"),
      Timeliness: require("../../../images/badges/badgeTimeliness.png")
    };
    const images = {
      Electronics: require("../../../images/categories/Electronics.png"),
      Home: require("../../../images/categories/Home.png"),
      Fashion: require("../../../images/categories/Fashion.png"),
      Cars: require("../../../images/categories/Cars.png"),
      Food: require("../../../images/categories/Food.png"),
      Beauty: require("../../../images/categories/Beauty.png"),
      Professionals: require("../../../images/categories/Professionals.png"),
      Repair_Refill: require("../../../images/categories/Repair_Refill.png"),
      Handyman: require("../../../images/categories/Handyman.png"),
      QuickJobs: require("../../../images/categories/QuickJobs.png"),
      Health: require("../../../images/categories/Health.png"),
      Art: require("../../../images/categories/Art.png"),
      Housing: require("../../../images/categories/Housing.png"),
      Travel: require("../../../images/categories/Travel.png"),
      Commercial: require("../../../images/categories/Commercial.png")
    };
    return (
      <View style={{ width: '100%', height: '100%' }}>

        <MapView
          style={styles.container}
          //provider = { MapView.PROVIDER_GOOGLE }
          customMapStyle={generatedMapStyle}
          showUserLocation={true}
          region={{
            latitude: this.state.location.coords.latitude,
            longitude: this.state.location.coords.longitude,
            latitudeDelta: this.props.mapDelta * 2,
            longitudeDelta: this.props.mapDelta * 2
          }}
        // latitudeDelta: 0.0922/5,
        // longitudeDelta: 0.0421/5}}
        >

          {this.props.resultArray.map(marker => (

            < MapView.Marker
              key={marker.xID}
              //title ={marker.title}
              //pinColor ={marker.color}
              //description = {marker.description}
              // image={require('../../images/badges/badgeQuality.png')}
              //coordinate={{latitude: 6.63, longitude:3.33}}
              coordinate={{ latitude: marker.locationWork._latitude, longitude: marker.locationWork._longitude }}
              onPress={
                () => {
                  //this.props.navigation.navigate("homeProfile", { profileID: marker.xID })
                  
                  this.props.markerDetailsDisplay(marker.name,marker.address)
                }
              }>
              <View>

                <FadeInView>
                  {/* <Image source ={require('../../images/badges/badgeQuality.png')} 
              style ={{height:50, width:50,  borderRadius:25, backgroundColor:'rgba(248, 224, 241, 0.64)',
              borderColor:'rgba(255, 255, 255, 0.64)', borderWidth:2, alignItems:'center', justifyContent:'center'}}/> */}
                  {/* <TouchableOpacity
                    style={{
                      marginTop: 5,
                      width: 50,
                      height: 50,
                      paddingRight: 8,
                      borderRadius: 21, backgroundColor: "white",
                      padding: 5, borderRadius: "50%",
                      zIndex: -1
                    }}
                    onPress={() => {
                      this.setState({ markerName: marker.name, markerAddress: marker.address, showMarkerDetails: this.state.showMarkerDetails == true ? false : this.state.showMarkerDetails == false ? true : false })
                    }}> */}

                    <View style={{ width: 42, marginRight: 8 }}>
                      {!!this.state.profilePictureURL &&
                        <View>{this.props.category1 ? <Image
                          style={{
                            marginTop: 5,
                            width: 50,
                            height: 50,
                            paddingRight: 8,
                            borderRadius: 21
                          }}
                          source={images[this.props.category1]} /> : <View
                            style={{
                              marginTop: 5,
                              width: 44,
                              height: 44,
                              paddingRight: 8,
                              borderRadius: 21,
                              backgroundColor: "white"
                            }}
                            source={images[this.props.category1]} />}
                          <Image
                            style={{
                              marginTop: this.props.category1 ? -46 : -43,
                              marginLeft: this.props.category1 ? 4 : 1,
                              width: 42,
                              height: 42,
                              paddingRight: 8,
                              borderRadius: 21
                            }}
                            source={{ uri: marker.profilePictureURL }} /></View>
                      }
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: -5
                        }}
                      >

                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: 4
                          }}
                        >
                          {(marker.infoBadges || [""]).map(badge => {
                            return (
                              <Image
                                key={"b_" + badge}
                                style={{
                                  width: 10,
                                  height: 10
                                }}
                                source={badges[badge]}
                              />
                            );
                          })}
                        </View>
                      </View>
                    </View> 
                </FadeInView>
              </View>
            </MapView.Marker>
          ))}
          <MapView.Marker
            //coordinate = { {latitude: this.state.location.coords.latitude, longitude:this.state.location.coords.longitude}}
            title='you'
            pinColor='green'
            description="your location"
            key={"centerMarker"}
            coordinate={this.state.location.coords}
          >
          </MapView.Marker>

          
          {/* <View style={{ backgroundColor: "white", padding: 20, borderRadius: 20, width: "80%", position: "absolute", bottom: 20 }}>
            <View id="facility_type" style={{ borderColor: '#3C3C3C', padding: 5, borderRadius: 5, width: "50%" }}>
              <Text style={{ paddingLeft: 10, color: "grey", fontSize: 10, }}>{this.state.markerName || ""}</Text>
              <Text style={{ paddingLeft: 10, color: "grey", fontSize: 10, }}>{this.state.markerAddress || ""}</Text>
            </View>
          </View> */}
        </MapView>

      </View >
    );
  }
}

const styles = ({
  container: {
    flex: 1,
    //backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },

  animatedView: {
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: 'rgba(248, 224, 241, 0.64)',
    borderColor: 'rgba(255, 255, 255, 0.64)',
    borderWidth: 2,
    alignItems: 'center',
    justifyContent: 'center',
  }
});


const generatedMapStyle = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#242f3e"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#746855"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#242f3e"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#d59563"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#d59563"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#263c3f"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#6b9a76"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#38414e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#212a37"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9ca5b3"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#746855"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#1f2835"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#f3d19c"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#2f3948"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#d59563"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#17263c"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#515c6d"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#17263c"
      }
    ]
  }
];



import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TextInput,
  TouchableOpacity,
  SafeAreaView,
  ActivityIndicator,
  Platform,
  Linking,
  Alert,
  Dimensions,
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
import { NavigationEvents } from "react-navigation";
import BlogPostPreview from "../../Home/Apps/shared/BlogPostPreview";
import { AntDesign } from "@expo/vector-icons";
const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;

export default class MeBlogEditScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Edit Blog",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "white",
      shadowOpacity: 1,
    },
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    this.unsubscribeFirestore = () => {
      return;
    };
    this.unsubscribeFirestore2 = () => {
      return;
    };
  }
  state = {
    blogData: {},
    blogPost: [],
    active: [],
    setStatusIndicator: false,
    blogPostsInOrder: true,
    aaOrder: false,
  };

  async componentDidMount() {
    try {
      if (!this.props.navigation.getParam("blogID", null))
        return alert("Oops - something went wrong");
      this.loadBlog();
    } catch (error) {
      global.warn?.(error, "blogMount");
    }
  }
  componentWillUnmount() { }

  loadBlog = async () => {
    try {
      if (!this.props.navigation.getParam("blogID", null))
        return alert("Oops - something went wrong");
      await global.fetchIdToken()
      const r3 = await fetch(global.cloudFunctionURL + "blog", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isWeb:true,
          action: "getBlog",
          // profileID: global.myProfileID,
          myUID: global.myUID,
          blogID: this.props.navigation.getParam("blogID", null),
          idToken: global.idToken,
        }),
      });
      const r4 = await r3.json();
      console.log(r4)

      this.setState({
        blogData: r4.response.blog,
      });

      const r = await fetch(global.cloudFunctionURL + "findGroups", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isWeb:true,
          isWeb: true,
          type: "groupIDs",
          groupIDs: r4.response.blog.chatGroupIDs,
          myUID: global.myUID,
          idToken: global.idToken,
          includeDeactivated: true,
        }),
      });
      const r2 = await r.json();
      // console.log("blogPost Data", r2);
      await this.setState({
        blogPost: r2.groups,
      });
      // console.log("blog data", this.state.blogPost);

      console.log("response received");
    } catch (error) {
      global.warn?.(error, "loadBlogPost");
    }
  };
  orderBlogPosts = async () => {
    try {
      this.setState({ aaOrder: true })
      const r = await fetch(global.cloudFunctionURL + "blog", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isWeb:true,
          action: "orderBlogPosts",
          blogID: this.state.blogData.xID,
          idToken: global.idToken,
          myUID: global.myUID,
          chatGroupIDs: this.state.blogPost.map(bp => { return bp.xID })
        }),
      });
      const r2 = await r.json();
      this.setState({ aaOrder: false })
      console.log("the  new response", r2);

      if (r2.msg == "SUCCESS") {
        this.setState({ blogPostsInOrder: true })
        await this.loadBlog();
      } else alert("Oops - something went wrong")
    } catch (error) {
      this.setState({ aaOrder: false })
      global.warn?.(error, "loadBlogPost");
    }
  };

  moveUp = (xID) => {
    let arr = global.duplicate(this.state.blogPost);

    let index = arr.findIndex((e) => e.xID == xID);

    if (index > 0) {
      let temp = arr[index];
      arr[index] = arr[index - 1];

      arr[index - 1] = temp;

      this.setState({ blogPost: arr, blogPostsInOrder: false });
    }
  };
  moveDown = (xID) => {
    let arr = global.duplicate(this.state.blogPost);
    console.log("beginin ", arr);
    let index = arr.findIndex((e) => e.xID == xID);
    console.log("index", index);
    if (index !== -1 && index < arr.length - 1) {
      let temp = arr[index];
      arr[index] = arr[index + 1];
      console.log("middle ", arr);
      arr[index + 1] = temp;
      console.log("end ", arr);
      this.setState({ blogPost: arr, blogPostsInOrder: false });
    }
  };

  activatePost = async (xID) => {
    this.setState({
      setStatusIndicator: true,
    });
    try {
      const r = await fetch(global.cloudFunctionURL + "editGroup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isWeb:true,
          action: "reactivate",
          chatGroupID: xID,
          idToken: global.idToken,
          myUID: global.myUID,
        }),
      });
      const r2 = await r.json();
      console.log("activation response", r2);
      if (r2.msg == "SUCCESS") {
        await this.loadBlog();
      } else alert("Oops - something went wrong")
      this.setState({
        setStatusIndicator: false,
      });
    } catch (error) {
      global.warn?.(error, "loadBlogPost");
    }
  };

  pausePost = async (xID) => {
    this.setState({
      setStatusIndicator: true,
    });
    try {
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "editGroup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isWeb:true,
          action: "deactivate",
          chatGroupID: xID,
          idToken: global.idToken,
          myUID: global.myUID,
        }),
      });
      const r2 = await r.json();
      console.log("pause response", r2);
      if (r2.msg == "SUCCESS") {
        await this.loadBlog();
      } else alert("Oops - something went wrong")
      this.setState({
        setStatusIndicator: false,
      });
    } catch (error) {
      global.warn?.(error, "loadBlogPost");
    }
  };

  renderAbout = () => {
    const amOwner = this.state.blogData?.ownerProfileID == global.myProfileID;
    const amManager = this.state.blogData?.editorProfileIDs?.includes(
      global.myProfileID
    );
    return (
      <View style={{ marginHorizontal: 15 }}>
        <Text
          style={{
            color: "white",
            fontWeight: "bold",
            fontFamily: "ProximaNovaA-Bold",
            fontSize: 20,
            marginBottom: 10,
            marginTop: 25,
          }}
        >
          Blog editors
        </Text>
        {/* *** Owner *** */}
        {this.state.blogData?.ownerProfileID && (
          <Member
            profileID={this.state.blogData.ownerProfileID}
            key={"OWNER" + (amOwner ? 2 : 1)}
            editable={true}
            isManager={true}
            amManager={amManager}
            isOwner={true}
            amOwner={amOwner}
            navigation={this.props.navigation}
            blogID={this.state.blogData?.xID}
            onUpdate={this.loadBlog}
          />
        )}
        {/* *** Editors *** */}
        {!!this.state.blogData?.editorProfileIDs &&
          this.state.blogData?.editorProfileIDs.map((pID) => {
            const isOwner = this.state.blogData?.ownerProfileID == pID;
            const isManager = this.state.blogData?.editorProfileIDs?.includes(
              pID
            );
            if (!isOwner)
              return (
                <Member
                  profileID={pID}
                  key={
                    pID +
                    (isOwner ? 2 : 1) +
                    (isManager ? 2 : 1) +
                    (amOwner ? 2 : 1) +
                    (amManager ? 2 : 1) +
                    2
                  }
                  editable={true}
                  isManager={isManager}
                  amManager={amManager}
                  isOwner={isOwner}
                  amOwner={amOwner}
                  navigation={this.props.navigation}
                  blogID={this.state.blogData?.xID}
                  onUpdate={this.loadBlog}
                />
              );
          })}
        <TouchableOpacity
          onPress={() => {
            let route = "meSelectContact";
            if (this.props.navigation.state.routeName.startsWith("home"))
              route = "homeSelectContact";
            if (this.props.navigation.state.routeName.startsWith("chat"))
              route = "messageSelectContact";
            if (this.props.navigation.state.routeName.startsWith("message"))
              route = "messageSelectContact";
            if (this.state.blogData?.xID)
              this.props.navigation.navigate(route, {
                input: this.state.profileID,
                purpose: "AddBlogEditor",
                //nextScreen: this.props.navigation.state.routeName,
                blogID: this.state.blogData?.xID,
                blogEditorProfileIDs:
                  this.state.blogData?.editorProfileIDs || [],
              });
          }}
          style={[
            global.gStyles.buttonXL,
            { margin: 3, marginTop: 15, width: 150, borderRadius: 12 },
          ]}
        >
          <Text
            style={[
              global.gStyles.buttontXL,
              {
                fontWeight: "bold",
                fontFamily: "ProximaNovaA-Bold",
                fontSize: 15,
              },
            ]}
          >
            + Add editor
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  render() {
    return (
      <SafeAreaView
        style={{
          // flex: 1,
          // backgroundColor: "#282828",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          width: SCREEN_WIDTH,
          backgroundColor: "#000",
        }}
      >
        <View
          style={{
            backgroundColor: "#282828",
            borderBottomColor: "#555",
            borderBottomWidth: 1,
            ...Platform.select({
              ios: { height: 55 },
              android: { height: 75 },
            }),
          }}
        />
        <ScrollView>
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              width: SCREEN_WIDTH,
            }}
          >
            <Image
              style={{
                width: SCREEN_WIDTH * 0.9,
                height: 150,
                paddingRight: 8,
                borderRadius: 8.5,
              }}
              source={{
                uri: this.state.blogData.blogPictureURL,
              }}
            />
            {/* <TouchableOpacity
              style={{
                borderRadius: 14,
                paddingLeft: 6,
                height: 38,
                justifyContent: "center",
                alignItems: "center",
                width: 38,
                bottom: 75,
              }}
            >
              <Text style={{ color: "#333", fontSize: 28 }}>{"▶️"}</Text>
            </TouchableOpacity> */}

            <View style={{ margin: 10, padding: 10 }}>
              <Text
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontFamily: "ProximaNovaA-Bold",
                  fontSize: 20,
                  marginVertical: 5,
                }}
              >
                {this.state.blogData.blogTitle}
              </Text>

              {typeof this.state.blogData.nOfSubscribers == "number" && <Text
                style={{
                  color: "#909497",
                  marginVertical: 5,
                  fontFamily: "ProximaNovaA-Regular",
                  fontSize: 10,
                }}
              >
                {this.state.blogData.nOfSubscribers} Subscriber{this.state.blogData.nOfSubscribers !== 1 && "s"}
              </Text>}
              <Text
                style={{
                  color: "#909497",
                  marginVertical: 4,
                  fontFamily: "ProximaNovaA-Regular",
                  fontSize: 10,
                }}
              >
                {this.state.blogData.blogDescription}
              </Text>
            </View>
            <View
              style={{
                width: SCREEN_WIDTH,
                // padding: 3,
                // margin: 2,
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <TouchableOpacity
                style={{
                  width: "40%",

                  height: 50,
                  backgroundColor: "#A9D046",
                  borderRadius: 12,
                  padding: 10,

                  alignItems: "center",
                  justifyContent: "center",
                }}
                onPress={async () => {
                  if (!this.state.blogData?.xID) await global.timeout(1000);
                  if (!this.state.blogData?.xID) await global.timeout(1000);
                  if (!this.state.blogData?.xID) await global.timeout(1000);
                  if (!this.state.blogData?.xID) await global.timeout(1000);
                  if (!this.state.blogData?.xID)
                    return alert(
                      "Something went wrong - go back and try again"
                    );
                  this.props.navigation.navigate("meBlogPostEdit", {
                    blog: this.state.blogData,
                    action: "new",
                  });
                }}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    fontFamily: "ProximaNovaA-Bold",
                    fontSize: 15,
                  }}
                >
                  + NEW POST
                </Text>
              </TouchableOpacity>
              {!!this.state.blogData && this.state.blogData != {} && (
                <TouchableOpacity
                  style={{
                    width: "40%",
                    height: 50,
                    // backgroundColor: "#A9D046",
                    borderRadius: 12,
                    padding: 10,
                    borderColor: "#A9D046",
                    borderWidth: 0.5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    this.props.navigation.navigate("meBlogNew", {
                      action: "edit",
                      blog: this.state.blogData,
                    });
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontFamily: "ProximaNovaA-Bold",
                      fontSize: 15,
                      color: "#A9D046",
                    }}
                  >
                    EDIT BLOG
                  </Text>
                </TouchableOpacity>
              )}
            </View>
            {!this.state.blogPost && (
              <ActivityIndicator
                style={{
                  alignSelf: "center",
                  color: "white",
                }}
              />
            )}

            {this.state.blogPost &&
              this.state.blogPost.map((item) => {
                return (
                  <View
                    key={item.xID}
                    style={{
                      minHeight: 90,
                      borderBottomWidth: 0,
                      borderBottomColor: "#606072",
                      alignItems: "center",
                      width: SCREEN_WIDTH * 0.9,

                      borderRadius: 6,
                      backgroundColor: "#242424",
                      // padding: 10,
                      margin: 10,
                      alignSelf: "center",
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        width: SCREEN_WIDTH,

                        // margin: 10,
                        // borderRadius: 12,
                        // padding: 15,
                        // alignItems: "center",
                      }}
                      onPress={() => {
                        global.navigateChatGroup(this.props.navigation, {
                          group: item,
                          isBlogPost: true,
                        })
                      }}
                    >
                      <BlogPostPreview
                        edit
                        blog={this.state.blogData}
                        post={item}
                        navigation={this.props.navigation}
                        addShuffle={true}
                        activatePost={() => this.activatePost(item.xID)}
                        pausePost={() => this.pausePost(item.xID)}
                      />
                    </TouchableOpacity>
                    {item.aStatus === "LIVE" && (
                      <View
                        style={{
                          borderColor: "#2ECC71",
                          borderWidth: 1,
                          padding: 5,
                          marginBottom: 10,
                          width: 50,
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 10,
                          alignSelf: "flex-end",
                          marginHorizontal: 20,
                        }}
                      >
                        {this.state.setStatusIndicator == true ? (
                          <ActivityIndicator
                            style={{
                              alignSelf: "center",
                              color: "#2ECC71",
                            }}
                          />
                        ) : (
                          <Text
                            style={{
                              color: "#2ECC71",
                            }}
                          >
                            Live
                          </Text>
                        )}
                      </View>
                    )}

                    {item.aStatus === "DEACTIVATED" && (
                      <View
                        style={{
                          borderColor: "#EC7063",
                          borderWidth: 1,
                          padding: 5,
                          marginBottom: 10,
                          width: 75,
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 10,
                          alignSelf: "flex-end",
                          marginHorizontal: 20,
                        }}
                      >
                        {this.state.setStatusIndicator == true ? (
                          <ActivityIndicator
                            style={{
                              alignSelf: "center",
                              color: "#EC7063",
                            }}
                          />
                        ) : (
                          <Text
                            style={{
                              color: "#EC7063",
                            }}
                          >
                            paused
                          </Text>
                        )}
                      </View>
                    )}

                    {/* <View style={{borderColor: "#EC7063", borderWidth:1 ,padding:5, marginBottom:10, width:100, alignItems:'center', justifyContent:'center', borderRadius:10}}>
                      <Text style ={{
                        color:"#EC7063"
                      }}>
                        Paused
                      </Text>
                    </View> */}

                    <View
                      style={{
                        borderColor: "#979A9A",
                        borderWidth: 0.5,
                        width: "90%",
                        marginTop: -5,
                        paddingTop: -5,
                      }}
                    />
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        paddingBottom: 10,
                      }}
                    >
                      <TouchableOpacity
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-around",
                          // marginVertical: 15,
                          padding: 10,
                        }}
                        onPress={() => {
                          this.moveUp(item.xID);
                        }}
                      >
                        <AntDesign name="arrowup" size={12} color="#2ECC71" />

                        <Text
                          style={{
                            color: "#2ECC71",
                            fontSize: 10,
                            fontFamily: "ProximaNovaA-Regular",
                          }}
                        >
                          Move Up
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-around",
                          // marginVertical: 15,
                          padding: 10,
                        }}
                        onPress={() => {
                          this.moveDown(item.xID);
                        }}
                      >
                        <AntDesign name="arrowdown" size={12} color="#EC7063" />

                        <Text
                          style={{
                            color: "#EC7063",
                            fontSize: 10,
                            fontFamily: "ProximaNovaA-Regular",
                          }}
                        >
                          Move Down
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                );
              })}
          </View>

          {this.renderAbout()}
        </ScrollView>

        {!this.state.blogPostsInOrder && <View style={{ alignSelf: "center", paddingVertical: 5 }}>
          {!this.state.aaOrder ? <TouchableOpacity
            onPress={() => {
              this.orderBlogPosts();
            }}
            style={[
              global.gStyles.buttonXL,
              { margin: 3, marginTop: 15, width: 150, borderRadius: 12 },
            ]}
          >
            <Text
              style={[
                global.gStyles.buttontXL,
                {
                  fontWeight: "bold",
                  fontFamily: "ProximaNovaA-Bold",
                  fontSize: 15,
                },
              ]}
            >
              Reorder Blog
              </Text>
          </TouchableOpacity> :
            <ActivityIndicator
              size="small"
              color="#ccccd5"
              animating={true}
            />}
        </View>}

        <NavigationEvents
          onDidFocus={async (payload) => {
            console.log("Reloading Content");
            this.loadBlog();
          }}
        />
      </SafeAreaView >
    );
  }
}

class Member extends Component {
  state = {
    pictureURL: "",
    name: "",
    businessName: "",
    showEditButtons: false,
    aa: false,
  };

  async componentDidMount() {
    try {
      const profileDoc = await firebase
        .firestore()
        .collection("Profiles")
        .doc(this.props.profileID)
        .get();
      const profile = profileDoc.data();
      let name = profile.profileName || "- No Name -";
      if (this.props.isOwner) name = name + " 👑";
      this.setState({
        pictureURL: profile.profilePictureURL || "",
        name,
        businessName: profile.profileNameBusiness || "",
      });
    } catch (e) {
      global.warn(e, "cdm_Member");
    }
  }

  editMember = async (action) => {
    let msg = "";
    if (action == "removeEditor")
      msg =
        "This will remove " +
        (this.state.name || "this person") +
        " as an editor.";
    else if (action == "transferOwnership")
      msg =
        "This will transfer blog ownership from you to " +
        (this.state.name || "this member") +
        ".";
    else return alert("Internal error");

    Alert.alert(
      "Sure?",
      msg,
      [
        {
          text: "Cancel",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel",
        },
        {
          text: "Yes",
          onPress: () => {
            this.editMemberExecution(action);
          },
        },
      ],
      { cancelable: false }
    );
  };
  editMemberExecution = async (action) => {
    try {
      this.setState({ aa: true });
      await global.fetchIdToken()
      const r = await fetch(global.cloudFunctionURL + "blog", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isWeb:true,
          action,
          targetProfileID: this.props.profileID,
          blogID: this.props.blogID,
          myUID: global.myUID,
          idToken: global.idToken,
        }),
      });
      const r2 = await r.json();
      if (r2.msg === "SUCCESS") {
        this.props.onUpdate();
        await global.timeout(1000);
      } else alert("Action not successful.");
      this.setState({ aa: false, showEditButtons: false });
    } catch (err) {
      this.setState({ aa: false });
      global.warn(err, "blog_editMember");
      alert("Action not successful");
    }
  };

  render() {
    return (
      <View
        style={{
          ...(this.state.showEditButtons && {
            backgroundColor: "#272727",
            borderRadius: 5,
            paddingTop: 5,
          }),
        }}
      >
        <TouchableOpacity
          onPress={() => {
            global.navigateProfile(this.props.navigation, this.props.profileID);
          }}
        >
          <View
            style={{
              height: 35,
              flexDirection: "row",
              alignItems: "center",
              marginLeft: 10,
              alignSelf: "stretch",
            }}
          >
            <View style={{ width: 33, justifyContent: "center" }}>
              {this.state.pictureURL ? (
                <Image
                  style={{
                    width: 25,
                    height: 25,
                    borderRadius: 12.5,
                  }}
                  source={{
                    uri: this.state.pictureURL,
                  }}
                />
              ) : (
                <View
                  style={{
                    width: 25,
                    height: 25,
                    borderRadius: 12.5,
                    backgroundColor: "#668",
                  }}
                />
              )}
            </View>
            <View style={{ flex: 1 }}>
              <Text style={{ color: "#aab", fontSize: 13 }}>
                {this.state.name}
              </Text>
              <Text style={{ color: "#889", fontSize: 10 }}>
                {this.state.businessName}
              </Text>
            </View>
            {this.props.amOwner && !this.props.isOwner && (
              <View style={{ width: 65 }}>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({
                      showEditButtons: !this.state.showEditButtons,
                    });
                  }}
                  style={{
                    alignSelf: "center",
                    width: 55,
                    borderWidth: 1,
                    height: 25,
                    borderRadius: 13,
                    borderColor: "#aab",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: this.state.showEditButtons
                      ? "#272727"
                      : "#000",
                  }}
                >
                  <Text style={{ color: "#aab", fontSize: 12 }}>Edit</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </TouchableOpacity>
        {this.state.showEditButtons &&
          (this.state.aa ? (
            <View
              style={{
                height: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "stretch",
                marginBottom: 7,
                marginHorizontal: 3,
              }}
            >
              <ActivityIndicator
                size="small"
                color="#ccccd5"
                animating={true}
              />
              <Text style={[global.gStyles.buttontXW, { marginLeft: 8 }]}>
                Updating
              </Text>
            </View>
          ) : (
            <View
              style={{
                height: 30,
                flexDirection: "row",
                justifyContent: this.state.aa ? "center" : "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                marginBottom: 7,
                marginHorizontal: 3,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.editMember("removeEditor");
                }}
                style={[global.gStyles.buttonXW, { margin: 3, minWidth: 85 }]}
              >
                <Text style={[global.gStyles.buttontXW, { fontWeight: "bold" }]}>
                  {" "}
                  ❌ Remove{" "}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  this.editMember("transferOwnership");
                }}
                style={[global.gStyles.buttonXW, { margin: 3, minWidth: 85 }]}
              >
                <Text style={global.gStyles.buttontXW}>Make Owner</Text>
              </TouchableOpacity>
            </View>
          ))}
      </View>
    );
  }
}

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform,
  Alert,
  Dimensions,
  Linking,
} from "react-native";
import * as Font from "expo-font";
import { LinearGradient } from "expo-linear-gradient";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { SvgCss } from "react-native-svg";
import firebase from "firebase";
import "firebase/firestore";
import BottomMenu from "../../../shared/BottomMenu";
import Moment from "react-moment";
import { withNavigation } from "react-navigation";

const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;

class BlogPreview extends Component {
  state = {
    blogData: {},
    blogPost: [],
    loadingBlog: true,
    loadingPosts: true,
  };
  async componentDidMount() {
    //console.log("params ", this.props);
    this.getBlogData();
  }
  getBlogData = async () => {
    try {
      await global.fetchIdToken()
      const r3 = await fetch(global.cloudFunctionURL + "blog", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isWeb:true,
          action: "getBlog",
          // profileID: global.myProfileID,
          myUID: global.myUID,
          blogID: this.props.blogID,
          idToken: global.idToken,
        }),
      });
      const r4 = await r3.json();
      this.setState({ loadingBlog: false });

      if (r4.msg !== "SUCCESS") {
        console.warn("ERROR! r4",)
        console.log("ERROR! r4:", r4);
        return
      }
      await this.setState({
        blogData: r4.response.blog,
      });

      const r = await fetch(global.cloudFunctionURL + "findGroups", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isWeb:true,
          isWeb: true,
          type: "groupIDs",
          groupIDs: this.state.blogData.chatGroupIDs,
          myUID: global.myUID,
          idToken: global.idToken,
        }),
      });
      const r2 = await r.json();
      this.setState({ loadingPosts: false });

      await this.setState({
        blogPost: r2.groups,
      });
      //console.log("response", this.state.blogPost);
    } catch (error) {
      global.warn?.(error, "blogMountD");
    }
  };

  render() {
    if (this.state.loadingPosts) return (<View style={{ height: 340, alignItems: "center", justifyContent: "center" }}>
      <ActivityIndicator size="small" color="#aab" />
    </View>)

    return this.state.blogPost.length === 0 ? (
      <View />
    ) : (
      <View
        style={{
          minHeight: SCREEN_HEIGHT * 0.2,
          borderBottomWidth: 0,
          borderBottomColor: "#606072",
          alignItems: "center",
          width: SCREEN_WIDTH * 0.95,

          margin: 6,
          borderRadius: 6,
          backgroundColor: "#242424",
          padding: 5,
          // marginBottom: 20,
          alignSelf: "center",
        }}
      >
        <View
          style={{
            borderBottomColor: "red",
            borderBottomWidth: 0.3,
            width: "95%",
            padding: 10,
            marginBottom: 3,
          }}
        >
          <Text
            style={{
              color: "white",
              fontSize: 15,
              fontFamily: "ProximaNovaA-Bold",
            }}
          >
            {this.props.title}
          </Text>
        </View>

        {this.state.blogPost &&
          this.state.blogPost.slice(0, 3).map((item) => {
            let milliseconds = 0;
            let timeCreated = 0;

            milliseconds = item?.timeCreated?._seconds
              ? item?.timeCreated?._seconds * 1000 +
              item?.timeCreated?._nanoseconds / 1000000
              : item?.timeCreated?.seconds * 1000 +
              item?.timeCreated?.nanoseconds / 1000000;
            if (milliseconds > 0) timeCreated = new Date(milliseconds);
            return (
              <TouchableOpacity
                key={item.xID}
                style={{
                  width: SCREEN_WIDTH * 0.9,
                  alignItems: "center",
                  paddingHorizontal: 5,
                  paddingBottom: 5,
                  borderBottomColor: "#000",
                  borderBottomWidth: 0.3,
                  marginBottom: 5,
                }}
                onPress={() => {
                  global.navigateChatGroup(this.props.navigation, {
                    group: item,
                    isBlogPost: true,
                    blog: this.state.blogData || {}
                  })
                }}
              >
                <View
                  style={{
                    width: "90%",
                    flexDirection: "row",
                    marginTop: 5,
                    paddingTop: 5,
                    justifyContent: "space-between",
                  }}
                >
                  <Image
                    style={{
                      width: SCREEN_WIDTH * 0.33,
                      height: 53,
                      paddingRight: 8,
                      borderRadius: 8.5,
                    }}
                    source={{
                      uri: item.groupPictureURL
                    }}
                  />
                  <View style={{ width: "50%" }}>
                    <Text
                      style={{
                        color: "white",
                        fontSize: 12,
                        fontFamily: "ProximaNovaA-Bold",
                      }}
                    >
                      {item.groupTitle}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            );
          })}
        <TouchableOpacity
          onPress={() => {
            this.props.navigation.navigate("homeBlog", {
              blogID: this.props.blogID,
              blogTitle: this.props.title
            });
          }}
        >
          <Text
            style={{
              color: "white",
              fontSize: 15,
              fontFamily: "ProximaNovaA-Bold",
              padding: 10,
            }}
          >
            v   See More
            </Text>
        </TouchableOpacity>
      </View>
    );
  }
}
export default withNavigation(BlogPreview);

import React, { Component, PureComponent } from "react";
import {
  Text,
  View,
  ScrollView,
  TextInput,
  Image,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
  ActivityIndicator,
  Platform
} from "react-native";
import NetInfo from '@react-native-community/netinfo';
import Moment from "react-moment";
import { NavigationEvents } from "react-navigation";
import firebase from "firebase";
import "firebase/firestore";

//import Post from "../../Home/Post/Post";


// class SearchBox extends Component {
//   state = {};
//   searchChats = searchString => {
//     alert("Search not yet implemented");
//   };
//   render() {
//     return (
//       <View style={{ padding: 5, alignItems: "center", marginTop: 0 }}>
//         <TextInput
//           style={{
//             alignSelf: "stretch",
//             height: 50,
//             marginBottom: 0,
//             marginTop: 0,
//             color: "#FFFFFF",
//             borderBottomColor: "#FFFFFF",
//             borderBottomWidth: 1
//           }}
//           placeholder="Search messages"
//           placeholderTextColor="#008866"
//           //textAlign= "center"
//           underlineColor={"transparent"}
//           returnKeyType="search"
//           onChangeText={string => this.searchChats(string)}
//         />
//       </View>
//     );
//   }
// }

class Post extends PureComponent {
  state = {
    title: "***** *****",
    text: "***** ***** ***** ***** *****",
    pictureURL: null,
    price: -1,
    priceType: "NONE",
    category1: "++++++++",
    category2: "++++++++",
    location: { address: "", coords: { latitude: 6.6, longitude: 3.3 } },
    likingProfileIDs: [],
    nOfSwipesRight: 0,
    nOfViews: 0,
    nOfClicks: 0,
    nOfSaves: 0,
    nOfUnreadSwipesRight: 0,
    status: "#######",
    statusReason: "",
    statusReasonText: "",
    nOfUnreadItems: 0,
    creationDate: null,
    hasUnreadItems: false,
    isConnected: true,
    showDetailed: false
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    global.messageNotifications = {};
    if (!global.myUID) {
      await global.setGlobals();
    }
    this.unsubscribeFirestore = firebase
      .firestore()
      .collection("Posts")
      .doc(this.props.postID)
      .onSnapshot(async p => {
        //let connected = await NetInfo.isConnected;
        if (true) {
          this.updatePost(p);
        }
      });

    // NetInfo.isConnected.addEventListener(
    //   "connectionChange",
    //   this.handleConnectivityChange
    // );
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  updatePost = async postSnapshot => {
    const post = postSnapshot.data();

    this.setState({
      title: post.postTitle,
      text: post.postText,
      pictureURL: post.postPictureURL,
      price: post.postPrice,
      priceType: post.postPriceType,
      category1: post.assignedCategory1,
      category2: post.assignedCategory2,
      location: {
        address: post.assignedLocationAddress,
        coords: {
          latitude: post.assignedLocationGeo._lat,
          longitude: post.assignedLocationGeo._long
        }
      },
      likingProfileIDs: post.likingProfileIDs,
      nOfSwipesRight: post.nOfSwipesRight,
      nOfViews: post.nOfViews,
      nOfClicks: post.nOfClicks,
      nOfSaves: post.nOfSaves,
      nOfUnreadSwipesRight: post.nOfSwipesRightSeen - post.nOfSwipesRight,
      status: post.systemStatus,
      statusReason: post.systemStatusReason,
      statusReasonText: post.systemStatusReasonText,
      nOfUnreadItems: post.systemStatusSeen ? 0 : 1,
      creationDate: post.timeCreated.toDate(),
      hasUnreadItems: !post.systemStatusSeen
    });
    this.props.onChange(post.timeCreated);
  };

  render() {
    B = props => <Text style={{ fontWeight: "bold" }}>{props.children}</Text>; //just for reference (delete?)

    const status = () => {
      switch (this.state.status) {
        case "PENDING_APPROVAL":
          return "Pending Approval";
          break;
        case "ACTIVE":
          return "Active";
          break;
      }
    };

    const calendarStrings = {
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      lastDay: "[Yesterday]",
      lastWeek: "dddd",
      sameElse: "DD-MM-YYYY"
    };

    return (
      <TouchableOpacity
        onPress={() => {
          this.setState({ showDetailed: true });
        }}
        onLongPress={() => {
          // if (global.devMode) {
          //   alert("DEV MODE, chatID: " + this.props.chatID);
          // }
        }}
      >
        <View
          style={[
            {
              flexDirection: "row",
              flex: 1,
              height: 85,
              //width: 100,
              backgroundColor: "black",
              alignItems: "center",
              paddingLeft: 11,
              paddingRight: 11,
              shadowOpacity: 0.5,
              shadowOffset: { width: 0, height: 3 },
              borderBottomColor: "#555555",
              borderWidth: 1
            },
            this.state.hasUnreadItems && {
              backgroundColor: "#A8CF45",
              height: 85
            }
          ]}
        >
          <View style={{ width: 50, marginRight: 8 }}>
            {!!this.state.pictureURL && (
              <Image
                style={{
                  width: 50,
                  height: 50,
                  paddingRight: 8,
                  borderRadius: 5
                }}
                source={{
                  uri: this.state.pictureURL
                }}
              />
            )}
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 3
              }}
            >
              <Image
                style={{
                  width: 13,
                  height: 10,
                  marginRight: 3,
                  borderRadius: 3
                }}
                source={require("../../images/icons/Heart.png")}
              />
              <Text style={{ color: "white", fontSize: 10 }}>
                {this.state.nOfSwipesRight}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "column",
              flex: 1,
              paddingHorizontal: 8
            }}
          >
            <Text
              style={[
                { fontSize: 14, color: "white" },
                this.state.hasUnreadItems && {
                  fontWeight: "bold",
                  color: "black"
                }
              ]}
            >
              {this.state.title}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              paddingHorizontal: 8,
              alignItems: "baseline",
              justifyContent: "flex-end"
            }}
          >
            <Text
              style={[
                { fontSize: 10, color: "white", fontStyle: "italic" },
                this.state.hasUnreadItems && {
                  color: "black"
                }
              ]}
            >
              <Moment element={Text} calendar={calendarStrings}>
                {this.state.creationDate}
              </Moment>
            </Text>
            <Text
              style={[
                { fontSize: 10, color: "white", fontStyle: "italic" },
                this.state.hasUnreadItems && {
                  color: "black"
                }
              ]}
            >
              {status()}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

export default class MePostsScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "My Posts",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };
  state = {
    postIDs: [],
    loading: true
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    try {
      if (!global.myUID) {
        await global.setGlobals();
      }
      this.unsubscribeFirestore = await firebase
        .firestore()
        .collection("Profiles")
        .doc(global.myProfileID)
        .onSnapshot(async s => {
          //let connected = await NetInfo.isConnected;
          if (true) {
            this.updatePosts(s);
          }
        });
      global.loadingSuccessMePosts = true;
    } catch (err) {
      global.loadingSuccessMePosts = false;
      global.warn(err, "PostRearrangeA1")
    }
  }

  // componentWillUpdate() {
  //   if (global.loadingSuccessMePosts == false) {
  //     console.log(
  //       "Messages Screen Update: loadingSuccessChatMain was false before, now trying to reload"
  //     );
  //     this.componentDidMount();
  //   }
  // }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  updatePosts = async profileSnapshot => {
    try {
      let ids = profileSnapshot.data().postIDs;
      this.sortablePostIDs = ids;
      this.setState({ postIDs: ids, loading: false });
    } catch (err) {
      console.log("Error updating posts (probably network)");
    }
  };

  sortablePostIDs = [];
  lastUpdated = {};
  reorder = (id, timestamp) => {
    this.lastUpdated[id] = timestamp;
    this.sortablePostIDs.sort((a, b) => {
      if (!a in this.lastUpdated) {
        this.lastUpdated[a] = 0;
      }
      if (!b in this.lastUpdated) {
        this.lastUpdated[b] = 0;
      }
      return this.lastUpdated[b] - this.lastUpdated[a];
    });
    this.setState({
      postIDs: this.sortablePostIDs
    });
  };

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#222" }}>
        <StatusBar barStyle="light-content" />

        <View style={{ backgroundColor: "#222", flex: 1 }}>
          <View
            style={{
              backgroundColor: "#222",
              borderBottomColor: "#555555",
              borderBottomWidth: 1,
              ...Platform.select({
                ios: {
                  height: 55
                },
                android: {
                  height: 80
                }
              })
            }}
          />
          {/* <SearchBox /> */}
          <ScrollView>
            {this.state.postIDs.map(id => {
              return (
                <Post
                  key={id}
                  postID={id}
                  navigation={this.props.navigation}
                  onChange={timestamp => {
                    this.reorder(id, timestamp);
                  }}
                />
              );
            })}
          </ScrollView>
          {this.state.postIDs.length == 0 && (
            <View
              style={{
                flex: 2,
                alignItems: "center",
                justifyContent: "flex-start"
              }}
            >
              {!this.state.loading ? (
                <Text style={{ color: "#999" }}>No posts yet</Text>
              ) : (
                  <ActivityIndicator
                    size="large"
                    color="#008866"
                    style={{
                      opacity: 1
                    }}
                    animating={true}
                  />
                )}
            </View>
          )}

          <TouchableOpacity
            style={{
              borderWidth: 1,
              borderColor: "rgba(0,0,0,0.2)",
              alignItems: "center",
              justifyContent: "center",
              width: 70,
              position: "absolute",
              bottom: 5,
              right: 15,
              height: 70,
              backgroundColor: "#A8CF45",
              borderRadius: 70
            }}
            onPress={() => this.props.navigation.navigate("newPost")}
          >
            <Text style={{ fontSize: 36, fontWeight: "200", marginBottom: 2 }}>+</Text>
          </TouchableOpacity>
          <NavigationEvents
            onDidFocus={async payload => {
              //this.setState({ showNotificationHandler: true });
            }}
          />
        </View>
      </SafeAreaView>
    );
  }
}

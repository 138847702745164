import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  ScrollView,
  SafeAreaView,
} from "react-native";
import Contact from "../Contacts/Contact"
import Discount from "../Discounts/Discount"


export default class MeNewReferralsScreen extends Component {
  static navigationOptions = {
    headerShown: false,
    tabBarVisible: false,
    headerMode: "none",
    navigationOptions: {
      headerVisible: false
    }
  };
  state = {
    name: "",
    showGetPicture: false,
    activityIndicatorAnimating: false,
    keyboardSpacerHeight: 0,
    loadedKey: "1000_1" // to force rerender when globals are loaded
  };

  async componentDidMount() {
    try {
      while (true) {
        await new Promise(resolve => { setTimeout(resolve, 1000); });
        this.setState({ loadedKey: "1000_" + Math.random() })
      }
    } catch (e) {
      console.log(e)
    }
  }
  async componentDidMount() {
    try {
      const waitForDiscounts = async () => {
        try {
          let counter = 0
          while (!global.myDiscounts && counter < 20) {
            await new Promise(resolve => { setTimeout(resolve, 1000); });
            counter += 1
          }
          this.setState({ loadedKey: "1000_3" })
        } catch (e) {
          console.log(e)
        }
      }
      if (!global.myDiscounts) waitForDiscounts()

      if (!global.myFollowingIDs) {
        let countr = 0
        while (!global.myFollowingIDs && countr < 40) {
          await new Promise(resolve => { setTimeout(resolve, 500); });
          countr += 1
        }
        this.setState({ loadedKey: "1000_2" })
      }
    } catch (e) {
      console.log(e)
    }
  }



  render() {
    const discounts = global.myDiscounts || []
    console.log("DISCOUNTS", discounts)
    const followingIDs = global.myFollowingIDs || []
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#000000" }}>
        <View style={{ flex: 1 }} key={this.state.loadedKey}>
          <ScrollView style={{ width: "100%" }}>
            <View style={{ alignItems: "center", marginTop: 30 }}>
              {/* <Image
                style={{ width: 120, height: 75, marginBottom: 30, marginTop: 10 }}
                source={require("../../images/logos/home_logo.png")}
              /> */}
              <Text style={[{ color: "#008866", fontSize: 58, marginBottom: 1 }]}>
                🎉
          </Text>
              <Text style={[{ color: "#008866", fontSize: 24, fontStyle: "italic" }]}>
                Special Thank You!
          </Text>
            </View>
            <View style={{ padding: 15, width: "100%", marginTop: 30 }}>
              {followingIDs.length > 0 && <View><Text style={[{ color: "#ccccd5", fontSize: 16, fontWeight: "bold" }]}>
                These people have invited you:
          </Text>
                <Text style={[{ color: "#ccccd5", fontSize: 12, }]}>
                  You'll find them in your contacts
          </Text>

                <View
                  style={{ backgroundColor: "#20202f", borderRadius: 10, paddingVertical: 6, marginHorizontal: 10, marginTop: 10, alignItems: "center", alignSelf: "stretch" }}>
                  {followingIDs.map(profileID => {
                    return (<View style={{ alignSelf: "stretch", borderBottomColor: "#333", borderBottomWidth: 1, minHeight: 50 }} key={profileID}>
                      <Contact
                        contactID={profileID}
                        following={true}
                        //followed={Array.isArray(global.myFollowerIDs) ? global.myFollowerIDs.includes(profileID) : false}
                        height={50}
                        paddingHorizontal={8}
                        noBackgroundColor={true}
                        noBorder={true}
                        showDistance={false}
                        navigation={this.props.navigation}
                        onPress={() => { }} />
                    </View>)
                  })}
                </View>
              </View>}

              {discounts.length > 0 && <View style={{ marginTop: 30 }}><Text style={[{ color: "#ccccd5", fontSize: 16, fontWeight: "bold" }]}>
                You have received the following discounts:
          </Text>
                <Text style={[{ color: "#ccccd5", fontSize: 12, }]}>
                  They will be automatically applied when you order
          </Text><View
                  style={{ marginTop: 10, alignItems: "center", alignSelf: "stretch" }}>
                  {discounts.map(discount => {
                    return (<View style={{ alignSelf: "stretch" }} key={discount.timeOfExpirationMillis || "_"}>
                      <Discount
                        discount={discount}
                        navigation={this.props.navigation}
                      />
                    </View>)
                  })}
                </View>
              </View>}
            </View>
          </ScrollView>
        </View>
        <View style={{ height: 80, alignItems: 'center', justifyContent: 'center' }}>
          <TouchableOpacity
            onPress={() => { this.props.navigation.navigate("homeMain", { justSignedIn: true }) }}
          >
            <View style={[global.gStyles.buttonXL, { paddingVertical: 2.5, paddingHorizontal: 30, flexDirection: "row", alignSelf: "center", alignItems: "center" }]} >
              <Text style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}>Continue to Wakanda</Text>
            </View>
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    );
  }
}
import React, { Component } from 'react'
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  Animated,
  Platform,
  Modal,
  Keyboard,
  Dimensions
} from "react-native";
import * as Permissions from 'expo-permissions';
import MapScreen from './mapScreenV2';

const gStyles = global.gStyles //    "../../styles/gStyles";




// Popup that appears at the bottom of the screen showing which location is used (with buttons for hide, change, try again)
// Typically popping up in case location could not be retrieved
//
//props: msDelay (number) miliseconds of delay before popup should potentially start
export default class CurrentLocationModal extends Component {

  state = {
    mapAddress: "Loading Location",
    mapCoords: null,
    showButtons: true
  }
  async componentDidMount() {
    this.keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      this._keyboardDidShow
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      this._keyboardDidHide
    );
  }

  componentWillUnmount() {
    if (this.keyboardDidShowListener) this.keyboardDidShowListener.remove();
    if (this.keyboardDidHideListener) this.keyboardDidHideListener.remove();
  }

  _keyboardDidShow = async () => {
    if (Platform.OS !== "ios")
      this.setState({ showButtons: false });
  };

  _keyboardDidHide = () => {
    if (Platform.OS !== "ios")
      this.setState({ showButtons: true });
  };

  render() {
    const marginBottom = 0 //+ (Dimensions.get("window").height >= 800 ? 78 : 48)
    const height = 80
    return (
      <Modal
        animationType="slide"
        transparent={false}
        visible={true}
        onRequestClose={() => {
          //leave this here
        }}>
        <MapScreen
          getUpdatedLocation={(address, coords) => {
            this.setState({ mapAddress: address, mapCoords: coords });
          }}
          addresstoMapScreen={this.props.location ? this.props.location.coords : global.location ? global.location.coords : null}
        />
        {!!this.state.showButtons && <View style={{ width: '15%', margin: 20, marginTop: -130, flexDirection: 'row' }}>
          <TouchableOpacity
            style={{ backgroundColor: 'grey', borderRadius: 5, width: 70, justifyContent: 'center' }}
            onPress={() => { this.props.onCancel() }}>
            <Text style={{ fontSize: 15, color: 'white', fontWeight: 'bold', padding: 5, textAlign: 'center' }}
            >Cancel</Text>
          </TouchableOpacity>
          {!!this.state.mapCoords && <TouchableOpacity
            style={{ backgroundColor: 'green', borderRadius: 5, width: 50, marginLeft: 10, justifyContent: 'center' }}
            onPress={async () => {
              await global.timeout(50)
              this.props.onSelect(this.state.mapCoords, this.state.mapAddress)
              global.location = { coords: this.state.mapCoords, address: this.state.mapCoords }
              global.myLocation = global.location
            }}>
            <Text style={{ fontSize: 15, color: 'white', fontWeight: 'bold', padding: 5, textAlign: 'center' }}
            >Set</Text>
          </TouchableOpacity>}
        </View>}
      </Modal>)
  }
}
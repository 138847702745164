import firebase from "firebase";
import "firebase/firestore";
import distance from "../../shared/distances";


// Function to get offers stored locally on device (mainly offers from your contacts)
//
// expects: params = {
//  amount (number): number of results
//  searchTerm (String): Category or term to search for
//}
//
// returns: array of offerSnapshots
// returns: -1 on error
export function findOffersOffline(params) {
  // if (!params.searchTerm || params.searchTerm.length < 3) {
  //   console.warn("Function expects parameter searchTerm - with more than 2 characters")
  //   return -1
  // }
  const term = params.searchTerm.toLowerCase().replace(/[^a-z]/g, "")
  console.log('findOffersOffline term', term)
  //if (term.length < 3) return -1;
  const amount = params.amount || 4

  let filteredLocalResultList = []
  if (global.localResultsList) {
    filteredLocalResultList = global.localResultsList.filter((p) => (
      (p.cat1.toLowerCase().replace(/[^a-z]/g, "").includes(term)) ||
      (p.cat2.toLowerCase().replace(/[^a-z]/g, "").includes(term)) ||
      (p.t.toLowerCase().replace(/[^a-z]/g, "").includes(term))))
  }
  if (term == "art") filteredLocalResultList.sort((a, b) => { if (b.cat1.toLowerCase() == "art") return 1; return -1 })
  filteredLocalResultList.sort(function (a, b) {
    return b.z - a.z;
  });//??
  if (filteredLocalResultList.length > amount) {
    filteredLocalResultList.length = amount
  }
  return filteredLocalResultList
}

// Function to fetch offers based on searchTerm and possibly location
//
// expects: params = {
//   searchTerm (String): Category or term to search for
//   location (optinal, Object): {coords:{ latitude: float, longitude: float }}
//   amount (number): number of results
//   offset (optional, number): Skip the first x results (used for follow-up queries)
// }
//
// returns: array of offerSnapshots
// returns: -1 on error
export async function findOffersAsync(params) {
  if (!global.myProfileID || !global.myUID) await global.timeout(500)
  if (!global.myProfileID || !global.myUID) await global.timeout(500)
  if (!global.myProfileID || !global.myUID) await global.timeout(500)
  if (!global.myProfileID || !global.myUID) await global.timeout(500)
  if (!global.myProfileID || !global.myUID) {
    console.warn("Called findAsync without myUID or myProfileID", global.myProfileID, global.myUID)
    return -1
  }
  if (!params.searchTerm || (params.searchTerm.length < 3 && params.searchTerm.toLowerCase() !== "dj" && params.searchTerm.toLowerCase() !== "tv")) {
    console.warn("Function expects parameter searchTerm - with more than 2 characters")
    return -1
  }
  const term = params.searchTerm.toLowerCase().replace(/[^a-z]/g, "")
  console.log('findOffersAsync term', term)
  if (term.length < 3 && params.searchTerm.toLowerCase() !== "dj" && params.searchTerm.toLowerCase() !== "tv") return -1;

  const coords = (params.location && params.location.coords) ? params.location.coords
    : (global.location && global.location.coords) ? global.location.coords : null

  try {
    await global.fetchIdToken()
    const r = await fetch(global.cloudFunctionURL + "searchIndex", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        isWeb: true,
        searchTerm: term,
        amount: params.amount || 4,
        coords,
        ...(params.offset && { offset: params.offset }),
        myUID: global.myUID,
        idToken: global.idToken
      })
    });
    const r2 = await r.json();
    //console.log("r2", r2)
    if (r2.msg == "SUCCESS") {
      const newList = r2.results || []
      newList.sort((a, b) => {
        let aDist = 999
        let bDist = 999
        if (coords && a.loc)
          aDist = Math.round(distance(a.loc._latitude, a.loc._longitude, coords.latitude, coords.longitude, "K"))
        if (coords && b.loc)
          bDist = Math.round(distance(b.loc._latitude, b.loc._longitude, coords.latitude, coords.longitude, "K"))
        if (a.loc._latitude == 6.6) aDist += 50
        if (b.loc._latitude == 6.6) bDist += 50
        return (b.z - bDist) - (a.z - aDist);
      });
      return newList
    } else {
      console.log("Error calling searchIndex (in findOffersAsync)", r2);
      return -1
    }
  } catch (err) {
    console.log("ERROR33:", err);
    console.warn("ERROR33")
    return -1
  }
}

// Function to fetch offers based on creation data or #likes
//
// expects: params = {
//   orderBy: "RECENT" or "LIKES" or "RECENTNEARBY" or "MOSTEVERLIKED" or "TOSWIPE"
//   location: {coords:{ latitude: float, longitude: float }}
//   amount (number): number of results
// }
//
// returns: array of offers (NOT offer snapshots)
// returns: -1 on error
export async function findPostOffersAsync(params) {
  if (!global.myProfileID || !global.myUID) await global.timeout(500)
  if (!global.myProfileID || !global.myUID) await global.timeout(500)
  if (!global.myProfileID || !global.myUID) await global.timeout(500)
  if (!global.myProfileID || !global.myUID) await global.timeout(500)
  if (!global.myProfileID || !global.myUID || !global.myLocalCurrency) await global.timeout(1500)
  if (!global.myProfileID || !global.myUID) {
    console.warn("Called findPostOffersAsync without myUID or myProfileID", global.myProfileID, global.myUID)
    return -1
  }

  let coords = (params.location && params.location.coords) ? params.location.coords
    : (global.location && global.location.coords) ? global.location.coords : null

  if (!coords) {
    await global.setLocationFast?.(960);
    coords = global.location?.coords
  }

  try {
    const r = await fetch(global.cloudFunctionURL + "findPosts2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        isWeb: true,
        isWeb: true,
        orderBy: params.orderBy || "LIKES",
        amount: params.amount || 5,
        coords,
        countryCurrency: global.myLocalCurrency || "NGN",
        ...(params.category1 && { category1: params.category1 }),
        ...(params.category2 && { category2: params.category2 }),
        myUID: global.myUID,
        idToken: global.idToken
      })
    });
    const r2 = await r.json();
    console.log("findPosts2 result", r2.msg)

    if (r2.msg == "SUCCESS") {

      const postResults = r2.results || []
      const results = postResults.map(post => {
        post.author = post.authorProfileID || ""
        post.hash = ""
        post.loc = post.assignedLocationGeo || {}
        post.t = post.postTitle || ""
        post.url = post.postPictureURL || ""
        post.p = post.postPrice
        post.pt = post.postPriceType
        post.pc = post.postPriceCurrency || "NGN"

        return post
      })
      return results

    } else {
      console.log("Error calling findPosts2 (in findPostOffersAsync)", r2);
      return -1
    }
  } catch (err) {
    console.log("ERROR8:", err);
    console.warn("ERROR8")
    return -1
  }
}

//optional argument: interests[]
//optional argument: category1[]
//optional argument: searchTerm (String) to search by name
//Note that category1 would override interests
//Note that searchTerm does not consider category1 or interests
//optional argument: coords as an object {latitude: float, longitude: float}
//optional argument: amount(Number) amount of results, defaults to 5
export async function findCommunitiesAsync(params) {
  if (!params) params = {}
  if (!global.location || !global.idToken || !global.myUID) await global.timeout(500)
  if (!global.location || !global.idToken || !global.myUID) await global.timeout(500)
  if (!global.location || !global.idToken || !global.myUID) await global.timeout(500)
  if (!global.location || !global.idToken || !global.myUID) await global.timeout(500)
  if (!global.location || !global.idToken || !global.myUID) await global.timeout(500)
  if (!global.location || !global.idToken || !global.myUID) await global.timeout(500)
  if (!global.location || !global.idToken || !global.myUID) await global.timeout(500)
  if (!global.location || !global.idToken || !global.myUID) await global.timeout(2500)
  if (!global.location || !global.idToken || !global.myUID) {
    console.warn("Called findCommunitiesAsync without globals set properly")
    console.log("Called findCommunitiesAsync without globals set properly", global.location, global.idToken, global.myUID)
    return -1
  }

  if (params.interests && !(params.interests.length > 0)) params.interests = null
  if (params.interests && !Array.isArray(params.interests)) {
    console.warn("Interests not an array")
    return -1;
  }
  if (params.amount && typeof params.amount !== "number") {
    console.warn("Amount is not a number")
    return -1;
  }

  const coords = (params.location && params.location.coords && params.location.coords.longitude) ? params.location.coords
    : (global.location && global.location.coords && global.location.coords.longitude) ? global.location.coords : null
  if (!coords) {
    console.warn("No Location found cxv")
    return -1
  }

  try {
    const r = await fetch(global.cloudFunctionURL + "findCommunities", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        isWeb: true,
        coords: coords,
        ...(params.interests && { interests: params.interests }),
        ...(params.category1 && { category1: params.category1 }),
        ...(params.searchTerm && { searchTerm: params.searchTerm }),
        ...(params.amount && { amount: Math.round(params.amount) }),
        myUID: global.myUID,
        idToken: global.idToken
      })
    });
    const r2 = await r.json();
    console.log("findCommunities result", r2.msg)

    if (r2.msg == "SUCCESS") {

      const results = r2.communities || []
      return results

    } else {
      console.log("Error calling findCommunities (in findCommunitiesAsync)", r2);
      return -1
    }
  } catch (err) {
    console.log("ERROR8e:", err);
    console.warn("ERROR8e")
    return -1
  }
}


//optional Arguments: amount
//optional Arguments: communities (array of communityID strings) will only find results inside communities // does not yet work in combination with category2
//optional Arguments: skills (array skill strings)
//optional Arguments: category1, category2
//optional arguments:  inquiryText (in case of an Inquiry)
//optional arguments:  location (else global.location is taken)
//optional arguments: maxDistance maximum distance in km
export async function findProvidersAsync(params) {
  if (!params) params = {}
  if (!global.location || !global.idToken || !global.myUID) await global.timeout(500)
  if (!global.location || !global.idToken || !global.myUID) await global.timeout(500)
  if (!global.location || !global.idToken || !global.myUID) await global.timeout(500)
  if (!global.location || !global.idToken || !global.myUID) await global.timeout(500)
  if (!global.location || !global.idToken || !global.myUID) await global.timeout(500)
  if (!global.location || !global.idToken || !global.myUID) await global.timeout(500)
  if (!global.location || !global.idToken || !global.myUID) await global.timeout(500)
  if (!global.location || !global.idToken || !global.myUID) await global.timeout(2500)
  if (!global.location || !global.idToken || !global.myUID) {
    console.warn("Called findProvidersAsync without globals set properly")
    console.log("Called findProvidersAsync without globals set properly", global.location, global.idToken, global.myUID)
    return -1
  }
  // if (params.interests && !(params.interests.length > 0)) params.interests = null
  // if (params.interests && !Array.isArray(params.interests)) {
  //   console.warn("Interests not an array")
  //   return -1;
  // }
  if (params.amount && typeof params.amount !== "number") {
    console.warn("Amount is not a number")
    return -1;
  }
  const coords = (params.location && params.location.coords && params.location.coords.longitude) ? params.location.coords
    : (global.location && global.location.coords && global.location.coords.longitude) ? global.location.coords : null
  if (!coords) {
    await global.setLocationFast(30)
  }

  console.log("Starting to call findProvidersAsync");
  try {

    const r = await fetch(global.cloudFunctionURL + "findProviders2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        isWeb: true,
        coords: coords || global.location ? global.location.coords : { latitude: 6.6, longitude: 3.3 },
        // valNearYou: 0.5,
        // valAffordable: 0.5,
        // valQuality: 0.5,
        // valProfessional: 0.5,
        amount: params.amount || 30,
        category1: params.category1 || null,
        category2: params.category2 || null,
        communities: params.communities || null,
        myUID: global.myUID,
        idToken: global.idToken,
        ...((params.skills) && {
          budget: params.budget || 0,
          budgetCurrency: params.budgetCurrency || "NGN",
          skills: params.skills
        }),
        ...((params.maxDistance) && { maxDistance: params.maxDistance }),
      })
    });
    const r2 = await r.json();

    console.log("Received response. ", r2.msg);
    if (r2.msg === "SUCCESS") {
      return r2.results || [];
    } else {
      console.warn("ServerError gdf7")
      return -1
    }
  } catch (e2) {
    console.log(e2);
    console.warn(e2, "Error gdf8")
    return -1
  }
};





import React, { Component } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  ImageBackground,
  StyleSheet,
  ScrollView,
  Platform,
  Dimensions,
} from "react-native";

const SCREEN_WIDTH = Dimensions.get("window").width;

// const SLIDE_DATA = [
//   { text: "Welcome >>>" },
//   { text: "This is Wakanda Market" },
//   { text: "Wakanda Market is great!" }
// ];

class Slider extends Component {
  renderSlides() {
    return this.props.data.map(slide => {
      return (
        <View key={slide.text} style={styles.container}>
          <Text style={styles.text}>{slide.text}</Text>
          <Text> </Text>
        </View>
      );
    });
  }
  render() {
    return (
      <View>
        <ScrollView horizontal pagingEnabled style={{ flex: 1 }}>
          {this.renderSlides()}
        </ScrollView>
      </View>
    );
  }
}

class WelcomeScreen extends Component {
  static navigationOptions = {
    //tabBarVisible: false,
  };

  render() {
    return (
      <ImageBackground
        source={require("../images/logos/WE-Background.jpg")}
        style={{
          flex: 1.2,
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor: "#000"
        }}
      >
        <View>
          <View
            style={{
              borderWidth: 0,
              ...Platform.select({
                ios: {
                  height: 0
                },
                android: {
                  height: 150
                }
              })
            }}
          />
          <View
            style={{
              flex: 5,
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {/* <Text style={{ color: "#fff", fontSize: 24, marginTop: 15 }}>
              Welcome!
            </Text> */}
            <Image
              style={{ width: 300, height: 300, tintColor: "white" }}
              source={require("../../../custom/Education/images/logos/WE-Logo.png")}
            />
            <View style={{ flexDirection: "row", alignSelf: "flex-start", margin: 20 }}>
              <Text style={{ color: "#fff", fontSize: 24, margin: 4, fontStyle: "italic", textAlign: "center", fontFamily: Platform.OS === "ios" ? 'Chalkduster' : 'Roboto' }}>
                Find a home tutor / teacher / lecturer near you within seconds
            </Text>
            </View>
          </View>
          <View style={{ flex: 1, alignItems: "center" }}>


            <Text style={{ color: "#fff", fontSize: 14, margin: 5 }} />
            {/* <Text style={{ color: "#fff", fontSize: 14, margin: 5 }} >PLACE SLIDER HERE!</Text> */}
          </View>
          <View style={{ flex: 1.4, alignItems: "center" }}>
            <TouchableOpacity
              onPress={() => this.props.navigation.navigate("signin")}
            >
              <Text style={{ color: "#fff", fontSize: 24, margin: 5, marginBottom: 5, fontFamily: Platform.OS === "ios" ? 'Chalkduster' : 'Roboto' }}>
                CONTINUE...
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={{ flexDirection: "row", position: "absolute", bottom: 25, alignItems: "flex-end", justifyContent: "center", marginTop: 15 }}>
          <Text style={{ fontSize: 14, color: "#fff", fontWeight: "bold" }} >powered by</Text>
          <Image
            style={{ width: 30, height: 24, marginLeft: 5, }}
            source={require("../../../images/logos/home_logo.png")}
          />
          <Text style={{ fontSize: 14, color: "#fff", fontWeight: "bold" }}>WAKANDA</Text>
        </View>
      </ImageBackground>
    );
  }
}

const styles = ({
  container1: {
    flex: 4,
    alignItems: "center",
    justifyContent: "center",
    width: SCREEN_WIDTH,
    backgroundColor: "#000"
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: SCREEN_WIDTH,
    backgroundColor: "#000"
  },
  text: {
    fontSize: 30
  }
});

export default WelcomeScreen;

import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  TextInput,
  SafeAreaView,
  SectionList,
  Keyboard,
  Platform,
  Modal,
  Dimensions,
  ActivityIndicator
} from "react-native";
import firebase from "firebase";
import "firebase/firestore";
const gStyles = global.gStyles //    "../../../../styles/gStyles";

class SearchBar extends Component {
  state = {
    searchTerm: "",
    countryCode: "+234",
    //countryCode: global.walletType == "ACN" ? "+233" : "+234",
    modalVisible: false,
  };

  callSearch = () => {
    let enteredNumber = this.state.searchTerm.replace(/[^\d]/g, "");
    if (enteredNumber.startsWith("00")) {
      alert("Invalid phone number"); return;
    }
    if (enteredNumber.startsWith("0")) {
      enteredNumber = enteredNumber.slice(1);
    }
    const phoneNumber = this.state.countryCode + enteredNumber;
    console.log("pn", phoneNumber, this.state.countryCode, enteredNumber)
    this.props.onPress(phoneNumber);
  }

  render() {
    const countryCodes = global.countryCodes;
    return (
      <View
        style={{
          flexDirection: "row",
          alignSelf: "center",
          height: 40,
          width: 275,
          backgroundColor: "#aaa",
          borderRadius: 20,
          borderBottomWidth: 0,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            this.setState({ modalVisible: true });
          }}
          style={{
            alignItems: "center",
            alignSelf: "flex-start",
            padding: 1,
            borderRightWidth: 1,
            borderRightColor: "gray",
            marginRight: 4.5,
            width: 65,
            height: 40,
            justifyContent: "center",
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            borderColor: "gray"
          }}
        >
          <Text>{this.state.countryCode}</Text>
        </TouchableOpacity>
        <TextInput
          style={{
            alignSelf: "stretch",
            height: 40,
            marginBottom: 20,
            color: "#FFFFFF",
            backgroundColor: "#aaa",
            borderRadius: 33,
            borderBottomWidth: 0,
            color: "black",
            paddingLeft: 10,
            flex: 1
          }}
          placeholder="Search a phone number"
          placeholderTextColor="#000"
          keyboardType="numeric"
          underlineColorAndroid="transparent"
          underlineColor={"transparent"}
          returnKeyType="search"
          onChangeText={searchTerm => this.setState({ searchTerm })}
          onSubmitEditing={this.callSearch}
          ref={component => (this.myTextInput = component)}
          // clearButtonMode="always" //xx how set colour?
          value={this.state.text}
        />

        {this.state.searchTerm.length > 0 && (
          <TouchableOpacity
            style={{
              alignItems: "center",
              alignSelf: "flex-start",
              padding: 1,
              backgroundColor: "#A8CF45",
              marginLeft: 4.5,
              width: 47,
              height: 40,
              justifyContent: "center",
              borderTopEndRadius: 20,
              borderBottomEndRadius: 20,
              borderColor: "gray",
              borderLeftWidth: 1
            }}
            onPress={this.callSearch}
          >
            <Image
              style={{
                width: 18,
                height: 18,
                marginRight: 1
              }}
              source={require("../../../../images/icons/Search.png")}
            />
          </TouchableOpacity>
        )}
        <Modal
          animationType="slide"
          transparent={false}
          visible={this.state.modalVisible}
          onRequestClose={() => {
            this.setState({ modalVisible: false });
          }}
        >
          <TouchableOpacity
            onPress={() => this.setState({ modalVisible: false })}
            style={{
              height: 50,
              backgroundColor: "#000"
            }}
          />
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#000"
            }}
          >
            <View
              style={{
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <View style={{ paddingTop: 20 }}>
                <Text style={{ fontSize: 30, color: "white" }}>
                  Select Country
                  </Text>
              </View>
              <View style={{ marginTop: 0, marginBottom: 5 }} />
              <SectionList
                renderItem={({ item, index, section }) => (
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({
                        modalVisible: false,
                        countryCode: item.split("(")[1].split(")")[0]
                      });
                    }}
                    style={{
                      flex: 1,
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 20,
                      width: Dimensions.get("window").width,
                      backgroundColor: "#112",
                      borderWidth: 1,
                      borderColor: "#223"
                    }}
                  >
                    <Text
                      style={{ fontSize: 16, color: "white" }}
                      key={index}
                    >
                      {item}
                    </Text>
                  </TouchableOpacity>
                )}
                renderSectionHeader={({ section: { title } }) => (
                  <Text style={{ fontWeight: "bold" }}>{title}</Text>
                )}
                sections={[
                  {
                    title: "",
                    data: countryCodes
                  }
                ]}
                keyExtractor={(item, index) => item + index}
              />
            </View>
          </View>
          <TouchableOpacity
            style={{
              position: "absolute",
              borderWidth: 0,
              backgroundColor: "#556",
              borderColor: "rgba(0,0,0,0.2)",
              alignItems: "center",
              justifyContent: "center",
              width: 20,
              top: 20,
              left: 20,
              height: 25,
              borderRadius: 30
            }}
            onPress={() => this.setState({ modalVisible: false })}
          >
            <Image
              style={{ width: 25, height: 25, opacity: 1 }}
              source={require("../../../../images/icons/CancelBlack.png")}
            />
            {/* <Text style={{ fontSize: 26, color: "#666" }}>x</Text> */}
          </TouchableOpacity>
        </Modal>
      </View>
    );
  }
}

class Contact extends Component {
  state = {
    contactProperties: {
      opponentProfileID: null,
      opponentName: "********",
      opponentNameBusiness: "************",
      opponentProfilePictureURL:
        "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/testDummies%2Favatar-1295429_640.png?alt=media&token=31c6b26e-6c40-4e6a-86cf-ee8c1f393706"
    },
    activityIndicatorAnimating: false,
  };

  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  componentDidMount() {
    if (this.props.contactID) {
      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Profiles")
        .doc(this.props.contactID)
        .onSnapshot(this.updateContact);
    }
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  updateContact = async contactSnapshot => {
    console.log("Update of  staff triggered. (StaffWebsitesDashboard Screen)");
    try {
      let contact = contactSnapshot.data();

      let opponentProfileID = contactSnapshot.id;
      let opponentName = contact.profileName;
      let opponentProfilePictureURL = contact.profilePictureURL;
      let opponentNameBusiness = contact.profileNameBusiness || "";

      let contactProperties = {
        opponentProfileID,
        opponentName,
        opponentNameBusiness,
        opponentProfilePictureURL
      };

      //console.log("contactProperties", contactProperties);
      this.setState({
        contactProperties,
      });
    } catch (err) {
      console.log("Slow internet. Could not fetch contacts (meContact)", err);
      alert("Could not fetch contacts. Check your internet connection");
    }

    //Image.prefetch(url);
  };

  callSet = async () => {
    if (!this.state.activityIndicatorAnimating) {
      try {
        this.setState({ activityIndicatorAnimating: true });

        await global.fetchIdToken()
        const r = await fetch(global.cloudFunctionURL + "setStaffWebsite", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            staffProfileID: this.props.contactID,
            myUID: global.myUID,
            idToken: global.idToken
          })
        });
        const r2 = await r.json();
        this.setState({ activityIndicatorAnimating: false });

        if (r2.msg === "SUCCESS") {
          alert("Success")
          await global.timeout(50)
        } else if (r2.msg === "HAS_IDSHOP") {
          alert("User already seems to have a website")
          await global.timeout(50)
        } else if (r2.msg === "HAS_WEBSITEJUSTPURCHASED") {
          alert("User has recently gotten a website already")
          await global.timeout(50)
        } else {
          alert("Request failed");
        }
      } catch (err) {
        alert("Request not successful.");
        console.log(err);
        this.setState({ activityIndicatorAnimating: false });
      }
    }
  };

  render() {
    return (
      <TouchableOpacity
        onPress={() => {
          if (!this.state.contactProperties.opponentProfileID) {
            return
          }
          let handoverID = this.state.contactProperties.opponentProfileID;
          this.props.navigation.navigate("meProfile", {
            profileID: handoverID
          });
        }}
      >
        <View
          style={[
            {
              flexDirection: "row",
              flex: 1,
              height: 65,
              backgroundColor: "black",
              alignItems: "center",
              paddingLeft: 11,
              paddingRight: 11,
              shadowOpacity: 0.5,
              shadowOffset: { width: 0, height: 3 },
              borderBottomColor: "#333",
              borderBottomWidth: 1
            }
          ]}
        >
          {!!this.state.contactProperties.opponentProfilePictureURL ? (
            <Image
              style={{ width: 42, height: 42, borderRadius: 4 }}
              source={{
                uri: this.state.contactProperties.opponentProfilePictureURL
              }}
            />
          ) : (
              <View style={{ width: 42, height: 42, borderRadius: 4, backgroundColor: "#000" }} />
            )}
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View
              style={{ flexDirection: "column", flex: 1, paddingHorizontal: 8 }}
            >
              <Text style={{ fontSize: 14, color: "white" }}>
                {this.state.contactProperties.opponentName}
              </Text>
              {this.state.contactProperties.opponentNameBusiness != "" && (
                <Text style={{ fontSize: 11, color: "white" }}>
                  {this.state.contactProperties.opponentNameBusiness}
                </Text>
              )}
            </View>
            {!this.state.activityIndicatorAnimating ? (<TouchableOpacity
              style={{ alignSelf: "stretch", alignItems: "center", padding: 4, backgroundColor: "#A8CF45", marginTop: 5, marginBottom: 5, borderRadius: 5 }}
              onPress={() => { this.callSet(); }}
            >
              <Text style={{ fontWeight: "bold", color: "#000", fontSize: 14 }}>
                Give Classic website
              </Text>
            </TouchableOpacity>)
              :
              (<ActivityIndicator
                size="small"
                color="#FFFFFF"
                animating={true}
                style={{ marginRight: 12, marginTop: 2 }}
              />)
            }
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

export default class StaffWebsitesDashboardScreen extends Component {
  static navigationOptions = {
    tabBarVisible: false,
    headerTransparent: true,
    title: "Assign Free Classic Websites",
    headerTitleStyle: {
      fontWeight: "bold",
      color: "#A8CF45",
      shadowOpacity: 1
    }
  };
  state = {
    activityIndicatorAnimating: false,
    activityIndicatorAnimating2: false,
    resultID: 0,
    resultProfiles: [],
    resultType: null,
    noContactFound: false,
  };
  constructor() {
    super();
    const firestore = firebase.firestore();
    //const fSettings = { timestampsInSnapshots: true };
    //firestore.settings(fSettings);
    this.unsubscribeFirestore = () => { return; };
  }

  async componentDidMount() {
    //
  }

  componentWillUnmount() {
    this.unsubscribeFirestore();
  }

  search = async term => {
    try {
      this.setState({ activityIndicatorAnimating: true, resultID: 0 });
      const r = await fetch(
        global.cloudFunctionURL + "findContact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ isWeb:true,
            phoneNumber: term,
            myUID: global.myUID,
            idToken: global.idToken
          })
        }
      );
      const r2 = await r.json();

      console.log("Received contact. ", r2.msg);
      this.setState({ activityIndicatorAnimating: false });
      Keyboard.dismiss();
      if (r2.msg === "NO_CONTACT_FOUND") {
        this.setState({ noContactFound: true });
        return;
      }
      if (r2.msg === "CONTACT_FOUND") {
        this.setState({ resultID: r2.contactID, resultType: "singleContact", noContactFound: false });
        return;
      }
      alert("Error: " + r2.msg);
    } catch (e2) {
      alert("An error occurred. Check internet connection.");
      console.log(e2);
      this.setState({ activityIndicatorAnimating: false });
    }
  };

  renderSingleContact = () => {
    return <ScrollView>
      {this.state.noContactFound && (
        <View
          style={{
            height: 300,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Text style={{ color: "white" }}>No result found.</Text>
        </View>
      )}
      {!this.state.noContactFound &&
        this.state.resultID != 0 && (
          <Contact
            id={this.state.resultID}
            contactID={this.state.resultID}
            navigation={this.props.navigation}
          />
        )}
    </ScrollView>
  }

  render() {
    // if (this.state.resultID == 0 && this.state.noContactFound == false) {
    //   //??del
    // }

    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#131313" }}>
        <View style={{ backgroundColor: "#131313", flex: 1 }}>
          <View
            style={{
              borderBottomColor: "#555555",
              borderWidth: 0,
              ...Platform.select({
                ios: {
                  height: 55
                },
                android: {
                  height: 105
                }
              })
            }}
          />
          <SearchBar
            onPress={text => {
              this.search(text);
            }}
          />
          <ActivityIndicator
            size="small"
            color="#FFFFFF"
            style={{
              opacity: this.state.activityIndicatorAnimating ? 1.0 : 0.0, marginTop: 10
            }}
            animating={true}
          />
          {this.renderSingleContact()}

          <View
            style={{
              marginLeft: 20,
              marginRight: 20,
              marginTop: 10,
              marginBottom: 40,
              alignItems: "center"
            }}
          >
            <Text style={{ color: "#aaa", marginVertical: 12, fontSize: 16 }}>ⓘ Select agent or staff who should obtain a free classic website.</Text>
          </View>

        </View>
      </SafeAreaView>
    );
  }
}

import React, { Component } from "react";
import { NavigationEvents } from "react-navigation";
import {
    Text,
    View,
    Image,
    ImageBackground,
    StatusBar,
    SafeAreaView,
    ScrollView,
    TouchableOpacity,
    Dimensions,
    Modal,
    Animated,
    Platform,
    StyleSheet,
    Alert,
    ActivityIndicator,
    Linking
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
//import { Card, ListItem, Button, Icon } from 'react-native-elements'
class Card extends Component { render() { return (<View />) } };
class Button extends Component { render() { return (<View />) } };
import firebase from "firebase";
import "firebase/firestore";
import * as RSSParser from 'react-native-rss-parser';
import { WebView } from "react-native-webview";
import Moment from "react-moment"


const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;
const cardPerSlide = 1
const cardPadding = 10
const paddingAround = cardPadding * 2 // slide horizontal padding
const cardBetweenPadding = cardPadding * (cardPerSlide - 1)
const totalPadding = paddingAround + cardBetweenPadding
const imageWidth = (SCREEN_WIDTH - totalPadding) / cardPerSlide
const imageHeight = (imageWidth / (2 / 3))
const contentOffset = (SCREEN_WIDTH - (SCREEN_WIDTH * 0.96)) / 2;
const deviceWidth = Dimensions.get('window').width
const FIXED_BAR_WIDTH = 250
const BAR_SPACE = 10

const STORAGE_KEY = '@save_news'


// const gStyles = global.gStyles //    "../../../styles/gStyles";
// import CachedImage from "../../../shared/CachedImage"


let animVal = new Animated.Value(0)


export default class NewsPage extends Component {
    static navigationOptions = ({ navigation }) => {
        return {
            tabBarVisible: false,
            headerTransparent: true,
            title: "News",
            headerTintColor: "#A8CF45",
            headerTitleStyle: {
                fontWeight: "bold",
                color: "#A8CF45",
                shadowOpacity: 0
            }
        }
    };

    state = {
        items: [],
        showWebView: false,
        webviewItem: '',
        url: global.myLocalCurrency == "GHS" ? `https://www.pulse.com.gh` : `https://www.pulse.ng`,
        savedNews: [],
        loading: true

    }


    saveNews = async (value) => {
        let savedArray = global.duplicate(this.state.savedNews)
        savedArray = savedArray.filter(item => { return value.title != item.title })

        savedArray.unshift(value)
        if (savedArray.length > 20) {
            savedArray.length = 20
        }

        try {
            const jsonValue = JSON.stringify(savedArray)
            await AsyncStorage.setItem('STORAGE_KEY1', jsonValue)
            //console.log('Data successfully saved in saveNews ', jsonValue)
        }
        catch (e) {
            console.log('Failed to save data to storage')
        }
    }


    getNews = async () => {
        try {
            const jsonValue = await AsyncStorage.getItem('STORAGE_KEY1')
            const savedNews = jsonValue != null ? JSON.parse(jsonValue) : [];
            //console.log('Data successfully saved in getNews ', savedNews)
            this.setState({ savedNews, loading: false })
            //console.log('Data successfully saved in getNews after saved ', savedNews)
        } catch (e) {
            // error reading value
            Alert.alert('Error')
        }
    }

    loadRSS = async () => {
        try {
            // fetch('http://www.nasa.gov/rss/dyn/breaking_news.rss')
            //   .then((response) => response.text())
            //   .then((responseData) => RSSParser.parse(responseData))
            //   .then((rss) => {
            //     //console.log(rss);
            //     console.log(rss.items?.length);
            //   });

            const rssURL = global.myLocalCurrency == "GHS" ? 'https://www.pulse.com.gh/rss' : 'https://www.pulse.ng/rss'
            fetch(rssURL)
                .then((response) => response.text())
                .then((responseData) => RSSParser.parse(responseData))
                .then((rss) => {
                    //console.log(rss);
                    let items = rss?.items
                    if (items) {
                        // console.log(items.length);
                        items = items.map(item => {
                            const alternateImageURL = global.myLocalCurrency == "GHS"
                                ? "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FPulseGH-red.png?alt=media&token=f436e69a-456c-4e9d-94b9-6e887a152072"
                                : "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FpulseNG.PNG?alt=media&token=ae813230-4744-43d5-8dcd-bc9ceb7c96af"
                            const contentArray = item?.content?.split?.("src=\"")
                            const imageURL = (contentArray?.[1]?.split?.("\"")?.[0]) || alternateImageURL
                            //console.log({ imageURL })
                            item["imageURL"] = imageURL
                            return item
                        })
                        //console.log({ items: items });
                        if (items.length > 1) {
                            items.length = 1
                        }
                        this.setState({ items: items, loading: false })
                        //console.log('ITEM FOUND IS ', this.state.items);
                    }
                });

        } catch (err) {
            global.warn(err, "NewsScreen");
        }

        await this.getNews()
    }

    async componentDidMount() {
        // try {
        //     // fetch('http://www.nasa.gov/rss/dyn/breaking_news.rss')
        //     //   .then((response) => response.text())
        //     //   .then((responseData) => RSSParser.parse(responseData))
        //     //   .then((rss) => {
        //     //     //console.log(rss);
        //     //     console.log(rss.items?.length);
        //     //   });

        //     fetch('https://www.pulse.ng/rss')
        //         .then((response) => response.text())
        //         .then((responseData) => RSSParser.parse(responseData))
        //         .then((rss) => {
        //             //console.log(rss);
        //             let items = rss?.items
        //             if (items) {
        //                 console.log(items.length);
        //                 items = items.map(item => {
        //                     const alternateImageURL = "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/SystemPictures%2FpulseNG.PNG?alt=media&token=ae813230-4744-43d5-8dcd-bc9ceb7c96af"
        //                     const contentArray = item?.content?.split?.("src=\"")
        //                     const imageURL = (contentArray?.[1]?.split?.("\"")?.[0]) || alternateImageURL
        //                     //console.log({ imageURL })
        //                     item["imageURL"] = imageURL
        //                     return item
        //                 })
        //                 //console.log({ items: items });
        //                 if (items.length > 2) {
        //                     items.length = 1
        //                 }
        //                 this.setState({ items: items, loading: false })
        //                 console.log('ITEM FOUND IS ', this.state.items);
        //             }
        //         });

        // } catch (err) {
        //     global.warn(err, "NewsScreen");
        // }

        // await this.getNews()

        this.loadRSS()

        const reloadGhana = async () => {
            {
                console.log("Reloading for Ghana")
                await this.setState({ url: `https://www.pulse.com.gh` })
                this.loadRSS()
            }
        }

        await global.timeout?.(1000)
        if (global.myLocalCurrency == "GHS" && this.state.url !== `https://www.pulse.com.gh`) reloadGhana()
        else {
            await global.timeout?.(1000)
            if (global.myLocalCurrency == "GHS" && this.state.url !== `https://www.pulse.com.gh`) reloadGhana()
            else {
                await global.timeout?.(1000)
                if (global.myLocalCurrency == "GHS" && this.state.url !== `https://www.pulse.com.gh`) reloadGhana()
                else {
                    await global.timeout?.(2000)
                    if (global.myLocalCurrency == "GHS" && this.state.url !== `https://www.pulse.com.gh`) reloadGhana()
                }
            }
        }
    }



    render() {

        if (this.state.items) return (
            <View style={{ flex: 1, }}>
                {this.state.showWebView &&
                    <View style={{ height: 600, flex: 1, backgroundColor: '#fff', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <TouchableOpacity onPress={() => { this.setState({ showWebView: false, webviewItem: '' }); }} >
                            <Text style={{ color: '#F44336', textAlign: 'right', padding: 10 }}>Close</Text></TouchableOpacity>
                        <WebView
                            originWhitelist={['*']}
                            //injectedJavaScript={`const meta = document.createElement('meta'); meta.setAttribute('content', 'width=width, initial-scale=1, maximum-scale=1, user-scalable=2.0'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta); `}
                            source={{
                                html: `<!DOCTYPE html><html><head><meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=0.8">
                <style></style></head>${this.state.webviewItem}</html>`
                            }}
                            style={{ resizeMode: 'cover', flex: 1 }}
                            javaScriptEnabled={true}
                            domStorageEnabled={true}
                            allowsInlineMediaPlayback={true}
                            allowsFullscreenVideo={true}
                            allowUniversalAccessFromFileURLs={true}
                            mediaPlaybackRequiresUserAction={false}
                            useWebKit={true}

                        />
                    </View>}
                {this.state.showWebView && <Button
                    onPress={() => Linking.openURL(this.state.url)}
                    title={global.myLocalCurrency == "GHS" ? "Read this on Pulse.com.gh" :
                        "Read this on Pulse.ng"}
                    color="#AA2924"
                    accessibilityLabel="Learn more about pulseng news"
                />}

                {!this.state.showWebView && <View style={{ justifyContent: 'center' }}>

                    {
                        <View style={{}}>
                            {this.state.items.map((u, id) => {
                                const publishedDate = new Date(Date.parse(u.published))
                                return (
                                    <View style={{ borderRadius: 20, }} key={id}>
                                        <TouchableOpacity onPress={() => {
                                            this.props.navigation.navigate("homeNews");
                                        }}>
                                            <Card containerStyle={{ flex: 1, width: "100%", marginHorizontal: 0, borderWidth: 0, marginBottom: 1, paddingTop: 0, paddingLeft: 0, overflow: 'hidden', height: 90, borderRadius: 12, backgroundColor: '#272727' }} >
                                                <View style={{ flexDirection: 'row' }}>
                                                    <Image
                                                        style={{
                                                            height: 100,
                                                            width: 105,
                                                        }}
                                                        resizeMode="cover"
                                                        source={{ uri: u.imageURL }}
                                                    />
                                                    <View style={{ flex: 1 }}>
                                                        <Text style={styles.textStyle}><Moment fromNow ago element={Text}>{publishedDate}</Moment> &nbsp; ago</Text>
                                                        <Text style={{ color: '#ffffff', paddingHorizontal: 10, }} numberOfLines={2}>{u.title}</Text>
                                                        <View style={styles.textStyleMore}><Text style={{ color: 'grey', fontSize: 12 }}>Read more &gt;&gt;</Text></View>
                                                    </View>
                                                </View>
                                            </Card>
                                        </TouchableOpacity>
                                    </View>
                                );
                            })}
                        </View>
                    }

                </View>}
                <NavigationEvents
                    onDidFocus={async payload => {
                        this.loadRSS()
                    }}
                />
            </View>
        )
        return (
            <View style={{ flex: 1, backgroundColor: "#131313" }}>
                <View
                    style={{
                        backgroundColor: "#282828",
                        borderBottomColor: "#555",
                        borderBottomWidth: 1,
                        ...Platform.select({ ios: { height: 100 }, android: { height: 75 } })
                    }}
                />
                <View style={{ height: 225, alignItems: "center", justifyContent: "center" }} />
                <Text style={{ color: "white" }}>Loading</Text>
            </View>
        );
    }


}
const styles = ({
    container: {
        flex: 1,
        backgroundColor: 'white',
        justifyContent: 'center',
        padding: 10,
    },
    textStyle: {
        fontSize: 12,
        color: 'grey',
        padding: 10
    },
    textStyleMore: {

        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },


});
